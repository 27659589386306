import { connect } from 'react-redux';
import { loadPage } from 'src/actions/common';
import { addAppSnackNotice, clearAppNotices } from 'src/actions/snackNotice';
import pageSelectors from './selectors';
import AvailabilityPage from './AvailabilityPage';
import { submitAvailability, toggleModal } from './actions';

export default connect(
  pageSelectors,
  { addAppSnackNotice, clearAppNotices, submitAvailability, toggleModal, loadPage },
)(AvailabilityPage);
