import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { layoutUpdate } from 'src/actions/common';

const withLayout = (layout) => (Component) => connect(
  null,
  { layoutUpdate },
)(
  class extends React.Component {
    static displayName = `Guard(${Component.name})`;

    static propTypes = {
      layoutUpdate: PropTypes.func.isRequired,
    };

    componentWillMount() {
      // eslint-disable-next-line no-shadow
      const { layoutUpdate } = this.props;
      layoutUpdate(layout);
    }

    render() {
      return <Component {...this.props} />;
    }
  },
);

export default withLayout;
