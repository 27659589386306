import { fromJS } from 'immutable';
import { PAGE_LOADED } from 'src/constants/common';
import { PAGE_NAME } from './constants';

const initialState = fromJS({ partner: '', totalCompletedServices: null, skus: null, });

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case PAGE_LOADED: {
      if (action.page === PAGE_NAME) {
        const { partner, totalCompletedServices, skus } = action;
        return state.merge({ partner, totalCompletedServices, skus });
      }
      return state;
    }
    default:
      return state;
  }
}

export default pageReducer;
