import * as Yup from 'yup';

export const TAB_LABELS = ['Personal Info', 'Billing'];

export const ACCOUNT_SCHEMA = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .email('Enter a valid email address')
    .required('Required'),
  phone: Yup.string()
    .test('len', 'Required', (val) => val && val.replace(/\D/g, '').length >= 10)
    .required('Required'),
  address: Yup.string().required('Required'),
  address2: Yup.string(),
});

export const SNACKBAR_ACCOUNT_TAB = 'evAccountTab';
export const SNACKBAR_BILLING_TAB = 'evBillingTab';
