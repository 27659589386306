// Libraries
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { compact } from 'lodash';
// Components
import { Grid } from 'HTKit/Grid';
import Banner from 'src/components/Elements/Banner';
import Icon from 'src/components/HTKit/Icon';
import Carousel from 'src/components/Elements/Carousel';
// Styles
import styles from './styles.scss';

const ServiceBanner = ({
  containerClasses,
  itemStyle,
  textColor,
  showBlobs,
  toolkitLabel,
  clockLabel,
  checkmarkLabel,
  theme,
}) => {
  // Styles
  const bannerStyles = cn(styles.serviceBanner, containerClasses, styles[theme]);
  const itemStyles = cn(
    styles.item,
    {
      white: !textColor,
      [textColor]: !!textColor,
    },
    styles[theme],
  );
  const slideStyles = cn(styles.slide, itemStyle);

  /*
    We need to set up outside the render or else Slick will somehow pick up null children (if
    that makes sense)
  */
  const slides = compact([
    toolkitLabel && (
      <h5 className={itemStyles}>
        <Icon name="tool" className={styles.toolkit} /> {toolkitLabel}
      </h5>
    ),
    clockLabel && (
      <h5 className={itemStyles}>
        <Icon name="flying-clock" className={styles.service} /> {clockLabel}
      </h5>
    ),
    checkmarkLabel && (
      <h5 className={itemStyles}>
        <Icon name="checkmark-broken-circle" className={styles.check} /> {checkmarkLabel}
      </h5>
    ),
  ]);
  const slidesToShow = slides.length;

  return (
    <Banner className={bannerStyles} showBlobs={showBlobs}>
      <Grid.FullWidth>
        <Carousel
          className={styles.carousel}
          autoplay
          autoplaySpeed={4000}
          speed={800}
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToShow}
          itemStyle={slideStyles}
          resolutions={{
            1020: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          }}
        >
          {slides}
        </Carousel>
      </Grid.FullWidth>
    </Banner>
  );
};

ServiceBanner.propTypes = {
  theme: PropTypes.oneOf(['default', 'light']),
  containerClasses: PropTypes.string,
  itemStyle: PropTypes.string,
  showBlobs: PropTypes.bool,
  textColor: PropTypes.string,
  toolkitLabel: PropTypes.string,
  clockLabel: PropTypes.string,
  checkmarkLabel: PropTypes.string,
};

ServiceBanner.defaultProps = {
  theme: 'default',
  showBlobs: true,
  toolkitLabel: 'Thousands of Techs Nationwide',
  clockLabel: 'Service as Soon as Today',
  checkmarkLabel: 'Don’t Pay Until It’s Done',
};

export default memo(ServiceBanner);
