import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import InputFieldV2 from 'src/components/HTKit/Forms/InputFieldV2';
import Form from 'src/components/HTKit/Forms/Form';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { logger } from 'src/utils/logger';
import { sleep } from 'src/utils/helpers';
import { homePath, push } from 'src/utils/paths';
import styles from './styles.scss';
import { fieldSaleAgentClientSelector, fieldSaleAgentIdSelector } from '../FieldSales/selectors';
import { pureCartSelector } from '../../selectors/cart';

const TwoFactorForm = ({ verifyPin }) => {
  const fieldSalesClient = useSelector(fieldSaleAgentClientSelector);
  const fieldSalesAgentId = useSelector(fieldSaleAgentIdSelector);
  const cart = useSelector(pureCartSelector);
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    pin: Yup.string()
      .min(5)
      .max(7)
      .required('Please enter a pin number')
  });

  const formik = useFormik({
    initialValues: { pin: '' },
    validationSchema: schema,
    validateOnChange: false,
    onSubmit({pin}) {
      verifyPin(pin.trim());
    },
  });

  useEffect(() => {
    const { clientId = '' } = fieldSalesClient;
    const { id = '' } = cart;

    (async () => {
      if (!clientId || !fieldSalesAgentId) {
        logger("Pin Redemption Error")(
          `
        clientId: ${clientId} :
        agentId: ${fieldSalesAgentId} : 
        cartId: ${id}`
        );

        await sleep(100);
        dispatch(push(homePath))
      }
    })()
  }, [fieldSalesClient, fieldSalesAgentId])

  return (
    <Form onSubmit={formik.handleSubmit} classes={styles.form}>
      <Form.Row>
        <Form.Column sm={4} md={4} mdOffset={2} lgOffset={4} lg={4}  classes={styles.inputWrapper}>
          <InputFieldV2
            type="text"
            name="pin"
            inputWrapperClass={styles.input}
            onChange={formik.handleChange}
            placeHolder="e.g. 12345"
            error={formik.errors.pin}
            value={formik.values.pin}
          />
        </Form.Column>
      </Form.Row>
      <Form.Row>
        <Form.Column classes={styles.inputWrapper}>
          <Button theme={THEMES.V2PRIMARY} className={styles.button}>
            {' '}
            Continue My Order
          </Button>
        </Form.Column>
      </Form.Row>
    </Form>
  );
};

TwoFactorForm.propTypes = {
  verifyPin: PropTypes.func.isRequired
}

export default TwoFactorForm;
