import { UPSELL_PLAN_ID, UPSELL_PLAN_BILLING_CYCLES } from 'src/constants/plan';

export const UPSELL_PLAN_CONTENT_DEFAULTS = {
  title: (estimatedSavings, planId) => {
    if(!estimatedSavings || !planId) return '';
    return (
      planId === UPSELL_PLAN_ID
        ? `Save ${estimatedSavings} on this order!`
        : 'Get unlimited support for your home devices'
    )
  },
  subTitle: (planName, amount, planInterval) => {
    if(!planName || !amount || !planInterval) return '';
    return `Join ${planName} for only $${amount}/${planInterval}*`
},
  disclaimer: (planInterval) =>
    planInterval === UPSELL_PLAN_BILLING_CYCLES.MONTHLY
      ? '*Membership amount is charged on a monthly basis but is an annual commitment. Cancel anytime within 30 days at no cost'
      : '*Cancel anytime within 30 days and we’ll refund the total cost of the membership.',
};
