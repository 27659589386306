import { createSelector } from 'reselect';
import { QA_FLOW_VALID_SKUS } from 'src/containers/AddSkuPage/QuestionsFlowPage/constants';

const getSkuIdByNewItem = state => state.getIn(['newItem', 'skuId'], 0);

const getSkuIdBySku = state => state.getIn(['sku', 'id'], 0);

export const isQuestionAnswerFlowSelector = createSelector(
  [getSkuIdByNewItem, getSkuIdBySku],
  (...skus) => !!skus.find(sku =>  QA_FLOW_VALID_SKUS.includes(sku))
);

