/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware, connectRouter } from 'connected-react-router/immutable';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk'
import { reducerMiddleware as bugsnagMiddleware } from 'src/utils/Bugsnag';
// import createReducer, { createReducerWrapper } from './reducers/index';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(
  initialState = {},
  history,
  createReducer,
  createReducerWrapper,
) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  let composeEnhancers = compose;
  if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }
  /* eslint-enable */
  // const rootReducer = createReducer();
  const store = createStore(
    createReducerWrapper(connectRouter(history)(createReducer({}, history))),
    fromJS(initialState),
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        thunk,
        routerMiddleware(history),
        bugsnagMiddleware(),
      ),
    ),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.asyncReducers = {}; // Async reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  return store;
}
