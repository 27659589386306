import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import cn from 'classnames';
import { Element } from 'react-scroll';
import { push } from 'src/utils/paths';
import { selectLocationPage } from '../../actions';
import GeoBreadcrumbs from './GeoBreadcrumbs/lazy';
import GeoChildren from './GeoChildren/lazyGeoChildren';
import styles from './styles.scss';

const LocationsList = ({ breadcrumbs, childLocations, location, locationPath }) => {
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(5);
  const dispatch = useDispatch();

  const pageCount = Math.ceil(location.get('totalChildren') / location.get('perPage'));
  const showPagination = location.get('totalChildren') > location.get('perPage');

  const increasePageRange = (e) => {
    e.preventDefault();
    setPageRangeDisplayed(pageRangeDisplayed + 100);
  };

  const goToCrumb = ({ link }) => {
    if (link) dispatch(push(link));
  };

  const handlePageClick = (e) => {
    const page = e.selected + 1;
    dispatch(selectLocationPage({ page, locationPath }));
  };

  return (
    <Element name="locations">
      <section className={cn('l-content-section', styles.geoSection)}>
        <GeoBreadcrumbs className="l-section-header" links={breadcrumbs} onClick={goToCrumb} />
        <GeoChildren list={childLocations} />

        {showPagination && (
          <div className={styles.paginationWrapper}>
            <ReactPaginate
              previousLabel={<span className={styles.nextPrevPage}>Previous</span>}
              nextLabel={<span className={styles.nextPrevPage}>Next</span>}
              breakLabel={
                <a href="" onClick={increasePageRange}>
                  ...
                </a>
              }
              breakClassName={styles.paginationBreak}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={pageRangeDisplayed}
              disableInitialCallback
              forcePage={location.get('page') - 1}
              onPageChange={handlePageClick}
              containerClassName={styles.paginationContainer}
              subContainerClassName={styles.paginationPageContainer}
              activeClassName={styles.activePage}
            />
          </div>
        )}
      </section>
    </Element>
  );
};

LocationsList.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
  childLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  location: PropTypes.object,
  locationPath: PropTypes.string,
};

export default memo(LocationsList);
