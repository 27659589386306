import { fromJS } from 'immutable';
import { PAGE_LOADED } from '../../../constants/common';

const accountInitialState = fromJS({});

export default function accountReducer(state = accountInitialState, action) {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        default:
          return state;
      }
    default:
      return state;
  }
}
