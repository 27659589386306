export const SUBMIT_AVAILABILITY = 'availabilityPage/SUBMIT_AVAILABILITY';
export const AVAILABILITY_SUBMITTED = 'availabilityPage/AVAILABILITY_SUBMITTED';
export const TOGGLE_MODAL = 'availabilityPage/TOGGLE_MODAL';
export const GET_AVAILABLE_TIMES = 'availabilityPage/GET_AVAILABLE_TIMES';
export const SUBMIT_TIMES_AND_UPDATE_CART = 'availabilityPage/SUBMIT_TIMES_AND_UPDATE_CART';

export const PARTNERS = {
  SAMSUNG: 'samsung',
  WALMART: 'walmart',
};

export const AVAILABILITY_FORM_ID = 'availabilityForm';

export const PAGE_NAME = 'availabilityPage';
