import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ModalV2, SIZES } from 'src/components/HTKit/Modals/ModalV2';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { agentStartNewProcess, fieldSalesPathAction } from 'src/containers/FieldSales/actions';
import { purePageSelector } from 'src/selectors/page';
// utils & constants
import { PAGE_NAME_CURRENT, QRCODE_PAGE_NAME } from 'src/containers/FieldSales/constants';
import { fieldSalesSkuListPath } from 'src/utils/paths';
import styles from './styles.scss';

const NewSalesOrderModal = ({ visible, onClose }) => {
  // hooks
  const dispatch = useDispatch();
  const { name } = useSelector(purePageSelector(PAGE_NAME_CURRENT));
  const qrCodePage = name === QRCODE_PAGE_NAME;

  // --------------- methods ----------
  // close modal and redirect to skulist
  const closeAndRedirect = () => {
    onClose(false);
    dispatch(fieldSalesPathAction(fieldSalesSkuListPath))
  }
  // removes current cart, starts new cart
  const onStartNewOrderClick = () => {
    dispatch(agentStartNewProcess({}, closeAndRedirect));
  }

  const modalText = qrCodePage
    ? 'The current order will no longer be visible to you.'
    : 'Starting a new order will empty the current cart. Are you sure?'

  const startNewOrderButton = (
    <Button theme={THEMES.V2PRIMARY} onClick={onStartNewOrderClick}>
      Start New Order
    </Button>
  );

  return (
    <ModalV2
      size={SIZES.MEDIUM}
      isVisible={visible}
      hide={onClose}
      styles={styles.contentModalWrapper}
      header="Start New Order"
      footerElement2={startNewOrderButton}
    >
      <p className="p1">{modalText}</p>
      { qrCodePage && <p className="p1 paddingTop-small">Your ID has been associated with the order and will be attributed when the customer completes the order</p>}
    </ModalV2>
  );
};

NewSalesOrderModal.propTypes = {
  isCartEmpty: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default NewSalesOrderModal;

/*
  USAGE:
    import Modals from 'src/components/Sales/Modals';
    <Modals isCartEmpty visible={isAddDevice} onClose={closeFunction}/>

  PROPS:
    isCartEmpty: This is a boolean that tells us whether the cart has items (name can be changed according to condition, later)
    visible: Boolean that tells the modal when to open.
    onClose: Function that triggers when you click on X
*/
