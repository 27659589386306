import { createSelector } from 'reselect';

const trackingSelector = (state) => state.get('tracking', {}).toJS();
const trackingPhoneSelector = (state) => state.getIn(['tracking', 'phone']);

export const phoneTitleSelector = createSelector(
  trackingPhoneSelector,
  (phone) => phone.get('title'),
);
export const phoneLinkSelector = createSelector(
  trackingPhoneSelector,
  (phone) => phone.get('link'),
);
export const irclickidSelector = createSelector(
  trackingSelector,
  (tracking) => tracking.irclickid,
);

