import memoize from 'lodash/memoize';
import { SCHEDULE_TYPES, SCHEDULER_VIEW_TYPES } from './serviceScheduling.constants';

/**
 * @param {{cart: Cart}}
 */
export const createCartServiceScheduling = ({ cart }) => {
  const isRecurringBookingAllowed = () => cart.allowRecurringBooking ?? false;
  const isExactTimeSchedule = () => cart.scheduleType === SCHEDULE_TYPES.EXACT_TIME;
  const isTimeWindowSchedule = () => cart.scheduleType === SCHEDULE_TYPES.TIME_WINDOW;

  const getScheduleType = () => cart.scheduleType;
  const getExactTimeType = () => cart.exactTimeType;
  const hasRecurringBooking = () => cart.recurring;

  const getAvailabilityPageConfig = ({ useTimeWindowGrid = false } = {}) => {
    let schedulerViewType;

    if (isExactTimeSchedule()) schedulerViewType = SCHEDULER_VIEW_TYPES.EXACT_TIME_SELECT;
    if (isTimeWindowSchedule()) {
      if (useTimeWindowGrid) {
        schedulerViewType = SCHEDULER_VIEW_TYPES.TIME_WINDOW_GRID;
      } else {
        schedulerViewType = SCHEDULER_VIEW_TYPES.TIME_WINDOW_SELECT;
      }
    }
    return {
      schedulerViewType,
    };
  };

  return {
    /** Check if recurring booking is allowed */
    isRecurringBookingAllowed,
    /** Check if the schedule type is exact time */
    isExactTimeSchedule,
    /** Check if the schedule type is time window */
    isTimeWindowSchedule,
    /** Get the schedule type */
    getScheduleType,
    /** Get the exact time type */
    getExactTimeType,
    /** Config object for the availability page */
    getAvailabilityPageConfig,

    /** Check if cart has a recurring booking */
    hasRecurringBooking,
  };
};

export const DEFAULT_RETURN_VALUES = {
  isRecurringBookingAllowed: () => false,
  isExactTimeSchedule: () => false,
  isTimeWindowSchedule: () => false,
  getScheduleType: () => SCHEDULE_TYPES.TIME_WINDOW,
  getExactTimeType: () => 'hourly',
  getAvailabilityPageConfig: () => ({
    schedulerViewType: SCHEDULER_VIEW_TYPES.EXACT_TIME,
  }),
  hasRecurringBooking: () => false,
};

const resolver = ({ cart }) => {
  return JSON.stringify({
    id: cart.id,
    token: cart.token,
    allowRecurringBooking: cart.allowRecurringBooking,
    availability: cart.availability,
    exactTimeType: cart.exactTimeType,
    recurrence: cart.recurrence,
    recurring: cart.recurring,
    scheduleType: cart.scheduleType,
  });
};
export const memoizedCreateCartServiceScheduling = memoize(createCartServiceScheduling, resolver);
export const memoizedCreateCartServiceSchedulingForCompletedOrders = memoize(
  createCartServiceScheduling,
  resolver,
);

/** @typedef {import('./serviceScheduling.types').ExactTimeTypes} */
/** @typedef {import('src/types/cart').Cart} Cart */
