import React, { memo, useState, cloneElement, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';
import cn from 'classnames';
import { Grid } from 'src/components/HTKit/Grid';
import { HEADER_ID } from 'src/components/Header/constants';
import BREAKPOINTS from 'src/components/HTKit/Grid/constants';
import StickyBar from 'src/components/Layout/StickyBar';
import { getScreenSizes } from 'src/utils/ui';
import styles from './styles.scss';

/*
  The bounding box is a very short rectangle positioned 45% from the
  top of the viewport. Its height is 1% of the viewport.
  If a section enters this small rectangle, <InView /> will trigger a callback.
*/
const IN_VIEW_OPTS = {
  root: null,
  rootMargin: '-45% 0px -54% 0px',
};

const DyanmicTabs = ({
  tabs,
  selected: selectedTab,
  children,
  onChange = () => {},
  containerClass = '',
  tabsOuterWrapperClass = '',
  tabsOffsetHeight: _tabsOffsetHeight,
  tabsClasses,
  showStacked = true,
  leftOffset = 10,
  isSticky,
  rowClasses,
  subNavRef,
}) => {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(true);
  const [tabsOffsetHeight, setTabsOffsetHeight] = useState(0);
  const [selected, setSelected] = useState(selectedTab || 0);
  const component = children[selected];
  const rowRef = useRef();
  const sectionsRef = useRef();

  // Manually set isMobileOrTablet
  useEffect(() => {
    const { width } = getScreenSizes();
    setIsMobileOrTablet(width < BREAKPOINTS.LG_GRID);
  }, []);

  // Set default tab
  useEffect(() => {
    if (selectedTab && selectedTab !== selected) {
      setSelected(selectedTab);
    }
  }, [selectedTab, selected]);

  // Setting the value of where the section should scroll to
  useEffect(() => {
    if (!isMobileOrTablet) return;
    if (_tabsOffsetHeight) {
      setTabsOffsetHeight(_tabsOffsetHeight);
    } else {
      const elem = document && document.querySelector(`#${HEADER_ID}`);
      if (elem) {
        setTabsOffsetHeight(elem.getBoundingClientRect().height);
      }
    }
  }, [_tabsOffsetHeight, isMobileOrTablet]);

  const scrollTabLabelIntoView = useCallback(
    (item) => {
      // Offseting by -10 so the tab won't sit flush to the screen
      rowRef.current.scrollTo({
        behavior: 'smooth',
        left: rowRef.current.children[item].offsetLeft - leftOffset,
      });
    },
    [rowRef],
  );

  const updateSelectedItem = useCallback((item) => {
    setSelected(item);
    onChange(item);
    scrollTabLabelIntoView(item);
  }, []);

  const handleOnClick = useCallback(
    (item) => () => {
      if (isMobileOrTablet && showStacked) {
        /*
          Offsetting by tabsOffsetHeight and height of the tabs row will result
          in the section sitting flush to the bottom of the tabs row.
          */
        const top =
          sectionsRef.current.children[item].getBoundingClientRect().top +
          window.pageYOffset -
          tabsOffsetHeight -
          rowRef.current.getBoundingClientRect().height;
        window.scrollTo({ behavior: 'smooth', top }); // scroll section into view
        scrollTabLabelIntoView(item);
      } else if (isMobileOrTablet && isSticky) {
        updateSelectedItem(item);
        const top =
          rowRef.current.children[item].getBoundingClientRect().top +
          rowRef.current.getBoundingClientRect().top +
          rowRef.current.getBoundingClientRect().y;
        window.scrollTo({ behavior: 'smooth', top });
      } else {
        updateSelectedItem(item);
      }
    },
    [tabsOffsetHeight, isMobileOrTablet, isSticky],
  );

  const handleInViewChange = useCallback(
    (item) => (inView) => {
      if (inView) {
        updateSelectedItem(item);
        scrollTabLabelIntoView(item);
      }
    },
    [],
  );

  return (
    <div className={cn(styles.container, containerClass)}>
      <StickyBar subNavRef={subNavRef} className={tabsOuterWrapperClass}>
        <Grid.FullWidth classes={styles.overflowHidden}>
          <div className={cn(rowClasses, styles.tabsRow)} ref={rowRef}>
            {tabs.map((tab, index) => (
              <div
                key={tab}
                onClick={handleOnClick(index)}
                className={cn('h6 paddingY-small1 marginRight-medium1', styles.tab, tabsClasses, {
                  [styles.selected]: selected === index,
                })}
              >
                {tab}
              </div>
            ))}
          </div>
        </Grid.FullWidth>
      </StickyBar>
      {isMobileOrTablet && showStacked ? (
        <div ref={sectionsRef} className={styles.stackedWrapper}>
          {children.map((child, i) => (
            <InView key={child.props.label} onChange={handleInViewChange(i)} {...IN_VIEW_OPTS}>
              {child}
            </InView>
          ))}
        </div>
      ) : (
        <>{component && cloneElement(component)}</>
      )}
    </div>
  );
};

DyanmicTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.arrayOf(PropTypes.element),
  onChange: PropTypes.func,
  containerClass: PropTypes.string,
  tabsOuterWrapperClass: PropTypes.string,
  tabsOffsetHeight: PropTypes.number,
  tabsClasses: PropTypes.string,
  selected: PropTypes.number,
  showStacked: PropTypes.bool,
  leftOffset: PropTypes.number,
  isSticky: PropTypes.bool,
  rowClasses: PropTypes.string,
  subNavRef: PropTypes.object,
};

export default memo(DyanmicTabs);

/*
  DynamicTabs

  This component seemed very specfic to ProductPage, but please feel
  free to move this to the Elements folder if the design team wants
  to use this component again.

  Given a list of tabs and their corresponding components, show one component
  if its tab is active. This is the behavior for desktop.

  On tablet and mobile, all components are rendered. When this component
  reaches the top nav, the row with the tabs will stick below
  the top nav unless the styles are overridden. A tap on the tab will
  scroll the window to its corresponding section. On scroll, the active tab will
  automatically highlight.

  ----

  Props:

  - tabs
    - Array of string where each item is the tab label.
  - children
    - Array of components to show. The order of these components should align with
      the order of the items in prop tabs.
  - selected
    - A number to set the default tab. It can also be used to control the tab
      state from outside the component.
  - tabsOffsetHeight
    - This is used to determine the top value for the sticky tabs row. The default
      is the height of the top nav so that the row will stick below it.
      ProductPage manually sets this value because the page has secondary header,
      so the tabs need to sit below this header instead.
  - onChange
    - Callback after the active tab is set.
*/
