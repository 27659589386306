// Libraries
import React, { useState, useRef } from 'react';
import cn from 'classnames';
// Components
import Icon from 'src/components/HTKit/Icon';
import SearchInput from 'src/components/Searchbar/SearchInput';
// Utils
import useOutsideClick from 'src/hooks/useOutsideClick';
// Constants
import BREAKPOINTS from 'src/components/HTKit/Grid/constants';
import { DEFAULT_TEXTS } from 'src/components/Searchbar/constants';
import HOMEPAGE_SEARCH from './constants';
// Styles
import styles from './styles.scss';

const HeroSearchbar = () => {
  const [isActive, setisActive] = useState(false);
  const wrapperRef = useRef(null);
  const handleClose = () => {
    setisActive(false);
    if (isActive) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
        duration: 300,
      });
    }
  };
  const { userAgent } = window.navigator;
  const isIOS = userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i);
  const screenWidth = window.innerWidth;
  const isMobile = screenWidth < BREAKPOINTS.MD_GRID;

  const handleClick = (e) => {
    if (e && e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
    setisActive(true);
    const element = document.getElementById(HOMEPAGE_SEARCH.id);
    const yOffset = -72; // from top of results to top of input + 16px offset from top.
    const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    if (!isIOS && isMobile) {
      window.scrollTo({
        top: yPosition,
        behavior: 'smooth',
        duration: 300,
      });
    }
  };
  const showSearchStyles = isActive ? styles.iosSearchContainer : styles.hide; // had to add a div and hide/show in css because ios was not autofocusing on the input first onclick.
  const showSearchComponent = () => {
    const isMobileOrTablet = screenWidth < BREAKPOINTS.LG_GRID;
    if (isMobileOrTablet && isIOS) {
      return (
        <>
        <div className={showSearchStyles}>
          <SearchInput
            location={HOMEPAGE_SEARCH.segmentLocation}
            isHomepageSearch
            inputClass={styles.searchInput}
            inputWrapperClass={styles.inputContainer}
            placeholder={DEFAULT_TEXTS.PLACEHOLDER.heroSearchText}
            activeSearch={isActive}
            autoFocus={isActive}
          />
        </div>
        {!isActive && <p className="p0 n300 paddingLeft-small">{DEFAULT_TEXTS.PLACEHOLDER.heroSearchText}</p>}
        </>
      );
    }
    return isActive ? (
      <SearchInput
        location={HOMEPAGE_SEARCH.segmentLocation}
        isHomepageSearch
        inputClass={styles.searchInput}
        inputWrapperClass={styles.inputContainer}
        placeholder={DEFAULT_TEXTS.PLACEHOLDER.heroSearchText}
        activeSearch={isActive}
        autoFocus
      />
    ) : (
      <p className="p0 n300 paddingLeft-small">{DEFAULT_TEXTS.PLACEHOLDER.heroSearchText}</p>
    );
  };
  const searchStyles = isIOS ? cn(styles.searchbar, styles.searchbarIos) : styles.searchbar;

  useOutsideClick(wrapperRef, handleClose, [isActive]);
  return (
    <section id={HOMEPAGE_SEARCH.id} ref={wrapperRef} className={styles.searchWrapper}>
      <Icon name={HOMEPAGE_SEARCH.searchIcon} className={styles.searchIcon} />
      <div onClick={(e) => handleClick(e)} className={searchStyles}> {/* the onclick is here instead of section because product didn't want the icon to be clickable */}
        {showSearchComponent()}
      </div>
      {isActive && (
        <Icon name="v2-close-icon" className={styles.closeIcon} onClick={() => handleClose()} />
      )}
    </section>
  );
};

export default HeroSearchbar;
