import homeCheckup from 'src/images/icons/home-checkup.svg';
import discounts from 'src/images/icons/inhome-discounts.svg';
import unlimitedSupport from 'src/images/icons/members-hotline.svg';
import support from 'src/images/icons/online-support.svg';
import antivirus from 'src/images/icons/premium-antivirus.svg';
import password from 'src/images/icons/premium-password.svg';
import productDiscount from 'src/images/icons/product-discount.svg';

export const PLAN_BENEFITS_IDS = {
  IN_HOME: 'inHome',
  ONLINE: 'online',
  CHECKUP: 'checkup',
  ANTIVIRUS: 'antivirus',
  PASSWORD: 'password',
  HOME_TECH: 'homeTech',
  PRODUCT: 'product',
};

export const BENEFITS_DESCRIPTION = {
  remote: "Get free 'round-the-clock' support for computers, printers, and more.",
  malwarebytes:
    "We've partnered with MalwareBytes to offer the best in protection against computer threats.",
  dashlane: 'Manage passwords securely, effortlessly, and across all devices with Dashlane.',
  homeTechCheckup:
    "Exclusive to members, we'll come to your home to evaluate and improve your home tech's security, reliability, and speed.",
  homeTechSpecialist: 'Unlimited, instant help with any connected device. No appointments needed.',
  inHomeServices: 'Save up to $100 with a members-only discount for any in-home service.',
  productDiscount: (discount) =>
    `Save ${discount}% on curated smart home products, with or without installation.`,
};

export const SERVICES = Object.freeze({
  malwarebytes: {
    name: 'Premium Antivirus Software',
    mobileName: 'Premium Antivirus Software',
    description: BENEFITS_DESCRIPTION.malwarebytes,
  },
  dashlane: {
    name: 'Premium Password Manager',
    mobileName: 'Premium Password Manager',
    description: BENEFITS_DESCRIPTION.dashlane,
  },
  google: {
    name: 'Google Home Mini',
    mobileName: 'Google Home Mini',
    description: '',
  },
});

export const REMOTE = {
  value: 100,
  valueFormatted: '$100',
};

//  HTH plan benfits for membership page
export const SMART_HOME_BENEFITS_DETAILS = {
  homeTech: {
    id: PLAN_BENEFITS_IDS.HOME_TECH,
    header: 'Home Tech Specialist',
    alternateHeader: 'Home Tech Specialist',
    subheader: BENEFITS_DESCRIPTION.homeTechSpecialist,
    badgeCopy: 'save up to $100',
    icon: support,
  },
  online: {
    id: PLAN_BENEFITS_IDS.ONLINE,
    header: 'Unlimited 24/7 Online Support',
    subheader: BENEFITS_DESCRIPTION.remote,
    badgeCopy: 'save up to $100',
    icon: unlimitedSupport,
    value: '$100',
  },
  inHome: (truckRollFee = '69') => ({
    id: PLAN_BENEFITS_IDS.IN_HOME,
    header: `Only $${truckRollFee} for In-Home Services`,
    subheader: BENEFITS_DESCRIPTION.inHomeServices,
    badgeCopy: 'membership exclusive',
    icon: discounts,
  }),
  productDiscount: (discount = '10') => ({
    id: PLAN_BENEFITS_IDS.PRODUCT,
    header: `${discount}% Savings on Products`,
    subheader: BENEFITS_DESCRIPTION.productDiscount(discount),
    badgeCopy: 'membership exclusive',
    icon: productDiscount,
  }),
  checkup: {
    id: PLAN_BENEFITS_IDS.CHECKUP,
    header: 'Home Technology Checkup',
    subheader: BENEFITS_DESCRIPTION.homeTechCheckup,
    badgeCopy: 'valued at $100',
    icon: homeCheckup,
    value: '$100',
  },
  antivirus: {
    id: PLAN_BENEFITS_IDS.ANTIVIRUS,
    header: 'Premium Antivirus Software',
    subheader: BENEFITS_DESCRIPTION.malwarebytes,
    badgeCopy: 'valued at $40',
    icon: antivirus,
    value: '$40',
  },
  password: {
    id: PLAN_BENEFITS_IDS.PASSWORD,
    header: 'Premium Password Manager',
    subheader: BENEFITS_DESCRIPTION.dashlane,
    badgeCopy: 'valued at $60',
    icon: password,
    value: '$60',
  },
};

// online plan benfits for membership page
export const ONLINE_DETAILS = {
  homeTechSpecialist: {
    id: PLAN_BENEFITS_IDS.HOME_TECH,
    header: 'Home Tech Specialist',
    subheader: BENEFITS_DESCRIPTION.homeTechSpecialist,
    badgeCopy: 'save up to $100',
  },
  online: {
    id: PLAN_BENEFITS_IDS.ONLINE,
    header: 'Unlimited 24/7 Online Support',
    subheader: BENEFITS_DESCRIPTION.remote,
    badgeCopy: 'save up to $100',
  },
};

// HT Lite plan benefits for membership page
export const HELLOTECH_LITE_DETAILS = {
  online: {
    id: PLAN_BENEFITS_IDS.ONLINE,
    header: 'Unlimited 24/7 Online Support',
    subheader: BENEFITS_DESCRIPTION.remote,
    badgeCopy: 'save up to $100',
  },
};

export const UPSELL_MODULE_BENEFITS_DETAILS = {
  homeTech: {
    id: PLAN_BENEFITS_IDS.HOME_TECH,
    header: 'Unlimited Remote Support',
    subheader: 'Instant access to a home tech specialist. No appointments needed',
    icon: support,
  },
  inHome: {
    id: PLAN_BENEFITS_IDS.IN_HOME,
    header: 'Exclusive Discounts',
    subheader: 'Members-only discount on in-home services and products, with $0 platform fees.',
    icon: discounts,
  },
  checkup: {
    id: PLAN_BENEFITS_IDS.CHECKUP,
    header: 'Home Technology Checkup',
    subheader: 'A comprehensive evaluation of your home technology ecosystem',
    icon: homeCheckup,
  },
  antivirus: {
    id: PLAN_BENEFITS_IDS.ANTIVIRUS,
    header: 'Premium Antivirus Software',
    subheader: 'We offer the best in protection against computer threats',
    icon: antivirus,
  },
  password: {
    id: PLAN_BENEFITS_IDS.PASSWORD,
    header: 'Premium Password Manager',
    subheader: BENEFITS_DESCRIPTION.dashlane,
    badgeCopy: 'valued at $60',
    icon: password,
  },
  productDiscount: (discount) => ({
    id: PLAN_BENEFITS_IDS.PRODUCT,
    header: 'Premium Password Manager',
    subheader: BENEFITS_DESCRIPTION.productDiscount(discount),
    icon: productDiscount,
  }),
};

/*
  These are hard coded benefits that are rendered based on what the plan's included skus offer.

  USAGE:

  Where these are being used in the codebase:
  SMART_HOME_BENEFITS_DETAILS: src/components/Plans/Pricing/utils.js and src/components/Sections/EverythingYouGetBlock/constants.js
  ONLINE_DETAILS: src/components/Plans/Pricing/utils.js
  BENEFITS_DESCRIPTION, SERVICES, REMOTE: src/components/SubscriptionBenefitsBreakdown/utils/upsellPlanItem
  UPSELL_MODULE_BENEFITS_DETAILS: src/components/HTKit/Modals/MembershipUpsellModal/utils.js
                                  This is specifically for the new upsell module. There will be a ticket to move the benefits to
                                  split or other CMS tool as product wants control over these.

  In the app:
  cart page line items (when plan is in cart)
  cart page remove plan modal
  upsell cart
  upsell Modal in services page
  field sales upsell plan modal
*/
