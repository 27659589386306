import get from 'lodash/get';
import { TYPES } from './constants';

/**
 * Simple segment track event
 * - NOTE: we need to know what these will be (patrick will pass them in on the links).
 *
 * @param typeOfLink
 * @returns {{meta: {analytics: [{eventPayload: {event: string}, eventType: string}]}, type: string}}
 */
// eslint-disable-next-line import/prefer-default-export
export function categoryLinkClick(segmentAttributes) {
  const event = get(segmentAttributes, 'event', '');
  const properties = get(segmentAttributes, 'properties', {});

  if (!event) return { type: TYPES.CATEGORY_LINK_CLICK };

  return {
    type: TYPES.CATEGORY_LINK_CLICK,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event,
            properties,
          },
        },
      ],
    },
  };
}

export const categoryPageLoadSegment = ({ eventName, loadTime, isFirstLoad }) => {
  return {
    type: TYPES.CATEGORY_PAGE_LOAD,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: eventName,
            properties: {
              loadTime,
              isFirstLoad,
            },
          },
        },
      ],
    },
  };
};
