import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { CalloutBox, CALLOUTBOX_THEMES } from 'Elements/CalloutBox';
import styles from './styles.scss';

const ErrorCalloutBox = ({ zip = '', visible, className }) => {
  if (!visible) return null;
  return (
    <div className={cn(styles.errorCallout, className)}>
      <CalloutBox
        text={`We apologize for the inconvenience. We do not currently offer service in your zip code ${zip}.`}
        theme={CALLOUTBOX_THEMES.CRITICAL}
      />
    </div>
  );
};

ErrorCalloutBox.propTypes = {
  zip: PropTypes.string,
  className: PropTypes.string,
  visible: PropTypes.bool,
};

export default ErrorCalloutBox;
