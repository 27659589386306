import { fromJS } from 'immutable';
import { UPDATE_LOCATION, UPDATE_STATUS } from './constants';

const initialState = fromJS({
  status: '',
  opened: false,
  reconnectStatus: '',
  data: {},
});

function techTrackingReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LOCATION: {
      const techLocation = action.data.payload.locations;
      return state.mergeDeepIn(['data'], techLocation);
    }
    case UPDATE_STATUS: {
      let newState = state;

      Object.keys(action.connection).forEach((key) => {
        newState = newState.set(key, action.connection[key]);
      });

      return newState;
    }
    default:
      return state;
  }
}

export default techTrackingReducer;
