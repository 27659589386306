import { SUBMIT_SCHEDULE, SCHEDULE_SUBMITTED, GET_AVAILABLE_TIMES } from './constants';

export function submitSchedule({ ranges, orderId, orderToken, publicPage }) {
  return {
    type: SUBMIT_SCHEDULE,
    ranges,
    orderId,
    orderToken,
    publicPage,
  };
}

export function scheduleSubmitted({ updated = false }) {
  return {
    type: SCHEDULE_SUBMITTED,
    updated,
  };
}

export function getAvailableTimes(data) {
  return {
    type: GET_AVAILABLE_TIMES,
    payload: data,
  };
}
