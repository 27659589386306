import React, { useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
// Hooks & Utils
import { useFormik } from 'formik';
import { logger } from 'src/utils/logger';
import { noop } from 'src/utils/event';
// Data
import { HEAR_ABOUT_US_OPTS } from 'src/constants/user';
// Components
import Form from 'src/components/HTKit/Forms/Form';
import SelectField from 'HTKit/Forms/SelectField';
import Button, { THEMES as BUTTON_THEMES } from 'src/components/HTKit/Forms/Button/index';
import InputFieldV2 from 'src/components/HTKit/Forms/InputFieldV2';
import {
  EmailFieldWithValidation,
  useExternalEmailCheck,
} from 'src/components/HTKit/Forms/EmailFieldWithValidation';
import { TermsOfServiceLink } from 'Elements/TermsOfService';
import { partnerObjectAllAvenuesJSSelector } from 'src/selectors/cart';
import styles from './styles.scss';

// Check with BE team for more values
const SOURCE = {
  PARTNER: 'partner',
};
const sanitizeFormikValues = (values) => {
  const sanitizedValues = { ...values };
  const sensitiveKeys = ['password', 'passwordConfirmation'];
  sensitiveKeys.forEach((key) => {
    if (sanitizedValues[key]) {
      sanitizedValues[key] = '**********';
    }
  });

  return sanitizedValues;
};

const logFormikStateError = (formikState, contextDescription) => {
  if (typeof formikState.errors !== 'object' || formikState.errors === null) {
    const sanitizedValues = sanitizeFormikValues(formikState.values);
    const errorInfo = {
      timestamp: new Date().toISOString(),
      description: contextDescription,
      values: sanitizedValues,
      errors: formikState.errors,
      touched: formikState.touched,
      isValid: formikState.isValid,
      dirty: formikState.dirty,
      status: formikState.status,
      submitCount: formikState.submitCount,
      initialValues: formikState.initialValues,
    };
    logger('Formik State Error')(errorInfo);
  }
};

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid email address')
    .required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  password: Yup.string()
    .label('Password')
    .min(6)
    .required('Required'),
  passwordConfirmation: Yup.string()
    .min(6)
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  hearAboutUs: Yup.string().required('Required'),
});

const CheckoutUserProfileForm = ({
  user = { email: '', firstName: '', lastName: '', phone: '' },
  opts = {},
  onValidation = noop,
  header = 'Create an Account',
  buttonText = 'Continue',
  isApiOrder = false,
}) => {
  const cartPartnerObj = useSelector(partnerObjectAllAvenuesJSSelector);
  /**
   * Typically an existing user will have a cartPartnerObj, whereas
   * a new user may not. So let's use opts.partnerName as a fallback
   * to determine if it's a partner cart.
   */
  const isPartnerCart = !!(cartPartnerObj || opts.partnerName);
  const { allowCommunication } = cartPartnerObj || {};

  // Hide hearAboutUs field for partner carts
  const modifiedSchema = useMemo(() => {
    if (isPartnerCart) {
      return schema.shape({
        hearAboutUs: Yup.string().notRequired(),
      });
    }
    return schema;
  }, [isPartnerCart]);

  /** Form details to send with onSubmit call */
  const formRef = useRef({});
  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      email: user.email || '',
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      phone: user.phone || '',
      password: '',
      passwordConfirmation: '',
      hearAboutUs: isApiOrder ? 'Other' : '',
    },
    enableReinitialize: true,
    validationSchema: modifiedSchema,
    onSubmit: (params, form) => {
      formRef.current = form;
      validateEmailWithConfig({ email: params.email });
    },
  });

  const submitUser = (params, form) => {
    logFormikStateError(formik, 'Formik Submit - CheckoutUserProfileForm');
    /**
     * Since `hear_about_us` is not passed for partner carts, `source` param is used to
     * bypass the requirement of `hear_about_us` in the BE.
     */
    const optParams = {
      ...(isPartnerCart ? { source: SOURCE.PARTNER } : {}),
    };

    /**
     * The option to subscribe for updates is no longer available. New users are automatically
     * subscribed unless they were referred from a partner cart. For partner carts, new users
     * are subscribed only if the partner allows communication.
     */

    const paramsToSubmit = {
      ...params,
      subscribeForUpdates: isPartnerCart ? allowCommunication : true,
    };
    onValidation({ params: paramsToSubmit, form, optParams, opts });
  };
  const userEmailAlreadyExists = Boolean(user.email);
  const {
    validateEmailWithConfig,
    emailValidationState,
    useSuggestion,
    handleOnChange,
  } = useExternalEmailCheck({
    useValidationService: !userEmailAlreadyExists,
    formik,
    fieldName: 'email',
    onSuccess: () => submitUser(formik.values, formRef.current),
  });

  useEffect(() => {
    logFormikStateError(formik, 'Formik Initialization - CheckoutUserProfileForm');
  }, [formik.errors]);

  return (
    <Form className={styles.form} onSubmit={formik.handleSubmit}>
      <h3 className="marginBottom-small1 text-align-center">{header}</h3>
      <Form.Row>
        <Form.Column lg={6} sm={2} md={4}>
          <InputFieldV2
            type="text"
            name="firstName"
            label="First Name"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            error={formik.errors.firstName}
          />
        </Form.Column>
        <Form.Column lg={6} sm={2} md={4}>
          <InputFieldV2
            type="text"
            name="lastName"
            label="Last Name"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            error={formik.errors.lastName}
          />
        </Form.Column>
      </Form.Row>

      <Form.Row>
        <Form.Column lg={12} sm={4} md={8}>
          <EmailFieldWithValidation
            type="text"
            name="email"
            label="Email Address"
            onChange={handleOnChange}
            value={formik.values.email}
            error={formik.errors.email}
            softWarning={emailValidationState?.did_you_mean}
            useSuggestion={useSuggestion}
          />
        </Form.Column>
      </Form.Row>

      <Form.Row>
        <Form.Column lg={12} sm={4} md={8}>
          <InputFieldV2
            type="tel"
            name="phone"
            label="Phone Number"
            mask="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
            error={formik.errors.phone}
          />
        </Form.Column>
      </Form.Row>

      <Form.Row>
        <Form.Column lg={6} sm={2} md={4}>
          <InputFieldV2
            type="password"
            name="password"
            label="Create Password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.errors.password}
          />
        </Form.Column>
        <Form.Column lg={6} sm={2} md={4}>
          <InputFieldV2
            type="password"
            name="passwordConfirmation"
            label="Confirm Password"
            onChange={formik.handleChange}
            value={formik.values.passwordConfirmation}
            error={formik.errors.passwordConfirmation}
          />
        </Form.Column>
      </Form.Row>

      {(!isPartnerCart || !isApiOrder) && (
        <Form.Row>
          <Form.Column lg={12} sm={4} md={8}>
            <SelectField
              value={formik.values.hearAboutUs}
              onChange={({ value }) => formik.setFieldValue('hearAboutUs', value)}
              label="How did you hear about us?"
              simpleValue
              clearable={false}
              searchable={false}
              options={HEAR_ABOUT_US_OPTS}
              error={formik.errors.hearAboutUs}
              v2LabelStyle
            />
          </Form.Column>
        </Form.Row>
      )}

      <Form.Row>
        <Form.Column sm={4} md={8} lg={12}>
          <Button type="submit" theme={BUTTON_THEMES.V2PRIMARY}>
            {buttonText}
          </Button>
          <TermsOfServiceLink
            className={cn('p2 n700 marginTop-tiny1')}
            allowCommunication={allowCommunication}
          />
        </Form.Column>
      </Form.Row>
    </Form>
  );
};

CheckoutUserProfileForm.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  opts: PropTypes.shape({
    /**
     * To force partner cart behavior if the partner idenity is not known through the cart object.
     */
    partnerName: PropTypes.string,
  }),
  onValidation: PropTypes.func,
  header: PropTypes.string,
  buttonText: PropTypes.string,
  isApiOrder: PropTypes.bool,
};

export default CheckoutUserProfileForm;

/*
  CheckoutUserProfileForm
  ------------------------

  - This form was originally created for the "Create Account" portion of the checkout flow. Design requested the same form be used in the API order checkout flow,
    so it's been extracted into a common-use component.
  - This form does not collect address information. Address info is entered in a separate step in the checkout flow.

  Props
    - user (obj): This form can be used with the User object we have in Redux, or any object that has the same keys/values. For example, in the new user checkout flow the
                  client's account, hasn't been created yet, but we pass an object { email: foo@bar.com } to prefill the email filled with the email they entered previously
    - onValidation (func): Executed after the form is validated
    - header (string): Customize the header text
    - buttonText: Customize the button text
*/
