import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Picture from './Picture';
import styles from './styles.scss';

const Wrapper = ({ children, className }) => (
  <div className={cn(styles.wrapper, className)}>{children}</div>
);

const PictureWrapper = ({ children, overflow = 'hidden' }) => {
  const pictureWrapperStyles = cn(styles.pictureWrapper, {
    [styles.overflowVisible]: overflow === 'visible',
  });
  return <div className={pictureWrapperStyles}>{children}</div>;
};

const StyledPicture = ({ center = true, cover = 'both', className, ...rest }) => {
  const pictureStyles = cn(className, styles.styledPicture, {
    [styles.center]: center,
    [styles.coverHeight]: cover === 'height',
    [styles.coverWidth]: cover === 'width',
    [styles.addMinHeightWidth]: cover === 'both',
  });
  return <Picture className={pictureStyles} {...rest} />;
};

const FullsizePicture = ({ className, wrapperClassName, children, ...rest }) => {
  return (
    <Wrapper className={wrapperClassName}>
      <PictureWrapper {...rest}>
        <StyledPicture className={className} {...rest} />
        {children}
      </PictureWrapper>
    </Wrapper>
  );
};

export default FullsizePicture;

Wrapper.propTypes = { className: PropTypes.string, children: PropTypes.node };
PictureWrapper.propTypes = {
  children: PropTypes.node,
  overflow: PropTypes.oneOf(['visible', 'hidden']),
};
StyledPicture.propTypes = {
  center: PropTypes.bool,
  cover: PropTypes.oneOf(['height', 'width', 'both']),
  className: PropTypes.string,
};
FullsizePicture.propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  children: PropTypes.node,
};
