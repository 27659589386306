import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Grid from 'src/components/HTKit/Grid/Grid';
import Icon from 'src/components/HTKit/Icon';
import styles from './styles.scss';

const EmptyState = ({
  additionalTitle,
  iconName,
  iconStyles,
  title,
  text,
  className,
  showButton,
  buttonContainerClass,
  children,
}) => {
  const subTitle = additionalTitle && <span>&ldquo;{additionalTitle}&rdquo;</span>;
  return (
    <Grid.Fluid classes={cn(className, styles.container)}>
      <Grid.Row classes={styles.customRow}>
        <Grid.Column classes={cn(iconStyles, styles.iconContainer)} sm={4} md={8} lg={12}>
          <Icon name={iconName} className={styles.icon} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row classes={styles.customRow}>
        <Grid.Column sm={4} md={8} lg={12}>
          <h4 className={cn('paddingTop-small1', styles.header)}>
            {title} {subTitle}
          </h4>
        </Grid.Column>
      </Grid.Row>
      {text && (
        <Grid.Row classes={styles.customRow}>
          <Grid.Column sm={4} md={6} mdOffset={1} lg={12} classes={styles.offsetOverride}>
            <p className="p1 n700 paddingTop-tiny1">{text}</p>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        {showButton && (
          <Grid.Column
            sm={4}
            classes={cn(buttonContainerClass, styles.button, 'paddingTop-small1')}
          >
            {children}
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid.Fluid>
  );
};

EmptyState.defaultProps = {
  iconName: 'logo-house-v2',
  showButton: false,
};

EmptyState.propTypes = {
  className: PropTypes.string,
  additionalTitle: PropTypes.string,
  iconName: PropTypes.string,
  iconStyles: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  buttonTheme: PropTypes.string,
  buttonClasses: PropTypes.string,
  buttonContainerClass: PropTypes.string,
  showButton: PropTypes.bool,
  children: PropTypes.node,
};

export default EmptyState;

/*

Props:
additionalTitle: for search-term
iconName: name of icon (from icomoon)
iconStyles: custom styles for icon eg: color
title: title of the empty state
text: any sub text

Usage:

import EmptyState from 'src/components/EmptyState';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';

<EmptyState
  iconName="search"
  additionalTitle="your search"
  text="Make sure all words are spelled correctly. Otherwise, please try using different keywords."
  title="No results for"
>
  <Button theme={THEMES.V2SECONDARY}/>
  <Button theme={THEMES.V2PRIMARY}/>
  <Button theme={THEMES.V2SECONDARY/>
</EmptyState>
*/
