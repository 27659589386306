import Cookies from 'js-cookie';
/**
 This cookie is set when a user enters the site with the coupon param: add_coupon_id
 1 hour expiry
 */

const EXPIRY = new Date(new Date().getTime() + 60 * 60 * 1000); // an hour, can also do 2/48
const COUPON_COOKIE_NAME = "_ht_add_coupon_id_";

export const getCouponCookie = () => Cookies.get(COUPON_COOKIE_NAME);
export const setCouponCookie = (cookieValue) => Cookies.set(COUPON_COOKIE_NAME, cookieValue, { expires: EXPIRY });
