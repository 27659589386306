import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
// Hooks
import { useFormik } from 'formik';
// Utils
import { extractZipFromAddress } from 'src/utils/address';
// Components & Styles
import Form from 'src/components/HTKit/Forms/Form';
import AddressAutocomplete from 'HTKit/Forms/AddressAutocomplete';
import InputFieldV2 from 'HTKit/Forms/InputFieldV2';
import TextAreaV2 from 'HTKit/Forms/TextAreaV2';
import Button, { THEMES } from 'HTKit/Forms/Button';
import styles from './styles.scss';

const AddressForm = ({ address = {}, buttonText = 'Next', errorsFromBackEnd, onSubmit }) => {
  const { fullAddress = '', address2 = '', details: addressDetails = '' } = address;

  const validationSchema = Yup.object().shape({
    fullAddress: Yup.string().required('Address is required.'),
    address2: Yup.string(),
    addressDetails: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      fullAddress,
      address2,
      addressDetails,
    },
    validationSchema,
    onSubmit(params) {
      const zipCode = extractZipFromAddress(params.fullAddress).zip;
      const addressToSubmit = { ...params, zip: zipCode };
      onSubmit(addressToSubmit);
    },
  });
  const onAddressChange = (addr) => {
    // The AddressAutocomplete component hijacks the native onChange event, so we have to manually call formik.handleChange
    formik.handleChange({
      target: { name: 'fullAddress', value: addr },
    });
  };

  const isSubmitButtonDisabled = !formik.values.fullAddress;

  const fullAddressError = formik.errors.fullAddress || errorsFromBackEnd.fullAddress;

  return (
    <Form withoutFormTag>
      {process.browser && (
        <Form.Row>
          <Form.Column>
            <>
              <AddressAutocomplete
                value={formik.values.fullAddress}
                onChange={onAddressChange}
                name="fullAddress"
                label="Address"
                error={fullAddressError}
              />
            </>
          </Form.Column>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Column>
          <InputFieldV2
            type="text"
            label="Apt or Suite #"
            name="address2"
            value={formik.values.address2}
            error={formik.errors.address2}
            onChange={formik.handleChange}
          />
        </Form.Column>
      </Form.Row>
      <Form.Row>
        <Form.Column>
          <TextAreaV2
            label="Additional instructions"
            placeholder="Any parking instructions or gate codes we should know about?"
            value={formik.values.addressDetails}
            name="addressDetails"
            onChange={formik.handleChange}
            error={formik.errors.addressDetails}
          />
        </Form.Column>
      </Form.Row>
      <Form.Row>
        <Form.Column>
          <div className={styles.buttonWrapper}>
            <Button
              theme={THEMES.V2PRIMARY}
              onClick={formik.handleSubmit}
              disabled={isSubmitButtonDisabled}
              className={styles.bookingButton}
            >
              {buttonText}
            </Button>
          </div>
        </Form.Column>
      </Form.Row>
    </Form>
  );
};

AddressForm.propTypes = {
  address: PropTypes.object,
  errorsFromBackEnd: PropTypes.object,
  onSubmit: PropTypes.func,
  buttonText: PropTypes.string,
};

AddressForm.defaultProps = { onSubmit: () => {}, errorsFromBackEnd: {} };

export default AddressForm;
