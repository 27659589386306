import { fromJS } from 'immutable';
import {
  TOGGLE_MODAL
} from './constants';

const initialState = fromJS({
  modals: {
    invalidDates: false,
    recommendedDates: false
  }
});

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return state.updateIn(['modals', action.modalName], (currentState) => (!currentState));
    default:
      return state;
  }
}

export default pageReducer;
