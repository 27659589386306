import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { tosPath } from 'src/utils/paths';

const TermsOfServiceLink = ({ className, allowCommunication }) => {
  return (
    <p className={className}>
      <span>By submitting, I agree to HelloTech&apos;s </span>
      <Link to={tosPath()} target="_blank" rel="noreferrer">
        terms of services.
      </Link>
      <span>
        {' '}
        I {allowCommunication ? 'agree to' : 'will not'} receive marketing communications from
        HelloTech. We will never sell your information.
      </span>
    </p>
  );
};

TermsOfServiceLink.propTypes = {
  className: PropTypes.string,
  allowCommunication: PropTypes.bool,
};

TermsOfServiceLink.defaultProps = {
  className: 'caption n900',
  allowCommunication: true,
};

export default TermsOfServiceLink;
