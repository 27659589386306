import { call, takeLatest, put, select, delay } from 'redux-saga/effects';
import { filter, includes } from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';
import logger from 'src/utils/logger';
import { LEAD_GEN_SOURCE_TYPES } from 'src/constants/tracking';
import { requestStarted, requestFinished, displayErrors } from 'src/utils/request';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { SUBSCRIPTION_TYPES } from 'src/constants/user';
import { toggleModal } from 'src/actions/modals';
import { invalidForm } from 'src/actions/common';
import { selectRoutes } from 'src/apiRoutes';
import { UPSELL_PLAN_ID } from 'src/constants/plan';
import { pureLocationSelector as locationSelector } from 'src/selectors/router';
import { setLeadGeneratedCookie } from 'src/utils/cookies/leadGenerated';
import {
  MODAL_NAME,
  GET_DISCOUNT,
  SHOW_FIRST_SERVICE_DISCOUNT_MODAL,
  SHOW_FIRST_SERVICE_DISCOUNT_DELAYED_MODAL,
  MODAL_WHITE_LISTED_URLS,
  MODAL_WITH_DELAY_WHITE_LISTED_URLS,
  HIDE_MODAL_URLS,
  LOAD_PLAN_DETAILS,
} from './constants';
import {
  discountSubmitted,
  showFirstServiceDiscountDelayedModal,
  successResponse,
  loadPlanDetails,
  planDetailsLoaded,
} from './actions';

function* isModalVisible() {
  const modalActivated = yield select((state) => state.getIn(['modals', MODAL_NAME, 'activated']));
  if (modalActivated) return false;

  const modalEnabled = yield select((state) => state.getIn(['modals', MODAL_NAME, 'enabled']));
  if (!modalEnabled) {
    yield put(showFirstServiceDiscountDelayedModal(20000));
    return false;
  }

  const account = yield select((state) => state.getIn(['user', 'account']));
  if (!account) yield loadUserProfileSaga(null, true);
  const ordersCount = yield select((state) =>
    state.getIn(['user', 'account', 'stats', 'ordersCount'], 0),
  );
  const partnerId = yield select((state) => state.getIn(['entities', 'cart', 'partner', 'id']));
  const openLoginModal = yield select((state) => state.getIn(['components', 'loginModal', 'open']));
  const landings = yield select((state) => state.getIn(['entities', 'landings']));
  const mobileSearchModal = yield select((state) =>
    state.getIn(['components', 'search', 'showMobileSearchModal']),
  );
  return (
    ordersCount === 0 &&
    partnerId == null &&
    landings.count() === 0 &&
    !openLoginModal &&
    !mobileSearchModal
  );
}

export function* getDiscountSaga({ payload: { email, source } }) {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.subscriptions.subscribe, {
    email,
    type: SUBSCRIPTION_TYPES.FIRST_SERVICE_DISCOUNT,
  });
  yield put(requestFinished());
  if (response.err) {
    const { status } = response.err.response;
    if (status === 422) {
      const { formErrors } = response.data;
      yield put(invalidForm('current', formErrors));
    } else {
      yield put(displayErrors(response));
    }
  } else {
    const {
      data: {
        coupon: { text },
      },
    } = response;
    /*
      The footer has a form that triggers this saga, but the FirstServiceDiscount modal itself if not visible.
      The footer saga is listening for successResponse() in order to trigger the modal to open which will then show the success version
      of this modal. We cannot use discountSubmitted() to trigger the modal, because discountSubmitted() changes the modal's Redux
      state, and if you  open the modal after discountSubmitted() runs the Redux state is reset to dfault values -GH Oct 21, 2020
    */
    yield put(successResponse());
    yield put(discountSubmitted(text));

    const src = source === "footer" ? LEAD_GEN_SOURCE_TYPES.footer : LEAD_GEN_SOURCE_TYPES.firstServiceDiscount;

    yield call(routes.tracking.leadGeneration, { email, source_name: src.name, source_type: src.type});
    /* Set cookie */
    setLeadGeneratedCookie({email});
  }
}

export function* showModalWithDelay({ payload: { ms } }) {
  if (!process.browser) return;
  yield delay(ms);
  const modalVisible = yield call(isModalVisible);
  if (modalVisible) {
    const currentUrl = yield select((state) =>
      state.getIn(['beforeRouteTransition', 'location', 'pathname']),
    );
    if (includes(MODAL_WITH_DELAY_WHITE_LISTED_URLS, currentUrl)) {
      yield put(loadPlanDetails());
    }
  }
}

export function* loadPlanDetailsSaga() {
  const plan = yield select((state) => state.getIn(['modals', 'firstServiceDiscount', 'plan']));
  const routes = yield call(selectRoutes);

  if (!plan) {
    const response = yield call(routes.plans.subscriptions.show, { id: UPSELL_PLAN_ID });
    if (response.err) {
      yield put(toggleModal(MODAL_NAME, false));
      logger.log(response.err);
    } else {
      const planDetails = response.data.plan;
      yield put(planDetailsLoaded(planDetails));
      yield put(toggleModal(MODAL_NAME, true));
    }
  } else {
    yield put(planDetailsLoaded(plan));
  }
}

export function* showModal() {
  const currentUrl = yield select((state) =>
    state.getIn(['beforeRouteTransition', 'location', 'pathname']),
  );
  const urlIncluded = filter(MODAL_WHITE_LISTED_URLS, (item) => currentUrl.indexOf(item) !== -1);
  const modalVisible = yield call(isModalVisible);
  if (modalVisible && urlIncluded.length === 0) {
    yield put(loadPlanDetails());
  }
}

function* locationChangedFlow() {
  const { pathname } = yield select(locationSelector);

  if (HIDE_MODAL_URLS.includes(pathname)) {
    yield put(toggleModal(MODAL_NAME, false));
  }
}

export function* componentFlow() {
  yield takeLatest(GET_DISCOUNT, getDiscountSaga);
  yield takeLatest(SHOW_FIRST_SERVICE_DISCOUNT_MODAL, showModal);
  yield takeLatest(SHOW_FIRST_SERVICE_DISCOUNT_DELAYED_MODAL, showModalWithDelay);
  yield takeLatest(LOCATION_CHANGE, locationChangedFlow);
  yield takeLatest(LOAD_PLAN_DETAILS, loadPlanDetailsSaga);
}

export default [componentFlow];
