import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import cn from 'classnames';
import debounce from 'lodash/debounce';
// Hooks
import { useFormik } from 'formik';
import { usePasscodeAuth } from './LoginPasscode.hooks';
// Utils
import { formatInitialsForAvatar } from './LoginPasscode.utils';
// Constants
import { AUTO_SUBMIT_DEBOUNCE_TIMEOUT_MS, NUM_PASSCODE_DIGITS } from '../constants';
// Components
import { LoginPasscodeBoxes } from './LoginPasscode.Boxes';
import Avatar from 'src/components/HTKit/Elements/Avatar';
// Styles
import styles from '../styles.scss';

const INSTRUCTIONS = [
  `Enter the ${NUM_PASSCODE_DIGITS}-digit code we just sent`,
  'you to securely use your saved',
  'information.',
];

const validationSchema = Yup.object().shape({
  passcode: Yup.string()
    .matches(
      new RegExp(`^[0-9]{${NUM_PASSCODE_DIGITS}}$`),
      `Passcode must be ${NUM_PASSCODE_DIGITS} digits`,
    )
    .required('Passcode is required'),
});

const PassCodeSpacer = () => <div className={styles.passcodeSpacer} />;

/**
 * Handles passcode input and verification for passwordless login.
 * Reusable across login pages, modals, and account verification flows.
 */
export const LoginPasscodeForm = ({ onResendSuccess, className, remainingTime, canResend }) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef(null);

  const {
    verifyPasscode,
    resendPasscode,
    email,
    phoneLastFour,
    userInitials,
    loginPasscodeError,
  } = usePasscodeAuth({
    onResendSuccess,
    onResendError: resetPasscodeAndFocusInput,
  });

  const formik = useFormik({
    initialValues: { passcode: '' },
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      await verifyPasscode(values.passcode);
    },
  });

  const debouncedSubmit = useCallback(
    debounce(() => formik.submitForm(), AUTO_SUBMIT_DEBOUNCE_TIMEOUT_MS),
    [formik.submitForm],
  );

  const handlePasscodeChange = (event) => {
    formik.setErrors({});
    const enteredPasscode = event.target.value.replace(/\D/g, '');
    formik.setFieldValue('passcode', enteredPasscode);
    if (enteredPasscode.length === NUM_PASSCODE_DIGITS) {
      debouncedSubmit();
    }
  };

  const focusInputField = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const resetPasscodeAndFocusInput = useCallback(() => {
    formik.setFieldValue('passcode', '');
    setIsInputFocused(true);
    focusInputField();
  }, [formik.setFieldValue, focusInputField]);

  const handleResendClick = () => {
    formik.resetForm();
    resendPasscode();
    resetPasscodeAndFocusInput();
  };

  useEffect(() => {
    focusInputField();
  }, [focusInputField]);

  useEffect(() => {
    if (loginPasscodeError) {
      formik.setFieldError('passcode', loginPasscodeError);
      resetPasscodeAndFocusInput();
    }
  }, [loginPasscodeError, formik.setFieldError, resetPasscodeAndFocusInput]);

  return (
    <div className={className}>
      <PassCodeSpacer />

      <div className="text-align-center">
        <Avatar
          name={formatInitialsForAvatar(userInitials)}
          className={cn('marginBottom-small1', styles.avatar)}
        />
        <p className="p1 n900">{email}</p>
        <p className="p1 n900">*** *** {phoneLastFour}</p>
      </div>

      <PassCodeSpacer />

      <div className="positionRelative">
        <div className={styles.passcodeBoxesContainer} onClick={focusInputField}>
          <input
            ref={inputRef}
            type="text"
            maxLength={NUM_PASSCODE_DIGITS}
            value={formik.values.passcode}
            onChange={handlePasscodeChange}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            className={styles.passcodeInput}
            autoComplete="one-time-code"
            autoFocus
          />
          <LoginPasscodeBoxes
            passcode={formik.values.passcode}
            isInputFocused={isInputFocused}
            hasError={
              !!formik.errors.passcode && formik.values.passcode.length === NUM_PASSCODE_DIGITS
            }
            numBoxes={NUM_PASSCODE_DIGITS}
          />
        </div>

        {formik.errors.passcode && (
          <div className={styles.errorMessage}>{formik.errors.passcode}</div>
        )}
      </div>

      <PassCodeSpacer />

      <p className="p1 n900 text-align-center">
        {INSTRUCTIONS.map((instruction, index) => (
          <span key={index}>
            {instruction}
            <br />
          </span>
        ))}
      </p>

      <PassCodeSpacer />

      <div className="text-align-center">
        <p className="p1 n900">
          Don't see a code?&nbsp;
          {!canResend && <span>Give it {remainingTime / 1000}s</span>}
        </p>
        <br />
        <button className="plainButton" onClick={handleResendClick} disabled={!canResend}>
          <span
            className={cn('p1 underline', {
              n300: !canResend,
              teal: canResend,
            })}
          >
            Send Again
          </span>
        </button>
      </div>
    </div>
  );
};

LoginPasscodeForm.propTypes = {
  onResendSuccess: PropTypes.func.isRequired,
  className: PropTypes.string,
  remainingTime: PropTypes.number,
  canResend: PropTypes.bool,
};
