import { call, put, takeEvery } from 'redux-saga/effects';
import { requestStarted, requestFinished } from 'src/utils/request';
import { LOAD_PAGE } from 'src/constants/common';
import {
  pageLoaded,
  pageLoadingError,
  pageNotice,
  clearPageNotices,
} from 'src/actions/common';
import { selectRoutes } from 'src/apiRoutes';
import { defaultNotice, errorNotice } from 'src/utils/notice';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { CONFIRM_APPOINTMENT } from './constants';
import { appointmentConfirmed } from './actions';

const ACCOUNT_ORDERS_PAGE_NAME = 'accountOrders';

export function* pageSaga() {
  const routes = yield call(selectRoutes);
  const requestResult = yield call(routes.orders.list);
  if (requestResult.err) {
    yield put(pageLoadingError(ACCOUNT_ORDERS_PAGE_NAME, requestResult));
    return;
  }
  const { orders } = requestResult.data;
  yield loadUserProfileSaga(ACCOUNT_ORDERS_PAGE_NAME, true);
  yield put(pageLoaded(ACCOUNT_ORDERS_PAGE_NAME, { orders }));
}

export function* confirmAppointmentSaga({ payload }) {
  const routes = yield call(selectRoutes);
  const { orderId } = payload;
  yield put(clearPageNotices(ACCOUNT_ORDERS_PAGE_NAME));
  yield put(requestStarted());
  const requestResult = yield call(routes.orders.confirmAppointment, { orderId });
  yield put(requestFinished());

  if (requestResult.err) {
    const { errors } = requestResult.data;
    yield put(pageNotice(ACCOUNT_ORDERS_PAGE_NAME, errorNotice(errors)));
  } else {
    const { order } = requestResult.data;
    yield put(
      pageNotice(
        ACCOUNT_ORDERS_PAGE_NAME,
        defaultNotice('Appointment has been successfully confirmed.'),
      ),
    );
    yield put(appointmentConfirmed(order));
  }
}

export function* accountOrdersFlow() {
  // eslint-disable-line no-empty-function
  yield takeEvery(
    (action) => action.type === LOAD_PAGE && action.page === ACCOUNT_ORDERS_PAGE_NAME,
    pageSaga,
  );
  yield takeEvery(CONFIRM_APPOINTMENT, confirmAppointmentSaga);
}

export default [accountOrdersFlow];
