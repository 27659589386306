import Cookies from 'js-cookie';

const CUSTOMER_ZIP_DATA_ID = '_customer_zip_data_id';

/**
 * @param {{zipCode?: string, isBookingAllowed?: boolean}} zipData
 */
export const setZipDataCookie = (zipData = {}) => {
  Cookies.set(CUSTOMER_ZIP_DATA_ID, JSON.stringify(zipData));
};

export const getZipDataCookie = () => {
  return Cookies.getJSON(CUSTOMER_ZIP_DATA_ID);
};
