import React from 'react';
import PropTypes from 'prop-types';
import { Badge, BADGE_TYPES } from 'src/components/Elements/Badge';
import styles from '../styles.scss';

const CategoryBadge = ({ text, badgeType }) => {
  if (!text) return null;
  return (
    <Badge type={badgeType} size="small" classes={styles.categoryBadge}>
      {text}
    </Badge>
  );
};

CategoryBadge.propTypes = {
  text: PropTypes.string.isRequired,
  badgeType: PropTypes.string,
};

CategoryBadge.defaultProps = { badgeType: BADGE_TYPES.COMPLETED };

export default React.memo(CategoryBadge);
