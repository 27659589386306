import { fromJS } from 'immutable';
import { PAGE_LOADED } from '../../constants/common';

export default function skuCategoriesReducer(state = fromJS({}), action) {
  switch (action.type) {
    case PAGE_LOADED:
      if (action.page === 'skuCategory') {
        return state.set(action.skuCategory.seoName, fromJS(action.skuCategory));
      }
      return state;
    default:
      return state;
  }
}
