import { fromJS } from 'immutable';
import { PAGE_LOADED } from 'src/constants/common';
import { PAGE_NAME, MEMBERSHIP_ADDITION_TOAST_DISPLAYED } from './constants';

const initialState = fromJS({
  publicPage: false,
  formattedRescheduleUrl: '',
  membershipAdditionToastDisplayed: false,
});

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case PAGE_LOADED: {
      if (action.page === PAGE_NAME) {
        const { publicPage, formattedRescheduleUrl } = action;
        return state.merge({ publicPage, formattedRescheduleUrl });
      }
      return state;
    }
    case MEMBERSHIP_ADDITION_TOAST_DISPLAYED: {
      const { payload } = action;
      return state.set('membershipAdditionToastDisplayed', payload);
    }
    default:
      return state;
  }
}

export default pageReducer;
