import { call, put, takeEvery } from 'redux-saga/effects'; // eslint-disable-line
import { LOAD_PAGE } from 'src/constants/common';
import { selectRoutes } from 'src/apiRoutes';
import { requestStarted, requestFinished, displayErrorsWithSnack } from 'src/utils/request';
import { pageLoaded, pageLoadingError } from 'src/actions/common';
import { zipValidated, zipNotValidated, hsnSegmentClickTracking } from './actions';
import { PAGE_NAME, SUBMIT_FORM } from './constants';

export function* pageSaga() {
  yield put(pageLoaded(PAGE_NAME, {}));
}

export function* submitFormSaga({ payload: zip }) {
  yield put(hsnSegmentClickTracking(null, zip));
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.locations.validateZip, { zip });
  yield put(requestFinished());
  if (response.err) {
    yield put(pageLoadingError(PAGE_NAME, response));
    yield put(displayErrorsWithSnack(response));
  } else {
    const { supported } = response.data;
    if (supported) yield put(zipValidated());
    if (!supported) yield put(zipNotValidated());
    yield put(hsnSegmentClickTracking(supported, zip));
  }
}

export function* zipCodeCheckFlow() {
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
  yield takeEvery(SUBMIT_FORM, submitFormSaga);
}

export default [zipCodeCheckFlow];
