export const STRIPE_API_KEY = process.env.NODE_ENV === 'production' ? 'pk_live_QhZjfNdnYvKlPDFiReRbVtbz' : 'pk_test_BHgNxki6du2lfRRa9ruoqKNd';

export const DEFAULT_CARD_FIELDS = { cvc: 'cvc', number: 'number', expMonth: 'expMonth', expYear: 'expYear' };
export const PAYMENT_TYPES = { MONTHLY: 'monthly', YEARLY: 'yearly' };

function setupStripe() {
  Stripe.setPublishableKey(STRIPE_API_KEY); // eslint-disable-line no-undef
}

export function getCardToken({ number, cvc, month, year, zip }) {
  return new Promise((resolve, reject) => {
    setupStripe();
    Stripe.card.createToken({ number, cvc, exp_month: month, exp_year: year, address_zip: zip }, (status, response) => { // eslint-disable-line no-undef
      const method = status === 200 ? resolve : reject;
      method(response, status);
    });
  });
}

export function formatErrors({ error }, fields = null) {
  const useFields = fields || DEFAULT_CARD_FIELDS;
  return new Promise((resolve, reject) => {
    let fieldName = null;
    switch (error.param) {
      case 'cvc':
      case 'card[cvc]':
        fieldName = useFields.cvc;
        break;
      case 'number':
      case 'card[number]':
        fieldName = useFields.number;
        break;
      case 'exp_month':
      case 'card[exp_month]':
        fieldName = useFields.expMonth;
        break;
      case 'exp_year':
      case 'card[exp_year]':
        fieldName = useFields.expYear;
        break;
      default:
        reject();
        return;
    }
    resolve({ [fieldName]: error.message });
  });
}

export function shouldWaiveFee() {
  const safetyFeeValue = 0;
  // 0 - apply fee; 1 - no fee
  return safetyFeeValue !== '0';
}
