// Libraries
import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
// Utils && Data
import { stopImmediatePropagation } from 'src/utils/event';
import useOutsideClick from 'src/hooks/useOutsideClick';
// Components
import Icon from 'HTKit/Icon';
// Styles
import styles from './styles.scss';

const DropDown = ({ defaultOptionText, options, onSelect }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuPosition, setmenuPosition] = useState(null);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const selectedItemRef = useRef(null);
  const menu = selectedItemRef.current;
  const iconStyles = cn(styles.menuIcon, { [styles.isActive]: menuOpen });
  const selectedStyles = cn('h4', styles.selected, {
    [styles.menuOpen]: menuOpen,
  });
  useEffect(() => {
    if (menu)
      setmenuPosition({
        width: menu.getBoundingClientRect().width,
        height: menu.getBoundingClientRect().height,
      });
  }, [menuOpen]);

  const closeDropdown = () => setMenuOpen(false);
  useOutsideClick(selectedItemRef, closeDropdown);

  return (
    <section className={styles.container}>
      <div className={styles.selectMenu}>
        <div className={selectedStyles} onClick={toggleMenu} ref={selectedItemRef}>
          <span>{defaultOptionText}</span>
          <Icon name="chevron" className={iconStyles} />
        </div>

        {menuOpen && menuPosition && (
          <div
            className={styles.optionMenu}
            style={{ top: menuPosition.height, width: menuPosition.width }}
          >
            {options.map((opt, index) => {
              return (
                <div
                  className={cn('h5', styles.option)}
                  key={index}
                  onClick={() => stopImmediatePropagation(onSelect(opt))}
                >
                  <div className={styles.optionIconContainer}>
                    <Icon name={opt.icon} className={styles.optionIcon} />
                  </div>
                  {opt.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

DropDown.propTypes = {
  defaultOptionText: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

DropDown.defaultProps = {
  defaultOptionText: 'I need help with...',
  onSelect: () => {},
  options: [],
};

export default React.memo(DropDown);
