import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
// Hooks
import useLoadHomePage from 'src/containers/HomePage/hooks/useLoadHomePage';
// Constants
import { PAGE_NAME } from 'src/containers/HomePage/constants';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
// Components && Styles
import AmazonTracking from 'src/utils/tracking/amazon/AmazonTrackingPixels';
import IframeView from 'src/components/Elements/IframeView';
// Local Iframe Config
import { getIframeSource } from './constants';
import { messagePageActions } from './messagePageActions';

/**
 * Wrapper page for our Webflow page. We listen for events passed from
 * child to parent/this page. And act on those evented messages.
 *
 * @param url
 * @returns {string}
 */
const HomePageIframe = () => {
  /*
    The decision whether to show the Iframe or the default is made in app.js.
    Here in the component we need to grab the path for the URL source from the split config
  */
  const { splitConfig } = useSplitIOTreatment(splitioConstants.SPLITIONAME_HOMEPAGE_IFRAME);

  const [source, setSource] = useState(null);

  const iframeSourceString = getIframeSource(source);

  useEffect(() => {
    setSource(get(splitConfig, 'source', null));
  }, [splitConfig]);

  useLoadHomePage({ getNumServices: false });

  if (!source) return null;
  return (
    <>
      <AmazonTracking pageName="home_page" />
      <IframeView
        callerPageName={PAGE_NAME}
        src={iframeSourceString}
        additionalActions={messagePageActions}
      />
    </>
  );
};

HomePageIframe.propTypes = {
  isTopNavOpen: PropTypes.bool,
};

export default HomePageIframe;
