import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPlanDetails } from 'src/containers/SkuPage/actions';

const useFetchPlanDetails = ({ upsellPlanId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPlanDetails({ planId: upsellPlanId }));
  }, []);
  return null;
};

export default useFetchPlanDetails;
