import { eventChannel } from 'redux-saga';
import { delay ,
  put,
  call,
  take,
  takeEvery,
  select,
  spawn
} from 'redux-saga/effects';

import Bugsnag from 'src/utils/Bugsnag';
import { USER_LOADED } from 'src/containers/AppBase/constants';
import {
  CREATE_WS_CONNECTION,
  CLOSE_WS_CONNECTION,
  TECH_TRACKING_URL,
  CONNECTED,
  NOT_CONNECTED,
  RECONNECTING,
  RECONNECT_FAILED
} from './constants';
import {
  updateLocation,
  updateStatus
} from './actions';

const RECONNECT_ATTEMPTS = 5;

let techTrackingSocket = null;

let isReconnecting = false;

let reconnectTries = RECONNECT_ATTEMPTS;

function createSagaEventChannel(socket) {
  return eventChannel((emit) => {
    /* eslint-disable no-param-reassign */
    socket.onopen = () => {
      techTrackingSocket = socket;
      const msg = { method: 'initial', table: 'locations', payload: {} };
      socket.send(JSON.stringify(msg));
      emit({ status: CONNECTED, opened: true, reconnectStatus: '' });
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      emit({ data });
    };

    socket.onerror = () => {
      // onclose is always called after onerror
    };

    socket.onclose = (event) => {
      techTrackingSocket = null;
      emit({ status: NOT_CONNECTED, wasClean: event.wasClean });
    };

    // the subscriber must return an unsubscribe function
    // this will be invoked when the saga calls `channel.close` method
    const unsubscribe = () => {};

    return unsubscribe;
    /* eslint-enable */
  });
}


function* handleSocketEvents(socket) {
  const socketSagaChannel = yield call(createSagaEventChannel, socket);

  while (true) { // eslint-disable-line
    try {
      const channelEmission = yield take(socketSagaChannel);
      if ('data' in channelEmission) {
        const { data } = channelEmission;
        yield put(updateLocation({ data }));
      }

      const connectedStateOpts = {};

      if ('opened' in channelEmission) {
        const { opened } = channelEmission;
        connectedStateOpts.opened = opened;
      }

      if ('reconnectStatus' in channelEmission) {
        const { reconnectStatus } = channelEmission;
        connectedStateOpts.reconnectStatus = reconnectStatus;
      }

      if ('status' in channelEmission) {
        const { status, wasClean } = channelEmission;
        connectedStateOpts.status = status;
        if (status !== CONNECTED) {
          socketSagaChannel.close();
          if (!wasClean) {
            yield spawn(reconnect);
          }
        }
      }

      if (Object.keys(connectedStateOpts).length !== 0) {
        yield put(updateStatus(connectedStateOpts));
      }
    } catch (err) {
      socketSagaChannel.close();
      techTrackingSocket = null;
      yield put(updateStatus({ status: NOT_CONNECTED }));
      Bugsnag.notifyException(err);
    }
  }
}

function* reconnect() {
  if (isReconnecting) return;
  isReconnecting = true;
  yield put(updateStatus({ reconnectStatus: RECONNECTING }));

  while (reconnectTries > 0) {
    yield delay(5000);
    if (!techTrackingSocket) {
      yield spawn(createWSConnection);
      reconnectTries -= 1;
    } else {
      reconnectTries = 0;
    }
  }

  yield delay(5000);
  if (!techTrackingSocket) {
    yield put(updateStatus({ reconnectStatus: RECONNECT_FAILED }));
  }
  isReconnecting = false;
  reconnectTries = RECONNECT_ATTEMPTS;
}

function* createWSConnection() {
  if (!techTrackingSocket) {
    let authToken = yield select((state) => state.getIn(['user', 'authToken']));
    if (!authToken) {
      const { user } = yield take(USER_LOADED);
      authToken = user.authToken;  // eslint-disable-line
    }
    const socket = new WebSocket(`${TECH_TRACKING_URL}?auth=${authToken}`);
    yield call(handleSocketEvents, socket);
  }
}

// eslint-disable-next-line require-yield
function* closeConnection() {
  if (techTrackingSocket) {
    techTrackingSocket.close();
  }
}

function* socketFlow() {
  yield takeEvery(CREATE_WS_CONNECTION, createWSConnection);
  yield takeEvery(CLOSE_WS_CONNECTION, closeConnection);
}

export default [
  socketFlow,
];
