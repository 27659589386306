import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Layout and Components
import IframeView from 'src/components/Elements/IframeView';
import withLayout from 'src/hoc/withLayout';
import { DEFAULT_LAYOUT } from 'src/constants/common';

// Utils and Actions
import { categoryLinkClick } from './actions';
import { messagePageActions } from './messagePageActions';

// Page Assets
import { CALLER_PAGE, getIframeSource } from './constants';

const CategoryPage = (props) => {
  const { src } = props;
  const iframeSourceString = getIframeSource(src);
  const additionalActions = messagePageActions(props);

  return (
    <IframeView
      callerPageName={CALLER_PAGE}
      src={iframeSourceString}
      additionalActions={additionalActions}
    />
  );
};

CategoryPage.propTypes = { src: PropTypes.string.isRequired };

const mapDispatchToProps = (dispatch) => {
  return {
    categoryLinkClick: () => dispatch(categoryLinkClick()),
  };
};

export default compose(connect(null, mapDispatchToProps), withLayout(DEFAULT_LAYOUT))(CategoryPage);
