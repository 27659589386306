import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Grid from 'src/components/HTKit/Grid/Grid';
import PromoBlocks from 'src/components/Searchbar/Results/PromoBlocks';
import EmptyState from 'src/components/EmptyState';
import CategoryResults from 'src/components/Searchbar/Results/CategoryResults';
import ServiceResults from 'src/components/Searchbar/Results/ServiceResults';
import ProductResults from 'src/components/Searchbar/Results/ProductResults';
import { DEFAULT_TEXTS } from 'src/components/Searchbar/constants';
import QuickLinks from 'src/components/Searchbar/Results/QuickLinks';
import styles from './styles.scss';

const Results = ({ serviceResults, productResults, scoredResults, searchTerm, ...props }) => {
  const rawResults = searchTerm ? scoredResults : [];

  const results =
    Array.isArray(rawResults) && rawResults.length ? (
      <>
        <ServiceResults serviceResults={serviceResults} searchTerm={searchTerm} {...props} />
        <ProductResults productResults={productResults} searchTerm={searchTerm} {...props} />
      </>
    ) : (
      searchTerm && (
        <EmptyState
          className={cn('paddingY-medium', styles.noResults)}
          iconName="search"
          title={DEFAULT_TEXTS.EMPTY_STATE.title}
          additionalTitle={searchTerm}
          text={DEFAULT_TEXTS.EMPTY_STATE.text}
        />
      )
    );
  return (
    <Grid.FullWidth classes={styles.gridContainer}>
      <Grid.Row classes={styles.autoComplete}>
        <Grid.Column
          sm={4}
          mdOffset={1}
          md={6}
          lg={12}
          classes={cn('marginBottom-medium', styles.column)}
        >
          {results}
          {!rawResults.length && <QuickLinks searchTerm={searchTerm} {...props} />}
        </Grid.Column>
        <Grid.Column sm={4} mdOffset={1} md={6} lg={12} classes={styles.blockColumn}>
          <PromoBlocks searchTerm={searchTerm} {...props} />
        </Grid.Column>
        {searchTerm && (
          <Grid.Column sm={4} mdOffset={1} md={6} lg={12} classes={styles.categoryWrapper}>
            {!scoredResults.length && (
              <CategoryResults scoredResults={scoredResults} searchTerm={searchTerm} {...props} />
            )}
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid.FullWidth>
  );
};

Results.propTypes = {
  scoredResults: PropTypes.array,
  productResults: PropTypes.array,
  serviceResults: PropTypes.array,
  searchTerm: PropTypes.string,
};

export default Results;
