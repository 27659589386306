import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../styles.scss';

const ServiceImage = ({ image, imageClasses, quantity, showQty }) => (
  <>
    {image && (
      <div className={styles.imageWrapper}>
        <div className={styles.imageContainer}>
          <img className={cn(imageClasses, styles.image)} src={image} />
          {showQty && <div className={styles.quantity}>{quantity}</div>}
        </div>
      </div>
    )}
  </>
);

ServiceImage.defaultProps = {
  quantity: 1,
  showQty: false,
};

ServiceImage.propTypes = {
  image: PropTypes.string,
  imageClasses: PropTypes.string,
  quantity: PropTypes.number,
  showQty: PropTypes.bool,
};

export default ServiceImage;
