import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useSelector } from 'react-redux';
// Utils
import { getUpsellData } from 'src/components/Upsell/NavSubCategoryUpsell/utils';
import { buildLocationsLink } from 'src/utils/locations';
import { skuCategoriesAndSkusJSSelector } from 'src/selectors/routesSchema';

// Constants
import { SKU_TYPE } from 'src/components/Header/TopNavHeader/TopNavItemsV2/constants';
// Components & Styles
import { Grid } from 'src/components/HTKit/Grid';
import { Badge, BADGE_TYPES } from 'src/components/Elements/Badge';
import NavSubCategoryUpsell from 'src/components/Upsell/NavSubCategoryUpsell';
import styles from './styles.scss';

const SkusToDisplay = ({ title, skus, category, onSecondaryMenuItemClick, skuLinkPrefix }) => {
  const serviceSkus = useSelector(skuCategoriesAndSkusJSSelector);

  /* Some skus/services can sit in more than one cat. We use alias from TopNavItems2/constants */
  const findAliasedSkuByCat = ({id, aliasCatId}) => {
    if (aliasCatId && id) {
      const aliasCategory = serviceSkus.find((cat) => cat.id === aliasCatId);
      if (aliasCategory) {
        const aliasSku = aliasCategory.skus.find((sku) => sku.id === id);
        if (aliasSku) {
          return aliasSku;
        }
      }
    }

    return null;
  }

  return (
    <>
      <h5 className="darkBlue700 text-weight-med paddingBottom-small2">{title}</h5>
      {skus.map((sku) => {
        const skuToDisplay = category.skus.find((s) => s.id === sku.id) || findAliasedSkuByCat({id: sku.id, aliasCatId: sku.aliasCatId});
        if (!skuToDisplay) return null;

        const { link = '' } = skuToDisplay;
        const builtLink = skuLinkPrefix
          ? buildLocationsLink({ prefix: skuLinkPrefix, link })
          : link;
        return (
          <div className="flex alignItemsFlexStart paddingBottom-small1" key={skuToDisplay.id}>
            {skuToDisplay.image && (
              <img
                src={skuToDisplay.image}
                className={cn('n700 marginRight-small', styles.skuIcon)}
                alt="icon"
              />
            )}
            <Link
              onClick={onSecondaryMenuItemClick}
              to={builtLink}
              className="p1 n700 paddingRight-tiny1 overrideLinkStyles"
            >
              {sku.name}
            </Link>
            {sku.popular && (
              <Badge size="small" type={BADGE_TYPES.CONFIRMED}>
                Popular
              </Badge>
            )}
          </div>
        );
      })}
    </>
  );
};

SkusToDisplay.propTypes = {
  title: PropTypes.string,
  category: PropTypes.object,
  skus: PropTypes.array,
  skuToDisplay: PropTypes.object,
  onSecondaryMenuItemClick: PropTypes.func,
  skuLinkPrefix: PropTypes.string,
};

const SecondaryMenu = ({
  category,
  onSecondaryMenuItemClick,
  selectedSubCategory,
  skuLinkPrefix,
}) => {
  const [installationSkus, setInstallationSkus] = useState([]);
  const [supportSkus, setSupportSkus] = useState([]);

  useEffect(() => {
    const installationSkusList = category.subCategorySkus.filter(
      (s) => s.skuType === SKU_TYPE.INSTALLATION,
    );
    const supportSkusList = category.subCategorySkus.filter((s) => s.skuType === SKU_TYPE.SUPPORT);
    setSupportSkus(supportSkusList);
    setInstallationSkus(installationSkusList);
  }, [category]);

  const upsellData = getUpsellData(selectedSubCategory);

  return (
    <Grid.Column lg={12} classes={styles.secondaryMenuContainer}>
      <Grid.Row>
        <Grid.Column lg={8}>
          <h3 className="paddingBottom-medium darkBlue700 text-weight-med">{category.label}</h3>
          <Grid.Row>
            <Grid.Column lg={6}>
              <SkusToDisplay
                skus={installationSkus}
                onSecondaryMenuItemClick={onSecondaryMenuItemClick}
                category={category}
                title="Installation &amp; Setup"
                skuLinkPrefix={skuLinkPrefix}
              />
            </Grid.Column>
            <Grid.Column lg={6}>
              {' '}
              <SkusToDisplay
                skus={supportSkus}
                onSecondaryMenuItemClick={onSecondaryMenuItemClick}
                category={category}
                title="Support"
                skuLinkPrefix={skuLinkPrefix}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column lg={4}>
          <NavSubCategoryUpsell.Desktop {...upsellData} />
        </Grid.Column>
      </Grid.Row>
    </Grid.Column>
  );
};

SecondaryMenu.propTypes = {
  category: PropTypes.object,
  onSecondaryMenuItemClick: PropTypes.func,
  selectedSubCategory: PropTypes.object,
  skuLinkPrefix: PropTypes.string,
};

export default SecondaryMenu;
