import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button, { THEMES } from 'HTKit/Forms/Button';
import HT_INFO from 'src/constants/hellotech';
import withToggleSiteNav from 'src/hoc/withToggleSiteNav';
import VideoModal from 'src/components/VideoModal';
import SeeHowItWorksModal from 'src/components/HTKit/Modals/SeeHowItWorks';
import techImage from 'src/images/tech-services.jpg';
import { makeParagraph } from '../utils';
import styles from './styles.scss';

const LocationVideo = (props) => {
  const [videoVisible, setVideoVisible] = useState(false);

  const toggleVideo = (visible) => {
    return () => {
      setVideoVisible({ videoVisible: visible });
    };
  };

  const toggleSiteNav = (e) => {
    if (e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
    props.toggleSiteNav();
  };

  const { location, title } = props;
  const containerStyles = classNames(styles.locationVideoInlined, styles.locationVideo);
  return (
    <section className="l-content-section">
      {title && (
        <div className="l-section-header">
          <h1 className="l-section-header">{title}</h1>
        </div>
      )}

      <div className={styles.locationVideo}>
        <SeeHowItWorksModal visible={videoVisible} onCancel={toggleVideo(false)} />
        <div className={containerStyles}>
          <VideoModal previewImage={techImage} videoUrl={HT_INFO.video.youtubePromo} />
        </div>
        {location.get('locationDescription').map((p, i) => (
          <p key={i}>{makeParagraph(p)}</p>
        ))}
        <div className={styles.getStartedWrapper}>
          <Button hugeV2 theme={THEMES.V2PRIMARY} onClick={toggleSiteNav}>
            Let&lsquo;s get Started
          </Button>
        </div>
      </div>
    </section>
  );
};

LocationVideo.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string,
  toggleSiteNav: PropTypes.func.isRequired,
};

export default withToggleSiteNav()(LocationVideo);
