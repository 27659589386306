import { SERVICE_TABS } from 'src/containers/DirectBooking/TechSkusPage/Parts/ServiceTabs/constants';
import { setDirectBookingTechData } from 'src/utils/cookies/directBookingCookie';

export const formatTabsData = (categories) => {
  const seoNames = Object.values(SERVICE_TABS);
  return seoNames
    .map((name) => {
      const match = categories.find((c) => c.seoName === name);
      return match ? { label: match.name, skus: match.skus } : null;
    })
    .filter(Boolean);
};

export const fillDirectBookingCookie = ({ techId, techPhoneNumber, techSlug }) => {
  return setDirectBookingTechData({ data: { techId, phoneNumber: techPhoneNumber, techSlug } });
};
