import { SUBMIT_FORM, CUSTOMER_VALIDATED, CUSTOMER_BAD_REQUEST, SHOW_OFFER } from './constants';

export function submitForm(info) {
  return {
    type: SUBMIT_FORM,
    payload: info,
  };
}

export function customerValidated(status) {
  return {
    type: CUSTOMER_VALIDATED,
    payload: status,
  };
}

export function customerBadRequest(status) {
  return {
    type: CUSTOMER_BAD_REQUEST,
    payload: status,
  };
}

export function showOffer(status) {
  return {
    type: SHOW_OFFER,
    payload: status,
  };
}
