export const IMAGE_ALLOWED_FILES = ['png', 'jpg', 'jpeg', 'gif', 'heic'];
export const RAW_ALLOWED_FILES = ['pdf', 'xls', 'xlsx', 'csv', 'txt'];
export const ALLOWED_FILES = [...IMAGE_ALLOWED_FILES, ...RAW_ALLOWED_FILES];
export const RESOURCE_TYPE_IMAGE = 'image';
export const RESOURCE_TYPE_RAW = 'raw';

export const FILE_UPLOAD_VALIDATIONS = {
  MAX_FILE_SIZE: 5 * 1024 * 1024, // 5MB
  MAX_FILE_SIZE_DISPLAY_STRING: '5MB',
  ALLOWED_FILE_EXTENSIONS: (addl = []) => [...ALLOWED_FILES, ...addl],
  ALLOWED_FILE_ACCEPT: (addl = []) => [...ALLOWED_FILES.map(file => `.${file}`), ...addl],
  ALLOWED_QA_DROPZONE_ACCEPT: () => IMAGE_ALLOWED_FILES.map(file => `.${file}`),
};

/* DRAG DROP ERROR CODES */
export const DRAG_DROP_ERROR_CODES = new Proxy({
      "file-too-large": (name) => `File ${name} exceeds ${FILE_UPLOAD_VALIDATIONS.MAX_FILE_SIZE_DISPLAY_STRING}`,
      "file-invalid-type": (name) => `File ${name} is not type: ${FILE_UPLOAD_VALIDATIONS.ALLOWED_QA_DROPZONE_ACCEPT().join(', ')}`,
      "default:": (name) => `File ${name} upload failed`,
   }, {
      get(obj, prop) {
        const key = typeof prop === 'string' ? prop.toLowerCase() : prop;
        return key in obj ? obj[key] : obj.default;
      }
     }
   )
