import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Utils
import { getZipDataCookie } from 'src/utils/cookies/zipCodeTestCookie';
import { noop } from 'src/utils/event';
// Context
import { useServicePageContext } from 'src/containers/ServicePage/context/context';
import { setIsZipSuccessFlowActive } from 'src/containers/ServicePage/context/ducks';
// Components
import { PanelV2 } from 'src/components/HTKit/Elements/PanelV2';
import ServiceInfoUpsellV3 from 'src/components/Service/ServiceInfo/ServiceInfoUpsellV3';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import Icon from 'src/components/HTKit/Icon';
import styles from './styles.scss';

const CtaButton = ({ onButtonClick }) => {
  return (
    <>
      <Button
        theme={THEMES.V2PRIMARY}
        type="button"
        onClick={onButtonClick}
        className="showDesktop"
      >
        Book Now
      </Button>
      <Button
        theme={THEMES.V2PRIMARY}
        type="button"
        onClick={onButtonClick}
        smallV2
        className="showMobileTablet"
      >
        Book Now
      </Button>
    </>
  );
};

const ResetZipLink = ({ onClick, className }) => {
  return (
    <p
      className={cn('text-link -no-decoration text-weight-med', styles.differentZipText, className)}
      onClick={onClick}
    >
      Different ZIP Code?
    </p>
  );
};

const ServiceZipCodeEntrySuccess = ({
  className,
  serviceExtra,
  resetZipCodeForm = noop,
  onButtonClick = noop,
  onUpsellToggle = noop,
  onUpsellMore = noop,
}) => {
  /* Context */
  const [servicePageContextState, servicePageContextDispatch] = useServicePageContext();

  /* Data */
  const zipDataCookie = getZipDataCookie() || { zipCode: '' };
  const { isZipSuccessViewActive } = servicePageContextState.zipCodeGate;

  /* Effects */
  // These effects are needed to synchronize the CTA button language and customer flow that is defined in ServicePage.js
  // On component mount set true, on dismount set false
  useEffect(() => {
    if (!isZipSuccessViewActive) servicePageContextDispatch(setIsZipSuccessFlowActive(true));
  }, [isZipSuccessViewActive]);
  useEffect(() => {
    // Have to define this dismount effect separately to avoid endless rerender loop
    return () => servicePageContextDispatch(setIsZipSuccessFlowActive(false));
  }, []);

  return (
    <PanelV2 mediumBorderRadius className={cn(className, styles.zipContent, styles.zipSuccess)}>
      <div className={cn('flex justifyContentSpaceBetween alignItemsCenter', styles.upsellSpacing)}>
        <div className="flex alignItemsCenter">
          <Icon name={cn('v2-green-checkmark', styles.successIcon)} />
          <p className="h5 marginLeft-tiny1">We Serve {zipDataCookie.zipCode}!</p>
        </div>
        <ResetZipLink className="showDesktop" onClick={resetZipCodeForm} />
      </div>

      <ServiceInfoUpsellV3
        serviceExtra={serviceExtra}
        onUpsellToggle={onUpsellToggle}
        onUpsellMore={onUpsellMore}
        className={styles.upsellSpacing}
      />

      <CtaButton onButtonClick={onButtonClick} />
      <ResetZipLink
        className="showMobileTablet marginTop-small text-align-center"
        onClick={resetZipCodeForm}
      />
    </PanelV2>
  );
};

CtaButton.propTypes = { onButtonClick: PropTypes.func };

ResetZipLink.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

ServiceZipCodeEntrySuccess.propTypes = {
  onUpsellToggle: PropTypes.func,
  onUpsellMore: PropTypes.func,
  resetZipCodeForm: PropTypes.func,
  className: PropTypes.string,
  onButtonClick: PropTypes.func,
  serviceExtra: PropTypes.object,
};
export default ServiceZipCodeEntrySuccess;
