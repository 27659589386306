import { fromJS } from 'immutable';
import { COOKIES_LOADED } from 'src/containers/AppBase/constants';
import { SET_CURRENT_LANDING, REMOVE_CURRENT_LANDING } from 'src/containers/LandingPage/constants';

const currentLandingInitialState = fromJS({});

export default function currentLandingReducer(state = currentLandingInitialState, action) {
  let currentLandingCookie = null;
  if (action.partnerLandingCookie) {
    currentLandingCookie = action.partnerLandingCookie;
  }

  switch (action.type) {
    case COOKIES_LOADED:
      if (currentLandingCookie) {
        return fromJS(currentLandingCookie);
      }
      return state;
    case SET_CURRENT_LANDING:
      return fromJS(action.partnerInfo);
    case REMOVE_CURRENT_LANDING:
      return null;
    default:
      return state;
  }
}
