import { splitReducer } from '@splitsoftware/splitio-redux';
import { isImmutable } from 'src/utils/helpers';

/**
 * Splitio SDK Reducer wrapper
 *
 *  This serves as a wrapper to the SDK reducer so we can manage the data coming out.
 *  As of now, their SDK formulates the data incorrectly. This is a stopgap to achieve parity
 *  to what we need. Hopefully they will update their sdk. I let them know.
 *
 * @param state
 * @param action
 * @returns {Promise<U>}
 */
export const splitReducerSanitizer = (state = {}, action = {}) => {
  const normalizedState = isImmutable(state) ? state.toJS() : state;

  return splitReducer(normalizedState, action);
}
