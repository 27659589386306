import React, { memo, useState, cloneElement, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.scss';

const Tabs = ({
  actions,
  selected: selectedTab,
  children,
  className,
  actionsClassName,
  tabsClasses,
  onChange,
}) => {
  const [selected, setSelected] = useState(selectedTab || 0);
  const component = children[selected];

  useEffect(() => {
    if (selectedTab && selectedTab !== selected) {
      setSelected(selectedTab);
    }
  }, [selectedTab, selected]);

  const handleChange = useCallback((item) => {
    setSelected(item);
    if (onChange) onChange(item);
  }, []);

  return (
    <div className={cn(styles.tabs, className)}>
      <div className={cn(styles.actions, actionsClassName)}>
        {actions.map((action, index) => (
          <div
            key={index}
            className={cn('h6', styles.tab, tabsClasses, {
              [styles.selected]: selected === index,
            })}
            onClick={() => handleChange(index)}
          >
            {action}
          </div>
        ))}
      </div>
      <div>{component && cloneElement(component)}</div>
    </div>
  );
};

Tabs.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.arrayOf(PropTypes.element),
  className: PropTypes.string,
  actionsClassName: PropTypes.string,
  tabsClasses: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.number,
};

export default memo(Tabs);
