import { useCallback } from 'react';
import useEventListener from 'src/hooks/useEventListener';

function useOutsideClick(ref, cb, dependencies = []) {
  if (!ref || !cb) return;

  const handler = (event) => {
    if (ref.current && !ref.current.contains(event.target)) cb();
  };

  const memoizedHandler = useCallback((e) => handler(e), dependencies);

  useEventListener({ eventName: 'click', handler: memoizedHandler });
}

export default useOutsideClick;

/*
  useOutsideClick: Do something when a click is detected outside of an element
  ...
  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => console.log('handling my event!', event)
  ...
  ...
  useOutsideClick(wrapperRef, handleClickOutside);
*/
