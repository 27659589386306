import camelCase from 'lodash/camelCase';
import mergeWith from 'lodash/mergeWith';
import isNull from 'lodash/isNull';
import { splitioConstants, useDispatchSplitTreatment } from 'src/components/SplitIO';

/**
 * Simple hook'ish that hands back the treatment and config (if any).
 *
 * Note: You can put the default config into split B config default. We probably want to keep it
 * in the constant because if split fails to load, we have the default, otherwise things could be
 * look bad (no config info).
 *
 * usage: const { splitConfig, splitTreatment } = useSplitIOTreatment(constants.HOME_PAGE);
 *
 * Note: Added specific readable exports by split name. Good for finding/removing and using multiple on same page
 *  {
 *    splitTreatment,
 *    splitConfig,
 *    homePageSplitTreatment,
 *    homePageSplitConfig
 *  }
 *
 * @param splitName
 * @returns {{splitConfig: *, splitTreatment: *}}
 */
const DEFAULTCONFIG = { splitTreatment: splitioConstants.control, splitConfig: {} };
/* Because we won't be using json to represent config values in split admin, null defaults to string w/ key/value pairs
   This is needed because we use "null" or null to represent a value we want an override for */
const isNullorLikeNull = val => isNull(val) || val === 'null';

export const useSplitIOTreatment = (splitName, attributes = null, predicate = () => true) => {
  /* If we can't handle what is passed, bone out with default */
  if (!splitName) return DEFAULTCONFIG;

  const readableSplitName = camelCase(splitName);
  /* base defaults that we set up in constants. Not required. */
  const baseDefaultSplitObject = splitioConstants.SPLITIO_SPLITS_WITH_ATTRIBUTES[splitName] || {};
  const defaultConfig = baseDefaultSplitObject.config;
  /*  Because we no longer frontload, we have to dispatch if we want split on a one by one basis */
  const {
    splitConfig,
    splitTreatment,
    splitLoaded
  } = useDispatchSplitTreatment(splitName, attributes, predicate);

  let splitConfigReassign = splitConfig;
  /* We have a split config & default Config, merge them. But *null* tells us to override with default. */
  if (splitConfig.id && defaultConfig) {
    splitConfigReassign = mergeWith({}, defaultConfig, splitConfig, (b, a) => isNullorLikeNull(a) ? b : a)
  }
  /* There is no split config but there is a default one, return it. */
  if (!splitConfig.id && defaultConfig) {
    splitConfigReassign = defaultConfig;
  }

  return {
    splitLoaded,
    splitTreatment,
    splitConfig: splitConfigReassign,
    [`${readableSplitName}SplitTreatment`]: splitTreatment,
    [`${readableSplitName}SplitConfig`]: splitConfig,
  };
};
