/* eslint-disable camelcase */
import { createSelector, createStructuredSelector } from 'reselect';
import componentSelector from 'src/selectors/component';
import { cartSelector } from 'src/selectors/cart';
import { brandingThemeSelector } from 'src/selectors/branding';
import { currentLandingSelector, isAlticeLandingSelector } from 'src/selectors/landings';
import layoutSelector from 'src/selectors/layout';
import { userSelector } from 'src/selectors/user';
import { WALMART_THEME } from 'src/utils/branding/constants';

/**
  Layout - Header
*/
const isTransparentSelector = createSelector(layoutSelector('header'), (header) =>
  header.get('transparent'),
);

const themeSelector = createSelector(layoutSelector('header'), (header) => header.get('theme'));

const actionableLogoSelector = createSelector(layoutSelector('header'), (header) =>
  header.get('actionableLogo'),
);

const homeUrlSelector = createSelector(layoutSelector('header'), (header) => header.get('homeUrl'));

const showSiteLinksSelector = createSelector(layoutSelector('header'), (header) =>
  header.get('showSiteLinks'),
);

/**
  Component - Sidebar
*/

const sidebarIsOpenSelector = createSelector(componentSelector('sidebar'), (sidebar) =>
  sidebar.get('open'),
);

/**
  Component - TopNav
*/

const topNavIsOpenSelector = createSelector(componentSelector('topNav'), (topNav) =>
  topNav.get('open'),
);

/**
  Component - SearchBar
 */

const searchBarIsOpenSelector = createSelector(componentSelector('search'), (search) =>
  search.get('show'),
);

export const hideMembershipSelector = createSelector(
  [isAlticeLandingSelector, brandingThemeSelector],
  (isAltice, theme) => isAltice || theme === WALMART_THEME,
);

export default createStructuredSelector({
  actionableLogo: actionableLogoSelector,
  cart: cartSelector,
  currentPartnerLanding: currentLandingSelector,
  hideMembership: hideMembershipSelector,
  homeUrl: homeUrlSelector,
  isTransparent: isTransparentSelector,
  showSiteLinks: showSiteLinksSelector,
  sidebarIsOpen: sidebarIsOpenSelector,
  snackbar: componentSelector('snackbar'),
  topNavIsOpen: topNavIsOpenSelector,
  searchBarIsOpen: searchBarIsOpenSelector,
  theme: themeSelector,
  user: userSelector,
});
