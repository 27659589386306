export const STATUS_ID = {
  ITEMS: 0,
  ADDRESS: 1,
  AVAILABILITY: 2,
  PAYMENT: 3,
  SUMMARY: 4,
  FINISHED: 5,
};

export const STATUS = {
  0: 'items',
  1: 'address',
  2: 'availability',
  3: 'payment',
  4: 'summary',
  5: 'finished',
};

// Errors
// server error message
export const NEED_AT_LEAST_ONE_VALID_DATE = 'NEED_AT_LEAST_ONE_VALID_DATE';
export const RECOMMEND_THREE_DATES = 'RECOMMEND_THREE_DATES';

/* actions */
export const ADD_PLAN_TO_CART = 'cart/ADD_PLAN_TO_CART';
export const REMOVE_PLAN_FROM_CART = 'cart/REMOVE_PLAN_FROM_CART';
