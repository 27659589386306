import {
  SUBMIT_FORM,
  FORM_SUBMITTED,
  DISMISS_THANK_YOU_PAGE,
  XFINITY_TECH_PAGE_FIRE_SEGMENT,
} from './constants';

export function submitForm(form, { onSuccess }) {
  return {
    type: SUBMIT_FORM,
    payload: { form, onSuccess },
  };
}

export function formSubmitted(status) {
  return {
    type: FORM_SUBMITTED,
    payload: status,
  };
}

export function dismissThankYouPage(status) {
  return {
    type: DISMISS_THANK_YOU_PAGE,
    payload: status,
  };
}

export function fireSegmentTracking() {
  return {
    type: XFINITY_TECH_PAGE_FIRE_SEGMENT,
  };
}
