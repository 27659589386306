// Libraries
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Constants
import { UPSELL_PLAN_BILLING_CYCLES } from 'src/constants/plan';
import { ADD_ANOTHER_PIN_REDIRECT } from 'src/containers/CartPage/constants';
// Paths
import { push } from 'src/utils/paths';
// Selectors
import { planSelector, standardAndSubscriptionCouponSelector } from 'src/selectors/cart';
import { userSubscriptionSelector } from 'src/selectors/user';
// Utils
import { isPaintedDoorTestSku } from 'src/containers/LandingPage/templates/ProductSingleSku/utils';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import { isPlanOnlyCart, getTotalCreditAdjustments } from 'src/utils/cart';
import { getFormattedPlanTrialExpirationDate } from 'src/containers/CartPage/utils';
// Components && Styles
import AddAnotherServiceBlock from 'src/containers/CartPage/Parts/AddAnotherServiceBlock';
import { AddMoreServicesButton } from 'src/containers/CartPage/Parts/AddAnotherServiceBlock/AddAnotherServiceBlock';
import { Grid } from 'src/components/HTKit/Grid';
import { PanelV2 } from 'src/components/HTKit/Elements/PanelV2';
import RemoveSubscriptionFromCartModal from 'src/components/HTKit/Modals/RemoveSubscriptionFromCartModal';
import PlanItem from './PlanItem';
import RegularCartServiceBreakdown from './RegularCartServiceBreakdown';
import { OrderSummary } from '../../Parts';
import styles from './styles.scss';

/**
 * @typedef {{days: number, expiresAt?: {integer: number, iso: string}, expires_at?: {integer: number, iso: string}}} PlanTrialData
 *
 * @param {{planInterval: string, planTrialData: PlanTrialData}} param0
 */
const getPlanCaptionText = (plan = {}) => {
  if (!plan) return '';
  if (plan.trial) return `Payment starts ${getFormattedPlanTrialExpirationDate(plan.trial)} `;
  const isMonthlyBilling = plan.billingIntervalMonths === 1;
  return isMonthlyBilling ? 'First of 12 Payments' : 'Membership Total';
};

/**
 * @param {{isPlanAddedToCart: boolean, hasProductsInCart: boolean, planTrialData: PlanTrialData}} param0
 */
const getOrderBreakdownTextPlan = ({ isPlanAddedToCart, hasProductsInCart, planTrialData }) => {
  if (planTrialData && planTrialData.days) return `${planTrialData.days} Day Free Trial`;
  return isPlanAddedToCart && hasProductsInCart ? 'Membership' : 'Due today';
};

/**
 * Return an object to be spread as props to <OrderSummary>
 * @param {{planAmount: number, planInterval: string, planTrialData: PlanTrialData}} param0
 */
const getOrderSummaryPlanAmounts = ({ planAmount, planInterval = '', planTrialData }) => {
  if (planTrialData) {
    const interval = planInterval.toLowerCase() === UPSELL_PLAN_BILLING_CYCLES.MONTHLY ? '/mo' : '';
    return { planAmount: '0.00', planLineThroughText: `$${planAmount}${interval}` };
  }
  return { planAmount: `${planAmount}` };
};

const RegularCart = ({
  cart,
  startBooking,
  goToAnotherService,
  removeItem,
  removePlan,
  goToEditSku,
  toggleRemovePlanModal,
  breakdownItems,
  itemsTotalFormatted,
  totalFormatted,
  plan,
  planInterval,
  planName,
  planAmount,
  canAddAnotherService,
  planDetails,
  showRemovePlanModal,
  coupon,
  couponText,
  couponValueFormatted,
  orderAdjustmentsNoCoupon,
  isPlanAddedToCart,
  selectedPlanPricingInterval,
  partnerName,
  cartHasPins,
  hasProductsInCart,
  subscriptionCouponValue,
  subscriptionCoupon,
  image,
  productOnlySkus,
  isPartner,
  showSkuLink,
  badgeSavings = {},
  onProductButtonClick,
}) => {
  // Hooks
  const standardAndSubscriptionCoupon = useSelector(standardAndSubscriptionCouponSelector);
  const dispatch = useDispatch();

  const servicesSectionStyles = classNames(
    styles.panelStyles,
    styles.servicesSection,
    'padding-small2',
  );

  const planTrialData = plan && plan.trial;

  /* Methods */
  const editItem = ({ skuId, index }) => goToEditSku({ skuId, index });
  const removeItemHandler = (item, index) => removeItem(item, index);
  const addAnotherPin = () => {
    const path = ADD_ANOTHER_PIN_REDIRECT[partnerName];
    return path ? dispatch(push(path)) : null;
  };

  /* Conditional Text Strings */
  const orderBreakdownTextProducts = hasProductsInCart
    ? 'Products and Services'
    : 'Due after service';
  const orderBreakdownCaptionProducts = hasProductsInCart ? '' : 'Service Total';
  const planCaptionText = getPlanCaptionText(plan);
  const orderBreakdownTextPlan = getOrderBreakdownTextPlan({
    isPlanAddedToCart,
    hasProductsInCart,
    planTrialData,
  });
  const getPlanCaption = () => {
    if (isPlanAddedToCart && hasProductsInCart) return '';
    return planCaptionText;
  };
  const orderSummaryPlanAmounts = getOrderSummaryPlanAmounts({
    planAmount,
    planInterval,
    planTrialData,
  });

  /* Coupon Data */
  const couponValue = subscriptionCoupon.size ? subscriptionCouponValue : couponValueFormatted;
  const couponDetails = { coupon, couponText, couponValue, orderAdjustmentsNoCoupon };

  /* Misc Data */
  const cartHasPlan = useSelector(planSelector);
  const userHasPlan = useSelector(userSubscriptionSelector);
  const isMemberCart = Boolean(cartHasPlan || userHasPlan);
  const creditAdjustment = getTotalCreditAdjustments(cart?.toJS ? cart.toJS() : cart);

  /* Painted Door test */
  const { splitTreatment } = useSplitIOTreatment(splitioConstants.SPLITIONAME_LP_MEMBERSHIP_TEST);
  const shouldShowPaintedDoorMembershipTest = splitTreatment === splitioConstants.ON;
  // Check to see if the paintedDoor sku ids are present in cart
  const canRemovePlan = !(
    breakdownItems.some((item) => isPaintedDoorTestSku({ skuId: item.skuId })) &&
    shouldShowPaintedDoorMembershipTest
  );
  /* End Painted Door Test */

  return (
    <Grid.Fluid>
      <Grid.Row>
        <Grid.Column sm={4} md={8} lg={8}>
          <PanelV2 className={servicesSectionStyles} lightShadow>
            {!!breakdownItems.length && (
              <RegularCartServiceBreakdown
                image={image}
                services={breakdownItems}
                editItem={editItem}
                removeItem={removeItemHandler}
                productOnlySkus={productOnlySkus}
                isPartner={isPartner}
                cartHasPins={cartHasPins}
                showSkuLink={showSkuLink}
                isMemberCart={isMemberCart}
                cart={cart}
              />
            )}
            {planName && (
              <PlanItem
                name={planName}
                amount={planAmount}
                planDetails={planDetails}
                removePlan={toggleRemovePlanModal}
                selectedPlanPricingInterval={selectedPlanPricingInterval}
                canRemovePlan={canRemovePlan}
                plan={plan}
              />
            )}
            {cartHasPins && (
              <AddMoreServicesButton onClick={addAnotherPin} fitContent>
                Add Another Pin Number
              </AddMoreServicesButton>
            )}
            <AddAnotherServiceBlock
              onServiceClick={goToAnotherService}
              onProductClick={onProductButtonClick}
              canAddAnotherService={canAddAnotherService}
              isPartner={isPartner}
            />
          </PanelV2>
        </Grid.Column>

        <Grid.Column sm={4} md={8} lg={4}>
          <OrderSummary
            name={orderBreakdownTextProducts}
            caption={orderBreakdownCaptionProducts}
            amount={itemsTotalFormatted}
            planName={planName}
            planText={orderBreakdownTextPlan}
            planCaption={getPlanCaption()}
            couponDetails={couponDetails}
            totalFormatted={totalFormatted}
            badgeText={badgeSavings.amount > 0 && `You saved ${badgeSavings.amountFormatted}`}
            startBooking={startBooking}
            isPlanOnlyCart={isPlanOnlyCart(cart)}
            standardAndSubscriptionCoupon={standardAndSubscriptionCoupon}
            creditAdjustment={creditAdjustment}
            {...orderSummaryPlanAmounts}
          />
        </Grid.Column>

        <RemoveSubscriptionFromCartModal
          visible={showRemovePlanModal}
          cart={cart}
          planDetails={planDetails}
          onCancel={toggleRemovePlanModal}
          removePlanFromCart={removePlan}
        />
      </Grid.Row>
    </Grid.Fluid>
  );
};

RegularCart.propTypes = {
  onProductButtonClick: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  removePlan: PropTypes.func.isRequired,
  startBooking: PropTypes.func.isRequired,
  goToAnotherService: PropTypes.func.isRequired,
  goToEditSku: PropTypes.func.isRequired,
  toggleRemovePlanModal: PropTypes.func.isRequired,
  cart: PropTypes.object,
  page: PropTypes.object.isRequired,
  breakdown: PropTypes.object,
  breakdownItems: PropTypes.array,
  orderAdjustmentsNoCoupon: PropTypes.array,
  itemsTotalFormatted: PropTypes.string,
  totalFormatted: PropTypes.string,
  plan: PropTypes.object,
  planInterval: PropTypes.string,
  planName: PropTypes.string,
  planAmount: PropTypes.string,
  canAddAnotherService: PropTypes.bool,
  planDetails: PropTypes.object,
  showRemovePlanModal: PropTypes.bool,
  coupon: PropTypes.object,
  couponText: PropTypes.string,
  couponValueFormatted: PropTypes.string,
  isPlanAddedToCart: PropTypes.bool,
  hasProductsInCart: PropTypes.bool,
  selectedPlanPricingInterval: PropTypes.string,
  partnerName: PropTypes.string,
  isPartner: PropTypes.bool,
  cartHasPins: PropTypes.bool,
  subscriptionCouponValue: PropTypes.string,
  subscriptionCoupon: PropTypes.array,
  productOnlySkus: PropTypes.array,
  image: PropTypes.string,
  showSkuLink: PropTypes.bool,
  badgeSavings: PropTypes.shape({
    amount: PropTypes.number,
    amountFormatted: PropTypes.string,
  }),
};
RegularCart.defaultProps = {
  orderAdjustmentsNoCoupon: [],
  breakdownItems: [],
};

AddMoreServicesButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  fitContent: PropTypes.bool,
};
AddMoreServicesButton.defaultProps = { onClick: () => {} };

export default RegularCart;
