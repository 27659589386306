import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.scss';

const DarkOverlay = ({ isOpen, toggleSidebar }) => {
  const darkOverlayStyles = cn('site-v2', styles.overlay, {
    [styles.open]: isOpen,
  });

  return <div className={darkOverlayStyles} onClick={toggleSidebar} />;
};

DarkOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default DarkOverlay;
