import { fromJS } from 'immutable';
import { PAGE_LOADED } from 'src/constants/common';
import { PAGE_NAME, LOAD_PLAN_DETAILS } from './constants';

const initialState = fromJS({ plan: {}, plansInfo: {} });

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAGE_LOADED: {
      if (action.page === PAGE_NAME) {
        const { plansInfo } = action;
        if (plansInfo) {
          return state.set('plansInfo', fromJS(plansInfo));
        }
      }
      return state;
    }
    case LOAD_PLAN_DETAILS: {
      const { plan } = action;
      return state.set('plan', fromJS(plan));
    }
    default:
      return state;
  }
};

export default pageReducer;
