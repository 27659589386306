/* These values come from the BE */
export const PREPAID_STATUS = {
  NONE: 'none',
  BASE: 'base',
  FULL: 'full',
};

export const SKU_TYPE = {
  PRODUCT: 'Product',
};

/**
 * SKU_CATEGORIES_BY_SEO
 * Seems we have disparate references. We should of put them here.
 * SKU_CATEGORIES_BY_ID will give an inversion reprsentation of this object
 */
export const SKU_CATEGORIES = {
  TV_MOUNTING: {
    name: 'TV Mounting',
    id: 7,
    seoName: 'tv-mounting',
  },
  COMPUTERS: {
    name: 'Computer Support',
    id: 1,
    seoName: 'computers',
  },
  WIFI: {
    name: 'WiFi & Network',
    id: 2,
    seoName: 'networking',
  },
  SMART_HOME: {
    name: 'Smart Home',
    id: 8,
    seoName: 'smart-home',
  },
  AUDIO_VIDEO: {
    name: 'Audio & Video',
    id: 4,
    seoName: 'television-and-audio',
  },
  MOBILE_TABLETS: {
    name: 'Mobile & Tablets',
    id: 3,
    seoName: 'mobile',
  },
  HOME_OFFICE: {
    name: 'Home Office / School',
    id: 26,
    seoName: 'home-office',
  },
  HOME_SECURITY: {
    name: 'Home Security',
    id: 40,
    seoName: 'home-security',
  },
  AROUND_THE_HOME: {
    name: 'Around the Home',
    id: 41,
    seoName: 'around-the-home',
  },
};

/**
 * SKU_CATEGORIES_BY_ID
 * This is created automatically based on object above: SKU_CATEGORIES_BY_SEO,
 * in which the category id is the key.
 */
export const SKU_CATEGORIES_BY_ID = (() => {
  return Object.entries(SKU_CATEGORIES).reduce((k, [, catObj]) => {
    return {
      [catObj.id]: { ...catObj },
    };
  }, {});
})();

/*
 *****************************************************
 * For reducers
 *****************************************************
 */
export const SKU_DATA_LOADED = 'common/sku/SKU_DATA_LOADED';
