import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import StarRating from '../HTKit/StarRating';
import styles from './styles.scss';
import verifiedImg from './images/verified.svg';

function displayDate(timestamp) {
  return moment(timestamp).format('MM/DD/YYYY');
}

const Reviews = ({ review, className = '' }) => {
  if (!review) return null;
  const [showMore, setShowMore] = useState(false);

  function handleShowMore(e) {
    e.preventDefault();
    setShowMore(!showMore);
  }

  const comment = review.get('comment');
  let displayComment = comment;
  if (comment.length >= 150) {
    if (showMore) {
      displayComment = (
        <span>
          {comment}{' '}
          <button onClick={handleShowMore} className="plainButton text-link medium-link">
            read less
          </button>
        </span>
      );
    } else {
      displayComment = (
        <span>
          {comment.substring(0, 147).trim()}
          {'... '}
          <button onClick={handleShowMore} className="plainButton text-link medium-link">
            read more
          </button>
        </span>
      );
    }
  }

  return (
    <div className={`site-v2 ${className}`}>
      <div className="flex justifyContentSpaceBetween alignItemsCenter">
        <div className={styles.starsWrapper}>
          <StarRating stars={review.get('stars')} />
        </div>
        <p className="p1">{displayDate(review.get('timestamp'))}</p>
      </div>
      <p className="p1 n700 marginY-small">{displayComment}</p>
      <p className="text-weight-bold">
        {review.get('author')}
        <img
          src={verifiedImg}
          alt="verified"
          className={`${styles.verifiedImg} marginLeft-small`}
        />
      </p>
      {review.get('city') && <p className={`p1 ${styles.reviewLocation}`}>{review.get('city')}</p>}
    </div>
  );
};

Reviews.propTypes = {
  review: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default Reviews;
