// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Images
import usaToday from './logo_usatoday.png';
import fortune from './logo_fortune.png';
import verge from './logo_verge.png';
// Styles
import styles from './styles.scss';

const InTheNews = ({ backgroundColor, headerClasses = '' }) => {
  const background = `${backgroundColor}Background`;
  return (
    <section className={cn('l-content-section', styles[background])}>
      <h2 className={cn('l-section-header', styles.header, headerClasses)}>In The News</h2>
      <div className={styles.container}>
        <figure className={styles.newsContainer}>
          <img src={usaToday} alt="USA Today" className={styles.logoStyle} />
          <h5>The best, cheapest way to get good tech support</h5>
          <p className="p1 paddingY-small1">
            <img src={usaToday} alt="USA Today" className={styles.logoStyleD} />
            You don&apos;t have to understand how an internal combustion engine works to drive your
            car to the mall, so why does it often feel like you need a degree in computer science
            just to use your PC?
          </p>
          <a
            href="http://www.usatoday.com/story/tech/columnist/saltzman/2017/01/21/tech-support-we-all-need-but-how-do-you-get/96783928/"
            className="medium-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read Full Article
          </a>
        </figure>
        <figure className={styles.newsContainer}>
          <img src={fortune} alt="Fortune" className={styles.logoStyle} />
          <h5>Succeeding With A &apos;Bolt-On Geek Squad&apos;</h5>
          <p className="p1 paddingY-small1">
            <img src={fortune} alt="Fortune" className={styles.logoStyleD} />
            Richard Wolpert is not your average Silicon Valley startup CEO. For starters, he&apos;s
            in Los Angeles, not the Bay Area.
          </p>
          <a
            href="http://fortune.com/2017/05/30/succeeding-with-a-bolt-on-geek-squad/"
            className="medium-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read Full Article
          </a>
        </figure>
        <figure className={styles.newsContainer}>
          <img src={verge} alt="The Verge" className={styles.logoStyle} />
          <h5>Samsung&apos;s new Galaxy Note 8 customers get free in-home visits from HelloTech</h5>
          <p className="p1 paddingY-small1">
            <img src={verge} alt="The Verge" className={styles.logoStyleD} />
            For the launch of its new Galaxy Note 8 smartphone, Samsung is — not surprisingly —
            doubling down on the whole support thing.
          </p>
          <a
            href="https://www.theverge.com/2017/8/23/16187814/samsung-galaxy-note-8-tech-support-hellotech"
            className="medium-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read Full Article
          </a>
        </figure>
      </div>
    </section>
  );
};

InTheNews.defaultProps = {
  backgroundColor: 'white',
};

InTheNews.propTypes = {
  backgroundColor: PropTypes.string,
  headerClasses: PropTypes.string,
};

export default InTheNews;

/*
 InTheNews:
 A reusable page section showing featured articles.

 Examples:
 <InTheNews backgroundColor="white" />

 Properties:
 backgroundColor:
 type: string
 description: Used to set the background-color CSS property
 */
