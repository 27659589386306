import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SubHeaderCopy from './SubHeaderCopy';
import { PARTNERS } from './constants';
import styles from './styles.scss';

const DefaultPrompt = () => {
  const subHeaderStyles = cn(styles.subHeader, 'p2');
  return (
    <p className={subHeaderStyles}>
      <SubHeaderCopy />
    </p>
  );
};

const AlternateTimesPrompt = ({ partner, previouslySelectedTimes }) => {
  if (partner === PARTNERS.SAMSUNG) {
    if (previouslySelectedTimes && previouslySelectedTimes.size > 0) {
      return (
        <p className={styles.subHeader}>
          This is the preferred appointment time you requested during sign up:
          <br />
          <br />
          {previouslySelectedTimes.first().get('display')}
        </p>
      );
    }
    return <DefaultPrompt />;
  }
  return <DefaultPrompt />;
};

AlternateTimesPrompt.propTypes = {
  partner: PropTypes.string,
  previouslySelectedTimes: PropTypes.array,
};

AlternateTimesPrompt.defaultProps = {
  partner: null,
  previouslySelectedTimes: null,
};

export default React.memo(AlternateTimesPrompt);
