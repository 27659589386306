import { connect } from 'react-redux';
import { compose } from 'redux';
import { loadPage, layoutUpdate } from 'src/actions/common';
import { push } from 'src/utils/paths';
import withToggleSiteNav from 'src/hoc/withToggleSiteNav';
import { showFirstServiceDiscountModal } from 'src/components/HTKit/Modals/FirstServiceDiscount/actions';
import { clearAppNotices } from 'src/actions/snackNotice';
import {
  removeItem,
  clearItemMessages,
  dismissModalMultipleAutoApplyCoupons,
  startBooking,
  removePlan,
  addPlanAndStartBooking,
  toggleRemovePlanModal,
  togglePlanHolderModal,
  showUpsellCartVersion as showUpsellCartVersionAction,
} from './actions';
import pageSelectors from './selectors';
import CartPage from './CartPage';

export default compose(
  connect(pageSelectors, {
    loadPage,
    layoutUpdate,
    clearItemMessages,
    removeItem,
    startBooking,
    showFirstServiceDiscountModal,
    dismissModalMultipleAutoApplyCoupons,
    push,
    removePlan,
    addPlanAndStartBooking,
    toggleRemovePlanModal,
    togglePlanHolderModal,
    clearAppNotices,
    showUpsellCartVersionAction,
  }),
  withToggleSiteNav(),
)(CartPage);
