// Libraries
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Guarantee from 'src/components/Guarantee';
import { Grid } from 'src/components/HTKit/Grid';
import heroImg from './images/HT-fees-hdr.jpg';
import heroImgMobile from './images/HT-fees-hdr-mobile.jpg';
import techImage from './images/fleet-quality.jpg';
import customerImage from './images/commitment.jpg';

// Styles
import styles from './styles.scss';

const SafetyandSupport = ({ isMobileOrTablet }) => {
  const heroImage = isMobileOrTablet ? heroImgMobile : heroImg;

  return (
    <Grid.Fluid classes="site-v2">
      <img src={heroImage} alt="techatwork" className="width-full" />
      <section className="paddingY-medium1">
        <div className={styles.descriptionContainer}>
          <h1 className="h2 paddingBottom-small2">
            HelloTech&apos;s Commitment to Safety and Support
          </h1>
          <p className="n700 paddingBottom-small2">
            <b className="p0 n900 text-weight-bold">
              At HelloTech, our top priority is to provide you with exceptional service and 24x7
              support.
            </b>{' '}
            That’s why we invest in knowledge development resources for our technicians as well as
            continual training of our customer support staff. We know how challenging it can be to
            keep up with the latest in technology. That’s why we aim to be your go-to experts, so
            you don’t have to!
          </p>
          <p className="n700 paddingBottom-small2">
            We appreciate the fact that customers put their trust in us by inviting HelloTech into
            the home.{' '}
            <b className="p0 n900 text-weight-bold">
              We invest time and resources into ensuring your safety by taking numerous precautions
              to carefully and fully vet our technicians.
            </b>{' '}
            Our rigorous background check process searches through national, state and county
            databases over the past 7 years to screen for any offense that would disqualify a
            potential technician from our service platform. For the safety of our techs on the job,
            HelloTech also makes occupational and accidental insurance available to protect
            technicians from any potential injuries that may occur while they’re in your home.
          </p>
        </div>
        <Grid.FullWidth>
          <Grid.Row>
            <Grid.Column
              sm={4}
              md={4}
              lg={6}
              classes={`paddingBottom-medium1 flex justifyContentSpaceBetween flexDirectionColumn ${styles.leftCol}`}
            >
              <div>
                <h4 className="paddingTop-medium paddingBottom-small">
                  Commitment to Customer Support:
                </h4>
                <p className="n700 marginBottom-small1">
                  At HelloTech, we are dedicated to maintaining a premium level of service and
                  support for all of our customers. This means investing in the latest tools,
                  training, and products for our team. Whenever you need assistance, we’re here to
                  help. HelloTech support agents are standing by 24x7 to assist with booking,
                  diagnosing issues and troubleshooting issues remotely when possible. We’re in the
                  business of making technology simple, and we believe that providing a seamless
                  customer experience is the best way to do so.
                </p>
              </div>
              <img src={customerImage} alt="techwithcustomer" className="width-full" />
            </Grid.Column>

            <Grid.Column
              sm={4}
              md={4}
              lg={6}
              classes={`paddingBottom-medium1 flex justifyContentSpaceBetween flexDirectionColumn ${styles.rightCol}`}
            >
              <div>
                <h4 className="paddingTop-medium paddingBottom-small">
                  Fleet of Quality Nationwide Techs:
                </h4>
                <p className="n700 marginBottom-small1">
                  Less than one out of every five applicants are selected to join the HelloTech
                  platform. During our rigorous onboarding process, techs are hand selected,
                  thoroughly background checked and must demonstrate top tier technical knowledge
                  through a series of skills assessments. Our continued investment in the best
                  onboarding tools, training, and staff is how we can assure that our nationwide
                  force of techs are the best in the business.
                </p>
              </div>
              <img src={techImage} alt="techworking" className="width-full" />
            </Grid.Column>
          </Grid.Row>
        </Grid.FullWidth>
        <div>
          <h4>HelloTech Guarantee:</h4>
          <p className="n700 paddingY-small1">
            <em>
              “We&apos;re committed to your complete satisfaction with our service. If you&apos;re
              not 100% happy, we&apos;ll do whatever it takes to make it right.”
            </em>
          </p>
          <p className="n700">
            Our satisfaction guarantee applies to all HelloTech services. If any issues arise during
            or after your service, please let us know right away. We are committed to doing
            everything we possibly can to make sure that every service we provide ends with a happy
            customer.
          </p>
        </div>
        <Guarantee containerStyle="marginTop-medium1" />
      </section>
    </Grid.Fluid>
  );
};

SafetyandSupport.propTypes = {
  isMobileOrTablet: PropTypes.bool.isRequired,
};

export default memo(SafetyandSupport);
