import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { immutableToJS } from 'src/utils/helpers';
import {
  evCustomerInfoPath,
  evSkuQuestionsPath,
  evSchedulingPath,
  evPaymentPath,
  evReviewPath,
} from 'src/utils/paths';
import { appendCartTokenToUrl } from 'src/utils/urlQuery';
import { WORKFLOW_STATUS, WORKFLOW_STEP_TYPE, WORKFLOW_TYPE } from './ev.constants';

export const orderWorkflowTypeIsEV = (order = {}) => {
  const orderJS = immutableToJS(order);
  return get(orderJS, 'workflow.type') === WORKFLOW_TYPE.EV;
};

export const orderWorkFlowTypeIsEVInstallOnly = (order = {}) => {
  const orderJS = immutableToJS(order);
  return get(orderJS, 'workflow.type') === WORKFLOW_TYPE.EV_INSTALL_ONLY;
};

export const cartWorkflowTypeIsEVInstallOnly = (cart = {}) => {
  return get(cart, 'workflow.type') === WORKFLOW_TYPE.EV_INSTALL_ONLY;
};

export const cartWorkflowTypeisEv = (cart = {}) => {
  return get(cart, 'workflow.type') === WORKFLOW_TYPE.EV;
};

export const extractWorkflowTokenfromOrder = (order = {}) => {
  const orderJS = immutableToJS(order);
  return get(orderJS, 'workflow.token');
};
export const cartWorkflowStatusIsSiteVisit = (cart = {}) => {
  return get(cart, 'workflow.status') === WORKFLOW_STATUS.SITE_VISIT;
};

// workflow can be an empty object
export const extractCartTokenFromWorkflow = (workflow) => {
  if (!workflow || isEmpty(workflow)) {
    return null;
  }

  const { steps, status } = workflow;
  const workflowItem = steps[status];

  if (!workflowItem) {
    return null;
  }

  const { type: itemType, token } = workflowItem;
  return itemType === WORKFLOW_STEP_TYPE.CART ? token : null;
};

/**
 * @param {Object} props
 * @param {'customerInfo' | 'qa' | 'scheduling' | 'payment' | 'review'} props.pathType - which path to use
 * @param {String} props.token - cart token
 * @param {String} props.extraQuery - extra query params (edit=true)
 */
export const buildEVPaths = ({ pathType, token, extraQuery = null, skuId }) => {
  const pathing = {
    pathname: '',
    search: appendCartTokenToUrl(extraQuery, token),
  };

  switch (pathType) {
    case 'customerInfo':
      pathing.pathname = evCustomerInfoPath();
      break;
    case 'qa':
      pathing.pathname = evSkuQuestionsPath(skuId);
      break;
    case 'scheduling':
      pathing.pathname = evSchedulingPath();
      break;
    case 'payment':
      pathing.pathname = evPaymentPath();
      break;
    case 'review':
      pathing.pathname = evReviewPath();
      break;
    default:
      break;
  }
  return pathing;
};

/**
 * `cart.breakdown.incentives` is the raw response from the 3rd party (Upfront) API.
 * We want to add our cartTotal to that for display purposes.
 */
export const getIncentiveDataFromCart = (cart = {}) => {
  const defaultIncentiveData = {
    /** Total amount of incentives */
    total: 0,
    /** Cart total after incentives */
    totalAfterIncentives: 0,
    /** Incentive programs to display */
    programs: [],
  };
  const breakdownIncentiveData = get(cart, 'breakdown.incentives', defaultIncentiveData);
  const cartTotal = get(cart, 'breakdown.total', 0);
  return { ...breakdownIncentiveData, cartTotal };
};
