// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
// Styles
import styles from './styles.scss';

const BBB = ({ breakLine, showTagLineMobile, showTagLineTablet, showTagLineDesktop, containerStyle, logoStyle }) => {
  const cx = classNames.bind(styles);
  const bbbImgClasses = cx({
    bbbImg: true,
    'ht-icon': true,
    'ht-bbb-logo': true,
    showTagLineMobile,
    showTagLineTablet,
    showTagLineDesktop,
  });
  const tagLineClasses = cx('p1 text-weight-med', {
    tagLine: true,
    showTagLineMobile,
    showTagLineTablet,
    showTagLineDesktop,
  });

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <i className={`${bbbImgClasses} ${logoStyle}`} alt="Better Business Bureau Logo" />
      <p className={tagLineClasses}>HelloTech is accredited and holds an A+&nbsp;
        <span className={breakLine ? styles.breakLine : ''}>rating from the Better Business Bureau</span>
      </p>
    </div>
  );
};

BBB.defaultProps = {
  breakLine: true,
  showTagLineMobile: true,
  showTagLineTablet: true,
  showTagLineDesktop: true,
  containerStyle: '',
  logoStyle: '',
};

BBB.propTypes = {
  breakLine: PropTypes.bool,
  showTagLineMobile: PropTypes.bool,
  showTagLineTablet: PropTypes.bool,
  showTagLineDesktop: PropTypes.bool,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  logoStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default BBB;

/*
  BBB:
    A component which renders the BBB logo.

  Examples:
    <BBB showTagLineMobile={false} showBlur={false} />

  Notable Properties:
    breakLine:
      type: boolean
      description: Used to force a manual line break on the tag line.
    showBlur:
      type: boolean
      description: Show/hide an image used as a blur overlay
    showTagLine***:
      type: boolean
      description: Used to control show/hide of the tagline in various screen widths
    containerStyle:
      type: string or object
      description: Styles to add to the container
    logoStyle:
      type: string or object
      description: styles to add to the BBB logo

  Last modified: -JK May 26, 2017
*/
