import React, { useState } from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import getPlanBenefitsListAndValue from 'src/components/SubscriptionBenefitsBreakdown/utils';
import { Row, NameColumn, PriceColumn } from '../TableParts';
import SavingsCallout from '../SavingsCallout';
import breakdownStyles from '../styles.scss';
import styles from './styles.scss';

const PlanItem = ({
  cart,
  showPlanItem,
  removePlan,
  planDetails,
}) => {
  const [showPlanDetails, togglePlanDetails] = useState(true);
  const handleToggle = () => togglePlanDetails(!showPlanDetails);

  const planId = cart.getIn(['plan', 'id']);
  if (!planId || !showPlanItem) return null;

  const planName = cart.getIn(['plan', 'name']);
  const planCost = cart.getIn(['plan', 'amount']);
  const { benefitsList, benefitsValue } = getPlanBenefitsListAndValue({ plan: planDetails });

  const planCostCopyStyles = classNames("p1 n700", styles.planCostCopy);

  return (
    <div className={breakdownStyles.itemSection}>
      <Row>
        <NameColumn
          showChevron
          persistentChevron
          open={showPlanDetails}
          handleToggle={handleToggle}
          nameColumnStyle={breakdownStyles.sectionHeaderNameColumn}
        >
          <div className={styles.planNameValueWrapper}>
            <h5 className={breakdownStyles.sectionHeaderSkuName}>
              1-Year {planName} Membership
            </h5>
            {benefitsValue > 0 && (
              <h6 className={styles.planBenefitsValue}>${benefitsValue} value</h6>
            )}
          </div>
        </NameColumn>
        <PriceColumn>
          <h5>${planCost}</h5>
        </PriceColumn>
      </Row>
      {showPlanDetails && benefitsList.length > 0 && benefitsList.map((benefit) => {
        const { name, value, cost } = benefit;
        return (
          <Row rowStyle={breakdownStyles.adjustmentRow} key={name}>
            <NameColumn>
              <p className="p1 n700">{name}</p>
            </NameColumn>
            <PriceColumn>
              <div className={styles.calloutPriceWrapper}>
                <SavingsCallout
                  amountFormatted={value}
                  className={styles.savingsCallout}
                />
                <p className={planCostCopyStyles}>{cost}</p>
              </div>
            </PriceColumn>
          </Row>
        );
      })}
      <Row rowStyle={breakdownStyles.footerRow}>
        <NameColumn>
          <button className="plainButton" onClick={removePlan}>
            <p className="p1 text-link">Remove</p>
          </button>
        </NameColumn>
      </Row>
    </div>
  );
}

PlanItem.propTypes = {
  cart: PropTypes.object.isRequired,
  showPlanItem: PropTypes.bool.isRequired,
  removePlan: PropTypes.func,
  planDetails: PropTypes.object,
};

export default PlanItem;
