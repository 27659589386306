import { useState, useCallback } from 'react';

const useToggle = (initialValue = false) => {
  const [toggleState, setToggleState] = useState(initialValue);
  const toggle = useCallback(() => setToggleState(!toggleState), [toggleState]);
  const setBooleanToggle = useCallback((bool) => setToggleState(bool), []);

  return {
    toggleState,
    setToggleState,
    toggle,
    setBooleanToggle,
  };
};

export default useToggle;
