import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setHeaderLogo } from 'src/components/Header/featuredContentOrSku/actions';
import { resolveLayout } from 'src/utils/layout';
import { layoutUpdate } from 'src/actions/common';
import { push } from 'src/utils/paths';
import { LANDING_TEMPLATES } from 'src/constants/landings';
import AmFam from './AmFam';

class AmFamWrapper extends Component {
  static propTypes = {
    layoutUpdate: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.layoutUpdate(resolveLayout(LANDING_TEMPLATES.FEATURED_CONTENT_OR_SKU));
  }

  render() {
    return <AmFam {...this.props} />;
  }
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.landing.toJS();
  return {
    reviews: state.getIn(['entities', 'reviews', 'landings', id.toString()], null),
  };
}

export default connect(
  mapStateToProps,
  { layoutUpdate, push, setHeaderLogo },
)(AmFamWrapper);
