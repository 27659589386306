import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Bar from '../Bar';
import styles from './styles.scss';

const Fixed = ({ snackbar, removeAppSnackNotice }) => {
  const notices = snackbar.get('notices');

  const dismiss = useCallback((notice) => {
    return () => {
      const noticeId = notice.get('noticeId');
      removeAppSnackNotice({ noticeId });
    };
  }, []);

  const outerWrapperStyles = classNames('site-v2', styles.outerWrapper, {
    [styles.hide]: notices.size === 0,
  });

  return (
    <div className={outerWrapperStyles}>
      <div className={styles.innerWrapper}>
        {notices.map((notice) => {
          const dimissHandler = dismiss(notice);
          return (
            <Bar
              key={notice.get('noticeId')}
              status={notice.get('status')}
              content={notice.get('content')}
              template={notice.get('template')}
              templateData={notice.get('templateData')}
              dismissable={notice.get('dismissable')}
              dimissableOnClick={dimissHandler}
              autoClose={notice.get('autoClose')}
            />
          );
        })}
      </div>
    </div>
  );
};

Fixed.propTypes = {
  snackbar: PropTypes.object,
  removeAppSnackNotice: PropTypes.func.isRequired,
};

export default Fixed;
