import Cookies from 'js-cookie';

/**
 This cookie is set when a user submits a lead Generated email. See hooks/useLeadGeneration.js
 2 days.
 - Get a cookie
 - Set a cookie: expects an object

 - Cookie has a 30 day expiry
 */

const EXPIRY_IN_DAYS = 1; // one day
const COOKIE_NAME = "_ht_lead_generated_";

export const getLeadGeneratedCookie = () => {
  const value = Cookies.get(COOKIE_NAME);

  return value && value !== 'undefined' ? JSON.parse(value) : null;
};

export const setLeadGeneratedCookie = (data) => Cookies.set(COOKIE_NAME, JSON.stringify(data), { expires: EXPIRY_IN_DAYS });
