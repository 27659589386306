import { pathnameFromString } from 'src/utils/validation';

/**
 * Our action object. We could use a switch statement, but inevitably, we'll have nesting.
 * Our recursive/gen functions will iterate over this object and get at our functional intent.
 */

const generateMessagePageActions = (additionalActions) => (props, attributes) => {
  /*
    This function returns an object with functions that will execute in response to receiving a postMessage from
    the iframe. These are the common set of functions that will be provided to all instances of IframeView.

    Because each implementation is different you may need to add or overwrite some of the functions here (e.g., the basic loaded() function
    does not fire any segment events, but you want to add that functionality and overwrite the base loaded() function). In these cases you
    can pass a function that returns an object that contains your custom functions and the 2 objects will be merged together.

    Example:

    const myCustomStuff = (props, attributes) => {
      return {
        loaded() {
          console.log('do custom stuff here', props, attributes)
          attributes.setPageLoaded(true);
        }
        myCustomFunc() {
          // do custom stuff here
        }
      }
    }
    ...
    <IframeView additionalActions={myCustomStuff} />

  */
  const additional = additionalActions ? additionalActions(props, attributes) : {};
  const baseActions = {
    loaded() {
      attributes.setPageLoaded(true);
    },
    height() {
      /* Get updated elem references */
      const iframeCategoryContainer = document.getElementById(attributes.containerId);
      const iframeCategoryPage = document.getElementById(attributes.iframeId);

      iframeCategoryContainer.style.height = `${attributes.message}px`;
      iframeCategoryPage.style.height = `${attributes.message}px`;
      iframeCategoryPage.style.width = '100%';
    },
    // This reads in the message from the type of 'goToLocation'. The message must be a
    // valid url else it's gonna link to something bad.
    goToLocation() {
      const pathName = pathnameFromString(attributes.message);
      window.location = pathName;
    },
    /*
        This links to an external source, rather than internal to the app.
        They will either replace the current window, or "open a new tab" ie: newTab?

        For this method, "message" is an object:
        message: { locationURL, newTab }
     */
    goToExternalLocation() {
      const { newTab = false, locationURL } = attributes.message;

      if (newTab) {
        window.open(locationURL, "_blank")
      } else {
        window.location = locationURL;
      }
    },
  };
  return { ...baseActions, ...additional };
};

export { generateMessagePageActions };
