import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
// Actions && Utils
import { push, addSkuPath, cartBookingSkuPath } from 'src/utils/paths';
import { getTotalCreditAdjustments } from 'src/utils/cart';
// Components && Styles
import { Grid } from 'src/components/HTKit/Grid';
import { PanelV2 } from 'src/components/HTKit/Elements/PanelV2';
import OrderBreakdownLineItem from 'src/components/OrderSummary/Breakdown/OrderBreakdownLineItem';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import OrderAdjustmentsBreakdown from 'src/components/OrderSummary/Breakdown/OrderAdjustmentsBreakdown';
import CreditAppliedLineItem from 'src/components/OrderSummary/Breakdown/CreditAppliedLineItem';
import OrderTotalV2 from 'src/components/OrderSummary/Breakdown/OrderTotalV2';
import regularCartStyles from 'src/containers/CartPage/CartTypes/RegularCart/styles.scss';
import RemoveSubscriptionFromCartModal from 'src/components/HTKit/Modals/RemoveSubscriptionFromCartModal';
import ApiOrderCartServiceBreakdown from './ApiOrderCartServiceBreakdown';
import PlanItem from '../RegularCart/PlanItem';
import styles from './styles.scss';

const ApiOrderCartPage = ({
  canAdvanceApiOrderCart,
  breakdownItems,
  itemsTotalFormatted,
  orderAdjustmentsNoCoupon,
  totalFormatted,
  startBooking,
  planDetails,
  toggleRemovePlanModal,
  showRemovePlanModal,
  cart,
  removePlan,
  plan,
}) => {
  const dispatch = useDispatch();

  const servicesSectionStyles = cn(
    regularCartStyles.panelStyles,
    regularCartStyles.servicesSection,
    'padding-small2',
  );
  const summaryContainerStyles = cn(
    regularCartStyles.panelStyles,
    regularCartStyles.summaryContainer,
    'padding-small2',
  );

  const { amout: creditAmount, amountFormated: creditAmountFormatted } = getTotalCreditAdjustments(
    cart?.toJS ? cart.toJS() : cart,
  );

  const editItem = ({ skuId, index }) => (event) => {
    event.preventDefault();
    dispatch(push(addSkuPath(skuId), { mode: 'edit', itemIndex: index }));
  };

  const simulateAddItem = ({ skuId, index }) => (event) => {
    // "simulateAdd" because it's really just editing, but they want the "Add Item" snackbar to show -GH Aug 6, 2020
    event.preventDefault();
    dispatch(push(cartBookingSkuPath, { skuId, itemIndex: index }));
  };
  const planLineItemStyles = breakdownItems.length && styles.planLineItem;

  return (
    <Grid.Fluid>
      <Grid.Row>
        <Grid.Column sm={4} md={8} lg={8}>
          <PanelV2 className={servicesSectionStyles} lightShadow>
            {!!breakdownItems.length && (
              <ApiOrderCartServiceBreakdown
                services={breakdownItems}
                editItem={editItem}
                simulateAddItem={simulateAddItem}
              />
            )}
            {planDetails && cart.get('plan') && (
              <div className={cn('marginTop-small2 paddingTop-small2', planLineItemStyles)}>
                <PlanItem
                  name={planDetails.get('name')}
                  amount={planDetails.get('amount')}
                  planDetails={planDetails}
                  removePlan={toggleRemovePlanModal}
                  selectedPlanPricingInterval={planDetails.get('planInterval')}
                  plan={plan}
                />
              </div>
            )}
          </PanelV2>
        </Grid.Column>

        <Grid.Column sm={4} md={8} lg={4}>
          <PanelV2 className={summaryContainerStyles} lightShadow>
            <h5>Order Summary</h5>
            <div className={regularCartStyles.totalWrapper}>
              <OrderBreakdownLineItem
                name="Due after service"
                caption="Service Total"
                amount={itemsTotalFormatted}
                className="marginBottom-small"
              />
              {!!creditAmount && <CreditAppliedLineItem amount={creditAmountFormatted} hideIcon />}
              {!!orderAdjustmentsNoCoupon.length && (
                <OrderAdjustmentsBreakdown adjustments={orderAdjustmentsNoCoupon} />
              )}
              <OrderTotalV2 text="Order Subtotal" totalFormatted={totalFormatted} />
            </div>

            <Button
              onClick={startBooking}
              theme={THEMES.V2PRIMARY}
              disabled={!canAdvanceApiOrderCart}
              hugeV2
            >
              Checkout
            </Button>
          </PanelV2>
        </Grid.Column>
        <RemoveSubscriptionFromCartModal
          visible={showRemovePlanModal}
          cart={cart}
          planDetails={planDetails}
          onCancel={toggleRemovePlanModal}
          removePlanFromCart={removePlan}
        />
      </Grid.Row>
    </Grid.Fluid>
  );
};

ApiOrderCartPage.propTypes = {
  canAdvanceApiOrderCart: PropTypes.bool,
  breakdownItems: PropTypes.array,
  itemsTotalFormatted: PropTypes.string,
  orderAdjustmentsNoCoupon: PropTypes.array,
  totalFormatted: PropTypes.string,
  startBooking: PropTypes.func,
  partnerName: PropTypes.string,
  planDetails: PropTypes.object,
  toggleRemovePlanModal: PropTypes.func,
  showRemovePlanModal: PropTypes.bool,
  cart: PropTypes.object,
  removePlan: PropTypes.func.isRequired,
  plan: PropTypes.object,
};

ApiOrderCartPage.defaultProps = {
  orderAdjustmentsNoCoupon: [],
  breakdownItems: [],
  startBooking: () => {},
};

export default ApiOrderCartPage;

/*
  - The Design team wants to add a version of the cart page with similar functionality because they find it useful. This component
    will ONLY be shown for API orders. Any other orders should go through the normal flow, which includes the <CartPage/> component.
    See JIRA ticket: https://hellotech.atlassian.net/browse/HTD-1998 for details

    -GH Aug 4, 2020

  - This component had to be updated to accomodate plan line item.
    See JIRA ticket: https://hellotech.atlassian.net/browse/MEM-244 for details

*/
