import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Grid } from 'HTKit/Grid';
import Icon from 'HTKit/Icon';
import PhoneIcon from 'src/components/Header/Parts/PhoneIcon';
import HTLogo from 'src/images/HT_logo-dark-blue.svg';
import { displayPhone } from 'src/utils/formatter/phone';
import { pureWorkflowStatePartnerJSSelector, pureWorkflowStateSkuJSSelector } from 'src/selectors/workflow';
import { evPartnerCobrandImageSelector } from 'src/containers/EV/ev.selectors';
import { useExtractEVPartnerClientAttributes } from 'src/containers/EV/ev.hooks';
import EVAccountModal from './EVAccountModal';

import styles from '../styles.scss';

const EVPortalHeader = () => {
  const [showAccountModal, setShowAccountModal] = useState(false);
  const evPartner = useSelector(pureWorkflowStatePartnerJSSelector);
  const {id} = useSelector(pureWorkflowStateSkuJSSelector) || {};
  const {phone} = useExtractEVPartnerClientAttributes(evPartner) || {};
  const partnerImageUrl = useSelector(evPartnerCobrandImageSelector({ skuId: id }));
  const toggleAccountModal = () => setShowAccountModal(!showAccountModal);

  return (
    <>
      <div className={cn('height100', styles.bgContainer)}>
        <Grid.Fluid classes="height100">
          <Grid.Row classes={cn('height100', styles.row)}>
            <Grid.Column sm={2} md={4} lg={6}>
              <div className="flex">
                <img src={HTLogo} className={styles.htLogo} alt="HelloTech Logo" />
                {
                  partnerImageUrl
                    ? <>
                        <span className={styles.divider} />
                        <img src={partnerImageUrl} className={styles.partnerLogo} alt="EV Logo" />
                      </>
                    : null
                }
              </div>
            </Grid.Column>
            <Grid.Column sm={2} md={4} lg={6}>
              <div className="flex justifyContentFlexEnd alignItemsCenter">
                <a
                  href={phone.link}
                  className={cn('flex alignItemsCenter -no-decoration', styles.phoneWrapper)}
                >
                  <PhoneIcon className={styles.phoneIcon} />
                  <span className={cn('p1', styles.phoneNumber)}>
                    {displayPhone(phone.title)}
                  </span>
                </a>
                <Icon
                  name="profile-outlined"
                  className={styles.profileIcon}
                  onClick={toggleAccountModal}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid.Fluid>
      </div>
      <EVAccountModal
        showAccountModal={showAccountModal}
        toggleAccountModal={toggleAccountModal}
      />
    </>
  );
};

export default EVPortalHeader;
