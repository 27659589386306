// Libraries
import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
// Utils
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
// External data
import { LOAD_PAGE } from 'src/constants/common';
import { BOOKING_STAGES } from 'src/containers/BookingPage/constants';
import { NEED_AT_LEAST_ONE_VALID_DATE, RECOMMEND_THREE_DATES } from 'src/constants/cart';
import { pageLoaded, setZipCodeRestriction } from 'src/actions/common';
import { selectRoutes } from 'src/apiRoutes';
import { availabilityLoaded } from 'src/components/AvailabilitySelector/actions';
import {
  getAvailableTimesApi,
  checkZipCodeRestriction,
} from 'src/components/AvailabilitySelector/utils';
import { updateCart } from 'src/containers/AddSkuPage/actions';
// Local Data
import {
  SUBMIT_AVAILABILITY,
  PAGE_NAME,
  GET_AVAILABLE_TIMES,
  SUBMIT_TIMES_AND_UPDATE_CART,
} from './constants';
import { availabilitySubmitted, toggleModal } from './actions';

/** API call to get available times from BE and load into Redux */
function* loadAvailableTimes({ numHoursToBuffer = 0, numDaysToBuffer = 0 }) {
  const availableDatesResponse = yield call(getAvailableTimesApi, {
    numHoursToBuffer,
    numDaysToBuffer,
  });
  if (!availableDatesResponse.err) {
    yield put(availabilityLoaded({ availability: availableDatesResponse.data }));
    return availableDatesResponse.data;
  }
  yield put(displayErrors(availableDatesResponse));
  return null;
}

/** Submit user selected times to BE and return response to the caller */
function* submitAvailabilityToAPI({
  selectedDateTimes,
  skipMinCount,
  manualCartToken,
  recurrence,
}) {
  const routes = yield call(selectRoutes);
  const response = yield call(routes.cart.goToPayment, {
    availability: selectedDateTimes,
    skip_min_count: skipMinCount,
    ...(manualCartToken && { token: manualCartToken }),
    ...(recurrence && { recurrence }),
  });
  return response;
}

/** Handle errors related to user submitted times */
function* handleCartAPIErrors(response) {
  const errors = response.data.errors || [];
  if (!errors.length) {
    return;
  }
  const errMsg = errors[0];
  if (errMsg && errMsg === NEED_AT_LEAST_ONE_VALID_DATE) {
    yield put(toggleModal({ modalName: 'invalidDates' }));
  } else if (errMsg === RECOMMEND_THREE_DATES) {
    yield put(toggleModal({ modalName: 'recommendedDates' }));
  } else {
    yield put(displayErrors(response));
  }
}

/** Runs on Cart Availability page load */
function* pageSaga({ numHoursToBuffer = 0, numDaysToBuffer = 0 }) {
  const availableDates = yield call(loadAvailableTimes, { numHoursToBuffer, numDaysToBuffer });
  if (availableDates) {
    yield put(pageLoaded('availability'));
  }

  const isOrderableZipResponse = yield call(checkZipCodeRestriction);
  if (!isOrderableZipResponse.err) {
    const {
      data: { isZipRestricted, orderableFromDate },
    } = isOrderableZipResponse;
    yield put(
      setZipCodeRestriction({
        isZipRestricted,
        orderableFromDate,
        page: PAGE_NAME,
      }),
    );
  }
}

/**
 * Call loadAvailableTimes() and then call onSuccess callback. pageSaga()
 * is specific to the Cart Availability page. This saga is more generic and
 * can be used in other places.
 */
function* getAvailableTimesSaga({ payload }) {
  const { onSuccess = () => {}, numHoursToBuffer = 0, numDaysToBuffer = 0 } = payload;
  const availableDates = yield call(loadAvailableTimes, { numHoursToBuffer, numDaysToBuffer });
  onSuccess(availableDates);
}

/**
 * Opinionated saga that submits user submitted times and then fires the availabilitySubmitted() action
 * Ideally this function and handleAvailabilitySubmission() would be combined into one, but I don't want
 * to refactor the cart flow right now. -GH Aug 11, 2023
 *
 * @param {import('./AvailabilityPage.types').SubmitAvailabilityActionArgs} args
 */
function* submitAvailabilitySaga({
  selectedDateTimes,
  skipMinCount,
  recurrence,
  manualCartToken, // Not sure where in the project this is being passed in, but leaving it for now
}) {
  const cart = yield select((state) => state.getIn(['entities', 'cart']));

  yield put(requestStarted());
  const response = yield call(submitAvailabilityToAPI, {
    selectedDateTimes,
    skipMinCount,
    manualCartToken,
    ...(recurrence && { recurrence }),
  });
  yield put(requestFinished());

  if (!response.err) {
    yield put(updateCart({ cart: response.data.cart }));
    yield put(
      availabilitySubmitted({
        id: cart.get('id'),
        updated: cart.get('status') !== 'availability',
        cart,
      }),
    );
  } else {
    yield call(handleCartAPIErrors, response);
  }
}

/** Unopinionated saga that submits user submitted times and then fires the onSuccess callback */
function* submitTimesAndUpdateCartSaga({ payload }) {
  const { onSuccess = () => {}, selectedDateTimes, skipMinCount, manualCartToken } = payload;

  yield put(requestStarted());
  const response = yield call(submitAvailabilityToAPI, {
    selectedDateTimes,
    skipMinCount,
    manualCartToken,
  });
  yield put(requestFinished());

  if (!response.err) {
    yield put(updateCart({ cart: response.data.cart }));
    onSuccess(response.data);
    return;
  }
  yield call(handleCartAPIErrors, response);
}

function* pageFlow() {
  yield takeLatest(
    (action) => action.type === LOAD_PAGE && action.page === BOOKING_STAGES.AVAILABILITY,
    pageSaga,
  );
  yield takeEvery(SUBMIT_AVAILABILITY, submitAvailabilitySaga);
  yield takeLatest(GET_AVAILABLE_TIMES, getAvailableTimesSaga);
  yield takeEvery(SUBMIT_TIMES_AND_UPDATE_CART, submitTimesAndUpdateCartSaga);
}

export default [pageFlow];
