import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { SearchProvider, SearchBox, WithSearch } from '@elastic/react-search-ui';
import { Layout } from '@elastic/react-search-ui-views';
import { useDispatch } from 'react-redux';

// Configs and Utils
import config from 'config';
import { searchClickSegment } from 'src/components/Searchbar/actions';

// Components
import InputField from 'src/components/HTKit/Forms/InputFieldV2';
import HomePageResults from 'src/components/Sections/HomePageHero/HeroSearchbar/HomepageSearchResults';
import Results from 'src/components/Header/TopNavHeader/Parts/SearchBar/Results';

// Helpers
import { getScoredResults } from './helpers';
import { searchConfig, DEFAULT_TEXTS } from './constants';

// Styles
import styles from './styles.scss';

const SearchInput = ({
  iconClick,
  isHomepageSearch,
  inputClass,
  iconName,
  placeholder,
  location,
  triggerName,
  autoFocus,
  ...props
}) => {
  const dispatch = useDispatch();
  dispatch(searchClickSegment({ locationName: location }));
  const QueryResults = (propAttrs) =>
    isHomepageSearch ? <HomePageResults {...propAttrs} /> : <Results {...propAttrs} />;

    return (
    <div className={cn(inputClass, styles.columnOverrides)}>
      <SearchProvider config={searchConfig(config.elasticSearch)}>
        <WithSearch mapContextToProps={(context) => context}>
          {(context) => {
            return (
              <Layout
                header={
                  <SearchBox
                    autocompleteMinimumCharacters={2}
                    autocompleteResults={{
                      title: 'name',
                      shouldTrackClickThrough: true,
                    }}
                    useAutocomplete
                    isOpen
                    allAutocompletedItemsCount
                    autocompleteView={({ autocompletedResults, getItemProps }) => {
                      const scoredResults = getScoredResults({ results: autocompletedResults });
                      const serviceResults = scoredResults.filter(
                        (r) => !r.is_product || r.is_product.raw === 'false',
                      );
                      const productResults = scoredResults.filter(
                        (r) => r.is_product && r.is_product.raw === 'true',
                      );

                      return (
                        <QueryResults
                          serviceResults={serviceResults}
                          productResults={productResults}
                          scoredResults={scoredResults}
                          iconClick={iconClick}
                          searchTerm={context.searchTerm}
                          shouldTrackClickThrough={true}
                          getItemProps={getItemProps}
                          {...props}
                        />
                      );
                    }}
                    inputView={({ getAutocomplete, getInputProps }) => {
                      const searchboxStyles = isHomepageSearch
                        ? styles.homepageSearchbox
                        : styles.searchBox;
                      return (
                        <div className={searchboxStyles}>
                          <InputField
                            iconName={iconName}
                            iconClass={styles.closeIcon}
                            iconOnClick={iconClick}
                            autoFocus={isHomepageSearch ? autoFocus : true}
                            {...props}
                            {...getInputProps({
                              placeholder,
                            })}
                          />
                          {getAutocomplete()}
                        </div>
                      );
                    }}
                  />
                }
              />
            );
          }}
        </WithSearch>
      </SearchProvider>
    </div>
  );
};

SearchInput.defaultProps = {
  placeholder: DEFAULT_TEXTS.PLACEHOLDER.heroSearchText,
  iconName: '',
};

SearchInput.propTypes = {
  iconClick: PropTypes.func,
  isHomepageSearch: PropTypes.bool,
  autoFocus: PropTypes.bool,
  inputClass: PropTypes.string,
  iconName: PropTypes.string,
  placeholder: PropTypes.string,
  location: PropTypes.string,
  triggerName: PropTypes.string,
};

export default SearchInput;

/*
  SearchInput- Input field that is used for search.
  Everything happens inside this component - Results, searchterm, apicalls.
  searchConfig is a function that takes in the apikeys and sets the configuration for the search.

  Usage:
  Clicking on the Icon will reveal the input.

  const [showSearchBar, setShowSearchBar] = useState(false);
  const searchClick = () => {
    setShowSearchBar(true);
  }
  <Icon name="search" onClick={searchClick} className={cn(styles.icon, className)} />
  {showSearchBar && (
    <SearchInput
      iconName="v2-close-icon"
      triggerName={someTriggerName}
      iconClick={() => onClose()}
      showSearchBar={showSearchBar}
      location="Top nav search" // location where the search input lives. For segment
      {...props}
    />
*/
