import { SCRIPT_TYPE } from './constants';

const makeBreadcrumbJsonLd = ({ position, name, link }) =>
  `{
    "@type": "ListItem",
    "position": "${position}",
    "name": "${name}",
    "item": "https://www.hellotech.com${link}"
  }`;

export const makeBreadcrumbsJsonLd = (items) => ({
  type: SCRIPT_TYPE,
  innerHTML: `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [${items.map((item, index) =>
    makeBreadcrumbJsonLd({ ...item, position: index + 1 }),
  )}]
}`,
});
