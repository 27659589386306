import { connect } from 'react-redux';
import {
  toggleHour,
  availabilityLoaded,
  removeExcessSelectedTime,
} from 'src/components/AvailabilitySelector/actions';
import { ComponentSelectors } from './selectors';
import AvailabilitySelectorWithConfig from './AvailabilitySelectorWithConfig';
import { AvailabilityTitleNote } from './Availability.Note';

export default connect(ComponentSelectors, {
  toggleHour,
  availabilityLoaded,
  removeExcessSelectedTime,
})(AvailabilitySelectorWithConfig);

export { AvailabilityTitleNote };
/*
  AvailabilitySelector
    Purpose: Render a scheduling selector
*/
