// Libraries
import React from 'react';
import PropTypes from 'prop-types';
// Components
import Icon from 'src/components/HTKit/Icon';
// Styles
import styles from './styles.scss';

const StageEditHeader = ({
  containerStyles,
  headerStyles = '',
  headerText,
  hideIcon,
  iconName,
  iconStyles,
  onClick,
}) => {
  return (
    <div className={containerStyles}>
      <h5 className={headerStyles}>{headerText}</h5>
      <a>{!hideIcon && <Icon name={iconName} className={iconStyles} onClick={onClick} />}</a>
    </div>
  );
};

StageEditHeader.propTypes = {
  containerStyles: PropTypes.string,
  headerStyles: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  hideIcon: PropTypes.bool,
  iconName: PropTypes.string,
  iconStyles: PropTypes.string,
  onClick: PropTypes.func,
};

StageEditHeader.defaultProps = {
  containerStyles: styles.stageEditHeader,
  headerStyles: '',
  headerText: '',
  hideIcon: false,
  iconName: 'v2-edit-icon',
  iconStyles: styles.stageEditIcon,
  onClick: () => {},
};

export default React.memo(StageEditHeader);

/*
  StageEditHeader
    - Header component for the StageEdit Component
*/
