import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CheckboxV2, CheckboxGroupV2 } from 'HTKit/Forms/CheckboxV2';
import { FORM_SIZE } from 'HTKit/Forms/constants';
import styles from './styles.scss';

const MembershipRenewal = ({
  showMembershipRenewal,
  subscription,
  willOptInValues,
  onOptInChange,
}) => {
  if (!showMembershipRenewal) return null;

  const membershipFee = `$${subscription.getIn(['currentPlan', 'amount'])}`;
  const renewsOnISO = subscription.getIn(['renewsOn', 'iso']);
  const renewalDate = moment(renewsOnISO).format('MMMM D, YYYY');

  return (
    <div className={styles.container}>
      <CheckboxGroupV2 value={willOptInValues} onChange={onOptInChange} size={FORM_SIZE.large}>
        <CheckboxV2 label="Use this card for Annual Membership Renewal" value="willOptIn" boldLabel>
          <p className="caption n700">
            To enjoy uninterrupted benefits, the{' '}
            <span className="text-weight-bold">{membershipFee} membership fee</span> will be
            processed on <span className="text-weight-bold">{renewalDate}.</span> You can change
            your renewal settings at any time.
          </p>
        </CheckboxV2>
      </CheckboxGroupV2>
    </div>
  );
};

MembershipRenewal.propTypes = {
  showMembershipRenewal: PropTypes.bool,
  subscription: PropTypes.object,
  willOptInValues: PropTypes.array,
  onOptInChange: PropTypes.func,
};

MembershipRenewal.defaultProps = {
  willOptInValues: [],
  onOptInChange: () => {},
};

export default memo(MembershipRenewal);
