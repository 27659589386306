/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import Grid from 'src/components/HTKit/Grid/Grid';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import styles from './styles.scss';

const DynamicHomePageBanner = () => {
  const { splitTreatment, splitConfig } = useSplitIOTreatment(splitioConstants.SPLITNAME_DYNAMIC_HOMEPAGE_BANNER);
  const {body, isExternalLink, link, linkText} = splitConfig || {};
  const linkAttributes = isExternalLink ? {target:"_blank", rel: "noopener noreferrer", to: link} : {to: link};

  if ([splitioConstants.OFF, splitioConstants.CONTROL].includes(splitTreatment) || !body) return null;

  const contentStyles = cn('p1', styles.content);
  const linkStyles = cn('p1', styles.link);

  return (
      <div className={styles.bannerWrapper}>
        <Grid.Fluid classes={styles.banner}>
          <Grid.Row>
            <Grid.Column md={6} mdOffset={1} lg={9} lgOffset={2}>
            <span className={contentStyles} dangerouslySetInnerHTML={{__html: body}} />
            <span>
              <Link {...linkAttributes} className={linkStyles}>{linkText}</Link>
            </span>
            </Grid.Column>
          </Grid.Row>
        </Grid.Fluid>
      </div>
  );
};

export default DynamicHomePageBanner;
