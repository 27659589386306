import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { isFunction } from 'lodash';
import { requestStarted, requestFinished, displayErrorsWithSnack } from 'src/utils/request';
import { selectRoutes } from 'src/apiRoutes';
import { LOAD_PAGE, NOTICE_TYPE } from 'src/constants/common';
import { clearFormErrors, pageNotice, clearPageNotices, pageLoaded } from 'src/actions/common';
import { setUpFormErrors } from 'src/sagas/common/form';
import { logger } from 'src/utils/logger';
import { SUBMIT_FORM, XFINITY_TECH_PAGE_NAME } from './constants';
import { formSubmitted } from './actions';

const errorLogger = logger(`${XFINITY_TECH_PAGE_NAME} sagas.js`);

export function* pageSaga() {
  yield put(pageLoaded(XFINITY_TECH_PAGE_NAME));
}

export function* submitFormSaga({ payload: { form, onSuccess } }) {
  const routes = yield call(selectRoutes);
  yield put(clearFormErrors(XFINITY_TECH_PAGE_NAME));
  yield put(clearPageNotices(XFINITY_TECH_PAGE_NAME));
  yield put(requestStarted());
  const response = yield call(routes.siteRequests.xfinity, { site_request: form });

  yield put(requestFinished());
  if (response.err) {
    const { status } = response.err.response;
    yield setUpFormErrors(XFINITY_TECH_PAGE_NAME, response);
    if (status !== 500) yield put(displayErrorsWithSnack(response));
    const formFields = JSON.stringify(form);
    const errMessage = response.data.errors
      ? `${response.data.errors.join(',')} - ${formFields}`
      : `${response.err} - ${formFields}`;
    errorLogger(errMessage);
  } else {
    const { message } = response.data;
    const notice = { type: NOTICE_TYPE.SUCCESS, name: 'general', message };
    yield put(pageNotice(XFINITY_TECH_PAGE_NAME, notice));
    yield put(formSubmitted({ formSubmitted: true }));
    if (isFunction(onSuccess)) onSuccess();
  }
}

export function* XfinityTechFlow() {
  yield takeEvery(
    (action) => action.type === LOAD_PAGE && action.page === XFINITY_TECH_PAGE_NAME,
    pageSaga,
  );
  yield takeLatest(SUBMIT_FORM, submitFormSaga);
}

export default [XfinityTechFlow];
