import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const MobileOrderSummary = ({ children, containerStyles }) => (
  <section className={containerStyles}>{children}</section>
);

MobileOrderSummary.propTypes = {
  children: PropTypes.element.isRequired,
  containerStyles: PropTypes.string,
};

MobileOrderSummary.defaultProps = {
  containerStyles: styles.mobileContainer,
};

export default React.memo(MobileOrderSummary);

// TODO: This is only being used for SubscriptionGifting and when that feature redone or removed this component should be removed. -GH June 22, 2020
