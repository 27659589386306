// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Components && Styles
import { Badge, BADGE_TYPES } from 'src/components/Elements/Badge';
import styles from '../styles.scss';

const OrderTotalV2 = ({
  containerStyles,
  descriptionStyles,
  priceStyles,
  totalFormatted,
  noBottomMargin,
  text,
  subText,
  badgeProps,
  badgeText,
}) => {
  const appliedContainerStyles = cn(styles.totalContainer, containerStyles, {
    [styles.noBottomMargin]: noBottomMargin,
  });
  const defaultBadgeProps = { variant: 'solid', size: 'small', type: BADGE_TYPES.CONFIRMED };
  const appliedBadgeprops = { ...defaultBadgeProps, ...badgeProps };
  return (
    <div
      className={cn(appliedContainerStyles, 'marginBottom-small1 paddingTop-small marginTop-tiny1')}
    >
      <div className={styles.totalRow}>
        <p className={cn('p0 n900', descriptionStyles)}>{text}</p>
        <div className={cn(styles.totalPrice, 'p0 n900', priceStyles)}>{totalFormatted}</div>
      </div>
      <div className={cn(styles.totalRow)}>
        <p className="caption n700">{subText}</p>
        {badgeText && <Badge {...appliedBadgeprops}>{badgeText}</Badge>}
      </div>
    </div>
  );
};

OrderTotalV2.propTypes = {
  containerStyles: PropTypes.string,
  descriptionStyles: PropTypes.string,
  priceStyles: PropTypes.string,
  totalFormatted: PropTypes.string,
  noBottomMargin: PropTypes.bool,
  text: PropTypes.string,
  subText: PropTypes.string,
  badgeProps: PropTypes.object,
  badgeText: PropTypes.string,
};

OrderTotalV2.defaultProps = {
  text: 'Due after Service',
  badgeProps: {},
  badgeType: BADGE_TYPES.CONFIRMED,
};

export default React.memo(OrderTotalV2);

/*
  - This is a base level component for rendering an order total. There is a design sheet in abstract
  TODO: Rename this once the v1 component is deleted

EXAMPLE USAGE
-------------
<OrderTotal
  totalFormatted="$12.49"
  subText="Small Text"
  badgeText="You Saved $60"
  badgeProps={{ variant: 'outlined', type: BADGE_TYPES.COMPLETED }}
/>
Notes:
  - totalFormatted: Price should be a string that is formatted into a $ amount before you send it to this component
  - badgeProps: An object of props that get merged with the default and applied to the <Badge> component
*/
