import { SET_ADDRESS, SUBMIT_ADDRESS, ADDRESS_SUBMITTED } from './constants';

export function setAddress(name, value) {
  return {
    type: SET_ADDRESS,
    name,
    value,
  };
}

export function submitAddress(payload) {
  return {
    type: SUBMIT_ADDRESS,
    payload,
  };
}

export function addressSubmitted({ updated = false }) {
  return {
    type: ADDRESS_SUBMITTED,
    updated,
  };
}
