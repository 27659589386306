import { fromJS } from 'immutable';
import { PAGE_LOADED } from '../../constants/common';

const initialState = null;

export default function reviewsReducer(state = initialState, action) {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        case 'siteFaq': {
          return fromJS(action.faqQuestions);
        }
        default:
          return state;
      }
    default:
      return state;
  }
}
