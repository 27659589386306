export const UPDATE_PROFILE = 'accountPage/UPDATE_PROFILE';
export const PREVENT_MEMBERSHIP_CANCELLATION = 'accountPage/PREVENT_MEMBERSHIP_CANCELLATION';

export const FORM_FIELDS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE: 'phone',
  ADDRESS: 'address',
  ADDRESS_INSTRUCTIONS: 'addressInstructions',
  CURRENT_PASSWORD: 'currentPassword',
  NEW_PASSWORD: 'password',
  NEW_PASSWORD_CONFIRMATION: 'passwordConfirmation',
};
