export const CARDS = {
  inHomeServices: {
    header: 'Need an in-home service?',
    details: false,
    showAdditionalContent: true,
  },
  remoteSupport: {
    header: '24/7 Remote Support',
    details:
      'Many services can be completed remotely and are included in your plan. Call, chat, or book online 24/7 for remote troubleshooting and support.',
    showAdditionalContent: true,
  },
  deviceManagement: {
    header: 'Device & Warranty Management',
    details:
      'If you’re having trouble with any of your devices, this is the place to start. You can find reference materials, warranty information and helpful device alerts. Stay on top of your devices and make sure your smart home is operating at peak performance.',
    showAdditionalContent: false,
  },
};

export const REMOTE_SUPPORT_SERVICES = [
  {
    label: 'Remote Support',
  },
];

export const INHOME_SERVICES_TABS = [
  { label: 'TV', iconName: 'v2-television', skuCategory: 'TV Mounting' },
  { label: 'Smart Home', iconName: 'v2-smart-home', skuCategory: 'Smart Home' },
  {
    label: 'Computer',
    iconName: 'v2-computer',
    skuCategory: 'Computers & Printers',
  },
  {
    label: 'Wifi & Network',
    iconName: 'v2-wifi',
    skuCategory: 'WiFi & Network',
  },
  {
    label: 'Audio/Video',
    iconName: 'v2-speaker',
    skuCategory: 'Audio & Video',
  },
  {
    label: 'Mobile & Tablet',
    iconName: 'v2-mobile',
    skuCategory: 'Mobile Devices',
  },
];

export const REDEEMABLE_SERVICES = {
  MALWAREBYTES: 'malwarebytes',
  DASHLANE: 'dashlane',
  AUDIT: 'audit',
};

export const REDEEMABLE_SERVICE_MODALS = {
  [REDEEMABLE_SERVICES.MALWAREBYTES]: 'malwarebytesModal',
  [REDEEMABLE_SERVICES.DASHLANE]: 'dashlaneModal',
};

export const SUBSCRIPTION_CANCELLATION = {
  modalName: 'subscriptionCancellationModal',
};

export const URL_PATH_ACTIONS = { CANCEL: 'cancel' };

export const PREVENT_MEMBERSHIP_CANCELLATION = { modalName: 'preventCancellationModal' };
