export const SCHEDULE_TYPES = {
  /** Scheduling requires an exact time (single time slot) */
  EXACT_TIME: 'exact_time',
  /** Scheduling requires a time window (block of hours) */
  TIME_WINDOW: 'time_window',
};

/** Specifies the exact time type increment  */
export const EXACT_TIME_TYPES = {
  HOURLY: 'hourly',
  HALF_HOURLY: 'half_hourly',
};

export const SCHEDULER_VIEW_TYPES = {
  EXACT_TIME_SELECT: 'exact_time_select',
  TIME_WINDOW_SELECT: 'time_window_select',
  TIME_WINDOW_GRID: 'time_window_grid',
};

export const SCHEDULING_ACTIONS = {
  NEW_ORDER: 'new_order',
  RESCHEDULE_ORDER: 'reschedule_order',
};
