import { fromJS } from 'immutable';
import { WORKFLOW_DATA_LOADED } from '../../constants/workflow';

const workflowReducer = (state = fromJS(null), action) => {

  switch (action.type) {
    case WORKFLOW_DATA_LOADED: {
      const { data } = action;
      return fromJS(data);
    }
    default:
      return state;
  }
};

export default workflowReducer;
