import { createSelector } from 'reselect';

const componentStateSelector = (name) => (state) => state.getIn(['components', name]);
const componentSelector = (name) =>
  createSelector(
    componentStateSelector(name),
    (component) => component,
  );

export default componentSelector;
