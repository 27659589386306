import React from 'react';
import { Grid } from 'src/components/HTKit/Grid';
import styles from './styles.scss';
import { AFFIRM_LOGO } from './constants';

const AffirmPaymentChoice = () => {
  return (
    <Grid.Fluid>
      <Grid.Row>
        <Grid.Column md={6} mdOffset={1} lg={8} lgOffset={2}>
          <div className={styles.affirm_payment_choice}>
            <img src={AFFIRM_LOGO} />
            <p className={styles.note}>
              Let’s review your order and you’ll complete your Affirm Checkout in the next step
            </p>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid.Fluid>
  )
}

export default AffirmPaymentChoice;
