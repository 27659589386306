import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Grid from 'src/components/HTKit/Grid/Grid';
import { PRODUCT_CARD_ID, getSimplisafeSkus } from 'src/containers/SimpliSafeLandingPage/constants';
import { PanelGroup } from 'src/components/HTKit/Elements/Panel';
import { push } from 'src/utils/paths';
import { connect } from 'react-redux';
import ProductCardItem from 'src/components/Elements/ProductCard';
import { isMobileV2 } from 'src/utils/ui';
import ModalContents from 'src/components/SkuDetailsFullModal/ModalContents';
import priceFormatter from '../../helpers';
import styles from './styles.scss';

const ProductCards = (props) => {
  const { skus } = props;
  const productCardsStyles = cn('h4', styles.productCardsTitle);
  const subTitleStyles = cn("p1", styles.subTitleMobile);
  /*  Passing thru our unique onclick for simplisafe for cta */
  const bookNowButtonBehavior = useCallback(({ id }) => () => {
    props.push(`/cart/add-sku/${id}`);
  }, []);
  const isMobile = isMobileV2();
  const [isOpen, setIsOpen] = useState(false);
  const [currentSku, setCurrentSku] = useState(null);

  const handleClick = (sku) => {
    setIsOpen(!isOpen);
    setCurrentSku(sku);
  }

  return (
    <>
      <PanelGroup>
        <Grid.FullWidth>
          <span id={PRODUCT_CARD_ID} className={styles.productCardContainerAnchor}>.</span>
          <h4 className={productCardsStyles}>Select a Service</h4>
          <Grid.Row>
            {skus &&
              skus.map((sku, index) => {
                const getImage = getSimplisafeSkus(sku);
                const image = isMobile ? getImage && getImage.mobileImage : getImage && getImage.image;

                return (
                  <Grid.Column sm={4} md={4} lg={3} key={index}>
                    <ProductCardItem
                      classes={styles.card}
                      cheapestPrice={priceFormatter(sku)}
                      image={image}
                      name={sku.name}
                      ratingAttributes={sku.ratingAttributes}
                      rating={sku.ratingAttributes.rating}
                      ratingCount={sku.ratingAttributes.ratingCount}
                      linkText="See Details"
                      buttonText="Book Now"
                      onButtonClick={bookNowButtonBehavior(sku)}
                      onClick={() => handleClick(sku)}
                      onLinkClick={() => handleClick(sku)}
                      {...props}
                    />
                  </Grid.Column>
                );
              })
            }
          </Grid.Row>
        </Grid.FullWidth>
      </PanelGroup>
      <Grid.Fluid>
        <Grid.Row>
          <p className={subTitleStyles}>
            As a nationwide service provider of SimpliSafe products,
            HelloTech provides customers with expert, in-home setup
            and device support for your home security system.
          </p>
        </Grid.Row>
      </Grid.Fluid>
      <ModalContents
        open={isOpen}
        handleClose={() => handleClick(currentSku)}
        name={currentSku && currentSku.name}
        ratingAttributes={currentSku && currentSku.ratingAttributes}
        rating={currentSku && currentSku.ratingAttributes.rating}
        ratingCount={currentSku && currentSku.ratingAttributes.ratingCount}
        cheapestPrice={priceFormatter(currentSku)}
        onClick={currentSku && bookNowButtonBehavior(currentSku)}
        skuBullets={currentSku && currentSku.skuBullets}
      />
    </>
  );
};

ProductCards.propTypes = {
  skus: PropTypes.array,
  push: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    push: (...args) => dispatch(push(...args)),
  };
}

export default connect(
  () => ({}),
  mapDispatchToProps,
)(ProductCards);
