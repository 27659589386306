import { ENV } from 'src/constants/app';
import { splitioConstants } from 'src/components/SplitIO';

// These match partner_landings.seo_name in the DB
export const IFRAME_LANDINGPAGE_SEONAMES = {
  GEEK_SQUAD: 'geek-squad-vs-hellotech',
  INSTALL_SETUP: 'install-setup',
};

// Given a landing page SEO name return the name of a split to load
export const LANDINGPAGE_SPLIT_MAPPINGS = {
  [IFRAME_LANDINGPAGE_SEONAMES.GEEK_SQUAD]:
    splitioConstants.SPLITIONAME_IFRAME_LANDING_PAGE_GEEKSQUADHT,
  [IFRAME_LANDINGPAGE_SEONAMES.INSTALL_SETUP]:
    splitioConstants.SPLITIONAME_IFRAME_LANDING_PAGE_INSTALLSETUP,
};

const baseHost = process.env.NODE_ENV === ENV.PRODUCTION ? 'content' : 'contents';
const baseRedirectPath = `https://${baseHost}.hellotech.com`;

export const LANDINGPAGE_IFRAME_CONTAINER_ID = 'IframeContainerLandingPage'; // This gets applied to the container surrounding the iframe
export const LANDINGPAGE_IFRAME_PAGE_ID = 'IframePageLandingPage'; // This gets applied directly to iframe

export const getIframeSource = (src) => {
  // This is similar the IFRAME function below, but instead of generating the source string and then returning an iframe tag, we just generate the source string
  if (!src) return '';
  return `${baseRedirectPath}${src}`;
};

/**
 * This tells us what messaging group to listen for, else we get all messages across the iframe(s)/sockets.
 * @type {string}
 */
