import { REGISTRATION_REQUEST, USER_SIGNED_UP } from './constants';
import { isImmutable } from 'src/utils/helpers';

export function registrationRequest(attributes) {
  return {
    type: REGISTRATION_REQUEST,
    payload: attributes,
  };
}

export function userSignedUp({ user, attributes }) {
  const usr = isImmutable(user) ? user.toJS() : user || {};
  const attr = isImmutable(attributes) ? attributes.toJS() : attributes || {};

  return {
    type: USER_SIGNED_UP,
    meta: {
      analytics: [
        {
          eventType: 'identify',
          eventPayload: {
            userId: usr.id,
            traits: {
              email: attr.email,
              first_name: attr.firstName,
              last_name: attr.lastName,
              id: usr.id,
            },
          },
        },
      ],
    },
  };
}
