import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Helmet from 'react-helmet';
import { RULES } from 'src/utils/validation';
import Button, { THEMES } from 'HTKit/Forms/Button';
import InputFieldV2 from 'HTKit/Forms/InputFieldV2';
import { Grid } from 'HTKit/Grid';
import Form from 'HTKit/Forms/Form';
import { validateForm } from 'src/actions/common';
import { updateProfile } from 'src/containers/Account/AccountPage/actions';
import { startBooking } from 'src/containers/CartPage/actions';
import { cartSelector, orderIsFromApiSelector } from 'src/selectors/cart';
import { pureUserSelector } from 'src/selectors/user';
import { INJECTED_USER_PHONE } from '../constants';

const VerificationForm = () => {
  const dispatch = useDispatch();
  const cart = useSelector(cartSelector);
  const user = useSelector(pureUserSelector);
  const userPhone = user.phone === INJECTED_USER_PHONE ? undefined : user.phone;
  const isApiOrder = useSelector(orderIsFromApiSelector);

  const initialFormState = {
    form: {
      firstName: user.firstName,
      lastName: user.lastName,
      phone: userPhone,
      email: user.email,
    },
    errors: null,
  };
  const [formState, setFormState] = useState(initialFormState);
  const [buttonText, setbuttonText] = useState('Continue');

  const handleFieldChange = (field) => (e) => {
    setbuttonText('Update');
    e.persist();

    setFormState((prevForm) => {
      return {
        form: { ...prevForm.form, [field]: e.target.value },
      };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { form } = formState;
    form.phone = form.phone?.replace(/\D+/g, '');
    const rules = {
      firstName: RULES.required,
      lastName: RULES.required,
      email: RULES.email,
      phone: RULES.phone,
    };

    dispatch(
      validateForm(
        { form, rules, name: 'verification' },
        {
          onSuccess: () => {
            dispatch(
              updateProfile(form, null, {
                onSuccess: () => dispatch(startBooking(cart, { stage: cart.status })),
              }),
            );
          },
          onError: (e) => {
            setFormState({ form: { ...formState.form }, errors: e.messages });
          },
        },
      ),
    );
  };

  const { form } = formState;

  /**
   * API orders skip this verification form. In the traditional password auth flow, API order users
   * are prompted to update their contact information and create a password in the
   * <FinishOrder /> component, making this page redundant.
   *
   * If it's an API order, we continue with the normal cart flow.
   */
  useEffect(() => {
    if (isApiOrder) {
      dispatch(startBooking(cart, { stage: cart.status }));
    }
  }, [isApiOrder]);

  /* Reduce flicker: prevent brief render of form for API orders */
  if (isApiOrder) return null;

  return (
    <div className="paddingY-medium1">
      <Helmet title="Customer Verification" />

      <Grid.Fluid>
        <Grid.Row>
          <Grid.Column>
            <Form onSubmit={handleSubmit}>
              <h1 className="h3 paddingBottom-small1 text-align-center">
                Let&apos;s Verify Your Information
              </h1>

              <Form.Row>
                <Form.Column sm={2} md={4} lg={6}>
                  <InputFieldV2
                    type="text"
                    label="First Name"
                    error={formState.errors && formState.errors.firstName}
                    value={form.firstName}
                    onChange={handleFieldChange('firstName')}
                    data-hj-whitelist
                  />
                </Form.Column>
                <Form.Column sm={2} md={4} lg={6}>
                  <InputFieldV2
                    type="text"
                    label="Last Name"
                    error={formState.errors && formState.errors.lastName}
                    value={form.lastName}
                    onChange={handleFieldChange('lastName')}
                    data-hj-whitelist
                  />
                </Form.Column>
              </Form.Row>

              <Form.Row>
                <Form.Column>
                  <InputFieldV2
                    type="tel"
                    label="Phone Number"
                    mask="phone"
                    placeholder="Please enter a valid number"
                    value={form.phone}
                    keepCharPositions
                    error={formState.errors && formState.errors.phone}
                    onChange={handleFieldChange('phone')}
                  />
                </Form.Column>
              </Form.Row>

              <Form.Row>
                <Form.Column>
                  <InputFieldV2
                    type="text"
                    label="Email"
                    value={form.email}
                    error={formState.errors && formState.errors.email}
                    onChange={handleFieldChange('email')}
                  />
                </Form.Column>
              </Form.Row>

              <Form.Row>
                <Form.Column>
                  <Button mediumV2 theme={THEMES.V2PRIMARY}>
                    {buttonText}
                  </Button>
                </Form.Column>
              </Form.Row>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid.Fluid>
    </div>
  );
};

export default VerificationForm;
