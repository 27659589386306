import { useDispatch } from 'react-redux';
import {
  fetchPlansForMonthlyUpsell,
  addPlanToCart,
  togglePlanModal,
} from 'src/containers/SkuPage/actions';
import { bookNow } from 'src/components/BookNowButton/actions';

export const createUpsellModalUtils = ({ isPlanPricingTest, upsellPlanId, skuId }) => {
  const dispatch = useDispatch();

  const fetchPlanAndOpenModal = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(
      fetchPlansForMonthlyUpsell({
        isPlanPricingTest,
      }),
    );
  };

  const addPlan = (e) => {
    e.preventDefault();
    dispatch(addPlanToCart({ planId: upsellPlanId, skuId }));
  };

  const closePlanModal = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(togglePlanModal({ modalState: false }));
  };

  const startBooking = () => dispatch(bookNow(skuId));

  return { fetchPlanAndOpenModal, addPlan, closePlanModal, startBooking };
};
