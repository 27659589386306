import React from 'react';
import PropTypes from 'prop-types';
// Utils
import getPlanBenefitsListAndValue from 'src/components/SubscriptionBenefitsBreakdown/utils';
import { formatSubscriptionName } from 'src/utils/plans';
// Components
import OrderBreakdownLineItem from 'src/components/OrderSummary/Breakdown/OrderBreakdownLineItem';
import SubscriptionBenefitsLineItem from 'src/components/OrderSummary/Breakdown/SubscriptionBenefitsLineItem';
import ServiceImage from 'src/components/OrderSummary/Breakdown/ServiceImage';
import shpImage from 'src/images/shp-cart-thumbnail.png';
import trialPlanThumbnail from 'src/images/subscription-trial-thumbnail.png';
import styles from './styles.scss';

/**
 * @param {{amount: number, isTrial: boolean}} param0
 * @returns {string}
 */
const getAmountText = ({ amount, isTrial }) => (isTrial ? 'FREE' : `$${amount}`);

const PlanItem = ({ amount, planDetails, removePlan, canRemovePlan, plan = {} }) => {
  const planTrialData = plan.trial;
  const { benefitsList } = getPlanBenefitsListAndValue({ plan: planDetails });
  const planNameText = formatSubscriptionName(plan);
  const amountText = getAmountText({ amount, isTrial: Boolean(plan.trial) });
  const image = planTrialData ? trialPlanThumbnail : shpImage;

  // Check to see if the paintedDoor sku ids are present in cart && split is turned on

  return (
    <div className={styles.itemContainer}>
      <ServiceImage image={image} imageClasses={styles.shpImage} />
      <div className={styles.itemSection}>
        <OrderBreakdownLineItem
          name={planNameText}
          amount={amountText}
          className="marginBottom-small"
          boldName
          boldAmount
        />
        {benefitsList.length > 0 &&
          benefitsList.map((benefit, i) => {
            return (
              <SubscriptionBenefitsLineItem
                key={`benefits-${i}`}
                name={benefit.name}
                amount={benefit.cost}
                lineThroughText={benefit.value}
                className="marginBottom-small"
              />
            );
          })}
        {canRemovePlan && (
          <button className="plainButton" onClick={removePlan}>
            <p className="p1 text-link">Remove</p>
          </button>
        )}
      </div>
    </div>
  );
};

PlanItem.propTypes = {
  name: PropTypes.string,
  amount: PropTypes.string,
  planDetails: PropTypes.object,
  removePlan: PropTypes.func,
  selectedPlanPricingInterval: PropTypes.string,
  canRemovePlan: PropTypes.bool,
  plan: PropTypes.object,
};

export default PlanItem;
