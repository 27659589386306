// COMCAST/XFINITY Redirects: https://hellotech.atlassian.net/wiki/spaces/ENGINEERIN/pages/1169883159/Handling+Comcast+Xfinity+redirects+from+the+BE
export const COMCAST_BUSINESS_REDIRECT_SLUGS = Object.freeze([
  '/sbp',
  '/sbe',
  '/sbsp',
  '/sbse',
  '/comcastbusiness',
]);
export const XFINITY_REDIRECT_SLUGS = Object.freeze([
  '/htx',
  '/xtr',
  '/xte',
  '/xtm',
  '/wxh',
  '/wwx',
  '/hxm',
  '/landings/xfinity',
]);

export const XFINITY_VANITY_URLS = Object.freeze([
  '/xfinity',
  '/xfinity-web',
  '/xfinity-email',
  '/xfinity-box',
  '/xfinity-app',
  '/xfinity-retail',
  '/xfinity-sales',
  '/xfinity-care',
  '/xfinity-courtesy',
  '/xfinity-movers',
]);

export const COMCAST_PHONE_NUMBERS = {
  XFINITY: '18007536139',
};
