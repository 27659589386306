import '@babel/polyfill';
import 'intersection-observer';
import 'whatwg-fetch';
import Bugsnag from 'src/utils/Bugsnag';
import React from 'react';
import ReactDOM from 'react-dom';
import { fromJS } from 'immutable';
import { createBrowserHistory } from 'history';
import smoothscroll from 'smoothscroll-polyfill';
import './styles/vendor.main.scss';
import { newRouteSchema } from './actions/common';
import addSagas from './sagas/index';
import { getSkuRoutes } from './utils/routeSchema';
import Root from './Root';
import createReducer, { createReducerWrapper } from './reducers/index';
import ErrorRoot from './ErrorRoot';
import configureStore from './store';
import { isMobile, isTablet } from './utils/ui';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const registration of registrations) {
      registration.unregister();
    }
  });
  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      }),
    );
  });
}

Bugsnag.init();

async function loadPolyfills() {
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
  if (process.browser) {
    smoothscroll.polyfill();
  }
}

loadPolyfills();

const history = createBrowserHistory();

// this needs to be declared outside of the if due to scope issues for minified files
const finishAppInit = (appStore, appHistory) => {
  addSagas(appStore);
  ReactDOM.render(
    <Root store={appStore} history={appHistory} context={{}} />,
    document.getElementById('app'),
  );
};

const htAppError = window.HT_APP_ERROR;

if (htAppError) {
  ReactDOM.render(
    <ErrorRoot code={htAppError.code} popularSkus={fromJS(htAppError.popularSkus)} />,
    document.getElementById('app'),
  );
} else {
  const initialState = window.APP_STATE || {};
  initialState.isMobile = isMobile();
  initialState.isTablet = isTablet();

  const store = configureStore(initialState, history, createReducer, createReducerWrapper);
  Bugsnag.setStore(store);

  if (window.APP_STATE) {
    finishAppInit(store, history);
  } else {
    getSkuRoutes().then((skuRoutesResponse) => {
      if (skuRoutesResponse.data) {
        store.dispatch(newRouteSchema(skuRoutesResponse.data.routes));
        finishAppInit(store, history);
      } else {
        window.alert('Uh-oh, something went wrong. Try that again'); // eslint-disable-line
      }
    });
  }
}
