import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../HTKit/Icon';

export const Placeholder = ({ children, ...props }) => (
  <div {...props}>
    <Icon name="calendar" className="marginRight-tiny1" />
    {children}
  </div>
);

Placeholder.propTypes = {
  children: PropTypes.node,
};
