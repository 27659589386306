import { PROMO_BAR_PRIMARY_LINK_CLICKED } from './constants';

export function bannerClicked(msg = '') {
  return {
    type: PROMO_BAR_PRIMARY_LINK_CLICKED,
    meta: {
      analytics: [{
        eventType: 'track',
        eventPayload: {
          event: 'Promotion Clicked',
          properties: {
            promo_message: msg
          }
        }
      }]
    }
  };
}

