import React from 'react';
import PropTypes from 'prop-types';
import TriangleDiscountImage from './parts/TriangleDiscountImage/index';
import TriangleDiscountBanner from './parts/TriangleDiscountBanner/index';

const TriangleDiscount = ({ raw, ...rest }) => {
  return raw ? <TriangleDiscountBanner {...rest} /> : <TriangleDiscountImage />;
};

TriangleDiscount.propTypes = {
  raw: PropTypes.bool,
  value: PropTypes.any,
  type: PropTypes.string,
  name: PropTypes.string,
  info: PropTypes.string,
};

export default TriangleDiscount;
