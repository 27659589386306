import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ROUTES from 'src/routes';
import styles from './styles.scss';

const FirstServiceDiscountDisclaimer = ({planName = 'HelloTech Home'}) => (
  <div className={styles.firstServiceDiscountDisclaimer}>
    <div className={cn('p1', styles.firstServiceDiscountDisclaimerInfo)}>
      Save even more with <strong>{planName}</strong>!<br />
      $69 for all in-home services.{' '}
      <Link to={ROUTES.PLANS} className="text-weight-bold">
        Learn More
      </Link>
    </div>
    <div className={cn('caption n700', styles.firstServiceDiscountDisclaimerInfo)}>
      *Valid for new customers only. By signing up, you’ll be the first to receive exclusive offers
      and important announcements.
      <br />
      <Link to={ROUTES.PRIVACY}>Privacy Policy</Link>
    </div>
  </div>
);

FirstServiceDiscountDisclaimer.propTypes = {
  planName: PropTypes.string,
}

export default React.memo(FirstServiceDiscountDisclaimer);
