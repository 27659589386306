import { createSelector } from 'reselect';
import { isImmutable } from 'src/utils/helpers';

const workflowStateSelector = (state) => state.getIn(['entities', 'workflow']);
const workflowCartStateSelector = (state) => state.getIn(['entities', 'cart', 'workflow']);

/**
 * This Gets The Workflow from the workflow root (entities/workflow) and is pure js
 * @type {OutputSelector<unknown, unknown, (res: *) => unknown>}
 */
export const pureWorkflowJSSelector = createSelector(workflowStateSelector, (workflow) => {
  if (!workflow) return null;
  return workflow.toJS();
});

/**
 * This Gets The Partner from the workflow root (entities/workflow) and is pure js
 * @type {OutputSelector<unknown, unknown, (res: *) => unknown>}
 */
export const pureWorkflowStatePartnerJSSelector = createSelector(workflowStateSelector, (workflow) => {
  if (!workflow) return null;
  const partner = workflow.getIn(['partner'], null);

  if (!partner) return null;
  return partner.toJS();
});

/**
 * This Gets The Sku from the workflow root (entities/workflow) and is pure js
 * NOTE: Sku here is not returned by the api. It is merged in the workflow reducer for convenience.
 * @type {OutputSelector<unknown, unknown, (res: *) => unknown>}
 */
export const pureWorkflowStateSkuJSSelector = createSelector(workflowStateSelector, (workflow) => {
  if (!workflow) return null;
  const sku = workflow.getIn(['sku'], null);

  if (!sku) return null;
  return sku.toJS();
});

/**
 * Workflow in various flows is either its own entity or part of the cart entity.
 * This selector will return the workflow from either the workflow or cart workflow.
 *
 * @type {OutputSelector<unknown, unknown, (res1: *, res2: *) => unknown>}
 */
export const pureWorkflowAllStateJSSelector = createSelector([workflowStateSelector, workflowCartStateSelector], (workflow, cartWorkflow) => {
  if (!workflow && !cartWorkflow) return null;

  const wf = [workflow, cartWorkflow].find((w) => w);

  return isImmutable(wf) ? wf.toJS() : wf;
});
