import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.scss';

const ServicePriceBranded = ({ service, sm, brandLogo, showFixedPrice = false }) => {
  const baseStyles = cn({ p0: !sm, p1: sm });

  return (
    <>
      <span className={styles.brand}>
        <img src={brandLogo} alt="Brand Logo" className={styles.brandLogo} />
        <p className="caption">Member&apos;s price</p>
      </span>
      <span className={baseStyles}>
        {!showFixedPrice && 'Starting at'}{' '}
        <span className="text-weight-bold">{service.extra.prices.memberPrice}</span>
      </span>
    </>
  );
};

ServicePriceBranded.propTypes = {
  service: PropTypes.shape({
    extra: PropTypes.shape({
      prices: PropTypes.shape({
        memberPrice: PropTypes.string,
      }),
    }),
  }),
  sm: PropTypes.bool,
  brandLogo: PropTypes.string,
  showFixedPrice: PropTypes.bool,
};

export default memo(ServicePriceBranded);
