import { fromJS } from 'immutable';
import { AVAILABILITY_LOADED, TOGGLE_HOUR_BY_INDEX } from './constants';

// =========================================
// Utility Functions
// =========================================

/**
 * Creates an index map for quick lookup of hours based on dateTime.
 * @param {Array} availableTimeSlots - The array containing the availability data.
 * @returns {Object} - An index map with dateTime as keys and { dateIndex, hourIndex, valid } as values.
 */
const createAvailabilityByDateTimeMap = (availableTimeSlots) => {
  const dateTimeMap = {};

  availableTimeSlots.forEach((dateData, dateIndex) => {
    dateData.hours.forEach((hourData, hourIndex) => {
      const { dateTime, valid } = hourData;
      dateTimeMap[dateTime] = { dateIndex, hourIndex, valid };
    });
  });

  return dateTimeMap;
};

// =========================================
// Reducers
// =========================================

export const availabilityByDateTimeReducer = (state = {}, action) => {
  switch (action.type) {
    case AVAILABILITY_LOADED:
      return action.availability.length === 0
        ? {}
        : createAvailabilityByDateTimeMap(action.availability);
    default:
      return state;
  }
};

export function availabilityReducer(state = fromJS([]), action) {
  switch (action.type) {
    case AVAILABILITY_LOADED: {
      if (action.availability.length === 0) {
        return state.clear();
      }
      return fromJS(action.availability);
    }

    case TOGGLE_HOUR_BY_INDEX: {
      const { dateIndex, hourIndex, checked } = action;
      return state.updateIn([dateIndex, 'hours', hourIndex, 'checked'], () => checked);
    }

    default:
      return state;
  }
}
