import { ENV } from 'src/constants/app';

const contentSubhost = process.env.NODE_ENV === ENV.PRODUCTION ? 'content' : 'contents';

export const contentBasehost = `https://${contentSubhost}.hellotech.com`;

/**
 * Lets us know if we are on the server (non-window)
 * @returns {boolean}
 */
export const isBrowser = () => typeof window !== 'undefined';
