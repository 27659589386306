export default Object.freeze({
  HOME: '/',
  CART: '/cart',
  TECH_SUPPORT: '/tech-support/',
  ADD_SKU: '/cart/add-sku/',
  RECOMMEND: '/cart/recommend',
  GEEK_SQUAD: '/geek-squad-isnt-best',
  MICROSOFT_SUPPORT: '/microsoft-support',
  MICROSOFT_WINDOWS_SUPPORT: '/microsoft-windows-support',
  CLIENTS_REGISTRATION: '/clients/register',
  LOGIN: '/login',
  ORDER_CONFIRMATION: '/clients/orders/confirmation/',
  SUBSCRIPTION_CONFIRMATION: '/clients/subscription/confirmation',
  SUBSCRIPTION_GIFT: '/subscription-gift',
  BOOKING: '/cart/booking/',
  BOOKING_PALO_ALTO_NETWORKS: '/cart/palo-alto-networks-booking',
  CANCELLATION_POLICY: '/cancellation-policy',
  PLANS: '/plans',
  TOS: '/tos',
  PRIVACY: '/privacy',
  SIGN: '/sign',
  CREATE_ACCOUNT: '/create-account',
  RESET_PASSWORD: '/clients/reset-password',
  DIRECT_BOOKING: '/schedule',
});
