import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Range } from 'immutable';
import { push, pushOnClick, skuPath } from 'src/utils/paths';
import ReviewCount from '../../../components/HTKit/ReviewCount';
import styles from '../styles.scss';

export class SkuCategoryItems extends Component {
  static propTypes = {
    skuCategory: PropTypes.object,
    push: PropTypes.func.isRequired,
    isDesktop: PropTypes.bool,
    onItemClick: PropTypes.func,
    itemLink: PropTypes.func,
  };

  static defaultProps = {
    isDesktop: false,
    onItemClick: null,
    itemLink: null,
  };

  constructor(props, context) {
    super(props, context);
    this.renderSkuItem = ::this.renderSkuItem;
    this.renderDesktopList = ::this.renderDesktopList;
    this.pushOnClick = pushOnClick(this.props.push);
  }

  splitIntoChunks(list, chunkSize = 1) {
    // see http://stackoverflow.com/a/38765329
    return Range(0, list.count(), chunkSize)
      .map((chunkStart) => list.slice(chunkStart, chunkStart + chunkSize));
  }

  renderSkuItem(sku, keyStr) {
    const onItemClick = this.props.onItemClick ? this.props.onItemClick(sku) : this.pushOnClick(skuPath(sku.get('seoName')));
    const itemLink = this.props.itemLink ? this.props.itemLink(sku) : skuPath(sku.get('seoName'));
    return (
      <a href={itemLink} onClick={onItemClick}>
        <div className={`l-box ${styles.skuItem}`}>
          <p className={styles.title}>{ sku.get('name') }</p>
          <ReviewCount
            reviews={sku.getIn(['ratingAttributes', 'ratingCount'])}
            stars={sku.getIn(['ratingAttributes', 'rating'])}
            keyStr={keyStr}
          />
          <p className={styles.startsAt}>Starts at only:</p>
          <p className={styles.price}>
            {sku.get('startsAtPrice')}
          </p>
        </div>
      </a>
    );
  }

  // desktop - sku list
  renderDesktopList() {
    const { skuCategory } = this.props;
    return (
      <div className={styles.desktopSkuList}>
        { skuCategory.get('skus').map((sku, index) =>
          <div key={index}>
            {this.renderSkuItem(sku, 'skucategroy-desktop')}
          </div>
        )}
      </div>
    );
  }

  // mobile/tablet - sku list
  renderTabletMobileList() {
    const { skuCategory } = this.props;
    return (
      <div className={styles.mobileSkuList}>
        { this.splitIntoChunks(skuCategory.get('skus'), 2).map((row, outerIndex) =>
          <div key={outerIndex} className={styles.skuRow}>
            { row.count() === 2 && row.map((sku, innerIndex) =>
              <div key={innerIndex} className={innerIndex % 2 === 0 ? styles.leftWrapper : styles.rightWrapper}>
                {this.renderSkuItem(sku, 'skucategroy-mobile')}
              </div>
            )}
            { row.count() === 1 && row.map((sku, innerIndex) =>
              <div key={innerIndex} className={innerIndex % 2 === 0 ? styles.leftWrapper : styles.rightWrapper}>
                {this.renderSkuItem(sku, 'skucategroy-mobile')}
              </div>
            )}
            { row.count() === 1 && (
              <div className={`${styles.rightWrapper}`}></div>
            )}
          </div>
        )}
      </div>
    );
  }

  render() {
    return this.props.isDesktop ? this.renderDesktopList() : this.renderTabletMobileList();
  }
}

export default connect(() => ({}), { push })(SkuCategoryItems);
