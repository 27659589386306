/* eslint-disable import/prefer-default-export */

// Transparent 1x1 pixel
export const TRANSPARENT_PIXEL =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

// MIME types
export const TYPES = {
  WEBP: 'image/webp',
  JPG: 'image/jpeg',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  SVG: 'image/svg+xml',
};
