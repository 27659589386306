import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'src/components/HTKit/Icon';
import { FORM_SIZE } from '../constants';
import styles from './styles.scss';

/**
  Box - The box that holds the checkmark
*/
const Box = ({ checked = false }) => {
  const boxStyles = cn(styles.box, {
    [styles.boxChecked]: checked,
  });

  return (
    <div className={boxStyles}>
      {checked && <Icon name="checkmark-v2" className={styles.icon} />}
    </div>
  );
};

Box.propTypes = { checked: PropTypes.bool };

/**
  CheckboxV2 - Holds the checkmark and label

  The actual <input /> is hidden so we can customize the look of the
  checkmark box.
*/
export const CheckboxV2 = ({
  children,
  label,
  checked,
  value: cbValue,
  disabled,
  onCheckboxChange,
  wrapperClass = '',
  labelClass = '',
  size = FORM_SIZE.medium,
  boldLabel = false,
  ...rest
}) => {
  const labelWrapperStyles = cn(styles.labelWrapper, styles[size], wrapperClass, {
    [styles.disabled]: disabled,
  });

  const labelStyles = cn(styles.label, labelClass, {
    [styles.bold]: boldLabel,
  });

  return (
    <label className={labelWrapperStyles}>
      <div>
        <input
          {...rest}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={onCheckboxChange.bind(null, cbValue)}
          value={cbValue}
          className={styles.input}
        />
        <Box checked={checked} disabled={disabled} />
      </div>
      <div>
        <span className={labelStyles}>{label}</span>
        {children}
      </div>
    </label>
  );
};

CheckboxV2.propTypes = {
  children: PropTypes.any,
  checked: PropTypes.bool,
  label: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  onCheckboxChange: PropTypes.func.isRequired,
  wrapperClass: PropTypes.string,
  labelClass: PropTypes.string,
  size: PropTypes.oneOf([FORM_SIZE.medium, FORM_SIZE.large]),
  boldLabel: PropTypes.bool,
};

export default CheckboxV2;
