import { CONFIRM_APPOINTMENT, APPOINTMENT_CONFIRMED } from './constants';

export function confirmAppointment(orderId) {
  return {
    type: CONFIRM_APPOINTMENT,
    payload: { orderId },
  };
}

export function appointmentConfirmed(order) {
  return {
    type: APPOINTMENT_CONFIRMED,
    payload: { order },
  };
}
