import { fromJS } from 'immutable';
import {
  CLEAR_PAGE_DATA,
  SET_PLAN_DETAILS,
  SUBMIT_CARD_INFO,
  SUBMIT_RECIPIENT_INFO,
  SUBMIT_PURCHASER_INFO,
  INITIAL_FORM,
  INCREMENT_COMPLETED_STAGE,
  APPLY_DISCOUNT,
} from './constants';

const initialPageData = { errors: {}, ...INITIAL_FORM, completedStage: 1, coupon: null };
const initialState = fromJS(initialPageData);

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_PAGE_DATA: {
      return state.merge({ ...initialPageData });
    }
    case SET_PLAN_DETAILS: {
      return state.set('planDetails', fromJS(action.plan));
    }
    case SUBMIT_RECIPIENT_INFO: {
      const { activateAt, ...rest } = action.form;
      return state.merge({ activateAt, holder: rest });
    }
    case SUBMIT_PURCHASER_INFO: {
      return state.merge({ purchaser: action.form });
    }
    case SUBMIT_CARD_INFO: {
      const { token, brand, digits, expiration } = action.cardInfo;
      return state.merge({ token, brand, digits, expiration });
    }
    case INCREMENT_COMPLETED_STAGE: {
      const { completedStage } = action;
      return state.merge({ completedStage: completedStage + 1 });
    }
    case APPLY_DISCOUNT: {
      const { coupon, price, code } = action;
      const total = coupon ? price.total : null;
      const error = coupon || !code ? null : 'Sorry, this coupon is not valid';
      return state
        .setIn(['planDetails', 'total'], total)
        .set('coupon', coupon)
        .setIn(['errors', 'coupon'], error);
    }
    default:
      return state;
  }
}

export default pageReducer;
