// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Components
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import SeeHowItWorksModal from 'HTKit/Modals/SeeHowItWorks';
import BBB from 'src/components/BBB';
import Icon from 'HTKit/Icon';
// Styles, etc.
import styles from './styles.scss';
import yelpBadge from './yelp-badge.png';

const SeeHowItWorks = ({ toggleVideoModal }) => {
  return (
    <Button className={styles.seeHowItWorks} onClick={toggleVideoModal} hugeV2 theme={THEMES.V2SECONDARY}>
      <Icon name="play-rounded" />
      SEE HOW IT WORKS
    </Button>
  );
};

const BBBComponent = ({ isDesktop }) => {
  const containerStyles = cn(styles.BBBWrapper, {
    [styles.mobile]: !isDesktop,
    [styles.desktop]: isDesktop,
  });
  return (
    <div className={containerStyles}>
      {isDesktop ? (
        <BBB showTagLineMobile={false} />
      ) : (
        <BBB showTagLineMobile={false} showBlur={false} />
      )}
    </div>
  );
};

const LetsGetStarted = ({ clickAction }) => {
  return (
    <Button hugeV2 onClick={clickAction} theme={THEMES.V2PRIMARY}>
      LET&apos;S GET STARTED
    </Button>
  );
};

const ButtonContainer = ({ toggleVideoModal, toggleSiteNav }) => {
  const getStartedClickAction = (e) => {
    e.preventDefault();
    if(e.nativeEvent) {e.nativeEvent.stopImmediatePropagation()}
    toggleSiteNav();
  }

  return (
    <div className={styles.ctaButtonContainer}>
      <LetsGetStarted clickAction={getStartedClickAction} />
      <SeeHowItWorks toggleVideoModal={toggleVideoModal} />
    </div>
  );
};

const HomePageHero = ({ containerStyles, headerClasses = '', title, toggleFirstServiceDiscountState, ...rest }) => {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const toggleVideoModal = () => {
    const newState = !showVideoModal;
    setShowVideoModal(newState);
    toggleFirstServiceDiscountState(newState);
  };

  const containerClassNames = cn('l-content-section', styles.container);

  return (
    <section className={containerClassNames} style={containerStyles}>
      <div className={styles.flexSpaceBetween}>
        <div className={styles.headerContainer}>
          <h1 className={cn(headerClasses, styles.header)}>{title}</h1>
          <ButtonContainer toggleVideoModal={toggleVideoModal} {...rest} />
          <BBBComponent />
        </div>
        <BBBComponent isDesktop />
      </div>

      <img src={yelpBadge} className={styles.yelpBadge} alt="People love us on Yelp" />

      <SeeHowItWorksModal visible={showVideoModal} onCancel={toggleVideoModal} />
    </section>
  );
};

HomePageHero.propTypes = {
  containerStyles: PropTypes.shape({}),
  headerClasses: PropTypes.string,
  title: PropTypes.string,
  toggleFirstServiceDiscountState: PropTypes.func.isRequired,
};

HomePageHero.defaultProps = {
  title: 'Goodbye Frustration. HelloTech.',
  containerStyles: {},
};

BBBComponent.propTypes = {
  isDesktop: PropTypes.bool,
};

ButtonContainer.propTypes = {
  toggleSiteNav: PropTypes.func.isRequired,
  toggleVideoModal: PropTypes.func.isRequired,
};

LetsGetStarted.propTypes = {
  clickAction: PropTypes.func.isRequired,
};

SeeHowItWorks.propTypes = {
  toggleVideoModal: PropTypes.func.isRequired,
};

export default React.memo(HomePageHero);
