import { select } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import authSelector from 'src/selectors/auth';
import methods, { BASE_LOCATIONS } from './utils/apiRouteMethods';

const routes = (auth = fromJS({})) => {
  const m = methods(auth);
  return {
    server: {
      initial: m.get('/api/clients/server/initial'),
    },
    routes: {
      skuCategories: m.get('/api/clients/routes/sku_categories'),
    },
    users: {
      login: m.post('/service/auth/sessions'),
      logout: m.destroy('/logout'),
      /** Check account existence for passwordless login */
      checkAccount: m.post('/api/auth/check'),
      /** Send auth code for passwordless login */
      sendAuthCode: m.post('/api/auth/code'),
      /** Validate auth code for passwordless login */
      validateAuthCode: m.post('/api/auth/validate'),
      current: m.get('/api/clients/users/current'),
      localPhone: m.get('/api/clients/users/local_phone'),
      signup: m.post('/api/clients/users?with_cart=true'),
      /** Register a new account */
      registration: m.post('/api/clients/users'),
      check: m.get('/api/clients/users/check_user'),
      profile: m.get('/api/clients/users/profile'),
      update: m.post('/api/clients/users/update_profile'),
      resetPassword: m.post('/api/clients/users/reset_password'),
      createPassword: m.post('/api/clients/users/create_password'),
      updatePassword: m.post('/service/auth/passwords'),
      /** Check account existence for legacy password login */
      validateEmail: m.get('/api2/clients/validate_email', BASE_LOCATIONS.API2),
      /** Verify email structural validity via third-party */
      validateEmailExternal: m.get('/api/email_checker/verify'),
      account: {
        skuRedemption: m.post('/api/clients/account/skus/:id/redeem'),
        transactions: m.get('/api/clients/account/transactions'),
        memberships: m.get('/api/clients/account/memberships'),
      },
      notifications: {
        show: m.get('/api/notifications/:token'),
        update: m.post('/api/notifications/:token'),
      },
    },
    audit: {
      answers: m.get('/api/clients/account/audit/answers'),
    },
    clientProducts: {
      list: m.get('/api/clients/account/client_products'),
      createDevice: m.post('/api/clients/account/client_products'),
      updateDevice: m.put('/api/clients/account/client_products/:id'),
      deleteDevice: m.destroy('/api/clients/account/client_products/:id'),
      updateProductGroup: m.put('/api/clients/account/client_products/:id/update_product_group/'),
      // createProduct: m.post('/api/clients/account/client_products/create_product'),
    },
    products: {
      categories: m.get('/api/products/categories'),
      list: m.get('/api/products/index_by_make_and_category'),
      purchaseLocations: m.get('/api/products/purchase_locations'),
      show: m.get('/api/clients/products/:id'),
    },
    plans: {
      list: m.get('/api/clients/support_plans/list'),
      show: m.get('/api/clients/support_plans/:name'),
      redeem: m.post('/api/clients/support_plans/redeem'),
      autoRenew: m.post('/api/clients/client_plans/auto_renew'),
      requestCancellation: m.post('/api/clients/client_plans/request_cancellation'),
      order: m.post('/api/clients/support_plans/:name/order'),
      updatePrices: m.post('/api/clients/support_plans/:name/update_prices'),
      subscriptions: {
        all: m.get('/api/clients/plans'),
        show: m.get('/api/clients/plans/:id'),
        active: m.get('/api/clients/account/subscriptions/active'),
        statistic: m.get('/api/clients/account/subscriptions/statistic'),
        audit: m.get('/api/clients/account/audits'),
        autoRenewStatus: m.post('/api/clients/account/subscriptions/:id/set_auto_renew'),
        giftPurchase: m.post('/api/clients/gifts'),
        giftApplyCoupon: m.put('/api/clients/gifts/apply_coupon'),
        cancel: m.destroy('/api/clients/account/subscriptions/:id'),
      },
      compare: m.get('/api/clients/plans/compare'),
    },
    skus: {
      find: m.get('/api/clients/skus/:id'),
      questions: m.get('/api/clients/skus/:id/questions'),
      validatePermission: m.get('/api/clients/skus/:id/validate_permission'),
      totalCompletedServices: m.get('/api/clients/skus/completed_services?:sku_ids'),
      totalCompletedServicesAndClientRating: m.get('/api/clients/stats'),
      directBooking: m.get('/api/clients/skus/direct_booking'),
      estimate: m.get('/api/clients/skus/:skuId/estimate'),
    },
    skuCategories: {
      find: m.get('/api/clients/sku_categories/:seoName'),
    },
    reviews: {
      list: m.get('/api/clients/reviews/'),
      sku: m.get('/api/clients/reviews/:id'),
      reviewsPage: m.get('/api/clients/reviews/reviews_page'),
      planHolders: m.get('/api/clients/plans/reviews/plan_holders'),
    },
    faqQuestions: {
      list: m.get('/api/clients/faq_questions'),
    },
    cart: {
      find: m.get('/api/clients/carts'),
      getRelatedSkus: m.get('/api/clients/carts/related_skus'),
      preview: m.get('/api/clients/carts/preview'),
      itemPrice: m.post('/api/clients/carts/item_price'),
      addItem: m.post('/api/clients/carts/add_item'),
      updateItem: m.post('/api/clients/carts/update_item'),
      updateQuantity: m.post('/api/clients/carts/update_quantity'),
      removeItem: m.post('/api/clients/carts/remove_item'),
      addCoupon: m.post('/api/clients/carts/add_coupon'),
      ensureCouponValid: m.post('/api/clients/carts/ensure_coupon_valid'),
      startBooking: m.post('/api/clients/carts/start_booking'),
      availableTimes: m.get('/api2/availability', BASE_LOCATIONS.API2),
      updateAvailability: m.post('/api/clients/carts/update_availability'),
      goToAvailability: m.post('/api/clients/carts/go_to_availability'),
      goToPayment: m.post('/api/clients/carts/go_to_payment'),
      goToSummary: m.post('/api/clients/carts/go_to_summary'),
      addCard: m.post('/api/clients/carts/add_card'),
      applyBonus: m.post('/api/clients/carts/apply_bonus'),
      removeAdjustment: m.post('/api/clients/carts/remove_adjustment'),
      getServerTime: m.get('/api/clients/carts/get_server_time'),
      /**
       * Checks if a service can be added to the cart based on subsidy limits
       * Note: This does not check the total number of items in the cart.
       */
      canAddService: m.post('/api/clients/carts/can_add_service'),
      /**
       * Checks if more services can be added to the cart
       * Returns true if the cart does not have any subsidized services
       */
      canAddMoreServices: m.get('/api/clients/carts/can_add_more_services'),
      selectPlan: m.post('/api/clients/carts/select_plan'),
      removePlan: m.destroy('/api/clients/carts/remove_plan'),
      estimatePlan: m.post('/api/clients/carts/estimate_plan'),
      addPlan: m.post('/api/clients/carts/add_plan'),
      checkZipCodeRestriction: m.get('/api/clients/geo_locations/check?zip=:zip'),
      checkGiftCard: m.get('/api/clients/carts/check_gift_card'),
      setPaymentType: m.post('/api/clients/carts/set_payment_type'),
      emptyCart: m.post('/api/clients/carts/empty'),
      completeBooking: m.post('/api/clients/carts/complete_booking'),
      getCartByToken: m.get('/api/clients/carts/:token'),
      /** Returns a human-readable string describing a recurring service schedule. */
      parseRecurringService: m.get('/api/recurring_services/parse'),
    },
    orders: {
      list: m.get('/api/clients/orders'),
      info: m.get('/api/clients/orders/:orderId/order_info'),
      cancellationFee: m.get('/api/clients/orders/:orderId/cancellation_fee'),
      techSuggestedAvailability: m.get('/api2/tech_suggested_availability', BASE_LOCATIONS.API2),
      acceptSuggestedAvailability: m.post('/api/clients/orders/:orderId/accept_tech_availability'),
      declineSuggestedAvailability: m.destroy(
        '/api2/tech_suggested_availability',
        BASE_LOCATIONS.API2,
      ),
      schedule: m.get('/api2/availability/get_reschedule_dates', BASE_LOCATIONS.API2),
      reschedule: m.put('/api/clients/orders/:orderId/reschedule'),
      confirmation: m.get('/api/clients/orders/:orderId/confirmation'),
      uploadImage: m.post('/api/clients/orders/:orderId/upload_image'),
      removeImage: m.post('/api/clients/orders/:orderId/remove_image'),
      getSurvey: m.get('/api/clients/orders/:orderId/customer_survey'),
      submitSurvey: m.post('/api/clients/orders/:orderToken/submit_survey'),
      cancelOrder: m.post('/api/clients/orders/:orderId/cancel_order'),
      confirmAppointment: m.post('/api/clients/orders/:orderId/confirm_appointment'),
      submitPostConversionInfo: m.post('/api/clients/orders/:orderId/submit_post_conversion_info'),
      public: {
        info: m.get('/api/orders/:orderToken'),
        cancel: m.post('/api/orders/:orderToken/cancel'),
        reschedule: m.post('/api/orders/:orderToken/reschedule'),
        rate: m.post('/api/orders/:orderToken/rate'),
        acceptSuggestedAvailability: m.post('/api/orders/:orderToken/accept_tech_availability'),
        getOrderToken: m.post('/api/status/summary'),
      },
      cancelReasons: m.get('/api/cancel_reasons/visible_to_client'),
      planUpsellDiscount: m.post('/api/clients/orders/plan_upsell_discount'),
      subscriptionIntent: {
        create: m.post('/api/clients/orders/:orderToken/subscription_intents'),
        update: m.put('/api/clients/orders/:orderToken/subscription_intents/:subscriptionIntentId'),
        destroy: m.destroy(
          '/api/clients/orders/:orderToken/subscription_intents/:subscriptionIntentId',
        ),
      },
      /** List the next N appointments for a recurring service in client's timezone */
      listNextAppointments: m.get(
        '/api/clients/recurring_services/:recurrenceId/next_appointments',
      ),
      /** Update a recurring service's recurrence configuration */
      updateRecurringService: m.put('/api/clients/recurring_services/:recurrenceId'),
    },
    workflows: {
      last: m.get('/api/clients/workflows'),
      get: m.get('/api/clients/workflows/:token'),
      create: m.post('/api/clients/workflows'),
      rebook: m.post('/api/clients/workflows/:token/rebook'),
      all: m.get('/api/clients/workflows/all'),
    },
    search: {
      get: m.get('/api2/search', BASE_LOCATIONS.API2),
      put: m.put('/api2/search', BASE_LOCATIONS.API2),
    },
    payments: {
      addCard: m.post('/api/clients/payments/add_card'),
    },
    landings: {
      find: m.get('/api/clients/landings/:name'),
    },
    siteRequests: {
      apiInstallation: m.post('/api/clients/site_requests/installation_request'),
      xfinity: m.post('/api/clients/site_requests/xfinity'),
    },
    locations: {
      find: m.get('/api/clients/locations'),
      validateZip: m.get('/api/zips/:zip/check_supported'),
      getTechCoverageByZip: m.post('/api/clients/carts/check_zip'),
    },
    subscriptions: {
      subscribe: m.post('/api/clients/subscriptions/subscribe'),
    },
    geo: {
      geocode: m.get('/api/geo/geocode'),
    },
    contacts: {
      elfOffer: m.post('/api/clients/contacts/elf_offer'),
    },
    partners: {
      ahsValidation: m.post('/api/clients/partner_validation/ahs'),
    },
    partnerMemberships: {
      info: m.get('/api/clients/partner_memberships/info'), // partner serializer data
      verify: m.post('/api/clients/partner_memberships/verify'),
    },
    speedTest: {
      sendResults: m.post('/api/clients/stats/speed_test'),
    },
    fieldSales: {
      startNewProcess: m.post('/api/clients/carts/fieldsale_start'),
      skuFilter: m.get('/api/clients/carts/fieldsale_filter'),
      startBooking: m.post('/api/clients/carts/fieldsale_start_booking'),
      clientCheck: m.post('/api/clients/carts/fieldsale_client_check'),
      clientConfirm: m.post('/api/clients/carts/fieldsale_client_confirm'),
      // can move to carts later
      finishLink: m.post('/api/clients/carts/send_finish_link'),
      sendPin: m.post('/api/clients/carts/send_pin'),
      verifyPin: m.post('/api/clients/carts/verify_pin'),
    },
    techs: {
      // for client-facing usage only
      profile: m.get('/api/clients/techs/:id'),
    },
    techFacing: {
      // for tech-facing usage only
      offerDetails: m.get('/api/techs/orders/:token/claim'),
      counteroffer: m.post('/api/techs/counters'),
      getWorkflowQuote: m.get('/api/techs/workflows/:token/quote?forceCase=camel'),
      finishQuote: m.post('/api/clients/carts/:cartToken/finish_quote'),
      sendQuote: m.post('/api/clients/carts/:cartToken/send_quote'),
    },
    tracking: {
      leadGeneration: m.post('/api/segment/identify'),
    },
    cloudinary: {
      getFileAssets: m.post('/api/assets/cloudinary/generate_signed_urls'),
      getAuthToken: m.post('/api/assets/cloudinary/generate_signature'),
      deleteResource: m.destroy('/api/assets/cloudinary/:type/:public_id'),
    },
    credits: {
      claim: m.post('/api/clients/credits'),
    },
  };
};

export function* selectRoutes() {
  const auth = yield select(authSelector);
  return routes(auth);
}

export default routes;
