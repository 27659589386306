export const SKU_TYPE = {
  INSTALLATION: 'Installation & Setup',
  SUPPORT: 'Support',
};

const categoryLinks = '/tech-support/for/';
const skuLinks = '/tech-support/';

const sharedSkusBetweenPopularAudioAndVideo = [
  {
    id: 1505,
    name: 'In-Wall Cable Running',
    link: `${skuLinks}cable-running`,
    skuType: SKU_TYPE.INSTALLATION,
    popular: false,
    aliasCatId: 2,
  },
  {
    id: 1506,
    name: 'TV Wire In-Wall Concealment',
    link: `${skuLinks}tv-cord-concealment`,
    skuType: SKU_TYPE.INSTALLATION,
    popular: false,
    aliasCatId: 7,
  },
];

export const POPULAR_SKUS = Object.freeze([
  {
    id: process.env.NODE_ENV === 'production' ? 1624 : 10562,
    name: 'General Handyman',
    link: `${skuLinks}general-handyman`,
  },
  {
    id: 1049,
    name: 'TV Mounting (33" - 60")',
    link: `${skuLinks}tvmount-medium`,
  },
  {
    id: 1050,
    name: 'TV Mounting (61" or larger)',
    link: `${skuLinks}tvmount-large`,
  },
  {
    id: 13,
    name: 'Computer Support',
    link: `${skuLinks}computer-repair-laptop-desktop`,
  },
  {
    id: 200,
    name: 'Smart Security Cam Install',
    link: `${skuLinks}smart-cam-installation`,
  },
  {
    id: 612,
    name: 'Smart Thermostat Install',
    link: `${skuLinks}Smart-Thermostat-Installation`,
  },
  ...sharedSkusBetweenPopularAudioAndVideo,
]);

export const SKU_CATEGORY_IDS = {
  COMPUTERS_PRINTERS: {
    id: 1,
    name: 'Computers & Printers',
    seoName: 'computers',
  },
  WIFI_NETWORK: {
    id: 2,
    name: 'Wifi & Networks',
    seoName: 'networking',
  },
  TV_MOUNTING: {
    id: 7,
    name: 'TV Mounting',
    seoName: 'tv-mounting',
  },
  MOBILE_DEVICES: {
    id: 3,
    name: 'Mobile Devices',
    seoName: 'mobile',
  },
  AUDIO_VIDEO: {
    id: 4,
    name: 'Audio & Video',
    seoName: 'television-and-audio',
  },
  SMART_HOME: {
    id: 8,
    name: 'Smart Home',
    seoName: 'smart-home',
  },
  HOME_SECURITY: {
    id: 40,
    name: 'Home Security',
    seoName: 'home-security',
  },
  POPULAR_SERVICES: {
    id: 39,
    name: 'Popular Services',
    seoName: 'popular',
  },
  AROUND_THE_HOME: {
    id: 41,
    name: 'Around the Home',
    seoName: 'around-the-home',
  },
};

// In order to find the matching photo for the upsell we need a unique Id to identify, which is key
export const NAV_SUBCATEGORY_UPSELL_DATA = {
  COMPUTERS_PRINTERS: { text: 'computer', key: 'COMPUTERS_PRINTERS' },
  COMPUTER: { text: 'computer', key: 'COMPUTER' },
  PRINTER: { text: 'printer', key: 'PRINTER' },
  SMART_HOME: { text: 'smart device', key: 'SMART_HOME' },
  HOME_SECURITY: { text: 'home security', key: 'HOME_SECURITY' },
  MOBILE_DEVICES: { text: 'mobile', key: 'MOBILE_DEVICES' },
  WIFI_NETWORK: { text: 'WiFi and network', key: 'WIFI_NETWORK' },
  AUDIO_VIDEO: { text: 'home audio', key: 'AUDIO_VIDEO' },
  HOME_THEATER: { text: 'home theater', key: 'HOME_THEATER' },
  UNIVERSAL_REMOTE: { text: 'universal remote', key: 'UNIVERSAL_REMOTE' },
  ADVANCED_AUDIO_SYSTEM: { text: 'home audio', key: 'ADVANCED_AUDIO_SYSTEM' },
  THERMOSTATS: { text: 'smart device', key: 'THERMOSTATS' },
  VIDEO_DOORBELL: { text: 'smart device', key: 'VIDEO_DOORBELL' },
  GARAGE_DOORS: { text: 'smart device', key: 'GARAGE_DOORS' },
  HUBS_SPEAKERS: { text: 'smart device', key: 'HUBS_SPEAKERS' },
  SECURITY_CAMERAS: { text: 'home security', key: 'SECURITY_CAMERAS' },
  LOCKS: { text: 'home security', key: 'LOCKS' },
  ALARM_SYSTEMS: { text: 'home security', key: 'ALARM_SYSTEMS' },
  PHONES_TABLETS: { text: 'mobile', key: 'PHONES_TABLETS' },
  ROUTERS: { text: 'WiFi and network', key: 'ROUTERS' },
};

export const SUB_CATEGORIES = [
  {
    id: 1,
    name: 'Computers & Printers',
    showSecondaryNav: true,
    link: `${categoryLinks}${SKU_CATEGORY_IDS.COMPUTERS_PRINTERS.seoName}`,
    upsellData: NAV_SUBCATEGORY_UPSELL_DATA.COMPUTERS_PRINTERS,
    subCategories: [
      {
        name: 'Computers',
        seoName: 'computers',
        showSecondaryNav: true,
        upsellData: NAV_SUBCATEGORY_UPSELL_DATA.COMPUTER,
        skus: [
          {
            id: 23,
            name: 'Email or Online Account Setup or Support',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 18,
            name: 'New Computer Setup',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 30,
            name: 'OS (Operating System) Install or Re-install',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 21,
            name: 'Software Install or Uninstall',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 13,
            name: 'Computer Repair & Help',
            skuType: SKU_TYPE.SUPPORT,
            popular: true,
          },
          {
            id: 14,
            name: 'Computer Tune-Up',
            skuType: SKU_TYPE.SUPPORT,
            popular: true,
          },
          {
            id: 24,
            name: 'Computer Training & Lessons',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
          {
            id: 16,
            name: 'Data Back-up or Transfer',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
          {
            id: 20,
            name: 'Virus Removal & Cleanup',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
        ],
      },
      {
        name: 'Printers',
        seoName: 'computers',
        showSecondaryNav: true,
        upsellData: NAV_SUBCATEGORY_UPSELL_DATA.PRINTER,
        skus: [
          {
            id: 22,
            name: 'Printer Setup',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 40,
            name: 'Printer Troubleshooting & Repair',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Wifi & Networks',
    showSecondaryNav: true,
    link: `${categoryLinks}${SKU_CATEGORY_IDS.WIFI_NETWORK.seoName}`,
    upsellData: NAV_SUBCATEGORY_UPSELL_DATA.WIFI_NETWORK,
    subCategories: [
      {
        name: 'Routers',
        seoName: 'networking',
        showSecondaryNav: true,
        upsellData: NAV_SUBCATEGORY_UPSELL_DATA.WIFI_NETWORK,
        skus: [
          {
            id: 426,
            name: 'New WiFi Connection Setup',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 17,
            name: 'WiFi and Network Connectivity Support',
            skuType: SKU_TYPE.SUPPORT,
            popular: true,
          },
          {
            id: 427,
            name: 'WiFi Signal Extension',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
        ],
      },
      {
        name: 'In-Wall Cable Running',
        seoName: 'cable-running',
        upsellData: null,
        showSecondaryNav: false,
        id: 1505,
        link: `${skuLinks}cable-running`,
        skus: [],
      },
    ],
  },
  {
    id: 7,
    name: 'TV Mounting',
    seoName: 'tv-mounting',
    upsellData: null,
    link: `${categoryLinks}${SKU_CATEGORY_IDS.TV_MOUNTING.seoName}`,
    showSecondaryNav: false,
    subCategories: [],
  },
  {
    id: 41,
    name: SKU_CATEGORY_IDS.AROUND_THE_HOME.name,
    seoName: SKU_CATEGORY_IDS.AROUND_THE_HOME.seoName,
    upsellData: null,
    link: `${categoryLinks}${SKU_CATEGORY_IDS.AROUND_THE_HOME.seoName}`,
    showSecondaryNav: false,
    subCategories: [],
  },
  {
    id: 3,
    name: 'Mobile Devices',
    showSecondaryNav: true,
    upsellData: NAV_SUBCATEGORY_UPSELL_DATA.MOBILE_DEVICES,
    link: `${categoryLinks}${SKU_CATEGORY_IDS.MOBILE_DEVICES.seoName}`,
    subCategories: [
      {
        name: 'Phone & Tablet',
        upsellData: NAV_SUBCATEGORY_UPSELL_DATA.PHONES_TABLETS,
        showSecondaryNav: true,
        seoName: 'mobile',
        skus: [
          {
            id: 2,
            name: 'New Mobile Device Setup',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 4,
            name: 'Mobile Device Support',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: 'Audio & Video',
    showSecondaryNav: true,
    link: `${categoryLinks}${SKU_CATEGORY_IDS.AUDIO_VIDEO.seoName}`,
    upsellData: NAV_SUBCATEGORY_UPSELL_DATA.AUDIO_VIDEO,
    subCategories: [
      {
        name: 'Home Theater',
        seoName: 'television-and-audio',
        showSecondaryNav: true,
        upsellData: NAV_SUBCATEGORY_UPSELL_DATA.HOME_THEATER,
        skus: [
          {
            id: 7,
            name: 'Home Theater Hook-up & Setup',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 37,
            name: 'TV Hook-up and Setup',
            skuType: SKU_TYPE.INSTALLATION,
            popular: true,
          },
          {
            id: 115,
            name: 'TV, Audio, or Video Device Hookup',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 518,
            name: 'Streaming Video Device, Setup & Support',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 25,
            name: 'TV and Home Theater Support',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
          {
            id: 9,
            name: 'Home Theater Tutorial',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
        ],
      },
      {
        name: 'Universal Remote',
        seoName: 'television-and-audio',
        showSecondaryNav: true,
        upsellData: NAV_SUBCATEGORY_UPSELL_DATA.UNIVERSAL_REMOTE,
        skus: [
          {
            id: 10,
            name: 'Universal Remote Setup',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 1375,
            name: 'Universal Remote Support',
            skuType: SKU_TYPE.SUPPORT,
            popular: true,
          },
        ],
      },
      {
        name: 'Advanced Audio Systems',
        seoName: 'television-and-audio',
        showSecondaryNav: true,
        upsellData: NAV_SUBCATEGORY_UPSELL_DATA.ADVANCED_AUDIO_SYSTEM,
        skus: [
          {
            id: 8,
            name: 'Surround Sound System Setup',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 1376,
            name: 'Surround Sound System Support',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
        ],
      },
      {
        name: 'Gaming Console Setup',
        seoName: 'gaming-console',
        upsellData: null,
        showSecondaryNav: false,
        id: 1552,
        link: `${skuLinks}gaming-console`,
        skus: [],
      },
    ],
  },
  {
    id: 8,
    name: 'Smart Home',
    showSecondaryNav: true,
    link: `${categoryLinks}${SKU_CATEGORY_IDS.SMART_HOME.seoName}`,
    upsellData: NAV_SUBCATEGORY_UPSELL_DATA.SMART_HOME,
    subCategories: [
      {
        name: 'Thermostats',
        seoName: 'smart-home',
        showSecondaryNav: true,
        upsellData: NAV_SUBCATEGORY_UPSELL_DATA.THERMOSTATS,
        skus: [
          {
            id: 612,
            name: 'Smart Thermostat Installation & Setup',
            skuType: SKU_TYPE.INSTALLATION,
            popular: true,
          },
          {
            id: 1369,
            name: 'Smart Thermostat Support',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
        ],
      },
      {
        name: 'Video Doorbells',
        seoName: 'smart-home',
        showSecondaryNav: true,
        upsellData: NAV_SUBCATEGORY_UPSELL_DATA.VIDEO_DOORBELL,
        skus: [
          {
            id: 613,
            name: 'Video Doorbell Installation',
            skuType: SKU_TYPE.INSTALLATION,
            popular: true,
          },
          {
            id: 1370,
            name: 'Video Doorbell Support',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
        ],
      },
      {
        name: 'Garage Doors',
        seoName: 'smart-home',
        showSecondaryNav: true,
        skuType: SKU_TYPE.SUPPORT,
        popular: false,
        upsellData: NAV_SUBCATEGORY_UPSELL_DATA.GARAGE_DOORS,
        skus: [
          {
            id: 1024,
            name: 'Smart Garage Installation',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 1371,
            name: 'Smart Garage Support',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
        ],
      },
      {
        name: 'Hubs & Speakers',
        seoName: 'smart-home',
        showSecondaryNav: true,
        upsellData: NAV_SUBCATEGORY_UPSELL_DATA.HUBS_SPEAKERS,
        skus: [
          {
            id: 415,
            name: 'Smart Hub or Speaker Setup',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 1372,
            name: 'Smart Hub or Speaker Support',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
        ],
      },
    ],
  },
  {
    id: 40,
    name: 'Home Security',
    showSecondaryNav: true,
    link: `${categoryLinks}${SKU_CATEGORY_IDS.HOME_SECURITY.seoName}`,
    upsellData: NAV_SUBCATEGORY_UPSELL_DATA.HOME_SECURITY,
    subCategories: [
      {
        name: 'Cameras',
        seoName: 'home-security',
        showSecondaryNav: true,
        upsellData: NAV_SUBCATEGORY_UPSELL_DATA.SECURITY_CAMERAS,
        skus: [
          {
            id: 200,
            name: 'Smart Security Cam Installation',
            skuType: SKU_TYPE.INSTALLATION,
            popular: true,
          },
          {
            id: 1373,
            name: 'Smart Security Cam Support',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
        ],
      },
      {
        name: 'Locks',
        seoName: 'home-security',
        showSecondaryNav: true,
        upsellData: NAV_SUBCATEGORY_UPSELL_DATA.LOCKS,
        skus: [
          {
            id: 411,
            name: 'Smart Lock Installation & Setup',
            skuType: SKU_TYPE.INSTALLATION,
            popular: false,
          },
          {
            id: 1374,
            name: 'Smart Lock Support',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
        ],
      },
      {
        name: 'Alarm Systems',
        seoName: 'home-security',
        showSecondaryNav: true,
        upsellData: NAV_SUBCATEGORY_UPSELL_DATA.ALARM_SYSTEMS,
        skus: [
          {
            id: 1378,
            name: 'Alarm System Installation & Setup',
            skuType: SKU_TYPE.INSTALLATION,
            popular: true,
          },
          {
            id: 1377,
            name: 'Alarm System Support',
            skuType: SKU_TYPE.SUPPORT,
            popular: false,
          },
        ],
      },
    ],
  },
  {
    id: 39,
    name: 'Popular Services',
    subCategories: POPULAR_SKUS,
    showSecondaryNav: false,
    upsellData: null,
  },
];
