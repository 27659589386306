import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames'
import Icon from 'src/components/HTKit/Icon';
import styles from './styles.scss';

const ServiceInfoOptions = ({ options, className }) => (
  <div className={className}>
    {options.map((o) => (
      <div key={o} className={cn('paddingBottom-small', styles.serviceItem)}>
        <Icon name="checkmark-v2" className="n700" />
        <span className="p2 n900">{o.bullet}</span>
      </div>
    ))}
  </div>
); 

ServiceInfoOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

export default memo(ServiceInfoOptions);
