import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toCommaSeparated } from  'src/utils/formatter/number';
import StarRating from '../StarRating';
import styles from './styles.scss';

class ReviewCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hover: false };
  }

  handleEnter = () => {
    if (this.props.withHover) {
      this.setState({ hover: true });
    }
  }

  handleLeave = () => {
    if (this.props.withHover) {
      this.setState({ hover: false });
    }
  }

  render() {
    const {
      stars,
      reviews,
      size,
      inline,
      color,
      keyStr,
      containerStyle,
      copyStyle
    } = this.props;

    const { hover } = this.state;

    const containerClass = classNames(styles.container, {
      [styles.inline]: inline,
      [styles.hover]: hover,
    }, containerStyle);
    const copyClass = classNames(styles.reviewsWrapper, copyStyle);
    const count = reviews && toCommaSeparated(reviews);

    return (
      <div
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}
        className={containerClass}
      >
        <div className={styles.starWrapper}>
          <StarRating stars={stars} size={size} color={color} keyStr={keyStr} hover={hover} />
        </div>
        <div className={copyClass}>
          <p>{count} reviews</p>
        </div>
      </div>
    );
  }
}

ReviewCount.propTypes = {
  stars: PropTypes.number.isRequired,
  reviews: PropTypes.number.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  inline: PropTypes.bool,
  withHover: PropTypes.bool,
  keyStr: PropTypes.string,
  containerStyle: PropTypes.string,
  copyStyle: PropTypes.string,
};

ReviewCount.defaultProps = {
  color: '#f67d5b', // ht-red-orange
  size: 18,
  containerStyle: '',
  copyStyle: ''
};

export default ReviewCount;

/*
  ReviewCount:
    Render the number of reviews and star rating for a given sku.

  Examples:
    <ReviewCount stars={4.876} reviewCount={1190} size={20} inline />

  Properties:
    stars:
      type: number
      description: sku's star rating
    reviews:
      type: number
      description: sku's number of reviews
    size: number
      description: size of stars in pixels
    color: string
      description: hex color of stars
    inline: bool
      description: if true, set the star rating and number of reviews in one line
    keyStr: string
      description: string to create a unique id for <Star />

  Last modified: -JK April 3, 2017
*/
