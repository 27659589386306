import { connect } from 'react-redux';
import { layoutUpdate, loadPage } from 'src/actions/common';
import { phoneTitleSelector } from 'src/selectors/tracking';
import { setSkuLinkPrefix } from 'src/components/Sidebar/actions';
import { updatePhone } from 'src/containers/AppBase/actions';
import pageSelector from 'src/selectors/page';
import locationSelector, {
  locationBreadcrumbsSelector,
  locationChildrenSelector,
} from 'src/selectors/location';
import { PAGE_NAME as SKU_PAGE_NAME } from 'src/containers/SkuPage/constants';
import LocationPage from './LocationPage';
import { PAGE_NAME } from './constants';

const mapStateToProps = (state, ownProps) => {
  const { locationPath: locationUrl } = ownProps.match.params;
  const locationPath = ['us', locationUrl].filter((p) => !!p).join('/');
  return {
    phone: phoneTitleSelector(state),
    page: pageSelector(PAGE_NAME)(state),
    locationPath,
    location: locationSelector(locationPath)(state),
    breadcrumbs: locationBreadcrumbsSelector(locationPath)(state),
    childLocations: locationChildrenSelector(locationPath)(state),
    skuPage: pageSelector(SKU_PAGE_NAME)(state),
  };
};

export default connect(mapStateToProps, { layoutUpdate, loadPage, updatePhone, setSkuLinkPrefix })(
  LocationPage,
);
