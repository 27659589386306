import { connect } from 'react-redux';
import { phoneTitleSelector, phoneLinkSelector } from 'src/selectors/tracking';
import { callClick } from 'src/actions/common';
import { PhoneDisplayLink } from './PhoneDisplayLink';

const mapStateToProps = (state) => ({
  phoneTitle: phoneTitleSelector(state),
  phoneLink: phoneLinkSelector(state),
});

export { ICON_ORIENTATION, LAST_FOUR_DEFAULT_HOME_PAGE } from './constants';
export { HomePageSplitOverride } from './HomePageSplitOverride';

export default connect(mapStateToProps, { callClick })(PhoneDisplayLink);
