import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import BenefitsList from 'src/components/HTKit/Modals/MembershipUpsellModal/Parts/BenefitsList';
import BENEFITS_ELEMENT_NAME from 'src/containers/CartPage/CartTypes/UpsellCart/Mobile/constants';

const NewBenefits = ({ plansInfo }) => (
  <Element name={BENEFITS_ELEMENT_NAME}>
    <p className="h6 blue paddingTop-medium paddingBottom-small paddingLeft-small">
      {plansInfo.name} Membership
    </p>
    <h3 className="paddingLeft-small">What&apos;s Included: </h3>
    <BenefitsList benefitsList={plansInfo.benefits} />
  </Element>
);

NewBenefits.propTypes = {
  plansInfo: PropTypes.object,
};

export default NewBenefits;
