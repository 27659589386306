import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { formatSubscriptionName } from 'src/utils/plans';
import OrderBreakdownLineItem from 'src/components/OrderSummary/Breakdown/OrderBreakdownLineItem';
import CouponAppliedLineItem from 'src/components/OrderSummary/Breakdown/CouponAppliedLineItem';
import CreditAppliedLineItem from 'src/components/OrderSummary/Breakdown/CreditAppliedLineItem';
import LowTierMembershipUpsell from 'src/containers/CartPage/Parts/LowTierMembershipUpsell';
import { totalSubscriptionCreditAdjustments } from 'src/containers/BookingPage/utils';
import SubscriptionLineItem from 'src/containers/BookingPage/SummaryPage/Parts/SubscriptionLineItem';
import { COUPON_ADJUSTMENT_TYPES } from 'src/components/OrderSummary/Breakdown/constants';
import { standardAndSubscriptionCouponSelector } from 'src/selectors/cart';

const PlanWithProductsInCartLineItems = (props) => {
  const {
    plan,
    hasProductsInCart,
    subscriptionTotalNoTax,
    couponText,
    couponValueFormatted,
    removeCoupon,
    couponStyles,
    subscriptionTaxes,
    taxesStyles,
    modalCallback,
    subscriptionCreditAdjustments,
  } = props;
  // Hooks
  const standardAndSubscriptionCoupon = useSelector(standardAndSubscriptionCouponSelector);

  const planName = formatSubscriptionName(plan);
  const {
    amount: subscriptionCreditAmount,
    amountFormatted: subscriptionCreditAmountFormatted,
  } = totalSubscriptionCreditAdjustments(subscriptionCreditAdjustments);

  return (
    plan &&
    hasProductsInCart && (
      <>
        <SubscriptionLineItem plan={plan} name={planName} amount={`$${subscriptionTotalNoTax}`} />

        <LowTierMembershipUpsell modalCallback={modalCallback} />
        {standardAndSubscriptionCoupon?.[COUPON_ADJUSTMENT_TYPES.SUBSCRIPTION] && (
          <CouponAppliedLineItem
            name={couponText}
            amount={couponValueFormatted}
            onClick={removeCoupon}
            className={couponStyles}
            couponType={COUPON_ADJUSTMENT_TYPES.SUBSCRIPTION}
          />
        )}

        {!!subscriptionCreditAmount && (
          <CreditAppliedLineItem amount={subscriptionCreditAmountFormatted} />
        )}

        {subscriptionTaxes && (
          <OrderBreakdownLineItem name="Taxes" amount={subscriptionTaxes} className={taxesStyles} />
        )}
      </>
    )
  );
};

PlanWithProductsInCartLineItems.propTypes = {
  plan: PropTypes.object,
  hasProductsInCart: PropTypes.bool,
  planName: PropTypes.string,
  subscriptionTotalNoTax: PropTypes.string,
  coupon: PropTypes.object,
  couponText: PropTypes.string,
  couponValueFormatted: PropTypes.string,
  removeCoupon: PropTypes.func,
  couponStyles: PropTypes.string,
  subscriptionTaxes: PropTypes.string,
  taxesStyles: PropTypes.string,
  modalCallback: PropTypes.func,
  subscriptionCreditAdjustments: PropTypes.array,
};

export default PlanWithProductsInCartLineItems;
