import { LOGIN, LOGGED_IN, LOGIN_REDIRECT, VALIDATE_AUTH_CODE } from './constants';

export const loginUser = (
  { email, password, page = 'login', onFinish = () => {} },
  skipRedirect,
) => ({
  type: LOGIN,
  email,
  password,
  page,
  onFinish,
  skipRedirect,
});

export const loggedIn = (payload = {}) => ({
  type: LOGGED_IN,
  payload,
});

export const loginRedirect = (path) => ({
  type: LOGIN_REDIRECT,
  path,
});

export const validateAuthCode = (payload = {}) => ({
  type: VALIDATE_AUTH_CODE,
  payload,
});
