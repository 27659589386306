import { fromJS } from 'immutable';
import { PAGE_LOADED } from 'src/constants/common';
import { SKU_DATA_LOADED } from 'src/constants/sku';
import { PAGE_NAME as ADD_SKU_PAGE_NAME } from 'src/containers/AddSkuPage/constants';
import { PAGE_NAME as PRODUCT_PAGE_NAME } from 'src/containers/ProductPage/constants';
import { PAGE_NAME as SKU_PAGE_NAME } from 'src/containers/SkuPage/constants';

export default function skusReducer(state = fromJS({}), action) {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        case ADD_SKU_PAGE_NAME:
        case SKU_PAGE_NAME:
        case PRODUCT_PAGE_NAME: {
          return state.set(action.sku.id.toString(), fromJS(action.sku));
        }
        default:
          return state;
      }
    case SKU_DATA_LOADED: {
      const { skuId, skuData } = action;
      return state.set(skuId.toString(), fromJS(skuData));
    }
    default:
      return state;
  }
}
