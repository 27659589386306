import { fromJS } from 'immutable';
import { UPDATE_SKU_QUESTIONS } from 'src/containers/AddSkuPage/constants';
import { PAGE_LOADED } from '../../constants/common';
import { getOrderIdentityKey } from '../../utils/identity';

export default function skusReducer(state = fromJS({}), action) {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        case 'addSku': {
          return state.set(action.sku.id.toString(), fromJS(action.questions));
        }
        case 'orderConfirmation': {
          const { order, questions } = action;
          return state.set(getOrderIdentityKey(order), fromJS(questions));
        }
        default:
          return state;
      }
    case UPDATE_SKU_QUESTIONS: {
      return state.set(action.skuId.toString(), fromJS(action.questions));
    }
    default:
      return state;
  }
}
