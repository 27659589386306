import React, { memo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Grid } from 'HTKit/Grid';
import ServiceInfoDetails from './ServiceInfoDetails';
import styles from './styles.scss';

const ServiceInfo = ({
  service,
  onBook,
  onRating,
  onUpsellToggle,
  onUpsellMore,
  className,
  ...rest
}) => (
  <>
    <img src={service.extra.image} className={styles.imageMobile} />
    <Grid.Fluid classes={cn(styles.serviceInfoGrid, className)}>
      <Grid.Row>
        <Grid.Column sm={0} md={0} lg={7} classes="paddingTop-small">
          <img src={service.extra.image} className={styles.desktopImage} />
        </Grid.Column>
        <Grid.Column sm={4} md={8} lg={5} classes="paddingTop-small">
          <ServiceInfoDetails
            service={service}
            onBook={onBook}
            onRating={onRating}
            onUpsellMore={onUpsellMore}
            onUpsellToggle={onUpsellToggle}
            {...rest}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid.Fluid>
  </>
);

ServiceInfo.propTypes = {
  service: PropTypes.shape({
    extra: PropTypes.shape({
      image: PropTypes.string,
      showOptions: PropTypes.bool,
    }),
    skuBullets: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onBook: PropTypes.func.isRequired,
  onRating: PropTypes.func.isRequired,
  onUpsellToggle: PropTypes.func.isRequired,
  onUpsellMore: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default memo(ServiceInfo);
