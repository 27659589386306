import Cookies from 'js-cookie';

/**
 This cookie is set when a user clicks to close the promo banner. We suppress the banner for
 2 days.
 - Get a cookie
 - Does it exist for the cookie test?
 - - No, show promo banner
 - - Yes, suppress promo banner

 - Cookie has a 2 day expiry
 */

const EXPIRY_IN_DAYS = 2; // two days
const PROMOTONAL_BANNER_COOKIE_NAME = "_ht_promotional_banner_";

export const getPromotionalBannerCookie = () => Boolean(Cookies.get(PROMOTONAL_BANNER_COOKIE_NAME));
export const setPromotionalBannerCookie = () => Cookies.set(PROMOTONAL_BANNER_COOKIE_NAME, true, { expires: EXPIRY_IN_DAYS });
