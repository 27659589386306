import { createSelector, createStructuredSelector } from 'reselect';
import { pageSelector as selectPage } from 'src/selectors/page';
import {
  cartSelector,
  affirmEligibleSelector,
  paymentTypeSelector,
  purePlanSelector,
} from 'src/selectors/cart';
import { isMobileOrTabletSelector, isMobileSelector } from 'src/selectors/resolution';
import { PAGE_NAME } from './constants';

const pageSelector = selectPage(PAGE_NAME);

export const subscriptionSelector = createSelector(pageSelector, (page) =>
  page.get('subscription'),
);

export const showMembershipRenewalSelector = createSelector(
  [subscriptionSelector, cartSelector],
  (subscription, cart) => subscription && !cart.get('card'),
);

export default createStructuredSelector({
  cart: cartSelector,
  page: pageSelector,
  subscription: subscriptionSelector,
  showMembershipRenewal: showMembershipRenewalSelector,
  isMobileOrTablet: isMobileOrTabletSelector,
  isMobile: isMobileSelector,
  affirmEligible: affirmEligibleSelector,
  paymentType: paymentTypeSelector,
  plan: purePlanSelector,
});
