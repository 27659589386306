export const PAGE_NAME = 'product';

export const LOAD_PLAN_DETAILS = `${PAGE_NAME}/LOAD_PLAN_DETAILS`;

export const PRODUCT_HEADER_TRIGGER = 'product-page-header-trigger';
export const TAB_KEYS = {
  FEATURE: 'feature',
  FAQ: 'faq',
  INSTALLATION: 'installation',
  COMPATIBILITY: 'productCompatibility',
  WORKS_WITH: 'worksWith',
  PRODUCT_SPECS: 'productSpecs',
};

export const SPECIALIST_CHAT_CLICK_SEGMENT = 'product/SPECIALIST_CHAT_CLICK_SEGMENT';
