import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'src/components/HTKit/Icon';
import SkuFAQContent from './SkuFAQContent';
import styles from './styles.scss';

const SkuFAQListDesktop = ({ open, faq, onClose }) => (
  <div className={cn(styles.list, { [styles.active]: open })}>
    <button onClick={onClose} className={cn('plainButton', styles.listClose)}>
      <Icon name="close" />
    </button>

    <div className={styles.listContent}>
      <SkuFAQContent faq={faq} />
    </div>
  </div>
);

SkuFAQListDesktop.propTypes = {
  faq: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default SkuFAQListDesktop;
