import { createSelector } from 'reselect';
import qs from 'query-string';
import { isImmutable } from 'src/utils/helpers';

const routerSelector = (state) => state.get('router');
export const beforeRouteTransitionSelector = (state) => state.get('beforeRouteTransition');

export const locationSelector = createSelector(routerSelector, (router) => router.get('location'));

export const pureLocationSelector = createSelector(routerSelector, (router) =>
  router.get('location').toJS(),
);

export const paramsSelector = createSelector(locationSelector, (location) =>
  qs.parse(location.get('search')),
);

export const locationSearchSelector = createSelector(locationSelector, (location) =>
  location.get('search'),
);

export const pathnameSelector = createSelector(locationSelector, (location) =>
  location.get('pathname'),
);

export const fullPathSelector = createSelector(pureLocationSelector, ({ pathname, search }) =>
  [pathname, search].join(''),
);

export const nextPathnameSelector = createSelector(locationSelector, (location) =>
  location.getIn(['state', 'nextPathname']),
);

export const previousLocationSelector = createSelector(
  // should return a string or null
  beforeRouteTransitionSelector,
  (beforeRouteTransition) => beforeRouteTransition.get('previousLocation'),
);

export const beforeRoutLocationSelector = createSelector(
  beforeRouteTransitionSelector,
  (beforeRouteTransition) => beforeRouteTransition.get('location'),
);

export const userEntersLocationFromAClick = createSelector(
  previousLocationSelector,
  beforeRoutLocationSelector,
  (prevLocation, location) => {
    const l = isImmutable(location) ? location.toJS() : (location || {});

     if (!prevLocation || prevLocation === l.pathname) return false;

     return true;
  }
)

/* Better in a "common" area, but "router" is still related */
export const locationStateStateSelector = (state) => state.getIn(['router', 'location', 'state']);

// Is our current page in edit mode? Derived from router location setting
export const inEditModeSelector = createSelector(locationStateStateSelector, (locationState) =>
  locationState ? locationState.get('mode', '') === 'edit' : false,
);
