import {
  CREATE_WS_CONNECTION,
  CLOSE_WS_CONNECTION,
  UPDATE_LOCATION,
  UPDATE_STATUS
} from './constants';

export function createWSConnection() {
  return {
    type: CREATE_WS_CONNECTION,
  };
}

export function closeWSConnection() {
  return {
    type: CLOSE_WS_CONNECTION,
  };
}

export function updateLocation({ data }) {
  return {
    type: UPDATE_LOCATION,
    data
  };
}

export function updateStatus(connection = {}) {
  return {
    type: UPDATE_STATUS,
    connection
  };
}
