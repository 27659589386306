import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Components
import { Grid } from 'HTKit/Grid';
import SlideModal, { HEADER_TYPES } from 'HTKit/Modals/Slide';
import Button, { THEMES } from 'HTKit/Forms/Button';
import Icon from 'HTKit/Icon';
// Assets
import modalImage from 'src/images/icons/home-tech-chat.svg';
// Constants, Utils && Styles
import { PLAN_BENEFITS } from '../constants';
import styles from '../styles.scss';

const ButtonContainer = ({ onConfirm, onCancel, planName }) => {
  return (
    <div className={styles.buttonWrapper}>
      <Button theme={THEMES.V2PRIMARY} onClick={onConfirm} className={styles.button}>
        Get {planName}
      </Button>
      <a href className={styles.removeConfirm} onClick={onCancel}>
        <span>No Thanks</span>
      </a>
    </div>
  );
};

const BenefitsContainer = ({ planAmount }) => {
  return (
    <div className={styles.planBenefits}>
      <h4>First Month Free</h4>
      <p className={cn('caption n700', styles.copy)}>Then ${planAmount}/month, annual commitment</p>
      {PLAN_BENEFITS.map((pb) => {
        return (
          <div key={pb} className={cn(styles.benefit, 'marginBottom-tiny1 text-align-left')}>
            <Icon name="checkmark-plain" className={styles.icon} />
            <span className="p2">{pb}</span>
          </div>
        );
      })}
    </div>
  );
};

const ModalImage = () => <img src={modalImage} className={styles.modalImage} />;

const HeaderComponent = ({ planName, title }) => {
  return (
    <>
      <p className="p1 n700">{planName}</p>
      <h3 className={cn('blue700 ', styles.modalHeader)}>{title}</h3>
    </>
  );
};

const MobileTabletLayout = ({ ...rest }) => {
  return (
    <Grid.Fluid classes={styles.mobileTabletLayout}>
      <Grid.Row>
        <Grid.Column sm={4} mdOffset={2} md={4} lgOffset={1} classes={styles.column}>
          <HeaderComponent {...rest} />
          <ModalImage />
          <BenefitsContainer {...rest} />
          <ButtonContainer {...rest} />
        </Grid.Column>
      </Grid.Row>
    </Grid.Fluid>
  );
};

const DesktopLayout = ({ ...rest }) => {
  return (
    <Grid.Fluid classes={styles.desktopLayout}>
      <Grid.Row classes="marginBottom-large1">
        <Grid.Column lgOffset={1} lg={5}>
          <ModalImage />
        </Grid.Column>
        <Grid.Column lg={5}>
          <HeaderComponent {...rest} />
          <BenefitsContainer {...rest} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column lg={12}>
          <ButtonContainer {...rest} />
        </Grid.Column>
      </Grid.Row>
    </Grid.Fluid>
  );
};

// Exported Render Component
const UpsellModal = ({ isVisible, ...rest }) => {
  return (
    <SlideModal
      open={isVisible}
      headerType={HEADER_TYPES.BOOKING}
      childrenStyles={styles.modalContentWrapper}
    >
      <DesktopLayout {...rest} />
      <MobileTabletLayout {...rest} />
    </SlideModal>
  );
};

ButtonContainer.propTypes = {
  onConfirm: PropTypes.function,
  onCancel: PropTypes.function,
  planName: PropTypes.string,
};
UpsellModal.propTypes = { isVisible: PropTypes.bool };
HeaderComponent.propTypes = {
  planName: PropTypes.string,
  title: PropTypes.string,
};
BenefitsContainer.propTypes = { planAmount: PropTypes.string };

ButtonContainer.defaultProps = { onConfirm: () => {}, onCancel: () => {} };
UpsellModal.defaultProps = { onConfirm: () => {}, onCancel: () => {} };

export default UpsellModal;
