import { fromJS } from 'immutable';
import { ANSWER_CHANGE } from 'src/components/PreQuestion/constants';
import { PAGE_LOADED } from 'src/constants/common';
import { PAGE_NAME, LOAD_PLAN_DETAILS, TOGGLE_PLAN_MODAL, PLANS_INFO_LOADED } from './constants';

const initialState = fromJS({
  plan: {},
  showPlanModal: false,
  plansInfo: {},
});

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case ANSWER_CHANGE:
      return state.deleteIn(['errors', action.question.id.toString()]);
    case PAGE_LOADED: {
      if (action.page === PAGE_NAME) {
        const { plansInfo } = action;
        if (plansInfo) {
          return state.set('plansInfo', fromJS(plansInfo));
        }
      }
      return state;
    }
    case LOAD_PLAN_DETAILS: {
      const { plan } = action;
      return state.set('plan', fromJS(plan));
    }
    case TOGGLE_PLAN_MODAL: {
      const { modalState } = action;
      return state.set('showPlanModal', modalState);
    }
    case PLANS_INFO_LOADED: {
      const { plansInfo = {} } = action;
      return state.set('plansInfo', fromJS(plansInfo));
    }
    default:
      return state;
  }
}

export default pageReducer;
