import { splitioConstants } from 'src/components/SplitIO';

export const PAGE_NAME = 'orderConfirmation';

/*
  Per Product team we can use the same language for in-home orders and product-only orders at this time. The change in language
  was intended to affect in-home service cancellation rates, and there is no issue with cancellation rates for products-only orders.
  Any changes to product-only order language will come at another time -GH June 3 2021
*/
export const THANK_YOU_TEXT = {
  [splitioConstants.SPLITIONAME_ORDER_CONFIRMATION_PAGE_LANGUAGE]: {
    title: "We're searching for the best local tech to service your order!",
    subtitle:
      "Once we match you with a qualified tech, we'll email and text message you confirmation details, including your scheduled time. Most appointments are confirmed within a few hours, but some markets might require additional time to source the best tech for your needs.",
  },
  REMOTE: {
    title: 'Your remote support session can be started at your convenience',
    subtitle: null,
  },
  PRODUCT: {
    title: 'Thank you for shopping with us!',
    subtitle:
      'We’ll send a confirmation email when your order ships. If you need help with set up once your product arrives, HelloTech offers installation services as soon as same-day.',
  },
  DEFAULT: {
    title: 'We’ll confirm your appointment details shortly!',
    subtitle: 'Be on the lookout for email and text message updates',
  },
};

export const VIRTUAL_SITE_VISIT_CALENDLY_URL =
  'https://calendly.com/d/2y8-n8c-xwz/comcast-remote-site-visit';
