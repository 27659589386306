import { call, put, takeEvery } from 'redux-saga/effects';
import { pageNotice, clearPageNotices, pageLoaded, updateUser } from 'src/actions/common';
import { addErrorPageSnackNotice, addSuccessPageSnackNotice } from 'src/actions/snackNotice';
import { selectRoutes } from 'src/apiRoutes';
import { LOAD_PAGE } from 'src/constants/common';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { defaultNotice, errorNotice } from 'src/utils/notice';
import { requestStarted, requestFinished } from 'src/utils/request';
import { UPDATE_CARD } from './constants';

const ACCOUNT_PAYMENT_PAGE_NAME = 'accountPayment';

export function* updateCardSaga({ token, opts = {} }) {
  const routes = yield call(selectRoutes);
  yield put(clearPageNotices(ACCOUNT_PAYMENT_PAGE_NAME));
  yield put(requestStarted());
  const requestResult = yield call(routes.payments.addCard, { token: token.id });
  yield put(requestFinished());

  const {useSnackbar = false, snackNoticeOpts = {}} = opts;

  if (requestResult.err) {
    const { errors } = requestResult.data;
    if (!useSnackbar) {
      yield put(pageNotice(ACCOUNT_PAYMENT_PAGE_NAME, errorNotice(errors)));
    } else {
      yield put(
        addErrorPageSnackNotice({
          content: requestResult.data.errors.join('. '),
          ...snackNoticeOpts,
        }),
      );
    }
  } else {
    const { user } = requestResult.data;
    if (!useSnackbar) {
      yield put(pageNotice(ACCOUNT_PAYMENT_PAGE_NAME, defaultNotice('Payment info has been successfully updated.')));
    } else {
            yield put(
        addSuccessPageSnackNotice({
          content: 'Payment info has been successfully updated.',
          dismissable: true,
          ...snackNoticeOpts,
        }),
      );
    }
    yield put(updateUser(user));
  }
}

export function* pageSaga() {
  yield loadUserProfileSaga(ACCOUNT_PAYMENT_PAGE_NAME, true);
  yield put(pageLoaded(ACCOUNT_PAYMENT_PAGE_NAME, {}));
}

export function* accountPaymentFlow() { // eslint-disable-line no-empty-function
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === ACCOUNT_PAYMENT_PAGE_NAME, pageSaga);
  yield takeEvery(UPDATE_CARD, updateCardSaga);
}

export default [
  accountPaymentFlow,
];
