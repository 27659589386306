import React from 'react';
import { flow } from 'lodash';
import { ensureImmutable } from 'src/utils/helpers';
import PlanModal from './PlanModal';

const enhance = flow(
  // eslint-disable-next-line react/display-name, react/prop-types
  (Component) => ({ plan, sku, ...rest }) => {
    return <Component {...rest} plan={ensureImmutable(plan)} sku={ensureImmutable(sku)} />;
  }
);

export default enhance(PlanModal);
