import { ensureImmutable } from 'src/utils/helpers';

import {
  AVAILABILITY_LOADED,
  TOGGLE_HOUR,
  REMOVE_EXCESS_SELECTED_TIME,
  TOGGLE_HOUR_BY_INDEX,
} from './constants';

export function availabilityLoaded({ availability }) {
  return {
    type: AVAILABILITY_LOADED,
    availability,
  };
}

export function toggleHour({ date, selectedHourData, newCheckState }) {
  return {
    type: TOGGLE_HOUR,
    date,
    selectedHourData: ensureImmutable(selectedHourData),
    newCheckState,
  };
}

export function removeExcessSelectedTime({ selectedHourData }) {
  return {
    type: REMOVE_EXCESS_SELECTED_TIME,
    selectedHourData: ensureImmutable(selectedHourData),
  };
}

export function toggleHourByIndex({ dateIndex, hourIndex, checked }) {
  return {
    type: TOGGLE_HOUR_BY_INDEX,
    dateIndex,
    hourIndex,
    checked,
  };
}
