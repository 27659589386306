import moment from 'moment';
import get from 'lodash/get';
import { BREAKDOWN_PRICE_TYPES } from 'src/containers/CartPage/constants';
import { getCartPlanUpsellCookie, setCartPlanUpsellCookie } from 'src/utils/cookies';

const hasHideUpsellCookie = () => {
  return !!getCartPlanUpsellCookie();
};

export const showUpsellPlan = ({ cart, user }) => {
  if (hasHideUpsellCookie()) {
    return false;
  }

  if (user) {
    const userHasLegacyOrSubscriptionPlan = user.getIn(['account', 'planHolder']);
    if (userHasLegacyOrSubscriptionPlan) return false;
  }

  if (cart) {
    const cartHasPlan = cart.get('plan');
    const cartHasNoItems = cart.get('items').size === 0;
    // has at least one sku covered by upsell plans
    const skusIncludedInUpsellPlan = cart.get('skusIncludedInUpsellPlan');

    if (cartHasPlan || cartHasNoItems || !skusIncludedInUpsellPlan) return false;
  }

  return true;
};

export const setHideUpsellCookie = () => {
  const upsellInfo = getCartPlanUpsellCookie();
  if (!upsellInfo) {
    setCartPlanUpsellCookie();
  }
};

export const getApiOrderAddMessage = (cart) => {
  /*
    Items on API orders technically aren't added, just edited. But Product wants an "Add" message
    to show in certain situations -GH Aug 14, 2020
  */
  const skusWithUnansweredQuestions = cart.items.filter(
    (item) => Object.keys(item.questions).length === 0,
  ).length;
  const baseAdditionMessage = 'Added to cart.';
  const additionalItemsRequired = ` Provide service details for ${skusWithUnansweredQuestions} more service${
    skusWithUnansweredQuestions > 1 ? 's' : ''
  }.`;
  return skusWithUnansweredQuestions
    ? baseAdditionMessage + additionalItemsRequired
    : baseAdditionMessage;
};

// Receive an array of sku objects and determine if the skuId matches a sku object in the list
export const isProductOnlySku = (skuId, skuList = []) =>
  Boolean(skuList.find((elem) => elem.skuId === skuId));

/**
 * @typedef {import('src/containers/CartPage/CartTypes/RegularCart/RegularCart.js').PlanTrialData} PlanTrialData
 *
 * @param {PlanTrialData} planTrialData
 */
export const getFormattedPlanTrialExpirationDate = (planTrialData = {}) => {
  const expiresAt = planTrialData.expiresAt || planTrialData.expires_at;
  if (!get(expiresAt, 'iso', false)) return '';
  return moment(expiresAt.iso).format('l');
};

/* Pass in root object (where priceType sits) or priceType */
export const skuIsPriceTypeHourly = (service) => {
  const priceType = typeof service === 'string' ? service : service?.priceType;
  return service && priceType === BREAKDOWN_PRICE_TYPES.hourly;
};

/**
 * Formats the price for hourly services.
 *
 * @param {Object} params - The parameters.
 * @param {Object} params.service - The service or the priceType
 * @param {string} params.price - The formatted price.
 * @param {boolean} params.withAsterik - Whether to append an asterisk to the price.
 * @returns {string} The formatted price.
 */
export const formatPriceTypeHourly = ({ service, price, withAsterik = false }) => {
  // Check if the service is hourly
  if (!skuIsPriceTypeHourly(service)) {
    return price;
  }

  const asterik = withAsterik ? '*' : '';

  return `${price}/hr${asterik}`;
};
