import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'src/components/HTKit/Icon';
import styles from './styles.scss';

const ServiceInfoOptions = ({ options }) => (
  <>
    <h4 className="h4">Service Includes:</h4>
    <div className={styles.list}>
      {
        options.map(({ bullet, text }, key) => (
          <div key={key} className={styles.item}>
            <Icon name="check-mark" className={styles.check} /> <span className="p0">{bullet || text}</span>
          </div>
        ))
      }
    </div>
  </>
);

ServiceInfoOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

export default memo(ServiceInfoOptions);
