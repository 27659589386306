import { DEFAULT_LAYOUT } from 'src/constants/common';

export const PAGE_NAME = 'addSku';
export const REMOTE_TRUE = 'remote-true';
export const UPDATE_ITEM_PRICE = 'addSkuPage/UPDATE_ITEM_PRICE';
export const ITEM_PRICE_UPDATED = 'addSkuPage/ITEM_PRICE_UPDATED';
export const ADD_TO_CART = 'addSkuPage/ADD_TO_CART';
export const UPDATE_CART = 'addSkuPage/UPDATE_CART';
export const UPDATE_SKU_QUESTIONS = 'addSkuPage/UPDATE_SKU_QUESTIONS';
export const CONFIRM_ITEM = 'addSkuPage/CONFIRM_ITEM';
export const POPULATE_CART_ANSWERS = 'addSkuPage/POPULATE_CART_ANSWERS';
export const OVERRIDE_BASE_PRICE_DISCLAIMER = '*Discount will be applied at checkout';
export const ADD_TO_CART_Q_FLOW = 'addSkuPage/ADD_TO_CART_Q_FLOW';
export const ADD_PRODUCT_TO_CART = 'addSkuPage/ADD_PRODUCT_TO_CART';
export const POPULATE_NEW_ITEM_WITH_CART_ANSWERS = 'addSkuPage/POPULATE_NEW_ITEM_WITH_CART_ANSWERS';
export const CLEAR_ITEM_STATUS = 'addSkuPage/CLEAR_ITEM_STATUS';

export const QUESTION_TYPES = {
  DROPDOWN: 'dropdown',
  TEXT_AREA: 'textarea',
  INPUT: 'input',
  CHECKBOX: 'checkbox',
  DEVICE: 'device',
  MEDIA: 'media',
};

export const REMOTE_QUESTION = {
  id: 'remote',
  textDirect: 'Do you want <i class="ht-icon ht-logo-HellotechNow -hellotech-now"></i>?',
  inputType: QUESTION_TYPES.DROPDOWN,
  required: true,
  answers: [
    {
      id: REMOTE_TRUE,
      text: 'Yes (Save %{SAVING}% with instant online support)',
      adjAmount: 0,
      adjAmountFormatted: null,
      adjDescription: 'HelloTech Now!',
      hasQuantity: false,
      default: true,
      warning: null,
    },
    {
      id: 'remote-false',
      text: 'No, I want in-home service',
      adjAmount: 0,
      adjAmountFormatted: null,
      adjDescription: 'HelloTech Now!',
      hasQuantity: false,
      default: true,
      warning: null,
    },
  ],
};

export const ADD_SKU_PAGE_LAYOUT = Object.freeze({
  ...DEFAULT_LAYOUT,
  footer: { show: false },
});

export const QA_ADD_SKU_PAGE_LAYOUT = Object.freeze({
  ...DEFAULT_LAYOUT,
  footer: { show: false },
  main: {
    withNavy050BG: true,
    withNavy050BGMobile: true,
  },
});
