import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.scss';

const Banner = ({ children, className, showBlobs, style = {} }) => (
  <div className={cn(styles.banner, className, { [styles.blobs]: showBlobs })} style={style}>{children}</div>
);

Banner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  showBlobs: PropTypes.bool,
  style: PropTypes.object
};

export default memo(Banner);
