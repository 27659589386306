import {
  PLAN_LOADED,
  PLAN_ORDERED,
  CHECK_USER,
  USER_CHECKED,
  ORDER_PLAN,
  PAYMENT_EDIT_MODE,
  GIFT_CARD_MESSAGE,
  UPDATE_PLAN_PRICES,
  PLAN_PRICES_UPDATED,
} from './constants';

export function giftCardMessage(message) {
  return {
    type: GIFT_CARD_MESSAGE,
    payload: { giftCardMessage: message },
  };
}

export function paymentEditMode(editMode) {
  return {
    type: PAYMENT_EDIT_MODE,
    payload: { editMode },
  };
}

export function planLoaded(plan) {
  return {
    type: PLAN_LOADED,
    payload: { plan },
  };
}

export function updatePlanPrices({ name, paymentCycle, address, couponCode, keepErrors }) {
  return {
    type: UPDATE_PLAN_PRICES,
    payload: { name, paymentCycle, address, couponCode, keepErrors },
  };
}

export function planPricesUpdated({ breakdown }) {
  return {
    type: PLAN_PRICES_UPDATED,
    breakdown,
  };
}

export function checkUser(email) {
  return {
    type: CHECK_USER,
    payload: { email },
  };
}

export function userChecked(exists) {
  return {
    type: USER_CHECKED,
    payload: { exists },
  };
}

export function orderPlan(plan, token) {
  return {
    type: ORDER_PLAN,
    payload: { plan, token },
  };
}

export function planOrdered(plan) {
  return {
    type: PLAN_ORDERED,
    payload: { plan },
  };
}
