export const UPDATE_TRACKING = 'tracking/UPDATE_TRACKING';
export const UPDATE_TRACKING_BY_UPSELL = 'tracking/UPDATE_TRACKING_BY_UPSELL';

export const LANDING_PHONE_COOKIE = {
  NAME: 'landing-phone',
  EXPIRATION: 14,
};

export const UPSELL_TRACKING = {
  added: 'Upsell Added',
  viewed: 'Upsell Viewed',
  removed: 'Upsell Removed',
};

/*
    Lets colate the sources/types here. If they change them, we don't have to hunt them down.
 */
export const LEAD_GEN_SOURCE_TYPES = {
  fieldSales: {
    type: 'Field Sales',
    name: 'Field Sales',
  },
  firstServiceDiscount: {
    type: 'Modal',
    name: 'Web Modal',
  },
  footer: {
    type: 'Footer',
    name: 'Web Footer',
  },
  skuGate: {
    type: 'Sku Gate',
    name: 'Web Sku Gate',
  },
  lowTechCoverage: {
    type: 'Low Tech Coverage',
    name: 'Web Low Tech Coverage',
  },
  registration: {
    type: 'Account Registration',
    name: 'Web Registration Page',
  },
  registrationCheckout: {
    type: 'Account Registration',
    name: 'Web Checkout Page',
  },
  evDefault: {
    type: 'EV Flow',
    name: 'Landing Page',
  },
  evDefaultLandingPage: {
    type: 'EV Flow',
    name: 'Landing Page',
  },
};
