/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const UPDATE_AUTH = 'app/update_auth';
export const LOAD_USER = 'app/load_user';
export const USER_LOADED = 'app/user_loaded';
export const USER_NOT_LOADED = 'app/USER_NOT_LOADED';
export const LOAD_CART_PREVIEW = 'app/LOAD_CART_PREVIEW';
export const UPDATE_CART_PREVIEW = 'app/UPDATE_CART_PREVIEW';
export const LOGOUT = 'app/LOGOUT';
export const USER_LOGGED_OUT = 'app/USER_LOGGED_OUT';
export const APP_LOADED = 'app/APP_LOADED';
export const COOKIES_LOADED = 'app/COOKIES_LOADED';
export const UPDATE_PHONE = 'app/UPDATE_PHONE';
export const UPDATE_IP = 'app/UPDATE_IP';
