import { createSelector, createStructuredSelector } from 'reselect';
import componentSelector from 'src/selectors/component';
import { isAlticeLandingSelector } from 'src/selectors/landings';
import { userSelector } from 'src/selectors/user';
import { isMobileSelector } from 'src/selectors/resolution';
import { sidebarOpenSelector } from 'src/selectors/sidebar';
import { skuCategoriesAndSkusJSSelector, productCategoriesAndProductsJSSelector } from 'src/selectors/routesSchema';
import { hideMembershipSelector } from 'src/components/Header/TopNavHeader/selectors';

const skuLinkPrefixSelector = createSelector(
  componentSelector('sidebar'),
  (sidebar) => sidebar.get('skuLinkPrefix'),
);

const menuOnOpenSelector = createSelector(
  componentSelector('sidebar'),
  (sidebar) => sidebar.get('menuOnOpen'),
);

// We need to filter out the Network category for Altice
const filteredSkuCategoriesAndSkusSelector = createSelector(
  [skuCategoriesAndSkusJSSelector, isAlticeLandingSelector],
  (categoriesAndSkus, isAltice) =>
    categoriesAndSkus.filter((category) => !(category.id === 2 && isAltice)),
);

const productCategoriesAndProductsSelector = createSelector(
  [productCategoriesAndProductsJSSelector],
  (categoriesAndSkus) => categoriesAndSkus
);

export default createStructuredSelector({
  isOpen: sidebarOpenSelector,
  isMobile: isMobileSelector,
  skuLinkPrefix: skuLinkPrefixSelector,
  user: userSelector,
  skuCategoriesAndSkus: filteredSkuCategoriesAndSkusSelector,
  productCategories: productCategoriesAndProductsSelector,
  menuOnOpen: menuOnOpenSelector,
  hideMembership: hideMembershipSelector,
});
