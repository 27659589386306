// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Utils
import { updateSelectedDateTimes } from './utils';
import { toggleHour } from './actions';
// Styles
import styles from './styles.scss';

const AvailabilityManager = (props) => {
  const clearToggledHours = () => {
    const { selectedHours, toggleHour: toggleHourAction, selectedDateTimes } = props;
    if (selectedHours.length === 0) return;
    selectedHours.forEach((time) => {
      updateSelectedDateTimes(selectedDateTimes, time.selectedHourData, false);
      toggleHourAction({
        date: time.date,
        selectedHourData: time.selectedHourData,
        newCheckState: false,
      });
    });
  };

  const { numSelectedHours } = props;
  const timesSelectedCaption = numSelectedHours === 1 ? 'time selected' : 'times selected';
  return (
    <div className={styles.availabilityManager}>
      <h5 className={styles.numSelectedHours}>
        {numSelectedHours} {timesSelectedCaption}
      </h5>
      <a className={styles.clearTimes} onClick={clearToggledHours}>
        Clear All
      </a>
    </div>
  );
};

AvailabilityManager.propTypes = {
  numSelectedHours: PropTypes.number.isRequired,
  selectedHours: PropTypes.array.isRequired,
  selectedDateTimes: PropTypes.array.isRequired,
  toggleHour: PropTypes.func.isRequired,
};

export default connect(() => ({}), { toggleHour })(AvailabilityManager);

/*
  AvailabilityManager
    Purpose: Renders the number of availabilities selected and also provides a means to clear all availabilities
*/
