import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.scss';

const ServicePriceMember = ({ service, sm, showFixedPrice = false }) => {
  const baseStyles = cn({ p0: !sm, p1: sm });
  const badgeStyles = cn(styles.badge, { [styles.sm]: sm });
  return (
    <>
      <span className={baseStyles}>
        {!showFixedPrice && 'Starting at'}{' '}
        <span className={styles.old}>{service.extra.prices.price}</span>{' '}
        <span className={cn(baseStyles, 'text-weight-bold blue')}>
          {service.extra.prices.memberPrice}
        </span>
      </span>
      <span className={badgeStyles}>Member Price</span>
    </>
  );
};

ServicePriceMember.propTypes = {
  service: PropTypes.shape({
    extra: PropTypes.shape({
      prices: PropTypes.shape({
        memberPrice: PropTypes.string,
        price: PropTypes.string,
      }),
    }),
  }),
  className: PropTypes.string,
  sm: PropTypes.bool,
  showFixedPrice: PropTypes.bool,
};

export default memo(ServicePriceMember);
