import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Snackbar from 'src/components/Snackbar';
import PaymentForm from 'src/components/PaymentForm';
import UpdateCardInfoPanel from 'src/components/Elements/Payment/UpdateCardInfoPanel/UpdateCardInfoPanel';
import { ACCOUNT_PAGE_NAME } from 'src/containers/Account/AccountPage/sagas';
import { SNACKBAR_BILLING_TAB } from './constants';

const EVBillingTab = ({ userJS, showPaymentForm, setShowPaymentForm }) => {
  const [showGeneralForm, setShowGeneralForm] = useState(false);

  const checkHasCardOnFile = (userObject) => !!get(userObject, 'account.card.number');

  useEffect(() => {
    if (userJS) {
      setShowGeneralForm(true);
      const hasCardOnFile = checkHasCardOnFile(userJS);
      setShowPaymentForm(!hasCardOnFile);
    }
  }, [userJS]);

  const toggleForm = () => setShowPaymentForm(!showPaymentForm);

  const { account: { card = {} } = {} } = userJS || {};
  const { expMonth = '', expYear = '', number = '', brand = '' } = card || {};
  const lastDigits = number.substr(-4);

  const hasCardOnFile = checkHasCardOnFile(userJS);

  return (
    <>
      <Snackbar
        pageName={ACCOUNT_PAGE_NAME}
        componentName={SNACKBAR_BILLING_TAB}
        barWrapperStyle="marginBottom-small"
      />
      {showGeneralForm && (
        <>
          {showPaymentForm ? (
            <>
              <PaymentForm />
              {hasCardOnFile && (
                <div className="marginTop-small text-align-center">
                  <button
                    type="button"
                    className="plainButton text-link medium-link"
                    onClick={toggleForm}
                  >
                    Use existing card
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="padding-tiny">
              <UpdateCardInfoPanel
                lastDigits={lastDigits}
                expMonth={expMonth}
                expYear={expYear}
                brand={brand}
                onClick={toggleForm}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

EVBillingTab.propTypes = {
  userJS: PropTypes.object,
  showPaymentForm: PropTypes.bool.isRequired,
  setShowPaymentForm: PropTypes.func.isRequired,
};

export default EVBillingTab;
