// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactModal from 'react-modal';
// Components
import Button from "../../Forms/Button";
import { ModalCloseButton } from "../Parts";
import styles from './styles.scss';

const MODAL_TEXT =
  "Only one promo code can be applied to your order. For your convenience, we'll apply the promo code with the higher value.";

const MultipleAutoApplyCouponsAdded = (props) => {
  const {isOpen} = props;
  const {onClose} = props;
  const buttonRowStyles = classNames('form-item', styles.buttonRow);
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      className={styles.dialog}
      overlayClassName={styles.overlay}
      contentLabel="Modal containing validation code"
    >
      <ModalCloseButton onClose={onClose} />
      <div className={styles.modal}>
        <p className={styles.copy}>{MODAL_TEXT}</p>
        <div className={buttonRowStyles}>
          <Button onClick={onClose}>Continue</Button>
        </div>
      </div>
    </ReactModal>
  );
};

MultipleAutoApplyCouponsAdded.propTypes = {
  isOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MultipleAutoApplyCouponsAdded;
