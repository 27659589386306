import { createSelector } from 'reselect';

export const skuCategoriesAndSkusStateSelector = (state) =>
  state.getIn(['routesSchema', 'serviceCategories']);

export const skuCategoriesAndSkusSelector = createSelector(
  skuCategoriesAndSkusStateSelector,
  (skuCategoriesAndSkus) => skuCategoriesAndSkus,
);

export const skuCategoriesAndSkusJSSelector = createSelector(
  skuCategoriesAndSkusSelector,
  (skuCategoriesAndSkus) => skuCategoriesAndSkus.toJS(),
);

export const productCategoriesAndProductsStateSelector = (state) =>
  state.getIn(['routesSchema', 'productCategories']);

export const productCategoriesAndProductsSelector = createSelector(
  productCategoriesAndProductsStateSelector,
  (productCategoriesAndSkus) => productCategoriesAndSkus,
);

export const productCategoriesAndProductsJSSelector = createSelector(
  productCategoriesAndProductsSelector,
  (productCategoriesAndSkus) => productCategoriesAndSkus.toJS(),
);
