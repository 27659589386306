import { useSelector } from 'react-redux';
import { pureCartSelector } from 'src/selectors/cart';
import {
  memoizedCreateCartServiceScheduling,
  DEFAULT_RETURN_VALUES,
} from './cartServiceSchedulingFactory';

export const useCartServiceScheduling = () => {
  const cart = useSelector(pureCartSelector);

  if (!cart) return DEFAULT_RETURN_VALUES;
  return memoizedCreateCartServiceScheduling({ cart });
};
