import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/components/Snackbar/Bar/styles.scss';

export const ManageWorkflowTemplate = ({ templateData }) => {
  const onLinkClick = templateData.get('onLinkClick');

  if (!onLinkClick) return null;
  const commonTextStyles = 'p1 successGreen500 text-weight-med';
  return (
    <p className={styles.manageWorkflowTemplate}>
      <span className={commonTextStyles}>Looking for your EV Installation project? </span>
      <button onClick={onLinkClick} className={cn(commonTextStyles, 'plainButton underline')}>
        Manage Project
      </button>
    </p>
  );
};

ManageWorkflowTemplate.propTypes = {
  templateData: PropTypes.shape({
    onLinkClick: PropTypes.func,
    get: PropTypes.func,
  }),
};
