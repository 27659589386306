import React, { memo, cloneElement } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { over, compact, first } from 'lodash';
import Icon from 'src/components/HTKit/Icon';
import LoaderComopnent from 'src/components/Loader/Loader.Component';
import { prevented } from 'src/utils/event';
import styles from './styles.scss';

/*
  Loader Icon
  This will take precedence over other icon settings.
*/
const getLoaderIcon = ({ showLoader, wrapperStyles }) => {
  return showLoader ? (
    <div className={wrapperStyles}>
      <LoaderComopnent loaderSize="small" containerClassName={styles.loaderWrapper} className={styles.loaderIcon} />
    </div>
  ) : null;
};

getLoaderIcon.propTypes = {
  showLoader: PropTypes.bool,
  wrapperStyles: PropTypes.string,
};

/*
  Error Icon

  If there's an error, then show this icon. This will take precedence of over other
  icon settings.
*/
const getErrorIcon = ({ hasError = false, wrapperStyles }) => {
  return hasError ? (
    <div className={wrapperStyles}>
      <Icon name="info-filled" className={cn(styles.postfixIcon, styles.errorIcon)} />
    </div>
  ) : null;
};

getErrorIcon.propTypes = {
  hasError: PropTypes.bool,
  wrapperStyles: PropTypes.string,
};

/*
  Icon

  Given an iconName, render this component. A ref must be passed to <InputFieldV2 /> to
  access it in the callback function.

  Usage:

  <InputFieldV2
    ...
    ref={inputRef}
    iconName="v2-green-checkmark"
    iconClass={styles.icon}
    iconOnClick={(inputRef) => { console.log('do something with the ref. maybe.') }}
  />
*/
const getIcon = ({
  iconName = '',
  iconClass = '',
  iconOnClick = null,
  wrapperStyles,
  inputRef,
}) => {
  if (!iconName) return null;
  const onClickCB = iconOnClick
    ? prevented(() => {
        iconOnClick(inputRef);
      })
    : null;

  const component = <Icon name={iconName} className={cn(styles.postfixIcon, iconClass)} />;

  return onClickCB ? (
    <button
      type="button"
      className={cn('plainButton', styles.iconButton, wrapperStyles)}
      onClick={onClickCB}
    >
      {component}
    </button>
  ) : (
    <div className={wrapperStyles}>{component}</div>
  );
};

getIcon.propTypes = {
  iconName: PropTypes.string,
  iconClass: PropTypes.string,
  iconOnClick: PropTypes.func,
  wrapperStyles: PropTypes.string,
  inputRef: PropTypes.object,
};

/*
  Custom Icon Component

  Pass a custom component to render. A ref must be passed to <InputFieldV2 /> to
  access it as a prop in the custom icon component.

  Usage:

  const SomethingCustom = ({ inputRef }) => {
    console.log(inputRef);
    return <p>Help</p>;
  };

  const inputRef = useRef();

  ....

  <InputFieldV2
    ...
    ref={inputRef}
    iconComponent={<SomethingCustom />}
  />

*/
const getIconComponent = ({ iconComponent = null, inputRef, wrapperStyles }) => {
  if (!iconComponent) return null;
  const modifiedIconComponent = cloneElement(iconComponent, {
    inputRef,
  });

  return <div className={wrapperStyles}>{modifiedIconComponent}</div>;
};

getIconComponent.propTypes = {
  iconComponent: PropTypes.node,
  wrapperStyles: PropTypes.string,
  inputRef: PropTypes.object,
};

/**
  Postfix Icon

  Icon to show on the right-hand side of the input.

  Notes:
  - If there is an error, the error icon will take precedence.
  - If showError prop is false, the icon passed takes precedence. (device cataloging)
*/

// Helpers
const iterateIconGetters = over(getLoaderIcon, getErrorIcon, getIcon, getIconComponent);
const iterateIconGettersCustom = over(getIcon, getErrorIcon, getIconComponent);

const PostfixIcon = ({ inputRef, size, showError = true, ...rest }) => {
  let iconGetters = iterateIconGetters;
  if (!showError) {
    iconGetters = iterateIconGettersCustom;
  }
  const getIconToRender = (props) => first(compact(iconGetters(props))) || null;
  const wrapperStyles = cn(styles.postfixIconWrapper, {
    [styles[`input${size}`]]: true,
  });

  return getIconToRender({ inputRef, wrapperStyles, ...rest });
};

PostfixIcon.propTypes = {
  inputRef: PropTypes.object,
  size: PropTypes.string.isRequired,
  showError: PropTypes.bool,
};

export default memo(PostfixIcon);
