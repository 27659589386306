import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const HouryPriceTypeDisclaimer = ({ showHourlyDisclaimer, classes, suppressTopPadding }) => {
  if (!showHourlyDisclaimer) {
    return null;
  }

  return (
    <div className={cn(classes, 'caption n700', { 'paddingTop-small': !suppressTopPadding })}>
      *Hourly services are charged for a minimum of one hour, and billable in half-hour increments
      thereafter. Though we&rsquo;ll try our very best, resolution is not always possible and
      can&rsquo;t be guaranteed.
    </div>
  );
};

HouryPriceTypeDisclaimer.propTypes = {
  showHourlyDisclaimer: PropTypes.bool.isRequired,
  classes: PropTypes.string,
  suppressTopPadding: PropTypes.bool,
};

export default HouryPriceTypeDisclaimer;
