// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import moment from 'moment';
// Utils
import { toggleHour } from 'src/components/AvailabilitySelector/actions';
import { updateSelectedDateTimes, adjustAndFormatTimeRange } from './Scheduling.utils';

// Styles
import styles from './styles.scss';

const WindowCountDisplay = (props) => {
  const { selectedHours, selectedDateTimes, numSelectedHours, config } = props;

  const dispatch = useDispatch();

  const clearToggledHours = () => {
    if (selectedHours.length === 0) return;
    selectedHours.forEach((time) => {
      updateSelectedDateTimes(selectedDateTimes, time.selectedHourData, false);
      dispatch(
        toggleHour({
          date: time.date,
          selectedHourData: time.selectedHourData,
          newCheckState: false,
        }),
      );
    });
  };

  const totalWindowsSelected = Math.ceil(numSelectedHours / config.GROUP_BY_HOURS);
  const timesSelectedCaption = totalWindowsSelected === 1 ? 'window selected' : 'windows selected';
  return (
    <div className={styles.availabilityManager}>
      <h5 className={styles.numSelectedHours}>
        {totalWindowsSelected} {timesSelectedCaption}
      </h5>
      <a className={styles.clearTimes} onClick={clearToggledHours}>
        Clear All
      </a>
    </div>
  );
};

const TimeRangeDisplay = ({ selectedHours }) => {
  // Render hidden <h5> if no hours are selected to prevent layout shift
  if (selectedHours.length === 0)
    return (
      <div className={styles.availabilityManager}>
        <h5 className={styles.hiddenText}>No time selected</h5>
      </div>
    );

  const startTime = selectedHours[0].selectedHourData.get('hour');
  const endTime = selectedHours[selectedHours.length - 1].selectedHourData.get('hour');
  const timeRange = adjustAndFormatTimeRange(startTime, endTime);

  /** Format is Weekday, Month Day */
  const formattedDate = moment(selectedHours[0].date).format('dddd, MMMM D');

  return (
    <div className={styles.availabilityManager}>
      <h5>{`${formattedDate} from ${timeRange}`}</h5>
    </div>
  );
};

export const AvailabilityManagerWithConfig = { WindowCountDisplay, TimeRangeDisplay };

WindowCountDisplay.propTypes = {
  selectedHours: PropTypes.array.isRequired,
  selectedDateTimes: PropTypes.array,
  numSelectedHours: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
};

TimeRangeDisplay.propTypes = {
  selectedHours: PropTypes.array.isRequired,
};

/*
  AvailabilityManager
    Purpose: Renders the number of availabilities selected and also provides a means to clear all availabilities
*/
