import { fromJS } from 'immutable';
import { PAGE_LOADED } from '../../constants/common';
import { LOCATION_UPDATED } from '../../containers/LocationPage/constants';

const locationsInitialState = fromJS({});

export default function locationsReducer(state = locationsInitialState, action) {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        case 'location': {
          if (state.get(action.locationPath) === action.location) {
            return state;
          }
          return state.set(action.locationPath, fromJS(action.location));
        }
        default:
          return state;
      }
    case LOCATION_UPDATED:
      return state.set(action.locationPath, fromJS(action.location));
    default:
      return state;
  }
}
