import { SET_CURRENT_LANDING, REMOVE_CURRENT_LANDING } from './constants';

export function setCurrentLanding(partnerInfo) {
  return {
    type: SET_CURRENT_LANDING,
    partnerInfo,
  };
}

export function removeCurrentLanding() {
  return {
    type: REMOVE_CURRENT_LANDING,
  };
}
