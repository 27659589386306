import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'src/components/HTKit/Icon';
import cn from 'classnames';
import styles from './styles.scss';

const ContactIcon = ({ toggleDropdown, chat, className }) => {
  const unreadMessages = chat.get('unreadMessages');
  const buttonStyles = cn('plainButton', styles.iconWrapper, className);

  return (
    <button type="button" className={buttonStyles} onClick={toggleDropdown}>
      {unreadMessages && <div className={styles.unreadMessages}></div>}
      <Icon name="chat" className={styles.icon} />
    </button>
  );
};

ContactIcon.propTypes = {
  chat: PropTypes.object,
  toggleDropdown: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ContactIcon.defaultProps = {
  className: '',
};

export default ContactIcon;
