import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './styles.scss';

const StartsAtPrice = ({
  cart,
  hasSubscription,
  sku,
  showSubscriptionPrice,
  showNormalPrice
}) => {
  const cartHasPlan = !!cart.getIn(['plan', 'id']);
  const remote = cart.get('remote');
  const items = cart.get('items');
  const basePrice = sku.get('basePrice');
  const remoteSavings = sku.get('remoteSavings');

  const price = remote || !items.size || !remoteSavings ? sku.get('startsAtPrice') : `$${(basePrice + remoteSavings) / 100}`;

  let subscriptionPrice = null;
  if ((hasSubscription || cartHasPlan) && showSubscriptionPrice && sku.get('subscriptionPrices')) {
    const subCheapestPrice = sku.getIn(['subscriptionPrices', 'cheapestPrice']);
    subscriptionPrice = `$${subCheapestPrice / 100}`;

    if (subCheapestPrice === null) subscriptionPrice = null;
    if (price === subscriptionPrice) subscriptionPrice = null;
  }

  return subscriptionPrice ? (
    <span>
      {showNormalPrice && (
        <span className={styles.subPrice}>{price}</span>
      )}
      {subscriptionPrice}
    </span>
  ) : (
    <span>
      {price}
    </span>
  );
};

StartsAtPrice.propTypes = {
  cart: PropTypes.object.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  sku: PropTypes.object.isRequired,
  showSubscriptionPrice: PropTypes.bool,
  showNormalPrice: PropTypes.bool,
};

StartsAtPrice.defaultProps = {
  showSubscriptionPrice: false,
  showNormalPrice: true,
};

const mapStateToProps = (state) => {
  return {
    cart: state.getIn(['entities', 'cart']),
    hasSubscription: state.getIn(['user', 'account', 'hasSubscription']),
  };
};

export default connect(mapStateToProps, {})(StartsAtPrice);
