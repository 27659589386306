import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { PanelV2 } from 'src/components/HTKit/Elements/PanelV2';
import Address from 'src/components/OrderSummary/Address';
import styles from './styles.scss';

const AddressCard = ({ onClick, address }) => {
  return (
    <PanelV2 mediumShadow className={`padding-small2 ${styles.cardContainer}`}>
      <div className="site-v2">
        <Address address={address} />
      </div>
      <div>
        <div className={styles.cardButton}>
          <Button theme={THEMES.V2PRIMARY} onClick={onClick}>
            Use this address
          </Button>
        </div>
      </div>
    </PanelV2>
  );
};

AddressCard.propTypes = {
  onClick: PropTypes.func,
  address: PropTypes.object.isRequired,
};

export default memo(AddressCard);
