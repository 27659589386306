import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {OPTION_PROP_TYPE, SIZE_PROP_TYPE} from './constants';
import styles from './styles.scss';

const SelectOption = ({
  innerProps,
  isSelected,
  isFocused,
  children,
  selectProps,
  data,
}) => {
  const showCustomStyle = Boolean(selectProps.options.find((o) => o.value === 0));
  const {htSize} = selectProps;
  const optionStyle = cn(styles.option, {
    [styles.selected]: isSelected || isFocused,
    [styles.customSelection]: showCustomStyle,
  }, styles[htSize]);
  const subtextStyle = cn(styles.subtext, styles[htSize])
  const {subtext} = data;

  return (
    <div {...innerProps} className={optionStyle}>
      {children}
      {subtext && (
        <p className={subtextStyle}>{subtext}</p>
      )}
    </div>
  );
};
SelectOption.propTypes = {
  innerProps: PropTypes.shape({}),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  selectProps: PropTypes.shape({
    options: PropTypes.array,
    htSize: SIZE_PROP_TYPE,
  }),
  children: PropTypes.node,
  data: OPTION_PROP_TYPE,
};

export default SelectOption;
