import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button, { THEMES as BUTTON_THEMES } from 'HTKit/Forms/Button';
import { DefaultMetaTags } from 'src/components/SEO';
import { geoJsonLd, breadcrumbsJsonLd } from 'src/utils/jsonLd/index';
import { locationsRootPath } from 'src/utils/paths';
import { getLocationScrollerName } from '../../utils';
import { GEO_TYPES } from '../../constants';
import styles from './styles.scss';

const MapHeader = ({ location, phone, breadcrumbs, mapBoxRef }) => {
  const buttonVisible = location.get('type') !== GEO_TYPES.COUNTRY;
  const meta = location.get('meta').toJS();
  const geoJson = geoJsonLd(location, phone);
  const breadcrumbsJson = breadcrumbsJsonLd(breadcrumbs);

  const headerStyles = cn(styles.headerWrapper, {
    [styles.headerWrapperMultiline]: location.get('headlines').count() > 1,
  });
  const titleStyles = cn(styles.headerOverMap, {
    [styles.headerOverMapNoButton]: !buttonVisible,
  });

  return (
    <div className={headerStyles}>
      <DefaultMetaTags
        title={meta.title}
        description={meta.description}
        script={[geoJson, breadcrumbsJson]}
      />
      {/* don't put anything on div itself, it's gonna be changed */}
      <div className={styles.headerMap} ref={mapBoxRef} />
      <div className={titleStyles}>
        <h1>
          {location.get('headlines').map((headline, i) => (
            <span key={i}>
              {headline}
              {i + 1 !== location.get('headlines').size && <br />}
            </span>
          ))}
        </h1>
        {buttonVisible && (
          <div className={styles.headerButton}>
            <Button isLink goTo={locationsRootPath()} theme={BUTTON_THEMES.V2SECONDARY}>
              {getLocationScrollerName(location)}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

MapHeader.propTypes = {
  location: PropTypes.object,
  phone: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
  mapBoxRef: PropTypes.func.isRequired,
};

export default memo(MapHeader);
