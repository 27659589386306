import {
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_PASSCODE_MODAL,
  CLOSE_PASSCODE_MODAL,
  SET_USER_INFO,
  SET_LOGIN_PASSCODE_ERROR,
} from './constants';

export function openLoginModal(skipRedirect, options = {}) {
  return {
    type: OPEN_MODAL,
    skipRedirect,
    ...options,
  };
}

export function closeLoginModal() {
  return {
    type: CLOSE_MODAL,
  };
}

export function openPasscodeModal(payload = {}) {
  return {
    type: OPEN_PASSCODE_MODAL,
    payload,
  };
}

export function closePasscodeModal() {
  return {
    type: CLOSE_PASSCODE_MODAL,
  };
}

export function setUserInfo(payload) {
  return {
    type: SET_USER_INFO,
    payload,
  };
}

export function clearUserInfo() {
  return {
    type: SET_USER_INFO,
    payload: {
      email: '',
      phoneLastFour: '',
      userInitials: '',
    },
  };
}

export function setLoginPasscodeError({ error }) {
  return {
    type: SET_LOGIN_PASSCODE_ERROR,
    payload: { error },
  };
}

export function clearLoginPasscodeError() {
  return {
    type: SET_LOGIN_PASSCODE_ERROR,
    payload: { error: '' },
  };
}
