import { formatDollarAmount } from 'src/utils/formatter/currency';

export const authOverride = (stage) => {
  if (stage === 'verification') {
    return true;
  }
  return false;
};

export const totalSubscriptionCreditAdjustments = (subscriptionCreditAdjustments = []) => {
  if (Array.isArray(subscriptionCreditAdjustments) && subscriptionCreditAdjustments.length) {
    const creditAmount = subscriptionCreditAdjustments.reduce((acc, credit) => {
      return acc + credit.amount;
    }, 0);

    return {
      amount: creditAmount,
      amountFormatted: formatDollarAmount({ amount: creditAmount, truncateEvenDollars: true }),
    };
  }
  return {
    amount: 0,
    amountFormatted: '$0',
  };
};
