import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// Data && Utils
import { orderIsFromApiSelector } from 'src/selectors/cart';
// Components
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import ApiOrderCart from './ApiOrderCart';
import RegularCart from './RegularCart';
import UpsellCart from './UpsellCart';
import EmptyCart from './EmptyCart';

const CartTypes = (props) => {
  const { cart, showUpsellCartVersion, totalItemCount } = props;

  const { splitTreatment } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_PRODUCT_PAGE_MEMBERSHIP_UPSELL,
  );

  // Show upsell cart for products when the split has 'ON' and 'CONTROL' values. Only hide when 'OFF'
  const showUpsellCartForProducts =
    splitTreatment !== splitioConstants.OFF && showUpsellCartVersion;

  const isApiOrder = useSelector(orderIsFromApiSelector);

  if (totalItemCount === 0) return <EmptyCart {...props} />;

  if (isApiOrder) return <ApiOrderCart {...props} />;

  if (cart.get('hasProducts')) {
    return showUpsellCartForProducts ? <UpsellCart {...props} /> : <RegularCart {...props} />;
  }

  if (showUpsellCartVersion) return <UpsellCart {...props} />;
  return <RegularCart {...props} />;
};

CartTypes.propTypes = {
  cart: PropTypes.object,
  showUpsellCartVersion: PropTypes.bool,
  isMonthlyPricingTest: PropTypes.bool,
  totalItemCount: PropTypes.number,
};

export default React.memo(CartTypes);
