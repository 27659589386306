import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ModalV2, SIZES } from 'src/components/HTKit/Modals/ModalV2';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { endedSessionAction, fieldSalesPathAction } from 'src/containers/FieldSales/actions';
// utils & constants
import { fieldSalesPath } from 'src/utils/paths';
import styles from './styles.scss';

/*
* Not currently being used.
* */
const EndedSessionModal = ({ visible, onClose }) => {
  // hooks
  const dispatch = useDispatch();

  // --------------- methods ----------
  // close modal and redirect to skulist
  const closeAndRedirect = () => {
    onClose(false);
    dispatch(fieldSalesPathAction(fieldSalesPath))
  }
  // removes current cart, starts new cart
  const onStartNewOrderClick = () => {
    dispatch(endedSessionAction({}, closeAndRedirect));
  }

  const startNewOrderButton = (
    <Button theme={THEMES.V2PRIMARY} onClick={onStartNewOrderClick}>
      Login & Start A New Cart
    </Button>
  );

  return (
    <ModalV2
      size={SIZES.MEDIUM}
      isVisible={visible}
      hide={onClose}
      styles={styles.contentModalWrapper}
      header="Expired Session"
      footerElement2={startNewOrderButton}
    >
      <p className="p1">
        Your session has expired. Your current cart will emptied. Please re-login.
      </p>
    </ModalV2>
  );
};

EndedSessionModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EndedSessionModal;

/*
  PROPS:
    visible: Boolean that tells the modal when to open.
    onClose: Function that triggers when you click on X
*/
