import { fromJS } from 'immutable';
import { PAGE_NAME as HOME_PAGE_NAME } from 'src/containers/HomePage/constants';
import { PAGE_NAME as SKU_PAGE_NAME } from 'src/containers/SkuPage/constants';
import { PAGE_NAME as PRODUCT_PAGE } from 'src/containers/ProductPage/constants';
import { PAGE_LOADED } from 'src/constants/common';

const reviewsInitialState = fromJS({ landings: {} });

export default function reviewsReducer(state = reviewsInitialState, action) {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        case HOME_PAGE_NAME:
        case 'geekSquad':
        case 'microsoft': {
          if (state.get('home') === action.reviews) {
            return state;
          }
          return state.set('home', fromJS(action.reviews));
        }
        case SKU_PAGE_NAME:
        case PRODUCT_PAGE: {
          return state.set(action.sku.id.toString(), fromJS(action.reviews));
        }
        case 'customerReviews': {
          return state.set('customerReviews', fromJS(action.reviews));
        }
        case 'landing': {
          return state.setIn(['landings', action.landing.id.toString()], fromJS(action.reviews || []));
        }
        default:
          return state;
      }
    default:
      return state;
  }
}
