/* eslint-disable import/prefer-default-export */
/**
 * Debug mode: Type this in your console, then reload: localStorage.splitio_debug = 'on'
 */

/**
 * * * * * * * * * NOTE READ * * * * * * * * *
 * This is partially incomplete. There are still items to do: HOC, hooks to
 * integrate. Releasing now because of CEBU need. Luckily we only needed selectors, so cool.
 *
 * * * * * * * * * * * * * * * * * * * * *
 */
import { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import { initSplitSdk, getTreatments, destroySplitSdk } from '@splitsoftware/splitio-redux';
import { initSplitSdk, getTreatments } from '@splitsoftware/splitio-redux';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { splitioConstants, splitioSelectors } from 'src/components/SplitIO';
import config from 'config';
import { sleep } from 'src/utils/helpers';
import { logger } from 'src/utils/logger';
import { getSegmentAnonymousId } from 'src/utils/cookies';
import {
  SPLITIONAME_NAV_PHONE_NUMBER,
  SPLITIONAME_POPULAR_ONLINE_SERVICES,
  SPLITIONAME_PRODUCT_SPECIALIST_CHAT,
  SPLITIONAME_PROMO_BAR,
  SPLITIONAME_MEMBER_BENEFITS_BUTTON_CHAT_MODULE,
  SPLITNAME_TOS_AUGUST_2022_FEATURE_FLAG,
} from 'src/components/SplitIO/constants';

const FSImpressionMethods = {};

/**
 * The splits are run as components load. If we have re-renders, we need to make sure we don't
 * just pass tons of events to FullStory. Split dedups this, so it's not a problem sending a bunch
 * of events to Split. We don't know if FS dedups, probably not. So, we debounce the events by split name.
 *
 * Once the event fires, we delete the method from the object. This is to prevent a memory leak.
 *
 * Note: 3000ms is an arbritrary number. We don't want to wait too long, but we don't want to spam the events,
 *       and allow slower browsers to catch up. I tested that by going to other pages, the events were still
 *       firing from the previous page, if within the 3s window. So, we don't lose them.
 *
 * To minimize the amount of events, we could do any of the following:
 *  1. Only fire the event if the user is in the treatment.
 *  2. Exclusion or inclusion list of splits to fire events for.
 *
 * @param {String} splitName
 * @param impressionData
 */
const LOG_EXCLUSIONS = [
  SPLITIONAME_NAV_PHONE_NUMBER,
  SPLITIONAME_MEMBER_BENEFITS_BUTTON_CHAT_MODULE,
  SPLITIONAME_PRODUCT_SPECIALIST_CHAT,
  SPLITNAME_TOS_AUGUST_2022_FEATURE_FLAG,
  SPLITIONAME_PROMO_BAR,
  SPLITIONAME_POPULAR_ONLINE_SERVICES,
];

function logImpression(impressionData) {
  try {
    const splitName = get(impressionData, 'impression.feature');

    if (!window.FS || !splitName || LOG_EXCLUSIONS.includes(splitName)) return;

    if (!FSImpressionMethods[splitName]) {
      FSImpressionMethods[splitName] = debounce(
        (data) => {
          window.FS.event('split_evaluation', data);
          /* Remove it. No reason to sit around potentially dozens of entries */
          delete FSImpressionMethods[splitName];
        },
        3000,
        {
          leading: false,
          trailing: true,
        },
      );
    }

    FSImpressionMethods[splitName](impressionData);
  } catch (err) {
    logger(`Split/FullStory log impression`)(err);
  }
}

const splitConfig = () => ({
  startup: {
    // Maximum amount of time to wait before notifying a timeout, default is ? as the package says 1.5, but the doc says 10.
    readyTimeout: splitioConstants.REQUEST_TIMEOUT_SDK,
    requestTimeoutBeforeReady: splitioConstants.REQUEST_TIMEOUT_SDK,
  },
  core: {
    authorizationKey: config.splitioKey,
    trafficType: splitioConstants.TRAFFIC_TYPE,
    key: getSegmentAnonymousId(),
  },
  impressionListener: {
    logImpression,
  },
});
/**
 * @readySplit {Boolean} - Either our split is loaded, timedout. We need to move forward with the app.
 * @update {Number} - Epoch date of when an update happens. Only updates when a changeset is pulled in from split.
 * @seenTreatments {<String>} - These are what the user has seen. So, we only update them during split update.
 * @param children
 * @constructor
 */
const seedSplits = [];

export const SplitIO = ({ children = null }) => {
  const dispatch = useDispatch();
  const error = useRef(false);
  const [update, setUpdate] = useState(+new Date());
  const [seedSplitKeys, setSeedSplitKeys] = useState(seedSplits);
  const seenTreatments = useSelector(splitioSelectors.splitTreatmentSeenSelector);
  const isSplitError = useSelector(splitioSelectors.splitErrorSelector);
  /**
   * Split timedout. lets push forward with the app
   */
  const onTimedoutCallback = () => {
    error.current = true;
  };

  /** Once the SDK is ready/updated, we dispatch a `getTreatments` action with all Splits in our workspace */
  const getSplitTreatments = useCallback(() => {
    /*
      Split throws if you pass it an undefined or empty array. There is nigh grace in split.
      Split will still try to update even with a timeout. This can cause jarring experiences with users.
      If we get a timeout, we will not allow updates.
     */
    if (Array.isArray(seenTreatments) && seenTreatments.length && !catastrophicConditions()) {
      /* We could have new splits on an update or modified existing ones */
      dispatch(getTreatments({ splitNames: seenTreatments }));
    }
  }, [update]);

  /** Seperate concerns as specifically to 'update' even though it just wraps a function */
  const onUpdateCallback = () => {
    /* We need to reflow this component. Update was made, insert into state, so we catch the flow down for useSelector */
    setUpdate(+new Date());
  };

  /*
   *  We are aware these are just wrappers for setReady.
   *  But lets qualify them since Split also does.
   *
   *  Note: Split (redux sdk) makes us do things, redux-sdk is missing things.
   *        When the config is missing the key, it doesn't throw us an error (like a timeout would). It throws
   *        back control treatments.
   */
  const onReadyCallback = () => {
    existsConfigurationKey();

    /* We don't use split for metrics, so we can seed upfront. So, just add them here. New */
    if (Array.isArray(seedSplitKeys) && seedSplitKeys.length > 0 && !catastrophicConditions()) {
      dispatch(getTreatments({ splitNames: seedSplitKeys }));
      setSeedSplitKeys([]);
    }
  };

  useEffect(() => {
    if (catastrophicConditions()) return;

    getSplitTreatments();
  }, [update]);
  /**
   * Since this component is a wrapper for the site. We need to make sure it always falls thru to the display after
   * our request_timeout timer. In other words, lets not hold up the site for split to load. We just have to deal.
   */
  useEffect(() => {
    (async function moveForward() {
      await sleep(splitioConstants.REQUEST_TIMEOUT_CLIENT);

      existsConfigurationKey();
    })();
  }, []);

  useEffect(() => {
    let startTime;
    (async function initSplit() {
      try {
        await retryCookie();
        startTime = +new Date();
        await dispatch(
          initSplitSdk({
            config: splitConfig(),
            onUpdate: onUpdateCallback,
            onTimedout: onTimedoutCallback,
            onReady: onReadyCallback,
          }),
        );
      } catch (e) {
        logger(`SPLIT (loadsec: ${getErrorCalltime(startTime)})`)(e);
        error.current = true;
        dispatchControlDefaults();
      }
    })();
  }, []);

  /** If a user comes in w/o a segment cookie we have to wait till it drops */
  const retryCookie = async () => {
    if (getSegmentAnonymousId() || catastrophicConditions()) return true;

    await sleep(splitioConstants.PERIODIC_EXECUTOR_SLEEP);
    return retryCookie();
  };

  /**
   * If we need to destroy.
   * @returns {Promise<void>}
   */
  // const destroyClientSplitSdk = async () => {
  //   await dispatch(destroySplitSdk());
  // }
  /**
   * We have found that there are a few very edge cases in which split can bone out. So, lets
   * consolidate these conditions. This is based off the initial load, given the alloted time we expect.
   * We set them w/ useRef because of the lifecycle conditions tied with useState, some of these we can't
   * get at when using useState.
   *
   * @returns {string|boolean}
   */
  const catastrophicConditions = () => {
    const inError = error.current || isSplitError || window.analytics.isBot;

    return inError;
  };

  /**
   * If there is no key, split returns control treatments, but we still want
   * to treat this like an error and not update. The issue here, and another concern we needed
   * to deal with. Once the cookie can't be read, we signal an error. Again, it might come in, but
   * we don't want a jarring condition.
   */
  const existsConfigurationKey = () => {
    if (!getSegmentAnonymousId()) {
      error.current = true;
    }
  };
  /**
   * We have a timeout or an sdk catastrophic error. Lets send down control & default configs to our splits.
   * We might have splits that want to show even in a "control" state.
   */
  const dispatchControlDefaults = () => {
    /* reduce down our treatments to control values and any configs */
    const treatments = splitioConstants.SPLTIONAMES_CONTROL_DEFAULT.reduce(
      (allTreatments, split) => {
        const reassignedTreatments = { ...allTreatments };

        reassignedTreatments[split] = {
          treatment: splitioConstants.CONTROL,
          config:
            JSON.stringify(
              get(splitioConstants.SPLITIO_SPLITS_WITH_ATTRIBUTES, `${split}.config`),
            ) || null,
        };
        /*
       There's no real way to lift outside the treatments as split controls the reducer state.
       So, we'll just tag this along too show a controlGroup resolution. From "timeouts" or "forced errors"
      */
        return { ...reassignedTreatments, isControlGroup: true };
      },
      {},
    );

    dispatch({
      type: 'ADD_TREATMENTS',
      payload: {
        key: getSegmentAnonymousId(),
        treatments,
      },
    });
  };

  return children;
};

SplitIO.propTypes = {
  children: PropTypes.element.isRequired,
};

/**
 * This is needed to just hand to bugsnag some additional info of timing.
 * No reason for the performance api.
 */
function getErrorCalltime(initialTime = 0) {
  const endTime = +new Date();

  if (!Number.isFinite(endTime / initialTime)) return 0;

  return (endTime - initialTime) / 1000;
}
