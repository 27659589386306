import { useSelector } from 'react-redux';
import { pureUserSelector } from 'src/selectors/user';
import { getLeadGeneratedCookie } from 'src/utils/cookies/leadGenerated';
import { getSegmentAnonymousId } from 'src/utils/cookies';
import { isBrowser } from 'src/utils/environment';
import useInterval from 'src/hooks/useInterval';
import { logger } from 'src/utils/logger';


const LeadGeneratedPolling = () => {

  /* Start polling for identify */
  /* Hooks */
  const user = useSelector(pureUserSelector);

  /* Constants */
  const POLLING_INTERVAL = 60000 * 2; // 2 minutes

  /* Methods */
  /*
    The user might not be logged in, but if they have previously set user trait info
  */
  const existingUser = () => (isBrowser() && window.analytics?.user?.().traits?.()) || {};
  /*
   shouldNotPoll
   We won't poll if:
     1. currently logged n
     2. analytics is not defined
     3. existingUser even when not logged in
     4. anonymousId is not set
     5. cookieLeadGeneratedAttrs is not set, thus user has not lead generated.

     Note:
  */
  const shouldPoll = (omitVisibilityState) => {
    const visibilityState = omitVisibilityState ? document.visibilityState : 'visible';

    if (!isBrowser()) return false;

    if (
         /* Make sure analytics is loaded */
         window.analytics &&
         /* Do we have an anonymous id */
         getSegmentAnonymousId() &&
         /* User is on the current tab */
         document.visibilityState === visibilityState &&
         /* User has entered a lead generated email */
         getLeadGeneratedCookie()?.email && !(
           /* User is logged in */
           !!user ||
           /* User has previously logged in, thus not a lead */
           !!existingUser().id
         )
      ) {
      return true;
    }

    return false;
  }

  /**
   * fireIdentify
   * If we passed all the requirements of polling, fire the polling.
   * @returns {false|void}
   */
  const fireIdentify = (ovs = false) => shouldPoll(ovs) && window.analytics?.identify({ anonymous_id: getSegmentAnonymousId(), email: getLeadGeneratedCookie()?.email });

  /* Fire once as the user loads the page */
  fireIdentify();
  useInterval(() => {
    try {
      /* Lets try to associate track events to a users lead generated email */
      /* If the window is focused, poll, else don't. We don't want to just churn out identify if the user is not on the page (tabbed over) */
      fireIdentify();
    } catch (e) {
      logger('Polling identify', e)
      return true;
    }

    return false;
  }, POLLING_INTERVAL);

  /* Get the listener. If a person leaves lets fire an identify to catch any associations that happened before the next firing. */
  if (isBrowser()) {
    document.onvisibilitychange = () => {
      if (document.visibilityState === 'hidden') {
        fireIdentify(true);
      }
    };
  }

  return null;
}

export default LeadGeneratedPolling;
