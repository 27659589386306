import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import cn from 'classnames';
import { prevent } from 'src/utils/event';
import styles from './styles.scss';

const FirstServiceDiscountCode = ({ code }) => {
  const [copied, setCopied] = useState(false);
  const title = copied ? 'Copied' : 'Copy Code';
  const copyStyles = cn(styles.firstServiceDiscountCodeCopy, 'text-link -no-decoration');
  return (
    <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
      <div className={styles.firstServiceDiscountCode}>
        <div className={styles.firstServiceDiscountCodeText}>{code}</div>

        <a href className={copyStyles} onClick={prevent}>
          {title}
        </a>
      </div>
    </CopyToClipboard>
  );
};

FirstServiceDiscountCode.propTypes = {
  code: PropTypes.string.isRequired,
};

export default React.memo(FirstServiceDiscountCode);
