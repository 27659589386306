import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import Button from 'HTKit/Forms/Button';

const BookNowButton = (props) => {
  const { skuId, bookNow, landing, title } = props;
  const onBookNow = () => {
    bookNow(skuId, landing);
  };

  const propsToRender = omit(props, ['skuId', 'landing', 'bookNow', 'onClick', 'title']);
  return (
    <Button {...propsToRender} onClick={onBookNow}>
      {title}
    </Button>
  );
};

BookNowButton.defaultProps = {
  landing: null,
  title: 'Book Now',
};

BookNowButton.propTypes = {
  skuId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  landing: PropTypes.shape({}),
  bookNow: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default BookNowButton;
