import { put, takeEvery } from 'redux-saga/effects'; // eslint-disable-line
import { ITEM_ADDED } from 'src/containers/CartPage/constants';
import { SET_CARD_DATA } from './constants';
import { clearCard } from './actions';
import { setCookiesPinData, deleteCookiesPinData } from './utils';

function setCardDataSaga({ card }) {
  // Store validated card in Redux and Cookie so that the AddSku page can use it. Set in both places in case cookies are disabled
  if (card) setCookiesPinData(card);
}

function* clearCardSaga() {
  yield put(clearCard());
  deleteCookiesPinData();
}

function* pinRedemptionFlow() {
  yield takeEvery(ITEM_ADDED, clearCardSaga);
  yield takeEvery(SET_CARD_DATA, setCardDataSaga);
}

export default [pinRedemptionFlow];
