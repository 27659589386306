import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { map } from 'lodash';
import classNames from 'classnames';
import { THEMES } from 'HTKit/Forms/Button';
import { push } from 'src/utils/paths';
import BookNowButton from 'src/components/BookNowButton';
import LegacyExpertInHomeService from 'src/components/Sections/legacy/ExpertInHomeService';
import SlideModal from 'HTKit/Modals/Slide';
import styles from './styles.scss';

class ProductMultipleSku extends Component {
  static propTypes = {
    landing: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    push: PropTypes.func.isRequired,
  };

  static getSkuPrice(sku) {
    if (sku.showFree) {
      return 'Free';
    }
    if (sku.hasAdjustments) {
      return `Starts at ${sku.startsAtPrice}`;
    }
    return `${sku.startsAtPrice}`;
  }

  state = { primarySku: null };

  onHeroImageClick = (url) => {
    this.props.push(url);
  };

  showSkuDetails = (sku) => {
    return (event) => {
      event.preventDefault();
      this.setState({ primarySku: sku });
    };
  };

  closeModal = () => {
    this.setState({ primarySku: null });
  };

  renderSku = (sku) => {
    const landing = this.props.landing.toJS();
    return (
      <div className={styles.sku}>
        <div className={styles.skuImage}>
          <img src={sku.image.medium} alt="Product" />
        </div>
        <div className={styles.skuDetails}>
          <h4>{sku.name}</h4>
          <p>{ProductMultipleSku.getSkuPrice(sku)}</p>
          <div className="paddingTop-small">
            <a href="/" className="medium-link -no-decoration" onClick={this.showSkuDetails(sku)}>
              View Service Details
            </a>
          </div>
          <div className={styles.skuButton}>
            <BookNowButton skuId={sku.id} landing={landing} mediumV2 theme={THEMES.V2PRIMARY} />
          </div>
        </div>
      </div>
    );
  };

  renderSkus(skus) {
    const renderedSkus = map(skus, (sku) => this.renderSku(sku));
    return (
      <div className={styles.products}>
        <h3 className={styles.productsTitle}>Select the service you need</h3>
        <div className={styles.productsList}>{renderedSkus}</div>
      </div>
    );
  }

  renderSkuDetails = (sku) => {
    const landing = this.props.landing.toJS();
    return sku ? (
      <div className={`${styles.skuInfo} paddingX-small2 paddingBottom-small2`}>
        <h2>{sku.name}</h2>
        <h4 className="paddingTop-tiny1 paddingBottom-small2">
          {ProductMultipleSku.getSkuPrice(sku)}
        </h4>
        <p className="p1 n700">{sku.description}</p>
        <p className="paddingTop-small1 paddingBottom-small text-weight-bold">Service includes:</p>
        <ul className={`${styles.skuInfoBullets} marginBottom-medium`}>
          {map(sku.skuBullets, (item) => (
            <li className="marginLeft-small">
              <p className="p1 n700">{item.bullet}</p>
            </li>
          ))}
        </ul>
        <BookNowButton skuId={sku.id} landing={landing} mediumV2 theme={THEMES.V2PRIMARY} />
      </div>
    ) : null;
  };

  renderPartnerLogo() {
    const { landing } = this.props;
    const partnerLogoClasses = classNames(
      styles.logoContainer,
      styles.partnerLogo,
      styles.multiSkuPartnerLogo,
    );
    const logoImgSrc = landing.getIn(['images', 'logo', 'original']);
    return logoImgSrc ? (
      <div className={partnerLogoClasses}>
        {logoImgSrc && <img alt={`${landing.get('seoName')} Logo`} src={logoImgSrc} />}
      </div>
    ) : null;
  }

  render() {
    const { landing, isMobile } = this.props;
    const skus = landing.getIn(['skus']).toJS();
    const contentClasses = classNames('l-content-section', styles.pageContent);
    const heroImgSrc = isMobile
      ? landing.getIn(['images', 'front', 'mobile'])
      : landing.getIn(['images', 'front', 'original']);
    const heroImageLink = landing.get('heroImageLink');
    const subtitle = landing.getIn(['header', 'subtitle']);
    const detailsOpened = !!this.state.primarySku;

    return (
      <div className={`${styles.page} site-v2`}>
        <Helmet title={landing.get('pageTitle')} />
        {heroImageLink ? (
          <div>
            <a href={heroImageLink} onClick={() => this.onHeroImageClick(heroImageLink)}>
              <img alt={landing.get('pageTitle')} src={heroImgSrc} className={styles.heroImg} />
            </a>
          </div>
        ) : (
          <div>
            <img alt={landing.get('pageTitle')} src={heroImgSrc} className={styles.heroImg} />
          </div>
        )}
        <div className={contentClasses}>
          <section className={styles.pageRow}>
            <div className={styles.pageColumn}>
              <div className={styles.pageContentHeader}>
                {this.renderPartnerLogo()}
                <h1 className={`h2 blue500 ${styles.pageContentHeaderTitle}`}>
                  {landing.getIn(['header', 'title'])}
                </h1>
                {/* eslint-disable */}
                {subtitle && (
                  <h2
                    className={`h5 ${styles.pageContentHeaderSubTitle}`}
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                  />
                )}
                {/* eslint-enable */}
              </div>
              <div className={styles.pageSkuDetailsMobile}>{this.renderSkus(skus)}</div>
              <div className={styles.pageContentDescription}>
                <div className={styles.pageContentDescriptionInfo}>
                  {/* eslint-disable */}
                  <p
                    dangerouslySetInnerHTML={{ __html: landing.getIn(['content', 'description']) }}
                  />
                  {/* eslint-enable */}
                </div>
              </div>
            </div>
            <div className={styles.pageColumn}>{this.renderSkus(skus)}</div>
            <SlideModal open={detailsOpened} handleClose={this.closeModal} showLogo={false}>
              {this.renderSkuDetails(this.state.primarySku)}
            </SlideModal>
          </section>
        </div>
        <div className={styles.pageInfo}>
          <LegacyExpertInHomeService backgroundColor="white" />
        </div>
      </div>
    );
  }
}

export default connect(null, { push })(ProductMultipleSku);
