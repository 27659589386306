import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Constants
import { UPSELL_PLAN_BILLING_CYCLES } from 'src/constants/plan';
// Utils
import { getFormattedPlanTrialExpirationDate } from 'src/containers/CartPage/utils';
// Components && Images
import ServiceImage from 'src/components/OrderSummary/Breakdown/ServiceImage';
import OrderBreakdownLineItem from 'src/components/OrderSummary/Breakdown/OrderBreakdownLineItem';
import { CalloutBox, CALLOUTBOX_THEMES } from 'Elements/CalloutBox';
import shpImage from 'src/images/shp-cart-thumbnail.png';
import trialPlanThumbnail from 'src/images/subscription-trial-thumbnail.png';
import styles from '../styles.scss';

/**
 * @typedef {import('src/containers/CartPage/CartTypes/RegularCart/RegularCart.js').PlanTrialData} PlanTrialData
 * @param {{amount: string, planInterval: string, planTrialData: PlanTrialData}} param0
 */
const getSubscriptionLineItemAmounts = ({ amount, planInterval = '', planTrialData }) => {
  if (planTrialData) {
    const interval = planInterval.toLowerCase() === UPSELL_PLAN_BILLING_CYCLES.MONTHLY ? '/mo' : '';
    return { amount: '$0.00', lineThroughText: `${amount}${interval}` };
  }
  return { amount };
};

const TrialMembershipCalloutBox = ({ planTrialData, planJs, className }) => {
  if (!planTrialData) return null;
  const formattedPlanTrialExpirationDate = getFormattedPlanTrialExpirationDate(planTrialData);
  const calloutText = `Your ${
    planTrialData.days
  } day free trial ends on ${formattedPlanTrialExpirationDate}. Your membership will auto-renew and be billed ${
    planJs.planInterval === UPSELL_PLAN_BILLING_CYCLES.MONTHLY ? 'monthly' : 'annually'
  } unless cancelled before the first billing cycle.`;
  return <CalloutBox className={className} theme={CALLOUTBOX_THEMES.PRIMARY} text={calloutText} />;
};

const SubscriptionLineItem = ({ amount, name, className, plan }) => {
  const planJs = (plan && plan.toJS()) || {};
  const planTrialData = planJs.trial;

  const image = planTrialData ? trialPlanThumbnail : shpImage;

  const subscriptionLineItemAmounts = getSubscriptionLineItemAmounts({
    amount,
    planInterval: planJs.planInterval,
    planTrialData,
  });

  const planName = planTrialData ? `${planJs.name} Membership` : name;
  return (
    <div
      className={cn('marginBottom-small flex flexDirectionColumn', styles.itemContainer, className)}
    >
      <div className="flex">
        <ServiceImage image={image} imageClasses={styles.shpImage} />
        <OrderBreakdownLineItem
          className={cn('marginTop-tiny1', styles.lineItem)}
          name={planName}
          {...subscriptionLineItemAmounts}
        />
      </div>
      {planTrialData && (
        <TrialMembershipCalloutBox
          className="marginTop-small2 text-align-left"
          planTrialData={planTrialData}
          planJs={planJs}
        />
      )}
    </div>
  );
};

TrialMembershipCalloutBox.propTypes = {
  planTrialData: PropTypes.shape({
    days: PropTypes.number,
  }),
  planJs: PropTypes.shape({
    planInterval: PropTypes.string,
  }),
  className: PropTypes.string,
};
SubscriptionLineItem.propTypes = {
  amount: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  plan: PropTypes.object,
};

export default SubscriptionLineItem;
