import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../styles.scss';

const SubscriptionBenefitsLineItem = ({ name, lineThroughText, amount, className, boldName, boldAmount }) => {
  const nameStyles = cn(styles.itemNameContainer, {
    'text-weight-bold': boldName,
  });
  const lineThroughStyles = cn('p2 n300 line-through marginRight-tiny1', styles.lineThroughTextSubscription);
  const amountStyles = cn('table-row', { 'text-weight-bold': boldAmount });
  return (
    <p className={className}>
      <div className={styles.itemInfo}>
        <div className={nameStyles}>
          <span className="table-row secondary">{name}</span>
        </div>
        <div className={styles.itemAmount}>
          {lineThroughText && <span className={lineThroughStyles}>{lineThroughText}</span>}
          <span className={amountStyles}>{amount}</span>
        </div>
      </div>
    </p>
  );
}

SubscriptionBenefitsLineItem.propTypes = {
  name: PropTypes.string,
  lineThroughText: PropTypes.string,
  amount: PropTypes.string,
  className: PropTypes.string,
  boldName: PropTypes.bool,
  boldAmount: PropTypes.bool,
};

export default SubscriptionBenefitsLineItem;
