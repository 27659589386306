import { fromJS } from 'immutable';
import { WARRANTY_UPDATED, DEVICE_UPDATED, MODELS_LOADED } from './constants';

const initialState = fromJS({productModels: []})

function devicesPageReducer(state = initialState, action) {
  switch (action.type) {
    case WARRANTY_UPDATED:
      return state.merge({ warrantySubmitted: action.payload.warrantySubmitted });
      
    case DEVICE_UPDATED:
      return state.merge({ deviceUpdated: action.payload.deviceUpdated });
        
    case MODELS_LOADED: {
      const { payload: { models: productModels } } = action;
      return state.merge({ productModels });
    }
    default:
  return state;
  }
}

export default devicesPageReducer;
