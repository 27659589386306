import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import ServicePrice from 'src/components/Service/ServicePrice';
import StarRating from 'src/components/Elements/StarRating';
import { ServiceHeaderTrigger } from 'src/components/Service/ServiceHeader';
import { ServiceBookNowTrigger } from 'src/components/Service/ServiceBookNow';
import ServiceZipCodeEntry from 'src/components/Service/ServiceZipCodeEntry';
import ServiceInfoOptions from './ServiceInfoOptions';
import ServiceInfoUpsellV2 from './ServiceInfoUpsellV2';
import styles from './styles.scss';

const ServiceInfoDetails = ({
  service,
  onBook,
  onRating,
  onUpsellToggle,
  onUpsellMore,
  className,
  ...rest
}) => {
  const { rating, showUpsell, ctaText, showZipCodeTest, selectedPlanId, plansInfo, prices } =
    service.extra || {};

  return (
    <div className={className}>
      <h4>{service.name}</h4>
      {!showZipCodeTest && (
        <ServicePrice service={service} onInfo={onUpsellMore} className={styles.price} />
      )}
      {rating && (
        <StarRating
          stars={rating.rating}
          count={rating.ratingCount}
          onClick={onRating}
          className={styles.rating}
          size="sm"
        />
      )}
      {showZipCodeTest && (
        <ServiceZipCodeEntry
          className="marginTop-small2"
          serviceExtra={service.extra}
          onUpsellToggle={onUpsellToggle}
          onUpsellMore={onUpsellMore}
          {...rest}
        />
      )}
      <div className="marginTop-small1">
        {showUpsell && !showZipCodeTest && (
          <ServiceInfoUpsellV2
            selectedPlanId={selectedPlanId}
            plansInfo={plansInfo}
            amount={prices.priceDifference}
            onToggle={onUpsellToggle}
            onLearnMore={onUpsellMore}
          />
        )}

        <ServiceHeaderTrigger />
        <ServiceBookNowTrigger />

        {!showZipCodeTest && (
          <Button theme={THEMES.V2PRIMARY} className="marginTop-small" onClick={onBook}>
            {ctaText}
          </Button>
        )}
        <ServiceInfoOptions options={service.skuBullets} className="marginTop-medium" />
      </div>
    </div>
  );
};

ServiceInfoDetails.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string,
    extra: PropTypes.shape({
      rating: PropTypes.shape({
        rating: PropTypes.number,
        ratingCount: PropTypes.number,
      }),
      prices: PropTypes.shape({
        priceDifference: PropTypes.string,
      }),
      showUpsell: PropTypes.bool,
      ctaText: PropTypes.string,
      upsellSelected: PropTypes.bool,
      selectedPlanId: PropTypes.number,
      plansInfo: PropTypes.object,
    }),
    skuBullets: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onBook: PropTypes.func.isRequired,
  onRating: PropTypes.func.isRequired,
  onUpsellToggle: PropTypes.func.isRequired,
  onUpsellMore: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default memo(ServiceInfoDetails);
