// Libraries
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Components
import Grid from 'src/components/HTKit/Grid/Grid';
import PromoBlocks from 'src/components/Searchbar/Results/PromoBlocks';
import EmptyState from 'src/components/EmptyState';
import CategoryResults from 'src/components/Searchbar/Results/CategoryResults';
import ServiceResults from 'src/components/Searchbar/Results/ServiceResults';
import ProductResults from 'src/components/Searchbar/Results/ProductResults';
import QuickLinks from 'src/components/Searchbar/Results/QuickLinks';
// Constants
import BREAKPOINTS from 'src/components/HTKit/Grid/constants';
import { DEFAULT_TEXTS } from 'src/components/Searchbar/constants';
import HOMEPAGE_SEARCH from './constants';
// Styles
import styles from './styles.scss';

const HomepageSearchResults = ({
  serviceResults,
  productResults,
  scoredResults,
  searchTerm,
  activeSearch,
  iconClick,
  ...props
}) => {
  const resultsStyles = !searchTerm ? styles.noSearchTerm : styles.autoComplete;
  const rawResults = searchTerm ? scoredResults : [];

  useEffect(() => {
    const element = document.getElementById(HOMEPAGE_SEARCH.resultsId);
    const screenWidth = window.innerWidth;

    if (searchTerm && screenWidth >= BREAKPOINTS.MD_GRID) {
      const yOffset = -144; // from top of results to top of input + offset from top of the page.
      const yPosition =
        element && element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({
        top: yPosition,
        behavior: 'smooth',
        duration: 300,
      });
    }
  }, [searchTerm]);
  const results =
    Array.isArray(rawResults) && rawResults.length ? (
      <>
        <ServiceResults serviceResults={serviceResults} searchTerm={searchTerm} iconClick={iconClick} {...props} />
        <ProductResults productResults={productResults} searchTerm={searchTerm} iconClick={iconClick} {...props} />
      </>
    ) : (
      searchTerm && (
        <>
          <EmptyState
            className={cn('paddingY-medium', styles.noResults)}
            iconName={HOMEPAGE_SEARCH.searchIcon}
            title={DEFAULT_TEXTS.EMPTY_STATE.title}
            additionalTitle={searchTerm}
            text={DEFAULT_TEXTS.EMPTY_STATE.text}
          />
          <QuickLinks iconClick={iconClick} searchTerm={searchTerm} />
        </>
      )
    );
  return activeSearch ? (
    <div id={HOMEPAGE_SEARCH.resultsId}>
      <Grid.FullWidth classes={styles.gridContainer}>
        <Grid.Row classes={resultsStyles}>
          <Grid.Column sm={4} md={8} lg={12} classes={cn('marginBottom-medium', styles.column)}>
            {results}
          </Grid.Column>
          {searchTerm && (
            <>
              <Grid.Column sm={4} md={8} lg={12} classes={styles.blockColumn}>
                <PromoBlocks iconClick={()=>iconClick} searchTerm={searchTerm} />
              </Grid.Column>
              <Grid.Column sm={4} md={8} lg={12}>
                {!rawResults.length && (
                  <CategoryResults
                    scoredResults={scoredResults}
                    searchTerm={searchTerm}
                    {...props}
                  />
                )}
              </Grid.Column>
            </>
          )}
        </Grid.Row>
      </Grid.FullWidth>
    </div>
  ) : null;
};

HomepageSearchResults.propTypes = {
  scoredResults: PropTypes.array,
  productResults: PropTypes.array,
  serviceResults: PropTypes.array,
  searchTerm: PropTypes.string,
  iconClick: PropTypes.func,
  activeSearch: PropTypes.bool,
};

export default HomepageSearchResults;
