import { makeSkuJsonLd, makeLocationSkuJsonLd, makeskuMeta, makeRichFaqScript } from './sku';
import { makeSkuCategoryJsonLd, makeLocationSkuCategoryJsonLd } from './skuCategory';
import { makePlanJsonLd } from './plan';
import { makeGeoJsonLd } from './geo';
import { makeBreadcrumbsJsonLd } from './breadcrumb';

/* Convert immutable objects to JS objects */
const pure = (fn) => (...args) => {
  const params = args.map((arg) => (arg.toJS === undefined ? arg : arg.toJS()));
  return fn(...params);
};

export const skuJsonLd = pure(makeSkuJsonLd);
export const skuMeta = pure(makeskuMeta);
export const locationSkuJsonLd = pure(makeLocationSkuJsonLd);
export const skuCategoryJsonLd = pure(makeSkuCategoryJsonLd);
export const locationSkuCategoryJsonLd = pure(makeLocationSkuCategoryJsonLd);
export const planJsonLd = pure(makePlanJsonLd);
export const geoJsonLd = pure(makeGeoJsonLd);
export const breadcrumbsJsonLd = pure(makeBreadcrumbsJsonLd);
export const faqJsonLd = pure(makeRichFaqScript);
