import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'HTKit/Icon';
import styles from './styles.scss';

/**
 * PhoneIcon
 *
 * Only to be used in headers.
 */
const PhoneIcon = ({ className = '' }) => (
  <Icon name="phone-outlined" className={`${styles.phoneIcon} ${className}`} />
);

PhoneIcon.propTypes = {
  className: PropTypes.string,
};

export default PhoneIcon;
