// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Styles
import styles from './styles.scss';

const OrderTotal = ({ className, containerStyles, descriptionStyles, priceStyles, totalFormatted }) => (
  <div className={cn(className, containerStyles)}>
    <div className={descriptionStyles}>Order Total</div>
    <div className={priceStyles}>{totalFormatted}</div>
  </div>
);

OrderTotal.propTypes = {
  className: PropTypes.string,
  containerStyles: PropTypes.string,
  descriptionStyles: PropTypes.string,
  priceStyles: PropTypes.string,
  totalFormatted: PropTypes.string,
};

OrderTotal.defaultProps = {
  className: '',
  containerStyles: styles.totalRow,
  descriptionStyles: 'p0',
  priceStyles: cn(styles.totalPrice, 'p0'),
};

export default React.memo(OrderTotal);

// TODO: This is only being used for SubscriptionGifting and when that feature redone or removed this component should be removed. -GH June 22, 2020
