import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { orderIsFromApiSelector } from 'src/selectors/cart';
import { fieldSaleAgentSelector } from 'src/containers/FieldSales/selectors';
import { getDirectBookingTechIdOrSlug } from 'src/utils/cookies/directBookingCookie';
import CartRelatedSku from './CartRelatedSku';
import styles from './styles.scss';

// TODO: Is there a better way to do this? Like not providing skus to this component at all?
const CartRelatedSkus = ({ relatedSkus }) => {
  const fieldSalesAgent = useSelector(fieldSaleAgentSelector);
  const isApiOrder = useSelector(orderIsFromApiSelector);
  const isDirectBookingFlow = Boolean(getDirectBookingTechIdOrSlug());

  if (!relatedSkus || !relatedSkus.length || isApiOrder || isDirectBookingFlow) return null;
  // remove if field sales (fieldSales) flow
  if (fieldSalesAgent.agentId) return null;

  const widgetSkusStyles = classNames(styles.widgetSkus, {
    [styles.withAfter]: relatedSkus.length % 3 === 2,
  });

  return (
    <div className={styles.widget}>
      <h4 className={styles.widgetTitle}>Customers also booked:</h4>

      <div className={widgetSkusStyles}>
        {relatedSkus.map((sku) => (
          <CartRelatedSku key={sku.id} sku={sku} />
        ))}
      </div>
    </div>
  );
};

CartRelatedSkus.propTypes = {
  relatedSkus: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(CartRelatedSkus);
