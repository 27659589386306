import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useEventListener from 'src/hooks/useEventListener';
import HTKustomerChat from 'src/utils/Kustomer/HTKustomerChat';
import PhoneDisplayLink from 'src/components/PhoneDisplayLink';
import Icon from 'HTKit/Icon';
import DarkOverlay from 'HTKit/Elements/DarkOverlay';
import styles from './styles.scss';

const ChatCallDropdown = ({ chat, showDropdown, toggleDropdown, setDropdownState }) => {
  const unreadMessages = chat.get('unreadMessages');

  const actionsRef = useRef();

  const handleOutsideClick = useCallback(
    (e) => {
      /*
      Clicks in the Header are also detected so we need to omit the event when the
      user clicks on <ChatIcon />
    */
      const outsideClicked = !actionsRef.current.contains(e.target);
      const clickedFromIcon = e.target.className.includes('ht-chat');
      if (!clickedFromIcon && showDropdown && outsideClicked) {
        setDropdownState(false);
      }
    },
    [showDropdown],
  );

  useEventListener({ eventName: 'click', handler: handleOutsideClick });

  const openChat = useCallback(() => {
    HTKustomerChat.open();
    toggleDropdown();
  }, []);

  const call = useCallback(() => {
    toggleDropdown();
  }, []);

  const containerStyles = cn('site-v2', styles.container, {
    [styles.show]: showDropdown,
  });

  return (
    <div className={containerStyles}>
      <div ref={actionsRef} className={styles.actionsWrapper}>
        <div className={styles.buttonWrapper}>
          <button type="button" className={`plainButton ${styles.chatIcon}`} onClick={openChat}>
            {unreadMessages && <div className={styles.unreadMessages}></div>}
            <Icon name="message" className={styles.icon} />
            <h4 className={styles.header}>Chat</h4>
          </button>
        </div>
        <div className={styles.buttonWrapper}>
          <button className="plainButton" onClick={call}>
            <PhoneDisplayLink icon={<Icon name="phone" className={styles.icon} />} />
            <h4 className={styles.header}>Call</h4>
          </button>
        </div>
      </div>
      <DarkOverlay absolute show={showDropdown} className={styles.darkOverlay} />
    </div>
  );
};

ChatCallDropdown.propTypes = {
  chat: PropTypes.object,
  showDropdown: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  setDropdownState: PropTypes.func.isRequired,
};

export default ChatCallDropdown;
