import { createSelector, createStructuredSelector } from 'reselect';
import { pageSelector as selectPage } from 'src/selectors/page';
import { isMobileV2Selector } from 'src/selectors/resolution';
import { ordersListStateSelector, orderIdPropsSelector } from 'src/selectors/order';
import { userStateSelector } from 'src/selectors/user';
import { PREPAID_STATUS } from 'src/constants/sku';
import { createCobrandedImagesObj } from 'src/selectors/partner';
import { PARTNERS } from 'src/constants/common';
import targetImage from 'src/images/branding/target-easyinstall.svg';
import walmartImage from 'src/images/branding/walmart-header-logo.svg';
import samsungImage from 'src/images/branding/samsung.svg';
import { PST_TIMEZONE_LA } from 'src/constants/app';
import { techProfilesSelector } from 'src/selectors/techs';
import { getFullFirstLastInitial } from 'src/utils/text';
import { addExplanationToAdjustment } from 'src/utils/cart';
import { ORDER_STATUS } from 'src/constants/order';
import { PAGE_NAME, SERVICE_ADJUSTMENT_TYPES, ORDER_ADJUSTMENT_TYPES } from './constants';

const adjustmentIncludesSubstring = ({ name, subString }) =>
  name
    .trim()
    .toLowerCase()
    .includes(subString);

const pageSelector = selectPage(PAGE_NAME);

const loadingSelector = createSelector(pageSelector, (page) => page.get('loading'));

const orderTokenSelector = (_, props) => {
  const {
    match: {
      params: { orderToken },
    },
  } = props;
  return orderToken;
};

const orderSelector = createSelector(
  [ordersListStateSelector, orderIdPropsSelector(), orderTokenSelector],
  (ordersList, orderId, orderToken) => {
    if (orderId) {
      return ordersList.find((order) => order.get('id') === orderId);
    }
    if (orderToken) {
      return ordersList.find((order) => order.get('token') === orderToken);
    }
    return null;
  },
);

const orderJSSelector = createSelector(orderSelector, (order) => order && order.toJS());

const orderIDSelector = createSelector(
  [orderIdPropsSelector(), orderJSSelector],
  (orderIdFromProps, order) => {
    if (orderIdFromProps) return orderIdFromProps;
    return order && order.id;
  },
);

const orderTotalAmountFormattedSelector = createSelector(orderJSSelector, (order = {}) => {
  const { statusId, subscriptionIntent, totalAmount, totalAmountToPay } = order;
  const isOrderComplete = statusId >= ORDER_STATUS.COMPLETED;
  if (subscriptionIntent) {
    if (isOrderComplete) {
      // Per Alex, order.totalAmountToPay is only valid if the order is not yet complete
      // After the order is complete, order.totalAmountToPay will become $0, so we need to do
      // some calculations to get the correct total amount for FE
      const orderTotal = parseFloat(order.totalAmount.replace('$', ''));
      const subscriptionAmount = parseFloat(order.subscriptionIntent.plan.amount.replace('$', ''));
      const subscriptionTax = parseFloat(order.subscriptionIntent.plan.tax.replace('$', ''));
      return `$${(orderTotal + subscriptionAmount + subscriptionTax).toFixed(2)}`;
    }
    return totalAmountToPay;
  }
  return totalAmount;
});

const orderBreakdownSelector = createSelector(orderJSSelector, (order) => order && order.breakdown);

const getFilteredServiceAdjustments = ({ adjustments, isServicePrepaid }) => {
  /* Filter out adjustments that we do not want to render */
  // const removeSubscriptionDiscount = ({ name }) =>
  //   !adjustmentIncludesSubstring({ name, subString: SERVICE_ADJUSTMENT_TYPES.SHP_DISCOUNT });
  const removePrepaidDiscount = ({ name }) => {
    return isServicePrepaid
      ? !adjustmentIncludesSubstring({ name, subString: SERVICE_ADJUSTMENT_TYPES.SUBSIDIZED })
      : true;
  };
  return adjustments.filter(removePrepaidDiscount);
};

const serviceItemsSelector = createSelector(orderBreakdownSelector, (breakdown) => {
  if (!breakdown) return null;

  return breakdown.services.map((service) => {
    const {
      name,
      amount,
      adjustments,
      prepaid,
      image,
      quantity,
      originalAmount,
      priceType,
      duration,
      price,
    } = service;
    const isServicePrepaid = prepaid === PREPAID_STATUS.BASE || prepaid === PREPAID_STATUS.FULL;
    return {
      name,
      amount: isServicePrepaid ? 'Prepaid' : amount,
      lineThroughText: amount !== originalAmount ? originalAmount : null,
      adjustments: getFilteredServiceAdjustments({ adjustments, isServicePrepaid }),
      image,
      quantity,
      priceType,
      duration,
      subTotal: {
        amount,
        lineThroughText: amount !== originalAmount ? originalAmount : null,
        price,
      },
    };
  });
});

const breakdownOrderAdjustmentsSelector = createSelector(
  orderBreakdownSelector,
  (breakdown) =>
    breakdown &&
    addExplanationToAdjustment(
      breakdown.adjustments.filter(
        ({ name }) => !adjustmentIncludesSubstring({ name, subString: ORDER_ADJUSTMENT_TYPES.TAX }),
      ),
    ),
);

const taxesSelector = createSelector(
  orderBreakdownSelector,
  (breakdown) =>
    breakdown &&
    breakdown.adjustments.find(({ name }) =>
      adjustmentIncludesSubstring({ name, subString: ORDER_ADJUSTMENT_TYPES.TAX }),
    ),
);

const couponSelector = createSelector(
  orderBreakdownSelector,
  (breakdown) => breakdown && breakdown.coupon,
);

const couponObjSelector = createSelector(orderBreakdownSelector, (breakdown) => {
  if (!breakdown || !breakdown.coupon) return null;
  const { name, discount } = breakdown.coupon;
  return { name, amount: `-${discount}` };
});

const orderUserSelector = createSelector(orderSelector, (order) => order && order.get('user'));

const userSelector = createSelector(
  [userStateSelector, orderUserSelector],
  (user, orderUser) => orderUser || user,
);

const activeCardSelector = createSelector(
  userSelector,
  (user) => user && user.getIn(['account', 'card']),
);

const userAddressSelector = createSelector(userSelector, (user) => {
  if (!user) return null;
  const addressObj = user.get('addressObj') ? user.get('addressObj').toJS() : {};
  const fullAddress = user.get('address');
  return { ...addressObj, fullAddress };
});

export const orderCardStatusSelector = (_, props) => {
  const {
    match: {
      params: { status },
    },
  } = props;
  return status;
};

const timeCanBeUpdatedSelector = createSelector(
  orderJSSelector,
  (order) => order && order.timeCanBeUpdated,
);

const canBeCancelledSelector = createSelector(
  orderJSSelector,
  (order) => order && order.canBeCancelled,
);

const hasPartnerSelector = createSelector(orderJSSelector, (order) => order && order.hasPartner);

const appointmentStartDateTimeSelector = createSelector(
  orderJSSelector,
  (order) => order && order.appointmentStartDateTime,
);

const orderStatusIdSelector = createSelector(orderJSSelector, (order) => order && order.statusId);

export const formattedRescheduleUrlSelector = createSelector(
  pageSelector,
  (page) => page && page.get('formattedRescheduleUrl'),
);

const cancelReasonsStateSelector = (state) => state.getIn(['entities', 'cancelReasons', 'list']);

export const cancelReasonsSelector = createSelector(
  cancelReasonsStateSelector,
  (cancelReasons) => cancelReasons && cancelReasons.toJS(),
);

export const cancelReasonsRecurringOnlySelector = createSelector(
  (state) => state.getIn(['entities', 'cancelReasons', 'listRecurringOnly']),
  (cancelReasons) => cancelReasons && cancelReasons.toJS(),
);

export const partnerCoBrandImageSelector = createSelector(orderJSSelector, (order) => {
  if (!order || !order.partner) return null;
  const {
    partner: { layoutName, partnerName, cobrandedLogo: cobrandImages = [] },
  } = order;
  const altText = `${partnerName} products & services`;

  // coBrandedImages are pulled from BE and legacyImages live in the project
  const legacyImages = {
    [PARTNERS.TARGET]: {
      alt: altText,
      mobile: targetImage,
      desktop: targetImage,
    },
    [PARTNERS.WALMART]: {
      alt: altText,
      mobile: walmartImage,
      desktop: walmartImage,
    },
    [PARTNERS.SAMSUNG]: {
      alt: altText,
      mobile: samsungImage,
      desktop: samsungImage,
    },
  };
  if (layoutName && legacyImages[layoutName]) return legacyImages[layoutName] || null;
  if (!cobrandImages.length) return null;
  return createCobrandedImagesObj({ cobrandImages, partnerName });
});

export const hasAuthErrorSelector = createSelector(orderJSSelector, (order) => {
  if (!order) return false;
  return order.hasAuthError;
});

export const orderShipmentsSelector = createSelector(orderJSSelector, (order) => {
  return order ? order.orderShipments : [];
});

export const hasProductsSelector = createSelector(orderJSSelector, (order) => {
  return order ? order.hasProducts : false;
});

export const orderTimezoneSelector = createSelector(orderJSSelector, (order) => {
  return order ? order.timezone : PST_TIMEZONE_LA;
});

const preferredTechDataJSSelector = createSelector(
  [orderJSSelector, techProfilesSelector],
  (order, techProfiles) => {
    if (order) {
      const { preferredTechId } = order;
      if (preferredTechId) {
        const profile = techProfiles.get(preferredTechId);
        if (profile) {
          const profileJS = profile.toJS();
          const {
            firstName,
            lastName,
            profilePicture: { retinaThumb },
          } = profileJS;
          const techName = getFullFirstLastInitial(firstName, lastName);
          return { techName, imageUrl: retinaThumb };
        }
        return {};
      }
    }
    return {};
  },
);

export default createStructuredSelector({
  activeCard: activeCardSelector,
  appointmentStartDateTime: appointmentStartDateTimeSelector,
  breakdown: orderBreakdownSelector,
  breakdownOrderAdjustments: breakdownOrderAdjustmentsSelector,
  canBeCancelled: canBeCancelledSelector,
  coupon: couponSelector,
  hasPartner: hasPartnerSelector,
  isMobileV2: isMobileV2Selector,
  loading: loadingSelector,
  order: orderJSSelector,
  orderId: orderIDSelector,
  orderToken: orderTokenSelector,
  orderCardStatus: orderCardStatusSelector,
  orderStatusId: orderStatusIdSelector,
  taxes: taxesSelector,
  totalAmountFormatted: orderTotalAmountFormattedSelector,
  timeCanBeUpdated: timeCanBeUpdatedSelector,
  user: userStateSelector,
  userAddress: userAddressSelector,
  formattedRescheduleUrl: formattedRescheduleUrlSelector,
  cancelReasons: cancelReasonsSelector,
  serviceItems: serviceItemsSelector,
  couponObj: couponObjSelector,
  partnerCoBrandImages: partnerCoBrandImageSelector,
  hasAuthError: hasAuthErrorSelector,
  orderShipments: orderShipmentsSelector,
  hasProducts: hasProductsSelector,
  timezone: orderTimezoneSelector,
  preferredTechData: preferredTechDataJSSelector,
});
