import { pathnameFromString } from 'src/utils/validation';
import { techBookingiframeContainerId, techBookingiframeInnerId } from './constants';

const messagePageActions = (props, attributes) => ({
  loaded() {
    attributes.setPageLoaded(true);
  },
  height() {
    /* Get updated elem references */
    const productPageDirectBookingContainer = document.getElementById(techBookingiframeContainerId);
    const productPageDirectBooking = document.getElementById(techBookingiframeInnerId);

    if (productPageDirectBookingContainer)
      productPageDirectBookingContainer.style.height = `${attributes.message}px`;
    if (productPageDirectBooking) {
      productPageDirectBooking.style.height = `${attributes.message}px`;
      productPageDirectBooking.style.width = '100%';
    }
  },

  /*
    This reads in the message from the type of 'goToLocation'. The message must be a
    valid url else it's gonna link to something bad.
  */
  goToLocation() {
    const pathName = pathnameFromString(attributes.message);
    window.location = pathName;
  },

  anchorScrollBy() {
    const scrollValue = attributes.message;
    if (window) window.scrollTo({ top: scrollValue});
  },
});

export { messagePageActions };

/*
  Dev Notes:
  This is identical to the iframes pageactions being used for other iframes on our site.
  Will revisit once the other ticket for consolodating iframes is done.
*/
