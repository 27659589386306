import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import { TOGGLE_DROPDOWN } from './constants';

const initialState = fromJS({ show: false });

function chatCallDropdownReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (state.get('show')) {
        return state.merge({ show: false });
      }
      return state;
    case TOGGLE_DROPDOWN:
      if (Object.keys(action).some((v) => v === 'toggleState')) {
        return state.merge({ show: action.toggleState });
      }
      return state.merge({ show: !state.get('show') });
    default:
      return state;
  }
}

export default chatCallDropdownReducer;
