import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.scss';

const StageEdit = ({ children, containerStyles }) => {
  const appliedStyles = cn(styles.stageWrapper, containerStyles);
  return <section className={appliedStyles}>{children}</section>;
};

StageEdit.propTypes = {
  children: PropTypes.element.isRequired,
  containerStyles: PropTypes.string,
};

export default React.memo(StageEdit);

/*
  StageEdit
    - Container component used when client can go back and edit a stage in the checkout flow
    <StageEdit containerStyles={myCoolStyles}>
      <StageEditHeader />
      <MyCoolComponent />
    </StageEdit>
*/
