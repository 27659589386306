import React from 'react';
import PropTypes from 'prop-types';
import OrderSummaryLineItem from './Breakdown/OrderSummaryLineItem';

const Address = ({ address, showDetails }) => {
  if (!address) return null;
  const { address1, address2, city, state, zip, details, fullAddress } = address;
  const cityStateZip = city || state || zip ? `${city}, ${state} ${zip}` : '';
  const isCustomAddress = !fullAddress?.includes(zip);
  /*
    Addresses are unverified if they can't be geolocated in the form that the customers use to fill out the address.
    When user edits a verified address to a custom one that cannot be verified, the zip field doesn't change, but
    'fullAddress' updates.
  */
  const isUnverifiedAddress = !address1 || isCustomAddress;
  if (isUnverifiedAddress) return <OrderSummaryLineItem>{fullAddress}</OrderSummaryLineItem>;
  return (
    <>
      {address2 && <OrderSummaryLineItem>{address2}</OrderSummaryLineItem>}
      <OrderSummaryLineItem>{address1}</OrderSummaryLineItem>
      <OrderSummaryLineItem>{cityStateZip}</OrderSummaryLineItem>
      {showDetails && <OrderSummaryLineItem>{details}</OrderSummaryLineItem>}
    </>
  );
};

Address.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    details: PropTypes.string,
    fullAddress: PropTypes.string,
  }).isRequired,
  showDetails: PropTypes.bool,
  className: PropTypes.string,
};

export default Address;
