import {useState} from 'react';
import { LEAD_GEN_SOURCE_TYPES } from 'src/constants/tracking';
import { logger } from 'src/utils/logger';
import useAPI from 'src/hooks/useAPI';
import { setLeadGeneratedCookie } from 'src/utils/cookies/leadGenerated';
import { getSegmentAnonymousId } from 'src/utils/cookies';
import { replaceSpaces } from 'src/utils/text';

export const useLeadGeneration = () => {
  const api = useAPI();
  const [leadGeneratedResponse, setResponse] = useState(null);

  /**
   * In cases in which things get dynamic, we can explicitly set the source name/type. Otherwise, we'll use the
   * source to extract from the object.
   *
   * @param email
   * @param source
   * @param sourceNameOverride
   * @param name
   * @param type
   * @param page
   * @param attributes
   * @returns {Promise<void>}
   */
  const submitLeadGenerated = async ({email, source, name, type, page, attributes}) => {
    const updatedEmail = replaceSpaces(email);
    const attributeEmail = attributes && attributes.email && replaceSpaces(attributes.email);

    try {
      const leadGenResponse = await api.tracking.leadGeneration({
        email: updatedEmail,
        source_name: name || LEAD_GEN_SOURCE_TYPES[source].name,
        source_type: type || LEAD_GEN_SOURCE_TYPES[source].type,
        anonymous_id: getSegmentAnonymousId(),
        ...(attributes && {...attributes, ...(attributeEmail && { email: attributeEmail })}),
      });

      if (leadGenResponse.err) {
        logger.error(`Error lead gen: ${page}`, leadGenResponse.err);
        setResponse({err: leadGenResponse.err});
      } else {
        /* Set cookie */
        setLeadGeneratedCookie({email});
        /* Set the response just in case the caller ever needs it */
        setResponse(leadGenResponse);
      }
    } catch (e) {
      logger.error(`Server Error lead gen: ${page}`, e);
    }
  }

  return {submitLeadGenerated, leadGeneratedResponse};
}

