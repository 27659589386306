import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { toggleTopnav } from 'src/components/Header/TopNavHeader/actions';
import { push, plansPath } from 'src/utils/paths';
import { Picture } from 'src/components/Elements/Picture';
import Icon from 'src/components/HTKit/Icon';
import Button, { THEMES as BUTTON_THEMES } from 'HTKit/Forms/Button';
import styles from './styles.scss';

const ctaButtonText = 'Join Now';

const Desktop = ({ text, imageSources = [], containerClassName = '' }) => {
  if (!text) return null;
  const dispatch = useDispatch();
  const goToPlansPage = () => dispatch(push(plansPath()));
  const closeTopNav = () => dispatch(toggleTopnav());

  const handleButtonClick = () => {
    goToPlansPage();
    closeTopNav();
  };
  return (
    <section className={cn(styles.container, containerClassName)}>
      <h4 className={cn(styles.ctaHeader, 'marginBottom-small2')}>{text}</h4>
      <Button
        theme={BUTTON_THEMES.V2INVERTED}
        className={styles.ctaButton}
        onClick={handleButtonClick}
        smallV2
      >
        {ctaButtonText}
      </Button>
      <Picture sources={imageSources} className={styles.image} />
    </section>
  );
};

const MobileTablet = ({ text, imageSources = [], containerClassName = '' }) => {
  if (!text) return null;
  const dispatch = useDispatch();
  const goToPlansPage = () => dispatch(push(plansPath()));
  return (
    <section className={cn(styles.container, containerClassName)} onClick={goToPlansPage}>
      <p className={cn(styles.ctaHeader, styles.ctaHeaderMobile)}>{text}</p>
      <p className={cn(styles.ctaActionText, 'flex alignItemsCenter')}>
        <span>{ctaButtonText}</span>&nbsp;
        <Icon name="v2-skinny-arrow-right" className={styles.arrowIcon} />
      </p>
      <Picture sources={imageSources} className={styles.image} />
    </section>
  );
};

const sharedPropTypes = {
  text: PropTypes.string,
  imageSources: PropTypes.array,
  containerClassName: PropTypes.string,
};

MobileTablet.displayName = 'NavSubCategoryUpsell.MobileTablet';
Desktop.displayName = 'NavSubCategoryUpsell.Desktop';

Desktop.propTypes = sharedPropTypes;
MobileTablet.propTypes = sharedPropTypes;

const NavSubCategoryUpsell = { Desktop, MobileTablet };

export default NavSubCategoryUpsell;
