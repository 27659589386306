/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'HTKit/Grid/Grid';
import PhoneDisplayLink from 'src/components/PhoneDisplayLink';
import HT_INFO from 'src/constants/hellotech';
import { UnderlinedInlineText, SupportEmailLink, UnformattedTextLink } from './Parts';
import styles from './styles.scss';

// const MEMBERSHIP_CANCELLATION_PHONE = '1-833-369-1227';
const MEMBERSHIP_CANCELLATION_PHONE = HT_INFO.phone.customers.membershipCancellation.title;

const StandardTOS = () => (
  <Grid.Fluid classes="site-v2 paddingY-medium2">
    <h1 className="paddingBottom-medium">HELLOTECH CUSTOMER TERMS OF SERVICE</h1>
    <p>
      <em>Last Modified on September 22, 2022</em>
    </p>
    <div className={styles.tosContainer}>
      <section>
        <br />
        <h4>PLEASE READ THIS AGREEMENT CAREFULLY; THIS IS A BINDING CONTRACT.</h4>
        <br />
        <p>
          Welcome to HelloTech! Your access and use of the HelloTech website located at
          www.hellotech.com (the “Site”) and features, products and services provided by HelloTech,
          Inc. (“HelloTech”, “we”, “us” or “our”) through the Site (individually and collectively,
          the “Service”) are subject to the terms and conditions in these Terms of Service (these
          “Terms”). These Terms constitute a legal agreement between you and HelloTech and are
          sometimes referred to herein as the “Agreement”.
        </p>

        <p className="text-weight-bold">
          By agreeing to these Terms, you expressly agree to arbitration of all disputes as further
          described in Section 19 below. You and HelloTech agree to arbitrate solely on an
          individual basis, and that these Terms do not permit class arbitration, or any claims
          brought as a plaintiff or class member in any class or representative arbitration
          proceeding.
        </p>

        <p>
          The Service provides individuals or businesses seeking to obtain technical support
          services (“Customer”) with a platform where they can engage an individual seeking to
          provide the selected technical support service (“Tech”). A Customer will use the Service
          in order to select the desired services and set up a time for the Tech to perform these
          on-demand technical support services (a “Booking”). Customers and any other users of the
          Service are collectively referred to herein as “Users”. The Services and the Site are
          collectively referred to as the “Platform”.
        </p>
        <p>
          Please note that the Platform is operated in the United States and is not available to,
          and should not be accessed and used by, residents of the European Economic Area.
        </p>
        <p>
          By agreeing to these Terms, including by a click-through or other agreement, or by using
          any aspect of the Platform, you expressly acknowledge that (a) you have read these Terms
          and agree to all of their terms and conditions, (b) you are 18 years of age or older, (c)
          you have the right, authority and capacity to enter into this Agreement, and (d) if you
          are entering into this Agreement on behalf of an entity, you have authority to act on
          behalf of that entity and to bind that entity to this Agreement.
        </p>
        <p>
          You further agree to receive all communications, agreements and notices that we provide in
          connection with the Platform electronically, including by e-mail, SMS text message, or by
          posting them on the Site or otherwise making them available through the Platform. You
          agree that all communications that we provide to you electronically satisfy any legal
          requirement that such communications be in writing. You must agree to these Terms in order
          to use the Platform, and if you do not accept these Terms then you may not use any aspect
          of the Platform.
        </p>
        <p>
          These Terms contain important language governing your use of the Platform. They address,
          among other things, information about how we provide the Platform, how we or you may
          terminate the Services and/or access to the Platform, the requirements imposed on you when
          managing your User account (an “Account”) and how we handle disputes (which includes a
          class action waiver and binding arbitration in most cases).
        </p>
      </section>

      <section>
        <p>
          <strong>1. Updates</strong>
        </p>
        <p>
          HelloTech may update these Terms at any time in its sole discretion, and HelloTech will
          post the updated version of these Terms on the Site. You understand and agree that you
          will be deemed to have accepted the updated Terms if you use any aspect of the Platform
          after the updated Terms are posted. If at any point you do not agree to any portion of
          these Terms, you must immediately stop using the Platform. Disputes arising under these
          Terms will be resolved in accordance with the version of these Terms in place at the time
          the dispute arose. We encourage you to review these Terms frequently to stay informed of
          the latest modifications.
        </p>
      </section>

      <section>
        <p>
          <strong>2. Provision of the Platform</strong>
        </p>
        <p>
          You are responsible for any Internet connection, data usage and telecommunications fees
          and charges that you incur when accessing the Platform. You acknowledge and agree that
          HelloTech may make changes to any aspect of the Platform at any time without notifying you
          in advance.
        </p>
      </section>

      <section>
        <p>
          <strong>3. Termination of the Platform</strong>
        </p>
        <p>
          HelloTech reserves the right to deny use of the Platform to any person or entity at
          HelloTech’s sole and absolute discretion. You acknowledge and agree that HelloTech may
          stop providing any aspect of the Platform or restrict your use of the Platform at any
          time, without notifying you in advance, for any reason or no reason. If HelloTech disables
          your access to your Account, you may be prevented from accessing the Platform, your
          Account details or any materials contained in your Account.
        </p>
      </section>

      <section>
        <p>
          <strong>4. User Accounts and Security</strong>
        </p>
        <p>
          <strong>4.1. User Account.</strong>
        </p>

        <p>
          To access certain aspects of the Platform, you must have an Account. You can create an
          Account by completing the registration process on the Site.
        </p>
        <p>
          You may be required to provide information about yourself as part of the registration
          process or your continued use of the Platform. You agree that any registration information
          that you submit to HelloTech will be correct, accurate and up-to-date. You shall not
          select or use as a username a name (a) of another person with the intent to impersonate
          that person; (b) subject to any rights of a person other than you with appropriate
          authorization; or (c) that is otherwise offensive, vulgar or obscene.
        </p>

        <p>
          <strong>4.2. User Account Security.</strong> Maintaining your Account security is very
          important. You are solely responsible for maintaining the confidentiality of your Account
          password and for all activities that are conducted via your Account. You agree to notify
          HelloTech immediately if you become aware of any unauthorized use of your password or of
          your Account.
        </p>
        <p>
          <strong>4.3. User Account Sharing or Transfers.</strong> Accounts are registered to you
          personally and may not be transferred at any time under any circumstances. You should not
          share your Account with, or disclose your password to, anyone else.
        </p>
        <p>
          <strong>4.4. Deactivation by You.</strong> You have the right to deactivate your account
          at any time. You may deactivate your account by emailing us at <SupportEmailLink />, or by
          calling us at 1-800-640-9005. We may maintain a record of account and order history for
          deactivated accounts.
        </p>
        <p>
          <strong>4.5. Termination by HelloTech.</strong> HelloTech may terminate your Account at
          any time for any reason or no reason, including if: (a) HelloTech determines that you are
          (i) in breach of or otherwise acting inconsistently with these Terms or (ii) engaging in
          fraudulent or illegal activities or other conduct that may result in liability to
          HelloTech; (b) HelloTech determines it is required by law to terminate your Account; or
          (c) HelloTech decides to stop providing the Platform or critical portions of the Platform.
          When terminating your Account, HelloTech may delete the Account and all the information in
          it. You have no ownership rights to your Account.
        </p>
        <p>
          <strong>4.6. Effect of User Account Deactivation.</strong> If you voluntarily deactivate
          your Account, you may reactivate that Account at any time by contacting us via email at{' '}
          <SupportEmailLink />, or by telephone at <PhoneDisplayLink displayNumber />. Accounts
          terminated by HelloTech for any type of abuse including, without limitation, a violation
          of these Terms, may not be reactivated for any reason without the consent of HelloTech,
          which may be withheld in HelloTech’s sole and absolute discretion.
        </p>
      </section>

      <section>
        <p>
          <strong>5. Cancellation and Rescheduling </strong>
        </p>
        <p>
          Our cancellation and rescheduling policies are posted at{' '}
          <UnformattedTextLink link="https://www.hellotech.com/cancellation-policy" /> and may be
          updated from time to time without notice.
        </p>
      </section>

      <section>
        <p>
          <strong>6. Pricing and Payments </strong>
        </p>
        <p>
          <strong>6.1. Fees and Payments.</strong> All prices, fees and other amounts for the
          Platform are set forth on the Site (the “Fees”). All amounts shall be expressed and paid
          in United States Dollars. All Fees are subject to change at any time without notice; no
          Fee is confirmed until the checkout process is complete. The Fees may include a platform
          fee, in which case such platform fee will be indicated as a line item at checkout. Except
          as otherwise expressly set forth herein or as expressly approved by HelloTech in writing
          in its sole discretion, all payments made are final and non-refundable and a User shall
          not have the right to cancel its purchase for any reason. If you use PayPal or any other
          payment or financial mechanism (“Payment Provider”), the applicable Payment Provider
          agreement governs your use of such Payment Provider, and you should refer to that
          agreement, and not these Terms, to determine your rights and liabilities with respect
          thereto. YOU REPRESENT AND WARRANT THAT YOU HAVE THE LEGAL RIGHT TO USE ANY PAYMENT MEANS
          USED TO INITIATE ANY TRANSACTION. All information that you provide to us or our
          third-party payment processors must be accurate, current and complete. You will also be
          responsible for paying any applicable taxes relating to payments that you make. HelloTech
          shall have no liability or responsibility to you or any other third party in connection
          with the accuracy of the Payment Provider information provided by you or your use of any
          Payment Provider (including any fees charged by such Payment Provider in connection with
          such use).
        </p>
        <p>
          <strong>6.2. Payment Processing.</strong> When you make a Booking, we will place a hold on
          the credit card that you provide to us at the time of Booking, such hold in the amount of
          the anticipated charge for the applicable Booking. Upon your marking the Booking as
          complete via the mobile app for Techs or other method facilitated by HelloTech such credit
          card will be charged if payment was not made via other means on the Platform. You may
          change the payment method at any time prior to job completion by emailing us at{' '}
          <SupportEmailLink />, or by calling us at <PhoneDisplayLink displayNumber />. Fees for
          cancellations and rescheduled Bookings, in accordance with the cancellation and
          rescheduling policies referred to in Section 5, will be charged to the credit card
          provided at the time of Booking.
        </p>
        <p>
          <strong>6.3. Failed Transactions.</strong> In the event that HelloTech is unable to
          successfully charge any linked payment instrument, HelloTech will notify you via email
          and/or within the “My Account” page, and you will be unable to initiate further orders or
          transactions with your Account. Upon HelloTech’s notification to you of such failure, you
          agree to link a new, valid payment instrument as soon as possible but in any event within
          three (3) business days. Your HelloTech Account may be disabled until a valid payment
          instrument is provided and the outstanding uncharged balance is resolved by HelloTech.
          HelloTech is not responsible for any charges imposed by the issuer of your payment
          instrument as a result of any failed charge by HelloTech. To the extent that HelloTech is
          unable to successfully charge any payment instrument linked to your Account as payment for
          authorized transactions for more than thirty (30) days, you understand and agree that
          HelloTech may employ a collection agency or other business in an effort to collect any
          outstanding debts and fees associated with your User Account, and you will not contest the
          use of a third party to collect the debt and fees owed to HelloTech. You agree that you
          will be responsible for any legal, court, arbitration or collection agency fees associated
          with rectifying your account and all monies owed thereunder. You agree that HelloTech, or
          any agency or business employed by HelloTech, has the right to contact you and your heirs
          via telephone, email or in-person using the information you provided upon registration or
          during any contact with HelloTech, in an effort to collect any monies and fees owed under
          your account, whether specifically referenced in these Terms or not, and such contact may
          be made in a manual or automated fashion.
        </p>
        <p>
          <strong>6.4. Subscription Plans.</strong>
        </p>
        <p>
          <strong>
            (a) <UnderlinedInlineText text="Annual Subscription Plans." />  We offer Users the
            opportunity to enroll in a subscription plan (a “Subscription Plan”).
            <UnderlinedInlineText text="All Subscriptions Plans are annual commitments" /> and are
            billed on either an annual or monthly basis.&nbsp;
            <UnderlinedInlineText text="For clarity, Subscription Plans that are billed on a monthly basis are annual commitments" />
          </strong>
        </p>
        <p>
          <strong>
            (b) <UnderlinedInlineText text="Fees." /> If you purchase a Subscription Plan, you will
            be charged the subscription fee, plus any applicable taxes, plus set-up fee (if
            applicable) and other charges (“Subscription Fee”), at the beginning of your
            Subscription Plan and monthly or annually, as specified when you purchase such
            Subscription Plan thereafter. Subscription Plans and Subscription Fees are described in
            more detail at <UnformattedTextLink link="https://www.hellotech.com/plans" />, and may
            be updated from time to time without notice (except that the monthly fee for any “Annual
            Plan, Pay Monthly” (if such option is selected at checkout) will remain locked in for
            the first year of such Subscription Plan).
          </strong>
        </p>
        <p>
          <strong>
            (c) <UnderlinedInlineText text="Subscription Initiation." /> Subscription Plans become
            active as soon as your initial payment is processed. If you select the “Annual Plan, Pay
            Annually” option, the entire applicable annual Subscription Fee plus applicable taxes
            will be charged at the initial payment. If you select the “Annual Plan, Pay Monthly”
            option, the applicable first month’s Subscription Fee plus set-up fee and applicable
            taxes will be charged at the initial payment, and thereafter the monthly Subscription
            Fee plus applicable taxes will be charged each month for the duration of the applicable
            year for that annual Subscription Plan.
          </strong>
        </p>
        <p>
          <strong>
            (d) <UnderlinedInlineText text="Trial Subscriptions." /> If you enroll in a free trial
            subscription, the free trial subscription will automatically convert into a paid
            subscription at the end of the trial period (which trial period will be indicated at the
            time of the enrollment in such trial period). Unless otherwise indicated, free trial
            subscriptions will automatically convert into HelloTech’s Online Support Annual Plan,
            Pay Monthly. You may cancel the automatic conversion into a paid subscription from “My
            Account” or by contacting HelloTech support at <SupportEmailLink /> at any time on or
            prior to the last business day before the auto-conversion is scheduled to take effect.
          </strong>
        </p>
        <p>
          <strong>
            (e) <UnderlinedInlineText text="Renewal." /> Your Subscription Plan will renew
            automatically at the end of the applicable subscription period (i.e., at the end of the
            one-year period) until you turn off auto-renewal. Renewal rates are subject to change,
            but you will be notified of any change in your rate and be provided with the option to
            cancel in accordance with these Terms. Plan auto-renewal may be disabled at any time
            (but must be done on or prior to the last business day before the auto-renewal is
            scheduled to take effect in order to avoid being charged for such renewed term),
            however, the plan will remain active for the remainder of the annual plan period and you
            will be responsible for the applicable charges for the remaining months of the annual
            plan. You may turn off the auto-renewal option from “My Account” or by contacting
            HelloTech support at <SupportEmailLink />. If you elect not to renew, your right to use
            the Platforms under the Subscription Plan will continue until the end of your then
            current subscription period and will then terminate without further charges.
          </strong>
        </p>
        <p>
          <strong>
            (f)&nbsp;
            <UnderlinedInlineText text="Cancellation of “Annual Plan, Pay Annually” Subscription Plans." />
            &nbsp; For Subscription Plans where the Subscription Fees are paid annually (i.e., the
            “Annual Plan, Pay Annually” option), you may cancel within the first 30 days of the
            Subscription Plan’s annual term to receive a refund of the Subscription Fee less any
            savings or discounts that you have received. You may cancel your Subscription Plan
            within the first 30 days by contacting HelloTech support at <SupportEmailLink /> or
            calling {MEMBERSHIP_CANCELLATION_PHONE}. Cancellations after the first 30 days are
            nonrefundable and the Subscription Plan will continue until the end of your then current
            subscription period.
          </strong>
        </p>
        <p>
          <strong>
            (g)&nbsp;
            <UnderlinedInlineText text="Cancellation of “Annual Plan, Pay Monthly” Subscription Plan." />
            &nbsp; For Subscription Plans where the Subscription Fees are paid monthly (i.e. the
            “Annual Plan, Pay Monthly” option), you may cancel within the first 30 days of the
            Subscription Plan’s annual term to receive a refund of the Subscription Fee less any
            savings or discounts that you have received. If you cancel after the first 30 days, you
            will be charged a lump sum cancellation fee of 50% of your remaining contract obligation
            and the Subscription Plan will continue until the end of that month’s billing period.
            You may cancel your Subscription Plan within the first 30 days by contacting HelloTech
            support at <SupportEmailLink /> or calling {MEMBERSHIP_CANCELLATION_PHONE}. To cancel
            after the first 30 days, please call {MEMBERSHIP_CANCELLATION_PHONE}
          </strong>
        </p>
        <p>
          <strong>
            (h) <UnderlinedInlineText text="Deactivation." /> Payment failures will result in
            immediate deactivation of the applicable Subscription Plan(s). HelloTech Services will
            not be available to Users with a deactivated plan due to payment failure. Any
            outstanding payments will need to be made in order to reactivate the applicable
            Subscription Plan and bring your account current.
          </strong>
        </p>

        <p>
          <strong>6.5. Gift Cards.</strong> HelloTech may offer gift cards for purchase ("Gift
          Cards"). Gift Cards are redeemable only as payment for a Booking or a Subscription Plan.
          Gift Cards have no cash value and are not redeemable for cash unless otherwise required by
          law. Gift Cards must be presented (or the applicable identification number entered) at the
          time of payment and any available balance will be applied to your purchase. Gift Cards do
          not expire and there are no inactivity, dormancy or service fees associated with Gift
          Cards. You agree that you will comply with all Gift Card terms and conditions. Gift Cards
          are not replaceable if lost or stolen. Gift Cards cannot be used for previous purchases,
          credits, or the purchase of Gift Cards, and cannot be used to make a payment towards third
          party items the balance on a credit card. We reserve the right to limit quantities of Gift
          Cards purchased by any person or entity and to cancel a Gift Card if we believe that the
          Gift Card was obtained through fraudulent or unauthorized means. No credit card, credit
          line, overdraft protection, or deposit account is associated with your Gift Card. Unused
          Gift Card balances are not transferable, and you may not sell a Gift Card or otherwise
          barter for its exchange although you may give a Gift Card to someone else as a gift. A
          Gift Card is void if copied, altered, transferred, purchased or sold. Purchases of Gift
          Cards are final and not refundable. We reserve the right to correct the balance of a Gift
          Card if we believe that a billing error has occurred, and we disclaim all liability for
          any such billing errors. Gift Cards and their use are subject to these Terms and use of a
          Gift Card constitutes acceptance thereof. Applicable terms and conditions are subject to
          change without notice. If the laws pertaining to a Gift Card require additional or
          different terms and conditions, then such terms and conditions shall apply. For questions
          or additional information, contact us at <SupportEmailLink />. In the event you do not use
          your Gift Card for a certain period of time, we may be required to turn over the remaining
          Gift Card balance to a state under such state's unclaimed or abandoned property law.
          Although your Gift Card does not expire, if we are obligated to turn over the remaining
          balance of your Gift Card under a state's unclaimed property law, by operation of law we
          will be released from any further liability or obligation with respect to your Gift Card
          and you may be required to contact the state's unclaimed property administrator to attempt
          to recover your unused Gift Card balance. To protect your right to continue to use your
          remaining Gift Card balance, we will make reasonable efforts to exempt your Gift Card from
          state unclaimed property laws.
        </p>
      </section>

      <br />

      <section>
        <p>
          <strong>7. Restrictions and Conditions of Use </strong>
        </p>
        <p>
          <strong>7.1. Use Restrictions.</strong> HelloTech permits you to view and use the Platform
          solely for your own personal or limited commercial use, as applicable, in either case not
          inconsistent with the intended purpose of the Platform. Furthermore, you agree that you
          will not:
        </p>
        <p>
          (a) cause, permit or authorize the modification, creation of derivative works,
          translation, reverse engineering, decompiling, disassembling or hacking of the Platform;
        </p>
        <p>
          (b) sell, assign, rent, lease, act as a service bureau, or grant rights in the Platform,
          including, without limitation, through sublicense, to any other person or entity without
          the prior written consent of HelloTech;
        </p>
        <p>
          (c) make any false, misleading or deceptive statement or representation regarding
          HelloTech or the Platform;
        </p>
        <p>
          (d) institute, assist, or become involved in any type of attack including, without
          limitation, denial of service attacks, upon the Platform (or any servers, systems or
          networks connected to the Platform) or otherwise attempt to obstruct, disrupt or interfere
          with the operation of the Platform or any other person’s or entity’s use of the Platform
          (or any servers, systems or networks connected to the Platform);
        </p>
        <p>
          (e) attempt to gain unauthorized access to the Platform, accounts registered to other
          Users, or any servers, systems or networks connected to the Platform;
        </p>
        <p>
          (f) use the Platform for any commercial purpose unless consistent with these Terms and the
          intended use of the Platform;
        </p>
        <p>
          (g) use the Platform to develop, generate, transmit or store information that is
          defamatory, harmful, abusive, obscene or hateful;
        </p>
        <p>
          (h) use the Platform to upload, post, e-mail or otherwise transmit any material that
          constitutes unsolicited or unauthorized advertising, promotional materials, "junk mail,"
          "spam," "chain letters," "pyramid schemes," or any other form of solicitation or
          commercial electronic message;
        </p>
        <p>(i) breach any agreements you enter into with any third parties;</p>
        <p>
          (j) use the Platform for any unlawful, prohibited, abnormal or unusual activity as
          determined by HelloTech in its sole discretion;
        </p>
        <p>
          (k) use the Platform to engage in any activity that (i) constitutes harassment or a
          violation of privacy or threatens other people or groups of people; (ii) is harmful to
          children in any manner; (iii) constitutes phishing, pharming or impersonates any other
          person or entity, or steals or assumes any person’s identity (whether a real identity or
          online nickname or alias); or (iv) violates any applicable law, ordinance, rule,
          regulation, treaty or self-regulatory guidelines;
        </p>
        <p>
          (l) improperly obtain or attempt to improperly obtain any information or data from the
          Platform including, without limitation, email addresses or mobile phone numbers of other
          Users;
        </p>
        <p>
          (m) intercept, examine or otherwise observe any proprietary communications protocol used
          by the Platform, whether through the use of a network analyzer, packet sniffer or other
          device; or
        </p>
        <p>
          (n) use any type of bot, spider, virus, clock, timer, counter, worm, software lock, drop
          dead device, Trojan horse, trap door, time bomb or any other codes, instructions or third
          party software that is designed to provide a means of surreptitious or unauthorized access
          to, or distort, delete, damage or disassemble, any aspect of the Platform.
        </p>
      </section>

      <br />

      <section>
        <p>
          <strong>8. Links </strong>
        </p>
        <p>
          <strong>8.1. Links from the Platform.</strong> The Platform may contain links to websites
          operated by independent third parties. HelloTech provides these links to other websites as
          a convenience and use of these websites is at your own risk. The linked websites are not
          under the control of HelloTech and HelloTech is not responsible for the content available
          on the other websites or services. Such links do not imply HelloTech’s endorsement of
          information or material on any other website and HelloTech disclaims all liability with
          regard to your access to and use of such linked websites. You understand and acknowledge
          that your access and use of linked websites and the services provided through these
          websites is governed by the terms of service and other agreements posted on such websites.
        </p>
        <p>
          <strong>8.2. Links to the Platform.</strong> Unless otherwise set forth in a written
          agreement between you and HelloTech, you must adhere to HelloTech’s linking policy as
          follows: (a) the appearance, position and other aspects of any link to the App or the Site
          may not be such as to damage or dilute the goodwill associated with HelloTech’s or its
          licensors’ names and trademarks; (b) the appearance, position and other attributes of the
          link may not create the false appearance that your organization or entity is sponsored by,
          affiliated with, or associated with HelloTech; and (c) when selected by a User, the link
          to the Site must display the Site on full-screen and not within a “frame” on the linking
          website. HelloTech reserves the right to revoke its consent to the link at any time and in
          its sole discretion (including any link on any social media networking platform).
          Furthermore, HelloTech reserves the right to revoke or modify any link (including any
          vanity link) to the Platform or otherwise related to your Account (including any link on
          any social media networking platform).
        </p>
      </section>

      <br />

      <section>
        <p>
          <strong>9. HelloTech Intellectual Property </strong>
        </p>
        <p>
          <strong>9.1. Trademarks. </strong> The HelloTech name and logo are trademarks and service
          marks of HelloTech. Unless permitted in a separate written agreement with HelloTech, you
          do not have the right to use any of HelloTech’s trademarks, service marks or logos and
          your unauthorized use of any of these may be a violation of federal and state trademark
          laws.
        </p>
        <p>
          <strong>9.2. Ownership.</strong> You acknowledge and agree that HelloTech, or its
          licensors, owns all right, title and interest in and to the Platform, including all
          intellectual property, industrial property and proprietary rights recognized anywhere in
          the world at any time and that the Platform is protected by United States and
          international copyright laws. Further, you acknowledge that the Platform may contain
          information that HelloTech has designated as confidential and you agree not to disclose
          such information without HelloTech’s prior written consent.
        </p>
        <p>
          <strong>9.3. Feedback.</strong> You may choose to, or HelloTech may invite you to, submit
          comments, bug reports, ideas or other feedback about the Platform or any modifications,
          features or improvements thereto (“Feedback”). You agree that HelloTech shall own any and
          all Feedback and HelloTech shall have the right to use, in any manner and for any purpose
          whatsoever, any and all Feedback. You hereby assign to HelloTech all right, title and
          interest that you may acquire in and to any Feedback.
        </p>
        <p>
          <strong>9.4. Repeat Infringer Policy; Copyright Complaints.</strong> In accordance with
          the Digital Millennium Copyright Act and other applicable law, we have adopted a policy of
          terminating, in appropriate circumstances, the accounts of Users who repeatedly infringe
          the intellectual property rights of others. If you believe that anything on our Platform
          infringes any copyright that you own or control, you may notify HelloTech as follows:
        </p>

        <table className={styles.contactTable}>
          <tbody>
            <tr>
              <td>To:</td>
              <td>HelloTech Copyright Agent</td>
            </tr>
            <tr>
              <td>Address:</td>
              <td>900 Hilgard Ave. Los Angeles, CA 90024</td>
            </tr>
            <tr>
              <td>Telephone Number:</td>
              <td>
                <PhoneDisplayLink displayNumber />
                &nbsp;
              </td>
            </tr>
            <tr>
              <td>E-Mail Address:</td>
              <td>
                <SupportEmailLink />
              </td>
            </tr>
          </tbody>
        </table>
        <p>Such notice must contain the following information:</p>
        <ul className="p0">
          <li>your address, telephone number, and email address;</li>
          <li>a description of the copyrighted work that you claim has been infringed;</li>
          <li>a description of where the alleged infringing material is located;</li>
          <li>
            a statement by you that you have a good faith belief that the disputed use is not
            authorized by you, the copyright owner, its agent, or the law;
          </li>
          <li>
            an electronic or physical signature of the person authorized to act on behalf of the
            owner of the copyright interest; and
          </li>
          <li>
            a statement by you, made under penalty of perjury, that the above information in your
            notice is accurate and that you are the copyright owner or authorized to act on the
            copyright owner’s behalf.
          </li>
        </ul>
      </section>

      <br />

      <section>
        <p>
          <strong>10. Privacy Policy </strong>
        </p>
        <p>
          By accepting these Terms or using the Platform, you represent that you have read and
          consent to our Privacy Policy in addition to these Terms. HelloTech may revise the Privacy
          Policy at any time, and a link to the new versions will be posted on the Site. If at any
          point you do not agree to any portion of the Privacy Policy, you must immediately stop
          using the Platform. By using the Platform, you agree to the then-current versions of these
          Terms and Privacy Policy, which will be posted on the Site.
        </p>
      </section>

      <br />

      <section>
        <p>
          <strong>11. Location</strong>
        </p>
        <p>
          The Platform is operated by HelloTech in the United States. In order for us to provide our
          Platform, you agree that we may transfer, use and store information about you in the
          United States and other countries, where you may not have the same rights and protections
          as you do under local law. If you choose to access the Platform from a location outside of
          the United States, you do so on your own initiative and you are responsible for compliance
          with applicable local laws; provided, however, that the Platform is not available to, and
          should not be accessed and used by, residents of the European Economic Area.
        </p>
      </section>

      <br />

      <section>
        <p>
          <strong>12. Submitted Content </strong>
        </p>
        <p>
          <strong>12.1.</strong> HelloTech is not the source of, does not verify or endorse and
          takes no responsibility for the content of communications made using the Platform or any
          materials submitted or made available through the Platform via any function which allows a
          User to post or share content (which shall include, without, limitation, any photograph,
          image or other visual likeness of you) (“Submitted Content”). By using the Platform, you
          agree that your Submitted Content may be viewable by other Users. Submitted Content is
          entirely the responsibility of the person from whom such content originated. You therefore
          may be exposed to content that is offensive, unlawful, harmful to minors, obscene,
          indecent or otherwise objectionable. Submitted Content may be protected by intellectual
          property rights owned by third parties. You are responsible for the content you choose to
          communicate and access using the Platform. In particular, you are responsible for ensuring
          that you do not submit material that (a) is protected by copyright, contains trade secrets
          or otherwise is subject to third party proprietary rights, including privacy and publicity
          rights, unless you are the owner of such rights or have permission from the rightful
          owner; (b) is false or is a misrepresentation; (c) is offensive, unlawful, harmful to
          minors, obscene, defamatory, libelous, threatening, pornographic, harassing, hateful,
          racially or ethnically offensive, or that encourages conduct that would be considered a
          criminal offense, gives rise to civil liability, violates any law, or is otherwise
          objectionable; or (d) impersonates another person. HelloTech may in its sole discretion
          block, prevent delivery of or otherwise remove the content of communications as part of
          its effort to protect the Platform or its Users, or otherwise enforce the terms of these
          Terms. Further, HelloTech may in its sole discretion remove such content and terminate
          your Account if you submit any content that is in breach of these Terms. You acknowledge
          that HelloTech has the right to pre- screen your Submitted Content but has no obligation
          to do so. At HelloTech’s sole discretion, any Submitted Content may be included in the
          Platform in whole or in part in modified form.
        </p>
        <p>
          <strong>12.2.</strong> HelloTech does not claim ownership of any of your Submitted
          Content. With respect to all of your Submitted Content, you grant HelloTech a perpetual,
          irrevocable, non-terminable, worldwide, royalty-free, sublicensable, fully paid-up,
          non-exclusive and transferable license to use, reproduce, distribute, prepare derivative
          works of, display, modify, copy and perform the Submitted Content or any part of the
          Submitted Content in connection with the Platform and HelloTech’s (and its successors’ and
          affiliates’) business, including, without limitation, for promoting, marketing and
          redistributing part or all of the Platform (and derivative works thereof) in any and all
          media formats and through any and all media channels. You also hereby grant each User a
          non-exclusive license to access your Submitted Content through the Platform while the
          Submitted Content is made available through the Platform. You may take down any of your
          Submitted Content at any time; however, you acknowledge and agree, that HelloTech may
          still have access to such Submitted Content and that the above license granted by you to
          HelloTech will remain in effect despite your removal of the Submitted Content from the
          Platform. You hereby represent, warrant and covenant that any Submitted Content you
          provide does not include anything (including, but not limited to, text, images, music or
          video) to which you do not have the full right to grant the license specified in this
          Section.
        </p>
        <p>
          <strong>12.3.</strong> You acknowledge that your Submitted Content is your sole
          responsibility. You agree that, under no circumstances, will HelloTech be liable in any
          way for any Submitted Content, including, but not limited to, any errors or omissions in
          any Submitted Content, or any loss or damage of any kind incurred as a result of the use
          or distribution of any Submitted Content transmitted or otherwise made available via the
          Platform.
        </p>
      </section>

      <br />

      <section>
        <p>
          <strong>13. Utilizing Your Computer or Other Device.</strong>
        </p>
        <p>
          Some of the benefits of the Platform require the Platform to access information on your
          computer or other applicable device. You hereby grant permission for the Platform to
          monitor your computer or other applicable device (including, but not limited to, your
          device’s memory) and to communicate information, including, without limitation, your
          Account information, to HelloTech’s servers for the purposes of analyzing your device’s
          performance during use of the Platform. Additionally, the Platform will utilize the
          processor, bandwidth and hard drive (or other memory or storage hardware) and/or cache of
          your computer or other applicable device for the limited purpose of facilitating the
          communication between, and the transmittal of data, content, services or features to, you
          and other users, and to facilitate the operation of the network of computers running
          instances of the Platform.
        </p>
      </section>

      <br />

      <section>
        <p>
          <strong>14. Mobile Messaging Terms and Conditions</strong>
        </p>
        <p>
          <strong>14.1</strong> By agreeing to these Terms or using the Platform, you also agree to
          receive communications from us, including via email, text message (to the extent permitted
          by applicable law), calls, and browser notifications. Communications from us may include
          responses to your inquiries, operational communications concerning your orders, requests
          for feedback, or marketing materials.
        </p>
        <p>
          <strong>14.2</strong> HelloTech offers a mobile messaging program (the “Program”), which
          you agree to use and participate in subject to these Mobile Messaging Terms and Conditions
          (for purposes of this Section 14, the “Mobile Messaging Terms”). By opting-in to or
          participating in any of our Programs, you accept and agree to these Terms, including,
          without limitation, your agreement to resolve any disputes with us through binding,
          individual-only arbitration, as detailed in Section 19 (Binding Arbitration) below. These
          Mobile Messaging Terms are limited to the Program and is not intended to modify other
          Terms or privacy policy that may govern the relationship between you and HelloTech in
          other contexts.
        </p>
        <p>
          <strong>14.3 Opt-In.</strong> The Program allows you to receive SMS/MMS mobile messages by
          affirmatively opting-into the Program, such as through online enrollment forms. Regardless
          of the opt-in method you utilized to join the Program, you agree that these Mobile
          Messaging Terms apply to your participation in the Program, and you acknowledge and agree
          that your information may be shared with third-party service providers who help us
          administer the Program. By participating in the Program, you agree to receive auto-dialed
          or prerecorded marketing mobile messages at the phone number associated with your opt-in.
          While you consent to receive messages sent using an auto-dialer, the foregoing shall not
          be interpreted to suggest or imply that any or all of our mobile messages are sent using
          an automatic telephone dialing system (“ATDS” or “auto-dialer”). Message and data rates
          may apply.
        </p>
        <p>
          <strong>14.4 Opt-Out.</strong> If you do not wish to continue participating in the Program
          or no longer agree to these Mobile Messaging Terms, you agree to reply STOP to any mobile
          message from HelloTech in order to opt out of the Program. You may receive an additional
          mobile message confirming your decision to opt out. You understand and agree that the
          foregoing option is the only reasonable method of opting out. You also understand and
          agree that any other method of opting out, including, but not limited to, texting words
          other than the word STOP or verbally requesting one of our employees to remove you from
          our list, is not a reasonable means of opting out.
        </p>
        <p>
          <strong>14.5 Duty to Notify and Indemnify.</strong> If at any time you intend to stop
          using the mobile telephone number that has been used to subscribe to the Program,
          including canceling your service plan or selling or transferring the phone number to
          another party, you agree that you will complete the User Opt-Out process set forth above
          prior to ending your use of the mobile telephone number. You understand and agree that
          your agreement to do so is a material part of these Terms. You further agree that, if you
          discontinue the use of your mobile telephone number without notifying HelloTech of such
          change, you agree that you will be responsible for all costs (including attorneys’ fees)
          and liabilities incurred by HelloTech, or any party that assists in the delivery of the
          mobile messages, as a result of claims brought by individual(s) who are later assigned
          that mobile telephone number. This duty and these Mobile Messaging Terms shall survive any
          cancellation or termination of your agreement to participate in any of our Programs. YOU
          AGREE THAT YOU SHALL INDEMNIFY, DEFEND, AND HOLD US HARMLESS FROM ANY CLAIM OR LIABILITY
          RESULTING FROM YOUR FAILURE TO NOTIFY US OF A CHANGE IN THE INFORMATION YOU HAVE PROVIDED,
          INCLUDING ANY CLAIM OR LIABILITY UNDER THE TELEPHONE CONSUMER PROTECTION ACT, 47 U.S.C. §
          227, OR SIMILAR STATE AND FEDERAL LAWS, AND ANY REGULATIONS PROMULGATED THEREUNDER
          RESULTING FROM US ATTEMPTING TO CONTACT YOU AT THE MOBILE TELEPHONE NUMBER YOU PROVIDED.
        </p>
        <p>
          <strong>14.6 Program Description.</strong> Without limiting the scope of the Program,
          users that opt-in to the Program can expect to receive messages concerning Booking
          updates, requests for feedback, and the marketing and sale of products, services, and
          events.
        </p>
        <p>
          <strong>14.7 Cost and Frequency.</strong> Message and data rates may apply. The Program
          may involve recurring mobile messages, and additional mobile messages may be sent
          periodically based on your interaction with HelloTech.
        </p>
        <p>
          <strong>14.8 MMS Disclosure.</strong> The Program will send SMS TMs (terminating messages)
          if your mobile device does not support MMS messaging.
        </p>
        <p>
          <strong>14.9 Our Disclaimer of Warranty.</strong> The Program is offered on an “as-is”
          basis and may not be available in all areas at all times and may not continue to work in
          the event of product, software, coverage, or other changes made by your wireless carrier.
          We will not be liable for any delays or failures in the receipt of any mobile messages
          connected with this Program. Delivery of mobile messages is subject to effective
          transmission from your wireless service provider/network operator and is outside of
          HelloTech’s control.
        </p>
        <p>
          <strong>14.10 Participant Requirements.</strong> You must have a wireless device of your
          own, capable of two-way messaging, be using a participating wireless carrier, and be a
          wireless service subscriber with text messaging service. Not all cellular phone providers
          carry the necessary service to participate. Check your device capabilities for specific
          text messaging instructions
        </p>
      </section>

      <br />

      <section>
        <p>
          <strong>15. NO ENDORSEMENT; LIMITED WARRANTY; DISCLAIMER OF WARRANTIES</strong>
        </p>
        <p>
          <strong>15.1.</strong> Although HelloTech displays information about Techs and Users and
          may collect payments of Fees for Techs, such actions do not in any way constitute
          HelloTech’s sponsorship or approval of any Tech. HelloTech does not endorse or recommend
          any Tech, and you agree that HelloTech is not responsible for the accuracy or completeness
          of information displayed through the Platform with respect to the Techs.
        </p>
        <p>
          <strong>15.2.</strong> HelloTech represents and warrants that the Services (a) will be
          performed in a good and workmanlike manner, using the degree of skill, care, and judgment
          consistent with customarily accepted good business practices, and (b) will be free from
          defects. This limited warranty is valid for a period of ninety (90) days following the
          date the Services were performed. To make a claim under this limited warranty for any
          Services performed, contact us at <SupportEmailLink /> and we will arrange for a Tech to
          re-perform the Services. Such re-performance shall be the sole remedy for any claims made
          under this limited warranty.
        </p>
        <p>
          <strong>15.3.</strong> YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE PLATFORM IS AT YOUR
          SOLE RISK AND THAT, EXCEPT AS SET FORTH IN SECTION 15.2, (A) THE PLATFORM IS PROVIDED ON
          AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER
          EXPRESS OR IMPLIED, (B) TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, HELLOTECH
          EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS INCLUDING, WITHOUT LIMITATION,
          WARRANTIES AND CONDITIONS OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, NON-INFRINGEMENT, AND THOSE ARISING FROM COURSE OF DEALING OR USAGE OF
          TRADE, (C) HELLOTECH MAKES NO WARRANTY AS TO THE ACCURACY, COMPLETENESS OR RELIABILITY OF
          ANY MATERIALS, INFORMATION OR DATA AVAILABLE THROUGH, OR THE PERFORMANCE OF, THE PLATFORM,
          AND (D) HELLOTECH DOES NOT REPRESENT OR WARRANT THAT (I) YOU WILL BE ABLE TO ACCESS OR USE
          THE PLATFORM AT THE TIMES OR LOCATIONS OF YOUR CHOOSING; (II) THAT OPERATION OF THE
          PLATFORM WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (III) YOUR USE OF THE
          PLATFORM WILL MEET YOUR REQUIREMENTS; (IV) DEFECTS IN THE OPERATION OF THE PLATFORM WILL
          BE CORRECTED; OR (V) THE PLATFORM IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
        </p>
        <p>
          <strong>15.4.</strong> TECHS ARE NOT REPRESENTATIVES OR AGENTS OF HELLOTECH. EXCEPT FOR
          CERTAIN REMOTE TECHNICAL SUPPORT, HELLOTECH DOES NOT PROVIDE TECHNICAL SUPPORT SERVICES,
          AND DOES NOT EMPLOY OR ENGAGE INDIVIDUALS TO PROVIDE TECHNICAL SUPPORT SERVICES. USERS
          HEREBY ACKNOWLEDGE THAT HELLOTECH DOES NOT SUPERVISE, DIRECT, CONTROL OR MONITOR A TECH’S
          WORK AND, EXCEPT AS SET FORTH IN SECTION 15.2, EXPRESSLY DISCLAIMS ANY RESPONSIBILITY AND
          LIABILITY FOR THE WORK PERFORMED IN ANY MANNER, INCLUDING, BUT NOT LIMITED TO, COMPLIANCE
          WITH ANY LAW, REGULATION, OR CODE.
        </p>
        <p>
          <strong>15.5.</strong> CUSTOMER IS RESPONSIBLE FOR (A) OBTAINING ALL PERMITS, LICENSES AND
          OTHER PERMISSIONS, IF ANY, THAT MAY BE REQUIRED TO BE OBTAINED FOR THE REPESECTIVE
          SERVICES TO BE PERFORMED, AND (B) CONFIRMING THAT ANY TECH PERFORMING SERVICES FOR SUCH
          CUSTOMER POSSESSES ALL REQUISITE PERMITS AND TRADE LICENSES THAT MAY BE REQUIRED FOR THE
          PERFORMANCE OF SUCH SERVICES. HELLOTECH ASSUMES NO RESPONSIBILITY FOR A CUSTOMER’S FAILURE
          TO OBTAIN OR CONFIRM SUCH PERMITS, LICENSES OR PERMISSIONS OR OTHERWISE COMPLY WITH ANY
          APPLICABLE LAWS, RULES OR REGULATIONS. WE ENCOURAGE YOU TO CONFIRM ANY SUCH REQUIREMENTS
          AND TO ASK TO SEE A COPY OF ANY LICENSES IF APPLICABLE.
        </p>
        <p>
          <strong>15.6.</strong>  NEITHER HELLOTECH NOR ITS PARENTS, AFFILIATES OR LICENSORS,
          INCLUDING THEIR RESPECTIVE DIRECTORS, OFFICERS, STOCKHOLDERS, AGENTS, INVESTORS,
          SUBSIDIARIES, ATTORNEYS, REPRESENTATIVES, INSURERS, EMPLOYEES, AGENTS, SUCCESSORS AND
          ASSIGNS (HEREINAFTER REFERRED TO COLLECTIVELY AS “HELLOTECH PARTIES”) IS RESPONSIBLE FOR
          THE CONDUCT, ACTS, OR OMISSIONS, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE PLATFORM
          AND YOU HEREBY RELEASE HELLOTECH PARTIES FROM ANY AND ALL LIABILITY, CLAIMS, DEMANDS, OR
          DAMAGES OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED
          AND UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH HELLOTECH OR THE PLATFORM.
        </p>
        <p>
          <strong>15.7.</strong> HELLOTECH ONLY ENABLES CONNECTIONS BETWEEN USERS AND IS NOT
          RESPONSIBLE FOR THE PERFORMANCE OR COMMUNICATIONS OF USERS, NOR DOES IT HA VE CONTROL OVER
          THE QUALITY, TIMING, LEGALITY, FAILURE TO PROVIDE, OR ANY OTHER ASPECT WHATSOEVER OF
          AGREEMENTS BETWEEN USERS, TECHS, CUSTOMERS, NOR OF THE INTEGRITY, RESPONSIBILITY,
          COMPETENCE, QUALIFICATIONS, OR ANY OF THE ACTIONS OR OMISSIONS WHATSOEVER OF ANY USERS, OR
          OF ANY RATINGS PROVIDED BY USERS WITH RESPECT TO EACH OTHER. HELLOTECH DISCLAIMS ANY AND
          ALL LIABILITY RELATING TO YOUR INTERACTIONS WITH ANY TECH(S), CUSTOMER(S) OR OTHER
          USER(S). ANY REPRESENTATIONS MADE TO YOU BY ANY TECH(S) ARE MADE SOLELY AT THE DISCRETION
          OF THE TECH AND HELLOTECH HAS NO WAY TO MONITOR OR VALIDATE, AND SHALL NOT BE RESPONSIBLE
          OR LIABLE IN ANY WAY FOR, ANY REPRESENTATIONS OR STATEMENTS MADE TO YOU BY THE TECH(S).
          YOU UNDERSTAND AND ACKNOWLEDGE THAT HELLOTECH SHALL HAVE NO LIABILITY TO YOU FOR ANY
          STATEMENTS OR REPRESENTATIONS MADE BY THE TECH TO YOU AS A RESULT OF YOUR USE OF THE
          PLATFORM.
        </p>
      </section>

      <br />

      <section>
        <p>
          <strong>16. LIMITATION ON LIABILITY</strong>
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, HELLOTECH AND THE OTHER HELLOTECH
          PARTIES SHALL NOT BE LIABLE TO YOU UNDER ANY CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
          LIABILITY OR OTHER LEGAL OR EQUITABLE THEORY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,
          SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING LOSS OF PROFITS, USE, DATA OR GOODWILL,
          ARISING OUT OF OR IN ANY WAY CONNECTED WITH ACCESS TO OR USE OF THE PLATFORM, EVEN IF
          HELLOTECH OR THE OTHER HELLOTECH PARTIES HAVE BEEN ADVISED OR SHOULD HAVE BEEN AWARE OF
          THE POSSIBILITY OF ANY SUCH LOSSES OR DAMAGES. WITHOUT LIMITING THE FOREGOING, TO THE
          MAXIMUM EXTENT PERMITTED BY LAW, IN NO CASE SHALL THE LIABILITY OF HELLOTECH OR ANY OF THE
          OTHER HELLOTECH PARTIES EXCEED, IN THE AGGREGATE, THE GREATER OF FIVE HUNDRED DOLLARS
          ($500) OR THE FEES ACTUALLY PAID BY YOU FOR THE SERVICES.
          <br />
          THIS LIMITATION OF LIABILITY IS INTENDED TO APPLY WITHOUT REGARD TO WHETHER OTHER
          PROVISIONS OF THESE TERMS HAVE BEEN BREACHED OR HAVE PROVEN INEFFECTIVE OR IF A REMEDY
          FAILS OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW FOR LIMITED LIABILITY OR
          EXCLUSION OF CERTAIN WARRANTIES, CONDITIONS OR REPRESENTATIONS SO NOT ALL OF THE ABOVE
          LIMITATIONS MAY APPLY TO YOU. YOU ACKNOWLEDGE AND UNDERSTAND THAT THE DISCLAIMERS,
          EXCLUSIONS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN FORM AN ESSENTIAL BASIS OF THE
          AGREEMENT BETWEEN THE PARTIES HERETO, THAT THE PARTIES HAVE RELIED UPON SUCH DISCLAIMERS,
          EXCLUSIONS AND LIMITATIONS OF LIABILITY, AND THAT ABSENT SUCH DISCLAIMERS, EXCLUSIONS AND
          LIMITATIONS OF LIABILITY, THE TERMS AND CONDITIONS OF THESE TERMS WOULD BE SUBSTANTIALLY
          DIFFERENT.
        </p>
      </section>

      <br />

      <section>
        <p>
          <strong>17. Indemnification</strong>
        </p>
        <p>
          You agree to defend, indemnify and hold HelloTech and the other HelloTech Parties harmless
          from and against any and all claims, demands, liabilities damages and losses including,
          without limitation, reasonable attorneys’ fees, resulting from or arising out of (a) your
          use of the Platform; or (b) your breach of these Terms or any other policies that
          HelloTech may issue for the Platform from time to time. You further agree to cooperate as
          required by HelloTech in the defense of any claim. HelloTech reserves the right to assume
          the exclusive defense and control of any matter subject to indemnification by you, and you
          will not in any event settle any claim without the prior written consent of HelloTech.
        </p>
      </section>

      <br />

      <section>
        <p>
          <strong>18. Governing Law; Jurisdiction</strong>
        </p>
        <p>
          These Terms are governed by California law, without regard to conflict of laws principles.
          The application of the United Nations Convention on Contracts for the International Sale
          of Goods is expressly excluded. You and HelloTech agree that, except as otherwise provided
          in Section 19 below, the state and federal courts located in Los Angeles, California will
          have exclusive jurisdiction of all disputes arising out of or related to these Terms or
          your use of the Platform and agree to submit to the personal jurisdiction and venue of
          these courts. Notwithstanding the foregoing, HelloTech shall be allowed to apply for
          equitable remedies (including injunctions) in any jurisdiction.
        </p>
      </section>

      <br />

      <section>
        <p>
          <strong>19. Binding Arbitration</strong>
        </p>
        <p>
          <strong>19.1. Arbitration Procedures.</strong> You and HelloTech agree that, except as
          provided in Section 19.4 below, all disputes, controversies and claims related to these
          Terms or the Platform (each a “Claim”) shall be finally and exclusively resolved by
          binding arbitration, which may be initiated by either party by sending a written notice
          requesting arbitration to the other party. Any election to arbitrate by one party shall be
          final and binding on the other. The arbitration will be conducted under the Streamlined
          Arbitration Rules and Procedures of JAMS that are in effect at the time the arbitration is
          initiated (the “JAMS Rules”) and under the terms set forth in these Terms. In the event of
          a conflict between the terms set forth in this Section 19 and the JAMS Rules, the terms in
          this Section 19 will control and prevail. Except as otherwise set forth in Section 19.4,
          you may seek any remedies available to you under federal, state or local laws in an
          arbitration action. As part of the arbitration, both you and HelloTech will have the
          opportunity for discovery of non-privileged information that is relevant to the Claim. The
          arbitrator will provide a written statement of the arbitrator’s decision regarding the
          Claim, the award given and the arbitrator’s findings and conclusions on which the
          arbitrator’s decision is based. The determination of whether a Claim is subject to
          arbitration shall be governed by the Federal Arbitration Act and determined by a court
          rather than an arbitrator. Except as otherwise provided in these Terms, (a) you and
          HelloTech may litigate in court to compel arbitration, stay proceedings pending
          arbitration, or confirm, modify, vacate or enter judgment on the award entered by the
          arbitrator; and (b) the arbitrator’s decision shall be final, binding on all parties and
          enforceable in any court that has jurisdiction, provided that any award may be challenged
          if the arbitrator fails to follow applicable law.
        </p>
        <p>
          <strong>19.2. Location.</strong>The arbitration will take place in Los Angeles,
          California, unless the parties agree to video, phone or internet connection appearances.
        </p>
        <p>
          <strong>19.3. LIMITATIONS.</strong> YOU AND HELLOTECH AGREE THAT ANY ARBITRATION SHALL BE
          LIMITED TO THE CLAIM BETWEEN HELLOTECH AND YOU INDIVIDUALLY. YOU AND HELLOTECH AGREE THAT
          (A) THERE IS NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE ARBITRATED ON A CLASS-ACTION
          BASIS OR TO UTILIZE CLASS ACTION PROCEDURES; (B) THERE IS NO RIGHT OR AUTHORITY FOR ANY
          DISPUTE TO BE BROUGHT IN A PURPORTED REPRESENTATIVE CAPACITY OR AS A PRIVATE ATTORNEY
          GENERAL; AND (C) NO ARBITRATION SHALL BE JOINED WITH ANY OTHER ARBITRATION.
        </p>
        <p>
          <strong>19.4. Exceptions to Arbitration.</strong> You and HelloTech agree that the
          following Claims are not subject to the above provisions concerning negotiations and
          binding arbitration: (a) any Claim seeking to enforce or protect, or concerning the
          validity of, any of HelloTech’s intellectual property rights; (b) any Claim related to, or
          arising from, allegations of theft, piracy, invasion of privacy or unauthorized use; and
          (c) any claim for equitable relief. In addition to the foregoing, either party may assert
          an individual action in small claims court for Claims that are within the scope of such
          court’s jurisdiction in lieu of arbitration.
        </p>
        <p>
          <strong>19.5. Arbitration Fees.</strong> If you initiate arbitration for a Claim, you will
          need to pay the JAMS arbitration initiation fee. If we are initiating arbitration for a
          Claim, we will pay all costs charged by JAMS for initiating the arbitration. All other
          fees and costs of the arbitration will be charged pursuant to the JAMS Rules.
        </p>
        <p>
          <strong>19.6. Severability.</strong> You and HelloTech agree that if any portion of this
          Section 19 is found illegal or unenforceable (except any portion of Section 19.4), that
          portion shall be severed and the remainder of the Section shall be given full force and
          effect. If Section 19.4 is found to be illegal or unenforceable then neither you nor
          HelloTech will elect to arbitrate any Claim falling within that portion of Section 19.4
          found to be illegal or unenforceable and such Claim shall be exclusively decided by a
          court of competent jurisdiction within Los Angeles, California, United States of America,
          and you and HelloTech agree to submit to the personal jurisdiction of that court.
        </p>
      </section>

      <br />

      <section>
        <p>
          <strong>20. General</strong>
        </p>
        <p>
          <strong>20.1. No Partnership.</strong> You agree that no joint venture, partnership,
          employment, or agency relationship exists between you and HelloTech as a result of these
          Terms or your use of the Platform.
        </p>
        <p>
          <strong>20.2. Assignment.</strong> HelloTech may assign its rights under these Terms to
          any person or entity without your consent. The rights granted to you under these Terms may
          not be assigned without HelloTech’s prior written consent, and any attempted unauthorized
          assignment by you shall be null and void.
        </p>
        <p>
          <strong>20.3. Severability.</strong> If any part of these Terms is determined to be
          invalid or unenforceable, then that portion shall be severed, and the remainder of these
          Terms shall be given full force and effect.
        </p>
        <p>
          <strong>20.4. Attorneys’ Fees.</strong> In the event any litigation or arbitration is
          brought by either party in connection with these Terms, except as otherwise provided in
          Section 19.5, the prevailing party shall be entitled to recover from the other party all
          the reasonable costs, attorneys’ fees and other expenses incurred by such prevailing party
          in the litigation.
        </p>
        <p>
          <strong>20.5. No Waiver.</strong> Our failure to enforce any provision of these Terms
          shall in no way be construed to be a present or future waiver of such provision, nor in
          any way affect our right to enforce the same provision at a later time. An express waiver
          by HelloTech of any provision, condition or requirement of these Terms shall not be
          understood as a waiver of your obligation to comply with the same provision, condition or
          requirement at a later time.
        </p>
        <p>
          <strong>20.6. Notices</strong> All notices given by you or required under these Terms
          shall be in writing and sent to <SupportEmailLink />.
        </p>
        <p>
          <strong>20.7. Export Administration.</strong> You must comply with all export laws and
          regulations of the United States or any other country (“Export Controls”) and you shall
          not export, direct or transfer any portion of the Platform, or any direct product thereof,
          to any destination, person or entity restricted or prohibited by the Export Controls.
        </p>
        <p>
          <strong>20.8. Equitable Remedies.</strong> You acknowledge and agree that HelloTech would
          be irreparably damaged if the terms of these Terms were not specifically enforced, and
          therefore you agree that we shall be entitled, without bond, other security, or proof of
          damages, to appropriate equitable remedies with respect to breaches of these Terms, in
          addition to such other remedies as we may otherwise have available to us under applicable
          laws.
        </p>
        <p>
          <strong>20.9. Entire Agreement.</strong> These Terms, including the documents referenced
          in these Terms, constitute the entire agreement between you and HelloTech with respect to
          the Platform and supersede any and all prior agreements between you and HelloTech relating
          to the Platform.
        </p>
      </section>

      <br />
    </div>
  </Grid.Fluid>
);

UnderlinedInlineText.propTypes = { text: PropTypes.string };
UnformattedTextLink.propTypes = { link: PropTypes.string };
export default StandardTOS;

/*
  StandardTOS:
    - A template containing the standard sitewide terms of service.
    - Meant to be used with the TOSPages container.

  Examples:
    <StandardTOS />

  Last modified: -GH May 11, 2021
*/
