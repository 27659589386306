import { TYPES as PICTURE_TYPES } from 'src/components/Elements/Picture';
import { NAV_SUBCATEGORY_UPSELL_DATA } from 'src/components/Header/TopNavHeader/TopNavItemsV2/constants';
import { Breakpoints } from 'HTKit/Grid';
// Computers
import computersMobilePng from 'src/images/products/computers_mobile.png';
import computersTabletPng from 'src/images/products/computers_tablet.png';
import computersDesktopPng from 'src/images/products/computers_desktop.png';
import computersMobileWebp from 'src/images/products/computers_mobile.webp';
import computersTabletWebp from 'src/images/products/computers_tablet.webp';
import computersDesktopWebp from 'src/images/products/computers_desktop.webp';
// Printers
import printersMobilePng from 'src/images/products/printers_mobile.png';
import printersTabletPng from 'src/images/products/printers_tablet.png';
import printersDesktopPng from 'src/images/products/printers_desktop.png';
import printersMobileWebp from 'src/images/products/printers_mobile.webp';
import printersTabletWebp from 'src/images/products/printers_tablet.webp';
import printersDesktopWebp from 'src/images/products/printers_desktop.webp';
// Wifi & Network
import wifiNetworkMobilePng from 'src/images/products/routers_mobile.png';
import wifiNetworkTabletPng from 'src/images/products/routers_tablet.png';
import wifiNetworkDesktopPng from 'src/images/products/routers_desktop.png';
import wifiNetworkMobileWebp from 'src/images/products/routers_mobile.webp';
import wifiNetworkTabletWebp from 'src/images/products/routers_tablet.webp';
import wifiNetworkDesktopWebp from 'src/images/products/routers_desktop.webp';
// Mobile Devices
import mobileDevicesMobilePng from 'src/images/products/phones_and_tablets_mobile.png';
import mobileDevicesTabletPng from 'src/images/products/phones_and_tablets_tablet.png';
import mobileDevicesDesktopPng from 'src/images/products/phones_and_tablets_desktop.png';
import mobileDevicesMobileWebp from 'src/images/products/phones_and_tablets_mobile.webp';
import mobileDevicesTabletWebp from 'src/images/products/phones_and_tablets_tablet.webp';
import mobileDevicesDesktopWebp from 'src/images/products/phones_and_tablets_desktop.webp';
// Audio & Video
import universalRemoteMobilePng from 'src/images/products/universal_remotes_mobile.png';
import universalRemoteTabletPng from 'src/images/products/universal_remotes_tablet.png';
import universalRemoteDesktopPng from 'src/images/products/universal_remotes_desktop.png';
import universalRemoteMobileWebp from 'src/images/products/universal_remotes_mobile.webp';
import universalRemoteTabletWebp from 'src/images/products/universal_remotes_tablet.webp';
import universalRemoteDesktopWebp from 'src/images/products/universal_remotes_desktop.webp';
// Advanced Audio Systems
import advancedAudioMobilePng from 'src/images/products/advanced_audio_systems_mobile.png';
import advancedAudioTabletPng from 'src/images/products/advanced_audio_systems_tablet.png';
import advancedAudioDesktopPng from 'src/images/products/advanced_audio_systems_desktop.png';
import advancedAudioMobileWebp from 'src/images/products/advanced_audio_systems_mobile.webp';
import advancedAudioTabletWebp from 'src/images/products/advanced_audio_systems_tablet.webp';
import advancedAudioDesktopWebp from 'src/images/products/advanced_audio_systems_desktop.webp';
// Locks
import yaleLocksMobilePng from 'src/images/products/yale_locks_mobile.png';
import yaleLocksTabletPng from 'src/images/products/yale_locks_tablet.png';
import yaleLocksDesktopPng from 'src/images/products/yale_locks_desktop.png';
import yaleLocksMobileWebp from 'src/images/products/yale_locks_mobile.webp';
import yaleLocksTabletWebp from 'src/images/products/yale_locks_tablet.webp';
import yaleLocksDesktopWebp from 'src/images/products/yale_locks_desktop.webp';
// Smart Home
import thermostatsMobilePng from 'src/images/products/thermostats_mobile.png';
import thermostatsTabletPng from 'src/images/products/thermostats_tablet.png';
import thermostatsDesktopPng from 'src/images/products/thermostats_desktop.png';
import thermostatsMobileWebp from 'src/images/products/thermostats_mobile.webp';
import thermostatsTabletWebp from 'src/images/products/thermostats_tablet.webp';
import thermostatsDesktopWebp from 'src/images/products/thermostats_desktop.webp';
// Thermostat
import videoDoorbellMobilePng from 'src/images/products/video_doorbell_mobile.png';
import videoDoorbellTabletPng from 'src/images/products/video_doorbell_tablet.png';
import videoDoorbellDesktopPng from 'src/images/products/video_doorbell_desktop.png';
import videoDoorbellMobileWebp from 'src/images/products/video_doorbell_mobile.webp';
import videoDoorbellTabletWebp from 'src/images/products/video_doorbell_tablet.webp';
import videoDoorbellDesktopWebp from 'src/images/products/video_doorbell_desktop.webp';
// Garage Doors
import garageDoorMobilePng from 'src/images/products/garage_doors_mobile.png';
import garageDoorTabletPng from 'src/images/products/garage_doors_tablet.png';
import garageDoorDesktopPng from 'src/images/products/garage_doors_desktop.png';
import garageDoorMobileWebp from 'src/images/products/garage_doors_mobile.webp';
import garageDoorTabletWebp from 'src/images/products/garage_doors_tablet.webp';
import garageDoorDesktopWebp from 'src/images/products/garage_doors_desktop.webp';
// Hubs & Speakers
import hubsSpeakersMobilePng from 'src/images/products/hubs_and_speakers_mobile.png';
import hubsSpeakersTabletPng from 'src/images/products/hubs_and_speakers_tablet.png';
import hubsSpeakersDesktopPng from 'src/images/products/hubs_and_speakers_desktop.png';
import hubsSpeakersMobileWebp from 'src/images/products/hubs_and_speakers_mobile.webp';
import hubsSpeakersTabletWebp from 'src/images/products/hubs_and_speakers_tablet.webp';
import hubsSpeakersDesktopWebp from 'src/images/products/hubs_and_speakers_desktop.webp';
// Security Cameras
import securityCamerasMobilePng from 'src/images/products/security_cameras_mobile.png';
import securityCamerasTabletPng from 'src/images/products/security_cameras_tablet.png';
import securityCamerasDesktopPng from 'src/images/products/security_cameras_desktop.png';
import securityCamerasMobileWebp from 'src/images/products/security_cameras_mobile.webp';
import securityCamerasTabletWebp from 'src/images/products/security_cameras_tablet.webp';
import securityCamerasDesktopWebp from 'src/images/products/security_cameras_desktop.webp';

const getImageSources = (upsellDataIndex = null) => {
  if (!upsellDataIndex) return [];

  const showMdGrid = `(min-width: ${Breakpoints.MD_GRID}px)`;
  const showLgGrid = `(min-width: ${Breakpoints.LG_GRID}px)`;

  const computerImages = [
    { srcSet: computersDesktopWebp, type: PICTURE_TYPES.WEBP, media: showLgGrid },
    { srcSet: computersDesktopPng, type: PICTURE_TYPES.PNG, media: showLgGrid },
    { srcSet: computersTabletWebp, type: PICTURE_TYPES.WEBP, media: showMdGrid },
    { srcSet: computersTabletPng, type: PICTURE_TYPES.PNG, media: showMdGrid },
    { srcSet: computersMobileWebp, type: PICTURE_TYPES.WEBP },
    { srcSet: computersMobilePng, type: PICTURE_TYPES.PNG },
  ];
  const universalRemoteImages = [
    { srcSet: universalRemoteDesktopWebp, type: PICTURE_TYPES.WEBP, media: showLgGrid },
    { srcSet: universalRemoteDesktopPng, type: PICTURE_TYPES.PNG, media: showLgGrid },
    { srcSet: universalRemoteTabletWebp, type: PICTURE_TYPES.WEBP, media: showMdGrid },
    { srcSet: universalRemoteTabletPng, type: PICTURE_TYPES.PNG, media: showMdGrid },
    { srcSet: universalRemoteMobileWebp, type: PICTURE_TYPES.WEBP },
    { srcSet: universalRemoteMobilePng, type: PICTURE_TYPES.PNG },
  ];
  const thermostatImages = [
    { srcSet: thermostatsDesktopWebp, type: PICTURE_TYPES.WEBP, media: showLgGrid },
    { srcSet: thermostatsDesktopPng, type: PICTURE_TYPES.PNG, media: showLgGrid },
    { srcSet: thermostatsTabletWebp, type: PICTURE_TYPES.WEBP, media: showMdGrid },
    { srcSet: thermostatsTabletPng, type: PICTURE_TYPES.PNG, media: showMdGrid },
    { srcSet: thermostatsMobileWebp, type: PICTURE_TYPES.WEBP },
    { srcSet: thermostatsMobilePng, type: PICTURE_TYPES.PNG },
  ];
  const yaleLocksImages = [
    { srcSet: yaleLocksDesktopWebp, type: PICTURE_TYPES.WEBP, media: showLgGrid },
    { srcSet: yaleLocksDesktopPng, type: PICTURE_TYPES.PNG, media: showLgGrid },
    { srcSet: yaleLocksTabletWebp, type: PICTURE_TYPES.WEBP, media: showMdGrid },
    { srcSet: yaleLocksTabletPng, type: PICTURE_TYPES.PNG, media: showMdGrid },
    { srcSet: yaleLocksMobileWebp, type: PICTURE_TYPES.WEBP },
    { srcSet: yaleLocksMobilePng, type: PICTURE_TYPES.PNG },
  ];
  const securityCamerasImages = [
    { srcSet: securityCamerasDesktopWebp, type: PICTURE_TYPES.WEBP, media: showLgGrid },
    { srcSet: securityCamerasDesktopPng, type: PICTURE_TYPES.PNG, media: showLgGrid },
    { srcSet: securityCamerasTabletWebp, type: PICTURE_TYPES.WEBP, media: showMdGrid },
    { srcSet: securityCamerasTabletPng, type: PICTURE_TYPES.PNG, media: showMdGrid },
    { srcSet: securityCamerasMobileWebp, type: PICTURE_TYPES.WEBP },
    { srcSet: securityCamerasMobilePng, type: PICTURE_TYPES.PNG },
  ];
  const mobileDeviceImages = [
    { srcSet: mobileDevicesDesktopWebp, type: PICTURE_TYPES.WEBP, media: showLgGrid },
    { srcSet: mobileDevicesDesktopPng, type: PICTURE_TYPES.PNG, media: showLgGrid },
    { srcSet: mobileDevicesTabletWebp, type: PICTURE_TYPES.WEBP, media: showMdGrid },
    { srcSet: mobileDevicesTabletPng, type: PICTURE_TYPES.PNG, media: showMdGrid },
    { srcSet: mobileDevicesMobileWebp, type: PICTURE_TYPES.WEBP },
    { srcSet: mobileDevicesMobilePng, type: PICTURE_TYPES.PNG },
  ];
  const wifiNetworkImages = [
    { srcSet: wifiNetworkDesktopWebp, type: PICTURE_TYPES.WEBP, media: showLgGrid },
    { srcSet: wifiNetworkDesktopPng, type: PICTURE_TYPES.PNG, media: showLgGrid },
    { srcSet: wifiNetworkTabletWebp, type: PICTURE_TYPES.WEBP, media: showMdGrid },
    { srcSet: wifiNetworkTabletPng, type: PICTURE_TYPES.PNG, media: showMdGrid },
    { srcSet: wifiNetworkMobileWebp, type: PICTURE_TYPES.WEBP },
    { srcSet: wifiNetworkMobilePng, type: PICTURE_TYPES.PNG },
  ];

  const IMAGE_MAPPING_BY_UPSELL_DATA_KEY = {
    [NAV_SUBCATEGORY_UPSELL_DATA.COMPUTERS_PRINTERS.key]: computerImages,
    [NAV_SUBCATEGORY_UPSELL_DATA.COMPUTER.key]: computerImages,
    [NAV_SUBCATEGORY_UPSELL_DATA.PRINTER.key]: [
      { srcSet: printersDesktopWebp, type: PICTURE_TYPES.WEBP, media: showLgGrid },
      { srcSet: printersDesktopPng, type: PICTURE_TYPES.PNG, media: showLgGrid },
      { srcSet: printersTabletWebp, type: PICTURE_TYPES.WEBP, media: showMdGrid },
      { srcSet: printersTabletPng, type: PICTURE_TYPES.PNG, media: showMdGrid },
      { srcSet: printersMobileWebp, type: PICTURE_TYPES.WEBP },
      { srcSet: printersMobilePng, type: PICTURE_TYPES.PNG },
    ],
    [NAV_SUBCATEGORY_UPSELL_DATA.WIFI_NETWORK.key]: wifiNetworkImages,
    [NAV_SUBCATEGORY_UPSELL_DATA.ROUTERS.key]: wifiNetworkImages,
    [NAV_SUBCATEGORY_UPSELL_DATA.MOBILE_DEVICES.key]: mobileDeviceImages,
    [NAV_SUBCATEGORY_UPSELL_DATA.PHONES_TABLETS.key]: mobileDeviceImages,
    [NAV_SUBCATEGORY_UPSELL_DATA.AUDIO_VIDEO.key]: universalRemoteImages,
    [NAV_SUBCATEGORY_UPSELL_DATA.HOME_THEATER.key]: universalRemoteImages,
    [NAV_SUBCATEGORY_UPSELL_DATA.UNIVERSAL_REMOTE.key]: universalRemoteImages,
    [NAV_SUBCATEGORY_UPSELL_DATA.ADVANCED_AUDIO_SYSTEM.key]: [
      { srcSet: advancedAudioDesktopWebp, type: PICTURE_TYPES.WEBP, media: showLgGrid },
      { srcSet: advancedAudioDesktopPng, type: PICTURE_TYPES.PNG, media: showLgGrid },
      { srcSet: advancedAudioTabletWebp, type: PICTURE_TYPES.WEBP, media: showMdGrid },
      { srcSet: advancedAudioTabletPng, type: PICTURE_TYPES.PNG, media: showMdGrid },
      { srcSet: advancedAudioMobileWebp, type: PICTURE_TYPES.WEBP },
      { srcSet: advancedAudioMobilePng, type: PICTURE_TYPES.PNG },
    ],
    [NAV_SUBCATEGORY_UPSELL_DATA.HOME_SECURITY.key]: yaleLocksImages,
    [NAV_SUBCATEGORY_UPSELL_DATA.LOCKS.key]: yaleLocksImages,
    [NAV_SUBCATEGORY_UPSELL_DATA.SMART_HOME.key]: thermostatImages,
    [NAV_SUBCATEGORY_UPSELL_DATA.THERMOSTATS.key]: thermostatImages,
    [NAV_SUBCATEGORY_UPSELL_DATA.VIDEO_DOORBELL.key]: [
      { srcSet: videoDoorbellDesktopWebp, type: PICTURE_TYPES.WEBP, media: showLgGrid },
      { srcSet: videoDoorbellDesktopPng, type: PICTURE_TYPES.PNG, media: showLgGrid },
      { srcSet: videoDoorbellTabletWebp, type: PICTURE_TYPES.WEBP, media: showMdGrid },
      { srcSet: videoDoorbellTabletPng, type: PICTURE_TYPES.PNG, media: showMdGrid },
      { srcSet: videoDoorbellMobileWebp, type: PICTURE_TYPES.WEBP },
      { srcSet: videoDoorbellMobilePng, type: PICTURE_TYPES.PNG },
    ],
    [NAV_SUBCATEGORY_UPSELL_DATA.GARAGE_DOORS.key]: [
      { srcSet: garageDoorDesktopWebp, type: PICTURE_TYPES.WEBP, media: showLgGrid },
      { srcSet: garageDoorDesktopPng, type: PICTURE_TYPES.PNG, media: showLgGrid },
      { srcSet: garageDoorTabletWebp, type: PICTURE_TYPES.WEBP, media: showMdGrid },
      { srcSet: garageDoorTabletPng, type: PICTURE_TYPES.PNG, media: showMdGrid },
      { srcSet: garageDoorMobileWebp, type: PICTURE_TYPES.WEBP },
      { srcSet: garageDoorMobilePng, type: PICTURE_TYPES.PNG },
    ],
    [NAV_SUBCATEGORY_UPSELL_DATA.HUBS_SPEAKERS.key]: [
      { srcSet: hubsSpeakersDesktopWebp, type: PICTURE_TYPES.WEBP, media: showLgGrid },
      { srcSet: hubsSpeakersDesktopPng, type: PICTURE_TYPES.PNG, media: showLgGrid },
      { srcSet: hubsSpeakersTabletWebp, type: PICTURE_TYPES.WEBP, media: showMdGrid },
      { srcSet: hubsSpeakersTabletPng, type: PICTURE_TYPES.PNG, media: showMdGrid },
      { srcSet: hubsSpeakersMobileWebp, type: PICTURE_TYPES.WEBP },
      { srcSet: hubsSpeakersMobilePng, type: PICTURE_TYPES.PNG },
    ],
    [NAV_SUBCATEGORY_UPSELL_DATA.SECURITY_CAMERAS.key]: securityCamerasImages,
    [NAV_SUBCATEGORY_UPSELL_DATA.ALARM_SYSTEMS.key]: securityCamerasImages,
  };

  return IMAGE_MAPPING_BY_UPSELL_DATA_KEY[upsellDataIndex] || [];
};

export const getUpsellData = (category = {}) => {
  const { upsellData = { text: null, index: null } } = category;
  if (!upsellData) return { text: '' };
  const text = upsellData.text
    ? `Get 24/7 unlimited ${upsellData.text} support with HelloTech Home`
    : '';
  const imageSources = getImageSources(upsellData.key);
  return { text, imageSources };
};
