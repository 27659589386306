import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Icon from 'HTKit/Icon';
import NavSubCategoryUpsell from 'src/components/Upsell/NavSubCategoryUpsell';
import CategoryBadge from 'src/components/Sections/HomePageHero/Parts/CategoryBadge';
import PhoneFooter from '../PhoneFooter';
import styles from './styles.scss';

/**
  Simple list item component with predetermined styles
*/
const ListItem = ({ children, className }) => {
  const listItemStyles = cn(styles.menuItemWrapper, className);
  return <li className={listItemStyles}>{children}</li>;
};

ListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ListItem.defaultProps = {
  className: '',
};

/**
  Simple text component with predetermined styles.
*/
export const CopyComponent = ({ text, isPrimary, showBadge }) => {
  const copyStyles = isPrimary ? 'h5' : 'p1';
  return (
    <p className={copyStyles}>
      {text}
      {showBadge && <CategoryBadge text="New" />}
    </p>
  );
};

CopyComponent.propTypes = {
  text: PropTypes.string,
  isPrimary: PropTypes.bool,
  showBadge: PropTypes.bool,
};

/**
  Wrapper for an item in the menu
*/
const MenuItem = ({
  text,
  link,
  isExternalLink,
  onClick,
  onBackClick,
  hideIcon,
  isPrimaryItem,
  showBadge = false,
}) => {
  if (onClick) {
    const buttonStyles = cn('plainButton', styles.itemContent, styles.onClick);
    return (
      <ListItem>
        <button className={buttonStyles} onClick={onClick}>
          <CopyComponent text={text} isPrimary={isPrimaryItem} />
          {!hideIcon && <Icon name="chevron-right" className={styles.chevron} />}
        </button>
      </ListItem>
    );
  }
  if (onBackClick) {
    const buttonStyles = cn('plainButton', styles.itemContent, styles.onBackClick);
    const chevronStyles = cn(styles.chevron, styles.left);

    return (
      <ListItem>
        <button className={buttonStyles} onClick={onBackClick}>
          <Icon name="chevron-left" className={chevronStyles} />
          <CopyComponent text={text} isPrimary={isPrimaryItem} />
        </button>
      </ListItem>
    );
  }

  if (link) {
    const linkStyles = cn('-no-decoration', styles.itemContent, styles.link);
    return (
      <ListItem>
        {isExternalLink ? (
          <a href={link} className={linkStyles} target="_blank" rel="noopener noreferrer">
            <CopyComponent text={text} isPrimary={isPrimaryItem} />
          </a>
        ) : (
          <Link to={link} className={linkStyles}>
            <CopyComponent text={text} isPrimary={isPrimaryItem} showBadge={showBadge} />
          </Link>
        )}
      </ListItem>
    );
  }

  return (
    <ListItem className={styles.itemContent}>
      <CopyComponent text={text} isPrimary={isPrimaryItem} />
    </ListItem>
  );
};

MenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  isExternalLink: PropTypes.bool,
  onClick: PropTypes.func,
  hideIcon: PropTypes.bool,
  onBackClick: PropTypes.func,
  isPrimaryItem: PropTypes.bool,
  showBadge: PropTypes.bool,
};

/**
  Template to be used in the sidebar navigation menu

  headerItems/secondaryItems is an array of objects. The following is the expected values of an object.

  text - String - label of menu item
  link - String - link
  isExternalLink - Boolean - if link leads outside of HT
  onClick - Function - Function to trigger on click. Will also render the right chevron icon
  onBackClick - Function - Function to trigger on click. Will also render the left chevron icon

*/

export const MENU_TEMPLATE_TYPES = { DEFAULT: 'default', SORT_BY_SKU_TYPE: 'sortBySkuType' };

const getSecondaryItemLayout = ({ type, secondaryItems }) => {
  const defaultLayout = () =>
    secondaryItems.map((item, i) => <MenuItem key={`menuItem${i}`} {...item} />);
  const sortyBySkuTypeLayout = () =>
    secondaryItems.map((skuType) => {
      return (
        <>
          <h5 className={cn(styles.skuSubCategory, 'darkBlue700')}>{skuType.name}</h5>
          {(skuType.skus || []).map((sku, i) => (
            <MenuItem key={`menuItem${i}`} {...sku} />
          ))}
        </>
      );
    });

  const layoutMapping = {
    [MENU_TEMPLATE_TYPES.SORT_BY_SKU_TYPE]: sortyBySkuTypeLayout,
    default: defaultLayout,
  };

  return layoutMapping[type] ? layoutMapping[type]() : layoutMapping.default();
};

const MenuTemplate = ({ headerItems, secondaryItems, slide, visible, type, upsellData }) => {
  const wrapperStyles = cn(styles.templateWrapper, {
    [styles.slide]: slide,
    [styles.visible]: visible,
  });
  const secondaryItemLayout = getSecondaryItemLayout({ type, secondaryItems });

  return (
    <div className={wrapperStyles}>
      <div className={styles.linksContainer}>
        <ul className={styles.headerLinks}>
          {headerItems.map((item, i) => {
            return <MenuItem key={i} {...item} text={item.text} isPrimaryItem />;
          })}
        </ul>
        <div className={styles.scrollableSecondaryLinks}>
          <ul>{secondaryItemLayout}</ul>
          {upsellData && (
            <NavSubCategoryUpsell.MobileTablet
              {...upsellData}
              containerClassName="marginTop-huge"
            />
          )}
        </div>
      </div>

      <PhoneFooter />
    </div>
  );
};

MenuTemplate.propTypes = {
  headerItems: PropTypes.array,
  secondaryItems: PropTypes.array,
  slide: PropTypes.bool,
  visible: PropTypes.bool,
  type: PropTypes.string,
  upsellData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

MenuTemplate.defaultProps = {
  headerItems: [],
  secondaryItems: [],
  type: MENU_TEMPLATE_TYPES.DEFAULT,
  upsellData: false,
};

export default MenuTemplate;
