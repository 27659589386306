import { fromJS } from 'immutable';
import { SET_HEADER_LOGO } from './constants';

const initialState = fromJS({ logo: null });

function featuredContentOrSkuHeaderReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HEADER_LOGO: {
      const { payload: { logo } } = action;
      return state.set('logo', logo);
    }
    default:
      return state;
  }
}

export default featuredContentOrSkuHeaderReducer;
