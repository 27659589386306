import { fromJS } from 'immutable';
import { INVALID_FORM } from 'src/constants/common';
import {
  PLAN_LOADED,
  PLAN_ORDERED,
  USER_CHECKED,
  PAYMENT_EDIT_MODE,
  GIFT_CARD_MESSAGE,
  PLAN_PRICES_UPDATED,
  UPDATE_PLAN_PRICES,
} from './constants';

const planPageInitialState = fromJS({
  plan: null,
  discount: null,
  breakdown: {},
  existingUser: false,
  priceIsUpdating: false,
  planInformation: {},
  errors: {},
});

export default function planReducer(state = planPageInitialState, action) {
  switch (action.type) {
    case PLAN_LOADED:
      return state.merge({ plan: action.payload.plan, discount: null });
    case PLAN_ORDERED:
      return state.mergeDeep({ plan: action.payload.plan });
    case USER_CHECKED:
      return state.merge({ existingUser: action.payload.exists });
    case PAYMENT_EDIT_MODE:
      return state.set('newCard', action.payload.editMode);
    case GIFT_CARD_MESSAGE:
      return state.set('giftCardMessage', action.payload.giftCardMessage);
    case UPDATE_PLAN_PRICES:
      return state.set('priceIsUpdating', true);
    case INVALID_FORM: {
      if (action.page !== 'plan') return state;
      let newState = state.set('priceIsUpdating', false);
      if (action.errors.couponCode) {
        newState = newState.set('discount', fromJS({}));
      }
      return newState;
    }
    case PLAN_PRICES_UPDATED: {
      const { monthlyTotalNoTax, yearlyTotalNoTax } = action.breakdown;
      return state.merge({
        discount: {
          monthlyPrice: monthlyTotalNoTax,
          yearlyPrice: yearlyTotalNoTax,
        },
        priceIsUpdating: false,
        breakdown: action.breakdown,
      });
    }
    default:
      return state;
  }
}
