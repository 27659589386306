import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import Icon from 'src/components/HTKit/Icon';
import Grid from 'src/components/HTKit/Grid/Grid';

// Utils & Helpers
import { productPath } from 'src/utils/paths';
import { searchResultsClickedSegment } from 'src/components/Searchbar/actions';

// Styles
import styles from '../styles.scss';

const ProductResults = ({ productResults, iconClick, getItemProps, searchTerm }) => {
  const dispatch = useDispatch();

  return (
    !!productResults.length && (
      <>
        <p className="caption marginTop-medium1 paddingBottom-tiny1 paddingX-small1 n700">
          PRODUCTS ({productResults.length})
        </p>
        {productResults.map((product, i) => {
          const onClick = () => {
            dispatch(searchResultsClickedSegment({ searchTerm, clickedItem: "product", attributes: product }));
            iconClick();
          };
          return (
            <div key={i} onClick={onClick}>
              <Grid.FullWidth classes={styles.categoryContainer}>
                <Grid.Row classes={styles.customRow}>
                  <Link
                    {...getItemProps({
                      key: product.id.raw,
                      item: product,
                    })}
                    to={productPath(product.seo_name.raw)}
                    className={cn('paddingX-small1', styles.clickContainer)}
                  >
                    <Grid.Column classes={cn('paddingY-small1', styles.productColumnContainer)}>
                      <div className={styles.innerContainer}>
                        <img
                          src={product.image.raw}
                          className={styles.productImage}
                          alt={product.name.raw}
                        />
                        <div className={cn('h6 paddingLeft-small1', styles.name)}>
                          {product.name.raw}
                        </div>
                      </div>
                      <Icon name="chevron-right" className={styles.chevron} />
                    </Grid.Column>
                  </Link>
                </Grid.Row>
              </Grid.FullWidth>
            </div>
          );
        })}
      </>
    )
  );
};

ProductResults.defaultProps = {
  productResults: [],
  iconClick: () => {},
};

ProductResults.propTypes = {
  iconClick: PropTypes.func,
  getItemProps: PropTypes.func,
  productResults: PropTypes.array,
  searchTerm: PropTypes.string,
};

export default ProductResults;
