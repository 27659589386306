export const AUTO_RENEW = {
  modalName: 'autoRenew',
  buttonId: 'autoRenewOn',
  autoRenewLocation: 'autoRenewUpsell',
};

export const OPTIONS = [
  { value: true, title: 'On' },
  { value: false, title: 'Off' },
];

export const TABS = [
  'Plan Details',
  'Billing Details',
  // 'Payment History'
];

export const PLAN_DETAILS = [
  'Plan Type',
  'Status',
  'Member Since',
  'Renews On'
];

export const BILLING_DETAILS = {
  BILLING_TYPE: 'Billing Type',
  NEXT_PAYMENT: 'Next Payment On',
  PAYMENT_AMOUNT: 'Payment Amount',
  PAYMENT_METHOD: 'Payment Method',
};

// export const PAYMENT_HISTORY = [
//   'Date',
//   'Payment Method',
//   'Total'
// ]
