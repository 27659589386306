import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cartSelector } from 'src/selectors/cart';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import Icon from 'src/components/HTKit/Icon';
import styles from './styles.scss';

const MembersPrice = ({
  sku,
  onClick,
  buttonStyle,
  hasSubscription,
  cart
}) => {
  const cartHasPlan = !!cart.getIn(['plan', 'id']);
  if (!sku || hasSubscription || cartHasPlan) return null;

  const cheapestPrice = sku.getIn(['subscriptionPrices', 'cheapestPrice']);
  if (typeof cheapestPrice !== 'number') return null;

  const cheapestPriceFormatted = sku.getIn(['subscriptionPrices', 'cheapestPriceFormatted']);
  const buttonStyles = `${styles.membersButton} ${buttonStyle}`;

  return (
    <Button
      className={buttonStyles}
      theme={THEMES.V2SECONDARY}
      smallV2
      onClick={onClick}
    >
      <span className={styles.textWrapper}>
        Member&apos;s Price {cheapestPriceFormatted}
        <Icon name="v2-info-icon-default" className={styles.icon} />
      </span>
    </Button>
  );
}

MembersPrice.propTypes = {
  sku: PropTypes.object,
  onClick: PropTypes.func,
  buttonStyle: PropTypes.any,
  hasSubscription: PropTypes.bool.isRequired,
  cart: PropTypes.object,
};

MembersPrice.defaultProps = {
  onClick: () => { },
  buttonStyle: ''
};

const mapStateToProps = (state) => {
  return {
    hasSubscription: state.getIn(['user', 'account', 'hasSubscription']),
    cart: cartSelector(state),
  };
};

export default connect(mapStateToProps, {})(MembersPrice);
