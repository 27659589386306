import moment from 'moment';
import {
  formatTimeSpanDisplay,
  normalizeAvailabilityForWindowBasedView,
} from 'src/components/AvailabilitySelectorWithConfig/Scheduling.utils';
import { TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES } from 'src/components/AvailabilitySelectorWithConfig/Scheduling.constants';
import {
  RECURRING_FREQUENCY_VALUES,
  MONTHLY_OPTION_TYPES,
  CUSTOM_MONTHLY_VIEW_TABS,
} from './RecurringServicePanel.constants';
import { ensureImmutable } from 'src/utils/helpers';

/** Given a number, returns a string representing the ordinal form of that number. */
export const getOrdinal = (n) => {
  const lastTwoDigits = n % 100; // Get last two digits instead of just the last one
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return `${n}th`;
  }

  const lastDigit = n % 10;
  switch (lastDigit) {
    case 1:
      return `${n}st`;
    case 2:
      return `${n}nd`;
    case 3:
      return `${n}rd`;
    default:
      return `${n}th`;
  }
};

// #################
// DROPDOWN OPTIONS
// #################

/**
 * Creates options for recurring frequency dropdown, handling singular/plural labels based on interval.
 * Returns Week(s) and Month(s) choices with appropriate labels based on the interval value.
 */
export const createOptionsRecurringFrequency = (interval = 1) => {
  const usePlurals = interval > 1;
  return [
    { value: RECURRING_FREQUENCY_VALUES.WEEKLY, label: usePlurals ? 'Weeks' : 'Week' },
    { value: RECURRING_FREQUENCY_VALUES.MONTHLY, label: usePlurals ? 'Months' : 'Month' },
  ];
};

/**
 * Generates a list of available start times for the recurring service panel.
 *
 * Assumptions:
 * 1. The first available time slot may have fewer options due to it potentially being the current day.
 * 2. The last available time slot is likely to have the most options as it's furthest in the future.
 * 3. Using API-provided data ensures alignment with business hours and time increments.
 */
export const createOptionsStartTimeForExactTime = (availableTimes) => {
  if (availableTimes.length === 0) return [];

  // Human readable label
  const formatHourLabel = (hour) => {
    const period = hour.hour >= 12 ? 'PM' : 'AM';
    const displayHour = hour.hour % 12 || 12; // Convert 0 to 12 for 12 AM
    const displayMinute = hour.minute.toString().padStart(2, '0');
    return `${displayHour}:${displayMinute} ${period}`;
  };

  // 24-hour format to send to the API
  const formatDateTime = (hour) => `${hour.hour}:${hour.minute.toString().padStart(2, '0')}`;

  const lastDay = availableTimes[availableTimes.length - 1];
  return lastDay.hours.map((hour, i) => ({
    value: i + 1,
    label: formatHourLabel(hour),
    dateTime: hour.dateTime,
    hours: [formatDateTime(hour)],
  }));
};

export const createOptionsStartTimeForTimeWindows = (availableTimes) => {
  if (!availableTimes) return [];

  const timeWindows = normalizeAvailabilityForWindowBasedView({
    availability: ensureImmutable(availableTimes),
    config: TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES,
  });

  const lastDay = Array.from(timeWindows).pop();
  if (!lastDay) return [];

  /** @param {import('src/types/availability').HourData} hourData */
  const formatHourDataToTimeString = (hourData) => {
    const m = moment();
    m.set('hour', hourData.hour);
    m.set('minute', hourData.minute);
    return m.format('HH:mm');
  };

  return lastDay.hours.map((window, i) => {
    const formattedHoursList = window.map((hour) => {
      return formatHourDataToTimeString(hour);
    });
    return {
      value: i + 1,
      label: formatTimeSpanDisplay(window),
      dateTime: window[0].dateTime,
      hours: formattedHoursList,
    };
  });
};

/**
 * Given a date string in the format 'YYYY-MM-DDTHH:mm:ssZ', returns the "sequence" of the month the date falls on.
 * The sequence is calculated by dividing the day of the month by 7 and rounding up, which effectively gives the
 * week number of the month. For example, the 1st of the month is sequence 1, the 8th is sequence 2, and the 15th is
 * sequence 3.
 */
export const getSequenceFromDateTime = (dateTime) => {
  const momentDate = moment(dateTime);
  return Math.ceil(momentDate.date() / 7);
};

/**
 * Generates monthly recurrence options based on a given date:
 * - Same day each month (e.g. "15th of the month")
 * - Same relative day (e.g. "3rd Monday of the month")
 * - Custom selection
 *
 * @param {string} selectedDate - Date in YYYY-MM-DD format
 */
export const createOptionsMonthlyDaysOfTheMonth = (selectedDate) => {
  const customOption = { value: MONTHLY_OPTION_TYPES.CUSTOM, label: 'Custom...' };
  if (!selectedDate) return [customOption];

  const momentDate = moment(selectedDate);
  const pluralizedDay = `${momentDate.format('Do')} of the month`;
  const dayName = momentDate.format('dddd');
  const dayOfTheWeek = momentDate.day();
  const dayOfTheMonth = momentDate.date();
  const sequence = getSequenceFromDateTime(selectedDate);

  return [
    { value: MONTHLY_OPTION_TYPES.DAY_OF_THE_MONTH, label: pluralizedDay, dayOfTheMonth },
    {
      value: MONTHLY_OPTION_TYPES.ON_THE_ORDINAL,
      label: `${getOrdinal(sequence)} ${dayName} of the month`,
      sequence,
      dayOfTheWeek,
    },
    customOption,
  ];
};

/**
 * Creates ordinal options for monthly recurrence (1st, 2nd, 3rd, 4th, 5th, Last),
 * used to specify which week of the month a recurring event should occur on.
 */
export const createOptionsMonthlyCustomOrdinal = () => {
  const options = [];
  for (let week = 1; week <= 5; week += 1) {
    options.push({ value: week, label: getOrdinal(week), sequence: week });
  }
  options.push({ value: 99, label: 'Last', sequence: -1 });
  return options;
};

/**
 * Creates weekday options for monthly recurrence (Sunday through Saturday),
 * mapping each day to both its display name and zero-based day number.
 */
export const createOptionsMonthlyCustomWeekDays = () => {
  return [
    { value: 1, label: 'Sunday', dayOfTheWeek: 0 },
    { value: 2, label: 'Monday', dayOfTheWeek: 1 },
    { value: 3, label: 'Tuesday', dayOfTheWeek: 2 },
    { value: 4, label: 'Wednesday', dayOfTheWeek: 3 },
    { value: 5, label: 'Thursday', dayOfTheWeek: 4 },
    { value: 6, label: 'Friday', dayOfTheWeek: 5 },
    { value: 7, label: 'Saturday', dayOfTheWeek: 6 },
  ];
};

// ###################
// RECURRENCE PAYLOADS
// ###################

/**
 * Creates a weekly recurrence payload for the API, specifying which days
 * of the week a service should repeat on, at what interval and time.
 */
export const createPayloadWeekly = ({ startDate, days, interval, availability }) => {
  return {
    weekly: {
      days,
    },
    interval,
    availability,
    start_date: startDate,
  };
};

/**
 * Creates a monthly recurrence payload for services that repeat on specific dates
 * (e.g., "5th and 20th of every month"), specifying the interval and time.
 */
export const createPayloadMonthlySpecificDays = ({
  specific_days,
  interval,
  availability,
  startDate,
}) => {
  return {
    monthly: {
      specific_days,
    },
    interval,
    availability,
    start_date: startDate,
  };
};

/**
 * Creates a monthly recurrence payload for services that repeat on relative days
 * (e.g., "third Thursday of every month"), specifying the interval and time.
 */
export const createPayloadMonthlyOnThe = ({
  startDate,
  sequence,
  day_of_week,
  interval,
  availability,
}) => {
  return {
    monthly: {
      on_the: {
        sequence,
        day_of_week,
      },
    },
    interval,
    availability,
    start_date: startDate,
  };
};

/**
 * Creates an appropriate monthly recurrence payload based on the selected options.
 * Handles three patterns: specific date ("15th"), relative date ("third Thursday"),
 * or custom selections (multiple dates or complex patterns).
 */
export const createPayloadMonthly = ({
  monthlyRecurrenceOption,
  selectedStartTime,
  startDate,
  recurrenceInterval,
  customMenuView,
  monthlyCustomOrdinal,
  monthlyCustomWeekdays,
  monthlyCustomDays,
}) => {
  // DAY_OF_THE_MONTH, ON_THE_ORDINAL, and CUSTOM are the dropdown options for monthly recurrence.
  // Within CUSTOM we have two options separated by different vieww (customMenuView)
  if (monthlyRecurrenceOption?.value === MONTHLY_OPTION_TYPES.DAY_OF_THE_MONTH) {
    if (selectedStartTime?.hours) {
      return createPayloadMonthlySpecificDays({
        startDate,
        specific_days: [monthlyRecurrenceOption.dayOfTheMonth],
        interval: recurrenceInterval,
        availability: selectedStartTime.hours,
      });
    }
  }
  if (monthlyRecurrenceOption?.value === MONTHLY_OPTION_TYPES.ON_THE_ORDINAL) {
    if (
      selectedStartTime?.hours &&
      monthlyRecurrenceOption?.sequence &&
      monthlyRecurrenceOption?.dayOfTheWeek
    ) {
      return createPayloadMonthlyOnThe({
        startDate,
        sequence: monthlyRecurrenceOption.sequence,
        day_of_week: monthlyRecurrenceOption.dayOfTheWeek,
        interval: recurrenceInterval,
        availability: selectedStartTime.hours,
      });
    }
  }
  if (monthlyRecurrenceOption?.value === MONTHLY_OPTION_TYPES.CUSTOM) {
    if (customMenuView === CUSTOM_MONTHLY_VIEW_TABS.ON_THE_ORDINAL) {
      if (selectedStartTime?.hours && monthlyCustomOrdinal?.value && monthlyCustomWeekdays?.value) {
        return createPayloadMonthlyOnThe({
          startDate,
          sequence: monthlyCustomOrdinal.sequence,
          day_of_week: monthlyCustomWeekdays.dayOfTheWeek,
          interval: recurrenceInterval,
          availability: selectedStartTime.hours,
        });
      }
    }
    if (customMenuView === CUSTOM_MONTHLY_VIEW_TABS.SPECIFIC_DAYS) {
      if (selectedStartTime?.hours && monthlyCustomDays?.length) {
        return createPayloadMonthlySpecificDays({
          startDate,
          specific_days: monthlyCustomDays,
          interval: recurrenceInterval,
          availability: selectedStartTime.hours,
        });
      }
    }
  }
  return null;
};

// ############
// INITIALIZERS
// ############

/**
 * Creates helper functions to compute initial state values for time, weekday, and date
 * selections. Each helper prioritizes matching values from existing selections before
 * falling back to defaults.
 *
 * @param {Object} options - options for the start time dropdown
 * @param {import('src/types/service').TRecurrence} options.savedRecurrence - saved recurrence data
 */
export const getStateInitializers = ({ optionsStartTime, selectedTimes, savedRecurrence }) => {
  const getInitialStartTime = () => {
    if (!selectedTimes.length) return optionsStartTime[0];
    const match = optionsStartTime.find(
      (option) =>
        moment(option.dateTime).hour() === moment(selectedTimes[0]).hour() &&
        moment(option.dateTime).minute() === moment(selectedTimes[0]).minute(),
    );
    return match ?? optionsStartTime[0];
  };

  const getInitialStartTimeFromSavedRecurrence = () => {
    if (!savedRecurrence) return optionsStartTime[0];
    const savedStartTime = savedRecurrence.availability[0];

    if (!savedStartTime) return optionsStartTime[0];

    const normalizeTime = (time) => {
      // Guard against hours before 10am that may be formatted with a leading zero
      // e.g. "09:00" instead of "9:00"
      const [hours, minutes] = time.split(':');
      return `${hours.padStart(2, '0')}:${minutes}`;
    };

    const match = optionsStartTime.find(
      (option) => normalizeTime(option.hours[0]) === normalizeTime(savedStartTime),
    );

    return match ?? optionsStartTime[0];
  };

  const getInitialSelectedDay = () => {
    if (!selectedTimes.length) return 0;
    return moment(selectedTimes[0]).day();
  };

  const getInitialSelectedDate = () => {
    if (!selectedTimes.length) return 1;
    return moment(selectedTimes[0]).date();
  };

  return {
    /** Initialize with the same hour && minute as the first selected time OR the first option in the dropdown */
    getInitialStartTime,
    /** Initialize with the same day of the week (0-6) as the first selected time OR Sunday */
    getInitialSelectedDay,
    /** Initialize with the same date (1-31) as the first selected time OR the first day of the month */
    getInitialSelectedDate,
    /** Initialize with the same time as the first saved time OR the first option in the dropdown */
    getInitialStartTimeFromSavedRecurrence,
  };
};
