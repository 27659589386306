import React, { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'src/components/HTKit/Icon';
import styles from './styles.scss';

const Accordion = ({
  title,
  children,
  onToggle = () => {},
  opened = false,
  className,
  noPadding,
}) => {
  const [showMore, setShowMore] = useState(opened);
  const accordionContainerStyles = cn(className, 'site-v2');
  const rotationStyles = cn(styles.chevronIcon, {
    [styles.rotate]: showMore,
  });
  const contentStyles = cn(styles.accordionContent, {
    [styles.closed]: !showMore,
    [styles.noPadding]: noPadding,
  });

  useEffect(() => {
    setShowMore(opened);
  }, [opened]);

  const toggleAccordion = useCallback(() => {
    const shouldShow = !showMore;
    setShowMore(shouldShow);
    onToggle(shouldShow);
  }, [showMore]);

  return (
    <div className={accordionContainerStyles}>
      <div className={styles.titleContainer} onClick={toggleAccordion}>
        <div className={rotationStyles}>
          <Icon name="chevron-right" className={styles.chevronIcon} />
        </div>
        <h5 className={styles.title}>{title}</h5>
      </div>
      <div className={contentStyles}>{children}</div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  onToggle: PropTypes.func,
  opened: PropTypes.bool,
  noPadding: PropTypes.bool,
};

export default React.memo(Accordion);

// Note: In mobile, there is padding on both sides of it because of the l-content padding on page.

/*
  Accordion: Wrapper for accordion style dropdown

  Usage:
  import Accordion from 'src/components/Elements/Accordion'

  <Accordion title="Accordion Title" className={styles.yourCustomClassName}>
    <YourComponent> or other content
  </Accordion>
*/
