import { makeRatingJsonLd } from './rating';
import { makePriceRangeJsonLd } from './offer';
import { makeLocalBusinessJsonLd } from './business';
import { makeOrganizationJsonLd } from './organization';
import { makeOpeningHoursJsonLd } from './hours';
import { locationAddress } from './utils';
import { SCRIPT_TYPE } from './constants';

const makeGeoRating = (location) => {
  const attrs = location.ratingAttributes;
  if (!attrs) return '';
  if (attrs.rating == null || attrs.count == null) return '';
  return makeRatingJsonLd(attrs);
};

export const makeGeoJsonLd = (location, phone) => ({
  type: SCRIPT_TYPE,
  innerHTML: `{
  "@context": "http://schema.org",
  ${makeLocalBusinessJsonLd(location.name, location.title, phone)},
  ${makeOpeningHoursJsonLd()},
  "parentOrganization": {
    ${makeOrganizationJsonLd()}
  },
  ${makePriceRangeJsonLd()},
  ${makeGeoRating(location)},
  "address": "${locationAddress(location)}"
}`,
});
