import React from 'react';
import AMAZON_TRACKING_VALUES from 'src/utils/tracking/amazon/TrackingValues';
import PropTypes from 'prop-types';

const AmazonTracking = ({ pageName }) => {
  if (!process.browser) return null;
  const amazonTrackingData = AMAZON_TRACKING_VALUES[pageName] || {};
  const iframeExists = document.querySelector(`[id*='${amazonTrackingData.pixelId}']`);

  if (!iframeExists && process.env.NODE_ENV === 'production') {
    const protocol = '//';
    const a = Math.random() * 1000000000000000000;
    const pix = document.createElement('iframe');
    pix.style.display = 'none';
    pix.setAttribute('src', `${protocol + amazonTrackingData.attributeLink}&cb=${a}`);
    pix.setAttribute('id', amazonTrackingData.pixelId);
    document.body.appendChild(pix);
  }
  return null;
}

AmazonTracking.propTypes = {
  pageName: PropTypes.string.isRequired,
};

export default React.memo(AmazonTracking);

/*
Import this component into the page you want to add tracking pixels.
The pageName should match the string value in TrackingValues.js
<AmazonTrackingPixels pageName="name">
*/
