import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'src/components/HTKit/Icon';
import styles from './styles.scss';

const SelectArrow = ({ innerProps, selectProps }) => (
  <Icon
    name="chevron"
    {...innerProps}
    className={cn(styles.arrow, { [styles.focused]: selectProps.menuIsOpen })}
  />
);

SelectArrow.propTypes = {
  innerProps: PropTypes.shape({}),
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
  }),
};

export default SelectArrow;
