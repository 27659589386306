import React, { memo, forwardRef } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useServicePageContext } from 'src/containers/ServicePage/context/context';
import ServicePrice from 'src/components/Service/ServicePrice';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import SubNav from 'src/components/Layout/SubNav';
import ServiceImage from 'src/components/OrderSummary/Breakdown/ServiceImage';
import { SERVICE_HEADER_TRIGGER_ID } from './constants';
import styles from './styles.scss';

const ServiceHeader = (
  {
    service,
    onBook,
    onInfo,
    showFixedPrice,
    linethroughPrice,
    trigger = `#${SERVICE_HEADER_TRIGGER_ID}`,
    customCta = '',
  },
  ref,
) => {
  const priceStyles = linethroughPrice ? styles.linethroughPrice : styles.price;
  const serviceImage = get(service, 'extra.image', undefined);
  const showPrice = get(service, 'extra.showZipCodeTest', false);
  const [servicePageContextState] = useServicePageContext();
  const isZipCodeGateSuccessFlow = get(servicePageContextState, 'zipCodeGate.isZipSuccessViewActive', false);

  return (
    <SubNav
      ref={ref}
      innerClasses={cn('flex alignItemsCenter justifyContentSpaceBetween', styles.header)}
      startElementSelector={trigger}
    >
      <ServiceImage image={serviceImage} />
      <div className={priceStyles}>
        <h5 className="h5">{service.name}</h5>
        {!showPrice || isZipCodeGateSuccessFlow && (
          <ServicePrice
            service={service}
            sm
            onInfo={onInfo}
            showFixedPrice={showFixedPrice}
            linethroughPrice={linethroughPrice}
          />
        )}
      </div>
      <Button theme={THEMES.V2PRIMARY} hugeV2 onClick={onBook} className={styles.button}>
        {customCta || service.extra.ctaText}
      </Button>
    </SubNav>
  );
};

ServiceHeader.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string,
    extra: PropTypes.shape({
      ctaText: PropTypes.string,
      image: PropTypes.string,
    }),
  }),
  onBook: PropTypes.func.isRequired,
  onInfo: PropTypes.func.isRequired,
  trigger: PropTypes.string,
  showFixedPrice: PropTypes.string,
  linethroughPrice: PropTypes.bool,
  customCta: PropTypes.string,
};

export default memo(forwardRef(ServiceHeader));
