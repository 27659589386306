import React from 'react';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { fromJS } from 'immutable';
import { last } from 'lodash';

export function buildGeoServiceLink(location, service) {
  const geoURL = last(location.getIn(['geoPath'], fromJS([{}])).toJS()).link;
  return [geoURL, service.get('seoName')].join('/');
}

export const scrollToLocations = (offset = -50) => () =>
  scroller.scrollTo('locations', { duration: 1000, delay: 100, smooth: true, offset });

export const getLocationScrollerName = (location) => `Choose another ${location.get('type')}`;

export const layout = {};

export function makeParagraph(elements) {
  return elements
    .toJS()
    .map((element, i) =>
      element[0] === 'text'
        ? React.createElement('span', { key: i }, element[1])
        : React.createElement(Link, { to: element[1][1], key: i }, element[1][0]),
    );
}
