// Libraries
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Constants
import GUARANTEE_BADGES from './constants';
// Styles
import styles from './styles.scss';

const SecurePayment = (props) => {
  const { containerStyle } = props;

  return (
    <div className={cn('site-v2', containerStyle)}>
      <div className={styles.container}>
        <span className={cn('p2', styles.title)}>Secure Credit Card Payment</span>
      </div>
      <div className={styles.guaranteeWrapper}>
        {GUARANTEE_BADGES.map((badge, i) => {
          return <img src={badge.url} key={i} className={styles.guarantee} alt={badge.name} />;
        })}
      </div>
    </div>
  );
};

SecurePayment.defaultProps = {
  containerStyle: '',
};

SecurePayment.propTypes = {
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default memo(SecurePayment);

/*
  SecurePayment:
    A component which serves as a confidence badge for secure payments

  Examples:
    <SecurePayment />

  Properties:
    containerStyle:
      type: String or object
      description: Styles to add to the container

  Last modified: -Jk May 25, 2017
*/
