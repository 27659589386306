import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Bar from '../Bar';

const Relative = ({
  pages,
  pageName,
  componentName,
  removePageSnackNotice,
  barWrapperStyle,
  barStyle,
}) => {
  const snackNotices = pages.getIn([pageName, 'snackNotices', componentName]);

  const dismiss = useCallback((notice) => {
    return () => {
      const noticeId = notice.get('noticeId');
      removePageSnackNotice({
        pageName,
        componentName,
        noticeId
      });
    }
  }, []);

  const barWrapperStyles = barWrapperStyle;

  return (snackNotices && snackNotices.size > 0) ? (
    <div className={barWrapperStyles}>
      {snackNotices.map((notice) => {
        const dimissHandler = dismiss(notice);
        return (
          <Bar
            key={notice.get('noticeId')}
            status={notice.get('status')}
            content={notice.get('content')}
            template={notice.get('template')}
            templateData={notice.get('templateData')}
            dismissable={notice.get('dismissable')}
            dimissableOnClick={dimissHandler}
            barStyle={barStyle}
          />
        );
      })}
    </div>
  ) : null;
}

Relative.propTypes = {
  pages: PropTypes.object,
  pageName: PropTypes.string.isRequired,
  componentName: PropTypes.string.isRequired,
  removePageSnackNotice: PropTypes.func.isRequired,
  barWrapperStyle: PropTypes.string,
  barStyle: PropTypes.string,
};

Relative.defaultProps = {
  barWrapperStyle: '',
  barStyle: '',
};

export default Relative;
