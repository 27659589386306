import { convertToTimeZone } from 'src/containers/BookingPage/AvailabilityPage/AvailabilityPage.utils';

/**
 * Takes a base recurrence pattern (e.g. "Every 1st Friday of the month") and optionally adds a prefix.
 * Used to flexibly format recurrence display text with different prefixes (typically "Repeats").
 * Example: formatRecurrenceText({recurrenceText: "every Tuesday", prefix: "Repeats"}) -> "Repeats every Tuesday"
 */
export function formatRecurrenceText({ recurrenceText, prefix, options }) {
  if (!recurrenceText) return '';

  let transformedRecurrenceText = recurrenceText;
  if (options?.downcaseRecurrence) {
    transformedRecurrenceText = `${recurrenceText.charAt(0).toLowerCase()}${recurrenceText.slice(
      1,
    )}`;
  }

  if (!prefix) return transformedRecurrenceText;
  return `${prefix} ${transformedRecurrenceText}`;
}

/**
 * Human readable string for the chosen time slot.
 *
 * @param {UTCDateTime[]} selectedTimes - Array of UTC datetime strings in ISO8601 format
 * @param {IANATimezone} timeZone - IANA timezone to format the time in
 * @returns {string}
 */
export const getFormattedChosenTimeSlot = (selectedTimes, timeZone) => {
  if (selectedTimes.length === 0) {
    return '';
  }
  const startDateTime = convertToTimeZone(selectedTimes[0], timeZone);
  const startDateHasMinutes = startDateTime.minutes() > 0;
  const dateTimeFormat = startDateHasMinutes
    ? 'dddd, MMM D, YYYY [at] h:mma' // 9:30am
    : 'dddd, MMM D, YYYY [at] ha'; // 9am
  return startDateTime.format(dateTimeFormat);
};

/** @typedef {import('src/types/availability').AvailableTime} AvailableTime */
/** @typedef {import('src/types/availability').UTCDateTime} UTCDateTime */
/** @typedef {import('src/types/availability').IANATimezone} IANATimezone */
/** @typedef {import('src/types/availability').HourData} HourData */
