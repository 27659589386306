import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit, capitalize, merge, get } from 'lodash';
import cn from 'classnames';
import MaskedInput from 'react-text-mask';
import Counter from 'src/utils/counter';
import { sanitizeString } from 'src/utils/helpers';
import { prepareMask } from './utils';
import styles from './styles.scss';

export const THEMES = Object.freeze({
  DEFAULT: 'default',
  WHITE: 'white',
  V2: 'v2',
});

export default class InputField extends Component {
  static propTypes = {
    className: PropTypes.string,
    theme: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    subtext: PropTypes.string,
    invalid: PropTypes.bool,
    guide: PropTypes.bool,
    placeholderChar: PropTypes.string,
    error: PropTypes.string,
    success: PropTypes.string,
    mask: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.any,
    id: PropTypes.string,
  };

  static defaultProps = {
    theme: THEMES.DEFAULT,
    onChange: () => {},
    guide: true,
    placeholderChar: '\u2000',
  };

  constructor(props, context) {
    super(props, context);
    this.state = { id: null };
    this.elements = { input: null };
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    this.setState({ id: Counter.uniqueId('InputField-') });
    this.afterCreate();
  }

  onChange(event) {
    const value = sanitizeString(get(event, 'target.value', ''));
    const newEvent = merge(event, { target: { value } });
    this.props.onChange(newEvent);
  }

  afterCreate() {
    if (this.props.defaultValue) {
      this.props.onChange({ target: { value: this.props.defaultValue } });
    }
  }

  renderError() {
    return this.props.error ? (
      <div className={styles.inputFieldError}>{this.props.error}</div>
    ) : null;
  }

  renderSuccess() {
    return this.props.success ? (
      <div className={styles.inputFieldSuccess}>{this.props.success}</div>
    ) : null;
  }

  renderLabel() {
    return this.props.label ? (
      <div className={styles.inputLabel}>
        <label htmlFor={this.state.id}>{this.props.label}</label>
      </div>
    ) : null;
  }

  renderSubText() {
    return this.props.subtext ? (
      <div className={styles.labelSubText}>
        <subtext htmlFor={this.state.id}>{this.props.subtext}</subtext>
      </div>
    ) : null;
  }

  renderField() {
    const props = omit(this.props, ['id', 'label', 'valid', 'error', 'placeholder', 'onChange', 'mask', 'guide', 'placeholderChar', 'keepCharPositions', 'success', 'theme', 'subtext']);
    const inputStyles = cn(
      styles.inputField,
      {
        [styles.inputFieldInvalid]: this.props.invalid || this.props.error,
        [styles.inputFieldWhite]: this.props.theme === THEMES.WHITE,
      },
      this.props.theme ? styles[`inputField${capitalize(this.props.theme)}`] : '',
    );
    const hasMask = !!this.props.mask;
    const mask = prepareMask(this.props.mask);

    return (
      <div className={inputStyles}>
        {!hasMask && (
          <input
            ref={(el) => {
              this.elements.input = el;
            }}
            id={this.state.id}
            placeholder={this.props.placeholder}
            autoComplete="off"
            onChange={this.onChange}
            {...props}
          />
        )}
        {hasMask && (
          <MaskedInput
            placeholderChar={this.props.placeholderChar}
            guide={this.props.guide}
            mask={mask}
            ref={(el) => {
              this.elements.input = el;
            }}
            id={this.state.id}
            placeholder={this.props.placeholder}
            autoComplete="off"
            onChange={this.onChange}
            {...props}
          />
        )}
        {this.renderError()}
        {this.renderSuccess()}
      </div>
    );
  }

  render() {
    const { id, className } = this.props;
    return (
      <div className={cn(styles.input, className)} id={id}>
        {this.renderLabel()}
        {this.renderSubText()}
        {this.renderField()}
      </div>
    );
  }
}
