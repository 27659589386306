import { connect } from 'react-redux';
import { toggleModal } from 'src/actions/modals';
import { validateForm, clearFormErrors } from 'src/actions/common';
import { purePageSelector as pageSelector } from 'src/selectors/page';
import { pureModalSelector as modalSelector } from 'src/selectors/modal';
import { getDiscount } from './actions';
import { PAGE_NAME, MODAL_NAME } from './constants';
import FirstServiceDiscountModal from './FirstServiceDiscountModal';

const mapStateToProps = (state) => ({
  page: pageSelector(PAGE_NAME)(state),
  firstServiceDiscount: modalSelector(MODAL_NAME)(state),
  phone: state.getIn(['tracking', 'phone']),
  isMobile: state.get('isMobile'),
  fieldSalesAgent: state.getIn(['entities', 'cart', 'fieldSaleAgent', 'id']),
});

export default connect(
  mapStateToProps,
  { toggleModal, validateForm, clearFormErrors, getDiscount },
)(FirstServiceDiscountModal);
