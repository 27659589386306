import { select, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { selectRoutes } from 'src/apiRoutes';
import { pageLoadingError, pageLoaded } from 'src/actions/common';
import { LOAD_PAGE } from 'src/constants/common';
import { removeCurrentLanding } from 'src/containers/LandingPage/actions';
import { showFirstServiceDiscountDelayedModal } from 'src/components/HTKit/Modals/FirstServiceDiscount/actions';
import { removeLandingCookies, getPartnerLandingCookie, removeIsPartnerRelatedCookie } from 'src/utils/cookies';
import { setRatingsAndNumServices } from './actions';
import { PAGE_NAME, GET_RATINGS_AND_NUM_SERVICES, REMOVE_PARTNER_EFFECTS } from './constants';

function* pageSaga() {
  const routes = yield call(selectRoutes);
  let reviews = yield select((state) => state.getIn(['entities', 'reviews', 'home']));
  if (!reviews) {
    const requestResult = yield call(routes.reviews.list);
    if (!requestResult.err) {
      reviews = requestResult.data.reviews;
    } else {
      yield put(pageLoadingError(PAGE_NAME, requestResult));
      return;
    }
  }
  yield put(pageLoaded(PAGE_NAME, { reviews }));
  yield put(showFirstServiceDiscountDelayedModal());
}

function* getRatingsAndNumServicesSaga() {
  const routes = yield call(selectRoutes);
  const response = yield call(routes.skus.totalCompletedServicesAndClientRating);
  if (response.err) return;
  yield put(setRatingsAndNumServices({ ...response.data.stats }));
}

function* removePartnerEffects() {
  const partnerLandingCookie = yield select((state) => state.getIn(['entities', 'currentLanding']));

  removeIsPartnerRelatedCookie();

  if (partnerLandingCookie || getPartnerLandingCookie()) {
    removeLandingCookies();
    yield put(removeCurrentLanding());
  }
}

function* pageFlow() {
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
  yield takeEvery(GET_RATINGS_AND_NUM_SERVICES, getRatingsAndNumServicesSaga);
  yield takeLatest(REMOVE_PARTNER_EFFECTS, removePartnerEffects);
}

export default [pageFlow];
