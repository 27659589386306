import { combineReducers } from 'redux-immutable';
import {
  availabilityReducer,
  availabilityByDateTimeReducer,
} from 'src/components/AvailabilitySelector/reducer';
import techs from 'src/components/Techs/data/reducer';
import plans from './plans';
import skus from './skus';
import questions from './questions';
import skuCategories from './sku_categories';
import reviews from './reviews';
import cart from './cart/index';
import account from './account/account';
import orders from './orders';
import orderConfirmations from './orderConfirmations';
import landings from './landings';
import coupons from './coupons';
import locations from './locations';
import cancelReasons from './account/cancelReasons';
import devices from './devices';
import currentLanding from './currentLanding';
import faqQuestions from './faqQuestions';
import answers from './answers';
import workflow from './workflow';
import partners from './partners';

export default () =>
  combineReducers({
    plans,
    skus,
    questions,
    reviews,
    skuCategories,
    cart,
    account,
    orders,
    orderConfirmations,
    landings,
    coupons,
    locations,
    cancelReasons,
    devices,
    currentLanding,
    answers,
    faqQuestions,
    availability: availabilityReducer,
    availabilityByDateTime: availabilityByDateTimeReducer,
    techs,
    workflow,
    partners,
  });
