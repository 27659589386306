import { forEach, isObject } from 'lodash';

export const prepareSelectGroups = (groups) => {
  const result = [];
  const walk = (items) =>
    forEach(items, (group) => {
      result.push({ label: group.label, value: group.value, disabled: !!group.options });
      group.options && walk(group.options); // eslint-disable-line no-unused-expressions
    });
  walk(groups);
  return result;
};

export const prepareValue = (value, options) => {
  if (value && !isObject(value)) return options.find((o) => o.value === value);
  return value;
};
