import { logger } from 'src/utils/logger';
import truncate from 'lodash/truncate';

// dummy pluralize for now...
// TODO: migrate to lib or extend this function

export const pluralize = (text, count, suffix = 's') => {
  const postfix = count > 1 ? suffix : '';
  return `${text}${postfix}`;
};

/* Takes n number of words and returns first/last capitalized intitials */
export const getInitials = (name = '') => {
  if (!name || typeof name !== 'string') return '';

  try {
    const choppedName =
      name
        .toUpperCase()
        .split(' ')
        .map((n) => (n || '')[0]) || [];
    const first = choppedName[0] || '';
    const last = (choppedName.length > 1 ? choppedName[choppedName.length - 1] : '') || '';

    return `${first}${last}`;
  } catch (e) {
    logger('getInitials error caught')(e);
    return '';
  }
};

/*
  getFullFirstLastInitial

  Takes a first and last name and returns the first name with an abbreviated last name

  "Joe Smith" => "Joe S."
*/
export const getFullFirstLastInitial = (first = '', last = '') => {
  return `${first} ${`${last[0]}.` || ''}`;
};

/*
  Examples

  - getSubstringBetweenTwoChars
    const text = 'the label text is Full motion 73" - 85" (+$149)';
    getSubstringBetweenTwoChars(text, '(', ')') //returns '+$149'
*/
export const getSubstringBetweenTwoChars = (str, a, b) =>
  str
    .split(a)
    .pop()
    .split(b)[0];

/**
 * Example
 * - getFirstNameOnly
 *   const name = "David L."
 *   getFirstNameOnly(name)
 *   -> returns "David"
 */

export const getFirstNameOnly = (name) =>  name.substring(0, name.indexOf(' '));

/**
 * Takes in a string and returns a shortened version of it.
 *
 * @param str string
 * @param cutLength number
 * @param appended string
 */
export const shortenStringByNumber = (str, cutLength, appended) => {
  const appendToString = appended || "";
  return str?.length > cutLength ? str.substring(0,cutLength) + appendToString : str;
}

// Design request was to truncate long file names, but keep the file extension. Remove any query params
export const truncateLongFilename = (filename, maxChars = 20) => {
  const nameWithoutQueryParams = filename.split('?')[0];
  if (nameWithoutQueryParams.length <= maxChars) {
    return nameWithoutQueryParams;
  }
  /*
    It is possible that a file may have more than one period in the name, so grab the
    last element in the array after split('.') to get the proper file extension.
    e.g., "Screen_Shot_2022-11-18_at_6.02.11_PM.png"
  */
  const fileExtension = nameWithoutQueryParams.split('.').slice(-1)[0];
  return truncate(nameWithoutQueryParams, {length: maxChars, omission: `...${fileExtension}`});
};

/* Helper replace spaces with plus(or passed arg). Defaults to "+" */
export const replaceSpaces = (str, replaceWith = "+") => str.trim().replace(/\s/g, replaceWith);
