import {
  CHAT_SHOWN,
  NEW_CHAT_MESSAGE,
} from '../constants/chat';

export function chatShown(shown) {
  return {
    type: CHAT_SHOWN,
    shown,
  };
}

export function newChatMessage() {
  return {
    type: NEW_CHAT_MESSAGE,
  };
}
