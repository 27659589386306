import { SERVICE_ADJUSTMENT_TYPES } from 'src/containers/Account/OrderPage/constants';
import { isImmutable } from 'src/utils/helpers';

/** Designs call showing the subscription/automatic discounts as a separate line items */
export const filterDiscountsFromAdjustments = (adjustments) => {
  // eslint-disable-next-line no-underscore-dangle
  const _adjustments = isImmutable(adjustments) ? adjustments.toJS() : adjustments;
  return _adjustments.filter((adj) => {
    const name = adj.name.toLowerCase();
    const { SHP_DISCOUNT, AUTOMATIC_DISCOUNT } = SERVICE_ADJUSTMENT_TYPES;
    return ![SHP_DISCOUNT, AUTOMATIC_DISCOUNT].includes(name);
  });
};

/** This discount is referred to by many names, e.g. "automatic", "volume", and "bundle" discount */
export const getBundleDiscount = (item = {}) => {
  if (!item.adjustments || !item.adjustments.length) return 0;
  const bundleDiscount = item.adjustments.find(
    (adj) => adj.name.trim().toLowerCase() === SERVICE_ADJUSTMENT_TYPES.AUTOMATIC_DISCOUNT,
  );
  return bundleDiscount ? Math.abs(bundleDiscount.amount) : 0;
};

/** Subscription/member discount */
const getMemberDiscount = (item = {}) => {
  return item.savings || 0;
};

export const getDiscountsTotal = (item = {}) => {
  // Currently the only line item discounts are for multiple bundles or membership
  const discounts = [getBundleDiscount(item), getMemberDiscount(item)];
  return discounts.reduce((acc, elem) => acc + elem, 0);
};

export const getItemTotalWithoutDiscounts = (item) => {
  // item.amount represents the price charged to the customer PLUS add-ons, and MINUS discounts.
  const discountsTotal = getDiscountsTotal(item);
  const totalWithoutDiscounts = item.amount + discountsTotal;
  /**
   * @type {{totalWithoutDiscounts: number, discountsTotal: number}}
   */
  const itemTotalObj = {
    totalWithoutDiscounts,
    discountsTotal,
  };
  return itemTotalObj;
};
