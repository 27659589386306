import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Grid } from 'HTKit/Grid';
import styles from './styles.scss';

const ServiceDetails = ({ service, className }) => (
  <div className={className}>
    <Grid.FullWidth>
      <h4 className="h4">Details</h4>
      <div className={cn('p1', styles.description)}>{service.description}</div>
    </Grid.FullWidth>
  </div>
);

ServiceDetails.propTypes = {
  service: PropTypes.shape({
    description: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default memo(ServiceDetails);
