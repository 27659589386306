import {
  LOAD_USER,
  LOAD_CART_PREVIEW,
  UPDATE_CART_PREVIEW,
  USER_LOADED,
  USER_NOT_LOADED,
  UPDATE_AUTH,
  LOGOUT,
  USER_LOGGED_OUT,
  APP_LOADED,
  COOKIES_LOADED,
  UPDATE_PHONE,
  UPDATE_IP,
} from './constants';
import { isImmutable } from 'src/utils/helpers';

export function updateAuth(auth) {
  return {
    type: UPDATE_AUTH,
    auth,
  };
}

export function loadUser() {
  return {
    type: LOAD_USER,
  };
}

export function loadCartPreview() {
  return {
    type: LOAD_CART_PREVIEW,
  };
}

export function updateCartPreview({ cart }) {
  return {
    type: UPDATE_CART_PREVIEW,
    cart,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function userLoggedOut() {
  return {
    type: USER_LOGGED_OUT,
  };
}

export function userLoaded(user) {
  return {
    type: USER_LOADED,
    user,
    meta: {
      analytics: [
        {
          eventType: 'identify',
          eventPayload: {
            userId: user.id,
            traits: {
              id: user.id,
              email: user.email,
              first_name: user.firstName,
              last_name: user.lastName,
            },
          },
        },
      ],
    },
  };
}

export function userNotLoaded() {
  return {
    type: USER_NOT_LOADED,
  };
}

export function appLoaded({
  isMobile,
  isTablet,
  phone,
  pathname,
  adwordPhone,
  adGroup,
  term,
  user,
}) {
  const usr = isImmutable(user) ? user.toJS() : user || {};

  const analytics = [];
  if (adGroup || term || usr) {
    analytics.push({
      eventType: 'identify',
      eventPayload: {
        ...(usr && usr.id && { userId: usr.id }),
        traits: {
          adGroup,
          term,
          ...(usr && {
            id: usr.id,
            email: usr.email,
            first_name: usr.firstName,
            last_name: usr.lastName,
          }),
        },
      },
    });
  }
  analytics.push({
    eventType: 'page',
    eventPayload: {
      name: pathname,
      adGroup,
      term,
      properties: {
        path: pathname,
      },
    },
  });
  return {
    type: APP_LOADED,
    isMobile,
    isTablet,
    phone,
    adwordPhone,
    meta: {
      analytics,
    },
  };
}

export function cookiesLoaded(cookies, partnerLandingCookie) {
  return {
    type: COOKIES_LOADED,
    cookies,
    partnerLandingCookie,
  };
}

export function updatePhone({ phone, soft = false }) {
  return {
    type: UPDATE_PHONE,
    phone,
    soft,
  };
}

export function updateIP(ip) {
  return {
    type: UPDATE_IP,
    ip,
  };
}
