import { isImmutable } from 'src/utils/helpers';
import get from 'lodash/get';

import {
  SKU_LOADED,
  FETCH_PLAN_DETAILS,
  LOAD_PLAN_DETAILS,
  TOGGLE_PLAN_MODAL,
  ADD_PLAN_TO_CART,
  TRACK_ADD_PLAN_FROM_SKU,
  TRACK_REMOVE_PLAN_FROM_SKU,
  FETCH_PLANS_FOR_MONTHLY_UPSELL,
  PLANS_INFO_LOADED,
} from './constants';

const VIEWED = 'Viewed';
export function skuLoaded(sku, fromAClick) {
  const types = [...new Set([VIEWED, fromAClick ? 'Clicked' : VIEWED])];

  const partnerObject = get(sku, 'partner', false);
  const partner = partnerObject
    ? {
        partner_id: partnerObject.id,
        partner_name: partnerObject.name,
      }
    : null;

  const properties = {
    sku_id: sku.id,
    base_price: sku.basePrice / 100,
    category: sku.category,
    content_ids: [sku.id],
    id: `${sku.id}`,
    price: sku.basePrice / 100,
    image_url: sku.heroImageUrl,
    is_remote: sku.remote,
    name: sku.name,
    partner,
    physical_product: sku.physicalProduct,
    requires_installation: sku.requiresInstallation,
    product_url: `${window?.location?.origin}${window?.location?.pathname}`,
  };
  /* Iterator over the Viewed and/or Clicked */
  const payloads = types.map((property) => {
    return {
      eventType: 'track',
      eventPayload: {
        event: `Product ${property}`,
        properties,
      },
    };
  });

  return {
    type: SKU_LOADED,
    payload: { sku },
    meta: {
      analytics: [...payloads],
    },
  };
}

export const planAddedOnSkuTrack = (_sku) => {
  const sku = isImmutable(_sku) ? _sku.toJS() : _sku || {};

  return {
    type: TRACK_ADD_PLAN_FROM_SKU,
    payload: { sku },
  };
};

export const planRemovedOnSkuTrack = (sku) => {
  return {
    type: TRACK_REMOVE_PLAN_FROM_SKU,
    payload: { sku },
  };
};

export const fetchPlanDetails = ({ planId }) => ({
  type: FETCH_PLAN_DETAILS,
  planId,
});

export const loadPlanDetails = ({ plan }) => ({
  type: LOAD_PLAN_DETAILS,
  plan,
});

export const togglePlanModal = ({ modalState }) => ({
  type: TOGGLE_PLAN_MODAL,
  modalState,
});

export const addPlanToCart = ({ planId, skuId }) => ({
  type: ADD_PLAN_TO_CART,
  planId,
  skuId,
});

// Only for Location and ProductSingleSku pages
export const fetchPlansForMonthlyUpsell = (payload) => ({
  type: FETCH_PLANS_FOR_MONTHLY_UPSELL,
  ...payload,
});

// Only for Location and ProductSingleSku pages
export const plansInfoLoaded = ({ plansInfo }) => ({
  type: PLANS_INFO_LOADED,
  plansInfo,
});
