import Bugsnag from 'src/utils/Bugsnag';
import { ENV } from 'src/constants/app';

const log = (...args) => window.console.log(...args);
const info = (...args) => window.console.info(...args);

export const logger = (source = '') => (error) => {
  const err = error instanceof Error ? error : new Error(`${source}: ${error}`);

  return process.env.NODE_ENV !== ENV.PRODUCTION
    ? log(`${source}: `, error)
    : Bugsnag.notifyException(err);
};

export default { log, info, logger };
