import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Grid } from 'src/components/HTKit/Grid';
import { AccordionGroup as FaqGroup } from 'src/components/Elements/Accordion';
import FaqItem from './FaqItem';
import styles from './styles.scss';

const Faq = ({ className, titleStyle = '', title = 'FAQs', items = [], showBlobs = true }) => {
  if (!items.length) return null;
  const containerStyles = showBlobs ? styles.container : '';
  return (
    <section className={containerStyles}>
      <Grid.Fluid classes={className}>
        <Grid.Row>
          <Grid.Column sm={4} mdOffset={2} md={4} lgOffset={4} lg={4}>
            <h3 className={cn('n900 text-align-center', titleStyle, styles.title)}>{title}</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column sm={4} md={8} lgOffset={3} lg={6}>
            <FaqGroup className="marginTop-small2">
              {items.map((f) => (
                <FaqItem key={f.id} title={f.questionText}>
                  <div
                    className="p1 paddingTop-small1"
                    dangerouslySetInnerHTML={{ __html: f.answerText }}
                  />
                </FaqItem>
              ))}
            </FaqGroup>
          </Grid.Column>
        </Grid.Row>
      </Grid.Fluid>
    </section>
  );
};

Faq.propTypes = {
  className: PropTypes.string,
  titleStyle: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      questionText: PropTypes.any,
      answerText: PropTypes.any,
    }),
  ),
  showBlobs: PropTypes.bool,
};

export default memo(Faq);

/*
  Notes:
    Reusing current Accordion group component as FaqGroup
  Props:
    showBlobs: hide/show blobs in the faq component - predicting the hiding of blobs in the future
*/
