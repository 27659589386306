import { createSelector } from 'reselect';

const layoutStateSelector = (state) => state.get('layout');
const layoutSelector = (name) => createSelector(layoutStateSelector, (layout) => layout.get(name));

export const layoutHeaderSelector = createSelector(
  layoutStateSelector,
  (layout) => layout && layout.get('header'),
);

export const layoutFooterSelector = createSelector(
  layoutStateSelector,
  (layout) => layout && layout.get('footer'),
);

export const showFooterSelector = createSelector(
  layoutFooterSelector,
  (lfs) => lfs && lfs.get('show'),
);

export const footerTypeSelector = createSelector(
  layoutFooterSelector,
  (lfs) => lfs && lfs.get('type'),
);

export default layoutSelector;
