import { fromJS } from 'immutable';
import { TOGGLE_SEARCH_BAR } from './constants';

const initialState = fromJS({ show: false });

export default function  searchReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SEARCH_BAR:
      return state.set('show', !state.get('show'));

      default:
        return state;
    }
  }
