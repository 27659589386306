import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
// Utils
import { formatSubscriptionName } from 'src/utils/plans';
// Components
import OrderBreakdownLineItem from 'src/components/OrderSummary/Breakdown/OrderBreakdownLineItem';
import OrderTotalV2 from 'src/components/OrderSummary/Breakdown/OrderTotalV2';
import CouponAppliedLineItem from 'src/components/OrderSummary/Breakdown/CouponAppliedLineItem';
import CreditAppliedLineItem from 'src/components/OrderSummary/Breakdown/CreditAppliedLineItem';
import { totalSubscriptionCreditAdjustments } from 'src/containers/BookingPage/utils';
import LowTierMembershipUpsell from 'src/containers/CartPage/Parts/LowTierMembershipUpsell';
import SubscriptionLineItem from 'src/containers/BookingPage/SummaryPage/Parts/SubscriptionLineItem';
// Constants / Selectors
import { COUPON_ADJUSTMENT_TYPES } from 'src/components/OrderSummary/Breakdown/constants';
import { standardAndSubscriptionCouponSelector } from 'src/selectors/cart';

// Styles & Assets
import styles from '../styles.scss';

const PlanInRegularCartLineItems = (props) => {
  const {
    plan,
    hasProductsInCart,
    subscriptionTotalNoTax,
    subscriptionTotal,
    planTotalSubText,
    subscriptionTaxFormatted,
    removeCoupon,
    couponText,
    couponValueFormatted,
    modalCallback,
    subscriptionCreditAdjustments,
  } = props;
  // Hooks
  const standardAndSubscriptionCoupon = useSelector(standardAndSubscriptionCouponSelector);

  const planName = formatSubscriptionName(plan);
  const planNameCoupon = formatSubscriptionName(plan);
  const planHasCoupon = standardAndSubscriptionCoupon?.[COUPON_ADJUSTMENT_TYPES.SUBSCRIPTION];
  const {
    amount: subscriptionCreditAmount,
    amountFormatted: subscriptionCreditAmountFormatted,
  } = totalSubscriptionCreditAdjustments(subscriptionCreditAdjustments);

  const showPlanFees = Boolean(
    planHasCoupon || subscriptionTaxFormatted || subscriptionCreditAmount,
  );

  return (
    plan &&
    !hasProductsInCart && (
      <>
        <h5 className={styles.orderSummaryHeader}>Membership Summary</h5>

        <LowTierMembershipUpsell modalCallback={modalCallback} />

        <SubscriptionLineItem plan={plan} name={planName} amount={`$${subscriptionTotalNoTax}`} />
        {showPlanFees && (
          <div className={cn('paddingTop-tiny1', styles.taxes)}>
            {planHasCoupon && (
              <CouponAppliedLineItem
                name={planNameCoupon || couponText}
                amount={couponValueFormatted}
                onClick={removeCoupon}
                couponType={COUPON_ADJUSTMENT_TYPES.SUBSCRIPTION}
              />
            )}

            {!!subscriptionCreditAmount && (
              <CreditAppliedLineItem amount={subscriptionCreditAmountFormatted} />
            )}

            {subscriptionTaxFormatted && (
              <OrderBreakdownLineItem
                name="Taxes"
                amount={subscriptionTaxFormatted}
                className="marginBottom-small"
              />
            )}
          </div>
        )}

        <OrderTotalV2
          totalFormatted={subscriptionTotal}
          text="Due Today"
          subText={planTotalSubText}
        />
      </>
    )
  );
};

PlanInRegularCartLineItems.propTypes = {
  plan: PropTypes.object,
  hasProductsInCart: PropTypes.bool,
  planName: PropTypes.string,
  subscriptionTotalNoTax: PropTypes.string,
  subscriptionTotal: PropTypes.string,
  planTotalSubText: PropTypes.string,
  subscriptionTaxFormatted: PropTypes.string,
  coupon: PropTypes.object,
  removeCoupon: PropTypes.func,
  couponText: PropTypes.string,
  couponValueFormatted: PropTypes.string,
  hasSubscriptionCoupon: PropTypes.bool,
  modalCallback: PropTypes.func,
  subscriptionCreditAdjustments: PropTypes.array,
};

export default PlanInRegularCartLineItems;
