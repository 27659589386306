import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Utils and Actions
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
// Constants
import { PAGE_NAME } from 'src/containers/LandingPage/constants';
// eslint-disable-next-line import/order
import {
  LANDINGPAGE_SPLIT_MAPPINGS,
  IFRAME_LANDINGPAGE_SEONAMES,
  getIframeSource,
} from './constants';
// Components && Styles
import PageLoader from 'src/components/PageLoader';
import IframeView from 'src/components/Elements/IframeView';

import LandingPageTemplateSelector from '../LandingPageTemplateSelector';
import { messagePageActions } from './messagePageActions';

export const isIframeRoute = (name) => Object.values(IFRAME_LANDINGPAGE_SEONAMES).includes(name);

const LandingPageIframe = (props) => {
  const [source, setSource] = useState(null);
  const NO_SOURCE_FOUND = 'NO_SOURCE_FOUND';

  const { name, landing, isMobile, isPlanPricingTest } = props;

  const nameOfSplitToLoad = LANDINGPAGE_SPLIT_MAPPINGS[name];

  const { splitTreatment, splitLoaded, splitConfig } = useSplitIOTreatment(nameOfSplitToLoad);

  const noSourceFoundInSplitConfig = source === NO_SOURCE_FOUND;
  const splitIsTurnedOff = [(splitioConstants.CONTROL, splitioConstants.OFF)].includes(
    splitTreatment,
  );
  const showFallbackTreatment =
    !nameOfSplitToLoad || noSourceFoundInSplitConfig || splitIsTurnedOff;

  useEffect(() => {
    /*
      Because Product/Design want the ability to "self-service" and change the content of the iframe without dev work we
      are going to grab the path to the content from the split itself
    */
    if (splitLoaded) setSource(splitConfig.source || NO_SOURCE_FOUND);
  }, [splitLoaded]);

  const newVersionOfSource = getIframeSource(source);

  if (!splitLoaded) return <PageLoader />;

  if (showFallbackTreatment)
    return (
      <LandingPageTemplateSelector
        landing={landing}
        isMobile={isMobile}
        isPlanPricingTest={isPlanPricingTest}
      />
    );

  return (
    <IframeView
      src={newVersionOfSource}
      callerPageName={PAGE_NAME}
      additionalActions={messagePageActions}
    />
  );
};

LandingPageIframe.propTypes = {
  name: PropTypes.string,
  landing: PropTypes.object,
  isMobile: PropTypes.bool,
  isPlanPricingTest: PropTypes.bool,
};

export default LandingPageIframe;
