import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatHour } from './utils';
import styles from './styles.scss';

const DesktopHourBlock = ({ date, hourData, toggleHour }) => {
  const hourStyles = classNames("p2", styles.hour, {
    [styles.invalid]: !hourData.get('valid'),
    [styles.selected]: hourData.get('checked'),
  });

  return (
    <div role="button" className={hourStyles} onClick={toggleHour(date, hourData, !hourData.get('checked'))}>
      {formatHour(hourData.get('hour'))}
    </div>
  );
};

DesktopHourBlock.propTypes = {
  date: PropTypes.string.isRequired,
  hourData: PropTypes.object.isRequired,
  toggleHour: PropTypes.func.isRequired,
};

export default DesktopHourBlock;
