import { CHECK_REDEEM_CODE, ADD_GIFT_CARD, REMOVE_GIFT_CARD } from './constants';

export function checkRedeemCode(code) {
  return {
    type: CHECK_REDEEM_CODE,
    payload: { code },
  };
}

export function addGiftCard(clientPlanOrder) {
  return {
    type: ADD_GIFT_CARD,
    payload: { clientPlanOrder },
  };
}

export function removeGiftCard() {
  return {
    type: REMOVE_GIFT_CARD,
  };
}
