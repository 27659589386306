import { roundToNearestTen } from 'src/utils/math';
import {
  UPSELL_PLAN_ID,
  MONTHLY_PLAN_ID,
  PRICING_TEST_YEARLY_ID,
  PRICING_TEST_MONTHLY_ID,
  UPSELL_PLAN_BILLING_CYCLES,
  HELLOTECH_HOME_PLAN_PRODUCT_ID,
  ONLINE_PLAN_PRODUCT_ID,
  HELLOTECH_LITE_PLAN_PRODUCT_ID,
  HELLOTECH_LITE_PLAN,
  HELLOTECH_LITE_PLAN_ANNUAL_ID,
} from 'src/constants/plan';
import { HIDE_PRODUCT_CONNECTED_HOME } from 'src/constants/app';
import {
  SMART_HOME_BENEFITS_DETAILS,
  ONLINE_DETAILS,
  HELLOTECH_LITE_DETAILS,
} from 'src/constants/planBenefits';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';

/*
  Api call for active subscriptions data
*/
export const getSubscriptionPlansInfo = ({ api }) => {
  return new Promise((resolve, reject) => {
    api.plans.subscriptions
      .all()
      .then(({ err, data }) => {
        if (err) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ err, data });
        } else {
          resolve({ data });
        }
      })
      .catch(reject);
  });
};

/*
  Given a single subscription plan, let's accurately show the benefits
  based on the plan's included_skus.
*/
export const filterBenefits = ({ plan = {} }) => {
  const { includedSkus = [], truckRollFee, productDiscount: discount } = plan;
  const {
    inHome,
    online,
    productDiscount,
    checkup,
    antivirus,
    password,
  } = SMART_HOME_BENEFITS_DETAILS;
  const benefits = [];
  const { amount: discountAmount } = discount;

  benefits.push(online);
  benefits.push({ ...inHome(truckRollFee), truckRollFee });
  if (!HIDE_PRODUCT_CONNECTED_HOME) {
    benefits.push({ ...productDiscount(discountAmount), discountAmount });
  }
  includedSkus.forEach((sku) => {
    const skuName = sku.name.toLowerCase();

    if (skuName.includes('checkup')) {
      benefits.push(checkup);
    } else if (skuName.includes('malwarebytes')) {
      benefits.push(antivirus);
    } else if (skuName.includes('dashlane')) {
      benefits.push(password);
    }
  });
  return benefits;
};

export const getUpsellPlanIds = ({ isPlanPricingTest = false }) => {
  if (isPlanPricingTest) {
    return {
      yearlyID: PRICING_TEST_YEARLY_ID,
      monthlyID: PRICING_TEST_MONTHLY_ID,
    };
  }
  return {
    yearlyID: UPSELL_PLAN_ID,
    monthlyID: MONTHLY_PLAN_ID,
  };
};

/*
  Filter a list of planProducts to get HelloTech Home plans.
  Return the yearly and monthly SHP plans.

  If `isPlanPricingTest` is set to true, then return the test set of SHP plans.
*/
const filterPlanProducts = ({ planProducts = [], isPlanPricingTest = false }) => {
  if (!Array.isArray(planProducts) || !planProducts.length) return null;
  const { ANNUAL, MONTHLY } = UPSELL_PLAN_BILLING_CYCLES;

  const hellotechHomePlanProduct = planProducts.find(
    (product) => product.id === HELLOTECH_HOME_PLAN_PRODUCT_ID,
  );

  if (hellotechHomePlanProduct) {
    const { yearlyID, monthlyID } = getUpsellPlanIds({ isPlanPricingTest });

    const hellotechHomePlans = hellotechHomePlanProduct.prices.reduce(
      (accu, current) => {
        const { id, planInterval } = current;
        if (id === yearlyID || id === monthlyID) {
          return { ...accu, [planInterval]: current };
        }
        return accu;
      },
      { [ANNUAL]: null, [MONTHLY]: null },
    );

    return hellotechHomePlans;
  }
  return null;
};

/*
  Given a list of plan products, get the smart home plan product and filter it
  for the yearly and monthly subscription plans. With those yearly/monthly
  plans, format in the desired shape for usage.

  The return value
  {
    yearly: {
      id: UPSELL_PLAN_ID,
      name: 'UPSELL_PLAN_NAME',
      amount: 149,
      benefits: [],
      ...,
    },
    monthly: {
      id: 5,
      name: 'UPSELL_PLAN_NAME',
      amount: 14.99,
      benefits: [],
      ...,
    },
    featured: true
  }
*/
export const formatPlansForReducer = ({
  planProducts,
  featured = true,
  isMobileOrTablet = false,
  isPlanPricingTest = false,
}) => {
  const { ANNUAL, MONTHLY } = UPSELL_PLAN_BILLING_CYCLES;
  const hellotechHomePlans = filterPlanProducts({ planProducts, isPlanPricingTest });
  let formatted = {};
  [ANNUAL, MONTHLY].forEach((cycle) => {
    formatted = {
      ...formatted,
      [cycle]: {
        ...hellotechHomePlans[cycle],
        benefits: filterBenefits({ plan: hellotechHomePlans[cycle], isMobileOrTablet }),
      },
    };
  });
  return { ...formatted, featured };
};

/**
 * Get online plan details and get the appropriate
 * benefits and format it to look like smart home.
 *
 * TODO: Online plan was replaced by HT Lite plan on membership page. Retain
 * for now.
 */
export const formatOnlinePlanForReducer = ({ planProducts }) => {
  const { homeTechSpecialist, online } = ONLINE_DETAILS;
  const { ANNUAL, MONTHLY } = UPSELL_PLAN_BILLING_CYCLES;
  const plan = planProducts.find((product) => product.id === ONLINE_PLAN_PRODUCT_ID);
  const hellotechHomePlans = plan.prices.reduce(
    (accu, current) => {
      const { planInterval } = current;
      return { ...accu, [planInterval]: current };
    },
    { [ANNUAL]: null, [MONTHLY]: null },
  );
  const benefits = [homeTechSpecialist, online];
  let formatted = {};
  [ANNUAL, MONTHLY].forEach((cycle) => {
    formatted = {
      ...formatted,
      [cycle]: {
        ...hellotechHomePlans[cycle],
        benefits,
      },
    };
  });
  return { ...formatted };
};

export const formatHTLitePlanForReducer = ({ planProducts }) => {
  const { ANNUAL, MONTHLY } = UPSELL_PLAN_BILLING_CYCLES;
  const plan = planProducts.find((product) => product.id === HELLOTECH_LITE_PLAN_PRODUCT_ID) || {};

  const htLiteMonthlyPlans = {
    [ANNUAL]: null,
    [MONTHLY]: null,
  };

  (plan.prices || []).forEach((pricePlan) => {
    const { id } = pricePlan;
    if (id === HELLOTECH_LITE_PLAN) {
      htLiteMonthlyPlans[MONTHLY] = {
        benefits: [HELLOTECH_LITE_DETAILS.online],
        ...pricePlan,
      };
    } else if (id === HELLOTECH_LITE_PLAN_ANNUAL_ID) {
      htLiteMonthlyPlans[ANNUAL] = {
        benefits: [HELLOTECH_LITE_DETAILS.online],
        ...pricePlan,
      };
    }
  });

  return htLiteMonthlyPlans;
};

/*
  Get a rough estimate of savings between yearly and monthly hellotech Home plans
*/
export const getAnnualSavings = (hellotechHomePlans = {}) => {
  if (!Object.keys(hellotechHomePlans).length) return null;
  const { ANNUAL, MONTHLY } = UPSELL_PLAN_BILLING_CYCLES;
  const { [ANNUAL]: yearlyPlan, [MONTHLY]: monthlyPlan } = hellotechHomePlans;
  const { amount: yearlyAmount } = yearlyPlan;
  const { amount: monthlyAmount } = monthlyPlan;

  const savings = ((monthlyAmount * 12 - yearlyAmount) / (monthlyAmount * 12)) * 100;
  return `${roundToNearestTen(savings)}%`;
};

/*
  Get old and new prices of upsell plan (yearly and monthly) from splitconfig
  This is used in ServiceInfoUpsellV2, membership page, and upsell cart components
*/
export const getAnnualAndMonthlySplitPlanPricing = (isMonthly) => {
  const {
    splitLoaded: planPricingSplitLoaded,
    splitTreatment: planPricingSplitTreatment,
    splitConfig: planPricingSplitConfig,
  } = useSplitIOTreatment(splitioConstants.SPLITIONAME_PLAN_PRICING);

  const { ANNUAL, MONTHLY } = UPSELL_PLAN_BILLING_CYCLES;
  const planPricingSplit = planPricingSplitTreatment === 'showSavings';
  const { oldPrice: oldPriceAnnual, currentPrice: currentPriceAnnual } =
    planPricingSplit && planPricingSplitConfig[ANNUAL];
  const { oldPrice: oldPriceMonthly, currentPrice: currentPriceMonthly } =
    planPricingSplit && planPricingSplitConfig[MONTHLY];
  const prices = isMonthly
    ? { oldPrice: oldPriceMonthly, currentPrice: currentPriceMonthly, planPricingSplit }
    : { oldPrice: oldPriceAnnual, currentPrice: currentPriceAnnual, planPricingSplit };

  return planPricingSplitLoaded && prices;
};
