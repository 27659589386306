import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
// Utils
import { cardType } from 'src/utils/payments/card';
// Components
import Panel from 'src/components/HTKit/Elements/Panel/Panel';
import Button, { THEMES as BUTTON_THEMES } from 'src/components/HTKit/Forms/Button';
// Styles
import styles from './styles.scss';

const UpdateCardInfoPanel = ({ lastDigits, expMonth, expYear, onClick, brand, buttonText = 'Update', buttonTheme = BUTTON_THEMES.V2SECONDARY  }) => {
  const cardBrand = brand || cardType(lastDigits);
  return (
    <Panel className={styles.panel}>
      <p className={cn(styles.cardInfo, 'p1')}>
        <div>
          <span className={styles.cardBrand}>{cardBrand}</span> ending in {lastDigits}
        </div>
        <div>
          Expires {expMonth}/{expYear}
        </div>
      </p>

      <Button onClick={onClick} theme={buttonTheme} className={styles.button}>
        {buttonText}
      </Button>
    </Panel>
  );
};

UpdateCardInfoPanel.propTypes = {
  brand: PropTypes.string,
  expMonth: PropTypes.string,
  expYear: PropTypes.string,
  lastDigits: PropTypes.string,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  buttonTheme: PropTypes.string,
};

UpdateCardInfoPanel.defaultProps = {
  brand: '',
  expMonth: '',
  expYear: '',
  lastDigits: '',
  onClick: () => {},
};

export default UpdateCardInfoPanel;
