import React from 'react';
import PropTypes from 'prop-types';
import AddressAutocomplete from 'HTKit/Forms/AddressAutocomplete';
import Form from 'HTKit/Forms/Form';
import InputFieldV2 from 'HTKit/Forms/InputFieldV2';
import Snackbar from 'src/components/Snackbar';
import { ACCOUNT_PAGE_NAME } from 'src/containers/Account/AccountPage/sagas';
import { SNACKBAR_ACCOUNT_TAB } from './constants';

const EVAccountTab = ({ formik }) => {
  /** The AddressAutocomplete component hijacks the native onChange event, so we have to manually call formik.handleChange */
  const onAddressChange = (addr) => {
    formik.handleChange({
      target: { name: 'address', value: addr },
    });
  };

  return (
    <>
      <Snackbar
        pageName={ACCOUNT_PAGE_NAME}
        componentName={SNACKBAR_ACCOUNT_TAB}
        barWrapperStyle="marginBottom-small"
      />
      <Form withoutFormTag>
        <Form.Row>
          <Form.Column lg={6} md={8} sm={4}>
            <InputFieldV2
              name="firstName"
              label="First Name"
              placeholder="First Name"
              value={formik.values.firstName}
              error={formik.errors.firstName}
              onChange={formik.handleChange}
            />
          </Form.Column>
          <Form.Column lg={6} md={8} sm={4}>
            <InputFieldV2
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              value={formik.values.lastName}
              error={formik.errors.lastName}
              onChange={formik.handleChange}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column lg={6} md={8} sm={4}>
            <InputFieldV2
              name="email"
              label="Email"
              placeholder="Email"
              value={formik.values.email}
              error={formik.errors.email}
              onChange={formik.handleChange}
            />
          </Form.Column>
          <Form.Column lg={6} md={8} sm={4}>
            <InputFieldV2
              name="phone"
              label="Phone"
              mask="phone"
              placeholder="Phone"
              value={formik.values.phone}
              error={formik.errors.phone}
              onChange={formik.handleChange}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4}>
            <AddressAutocomplete
              value={formik.values.address}
              onChange={onAddressChange}
              name="address"
              error={formik.errors.address}
              label="Address"
              relativeMenu
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4} classes="marginBottom-medium">
            <InputFieldV2
              name="address2"
              label="Apt/Unit"
              placeholder="Apt/Unit"
              value={formik.values.address2}
              error={formik.errors.address2}
              onChange={formik.handleChange}
            />
          </Form.Column>
        </Form.Row>
      </Form>
    </>
  );
};

EVAccountTab.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default EVAccountTab;
