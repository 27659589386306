import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { prevented } from 'src/utils/event';
import styles from './styles.scss';

const Cta = ({ linkPath, onButtonClick, linkText, buttonText, onLinkClick }) => {

  return (
    <div className={styles.cta}>
      <Link className={styles.ctaLink} to={linkPath} onClick={prevented(onLinkClick)}>
        {linkText}
      </Link>
      <Button theme={THEMES.V2SECONDARY} className={styles.ctaButton} smallV2 onClick={onButtonClick}>
        {buttonText}
      </Button>
    </div>
  );
};

Cta.propTypes = {
  linkPath: PropTypes.func,
  onLinkClick: PropTypes.func,
  onButtonClick: PropTypes.func.isRequired,
  linkText: PropTypes.string,
  buttonText: PropTypes.string,
};

export default Cta;
