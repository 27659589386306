import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FORM_SIZE } from '../constants';
import styles from './styles.scss';

/**
  Circle - The radio circle
*/
const Circle = ({ selected = false }) => {
  const circleStyles = cn(styles.circle, {
    [styles.circleSelected]: selected,
  });

  return <div className={circleStyles}>{selected && <div className={styles.innerDot} />}</div>;
};

Circle.propTypes = { selected: PropTypes.bool };

/**
  RadioV2
*/
export const RadioV2 = ({
  children,
  label,
  selected,
  value: radioValue,
  disabled,
  onChange,
  enableDeselect = false,
  wrapperClass = '',
  labelClass = '',
  size = FORM_SIZE.medium,
  boldLabel = false,
  ...rest
}) => {
  const labelWrapperStyles = cn(styles.labelWrapper, styles[size], wrapperClass, {
    [styles.disabled]: disabled,
  });

  const labelStyles = cn(styles.label, labelClass, {
    [styles.bold]: boldLabel,
  });

  const onClick = useCallback(
    (rValue) => (event) => {
      const {
        target: { checked },
      } = event;
      if (enableDeselect && selected) {
        onChange(null); // deselect
      } else {
        onChange(checked ? rValue : null);
      }
    },
    [selected],
  );

  return (
    <label className={labelWrapperStyles}>
      <div>
        <input
          {...rest}
          type="radio"
          disabled={disabled}
          checked={selected}
          onClick={onClick(radioValue)}
          value={radioValue}
          className={styles.input}
        />
        <Circle selected={selected} disabled={disabled} />
      </div>
      <div>
        <span className={labelStyles}>{label}</span>
        {children}
      </div>
    </label>
  );
};

RadioV2.propTypes = {
  children: PropTypes.any,
  selected: PropTypes.bool,
  label: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  enableDeselect: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  wrapperClass: PropTypes.string,
  labelClass: PropTypes.string,
  size: PropTypes.oneOf([FORM_SIZE.medium, FORM_SIZE.large]),
  boldLabel: PropTypes.bool,
};

export default RadioV2;
