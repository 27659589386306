import React, { memo } from 'react';
import cn from 'classnames';
import { Grid } from 'HTKit/Grid';
import styles from './styles.scss';

const HeaderTextPanel = () => {
  const headerTitleStyles = cn("h1-hero", styles.headerTitle);
  const subTitleStyles = cn("p1", styles.subTitle);

  return(
    <div className={styles.headerContainer}>
      <Grid.Fluid classes={styles.headerWrapper}>
        <Grid.Row>
          <Grid.Column sm={2} md={4} lg={6}>
            <h1 className={headerTitleStyles}>SimpliSafe Home Security System Setup</h1>
            <p className={subTitleStyles}>
              As a nationwide service provider of SimpliSafe products, HelloTech provides customers with expert, in-home setup and device support for your home security system.
            </p>
          </Grid.Column>
          <Grid.Column sm={4} md={4} lg={6}>
          </Grid.Column>
        </Grid.Row>
      </Grid.Fluid>
    </div>
  );
}

export default memo(HeaderTextPanel);
