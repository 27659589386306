/* eslint-disable camelcase */

/* eslint-disable prefer-template,no-useless-concat */
// import 'whatwg-fetch';
import 'isomorphic-fetch';
import { fromJS } from 'immutable';
import { addErrorAppSnackNotice, addErrorPageSnackNotice } from 'src/actions/snackNotice';
import { getSegmentAnonymousId, getZipDataCookie } from 'src/utils/cookies';
import { ENV } from 'src/constants/app';
import { newNotice } from '../components/NoticeStack/actions';
import * as commonActions from '../actions/common';

const isStaging = process.env.NODE_ENV === ENV.STAGING;
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON or text from the request
 */
function parseResponse(response) {
  return response[response.status === 204 ? 'text' : 'json']();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response && response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response ? response.statusText : 'Offline');
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @param  {object} [auth] authToken and formAuthenticityToken
 *
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options = {}, auth = fromJS({})) {
  const requestOptions = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  };

  const cookieAuth = auth.get('cookie');
  if (cookieAuth && !process.browser) {
    requestOptions.headers.cookie = auth.get('cookie'); // browser ignores this header
  }

  const tokenAuth = auth.get('authToken');
  if (tokenAuth) {
    requestOptions.headers['Auth-Token'] = tokenAuth; // eslint-disable-line
  }

  const ajsAnonymousId = getSegmentAnonymousId();
  if (ajsAnonymousId) {
    requestOptions.headers['Ajs-Anonymous-Id'] = ajsAnonymousId;
  }

  const { zipCode } = getZipDataCookie() || {};
  if (zipCode) {
    requestOptions.headers.cz = zipCode;
  }

  Object.assign(requestOptions, options);
  return fetch(url, requestOptions)
    .then(checkStatus)
    .then(parseResponse)
    .then((data) => {
      let dataObject = data;
      if (dataObject === '') {
        dataObject = {};
      }
      return { data: dataObject };
    })
    .catch((err) => {
      if (!err.response) {
        console.log('offline'); // eslint-disable-line
        return { err, data: {}, offline: true, status: 500 };
      }
      return err.response
        .json()
        .then((data) => ({ err, data }))
        .catch(() =>
          // parse error
          ({ err, data: {} }),
        );
    });
}

function jsonWithBody(url, body, options, method, auth) {
  const requestOptions = {
    method,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const cookieAuth = auth.get('cookie');
  if (cookieAuth && !process.browser) {
    requestOptions.headers.cookie = cookieAuth; // browser ignores this header
  }

  const tokenAuth = auth.get('authToken');
  if (tokenAuth) {
    requestOptions.headers['Auth-Token'] = tokenAuth; // eslint-disable-line
  }

  const ajsAnonymousId = getSegmentAnonymousId();
  if (ajsAnonymousId) {
    requestOptions.headers['Ajs-Anonymous-Id'] = ajsAnonymousId;
  }

  const { zipCode } = getZipDataCookie() || {};
  if (zipCode) {
    requestOptions.headers.cz = zipCode;
  }

  if (isStaging) {
    requestOptions.headers.Authorization =
      'Basic ' + new Buffer('hellotech' + ':' + 'HTCebu').toString('base64');
  }

  return request(
    url,
    // eslint-disable-next-line prefer-object-spread
    Object.assign(
      {
        body: JSON.stringify(
          // eslint-disable-next-line prefer-object-spread
          Object.assign(
            {
              authenticity_token: auth.get('formAuthenticityToken', ''),
            },
            body,
          ),
        ),
      },
      requestOptions,
    ),
  );
}

export function postJson(url, body = {}, options = {}, auth = fromJS({})) {
  return jsonWithBody(url, body, options, 'POST', auth);
}

export function putJson(url, body = {}, options = {}, auth = fromJS({})) {
  return jsonWithBody(url, body, options, 'PUT', auth);
}

export function deleteJson(url, body = {}, options = {}, auth = fromJS({})) {
  return jsonWithBody(url, body, options, 'DELETE', auth);
}

export function displayErrors(requestResult) {
  if (requestResult.offline) {
    return newNotice({
      title: 'Offline',
      content:
        'It appears that the browser has lost connectivity. Please try again or contact Support.',
      unique: true,
    });
  }
  const { errors, error_title } = requestResult.data;
  const title = error_title || 'Sorry';
  return newNotice({
    title,
    content: errors
      ? errors.join('. ')
      : 'It appears that there was an error. Please try again or contact Support.',
  });
}

export const displayErrorsWithSnack = (requestResult, opts = {}) => {
  if (requestResult.offline) {
    return addErrorAppSnackNotice({
      content: 'It appears that the browser has lost connectivity. Please try again.',
    });
  }
  const { errors } = requestResult.data;
  const normalizedErrors = typeof errors === 'string' ? [errors] : errors;

  const errorString = normalizedErrors
    ? normalizedErrors.join('. ')
    : 'A request has failed. Please try again or contact Support.';
  const { customContent, ...restOpts } = opts;
  const content = customContent ? `${customContent} - ${errorString}` : errorString;

  if (restOpts.pageName) {
    return addErrorPageSnackNotice({ content, ...restOpts });
  }
  return addErrorAppSnackNotice({ content });
};

/* eslint-disable */
export function toQueryString(obj, prefix) {
  let str = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? `${prefix}[${p}]` : p;
      let v = obj[p];

      if (v !== null && typeof v === 'object') {
        if (Array.isArray(v)) {
          v.forEach((arrayItem) => {
            const key = `${k}[]`;
            str.push(`${encodeURIComponent(key)}=${encodeURIComponent(arrayItem)}`);
          });
        } else {
          str.push(toQueryString(v, k));
        }
      } else {
        str.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
      }
    }
  }
  return str.join('&');
}

/* eslint-enable */
export function requestStarted(...args) {
  return commonActions.requestStarted(args);
}

export function requestFinished(...args) {
  return commonActions.requestFinished(args);
}

export function requestFailed(...args) {
  return commonActions.requestFailed(args);
}

/* eslint-disable prefer-object-spread */
export const toggleLoading = (loading, ...rest) =>
  (loading ? requestStarted : requestFinished)(...rest);
