const basePrice = (price) => parseInt(price / 100, 10);

export const makeOfferJsonLd = (price) =>
  `"offers": {
    "@type": "Offer",
    "price": "${basePrice(price)}",
    "priceCurrency": "USD"
  }`;

export const makePriceRangeJsonLd = (price) => `"priceRange": "$${price ? basePrice(price) : ''}"`;
