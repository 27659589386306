import { connect } from 'react-redux';
import pageSelectors from './selectors';
import { toggleHour, availabilityLoaded } from './actions';
import AvailabilitySelector from './AvailabilitySelector';

export default connect(
  pageSelectors,
  { toggleHour, availabilityLoaded },
)(AvailabilitySelector);

/*
  AvailabilitySelector
    Purpose: Render a scheduling selector
*/
