import { fromJS } from 'immutable';
import { SET_BRANDING, REMOVE_BRANDING } from './constants';

const initialState = fromJS({
  theme: null,
  data: {},
});

const brandingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BRANDING: {
      const { branding } = action;
      return branding ? fromJS(branding) : initialState;
    }
    case REMOVE_BRANDING: {
      return initialState;
    }
    default:
      return state;
  }
};

export default brandingReducer;
