const CONFIG = () => {
  try {
    // eslint-disable-next-line no-undef
    return typeof __CONFIG__ === 'string' ? JSON.parse(__CONFIG__) : __CONFIG__
  } catch (e) {
    return {};
  }
};
const config = CONFIG() || {};

export default config;
