export const tvMountQA = [
  {
    questionText:
      'Do I need to provide any materials for my TV mounting service, other than the TV?',
    answerText:
      'The only materials you need to provide for your TV mounting service are the necessary cables to connect your TV to any of your AV components. Depending on the types of receivers, speakers, and other equipment you have, you might need HDMI and/or optical cables as well as audio and power cords.',
  },
  {
    questionText: 'Can my TV be mounted above a fireplace?',
    answerText:
      'Yes, you can have your TV mounted above a fireplace as long as the wall material is made of drywall or sheetrock.',
  },
  {
    questionText: 'How can I hide wires after my TV wall mount installation?',
    answerText: `HelloTech provides many options for hiding wires after your TV wall mount installation to achieve a neat, clean appearance. Included in your service are neatly dressed wires, but cord masking and in-wall cord concealment services are offered for an additional cost.
With an external cord masking option, your TV and component wires are enclosed in a discrete plastic channel that runs along the wall's surface.
If your home has drywall over stud construction, HelloTech provides in-wall cord concealment services using a power bridge. For this method, wires are hidden behind the wall, pulled out through an opening placed lower towards the floor, and then plugged into a nearby outlet.`,
  },
  {
    questionText: 'Can you mount a TV on any surface?',
    answerText:
      "TVs can be mounted on most surfaces, including drywall that's screwed to metal or wood studs and brick. TVs cannot be mounted to plaster, tile, or stone walls.",
  },
  {
    questionText: 'What type of TV mount do I need?',
    answerText: `The type of TV mount you need depends on the screen size and weight of your TV. Different mounts offer a wide variety of viewing options as well. <a href='https://www.hellotech.com/blog/best-tv-wall-mounts'>Answer these seven questions to determine which TV mount is right for you.</a>`,
  },
  {
    questionText: "What's included in HelloTech's TV mounting service?",
    answerText: `HelloTech offers a same-day <a href='https://www.hellotech.com/tech-support/for/tv-mounting'>TV mounting service</a> and has service techs available 7 days a week. HelloTech's TV mounting service includes:
•	Installation of a mounting bracket
•	Mounting of one TV
•	Connecting all necessary video and audio cables and components
•	Neat dressing of wires
•	Configuring peripheral devices
•	In-wall or external cord concealment services (at an additional cost)`,
  },
  {
    questionText: 'Where can I put my cable box after a TV wall installation?',
    answerText:
      "You can put your cable box wherever you'd like after a TV wall installation. Many people choose to keep their cable box in an entertainment center that's placed near or underneath the mounted TV.",
  },
  {
    questionText: 'How much does it cost to get a TV mounted with HelloTech?',
    answerText: `The base cost to get a <a href='https://www.hellotech.com/tech-support/tv-wall-mounting-install'>TV mounted with HelloTech</a> is $69. Additional charges may apply if you have a particularly large TV, add on a TV mount, or choose to get in-wall cord concealment or external cord masking services.`,
  },
  {
    questionText: 'How do I find a TV mounting service near me?',
    answerText: `<a href='https://www.hellotech.com/tech-support/tv-wall-mounting-install'>TV mounting services</a> from HelloTech are available throughout the United States.`,
  },
  {
    questionText: 'How high should I mount my TV?',
    answerText: `The ideal height for TV mounting depends mainly on four factors: the size of the TV, how far away you'll be, your eye level height, and the viewing angle.
As a rule, the center of a 42” TV should be mounted about 56 inches from the floor, a 55” TV around 61 inches, a 65” TV around 65 inches, and a 70” TV around 67 inches. But the exact mounting height will vary depending on your eye level, which will vary depending on the height of your seats, couch, or bed.
Per the Society of Motion Picture and Television Engineers, you’ll want the TV to be mounted for a viewing angle of not more than 30 degrees. Most people casually sit between 10 to 15 degrees reclined.`,
  },
  {
    questionText: 'What kind of TV mount should I use?',
    answerText:
      'Full-motion (aka articulating) mounts offer the most versatility. They can extend from the wall, swivel side to side, tilt up and down, and even rotate. However, if you want your TV to be flush with the wall, then you should choose a low-profile (aka flat or fixed) or tilting mount.',
  },
];

export const tvDismountQA = [
  {
    questionText: "What if I'm mounting a TV in the same location with a new mount ?",
    answerText:
      "If you're mounting a TV in the same location as your old TV and using a new mount, you'll want to book a TV mounting service instead of the TV dismount/remount service from HelloTech for the best results. The only exception to this is if HelloTech can reuse all of the existing mounting holes.",
  },
  {
    questionText: 'What is a TV dismount or remount?',
    answerText: `A <a href='https://www.hellotech.com/tech-support/tv-dismount-remount'>TV dismount</a> or remount means either that you're removing a TV that's already been mounted or you're mounting a new TV in the same location as your old TV using the existing wall mount.`,
  },
  {
    questionText: 'Can I remove the TV from the wall and use the stand that came with the TV?',
    answerText:
      'Yes, you can remove the TV from the wall and use the stand that came with the TV. After dismounting, your tech can install your TV to its stand.',
  },
  {
    questionText: 'What is included in a TV Dismount or Remount Service with HelloTech?',
    answerText: `HelloTech provides <a href='https://www.hellotech.com/tech-support/tv-dismount-remount'>convenient TV dismounting</a> and remounting services. For a TV dismount/remount, HelloTech will:
•	Dismount your TV from the wall
•	Disconnect cords from relevant AV components
•	Neatly package the TV and components in a box you provide
•	Remove your mount from the wall
•	Conceal in-wall rated wires when applicable at an additional cost`,
  },
  {
    questionText: 'How do you dismount a TV?',
    answerText: `To <a href='https://www.hellotech.com/blog/dismounting-your-tv-do-it-without-breaking-a-sweat-and-the-tv'>dismount a TV</a>, first, unplug all the cords. Then, after loosening any necessary Phillips or Allen screws from the wall mount, the TV is dismounted by lifting and pushing from the bottom to the top.`,
  },
  {
    questionText: 'How do I find a TV dismount or remount service near me?',
    answerText:
      "HelloTech offers <a href='https://www.hellotech.com/tech-support/tv-dismount-remount'>TV dismount and remount services</a> throughout the United States. When you purchase a TV dismount or remount service from HelloTech, you'll benefit from the skilled services of local techs.",
  },
];
