import { call, put, takeEvery, all } from 'redux-saga/effects'; // eslint-disable-line
import { LOAD_PAGE } from 'src/constants/common';
import { pageLoaded } from 'src/actions/common';
import { selectRoutes } from 'src/apiRoutes';
import { PAGE_NAME, SPEEDTEST_SKU_IDS, SPEEDTEST_RESULTS_RECEIVED } from './constants';
import { setSkuData } from './actions';

export function* pageSaga() {
  yield put(pageLoaded(PAGE_NAME, {}));
}

function* getRelatedSkus() {
  const routes = yield call(selectRoutes);
  const response = yield all(SPEEDTEST_SKU_IDS.map((id) => call(routes.skus.find, { id })));
  // Error response from server be { err: 'Error Message' }
  const relatedSkuData = response.filter((sku) => !sku.err).map((sku) => sku.data.sku);
  yield put(setSkuData(relatedSkuData));
}

export function* speedtestResultsReceivedSaga({ testResults }) {
  const routes = yield call(selectRoutes);
  yield call(routes.speedTest.sendResults, testResults);
  yield call(getRelatedSkus);
}

export function* speedTestFlow() {
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
  yield takeEvery(SPEEDTEST_RESULTS_RECEIVED, speedtestResultsReceivedSaga);
}

export default [speedTestFlow];
