import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import layoutSelector from 'src/selectors/layout';
import HTLogo from 'src/components/Header/Parts/HTLogo';
import { usePartnerLogoUrl } from 'src/components/Header/utils';
import { homePath } from 'src/utils/paths';
import styles from './styles.scss';

const PartnerLogo = () => {
  const partnerLogo = usePartnerLogoUrl();
  return partnerLogo ? <img src={partnerLogo} className={styles.partnerLogo} /> : null;
};

const PlainHeader = () => {
  const header = useSelector(layoutSelector('header'));
  const homeUrl = header.get('homeUrl', homePath());

  return (
    <nav className={styles.container}>
      {header.get('actionableLogo') ? (
        <Link to={homeUrl} className={styles.logoWrapper}>
          <HTLogo />
          <PartnerLogo />
        </Link>
      ) : (
        <>
          <HTLogo defaultCursor />
          <PartnerLogo />
        </>
      )}
    </nav>
  );
};

PlainHeader.propTypes = {
  header: PropTypes.object.isRequired,
};

export default memo(PlainHeader);
