import React from 'react';
import { useParams } from 'react-router-dom';
import SubNav from 'src/containers/HomeHub/Parts/SubNav';
import { CALLER_PAGE, HOME_HUB_SOURCES } from 'src/containers/HomeHub/constants';
import { messagePageActions } from 'src/containers/HomeHub/messagePageActions';
import IframeView from 'Elements/IframeView';
import { DEFAULT_LAYOUT } from 'src/constants/common';
// HOC
import withLayout from 'src/hoc/withLayout';
import { DefaultMetaTags } from 'src/components/SEO';

const HomeHub = () => {
  const { category = HOME_HUB_SOURCES.default } = useParams();
  const categoryReference = HOME_HUB_SOURCES[category];
  const iframeSourceString = categoryReference.src;
  const additionalActions = messagePageActions({});

  return (
    <>
      <DefaultMetaTags
        title={categoryReference.metaTitle}
        description={categoryReference.metaDescription}
      />
      <SubNav />
      <IframeView
        callerPageName={CALLER_PAGE}
        src={iframeSourceString}
        additionalActions={additionalActions}
      />
    </>
  );
};

export default withLayout(DEFAULT_LAYOUT)(HomeHub);
