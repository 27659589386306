import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { flow } from 'lodash';
import { Grid } from 'HTKit/Grid';
import Carousel from 'src/components/Elements/Carousel';
import Review from 'src/components/Elements/Review';
import styles from './styles.scss';

const CustomerReviews = (
  {
    reviews,
    className,
    headerText = 'Reviews',
    headerStyles = '',
    itemStyles = '',
    carouselItemStyle = '',
    suppressAddressLine = false,
    slidesToShow = 3,
  },
  ref,
) => {
  if(!reviews || Array.isArray(reviews) && reviews.length === 0) return null;

  // We conditionally apply # slides to show based on the number of reviews.
  const slidesToShowAdjust = [1,2,3].find(v => v === reviews.length) || slidesToShow;

  return (
    <div className={cn(styles.reviews, className)} ref={ref}>
      <Grid.FullWidth>
        <h3 className={cn('h3', headerStyles)}>{headerText}</h3>
        <div className={styles.list}>
          <Carousel
            arrows
            equalHeight
            variableWidth={false}
            slidesToShow={slidesToShowAdjust}
            slidesToScroll={1}
            infinite={false}
            itemStyle={carouselItemStyle}
            resolutions={{
              840: {
                arrows: false,
                variableWidth: true,
                infinite: true,
                slidesToShow: 1,
              },
            }}
          >
            {reviews.map((r) => (
              <Review
                review={r}
                key={r.id}
                className={cn(styles.item, itemStyles)}
                suppressAddressLine={suppressAddressLine}
              />
            ))}
          </Carousel>
        </div>
      </Grid.FullWidth>
    </div>
  );
};

CustomerReviews.propTypes = {
  className: PropTypes.string,
  carouselItemStyle: PropTypes.string,
  reviews: PropTypes.arrayOf(PropTypes.shape({})),
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerStyles: PropTypes.string,
  itemStyles: PropTypes.string,
  suppressAddressLine: PropTypes.bool,
  slidesToShow: PropTypes.number,
};

export default flow(forwardRef, memo)(CustomerReviews);
