import React from 'react';
import IframeView from 'src/components/Elements/IframeView';
import { CALLER_PAGE_NAME, CANCELLATION_POLICY_URL } from './constants';

const CancellationPolicy = () => {
  return (
    <IframeView
      src={CANCELLATION_POLICY_URL}
      callerPageName={CALLER_PAGE_NAME}
    />
  );
};

export default CancellationPolicy;
