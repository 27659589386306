import { call, put, takeEvery, select } from 'redux-saga/effects';
import { selectRoutes } from 'src/apiRoutes';
import { pageLoaded, pageNotice, clearPageNotices, updateUser } from 'src/actions/common';
import { addErrorPageSnackNotice, addSuccessPageSnackNotice } from 'src/actions/snackNotice';
import { LOAD_PAGE } from 'src/constants/common';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { defaultNotice, errorNotice } from 'src/utils/notice';
import { requestStarted, requestFinished } from 'src/utils/request';
import { UPDATE_PROFILE } from './constants';
import { updateCart } from '../../AddSkuPage/actions';

export const ACCOUNT_PAGE_NAME = 'account';

export function* pageSaga() {
  yield loadUserProfileSaga(ACCOUNT_PAGE_NAME, true);
  yield put(pageLoaded(ACCOUNT_PAGE_NAME, {}));
}

export function* updateProfileSaga({ payload }) {
  const routes = yield call(selectRoutes);
  /* Temp solution as BE will come up with a solution that will update all workflow with the users address. */
  const currentCart = yield select((state) => state.getIn(['entities', 'cart']));
  const cartToken = currentCart.get('token');
  const workflow = currentCart.get('workflow');
  /* End temp solution */
  const { profile, opts } = payload;
  const onSuccess = opts?.onSuccess || (() => null);
  yield put(clearPageNotices(ACCOUNT_PAGE_NAME));
  yield put(requestStarted());
  const requestResult = yield call(routes.users.update, {
    client: profile,
    ...(workflow && cartToken && { cart_token: cartToken }),
  });
  yield put(requestFinished());

  const { useSnackbar = false, snackNoticeOpts = {} } = opts;

  if (requestResult.err) {
    const { errors } = requestResult.data;
    if (!useSnackbar) {
      yield put(pageNotice(ACCOUNT_PAGE_NAME, errorNotice(errors)));
    } else {
      yield put(
        addErrorPageSnackNotice({
          content: requestResult.data.errors.join('. '),
          ...snackNoticeOpts,
        }),
      );
    }
  } else {
    const { user, cart } = requestResult.data;
    if (!useSnackbar) {
      yield put(pageNotice(ACCOUNT_PAGE_NAME, defaultNotice('Account was successfully updated.')));
    } else {
      yield put(
        addSuccessPageSnackNotice({
          content: 'Account was successfully updated.',
          dismissable: true,
          ...snackNoticeOpts,
        }),
      );
    }
    yield put(updateUser(user));

    if (cart) {
      /*
          If we are in a workflow, a race condition ensues with getting cart. So, this one has no workflow or items etc...
          We don't want this one to update state. Its the wrong cart once past customer-info.
     */
      if (!workflow) {
        yield put(updateCart({ cart }));
      }
    }
    onSuccess({ user, cart });
  }
}

export function* accountFlow() {
  yield takeEvery(
    (action) => action.type === LOAD_PAGE && action.page === ACCOUNT_PAGE_NAME,
    pageSaga,
  );
  yield takeEvery(UPDATE_PROFILE, updateProfileSaga);
}

export default [accountFlow];
