export const PAGE_NAME = 'payment';
export const PAYMENT_EDIT_MODE = 'paymentPage/PAYMENT_EDIT_MODE';
export const SUBMIT_PAYMENT = 'paymentPage/SUBMIT_PAYMENT';
export const SUBMIT_EXISTING_PAYMENT = 'paymentPage/SUBMIT_EXISTING_PAYMENT';
export const PAYMENT_SUBMITTED = 'paymentPage/PAYMENT_SUBMITTED';
export const BONUS_EDIT_MODE = 'paymentPage/BONUS_EDIT_MODE';
export const SUBSCRIPTION_LOADED = 'paymentPage/SUBSCRIPTION_LOADED';
export const SUBMIT_PAYMENT_TYPE = 'paymentPage/SUBMIT_PAYMENT_TYPE';
export const PAYMENT_TYPES = {
  card: 'card',
  affirm: 'affirm'
}
