import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { flow } from 'lodash';
import SlideModal from 'src/components/HTKit/Modals/Slide';
import Review from 'src/components/Review';
import { ensureImmutable } from 'src/utils/helpers';
import styles from './styles.scss';

const enhance = flow(
  // eslint-disable-next-line react/display-name, react/prop-types
  (Component) => ({ reviews, ...rest }) => {
    // show 10 reviews. move the first 3 reviews to end of list
    const prepared = ensureImmutable(reviews);
    const newList = prepared.slice(3, 10).concat(prepared.slice(0, 3));
    return <Component {...rest} reviews={newList} />;
  },
  memo,
);

const ReviewsModal = ({ reviews, visible, onClose }) => (
  <SlideModal open={visible} handleClose={onClose} showLogo={false}>
    <div className={cn('l-content-section', styles.container)}>
      {reviews.map((review, index) => (
        <Review key={index} review={review} className="marginBottom-medium1" />
      ))}
    </div>
  </SlideModal>
);

ReviewsModal.propTypes = {
  visible: PropTypes.bool,
  reviews: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default enhance(ReviewsModal);
