export const HT_US_STATES = {
  AR: 'Arkansas',
  AZ: 'Arizona',
  CA: 'California',
  CT: 'Connecticut',
  ID: 'Idaho',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MO: 'Missouri',
  MS: 'Mississippi',
  NC: 'North Carolina',
  NJ: 'New Jersey',
  NY: 'New York',
  OH: 'Ohio',
  TN: 'Tennessee',
  TX: 'Texas',
};
