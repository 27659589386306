import {
  SET_PASSWORD,
  PASSWORD_CREATED,
} from './constants';

export function createPassword({ password, resetPasswordToken, orderToken }) {
  return {
    type: SET_PASSWORD,
    password,
    resetPasswordToken,
    orderToken,
  };
}

export function passwordCreated() {
  return {
    type: PASSWORD_CREATED,
  };
}
