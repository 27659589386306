import { SUBMIT_SURVEY, FORM_SUBMITTED } from './constants';

export function submitSurvey(survey) {
  return {
    type: SUBMIT_SURVEY,
    payload: { survey },
  };
}

export function formSubmitted(status) {
  return {
    type: FORM_SUBMITTED,
    payload: status,
  };
}
