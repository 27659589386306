import React from 'react';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'HTKit/Icon';
import { TOAST_CONTAINER_ID } from '../constants';
import styles from './styles.scss';

// Slide Transition that closely resembles what the Design team spec'd out
const CustomTransition = cssTransition({
  enter: styles.animateUp,
  exit: styles.animateDown,
});

// Close Button
const CloseButton = ({ closeToast }) => (
  <button onClick={closeToast} className="plainButton">
    <Icon name="v2-close-icon" className={cn('marginLeft-small', styles.closeButton)} />
  </button>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

// Toast Container
const HTToastContainer = (props = {}) => {
  const { className = '', ...restProps } = props;
  return (
    <ToastContainer
      autoClose={4000}
      closeButton={<CloseButton />}
      containerId={TOAST_CONTAINER_ID}
      draggable={false}
      enableMultiContainer
      hideProgressBar
      newestOnTop
      position={toast.POSITION.BOTTOM_CENTER}
      transition={CustomTransition}
      className={cn('marginBottom-medium', styles.toastContainer, className)}
      toastClassName={cn('marginTop-small', styles.toast)}
      bodyClassName={styles.toastBody}
      {...restProps}
    />
  );
};

HTToastContainer.propTypes = {
  className: PropTypes.string,
};

export default HTToastContainer;
