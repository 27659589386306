import { combineReducers } from 'redux-immutable';
import firstServiceDiscount from 'src/components/HTKit/Modals/FirstServiceDiscount/reducer';
import surveyMonkey from 'src/components/SurveyMonkey/reducer';
import { Modals as fieldSales } from 'src/containers/FieldSales/reducer';

export default () => combineReducers({
  firstServiceDiscount,
  surveyMonkey,
  fieldSales
});
