import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'HTKit/Icon';
import PanelV2 from 'HTKit/Elements/PanelV2/PanelV2';
import Avatar from 'HTKit/Elements/Avatar';
import glenn from 'src/components/Sections/MeetOurTechs/imgs/glenn.png';
import styles from './styles.scss';

/**
 * Wrapper
 *
 * A wrapper to determine whether to use the Panel component or a div.
 */
const Wrapper = ({ asPanel = true, children, ...rest }) => {
  return asPanel ? <PanelV2 {...rest}>{children}</PanelV2> : <div {...rest}>{children}</div>;
};

Wrapper.propTypes = {
  asPanel: PropTypes.bool,
  children: PropTypes.node,
};

/**
 *  PreferredTechCard
 *
 *  This card is used to indicate to the customer that their preferred tech is
 *  up for consideration for direct offer.
 *
 *  Note: This should not show for remote carts/orders.
 *
 *  @params rest: props for the wrapping PanelV2/div
 */
const PreferredTechCard = ({
  isRemote = true,
  cartHasOnlyProducts = false,
  techName = '',
  imageUrl = glenn,
  asPanel = true,
  className = '',
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const toggleCopy = () => setOpen(!open);

  // Styles
  const containerStyles = cn(
    'flex alignItemsCenter justifyContentCenter flexDirectionColumn paddingY-medium paddingX-small',
    styles.container,
    className,
  );
  const iconStyles = cn(styles.infoIcon, {
    [styles.open]: open,
  });

  if (!techName || isRemote || cartHasOnlyProducts) return null;

  return (
    <Wrapper asPanel={asPanel} className={containerStyles} {...rest}>
      <div className={cn('flex alignItemsCenter width-full', styles.innerContainer)}>
        <div className="marginRight-medium">
          <Avatar image={imageUrl} name={techName} />
        </div>
        <div className="flex alignItemsCenter justifyContentSpaceBetween width-full">
          <div>
            <p className="caption n700">Preferred Technician</p>
            <p className="p1 n900">{techName}</p>
          </div>
          <button onClick={toggleCopy} className="plainButton marginLeft-tiny">
            <Icon name="info-outline" className={iconStyles} />
          </button>
        </div>
      </div>
      {open && (
        <div className={cn('paddingTop-small2 width-full', styles.innerContainer)}>
          <p className="caption n800">
            We&apos;ll try our best to get your preferred technician. If they&apos;re unavailable,
            we&apos;ll assign a top-rated technician in their place.
          </p>
        </div>
      )}
    </Wrapper>
  );
};

PreferredTechCard.propTypes = {
  isRemote: PropTypes.bool,
  cartHasOnlyProducts: PropTypes.bool,
  techName: PropTypes.string,
  imageUrl: PropTypes.string,
  asPanel: PropTypes.bool,
  className: PropTypes.string,
};

export default memo(PreferredTechCard);
