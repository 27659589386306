import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { LOAD_PAGE } from 'src/constants/common';
import { pageLoaded } from 'src/actions/common';
import { PRICING_TEST_YEARLY_ID } from 'src/constants/plan';
import { selectRoutes } from 'src/apiRoutes';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import { push, subscriptionGifConfirmationPath } from 'src/utils/paths';
import { planIdSelector } from './selectors';
import { clearPageData, setPlanDetails, applyDiscount } from './actions';
import { GET_PLAN_DETAILS, PAGE_NAME, SUBMIT_ORDER, APPLY_PROMO_CODE } from './constants';

function* pageSaga() {
  yield put(pageLoaded(PAGE_NAME));
}

function* getPlanDetailsSaga() {
  const routes = yield call(selectRoutes);
  const response = yield call(routes.plans.subscriptions.show, { id: PRICING_TEST_YEARLY_ID });
  if (response.err) {
    yield put(displayErrors(response));
  } else {
    yield put(setPlanDetails(response.data.plan));
  }
}

function* submitOrderSaga({ orderDetails }) {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.plans.subscriptions.giftPurchase, orderDetails);
  yield put(requestFinished());
  if (response.err) {
    yield put(displayErrors(response));
  } else {
    yield put(clearPageData());
    yield put(push(subscriptionGifConfirmationPath, response.data.gift));
  }
}

function* applyPromoCodeSaga({ code }) {
  const routes = yield call(selectRoutes);
  const planId = yield select(planIdSelector);
  yield put(requestStarted());
  const response = yield call(routes.plans.subscriptions.giftApplyCoupon, { planId, coupon: code });
  yield put(requestFinished());
  if (response.err) {
    yield put(displayErrors(response));
  } else {
    yield put(applyDiscount({ ...response.data.discount, code }));
  }
}

function* pageFlow() {
  yield takeLatest(SUBMIT_ORDER, submitOrderSaga);
  yield takeLatest(GET_PLAN_DETAILS, getPlanDetailsSaga);
  yield takeLatest(APPLY_PROMO_CODE, applyPromoCodeSaga);
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
}

export default [pageFlow];
