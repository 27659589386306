import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const SkuFAQButton = ({ children, onClick }) => (
  <button onClick={onClick} className={`plainButton ${styles.button}`}>
    {children}
  </button>
);

SkuFAQButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SkuFAQButton;
