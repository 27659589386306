// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Components
import withResolution from 'src/hoc/withResolution';
import SlideModal from 'HTKit/Modals/Slide';
import ModalWrapper from '../ModalWrapper';
import RemoveSubscriptionContent from './RemoveSubscriptionContent';
// Styles
import styles from './styles.scss';

const RemoveSubscriptionFromCartModal = (props) => {
  const { visible, onCancel, isMobileOrTablet } = props;
  const desktopModalStyles = cn('site-v2', styles.desktopModal);
  const mobileModalStyles = cn('site-v2', styles.mobileModal);

  return isMobileOrTablet ? (
    <SlideModal
      open={visible}
      handleClose={onCancel}
      showLogo={false}
      contentStyles={mobileModalStyles}
      closeStyles={styles.mobileModalClose}
      headerStyles={styles.headerStyles}
    >
      <RemoveSubscriptionContent {...props} />
    </SlideModal>
  ) : (
      <ModalWrapper
        isOpen={visible}
        onClose={onCancel}
        modalStyles={desktopModalStyles}
        closeStyles={styles.modalClose}
      >
        <RemoveSubscriptionContent {...props} />
      </ModalWrapper>
    );
};

RemoveSubscriptionFromCartModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  isMobileOrTablet: PropTypes.bool.isRequired,
};

export default withResolution(RemoveSubscriptionFromCartModal);

/*
  This modal is expecting a page object with a `planDetails` key -GH Oct 25, 2019
*/
