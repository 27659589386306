import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Hooks & Utils
import { useSelector } from 'react-redux';
import { useAvailabilitySelectorSelectTimeWindow } from './AvailabilitySelectorSelectTimeWindow.hooks';
import { useRecurrenceText } from 'src/containers/BookingPage/AvailabilityPage/hooks';
import { SCHEDULING_ACTIONS } from 'src/utils/serviceScheduling/serviceScheduling.constants';
// Selectors
import { pureCartSelector } from 'src/selectors/cart';
// Components
import { SelectDatePicker } from '../SelectDatePicker/SelectDatePicker';
import { RecurringServicePanel } from '../RecurringServicePanel';
import { TimeOptionsSelectField } from '../AvailabilitySelector/TimeOptionsSelectField';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import ButtonFooter from 'src/components/ButtonFooter';
import { CalloutBox } from '../Elements/CalloutBox';
import styles from './styles.scss';

// ###############
// SUB COMPONENTS
// ###############
const SubmitButton = (props) => (
  <Button theme={THEMES.V2PRIMARY} {...props}>
    Continue
  </Button>
);

const ChosenTimeSlot = ({ isVisible, isMobile, text, className }) => {
  const commonStyles = cn('p0 text-weight-med text-align-center', className);

  if (!isVisible) return null;
  if (isMobile) return <p className={commonStyles}>{text}</p>;
  return <p className={cn('showTabletDesktopV2', commonStyles)}>{text}</p>;
};

const MobileButtonFooter = ({
  isTimeSlotVisible,
  timeSlotText,
  onSubmitClick,
  showRecurrenceText,
  formattedRecurrenceText,
  isSubmitButonDisabled,
}) => {
  return (
    <ButtonFooter showMobile showTablet={false} showDesktop={false} className={styles.buttonFooter}>
      <ChosenTimeSlot isMobile isVisible={isTimeSlotVisible} text={timeSlotText} />
      {showRecurrenceText && isTimeSlotVisible && (
        <p className="p1 text-align-center n800 marginTop-tiny">{formattedRecurrenceText}</p>
      )}
      <SubmitButton
        onClick={onSubmitClick}
        className="marginTop-small"
        disabled={isSubmitButonDisabled}
      />
    </ButtonFooter>
  );
};

const CalloutBoxTextWrapper = ({ children }) => (
  <div className={cn(styles.calloutBoxWrapper, 'paddingY-small')}>{children}</div>
);
// ###############
// MAIN COMPONENT
// ###############

export const AvailabilitySelectorSelectTimeWindow = ({
  selectedTimes,
  schedulingAction,
  orderConfig,
  isEditing,
}) => {
  /** @type {Cart} */
  const cart = useSelector(pureCartSelector);

  const {
    selectedDate,
    handleSelectDate,
    chosenTimeSlot,
    setChosenTimeSlot,
    timeOptions,
    validDates,
    handleSubmitAvailability,
    recurrence,
    setRecurrence,
    isEditMode,
    allowRecurringBooking,
    formattedChosenTimeSlot,
    showSubmitButton,
    isSubmitButonDisabled,
    handleRecurringPanelVisibilityChange,
  } = useAvailabilitySelectorSelectTimeWindow({
    cart,
    selectedTimes,
    schedulingAction,
    orderConfig,
    isEditing,
  });
  const { formattedRecurrenceText, showRecurrenceText } = useRecurrenceText({ recurrence });

  return (
    <div className={styles.container}>
      <SelectDatePicker
        selectedDate={selectedDate}
        setSelectedDate={handleSelectDate}
        validDates={validDates}
        className={styles.dropdown}
      />
      <TimeOptionsSelectField
        label="Start time"
        options={timeOptions}
        value={chosenTimeSlot}
        onChange={setChosenTimeSlot}
        className={styles.dropdown}
      />
      <CalloutBox
        text="Your tech will provide an exact ETA when they are en route"
        className="marginY-medium"
        textContentContainer={CalloutBoxTextWrapper}
      />
      {allowRecurringBooking && (
        <RecurringServicePanel
          selectedDate={selectedDate}
          selectedTimes={selectedTimes}
          isEditMode={isEditMode}
          cart={cart}
          setRecurrence={setRecurrence}
          onPanelVisibilityChange={handleRecurringPanelVisibilityChange}
          useTimeWindows
        />
      )}

      <ChosenTimeSlot
        isVisible={chosenTimeSlot?.value}
        text={formattedChosenTimeSlot}
        className="marginTop-medium1"
      />

      {showRecurrenceText && chosenTimeSlot?.value && (
        <div className={cn(styles.recurrenceText, styles.desktop)}>
          <p className="marginTop-tiny p1 text-align-center n800">{formattedRecurrenceText}</p>
        </div>
      )}

      {showSubmitButton && (
        <>
          <div className="showTabletDesktopV2">
            <SubmitButton
              onClick={handleSubmitAvailability}
              className="marginTop-small1"
              disabled={isSubmitButonDisabled}
            />
          </div>
          <MobileButtonFooter
            isTimeSlotVisible={chosenTimeSlot?.value}
            timeSlotText={formattedChosenTimeSlot}
            onSubmitClick={handleSubmitAvailability}
            showRecurrenceText={showRecurrenceText}
            formattedRecurrenceText={formattedRecurrenceText}
            isSubmitButonDisabled={isSubmitButonDisabled}
          />
        </>
      )}
    </div>
  );
};

// ###########
// PROP TYPES
// ###########

AvailabilitySelectorSelectTimeWindow.propTypes = {
  selectedTimes: PropTypes.arrayOf(PropTypes.string),
  schedulingAction: PropTypes.oneOf(Object.values(SCHEDULING_ACTIONS)),
  orderConfig: PropTypes.shape({
    orderId: PropTypes.number,
    orderToken: PropTypes.string,
    publicPage: PropTypes.bool,
    order: PropTypes.object,
  }),
  isEditing: PropTypes.bool,
};

ChosenTimeSlot.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CalloutBoxTextWrapper.propTypes = { children: PropTypes.node };

MobileButtonFooter.propTypes = {
  isTimeSlotVisible: PropTypes.bool.isRequired,
  timeSlotText: PropTypes.string.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  showRecurrenceText: PropTypes.bool,
  formattedRecurrenceText: PropTypes.string,
  isSubmitButonDisabled: PropTypes.bool,
};

/** @typedef {import('src/types/cart').Cart} Cart */
