import { fromJS } from 'immutable';
import { CHAT_SHOWN, NEW_CHAT_MESSAGE } from '../constants/chat';

const initialChatState = {
  unreadMessages: false,
  chatShown: false,
};

export default function chatReducer(state = fromJS(initialChatState), action) {
  switch (action.type) {
    case CHAT_SHOWN: {
      let newState = state.set('chatShown', action.shown);
      if (action.shown) {
        newState = newState.set('unreadMessages', false);
      }
      return newState;
    }
    case NEW_CHAT_MESSAGE:
      return state.set('unreadMessages', !state.get('chatShown'));
    default:
      return state;
  }
}
