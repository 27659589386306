import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Grid } from 'HTKit/Grid';
import styles from './styles.scss';

const ServiceBlock = ({ imgClasses }) => {
  const imageStyles = cn(styles.image, imgClasses);
  const titleStyles = cn('h4', styles.title);
  const subTitleStyles = cn('p1', styles.subTitle);

  return (
    <Grid.FullWidth classes={styles.container}>
      <Grid.Row classes={styles.customRow}>
        <Grid.Column sm={4} md={4} lg={5}>
          <div className={styles.serviceText}>
            <h4 className={titleStyles}> Service as soon as today </h4>
            <p1 className={subTitleStyles}>In-home service from 9am-9pm. Book online or by phone in minutes for service as soon as today!</p1>
          </div>
        </Grid.Column>
        <Grid.Column sm={4} md={4} lg={6}>
          <div className={imageStyles}/>
        </Grid.Column>
      </Grid.Row>
    </Grid.FullWidth>
  );
};

ServiceBlock.propTypes = {
  imgClasses: PropTypes.string,
};

export default ServiceBlock;
