import {merge} from 'lodash';
import validate from 'validate.js';

validate.validators.xfinity = (value, value2, key, options) => {
  const o = merge({}, options);
  o[key] = value;
  const validEmail = !validate.single(o.clientEmail, {presence: true, email: true});

  if (o.clientEmail && o.clientPhone) {
    return o.emailTosCheckBox && validEmail && o.phoneTosCheckBox ? undefined : true;
  }
  if (o.clientEmail) {
    return o.emailTosCheckBox && validEmail ? undefined : true;
  }
  if (o.clientPhone) {
    return o.phoneTosCheckBox ? undefined : true;
  }
  return true;
};

const xfinityCheckSum = (value, {clientEmail, clientPhone}) => {
  return !!(clientEmail || clientPhone);
};

export default {
  email: {
    email: true,
    presence: {
      allowEmpty: false,
    },
  },
  firstName: {
    presence: {
      allowEmpty: false,
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
    },
  },
  boolean: {
    presence: {
      message: '^You need to check the checkbox'
    },
    inclusion: {
      within: [true],
      message: '^You need to check the checkbox'
    }
  },
  dropDown: (value) => {
    return {
      inclusion: {
        within: value,
        message: '^You need to pick an option'
      }
    }
  },

  password: (value) => {
    const presenceMsg = validate.validators.presence(value);
    if (presenceMsg) {
      return {
        presence: {
          allowEmpty: false,
          message: "^Password can't be blank",
        }
      };
    }
    return {
      length: {
        minimum: 6,
      }
    };
  },
  required: {
    presence: {
      allowEmpty: false,
    },
  },
  phone: {
    presence: {
      allowEmpty: false,
    },
  },
  cvc: {
    presence: {
      allowEmpty: false,
    },
    length: {
      minimum: 3,
      maximum: 4,
    },
  },
  stars: {
    presence: {
      allowEmpty: false,
    },
  },
  comment: {
    presence: {
      allowEmpty: false,
    },
  },
  zip: {
    presence: {
      allowEmpty: false,
    },
    length: {
      is: 5,
    },
  },
  passwordConfirmation: {
    equality: {
      attribute: 'password',
      message: '^Passwords do not match',
    },
  },
  equalsTo: (field) => ({ equality: field }),
  xfinity: (value, options) => ({ xfinity: value, presence: { allowEmpty: xfinityCheckSum(value, options) } }),
};

export function loginEmailRule(value) {
  const presenceMsg = validate.validators.presence(value, { allowEmpty: false });
  if (presenceMsg) {
    return {
      presence: {
        allowEmpty: false,
        message: "^Email can't be blank."
      }
    };
  }
  return {
    email: { message: '^Enter a valid email address.' }
  };
}

export function loginPasswordRule(value) {
  const presenceMsg = validate.validators.presence(value, { allowEmpty: false });
  if (presenceMsg) {
    return {
      presence: {
        allowEmpty: false,
        message: "^Password can't be blank"
      }
    };
  }
  return {
    length: {
      minimum: 6,
      message: '^Invalid email or password. Please try again.',
    }
  };
}
