import { put, takeLatest } from 'redux-saga/effects';
import { isFunction, forEach, map, minBy, filter } from 'lodash';
import { displayErrors } from '../../utils/request';
import { invalidForm, clearFormErrors } from '../../actions/common';
import { VALIDATE_FORM, INVALID_FORM } from '../../constants/common';
import { isValid } from '../../utils/validation';

export function* setUpFormErrors(formName, response) {
  const {status} = response.err.response;
  if (status === 422) {
    const { formErrors } = response.data;
    yield put(invalidForm(formName, formErrors));
  } else {
    yield put(displayErrors(response));
  }
}

export function* validateFormSaga({ payload: { name, form, rules, onSuccess, onError, except, strict } }) {
  const { valid, messages } = isValid(form, rules, except, strict);

  if (!valid && name) {
    const errorMessages = {};
    forEach(messages, (value, key) => { errorMessages[key] = value.join('. '); });
    yield put(invalidForm(name, errorMessages));
  } else if (name) {
    yield put(clearFormErrors(name));
  }

  const method = valid ? onSuccess : onError;

  if (isFunction(method)) {
    method({ valid, messages });
  }
}

function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  };
}

function getAbsoluteOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left,
    top: rect.top,
  };
}

function isElementInViewport(el) {
  const TOP_ADJ = 50;
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= TOP_ADJ &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

// eslint-disable-next-line require-yield
export function* formErrorSaga({ errors }) {
  if (!process.browser) return;
  const errorHeights = filter(map(errors, (_, errorName) => {
    const e = document.getElementById(errorName);
    if (!e) return [null, null];
    return [e, getOffset(e).top];
  }), '0');
  const highestError = minBy(errorHeights, '1');
  if (highestError) {
    const top = highestError[1];
    const element = highestError[0];

    // yeah, sorry... just "magic" .modal & .modal-content classes for now
    // since otherwise there will be a lot of binded Parts...
    if (isFunction(element.closest) && element.closest('.modal')) {
      if (!isElementInViewport(element)) {
        const scrollElement = element.closest('.modal-content');
        element.scrollIntoView(true);
        scrollElement.scrollBy(0, -60);
      }
    } else {
      window.scroll(0, top);
      for (let i = 0; i <= 5; i += 1) {
        const offset = getAbsoluteOffset(element);
        const elementAtPoint = document.elementFromPoint(offset.left, offset.top);
        if (!elementAtPoint || (elementAtPoint === element) || elementAtPoint.contains(element) || element.contains(elementAtPoint)) break;
        const rect = elementAtPoint.getBoundingClientRect();
        window.scroll(0, window.scrollY - rect.top - rect.height);
      }
    }
  }
}

function* formFlow() {
  yield takeLatest(VALIDATE_FORM, validateFormSaga);
}

function* formErrorFlow() {
  yield takeLatest(INVALID_FORM, formErrorSaga);
}

export default [
  formFlow,
  formErrorFlow,
];
