import { isObject } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export function onFormValueChange(field, escape = false) {
  return (data) => {
    // eslint-disable-next-line no-nested-ternary
    const value = isObject(data) && !escape ? (data.target ? data.target.value : data.value) : data;
    this.setState({ [field]: value });
  };
}
