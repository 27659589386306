import { fromJS } from 'immutable';
import { PAGE_LOADED } from 'src/constants/common';
import { CLEAR_ITEM_STATUS } from './constants';
import { ITEM_ADDED, ITEM_EDITED } from '../CartPage/constants';

const initialState = fromJS({ email: null, itemAdded: false, itemEdited: false });

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        case 'addSku':
          return state.set('email', action.email);
        default:
          return state;
      }
    case ITEM_ADDED:
      return state.set('itemAdded', true);
    case ITEM_EDITED:
      return state.set('itemEdited', true);
    case CLEAR_ITEM_STATUS:
      return state.merge({ itemAdded: false, itemEdited: false });
    default:
      return state;
  }
}

export default pageReducer;
