import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../HTKit/Icon';
import { formatDate } from './SelectDatePicker.utils';

export const SingleValue = ({ data, ...props }) => {
  return (
    <div {...props}>
      <Icon name="calendar" className="marginRight-tiny1" />
      {formatDate(data.value)}
    </div>
  );
};

SingleValue.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.instanceOf(Date),
    label: PropTypes.string,
  }),
};
