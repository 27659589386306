import React, { memo, useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { HEADER_WRAPPER_ID, HEADER_ID } from 'src/components/Header/constants';
import styles from './styles.scss';

const MARGIN_OF_ERROR = 3;

/**
 * StickyBar
 *
 * Use this component when it needs to appear in the document flow but then appears "attached" to
 * either <Header /> or <SubNav /> after scrolling.
 */
const StickyBar = ({
  children,
  className = '',
  elevatedClassName = '',
  subNavRef,
  style = {},
  ...rest
}) => {
  const stickyBarRef = useRef();
  const [shouldElevateBar, setShouldElevateBar] = useState(false);
  const [offsetBottom, setOffsetBottom] = useState(null);

  useEffect(() => {
    const handler = throttle(() => {
      if (subNavRef && subNavRef.current) {
        /**
         * Run this if-block if a <SubNav /> ref is passed.
         *
         * This block will:
         *   - manually set the top position of this component by collecting the bottom
         *     position of <SubNav /> so that this component can stick directly
         *     below <SubNav />.
         *   - remove the box-shadow of <SubNav /> so that this component will appear
         *     to be on the same level of <Header /> and <SubNav />.
         *
         * Other Notes:
         *   - Math.abs is used because Safari mobile will return float values.
         *     getBoundingClientRect.top/bottom will be ofslightly different values which
         *     will affect comparisons as seen below. For example, currentOffsetBottom will
         *     be 132.1245 in one call but be 133 in a subsequent call.
         */

        const { top: stickyBarTop } = stickyBarRef.current.getBoundingClientRect();
        const { bottom: currentOffsetBottom } = subNavRef.current.getBoundingClientRect();

        // Collect value to set the top position
        if (Math.abs(currentOffsetBottom - offsetBottom) > MARGIN_OF_ERROR) {
          setOffsetBottom(currentOffsetBottom);
        }

        // Control the "level" of the sticky bar and <SubNav />
        if (Math.abs(currentOffsetBottom - stickyBarTop) < MARGIN_OF_ERROR) {
          if (!shouldElevateBar) {
            // eslint-disable-next-line no-param-reassign
            subNavRef.current.style.boxShadow = 'none';
            setShouldElevateBar(true);
          }
        } else if (shouldElevateBar) {
          // eslint-disable-next-line no-param-reassign
          subNavRef.current.style.boxShadow = '';
          setShouldElevateBar(false);
        }
      } else {
        /**
         * This block contains the default behavior of this component.
         *
         * When the sticky bar (visually) hits the bottom of the main header, remove
         * the box-shadow on the outer header so that this bar appears to be on the
         * same level of the header.
         */
        const headerWrapperElem = document.querySelector(`#${HEADER_WRAPPER_ID}`);
        const headerElem = document.querySelector(`#${HEADER_ID}`);
        const { bottom: headerBottom } = headerElem.getBoundingClientRect();
        const { top: stickyBarTop } = stickyBarRef.current.getBoundingClientRect();

        if (Math.abs(headerBottom - stickyBarTop) < MARGIN_OF_ERROR) {
          headerWrapperElem.style.boxShadow = 'none';
          setShouldElevateBar(true);
        } else {
          headerWrapperElem.style.boxShadow = '';
          setShouldElevateBar(false);
        }
      }
    }, 50);

    window.addEventListener('scroll', handler, { passive: true });

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [subNavRef, shouldElevateBar, offsetBottom]);

  const containerClasses = cn(
    styles.container,
    {
      [styles.elevate]: shouldElevateBar,
      [elevatedClassName]: shouldElevateBar && elevatedClassName,
    },
    className,
  );

  // We're subtracting 1px from offsetBottom to offset this container's border top width.
  const containerStyle = {
    ...style,
    ...(subNavRef ? { top: `${offsetBottom}px` } : {}),
  };

  return (
    <div ref={stickyBarRef} className={containerClasses} style={containerStyle} {...rest}>
      {children}
    </div>
  );
};

StickyBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  subNavRef: PropTypes.object,
  elevatedClassName: PropTypes.string,
  style: PropTypes.object,
};

export default memo(StickyBar);
