import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const ScrollToTop = ({ location, children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};

ScrollToTop.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

ScrollToTop.defaultProps = {
  children: null,
};

export default withRouter(ScrollToTop);
