import { TOGGLE_DROPDOWN } from './constants';

export const toggleDropdown = () => ({
  type: TOGGLE_DROPDOWN,
});

export const setDropdownState = (toggleState = false) => ({
  type: TOGGLE_DROPDOWN,
  toggleState,
});
