import React, { useRef, useCallback, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import MaskedInput from 'react-text-mask';
import { prepareMask } from 'src/components/HTKit/Forms/InputField/utils';
import Counter from 'src/utils/counter';
import { FORM_SIZE } from '../constants';
import { Label, ErrorMsg } from '../Parts';
import PostfixIcon from './PostfixIcon';
import styles from './styles.scss';

/**
  Input - input to render
*/
const Input = ({
  appendedNode,
  size,
  inputId,
  defaultValue,
  onChange = () => {},
  placeholder = '',
  mask,
  guide = true,
  error,
  iconName,
  iconClass,
  iconOnClick,
  iconComponent,
  inputRef,
  inputWrapperClass,
  disabled,
  prependedNode,
  showLoader,
  ...rest
}) => {
  const hasMask = !!mask;
  const maskType = prepareMask(mask);
  const hasError = !!error;
  const onChangeCB = useCallback((event) => onChange(event), [onChange]);

  // Set default value
  useEffect(() => {
    if (defaultValue) {
      onChange({ target: { value: defaultValue } });
    }
  }, []);

  const styleSize = styles[`${size}`];

  // Styles
  const inputWrapperStyles = cn(inputWrapperClass, styles.inputWrapper, {
    [styles.withError]: hasError,
    [styles.disabled]: disabled,
  });
  const inputStyles = cn(styles.inputV2, styleSize, {
    [styles.withPrepended]: !!prependedNode,
    [styles.withAppended]: !!(appendedNode || iconName || iconComponent),
  });

  return (
    <div className={inputWrapperStyles}>
      {prependedNode && (
        <div className={cn(styles.sideElements, styles.prepended, styleSize)}>{prependedNode}</div>
      )}
      {!hasMask ? (
        <input
          id={inputId}
          ref={inputRef}
          className={inputStyles}
          defaultValue={defaultValue}
          onChange={onChangeCB}
          placeholder={placeholder}
          autoComplete="off"
          disabled={disabled}
          {...rest}
        />
      ) : (
        <MaskedInput
          id={inputId}
          ref={inputRef}
          className={inputStyles}
          defaultValue={defaultValue}
          onChange={onChangeCB}
          placeholder={placeholder}
          autoComplete="off"
          mask={maskType}
          guide={guide}
          placeholderChar={'\u2000'}
          {...rest}
        />
      )}
      {appendedNode ? (
        <div className={cn(styles.sideElements, styles.appended, styleSize)}>{appendedNode}</div>
      ) : (
        <PostfixIcon
          {...rest}
          inputRef={inputRef}
          hasError={hasError}
          size={size}
          iconName={iconName}
          iconClass={iconClass}
          iconOnClick={iconOnClick}
          iconComponent={iconComponent}
          showLoader={showLoader}
        />
      )}
    </div>
  );
};

Input.propTypes = {
  appendedNode: PropTypes.node,
  inputRef: PropTypes.object,
  size: PropTypes.string,
  inputId: PropTypes.string,
  defaultValue: PropTypes.any,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  mask: PropTypes.string,
  guide: PropTypes.bool,
  onChange: PropTypes.func,
  iconName: PropTypes.string,
  iconClass: PropTypes.string,
  iconOnClick: PropTypes.func,
  iconComponent: PropTypes.node,
  inputWrapperClass: PropTypes.string,
  disabled: PropTypes.bool,
  prependedNode: PropTypes.node,
  showLoader: PropTypes.bool,
};

// eslint-disable-next-line react/display-name
const InputFieldV2 = forwardRef(
  (
    {
      size = FORM_SIZE.medium,
      containerClass = '',
      label,
      labelComponent,
      dangerouslySetLabel,
      error,
      id,
      errorClassName,
      ...restProps
    },
    inputRef,
  ) => {
    // set id for <input />
    const inputId = useRef(id || Counter.uniqueId('InputFieldV2-'));

    return (
      <div className={cn(styles.inputFieldV2, containerClass)}>
        <Label
          size={size}
          label={label}
          labelComponent={labelComponent}
          dangerouslySetLabel={dangerouslySetLabel}
          inputId={inputId.current}
        />
        <Input
          size={size}
          error={error}
          inputId={inputId.current}
          {...restProps}
          inputRef={inputRef}
        />
        <ErrorMsg error={error} className={errorClassName} />
      </div>
    );
  },
);

InputFieldV2.propTypes = {
  inputRef: PropTypes.object,
  id: PropTypes.string,
  size: PropTypes.string,
  containerClass: PropTypes.string,
  label: PropTypes.string,
  labelComponent: PropTypes.node,
  dangerouslySetLabel: PropTypes.string,
  error: PropTypes.string,
  errorClassName: PropTypes.string,
};

export default InputFieldV2;

/*
  InputFieldV2

  Component to render inputs for forms.

  Props:

  containerClass - A class to apply to the overall component.
  size - The size theme for the input. 'medium' is the default.
  onChange - Function to call when the input changes.
  value - Value of the input.
  label - A label of type string to render.
  labelComponent - A custom component to render for the label.
  dangerouslySetLabel - A string to dangerously set its inner html.
  error - The message to display for the input.
  placeholder - A placeholder.
  mask - A mask to apply for the input. See the utils for more.
  guide - Set to show placeholder characters and non-placeholder mask characters.
  iconName - Icon to render in the input.
  iconClass - A class to apply to the icon.
  iconOnClick - Function to call when the icon is clicked.
  iconComponent - A custom component to use as the icon. Please see
                  PostfixIcon.js for its usage.

  Remaining props will be used for the input tag.

  --------

  Usage:

  import InputFieldV2 from 'HTKit/Forms/InputFieldV2';
  import { FORM_SIZE } from 'HTKit/Forms/constants';

  ...

  const [inputValue, setInputValue] = useState();

  const onChange = (e) => {
    setInputValue(e.target.value);
  }

  return (
    <InputFieldV2
      label="A happy label"
      size={FORM_SIZE.large}
      value={inputValue}
      onChange=(onChange)
    />
  );
*/
