import React from 'react';
import PropTypes from 'prop-types';

export const UnderlinedInlineText = ({ text }) => <span className="underline">{text}</span>;
export const SupportEmailLink = () => (
  <a href="mailto:support@hellotech.com">support@hellotech.com</a>
);
export const UnformattedTextLink = ({ link }) => <a href={link}>{link}</a>;

UnderlinedInlineText.propTypes = { text: PropTypes.string };
UnformattedTextLink.propTypes = { link: PropTypes.string };
