// Libraries
import { connect } from 'react-redux';
import { compose } from 'redux';
import SummaryPage from './SummaryPage';
// Selectors
import pageSelectors from './selectors';

export default compose(
  connect(
    pageSelectors,
    null,
  )
)(SummaryPage);
