import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobileOrTabletSelector } from 'src/selectors/resolution';
import { isMobileOrTablet } from 'src/utils/ui';

const mapStateToProps = (state) => ({
  isMobileOrTablet: isMobileOrTabletSelector(state),
});

const withResolution = (Component) =>
  connect(mapStateToProps, null, null, { forwardRef: true })(
    class extends React.Component {
      // eslint-disable-line react/prefer-stateless-function
      static displayName = `Guard(${Component.name})`;

      static propTypes = {
        isGridMobileOrTablet: PropTypes.bool,
      };

      component = React.createRef();

      render() {
        return (
          <Component
            {...this.props}
            isGridMobileOrTablet={isMobileOrTablet()}
            ref={this.component}
          />
        );
      }
    },
  );

export default withResolution;
