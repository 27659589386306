import { SUBMIT_DEVICE,
        DEVICE_SUBMITTED,
        UPDATE_WARRANTY,
        WARRANTY_UPDATED,
        UPDATE_DEVICE,
        DEVICE_UPDATED,
        LOAD_MODELS, 
        MODELS_LOADED,
        DELETE_DEVICE,
        DEVICE_DELETED
      } from './constants';

export function loadModels({ categoryId, makeId }) {
  return {
    type: LOAD_MODELS,
    payload: { categoryId, makeId },
  }
}

export function modelsLoaded({ models }) {
  return {
    type: MODELS_LOADED,
    payload: { models },
  }
}

export function submitDevice(form) {
  return {
    type: SUBMIT_DEVICE,
    payload: form,
  }
}

export function deviceSubmitted(status) {
  return {
    type: DEVICE_SUBMITTED,
    payload: status
  }
}

export function updateDevice(form, clientProductId) {
  return {
    type: UPDATE_DEVICE,
    payload: form, clientProductId,
  }
}

export function deviceUpdated(status) {
  return {
    type: DEVICE_UPDATED,
    payload: status
  }
}

export function updateWarranty(purchaseLocationId, deviceId, datePurchased) {
  return {
    type: UPDATE_WARRANTY,
    payload: { purchaseLocationId, deviceId, datePurchased },
  }
}

export function warrantyUpdated(status) {
  return {
    type: WARRANTY_UPDATED,
    payload: status
  }
}

export function deleteDevice(id) {
  return {
    type: DELETE_DEVICE,
    payload: id,
  }
}

export function deviceDeleted(id) {
  return {
    type: DEVICE_DELETED,
    payload: { id }
  }
}
