import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import InputFieldV2 from '../InputFieldV2/InputFieldV2';

/**
 * EmailFieldWithValidation Component
 *
 * This component is specifically designed for email address inputs, integrating validation and soft warning functionality.
 * It is optimized for use with the `useExternalEmailCheck` hook, which provides validation logic and soft warnings.
 *
 * When a `softWarning` is provided and no other errors are present, the component displays a clickable suggestion for a
 * potentially correct email. Clicking this suggestion invokes the `useSuggestion` function from `useExternalEmailCheck`,
 * which updates the email field with the suggested value.
 *
 * The component is also designed to work seamlessly with Formik for form state management, particularly handling values
 * and errors related to the email field.
 *
 * Props:
 * - `error`: A string representing the validation error message for the email field. This can come from Formik's
 *   validation or custom validation logic.
 * - `softWarning`: A string containing a suggested email address, typically provided by an external validation service
 *   via `useExternalEmailCheck`.
 * - `useSuggestion`: A function to apply the suggested email to the input field, provided by `useExternalEmailCheck`.
 * - Additional props (such as `name`, `placeholder`, `onChange`, `value`) are spread to the underlying `InputFieldV2`
 *   component, aligning with standard input field behavior.
 *
 * Example Usage:
 * <EmailFieldWithValidation
 *   name="email"
 *   placeholder="Enter your email address"
 *   onChange={handleOnChange} // Function from `useExternalEmailCheck`
 *   value={formik.values.email} // Value managed by Formik
 *   error={formik.errors.email || customError} // Error from Formik or custom logic
 *   softWarning={emailValidationState?.did_you_mean} // Suggested email from `useExternalEmailCheck`
 *   useSuggestion={useSuggestion} // Function from `useExternalEmailCheck`
 * />
 */

export const EmailFieldWithValidation = forwardRef(
  ({ error, softWarning, useSuggestion = () => {}, ...props }, ref) => {
    return (
      <div>
        <InputFieldV2 ref={ref} error={error} {...props} />
        {softWarning && !error && (
          <button className="plainButton n700 caption" onClick={useSuggestion} type="button">
            <>
              Did you mean: <span className="underline">{softWarning}</span>?
            </>
          </button>
        )}
      </div>
    );
  },
);

EmailFieldWithValidation.displayName = 'EmailFieldWithValidation';

EmailFieldWithValidation.propTypes = {
  error: PropTypes.string,
  softWarning: PropTypes.string,
  containerClass: PropTypes.string,
  useSuggestion: PropTypes.func,
};
