import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ModalV2 as Modal } from 'src/components/HTKit/Modals/ModalV2';
import Icon from 'src/components/HTKit/Icon';
import styles from './styles.scss';

const ProductInstallModal = ({value, bullets, ...props}) => {

  return (
    <Modal {...props}>
      <p className="p1 paddingBottom-small2">
        Bundle this device with installation &amp; setup for only {value}. Just book an appointment
        during checkout, and one of our skilled technicians will take care of the rest!
      </p>
      {bullets.map((b, index) => (
        <div
          key={index}
          className={cn('paddingLeft-small paddingBottom-small', styles.productItem)}
        >
          <Icon name="checkmark-v2" className="n700" />
          <span className="p1 n900">{b.description}</span>
        </div>
      ))}
    </Modal>
  );
};

ProductInstallModal.propTypes = {
  bullets: PropTypes.array,
  value: PropTypes.string,
};

export default ProductInstallModal
