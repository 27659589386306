import debounce from 'lodash/debounce';
import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Uitls
import { logger } from 'src/utils/logger';
// Hooks
import { useSplitIOTreatment, splitioConstants } from 'src/components/SplitIO';
import useAPI from 'src/hooks/useAPI';
import { useValidateEmailExternal } from 'src/hooks/useValidateEmailExternal';
import { useSendAuthCode } from 'src/containers/LoginPage/Modal/LoginPasscode/LoginPasscode.hooks';
// Selectors
import { pureUserSelector } from 'src/selectors/user';
import { pureCartSelector } from 'src/selectors/cart';
// Actions
import {
  setUserInfo,
  clearUserInfo,
  openPasscodeModal,
} from 'src/containers/LoginPage/Modal/actions';
import { startBooking } from 'src/containers/CartPage/actions';
import { createPasswordlessAccount } from '../actions';
import { updateProfile } from 'src/containers/Account/AccountPage/actions';
import { displayErrors } from 'src/utils/request';
// Constants
import { AUTO_SUBMIT_DEBOUNCE_TIMEOUT_MS } from 'src/containers/LoginPage/Modal/constants';

/**
 * Manages account verification and profile updates for passwordless flow.
 */
export const useAccountVerificationAndUpdate = () => {
  const dispatch = useDispatch();
  const api = useAPI();
  const sendAuthCode = useSendAuthCode();
  const cart = useSelector(pureCartSelector);

  const checkAccount = useCallback(
    async (email) => {
      try {
        api.toggleLoader(true);
        const response = await api.users.checkAccount({ email });
        if (response.err) {
          const is404 = response.err.response.status === 404;
          if (is404) {
            // We need to distinguish between a 404 and a network error.
            // 404 is user not found, so user should continue filling out the form and create an account.
            // TODO: What happens when they try to create an account when one already exists?
            return;
          }
          throw new Error(response.err);
        }

        // User found
        const { phone_last_four = '', user_initials = '' } = response.data;
        dispatch(
          setUserInfo({
            phoneLastFour: phone_last_four,
            userInitials: user_initials,
            email,
          }),
        );
        dispatch(openPasscodeModal({ isPasswordlessCheckout: true }));
        await sendAuthCode({ email });
      } catch (error) {
        logger('VerificationFormPasswordless')(error);
      } finally {
        api.toggleLoader(false);
      }
    },
    [dispatch],
  );

  const handleUpdateProfile = useCallback(
    (values) => {
      dispatch(
        updateProfile(values, null, {
          onSuccess: () =>
            dispatch(startBooking(cart, { stage: cart.status, isPasswordless: true })),
        }),
      );
    },
    [dispatch],
  );

  /** Debounced version of checkAccount used by the auto-submit email input */
  const debouncedCheckAccount = useCallback(
    debounce(checkAccount, AUTO_SUBMIT_DEBOUNCE_TIMEOUT_MS),
    [checkAccount],
  );

  return {
    /** For unauthorized users check if they already have an account */
    debouncedCheckAccount,
    /** Update user profile */
    handleUpdateProfile,
  };
};

/**
 * Cleans up user info and email field when passcode modal closes without successful authentication.
 * If a user is logged in, no cleanup occurs as they've already passed the auth challenge.
 */
export const usePasscodeModalCleanup = ({ formik }) => {
  const dispatch = useDispatch();

  const user = useSelector(pureUserSelector);
  const isPasscodeModalOpen = useSelector((state) =>
    state.getIn(['components', 'loginModal', 'isPasscodeModalOpen']),
  );

  useEffect(() => {
    const shouldClearEmail = !isPasscodeModalOpen && !user;
    if (shouldClearEmail) {
      dispatch(clearUserInfo());
      formik.setFieldValue('email', '');
    }
  }, [isPasscodeModalOpen, dispatch, formik.setFieldValue]);
};

/** Handles creation of passwordless accounts */
export const useCreateAccount = () => {
  const dispatch = useDispatch();

  const [emailValidationError, setEmailValidationError] = useState(null);

  const { validateEmail, emailValidationState } = useValidateEmailExternal();

  const { splitTreatment: emailValidatorTreatment } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_USER_EMAIL_VALIDATOR,
  );
  const isEmailValidatorSplitOn = emailValidatorTreatment === splitioConstants.ON;

  const dispatchCreateAction = (values) => {
    dispatch(
      createPasswordlessAccount({
        user: {
          ...values,
        },
      }),
    );
  };

  /**
   * Creates accounts for passwordless users.
   *
   * Integrates a 3rd party email validation service when enabled. The service checks the validity
   * of the email and suggests an alternative if it's invalid. Users are not allowed to submit
   * until they provide a valid email.
   */
  const handleCreateAccount = async (values) => {
    setEmailValidationError(null);

    if (!isEmailValidatorSplitOn) {
      dispatchCreateAction(values);
      return;
    }

    try {
      const validationResult = await validateEmail(values.email);

      if (validationResult.err) {
        throw new Error(validationResult.err);
      }

      if (!validationResult.valid) {
        if (!validationResult.did_you_mean) {
          setEmailValidationError('Invalid email address');
        }
        // If there's a suggestion (did_you_mean), we don't set an error
        // as the suggestion will be displayed differently as emailSuggestion
        return;
      }

      // Email is valid, proceed with account creation
      dispatchCreateAction(values);
    } catch (error) {
      logger('handleCreateAccount')(error);
      dispatch(displayErrors({ data: { errors: ['An error occurred. Please try again later.'] } }));
    }
  };

  return {
    handleCreateAccount,
    /**
     * Error message when email is invalid and no suggestion is available.
     * This is set when the validation service deems the email invalid but doesn't provide an alternative.
     * Note: For network errors, displayErrors() is called instead.
     */
    emailValidationError,
    /** Suggested email address when 3rd party validation service says email is invalid */
    emailSuggestion: emailValidationState.did_you_mean,
  };
};
