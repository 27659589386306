import { formatDollarAmount } from 'src/utils/formatter/currency';

export const getCartItemCount = (cart) => {
  if (!cart) return 0;
  let totalCount = 0;

  const itemsCount = cart.get('items').size;
  totalCount += itemsCount;

  if (cart.getIn(['plan', 'id'])) totalCount += 1;

  return totalCount;
};

export const isPlanOnlyCart = (cart) => {
  if (!cart) return false;
  return !!cart.get('plan') && cart.get('items').size === 0;
};

/**
 * The new version of fees returns a string of text to render (adjustment.explanation). The legacy version does not and was hardcoded.
 * on the FE. Since the new version is governed by a split we need to continue to provide a hardcoded string for legacy
 * @param {string} name
 */
export const getLegacyFeeExplanation = (name) => {
  if (name.match(/Safety & Support/i))
    return 'The safety & support fee will be applied to all in-home orders. This fee is invested into crucial tools, training and staff which sustain our platform.';
  if (name.match(/Platform/i))
    return 'This platform fee is charged to cover tools, staff, insurance, claims, and other associated costs with running the platform.';
  return '';
};

export const addExplanationToAdjustment = (adjustments = []) => {
  if (!adjustments.length) return adjustments;
  return adjustments.map((a) => ({
    ...a,
    explanation: a.explanation || getLegacyFeeExplanation(a.name),
  }));
};

export const getTotalCreditAdjustments = (cartJS = null) => {
  if (cartJS?.breakdown) {
    const { adjustments, subscriptionAdjustments } = cartJS.breakdown;
    const creditGlobalAdjustments = adjustments.filter((adj) => adj.type === 'credit');
    const subscriptionCreditAdjustments = subscriptionAdjustments.filter(
      (adj) => adj.type === 'credit',
    );
    const totalCredit = [...creditGlobalAdjustments, ...subscriptionCreditAdjustments].reduce(
      (acc, adj) => acc + adj.amount,
      0,
    );

    return {
      amount: totalCredit,
      amountFormatted: formatDollarAmount({ amount: totalCredit, truncateEvenDollars: true }),
      amountFormattedAbs: formatDollarAmount({
        amount: Math.abs(totalCredit),
        truncateEvenDollars: true,
      }),
    };
  }
  return {
    amount: 0,
    amountFormatted: '$0',
    amountFormattedAbs: '$0',
  };
};
