export const HEAR_ABOUT_US_OPTS = [
  { value: 'Banner Ad', label: 'Banner Ad' },
  { value: 'Blog Article', label: 'Blog Article' },
  { value: 'Card in Product Packaging', label: 'Card in Product Packaging' },
  { value: 'Email/Newsletter', label: 'Email/Newsletter' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Flyer/Door Hanger', label: 'Flyer/Door Hanger' },
  { value: 'Friend', label: 'Friend' },
  { value: 'In the News', label: 'In the News' },
  { value: 'Instagram Ad', label: 'Instagram Ad' },
  { value: 'Radio Ad', label: 'Radio Ad' },
  { value: 'TV Ad', label: 'TV Ad' },
  { value: 'Web Search', label: 'Web Search (Google, Yahoo, Bing)' },
  { value: 'Yelp', label: 'Yelp' },
  { value: 'Xfinity referral', label: 'Xfinity referral' },
  { value: 'Other', label: 'Other' },
];

export const GROUPON_HEAR_ABOUT_US_OPTS = [
  { value: 'Groupon Website', label: 'Groupon Website' },
  { value: 'Groupon Email', label: 'Groupon Email' },
];

export const SUBSCRIPTION_TYPES = {
  DEFAULT: 0,
  NEWSLETTER: 1,
  FIRST_SERVICE_DISCOUNT: 2,
};

// These are BE values of the user model
export const USER_TYPES = {
  Client: 'Client',
  Tech: 'Geek',
  Admin: 'Admin',
  Partner: 'Partner',
  Recruit: 'Recruits::Recruit',
  FledglingGeek: 'FledglingGeek',
};
