// Libaries
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Components
import withToggleSiteNav from 'src/hoc/withToggleSiteNav';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import BBB from 'src/components/BBB';
// Styles
import styles from './styles.scss';

const HeroSectionAlticeGeneral = ({ details, toggleSiteNav }) => {
  const toggleSiteNavAction = (event) => {
    event.preventDefault();
    if (event.nativeEvent) event.nativeEvent.stopImmediatePropagation();
    toggleSiteNav();
  };

  const className = cn(styles.container, 'site-v2 l-content-section');
  return (
    <section className={className}>
      <h1 className={cn(styles.header, 'blue500 uppercase')}>{details.title}</h1>
      {/* eslint-disable */}
      <h3 className={styles.subHeader} dangerouslySetInnerHTML={{ __html: details.subtitle }} />
      {/* eslint-enable */}
      <div className={styles.ctaButton}>
        <Button hugeV2 theme={THEMES.V2PRIMARY} onClick={toggleSiteNavAction}>
          LET&apos;S GET STARTED
        </Button>
      </div>
      <div className={styles.BBBWrapper}>
        <BBB showTagLineMobile={false} showTagLineTablet={false} />
      </div>
    </section>
  );
};

HeroSectionAlticeGeneral.propTypes = {
  details: PropTypes.object.isRequired,
  toggleSiteNav: PropTypes.func.isRequired,
};

export default withToggleSiteNav()(HeroSectionAlticeGeneral);

/*
  HeroSectionAlticeGeneral:
    Renders a hero image/component used in Altice's landing pages.

  Last modified: -GH May 10, 2017
*/
