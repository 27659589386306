import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { ModalCloseButton } from 'src/components/HTKit/Modals/Parts';
import Icon from 'HTKit/Icon';
import { HEADER_TYPES } from '../constants';
import styles from './styles.scss';

/**
  We have a BookingHeader component, but it's hooked into Redux and has behavior based on Redux values. Here we don't need
  any behavior, just a visual duplicate.
*/
const BookingHeader = () => {
  return (
    <div className={styles.bookingHeader}>
      <Icon name="ht-logo" />
    </div>
  );
};

const ModalHeader = ({
  showLogo,
  buttonStyle,
  headerStyles,
  headerType = HEADER_TYPES.DEFAULT,
  handleClose,
}) => {
  const handleCloseFn = (event) => {
    event.preventDefault();
    handleClose();
  };

  const cx = classNames.bind(styles);

  const closeClasses = cx({
    close: true,
    [buttonStyle]: !!buttonStyle,
  });

  const headerStyle = cx({
    header: true,
    [headerStyles]: true,
  });

  if (headerType === HEADER_TYPES.BOOKING) return <BookingHeader />;

  return (
    <div className={headerStyle}>
      {showLogo && (
        <div className={`flex alignItemsCenter justifyContentCenter ${styles.logoOuterWrapper}`}>
          <Icon name="logo" className={styles.logo} />
        </div>
      )}
      <ModalCloseButton onClick={handleCloseFn} buttonStyle={closeClasses} />
    </div>
  );
};

ModalHeader.propTypes = {
  handleClose: PropTypes.func.isRequired,
  showLogo: PropTypes.bool,
  buttonStyle: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  headerStyles: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  headerType: PropTypes.oneOf(Object.values(HEADER_TYPES)),
};

ModalHeader.defaultProps = {
  showLogo: true,
};

export default ModalHeader;
