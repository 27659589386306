import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ServiceImage from 'src/components/OrderSummary/Breakdown/ServiceImage';
import { isMobileV2, isTabletV2 } from 'src/utils/ui';
import ChevronIcon from '../ChevronIcon';
import styles from './styles.scss';

const isMobileOrTablet = isMobileV2() || isTabletV2();

/**
  Row

  A simple component to wrap the NameColumn and PriceColumn components.
*/

export const Row = ({ children, rowStyle }) => {
  const rowStyles = classNames(styles.row, rowStyle);
  return <div className={rowStyles}>{children}</div>;
};

Row.defaultProps = {
  rowStyle: '',
};

Row.propTypes = {
  children: PropTypes.any.isRequired,
  rowStyle: PropTypes.string,
};

/**
  NameColumn

  The left most column in a Row. This will generally hold the name of
  skus and adjustments.
*/

export const NameColumn = ({
  children,
  nameColumnStyle,
  item,
  open,
  handleToggle,
  showChevron,
  persistentChevron,
  image,
}) => {
  const iconWrapperStyles = image && !isMobileOrTablet ? styles.imageWrapper : styles.iconWrapper;
  const columnStyles = classNames(styles.nameColumn, nameColumnStyle);
  return (
    <div className={columnStyles}>
      <div className={iconWrapperStyles}>
        {!isMobileOrTablet && image && <ServiceImage image={image} quantity={'quantity'} />}
        {isMobileOrTablet && showChevron && (
          <ChevronIcon
            item={item}
            open={open}
            handleToggle={handleToggle}
            persistent={persistentChevron}
          />
        )}
      </div>
      {children}
    </div>
  );
};

NameColumn.defaultProps = {
  nameColumnStyle: '',
  showChevron: false,
};

NameColumn.propTypes = {
  children: PropTypes.any.isRequired,
  nameColumnStyle: PropTypes.string,
  showChevron: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  persistentChevron: PropTypes.bool,
  image: PropTypes.string,
};

/**
  PriceColumn

  The column is used to place next to the NameColumn.
  This component will hold the prices of skus and adjustments.
*/

export const PriceColumn = ({ children, priceColumnStyle }) => {
  const columnStyles = classNames(styles.priceColumn, priceColumnStyle);

  return <div className={columnStyles}>{children}</div>;
};

PriceColumn.defaultProps = {
  priceColumnStyle: '',
};

PriceColumn.propTypes = {
  children: PropTypes.any.isRequired,
  priceColumnStyle: PropTypes.string,
};
