import React from 'react';
import PropTypes from 'prop-types';
import { ModalV2, SIZES } from 'src/components/HTKit/Modals/ModalV2';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';

const CTAButton = ({ text, onClick, theme }) => (
  <Button theme={theme} onClick={onClick} type="button">
    {text}
  </Button>
);

CTAButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.string,
};

// const RecommendedDatesModal = ({ visible, onCancel, submit, selectedDateTimes }) => {
const RecommendedDatesModal = ({
  visible,
  onCancel,
  submit,
  selectedDateTimes,
  numTimesRequired = 3,
}) => {
  const onSubmit = () => {
    submit();
    onCancel();
  };
  const modalText =
    selectedDateTimes.length >= numTimesRequired
      ? `If you already selected ${numTimesRequired} times, some of those times are no longer available.`
      : `We suggest selecting at least ${numTimesRequired} possible appointment start times for faster confirmation.`;

  return (
    <ModalV2
      isVisible={visible}
      hide={onCancel}
      header={`Choose at least ${numTimesRequired} times`}
      footerElement2={
        <CTAButton theme={THEMES.V2SECONDARY} text="Continue Anyway" onClick={onSubmit} />
      }
      footerElement3={
        <CTAButton theme={THEMES.V2PRIMARY} text="Add More Times" onClick={onCancel} />
      }
      size={SIZES.MEDIUM}
    >
      <p className="p0 n900">{modalText}</p>
    </ModalV2>
  );
};

RecommendedDatesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  selectedDateTimes: PropTypes.array,
  numTimesRequired: PropTypes.number,
};

RecommendedDatesModal.defaultProps = {
  selectedDateTimes: [],
};

export default RecommendedDatesModal;
