import { createSelector } from 'reselect';
import { LANDING_TEMPLATES as LT } from 'src/constants/landings';

const ALTICE_TEMPLATES = [
  LT.OPTIMUM_GENERAL,
  LT.OPTIMUM_MOVERS,
  LT.OPTIMUM_SUPPORT,
  LT.SUDDENLINK_GENERAL,
  LT.SUDDENLINK_MOVERS,
  LT.SUDDENLINK_SUPPORT,
];

/**
  CURRENT LANDING
*/
const currentLandingStateSelector = (state) => state.getIn(['entities', 'currentLanding'], null);
export const currentLandingSelector = createSelector(
  currentLandingStateSelector,
  (currentLanding) => currentLanding,
);

/*
  Per Altice's contract, we are not allowed to show network related skus (Wifi & Network category) and plans
*/
export const isAlticeLandingSelector = createSelector(
  currentLandingSelector,
  (currentLanding) => {
    if (currentLanding) {
      const template = currentLanding.get('template');
      return ALTICE_TEMPLATES.includes(template);
    }
    return false;
  },
);

/**
  LANDINGS
*/
const landingStateSelector = (state) => state.getIn(['entities', 'landings'], null);

export const landingSelector = createSelector(
  landingStateSelector,
  (landings) => landings,
);
