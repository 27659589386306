import { fromJS } from 'immutable';
import { UPDATE_CART } from 'src/containers/AddSkuPage/constants';
import { SET_ADDRESS } from 'src/containers/BookingPage/AddressPage/constants';
import { UPDATE_CART_PREVIEW } from 'src/containers/AppBase/constants';
import { PAGE_LOADED, SET_ZIP_CODE_RESTRICTION } from 'src/constants/common';
import { PAGE_NAME as AVAILABILITY_PAGE } from 'src/containers/BookingPage/AvailabilityPage/constants';

export default function cartReducer(state = fromJS({}), action) {
  switch (action.type) {
    case UPDATE_CART:
      if (action.replace) {
        return fromJS(action.cart);
      }
      return (state || fromJS({})).merge(action.cart);
    case UPDATE_CART_PREVIEW:
      return (state || fromJS({})).merge(action.cart);
    case PAGE_LOADED:
      switch (action.page) {
        case 'booking':
        case 'cart':
        case 'availability':
        case 'finishPartnerOrder':
        case 'summary': {
          return state.merge(action.cart);
        }
        default:
          return state;
      }
    case SET_ADDRESS: {
      if (action.name === 'phone') {
        return state.setIn([action.name], action.value);
      }
      if (action.name === 'phoneType') {
        return state.setIn([action.name], action.value);
      }
      return state.setIn(['address', action.name], action.value);
    }
    case SET_ZIP_CODE_RESTRICTION: {
      if (action.page !== AVAILABILITY_PAGE) return state;
      const { isZipRestricted, orderableFromDate } = action;
      return state.merge({ isZipRestricted, orderableFromDate });
    }
    default:
      return state;
  }
}
