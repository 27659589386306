import { call, put, takeEvery } from 'redux-saga/effects';
import { LOAD_PAGE } from 'src/constants/common';
import { pageLoaded } from 'src/actions/common';
import { push, accountOrderPublicPath } from 'src/utils/paths';
import { selectRoutes } from 'src/apiRoutes';
import { requestStarted, requestFinished, displayErrorsWithSnack } from 'src/utils/request';
import { SUBMIT_FORM, PAGE_NAME } from './constants';

export function* pageSaga() {
  yield put(pageLoaded(PAGE_NAME));
}

export function* submitFormSaga({ form }) {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.orders.public.getOrderToken, { ...form });
  yield put(requestFinished());
  if (response.data.errors) {
    yield put(displayErrorsWithSnack(response));
  } else {
    yield put(push(accountOrderPublicPath(response.data.token)));
  }
}

export function* getOrderStatusFlow() {
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
  yield takeEvery(SUBMIT_FORM, submitFormSaga);
}

export default [getOrderStatusFlow];
