import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '../styles.scss';

const LandingSkuHeroImage = ({ alt, src }) => (
  <img alt={alt} src={src} className={styles.heroImg} />
);

LandingSkuHeroImage.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
};

export default class ProductSingleSkuImage extends Component {
  static propTypes = {
    landing: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onImageClick = (url) => {
    this.props.onClick(url);
  }

  render() {
    const { landing, isMobile } = this.props;
    const heroImgSrc = isMobile ? landing.getIn(['images', 'front', 'mobile']) : landing.getIn(['images', 'front', 'original']);
    const heroImageLink = landing.get('heroImageLink');
    const alt = landing.get('pageTitle');
    return heroImageLink ? (
      <div>
        <a href={heroImageLink} onClick={() => this.onImageClick(heroImageLink)}>
          <LandingSkuHeroImage alt={alt} src={heroImgSrc} />
        </a>
      </div>
    ) : (
      <div>
        <LandingSkuHeroImage alt={alt} src={heroImgSrc} />
      </div>
    );
  }
}
