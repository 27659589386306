import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import AddAnotherServiceBlock from 'src/containers/CartPage/Parts/AddAnotherServiceBlock';
import { PromoCode, ServicesBreakdown } from 'src/containers/CartPage/Parts';
import { BUTTON_CTAS } from 'src/containers/CartPage/constants';
import HourlyPriceTypeDisclaimer from 'src/components/Cart/Disclaimers/HourlyPriceDisclaimer';
import { atLeastOnePriceTypeHourlySelector } from 'src/selectors/cart';
import BenefitsBreakdown, { NewBenefits } from './BenefitsBreakdown';
import styles from './styles.scss';

const UpsellTab = ({
  page,
  showStandardTab,
  removeItem,
  goToAnotherService,
  goToEditSku,
  addPlanAndStartBooking,
  plansInfo,
  canAddAnotherService,
  goToProductsShop,
}) => {
  const showHourlyDisclaimer = useSelector(atLeastOnePriceTypeHourlySelector);
  const estimatedCart = page.get('estimatedCart');
  const planDetails = page.get('planDetails');
  const isPartner = Boolean(estimatedCart.get('partner'));
  const containerStyles = classNames(styles.container, {
    [styles.hide]: showStandardTab,
  });
  const upsellPlanForBenefits = plansInfo.monthly;
  const addAnotherServiceStyles = classNames(
    'paddingTop-small paddingBottom-medium showDesktop',
    styles.lineBreak,
  );
  return (
    <section className={containerStyles}>
      <ServicesBreakdown
        cart={estimatedCart}
        removeItem={removeItem}
        goToEditSku={goToEditSku}
        showPlanItem={false}
        plansInfo={plansInfo}
        isUpsellCart
      />
      {/* Desktop only block */}
      <AddAnotherServiceBlock
        onServiceClick={goToAnotherService}
        onProductClick={goToProductsShop}
        canAddAnotherService={canAddAnotherService}
        isPartner={isPartner}
        classes={addAnotherServiceStyles}
      />
      <span className="showDesktop">
        <NewBenefits plansInfo={upsellPlanForBenefits} />
      </span>

      {/* Mobile/Tablet only block */}
      <div className="showMobileTablet">
        <Button onClick={goToAnotherService} mediumV2 theme={THEMES.V2SECONDARY}>
          {BUTTON_CTAS.KEEP_SHOPPING}
        </Button>
        <Button
          onClick={addPlanAndStartBooking}
          mediumV2
          theme={THEMES.V2PRIMARY}
          className={styles.buttonSpacer}
        >
          Join &amp; Checkout
        </Button>
        <HourlyPriceTypeDisclaimer showHourlyDisclaimer={showHourlyDisclaimer} />
        <PromoCode wrapperStyle={styles.promoSpacer} copySize="p2" />
      </div>
      <span className="showMobileTablet">
        <BenefitsBreakdown planDetails={planDetails} plansInfo={plansInfo} />
      </span>
    </section>
  );
};

UpsellTab.propTypes = {
  page: PropTypes.object,
  plansInfo: PropTypes.object,
  showStandardTab: PropTypes.bool.isRequired,
  removeItem: PropTypes.func.isRequired,
  goToAnotherService: PropTypes.func.isRequired,
  goToProductsShop: PropTypes.func.isRequired,
  goToEditSku: PropTypes.func.isRequired,
  addPlanAndStartBooking: PropTypes.func.isRequired,
  canAddAnotherService: PropTypes.bool,
};

export default UpsellTab;
