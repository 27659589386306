import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bannerImage from 'src/images/orange-triangle.png';
import styles from './styles.scss';

const TriangleDiscountBanner = (props) => {
  const { value, type, info, name } = props;
  const discountInfo = info || `For All ${name} Members`;
  const sectionStyles = cn(styles.container, 'l-content-section');

  return (
    <div className={sectionStyles}>
      <div className={styles.discount}>
        <div className={styles.discountAngle}>
          <img src={bannerImage} alt="Discount" />
          <div className={styles.discountAngleInfo}>
            <div className={styles.discountPercentage}>
              <div className={styles.discountPercentageValue}>{value}</div>
              <div className={styles.discountPercentageInfo}>
                <div>{type}</div>
                <div>off</div>
              </div>
            </div>
            <div className={styles.discountInfo}>{discountInfo}</div>
          </div>
        </div>
      </div>

      <div className={styles.discountBanner}>
        <div className={styles.promo}>
          {value} {type} OFF {discountInfo}
        </div>
      </div>
    </div>
  );
};

TriangleDiscountBanner.propTypes = {
  value: PropTypes.any,
  type: PropTypes.string,
  info: PropTypes.string,
  name: PropTypes.string,
};

TriangleDiscountBanner.defaultProps = {
  value: '',
  type: '',
  name: '',
  info: '',
};

export default TriangleDiscountBanner;
