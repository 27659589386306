export const ORDER_ADJUSTMENT_TYPES = {
  COUPON: 'coupon',
  SUPPORT_FEE: 'support_fee',
  CREDIT: 'credit',
};

export const COUPON_ADJUSTMENT_TYPES = {
  STANDARD: 'standard',
  SUBSCRIPTION: 'subscription',
};
