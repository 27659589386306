import { call, put, takeEvery } from 'redux-saga/effects'; // eslint-disable-line
import { LOAD_PAGE } from 'src/constants/common';
import { selectRoutes } from 'src/apiRoutes';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import { pageLoaded } from 'src/actions/common';
import { PAGE_NAME } from './constants';

const formatSkuIds = (ids) => `&sku_ids[]=${ids.join('&sku_ids[]=')}`;

export function* pageSaga({ partner, skuIds }) {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const totalCompletedResponse = yield call(routes.skus.totalCompletedServices, {
    sku_ids: formatSkuIds(skuIds),
  });

  if (totalCompletedResponse.err) {
    yield put(displayErrors(totalCompletedResponse));
  } else {
    const totalCompletedServices = totalCompletedResponse.data.stats.total;
    yield put(pageLoaded(PAGE_NAME, { partner, totalCompletedServices }));
  }
  yield put(requestFinished());
}

export function* landingPageTestFlow() {
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
}

export default [landingPageTestFlow];
