import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'HTKit/Icon';
import PhoneDisplayLink, { ICON_ORIENTATION } from 'src/components/PhoneDisplayLink';
import styles from './styles.scss';

const PhoneIcon = () => <Icon name="phone-outlined" className={styles.phoneIcon} />;

const PhoneFooter = ({ className }) => {
  const phoneFooterStyles = (className, styles.phoneFooter);
  const linkStyles = cn('-no-decoration', styles.phoneDisplay);

  return (
    <div className={phoneFooterStyles}>
      <p className="p0 n700">
        <PhoneDisplayLink
          displayNumber
          icon={<PhoneIcon />}
          linkStyle={linkStyles}
          iconOrientation={ICON_ORIENTATION.left}
          splitCaller={true}
        />
      </p>
    </div>
  );
};

PhoneFooter.propTypes = {
  className: PropTypes.string.isRequired,
};

export default PhoneFooter;
