import { fromJS } from 'immutable';
import { PAGE_LOADED } from 'src/constants/common';
import { DEVICE_DELETED } from 'src/containers/Account/DevicesPage/constants'

const initialState = fromJS({clientProducts: {}});

export default function devicesReducer(state = initialState, action) {
  switch (action.type) {
    case PAGE_LOADED: {
      switch (action.page) {
        case 'accountDevices': {
          const { clientProducts, categories, purchaseLocations } = action;
          return fromJS({clientProducts, categories, purchaseLocations})
        }
        default:
          return state;
      }
    }

    case DEVICE_DELETED: {
      const { id } = action.payload;
      const devices = state.get('clientProducts').filter((d) => {
        const device = d.getIn(['client_product','id']) !== id
        return device
        }
      )
      return state.set('clientProducts', devices);
    }
  default:
    return state;
  }
}
