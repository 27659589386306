import React, { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'src/components/HTKit/Icon';
import styles from './styles.scss';

const FaqItem = ({
  title,
  children,
  className,
}) => {
  const [showMore, setShowMore] = useState(false);
  const containerStyles = cn(className, styles.faqWrapper, {
    [styles.closed]: !showMore,
  });
  const contentStyles = cn('p1', styles.faqContent, {
    [styles.closed]: !showMore,
  });

  useEffect(() => {
    setShowMore(showMore);
  }, [showMore]);

  const toggleAccordion = useCallback(() => {
    const shouldShow = !showMore;
    setShowMore(shouldShow);
  }, [showMore]);

  const titleStyles = cn('p0 n900', {
    [styles.openedContent]: showMore,
  });

  const iconName = cn({
    'minus-icon': showMore,
    'v2-plus-icon': !showMore,
  });

  return (
    <section className={containerStyles} onClick={toggleAccordion}>
      <div className="paddingX-small1 paddingY-medium">
        <div className={styles.titleContainer}>
          <div className={titleStyles}>{title}</div>
          <Icon name={iconName} className={styles.icon} />
        </div>
        <div className={contentStyles}>{children}</div>
      </div>
    </section>
  );
};

FaqItem.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default FaqItem;

/*
  Notes:
    This component is very similar to our current Accordion.
    Since there were significant changes in design but not functionality,
    and, since we *may* replace the old one with this, I made the decision
    to reuse the functionality

  -MR July 6, 2021
*/
