import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'src/components/HTKit/Icon';
import { isImmutable } from 'src/utils/helpers';
import { filterDiscountsFromAdjustments } from '../utils';
import styles from './styles.scss';

const ChevronIcon = ({ open, handleToggle, item, persistent }) => {
  let hideBasedOnAdjs = false;
  if (item) {
    const adjustments = isImmutable(item) ? item.get('adjustments') : item.adjustments;
    const filteredAdjs = filterDiscountsFromAdjustments(adjustments);
    hideBasedOnAdjs = filteredAdjs.size === 0;
  }

  const toggleButtonStyles = classNames('plainButton', styles.iconBtn, {
    [styles.hidden]: hideBasedOnAdjs && !persistent,
  });
  const iconStyles = classNames(styles.icon, {
    [styles.open]: open,
  });

  return (
    <button className={toggleButtonStyles} onClick={handleToggle} type="button">
      <Icon name="chevron" className={iconStyles} />
    </button>
  );
};

ChevronIcon.defaultProps = {
  persistent: false,
};

ChevronIcon.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  item: PropTypes.object,
  persistent: PropTypes.bool,
};

export default ChevronIcon;
