import React, { Children, cloneElement, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Grid } from 'src/components/HTKit/Grid';
import Label from 'src/components/HTKit/Forms/Parts/Label';
import getGroupedChildren from '../helpers/getGroupedChildren';
import { FORM_SIZE } from '../constants';

const RadioGroupV2 = ({
  label,
  labelComponent,
  dangerouslySetLabel,
  children,
  value,
  onChange,
  disableSort = false,
  disableGrouping = false,
  enableDeselect = false,
  containerClassName = '',
  columnClassName = '',
  rowClassName = '',
  size = FORM_SIZE.medium,
}) => {
  // We want to pass down extra props to the children (RadioV2)
  const childrenWithExtras = Children.map(children, (child) => {
    return cloneElement(child, {
      selected: value === child.props.value,
      onChange,
      size,
      enableDeselect,
    });
  });

  // Group the children if necessary. See the utils
  const { groupedChildren, columnProps } = getGroupedChildren({
    children: childrenWithExtras,
    disableSort,
    disableGrouping,
  });

  return (
    <div className={cn('site-v2', containerClassName)}>
      <Label
        size={size}
        label={label}
        labelComponent={labelComponent}
        dangerouslySetLabel={dangerouslySetLabel}
      />
      <Grid.FullWidth>
        <Grid.Row classes={rowClassName}>
          {groupedChildren.map((group, i) => (
            <Grid.Column classes={columnClassName} {...columnProps} key={i}>
              {group}
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid.FullWidth>
    </div>
  );
};

RadioGroupV2.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  labelComponent: PropTypes.node,
  dangerouslySetLabel: PropTypes.string,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disableSort: PropTypes.bool,
  disableGrouping: PropTypes.bool,
  enableDeselect: PropTypes.bool,
  containerClassName: PropTypes.string,
  columnClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  size: PropTypes.oneOf([FORM_SIZE.medium, FORM_SIZE.large]),
};

export default memo(RadioGroupV2);

/**
  RadioGroupV2

  This group is used with RadioV2. If the options count exceed a certain limit,
  the options will be displayed in multiple columns instead of a single column. Also,
  the options will be sorted alphabetically when they are broken down into columns.
  Props are provided to override these default settings.

  Note:
  RadioV2 must be a direct child of RadioGroupV2 for this to work, or
  just take extra care if you're using a custom checkbox component.

  Props:
  label - A label of type string to render.
  labelComponent - A custom component to render for the label.
  dangerouslySetLabel - A string to dangerously set its inner html.
  value - Value of the selected radio
  onChange - Function to when selecting. First argument is the selected value.
  disableSort - Disable sorting of list when breaking into multiple columns.
  disableGrouping - Disable breaking into multiple columns. Show options in a single column regardless of options count.
  size - Form size. Affects container label and list spacing

  ---------

  General Usage:

  import { RadioV2, RadioGroupV2 } from 'src/components/HTKit/Forms/RadioV2';

  ...

  const [radio, setRadio] = useState(1);
  const onChange = (selectedValue) => {
    setRadio(selectedValue);
  };

  return (
    <RadioGroupV2
      label="An Optional Label"
      value={radio}
      onChange={onChange}
      containerClassName={styles.container}
    >
      <RadioV2 value={1} label="Number 1" />
      <RadioV2 value={2} label="Number 2" />
      <RadioV2 value={3} label="Number 3" boldLabel />
      <RadioV2 value={4} label="Number 4">
        <p className="caption">Some custom component to show below the label</p>
      </RadioV2>
    </RadioGroupV2>
  );
*/
