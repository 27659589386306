import { fromJS } from 'immutable';
import { PAGE_LOADED } from 'src/constants/common';
import { ADD_PLAN_TO_CART } from 'src/constants/cart';
import {
  ITEM_ADDED,
  ITEM_EDITED,
  CLEAR_ITEM_MESSAGES,
  SHOW_MODAL_MULTIPLE_AUTO_APPLY_COUPONS,
  DISMISS_MODAL_MULTIPLE_AUTO_APPLY_COUPONS,
  SET_RELATED_SKUS,
  TOGGLE_ADD_ANOTHER_SERVICE,
  LOAD_PLAN_DETAILS,
  LOAD_ESTIMATED_CART,
  SHOW_UPSELL_CART_VERSION,
  TOGGLE_REMOVE_PLAN_MODAL,
  TOGGLE_PLAN_HOLDER_MODAL,
  PAGE_NAME,
  API_ITEM_UPDATED,
} from './constants';

const initialState = fromJS({
  showModalMultipleAutoApplyCoupons: false,
  relatedSkus: [],
  anotherServiceAvailable: true,
  planDetails: {},
  estimatedCart: {},
  showItemWasAdded: false,
  showItemWasEdited: false,
  showUpsellCartVersion: false,
  showRemovePlanModal: false,
  showPlanHolderModal: false,
});

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case ITEM_ADDED: {
      return state.merge({ showItemWasAdded: true });
    }
    case API_ITEM_UPDATED: {
      return state.merge({ showItemWasAdded: true });
    }
    case ADD_PLAN_TO_CART: {
      return state.merge({ showItemWasAdded: true });
    }
    case ITEM_EDITED: {
      return state.merge({ showItemWasEdited: true });
    }
    case CLEAR_ITEM_MESSAGES: {
      return state.merge({
        showItemWasAdded: false,
        showItemWasEdited: false,
      });
    }
    case SHOW_MODAL_MULTIPLE_AUTO_APPLY_COUPONS: {
      return state.merge({ showModalMultipleAutoApplyCoupons: true });
    }
    case DISMISS_MODAL_MULTIPLE_AUTO_APPLY_COUPONS: {
      return state.merge({ showModalMultipleAutoApplyCoupons: false });
    }
    case SET_RELATED_SKUS: {
      const {
        payload: { skus: relatedSkus },
      } = action;
      return state.merge({ relatedSkus });
    }
    case TOGGLE_ADD_ANOTHER_SERVICE: {
      const {
        payload: { visible },
      } = action;
      return state.set('anotherServiceAvailable', visible);
    }
    case LOAD_PLAN_DETAILS: {
      const { plan } = action;
      return state.merge({ planDetails: fromJS(plan) });
    }
    case LOAD_ESTIMATED_CART: {
      const { cart } = action;
      return state.set('estimatedCart', fromJS(cart));
    }
    case SHOW_UPSELL_CART_VERSION: {
      const { show } = action;
      return state.merge({ showUpsellCartVersion: show });
    }
    case TOGGLE_REMOVE_PLAN_MODAL: {
      return state.merge({ showRemovePlanModal: !state.get('showRemovePlanModal') });
    }
    case TOGGLE_PLAN_HOLDER_MODAL: {
      const { show } = action;
      return state.merge({ showPlanHolderModal: show });
    }
    case PAGE_LOADED: {
      if (action.page === PAGE_NAME) {
        const { plansInfo } = action;
        return plansInfo ? state.set('plansInfo', fromJS(plansInfo)) : state;
      }
      return state;
    }
    default:
      return state;
  }
}

export default pageReducer;
