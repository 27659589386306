import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../styles.scss';

export const LoginPasscodeBoxes = ({ passcode, isInputFocused, hasError, numBoxes }) => {
  // Create an array of box indices [0, 1, 2, ...]
  const boxIndices = Array.from({ length: numBoxes }, (_, i) => i);

  return (
    <>
      {boxIndices.map((index) => {
        const isNextEmptyBox = index === passcode.length;
        const isLastBox = index === boxIndices.length - 1;
        const isLastBoxFilled = Boolean(passcode[boxIndices.length - 1]);
        const shouldShowFocus =
          isInputFocused && (isNextEmptyBox || (hasError && isLastBox && isLastBoxFilled));

        return (
          <div
            key={index}
            className={cn(styles.passcodeBox, {
              [styles.focused]: shouldShowFocus,
            })}
          >
            {passcode[index] || ''}
          </div>
        );
      })}
    </>
  );
};

LoginPasscodeBoxes.propTypes = {
  passcode: PropTypes.string.isRequired,
  isInputFocused: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  numBoxes: PropTypes.number.isRequired,
};
