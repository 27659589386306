import { logger } from 'src/utils/logger';
import { getFieldSalesCookie } from 'src/utils/cookies';
import { getResponseError } from 'src/utils/helpers';
import { FIELD_SALES_LOGGER_TITLE, ITEM_TYPES } from './constants';

import shpImage from '../../images/shp-cart-thumbnail.png';

/*
* Logger Helper
*
* param {}
* param {}
*
* We want to extract as much base info as possible.
* */

export const LogSalesError = (source, error) => {
  const cookieData = JSON.stringify(getFieldSalesCookie());

  logger(`${FIELD_SALES_LOGGER_TITLE} (s: ${source})(c: ${cookieData})`)(getResponseError(error));
}

export const logError = (err) => {
  LogSalesError('skuList', err);
}

/*
* This just compares current item to acceptable matches for SKU/PRODUCT types
* We broke it out here to allow for some sku cat types to not display images.
*
* Concat to allow for single items.
* */
export const testAgainstItemType = (item = []) => {
  return [].concat(item).some(i => {
    return ['PRODUCT','SKU','PLAN', 'BUNDLE_CUSTOM']
      .map(skutype => ITEM_TYPES.get(skutype))
      .some(reg => reg.test(i.type))
  })
}

/* Return last item in sku array as user requested */
export const handleProperItemList = (skuList) => {
  const lastEntry = skuList.length - 1;

  return skuList[lastEntry];
}

/*
* We want to use the default shp image for plan displays
* For both parent & child displays
*
* recursive
* */
export const fillPlansWithDefaultImage = (items) => {
  if (items.length === 0) return items;

  return items.map(item => {
    const list = {...item};

    if (ITEM_TYPES.get('PLAN').test(item.type)) {
      list.image = shpImage
    }
    list.children = fillPlansWithDefaultImage(item.children);

    return list;
  })
}

/*
* Tells us if our current display group has any images
*
* Concat to allow for single items.
* */
export const determineIfImageInGrouping = (group = []) => {
  return [].concat(group).some(item => {
    return testAgainstItemType(item) && item.image
  })
}
