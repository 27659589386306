import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Grid } from 'HTKit/Grid';
import Accordion, { AccordionGroup } from 'src/components/Elements/Accordion';
import styles from './styles.scss';

const ServiceFaq = ({ service = {}, className }) => {
  if (!service.faq) return null;
  return (
    <div className={className}>
      <Grid.FullWidth>
        <Grid.Row>
          <Grid.Column>
            <h4 className={cn('h4', styles.title)}>Frequently Asked Questions</h4>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column sm={4} md={8} lg={6} lgOffset={3}>
            <AccordionGroup className={styles.list}>
              {service.faq.map((f) => (
                <Accordion key={f.id} title={f.questionText} className={styles.item}>
                  <div
                    className={cn('p1', styles.text)}
                    dangerouslySetInnerHTML={{ __html: f.answerText }}
                  />
                </Accordion>
              ))}
            </AccordionGroup>
          </Grid.Column>
        </Grid.Row>
      </Grid.FullWidth>
    </div>
  );
};

ServiceFaq.propTypes = {
  service: PropTypes.shape({
    faq: PropTypes.arrayOf(
      PropTypes.shape({
        questionText: PropTypes.string,
        answerText: PropTypes.string,
      }),
    ),
  }),
  className: PropTypes.string,
};

export default memo(ServiceFaq);
