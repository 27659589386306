import React, { useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

/* Components */
import Button, { THEMES as BUTTON_THEMES } from 'src/components/HTKit/Forms/Button';
import { CalloutBox, CALLOUTBOX_THEMES } from 'src/components/Elements/CalloutBox';
import { Picture } from 'src/components/Elements/Picture';
import shpThumbnail from 'src/images/shp-cart-thumbnail.png';
import { RemoveItemModal, MODAL_TYPES } from './CartNavigationDisplay.RemoveItemModal';

/* Hooks */
import useOutsideClick from 'src/hooks/useOutsideClick';
import { useCartNavigationDisplay } from './CartNavigationDisplay.hooks';
import { useLocation } from 'react-router-dom';

/* Constants & Utils */
import { formatPriceTypeHourly } from 'src/containers/CartPage/utils';
import { formatter } from 'src/utils/formatter/currency';
import { UPSELL_PLAN_BILLING_CYCLES } from 'src/constants/plan';
import { BREAKDOWN_PRICE_TYPES } from 'src/containers/CartPage/constants';
import { isDevelopment } from 'src/utils/env';

/* Styles */
import styles from './styles.scss';

const linkStyles = 'text-link p2 underline text-weight-med';
const skuAndPlanNameStyles = 'p1 text-weight-med';

const PlanDisplay = ({ plan, toggleRemovePlanModal }) => {
  if (!plan || !plan.id) return null;

  const { name, planInterval, amount } = plan;
  const interval = planInterval === UPSELL_PLAN_BILLING_CYCLES.ANNUAL ? 'year' : 'month';

  return (
    <div className={styles.cardDetails}>
      <div className={styles.leftSide}>
        <div className="marginRight-small">
          <Picture src={shpThumbnail} className={styles.image} />
        </div>
        <div>
          <p className={skuAndPlanNameStyles}>{name}</p>
          <button className="plainButton">
            <span className={linkStyles} onClick={toggleRemovePlanModal}>
              Remove
            </span>
          </button>
        </div>
      </div>
      <div className={styles.rightSide}>
        <p className="p1 n900">{`${formatter(0).format(amount)}/${interval}`}</p>
        <p className="caption n700 text-align-right">Due today</p>
      </div>
    </div>
  );
};

const BreakdownDisplay = ({
  breakdownItems,
  editSku,
  toggleRemoveServiceModal,
  toggleCartNavigationDisplay,
}) => {
  if ((breakdownItems || []).length === 0) return null;

  const service = breakdownItems[0];
  const { image, name, subTotal, skuId } = service;

  const imageSrc = isDevelopment() ? 'https://picsum.photos/id/237/56/56' : image;

  const subTotalAmount = formatPriceTypeHourly({
    service,
    price: subTotal.amount,
    withAsterik: true,
  });

  const hasMembershipDiscount = Boolean(subTotal.lineThroughText);

  const subTotalStyles = cn({
    blue700: hasMembershipDiscount,
    [`text-weight-med`]: hasMembershipDiscount,
    n900: !hasMembershipDiscount,
  });

  const handleEditSku = () => {
    editSku(skuId);
    toggleCartNavigationDisplay();
  };

  return (
    <div className={styles.cardDetails}>
      <div className={styles.leftSide}>
        <Picture src={imageSrc} className={cn(styles.image, 'marginRight-small')} />
        <div>
          <div className={skuAndPlanNameStyles}>{name}</div>
          <button className={cn('plainButton', styles.editText)} onClick={handleEditSku}>
            <span className={linkStyles}>Edit details</span>
          </button>
          <button className="plainButton" onClick={toggleRemoveServiceModal}>
            <span className={linkStyles}>Remove</span>
          </button>
        </div>
      </div>
      <div className={styles.rightSide}>
        <p className={cn(styles.amount, 'p1 text-align-right')}>
          {subTotal.lineThroughText && (
            <span className="line-through n700">{subTotal.lineThroughText}</span>
          )}
          <span className={subTotalStyles}>&nbsp;{subTotalAmount}</span>
        </p>
        <p className="caption n700 text-align-right">Due after service</p>
      </div>
    </div>
  );
};

const Spacer = () => <div className={styles.spacer} />;

export const CartNavigationDisplay = ({
  toggleCartNavigationDisplay,
  offsetRightSmall,
  offsetRightMedium = true,
}) => {
  /* Hooks */
  const wrapperRef = useRef(null);
  const {
    breakdownItems,
    cartedPlan,
    editSku,
    isInBookingFlow,
    removePlan,
    removeSku,
    showRemovePlanModal,
    showRemoveServiceModal,
    startBookingProcess,
    toggleRemovePlanModal,
    toggleRemoveServiceModal,
  } = useCartNavigationDisplay();

  const isPriceTypeHourly = breakdownItems[0]?.priceType === BREAKDOWN_PRICE_TYPES.hourly;

  const { pathname } = useLocation();
  const showBookingButton = !isInBookingFlow(pathname);

  const hasServiceInCart = (breakdownItems.length || []) > 0;

  // Use medium offset by default
  const offsetStyles = cn({
    [styles.offsetRightSmall]: offsetRightSmall,
    [styles.offsetRightMedium]: offsetRightMedium && !offsetRightSmall,
  });

  /* Any clicks outside, close this display */
  useOutsideClick(wrapperRef, toggleCartNavigationDisplay);

  return (
    <div ref={wrapperRef} className={cn(styles.cartDisplayContainer, offsetStyles)}>
      <BreakdownDisplay
        breakdownItems={breakdownItems}
        editSku={editSku}
        toggleRemoveServiceModal={toggleRemoveServiceModal}
        toggleCartNavigationDisplay={toggleCartNavigationDisplay}
      />
      {hasServiceInCart && <Spacer />}
      <PlanDisplay plan={cartedPlan} toggleRemovePlanModal={toggleRemovePlanModal} />
      <Spacer />
      <CalloutBox
        theme={CALLOUTBOX_THEMES.PRIMARY}
        text="Have a promo code? You'll have the chance to apply it near the end of this booking."
      />

      {showBookingButton && (
        <>
          <Spacer />
          <Button theme={BUTTON_THEMES.V2PRIMARY} onClick={startBookingProcess}>
            Book Now
          </Button>
        </>
      )}

      {isPriceTypeHourly && (
        <>
          <Spacer />
          <p className="p2 n700">
            *Hourly services are charged for a minimum of one hour, and billable in half-hour
            increments thereafter. Though we'll try our very best, resolution is not always possible
            and can't be guaranteed.
          </p>
        </>
      )}
      <RemoveItemModal
        isVisible={showRemoveServiceModal}
        hide={toggleRemoveServiceModal}
        onConfirm={() => removeSku(pathname)}
        type={MODAL_TYPES.SERVICE}
      />
      <RemoveItemModal
        isVisible={showRemovePlanModal}
        hide={toggleRemovePlanModal}
        onConfirm={() => removePlan(pathname)}
        type={MODAL_TYPES.MEMBERSHIP}
      />
    </div>
  );
};

CartNavigationDisplay.propTypes = {
  toggleCartNavigationDisplay: PropTypes.func.isRequired,
  // `right` CSS property is medium
  offsetRightMedium: PropTypes.bool,
  // `right` CSS property is small
  offsetRightSmall: PropTypes.bool,
};
PlanDisplay.propTypes = {
  plan: PropTypes.object,
  toggleRemovePlanModal: PropTypes.func.isRequired,
};
BreakdownDisplay.propTypes = {
  breakdownItems: PropTypes.object,
  editSku: PropTypes.func.isRequired,
  toggleRemoveServiceModal: PropTypes.func.isRequired,
  toggleCartNavigationDisplay: PropTypes.func.isRequired,
};
