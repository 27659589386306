import React from 'react';
import PropTypes from 'prop-types';
import {AFFIRM_LOGO_HALFMOON} from './constants';
import styles from './styles.scss';

const AffirmReviewNote = ({ buttonText }) => {
 return (
   <div className={styles.affirm_review_note}>
     <img className={styles.affirm_review_note__image} src={AFFIRM_LOGO_HALFMOON} />
     <div className={styles.affirm_review_note__text}>
       <p className="p2 paddingLeft-small">{`Clicking ${buttonText} will redirect you to Affirm to complete your purchase securely.`}</p>
     </div>
   </div>
 )
}

AffirmReviewNote.propTypes = {
  buttonText: PropTypes.string.isRequired
}

export default AffirmReviewNote;
