import {
  CLEAR_PAGE_DATA,
  GET_PLAN_DETAILS,
  SET_PLAN_DETAILS,
  SUBMIT_CARD_INFO,
  SUBMIT_RECIPIENT_INFO,
  SUBMIT_PURCHASER_INFO,
  SUBMIT_ORDER,
  INCREMENT_COMPLETED_STAGE,
  APPLY_PROMO_CODE,
  APPLY_DISCOUNT,
} from './constants';

export function clearPageData() {
  return {
    type: CLEAR_PAGE_DATA,
  };
}

export function getPlanDetails(planId) {
  return {
    type: GET_PLAN_DETAILS,
    planId,
  };
}

export function setPlanDetails(plan) {
  return {
    type: SET_PLAN_DETAILS,
    plan,
  };
}

export function submitRecipientInfo(form) {
  return {
    type: SUBMIT_RECIPIENT_INFO,
    form,
  };
}

export function submitPurchaserInfo(form) {
  return {
    type: SUBMIT_PURCHASER_INFO,
    form,
  };
}

export function submitCardInfo(cardInfo) {
  return {
    type: SUBMIT_CARD_INFO,
    cardInfo,
  };
}

export function submitOrder(orderDetails) {
  return {
    type: SUBMIT_ORDER,
    orderDetails,
  };
}

export function incrementCompletedStage(completedStage) {
  return {
    type: INCREMENT_COMPLETED_STAGE,
    completedStage,
  };
}

export const applyPromoCode = (code) => ({
  type: APPLY_PROMO_CODE,
  code,
});

export const applyDiscount = ({ coupon, price, code }) => ({
  type: APPLY_DISCOUNT,
  coupon,
  price,
  code,
});
