import { fromJS } from 'immutable';
import { SET_ZIP_CODE_RESTRICTION } from 'src/constants/common';
import { PAGE_NAME as RESCHEDULING_PAGE } from './constants';

const initialState = fromJS({});

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ZIP_CODE_RESTRICTION: {
      if (action.page !== RESCHEDULING_PAGE) return state;
      const { isZipRestricted, orderableFromDate } = action;
      return state.merge({ isZipRestricted, orderableFromDate });
    }
    default:
      return state;
  }
}

export default pageReducer;
