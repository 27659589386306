import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ServiceBreakdownItem from 'src/components/OrderSummary/Breakdown/ServiceBreakdownItem';
import { QA_FLOW_VALID_SKUS } from 'src/containers/AddSkuPage/QuestionsFlowPage/constants';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import styles from './styles.scss';

const ApiCartServiceBreakdown = ({ services, simulateAddItem, editItem, removeItem }) => {
  /*
    - removeItem will be added in the future. Keeping it in the flow for now. May be able to
      implement the same way <RegularCart/> does -GH Aug 6, 2020
  */
  if (!services) return null;
  const editStyles = cn('plainButton', styles.editButton, {
    [styles.border]: Boolean(removeItem),
  });
  return (
    <>
      {services.map((service, i) => {
        const { skuId, index } = service;
        const showAddServiceDetailsButton = service.hasUnansweredQuestions;
        const showEditButton = !QA_FLOW_VALID_SKUS.includes(skuId) && !showAddServiceDetailsButton;
        return (
          <>
            <div
              className={cn(styles.itemSection, 'paddingTop-small2 marginTop-small2')}
              key={`service-${i}`}
            >
              <ServiceBreakdownItem {...service} boldServiceName />
              {showEditButton && (
                <button className={editStyles} onClick={editItem({ skuId, index })}>
                  <p className="p1 text-link">Edit</p>
                </button>
              )}
              {removeItem && (
                <button className="plainButton" onClick={() => removeItem(service, index)}>
                  <p className="p1 text-link">Remove</p>
                </button>
              )}
            </div>
            {showAddServiceDetailsButton && (
              <Button
                onClick={simulateAddItem({ skuId, index })}
                theme={THEMES.V2PRIMARY}
                className={cn('marginTop-small1', styles.addDetailsButton)}
              >
                Add Service Details
              </Button>
            )}
          </>
        );
      })}
    </>
  );
};

ApiCartServiceBreakdown.propTypes = {
  services: PropTypes.array,
  simulateAddItem: PropTypes.func,
  editItem: PropTypes.func,
  removeItem: PropTypes.func,
};

ApiCartServiceBreakdown.defaultProps = {
  simulateAddItem: () => {},
  editItem: () => {},
};

export default ApiCartServiceBreakdown;
