import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { availabilityStateSelectorJs } from 'src/selectors/availability';
// Utils
import {
  createOptionsMonthlyCustomOrdinal,
  createOptionsMonthlyCustomWeekDays,
  createOptionsMonthlyDaysOfTheMonth,
  createOptionsRecurringFrequency,
  createOptionsStartTimeForExactTime,
  createOptionsStartTimeForTimeWindows,
  createPayloadWeekly,
  getSequenceFromDateTime,
  getStateInitializers,
  createPayloadMonthly,
} from './RecurringServicePanel.utils';
import { convertToTimeZone } from 'src/containers/BookingPage/AvailabilityPage/AvailabilityPage.utils';
// Constants
import {
  CUSTOM_MONTHLY_VIEW_TABS,
  RECURRING_FREQUENCY_VALUES,
  MONTHLY_OPTION_TYPES,
  STATE_DEFAULTS,
} from './RecurringServicePanel.constants';
import { DEFAULT_TIME_ZONE, isoFormat } from 'src/constants/time';

/** The minimum value for the recurrence interval */
const MIN_VALUE_INTERVAL = 1;

/** Provides state and functions to control the Recurring Service Panel. */
export const useRecurringServicePanel = ({
  isEditMode,
  cart,
  selectedDate, // YYYY-MM-DD
  selectedTimes,
  setRecurrence,
  useTimeWindows,
  onPanelVisibilityChange = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  /** Availability as provided by the API */
  const availableTimes = useSelector(availabilityStateSelectorJs);
  const hasInitializedRef = useRef(false);

  // #############
  // INTERVAL
  // #############
  const [recurrenceInterval, setRecurrenceInterval] = useState(STATE_DEFAULTS.recurrenceInterval);

  const handleIntervalChange = useCallback((value) => {
    setRecurrenceInterval(value);
  }, []);

  // ##########
  // FREQUENCY
  // ##########

  const [recurringFrequency, setRecurringFrequency] = useState(STATE_DEFAULTS.recurringFrequency);

  const optionsRecurringFrequency = useMemo(
    () => createOptionsRecurringFrequency(recurrenceInterval),
    [recurrenceInterval],
  );

  // #############
  // WEEKLY DAYS
  // #############

  const [selectedWeeklyDays, setSelectedWeeklyDays] = useState(STATE_DEFAULTS.selectedWeeklyDays);

  const handleWeeklyDaySelection = (value) => () => {
    // PREVENT DESELECTION IF IT WOULD MEAN THAT NO DAYS ARE SELECTED
    if (selectedWeeklyDays.length === 1 && selectedWeeklyDays[0] === value) {
      return;
    }
    const newSelectedWeeklyDays = [...selectedWeeklyDays];
    if (selectedWeeklyDays.includes(value)) {
      newSelectedWeeklyDays.splice(newSelectedWeeklyDays.indexOf(value), 1);
    } else {
      newSelectedWeeklyDays.push(value);
    }
    setSelectedWeeklyDays(newSelectedWeeklyDays);
  };

  // ##################
  // DAYS OF THE MONTH
  // ##################

  const [monthlyRecurrenceOption, setMonthlyRecurrenceOption] = useState(
    STATE_DEFAULTS.monthlyRecurrence,
  );

  const optionsMonthlyDaysOfTheMonth = createOptionsMonthlyDaysOfTheMonth(selectedDate);
  // ###########
  // START TIME
  // ###########

  const [selectedStartTime, setSelectedStartTime] = useState(STATE_DEFAULTS.startTime);

  const optionsStartTime = useMemo(() => {
    if (useTimeWindows) {
      return createOptionsStartTimeForTimeWindows(availableTimes);
    }
    return createOptionsStartTimeForExactTime(availableTimes);
  }, [availableTimes, useTimeWindows]);

  // ###################
  // MONTHLY CUSTOM
  // ###################

  const [customMenuView, setCustomMenuView] = useState(CUSTOM_MONTHLY_VIEW_TABS.SPECIFIC_DAYS);
  const [monthlyCustomDays, setMonthlyCustomDays] = useState(STATE_DEFAULTS.monthlyCustomDays);
  const [monthlyCustomOrdinal, setMonthlyCustomOrdinal] = useState(
    STATE_DEFAULTS.monthlyCustomOrdinal,
  );
  const [monthlyCustomWeekdays, setMonthlyCustomWeekdays] = useState(
    STATE_DEFAULTS.monthlyCustomWeekdays,
  );

  const optionsMonthlyCustomOrdinal = useMemo(createOptionsMonthlyCustomOrdinal, []);
  const optionsMonthlyCustomWeekdays = useMemo(createOptionsMonthlyCustomWeekDays, []);

  const handleMonthlyCustomDaySelection = (value) => () => {
    // PREVENT DESELECTION IF IT WOULD MEAN THAT NO DAYS ARE SELECTED
    if (monthlyCustomDays.length === 1 && monthlyCustomDays[0] === value) {
      return;
    }
    const newMonthlyCustomDays = [...monthlyCustomDays];
    if (monthlyCustomDays.includes(value)) {
      newMonthlyCustomDays.splice(newMonthlyCustomDays.indexOf(value), 1);
    } else {
      newMonthlyCustomDays.push(value);
    }
    setMonthlyCustomDays(newMonthlyCustomDays);
  };

  // ########
  // EFFECTS
  // ########

  /** Listener for panel visibility change */
  useEffect(() => {
    onPanelVisibilityChange(isOpen);
  }, [isOpen, onPanelVisibilityChange]);

  /** Updates the recurrence object in the availability context based on the user's selections. */
  const updateRecurrence = useCallback(() => {
    if (!recurringFrequency || recurrenceInterval <= 0) {
      setRecurrence(null);
      return;
    }

    const startDate = convertToTimeZone(selectedTimes[0], cart.timezone || DEFAULT_TIME_ZONE)
      .startOf('day')
      .format(isoFormat);

    if (recurringFrequency.value === RECURRING_FREQUENCY_VALUES.WEEKLY) {
      if (selectedWeeklyDays.length > 0 && selectedStartTime?.hours) {
        setRecurrence(
          createPayloadWeekly({
            startDate,
            days: selectedWeeklyDays,
            interval: recurrenceInterval,
            availability: selectedStartTime.hours,
          }),
        );
      }
    }

    if (recurringFrequency.value === RECURRING_FREQUENCY_VALUES.MONTHLY) {
      setRecurrence(
        createPayloadMonthly({
          monthlyRecurrenceOption,
          selectedStartTime,
          startDate,
          recurrenceInterval,
          customMenuView,
          monthlyCustomOrdinal,
          monthlyCustomWeekdays,
          monthlyCustomDays,
        }),
      );
    }
  }, [
    recurringFrequency,
    recurrenceInterval,
    selectedWeeklyDays,
    setRecurrence,
    selectedStartTime,
    monthlyRecurrenceOption,
    customMenuView,
    monthlyCustomWeekdays,
    monthlyCustomOrdinal,
    monthlyCustomDays,
    selectedTimes,
    cart.timezone,
  ]);

  const initializeState = useCallback(() => {
    const {
      getInitialStartTime,
      getInitialSelectedDay,
      getInitialSelectedDate,
    } = getStateInitializers({ optionsStartTime, selectedTimes });

    if (!recurrenceInterval) setRecurrenceInterval(1);
    if (!recurringFrequency) setRecurringFrequency(optionsRecurringFrequency[0]);
    if (!selectedWeeklyDays.length) {
      const initialSelectedDay = getInitialSelectedDay();
      setSelectedWeeklyDays([initialSelectedDay]);
    }
    if (!selectedStartTime) {
      const initialStartTime = getInitialStartTime();
      setSelectedStartTime(initialStartTime);
    }
    if (!monthlyCustomDays.length) {
      const initialSelectedDate = getInitialSelectedDate();
      setMonthlyCustomDays([initialSelectedDate]);
    }
    if (!monthlyCustomOrdinal) {
      const sequence = selectedTimes.length ? getSequenceFromDateTime(selectedTimes[0]) : 1;
      const defaultOption = optionsMonthlyCustomOrdinal.find((o) => o.sequence === sequence);
      setMonthlyCustomOrdinal(defaultOption);
    }
    if (!monthlyCustomWeekdays) {
      // const dayOfTheWeek = selectedTimes.length ? dayjs(selectedTimes[0]).day() : 0;
      const dayOfTheWeek = selectedTimes.length ? moment(selectedTimes[0]).day() : 0;
      const defaultOption = optionsMonthlyCustomWeekdays.find(
        (o) => o.dayOfTheWeek === dayOfTheWeek,
      );
      setMonthlyCustomWeekdays(defaultOption);
    }
  }, [
    recurrenceInterval,
    recurringFrequency,
    optionsRecurringFrequency,
    selectedWeeklyDays,
    selectedStartTime,
    optionsStartTime,
    selectedTimes,
    monthlyCustomDays,
    monthlyCustomOrdinal,
    monthlyCustomWeekdays,
    optionsMonthlyCustomWeekdays,
    optionsMonthlyCustomOrdinal,
  ]);

  const initializeStateFromSavedData = useCallback(() => {
    const savedCartRecurrence = cart.recurrence;
    if (savedCartRecurrence) {
      const isWeekly = 'weekly' in savedCartRecurrence;
      const isMonthly = 'monthly' in savedCartRecurrence;

      const { getInitialStartTimeFromSavedRecurrence } = getStateInitializers({
        optionsStartTime,
        selectedTimes,
        savedRecurrence: savedCartRecurrence,
      });

      setRecurrence(savedCartRecurrence);

      const recurringFrequencyValue = isWeekly
        ? RECURRING_FREQUENCY_VALUES.WEEKLY
        : RECURRING_FREQUENCY_VALUES.MONTHLY;
      setRecurringFrequency(
        optionsRecurringFrequency.find((o) => o.value === recurringFrequencyValue) ??
          optionsRecurringFrequency[0],
      );
      setRecurrenceInterval(savedCartRecurrence.interval);
      setSelectedStartTime(getInitialStartTimeFromSavedRecurrence());

      if (isWeekly) {
        setSelectedWeeklyDays(savedCartRecurrence.weekly.days);
      }
      // NOTE: cart.recurrence.monthly is in camelCase because of the way we fetch data. I'm using lodash/get to void typing issues
      if (isMonthly) {
        const customOption =
          optionsMonthlyDaysOfTheMonth.find((o) => o.value === MONTHLY_OPTION_TYPES.CUSTOM) ??
          optionsMonthlyDaysOfTheMonth[-1];
        if ('specificDays' in savedCartRecurrence.monthly) {
          const specificDays = get(savedCartRecurrence.monthly, 'specificDays', []);
          const dayOfTheMonthOption = optionsMonthlyDaysOfTheMonth.find(
            (o) => o.value === MONTHLY_OPTION_TYPES.DAY_OF_THE_MONTH,
          );

          const savedDataMatchesDayOfTheMonthOption =
            specificDays.length === 1 && specificDays[0] === dayOfTheMonthOption?.dayOfTheMonth;
          if (savedDataMatchesDayOfTheMonthOption) {
            setMonthlyRecurrenceOption(dayOfTheMonthOption);
          } else {
            setMonthlyRecurrenceOption(customOption);
            setCustomMenuView(CUSTOM_MONTHLY_VIEW_TABS.SPECIFIC_DAYS);
            setMonthlyCustomDays(specificDays);
          }
        }
        if ('onThe' in savedCartRecurrence.monthly) {
          const ordinalOption = optionsMonthlyDaysOfTheMonth.find(
            (o) => o.value === MONTHLY_OPTION_TYPES.ON_THE_ORDINAL,
          );

          const savedSequence = get(savedCartRecurrence.monthly, 'onThe.sequence', 0);
          const savedDayOfWeek = get(savedCartRecurrence.monthly, 'onThe.dayOfWeek', 0);

          // @ts-ignore TS2339: Property 'sequence' does not exist on type MonthlyRecurrenceOption
          const savedDataMatchesOrdinalOption =
            ordinalOption?.sequence === savedSequence &&
            ordinalOption?.dayOfTheWeek === savedDayOfWeek;

          if (savedDataMatchesOrdinalOption) {
            setMonthlyRecurrenceOption(ordinalOption);
          } else {
            setMonthlyRecurrenceOption(customOption);
            setCustomMenuView(CUSTOM_MONTHLY_VIEW_TABS.ON_THE_ORDINAL);
            setMonthlyCustomOrdinal(
              optionsMonthlyCustomOrdinal.find((o) => o.sequence === savedSequence) ??
                optionsMonthlyCustomOrdinal[0],
            );
            setMonthlyCustomWeekdays(
              optionsMonthlyCustomWeekdays.find((o) => o.dayOfTheWeek === savedDayOfWeek) ??
                optionsMonthlyCustomWeekdays[0],
            );
          }
        }
      }
    }
  }, [
    cart.recurrence,
    setRecurrence,
    optionsMonthlyCustomOrdinal,
    optionsMonthlyCustomWeekdays,
    optionsRecurringFrequency,
    optionsStartTime,
    selectedTimes,
    optionsMonthlyDaysOfTheMonth,
  ]);

  const resetStateAndRecurrence = useCallback(() => {
    setRecurrence(null);
    setRecurrenceInterval(STATE_DEFAULTS.recurrenceInterval);
    setRecurringFrequency(STATE_DEFAULTS.recurringFrequency);
    setSelectedWeeklyDays(STATE_DEFAULTS.selectedWeeklyDays);
    setSelectedStartTime(STATE_DEFAULTS.startTime);
    setMonthlyRecurrenceOption(STATE_DEFAULTS.monthlyRecurrence);
    setMonthlyCustomWeekdays(STATE_DEFAULTS.monthlyCustomWeekdays);
    setMonthlyCustomOrdinal(STATE_DEFAULTS.monthlyCustomOrdinal);
    setMonthlyCustomDays(STATE_DEFAULTS.monthlyCustomDays);
  }, [setRecurrence]);

  useEffect(() => {
    if (isOpen && !hasInitializedRef.current) {
      if (isEditMode && cart.recurrence) {
        initializeStateFromSavedData();
      } else {
        initializeState();
      }
      hasInitializedRef.current = true;
    }
  }, [isOpen, isEditMode, cart.recurrence, initializeStateFromSavedData, initializeState]);

  useEffect(() => {
    if (isEditMode && cart.recurrence && !hasInitializedRef.current) {
      setIsOpen(true);
    }
  }, [isEditMode, cart.recurrence]);

  useEffect(() => {
    if (isOpen) {
      updateRecurrence();
    } else {
      setRecurrence(null);
    }
  }, [isOpen, updateRecurrence, setRecurrence]);

  useEffect(() => {
    if (!selectedTimes.length) {
      resetStateAndRecurrence();
    }
  }, [resetStateAndRecurrence, selectedTimes]);

  return {
    isOpen,
    setIsOpen,
    frequency: {
      optionsRecurringFrequency,
      recurringFrequency,
      setRecurringFrequency,
    },
    interval: {
      recurrenceInterval,
      handleIntervalChange,
      minValueInterval: MIN_VALUE_INTERVAL,
    },
    weeklyDays: {
      selectedWeeklyDays,
      handleWeeklyDaySelection,
    },
    startTime: {
      optionsStartTime,
      selectedStartTime,
      setSelectedStartTime,
    },
    daysOfTheMonth: {
      optionsMonthlyDaysOfTheMonth,
      monthlyRecurrenceOption,
      setMonthlyRecurrenceOption,
    },
    monthlyCustom: {
      customMenuView,
      setCustomMenuView,
      specificDays: {
        monthlyCustomDays,
        handleMonthlyCustomDaySelection,
      },
      ordinal: {
        optionsMonthlyCustomOrdinal,
        monthlyCustomOrdinal,
        setMonthlyCustomOrdinal,
        optionsMonthlyCustomWeekdays,
        monthlyCustomWeekdays,
        setMonthlyCustomWeekdays,
      },
    },
  };
};
