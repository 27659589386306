import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import FeaturedServiceLanding from './FeaturedServiceLanding';
import FeaturedServiceSku from './FeaturedServiceSku';
import styles from './styles.scss';

const FeaturedServices = ({ skus, landings, onServiceClick }) => {
  const servicesStyles = cn('l-content-section', styles.services);
  return (
    <div className={servicesStyles}>
      <h2 className="l-section-header blue500">Featured HelloTech Services</h2>
      <div className={styles.servicesList}>
        {skus.map((sku) => (
          <div key={sku.id}>
            <FeaturedServiceSku sku={sku} onClick={onServiceClick} />
          </div>
        ))}
        {landings.map((landing) => (
          <div key={landing.id}>
            <FeaturedServiceLanding landing={landing} onClick={onServiceClick} />
          </div>
        ))}
      </div>
    </div>
  );
};

FeaturedServices.propTypes = {
  skus: PropTypes.array.isRequired,
  landings: PropTypes.array.isRequired,
  onServiceClick: PropTypes.func.isRequired,
};

export default FeaturedServices;
