import { fromJS } from 'immutable';
import { SUBSCRIPTION_LOADED, BONUS_EDIT_MODE, PAYMENT_EDIT_MODE } from './constants';

const initialState = fromJS({
  editBonus: false,
  subscription: null,
});

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case SUBSCRIPTION_LOADED: {
      return state.set('subscription', fromJS(action.subscription));
    }
    case BONUS_EDIT_MODE:
      return state.set('editBonus', action.editMode);
    case PAYMENT_EDIT_MODE:
      return state.set('editMode', action.editMode);
    default:
      return state;
  }
}

export default pageReducer;
