import { SERVICES, BENEFITS_DESCRIPTION } from 'src/constants/planBenefits';
import { roundToNearestTen } from 'src/utils/math';

const filterIncludedSkus = ({ plan }) => {
  let filteredSkus = [];
  let totalValue = 0;
  if (!plan || plan.isEmpty() || !plan.get('includedSkus'))
    return { includedBenefits: [], includedTotalValue: 0 };
  plan.get('includedSkus').forEach((sku) => {
    const skuName = sku.get('name').toLowerCase();
    const cheapestPrice = roundToNearestTen(sku.get('cheapestPrice'));
    const cheapestPriceFormatted = `$${cheapestPrice}`;

    const services = Object.keys(SERVICES).reduce((acct, service) => {
      if (skuName.indexOf(service) === -1) return acct;

      return [
        ...acct,
        {
          name: SERVICES[service].name,
          cost: 'INCLUDED',
          mobileName: SERVICES[service].mobileName,
          value: `${cheapestPriceFormatted}`,
          description: SERVICES[service].description,
          price: cheapestPrice,
        },
      ];
    }, []);

    totalValue += services.reduce((acct, service) => acct + service.price, 0);
    filteredSkus = filteredSkus.concat(services);
    const hasHomeTechnologySku =
      skuName.indexOf('home') !== -1 &&
      skuName.indexOf('technology') !== -1 &&
      skuName.indexOf('check') !== -1;
    if (hasHomeTechnologySku) {
      filteredSkus.push({
        name: 'Home Technology Checkup',
        cost: 'INCLUDED',
        value: `${cheapestPriceFormatted}`,
        mobileName: 'Home Technology Checkup',
        description: BENEFITS_DESCRIPTION.homeTechCheckup,
      });
      totalValue += cheapestPrice;
    }
  });

  return { includedBenefits: filteredSkus, includedTotalValue: totalValue };
};

export default filterIncludedSkus;
