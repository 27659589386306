import React from 'react';
import styles from './styles.scss';

export default function USToday() {
  return (
    <section className={`l-content-section ${styles.USATodayContainer}`}>
      <p className="h4 text-weight-regular marginBottom-small">
        USA Today calls HelloTech &ldquo;the best, cheapest way to get good tech support.&rdquo;
      </p>
      <p className="h4">- USA Today</p>
    </section>
  );
}
