import React from 'react';
import PropTypes from 'prop-types';
import InputFieldV2 from 'src/components/HTKit/Forms/InputFieldV2';
import Button, { THEMES as BUTTON_THEMES } from 'src/components/HTKit/Forms/Button';
import styles from '../styles.scss';

const CouponInputV2 = ({ value, couponErrors, onClick, onChange, placeHolder, ...rest }) => {
  return (
    <form onSubmit={onClick} className={styles.couponForm}>
      <InputFieldV2
        type="text"
        value={value}
        onChange={onChange}
        error={couponErrors}
        placeholder={placeHolder}
        containerClass={styles.input}
        {...rest}
      />
      <Button theme={BUTTON_THEMES.V2SECONDARY} onClick={onClick} className={styles.button}>
        Apply Code
      </Button>
    </form>
  );
};

CouponInputV2.propTypes = {
  value: PropTypes.string,
  couponErrors: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
};

export default CouponInputV2;

/*
  CouponInputV2
    - TODO: Should rename this to CouponInput once the v1 version is deleted
*/
