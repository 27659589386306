/* Action For FieldSales Level State */
/* Exit order modal */
export const FIELD_SALES_TOGGLE_EXIT_ORDER_MODAL = 'fieldsales/FIELD_SALES_TOGGLE_EXIT_ORDER_MODAL';
// update client
export const FIELD_SALES_UPDATE_CLIENT = 'fieldsales/FIELD_SALES_UPDATE_CLIENT';
// error page
export const FIELD_SALES_ERROR_PAGE = 'fieldsales/FIELD_SALES_ERROR_PAGE';


/* ---------------------------------------------------------------------------
/* ---------------- ACTIONS --------------------------------------------------
/* ---------------------------------------------------------------------------

/* Error Page alert. Basically, shut down the header behaviors */
export function errorPageAction(bool) {
  return {
    type: FIELD_SALES_ERROR_PAGE,
    payload: bool
  };
}

/* Save client to context state */
export function updateClientAction(clientPayload) {
  return {
    type: FIELD_SALES_UPDATE_CLIENT,
    payload: clientPayload
  };
}
