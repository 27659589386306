import { createSelector, createStructuredSelector } from 'reselect';
import { currentLandingSelector } from 'src/selectors/landings';
import layoutSelector from 'src/selectors/layout';
import { fieldSaleAgentVerifyLayoutSelector } from 'src/containers/FieldSales/selectors';

const headerSelector = layoutSelector('header');

const headerSelectorJS = createSelector(headerSelector, (header) => header.toJS());

const showHeaderSelector = createSelector(headerSelector, (header) => header.get('showHeader'));

export default createStructuredSelector({
  fieldSaleAgent: fieldSaleAgentVerifyLayoutSelector,
  currentPartnerLanding: currentLandingSelector,
  header: headerSelector,
  headerJS: headerSelectorJS,
  showHeader: showHeaderSelector,
});
