import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SKU_CATEGORY_IDS,
  SUB_CATEGORIES,
} from 'src/components/Header/TopNavHeader/TopNavItemsV2/constants';
import { PrimaryMenu, CategoryMenu, ServiceMenu, SubCategoryMenu } from '..';
import { ServiceMenuRepeatBookings } from '../ServiceMenu';

const Default = (props) => {
  const {
    user,
    setCategoryMenuVisibleState,
    setServiceMenuVisibleState,
    hideMembership,
    skuCategoriesAndSkus,
    logout,
    isMobile,
    categoryMenuVisible,
    setSelectedCategoryCB,
    serviceMenuVisible,
    selectedCategory,
    skuLinkPrefix,
    selectedSubCategory,
  } = props;
  return (
    <>
      <PrimaryMenu
        user={user}
        setCategoryMenuVisibleState={setCategoryMenuVisibleState}
        setServiceMenuVisibleState={setServiceMenuVisibleState}
        hideMembership={hideMembership}
        logout={logout}
        isMobile={isMobile}
      />
      <CategoryMenu
        visible={categoryMenuVisible}
        setCategoryMenuVisibleState={setCategoryMenuVisibleState}
        skuCategoriesAndSkus={skuCategoriesAndSkus}
        setSelectedCategory={setSelectedCategoryCB}
      />
      <ServiceMenu
        visible={serviceMenuVisible}
        setServiceMenuVisibleState={setServiceMenuVisibleState}
        selectedCategory={selectedCategory}
        skuLinkPrefix={skuLinkPrefix}
        selectedSubCategory={selectedSubCategory}
      />
    </>
  );
};

const RepeatBooking = (props) => {
  const {
    user,
    setCategoryMenuVisibleState,
    setServiceMenuVisibleState,
    hideMembership,
    skuCategoriesAndSkus, // This is the data coming from the BE
    logout,
    isMobile,
    categoryMenuVisible,
    setSelectedCategoryCB,
    serviceMenuVisible,
    selectedCategory,
    skuLinkPrefix,
    subCategoryMenuVisible,
    setSubCategoryMenuVisibleState,
    selectedSubCategory,
    setSelectedSubCategory,
  } = props;

  const {
    POPULAR_SERVICES,
    COMPUTERS_PRINTERS,
    TV_MOUNTING,
    WIFI_NETWORK,
    SMART_HOME,
    AUDIO_VIDEO,
    MOBILE_DEVICES,
    HOME_SECURITY,
    AROUND_THE_HOME,
  } = SKU_CATEGORY_IDS;

  const categoriesByListOrder = [
    POPULAR_SERVICES,
    COMPUTERS_PRINTERS,
    SMART_HOME,
    HOME_SECURITY,
    MOBILE_DEVICES,
    WIFI_NETWORK,
    AUDIO_VIDEO,
    TV_MOUNTING,
    AROUND_THE_HOME
  ];

  const [allSkus, setAllSkus] = useState([]);

  useEffect(() => {
    setAllSkus(skuCategoriesAndSkus.flatMap((cat) => cat.skus));
  }, [skuCategoriesAndSkus]);

  const formatSkuDataFromDB = (category) => {
    // The DB includes the URL/link to the sku, so we only need to grab what is provided with no manipulation
    const skuInfoFromDb = skuCategoriesAndSkus.find((sc) => sc.id === category.id);
    if (skuInfoFromDb && skuInfoFromDb.skus) {
      skuInfoFromDb.subCategories = skuInfoFromDb.skus;
    }
    return skuInfoFromDb;
  };

  const formatHardcodedSkuData = (category = {}) => {
    // Add link property from BE data to hardcoded data
    const dupeCat = { ...category };
    const subCats = dupeCat.subCategories || [];
    subCats.forEach((sc) => {
      if (!sc.skus) return;

      sc.skus.forEach((s) => {
        const match = allSkus.find((as = {}) => as.id === s.id);
        // eslint-disable-next-line no-param-reassign
        if (match && match.link) s.link = match.link;
      });
    });
    return dupeCat;
  };

  const customSkuCategoriesAndSkus = categoriesByListOrder.map((category) => {
    /*
      - The data for this component will be partially hardcoded and partially from the BE data pulled from API.
      - The hardcoded constants file will either have subCategories/skus, or an empty array. An empty array
        indicates that we should grab the missing data from the BE data.
      - customSkuCategoriesAndSkus will be an object that matches the shape of the data we pull from the BE
    */
    const categorySkuInfoHardCoded = SUB_CATEGORIES.find((sc) => sc.id === category.id) || {
      id: null,
      subCategories: [],
    };
    const foundMatchingCategory =
      categorySkuInfoHardCoded.id && categorySkuInfoHardCoded.subCategories.length;
    const categorySkuInfo = foundMatchingCategory
      ? formatHardcodedSkuData(categorySkuInfoHardCoded)
      : formatSkuDataFromDB(category);
    return { ...category, ...categorySkuInfo };
  });

  return (
    <>
      <PrimaryMenu
        user={user}
        setCategoryMenuVisibleState={setCategoryMenuVisibleState}
        setServiceMenuVisibleState={setServiceMenuVisibleState}
        hideMembership={hideMembership}
        logout={logout}
        isMobile={isMobile}
      />
      <CategoryMenu
        visible={categoryMenuVisible}
        setCategoryMenuVisibleState={setCategoryMenuVisibleState}
        skuCategoriesAndSkus={customSkuCategoriesAndSkus}
        setSelectedCategory={setSelectedCategoryCB}
      />
      <ServiceMenuRepeatBookings
        visible={serviceMenuVisible}
        setServiceMenuVisibleState={setServiceMenuVisibleState}
        selectedCategory={selectedCategory}
        skuLinkPrefix={skuLinkPrefix}
        setSelectedSubCategory={setSelectedSubCategory}
        setSubCategoryMenuVisibleState={setSubCategoryMenuVisibleState}
      />
      <SubCategoryMenu
        visible={subCategoryMenuVisible}
        selectedCategory={selectedCategory}
        selectedSubCategory={selectedSubCategory}
        setSubCategoryMenuVisibleState={setSubCategoryMenuVisibleState}
        skuLinkPrefix={skuLinkPrefix}
      />
    </>
  );
};

const sharedPropTypes = {
  user: PropTypes.object,
  setCategoryMenuVisibleState: PropTypes.func,
  setServiceMenuVisibleState: PropTypes.func,
  hideMembership: PropTypes.bool,
  skuCategoriesAndSkus: PropTypes.object,
  logout: PropTypes.func,
  isMobile: PropTypes.bool,
  categoryMenuVisible: PropTypes.bool,
  setSelectedCategoryCB: PropTypes.func,
  serviceMenuVisible: PropTypes.bool,
  selectedCategory: PropTypes.object,
  skuLinkPrefix: PropTypes.string,
  selectedSubCategory: PropTypes.string,
};

const MenuLayouts = { Default, RepeatBooking };

Default.propTypes = { ...sharedPropTypes };
RepeatBooking.propTypes = { ...sharedPropTypes };

export default MenuLayouts;
