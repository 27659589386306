import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Elements, injectStripe } from 'react-stripe-elements';
import AsyncStripeProvider from 'src/components/AsyncStripeProvider';
import { submitPaymentForm } from 'src/components/PaymentForm';
import Tabs from 'Elements/Tabs';
import { ModalV2 } from 'HTKit/Modals/ModalV2';
import Button, { THEMES } from 'HTKit/Forms/Button';
import { ACCOUNT_PAGE_NAME } from 'src/containers/Account/AccountPage/sagas';
import { updateProfile } from 'src/containers/Account/AccountPage/actions';
import { updateCard } from 'src/containers/Account/PaymentPage/actions';
import { addErrorPageSnackNotice } from 'src/actions/snackNotice';
import { pureUserSelector } from 'src/selectors/user';
import { logger } from 'src/utils/logger';
import EVAccountTab from './EVAccountTab';
import EVBillingTab from './EVBillingTab';
import {
  TAB_LABELS,
  ACCOUNT_SCHEMA,
  SNACKBAR_ACCOUNT_TAB,
  SNACKBAR_BILLING_TAB,
} from './constants';
import styles from '../styles.scss';

const EVAccountModal = injectStripe(({ stripe, showAccountModal, toggleAccountModal }) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  // For EVBillingTab
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const userJS = useSelector(pureUserSelector);

  const isAccountTab = selectedTab === 0;
  const isBillingTab = selectedTab === 1;

  // EVAccountTab
  const initialValues = useMemo(() => {
    const { firstName, lastName, email, phone, addressObj } = userJS || {};
    const { address1, zip, state, city, address2 } = addressObj || {};
    return {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      phone: phone || '',
      address: `${address1}, ${city}, ${state}, ${zip}` || '',
      address2: address2 || '',
    };
  }, [userJS]);

  const updateProfileAction = (data) =>
    dispatch(
      updateProfile(data, null, {
        useSnackbar: true,
        snackNoticeOpts: { pageName: ACCOUNT_PAGE_NAME, componentName: SNACKBAR_ACCOUNT_TAB },
      }),
    );

  const accountFormik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ACCOUNT_SCHEMA,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values) => {
      updateProfileAction(values);
    },
  });

  useEffect(() => {
    if (!showAccountModal) {
      accountFormik.resetForm();
    }
  }, [showAccountModal]);

  const saveChanges = () => {
    if (isAccountTab) {
      accountFormik.handleSubmit();
    } else if (isBillingTab) {
      const { name } = userJS;
      submitPaymentForm(dispatch, stripe, { name })
        .then((token) => {
          dispatch(
            updateCard(token, {
              useSnackbar: true,
              snackNoticeOpts: { pageName: ACCOUNT_PAGE_NAME, componentName: SNACKBAR_BILLING_TAB },
            }),
          );
        })
        .catch((e) => {
          if (e) {
            logger('EV Billing Tab')(e);
            const { message = 'An error occurred processing your payment info.' } = e || {};
            dispatch(
              addErrorPageSnackNotice({
                content: message,
                pageName: ACCOUNT_PAGE_NAME,
                componentName: SNACKBAR_BILLING_TAB,
              }),
            );
          }
        });
    }
  };

  const disableSaveButton = () => {
    if (isAccountTab) {
      return false;
    }
    if (isBillingTab) {
      return !showPaymentForm;
    }
    return false;
  };

  return (
    <ModalV2
      header="Account Details"
      isVisible={showAccountModal}
      hide={toggleAccountModal}
      childrenClassName={styles.modalChildren}
      footerElement1={<></>}
      footerElement2={
        <Button
          type="button"
          mediumV2
          theme={THEMES.V2PRIMARY}
          onClick={saveChanges}
          disabled={disableSaveButton()}
        >
          Save Changes
        </Button>
      }
    >
      <Tabs
        actions={TAB_LABELS}
        selected={selectedTab}
        onChange={setSelectedTab}
        className={styles.tabs}
        tabsClasses={styles.tab}
        actionsClassName={styles.actions}
      >
        <EVAccountTab formik={accountFormik} />
        <EVBillingTab
          userJS={userJS}
          showPaymentForm={showPaymentForm}
          setShowPaymentForm={setShowPaymentForm}
        />
      </Tabs>
    </ModalV2>
  );
});

EVAccountModal.propTypes = {
  stripe: PropTypes.object,
  showAccountModal: PropTypes.bool.isRequired,
  toggleAccountModal: PropTypes.func.isRequired,
};

const EVAccountModalWrapper = (props) => {
  return (
    <AsyncStripeProvider>
      <Elements>
        <EVAccountModal {...props} />
      </Elements>
    </AsyncStripeProvider>
  );
};

EVAccountModalWrapper.propTypes = {
  showAccountModal: PropTypes.bool.isRequired,
  toggleAccountModal: PropTypes.func.isRequired,
};

export default EVAccountModalWrapper;
