export const noop = () => ({});
export const prevent = (event) => event.preventDefault();
export const prevented = (fn) => (event) => {
  if (event.stopPropagation) event.stopPropagation();
  event.preventDefault();
  fn();
};
export const stopImmediatePropagation = (fn) => (event) => {
  if (event.stopPropagation) event.stopPropagation();
  if (event.nativeEvent.stopImmediatePropagation) event.nativeEvent.stopImmediatePropagation();
  fn(event);
};

export const syntheticEventCreation = value => {
  return {
    target: { value },
    preventDefault: noop,
    stopPropagation: noop
  };
};
