import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'HTKit/Icon';
import styles from './styles.scss';

const ModalCloseButton = ({ buttonStyle = '', onClick }) => (
  <button
    className={cn('plainButton text-link -no-decoration', styles.modalCloseContainer, buttonStyle)}
    onClick={onClick}
  >
    <Icon name="v2-close-icon" className={styles.modalCloseIcon} />
    <span>Close</span>
  </button>
);

ModalCloseButton.propTypes = {
  buttonStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func.isRequired,
};

export default React.memo(ModalCloseButton);
