import HT_INFO from 'src/constants/hellotech';

export const displayPhone = (phone) => {
  if (phone === HT_INFO.phone.customers.empty.title) return null;
  return phone === '1---' ? HT_INFO.phone.customers.default.title : phone;
};

/*
  Format a string representation of a phone number
  Example:
    '8006409005' -> (800) 640 9005
*/
export const formatPhoneBasic = (phone = '', seperator = ' ') => {
  // Extract only the digits
  const reg = /(\d{3})(\d{3})(\d{4})/;
  return phone.replace(/\D/g, '').replace(reg, `($1) $2${seperator}$3`);
};

/**
 *  Format a string representation of a phone number with dashes only
 *
 *  '8006409005' -> 1-800-640-9005
 */
export const formatPhoneDash = (phone = '') => {
  const stripped = phone.replace(/\D/g, '');
  const regexMatch = stripped.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (regexMatch) {
    const [, intlCode, areaCode, number1, number2] = regexMatch;
    return `${intlCode || '1'}-${areaCode}-${number1}-${number2}`;
  }
  return phone;
};

/**
 *  Format a string representation of a phone number for use in anchor's hyperlink
 *
 *  '800-640-9005' -> 'tel:18006409005'
 */
export const formatPhoneHref = (phone = '') => {
  const stripped = phone.replace(/\D/g, '');
  const regexMatch = stripped.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (regexMatch) {
    const [, intlCode, areaCode, number1, number2] = regexMatch;
    return `tel:${intlCode || '1'}${areaCode}${number1}${number2}`;
  }
  return `tel:${phone}`;
};
