export default class GeoMap {
  constructor() {
    if (!process.browser || GeoMap.loaded) {
      return;
    }
    const script = document.createElement('script');
    script.setAttribute('src', 'https://api.tiles.mapbox.com/mapbox.js/v2.1.5/mapbox.js');
    let resolvePromise;
    GeoMap.loaded = new Promise((resolve) => {
      resolvePromise = resolve;
    });
    script.onload = resolvePromise;
    document.body.appendChild(script);
    GeoMap.loaded.then(() => {
      global.L.mapbox.accessToken = 'pk.eyJ1IjoiYXNoYWJhbm92IiwiYSI6Ink3V1pWSjgifQ.Vm9ldfv6k5aoc1YsXQIDvA';
    });
    const stylesheet = document.createElement('link');
    stylesheet.setAttribute('href', 'https://api.tiles.mapbox.com/mapbox.js/v2.1.5/mapbox.css');
    stylesheet.setAttribute('type', 'text/css');
    stylesheet.setAttribute('rel', 'stylesheet');
    document.head.appendChild(stylesheet);
    // document.head.insertBefore(stylesheet, document.head.firstChild);
  }

  defaultConfig = {
    element: 'map',
    mapType: 'examples.xqwfusor',
    mapConfig: { zoomControl: false }
  };

  getMap(config = this.defaultConfig) {
    return GeoMap.loaded.then(() => {
      return window.L.mapbox.map(config.element, config.mapType, config.mapConfig);
    });
  }

  changeLayer(map, layerName) {
    const layer = window.L.mapbox.tileLayer(layerName);

    if (GeoMap.lastLayer) {
      map.removeLayer(GeoMap.lastLayer);
    }

    layer.addTo(map);
    GeoMap.lastLayer = layer;
  }

  changeZoom(map, zoom) {
    map.setZoom(zoom);
  }

  clearLayers(map) {
    map.eachLayer((layer) => {
      map.removeLayer(layer);
    });
    map.off();
    map.remove();
  }

  makeStrict(map) {
    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    if (map.tap) map.tap.disable();
    return this;
  }

  defineCenter(map, config) {
    map.setView(config.coords, config.zoom);
    return this;
  }
}
