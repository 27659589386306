import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const FluidGrid = ({ children, classes }) => {
  const fluidGridStyles = cn('grid', classes);
  return <div className={fluidGridStyles}>{children}</div>;
};

const FullWidthGrid = ({ children, classes }) => {
  const fullWidthGridStyles = cn('grid-full-width', classes);
  return <div className={fullWidthGridStyles}>{children}</div>;
};

const Row = ({ children, classes }) => {
  const rowStyles = cn('row', classes);
  return <div className={rowStyles}>{children}</div>;
};

const Column = ({ children, classes, sm, smOffset, md, mdOffset, lg, lgOffset }) => {
  const smStyles = sm && `col-sm-${sm}`;
  const smOffsetStyles = Number.isInteger(smOffset) && `col-sm-offset-${smOffset}`;
  const mdStyles = md && `col-md-${md}`;
  const mdOffsetStyles = Number.isInteger(mdOffset) && `col-md-offset-${mdOffset}`;
  const lgStyles = lg && `col-lg-${lg}`;
  const lgOffsetStyles = Number.isInteger(lgOffset) && `col-lg-offset-${lgOffset}`;
  const genericColumn = !sm && !md && !lg && 'col'; // .col is styled with flex:1
  const columnStyles = cn({
    [smStyles]: Boolean(smStyles),
    [smOffsetStyles]: Boolean(smOffsetStyles),
    [mdStyles]: Boolean(mdStyles),
    [mdOffsetStyles]: Boolean(mdOffsetStyles),
    [lgStyles]: Boolean(lgStyles),
    [lgOffsetStyles]: Boolean(lgOffsetStyles),
    [genericColumn]: Boolean(genericColumn),
    [classes]: Boolean(classes),
  });
  return <div className={columnStyles}>{children}</div>;
};

const Grid = {
  Fluid: FluidGrid,
  FullWidth: FullWidthGrid,
  Row,
  Column,
};

FluidGrid.propTypes = { children: PropTypes.any, classes: PropTypes.string };
FullWidthGrid.propTypes = { children: PropTypes.any, classes: PropTypes.string };
Row.propTypes = { children: PropTypes.any, classes: PropTypes.string };
Column.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.string,
  sm: PropTypes.number,
  smOffset: PropTypes.number,
  md: PropTypes.number,
  mdOffset: PropTypes.number,
  lg: PropTypes.number,
  lgOffset: PropTypes.number,
};

export default Grid;

/*
  Grid: A wrapper that returns styled HTML elements meant to be used with our custom layout grid.
    Structure should be Grid > Row > Column
    See src/styles/global_styles/_ht_grid.scss that this component applies.


  FluidGrid: Grid with fixed margins at smaller breakpoints. Past the largest breakpoint the margins become 0 auto.
  FullWidthGrid: Grid that stretches 100% with no margins.
  Row: By default Rows have no vertical spacing, but do have horizontal gutters.
  Column: Define your column widths here. If no width props are provided then a generic .col class will return which is styled with flex: 1

  ----------------------
  Design System Specs

  Columns per breakpoint:
  sm => 4 columns
  md => 8 columns
  lg => 12 columns

  ----------------------
  Example Use:

  import { Grid } from 'HTKit/Grid';

  <Grid.Fluid>
    <Grid.Row>
      <Grid.Column sm={2}>Column 1</Grid.Column>
      <Grid.Column sm={2}>Column 2</Grid.Column>
    </Grid.Row>
  </Grid.Fluid>

  - The desired width of your column should be considered to be a fraction of the total columns at a target breakpoint.
    So choosing sm=3 is 3(desired columns)/4(total columns) or 75% width. At lg breakpoint 3(desired columns)/12(total columns) is 25%.
  - The underlying CSS is mobile first. Meaning that any decisions you make at smaller sizes will cascade up to larger sizes. This has implications
    if you decide to apply custom gutters or margin at a particular breakpoint (e.g., md), and then want to return to the Grid defaults for lg and above.
    See src/styles/global_styles/_ht_grid.scss for instructions on how to do this.

  Example 1: Column should be 100% width across all breakpoints => <Grid.Column sm={4}>Column 1</Grid.Column>
  Example 2: Column should be 100% width at sm, 50% width at md, and 33% width at lg => <Grid.Column sm={4} md={4} lg={4}>Column 1</Grid.Column>
  ----------------------
  1. FluidGrid
      Purpose: This grid has default margins. Best used as an outer container.
      Props:
        - classes: Any additional CSS classes  to pass, e.g. overrides.

  2. FullWidthGrid
      Purpose:  This grid has no margins. Best used as an inner container within a FluidGrid or other
                component that has its width constricted.
      Props:
        - classes: Any additional CSS classes  to pass, e.g. overrides.

  3. Row
      Purpose:  Meant to hold <Grid.Column> components and be nested inside a <Grid.Fluid> or <Grid.FullWidth> component.
                Negative left and right margins are applied to horizontally align content with the edge of the parent.
      Props:
        - classes: Any additional CSS classes  to pass, e.g. overrides.

  4. Column
      Purpose:  Meant to hold your content and be nested inside a <Grid.Row> component. Pass props to define desired widths at targeted breakpoints (e.g., sm, md, lg).
      Props:
        - classes: Any additional CSS classes  to pass, e.g. overrides.
        - lg: desired width at lg breakpoint
        - md: desired width at md breakpoint
        - sm: desired width at sm breakpoint
        - lgOffset: desired offset width at lg breakpoint
        - mdOffset: desired offset width at md breakpoint
        - smOffset: desired offset width at sm breakpoint

*/
