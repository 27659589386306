import React from 'react';
import cn from 'classnames';
// Data
import HT_INFO from 'src/constants/hellotech';
// Images && Styles
import facebook from 'src/images/icons/facebook.svg';
import linkedin from 'src/images/icons/linkedin.svg';
import twitter from 'src/images/icons/twitter.svg';
import styles from '../styles.scss';

const icons = [
  { name: 'facebook', icon: facebook, link: HT_INFO.siteLinks.facebook.url },
  { name: 'twitter', icon: twitter, link: HT_INFO.siteLinks.twitter.url },
  { name: 'linkedin', icon: linkedin, link: HT_INFO.siteLinks.linkedin.url },
];

const FooterSocial = () => {
  return (
    <div className={cn(styles.socialIconContainer, 'marginLeft-tiny1')}>
      {icons.map((item) => (
        <a key={item.name} href={item.link}>
          <img src={item.icon} className={styles.socialIcon} />
        </a>
      ))}
    </div>
  );
};

export default FooterSocial;
