/* eslint-disable import/no-cycle */
import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// HOC
import withLayout from 'src/hoc/withLayout';
// Components
import IframeView from 'src/components/Elements/IframeView';
// Constants
import { DEFAULT_LAYOUT } from 'src/constants/common';
import {
  CALLER_PAGE,
  iframeSourceString,
} from './constants';

import { messagePageActions } from './messagePageActions';

const ServicesPage = (props) => {
  return (
    <>
      {!props.hideTitle && <Helmet title="Services Page" />}
      <IframeView
        callerPageName={CALLER_PAGE}
        src={iframeSourceString}
        additionalActions={messagePageActions}
      />
    </>
  );
};

ServicesPage.propTypes = {
  src: PropTypes.string,
  hideTitle: PropTypes.bool,
};

export const ServicesPagewithoutLayout = React.memo(ServicesPage);

export default React.memo(withLayout(DEFAULT_LAYOUT)(ServicesPage));
