import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

export default class MultipleAccountSkuHero extends Component { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    landing: PropTypes.shape({}).isRequired,
    isMobile: PropTypes.bool.isRequired,
    onLinkClick: PropTypes.func.isRequired,
  };

  render() {
    const { landing, isMobile, onLinkClick } = this.props;
    const link = landing.get('heroImageLink');
    const image = isMobile ? landing.getIn(['images', 'front', 'mobile']) : landing.getIn(['images', 'front', 'original']);
    const alt = landing.get('pageTitle');
    const heroImage = <img alt={alt} src={image} className={styles.heroImg} />;
    return (
      <section className={styles.section}>
        { link ? (
          <a href={link} onClick={() => onLinkClick(link)}>
            { heroImage }
          </a>
        ) :
          heroImage
        }
      </section>
    );
  }
}
