import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { prevented } from 'src/utils/event';
import { accountPaymentPath, resetPasswordPath } from 'src/utils/paths';
import styles from './styles.scss';

const UpdateNowComponent = ({ copyStyles, templateData, openLoginModal }) => {
  const isLoggedIn = templateData.get('isLoggedIn');
  const hasSetPassword = templateData.get('hasSetPassword');
  const openModal = prevented(() => openLoginModal());
  const updateCopy = 'Update Now.';
  const updateCopyStyles = cn(copyStyles, styles.link);

  const LinkDisplay = url => <Link className={updateCopyStyles} to={url()}>{updateCopy}</Link>

  let component = LinkDisplay(accountPaymentPath);

  if (!isLoggedIn) {
    if (hasSetPassword) {
      component = (
        <button className={`plainButton ${updateCopyStyles}`} onClick={openModal}>
          {updateCopy}
        </button>
      );
    } else {
      component = LinkDisplay(resetPasswordPath);
    }
  }
  return component;
};

const AuthFailed = (props) => {
  const { copyStyles } = props;
  return (
    <p className={copyStyles}>
      Card preauthorization failed, please update your billing information.&nbsp;
      <UpdateNowComponent {...props} />
    </p>
  );
};

AuthFailed.propTypes = {
  copyStyles: PropTypes.string,
  templateData: PropTypes.shape({
    isLoggedIn: PropTypes.bool.isRequired,
    hasSetPassword: PropTypes.bool,
  }),
  openLoginModal: PropTypes.func.isRequired,
};

AuthFailed.defaultProps = {
  copyStyles: '',
};

export default AuthFailed;
