import React from 'react';
import PropTypes from 'prop-types';
import { CalloutBox } from 'src/components/Elements/CalloutBox';

const VirtualSiteVisitCalloutBox = ({ className }) => {
  return (
    <CalloutBox
      header="After you book, schedule your FREE virtual site visit"
      text="This quick 15-minute video call helps us make certain we can perform your service exactly the way you want it."
      className={className}
    />
  );
};

export default VirtualSiteVisitCalloutBox;

VirtualSiteVisitCalloutBox.propTypes = {
  className: PropTypes.string,
};
