import {
  TRIGGER_AUTO_RENEW,
  REQUEST_CANCELLATION,
  REDEEM,
  SET_REDEMPTION_CODE,
  TOGGLE_MODAL,
  SET_STATS,
  UPDATE_AUTO_RENEW_STATUS,
  SET_TRANSACTIONS,
  SET_MEMBERSHIP,
  AUTO_RENEW_TOGGLED,
  CANCEL_SUBSCRIPTION,
} from './constants';

export const triggerAutoRenew = () => ({
  type: TRIGGER_AUTO_RENEW,
});

export const cancelSubscription = (payload) => ({
  type: CANCEL_SUBSCRIPTION,
  payload,
});

export const requestCancellation = () => ({
  type: REQUEST_CANCELLATION,
});

export const redeem = ({ skuId, modalName }) => ({
  type: REDEEM,
  skuId,
  modalName,
});

export const setRedemptionCode = ({ skuId, code }) => ({
  type: SET_REDEMPTION_CODE,
  skuId,
  code,
});

export const toggleModal = ({ modalName, forceState }) => ({
  type: TOGGLE_MODAL,
  modalName,
  forceState,
});

export const setStats = ({ statistic }) => ({
  type: SET_STATS,
  statistic,
});

export const setTransactions = ({ transactions }) => ({
  type: SET_TRANSACTIONS,
  transactions,
});

export const setMembership = ({ membership }) => ({
  type: SET_MEMBERSHIP,
  membership,
});

export const updateAutoRenewStatus = ({ status, renewLocation, cancellationId }) => ({
  type: UPDATE_AUTO_RENEW_STATUS,
  status,
  renewLocation,
  cancellationId,
});

export const autoRenewSegmentEvent = ({ toggleStatus, subscription }) => {
  return {
    type: AUTO_RENEW_TOGGLED,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: 'Auto Renew Toggled',
            properties: {
              toggle_status: toggleStatus,
              subscription_id: subscription.subscriptionInfo.id,
              subscription_status: subscription.subscriptionInfo.status,
              subscription_plan_id: subscription.planInfo.id,
              subscription_plan_name: subscription.planInfo.name,
              subscription_amount: subscription.planInfo.amount,
              subscription_plan_interval: subscription.planInfo.planInterval,
              subscription_benefits_used: subscription.servicesUsed,
              subscription_benefits_remaining: subscription.unclaimedBenefits,
              subscription_created_at: subscription.subscriberSince.iso,
              subscription_renew_at: subscription.renewsOn.iso,
              subscription_auto_renew: subscription.subscriptionInfo.willAutoRenew,
              subscription_partner: subscription.planInfo.partnerId,
              subscription_cancelled_at: subscription.subscriptionInfo.cancelAt,
              subscription_renewed_into_monthly: subscription.subscriptionInfo.renewedIntoMonthly,
              subscription_account_url: subscription.subscriptionAccountUrl,
            },
          },
        },
      ],
    },
  };
};
