import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { chunk } from 'lodash';
import { Grid } from 'src/components/HTKit/Grid';
import styles from '../styles.scss';

const Benefits = ({ benefitsList }) => {
  const chunkedList = useMemo(() => {
    return chunk(benefitsList, 2);
  }, [benefitsList]);

  return (
    <Grid.FullWidth classes="paddingTop-medium1">
      {chunkedList.map((chunkedBenefits, index) => (
        <Grid.Row classes={styles.benefitsContainer} key={index}>
          {chunkedBenefits.map((benefit) => {
            const { header, subheader, icon } = benefit;
            return (
              <>
                {/* DesktopView */}
                <>
                  <Grid.Column key={header} sm="hidden" lg={1} classes={styles.imageContainer}>
                    <img src={icon} className={styles.image} />
                  </Grid.Column>
                  <Grid.Column
                    sm="hidden"
                    lg={5}
                    key={header}
                    classes={styles.benefitsTextContainer}
                  >
                    <h6 className={styles.benefitName}>{header}</h6>
                    <p className={cn('p2 n700', styles.description)}>{subheader}</p>
                  </Grid.Column>
                </>
                {/* Mobile view */}
                <Grid.Row classes={cn('paddingBottom-medium', styles.mobileBenefitsContainer)}>
                  <Grid.Column lg={1} key={header} classes={styles.imageContainer}>
                    <img src={icon} className={styles.image} />
                  </Grid.Column>
                  <Grid.Column classes="paddingLeft-small" lg={5} key={header}>
                    <h6 className={styles.benefitName}>{header}</h6>
                    <p className="p2 n700 marginTop-tiny">{subheader}</p>
                  </Grid.Column>
                </Grid.Row>
              </>
            );
          })}
        </Grid.Row>
      ))}
    </Grid.FullWidth>
  );
};

Benefits.propTypes = {
  benefitsList: PropTypes.array,
};

export default Benefits;
