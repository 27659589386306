import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'src/components/HTKit/Grid';
import Icon from 'src/components/HTKit/Icon';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { homePath } from 'src/utils/paths';
import { isDevelopment } from 'src/utils/env';
import HTKustomerChat from 'src/utils/Kustomer/HTKustomerChat';
import blob from 'src/images/blobs/blueblob.svg';
import styles from './styles.scss';

const AppLevelFallback = ({ error }) => {
  const goHome = () => {
    window.location.href = homePath();
  };

  const openChat = () => {
    HTKustomerChat.open();
  };

  let errorString;
  if (error instanceof Error) {
    errorString = JSON.stringify(error, Object.getOwnPropertyNames(error));
  } else {
    errorString = JSON.stringify(error);
  }

  return (
    <div className={`site-v2 ${styles.mainContainer}`}>
      <header className={styles.header}>
        <nav>
          <Grid.Fluid>
            <Grid.Row>
              <Grid.Column>
                <a href={homePath()}>
                  <Icon name="ht-logo" className={styles.logo} />
                </a>
              </Grid.Column>
            </Grid.Row>
          </Grid.Fluid>
        </nav>
      </header>
      <section className={`paddingY-huge1 ${styles.section}`}>
        {!isDevelopment() ? (
          <Grid.Fluid>
            <Grid.Row>
              <Grid.Column sm={4} mdOffset={1} md={6} lgOffset={4} lg={4} classes={styles.column}>
                <h2>Looks like this page is unavailable</h2>
                <p className="p1 marginTop-small">
                  Don&apos;t worry, our highly-trained technicians are still available to assist
                  you with your tech needs.
                </p>
                <div className={styles.buttonContainer}>
                  <Button onClick={goHome} theme={THEMES.V2INVERTED} className={`marginTop-medium2 ${styles.button}`}>
                    Book a Service
                  </Button>
                  <Button onClick={openChat} theme={THEMES.V2INVERTED} className={`marginTop-small1 ${styles.button}`}>
                    Chat with Us
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid.Fluid>
        ) : (
          <Grid.Fluid>
            <Grid.Row>
              <Grid.Column sm={4} mdOffset={1} md={6} lgOffset={2} lg={8} classes={styles.column}>
                <h2>Something borked up</h2>
                <p className="marginY-small">
                  It&apos;s cool. You got this. &#128170;
                </p>
                <p><code>{errorString}</code></p>
              </Grid.Column>
            </Grid.Row>
          </Grid.Fluid>
        )}
        <img src={blob} alt="" className={styles.blob} />
      </section>
    </div>
  );
};

AppLevelFallback.propTypes = {
  error: PropTypes.object,
};

export default memo(AppLevelFallback);
