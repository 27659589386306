import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Selectors
import {
  productOnlySkusSelector,
  breakdownItemsSelector,
  estimatedCartBreakdownItemsSelector,
} from 'src/containers/CartPage/selectors';
import { cartHasPinsSelector } from 'src/selectors/cart';
// Actions
import { updateQuantity } from 'src/containers/CartPage/actions';
// Constants
import { QA_FLOW_VALID_SKUS } from 'src/containers/AddSkuPage/QuestionsFlowPage/constants';
import { skuPath, push } from 'src/utils/paths';
// Utils
import { isMobileV2, isTabletV2 } from 'src/utils/ui';
import { formatPriceTypeHourly, isProductOnlySku } from 'src/containers/CartPage/utils';
import { filterDiscountsFromAdjustments } from 'src/containers/CartPage/Parts/ServicesBreakdown/utils';
// Components
import LazyView from 'src/components/LazyLoad/LazyView';
import QtyDropdown from 'src/components/HTKit/Elements/QtyDropdown';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import ProductInstallationUpsell from 'src/containers/CartPage/Parts/ServicesBreakdown/ProductInstallationUpsell/lazy';
import { Row, NameColumn, PriceColumn } from './TableParts';
import PlanItem from './PlanItem';
import styles from './styles.scss';
import { BREAKDOWN_PRICE_TYPES } from 'src/containers/CartPage/constants';

const isMobileOrTablet = isMobileV2() || isTabletV2();
/**
  SectionHeader

  Top section of ItemSection. Holds the sku name and prices.
*/
const SectionHeader = ({ open, item, handleToggle, isPartner }) => {
  const skuName = item.name;
  const priceComponent = (
    <div className={styles.sectionHeaderSkuPriceWrapper}>
      {item.subTotal.lineThroughText && (
        <p className="p2 n300 text-weight-med line-through paddingRight-tiny1">
          {item.subTotal.lineThroughText}
        </p>
      )}
      <p className="h6">
        {formatPriceTypeHourly({ service: item, price: item.subTotal.amount, withAsterik: true })}
      </p>
    </div>
  );
  const { skuSeoName, quantity, priceType } = item;
  const dispatch = useDispatch();

  const handleClickOnSku = () => {
    dispatch(push(skuPath(skuSeoName)));
  };
  const handleDropdownChange = (selectedValue) => {
    const qty = selectedValue.value;
    const { index } = item;
    dispatch(updateQuantity(qty, index));
  };
  const cartHasPins = useSelector(cartHasPinsSelector);
  const isApiCart = isPartner && cartHasPins;
  const isHourlyService = priceType === BREAKDOWN_PRICE_TYPES.hourly;
  return (
    <Row>
      <NameColumn
        showChevron
        item={item}
        open={open}
        handleToggle={handleToggle}
        nameColumnStyle={styles.sectionHeaderNameColumn}
        image={item.image}
      >
        <p
          className={classNames('text-weight-bold p1', styles.skuLink, styles.sectionHeaderSkuName)}
          onClick={handleClickOnSku}
        >
          {skuName}
        </p>
      </NameColumn>
      <div className={styles.flexColumn}>
        <PriceColumn>
          <QtyDropdown
            shouldShowDropdown={!isApiCart && !isHourlyService}
            dropdownValue={quantity}
            onDropdownChange={handleDropdownChange}
            quantity={quantity}
            className={styles.qtyDropdown}
          />
        </PriceColumn>
        <PriceColumn>{priceComponent}</PriceColumn>
      </div>
    </Row>
  );
};

SectionHeader.propTypes = {
  open: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  handleToggle: PropTypes.func.isRequired,
  isPartner: PropTypes.bool,
};

/**
  Base Price

  Only show this if there are discounts applied
 */

const BasePrice = ({ item, open }) => {
  if (!open) return null;
  const adjStyles = !isMobileOrTablet ? 'paddingLeft-small2' : '';
  return (
    <Row rowStyle={styles.adjustmentRow}>
      <NameColumn>
        <p className={classNames('p1 n700', adjStyles)}>Base Price</p>
      </NameColumn>
      <PriceColumn>
        <p className="p1 n700">{item.amount}</p>
      </PriceColumn>
    </Row>
  );
};

BasePrice.propTypes = { item: PropTypes.object.isRequired, open: PropTypes.bool.isRequired };

/**
  Adjustments

  Shows the item's adjustments information.
*/
const Adjustments = ({ open, item }) => {
  if (!open) return null;
  const { adjustments } = item;
  const filteredAdjs = filterDiscountsFromAdjustments(adjustments);
  const adjStyles = !isMobileOrTablet ? 'paddingLeft-small2' : '';
  return filteredAdjs.map((adj, index) => (
    <Row rowStyle={styles.adjustmentRow} key={index}>
      <NameColumn>
        <p className={classNames('p1 n700', adjStyles)}>+ {adj.name}</p>
      </NameColumn>
      <PriceColumn>
        <p className="p1 n700">{adj.amount}</p>
      </PriceColumn>
    </Row>
  ));
};

Adjustments.propTypes = { open: PropTypes.bool.isRequired, item: PropTypes.object.isRequired };

const ServiceDiscounts = ({ item, open }) => {
  if (!item.serviceDiscounts.length || !open) return null;
  const discStyles = !isMobileOrTablet ? 'paddingLeft-small2' : '';
  return item.serviceDiscounts.map((disc, i) => (
    <Row rowStyle={styles.adjustmentRow} key={i}>
      <NameColumn>
        <p className={classNames('p1 blue700', discStyles)}>{disc.name}</p>
      </NameColumn>
      <PriceColumn>
        <p className="p1 blue700">{disc.amount}</p>
      </PriceColumn>
    </Row>
  ));
};

ServiceDiscounts.propTypes = {
  open: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
};

/**
  Footer

  The bottom section of ItemSection. Holds item actions and prices.
*/
const Footer = ({ item, removeItemHandler, goToEditSkuHandler, isPartner, productOnlySkus }) => {
  const { quantity, priceType } = item;
  const itemId = item.skuId;
  const dispatch = useDispatch();
  const editStyles = classNames('plainButton', styles.editButton);
  const handleDropdownChange = (selectedValue) => {
    const qty = selectedValue.value;
    const { index } = item;
    dispatch(updateQuantity(qty, index));
  };
  const cartHasPins = useSelector(cartHasPinsSelector);
  const isApiCart = isPartner && cartHasPins;
  const footerStyles = !isMobileOrTablet ? 'paddingLeft-small2' : '';
  const isHourlyService = priceType === BREAKDOWN_PRICE_TYPES.hourly;

  const editText = 'Edit Details';
  const editRemoveDivider = ' ';
  const editTextStyles = 'p2 text-link text-weight-med';

  const showEditButton =
    !isProductOnlySku(itemId, productOnlySkus) && !QA_FLOW_VALID_SKUS.includes(itemId);
  return (
    <Row rowStyle={classNames(styles.footerRow, footerStyles)}>
      <NameColumn>
        {showEditButton && (
          <>
            <button className={editStyles} onClick={goToEditSkuHandler}>
              <p className={editTextStyles}>{editText}</p>
            </button>
            {<span className={styles.editRemoveDivider}>{editRemoveDivider}</span>}
          </>
        )}
        <button className="plainButton" onClick={removeItemHandler}>
          <p className={editTextStyles}>Remove</p>
        </button>
      </NameColumn>
      <PriceColumn priceColumnStyle={styles.qtyPriceCol}>
        <QtyDropdown
          shouldShowDropdown={!isApiCart && !isHourlyService}
          dropdownValue={quantity}
          onDropdownChange={handleDropdownChange}
          quantity={quantity}
        />
      </PriceColumn>
    </Row>
  );
};

Footer.propTypes = {
  item: PropTypes.object.isRequired,
  removeItemHandler: PropTypes.func.isRequired,
  goToEditSkuHandler: PropTypes.func.isRequired,
  isPartner: PropTypes.bool,
  productOnlySkus: PropTypes.array,
};

/**
  ItemSection

  This component is used for each cart item.
*/

const ItemSection = ({
  item,
  removeItem,
  goToEditSku,
  isPartner,
  isUpsellCart = false,
  plansInfo = { monthly: {} },
}) => {
  const { adjustments, skuId, index: itemIndex, priceType } = item;
  const filteredAdjs = filterDiscountsFromAdjustments(adjustments);
  const totalAdjustments = [...filteredAdjs, item.serviceDiscounts];
  const [showDetails, toggleDetails] = useState(totalAdjustments.length > 0);
  const handleToggle = () => toggleDetails(!showDetails);
  const removeItemHandler = () => removeItem(item, itemIndex);
  const goToEditSkuHandler = goToEditSku({ skuId, index: itemIndex });

  const { splitTreatment, splitLoaded } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_CART_PRODUCT_INSTALLATION_UPSELL,
  );
  const productOnlySkus = useSelector(productOnlySkusSelector);
  const showProductInstallationUpsell =
    splitLoaded &&
    splitTreatment === splitioConstants.ON &&
    isProductOnlySku(skuId, productOnlySkus);

  const { id: planIdForEstimate } = plansInfo.monthly;
  const isPriceTypeHourly = priceType === BREAKDOWN_PRICE_TYPES.hourly;
  return (
    <div className={styles.itemSection}>
      <SectionHeader
        open={showDetails}
        item={item}
        handleToggle={handleToggle}
        isPartner={isPartner}
      />
      {!isPriceTypeHourly ? <BasePrice item={item} open={showDetails} /> : null}
      <Adjustments open={showDetails} item={item} />
      <ServiceDiscounts open={showDetails} item={item} />
      <Footer
        open={showDetails}
        item={item}
        productOnlySkus={productOnlySkus}
        removeItemHandler={removeItemHandler}
        goToEditSkuHandler={goToEditSkuHandler}
        isPartner={isPartner}
      />
      {showProductInstallationUpsell && (
        <LazyView>
          <ProductInstallationUpsell
            productId={skuId}
            index={itemIndex}
            quantity={item.quantity}
            isUpsellCart={isUpsellCart}
            item={item}
            planIdForEstimate={planIdForEstimate}
          />
        </LazyView>
      )}
    </div>
  );
};

ItemSection.propTypes = {
  item: PropTypes.object.isRequired,
  removeItem: PropTypes.func.isRequired,
  goToEditSku: PropTypes.func.isRequired,
  isPartner: PropTypes.bool,
  isUpsellCart: PropTypes.bool,
  plansInfo: PropTypes.object,
};

/**
  ServicesBreakdown

  This is the main wrapper.
*/

let itemId = 0;

const ServicesBreakdown = ({
  cart,
  planDetails,
  removeItem,
  removePlan,
  goToEditSku,
  showPlanItem,
  isUpsellCart,
  plansInfo,
}) => {
  // Services & Products
  const breakdownItems = useSelector(breakdownItemsSelector); // Non-member cart pricing
  const estimatedCartBreakdownItems = useSelector(estimatedCartBreakdownItemsSelector); // Estimated Member cart pricing
  const items = isUpsellCart ? estimatedCartBreakdownItems : breakdownItems;

  // Plan Item
  const planId = cart.getIn(['plan', 'id']);

  const isPartner = Boolean(cart.get('partner'));

  // Using this hack since there is no unique keys to work with. Need to add index for sku edit.
  const itemsWithKeys = useMemo(() => {
    const modifiedItems = items.map((item, index) => {
      const itemWithKey = { ...item, itemKey: itemId, index };
      itemId += 1;
      return itemWithKey;
    });
    return modifiedItems.reverse();
  }, [items, isUpsellCart]);

  return itemsWithKeys.length > 0 || planId ? (
    <div>
      <PlanItem
        cart={cart}
        removePlan={removePlan}
        planDetails={planDetails}
        showPlanItem={showPlanItem}
      />
      {itemsWithKeys.map((item) => {
        return (
          <ItemSection
            key={item.itemKey}
            item={item}
            removeItem={removeItem}
            goToEditSku={goToEditSku}
            isPartner={isPartner}
            isUpsellCart={isUpsellCart}
            plansInfo={plansInfo}
          />
        );
      })}
    </div>
  ) : null;
};

ServicesBreakdown.propTypes = {
  removeItem: PropTypes.func.isRequired,
  removePlan: PropTypes.func.isRequired,
  goToEditSku: PropTypes.func.isRequired,
  cart: PropTypes.object,
  planDetails: PropTypes.object,
  showPlanItem: PropTypes.bool,
  isUpsellCart: PropTypes.bool,
  plansInfo: PropTypes.object,
};

ServicesBreakdown.defaultProps = {
  showPlanItem: true,
};

export default ServicesBreakdown;

// This component gets rendered in the Tabbed version of the upsell
