// Libraries
import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';
// Hooks && Utils
import { useSchedulingEditModePreSeed } from 'src/containers/BookingPage/AvailabilityPage/hooks';
import { normalizeAvailabilityForWindowBasedView } from 'src/components/AvailabilitySelectorWithConfig/Scheduling.utils';
import { useCartServiceScheduling } from 'src/utils/serviceScheduling/useCartServiceScheduling';
import { useSplitIOTreatment, splitioConstants } from 'src/components/SplitIO';
// Actions & Selectors
import { inEditModeSelector } from 'src/selectors/router';
import { pureCartSelector } from 'src/selectors/cart';
// Data
import { BOOKING_STAGES } from 'src/containers/BookingPage/constants';
import { TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES } from 'src/components/AvailabilitySelectorWithConfig/Scheduling.constants';
import { AVAILABILITY_FORM_ID } from './constants';
import {
  SCHEDULER_VIEW_TYPES,
  SCHEDULING_ACTIONS,
} from 'src/utils/serviceScheduling/serviceScheduling.constants';
// Components
import { LegacyTimeSelector } from './Legacy/TimeSelector/AvailabilityPage.TimeSelector';
import { LegacyTimeManager } from './Legacy/TimeSelector/AvailabilityPage.TimeManager';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { InvalidDatesModal, RecommendedDatesModal } from './Modals';
import { HeaderBlock } from './AvailabilityPage.HeaderBlock';
import AvailabilityPageGrid from './AvailabilityPage.Grid';
import { AvailabilitySelectorSelectTimeWindow } from 'src/components/AvailabilitySelectorSelectTimeWindow';
import { AvailabilitySelectorExactTime } from 'src/components/AvailabilitySelectorExactTime/AvailabilitySelectorExactTime';
// Styles, etc.
import styles from './styles.scss';

const SubmitButton = (props) => (
  <Button theme={THEMES.V2PRIMARY} {...props}>
    Continue
  </Button>
);
const AvailabilityPage = ({
  loadPage,
  submitAvailability,
  toggleModal,
  cart,
  page,
  currentStage,
  availability,
  numSelectedHours,
  selectedHours,
  partner,
}) => {
  const pureCart = useSelector(pureCartSelector);
  const { getAvailabilityPageConfig, isTimeWindowSchedule } = useCartServiceScheduling({
    cart: pureCart,
  });
  const availabilityPageConfig = getAvailabilityPageConfig({ useTimeWindowGrid: false });

  /** Redux value that gets set when the user clicks edit from the Summary page */
  const isEditMode = useSelector(inEditModeSelector);

  const { schedulerViewType } = availabilityPageConfig;
  const useExactTimeScheduler = schedulerViewType === SCHEDULER_VIEW_TYPES.EXACT_TIME_SELECT;

  /** Feature flag for time window dropdown */
  const { splitTreatment, splitLoaded: isTimeWindowsSplitLoaded } = useSplitIOTreatment(
    splitioConstants.SPLITNAME_TIME_WINDOW_DROPDOWN_SELECT,
  );
  const useTimeWindowDropdownScheduler =
    [splitioConstants.ON, splitioConstants.CONTROL].includes(splitTreatment) &&
    schedulerViewType === SCHEDULER_VIEW_TYPES.TIME_WINDOW_SELECT;

  const isTimeWindowsScheduleType = isTimeWindowSchedule();
  const hasExistingAvailability = isEditMode || pureCart?.availability?.length > 0;

  const selectedDateTimesRef = useRef([]);
  const { seed } = useSchedulingEditModePreSeed({
    selectedDateTimesRef,
    availability,
    // We want to seed the edit mode with the availability from the cart if it exists
    isEditMode: hasExistingAvailability,
    entity: cart,
  });
  const selectedDateTimes = useMemo(() => {
    return selectedDateTimesRef.current;
  }, [selectedDateTimesRef, seed.length]);

  useEffect(() => {
    if (currentStage === BOOKING_STAGES.AVAILABILITY) {
      loadPage(BOOKING_STAGES.AVAILABILITY);
    }
  }, [currentStage, loadPage]);

  const onSubmitAvailability = (event) => {
    event.preventDefault();
    submitAvailability({
      selectedDateTimes,
      skipMinCount: false,
    });
  };

  const submitAvailabilitySkipMin = (event) => {
    if (event) event.preventDefault();
    submitAvailability({
      selectedDateTimes,
      skipMinCount: true,
    });
  };

  const handleToggleModal = (modalName) => {
    return () => {
      toggleModal({ modalName });
    };
  };

  const shouldBlockContent =
    currentStage !== BOOKING_STAGES.AVAILABILITY ||
    page.get('loading') ||
    (isTimeWindowsScheduleType && !isTimeWindowsSplitLoaded);
  if (shouldBlockContent) return null;

  const previouslySelectedTimes = cart && cart.get('availability');
  const disableSubmitButton = selectedDateTimes.length === 0;
  const availabilityChompedChunked = normalizeAvailabilityForWindowBasedView({
    availability,
    config: TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES,
  });
  const showSubmitButtonBlock =
    !isTimeWindowsScheduleType || (isTimeWindowsScheduleType && !disableSubmitButton);

  if (useExactTimeScheduler) {
    return (
      <AvailabilityPageGrid>
        <div className={styles.sectionHeaderWrapper}>
          <HeaderBlock
            schedulerViewType={SCHEDULER_VIEW_TYPES.EXACT_TIME_SELECT}
            partner={partner}
            previouslySelectedTimes={previouslySelectedTimes}
          />
        </div>
        <AvailabilitySelectorExactTime
          selectedTimes={selectedDateTimesRef.current}
          schedulingAction={SCHEDULING_ACTIONS.NEW_ORDER}
          isEditing={hasExistingAvailability}
        />
        <InvalidDatesModal
          visible={page.getIn(['modals', 'invalidDates'])}
          onCancel={handleToggleModal('invalidDates')}
        />
        <RecommendedDatesModal
          visible={page.getIn(['modals', 'recommendedDates'])}
          onCancel={handleToggleModal('recommendedDates')}
          submit={submitAvailabilitySkipMin}
          selectedDateTimes={selectedDateTimes}
        />
      </AvailabilityPageGrid>
    );
  }

  if (useTimeWindowDropdownScheduler) {
    return (
      <AvailabilityPageGrid>
        <div className={styles.sectionHeaderWrapper}>
          <HeaderBlock
            schedulerViewType={SCHEDULER_VIEW_TYPES.TIME_WINDOW_SELECT}
            partner={partner}
            previouslySelectedTimes={previouslySelectedTimes}
          />
        </div>
        <AvailabilitySelectorSelectTimeWindow
          selectedTimes={selectedDateTimesRef.current}
          schedulingAction={SCHEDULING_ACTIONS.NEW_ORDER}
          isEditing={hasExistingAvailability}
        />
        <InvalidDatesModal
          visible={page.getIn(['modals', 'invalidDates'])}
          onCancel={handleToggleModal('invalidDates')}
        />
        <RecommendedDatesModal
          visible={page.getIn(['modals', 'recommendedDates'])}
          onCancel={handleToggleModal('recommendedDates')}
          submit={submitAvailabilitySkipMin}
          selectedDateTimes={selectedDateTimes}
        />
      </AvailabilityPageGrid>
    );
  }

  // Legacy return block
  const legacySchedulerViewType = isTimeWindowsScheduleType
    ? SCHEDULER_VIEW_TYPES.TIME_WINDOW_GRID
    : null;
  return (
    <AvailabilityPageGrid>
      <div className={styles.sectionHeaderWrapper}>
        <HeaderBlock
          schedulerViewType={legacySchedulerViewType}
          partner={partner}
          previouslySelectedTimes={previouslySelectedTimes}
        />
      </div>

      <div className="form" id={AVAILABILITY_FORM_ID}>
        <LegacyTimeSelector
          useTimeWindows={isTimeWindowsScheduleType}
          selectedDateTimes={selectedDateTimes}
          availability={isTimeWindowsScheduleType ? availabilityChompedChunked : availability}
        />
        <div className="showTabletDesktopV2">
          <LegacyTimeManager
            useTimeWindows={isTimeWindowsScheduleType}
            numSelectedHours={numSelectedHours}
            selectedHours={selectedHours}
            selectedDateTimes={selectedDateTimes}
          />
        </div>
        {showSubmitButtonBlock && (
          <div className={cn(styles.buttonWrapper, styles.showTabletDesktop)}>
            <SubmitButton disabled={disableSubmitButton} onClick={onSubmitAvailability} />
          </div>
        )}
      </div>
      {showSubmitButtonBlock && (
        <div className={cn(styles.buttonWrapperMobile, 'showMobileOnlyV2')}>
          <LegacyTimeManager
            useTimeWindows={isTimeWindowsScheduleType}
            numSelectedHours={numSelectedHours}
            selectedHours={selectedHours}
            selectedDateTimes={selectedDateTimes}
          />
          <SubmitButton disabled={disableSubmitButton} onClick={onSubmitAvailability} />
        </div>
      )}
      <InvalidDatesModal
        visible={page.getIn(['modals', 'invalidDates'])}
        onCancel={handleToggleModal('invalidDates')}
      />
      <RecommendedDatesModal
        visible={page.getIn(['modals', 'recommendedDates'])}
        onCancel={handleToggleModal('recommendedDates')}
        submit={submitAvailabilitySkipMin}
        selectedDateTimes={selectedDateTimes}
      />
    </AvailabilityPageGrid>
  );
};
AvailabilityPage.propTypes = {
  submitAvailability: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  availability: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  partner: PropTypes.string,
  currentStage: PropTypes.string,
  isMobile: PropTypes.bool,
  selectedHours: PropTypes.array.isRequired,
  numSelectedHours: PropTypes.number.isRequired,
  addAppSnackNotice: PropTypes.func,
  clearAppNotices: PropTypes.func,
  snackbar: PropTypes.object,
};

export default AvailabilityPage;
