import { fromJS } from 'immutable';
import { TYPES } from './actions';
import { MODAL_ENDED_SESSION, MODAL_NEW_SALES_ORDER } from './constants';

const modalInitialState = fromJS(
  {
    [MODAL_NEW_SALES_ORDER]: { visible: false },
    [MODAL_ENDED_SESSION]: { visible: false }
   }
  );

/*
* Modal specific reducer. Because the header sits outside our context & sits in
* two site layouts, we have no choice but to reach into the app redux. Blaarg.
* */
export const Modals = (state = modalInitialState, action) => {
  switch (action.type) {
    case TYPES.FIELD_SALES_MODAL_ACTION: {
      const { name, visible } = action.payload;
      return state.setIn([name ,'visible'], visible);
    }
    default:
      return state;
  }
};
