import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PanelV2 } from 'HTKit/Elements/PanelV2';
import HeaderTabs from '../Parts/HeaderTabs';
import StandardTab from '../Parts/StandardTab';
import UpsellTab from '../Parts/UpsellTab';
import styles from './styles.scss';

const Mobile = ({
  cart,
  page,
  removeItem,
  startBooking,
  goToAnotherService,
  goToEditSku,
  addPlanAndStartBooking,
  plansInfoJS,
  isMonthly
}) => {
  const [showStandardTab, setTab] = useState(false);

  return (
    <PanelV2 heavyShadow className={styles.container}>
      <HeaderTabs
        cart={cart}
        page={page}
        showStandardTab={showStandardTab}
        setTab={setTab}
        plansInfo={plansInfoJS}
        isMonthly={isMonthly}
      />
      <StandardTab
        cart={cart}
        page={page}
        showStandardTab={showStandardTab}
        removeItem={removeItem}
        startBooking={startBooking}
        goToAnotherService={goToAnotherService}
        goToEditSku={goToEditSku}
      />
      <UpsellTab
        page={page}
        showStandardTab={showStandardTab}
        removeItem={removeItem}
        addPlanAndStartBooking={addPlanAndStartBooking}
        goToAnotherService={goToAnotherService}
        goToEditSku={goToEditSku}
        plansInfo={plansInfoJS}
        isMonthly={isMonthly}
      />
    </PanelV2>
  );
}

Mobile.propTypes = {
  cart: PropTypes.object,
  page: PropTypes.object,
  plansInfoJS: PropTypes.object,
  isMonthly: PropTypes.bool,
  removeItem: PropTypes.func.isRequired,
  startBooking: PropTypes.func.isRequired,
  goToAnotherService: PropTypes.func.isRequired,
  goToEditSku: PropTypes.func.isRequired,
  addPlanAndStartBooking: PropTypes.func.isRequired,
};

export default Mobile;
