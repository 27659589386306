import { fromJS } from 'immutable';

const initialState = fromJS({ mapErrorType: null });

function pageReducer(state = initialState, action) {
  switch (action.page) {
    case 'techMap': {
      switch (action.type) {
        default:
          return state;
      }
    }
    default:
      return state;
  }
}

export default pageReducer;
