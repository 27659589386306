import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { capitalize } from 'lodash';
import BADGE_TYPES from './constants';
import styles from './styles.scss';

const getStyles = ({ classes, size, type, variant, color: colorProp }) => {
  const typeColors = {
    [BADGE_TYPES.COMPLETED]: 'green',
    [BADGE_TYPES.CONFIRMED]: 'blue',
    [BADGE_TYPES.PENDING]: 'black',
    [BADGE_TYPES.CANCELLED]: 'grey',
    [BADGE_TYPES.WARNING]: 'yellow',
    [BADGE_TYPES.RATING]: 'blue',
  };
  const color = type ? typeColors[type] : colorProp;
  return cn(styles[`${variant}${capitalize(color)}`], styles[`size${capitalize(size)}`], classes);
};

const Badge = forwardRef((props, ref) => {
  const { children, ...rest } = props;
  const containerStyles = getStyles(rest);
  const baseStyles = cn(containerStyles, {
    [styles.badgeInline]: rest.inline,
  });

  return (
    <div ref={ref} className={baseStyles}>
      <div className={styles.childrenContainer}>{children}</div>
    </div>
  );
});

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  color: PropTypes.oneOf(['blue', 'black', 'green', 'grey', 'yellow', 'blue']),
  type: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xl']),
  variant: PropTypes.string.isRequired,
};

Badge.defaultProps = {
  color: 'grey',
  variant: 'outlined',
  size: 'medium',
  classes: '',
  type: null,
};

Badge.displayName = 'Badge';

export default React.memo(Badge);

/*
  Badge

  EXAMPLE USAGE
  -------------
  import { Badge, BADGE_TYPES } from 'src/components/Elements/Badge';
  ...
  ...
  <Badge>Default</Badge>
  <Badge type={BADGE_TYPES.CONFIRMED} variant="solid" size="small">Confirmed</Badge>
  <Badge type={BADGE_TYPES.WARNING} variant="outlined" size="large">Large</Badge>
  <Badge color="grey" variant="outlined" size="large" classes={[styles.foo, styles.bar]}>Large</Badge>
  <Badge color="grey" variant="outlined" size="large" classes={styles.baz}>Large</Badge>

  PROPS
  -----
  classes: additional styles to override or extend the default styles
  color: see proptypes for defined colors. If type is provided, then the color prop is ignored
  size: 'small', 'medium', or 'large'
  type: Predefined colors for different statuses, e.g. Warning, Confirmed, etc. If type is provided, then the color prop is ignored
  variant: 'outlined' or 'solid'
*/
