import { fromJS } from 'immutable';
import { CUSTOMER_VALIDATED, CUSTOMER_BAD_REQUEST, SHOW_OFFER } from './constants';

const initialState = fromJS({ formSubmitted: false, showOffer: false });

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_VALIDATED:
      return state.merge({ customerValidated: action.payload });
    case SHOW_OFFER:
      return state.merge({ showOffer: action.payload });
    case CUSTOMER_BAD_REQUEST:
      return state.merge({ customerBadRequest: true });
    default:
      return state;
  }
}

export default pageReducer;
