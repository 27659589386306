import { fromJS } from 'immutable';
import { PAGE_LOADED } from 'src/constants/common';
import { PAGE_NAME } from './constants';

const initialState = fromJS({});

export default (state = initialState, action) => {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        case PAGE_NAME:
          return state.set('audits', action.audits);
        default:
          return state;
      }
    default:
      return state;
  }
};
