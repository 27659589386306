import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.scss';
import { formatPriceTypeHourly } from 'src/containers/CartPage/utils';

const ServicePriceOriginal = ({
  service,
  sm,
  onInfoClick,
  showFixedPrice = false,
  linethroughPrice = false,
  showMemberPriceBadge,
}) => {
  const baseStyles = cn({ p0: !sm, p1: sm });

  return (
    <>
      {linethroughPrice ? (
        <div className={cn(styles.linethroughPrice)}>
          <h5 className="text-weight-bold alertRed500 paddingRight-tiny1">
            {service.extra.prices.price}
          </h5>
          <p className="p2 line-through n700">{service.listPrice.formatted}</p>
        </div>
      ) : (
        <span className={baseStyles}>
          {!showFixedPrice && 'Starting at'}{' '}
          <span className="text-weight-bold">
            {formatPriceTypeHourly({ service, price: service.extra.prices.price })}
          </span>
        </span>
      )}

      {service.extra.prices.memberPrice && !showMemberPriceBadge && (
        <>
          &nbsp;|&nbsp;
          <a className={cn('blue', baseStyles)} onClick={onInfoClick}>
            Member Price{' '}
            <span className="text-weight-bold">{service.extra.prices.memberPrice}</span>
          </a>
        </>
      )}
    </>
  );
};

ServicePriceOriginal.propTypes = {
  service: PropTypes.shape({
    extra: PropTypes.shape({
      prices: PropTypes.shape({
        memberPrice: PropTypes.string,
        price: PropTypes.string,
      }),
    }),
    listPrice: PropTypes.shape({
      formatted: PropTypes.string,
    }),
  }),
  sm: PropTypes.bool,
  onInfoClick: PropTypes.func.isRequired,
  showFixedPrice: PropTypes.bool,
  linethroughPrice: PropTypes.bool,
  showMemberPriceBadge: PropTypes.bool,
};

export default memo(ServicePriceOriginal);
