// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Components
import InputField from 'src/components/HTKit/Forms/InputField/index';
// Styles
import styles from './styles.scss';

const CouponInput = ({
  containerStyles,
  couponErrors,
  couponText,
  couponValue,
  hideButton,
  onClick,
  onChange,
  placeHolderText,
  ...rest
}) => {
  const couponFormRowStyles = cn(styles.couponFormRow, 'form-item', 'form-row');
  const couponInputStyles = cn(styles.couponInput, 'form-col', '-inline');
  const couponApplyStyles = cn(styles.couponApply, 'form-col', '-inline', 'medium-link');
  const couponValueStyles = cn(styles.couponValue, 'p2');

  return (
    <form className={containerStyles} onSubmit={onClick}>
      <div className={couponFormRowStyles}>
        <div className={couponInputStyles}>
          <InputField
            type="text"
            value={couponText}
            onChange={onChange}
            error={couponErrors}
            placeholder={placeHolderText}
            readOnly={!!couponValue}
            {...rest}
          />
        </div>
        {!hideButton && (
          <a className={couponApplyStyles} onClick={onClick}>
            {couponValue ? 'Remove' : 'Apply'}
          </a>
        )}
        {couponValue && <span className={couponValueStyles}>{couponValue}</span>}
      </div>
    </form>
  );
};

CouponInput.propTypes = {
  couponErrors: PropTypes.string,
  containerStyles: PropTypes.string,
  couponText: PropTypes.string,
  couponValue: PropTypes.string,
  hideButton: PropTypes.bool,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeHolderText: PropTypes.string,
};

CouponInput.defaultProps = {
  couponText: '',
  containerStyles: 'form',
  hideButton: false,
  readonly: false,
  onChange: () => {},
  onClick: () => {},
  placeHolderText: 'Enter Coupon Code',
};

export default React.memo(CouponInput);

// TODO: This is only being used for SubscriptionGifting and when that feature redone or removed this component should be removed. -GH June 22, 2020
