// Libraries
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Constants
import { SCREEN_WIDTHS } from 'src/components/AvailabilitySelector/constants';
// Components
import Icon from 'HTKit/Icon';
import DesktopHourBlock from './DesktopHourBlock';
// Data
import { dateHasSelectedHours, formatDate } from './Scheduling.utils';
import { useFirstAvailableIndex } from './hooks';
// Styles
import styles from './styles.scss';

const DesktopSelector = (props) => {
  /* Local State */
  const [currentIndex, setCurrentIndex] = useState(0);
  const [numDatesToShow, setNumDatesToShow] = useState(4);
  const [reachedEndOfViewableList, setReachedEndOfViewableList] = useState();

  /* Constants */
  const { availability, toggleHour, enableDateToggle = true } = props;
  const firstAvailableIndex = useFirstAvailableIndex(availability);
  const datesToShow = availability.slice(currentIndex, currentIndex + numDatesToShow);
  const leftToggleStyles = classNames(styles.toggle, {
    [styles.disable]: currentIndex === 0,
  });
  const rightToggleStyles = classNames(styles.toggle, {
    [styles.disable]: reachedEndOfViewableList,
  });

  /* Methods */
  const setDatesToShow = () => {
    const screen = SCREEN_WIDTHS;
    if (window.innerWidth > screen.maxWidth) {
      setNumDatesToShow(5);
    } else if (window.innerWidth > screen.tablet && window.innerWidth < screen.desktop) {
      setNumDatesToShow(3);
    } else {
      setNumDatesToShow(4);
    }
  };

  const shiftDates = (shift = 1) => {
    return (event) => {
      if (event) event.preventDefault();
      if (currentIndex === 0 && shift < 0) return;
      if (reachedEndOfViewableList && shift > 0) return;
      setCurrentIndex(currentIndex + shift);
    };
  };

  /* This is the day header toggle: Will toggle on/off all windows within that day */
  const toggleHoursForDay = (date) => {
    /* Dealing with "groups/windows" of time, lets merge them. */
    const mergedData = date.hours.reduce((all, group) => all.concat(group), []);
    const allValidHours = mergedData.filter((hour) => hour.valid);
    const checkedHours = allValidHours.filter((hour) => hour.checked && hour.valid);
    const noHoursSelected = checkedHours.length === 0;
    const someHoursSelected = checkedHours.length > 0 && checkedHours.length < allValidHours.length;
    const noOrSomeHours = noHoursSelected || someHoursSelected;
    /* Iterate thru each hour and toggle it. Group needs 'some' hours */
    allValidHours.forEach((hour) => {
      toggleHour(date?.date, [hour], noOrSomeHours)();
    });
  };

  const handleDateClick = (date) => () => {
    if (!enableDateToggle) return;
    toggleHoursForDay(date);
  };

  useEffect(() => {
    setDatesToShow();
    window.addEventListener('resize', setDatesToShow);

    return () => window.removeEventListener('resize', setDatesToShow);
  }, []);

  useEffect(() => {
    if (firstAvailableIndex !== null) {
      shiftDates(firstAvailableIndex)();
    }
  }, [firstAvailableIndex]);

  useEffect(() => {
    setReachedEndOfViewableList(currentIndex === availability.length - numDatesToShow);
  }, [currentIndex, availability, numDatesToShow]);

  return (
    <div className={styles.desktop}>
      <div className={styles.selectorWrapper}>
        <a className={leftToggleStyles} onClick={shiftDates(-1)}>
          <Icon name="v2-skinny-arrow" className={styles.calendarIcon} />
        </a>

        {datesToShow &&
          datesToShow.map((date) => {
            return (
              <section key={date.date} className={styles.dateHourWrapper}>
                <div className={styles.availabilityDate} onClick={handleDateClick(date)}>
                  <p className={styles.weekday}>{formatDate(date.date, 'ddd')}</p>
                  <p className={styles.day}>{formatDate(date.date, 'MMM D')}</p>
                  {dateHasSelectedHours(date) && (
                    <div className={styles.availabilityDateSelected} />
                  )}
                </div>
                <div className={styles.hourwrapperDesktop}>
                  {date?.hours.map((hourData, index) => (
                    <DesktopHourBlock
                      key={index}
                      date={date.date}
                      hourData={hourData}
                      toggleHour={toggleHour}
                    />
                  ))}
                </div>
              </section>
            );
          })}

        <a className={rightToggleStyles} onClick={shiftDates()}>
          <Icon name="v2-skinny-arrow-right" className={styles.calendarIcon} />
        </a>
      </div>
    </div>
  );
};

DesktopSelector.propTypes = {
  availability: PropTypes.object.isRequired,
  toggleHour: PropTypes.func.isRequired,
  enableDateToggle: PropTypes.bool,
};

export default DesktopSelector;
