import { connect } from 'react-redux';
import { currentLandingSelector } from 'src/selectors/landings';
import layoutSelector from 'src/selectors/layout';
import BookingHeader from './BookingHeader';

const mapStateToProps = (state) => ({
  header: layoutSelector('header')(state),
  currentPartnerLanding: currentLandingSelector(state),
});

export default connect(
  mapStateToProps,
  {},
)(BookingHeader);
