import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import useAPI from 'src/hooks/useAPI';
import { pureCartSelector } from 'src/selectors/cart';
import { updateAuth, userLoaded } from 'src/containers/AppBase/actions';
import { push, accountPath } from 'src/utils/paths';
import { updateCart } from 'src/containers/AddSkuPage/actions';
import { goToStage } from 'src/containers/BookingPage/actions';
import { userSignedUp } from 'src/containers/RegistrationPage/actions';
import { useLeadGeneration } from 'src/utils/leadGenerated/useLeadGeneration';
import { logger } from 'src/utils/logger';
import { displayErrorsWithSnack } from 'src/utils/request';
import { updateProfile } from '../Account/AccountPage/actions';

/**
 * This hook handles the user signup for partner plan redemption flow.
 * There are 3 stages
 * 1. User creation
 * 2. Addition of plan to cart
 * 3. Routing user to the next stage
 */
export const useSignupForPartnerPlanRedemption = ({ planId, partnerMembershipsId }) => {
  const dispatch = useDispatch();
  const cart = useSelector(pureCartSelector);
  const api = useAPI();
  const { submitLeadGenerated } = useLeadGeneration();

  const request = useCallback(
    ({ params, optParams = {}, opts = {} }) =>
      new Promise((resolve, reject) => {
        api.toggleLoader(true);

        /*
        *******************************************************
          USER CREATION
        *******************************************************
        */
        api.users
          .registration({ user: params, ...optParams })
          .then(async ({ err, data }) => {
            if (err) {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ err, data });
            } else {
              let cartData = cart;
              const { user } = data;

              resolve(data);

              Cookies.set('new_user', true);

              dispatch(updateAuth({ authToken: user.authToken }));
              dispatch(userLoaded(user));
              dispatch(userSignedUp({ user, attributes: params }));

              if (cartData) {
                submitLeadGenerated({
                  email: params.email,
                  source: 'registrationCheckout',
                  page: 'useSignupForPartnerPlanRedemption',
                });

                /*
                *******************************************************
                  ADD PLAN TO CART
                *******************************************************
                */

                /** Get updated API routes with auth from newly registered user */
                const updatedApi = api.routesWithNewAuth(user);

                // HACK. Trigger adding a client to cart (save_cart! method on BE)
                await updatedApi.cart.emptyCart();

                // Add plan
                const selectPlanResponse = await updatedApi.cart.selectPlan({
                  plan_id: planId,
                  partner_membership_id: partnerMembershipsId,
                });

                if (!selectPlanResponse.err) {
                  const { cart: selectPlanCartData } = selectPlanResponse.data;
                  dispatch(updateCart({ cart: selectPlanCartData }));

                  /*
                    HACK to add the client phone to the cart. If we don't call this then cart.phone
                    will be an empty string and the go_to_availability API call will return an error.
                  */
                  dispatch(updateProfile({ client: { phone: user.phone } }));
                  cartData = selectPlanCartData;
                } else {
                  logger('useSignupForPartnerPlanRedemption')('Failed to add plan');
                  dispatch(displayErrorsWithSnack(selectPlanResponse));
                  return;
                }

                // Call start booking so cart is in the correct stage
                const startBookingResponse = await updatedApi.cart.startBooking();
                if (!startBookingResponse.err) {
                  const startBookingCart = startBookingResponse.data.cart;
                  dispatch(updateCart({ cart: startBookingCart }));
                  cartData = startBookingCart;
                } else {
                  logger('useSignupForPartnerPlanRedemption')('Failed to start booking');
                  dispatch(displayErrorsWithSnack(startBookingResponse));
                  return;
                }

                /*
                *******************************************************
                  ROUTE USER TO NEXT STAGE
                *******************************************************
                */
                if (cartData.statusId > 0) {
                  dispatch(goToStage(cartData.status, false, { ...opts })); // Go to next stage in checkout
                  return;
                }
                dispatch(goToStage('address', false, { ...opts })); // Fallback to address stage
              } else {
                dispatch(push(accountPath));
              }
            }
          })
          .catch(reject)
          .finally(() => {
            api.toggleLoader(false);
          });
      }),
    [cart, api],
  );

  return request;
};
