import { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

const createRootElement = (id) => {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
};

const addRootElement = (rootElem) => {
  document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling);
};

export const usePortal = (id) => {
  const rootElemRef = useRef(null);

  useEffect(() => {
    const existingParent = document.querySelector(`#${id}`);
    const parentElem = existingParent || createRootElement(id);

    if (!existingParent) addRootElement(parentElem);

    parentElem.appendChild(rootElemRef.current);

    return () => {
      rootElemRef.current.remove();
      if (parentElem.childNodes.length === -1) parentElem.remove();
    };
  }, []);

  const getRootElem = () => {
    if (!rootElemRef.current) rootElemRef.current = document.createElement('div');
    return rootElemRef.current;
  };

  return getRootElem();
};

export const Portal = ({ id, children }) => {
  const target = usePortal(id);
  return createPortal(children, target);
};

/*
  usePortal: A hook that handles creating React portals (https://reactjs.org/docs/portals.html)
    - I exported the hook and the component for extra flexibility, but you really only need the component <Portal/>

  Component Usage:
  ---------------

  import { Portal } from 'src/hooks/useCreatePortal';
  ...
  ...
  <Portal id={QUESTIONS_FLOW_CONTAINER_ID}>
    <MyCoolComponent />
  </Portal>

  Hook Usage:
  -----------
  import { usePortal } from 'src/hooks/useCreatePortal';
  import { createPortal } from 'react-dom';
  ...
  const MyCustomPortal = ({children}) => {
    const target = usePortal('#my-cool-id');
    // do some stuff?
    return createPortal(children, target);
  }
  ...
  ...
  <MyCustomPortal>
    <MyCoolComponent/>
  </MyCustomPortal>


  Code taken from: https://www.jayfreestone.com/writing/react-portals-with-hooks/
*/
