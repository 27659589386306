import PropTypes from 'prop-types';
import { SIZES } from '../constants';

export const OPTION_PROP_TYPE = PropTypes.arrayOf(PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  subtext: PropTypes.string,
}));

export const SIZE_PROP_TYPE = PropTypes.oneOf([SIZES.xs, SIZES.sm, SIZES.md, SIZES.xl]);
