import React from 'react';
import PropTypes from 'prop-types';
import SkuFAQItem from './SkuFAQItem';

const SkuFAQContent = ({ faq, className }) => (
  <div className={className}>
    {faq.map((question) => (
      <SkuFAQItem key={question.id} question={question} />
    ))}
  </div>
);

SkuFAQContent.propTypes = {
  faq: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
};

SkuFAQContent.defaultProps = {
  className: '',
};

export default SkuFAQContent;
