import React from 'react';
import PropTypes from 'prop-types';
import HT_INFO from 'src/constants/hellotech';
import ContentModal from '../Content';
import styles from './styles.scss';

const SeeHowItWorks = (props) => {
  const { visible, onCancel } = props;

  return (
    <ContentModal visible={visible} onCancel={onCancel} styles={styles.modal} closeStyles={styles.modalClose}>
      <div>
        <iframe width="100%" height="300" src={HT_INFO.video.youtubePromo} frameBorder="0" allowFullScreen />
      </div>
    </ContentModal>
  );
};

SeeHowItWorks.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SeeHowItWorks;
