
export const CLOUDINARY_TYPES = {
  SET_FILE: 'cloudinary/setFileUploadSuccess',
  DELETE_FILE: 'cloudinary/deleteFileUploadSuccess',
  SET_FILE_ERRORS: 'cloudinary/setFileUploadErrors',
}

export const setFileUploadSuccess = ({success, append, editorNamespace}) => ({
  type: CLOUDINARY_TYPES.SET_FILE,
  payload: { success, append, editorNamespace},
});

export const deleteFileUploadSuccess = ({ids, editorNamespace}) => ({
  type: CLOUDINARY_TYPES.DELETE_FILE,
  payload: { ids, editorNamespace},
});

export const setFileUploadErrors = ({ids, editorNamespace}) => ({
  type: CLOUDINARY_TYPES.SET_FILE_ERRORS,
  payload: { ids, editorNamespace},
});
