import {
  GET_DISCOUNT,
  DISCOUNT_SUBMITTED,
  SHOW_FIRST_SERVICE_DISCOUNT_MODAL,
  SHOW_FIRST_SERVICE_DISCOUNT_DELAYED_MODAL,
  TOGGLE_FIRST_SERVICE_DISCOUNT_STATE,
  SUCCESS_RESPONSE,
  LOAD_PLAN_DETAILS,
  PLAN_DETAILS_LOADED,
} from './constants';

/** TODO: Refactor for broader use. Originally used by FirstServiceDiscountModal, now also used by <SubscribeForm /> */
export const getDiscount = (email, { source = '' } = {}) => ({
  type: GET_DISCOUNT,
  payload: { email, source },
});

export const discountSubmitted = (code) => ({
  type: DISCOUNT_SUBMITTED,
  payload: { code },
});

export const showFirstServiceDiscountModal = () => ({
  type: SHOW_FIRST_SERVICE_DISCOUNT_MODAL,
});

export const showFirstServiceDiscountDelayedModal = (ms = 3000) => ({
  type: SHOW_FIRST_SERVICE_DISCOUNT_DELAYED_MODAL,
  payload: { ms },
});

export const toggleFirstServiceDiscountState = (enabled) => ({
  type: TOGGLE_FIRST_SERVICE_DISCOUNT_STATE,
  payload: { enabled },
});

export const successResponse = () => ({
  type: SUCCESS_RESPONSE,
});

export function loadPlanDetails(planDetails) {
  return {
    type: LOAD_PLAN_DETAILS,
    planDetails,
  };
}

export function planDetailsLoaded(plan) {
  return {
    type: PLAN_DETAILS_LOADED,
    payload: { plan },
  };
}
