import { contentBasehost as baseRedirectPath } from 'src/utils/environment';

export const HOMEPAGE_IFRAME_CONTAINER_ID = 'IframeContainerHomePage'; // This gets applied to the container surrounding the iframe
export const HOMEPAGE_IFRAME_PAGE_ID = 'IframePageHomePage'; // This gets applied directly to iframe

export const getIframeSource = (src) => {
  // This is similar the IFRAME function below, but instead of generating the source string and then returning an iframe tag, we just generate the source string
  if (!src) return '';
  return `${baseRedirectPath}${src}`;
};

/**
 * This tells us what messaging group to listen for, else we get all messages across the iframe(s)/sockets.
 * @type {string}
 */
