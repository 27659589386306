import { createSelector } from 'reselect';

const modalStateSelector = (name) => (state) => state.getIn(['modals', name]);
const modalSelector = (name) =>
  createSelector(
    modalStateSelector(name),
    (modal) => modal,
  );

export const pureModalSelector = (name) =>
  createSelector(
    modalStateSelector(name),
    (modal) => modal.toJS(),
  );

export default modalSelector;
