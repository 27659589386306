import { isImmutable } from 'src/utils/helpers';
import get from 'lodash/get';
import { logger } from 'src/utils/logger';
import { getCartProperties as getPartialCartProperties } from 'src/containers/CartPage/actions';

import {
  CONFIRM_BOOKING,
  SUMMARY_SUBMITTED,
  APPLY_BONUS,
  BONUS_EDIT_MODE,
  REMOVE_ADJUSTMENT,
  REMOVE_ITEM,
  ADD_COUPON,
  GET_SUBSCRIPTION_DETAILS,
  REMOVE_PLAN_FROM_CART,
  PURCHASE_PLAN_ONLY,
  CREDIT_APPLIED_TOAST_SHOWN,
  UPDATE_LOW_TIER_CONFIG,
} from './constants';

/*
 * todo: Break this out so we can merge whats in cart very similiar
 * This function bags up all cart related information and sends to segment
 * */
function getSummaryCartProperties({ _cart, id, amount, coupon, tax, discount, geekCut }) {
  try {
    const cart = isImmutable(_cart) ? _cart.toJS() : _cart || {};
    const breakdown = get(cart, 'breakdown', {});
    const items = get(cart, 'items', {});
    const email = get(cart, 'clientEmail', '');
    const breakdownItems = get(breakdown, 'items', []) || [];
    // try to get partner name from various areas of cart :-(
    const getName = () =>
      cart.partnerName ||
      get(cart, 'partner.name', '') ||
      get(cart, 'partner.landingSeoName', '') ||
      '';
    // try to get additional info by matching in another part of cart
    const getAdditionalItemAttributes = (skuId) => items.find((item) => item.skuId === skuId);

    // partner colate
    const partnerObject = get(cart, 'partner', false);
    const partner = partnerObject
      ? {
          partner_id: partnerObject.id,
          partner_name: getName(),
        }
      : null;
    const htRevenue = (amount - (discount + geekCut)) / 100;
    const products = breakdownItems.map((item) => {
      const skuId = item.skuId;
      const price = item.amount / 100;

      return {
        sku_id: skuId,
        id: `${skuId}`,
        price,
        base_price: price,
        category: item.category,
        is_remote: get(getAdditionalItemAttributes(skuId), 'remote', false),
        image_url: get(getAdditionalItemAttributes(skuId), 'heroImageUrl', false),
        name: item.name,
      };
    });

    return {
      order_id: id,
      orderId: id,
      tax: tax / 100,
      coupon,
      cart_id: cart.id,
      total: amount / 100,
      actual_revenue: htRevenue,
      revenue: amount / 100,
      discount,
      products,
      partner,
      email,
    };
  } catch (e) {
    logger('Order Completed Event Error')(e);

    return {};
  }
}

export function confirmBooking({ affirmToken = '' } = {}) {
  return {
    type: CONFIRM_BOOKING,
    affirmToken,
  };
}

export function summarySubmitted({
  id,
  amount,
  services,
  tax,
  discount,
  coupon,
  stateCart,
  geekCut,
}) {
  const cartID = get(services, '0.cart_id', '');

  return {
    type: SUMMARY_SUBMITTED,
    orderId: id,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: 'Checkout Step Completed',
            properties: {
              step: 'Confirmation',
              cart_id: cartID,
              cart: getPartialCartProperties(stateCart),
            },
          },
        },
        {
          eventType: 'track',
          eventPayload: {
            event: 'Order Completed',
            properties: getSummaryCartProperties({
              _cart: stateCart,
              id,
              amount,
              coupon,
              tax,
              discount,
              geekCut,
            }),
          },
        },
      ],
    },
  };
}

export function removeItem(itemIndex) {
  return {
    type: REMOVE_ITEM,
    itemIndex,
  };
}

export function bonusEditMode(editMode) {
  return {
    type: BONUS_EDIT_MODE,
    editMode,
  };
}

export function applyBonus(amount) {
  return {
    type: APPLY_BONUS,
    amount,
  };
}

export function removeAdjustment(adjustmentType) {
  return {
    type: REMOVE_ADJUSTMENT,
    adjustmentType,
  };
}

export function addCoupon(code = '', _cart) {
  const cart = isImmutable(_cart) ? _cart.toJS() : _cart || {};
  return {
    type: ADD_COUPON,
    code,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: 'Coupon Entered',
            properties: {
              cart_id: cart.id,
              coupon_code: code,
            },
          },
        },
      ],
    },
  };
}

export function getSubscriptionDetails(planDetails) {
  return {
    type: GET_SUBSCRIPTION_DETAILS,
    planDetails,
  };
}

export function removePlanFromCart() {
  return {
    type: REMOVE_PLAN_FROM_CART,
  };
}

export const purchasePlanOnly = () => ({
  type: PURCHASE_PLAN_ONLY,
});

export const showCreditAppliedToast = () => ({
  type: CREDIT_APPLIED_TOAST_SHOWN,
});

export const updateLowTierConfig = (payload) => ({
  type: UPDATE_LOW_TIER_CONFIG,
  payload,
});
