// Libraries
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// External Data
import { resolveBookingPageLayout } from 'src/utils/layout';
import { isPlanOnlyCart } from 'src/utils/cart';
// Components
import Breadcrumbs from 'src/components/Breadcrumbs';
import PageLoader from 'src/components/PageLoader';
import AvailabilityPage from './AvailabilityPage';
import AddressPage from './AddressPage';
import PaymentPage from './PaymentPage';
import SummaryPage from './SummaryPage';
// Constants
import {
  BREADCRUMB_STAGES_DEFAULT,
  BREADCRUMB_STAGES_NO_AVAILABILITY,
  BOOKING_STAGES,
  PAGE_NAME,
} from './constants';
import styles from './styles.scss';
import summaryStyles from './SummaryPage/styles.scss';
import VerificationPage from './VerificationPage';

export const allowStage = ({ user, stage, cart, allowOverrideStage, authOverride }) => {
  // USED IN pagesFlow.js
  if (!authOverride && (!user || !cart)) return false;
  const status = cart.get('status');
  if (stage === allowOverrideStage || stage === 'verification') return true;
  /**
   * authOverride is required because the page redirects to /cart page when user doesn't exists (in pagesFlow.js)
   * We want users to be redirected to /sign or /verification step where there's login etc
   * */
  switch (stage) {
    case 'address':
      return ['address', 'availability', 'payment', 'summary'].includes(status);
    case 'availability':
      return ['availability', 'payment', 'summary'].includes(status);
    case 'payment':
      return ['payment', 'summary'].includes(status);
    case 'summary':
      return ['summary', 'payment'].includes(status);
    default:
      throw new Error(`Unknown status: ${status}`);
  }
};

export const BookingPage = ({
  cart,
  layoutUpdate,
  loadPage,
  loading,
  stageLoaded,
  stage,
  currentPartner,
  showFirstServiceDiscountModal,
  partnerName,
  isZipRestricted,
  zipIsOrderableFromDate,
  isApiOrder,
  ...props
}) => {
  const stageRef = useRef({
    stage: '',
    cart: {},
  });

  useEffect(() => {
    stageRef.current.stage = stage;
    stageRef.current.cart = cart;
    layoutUpdate(resolveBookingPageLayout({ cart, currentPartner }));
    loadPage(PAGE_NAME, { stage });
    return () => {
      // fires on component unmount
      showFirstServiceDiscountModal();
    };
  }, []);

  useEffect(() => {
    // After BookingPage loads, fire stageLoaded action with new stage
    if (!loading) stageLoaded(stage);
  }, [loading]);

  useEffect(() => {
    // Reload page if new stage selected
    if (stageRef.current.stage !== stage) {
      stageRef.current.stage = stage;
      loadPage(PAGE_NAME, { stage });
      layoutUpdate(resolveBookingPageLayout({ cart, currentPartner }));
    }
  }, [stage]);

  useEffect(() => {
    if (!stageRef.current.cart.equals(cart)) {
      layoutUpdate(resolveBookingPageLayout({ cart, currentPartner }));
    }
  }, [cart]);

  const pagePadding = cn({
    [summaryStyles.page]: isApiOrder,
  });
  const skipAvailability = cart.get('remote') || isPlanOnlyCart(cart);
  const crumbSteps = skipAvailability
    ? BREADCRUMB_STAGES_NO_AVAILABILITY
    : BREADCRUMB_STAGES_DEFAULT;

  // TODO: Breadcrumbs should probably redone with Hooks if/when this BookingPage component is ever refactored -GH Apr 14, 2020
  const getStep = (comparetor, idx) => {
    const foundStep = crumbSteps.find((stg) => stg.name === comparetor);
    return (foundStep || crumbSteps[idx]).step;
  };
  const stepInCheckoutFlow = getStep(stage, 1);
  const currentPageUserIsVisiting = getStep(stage, 0);

  if (loading) return <PageLoader />;

  return (
    <div className={pagePadding}>
      {!isApiOrder && (
        <Breadcrumbs
          activeStep={currentPageUserIsVisiting}
          numSteps={crumbSteps.length}
          completedStep={stepInCheckoutFlow}
        />
      )}

      {stage === BOOKING_STAGES.VERIFICATION && (
        <div className={styles.container}>
          <section className={styles.stageSection}>
            <VerificationPage {...props} />
          </section>
        </div>
      )}
      {stage === BOOKING_STAGES.ADDRESS && (
        <div className={styles.container}>
          <section className={styles.stageSection}>
            <AddressPage partner={partnerName} currentStage={stage} />
          </section>
        </div>
      )}

      {stage === BOOKING_STAGES.AVAILABILITY && (
        <div className={styles.availabilityContainer}>
          <AvailabilityPage partner={partnerName} currentStage={stage} />
        </div>
      )}

      {stage === BOOKING_STAGES.PAYMENT && (
        <div className={styles.container}>
          <section className={styles.stageSection}>
            <PaymentPage currentStage={stage} />
          </section>
        </div>
      )}

      {stage === BOOKING_STAGES.SUMMARY && <SummaryPage />}
    </div>
  );
};

BookingPage.propTypes = {
  layoutUpdate: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  stageLoaded: PropTypes.func.isRequired,
  showFirstServiceDiscountModal: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  user: PropTypes.object,
  currentPartner: PropTypes.object,
  stage: PropTypes.string.isRequired,
  partnerName: PropTypes.string,
  pathname: PropTypes.string,
  isZipRestricted: PropTypes.bool,
  zipIsOrderableFromDate: PropTypes.string,
  isApiOrder: PropTypes.bool,
};
