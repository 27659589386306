import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useFetchPlanDetails } from 'src/containers/LandingPage/templates/ProductSingleSku/hooks';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import { THEMES } from 'src/components/HTKit/Forms/Button';
import ReviewCount from 'src/components/HTKit/ReviewCount';
import PlanModal, { CTA_BUTTON_TEXT } from 'src/containers/SkuPage/Parts/PlanModal/PlanModal';
import { PAGE_NAME } from 'src/containers/SkuPage/constants';
import LegacyExpertInHomeService from 'src/components/Sections/legacy/ExpertInHomeService';
import StartsAtPrice from 'src/components/StartsAtPrice';
import { getUpsellPlanIds } from 'src/components/Plans/Pricing/utils';
import { resolveLayout } from 'src/utils/layout';
import { push } from 'src/utils/paths';
import { layoutUpdate } from 'src/actions/common';
import Guarantee from 'src/components/Guarantee';
import Review from 'src/components/Review';
import { isMobileV2, isTabletV2 } from 'src/utils/ui';
import { createUpsellModalUtils } from 'src/containers/LandingPage/utils';
import {
  Price,
  BookingButton,
  CTA_TEXT,
} from './Parts/ProductSingleSkuHeader/ProductSingleSkuHeader';
import { ProductSingleSkuImage, ProductSingleSkuHeader } from './Parts/index';
import { PRODUCT_SKU_HEADER_TRIGGER } from './constants';
import getSkuTitle, { isPaintedDoorTestSku } from './utils';
import styles from './styles.scss';

const ProductSingleSku = ({ page, landing, upsellPlan, showPlanModal, isPlanPricingTest }) => {
  useEffect(() => {
    layoutUpdate(resolveLayout(landing.get('template')));
  }, []);
  const sku = landing.getIn(['skus', 0]);
  const skuJS = sku.toJS();
  const skuId = skuJS.id;
  const { splitTreatment: lpMembershipSplitTreatment } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_LP_MEMBERSHIP_TEST,
  );
  const isPaintedDoorMembershipTestSku = isPaintedDoorTestSku({ skuId });
  const showPaintedDoorMembershipTest =
    lpMembershipSplitTreatment === splitioConstants.ON && isPaintedDoorMembershipTestSku;
  const { monthlyID: upsellPlanId } = getUpsellPlanIds({ isPlanPricingTest });
  const skuTitle = getSkuTitle(skuJS.id); // The title product wants to render is not the same as skuname in one case - hence this util.
  const contentClasses = classNames('l-content-section', styles.pageContent);
  const title = landing.getIn(['header', 'title']);
  const subtitle = landing.getIn(['header', 'subtitle']);
  const splitTitle = showPaintedDoorMembershipTest
    ? `Expert ${skuTitle} for HelloTech Members`
    : title;
  const logoImgSrc = landing.getIn(['images', 'logo', 'original']);
  const plansInfoJS = page.get('plansInfo').toJS();
  const planDetailsJS = page.get('plan').toJS();
  const isMobileOrTablet = isMobileV2() || isTabletV2();
  const isMobile = isMobileV2();

  useFetchPlanDetails({ upsellPlanId });

  const goToHeroLink = (heroImageLink) => {
    push(heroImageLink);
  };

  const { fetchPlanAndOpenModal, addPlan, closePlanModal } = createUpsellModalUtils({
    isPlanPricingTest,
    upsellPlanId,
    skuId,
  });

  const renderSkuDetails = () => {
    const landingJS = landing ? landing.toJS() : {};
    const { ratingAttributes } = landingJS;
    const withReviews = ratingAttributes && ratingAttributes.ratingCount > 0;
    const skuDetailsClassNames = classNames(styles.skuDetails, {
      [styles.withReviews]: withReviews,
    });
    const { hasAdjustments, name, showFree } = skuJS;
    const cheapestPriceFormatted = sku.getIn(['subscriptionPrices', 'cheapestPriceFormatted']);

    return (
      <div className={styles.sku}>
        <div className={skuDetailsClassNames}>
          <h4>{name}</h4>
          <>
            {showFree && <h2 className="paddingY-tiny1">FREE</h2>}
            {!showFree && hasAdjustments && (
              <>
                <span className="p0 n900 paddingBottom-tiny">
                  Starts at only{' '}
                  {showPaintedDoorMembershipTest ? (
                    cheapestPriceFormatted
                  ) : (
                    <StartsAtPrice sku={sku} />
                  )}
                </span>
                <Price
                  showMemberOnlyView={showPaintedDoorMembershipTest}
                  plansInfo={planDetailsJS}
                  onMemberPriceClick={fetchPlanAndOpenModal}
                  sku={sku}
                  className="p1 marginBottom-small"
                  withReviews={withReviews}
                />
              </>
            )}
          </>
          {withReviews && (
            <ReviewCount
              reviews={ratingAttributes.ratingCount}
              stars={ratingAttributes.rating}
              copyStyle={classNames('paddingBottom-small', styles.skuReviewCopy)}
              keyStr="mobile"
            />
          )}
          <BookingButton
            skuId={skuJS.id}
            landing={landing}
            theme={THEMES.V2PRIMARY}
            showMemberOnlyView={showPaintedDoorMembershipTest}
            onClickMemberOnlyView={addPlan}
          />
        </div>
      </div>
    );
  };

  const renderInfo = () => {
    const reviews = landing.get('reviews');
    return (
      <div className={styles.info}>
        <div className={styles.infoGuarantee}>
          <Guarantee />
        </div>
        <div className={styles.infoReviews}>
          {reviews.map((r) => (
            <div className={styles.infoReview} key={r.get('id')}>
              <Review review={r} />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.page}>
      <Helmet title={landing.get('pageTitle')} />
      <ProductSingleSkuHeader
        sku={sku}
        image={logoImgSrc}
        ratingAttributes={landing.get('ratingAttributes')}
        onMemberPriceClick={fetchPlanAndOpenModal}
        showMemberOnlyView={showPaintedDoorMembershipTest}
        onBookClick={addPlan}
        plansInfo={planDetailsJS}
      />
      <ProductSingleSkuImage isMobile={isMobile} landing={landing} onClick={goToHeroLink} />
      <div className={contentClasses}>
        <section className={styles.pageRow}>
          <div className={styles.pageColumn}>
            <div className={styles.pageContentHeader}>
              <h1 className={classNames('h2 blue500', styles.pageContentHeaderTitle)}>{splitTitle}</h1>
              {/* eslint-disable */}
              {subtitle && (
                <h2
                  className={`h5 ${styles.pageContentHeaderSubTitle}`}
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                />
              )}
              {/* eslint-enable */}
            </div>
            <div className={styles.pageSkuDetails}>{renderSkuDetails()}</div>
            <div id={PRODUCT_SKU_HEADER_TRIGGER} className={styles.pageContentDescription}>
              <div className={styles.pageContentDescriptionInfo}>
                {/* eslint-disable */}
                <p
                  dangerouslySetInnerHTML={{ __html: landing.getIn(['content', 'description']) }}
                />
                {/* eslint-enable */}
              </div>
            </div>
          </div>
          <div className={styles.pageColumn}>{renderInfo()}</div>
        </section>
      </div>
      <div className={styles.pageInfo}>
        <LegacyExpertInHomeService backgroundColor="white" />
      </div>

      {/* modals */}
      <PlanModal
        plan={upsellPlan}
        sku={sku}
        visible={showPlanModal}
        closePlanModal={closePlanModal}
        addPlan={addPlan}
        plansInfo={plansInfoJS}
        isMobileOrTablet={isMobileOrTablet}
        ctaButtonText={showPaintedDoorMembershipTest ? CTA_TEXT : CTA_BUTTON_TEXT}
      />
    </div>
  );
};

ProductSingleSku.propTypes = {
  page: PropTypes.object.isRequired,
  landing: PropTypes.object.isRequired,
  upsellPlan: PropTypes.object,
  showPlanModal: PropTypes.bool,
  splitObject: PropTypes.object,
  isPlanPricingTest: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const page = state.getIn(['pages', PAGE_NAME]);
  return {
    page,
    upsellPlan: page.get('plan'),
    showPlanModal: page.get('showPlanModal'),
    plansInfo: page.get('plansInfo'),
  };
};

export default connect(mapStateToProps, {
  layoutUpdate,
  push,
})(ProductSingleSku);
