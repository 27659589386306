import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../styles.scss';

const SupportFeeHint = ({ explanation }) => {
  if (!explanation) return null;
  return <section className={cn('caption', styles.safetyFee)}>{explanation}</section>;
};

SupportFeeHint.propTypes = { explanation: PropTypes.string };

export default React.memo(SupportFeeHint);
