import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Hooks
import { useSpring, animated } from 'react-spring';
import useEventListener from 'src/hooks/useEventListener';
// Constants
import { ANIMATION_DURATION } from './constants';
// Components & Style
import CalloutBox from './CalloutBox';
import styles from './styles.scss';

const FadeInTextContent = ({ children, isParentVisible }) => {
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    if (isParentVisible) setStartAnimation(true);
  }, [isParentVisible]);

  const { opacity } = useSpring({
    opacity: startAnimation ? 1 : 0,
  });

  return (
    <animated.div className={cn(styles.textContent, 'paddingY-small')} style={{ opacity }}>
      {children}
    </animated.div>
  );
};

const AnimatedCalloutBox = ({ visible, wrapperClassName, ...rest }) => {
  const callBoxRef = useRef();

  const startHeight = '0';
  const [contentHeight, setContentHeight] = useState(startHeight);

  const setNewHeight = () => {
    if (callBoxRef.current) {
      return setContentHeight(callBoxRef.current.offsetHeight || '0');
    }
    return null;
  };

  useEventListener({ eventName: 'resize', handler: setNewHeight });

  useEffect(() => {
    setNewHeight();
  }, []);

  const expand = useSpring({
    config: { duration: ANIMATION_DURATION.SLIDE },
    height: visible ? `${contentHeight}px` : `${startHeight}px`,
  });

  // Wrap the content container in a closure so we can pass visible state from parent
  const TextContentContainer = (props) => (
    <FadeInTextContent isParentVisible={visible} {...props} />
  );

  const animatedDivStyles = cn(styles.animatedContainer, { [wrapperClassName]: visible });

  return (
    <animated.div style={expand} className={animatedDivStyles}>
      <CalloutBox {...rest} textContentContainer={TextContentContainer} ref={callBoxRef} />
    </animated.div>
  );
};

FadeInTextContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isParentVisible: PropTypes.bool,
};

AnimatedCalloutBox.propTypes = { visible: PropTypes.bool, wrapperClassName: PropTypes.string };

export default AnimatedCalloutBox;

/*
  Notes
  -------
  - className prop will get passed through to the base CalloutBox. wrapperClassName will be applied
    to the outer AnimatedCalloutBox div
  - If you want to apply vertical margins you should apply them using the wrapperClassName prop. Because of the way
    the animation works it will not apply the margins as expected.
  - If you want to apply horizontal margins you should consider wrapping this component in a parent element
    and applying the margins to the parent, e.g.
      <div className="marginLeft-small"><AnimatedCalloutBox /></div>
    instead of
      <AnimatedCalloutBox wrapperClassName="marginLeft-small" />
*/
