import { fromJS } from 'immutable';
import { QUESTION_TYPES } from 'src/containers/AddSkuPage/constants';
import { PAGE_LOADED } from '../../constants/common';

export default function reducer(state = fromJS({}), action) {
  switch (action.type) {
    case PAGE_LOADED: {
      switch (action.page) {
        case 'orderConfirmation': {
          const { questions } = action;
          if (!questions) return state;
          const newQuestions = {};
          questions.forEach((q) => {
            if (!newQuestions[q.serviceId.toString()]) newQuestions[q.serviceId.toString()] = {};
            switch (q.inputType) {
              case QUESTION_TYPES.INPUT:
                newQuestions[q.serviceId.toString()][q.id.toString()] = { text: '' };
                break;
              case QUESTION_TYPES.TEXT_AREA:
                newQuestions[q.serviceId.toString()][q.id.toString()] = { text: '' };
                break;
              case QUESTION_TYPES.DROPDOWN: {
                const defaultAnswer = q.answers.find((a) => a.default);
                newQuestions[q.serviceId.toString()][q.id.toString()] = defaultAnswer ? { id: defaultAnswer.id } : null;
                break;
              }
              case QUESTION_TYPES.CHECKBOX:
                newQuestions[q.serviceId.toString()][q.id.toString()] = [];
                break;
              case QUESTION_TYPES.DEVICE: {
                const defaultAnswer = q.answers.find((a) => a.default);
                newQuestions[q.serviceId.toString()][q.id.toString()] = defaultAnswer
                  ? { make: defaultAnswer.id, model: defaultAnswer.id }
                  : null;
                break;
              }
              case QUESTION_TYPES.MEDIA:
                newQuestions[q.serviceId.toString()][q.id.toString()] = {media: []};
                break;
              default: {
                throw new Error(`Unknown inputType: ${q.inputType}`);
              }
            }
          });
          return fromJS(newQuestions);
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
}
