import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import plan from 'src/containers/Plans/PlanPageAlternate/reducer';
import registration from 'src/containers/RegistrationPage/reducer';
import sku from 'src/containers/SkuPage/reducer';
import skuCategory from 'src/containers/SkuCategoryPage/reducer';
import addSku from 'src/containers/AddSkuPage/reducer';
import product from 'src/containers/ProductPage/reducer';
import homePage from 'src/containers/HomePage/reducer';
import customerReviews from 'src/containers/CustomerReviewsPage/reducer';
import cart from 'src/containers/CartPage/reducer';
import booking from 'src/containers/BookingPage/reducer';
import bookingPaloAltoNetworks from 'src/containers/BookingPagePaloAltoNetworks/reducer';
import availability from 'src/containers/BookingPage/AvailabilityPage/reducer';
import reschedule from 'src/containers/ReschedulingPage/reducer';
import verification from 'src/containers/BookingPage/VerificationPage/reducer';
import address from 'src/containers/BookingPage/AddressPage/reducer';
import payment from 'src/containers/BookingPage/PaymentPage/reducer';
import summary from 'src/containers/BookingPage/SummaryPage/reducer';
import orderConfirmation from 'src/containers/OrderConfirmationPage/reducer';
import account from 'src/containers/Account/AccountPage/reducer';
import accountOrders from 'src/containers/Account/OrdersPage/reducer';
import accountOrder from 'src/containers/Account/OrderPage/reducer';
import accountPayment from 'src/containers/Account/PaymentPage/reducer';
import accountDevices from 'src/containers/Account/DevicesPage/reducer';
import accountScoreCard from 'src/containers/Account/HomeScoreCardPage/reducer';
import accountPlan from 'src/containers/Account/PlanPage/reducer';
import techMap from 'src/containers/Account/TechMapPage/reducer';
import createPasswordPage from 'src/containers/CreatePasswordPage/reducer';
import landing from 'src/containers/LandingPage/reducer';
import customerSurvey from 'src/containers/CustomerSurvey/reducer';
import login from 'src/containers/LoginPage/reducer';
import location from 'src/containers/LocationPage/reducer';
import geekSquad from 'src/containers/GeekSquadPage/reducer';
import microsoft from 'src/containers/MicrosoftPage/reducer';
import redeem from 'src/containers/Plans/RedeemPage/reducer';
import finishPartnerOrder from 'src/containers/FinishPartnerOrderPage/reducer';
import ahsValidationPage from 'src/containers/AHSValidationPage/reducer';
import XfinityTech from 'src/containers/ComcastPage/XfinityTechPage/reducer';
import subscriptionGifting from 'src/containers/SubscriptionGiftingPage/reducer';
import zipCodeValidationPage from 'src/containers/ZipCodeValidationPage/reducer';
import landingPageTest from 'src/containers/SimpliSafeLandingPage/reducer';
import getStatusPage from 'src/containers/GetOrderStatusPage/reducer';
import speedTestPage from 'src/containers/SpeedTestPage/reducer';
import pinRedemptionPage from 'src/containers/FinishOrder/PINRedemption/reducer';
import {
  NOOP,
  INVALID_FORM,
  CLEAR_FORM_ERRORS,
  CLEAR_FORM_ERROR,
  LOAD_PAGE,
  PAGE_LOADED,
  PAGE_LOADING_ERROR,
  PAGE_NOTICES,
  CLEAR_PAGE_NOTICES,
  PAGE_NOTICE,
  CLEAR_PAGE_NOTICE,
} from 'src/constants/common';
import { ADD_PAGE_SNACK_NOTICE, REMOVE_PAGE_SNACK_NOTICE } from 'src/constants/snackNotice';
import current from './current';

const pages = {
  plan,
  current,
  registration,
  sku,
  skuCategory,
  addSku,
  product,
  homePage,
  customerReviews,
  cart,
  redeem,
  booking,
  bookingPaloAltoNetworks,
  availability,
  reschedule,
  verification,
  address,
  payment,
  summary,
  orderConfirmation,
  account,
  accountOrders,
  accountOrder,
  accountDevices,
  accountScoreCard,
  accountPayment,
  accountPlan,
  techMap,
  createPasswordPage,
  landing,
  customerSurvey,
  login,
  location,
  geekSquad,
  microsoft,
  finishPartnerOrder,
  ahsValidationPage,
  XfinityTech,
  subscriptionGifting,
  zipCodeValidationPage,
  landingPageTest,
  getStatusPage,
  speedTestPage,
  pinRedemptionPage,
};

export function commonPageReducer(globalState = fromJS({}), action) {
  let state = globalState;
  if (!state.get('pages')) {
    const pagesState = fromJS({}).asMutable();
    const pageCommonState = fromJS({
      errors: {},
      notices: {},
      snackNotices: { noticeId: 0 },
      loading: true,
      error: null,
      beforeLoadState: null,
    });
    Object.keys(pages).forEach((pageName) => {
      if (pageName !== 'current') {
        const page = pages[pageName];
        const initialPageState = page(undefined, { type: NOOP }) || {};
        pagesState.set(pageName, pageCommonState.merge(initialPageState));
      }
    });
    state = state.set('pages', pagesState.asImmutable());
  }
  switch (action.type) {
    case LOCATION_CHANGE: {
      const oldLocation = state.getIn(['beforeRouteTransition', 'location']).toJS();
      const beforeRouteTransition = state.get('beforeRouteTransition');
      const newLocation = action.payload.location;

      if (oldLocation.pathname === newLocation.pathname) {
        return state;
      }

      const newState = state.asMutable();
      newState.mergeIn(['tracking'], {
        previousLocation: beforeRouteTransition.get('previousLocation'),
        currentLocation: beforeRouteTransition.getIn(['location.pathname']),
      });

      Object.keys(pages).forEach((pageName) => {
        if (pageName !== 'current') {
          newState.mergeIn(['pages', pageName], {
            errors: {},
            notices: {},
            snackNotices: { noticeId: 0 },
            loading: true,
            error: null,
          });
        }
      });
      return newState.asImmutable();
    }
    case INVALID_FORM:
      return state.setIn(['pages', action.page, 'errors'], fromJS(action.errors));
    case CLEAR_FORM_ERRORS:
      return state.setIn(['pages', action.page, 'errors'], fromJS({}));
    case CLEAR_FORM_ERROR:
      return state.setIn(['pages', action.page, 'errors', action.field], null);
    case PAGE_NOTICES:
      return state.setIn(['pages', action.page, 'notices'], fromJS(action.notices));
    case CLEAR_PAGE_NOTICES:
      return state.setIn(['pages', action.page, 'notices'], fromJS({}));
    case PAGE_NOTICE:
      return state.setIn(['pages', action.page, 'notices', action.name], {
        type: action.noticeType,
        message: action.message,
      });
    case CLEAR_PAGE_NOTICE:
      return state.deleteIn(['pages', action.page, 'notices', action.name]);
    case ADD_PAGE_SNACK_NOTICE: {
      const {
        pageName,
        componentName,
        status,
        content,
        template,
        templateData,
        dismissable,
      } = action;
      const snackNotices = state.getIn(['pages', pageName, 'snackNotices']);
      const currentNoticeId = snackNotices.get('noticeId');
      const componentNotices = snackNotices.get(componentName);
      let newComponentNotices = null;
      if (componentNotices) {
        newComponentNotices = componentNotices.unshift(
          fromJS({
            status,
            content,
            template,
            templateData,
            dismissable,
            noticeId: currentNoticeId,
          }),
        );
      } else {
        newComponentNotices = fromJS([
          {
            status,
            content,
            template,
            templateData,
            dismissable,
            noticeId: currentNoticeId,
          },
        ]);
      }
      const newNoticeId = currentNoticeId + 1;
      const newSnackNotices = snackNotices.merge({
        [componentName]: newComponentNotices,
        noticeId: newNoticeId,
      });
      return state.setIn(['pages', pageName, 'snackNotices'], newSnackNotices);
    }
    case REMOVE_PAGE_SNACK_NOTICE: {
      const { pageName, componentName, noticeId } = action;
      const componentNotices = state.getIn(['pages', pageName, 'snackNotices', componentName]);
      const index = componentNotices.findIndex((notice) => notice.get('noticeId') === noticeId);
      const newComponentNotices = componentNotices.delete(index);
      return state.setIn(['pages', pageName, 'snackNotices', componentName], newComponentNotices);
    }
    case LOAD_PAGE: {
      if (state.hasIn(['pages', action.page])) {
        let pageState = state.getIn(['pages', action.page]);
        pageState = pageState.merge({
          errors: {},
          notices: {},
          snackNotices: { noticeId: 0 },
          loading: true,
          error: null,
          beforeLoadState: pageState,
        });
        return state.setIn(['pages', action.page], pageState);
      }
      return state;
    }
    case PAGE_LOADED: {
      let pageState = state.getIn(['pages', action.page]);
      const beforeLoadState = pageState.get('beforeLoadState');
      pageState = pageState.merge({ loading: false, error: null, beforeLoadState: null });
      if (beforeLoadState && beforeLoadState.equals(pageState)) {
        pageState = beforeLoadState;
      }
      return state.setIn(['pages', action.page], pageState);
    }
    case PAGE_LOADING_ERROR:
      return state.mergeIn(['pages', action.page], { loading: false, error: action.error });
    default:
      return state;
  }
}

export default () => combineReducers(pages);
