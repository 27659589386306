/* eslint-disable camelcase */
import moment from 'moment';
import { call, select } from 'redux-saga/effects';
import { selectRoutes } from 'src/apiRoutes';
import { extractZipFromAddress } from 'src/utils/address';
import { PST_TIMEZONE_LA } from 'src/constants/app';
import { isImmutable, immutableToJS } from 'src/utils/helpers';
import { memoizedCreateCartServiceScheduling } from 'src/utils/serviceScheduling/cartServiceSchedulingFactory';
import { SCHEDULE_TYPES } from 'src/utils/serviceScheduling/serviceScheduling.constants';

const getZip = (cart) => {
  if (!cart.address) return '';
  if (cart.address.zip) return cart.address.zip;
  return extractZipFromAddress(cart.address.fullAddress).zip;
};

/**
 * Fetches the available times for booking an appointment from the BE.
 *
 * @param {Object} params - The parameters for the API call.
 * @param {number} params.numHoursToBuffer - Number of hours to add to the first available appointment time.
 * @param {number} params.numDaysToBuffer - Number of days to add to the first available appointment time.
 *
 * @returns {Object} The response object containing the available times.
 */
export function* getAvailableTimesApi({ numHoursToBuffer = 0, numDaysToBuffer = 0 }) {
  const routes = yield call(selectRoutes);
  const cart = yield select((state) => state.getIn(['entities', 'cart']));

  const lat = cart.get('lat');
  const lng = cart.get('lng');
  const items = cart.get('items');
  const tz = cart.get('timezone');
  const isRemote = cart.get('remote');
  const hasProducts = cart.get('hasProducts');
  const orderId = cart.getIn(['order', 'id'], null);

  const query = {
    source: 'client',
    tz: tz || PST_TIMEZONE_LA,
    zip: getZip(cart.toJS()),
  };

  if (hasProducts) {
    query.isProduct = true;
  }

  if (items.size > 0) {
    query.skus = [];
    items.forEach((item) => query.skus.push(item.get('skuId')));
  }

  if (isRemote) {
    query.isRemote = true;
  } else {
    if (lng) query.lng = lng;
    if (lat) query.lat = lat;
  }

  if (orderId) {
    query.orderId = orderId;
  }

  if (numHoursToBuffer) {
    query.numHoursToBuffer = numHoursToBuffer;
  }

  if (numDaysToBuffer) {
    query.numDaysToBuffer = numDaysToBuffer;
  }

  const { isExactTimeSchedule, getExactTimeType } = memoizedCreateCartServiceScheduling({
    cart: cart.toJS() || {},
  });

  if (isExactTimeSchedule()) {
    query.scheduleType = SCHEDULE_TYPES.EXACT_TIME;
    query.exactTimeType = getExactTimeType();
  }

  const response = yield call(routes.cart.availableTimes, query);
  return response;
}

export function* checkZipCodeRestriction() {
  const cart = yield select((state) => state.getIn(['entities', 'cart']));
  const routes = yield call(selectRoutes);
  const zip = getZip(cart.toJS());
  const response = yield call(routes.cart.checkZipCodeRestriction, { zip });
  return response.err ? response.err : response.data;
}

export const isTodaysDate = (date) => moment(date).isSame(moment(), 'day');

export const formatDate = (date, format) => {
  return moment(date, 'YYYY-MM-DD').format(format);
};

export const formatTimeForTimeWindowsDisplay = (hour) => {
  let formatted = hour;
  if (hour === 12) {
    formatted = '12pm';
  } else if (hour < 12) {
    formatted = `${hour}am`;
  } else if (hour > 12) {
    formatted = `${hour - 12}pm`;
  }
  return formatted;
};

export const formatTimeSpanDisplay = (data) => {
  const { 0: a, length: l, [l - 1]: b } = data;

  // Adjusting the end hour to display "8" instead of "9" if the start hour is "5"
  let endHour = b.hour + 1;
  if (a.hour === 17 && endHour === 21) {
    // 17 is 5pm in 24-hour format, 21 is 9pm
    endHour = 20; // Change to 20 which represents 8pm in 24-hour format
  }

  const display = `${formatTimeForTimeWindowsDisplay(a.hour)} - ${formatTimeForTimeWindowsDisplay(
    endHour,
  )}`;
  return display;
};

export const dateHasSelectedHours = (d) => {
  const date = isImmutable(d) ? d.toJS() : d;
  const selectedHours = date.hours.find((elem) => elem.checked);
  return Boolean(selectedHours);
};

/* XOR: Push in if doesn't currently exist as selected, remove if it does */
export const updateSelectedDateTimes = (selectedDateTimes, hourData, newCheckState) => {
  const selectedHourData = isImmutable(hourData) ? hourData.toJS() : hourData;
  const index = selectedDateTimes.indexOf(selectedHourData.dateTime);

  if (index === -1 && newCheckState) {
    selectedDateTimes.push(selectedHourData.dateTime);
  } else if (index !== -1 && !newCheckState) {
    selectedDateTimes.splice(index, 1);
  }
};

/**
 * Updates the immutable availability object with the selected hours to js and
 * manipulates the object to give us an useable form for window selections.
 */
export const normalizeAvailabilityForWindowBasedView = ({ availability, config }) => {
  if (!availability.size) return [];

  /* 1. Convert immutable to js */
  const availabilityJS = availability?.toJS();
  /*
     ----------------------------------
     Buffer the availability.
     1. chomp off n number of days from start

     Note: If performance degrades, we can use tranducers
     ----------------------------------
  */
  /* a. we don't want to use the users clock */
  const startDateBuffered = moment(availabilityJS[0].date)
    .add(config.BUFFERED_DAYS, 'days')
    .format('YYYY-MM-DD');
  /* b. Remove all dates prior to startDateBuffered, otherwise app will still display them. Just faded out. */
  const availabilityBuffered = availabilityJS.filter((date) => {
    return moment(date.date).isSameOrAfter(startDateBuffered);
  });

  /* c. Chomp off times after END_HOUR_MILIITARY & Group Times */
  const availabilityChompedChunked = availabilityBuffered.map((date) => {
    /* c.i Remove end hours */
    const hours = date.hours.filter(({ hour }) => {
      return hour < config.END_HOUR_MILIITARY;
    });
    /* c.ii Chunk the hours groupings: simple. Need a more robust solution */
    const groupHours = (() => {
      const tempArray = [];
      for (let i = 0; i < hours.length; i += config.GROUP_BY_HOURS) {
        const size = i + config.GROUP_BY_HOURS;
        if (size <= hours.length) {
          tempArray.push(hours.slice(i, size));
        }
      }
      return tempArray;
    })();

    return {
      ...date,
      hours: groupHours,
    };
  });

  return availabilityChompedChunked;
};

/**
 * Adjusts the end time for inclusivity and applies special formatting rules.
 * Specifically, this function adds one hour to the end time for inclusiveness, except when
 * the adjustment results in a 9 PM end time, which is then modified to 8 PM.
 *
 * @param {number} startTime - The start hour of the time range in 24-hour format.
 * @param {number} endTime - The end hour of the time range in 24-hour format.
 * @returns {string} A formatted time range string showing the start and adjusted end times.
 */
export const adjustAndFormatTimeRange = (startTime, endTime) => {
  // Adjust end time to be inclusive by adding one to the hour
  let adjustedEndTime = endTime + 1;

  // If the inclusive end time would be 9PM, adjust it to end at 8PM
  if (adjustedEndTime === 21) {
    adjustedEndTime = 20; // Change to 8PM
  }

  return `${formatTimeForTimeWindowsDisplay(startTime)} to ${formatTimeForTimeWindowsDisplay(
    adjustedEndTime,
  )}`;
};

/**
 * Formats a time range string by incrementing the end time by one hour, except when it includes '8pm'.
 * This adjustment helps align displayed time ranges with actual bookable times in the system.
 *
 * @param {string} timeRange - The time range string to format, e.g., "1pm-4pm".
 * @returns {string} The adjusted time range string.
 */
const formatTimeRangeForTimeWindows = (timeRange = '') => {
  const isLastHourBooked = /8pm/i.test(timeRange);
  if (!timeRange || isLastHourBooked) return timeRange;

  const [startTime, endTime] = timeRange.split('-');

  let endHour = parseInt(endTime, 10);
  const isPM = /pm/i.test(endTime);

  // Convert end time to 24-hour format if it's PM and not noon
  if (isPM && endHour !== 12) endHour += 12;

  // Increment the end time by 1 hour to match the display on the availability selector
  endHour += 1;

  const formattedEndTime = formatTimeForTimeWindowsDisplay(endHour);
  return `${startTime.trim()} - ${formattedEndTime}`;
};

/**
 * Formats availability data for display on the Cart Summary and Order Confirmation Pages.
 * This function handles both Immutable.js Maps and plain JavaScript objects for consolidated availability,
 * extracting and formatting the earliest date and corresponding time range. The time range is adjusted
 * according to business rules for display.
 *
 * @param {Map|Object} consolidatedAvailability - Consolidated availability data, which may be an
 *                                                Immutable.js Map or a plain object.
 * @returns {Object} An object with `formattedDate` and `formattedTime` suitable for display.
 *
 * Example:
 * // Input - Immutable.js Map or plain object with dates as keys and arrays of time strings as values:
 * let availabilityMap = Immutable.fromJS({
 *   '2024-03-03': ['1pm-4pm']
 * });
 * let availabilityObj = {
 *   '2024-03-03': ['1pm-4pm']
 * };
 * // Output:
 * {
 *   formattedDate: 'Wednesday, Mar 3',
 *   formattedTime: '1pm - 5pm'
 * }
 */
export const getFormattedAvailabilityForTimeWindows = (consolidatedAvailability) => {
  if (!consolidatedAvailability) return { formattedDate: '', formattedTimes: '' };

  const availabilityAsJs = immutableToJS(consolidatedAvailability);

  const date = Object.keys(availabilityAsJs)[0];
  const times = availabilityAsJs[date][0];

  const formattedDate = moment(date).format('dddd, MMM D');
  const formattedTimes = formatTimeRangeForTimeWindows(times);

  return {
    formattedDate,
    formattedTimes,
  };
};
