import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const ItemSummary = ({ children, containerStyles, header, headerStyles }) => (
  <section className={containerStyles}>
    <h5 className={headerStyles}>{header}</h5>
    {children}
  </section>
);

ItemSummary.propTypes = {
  children: PropTypes.element,
  containerStyles: PropTypes.string,
  header: PropTypes.string,
  headerStyles: PropTypes.string,
};

ItemSummary.defaultProps = {
  containerStyles: styles.itemSummary,
  header: 'Order Summary',
  headerStyles: styles.orderSummaryHeader,
};

export default React.memo(ItemSummary);

// TODO: This is only being used for SubscriptionGifting and when that feature redone or removed this component should be removed. -GH June 22, 2020
