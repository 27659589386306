import { fromJS } from 'immutable';
import { CLOUDINARY_TYPES } from 'src/components/Cloudinary/cloudinary.actions';
import { keysToSnakeCase } from 'src/components/Cloudinary/cloudinary.utils';

/*
 ********* INITIAL STATE
 */

const initialState = fromJS({
  fileUploadSuccess: {},
  fileUploadError: {}
});


export default (state = initialState, action) => {
  switch (action.type) {
    case CLOUDINARY_TYPES.SET_FILE: {
      const { success, append = false, editorNamespace } = action.payload;

      if (!append) {
        state.setIn(['fileUploadSuccess', editorNamespace.toString()], [keysToSnakeCase(success)]);
      };

      const currentSuccess = state.getIn(['fileUploadSuccess', editorNamespace.toString()], []);
      const transformedSuccess = keysToSnakeCase(success);
      /* If we don't have preseeded files, no dupes, merge/push/join them */
      if (!currentSuccess.some(f => f.public_id === transformedSuccess.public_id)) {
        currentSuccess.push(transformedSuccess);
      }

      return state.setIn(['fileUploadSuccess', editorNamespace.toString()], currentSuccess);
    }

    case CLOUDINARY_TYPES.DELETE_FILE: {
      const {ids, editorNamespace} = action.payload;

      const currentSuccess = state.getIn(['fileUploadSuccess', editorNamespace.toString()], []);
      const filteredSuccess = currentSuccess.filter((item) => !ids.includes(item.public_id));

      return state.setIn(['fileUploadSuccess', editorNamespace.toString()], filteredSuccess);
    }
    case CLOUDINARY_TYPES.SET_ERRORS: {
        // not using this
      return state;
    }

    default:
      return state;
  }
}








