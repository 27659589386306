import {
  ADD_APP_SNACK_NOTICE,
  REMOVE_APP_SNACK_NOTICE,
  CLEAR_APP_NOTICES,
  ADD_PAGE_SNACK_NOTICE,
  REMOVE_PAGE_SNACK_NOTICE,
} from 'src/constants/snackNotice';

// These actions are for specific scenarios. Use these over the others if possible.

export const addSuccessAppSnackNotice = ({ content, template, templateData = {} }) => ({
  type: ADD_APP_SNACK_NOTICE,
  status: 'success',
  content,
  template,
  templateData,
  dismissable: false,
});

export const addErrorAppSnackNotice = ({ content, template, templateData = {} }) => ({
  type: ADD_APP_SNACK_NOTICE,
  status: 'error',
  content,
  template,
  templateData,
  dismissable: true,
});

export const addSuccessPageSnackNotice = ({
  pageName,
  componentName,
  content,
  template,
  templateData = {},
  dismissable = false,
}) => ({
  type: ADD_PAGE_SNACK_NOTICE,
  pageName,
  componentName,
  status: 'success',
  content,
  template,
  templateData,
  dismissable,
});

export const addErrorPageSnackNotice = ({
  pageName,
  componentName,
  content,
  template,
  templateData = {},
  dismissable = true,
}) => ({
  type: ADD_PAGE_SNACK_NOTICE,
  pageName,
  componentName,
  status: 'error',
  content,
  template,
  templateData,
  dismissable,
});

// More generic actions

export const addAppSnackNotice = ({ autoClose = true, status, content, template, templateData = {}, dismissable = true }) => ({
  type: ADD_APP_SNACK_NOTICE,
  status,
  content,
  template,
  templateData,
  dismissable,
  autoClose,
});

export const removeAppSnackNotice = ({ noticeId }) => ({
  type: REMOVE_APP_SNACK_NOTICE,
  noticeId,
});

export const clearAppNotices = () => ({
  type: CLEAR_APP_NOTICES,
});

export const addPageSnackNotice = ({
  pageName,
  componentName,
  status,
  content,
  template,
  templateData = {},
  dismissable,
}) => ({
  type: ADD_PAGE_SNACK_NOTICE,
  pageName,
  componentName,
  status,
  content,
  template,
  templateData,
  dismissable,
});

export const removePageSnackNotice = ({ pageName, componentName, noticeId }) => ({
  type: REMOVE_PAGE_SNACK_NOTICE,
  pageName,
  componentName,
  noticeId,
});
