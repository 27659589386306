import { fromJS } from 'immutable';
import { SUBMIT_FORM, RESET_FORM, ZIP_VALIDATED, ZIP_NOT_VALIDATED } from './constants';

const initialState = { submitted: false, valid: null, zip: '', error: null };

function pageReducer(state = fromJS(initialState), action) {
  switch (action.type) {
    case SUBMIT_FORM:
      return state.merge({ submitted: true, zip: action.payload });
    case RESET_FORM:
      return state.merge(initialState);
    case ZIP_VALIDATED:
      return state.merge({ valid: true });
    case ZIP_NOT_VALIDATED:
      return state.merge({ valid: false });
    default:
      return state;
  }
}

export default pageReducer;
