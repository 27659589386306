// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
// Actions
import { startBookingAction as fieldSalesStartBooking } from 'src/containers/FieldSales/actions';
// Selectors
import {
  standardAndSubscriptionCouponSelector,
  totalServicesNonFormattedSelector,
  atLeastOnePriceTypeHourlySelector,
} from 'src/selectors/cart';
import { fieldSaleAgentVerifyLayoutSelector } from 'src/containers/FieldSales/selectors';
// Constants
import { COUPON_ADJUSTMENT_TYPES } from 'src/components/OrderSummary/Breakdown/constants';
// Components
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { PanelV2 } from 'src/components/HTKit/Elements/PanelV2';
import OrderBreakdownLineItem from 'src/components/OrderSummary/Breakdown/OrderBreakdownLineItem';
import OrderAdjustmentsBreakdown from 'src/components/OrderSummary/Breakdown/OrderAdjustmentsBreakdown';
import CouponAppliedLineItem from 'src/components/OrderSummary/Breakdown/CouponAppliedLineItem';
import CreditAppliedLineItem from 'src/components/OrderSummary/Breakdown/CreditAppliedLineItem';
import OrderTotalV2 from 'src/components/OrderSummary/Breakdown/OrderTotalV2';
import { AFFIRM_PROMO_TYPES, AffirmPromo } from 'src/components/Affirm';
import HourlyPriceTypeDisclaimer from 'src/components/Cart/Disclaimers/HourlyPriceDisclaimer';
import PromoCode from '../PromoCode';
// Styles
import styles from './styles.scss';

const OrderSummary = ({
  name,
  caption,
  amount,
  planName,
  planText,
  planCaption,
  planAmount,
  planLineThroughText,
  couponDetails,
  totalFormatted,
  startBooking,
  shouldAddPlanToCart,
  addPlanAndStartBooking,
  badgeText,
  isPlanOnlyCart,
  creditAdjustment = {},
  isForEstimatedCart = false,
}) => {
  // Hooks
  const dispatch = useDispatch();
  const standardAndSubscriptionCoupon = useSelector(standardAndSubscriptionCouponSelector);
  const affirmAmount = useSelector(totalServicesNonFormattedSelector);
  const fieldSalesAgent = useSelector(fieldSaleAgentVerifyLayoutSelector);
  const showHourlyDisclaimer = useSelector(atLeastOnePriceTypeHourlySelector);
  const summaryContainerStyles = classNames(
    styles.panelStyles,
    styles.summaryContainer,
    'padding-small2',
  );
  /**
   * buttonNoteDisplay
   * This function will return a display for a button caption, underneath.
   * If for whatever reason we get this text from BE and its empty, we return null
   * if empty.
   *
   * @param txt - string
   * @returns {React.Component | null}
   */
  const buttonNoteDisplay = (txt = '') => {
    if (!txt) return null;

    return <p className="p2 n700 paddingTop-small">{txt}</p>;
  };
  const ButtonDisplay = () => {
    let buttonTxt = 'Checkout';
    let onClick = startBooking;
    // Default caption/note display
    let ButtonNoteElem = () => buttonNoteDisplay();
    // different treatment if fieldsales flow
    if (fieldSalesAgent) {
      buttonTxt = 'Create Order Link';
      onClick = () => {
        dispatch(fieldSalesStartBooking());
      };
      ButtonNoteElem = () =>
        buttonNoteDisplay(
          'This order is not finalized until the customer approves and completes it via the order link.',
        );
    }
    // different treatment if desktop Upsell split
    if (shouldAddPlanToCart) {
      buttonTxt = 'Join & Checkout';
      onClick = addPlanAndStartBooking;
    }
    return (
      <>
        <Button onClick={onClick} theme={THEMES.V2PRIMARY} hugeV2>
          {buttonTxt}
        </Button>
        <ButtonNoteElem />
      </>
    );
  };
  const { coupon, couponText, couponValue, orderAdjustmentsNoCoupon } = couponDetails;
  const hasStandardCoupon = Boolean(
    standardAndSubscriptionCoupon?.[COUPON_ADJUSTMENT_TYPES.STANDARD],
  );
  const hasSubscriptionCoupon = Boolean(
    standardAndSubscriptionCoupon?.[COUPON_ADJUSTMENT_TYPES.SUBSCRIPTION],
  );
  const { amount: creditAmount, amountFormatted: creditAmountFormatted } = creditAdjustment;

  return (
    <PanelV2 className={summaryContainerStyles} lightShadow>
      <h5>Order Summary</h5>
      <div className={styles.totalWrapper}>
        {!isPlanOnlyCart && (
          <OrderBreakdownLineItem
            name={name}
            caption={caption}
            amount={amount}
            className="marginBottom-small"
          />
        )}
        {planName && (
          <OrderBreakdownLineItem
            name={planText}
            caption={planCaption}
            lineThroughText={planLineThroughText}
            amount={`$${planAmount}`}
            className="marginBottom-small"
          />
        )}
        {hasStandardCoupon && (
          <CouponAppliedLineItem
            couponType={COUPON_ADJUSTMENT_TYPES.STANDARD}
            name={couponText}
            amount={couponValue}
            hideIcon
            forceCouponProp={isForEstimatedCart}
          />
        )}
        {hasSubscriptionCoupon && (
          <CouponAppliedLineItem
            couponType={COUPON_ADJUSTMENT_TYPES.SUBSCRIPTION}
            name={couponText}
            amount={couponValue}
            hideIcon
            forceCouponProp={isForEstimatedCart}
          />
        )}
        {!!creditAmount && <CreditAppliedLineItem amount={creditAmountFormatted} />}
        {!!orderAdjustmentsNoCoupon.length && (
          <OrderAdjustmentsBreakdown adjustments={orderAdjustmentsNoCoupon} hideCreditAdjustment />
        )}
        <OrderTotalV2 text="Order Subtotal" totalFormatted={totalFormatted} badgeText={badgeText} />
      </div>
      <AffirmPromo type={AFFIRM_PROMO_TYPES.cart} amount={affirmAmount} />

      {!coupon && <PromoCode wrapperStyle="marginBottom-small1" headerSize="h6" copySize="p2" />}

      <ButtonDisplay />
      <HourlyPriceTypeDisclaimer showHourlyDisclaimer={showHourlyDisclaimer} />
    </PanelV2>
  );
};

OrderSummary.propTypes = {
  name: PropTypes.string,
  caption: PropTypes.string,
  amount: PropTypes.number,
  planName: PropTypes.string,
  planText: PropTypes.string,
  planCaption: PropTypes.string,
  planAmount: PropTypes.number,
  planLineThroughText: PropTypes.string,
  couponDetails: PropTypes.shape({
    coupon: PropTypes.object,
    couponText: PropTypes.string,
    couponValue: PropTypes.number,
    orderAdjustmentsNoCoupon: PropTypes.number,
  }),
  totalFormatted: PropTypes.string,
  startBooking: PropTypes.func,
  addPlanAndStartBooking: PropTypes.func,
  shouldAddPlanToCart: PropTypes.bool,
  badgeText: PropTypes.string,
  isPlanOnlyCart: PropTypes.bool,
  /**
   * Enforcing required props for credit adjustments.
   * Includes total credit amount from global `adjustments` and `subscriptionAdjustments`
   */
  creditAdjustment: PropTypes.shape({
    amount: PropTypes.number,
    amountFormatted: PropTypes.string,
    amountFormattedAbs: PropTypes.string,
  }).isRequired,
  isForEstimatedCart: PropTypes.bool,
};

export default OrderSummary;
