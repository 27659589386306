import React from 'react';
import PropTypes from 'prop-types';
import { StageEdit, StageEditHeader } from 'src/components/OrderSummary';
import OrderSummaryLineItem from 'src/components/OrderSummary/Breakdown/OrderSummaryLineItem';

export const RecurringServiceBlock = ({ onHeaderClick, text }) => {
  return (
    <StageEdit>
      <StageEditHeader headerText="Recurring Service" onClick={onHeaderClick} />
      <OrderSummaryLineItem> {text}</OrderSummaryLineItem>
    </StageEdit>
  );
};

RecurringServiceBlock.propTypes = {
  text: PropTypes.string.isRequired,
  onHeaderClick: PropTypes.func.isRequired,
};
