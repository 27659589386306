import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { brandingFirstCobrandLogoSelector } from 'src/selectors/branding';
import cn from 'classnames';
import ServicePriceMember from './ServicePriceMember';
import ServicePriceOriginal from './ServicePriceOriginal';
import ServicePriceBranded from './ServicePriceBranded';
import styles from './styles.scss';

const ServicePrice = ({
  service,
  onInfo = () => {},
  className,
  sm,
  showFixedPrice,
  linethroughPrice,
}) => {
  const brandLogo = useSelector(brandingFirstCobrandLogoSelector);
  const originalPrice = service.extra.prices.price;
  const noSavings = service.extra.prices.memberPrice === originalPrice;

  let Price = ServicePriceOriginal;
  if (service.extra.showMemberPrice && !noSavings) {
    Price = brandLogo ? ServicePriceBranded : ServicePriceMember;
  }

  return (
    <div className={cn(styles.price, { [styles.sm]: sm }, className)}>
      <Price
        service={service}
        sm={sm}
        onInfoClick={onInfo}
        brandLogo={brandLogo}
        showFixedPrice={showFixedPrice}
        linethroughPrice={linethroughPrice}
        showMemberPriceBadge={noSavings}
      />
    </div>
  );
};

ServicePrice.propTypes = {
  service: PropTypes.shape({
    extra: PropTypes.shape({
      showMemberPrice: PropTypes.bool,
      prices: PropTypes.shape({
        memberPrice: PropTypes.string,
        price: PropTypes.string,
      }),
    }),
  }),
  className: PropTypes.string,
  sm: PropTypes.bool,
  onInfo: PropTypes.func.isRequired,
  showFixedPrice: PropTypes.bool,
  linethroughPrice: PropTypes.bool,
};

export default memo(ServicePrice);
