import React, { useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { chunk } from 'lodash';
import getPlanBenefitsListAndValue from 'src/components/SubscriptionBenefitsBreakdown/utils';
import { Grid } from 'src/components/HTKit/Grid';
import { Badge, BADGE_TYPES } from 'src/components/Elements/Badge';
import { PLAN_BENEFITS_IDS } from 'src/constants/planBenefits';
import styles from './styles.scss';

// Move this to a global file if used multiple times
const getSortedList = (benefits) => {
  const { ONLINE, CHECKUP, ANTIVIRUS, PASSWORD, IN_HOME, HOME_TECH, PRODUCT } = PLAN_BENEFITS_IDS;
  const sortOrder = [ONLINE, CHECKUP, ANTIVIRUS, PASSWORD, IN_HOME, HOME_TECH, PRODUCT];
  return sortOrder.reduce((acc, elem) => {
    const match = benefits.find((b) => {
      return b.id === elem;
    });
    if (match) acc.push(match);
    return acc;
  }, []);
};

const Benefits = ({ plan, plansInfo }) => {
  if (!plansInfo) return null;
  const { benefits } = plansInfo.monthly;

  const { benefitsList, benefitsValue } = getPlanBenefitsListAndValue({ plan });
  const sortedList = getSortedList(benefits);

  const chunkedList = useMemo(() => {
    return chunk(sortedList, 2);
  }, [benefitsList, benefitsValue]);

  return (
    <Grid.FullWidth classes="paddingTop-medium1">
      {chunkedList.map((chunkedBenefits, index) => (
        <Grid.Row classes={styles.benefitsContainer} key={index}>
          {chunkedBenefits.map((benefit) => {
            const { header, subheader, icon, value } = benefit;
            return (
              <>
                {/* DesktopView */}
                <Grid.Column key={header} sm="hidden" lg={1} classes={styles.imageContainer}>
                  <img src={icon} className={styles.image} />
                </Grid.Column>
                <Grid.Column sm="hidden" lg={5} key={header} classes={styles.benefitsTextContainer}>
                  <h6 className="marginBottom-tiny">
                    {header}
                    {value && (
                      <span className="marginLeft-tiny1">
                        <Badge type={BADGE_TYPES.CONFIRMED} variant="outlined" size="small">
                          {value} value
                        </Badge>
                      </span>
                    )}
                  </h6>
                  <p className={cn('p2 n700')}>{subheader}</p>
                </Grid.Column>
                {/* Mobile view */}
                <Grid.Row classes={cn('paddingBottom-medium', styles.mobileBenefitsContainer)}>
                  <Grid.Column lg={1} key={header} classes={styles.imageContainer}>
                    <img src={icon} className={styles.image} />
                  </Grid.Column>
                  <Grid.Column classes={styles.mobileContent} lg={5} key={header}>
                    <h6 className="marginBottom-tiny">
                      {header}
                      {value && (
                        <span className={cn('marginLeft-tiny1', styles.badge)}>
                          <Badge type={BADGE_TYPES.CONFIRMED} variant="outlined" size="small">
                            {value} value
                          </Badge>
                        </span>
                      )}
                    </h6>
                    <p className="p2 n700">{subheader}</p>
                  </Grid.Column>
                </Grid.Row>
              </>
            );
          })}
        </Grid.Row>
      ))}
    </Grid.FullWidth>
  );
};

Benefits.propTypes = {
  plan: PropTypes.object,
  plansInfo: PropTypes.object,
  showValueBadges: PropTypes.bool,
};

export default Benefits;
