/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */
import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { splitReducerSanitizer } from 'src/components/SplitIO';
import { NEW_ROUTE_SCHEMA, UPDATE_USER, USER_IS_PRISTINE } from 'src/constants/common';
import {
  UPDATE_AUTH,
  USER_LOADED,
  USER_LOGGED_OUT,
} from 'src/containers/AppBase/constants';
import branding from 'src/utils/branding/reducer';

import components from './components';
import modals from './modals';
import entities from './entities';
import pages, { commonPageReducer } from './pages/index';
// import segment from './segment';
import newItem from './newItem';
import layoutReducer from './layout';
import tracking from './tracking';
import chat from './chat';
import sockets from './sockets';

const routeSchemaInitialState = fromJS({
  skuCategories: [],
  popularSkus: [],
});

function routeSchemaReducer(state = routeSchemaInitialState, action) {
  switch (action.type) {
    case NEW_ROUTE_SCHEMA:
      return fromJS(action.routes);
    default:
      return state;
  }
}

function optimizeIndexReducer(state = '0', action) {
  switch (action.type) {
    default:
      return state;
  }
}

function isMobileReducer(state = false, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function isTabletReducer(state = false, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function isMobileV2Reducer(state = false, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function isTabletV2Reducer(state = false, action) {
  switch (action.type) {
    default:
      return state;
  }
}
function isUserPristineReducer(state = true, action) {
  switch (action.type) {
    case USER_IS_PRISTINE: {
      return fromJS(action.payload.userIsPristine);
    }
    default: {
      return state;
    }
  }
}

const authReducerInitialState = fromJS({
  cookie: null,
  authToken: '',
  formAuthenticityToken: null,
});

function authReducer(state = authReducerInitialState, action) {
  switch (action.type) {
    case UPDATE_AUTH:
      return state.merge(action.auth);
    case USER_LOGGED_OUT:
      return authReducerInitialState;
    case UPDATE_USER:
      return state.merge({
        authToken: action.payload.user.authToken,
        formAuthenticityToken: action.payload.user.formAuthenticityToken,
      });
    case USER_LOADED:
      return state.merge({
        authToken: action.user.authToken,
        formAuthenticityToken: action.user.formAuthenticityToken,
      });
    default:
      return state;
  }
}

function userReducer(state = null, action) {
  switch (action.type) {
    case USER_LOADED:
      return state ? state.merge(action.user) : fromJS(action.user);
    case UPDATE_USER:
      return fromJS(action.payload.user);
    case USER_LOGGED_OUT:
      return null;
    default:
      return state;
  }
}

function sameReducer(state = null) {
  return state;
}

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers, history) {
  const combined = combineReducers({
    beforeRouteTransition: sameReducer,
    // segment,
    user: userReducer,
    auth: authReducer,
    routesSchema: routeSchemaReducer,
    components: components(),
    entities: entities(),
    pages: pages(),
    modals: modals(),
    optimizeIndex: optimizeIndexReducer,
    isMobile: isMobileReducer,
    isTablet: isTabletReducer,
    isMobileV2: isMobileV2Reducer,
    isTabletV2: isTabletV2Reducer,
    isUserPristine: isUserPristineReducer,
    newItem,
    layout: sameReducer,
    tracking,
    chat,
    router: connectRouter(history),
    sockets: sockets(),
    branding,
    splitio: splitReducerSanitizer,
    ...asyncReducers,
  });
  return (state, action) => {
    let newState = state;
    newState = commonPageReducer(newState, action);
    newState = layoutReducer(newState, action);
    return combined(newState, action);
  };
}

export function createReducerWrapper(routeReducer) {
  return (state = fromJS({}), action) => {
    let prevLocation = state.getIn(['beforeRouteTransition', 'location', 'pathname']);
    const setPreviousLocation = state.getIn(['beforeRouteTransition', 'previousLocation']);
    const currentLocation = state.getIn(['router', 'location', 'pathname']);

    if (prevLocation === currentLocation) {
      prevLocation = setPreviousLocation;
    };

    const newState = state.set('beforeRouteTransition', state.get('router', fromJS({})).merge(fromJS({
      previousLocation: prevLocation
    })));

    return routeReducer(newState, action);
  };
}

