import React from 'react';
import PropTypes from 'prop-types';
import { TRANSPARENT_PIXEL, TYPES } from './constants';

const Sources = ({ sources }) => {
  // returns an array of <source> elements
  if (!sources) return null;
  return sources.map((source, index) => {
    if (source.srcSet == null) return null;
    return (
      <source
        key={`sources-${index}`}
        srcSet={source.srcSet}
        media={source.media}
        type={source.type}
      />
    );
  });
};

const ImageFallback = ({
  alt = '',
  src = TRANSPARENT_PIXEL,
  sizes,
  skipSizes = false,
  ...rest
}) => {
  // Adds sizes props if sources isn't defined
  const sizesProp = skipSizes ? null : { sizes };
  return <img alt={alt} srcSet={src} {...sizesProp} {...rest} />;
};

const Picture = ({ sources = null, ...rest }) => {
  return sources === null ? (
    <ImageFallback {...rest} />
  ) : (
    <picture>
      <Sources sources={sources} />
      <ImageFallback skipSizes {...rest} />
    </picture>
  );
};


Picture.propTypes = {
  sources: PropTypes.array,
  srcSet: PropTypes.string,
  type: PropTypes.oneOf([Object.values(TYPES)]),
  media: PropTypes.string,
};

ImageFallback.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  sizes: PropTypes.string,
  skipSizes: PropTypes.bool,
  className: PropTypes.string,
};

export default Picture;

/*
  Picture: A wrapper for <img> tags that provides the ability to return a raw <img> tag or a <picture> tag with <source> children.
  - This component borrows heavily from react-responsive-picture: https://github.com/braposo/react-responsive-picture
Props:
  - sources: Array (The array of source objects.)
  - src: String (Source for standalone/fallback image. To prevent issues in some browsers, by default src is set to a 1x1 transparent pixel data image.)
  - alt: String (Alternative text for image)
  - className: String (Any additional CSS classes you might want to use to style the image.)
Tips:
  - In sources array:
    - Define smaller images first.
    - Define webp version first of the same image
    - No need to define `type` for jpg

Examples:

1. Returning a raw image tag
-----------------------------
<Picture src="path-to-image@2x.png 2x, path-to-image.png 1x" />
  returns =>
<img srcset="path-to-image@2x.png 2x, path-to-image.png 1x" />

2. Returning picture and source tags
------------------------------------
<Picture
    sources = {[
        { srcSet: "path-to-mobile-image.jpg, path-to-mobile-image@2x.jpg 2x", media: "(max-width: 420px)" },
        { srcSet: "path-to-desktop-image.jpg 1x, path-to-desktop-image@2x.jpg 2x", },
        { srcSet: "path-to-desktop-image.webp", type: "image/webp" }
    ]}
/>
  returns =>
<picture>
  <source srcset="path-to-mobile-image.jpg, path-to-mobile-image@2x.jpg 2x" media="(max-width: 420px)">
  <source srcset="path-to-desktop-image.jpg 1x, path-to-desktop-image@2x.jpg 2x">
  <source srcset="path-to-desktop-image.webp" type="image/webp">
  <img srcset="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" />
</picture>

3. Return picture and source tags, but don't load desktop images if user is on mobile
------------------------------------------------------------------------------------
  <Picture
    sources={ srcSet: "path-to-mobile-image.jpg", media: `(min-width: ${BREAKPOINTS.LG_GRID}px)` }
    src={ "path-to-fallback.jpg" }
  />
    returns =>
  <picture>
    <source srcset="path-to-mobile-image.jpg" media="(min-width: 992px)">
    <img srcset="path-to-fallback.jpg" />
  </picture>
*/
