export const PAGE_NAME = 'landing';

export const GEEK_SQUAD_VS_HELLOTECH_SEO_NAME = 'geek-squad-vs-hellotech';
export const INSTALL_SETUP_SEO_NAME = 'install-setup';

export const LANDING_PAGES = [
  ['/ahs', 'ahstvmounting'],
  ['/alticenest', 'alticenest'],
  ['/amfam', 'amfam'],
  ['/amigo-energy', 'amigo-energy'],
  ['/august', 'august'],
  ['/axis', 'helloaxis'],
  ['/bravia', 'bravia'],
  ['/bravia-installation', 'bravia-installation'],
  ['/getcujo', 'cujo'],
  ['/homeadvisor', 'homeadvisor'],
  ['/hsa', 'hsatvmounting'],
  ['/just-energy', 'just-energy'],
  ['/keezel', 'keezel'],
  ['/linksys', 'linksys'],
  ['/lowes', 'lowes'],
  ['/lowessh', 'lowessh'],
  ['/netgear', 'netgear'],
  ['/optimum', 'optimum-general'],
  ['/optimum-movers', 'optimum-movers'],
  ['/optimum-support', 'optimum-support'],
  ['/rachio', 'rachio'],
  ['/ringhomeinstall', 'ring'],
  ['/simplisafe', 'simplisafe'],
  ['/simplisafe-installation', 'simplisafe-installation'],
  ['/smb-basic', 'smb-basic'],
  ['/smb-premium', 'smb-premium'],
  ['/square', 'square'],
  ['/suddenlink', 'suddenlink-general'],
  ['/suddenlink-movers', 'suddenlink-movers'],
  ['/suddenlink-support', 'suddenlink-support'],
  ['/tara-energy', 'tara-energy'],
  ['/xfinity', 'xfinity'],
  ['/comcastbusiness', 'comcastbusiness'],
  [`/${GEEK_SQUAD_VS_HELLOTECH_SEO_NAME}`, GEEK_SQUAD_VS_HELLOTECH_SEO_NAME],
  [`/${INSTALL_SETUP_SEO_NAME}`, INSTALL_SETUP_SEO_NAME],
];

// Partner Cookie related
export const PARTNER_LANDING_COOKIE = 'partnerLanding';
export const SET_CURRENT_LANDING = 'landingPage/SET_CURRENT_LANDING';
export const REMOVE_CURRENT_LANDING = 'landingPage/REMOVE_CURRENT_LANDING';
export const PAINTED_DOOR_TEST_IDS = [879, 880];
