import { fromJS } from 'immutable';
import { FORM_SUBMITTED, DISMISS_THANK_YOU_PAGE } from './constants';

const XfinityTechPageInitialState = fromJS({
  errors: {},
  formSubmitted: false,
});

export default function techReducer(state = XfinityTechPageInitialState, action) {
  switch (action.type) {
    case FORM_SUBMITTED:
      return state.merge({ formSubmitted: action.payload.formSubmitted });
    case DISMISS_THANK_YOU_PAGE:
      return state.merge({ formSubmitted: action.payload.formSubmitted });
    default:
      return state;
  }
}
