import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
// Utils
import { formatDate } from './SelectDatePicker.utils';
// Components
// import Select from '../HTKit/Forms/SelectField';
import SelectField from 'react-select';
import { CalendarDatePicker } from '../CalendarDatePicker';
import Icon from '../HTKit/Icon';
import { Label } from '../HTKit/Forms/Parts';
import { Placeholder } from './SelectDatePicker.Placeholder';
import { SingleValue } from './SelectDatePicker.SingleValue';
import styles from './styles.scss';

// ##############
// SUBCOMPONENTS
// ##############
const DropdownIndicator = memo(({ selectProps }) => {
  const iconName = selectProps.menuIsOpen ? 'chevron-up' : 'chevron';
  return <Icon name={iconName} />;
});

// ##############
// MAIN COMPONENT
// ##############

export const SelectDatePicker = ({
  className = '',
  label = 'Start date',
  selectedDate,
  setSelectedDate,
  validDates,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleDateChange = useCallback(
    (value) => {
      if (value instanceof Date) {
        setSelectedDate(value);
        setIsCalendarOpen(false);
      }
    },
    [setSelectedDate],
  );

  const selectValue = selectedDate
    ? { value: selectedDate, label: formatDate(selectedDate) }
    : null;

  return (
    <div className={className}>
      <Label label={label} />
      <SelectField
        value={selectValue}
        onChange={() => {}}
        onMenuOpen={() => setIsCalendarOpen(true)}
        onMenuClose={() => setIsCalendarOpen(false)}
        isSearchable={false}
        components={{
          // eslint-disable-next-line react/display-name
          Menu: (props) => {
            // eslint-disable-next-line react/prop-types
            const { innerProps, ...rest } = props;
            return (
              <div {...innerProps} {...rest} className={styles.absolutePosition}>
                <CalendarDatePicker
                  selectedDate={selectedDate}
                  onDateChange={handleDateChange}
                  validDates={validDates}
                />
              </div>
            );
          },
          DropdownIndicator,
          Placeholder,
          SingleValue,
          IndicatorSeparator: () => null,
        }}
        menuIsOpen={isCalendarOpen}
        placeholder="Select a date"
        classNamePrefix="select-date-picker"
        className={styles.datePickerContainer}
      />
    </div>
  );
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
  }),
};
DropdownIndicator.displayName = 'DropdownIndicator';

SelectDatePicker.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  selectedDate: PropTypes.instanceOf(Date),
  setSelectedDate: PropTypes.func.isRequired,
  validDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
};
