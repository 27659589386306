import { ENV } from 'src/constants/app';
import { PRODUCTS_CATEGORY_IDS } from 'src/components/Header/TopNavHeader/constants';

/**
 * Add your Product urls here
 */
const baseEnterPath = '/products';
const baseHost = process.env.NODE_ENV === ENV.PRODUCTION ? 'products' : 'productss';
const baseRedirectPath = `https://${baseHost}.hellotech.com/`;
const {
  SMART_THERMOSTAT,
  SMART_GARAGE_DOOR_OPENER,
  VIDEO_DOORBELL,
  SMOKE_ALARM,
  SMART_HUB,
  MESH_NETWORK,
  SMART_CAMERA,
  TV_MOUNT,
  SMART_LOCK,
  HOME_THEATER,
} = PRODUCTS_CATEGORY_IDS;

/* These are the links that will redirect into the iframe behavior. Need this also on the nav dropdown */
export const IFRAME_SRCS_CAT_BY_ID = Object.freeze({
  default: `${baseEnterPath}/shop`,
  [SMART_THERMOSTAT]: `${baseEnterPath}/smart-thermostat-bundle`,
  [VIDEO_DOORBELL]: `${baseEnterPath}/video-doorbell-bundle`,
  [SMART_CAMERA]: `${baseEnterPath}/smart-camera-bundle`,
  [SMART_HUB]: `${baseEnterPath}/smart-hub-bundle`,
  [MESH_NETWORK]: `${baseEnterPath}/mesh-network-bundle`,
  [SMOKE_ALARM]: `${baseEnterPath}/smoke-alarm-bundle`,
  [SMART_GARAGE_DOOR_OPENER]: `${baseEnterPath}/smart-garage-door-bundle`,
  [TV_MOUNT]: `${baseEnterPath}/tv-mount-bundle`,
  [SMART_LOCK]: `${baseEnterPath}/smart-lock`,
  [HOME_THEATER]: `${baseEnterPath}/home-theater`,
});

/* These are the sources which a user is "rerouted" */
export const IFRAME_SRCS = (version = '') => {
  const categoryPath = `${baseRedirectPath}categories${version}`;
  const themePath = `${baseRedirectPath}themes${version}`;

  return {
    default: baseRedirectPath,
    [`${baseEnterPath}/shop`]: `${baseRedirectPath}${version}`,
    [IFRAME_SRCS_CAT_BY_ID[SMART_THERMOSTAT]]: `${categoryPath}/smart-thermostat`,
    [IFRAME_SRCS_CAT_BY_ID[VIDEO_DOORBELL]]: `${categoryPath}/video-doorbell`,
    [IFRAME_SRCS_CAT_BY_ID[SMART_CAMERA]]: `${categoryPath}/smart-camera`,
    [IFRAME_SRCS_CAT_BY_ID[SMART_HUB]]: `${categoryPath}/smart-hub`,
    [IFRAME_SRCS_CAT_BY_ID[MESH_NETWORK]]: `${categoryPath}/mesh-network`,
    [IFRAME_SRCS_CAT_BY_ID[SMOKE_ALARM]]: `${categoryPath}/smoke-alarm`,
    [IFRAME_SRCS_CAT_BY_ID[SMART_GARAGE_DOOR_OPENER]]: `${categoryPath}/smart-garage-door-opener`,
    [IFRAME_SRCS_CAT_BY_ID[TV_MOUNT]]: `${categoryPath}/tv-mounts`,
    [IFRAME_SRCS_CAT_BY_ID[SMART_LOCK]]: `${categoryPath}/smart-lock`,
    [IFRAME_SRCS_CAT_BY_ID[HOME_THEATER]]: `${categoryPath}/home-theater`,
    [`${baseEnterPath}/safety-security-bundle`]: `${themePath}/safety-security`,
    [`${baseEnterPath}/comfort-energy-bundle`]: `${themePath}/comfort-energy-savers`,
    [`${baseEnterPath}/home-connectivity-bundle`]: `${themePath}/home-connectivity`,
  };
};

export const getIframeSource = (src = 'default', options = {}) => {
  const { params = '', version = '' } = options;
  const iframeUrls = IFRAME_SRCS(version);
  const iframeSrc = iframeUrls[src] || iframeUrls.default;
  return `${iframeSrc}${params}`;
};

export const CALLER_PAGE = 'productspage';

// Action types
export const TYPES = {};

// Routes that match for the iframe
export const IframeProductPageRoutes = Object.keys(IFRAME_SRCS());
