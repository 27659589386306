import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import AlternateTimesPrompt from './AlternateTimesPrompt';
import { SCHEDULER_VIEW_TYPES } from 'src/utils/serviceScheduling/serviceScheduling.constants';
import styles from './styles.scss';

export const HeaderBlock = ({ partner, previouslySelectedTimes, schedulerViewType }) => {
  if (
    [SCHEDULER_VIEW_TYPES.EXACT_TIME_SELECT, SCHEDULER_VIEW_TYPES.TIME_WINDOW_SELECT].includes(
      schedulerViewType,
    )
  ) {
    return <h3 className="text-align-center marginTop-tiny1">When should we arrive?</h3>;
  }

  if (schedulerViewType === SCHEDULER_VIEW_TYPES.TIME_WINDOW_GRID) {
    return (
      <>
        <h3 className="text-align-center marginTop-tiny1">When should we arrive?</h3>
        <p className={cn(styles.subHeader, 'p2 n700')}>
          Your tech will provide an exact ETA when en route
        </p>
      </>
    );
  }

  return (
    <>
      <h3 className={styles.header}>When are you available?</h3>
      <AlternateTimesPrompt partner={partner} previouslySelectedTimes={previouslySelectedTimes} />
    </>
  );
};

HeaderBlock.propTypes = {
  schedulerViewType: PropTypes.oneOf(Object.values(SCHEDULER_VIEW_TYPES)),
  partner: PropTypes.string,
  previouslySelectedTimes: PropTypes.array,
};
