import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles.scss';

const UpsellBadge = ({ children }) => {
  if (!children) return null;
  return <div className={styles.badge}>{children}</div>;
};

UpsellBadge.propTypes = { children: PropTypes.node };
export default UpsellBadge;
