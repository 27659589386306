import { connect } from 'react-redux';
import { compose } from 'redux';
import { toggleFirstServiceDiscountState } from 'src/components/HTKit/Modals/FirstServiceDiscount/actions';
import withToggleSiteNav from 'src/hoc/withToggleSiteNav';
import HomePageHero from './HomePageHero';

export default compose(
  connect(
    () => ({}),
    { toggleFirstServiceDiscountState },
  ),
  withToggleSiteNav(),
)(HomePageHero);
