import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import ButtonFooter from 'src/components/ButtonFooter';
import useScrollTrigger from 'src/hooks/useScrollTrigger';
import styles from './styles.scss';

const ServiceBookNow = ({
  className,
  trigger = '#service-book-now-trigger',
  service,
  onBook,
  customCta = '',
}) => {
  const [visible, setVisible] = useState(false);
  const boxStyles = cn(styles.box, { [styles.visible]: visible }, className);

  useScrollTrigger({
    trigger,
    onReach: (v) => setVisible(v),
  });

  return (
    <ButtonFooter className={boxStyles} showMobile={visible}>
      <Button smallV2 theme={THEMES.V2PRIMARY} onClick={onBook}>
        {customCta || service.extra.ctaText}
      </Button>
    </ButtonFooter>
  );
};

ServiceBookNow.propTypes = {
  className: PropTypes.string,
  service: PropTypes.shape({
    extra: PropTypes.shape({
      ctaText: PropTypes.string,
    }),
  }),
  trigger: PropTypes.string,
  onBook: PropTypes.func,
  customCta: PropTypes.string,
};

export default memo(ServiceBookNow);
