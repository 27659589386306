/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { splitioConstants, useDispatchSplitTreatment } from 'src/components/SplitIO';

/**
 * This treatment is a simple show/hide
 * SplitTreatmenToggle
 * @param { splitName } - whats the slit name we want to test
 * @param { children } - element component
 * @param { matchTreatment } - user decides on/off to decide what to do with component
 * @param { control } - if splits fails to load, we can't determine on/off. And not sold on using control right now.
 *                           So, lets allow for the caller component to say "use me if you can't make a determination".
 *
 * Treatments are considered the "test". So, lets not think of "on" as showing and "off" as not.
 * It depends on what the intent of the treatment is and what is the default state of the component.
 *
 * usage:
 * ex 1:
 * <SplitTreatmentToggle splitName={whaever}>
 *   <ToggleME />
 * </SplitTreatmentToggle>
 * ex 2:
 * <SplitTreatmentToggle splitName={whatever}>
 *   <SHOW_ME />
 *   <OR_SHOW_ME />
 * </SplitTreatmentToggle>
 * ex3:
 * <SplitTreatmentToggle splitName={whatever}>
 *   <SHOW_ME />
 *   <OR_SHOW_ME />
 *   <SHOW_ME_TOO />
 *   <AND_ME />
 * </SplitTreatmentToggle
 * ex4:
 * <SplitTreatmentToggle splitName={whatever} matchTreatment={["off", "control"]}>
 *   <SHOW_ME />
 * </SplitTreatmentToggle>
 * Note: Since phone_number to show is the default. Then it WOULD show if the treatment was off. We
 *     pass in what we want the condition to match given the splitName. User(you) has that control.
 *
 *     TODO: pass along a config to the children
 */

export const SplitTreatmentToggle = ({
  splitName,
  control = false,
  children = null,
  matchTreatment = splitioConstants.ANY,
}) => {
  /*  Because we no longer frontload, we have to dispatch if we want split on a one by one basis */
  const { splitTreatment } = useDispatchSplitTreatment(splitName);
  const [firstChild, ...rest] = React.Children.toArray(children);
  const restOfChildren = isEmpty(rest) ? null : rest;
  // If we want controls to pass too, here is were we insert for test
  const matchTreatments = [...(control ? [splitioConstants.CONTROL] : [])].concat(matchTreatment);
  // A/B tests may run in terms of config treatments. So, we need to allow for treatments other than "ON" or "OFF".
  const anyTreatment = matchTreatment === splitioConstants.ANY && ![splitioConstants.OFF].includes(splitTreatment);

  if (matchTreatments.includes(splitTreatment) || anyTreatment) return firstChild;

  return <>{restOfChildren}</>;
};

SplitTreatmentToggle.propTypes = {
  children: PropTypes.element.isRequired,
  splitName: PropTypes.string.isRequired,
  matchTreatment: PropTypes.string,
  control: PropTypes.bool,
};
