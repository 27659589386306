import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Grid } from 'src/components/HTKit/Grid';
import styles from './styles.scss';

const FormColumn = ({ children, classes = '', ...rest }) => {
  return (
    <Grid.Column classes={cn(styles.formColumn, classes)} {...rest}>
      {children}
    </Grid.Column>
  );
};

FormColumn.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.string,
};

const FormRow = ({ children, classes = '', ...rest }) => {
  return (
    <Grid.Row classes={classes} {...rest}>
      {children}
    </Grid.Row>
  );
};

FormRow.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.string,
};

const Form = ({ children, classes = '', withoutFormTag = false, ...rest }) => {
  return (
    <Grid.FullWidth classes={cn(styles.form, classes)}>
      {withoutFormTag ? children : <form {...rest}>{children}</form>}
    </Grid.FullWidth>
  );
};

Form.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.string,
  withoutFormTag: PropTypes.bool,
};

Form.Row = FormRow;
Form.Column = FormColumn;

export default Form;

/*
  This component is built on top of our Grid system, so it works
  similarly to the Grid components.

  The main differences are the form tag that is rendered and the
  bottom margins that are applied to each column. The negative margin
  is applied to offset the margin of the bottom row because the designs
  called for no bottom margin on the last row.

  Notes:
  Every child of <Form/> should be a group of <Form.Row><Form.Column> for
  style purposes.

  ------------

  Usage:

  import Form from 'HTKit/Forms/Form';
  import InputField from 'HTKit/Forms/InputFieldV2';
  import Button from 'HTKit/Forms/Button';

  ...

  <Form>
    <Form.Row>
      <Form.Column lg={6} md={4} sm={4}>
        <InputField />
      </Form.Column>
      <Form.Column lg={6} md={4} sm={4}>
        <InputField />
      </Form.Column>
    </Form.Row>
    <Form.Row>
      <Form.Column lg={12} md={8} sm={4}>
        <InputField />
      </Form.Column>
    </Form.Row>
    <Form.Row>
      <Form.Column lg={12} md={8} sm={4}>
        <Button>Submit</Button>
      </Form.Column>
    </Form.Row>
  </Form>
*/
