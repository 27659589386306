import { fromJS } from 'immutable';
import { PAGE_LOADED } from '../../constants/common';

const initialState = fromJS({});

export default function confirmationsReducer(state = initialState, action) {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        case 'orderConfirmation': {
          return state.set(action.order.id.toString(), fromJS(action.order));
        }
        default:
          return state;
      }
    default:
      return state;
  }
}
