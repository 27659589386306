// E.g. formatter(0).format(49.00)
export const formatter = (digits = 0) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
  });

/*
Example Usage - Hide "00" for even dollar amounts ($72 not $72.00), but show it for
amounts with cents ($72.99)
---
import { formatDollarAmount } from 'src/utils/formatter/currency';

const evenDollars = 7200;
const unevenDollars = 7299;

formatDollarAmount({amount: evenDollars, truncateEvenDollars: true})    -> Returns $72
formatDollarAmount({amount: evenDollars})                               -> Returns $72.00
formatDollarAmount({amount: unevenDollars})                             -> Returns $72.99
formatDollarAmount({amount: unevenDollars, truncateEvenDollars: true})  -> Returns $72.99

*/
const isEvenDollarAmount = (num) => String(num).slice(-2) === '00';
const getFormatterDigit = (num) => (isEvenDollarAmount(num) ? 0 : 2);
// Provide the amount in cents, e.g. 7200 represents $72
export const formatDollarAmount = ({ amount, digits = 2, truncateEvenDollars = false }) => {
  if (!Number.isFinite(amount)) return null;
  if (truncateEvenDollars) return formatter(getFormatterDigit(amount)).format(amount / 100);
  return formatter(digits).format(amount / 100);
};

/**
 * Moved same function from product/services here.
 * Added the +().toFixed(2)
 *   - New goe pricing is creating long floats. So, we toFixed it and add the + to trim off zeros.
 *   - Then allow the secondary part 'cents...' to do its thing.
 * @param price
 * @param cents
 * @returns {string}
 */
export const formatPrice = (price, cents = true) => `$${+price.toFixed(2) / ((cents && 100) || 1)}`;
