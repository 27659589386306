import React from 'react';
import PropTypes from 'prop-types';
import { ModalV2 } from 'src/components/HTKit/Modals/ModalV2';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';

const InvalidDatesModal = ({ visible, onCancel }) => {
  return (
    <ModalV2
      isVisible={visible}
      hide={onCancel}
      header="Selected Times Are No Longer Available"
      footerElement3={
        <Button mediumV2 theme={THEMES.V2PRIMARY} onClick={onCancel}>
          Ok
        </Button>
      }
    >
      <p>
        Your selected times are no longer available. Please select additional times.
      </p>
    </ModalV2>
  );
};

InvalidDatesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default InvalidDatesModal;
