import get from 'lodash/get';
import { SET_LANDING } from 'src/constants/landing';
import { getIsPartnerRelatedCookie } from 'src/utils/cookies';
import { isImmutable } from 'src/utils/helpers';
import { logger } from 'src/utils/logger';

// eslint-disable-next-line import/prefer-default-export
export const setLanding = (landing) => {
  let sku = {};
  let price = 0;
  let partner = null;
  let currentLanding = null;
  let currentLandingPartner = null;
  let name = '';

  try {
    sku = get(landing, 'skus.0', {}) || {};
    price = `${landing.startsAtPrice}`.replace(/\D/g, '');
    currentLanding = landing && landing.currentLanding;
    if (currentLanding) {
      currentLandingPartner = isImmutable(currentLanding) ? currentLanding.toJS() : currentLanding;
      name = currentLandingPartner.name || currentLandingPartner.seoName;
      // partner colate
      partner = {
        partner_id: currentLandingPartner.partnerId || currentLandingPartner.id,
        partner_name: name,
      };
    } else {
      partner = getIsPartnerRelatedCookie() || null;
    }
  } catch (e) {
    // something happened
    logger('Partner Landing Segment')(e);
  }

  return {
    type: SET_LANDING,
    payload: { landing },
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: `Product Viewed`,
            properties: {
              sku_id: sku.id,
              id: `${sku.id}`,
              base_price: price,
              price,
              content_ids: [sku.id],
              category: sku.category || '',
              image_url: get(landing, 'images.front.original', ''),
              is_remote: sku.remote || false,
              product_url: `${window?.location?.origin}${window?.location?.pathname}`,
              name,
              partner,
            },
          },
        },
      ],
    },
  };
};
