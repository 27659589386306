import { fromJS } from 'immutable';
import { bookingCta } from 'src/components/Service/ServiceZipCodeEntry/constants';
import { SET_BUTTON_CTA, SET_SHOW_ZIP_CODE_TEST } from './constants';

const initialValues = { buttonCta: bookingCta, showZipCodeTest: false };
const initialState = fromJS(initialValues);

export default function zipCodeTestReducer(state = initialState, action) {
  const { payload = initialValues } = action;
  switch (action.type) {
    case SET_BUTTON_CTA:
      return state.set('buttonCta', payload.buttonCta);
    case SET_SHOW_ZIP_CODE_TEST:
      return state.set('showZipCodeTest', payload.showZipCodeTest);
    default:
      return state;
  }
}
