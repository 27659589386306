import { takeLatest, put } from 'redux-saga/effects';
import { toggleModal } from 'src/actions/modals';
import {
  MODAL_NAME,
  SUCCESS_RESPONSE,
} from 'src/components/HTKit/Modals/FirstServiceDiscount/constants';

function* successResponseSaga() {
  yield put(toggleModal(MODAL_NAME, true));
}

export function* componentFlow() {
  yield takeLatest(SUCCESS_RESPONSE, successResponseSaga);
}

export default [componentFlow];
