import get from 'lodash/get';
import { pathnameFromString } from 'src/utils/validation';

/*
  Helper for tracking and redirect

*/
const trackAndRedirect = (props, segmentAttributes, redirect) => {
  props.categoryLinkClick(segmentAttributes);
  props.push(redirect);
};
/**
 * Our action object. We could use a switch statement, but inevitably, we'll have nesting.
 * Our recursive/gen functions will iterate over this object and get at our functional intent.
 *
 * note: postMessage can pass an object. So, at some point, the iframe can pass a full segment
 *       analytic object, but for now it's just patrick hardcoding and he doesn't have access to
 *       the api response. So, we currently would handle it on our end, BUT if the iframe every
 *       deals with APIS', he could pass the full object to us and we just "action" on it.
 */
const messagePageActions = (propsFromComponent) => (props, attributes) => {
  return {
    loaded() {
      /* This is the useState setter passed in */
      attributes.setPageLoaded(true);
    },
    /**
     * This method will call Segment, and then push to new pathing
     * Note: Pathing needs to be full url (webflow requires this), so we will chomp off what
     * we need and forward. Also, there is NO 'segment' SLA so, at this point, we just
     * consume the 'event' and 'action' on it, BUT we'd need to update at some point based on
     * segment conventions communicating to the iframe. Ie, does iframe pass us the full meta object,
     * or do we just get an event, and we build the meta object.
     *
     * Iframe calls this method.
     * Usage:
     * postMessageToParent(
     *   { type: 'genericLinkAndTrackClick', message: {redirectUrl: string, segmennt: {event: string} }}
     * )
     * */
    genericLinkAndTrackClick() {
      /* Patrick has to pass full urls. But we have to redirect on the environment level */
      const pathName = pathnameFromString(get(attributes, 'message.redirectUrl', ''));
      const segmentAttributes = get(attributes, 'message.segment', {});
      const mergedProps = { ...props, ...propsFromComponent };
      trackAndRedirect(mergedProps, segmentAttributes, pathName);
    },
    anchorScrollBy() {
      window.scrollTo(0, attributes.message);
    },
    captureBodyClickEvent() {
      const { isTopNavOpen = false, toggleTopnav = () => {} } = props;
      if (isTopNavOpen) toggleTopnav();
    },
  };
};

export { messagePageActions };
