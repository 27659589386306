import { merge, get } from 'lodash';
import { List } from 'immutable';
import { resolveLayout, resolveBookingPageLayout } from 'src/utils/layout';
import { PARTNERS } from 'src/constants/common';
import { partnerCoBrandExistsAllAvenuesSelector } from 'src/selectors/partner';
import { ADD_SKU_PAGE_LAYOUT, QA_ADD_SKU_PAGE_LAYOUT, REMOTE_TRUE } from './constants';

export function checkPartnerCart(cart, sku) {
  const defaultOpts = {
    showDialog: false,
    title: '',
    description: '',
  };

  if (cart.get('items').size === 0) return defaultOpts;
  if (cart.getIn(['partner', 'id'], null) === sku.getIn(['partner', 'id'], null))
    return defaultOpts;

  const customOpts = {};
  customOpts.showDialog = true;
  customOpts.title = `Adding this service will remove the ${
    !cart.get('partner') && sku.get('partner') ? 'non-' : ''
  }partner service(s) already in your cart.`;
  customOpts.description = `If you need additional ${
    sku.get('partner') ? '' : 'non-'
  }partner service(s), go directly to the cart to complete booking and then proceed with requesting the additional service.`;

  return merge({}, defaultOpts, customOpts);
}

export function hasQuestionAnswers(question) {
  if (question) {
    if (question.get('id') && question.get('id') !== REMOTE_TRUE) {
      return true;
    }
    if (question.count() > 0 && question.get('id') !== REMOTE_TRUE && question.get('text') !== '') {
      return true;
    }
  }
  return false;
}

export function filterRemoteQuestions({ sku, item, questions, cart }) {
  let filteredQuestions = questions;
  if (sku.get('remote')) {
    const itemQuestions = item.get('questions');
    const forbidRemote = !!questions.find((question) => {
      const itemQuestion = itemQuestions.get(question.get('id').toString());
      if (hasQuestionAnswers(itemQuestion)) {
        return question.get('answers').find((answer) => {
          if (answer.get('forbidRemote')) {
            if (
              itemQuestion &&
              itemQuestion.get('id') &&
              answer.get('id') === itemQuestion.get('id')
            ) {
              return true;
            }
            if (itemQuestion && List.isList(itemQuestion)) {
              return itemQuestion.find((a) => {
                return a.get('id') === answer.get('id');
              });
            }
          }
          return false;
        });
      }
      return false;
    });
    if (forbidRemote || !cart.get('remotePossible')) {
      filteredQuestions = filteredQuestions.filter((q) => q.get('id') !== 'remote');
    }
  }
  return filteredQuestions;
}

export function getItemDisplayPrice({ item, total }) {
  // - If the item does not have a 'startsAtPrice' return `totalPrice` by default on page load.
  // - If the item has a 'startsAtPrice' return that by default on page load.
  // - If the item has a 'startsAtPrice' and the customer makes a price adjustment, then return the total price. Per Minah (GH Jun 20, 2018)
  const basePriceOverridden = item.get('overrideBasePrice');
  const startsAtPrice = item.get('startsAtPrice');
  const totalPriceWithoutChanges = total;
  const totalPriceWithChanges = item.get('totalPrice');
  let displayPrice = '';
  if (!basePriceOverridden) {
    return totalPriceWithChanges;
  }
  if (basePriceOverridden) {
    if (totalPriceWithoutChanges === totalPriceWithChanges) {
      displayPrice = startsAtPrice;
    } else {
      displayPrice = totalPriceWithChanges;
    }
  }
  return displayPrice;
}

export const resolveAddSkuPageLayout = ({ sku, isConfirmation, newSkuQAFlow, cart }) => {
  const partnerLayoutFromCart = cart && cart.partnerName;
  const partnerLayoutFromSku = sku.partner && sku.partner.layoutName;
  // Seed of new cobranding layout. partnerName/partner/layoutName...huh.
  // Do we have images?
  const cobrandingLayout =
    partnerCoBrandExistsAllAvenuesSelector({ cart, skus: [sku] }) && PARTNERS.COBRAND;
  const partnerLayout = partnerLayoutFromCart || partnerLayoutFromSku || cobrandingLayout; // Manual override to force partner sku for PIN Redemption

  if (isConfirmation || partnerLayout) {
    return resolveBookingPageLayout({ cart, currentPartner: null, partnerLayout });
  }

  const templateName = sku ? sku.landingTemplateName : null;
  const layout = newSkuQAFlow ? QA_ADD_SKU_PAGE_LAYOUT : ADD_SKU_PAGE_LAYOUT;

  return resolveLayout(templateName, layout);
};

export const formatItemPropsForSaga = ({ completedAnswers = [] }) => {
  const productAnswers = {}; // working object to format products/unlisted
  const preQuestionAnswers = []; // working array to format preQuestions/Answers

  // split product-related answers and preQuestion answers
  completedAnswers.forEach((a) => {
    const { qid, answers } = a;

    if (qid === 'brand') {
      productAnswers.brand = a;
    } else if (qid === 'product') {
      productAnswers.product = a;
      if (answers.preQuestion) preQuestionAnswers.push(answers.preQuestion);
    } else {
      preQuestionAnswers.push(a);
    }
  });

  // build products or unlistedProducts object
  const formattedProductsAnswers = { products: [], unlistedProducts: [] };
  if (get(productAnswers, 'brand.answers.showInput')) {
    const otherBrand = get(productAnswers, 'brand.answers.value') || 'Other';
    const otherMake = get(productAnswers, 'product.answers.label', '');
    formattedProductsAnswers.unlistedProducts.push(`${otherBrand} -- ${otherMake}`);
  } else {
    const productId = get(productAnswers, 'product.answers.id');
    formattedProductsAnswers.products.push(productId);
  }

  // build questions object
  const formattedPreQuestionAnswers = preQuestionAnswers.reduce((iObj, answerData) => {
    const itemObj = { ...iObj };
    const { qid, answers } = answerData;
    if (Array.isArray(answers)) {
      // Checkboxes
      itemObj[qid] = answers.map((a) => ({ id: a.id }));
    } else if (answers.text) {
      // Freeform text
      itemObj[qid] = { text: answers.text };
    } else {
      // Radio
      itemObj[qid] = { id: answers.id };
    }
    return itemObj;
  }, {});

  return { questions: formattedPreQuestionAnswers, ...formattedProductsAnswers };
};
