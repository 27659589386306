export const COOKIES = Object.freeze({ COMCAST_USER_ID: 'saved-comcast-user-id' });
export const SEGMENT_EVENTS = Object.freeze({
  SEGMENT_TECH_360: 'Tech360 Submission',
  SEGMENT_LEGACY: 'Legacy Referral Submission',
});

/* See the comcast_types table in the DB */
const COMCAST_FIELD_TYPES = {
  USER: 'user',
  ENTITY: 'entity',
};

/* See the comcast_types table in the DB */
export const COMCAST_TYPES = Object.freeze({
  TECH: { id: 1, fieldType: COMCAST_FIELD_TYPES.USER },
  MOVER: { id: 2, fieldType: COMCAST_FIELD_TYPES.USER },
  JOB: { id: 3, fieldType: COMCAST_FIELD_TYPES.ENTITY },
  ORDER: { id: 4, fieldType: COMCAST_FIELD_TYPES.ENTITY },
  ACCOUNT: { id: 5, fieldType: COMCAST_FIELD_TYPES.ENTITY },
  SMB: { id: 6, fieldType: COMCAST_FIELD_TYPES.USER },
  SMB_SALES: { id: 7, fieldType: COMCAST_FIELD_TYPES.USER },
});

export const COMCAST_SALES = {
  SMB_DIRECT: 'isSmbDirect', // Comcast's SMB sales phone bank
};
