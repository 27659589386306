import { connect } from 'react-redux';
import { toggleSidebar } from 'src/components/Sidebar/actions';
import { toggleSearchBar } from 'src/components/Searchbar/actions';
import { toggleTopnav } from 'src/components/Header/TopNavHeader/actions';
import { logout } from 'src/containers/AppBase/actions';
import { openLoginModal } from 'src/containers/LoginPage/Modal/actions';
import { clearAppNotices } from 'src/actions/snackNotice';
import { push } from 'src/utils/paths';
import TopNavHeader from './TopNavHeader';
import componentSelectors from './selectors';

export default connect(componentSelectors, {
  clearAppNotices,
  logout,
  openLoginModal,
  push,
  toggleSidebar,
  toggleSearchBar,
  toggleTopnav,
})(TopNavHeader);
