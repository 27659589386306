import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from 'HTKit/Icon';

const LinkWrapper = ({ children, ctaLinkExternal, ctaLinkTarget, useBannerClicked, ctaLink }) => {
  return ctaLinkExternal ? (
    <a href={ctaLinkExternal} onClick={useBannerClicked} target={ctaLinkTarget}>
      {children}
    </a>
  ) : (
    <Link to={ctaLink} onClick={useBannerClicked}>
      {children}
    </Link>
  );
};

LinkWrapper.propTypes = {
  children: PropTypes.node,
  useBannerClicked: PropTypes.func,
  ctaLink: PropTypes.string,
  ctaLinkExternal: PropTypes.string,
  ctaLinkTarget: PropTypes.string,
};

const DisplayHeadlineAndLink = ({
  bannerClicked,
  ctaText,
  headline,
  ctaLink,
  ctaLinkExternal,
  ctaLinkTarget = '_blank',
  icon,
}) => {
  const useBannerClicked = useCallback(() => bannerClicked(headline), []);

  return (
    <>
      {icon && <Icon name={icon} />}
      <LinkWrapper
        ctaLinkExternal={ctaLinkExternal}
        ctaLink={ctaLink}
        ctaLinkTarget={ctaLinkTarget}
        useBannerClicked={useBannerClicked}
      >
        {headline}
        <>&nbsp;{ctaText}</>
      </LinkWrapper>
    </>
  );
};

DisplayHeadlineAndLink.propTypes = {
  bannerClicked: PropTypes.func.isRequired,
  ctaText: PropTypes.string,
  headline: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaLinkExternal: PropTypes.string,
  ctaLinkTarget: PropTypes.string,
  icon: PropTypes.string,
};

export default React.memo(DisplayHeadlineAndLink);
