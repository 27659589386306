import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { prevented } from 'src/utils/event';
import { buildLocationsLink } from 'src/utils/locations';
import { getUpsellData } from 'src/components/Upsell/NavSubCategoryUpsell/utils';
import { skuPath, push } from 'src/utils/paths';
import MenuTemplate from '../MenuTemplate';

const ServiceMenuRepeatBookings = ({
  visible,
  setServiceMenuVisibleState,
  selectedCategory,
  skuLinkPrefix,
  setSubCategoryMenuVisibleState,
  setSelectedSubCategory,
}) => {
  const { name = '' } = selectedCategory;
  const dispatch = useDispatch();

  const headerItems = [
    {
      text: name,
      onBackClick: prevented(() => setServiceMenuVisibleState(false)),
    },
  ];

  const getSecondaryItems = (category = {}) => {
    // Determine what to show in this menu; the skus or the subCategories
    const { subCategories = [], showSecondaryNav } = category;
    const showSkusInThisMenu = !showSecondaryNav;
    if (showSkusInThisMenu) {
      return subCategories.map((subCat) => {
        const { link = '', name: skuName } = subCat;
        return {
          text: skuName,
          link: skuLinkPrefix ? buildLocationsLink({ link, prefix: skuLinkPrefix }) : link,
        };
      });
    }

    return subCategories.map((subCat) => {
      return {
        text: subCat.name,
        onClick: prevented(() => {
          setSubCategoryMenuVisibleState(subCat.showSecondaryNav);
          setSelectedSubCategory(subCat);
          if (!subCat.showSecondaryNav) {
            dispatch(push(skuPath(subCat.seoName)))
          }
        }),
        hideIcon: !subCat.showSecondaryNav,
      };
    });
  };

  const secondaryitems = getSecondaryItems(selectedCategory);

  // Only grab the upsell data when visible to avoid fetching the images before the menu is opened
  const upsellData = visible ? getUpsellData(selectedCategory) : false;

  return (
    <MenuTemplate
      slide
      visible={visible}
      headerItems={headerItems}
      secondaryItems={secondaryitems}
      upsellData={upsellData}
    />
  );
};

ServiceMenuRepeatBookings.propTypes = {
  visible: PropTypes.bool.isRequired,
  setServiceMenuVisibleState: PropTypes.func.isRequired,
  selectedCategory: PropTypes.object,
  skuLinkPrefix: PropTypes.string,
  setSubCategoryMenuVisibleState: PropTypes.func,
  setSelectedSubCategory: PropTypes.func,
};

export default React.memo(ServiceMenuRepeatBookings);
