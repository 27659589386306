import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { map, keys } from 'lodash';
import { push, skuPath } from 'src/utils/paths';
import Button, { THEMES } from 'HTKit/Forms/Button';
import SelectField from 'HTKit/Forms/SelectField';
import { Grid } from 'HTKit/Grid';
import Form from 'HTKit/Forms/Form';
import { resolveLayout } from 'src/utils/layout';
import { onFormValueChange } from 'src/utils/forms';
import { layoutUpdate } from 'src/actions/common';
import { HT_US_STATES } from './constants';

const STATES = map(keys(HT_US_STATES), (key) => ({ value: key, label: HT_US_STATES[key] }));
const SKU_ROUTES = { NC: 'install-nest-thermostat-alarm', DEFAULT: 'altice-nest-install' };

class AlticeNest extends Component {
  static propTypes = {
    landing: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    layoutUpdate: PropTypes.func,
    push: PropTypes.func,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.layoutUpdate(resolveLayout(this.props.landing.get('template')));
  }

  state = { state: undefined };

  onValueChange = onFormValueChange.bind(this);

  onFormSubmit = (event) => {
    const { state } = this.state;
    event.preventDefault();
    const path = SKU_ROUTES[state] || SKU_ROUTES.DEFAULT;
    this.props.push(skuPath(path));
  };

  render() {
    const { isMobile, landing } = this.props;
    const heroImgSrc = isMobile
      ? landing.getIn(['images', 'front', 'mobile'])
      : landing.getIn(['images', 'front', 'original']);
    const disabled = !this.state.state;

    return (
      <div className="site-v2">
        <Helmet title={landing.get('pageTitle')} />
        <Grid.Fluid>
          <Grid.Row>
            <img alt={landing.get('pageTitle')} src={heroImgSrc} className="width-full" />
          </Grid.Row>
          <Grid.Row classes="paddingY-medium1">
            <Grid.Column sm={4} mdOffset={1} md={6} lgOffset={2} lg={8}>
              <h1 className="h2 text-align-center">{landing.getIn(['header', 'title'])}</h1>
              <div className="h4 text-align-center marginY-medium">{landing.getIn(['content', 'description'])}</div>
              <Form onSubmit={this.onFormSubmit}>
                <Form.Row>
                  <Form.Column sm={4} md={8} lgOffset={2} lg={8}>
                    <SelectField
                      placeholder="Select your state"
                      options={STATES}
                      value={this.state.state}
                      onChange={this.onValueChange('state')}
                      simpleValue
                      clearable={false}
                      searchable={false}
                    />
                  </Form.Column>
                </Form.Row>
                <Form.Row>
                  <Form.Column sm={4} md={8} lgOffset={2} lg={8}>
                    <Button disabled={disabled} mediumV2 theme={THEMES.V2PRIMARY}>{landing.getIn(['content', 'cta'])}</Button>
                  </Form.Column>
                </Form.Row>
              </Form>
              <p className="p2 text-align-center marginTop-small1">
                {landing.getIn(['header', 'subtitle'])}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid.Fluid>
      </div>
    );
  }
}

export default connect(() => ({}), { layoutUpdate, push })(AlticeNest);

/**
  Is this page even being used? There's a redirect in nginx.conf.

  /alticenest -> /tech-support/install-nest-products

  The sku doesn't even exist...
*/
