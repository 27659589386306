import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

/**
 * React Router v5 does not provide a hook for accessing the search params, so let's make our own.
 * URLSearchParams docs: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {URLSearchParams}
 */
function useSearchParams() {
  const location = useLocation();

  return useMemo(() => new URLSearchParams(location.search), [location.search]);
}

export default useSearchParams;
