import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Paths
import { homePath } from 'src/utils/paths';
// Hooks & Utils
import { useCartFlow } from 'src/utils/cartFlow';
import { resolveHomeUrl, usePartnerLogoUrl } from 'src/components/Header/utils';
import useToggle from 'src/hooks/useToggle';
import { useSelector } from 'react-redux';

// Selectors
import { cartSelector } from 'src/selectors/cart';
// Components
import { Link, useLocation } from 'react-router-dom';
import CartPreviewIcon from '../Parts/CartPreviewIcon';
import { CartNavigationDisplay } from 'src/components/Header/TopNavHeader/Parts';
import BookingHeaderLogo from './BookingHeaderLogo';
import styles from './styles.scss';

const BookingHeader = ({ header, currentPartnerLanding }) => {
  const logoUrl = usePartnerLogoUrl();
  const actionable = header.get('actionableLogo');
  const homeUrl = header.get('homeUrl', homePath());
  const resolvedHomeUrl = resolveHomeUrl({ homeUrl, currentPartnerLanding });

  const wrapperStyles = cn(styles.logoWrapper, {
    [styles.withPartnerLogo]: logoUrl,
    [styles.noAction]: !actionable,
  });

  const { pathname } = useLocation();
  const { getBookingHeaderConfig } = useCartFlow();
  const bookingHeaderConfig = getBookingHeaderConfig(pathname);

  const cart = useSelector(cartSelector);

  const {
    toggleState: isCartNavigationDisplayOpen,
    toggle: toggleCartNavigationDisplay,
  } = useToggle(false);

  const handleCartIconClick = () => {
    toggleCartNavigationDisplay(!isCartNavigationDisplayOpen);
  };

  return (
    <nav className={styles.container}>
      {actionable ? (
        <Link to={resolvedHomeUrl} className={wrapperStyles}>
          <BookingHeaderLogo logo={logoUrl} />
        </Link>
      ) : (
        <div className={wrapperStyles}>
          <BookingHeaderLogo logo={logoUrl} />
        </div>
      )}
      {bookingHeaderConfig.showCartIcon && (
        <div className={styles.cartPreviewIcon}>
          <CartPreviewIcon cart={cart} onClick={handleCartIconClick} />
        </div>
      )}
      {isCartNavigationDisplayOpen && (
        <CartNavigationDisplay toggleCartNavigationDisplay={handleCartIconClick} offsetRightSmall />
      )}
    </nav>
  );
};

BookingHeader.propTypes = {
  header: PropTypes.object.isRequired,
  currentPartnerLanding: PropTypes.object,
};

export default memo(BookingHeader);
