import React, { useCallback, useRef, useState, useEffect } from 'react';
import cn from 'classnames';
/* Utils && Data */
import useToggle from 'src/hooks/useToggle';
import useEventListener from 'src/hooks/useEventListener';
import useWindowType, { WIN_LG, WIN_XL } from 'src/hooks/useWindowType';
import { useCartFlow } from 'src/utils/cartFlow';
/* Paths */
import { cartPath } from 'src/utils/paths';
/* Components */
import ChatCallDropdown from '../Parts/ChatCallDropdown';
import TopNavItemsV2 from './TopNavItemsV2';
import {
  AccountMenuItems, // Sign in/out menu
  CartNavigationDisplay, // Cart preview display
  LeftComponent,
  CenterComponent,
  RightComponent,
} from './Parts';
// Constants
import { TRANSPARENT_THEMES } from './constants';

// Styles
import styles from './styles.scss';

const TopNavHeader = (propAttr) => {
  const {
    actionableLogo,
    cart,
    clearAppNotices,
    homeUrl,
    hideMembership,
    isTransparent,
    logout,
    openLoginModal,
    showSiteLinks,
    sidebarIsOpen,
    snackbar,
    push,
    toggleSidebar,
    toggleTopnav,
    toggleSearchBar,
    searchBarIsOpen,
    topNavIsOpen,
    theme,
    user,
    currentPartnerLanding,
  } = propAttr;
  const windowType = useWindowType();

  const { getTopNavConfig, isSingleServiceFlow } = useCartFlow();

  const [atTopOfViewport, setAtTopOfViewport] = useState(true);
  const [showPhoneInfo, setShowPhoneInfo] = useState(false);
  const topNavHeaderContainer = useRef(null);

  const handleShowPhoneInfo = () => {
    setShowPhoneInfo(true);
  };
  const handleHidePhoneInfo = () => {
    setShowPhoneInfo(false);
  };

  const handleClick = () => {
    if (sidebarIsOpen) toggleSidebar();
    toggleSearchBar(!searchBarIsOpen);
    document.body.style.overflow = 'hidden';
  };
  const notices = snackbar.get('notices');
  const isNoticePresent = notices.toJS().length;

  const topNavIsTransparent =
    atTopOfViewport && isTransparent && !topNavIsOpen && !sidebarIsOpen && !isAccountMenuOpen;

  const containerStyles = cn('htHeaderContainer', styles.container, {
    [styles.transparent]: topNavIsTransparent,
    [styles.searchContainer]: searchBarIsOpen,
    [styles.showNavShadow]: topNavIsOpen,
  });

  const overlayStyles = cn({
    [styles.searchOverlay]: searchBarIsOpen,
  });

  const themeComponentStyles = cn({
    [styles['transparent-inverted']]: topNavIsTransparent && theme === TRANSPARENT_THEMES.INVERTED,
    [styles['transparent-classic']]: topNavIsTransparent && theme === TRANSPARENT_THEMES.CLASSIC,
  });

  const handleToggle = useCallback((e) => {
    if (e && e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
    toggleTopnav();
  }, []);

  const handleToggleWithSnackbar = (e) => {
    if (e && e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
    if (isNoticePresent) {
      clearAppNotices();
    }
    handleToggle();
  };

  const handleTransparentScroll = () => {
    if (!isTransparent) return null;
    if (window.scrollY === 0) return setAtTopOfViewport(true);
    return setAtTopOfViewport(false);
  };

  // <--- GENERAL MENU HANDLING | Start --->
  const { toggleState: isAccountMenuOpen, toggle: toggleAccountMenuHook } = useToggle(false);

  const {
    toggleState: isCartNavigationDisplayOpen,
    toggle: toggleCartNavigationDisplay,
  } = useToggle(false);

  const closeMenus = () => {
    if (isAccountMenuOpen) toggleAccountMenuHook(false);
    if (isCartNavigationDisplayOpen) toggleCartNavigationDisplay(false);
  };
  // <--- GENERAL MENU HANDLING | End --->

  // <--- PROFILE ICON HANDLING | Start --->
  const toggleAccountMenu = (e) => {
    if (sidebarIsOpen) toggleSidebar();
    closeMenus();
    if (e && e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
    toggleAccountMenuHook(!isAccountMenuOpen);
  };
  // <--- PROFILE ICON HANDLING | End --->

  // <--- CART ICON HANDLING | Start --->
  const handleCartIconClick = () => {
    if (sidebarIsOpen) toggleSidebar();
    closeMenus();

    return isSingleServiceFlow()
      ? toggleCartNavigationDisplay(!isCartNavigationDisplayOpen)
      : push(cartPath);
  };

  const topNavConfigEnhanced = {
    ...getTopNavConfig(),
    onCartIconClick: handleCartIconClick,
  };
  // <--- CART ICON HANDLING | End --->

  useEventListener({ eventName: 'scroll', handler: handleTransparentScroll });

  useEffect(() => {
    handleTransparentScroll();
  }, []);

  const handleIconClick = () => {
    toggleSearchBar(!searchBarIsOpen);
    setShowPhoneInfo(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <>
      <div ref={topNavHeaderContainer} className={containerStyles}>
        {searchBarIsOpen && <div className={overlayStyles} onClick={handleIconClick} />}
        {!searchBarIsOpen && (
          <LeftComponent
            actionableLogo={actionableLogo}
            homeUrl={homeUrl}
            topNavIsTransparent={topNavIsTransparent}
            sidebarIsOpen={sidebarIsOpen}
            showSiteLinks={showSiteLinks}
            theme={theme}
            themeStyles={themeComponentStyles}
            toggleSidebar={toggleSidebar}
            currentPartnerLanding={currentPartnerLanding}
          />
        )}
        {!searchBarIsOpen && (
          <CenterComponent
            showSiteLinks={showSiteLinks}
            toggleTopnav={handleToggleWithSnackbar}
            topNavIsOpen={topNavIsOpen}
            hideMembership={hideMembership}
            themeStyles={themeComponentStyles}
          />
        )}
        <RightComponent
          cart={cart}
          onEnter={handleShowPhoneInfo}
          onLeave={handleHidePhoneInfo}
          push={push}
          showSiteLinks={showSiteLinks}
          toggleAccountMenu={toggleAccountMenu}
          themeStyles={themeComponentStyles}
          user={user}
          showSearchBar={searchBarIsOpen}
          searchClick={handleClick}
          onClose={handleIconClick}
          showPhoneInfo={showPhoneInfo}
          topNavConfig={topNavConfigEnhanced}
        />
        {isCartNavigationDisplayOpen ? (
          <CartNavigationDisplay toggleCartNavigationDisplay={toggleCartNavigationDisplay} />
        ) : null}
        {isAccountMenuOpen ? (
          <AccountMenuItems
            isSignedIn={Boolean(user)}
            openLoginModal={openLoginModal}
            signOut={logout}
            toggleAccountMenu={toggleAccountMenu}
          />
        ) : null}
        {topNavIsOpen && [WIN_LG, WIN_XL].includes(windowType) && (
          <TopNavItemsV2 toggleTopnav={handleToggle} />
        )}
      </div>
      <ChatCallDropdown />
    </>
  );
};

export default TopNavHeader;
