import React from 'react';
import { Elements } from 'react-stripe-elements';
import AsyncStripeProvider from 'src/components/AsyncStripeProvider';
import Page from './page';

const AccountPaymentPage = (props) => {
  return (
    <AsyncStripeProvider>
      <Elements>
        <Page {...props} />
      </Elements>
    </AsyncStripeProvider>
  );
};

export default AccountPaymentPage;
