import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StripeProvider } from 'react-stripe-elements';
import { STRIPE_API_KEY } from 'src/utils/payments';

export default class AsyncStripeProvider extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired
  };

  constructor(props, context) { // eslint-disable-line no-useless-constructor
    super(props, context);
    this.state = { stripe: null };
  }

  componentDidMount() {
    if (window.Stripe) {
      if (!this.state.stripe) {
        this.setState({ stripe: window.Stripe(STRIPE_API_KEY) }); // eslint-disable-line
      }
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.setState({ stripe: window.Stripe(STRIPE_API_KEY) }); // eslint-disable-line
      });
    }
  }

  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        {this.props.children}
      </StripeProvider>
    );
  }
}

