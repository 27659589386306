import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { cancellationPolicyPath } from 'src/utils/paths';

import { planSelector } from 'src/selectors/cart';
import { userSubscriptionSelector } from 'src/selectors/user';

const CancellationDisclaimer = ({ className }) => {
  const cartHasPlan = useSelector(planSelector);
  const userHasPlan = useSelector(userSubscriptionSelector);
  const isMember = Boolean(cartHasPlan || userHasPlan);

  const link = (
    <Link to={cancellationPolicyPath()} target="_blank" rel="noreferrer">
      <span>{isMember ? 'member\'s ' : ''}cancellation and rescheduling policies</span>
    </Link>
  );
  return (
    <div className={className}>
      {isMember ? (
        <span className="caption">
          By submitting this request, you agree to HelloTech&apos;s {link}.
          You can cancel prior to three hours before your appointment without incurring a fee.
        </span>
      ) : (
        <span className="caption">
          By submitting this request, you agree to HelloTech&apos;s {link}.
          You can cancel any time prior to your tech being assigned without incurring a fee.
        </span>
      )}
    </div>
  );
};

CancellationDisclaimer.propTypes = { className: PropTypes.string };

export default memo(CancellationDisclaimer);
