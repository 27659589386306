import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { POPULAR_SKUS } from 'src/containers/HomePage/constants';
import homepageClickSegment from 'src/containers/HomePage/actions';
import styles from './styles.scss';

const PopularSkusByType = ({ skus, title, subTitle, classes, cardClasses }) => {
  const dispatch = useDispatch();
  const subTitleStyles = cn('p0', styles.subTitle);
  const cardStyles = cn(cardClasses, styles.card);
  const cardContainerStyles = cn(classes, styles.cardContainer);
  const titleStyles = subTitle ? styles.title : styles.titleWithoutSub;
  return (
    <>
      <div className={styles.headerContainer}>
        <h3 className={titleStyles}>{title}</h3>
        {subTitle && <p className={subTitleStyles}>{subTitle}</p>}
      </div>
      <div className={cardContainerStyles}>
        {skus &&
          skus.map((sku, index) => {
            return (
              <div className={cardStyles} key={index}>
                <Link
                  className={styles.link}
                  to={sku.link}
                  onClick={() =>
                    dispatch(homepageClickSegment({ id: sku.id, event: 'Homepage Popular Services' }))
                  }
                >
                  <img src={sku.image} className={styles.image} />
                  <h6 className={styles.imageTitle}>{sku.name}</h6>
                </Link>
              </div>
            );
          })}
      </div>
    </>
  );
};

PopularSkusByType.propTypes = {
  skus: PropTypes.array,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  classes: PropTypes.string,
  cardClasses: PropTypes.string,
};

PopularSkusByType.defaultProps = {
  skus: POPULAR_SKUS,
  title: 'Popular In-Home Services',
};

export default memo(PopularSkusByType);
