export const LOAD_MODELS = 'devicesPage/LOAD_MODELS';
export const MODELS_LOADED = 'devicesPage/MODELS_LOADED';

export const SUBMIT_DEVICE = 'devicesPage/SUBMIT_DEVICE';
export const DEVICE_SUBMITTED = 'devicesPage/DEVICE_SUBMITTED';

export const UPDATE_WARRANTY = 'devicesPage/UPDATE_WARRANTY';
export const WARRANTY_UPDATED = 'devicesPage/WARRANTY_UPDATED';

export const UPDATE_DEVICE = 'devicesPage/UPDATE_DEVICE';
export const DEVICE_UPDATED = 'devicesPage/DEVICE_UPDATED';

export const DELETE_DEVICE = 'devicesPage/DELETE_DEVICE';
export const DEVICE_DELETED = 'devicesPage/DEVICE_DELETED';

export const ACCOUNT_DEVICES_PAGE = 'accountDevices';
