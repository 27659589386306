import { ADD_PLAN_TO_CART, REMOVE_PLAN_FROM_CART } from 'src/constants/cart';

export const addPlanToCart = (
  planId,
  { successCB = () => {}, errorCB = () => {}, cancelPlanId } = {},
) => ({
  type: ADD_PLAN_TO_CART,
  /** Plan id to add */
  planId,
  /** Plan id that should be cancelled. Used for plan upsells */
  cancelPlanId,
  successCB,
  errorCB,
});

export const removePlanFromCart = () => ({
  type: REMOVE_PLAN_FROM_CART,
});
