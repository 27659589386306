import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bannerImage from 'src/images/orange-triangle.png';
import styles from './styles.scss';

const SupportPlanTriangle = (props) => {
  const { value, containerStyle } = props;
  const containerStyles = cn(styles.container, containerStyle);

  return (
    <div className={containerStyles}>
      <img src={bannerImage} alt="Discount" />
      <div className={styles.contentContainer}>{value}</div>
    </div>
  );
};

SupportPlanTriangle.propTypes = {
  value: PropTypes.any,
  containerStyle: PropTypes.string,
};

SupportPlanTriangle.defaultProps = {
  containerStyle: '',
};

export default SupportPlanTriangle;
