import { fromJS } from 'immutable';
import {
  BONUS_EDIT_MODE,
  GET_SUBSCRIPTION_DETAILS,
  UPDATE_LOW_TIER_CONFIG,
  CREDIT_APPLIED_TOAST_SHOWN,
} from './constants';

const initialState = fromJS({
  editBonus: false,
  lowTierConfig: {
    splitTreatment: null,
    hthUpsellShown: false,
    showHthModal: false,
  },
  creditAppliedToastShown: false,
});

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case BONUS_EDIT_MODE:
      return state.set('editBonus', action.editMode);
    case GET_SUBSCRIPTION_DETAILS:
      return state.set('planDetails', fromJS(action.planDetails));
    case CREDIT_APPLIED_TOAST_SHOWN:
      return state.set('creditAppliedToastShown', true);
    case UPDATE_LOW_TIER_CONFIG:
      return state.mergeDeep({ lowTierConfig: action.payload });
    default:
      return state;
  }
}

export default pageReducer;
