import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import cn from 'classnames';
import Grid from 'src/components/HTKit/Grid/Grid'
import TwoFactorForm from 'src/containers/2FA/form';
// HOC
import withLayout from 'src/hoc/withLayout';
// Selectors
import { pureCartSelector } from 'src/selectors/cart';
// Actions
import { verifyPinAction } from 'src/containers/FieldSales/actions';
// Hooks
import useAPI from 'src/hooks/useAPI';
import useNotice from 'src/hooks/useNotice';
// Utils
import { logger } from 'src/utils/logger';
import { removeFieldSalesCookie } from 'src/utils/cookies';
// Constants
import { BOOKING_LAYOUT } from 'src/constants/common';
import { FIELD_SALES_LOGGER_TITLE } from 'src/containers/FieldSales/constants';
// Styles
import styles from './styles.scss';

const TwoFactorAuth = ({ match }) => {
  // hooks
  const api = useAPI();
  const { error } = useNotice();
  const dispatch = useDispatch();
  const cart = useSelector(pureCartSelector) || {};
  // constants
  const { token = cart.token } = match.params || {};

  // User enters pin and submits. This verifies that submission
  const verifyPin = (pin) => {
    dispatch(verifyPinAction({ pin, token }))
  }
  // This initiates a sms to the customer.
  const sendPin = async () => {
    if (!token) return;

    api.toggleLoader(true);

    try {
      const response = await api.fieldSales.sendPin({ cart_token: token });

      if (response.err) throw response;
    } catch (e) {
      const err = get(e, 'data.error', '');
      logger(FIELD_SALES_LOGGER_TITLE)(err);
      error(err);
    }

    api.toggleLoader(false);
  }

  useEffect(() => {
    sendPin();
    removeFieldSalesCookie();
  }, [token])

  return (
    <div className={styles.pageContainer}>
      <div className={styles.blobBackground}/>
      <Grid.Fluid>
        <Grid.Row>
          <Grid.Column classes={styles.column} sm={2} smOffset={1} md={4} mdOffset={2}>
            <h2 className={cn('paddingTop-huge1 paddingBottom-small1', styles.title)}>
              Hi {cart.clientName}!
            </h2>
          </Grid.Column>
          <Grid.Column classes={styles.column} sm={4} md={4} mdOffset={2} lgOffset={4} lg={4}>
            <p className={cn('p0 n700 paddingBottom-medium1', styles.description)}>
              For security, please enter the 5-digit code we sent you via text and email:
            </p>
          </Grid.Column>
          <TwoFactorForm verifyPin={verifyPin} />
        </Grid.Row>
      </Grid.Fluid>
    </div>
  );
};

TwoFactorAuth.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object
  })
};

export default withLayout(BOOKING_LAYOUT)(TwoFactorAuth);

