import React, { useState, useEffect, useMemo, lazy, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { addErrorAppSnackNotice } from 'src/actions/snackNotice';
import { CREDIT_LABEL } from 'src/constants/credit';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { ModalV2 } from 'src/components/HTKit/Modals/ModalV2';
import useAPI from 'src/hooks/useAPI';
import useSearchParams from 'src/hooks/useSearchParams';
import { formatDollarAmount } from 'src/utils/formatter/currency';
import { logger } from 'src/utils/logger';
import styles from './claimStoreCreditsModal.styles.scss';

const LazySuccessLottie = lazy(() => import('./ClaimStoreCreditsModal.SuccessLottie'));

const ClaimSuccessfulContent = ({ creditInfo, close }) => {
  const { amount = 0 } = creditInfo;
  return (
    <div className="flex flexDirectionColumn alignItemsCenter">
      <Suspense fallback={() => {}}>
        <LazySuccessLottie />
      </Suspense>
      <h3 className="marginTop-small2 text-align-center">
        Your {amount > 0 ? `${formatDollarAmount({ amount, truncateEvenDollars: true })} ` : ''}
        {CREDIT_LABEL} is now available!
      </h3>
      <p className="p1 n900 text-align-center marginTop-small2 marginBottom-small2">
        Make sure you complete your order
        <br />
        using the same email that we sent the {CREDIT_LABEL} to.
      </p>
      <Button onClick={close} theme={THEMES.V2PRIMARY} mediumV2 className={styles.ctaButton}>
        Got it!
      </Button>
    </div>
  );
};

ClaimSuccessfulContent.propTypes = {
  close: PropTypes.func.isRequired,
  creditInfo: PropTypes.shape({
    amount: PropTypes.number,
  }),
};

const ClaimStoreCreditsModal = () => {
  const dispatch = useDispatch();
  const api = useAPI();

  const [isOpen, setIsOpen] = useState(false);
  const [hasCheckedCredit, setHasCheckedCredit] = useState(false);
  const [claimSuccessful, setClaimSuccessful] = useState(true);
  const [creditInfo, setCreditInfo] = useState({});
  const closeModal = () => setIsOpen(false);

  const searchParams = useSearchParams();
  const creditToken = searchParams.get('creditToken');
  let customerEmail = searchParams.get('email') || '';
  customerEmail = customerEmail.replace(/\s/g, '+'); // making a bold assumption that the email is not encoded

  /**
   * Read url params and claim credit using the provided email and token.
   */
  useEffect(() => {
    (async () => {
      if (!hasCheckedCredit && customerEmail && creditToken) {
        setHasCheckedCredit(true);

        const response = await api.credits.claim({
          credit: {
            source: 'hellotech',
            email: customerEmail,
            token: creditToken,
          },
        });

        if (!response.err) {
          const { data = {} } = response;
          setCreditInfo(data);
          setClaimSuccessful(true);
          setIsOpen(true);
        } else {
          const { errors = [] } = response.data || {};
          if (errors.includes('Email already associated with a credit')) {
            setClaimSuccessful(false);
            setIsOpen(true);
          } else {
            dispatch(addErrorAppSnackNotice({ content: `${CREDIT_LABEL} could not be applied.` }));
          }
          logger('ClaimStoreCreditsModal')(errors.join(', '));
        }
      }
    })();
  }, [hasCheckedCredit, customerEmail, creditToken]);

  const modalProps = useMemo(() => {
    return claimSuccessful
      ? {
          headerContainerClasses: styles.hideModalHeader,
          childrenClassName: styles.modalClass,
        }
      : {
          hide: closeModal,
          header: `${CREDIT_LABEL} already claimed`,
          footerElement3: (
            <Button onClick={closeModal} theme={THEMES.V2PRIMARY} mediumV2 inlineBlock>
              OK
            </Button>
          ),
        };
  }, [claimSuccessful]);

  return (
    <ModalV2 isVisible={isOpen} {...modalProps}>
      {claimSuccessful && <ClaimSuccessfulContent creditInfo={creditInfo} close={closeModal} />}
      {!claimSuccessful && (
        <p className="p1 marginTop-small2">
          You’ve already claimed your {CREDIT_LABEL}. Book a service and it will be automatically
          applied!
        </p>
      )}
    </ModalV2>
  );
};

export default ClaimStoreCreditsModal;
