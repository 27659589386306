import { createSelector, createStructuredSelector } from 'reselect';
import { pageSelector as selectPage } from 'src/selectors/page';
import {
  cartSelector,
  cartBreakdownSelector,
  orderAdjustmentsSelector,
  itemsTotalFormattedSelector,
  subscriptionTotalFormattedSelector,
  totalFormattedSelector,
  planIntervalSelector,
  getPricesAndDiscounts,
  getFilteredServiceAdjustments,
  getFilteredServiceDiscounts,
  planNameSelector,
  planAmountSelector,
  couponSelector,
  couponTextSelector,
  couponValueFormattedSelector,
  orderAdjustmentsNoCouponSelector,
  totalSavingsSelector,
  totalSavingsFormattedSelector,
  badgeSavingsSelector,
  planSelector,
  partnerNameSelector,
  cartHasPinsSelector,
  subscriptionCouponSelector,
  subscriptionCouponValueSelector,
  couponValueSelector,
  purePlanSelector,
  subscriptionCreditAdjustmentsSelector,
} from 'src/selectors/cart';
import { PREPAID_STATUS } from 'src/constants/sku';
import { userSelector } from 'src/selectors/user';
import { currentLandingSelector } from 'src/selectors/landings';
import componentSelector from 'src/selectors/component';
import { PARTNERS } from 'src/constants/common';
import { PAGE_NAME } from './constants';

const pageSelector = selectPage(PAGE_NAME);

const userHasSubscriptionSelector = createSelector(
  cartSelector,
  userSelector,
  (cart, user) =>
    cart.getIn(['plan', 'id']) || (user && user.getIn(['account', 'hasSubscription'])),
);

const snackbarSelector = (state) => componentSelector('snackbar')(state);

const formattedOrderAdjustmentSelector = createSelector(
  orderAdjustmentsSelector,
  (adjustments) =>
    adjustments &&
    adjustments.map(({ name, amountFormatted, type }) => ({ name, type, amount: amountFormatted })),
);

export const estimatedCartSelector = createSelector(pageSelector, (page) =>
  page.get('estimatedCart'),
);
export const estimatedCartBreakdownSelector = createSelector(
  estimatedCartSelector,
  (cart) => cart && cart.get('breakdown'),
);

/** Format breakdown items for cart or estimated cart */
const getFormattedCartBreakdownItems = (breakdownSelector, targetedCartSelector) =>
  createSelector(breakdownSelector, targetedCartSelector, (breakdown, cart) => {
    /* returns { name:'service 1', amount: '$1', adjustments: [{ name:'adjustment 1', amount:'$2' }]}; */
    if (!breakdown || !cart) return null;
    const { items: cartItems } = cart.toJS(); // if there are answers to skuQuestions they'll be on cart.items.questions
    return breakdown.toJS().items.map((breakdownItem, breakdownItemIndex) => {
      const {
        name,
        adjustments,
        skuId,
        category,
        prepaid,
        image,
        requiresInstallation,
        isProduct,
        quantity,
        skuSeoName,
        priceType,
      } = breakdownItem;
      const isItemPrepaid = prepaid === PREPAID_STATUS.BASE || prepaid === PREPAID_STATUS.FULL;
      const pricesAndDiscounts = getPricesAndDiscounts({
        item: breakdownItem,
        isItemPrepaid,
      });
      const matchingCartItem = cartItems.find((cartItem, cartItemIndex) => {
        return cartItem.skuId === skuId && cartItemIndex === breakdownItemIndex;
      });
      const hasUnansweredQuestions = matchingCartItem
        ? matchingCartItem.hasUnansweredQuestions
        : false;
      return {
        name,
        category,
        index: breakdownItemIndex,
        skuId,
        hasUnansweredQuestions,
        image,
        adjustments: getFilteredServiceAdjustments({ adjustments, isItemPrepaid: prepaid }),
        serviceDiscounts: getFilteredServiceDiscounts(adjustments),
        requiresInstallation,
        isProduct,
        quantity,
        skuSeoName,
        priceType,
        ...pricesAndDiscounts,
      };
    });
  });

/** estimatedCart.breakdown.items formatted to include additional data */
export const estimatedCartBreakdownItemsSelector = getFormattedCartBreakdownItems(
  estimatedCartBreakdownSelector,
  estimatedCartSelector,
);

/** cart.breakdown.items formatted to include additional data */
export const breakdownItemsSelector = getFormattedCartBreakdownItems(
  cartBreakdownSelector,
  cartSelector,
);

export const productOnlySkusSelector = createSelector(breakdownItemsSelector, (breakdownItems) => {
  /*
    "Requires installation" is from our standpoint, not the purchaser's. If they purchased the product/install
    bundle then it will be true, because we need to send a tech out.
  */
  const productOnlySkus =
    breakdownItems &&
    breakdownItems.filter((i) => i.isProduct === true && i.requiresInstallation === false);
  return productOnlySkus;
});

const canAddAnotherServiceSelector = createSelector(pageSelector, (page) =>
  page.get('anotherServiceAvailable'),
);

const planDetailsSelector = createSelector(pageSelector, (page) => page.get('planDetails'));

const showRemovePlanModalSelector = createSelector(pageSelector, (page) =>
  page.get('showRemovePlanModal'),
);

const showUpsellCartVersionSelector = createSelector(pageSelector, (page) =>
  page.get('showUpsellCartVersion'),
);

const estimatedTotalSavingsSelector = createSelector(pageSelector, (page) =>
  page.getIn(['estimatedCart', 'breakdown', 'totalSavings']),
);

const estimatedTotalSavingsFormattedSelector = createSelector(pageSelector, (page) => {
  if (!page) return 0;
  return page.getIn(['estimatedCart', 'breakdown', 'totalSavingsFormatted']) || 0;
});

const plansInfoSelector = createSelector(pageSelector, (page) => page.get('plansInfo'));

const plansInfoJSSelector = createSelector(
  plansInfoSelector,
  (plansInfo) => plansInfo && plansInfo.toJS(),
);

const isPlanAddedToCartSelector = createSelector(planSelector, (plan) => Boolean(plan));

const selectedPlanPricingIntervalSelector = createSelector(cartSelector, (cart) =>
  cart.getIn(['plan', 'planInterval']),
);

export const canAdvanceApiOrderCartSelector = createSelector(cartSelector, (cart) => {
  if (!cart || !cart.get('items')) return true;
  const { items } = cart.toJS();
  return !items.some((i) => i.hasUnansweredQuestions);
});

const hasProductsInCartSelector = createSelector(cartSelector, (cart) =>
  Boolean(cart.get('hasProducts')),
);

const showSkuLinkSelector = createSelector(cartSelector, (cart) => {
  // Don't show link for Comcast/Xfinity carts
  const landingSeoName = cart.getIn(['partner', 'landingSeoName']) || '';
  const partnerName = cart.getIn(['partner', 'partnerName']) || '';
  const partnerNameSources = [landingSeoName.toLowerCase(), partnerName.toLowerCase()];
  if (
    partnerNameSources.find(
      (elem) => elem.includes(PARTNERS.COMCAST) || elem.includes(PARTNERS.XFINITY),
    )
  )
    return false;
  return true;
});

export default createStructuredSelector({
  cart: cartSelector,
  page: pageSelector,
  currentPartner: currentLandingSelector,
  userHasSubscription: userHasSubscriptionSelector,
  snackbar: snackbarSelector,
  breakdown: cartBreakdownSelector,
  breakdownItems: breakdownItemsSelector,
  orderAdjustments: formattedOrderAdjustmentSelector,
  subscriptionCreditAdjustments: subscriptionCreditAdjustmentsSelector,
  itemsTotalFormatted: itemsTotalFormattedSelector,
  subscriptionTotalFormatted: subscriptionTotalFormattedSelector,
  totalFormatted: totalFormattedSelector,
  plan: purePlanSelector,
  planInterval: planIntervalSelector,
  planName: planNameSelector,
  planAmount: planAmountSelector,
  canAddAnotherService: canAddAnotherServiceSelector,
  planDetails: planDetailsSelector,
  showRemovePlanModal: showRemovePlanModalSelector,
  showUpsellCartVersion: showUpsellCartVersionSelector,
  coupon: couponSelector,
  couponText: couponTextSelector,
  couponValueFormatted: couponValueFormattedSelector,
  orderAdjustmentsNoCoupon: orderAdjustmentsNoCouponSelector,
  totalSavings: totalSavingsSelector,
  totalSavingsFormatted: totalSavingsFormattedSelector,
  badgeSavings: badgeSavingsSelector,
  estimatedTotalSavings: estimatedTotalSavingsSelector,
  estimatedTotalSavingsFormatted: estimatedTotalSavingsFormattedSelector,
  plansInfo: plansInfoSelector,
  plansInfoJS: plansInfoJSSelector,
  isPlanAddedToCart: isPlanAddedToCartSelector,
  selectedPlanPricingInterval: selectedPlanPricingIntervalSelector,
  canAdvanceApiOrderCart: canAdvanceApiOrderCartSelector,
  partnerName: partnerNameSelector,
  cartHasPins: cartHasPinsSelector,
  hasProductsInCart: hasProductsInCartSelector,
  subscriptionCouponValue: subscriptionCouponValueSelector,
  subscriptionCoupon: subscriptionCouponSelector,
  productOnlySkus: productOnlySkusSelector,
  showSkuLink: showSkuLinkSelector,
  couponValue: couponValueSelector,
});
