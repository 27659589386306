import { isProduction, isStaging } from 'src/utils/env';

// Cannot use config from 'src/config' because we need the values on server side
export const getRailsHost = () => {
  if (isProduction()) return 'https://admin.hellotech.com';
  if (isStaging()) return 'https://admins.hellotech.com';
  return 'http://localhost';
};

const getClientHome = () => {
  if (isProduction()) return 'https://hellotech.com';
  if (isStaging()) return 'https://htstaging.hellotech.com';
  return 'http://localhost';
};

const getPartnersHost = () => {
  if (isProduction()) return 'https://partner.hellotech.com';
  if (isStaging()) return 'https://partners.hellotech.com';
  return 'http://partners.ht.com';
};

export function homePathForUser(user) {
  const railsHost = getRailsHost();
  let location;
  switch (user.type) {
    case 'Client':
      location = getClientHome();
      break;
    case 'Admin':
      location = `${railsHost}/admin`;
      break;
    case 'FledglingGeek':
    case 'Geek':
      location = `${railsHost}/geeks`;
      break;
    case 'Recruits::Recruit':
      location = `${railsHost}/recruits/geek_applications/current_status`;
      break;
    case 'Partner':
      location = getPartnersHost();
      break;
    default:
      throw new Error(`Unknown user type: ${user.type}`);
  }
  return location;
}
