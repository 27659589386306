import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { size, map } from 'lodash';
import { NOTICE_TYPE } from 'src/constants/common';
import styles from './styles.scss';

const Message = ({ notice }) => {
  let type = '-default';
  if (notice.type === NOTICE_TYPE.SUCCESS) {
    type = '-success';
  } else if (notice.type === NOTICE_TYPE.ERROR) {
    type = '-error';
  }

  return <p className={cn(styles.message, styles[type])}>{notice.message}</p>;
};

Message.propTypes = {
  notice: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
  }),
};

const Notice = ({ page, className, messages }) => {
  const pages = useSelector((state) => state.get('pages'));
  const pagesJS = pages.toJS();
  const notices = page ? (pagesJS[page] || {}).notices : messages;
  const hasNotices = size(notices);
  return hasNotices ? (
    <div className={className}>
      {map(notices, (notice, index) => (
        <Message notice={notice} key={index} />
      ))}
    </div>
  ) : null;
};

Notice.propTypes = {
  page: PropTypes.string,
  className: PropTypes.string,
  messages: PropTypes.array,
  pages: PropTypes.object.isRequired,
};

export default memo(Notice);
