import React from 'react';
import PropTypes from 'prop-types';
import Button, { THEMES as BUTTON_THEMES } from 'HTKit/Forms/Button';
import { addSkuPath } from 'src/utils/paths';
import styles from './styles.scss';

function CartRelatedSku({ sku }) {
  const { name, startsAtPrice, heroMobileImage } = sku;
  const image = heroMobileImage ? heroMobileImage.squared : null;
  const imageStyles = image
    ? {
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center center',
        backgroundSize: '100%',
      }
    : {};

  return (
    <div className={styles.sku}>
      <div className={styles.skuImage} style={imageStyles} />
      <div className={styles.skuInfo}>
        <h5>{name}</h5>
        <p className={`p1 ${styles.skuInfoPrice}`}>Prices starting at {startsAtPrice}</p>
        <Button
          theme={BUTTON_THEMES.V2SECONDARY}
          smallV2
          className={styles.skuInfoButton}
          isLink
          goTo={addSkuPath(sku.id)}
        >
          Add to Cart
        </Button>
      </div>
    </div>
  );
}

CartRelatedSku.propTypes = {
  sku: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    startsAtPrice: PropTypes.string,
    heroMobileImage: PropTypes.string,
  }).isRequired,
};

export default CartRelatedSku;
