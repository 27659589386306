import BBB from 'src/images/branding/bbb.svg';
import stripe from 'src/images/branding/stripe.svg';
import godaddy from 'src/images/branding/godaddy.svg';

const GUARANTEE_BADGES = [
  { name: 'Better Business Bureau security badge', url: BBB },
  { name: 'Go Daddy security badge', url: godaddy },
  { name: 'Stripe security badge', url: stripe },
];

export default GUARANTEE_BADGES;
