import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { prevented } from 'src/utils/event';
import { SKU_TYPE } from 'src/components/Header/TopNavHeader/TopNavItemsV2/constants';
import { buildLocationsLink } from 'src/utils/locations';
import { getUpsellData } from 'src/components/Upsell/NavSubCategoryUpsell/utils';
import { MENU_TEMPLATE_TYPES } from '../MenuTemplate/MenuTemplate';
import MenuTemplate from '../MenuTemplate';

const SubCategoryMenu = ({
  visible,
  selectedSubCategory,
  setSubCategoryMenuVisibleState,
  subCategoryOrder,
  skuLinkPrefix,
}) => {
  const [secondaryItems, setSecondaryItems] = useState([]);

  const headerItems = [
    {
      text: selectedSubCategory.name,
      onBackClick: prevented(() => setSubCategoryMenuVisibleState(false)),
    },
  ];

  // Only grab the upsell data when visible to avoid fetching the images before the menu is opened
  const upsellData = visible ? getUpsellData(selectedSubCategory) : false;

  useEffect(() => {
    const items = subCategoryOrder.reduce((acc, subCatName) => {
      if (!Object.keys(selectedSubCategory).length) return acc;
      const allSkus = selectedSubCategory.skus || [];
      const matchingSkusByType = allSkus
        .filter((sku) => sku.skuType === subCatName && sku.link)
        .map((sku) => {
          const { link = '' } = sku;
          return {
            text: sku.name,
            link: skuLinkPrefix ? buildLocationsLink({ prefix: skuLinkPrefix, link }) : link,
          };
        });
      acc.push({ name: subCatName, skus: matchingSkusByType });
      return acc;
    }, []);
    setSecondaryItems(items);
  }, [selectedSubCategory, skuLinkPrefix, subCategoryOrder]);

  return (
    <MenuTemplate
      slide
      visible={visible}
      headerItems={headerItems}
      secondaryItems={secondaryItems}
      type={MENU_TEMPLATE_TYPES.SORT_BY_SKU_TYPE}
      upsellData={upsellData}
    />
  );
};

SubCategoryMenu.propTypes = {
  visible: PropTypes.bool,
  selectedCategory: PropTypes.object,
  selectedSubCategory: PropTypes.string,
  setSubCategoryMenuVisibleState: PropTypes.func,
  subCategoryOrder: PropTypes.array,
  skuLinkPrefix: PropTypes.string,
};

SubCategoryMenu.defaultProps = {
  subCategoryOrder: [SKU_TYPE.INSTALLATION, SKU_TYPE.SUPPORT], // The skus will be sorted and displayed in this order
};

export default SubCategoryMenu;
