import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { loadPage } from 'src/actions/common';
// Selectors
import { pageSelector } from 'src/selectors/page';
import { landingSelector } from 'src/selectors/landings';
import { isMobileSelector } from 'src/selectors/resolution';
// Components
import PageLoader from 'src/components/PageLoader';
import ErrorPage from 'src/containers/ErrorPage';
import SimpliSafeLandingPage from 'src/containers/SimpliSafeLandingPage';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import LandingPageIframe, { isIframeRoute } from './Iframe/LandingPageIframe';
import LandingPageTemplateSelector from './LandingPageTemplateSelector';
// Constants
import { PAGE_NAME } from './constants';

const LandingPage = ({ match, name: ownPropsName = '' }) => {
  const dispatch = useDispatch();
  const page = useSelector(pageSelector(PAGE_NAME));
  const landings = useSelector(landingSelector);
  const isMobile = useSelector(isMobileSelector);
  const {
    params: { name: paramsName = '' },
  } = match;
  const name = (ownPropsName || paramsName).toLowerCase();
  const landing = landings.get(name);

  const { splitLoaded, splitTreatment } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_PLAN_PRICING,
  );
  const isPlanPricingTest = splitTreatment === splitioConstants.ON;

  useSplitIOTreatment(splitioConstants.SPLITNAME_LP_INSTALL_SETUP_SHOW_SITE_NAV); // Load split here, so we can use in sagas
  useEffect(() => {
    if (splitLoaded && page.get('loading')) {
      dispatch(loadPage(PAGE_NAME, { name, isPlanPricingTest }));
    }
  }, [splitLoaded, name]);

  if (page.get('loading')) return <PageLoader />;
  if (page.get('error')) return <ErrorPage page={page} />;

  if (isIframeRoute(name))
    return (
      <LandingPageIframe
        name={name}
        landing={landing}
        isMobile={isMobile}
        isPlanPricingTest={isPlanPricingTest}
      />
    );

  if (name === 'simplisafe') return <SimpliSafeLandingPage />;

  return (
    <LandingPageTemplateSelector
      landing={landing}
      isMobile={isMobile}
      isPlanPricingTest={isPlanPricingTest}
    />
  );
};

LandingPage.propTypes = {
  match: PropTypes.object.isRequired,
  name: PropTypes.string,
};

export default LandingPage;
