import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  TOGGLE_SIDEBAR,
  SET_SKU_LINK_PREFIX
} from './constants';

const initialState = fromJS({
  open: false,
  skuLinkPrefix: null,
  menuOnOpen: null, // specify menu to show on open
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return state.set('open', false);
    }
    case TOGGLE_SIDEBAR: {
      const { menuOnOpen } = action;
      const newOpenState = !state.get('open');
      const newMenuOnOpen = newOpenState ? menuOnOpen : null;
      return state.set('open', newOpenState)
                  .set('menuOnOpen', newMenuOnOpen);
    }
    case SET_SKU_LINK_PREFIX: {
      return state.set('skuLinkPrefix', action.payload.prefix);
    }
    default: {
      return state;
    }
  }
}

export default reducer;
