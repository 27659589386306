import { call, put, takeEvery } from 'redux-saga/effects'; // eslint-disable-line
import { LOAD_PAGE } from 'src/constants/common';
import { selectRoutes } from 'src/apiRoutes';
import { errorNotice } from 'src/utils/notice';
import { clearFormErrors, pageNotice, clearPageNotices, pageLoaded } from 'src/actions/common';
import { requestStarted, requestFinished } from 'src/utils/request';
import { SUBMIT_FORM } from './constants';
import { customerValidated, showOffer } from './actions';

const PARTNER_VALIDATION_PAGE_NAME = 'ahsValidationPage';

export function* submitFormSaga({ payload }) {
  const routes = yield call(selectRoutes);
  yield put(showOffer(false));
  const form = payload;
  form.contractId = form.contractId.trim();
  form.address1 = form.address1.trim();
  const emptyFormSubmitted = !form.address1 && !form.contractId;
  if (emptyFormSubmitted) {
    yield put(customerValidated(false));
    yield put(pageNotice(PARTNER_VALIDATION_PAGE_NAME, errorNotice('Please enter a contract number or address.')));
    return;
  }
  const customerInfo = {
    data: {
      contractId: form.contractId,
      address: {
        address1: form.address1,
        address2: form.address2,
        city: form.city,
        postalCode: form.zipCode,
        state: form.state
      }
    }
  };

  yield put(clearFormErrors(PARTNER_VALIDATION_PAGE_NAME));
  yield put(clearPageNotices(PARTNER_VALIDATION_PAGE_NAME));
  yield put(requestStarted());
  const response = yield call(routes.partners.ahsValidation, { customerInfo });
  yield put(requestFinished());
  if (response.data.err){
    if (response.data.showOffer) {
      yield put(showOffer(true));
    }
    yield put(customerValidated(false));
    yield put(pageNotice(PARTNER_VALIDATION_PAGE_NAME, errorNotice(response.data.err || response.err.message)));
  } else {
    yield put(customerValidated(true));
  }
}

export function* pageSaga() {
  yield put(pageLoaded(PARTNER_VALIDATION_PAGE_NAME, {}));
}

export function* partnerValidationFlow() {
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === PARTNER_VALIDATION_PAGE_NAME, pageSaga);
  yield takeEvery(SUBMIT_FORM, submitFormSaga);
}

export default [
  partnerValidationFlow,
];
