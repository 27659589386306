import { call, put, takeEvery } from 'redux-saga/effects';
import {
  push,
  paloAltoNetworksAccountOrderPath,
  paloAltoNetworksAccountOrderPublicPath,
} from 'src/utils/paths';
// import { LOAD_PAGE } from 'src/constants/common';
import { NEED_AT_LEAST_ONE_VALID_DATE } from 'src/constants/cart';
import { selectRoutes } from 'src/apiRoutes';
import { orderInfoLoaded } from 'src/actions/order';
import { newNotice } from 'src/components/NoticeStack/actions';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import {
  SUBMIT_RESCHEDULE,
  PALO_ALTO_NETWORKS_DETAILS,
} from 'src/containers/BookingPagePaloAltoNetworks/constants';

function* submitSaga({ ranges, orderId, orderToken, publicPage }) {
  yield put(requestStarted());
  const routes = yield call(selectRoutes);
  let response;
  if (publicPage) {
    response = yield call(routes.orders.public.reschedule, { orderToken }, { range_data: ranges });
  } else {
    response = yield call(routes.orders.reschedule, { orderId }, { range_data: ranges });
  }
  yield put(requestFinished());

  const {
    data: { order },
    err,
  } = response;

  if (!err) {
    yield put(
      newNotice({
        title: "We've updated your preferred service time.",
        content:
          "Please be aware that your appointment has not yet been confirmed. Once we have located just the right tech for your service, you'll receive a confirmation email with your confirmed appointment time and details about your tech",
        theme: PALO_ALTO_NETWORKS_DETAILS.shortName,
      }),
    );

    yield put(orderInfoLoaded({ order }));

    if (publicPage) {
      yield put(push(paloAltoNetworksAccountOrderPublicPath(orderToken)));
    } else {
      yield put(push(paloAltoNetworksAccountOrderPath(orderId)));
    }
  } else {
    const errors = response.data.errors || [];
    const errMsg = errors[0];
    if (errMsg === NEED_AT_LEAST_ONE_VALID_DATE) {
      yield put(
        newNotice({
          title: 'Your Selected Times Are No Longer Available',
          content: 'Please choose additional times.',
          theme: PALO_ALTO_NETWORKS_DETAILS.shortName,
        }),
      );
    } else {
      yield put(displayErrors(response));
    }
  }
}

function* pageFlow() {
  yield takeEvery(SUBMIT_RESCHEDULE, submitSaga);
}

export default [pageFlow];
