import merge from 'lodash/merge';
import pick from 'lodash/pick';
import {
  LAYOUT_UPDATE,
  DEFAULT_LAYOUT,
  INVALID_FORM,
  CLEAR_FORM_ERRORS,
  CLEAR_FORM_ERROR,
  LOAD_PAGE,
  PAGE_LOADED,
  PAGE_LOADING_ERROR,
  PAGE_NOTICES,
  CLEAR_PAGE_NOTICES,
  PAGE_NOTICE,
  CLEAR_PAGE_NOTICE,
  UPDATE_USER,
  VALIDATE_FORM,
  NEW_ROUTE_SCHEMA,
  CALL_CLICK,
  SET_ZIP_CODE_RESTRICTION,
  ADD_URL_PARAM_COUPON,
  UPDATE_USER_SEGMENT_IDENTIFY,
  USER_IS_PRISTINE,
} from '../constants/common';

export const REQUEST_STARTED = 'common/REQUEST_STARTED';
export const REQUEST_FINISHED = 'common/REQUEST_FINISHED';
export const REQUEST_FAILED = 'common/REQUEST_FAILED';

export function requestStarted() {
  return {
    type: REQUEST_STARTED,
  };
}

export function layoutUpdate(layout, deep = false) {
  const mergedLayout = deep ? layout : merge({}, DEFAULT_LAYOUT, layout);
  return {
    type: LAYOUT_UPDATE,
    layout: mergedLayout,
    deep,
  };
}

export function requestFinished() {
  return {
    type: REQUEST_FINISHED,
  };
}

export function requestFailed() {
  return {
    type: REQUEST_FAILED,
  };
}

export function invalidForm(page, errors) {
  return {
    type: INVALID_FORM,
    page,
    errors,
  };
}

export function clearFormErrors(page) {
  return {
    type: CLEAR_FORM_ERRORS,
    page,
  };
}
export function clearFormError(page, field) {
  return {
    type: CLEAR_FORM_ERROR,
    page,
    field,
  };
}

export function pageNotices(page, notices) {
  return {
    type: PAGE_NOTICES,
    page,
    notices,
  };
}

export function clearPageNotices(page) {
  return {
    type: CLEAR_PAGE_NOTICES,
    page,
  };
}

export function pageNotice(page, { type, name, message }) {
  return {
    type: PAGE_NOTICE,
    page,
    name,
    message,
    noticeType: type,
  };
}

export function clearPageNotice(page, { name }) {
  return {
    type: CLEAR_PAGE_NOTICE,
    page,
    name,
  };
}

export function loadPage(page, payload = {}) {
  return {
    type: LOAD_PAGE,
    page,
    ...payload,
  };
}

export function pageLoaded(page, payload = {}) {
  return {
    type: PAGE_LOADED,
    page,
    ...payload,
  };
}

export function pageLoadingError(page, requestResult) {
  const status = requestResult.err.response ? requestResult.err.response.status : 500;
  const messages = requestResult.data.errors;
  const error = {
    status,
    messages,
  };
  return {
    type: PAGE_LOADING_ERROR,
    page,
    error,
  };
}

export function updateUser(user) {
  return {
    type: UPDATE_USER,
    payload: { user },
    ...identifyCallOnUserUpdateObject(user),
  };
}

/**
 * We need a way to determine if we've "tried" to get a user from the server.
 * If userIsPristine is true, it means no - we have NOT received the response from the server.
 * This helps us because it
 * @returns {{payload: {userIsPristine: boolean}, type: string}}
 */
export function userIsPristine() {
  return {
    type: USER_IS_PRISTINE,
    payload: { userIsPristine: false },
  };
}

export function identifySegmentStandaloneCall(user) {
  return {
    type: UPDATE_USER_SEGMENT_IDENTIFY,
    ...identifyCallOnUserUpdateObject(user),
  };
}
export function identifyCallOnUserUpdateObject(user) {
  const userObject = user || {};
  return {
    meta: {
      analytics: [
        {
          eventType: 'identify',
          eventPayload: {
            userId: userObject.id,
            traits: {
              id: userObject.id,
              email: userObject.email,
              first_name: userObject.firstName,
              last_name: userObject.lastName,
            },
          },
        },
      ],
    },
  };
}
export function validateForm(
  { form, rules = {}, name = null, except = [], strict = false },
  lifecycle = null,
) {
  const defaultLifecycle = { onSuccess: () => {}, onError: () => {} };
  const { onSuccess, onError } = merge(
    {},
    defaultLifecycle,
    pick(lifecycle, ['onSuccess', 'onError']),
  );
  return {
    type: VALIDATE_FORM,
    payload: { form, rules, name, onSuccess, onError, strict, except },
  };
}

export function newRouteSchema(routes) {
  return {
    type: NEW_ROUTE_SCHEMA,
    routes,
  };
}

export function callClick() {
  return {
    type: CALL_CLICK,
  };
}

export const setZipCodeRestriction = ({ isZipRestricted, orderableFromDate, page }) => ({
  type: SET_ZIP_CODE_RESTRICTION,
  isZipRestricted,
  orderableFromDate,
  page,
});

export const addUrlParamCoupon = (coupon) => ({
  type: ADD_URL_PARAM_COUPON,
  coupon,
});
