import React from 'react';
import cn from 'classnames';
import Icon from 'HTKit/Icon';
import styles from './styles.scss';

const SubHeaderCopy = () => {
  const defaultSubheaderCopy = (
    <p className={cn('p2',styles.subHeaderCopy)}>
      <Icon name="clock" className={styles.subheaderIcon} />
      Pick as many times as possible
    </p>
  );

  return defaultSubheaderCopy;
};

export default React.memo(SubHeaderCopy);
