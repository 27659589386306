import memoize from 'lodash/memoize';

export const MAX_ITEMS = {
  SINGLE_SERVICE: 1,
  MULTIPLE_SERVICES: Infinity,
};

export const DEFAULT_CONFIG_VALUES = {
  isSingleServiceFlow: () => false,
  getMaxAllowedItems: () => 0,
  isAtItemLimit: () => false,
  getSkuPageConfig: () => ({ showWarning: false }),
  getTopNavConfig: () => ({ showCartIcon: true, onCartIconClick: null }),
  getBookingHeaderConfig: () => ({ showCartIcon: false }),
  getAddSkuPageSagaConfig: () => ({ shouldSkipCartAndStartBooking: false }),
  getOrderConfirmationPageConfig: () => ({ shouldShowRelatedSkus: false }),
};

/**
 * createCartFlow()
 *
 * Creates a set of utility functions to manage cart flow behavior based on the current cart state.
 * This function is designed to handle both single-service and multi-service scenarios across
 * multiple applications with different state management solutions (e.g., Redux sagas, Redux thunks, Zustand).
 *
 * Key features:
 * - Determines the current flow type (single-service or multi-service)
 * - Manages item limits based on the flow type
 * - Can be used in components, business logic files, and potentially server-side
 *
 * Usage:
 * const cartFlow = createCartFlow({ cart });
 * if (cartFlow.isAtItemLimit()) {
 *   // Prevent adding more items or show a warning
 * }
 *
 * Note: Consider using the memoized version (memoizedCreateCartFlow) for performance optimization
 * when used in components or other scenarios where it might be called frequently with the same cart state.
 */
export const createCartFlow = ({ cart }) => {
  // #######################
  //    CART STATE UTILS
  // #######################
  const isSingleServiceFlow = () => Boolean(cart?.singleService);

  const getMaxAllowedItems = () =>
    isSingleServiceFlow() ? MAX_ITEMS.SINGLE_SERVICE : MAX_ITEMS.MULTIPLE_SERVICES;

  const isAtItemLimit = () => cart?.items.length >= getMaxAllowedItems();

  // ##################
  //     UI HELPERS
  // ##################

  const getSkuPageConfig = ({
    /** Sku to be added to the cart. */
    newSkuId,
    /** Sku in the cart. May be null if there is no sku in the cart. */
    existingSkuId = null,
  }) => {
    /** Determines if the sku id they are adding is the same as the sku in the cart */
    const isAddingNewSku = existingSkuId && newSkuId !== existingSkuId;

    /** Determine if the warning modal should be shown */
    const showWarning = isSingleServiceFlow() && isAtItemLimit() && isAddingNewSku;

    return { showWarning };
  };

  const getTopNavConfig = () => {
    /**
     * Don't add any callbacks here, the property is more for documentation/typing purposes.
     * The caller should add callbacks if needed.
     *
     * Example:
     *  const baseConfig = getTopNavConfig();
     *  const myCallback = () => {};
     *  const baseConfigEnhanced = { ...baseConfig, onCartIconClick: myCallback };
     */
    const base = { onCartIconClick: null };

    if (isSingleServiceFlow()) {
      const cartHasItems = Boolean(cart?.items.length);
      const cartHasPlan = Boolean(cart?.plan);
      const showCartIcon = cartHasItems || cartHasPlan;
      return { showCartIcon, ...base };
    }
    return { showCartIcon: true, ...base };
  };

  const getBookingHeaderConfig = (pathname = '') => {
    const isOnConfirmationPage = pathname.includes('/orders/confirmation');
    const isOnBookingsummaryPage = pathname.includes('/cart/booking/summary');
    const showCartIcon = isSingleServiceFlow() && !isOnConfirmationPage && !isOnBookingsummaryPage;

    return { showCartIcon };
  };

  const getAddSkuPageSagaConfig = () => {
    /** Handle navigation away from the AddSkuPage */
    const shouldSkipCartAndStartBooking = isSingleServiceFlow();
    return {
      shouldSkipCartAndStartBooking,
    };
  };

  const getOrderConfirmationPageConfig = () => {
    const shouldShowRelatedSkus = isSingleServiceFlow();

    return {
      shouldShowRelatedSkus,
    };
  };

  return {
    // Cart State Utils
    isSingleServiceFlow,
    getMaxAllowedItems,
    isAtItemLimit,

    // UI Helpers
    getSkuPageConfig,
    getTopNavConfig,
    getBookingHeaderConfig,
    getAddSkuPageSagaConfig,
    getOrderConfirmationPageConfig,
  };
};

const resolver = ({ cart }) => {
  return JSON.stringify({
    availability: cart.availability,
    breakdownTotal: cart.breakdown?.total,
    clientId: cart.clientId,
    hasOnlyProducts: cart.hasOnlyProducts,
    hasPlan: !!cart.plan,
    hasProducts: cart.hasProducts,
    id: cart.id,
    itemCount: cart.items?.length,
    singleService: cart.singleService,
    skipCart: cart.skipCart,
    statusId: cart.statusId,
    token: cart.token,
  });
};

/**
 * Memoized version of createCartFlow() for performance.
 *
 * The list of properties in the resolver function are used to
 * determine if the cart has changed in such a way that the
 * cartFlow object needs to be recreated.
 */
export const memoizedCreateCartFlow = memoize(createCartFlow, resolver);

/**
 * A memoized version of createCartFlow() specifically for completed orders.
 *
 * Purpose:
 * Maintains a separate memoization cache for completed order carts, distinct from active Redux carts.
 * This separation ensures that carts from completed orders don't interfere with the
 * memoization of the active cart, and vice versa.
 *
 * Usage:
 * Use this function for carts attached to completed orders (e.g., `order.cart`).
 * For the user's active cart stored in Redux, use memoizedCreateCartFlow() instead.
 */
export const memoizedCreateCartFlowForCompletedOrders = memoize(createCartFlow, resolver);
