// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from 'react';

/**
 * We can use this in 2 ways
 * 1. User sets execution to fire until call back is true
 * 2. Call iteration "maxIterations"
 * 3. Endless looping
 *
 * usage:
 const {finishedLoading} = useInterval(() => {
    if (('affirm' in window && window.affirm.jsReady())) {
      return true;
    }

    return false;
  }, 200, 20);

  result: Every 200ms it tests to see if callback is true, if not, increases iteration count.
          Will un every 200ms until true is returned or maxIterations is reached.

          If no delay and maxIterations are passed, runs loop. ie time counter.


 * @param callback
 * @param delay
 * @param maxIterations
 * @returns {{iterationCount: number, finished: boolean}}
 */
function useInterval(callback, delay, maxIterations = null) {
  const savedCallback = useRef();
  const iterationCount = useRef(0);
  const [finished, setFinished] = useState(false);

  const maxIterationsReached  = () => {
    if (maxIterations && iterationCount.current >= maxIterations) {
      return true;
    }

    return false;
  };

  /* Remember the callback. */
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  /*
   - Set up the interval.
   - Communicate with caller to determine if predicate fn passed every tick
      i. if so, clear interval
   - If number ticks exceed max, clear interval.
   */
  useEffect(() => {
    function tick(id) {
      const done = savedCallback.current();
      if (done || maxIterationsReached() ) {
        setFinished(true);
        clearInterval(id);
      }
      // eslint-disable-next-line no-plusplus
      iterationCount.current++;
    }

    if (delay !== null) {
      const id = setInterval(() => tick(id), delay);
      return () => clearInterval(id);
    }
    return () => {};

  }, [delay]);

  return { finished, iterationCount }
}

export default useInterval;

