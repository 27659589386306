// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import Star from './Star';
// Styles
import styles from './styles.scss';

const StarRating = (props) => {
  const { stars, starColor, size, keyStr, hover } = props;
  const fullStars = Math.floor(stars);
  const partialStarValue = (stars % 1) * 100;
  const emptyStars = 5 - (fullStars + (partialStarValue !== 0 ? 1 : 0));

  const renderedStars = [...Array(fullStars)].map((_, index) => (
    <span className={styles.starWrapper} key={`star-full-${index}`}>
      <Star fillColor={starColor} size={size} hover={hover} />
    </span>
  ));

  if (partialStarValue !== 0) {
    renderedStars.push(
      <span className={styles.starWrapper} key="star-half">
        <Star
          fillPercentage={partialStarValue}
          fillColor={starColor}
          size={size}
          keyStr={keyStr}
          hover={hover}
        />
      </span>,
    );
  }

  if (emptyStars > 0) {
    [...Array(emptyStars)].map((_, index) => {
      renderedStars.push(
        <span className={styles.starWrapper} key={`star-empty-${index}`}>
          <Star fillPercentage={0} size={size} hover={hover} />
        </span>,
      );
    });
  }

  return renderedStars;
};

StarRating.propTypes = {
  stars: PropTypes.number.isRequired,
  starColor: PropTypes.string,
  size: PropTypes.number,
  hover: PropTypes.bool,
  keyStr: PropTypes.string,
};

StarRating.defaultProps = {
  starColor: '#f67d5b', // ht-red-orange
  hover: false,
};

export default StarRating;

/*
  StarRating:
    Used to render a number of stars associated with a rating of some sort.

  Examples:
    <StarRating stars={5} starColor="#00c9bc" />
    <StarRating stars={4.898} />

  Properties:
    stars:
      type: number
      description: Number of stars/partial star to render based on a 5 star scale.
    starColor:
      type: string
      description: A hex color of the stars.
    size:
      type: number
      description: size of each star in pixels
    hover:
      type: boolean
      description: if component has hover state
    keyStr:
      type: String
      description: string to create a unique id for <Star />

  Last modified: -JK April 3, 2017
*/
