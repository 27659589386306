// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

// Selectors
import { userPlanIdSelector } from 'src/selectors/user';

// Utils
import { isProductOnlySku } from 'src/containers/CartPage/utils';

// Components
import ServiceBreakdownItem from 'src/components/OrderSummary/Breakdown/ServiceBreakdownItem';
import ServiceImage from 'src/components/OrderSummary/Breakdown/ServiceImage';
import QtyDropdown from 'src/components/HTKit/Elements/QtyDropdown';
import ProductInstallationUpsell from 'src/containers/CartPage/Parts/ServicesBreakdown/ProductInstallationUpsell';

// Constants & Actions
import { QA_FLOW_VALID_SKUS } from 'src/containers/AddSkuPage/QuestionsFlowPage/constants';
import { updateQuantity } from 'src/containers/CartPage/actions';
import { skuPath, push } from 'src/utils/paths';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import { BREAKDOWN_PRICE_TYPES } from 'src/containers/CartPage/constants';

// Styles
import styles from './styles.scss';

const RegularCartServiceBreakdown = ({
  cart,
  services,
  editItem,
  removeItem,
  productOnlySkus,
  isPartner,
  cartHasPins,
  showSkuLink,
  isMemberCart,
}) => {
  if (!services) return null;
  const editStyles = cn('plainButton', styles.editButton);
  const dispatch = useDispatch();
  const { splitTreatment, splitLoaded } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_CART_PRODUCT_INSTALLATION_UPSELL,
  );

  // Product Installation plan info
  const userPlanId = useSelector(userPlanIdSelector);
  const planIdForEstimate = isMemberCart ? userPlanId : null;
  return (
    <>
      {services.map((service, i) => {
        const { skuId, index, image, quantity, skuSeoName, priceType } = service;

        const showEditButton =
          !isProductOnlySku(skuId, productOnlySkus) && !QA_FLOW_VALID_SKUS.includes(skuId);

        const showProductInstallationUpsell =
          splitLoaded &&
          splitTreatment === splitioConstants.ON &&
          isProductOnlySku(skuId, productOnlySkus);

        const handleDropdownChange = (selectedValue) => {
          const qty = selectedValue.value;
          // action to update item in cart
          dispatch(updateQuantity(qty, index));
        };
        const handleClickOnSku = () => {
          dispatch(push(skuPath(skuSeoName)));
        };
        const isApiCart = isPartner && cartHasPins;
        const immutableService = cart.getIn(['breakdown', 'items', i]);
        const isHourlyService = priceType === BREAKDOWN_PRICE_TYPES.hourly;
        return (
          <div className={styles.itemContainer} key={`service-${i}`}>
            <ServiceImage image={image} quantity={quantity} />
            <div className={styles.itemSection}>
              <ServiceBreakdownItem
                {...service}
                boldServiceName
                shouldShowDropdown={!isApiCart && !isHourlyService}
                shouldShowLink={showSkuLink}
                dropdownValue={quantity}
                onDropdownChange={handleDropdownChange}
                onItemNameClick={handleClickOnSku}
                quantity={quantity}
              />
              <div className={styles.itemButtonContainer}>
                <div>
                  {showEditButton && (
                    <button className={editStyles} onClick={editItem({ skuId, index })}>
                      <p className="p1 text-link">Edit</p>
                    </button>
                  )}
                  <button className="plainButton" onClick={() => removeItem(service, index)}>
                    <p className="p1 text-link">Remove</p>
                  </button>
                </div>
                {/* Qty dropdown for mobile view only */}
                <QtyDropdown
                  shouldShowDropdown={!isApiCart && !isHourlyService}
                  className={styles.mobileQtyDropdown}
                  dropdownValue={quantity}
                  quantity={quantity}
                  onDropdownChange={handleDropdownChange}
                />
              </div>
              {showProductInstallationUpsell && (
                <ProductInstallationUpsell
                  productId={skuId}
                  index={index}
                  quantity={service.quantity}
                  item={immutableService}
                  planIdForEstimate={planIdForEstimate}
                  isMemberCart={isMemberCart}
                />
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

RegularCartServiceBreakdown.propTypes = {
  services: PropTypes.array,
  editItem: PropTypes.func,
  removeItem: PropTypes.func,
  productOnlySkus: PropTypes.array,
  onItemNameClick: PropTypes.func,
  isPartner: PropTypes.bool,
  cartHasPins: PropTypes.bool,
  showSkuLink: PropTypes.bool,
  cart: PropTypes.object,
  isMemberCart: PropTypes.bool,
};

export default RegularCartServiceBreakdown;
