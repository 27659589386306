import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
// Selectors
import { planIdSelector } from 'src/selectors/cart';
// Constants
import { UPSELL_PLAN_BILLING_CYCLES, MONTHLY_PLAN_ID } from 'src/constants/plan';
// Components
import { RadioV2 } from 'src/components/HTKit/Forms/RadioV2';
import styles from './styles.scss';
import { formatPriceTypeHourly } from 'src/containers/CartPage/utils';

const RADIO_OPTIONS = { PLAN: 'PLAN', NO_PLAN: 'NO_PLAN' };

const ServiceInfoUpsellV3 = ({
  serviceExtra = {}, // This is sku.extra
  onUpsellToggle,
  onUpsellMore,
  className,
}) => {
  /* Data */
  const { showUpsell, plansInfo, prices } = serviceExtra || {};
  const { MONTHLY } = UPSELL_PLAN_BILLING_CYCLES;
  const monthlyPlanId = get(plansInfo, [MONTHLY, 'id'], MONTHLY_PLAN_ID);
  const planIdInCart = useSelector(planIdSelector);
  const isUpsellPlanInCart = planIdInCart === monthlyPlanId;
  const monthlyPlanAmount = get(plansInfo, [MONTHLY, 'amount']);

  /* Local State */
  const [radio, setRadio] = useState(null);
  const onRadioChange = (selectedValue) => (e) => {
    if (e) e.preventDefault();
    setRadio(selectedValue);

    /*
       We control the apis here, otherwise it becomes unmanagemable normalizing the radios w/ cart,
       from various sources ie. inline radi, modal.

       Note: planId is what the upsell is giving us. The isUpsellPlanInCart is tell us if the plan in the cart is the
             same as what we are offering in the upsell.
    */
    if (selectedValue === RADIO_OPTIONS.PLAN && !planIdInCart) onUpsellToggle(true, monthlyPlanId);
    if (selectedValue === RADIO_OPTIONS.NO_PLAN && planIdInCart)
      onUpsellToggle(false, monthlyPlanId);
  };

  /* Styles */
  const standardContainerStyles = cn(
    'padding-small marginBottom-small flex alignItemsCenter',
    styles.upsellContainerV3,
    {
      [styles.v3Radioselected]: radio === RADIO_OPTIONS.NO_PLAN,
    },
  );
  const memberContainerStyles = cn(styles.upsellContainerV3, styles.memberContainer, {
    [styles.v3Radioselected]: radio === RADIO_OPTIONS.PLAN,
  });

  const priceHeaderStyles = cn('p2 text-weight-bold marginBottom-tiny');

  /* Methods */
  const handleLearnMoreClick = (e) => {
    if (e) e.stopPropagation();
    onUpsellMore();
  };

  /*
     Carted plan Id is the same as the monthly plan id.

     NOTE: We align the radio with the cart.
  */
  useEffect(() => {
    if (isUpsellPlanInCart && radio !== RADIO_OPTIONS.PLAN) setRadio(RADIO_OPTIONS.PLAN);
    if (!isUpsellPlanInCart && radio !== RADIO_OPTIONS.NO_PLAN) setRadio(RADIO_OPTIONS.NO_PLAN);
  }, [isUpsellPlanInCart]);

  return (
    <form className={className}>
      <div className={standardContainerStyles} onClick={onRadioChange(RADIO_OPTIONS.NO_PLAN)}>
        <RadioV2 value={RADIO_OPTIONS.NO_PLAN} selected={radio === RADIO_OPTIONS.NO_PLAN} />
        <div>
          <p className={priceHeaderStyles}>Standard Price</p>
          <p className="p1 text-weight-med">
            <span>
              {formatPriceTypeHourly({ service: serviceExtra.priceType, price: prices.price })}
            </span>
            &nbsp;
            <span className="n700 text-weight-regular">+ additional fees</span>
          </p>
        </div>
      </div>

      {showUpsell && (
        <>
          <div className={cn('text-align-center', styles.upsellV3MemberLabel)}>
            {`SAVE ${prices.priceDifference || ''}`}
          </div>
          <div className={memberContainerStyles}>
            <div
              className="flex alignItemsCenter padding-small"
              onClick={onRadioChange(RADIO_OPTIONS.PLAN)}
            >
              <RadioV2 value={RADIO_OPTIONS.PLAN} selected={radio === RADIO_OPTIONS.PLAN} />
              <div>
                <p className={priceHeaderStyles}>Members Price</p>
                <p className="p1 text-weight-med">
                  <span className="n300 line-through">{prices.price}&nbsp;</span>
                  <span className="blue700">{prices.memberPrice}&nbsp;</span>
                  <span className="n700 text-weight-regular">
                    with ${monthlyPlanAmount}/mo annual membership.
                  </span>
                  &nbsp;
                  <a
                    type="button"
                    className="text-link underline blue700"
                    onClick={handleLearnMoreClick}
                  >
                    Learn More
                  </a>
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

ServiceInfoUpsellV3.propTypes = {
  className: PropTypes.string,
  onUpsellToggle: PropTypes.func,
  onUpsellMore: PropTypes.func,
  serviceExtra: PropTypes.shape({
    rating: PropTypes.shape({
      rating: PropTypes.number,
      ratingCount: PropTypes.number,
    }),
    prices: PropTypes.shape({
      priceDifference: PropTypes.string,
    }),
    showUpsell: PropTypes.bool,
    ctaText: PropTypes.string,
    upsellSelected: PropTypes.bool,
    selectedPlanId: PropTypes.number,
    plansInfo: PropTypes.object,
    priceType: PropTypes.object,
  }),
};
export default ServiceInfoUpsellV3;
