import React from 'react';
import PropTypes from 'prop-types';
import DyanmicTabs from 'src/components/Elements/DynamicTabs';
import ProductCards from './ProductCards';
import styles from '../../styles.scss';

const ServiceTabs = ({ tabsData, className }) => {
  if (!tabsData || !tabsData.length) return null;

  const tabs = tabsData.map((tab) => tab.label);
  const skusToShow = tabsData.map((tab) => tab.skus);

  return (
    <DyanmicTabs
      tabs={tabs}
      tabsOuterWrapperClass={className}
      containerClass={styles.contentContainer}
      showStacked={false}
      leftOffset={100}
      tabsClasses={styles.tabs}
      rowClasses='text-align-left'
    >
      {skusToShow.map((skus, i) => {
        return <ProductCards skus={skus} key={`slide ${i}`} />;
      })}
    </DyanmicTabs>
  );
};

ServiceTabs.propTypes = {
  tabsData: PropTypes.array,
  className: PropTypes.string,
};
ServiceTabs.defaultProps = { tabsData: [] };

export default ServiceTabs;
