import get from 'lodash/get';
import { createSelector } from 'reselect';
import { newItemSelector } from 'src/containers/AddSkuPage/selectors';
import { isImmutable, immutableToJS } from 'src/utils/helpers';
import { cartSelector } from './cart';
import { layoutHeaderSelector } from './layout';
import { skusSelector } from './skus';

/**
 *****************************************************************
   Selectors for entities.partners slice
 *****************************************************************
 */

const partnersStateSelector = (state) => state.getIn(['entities', 'partners']);

export const partnerInfoJSSelector = (partnerKey) =>
  createSelector(partnersStateSelector, (partnersState) => {
    const partnerInfo = partnersState.get(partnerKey);
    return partnerInfo && partnerInfo.toJS();
  });

/**
 *****************************************************************
  End Selectors for entities.partners slice
 *****************************************************************
 */

const PARTNER_NAME = 'partnerName';
export const PARTNER_LOGOS = 'cobrandedLogo';
/* Workflow does not camel and too much code is already reading snake */
export const PARTNER_LOGOS_SNAKE = 'cobranded_logo';

/**
 * Helpers defind here to avoid linting issues
 */
function normalizeImmutableData({ baseObject, pathing }) {
  const valueByPath = baseObject.getIn(pathing);
  // we either get back an immutable or not. Handle.
  return isImmutable(valueByPath) ? valueByPath.toJS() : valueByPath;
}

function normalizeData({ baseObject, pathing }) {
  return isImmutable(baseObject)
    ? normalizeImmutableData({ baseObject, pathing })
    : get(baseObject, pathing.join('.'));
}

/**
 * As cobranded gets built out, there might be something other than images (css, video).
 * Lets make sure we are using the proper asset.
 * @param partnerLogo
 */
function isProperLogoDisplay(partnerLogo) {
  if (
    [partnerLogo.mediaType, partnerLogo.media_type].includes('image') &&
    partnerLogo.category === 'cobranded_logo'
  ) {
    return true;
  }

  return false;
}

export function createCobrandedImagesObj({ cobrandImages = [], partnerName = '' }) {
  if (!cobrandImages.length) return null;
  return cobrandImages.reduce(
    (logoObject, partnerLogo) => {
      const cobrandImagesObject = logoObject;
      if (isProperLogoDisplay(partnerLogo)) {
        cobrandImagesObject[partnerLogo.resolution] = partnerLogo.original;
        cobrandImagesObject.alt = `${partnerName} products & services`;
      }

      return cobrandImagesObject;
    },
    {
      mobile: '',
      desktop: '',
      alt: '',
    },
  );
}

/**
 * This facilitates IF we get passed the state w/ entities not within an immutable context.
 * @param state
 * @param key
 */
function withinReduxStateEntities(state, key) {
  return get(state, `entities.${key}`);
}

/**
 * These selectors are broken out to assume the following.
 * 1. We are getting redux state?
 *   i. Yes immutable? Current selectors assume this. So we go with it.
 *   ii. No  immutable? Usually means the user is passing in an object reprenting their need.
 *       a. They are passing in the object directly ie.. cart
 *       b. They are passing in the full state w/ entities
 * @param key
 */
export const partnerKeyByCartSelector = (key) =>
  createSelector(
    (state) =>
      isImmutable(state)
        ? cartSelector(state)
        : get(state, 'cart', withinReduxStateEntities(state, 'cart')),
    (cart = {}) =>
      normalizeData({
        baseObject: cart,
        pathing: ['partner', key],
      }),
  );

export const partnerKeyByCartPlanSelector = (key) =>
  createSelector(
    (state) =>
      isImmutable(state)
        ? cartSelector(state)
        : get(state, 'cart', withinReduxStateEntities(state, 'cart')),
    (cart = {}) =>
      normalizeData({
        baseObject: cart,
        pathing: ['plan', 'partner', key],
      }),
  );

export const partnerKeyBySkusSelector = (key) =>
  createSelector(
    (state) =>
      isImmutable(state)
        ? skusSelector(state)
        : get(state, 'skus', withinReduxStateEntities(state, 'skus')),
    (skus = []) =>
      normalizeData({
        baseObject: skus,
        pathing: [0, 'partner', key],
      }),
  );

export const partnerKeyBySkuIdSelector = (skuId = null) => (key) =>
  createSelector(
    (state) =>
      isImmutable(state)
        ? skusSelector(state)
        : get(state, 'skus', withinReduxStateEntities(state, 'skus')),
    (skus = []) =>
      skuId
        ? normalizeData({
            baseObject: skus,
            pathing: [skuId, 'partner', key],
          })
        : null,
  );

export const partnerKeyByNewItemSelector = (key) =>
  createSelector(
    (state) => (isImmutable(state) ? newItemSelector(state) : get(state, 'newItem')),
    (newItem) =>
      normalizeData({
        baseObject: newItem,
        pathing: ['partner', key],
      }),
  );

export const partnerKeyByNewItemWithSkuIdSelector = (skuId = null) => (key) =>
  createSelector(
    (state) => (isImmutable(state) ? newItemSelector(state) : get(state, 'newItem')),
    (newItem) => {
      const normalizedNewItem = immutableToJS(newItem);
      return get(normalizedNewItem, 'skuId') === skuId
        ? normalizeData({
            baseObject: newItem,
            pathing: ['partner', key],
          })
        : null;
    },
  );

export const partnerKeyByLayoutHeaderSelector = (key) =>
  createSelector(
    [
      (state) => (isImmutable(state) ? layoutHeaderSelector(state) : get(state, 'layout.header')),
      (state) =>
        isImmutable(state) ? partnersStateSelector(state) : get(state, 'entities.partners'),
    ],
    (layoutHeader, partnersInfo) => {
      const partnerKey = isImmutable(layoutHeader)
        ? layoutHeader.get('partnerKey')
        : get(layoutHeader, 'partnerKey');
      return normalizeData({
        baseObject: partnersInfo,
        pathing: [partnerKey, key],
      });
    },
  );

/**
 * This gets at the partner cobrand image in the workflow object, standalone (not in cart).
 * @param key
 * @returns {OutputSelector<unknown, *, (res: *) => *>}
 */
export const partnerKeyByWorkflowSelector = (key) =>
  createSelector(
    (state) => {
      const stateJS = state.toJS();
      return get(stateJS, 'entities.workflow');
    },
    (workflow) =>
      normalizeData({
        baseObject: workflow,
        pathing: ['partner', key],
      }),
  );

export const partnerNameByAllAvenuesSelector = createSelector(
  partnerKeyByCartSelector(PARTNER_NAME),
  partnerKeyBySkusSelector(PARTNER_NAME),
  partnerKeyByNewItemSelector(PARTNER_NAME),
  (...selectorPartnerName) => selectorPartnerName.find(Boolean),
);

export const partnerCoBrandImagesAllAvenuesSelector = createSelector(
  partnerNameByAllAvenuesSelector,
  partnerKeyByCartSelector(PARTNER_LOGOS),
  partnerKeyBySkusSelector(PARTNER_LOGOS),
  partnerKeyByNewItemSelector(PARTNER_LOGOS),
  partnerKeyByCartPlanSelector(PARTNER_LOGOS),
  partnerKeyByLayoutHeaderSelector(PARTNER_LOGOS),
  (partnerName, ...selectorCoBrandImages) => {
    const cobrandImages = selectorCoBrandImages.find((partnerLogoArray) => {
      return partnerLogoArray && partnerLogoArray.length;
    });

    if (!cobrandImages) return null;
    return createCobrandedImagesObj({ cobrandImages, partnerName });
  },
);

export const partnerCoBrandExistsAllAvenuesSelector = createSelector(
  partnerKeyByCartSelector(PARTNER_LOGOS),
  partnerKeyBySkusSelector(PARTNER_LOGOS),
  partnerKeyByNewItemSelector(PARTNER_LOGOS),
  partnerKeyByCartPlanSelector(PARTNER_LOGOS),
  partnerKeyByLayoutHeaderSelector(PARTNER_LOGOS),
  (...selectorCoBrandImages) => {
    return selectorCoBrandImages.find((imgArray) => {
      return imgArray && imgArray.length;
    });
  },
);
