import { getScreenSizes } from 'src/utils/ui';
import BREAKPOINTS from 'src/components/HTKit/Grid/constants';

export const getScoredResults = ({ results }) => {
  const filteredResults = results.filter((result) => {
    // eslint-disable-next-line no-underscore-dangle
    if (result._meta.score > 0.2) return result;
    return null;
  });
  return filteredResults;
};

export const getDevice = () => {
  const { width: windowWidth } = getScreenSizes();
  if (windowWidth < BREAKPOINTS.MD_GRID) {
    return 'mobile';
  }
  if (windowWidth <= BREAKPOINTS.LG_GRID && windowWidth > BREAKPOINTS.MD_GRID) {
    return 'tablet';
  }
  return 'desktop';
};
