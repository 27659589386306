import {
  GET_RATINGS_AND_NUM_SERVICES,
  SET_RATINGS_AND_NUM_SERVICES,
  HOME_PAGE_CLICK_SEGMENT,
  REMOVE_PARTNER_EFFECTS,
} from './constants';

export function getRatingsAndNumServices() {
  return { type: GET_RATINGS_AND_NUM_SERVICES };
}

export function setRatingsAndNumServices(stats) {
  return { type: SET_RATINGS_AND_NUM_SERVICES, stats };
}

export function removePartnerEffects() {
  return {
    type: REMOVE_PARTNER_EFFECTS,
  };
}

const homePageClickSegment = () => {
  return {
    type: HOME_PAGE_CLICK_SEGMENT,
  };
};

export default homePageClickSegment;
