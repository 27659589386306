import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { CREDIT_LABEL } from 'src/constants/credit';
import OrderBreakdownLineItem from './OrderBreakdownLineItem';

const CreditAppliedLineItem = ({
  name = CREDIT_LABEL,
  amount,
  className,
  noBottomMargin = false,
}) => {
  if (!amount) return null;
  const lineItemStyles = cn(
    {
      'marginBottom-small': !noBottomMargin,
    },
    className,
  );

  return <OrderBreakdownLineItem name={name} amount={amount} className={lineItemStyles} success />;
};

CreditAppliedLineItem.propTypes = {
  name: PropTypes.string,
  /**
   * Formatted amount to display
   */
  amount: PropTypes.string,
  className: PropTypes.string,
  noBottomMargin: PropTypes.bool,
};

export default CreditAppliedLineItem;
