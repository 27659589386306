// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Constants
import {
  VIRTUAL_SERVICE_HEADER,
  VIRTUAL_SERVICE_NOTICE,
  VIRTUAL_SERVICE_HEADER_IMAGE,
} from './constants';
// Styles
import styles from './styles.scss';

const VirtualServiceNotice = ({ header, text, image }) => (
  <div className={styles.virtualService}>
    <p className={cn(styles.header, 'p0')}>
      <img alt={image.altText} src={image.imgSrc} className={styles.image} />
      {header}
    </p>
    <p className={cn(styles.text, 'p2')}>{text}</p>
  </div>
);

VirtualServiceNotice.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.shape({
    imgSrc: PropTypes.isRequired,
    altText: PropTypes.isRequired,
  }),
};

VirtualServiceNotice.defaultProps = {
  header: VIRTUAL_SERVICE_HEADER,
  text: VIRTUAL_SERVICE_NOTICE,
  image: {
    imgSrc: VIRTUAL_SERVICE_HEADER_IMAGE.imgSrc,
    altText: VIRTUAL_SERVICE_HEADER_IMAGE.altText,
  },
};

export default React.memo(VirtualServiceNotice);
