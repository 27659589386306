// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid } from 'src/components/HTKit/Grid';
// Logos
import walmartLogo from 'src/images/branding/walmart-header-logo.svg';
import HTLogo from 'src/images/HT_logo-dark-blue.svg';
// Styles
import { homePath } from 'src/utils/paths';
import styles from './styles.scss';

const WalmartHeader = ({ header }) => {
  return (
    <Grid.FullWidth classes={styles.grid}>
      <Grid.Row classes={styles.row}>
        <Grid.Column sm={2} md={4} lg={6} classes="flex justifyContentFlexEnd alignItemsCenter">
          {header.get('actionableLogo') ? (
            <Link to={homePath()} className={styles.linkStyle}>
              <img src={walmartLogo} className={styles.walmartLogo} alt="walmart Logo" />
            </Link>
          ) : (
            <img src={walmartLogo} className={styles.walmartLogo} alt="walmart Logo" />
          )}
        </Grid.Column>
        <Grid.Column sm={2} md={4} lg={6} classes={styles.htLogoContainer}>
          {header.get('actionableLogo') ? (
            <Link to={homePath()} className={styles.linkStyle}>
              <img src={HTLogo} className={styles.htLogo} alt="HelloTech Logo" />
            </Link>
          ) : (
            <img src={HTLogo} className={styles.htLogo} alt="HelloTech Logo" />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid.FullWidth>
  );
};

function mapStateToProps(state) {
  return {
    header: state.getIn(['layout', 'header']),
  };
}

WalmartHeader.propTypes = {
  header: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(WalmartHeader);

/*
  WalmartHeader:
    When Walmart orders are received through API the client is sent an email to set a password
    and setup an appointment. Per our agreement with Walmart the pages have to be Walmart/HelloTech
    branded. This header is applied for those situations.

  Examples:
    <WalmartHeader />

  Properties:
    backgroundColor:
      type: string
      description: Used to set the background-color CSS property

  Last modified: -SL Nov 5, 2017
*/
