import React from 'react';
import PropTypes from 'prop-types';
import shield from 'src/images/HT-guarantee.svg';
import styles from './styles.scss';

const Guarantee = (props) => {
  const { containerStyle, imageStyle, sloganStyle } = props;
  return (
    <div className={containerStyle}>
      <div className={styles.container}>
        <img src={shield} alt="HelloTech Guarantee" className={`${styles.image} ${imageStyle}`} />
        <p className={`${styles.slogan} ${sloganStyle}`}>We&apos;re committed to your complete satisfaction with our service.  If you&apos;re not 100% happy, we&apos;ll do whatever it takes to make it right.</p>
      </div>
    </div>
  );
};

Guarantee.defaultProps = {
  containerStyle: '',
  imageStyle: '',
  sloganStyle: '',
};

Guarantee.propTypes = {
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  imageStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  sloganStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Guarantee;
