import {
  accountPath,
  accountScoreCardPath,
  accountDevicesPath,
  accountProfilePath,
  getOrderStatusPath,
} from 'src/utils/paths';

export const TOGGLE_TOPNAV = 'topNavHeader/TOGGLE_TOPNAV';
export const SERVICES_MENU = 'topNavHeader/SERVICES_MENU';
export const PRODUCT_MENU = 'topNavHeader/PRODUCT_MENU';

export const PROFILE_MENU_ACTIONS = { GET_STATUS: 'getStatus', SIGN_IN: 'signIn' };

export const LOGGED_IN_MENU = [
  {
    name: 'Orders',
    link: accountPath(),
  },
  {
    name: 'Home Scorecard',
    link: accountScoreCardPath(),
  },
  {
    name: 'Devices',
    link: accountDevicesPath(),
  },
  {
    name: 'My Account',
    link: accountProfilePath(),
  },
  {
    name: 'Sign Out',
  },
];

export const LOGGED_OUT_MENU = [
  { name: 'Track My Order', link: getOrderStatusPath() },
  { name: 'Sign In', action: PROFILE_MENU_ACTIONS.SIGN_IN },
];

export const SKU_CATEGORY_IDS = {
  COMPUTER_SUPPORT: 1,
  WIFI_NETWORK: 2,
  TV_MOUNTING: 7,
  MOBILE_TABLETS: 3,
  AUDIO_VIDEO: 4,
  SMART_HOME: 8,
  FEATURED_BRANDS: 999,
  HOME_OFFICE_SETUP: 26,
  VIRTUAL_SERVICES: 27,
  HOME_SECURITY: 40,
};

export const PRODUCTS_CATEGORY_IDS = {
  FEATURED_PRODUCTS: 28,
  SMART_THERMOSTAT: 29,
  VIDEO_DOORBELL: 30,
  SMOKE_ALARM: 31,
  SMART_GARAGE_DOOR_OPENER: 32,
  SMART_HUB: 33,
  MESH_NETWORK: 34,
  SMART_CAMERA: 35,
  TV_MOUNT: 36,
  SMART_LOCK: 10,
  HOME_THEATER: 14
};

export const TRANSPARENT_THEMES = {
  CLASSIC: 'transparentClassic',
  INVERTED: 'transparentInverted',
};
