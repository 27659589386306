import { call, put, takeEvery, select } from 'redux-saga/effects';
import { showFirstServiceDiscountDelayedModal } from 'src/components/HTKit/Modals/FirstServiceDiscount/actions';
import { LOAD_PAGE } from '../../constants/common';
import { pageLoadingError, pageLoaded } from '../../actions/common';
import { selectRoutes } from '../../apiRoutes';

export function* pageSaga() {
  const routes = yield call(selectRoutes);
  let reviews = yield select((state) => state.getIn(['entities', 'reviews', 'home']));
  if (!reviews) {
    const requestResult = yield call(routes.reviews.list);
    if (!requestResult.err) {
      reviews = requestResult.data.reviews;
    } else {
      yield put(pageLoadingError('geekSquad', requestResult));
      return;
    }
  }
  yield put(pageLoaded('geekSquad', { reviews }));
  yield put(showFirstServiceDiscountDelayedModal());
}

export function* pageFlow() {
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === 'geekSquad', pageSaga);
}

export default [
  pageFlow,
];
