// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

// Components
import Grid from 'src/components/HTKit/Grid/Grid';
import Carousel from 'src/components/Elements/Carousel';

// Helpers & constants
import { searchResultsClickedSegment } from 'src/components/Searchbar/actions';
import BREAKPOINTS from 'src/components/HTKit/Grid/constants';
import { PROMO_DETAILS } from '../constants';

// Styles
import styles from '../styles.scss';

const PromoBlock = ({ className, title, text, image, paddedImage, link, onClick }) => {
  const rowStyles = cn(className, styles.rowWrapper, {
    'padding-small2': paddedImage,
    'paddingX-medium': !paddedImage,
    [styles.rowSingleBlock]: PROMO_DETAILS.length === 1,
  });

  const imageStyles = cn(styles.image, {
    'paddingTop-small': paddedImage,
  });

  return (
    <Link to={link} onClick={onClick} className={rowStyles}>
      <div className={styles.imageColumn}>
        <img className={imageStyles} src={image} />
      </div>
      <div className="paddingLeft-small">
        <h5 className={styles.title}>{title}</h5>
        {text && <p className="p1">{text}</p>}
      </div>
    </Link>
  );
};

const PromoBlocks = ({ iconClick, searchTerm }) => {
  const PromoBlockMapped = PROMO_DETAILS.map((promo) => {
    const dispatch = useDispatch();
    const onClick = () => {
      dispatch(searchResultsClickedSegment({ searchTerm, clickedItem: "plan promo", attributes: null }));
      iconClick();
    };
    return (
      <PromoBlock
        key={promo.id}
        title={promo.title}
        text={promo.text}
        image={promo.image}
        paddedImage={promo.paddedImage}
        link={promo.link}
        onClick={onClick}
      />
    );
  });

  return (
    <Grid.FullWidth>
      <div className={styles.mobile}>
        <Carousel
          equalHeight
          slidesToScroll={1}
          infinite={true}
          resolutions={{
            [BREAKPOINTS.MD_GRID]: {
              variableWidth: true,
              slidesToShow: 1,
            },
          }}
        >
          {PromoBlockMapped}
        </Carousel>
      </div>
      <div className={styles.desktop}>{PromoBlockMapped}</div>
    </Grid.FullWidth>
  );
};

PromoBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  paddedImage: PropTypes.bool,
  link: PropTypes.func,
  onClick: PropTypes.func,
};

PromoBlocks.propTypes = {
  iconClick: PropTypes.func,
  searchTerm: PropTypes.string,
};

export default PromoBlocks;
