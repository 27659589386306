import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
// Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
// Utils
import { push, resetPasswordPath, clientsRegistrationPath } from 'src/utils/paths';
import { getRailsHost } from 'src/utils/user';
import { purePageSelector } from 'src/selectors/page';
import { loginUser } from './actions';
// Components && Styles
import { Link } from 'react-router-dom';
import InputFieldV2 from 'HTKit/Forms/InputFieldV2';
import Button, { THEMES as BUTTON_THEMES } from 'HTKit/Forms/Button';
import Grid from 'HTKit/Grid/Grid';
import Form from 'HTKit/Forms/Form';
import Notice from 'src/components/Notice';
import styles from './styles.scss';

const LOGIN_PAGE_NAME = 'login';

export const LoginForm = ({ initialEmail }) => {
  const dispatch = useDispatch();
  const skipRedirect = useSelector((state) =>
    state.getIn(['components', 'loginModal', 'skipRedirect'], false),
  );
  // We need page here because BE network errors are set on the redux store
  const page = useSelector(purePageSelector(LOGIN_PAGE_NAME));

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      email: initialEmail || '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Enter a valid email address.')
        .required("Email can't be blank."),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, skipRedirect));
    },
  });

  const getInteralLoginPath = () => `${getRailsHost()}/internal/login`;
  const handleLinkClick = (path) => (e) => {
    e.preventDefault();
    dispatch(push(path));
  };

  return (
    <Grid.Fluid classes={`site-v2 paddingY-medium1 ${styles.page}`}>
      <Grid.Row>
        <Grid.Column sm={4} mdOffset={1} md={6} lgOffset={3} lg={6}>
          <div className="flex justifyContentSpaceBetween alignItemsCenter">
            <h1>Sign In</h1>
            <a href={getInteralLoginPath()} className="medium-link text-align-right">
              Sign in as Tech/Partner
            </a>
          </div>
          <Notice page="login" />
          <Form classes="paddingTop-small2" onSubmit={formik.handleSubmit}>
            <Form.Row>
              <Form.Column>
                <InputFieldV2
                  type="email"
                  label="Email Address"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={page.errors.email || formik.errors.email}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <InputFieldV2
                  label="Password"
                  type="password"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={page.errors.password || formik.errors.password}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <Button theme={BUTTON_THEMES.V2PRIMARY} type="submit">
                  Sign In
                </Button>
              </Form.Column>
            </Form.Row>
          </Form>
          <div className="marginTop-small2 flex justifyContentSpaceBetween">
            <span>
              <a
                href={resetPasswordPath()}
                onClick={handleLinkClick(resetPasswordPath())}
                className="medium-link text-weight-bold"
              >
                Forgot password?
              </a>
            </span>
            <span>
              <Link
                to={clientsRegistrationPath()}
                onClick={handleLinkClick(clientsRegistrationPath())}
                className="medium-link text-weight-bold"
              >
                Register
              </Link>
            </span>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid.Fluid>
  );
};

LoginForm.propTypes = {
  initialEmail: PropTypes.string,
};
