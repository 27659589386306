import { createSelector, createStructuredSelector } from 'reselect';
import { skuCategoriesAndSkusJSSelector } from 'src/selectors/routesSchema';
import { purePageSelector as selectPage } from 'src/selectors/page';
import { brandingThemeSelector } from 'src/selectors/branding';
import { WALMART_THEME } from 'src/utils/branding/constants';
import componentSelector from 'src/selectors/component';
import { PAGE_NAME, SKU_CATEGORY_ICONS } from './constants';

const homePageSelector = selectPage(PAGE_NAME);

const replaceIcons = (categories = []) =>
  categories.map(({ id, name, link }) => {
    const { icon = '', listOrder = '', badgeText } =
      SKU_CATEGORY_ICONS.find((replacement) => id === replacement.categoryId) || {};
    return { id, label: name, link, icon, listOrder, badgeText };
  });

const filteredSkuCategoriesSelector = createSelector(
  /*
    Get existing sku categories and replace the icons, and then sort them.
    If the category doesn't have a link or an icon, it gets rejected. Link and icon properties are set on the backend
  */
  /*
    TODO: If/when this becomes the permanent home page we should look into replacing the icon saved in the DB
          to avoid having to manually replace the icons here.
  */
  skuCategoriesAndSkusJSSelector,
  (categoriesAndSkus) => {
    const targetCategories = categoriesAndSkus.filter((category) => category.link && category.id);

    const categoriesWithIcons = replaceIcons(targetCategories);
    return categoriesWithIcons.sort((first, second) => first.listOrder - second.listOrder);
  },
);

const homePageReviewsSelector = (state) => state.getIn(['entities', 'reviews', 'home']);
const reviewsSelector = createSelector(
  homePageReviewsSelector,
  (reviews) => reviews && reviews.toJS(),
);

const servicesCompletedSelector = createSelector(
  homePageSelector,
  (page) => page.stats && page.stats.completedServicesCount,
);
const ratingSelector = createSelector(
  homePageSelector,
  (page) => page.stats && page.stats.averageReviewsCount,
);

const themesToHideMembershipPanel = [WALMART_THEME];
export const hideMembershipSelector = createSelector(brandingThemeSelector, (theme) =>
  themesToHideMembershipPanel.includes(theme),
);

const isTopNavOpenSelector = createSelector(componentSelector('topNav'), (topNav) => {
  return topNav.get('open');
});

export default createStructuredSelector({
  rating: ratingSelector,
  reviews: reviewsSelector,
  servicesCompleted: servicesCompletedSelector,
  skuCategories: filteredSkuCategoriesSelector,
  hideMemberShip: hideMembershipSelector,
  isTopNavOpen: isTopNavOpenSelector,
});
