export const OPEN_MODAL = 'loginModal/OPEN_MODAL';
export const CLOSE_MODAL = 'loginModal/CLOSE_MODAL';
export const OPEN_PASSCODE_MODAL = 'loginModal/OPEN_PASSCODE_MODAL';
export const CLOSE_PASSCODE_MODAL = 'loginModal/CLOSE_PASSCODE_MODAL';
export const SET_USER_INFO = 'loginModal/SET_USER_INFO';
export const SET_LOGIN_PASSCODE_ERROR = 'loginModal/SET_LOGIN_PASSCODE_ERROR';

export const LOGIN_MODAL_OPTIONS = {
  IS_PASSWORDLESS: 'isPasswordless',
};

export const AUTO_SUBMIT_DEBOUNCE_TIMEOUT_MS = 750;

export const NUM_PASSCODE_DIGITS = 6;

export const RESEND_COOLDOWN_MS = 15000;
