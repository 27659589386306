import get from 'lodash/get';
import { pathnameFromString } from 'src/utils/validation';

/*
  Helper for tracking and redirect
*/
const trackAndRedirect = (props, segmentAttributes, redirect) => {
  props.push(redirect);
};

const messagePageActions = (props, attributes) => {
  return {
    anchorScrollBy() {
      window.scrollTo(0, attributes.message);
    },
    /**
     * This method was created to fire a Segment event, and then push to new pathing.
     *
     * Note: Pathing needs to be full url (webflow requires this), so we will chomp off what
     * we need and forward. Also, there is NO 'segment' SLA so, at this point, we just
     * consume the 'event' and 'action' on it, BUT we'd need to update at some point based on
     * segment conventions communicating to the iframe. Ie, does iframe pass us the full meta object,
     * or do we just get an event, and we build the meta object.
     *
     * Iframe calls this method.
     * Usage:
     * postMessageToParent(
     *   { type: 'genericLinkAndTrackClick', message: {redirectUrl: string, segmennt: {event: string} }}
     * )
     * */
    genericLinkAndTrackClick() {
      /* Patrick has to pass full urls. But we have to redirect on the environment level */
      const pathName = pathnameFromString(get(attributes, 'message.redirectUrl', ''));
      const segmentAttributes = get(attributes, 'message.segment', {});

      trackAndRedirect(props, segmentAttributes, pathName);
    },
  };
};

export { messagePageActions };
