import { createSelector } from 'reselect';
import get from 'lodash/get';
import { newItemSelector } from 'src/containers/AddSkuPage/selectors';
import { QUESTION_TYPES } from 'src/containers/AddSkuPage/constants';
import { cartSelector } from 'src/selectors/cart';
import { pureWorkflowAllStateJSSelector, pureWorkflowJSSelector } from 'src/selectors/workflow';

export const questionsStateSelector = (state) => state.getIn(['entities', 'questions'], null);
export const questionStateSelector = (questionId) => (state) =>
  state.getIn(['entities', 'questions', `${questionId}`], null);

export const questionsSelector = createSelector(questionsStateSelector, (questions) => questions);

/**
 * Returns the immutable question object for the given questionId.
 * @param questionId
 * @returns {OutputSelector<unknown, *, (res: *) => *>}
 */
export const questionSelector = (questionId) =>
  createSelector(questionStateSelector(questionId), (question) => question);

/**
 * Returns the plain JS object for the given questionId.
 * @param questionId
 * @returns {OutputSelector<unknown, *|null, (res: *) => (*|null)>}
 */
export const questionJSSelector = (questionId) =>
  createSelector(questionStateSelector(questionId), (question) => {
    return question ? question.toJS() : null;
  });

/**
 * Returns an object where each key is a group name and each value is an array of questions in that group.
 * The group is determined by skuQuestion.group. The returned questions can either be in their original
 * immutable state or converted to plain JS objects based on the asJS parameter.
 */
export const questionsByGroupSelector = ({ skuId, asJS = false }) =>
  createSelector(questionStateSelector(skuId), (questions) => {
    if (!questions) return {};
    return questions.reduce((acc, question) => {
      const groupName = question.get('group') || 'none';
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(asJS ? question.toJS() : question);
      return acc;
    }, {});
  });


/**
 * Return the matched data set of the users selection.
 * Used on the EV Quote Page.
 * Used when user interactions are within the scope of the form.
 *
 * The users answers sit in one object. The questions to map the answers sit in another.
 *
 * @type {OutputSelector<unknown, unknown, (res: *) => unknown>}
 */
export const pureDeviceQuestionSelector = createSelector(
  [newItemSelector, questionsStateSelector],
  (newItem, questions) => {
    if (!newItem || !questions) return null;

    /* Change to pure js */
    const pureNewItem = newItem?.toJS();
    const pureQuestions = questions?.toJS();

    /* Base extracted objects from each */
    const newItemId = pureNewItem?.skuId;
    const questionSet = pureQuestions?.[newItemId];

    /* Get associated question set for device */
    const device = questionSet?.find(qs => qs.inputType === QUESTION_TYPES.DEVICE) || {};
    const deviceId = device.id;

    /* Get the corresponding newItem questions (which has the answers selected by user) */
    const newItemDeviceQuestionAnswerModel = get(pureNewItem, `questions.${deviceId}.model`) || "";
    const newItemDeviceQuestionAnswerMake = get(pureNewItem, `questions.${deviceId}.make`, get(pureNewItem, `questions.${deviceId}.answerValue`)) || "";
    /* Get the Selected Model Device info. This is base data associated with the users selection */
    const answers = get(device, 'answers', []).find(d => d.id === newItemDeviceQuestionAnswerMake);
    /* If we have an "other" selection, there will be no matches. */
    const selectedModel = get(answers, 'productQuestion.answers', []).find(ans => {
      // eslint-disable-next-line eqeqeq
      return ans.id == newItemDeviceQuestionAnswerModel
    }) || {};

    /*
      Formulate the base name: We want to use both the Make + Model to be in the name.
      We can either have an id derived name (from mapped between objects) or a user 'other' input.
    */
    const baseName = [get(answers, 'text', newItemDeviceQuestionAnswerMake), get(selectedModel, 'name', newItemDeviceQuestionAnswerModel)];

    return {
      ...selectedModel,
      name: baseName.filter(Boolean).join(' '),
      complete: newItemDeviceQuestionAnswerModel && newItemDeviceQuestionAnswerMake,
      nonEditable: device.nonEditable
    } ;
  });


/**
 * Return the matched data set of the users selection on portal page. Have no concept of newItem here.
 * This Gets the sku id from workflow, matches in cart. Then matches the question set to get the users answers.
 *
 * @type {OutputSelector<unknown, unknown, (res: *) => unknown>}
 */
export const pureDeviceQuestionPortalSelector = (options = {}) => createSelector(
  [cartSelector, pureWorkflowJSSelector, questionsStateSelector],
  (cart, workflow, questions) => {
    if (!workflow || !cart || !questions) return null;

    /* Get out of immutable */
    const pureCart = cart?.toJS();
    const pureQuestions = questions?.toJS();

    /* Get the workflow installation ID, so we can match the questions/cart items */
    const installationSkuID = get(workflow, 'steps.installation.sku_ids.0', '');

    /* Get the item from the cart by skuid */
    const cartItem = pureCart?.items.find(item => item.skuId === installationSkuID);

    /* Get the question associated with the sku */
    const questionSet = get(pureQuestions, installationSkuID, []);

    /* Get associated question set for the device */
    const device = questionSet?.find(qs => qs.inputType === QUESTION_TYPES.DEVICE) || {};
    const deviceId = device.id;

    /* Get the corresponding newItem questions (which has the answers selected by user) */
    const newItemDeviceQuestionAnswerModel = get(cartItem, `questions.${deviceId}.model`) || "";
    const newItemDeviceQuestionAnswerMake = get(cartItem, `questions.${deviceId}.make`, get(cartItem, `questions.${deviceId}.answerValue`)) || "";

    /* Get the Selected Model Device info. This is base data associated with the users selection */
    const answers = get(device, 'answers', []).find(d => d.id === newItemDeviceQuestionAnswerMake);

    const selectedModel = get(answers, 'productQuestion.answers', []).find(ans => {
      // eslint-disable-next-line eqeqeq
      return ans.id == newItemDeviceQuestionAnswerModel
    }) || {};

      /*
        Formulate the base name: We want to use both the Make + Model to be in the name.
        We can either have an id derived name (from mapped between objects) or a user 'other' input.
     */
    const baseName = [get(answers, 'text', newItemDeviceQuestionAnswerMake), get(selectedModel, 'name', newItemDeviceQuestionAnswerModel)];

    return {
      ...selectedModel,
      name: baseName.filter(Boolean).join(' '),
      nonEditable: options.suppressEdit ? true : device.nonEditable
    } ;
  });

/**
 * Lets get the default device attributes for the sku. We do this to preseed the device question with "set" defaults
 * by the admin tool. Used currently on the ev QA page.
 *
 * @param options
 * @returns {OutputSelector<unknown, unknown, (res: *) => unknown>}
 */
export const getDeviceDefaultAttributes = (options) => createSelector(questionsStateSelector, (questions) => {
  const skuId = options?.skuId;

  if (!skuId || !questions) return null;

  /* get out of immutable */
  const pureQuestions = questions?.toJS();

  /* Get the question associated with the sku */
  const questionSet = get(pureQuestions, skuId, []);

  /* Get the data for device */
  const device = questionSet?.find(qs => qs.inputType === QUESTION_TYPES.DEVICE) || {};

  return device;
})


/**
 * This is needed on the quote page as a user can interact with the device question "outside or within the scope for the form".
 * As such, we need to be able to get the previous saved data from the cart.
 *
 * This returns the users selected answers for the device question.
 *
 * @param options
 * @returns {OutputSelector<unknown, unknown, (res: *) => unknown>}
 */
export const getDeviceSavedAttributes = createSelector([cartSelector, pureWorkflowAllStateJSSelector, questionsStateSelector], (cart, workflow, questions) => {
  /* Pure js the cart */
  const pureCart = cart?.toJS();
  const pureQuestions = questions?.toJS();

  /* Get the workflow installation ID, so we can match the questions/cart items */
  const installationSkuID = get(workflow, 'steps.installation.skuIds.0', '');
  /* Get the question associated with the sku */
  const questionSet = get(pureQuestions, installationSkuID, []);

  /* Get associated question set for device */
  const device = questionSet?.find(qs => qs.inputType === QUESTION_TYPES.DEVICE) || {};
  const deviceId = device.id;

  /* Get the item from the cart by skuid */
  const cartItem = pureCart?.items.find(item => item.skuId === installationSkuID);

  /* Get the saved answers by matching it with the question id for the device */
  const deviceAnswers = get(cartItem, `questions.${deviceId}`) || {};
  return {
    ...deviceAnswers,
    question: device,
  };
})
