import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'src/utils/paths';
import { layoutUpdate } from 'src/actions/common';
import { PARTNER_MULTIPLE_ACCOUNT_SKU_LAYOUT } from 'src/constants/common';
import MultipleAccountSku from './MultipleAccountSku';

class MultipleAccountSkuWrapper extends Component {
  static propTypes = {
    layoutUpdate: PropTypes.func.isRequired,
    isSmb: PropTypes.bool,
  };

  componentDidMount() {
    this.props.layoutUpdate(PARTNER_MULTIPLE_ACCOUNT_SKU_LAYOUT);
  }

  render() {
    return <MultipleAccountSku {...this.props} />;
  }
}

function mapStateToProps(state, ownProps) {
  const id = ownProps.landing.get('id');
  return {
    reviews: state.getIn(['entities', 'reviews', 'landings', id.toString()], null),
  };
}

export default connect(mapStateToProps, { layoutUpdate, push })(MultipleAccountSkuWrapper);
