import { useSelector } from 'react-redux';
import componentSelector from 'src/selectors/component';
import { brandingFirstCobrandLogoSelector } from 'src/selectors/branding';
import { currentLandingSelector } from 'src/selectors/landings';
import { landingPath } from 'src/utils/paths';
import { LANDING_TEMPLATES as LT } from 'src/constants/landings';
// import { MAIN, MONOCHROME } from 'src/utils/branding/constants';
import { getIsPartnerRelatedCookie } from 'src/utils/cookies';
import { PARTNERS } from 'src/constants/common';
import optimumLogo from 'src/images/branding/optimumLogo.svg';
import suddenlinkLogo from 'src/images/branding/suddenlinkLogo.svg';
import xfinityLogo from 'src/images/branding/xfinity-topnav-logo.svg';

export const resolveHomeUrl = ({ homeUrl, currentPartnerLanding }) => {
  let resolvedHomeUrl = homeUrl;
  /*
    If the client is on a partner landing page, then let's override
    the homeUrl that was initially set in the layout state.

    The utils in `src/utils/layouts` will handle the homeUrl on certain pages,
    but since some landing pages do not call resolveBookingPageLayout, we're
    overriding the home url here.
  */
  const seoName = currentPartnerLanding && currentPartnerLanding.get('seoName');
  if (seoName) {
    resolvedHomeUrl = landingPath(seoName);
  }

  return resolvedHomeUrl;
};

/**
 * The order of these statements matter.
 * Partner landing pages take precedent over all.
 * Branding themes come next.
 *
 * @returns {string} - url of logo source
 */
const resolvePartnerLogo = ({
  currentPartnerLanding,
  featuredTemplateHeader,
  brandingLogo,
  // topNavIsTransparent, // feature is not being used
}) => {
  const partnerTemplate = currentPartnerLanding && currentPartnerLanding.get('template');
  const isPartnerRelated = getIsPartnerRelatedCookie();
  const partnerLandingSeoName = currentPartnerLanding && currentPartnerLanding.get('seoName');

  if (partnerLandingSeoName === PARTNERS.XFINITY) {
    return xfinityLogo;
  }

  if (partnerTemplate) {
    if ([LT.OPTIMUM_MOVERS, LT.OPTIMUM_SUPPORT].includes(partnerTemplate)) {
      return optimumLogo;
    }
    if ([LT.SUDDENLINK_MOVERS, LT.SUDDENLINK_SUPPORT].includes(partnerTemplate)) {
      return suddenlinkLogo;
    }
  } else {
    if (featuredTemplateHeader && featuredTemplateHeader.get('logo')) {
      return featuredTemplateHeader.get('logo');
    }
    if (brandingLogo && !isPartnerRelated) {
      return brandingLogo;
    }
  }

  return null;
};

export const usePartnerLogoUrl = (opts = {}) => {
  const { topNavIsTransparent = false } = opts;
  const currentPartnerLanding = useSelector(currentLandingSelector);
  const featuredTemplateHeader = useSelector(componentSelector('featuredContentOrSkuHeader'));
  const brandingLogo = useSelector(brandingFirstCobrandLogoSelector);

  return resolvePartnerLogo({
    currentPartnerLanding,
    featuredTemplateHeader,
    brandingLogo,
    topNavIsTransparent,
  });
};
