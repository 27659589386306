import React from 'react';
import PropTypes from 'prop-types';
import RSelect from 'react-select';
import cn from 'classnames';
import Counter from 'src/utils/counter';
import Label from '../Parts/Label';
import SelectArrow from './SelectArrow';
import SelectOption from './SelectOption';
import SelectFormattedLabel from './SelectFormattedLabel';
import { prepareSelectGroups, prepareValue } from './utils';
import { SIZES, SELECT_FIELD_LABEL_ID } from '../constants';
import { OPTION_PROP_TYPE, SIZE_PROP_TYPE } from './constants';
import styles from './styles.scss';

const SelectEmpty = ({ emptyText = 'Nothing Found' }) => (
  <div className={styles.empty}>{emptyText}</div>
);

const Select = ({
  id,
  groups,
  options,
  invalid,
  error,
  theme,
  label,
  hint,
  className,
  size = SIZES.md,
  searchable = false,
  value = null,
  emptyText,
  relativeMenu = false,
  v2LabelStyle = false,
  ...rest
}) => {
  const fieldOptions = (groups ? prepareSelectGroups(groups) : options) || [];
  const selectStyles = cn(
    styles.select,
    { [styles.invalid]: error || invalid, [styles.searchable]: searchable },
    { [styles.relativeMenu]: relativeMenu },
    styles[size],
  );
  const fieldValue = prepareValue(value, fieldOptions);
  return (
    <div id={id} className={className}>
      {label && (
        <>
          {!v2LabelStyle ? (
            <div
              className={styles.label}
              dangerouslySetInnerHTML={{ __html: label }}
              id={SELECT_FIELD_LABEL_ID}
            />
          ) : (
            <Label dangerouslySetLabel={label} inputId={SELECT_FIELD_LABEL_ID} />
          )}
        </>
      )}
      {hint && <div className={styles.hint}>{hint}</div>}
      <div className={styles.field}>
        <RSelect
          {...rest}
          value={fieldValue}
          isSearchable={searchable}
          className={selectStyles}
          classNamePrefix="react-select"
          components={{
            Option: SelectOption,
            DropdownIndicator: SelectArrow,
            NoOptionsMessage: function NoOptionsMessage() {
              return <SelectEmpty emptyText={emptyText} />;
            },
          }}
          formatOptionLabel={SelectFormattedLabel}
          options={fieldOptions}
          instanceId={Counter.uniqueId('SelectField-')}
          menuBuffer={100}
          htSize={size}
        />
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </div>
  );
};

SelectEmpty.propTypes = {
  emptyText: PropTypes.string,
};

Select.propTypes = {
  emptyText: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  invalid: PropTypes.bool,
  error: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(OPTION_PROP_TYPE),
  groups: PropTypes.object,
  theme: PropTypes.string,
  id: PropTypes.string,
  size: SIZE_PROP_TYPE,
  searchable: PropTypes.bool,
  value: PropTypes.any,
  relativeMenu: PropTypes.bool,
  v2LabelStyle: PropTypes.bool,
};

export default Select;
