import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'src/components/HTKit/Icon';
import { useSelector } from 'react-redux';
import { standardAndSubscriptionCouponSelector } from 'src/selectors/cart';
import OrderBreakdownLineItem from './OrderBreakdownLineItem';
import styles from '../styles.scss';

const CouponAppliedLineItem = ({
  couponType,
  name,
  amount,
  onClick,
  hideIcon,
  className,
  noBottomMargin,
  forceCouponProp = false,
}) => {
  const couponsGroup = useSelector(standardAndSubscriptionCouponSelector);
  const derivedCoupon = couponsGroup?.[couponType] ? couponsGroup[couponType] : {};
  const suppressIcon = forceCouponProp ? hideIcon : hideIcon || derivedCoupon.autoApplied;

  const lineItemStyles = cn(
    {
      'marginBottom-small': !noBottomMargin,
    },
    className,
  );
  const nameComponent = (
    <div className={styles.couponNameContainer}>
      <span>{forceCouponProp ? name : derivedCoupon.name || name}</span>
      {!suppressIcon && <Icon name="v2-close-icon" className={styles.icon} onClick={onClick} />}
    </div>
  );
  return (
    <OrderBreakdownLineItem
      name={nameComponent}
      amount={forceCouponProp ? amount : derivedCoupon.amountFormatted || amount}
      className={lineItemStyles}
      success
    />
  );
};

CouponAppliedLineItem.propTypes = {
  name: PropTypes.string,
  amount: PropTypes.string,
  onClick: PropTypes.func,
  hideIcon: PropTypes.bool,
  className: PropTypes.string,
  noBottomMargin: PropTypes.bool,
  couponType: PropTypes.string,
  /**
   * Do not use the derived coupon, force the usage of props that were passed.
   * Use case: Estimated cart is shown to the user.
   */
  forceCouponProp: PropTypes.bool,
};

CouponAppliedLineItem.defaultProps = {
  onClick: () => {},
  hideIcon: false,
  noBottomMargin: false,
};

export default CouponAppliedLineItem;

/*
  CouponAppliedLineItem
    A variety of <OrderBreakdownLineItem/> with green text and a coupon name.
*/
