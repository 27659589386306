// Returns a number formatted in USD with $ dollar sign. 'digits' represents the digits after the decimal -GH Sep 5, 2019
// E.g. formatter(0).format(49.00) returns $49

export const formatter = (digits = 0) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
  });

export const numberWithComma = ({ num, maxDigits = 3 }) => {
  return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: maxDigits }).format(num);
};

// Find the services that are redeemable in the MemberBenefits section
export const findRedeemableServices = (includedServices) => {
  const redeemableServices = {
    audit: {},
    dashlane: {},
    malwarebytes: {},
  };

  includedServices.forEach((s) => {
    const serviceName = s.name.toLowerCase();
    if (
      serviceName.indexOf('home') !== -1 &&
      serviceName.indexOf('technology') !== -1 &&
      serviceName.indexOf('check') !== -1
    ) {
      redeemableServices.audit = s;
    }
    if (serviceName.indexOf('dashlane') !== -1) {
      redeemableServices.dashlane = s;
    }
    if (serviceName.indexOf('malwarebytes') !== -1) {
      redeemableServices.malwarebytes = s;
    }
  });
  return redeemableServices;
};

export const getRedeemableServiceButtonText = ({ service, redeemableServices }) => {
  if (!service || !redeemableServices) return null;
  if (!redeemableServices[service]) return null;
  return redeemableServices[service].redeemed ? 'Redeem' : 'View';
};
export const getAuditButtonText = (redeemed) => (redeemed ? 'See Scorecard' : 'Book Now');

/**
 * Only show included/redeemable service if it's included in the plan
 */
export const showRedeemableService = (services = {}) => (vendor) => {
  if (!Object.keys(services).length) return false;
  return Boolean(services[vendor] && services[vendor].id);
};
