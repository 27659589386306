import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { isDevelopment } from 'src/utils/env';
import { formatPriceTypeHourly } from 'src/containers/CartPage/utils';
import { useExceedMaxServicesWarningModal } from 'src/containers/ServicePage/ServicePage.hooks';
import { ModalV2, SIZES } from 'src/components/HTKit/Modals/ModalV2';
import { PanelV2 } from 'src/components/HTKit/Elements/PanelV2';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { Picture } from 'src/components/Elements/Picture';
import styles from './styles.scss';

const SkuItem = ({ sku, onClick = () => {}, buttonText = 'Book Now' }) => {
  const src = isDevelopment()
    ? 'https://picsum.photos/id/237/56/56'
    : sku.heroMobileImageUrl || sku.heroImageUrl;

  const startsAtPrice = formatPriceTypeHourly({
    service: sku,
    price: sku.startsAtPrice,
    withAsterik: false,
  });
  return (
    <PanelV2 noShadow className={cn(styles.panel, styles.exceedWarning)}>
      <div className={styles.exceedWarningContent}>
        <div className="flex alignItemsCenter">
          <Picture src={src} className={cn(styles.exceedWarningImage, 'marginRight-small')} />
          <div className="flex flexDirectionColumn">
            <p className="n900 h5 text-weight-med">{sku.name}</p>
            {sku.startsAtPrice && <p className="n900 p1">Starting at {startsAtPrice}</p>}
          </div>
        </div>
        <Button
          theme={THEMES.V2PRIMARY}
          onClick={onClick}
          className={cn(styles.button, styles.exceedWarningCta)}
        >
          {buttonText}
        </Button>
      </div>
    </PanelV2>
  );
};

const ExceedWarningSpacer = () => <div className={styles.exceedWarningSpacer} />;

export const ExceedMaxServicesWarningModal = ({
  isVisible,
  hide,
  onKeepServiceClick,
  onSelectNewService,
  existingSkuInCart,
  newSkuToAdd,
}) => {
  const { storedSkuDetails } = useExceedMaxServicesWarningModal({
    isVisible,
    skuId: existingSkuInCart?.skuId,
    // On fetch error, proceed with confirmation as this is a warning modal
    onFetchError: onSelectNewService,
  });

  if (!storedSkuDetails) return null;
  return (
    <ModalV2
      header="You can only book 1 service at a time"
      headerContainerClasses={styles.exceedHeaderContainer}
      size={SIZES.MEDIUM}
      isVisible={isVisible}
      hide={hide}
    >
      <p className="p1 marginTop-medium">
        Starting a new booking will discard your previous, in-progress booking. If you need multiple
        services, book each one separately.
      </p>
      <ExceedWarningSpacer />
      <p className="p0 text-weight-med marginBottom-small">Which service would you like to book?</p>
      <SkuItem sku={storedSkuDetails} onClick={onKeepServiceClick} buttonText="Finish Booking" />
      <SkuItem sku={newSkuToAdd} onClick={onSelectNewService} buttonText="Start New Booking" />
    </ModalV2>
  );
};

ExceedMaxServicesWarningModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  onKeepServiceClick: PropTypes.func.isRequired,
  onSelectNewService: PropTypes.func.isRequired,
  existingSkuInCart: PropTypes.object.isRequired,
  newSkuToAdd: PropTypes.object.isRequired,
};

SkuItem.propTypes = {
  sku: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};
