import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { chunk, flow } from 'lodash';
// Components && Styles
import { Grid } from 'HTKit/Grid';
import Review from 'src/components/Elements/Review';
import styles from './styles.scss';

const CustomerReviewsStacked = ({
  reviews = [],
  className,
  headerText = 'Reviews',
  headerStyles = '',
  itemStyles = '',
  suppressAddressLine = false,
  maxReviews = 6,
}, ref) => {
  if (!reviews.length) return null;

  const reviewsToShow = reviews.slice(0, maxReviews);
  const chunkedReviews = chunk(reviewsToShow, 3);

  return (
    <div className={className} ref={ref}>
      <Grid.FullWidth>
        <Grid.Row classes={styles.headerRow}>
          <Grid.Column sm={4} mdOffset={1} md={6} lgOffset={0} lg={12}>
            <h4 className={cn(styles.header, headerStyles)}>{headerText}</h4>
          </Grid.Column>
        </Grid.Row>
      </Grid.FullWidth>
      <Grid.FullWidth>
        {chunkedReviews.map((_reviews, chunkIndex) => {
          return (
            <Grid.Row key={`chunk-${chunkIndex}`} classes={styles.contentRow}>
              {_reviews.map((r, idx) => (
                <Grid.Column
                  sm={4}
                  mdOffset={1}
                  md={6}
                  lgOffset={0}
                  lg={4}
                  classes={styles.contentColumn}
                  key={`review-${idx}`}
                >
                  <Review
                    review={r}
                    className={cn(styles.item, itemStyles)}
                    suppressAddressLine={suppressAddressLine}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          );
        })}
      </Grid.FullWidth>
    </div>
  );
};

CustomerReviewsStacked.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerStyles: PropTypes.string,
  itemStyles: PropTypes.string,
  suppressAddressLine: PropTypes.bool,
  maxReviews: PropTypes.number,
};

export default flow(forwardRef)(CustomerReviewsStacked);
