// Libraries
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// Utils
import { push, directBookingTechProfilePath } from 'src/utils/paths';
// Components
import Grid from 'src/components/HTKit/Grid/Grid';
import BackButton from 'src/components/Elements/BackButton';
import { Picture, TYPES } from 'src/components/Elements/Picture';
import Avatar from 'src/components/HTKit/Elements/Avatar';
import blob from 'src/images/blobs/light-blue-blob.svg';
import TechRatingBadge from '../TechRatingBadge';
// Styles
import styles from './styles.scss';

const TechBanner = ({
  techInfo,
  techName = '',
  header = 'Book with',
  subText = '',
  showRating = true,
}) => {
  if (!techInfo || !Object.keys(techInfo).length) return null;
  const dispatch = useDispatch();
  const blobSources = [
    {
      srcSet: blob,
      type: TYPES.SVG,
    },
  ];
  const { id, profilePicture, rating } = techInfo;

  return (
    <div className={styles.outerConatiner}>
      <Picture sources={blobSources} className={styles.blob} />
      <Grid.Fluid classes="paddingTop-medium paddingBottom-small2">
        <Grid.Row classes={styles.profileRowContainer}>
          <Grid.Column md={1} lg={1} classes={cn('marginBottom-small1', styles.buttonContainer)}>
            <BackButton
              className={styles.backButton}
              onClick={() => dispatch(push(directBookingTechProfilePath(id)))}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row classes={cn('flex alignItemsCenter', styles.profileRowContainer)}>
          <Grid.Column sm={1} md={1} lg={1} classes={cn(styles.avatar, styles.columnContainer)}>
            <Avatar
              image={profilePicture && profilePicture.medium}
              className={styles.techImage}
              imageAlt={techName}
              name={techName}
            />
          </Grid.Column>
          <Grid.Column sm={3} md={4} lg={6} classes={cn(styles.techName, styles.columnContainer)}>
            <h4 className="paddingBottom-tiny1">
              {header} {techName}
            </h4>
            {subText && <p className="p1 n800">{subText}</p>}
            {showRating && !!rating && <TechRatingBadge rating={rating} />}
          </Grid.Column>
        </Grid.Row>
      </Grid.Fluid>
    </div>
  );
};

TechBanner.propTypes = {
  techInfo: PropTypes.object,
  techName: PropTypes.string,
  header: PropTypes.string,
  subText: PropTypes.string,
  showRating: PropTypes.bool,
};

export default TechBanner;
