import React from 'react';
import PropTypes from 'prop-types';
import { ENV } from 'src/constants/app';

// Layout and Components
import IframeView from 'src/components/Elements/IframeView';
import withLayout from 'src/hoc/withLayout';
import { DEFAULT_LAYOUT } from 'src/constants/common';

// Utils and Actions
import { useSplitIOTreatment, splitioConstants } from 'src/components/SplitIO';
import { messagePageActions } from './messagePageActions';

/**
 * Add your Product urls here
 */
const baseHost = process.env.NODE_ENV === ENV.PRODUCTION ? 'products' : 'productss';
const baseRedirectPath = `https://${baseHost}.hellotech.com/bundles`;

export const getIframeSource = (options = {}) => {
  const { version = '' } = options;
  const iframeSrc = `${baseRedirectPath}${version}`;
  return `${iframeSrc}`;
};

export const CALLER_PAGE = 'productspage';


const ProductBundlesPage = () => {
  const { splitConfig } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_PRODUCT_SHOP_VERSION_TEST,
  );

  const iframeSourceString = getIframeSource({
    version: splitConfig.path,
  });

  return (
    <IframeView
      callerPageName={CALLER_PAGE}
      src={iframeSourceString}
      additionalActions={messagePageActions}
    />
  );
};

ProductBundlesPage.propTypes = {
  src: PropTypes.string.isRequired,
};

export default withLayout(DEFAULT_LAYOUT)(ProductBundlesPage);
