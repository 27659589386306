import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'src/components/HTKit/Icon';
import classNames from 'classnames';
import { TEMPLATE_COMPONENTS } from '../constants';
import styles from './styles.scss';

const WRAPPER_BOTTOM_PAD = 8;
const BAR_TRANSITION_TIME = 400; // also change $bar-transition-time in styles.scss
const SUCCESS_REMOVAL_DELAY = 5000;

/**
 * ContentHandler is responsible for rendering the message inside the notification bar.
 * It either directly uses HTML content or utilizes a custom template based on the `template` prop.
 */
const ContentHandler = ({ content, template, templateData }) => {
  const copyStyles = classNames('p1', styles.copy);
  let component = <p className={copyStyles} dangerouslySetInnerHTML={{ __html: content }} />;
  if (template) {
    const CustomTemplate = TEMPLATE_COMPONENTS[template];
    component = <CustomTemplate copyStyles={copyStyles} templateData={templateData} />;
  }
  return component;
};

/**
 * Bar is the main component for displaying a notification or status bar.
 * It supports various statuses, can be dismissible, and allows for custom content or templates.
 */
const Bar = ({
  status,
  content,
  template,
  templateData,
  dismissable,
  dimissableOnClick,
  barStyle,
  autoClose = true,
  className,
  barClassName,
}) => {
  if (!content && !template) return null;
  const barRef = useRef(null);

  const [wrapperMaxHeight, setWrapperMaxHeight] = useState(0);
  const [showBar, setShowBar] = useState(false);

  const handleDismiss = (e) => {
    if (e) e.preventDefault();
    setWrapperMaxHeight(0);
    setShowBar(false);
    setTimeout(() => {
      dimissableOnClick();
    }, BAR_TRANSITION_TIME);
  };

  useEffect(() => {
    const barElem = barRef.current;
    setWrapperMaxHeight(barElem.offsetHeight + WRAPPER_BOTTOM_PAD);
    setShowBar(true);
  }, []);

  useEffect(() => {
    if (['success', 'info'].includes(status) && autoClose) {
      const timeoutId = setTimeout(() => {
        handleDismiss();
      }, SUCCESS_REMOVAL_DELAY);
      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, []);

  let iconName = 'v2-green-checkmark';
  if (status === 'warning') iconName = 'v2-neutral-yellow-circle';
  if (status === 'error') iconName = 'v2-red-circle-x';
  if (status === 'info') iconName = 'v2-info-icon-default';

  const barStyles = classNames(
    styles.bar,
    {
      [styles.success]: status === 'success',
      [styles.warning]: status === 'warning',
      [styles.error]: status === 'error',
      [styles.info]: status === 'info',
      [styles.showBar]: showBar,
    },
    barStyle,
    barClassName,
  );

  const wrapperInlineStyle = { maxHeight: `${wrapperMaxHeight}px` };

  return (
    <div style={wrapperInlineStyle} className={classNames(styles.barWrapper, className)}>
      <div className={styles.barPadding}>
        <div ref={barRef} className={barStyles}>
          <Icon name={iconName} className={styles.statusIcon} />
          <ContentHandler content={content} template={template} templateData={templateData} />
          {dismissable && (
            <button className="plainButton" onClick={handleDismiss} type="button">
              <Icon name="v2-close-icon" className={styles.closeIcon} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Bar.propTypes = {
  status: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  content: PropTypes.string,
  template: PropTypes.string,
  templateData: PropTypes.object,
  dismissable: PropTypes.bool,
  dimissableOnClick: PropTypes.func,
  barStyle: PropTypes.string,
  autoClose: PropTypes.bool,
  className: PropTypes.string,
  barClassName: PropTypes.string,
};

Bar.defaultProps = {
  status: 'warning', // [success, warning, error, info]
  content: null,
  dimissableOnClick: null,
};

export default Bar;
