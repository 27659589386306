import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  ADD_APP_SNACK_NOTICE,
  REMOVE_APP_SNACK_NOTICE,
  CLEAR_APP_NOTICES,
} from 'src/constants/snackNotice';

const initialState = fromJS({ notices: [], noticeId: 0 });

function snackbarReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_APP_SNACK_NOTICE: {
      const { autoClose, status, content, template, templateData, dismissable } = action;
      let newNotices = state.get('notices');
      const currentId = state.get('noticeId');
      newNotices = newNotices.unshift(
        fromJS({ autoClose, status, content, template, templateData, dismissable, noticeId: currentId }),
      );
      const newId = currentId + 1;
      return state.merge({
        notices: newNotices,
        noticeId: newId,
      });
    }
    case REMOVE_APP_SNACK_NOTICE: {
      const { noticeId } = action;
      const notices = state.get('notices');
      const index = notices.findIndex((notice) => notice.get('noticeId') === noticeId);
      const newNotices = notices.delete(index);
      return state.set('notices', newNotices);
    }
    case CLEAR_APP_NOTICES:
    case LOCATION_CHANGE: {
      return state.set('notices', fromJS([]));
    }
    default:
      return state;
  }
}

export default snackbarReducer;
