// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import DesktopSelector from './DesktopSelector';
import MobileSelector from './MobileSelector';
// Utils
import { updateSelectedDateTimes } from './Scheduling.utils';

const Selector = (props) => (
  <>
    <div className="showMobileOnlyV2">
      <MobileSelector {...props} />
    </div>
    <div className="showTabletDesktopV2">
      <DesktopSelector {...props} />
    </div>
  </>
);

class AvailabilitySelectorWithConfig extends Component {
  static propTypes = {
    availability: PropTypes.object.isRequired,
    toggleHour: PropTypes.func.isRequired,
    availabilityLoaded: PropTypes.func.isRequired,
    selectedDateTimes: PropTypes.array,
    isMobile: PropTypes.bool.isRequired,
    removeExcessSelectedTime: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
    enableDateToggle: PropTypes.bool,
  };

  componentWillUnmount() {
    this.props.availabilityLoaded({ availability: [] });
  }

  toggleHour = (date, selectedHourData, newCheckState) => {
    const { selectedDateTimes } = this.props;
    return (event) => {
      if (event) event.preventDefault();
      if (selectedHourData.some((shd) => !shd.valid)) return;

      selectedHourData.forEach((shd) => {
        /* Note: selectedDateTimes is pass by reference (ref/current), so modifying it down the line updates it. */
        updateSelectedDateTimes(selectedDateTimes, shd, newCheckState);
        this.props.toggleHour({ date, selectedHourData: shd, newCheckState });
      });

      /* 1. Get the removed items. Any older dates greater than MAX allowed */
      let removedItems = [];
      /* 1a. If we don't have MAX selected group, skip */
      if (this.props.config.REQUIRED_MAX_TIMES_LENGTH !== Infinity) {
        const numItemsToRemove =
          selectedDateTimes.length - this.props.config.REQUIRED_MAX_TIMES_LENGTH;
        if (numItemsToRemove > 0) {
          /* 2. Note: selectedDateTimes is pass by reference (ref/current), so modifying it down the line updates it. */
          removedItems = selectedDateTimes.splice(0, numItemsToRemove);
        }
      }

      /* 3. Delete the oldest dates. Stack strategy */
      removedItems.forEach((shd) => {
        /* 3. Remove the castoff dates via redux action */
        this.props.removeExcessSelectedTime({ selectedHourData: shd });
      });
    };
  };

  render() {
    const { availability, enableDateToggle } = this.props;
    return (
      <Selector
        availability={availability}
        toggleHour={this.toggleHour}
        enableDateToggle={enableDateToggle}
      />
    );
  }
}

Selector.propTypes = {
  availability: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  toggleHour: PropTypes.func,
  enableDateToggle: PropTypes.bool,
};

Selector.defaultProps = {
  toggleHour: () => {},
};

export default AvailabilitySelectorWithConfig;

/*
  AvailabilitySelector
    Purpose: Render a scheduling selector
*/
