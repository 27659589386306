import React from 'react';
import PropTypes from 'prop-types';
import SlideModal from 'src/components/HTKit/Modals/Slide';
import { LoginForm } from '../LoginForm';
import styles from './styles.scss';

const LoginModal = ({ isOpen, closeModal }) => {
  return (
    <SlideModal
      open={isOpen}
      handleClose={closeModal}
      customOverlayClass={isOpen ? styles.loginModalOverlay : ''}
    >
      <div>{isOpen && <LoginForm />}</div>
    </SlideModal>
  );
};

LoginModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default LoginModal;
