import { connect } from 'react-redux';
import { toggleDropdown } from '../ChatCallDropdown/actions';
import ContactIcon from './ContactIcon';

const mapStateToProps = (state) => ({
  chat: state.get('chat'),
});

export default connect(
  mapStateToProps,
  { toggleDropdown },
)(ContactIcon);
