import React from 'react';
import PropTypes from 'prop-types';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';

const SubmitOrder = ({ buttonStyles, buttonText, containerStyles, onClick }) => (
  <div className={containerStyles}>
    <Button onClick={onClick} className={buttonStyles} theme={THEMES.V2PRIMARY}>
      {buttonText}
    </Button>
  </div>
);

SubmitOrder.propTypes = {
  buttonStyles: PropTypes.string,
  buttonText: PropTypes.string,
  containerStyles: PropTypes.string,
  onClick: PropTypes.func,
};

SubmitOrder.defaultProps = {
  buttonStyles: '',
  buttonText: 'Complete Purchase',
  containerStyles: '',
  onclick: () => {},
};

export default React.memo(SubmitOrder);

// TODO: This is only being used for SubscriptionGifting and when that feature redone or removed this component should be removed. -GH June 22, 2020
