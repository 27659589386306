import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { map } from 'lodash';
import moment from 'moment';
import HT_INFO from '../../../constants/hellotech';

export default class DefaultMetaTags extends Component { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    description: PropTypes.string,
    noindex: PropTypes.bool,
  };

  static defaultProps = {
    description: HT_INFO.meta.description,
    noindex: false
  };

  renderFBAdmins() {
    return map(HT_INFO.social.facebook.admins, (admin) => (
      <meta property="fb:admins" content={`${admin}`} />
    ));
  }

  render() {
    const { noindex, description, ...rest } = this.props;
    return (
      <Helmet {...rest} titleTemplate="%s | HelloTech" defaultTitle={HT_INFO.meta.title} >
        <meta property="fb:app_id" content={HT_INFO.social.facebook.appId} />
        { this.renderFBAdmins() }
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@hellotech" />
        <meta name="twitter:title" content="HelloTech" />
        <meta name="twitter:creator" content="@hellotech" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content={HT_INFO.social.facebook.og.image.url} />
        <meta property="og:site_name" content={HT_INFO.social.facebook.og.siteName} />
        <meta property="og:type" content={HT_INFO.social.facebook.og.type} />
        <meta property="og:title" content="HelloTech" />
        <meta property="og:description" content="" />
        <meta property="og:url" content={HT_INFO.social.facebook.og.url} />
        <meta property="og:image" content={HT_INFO.social.facebook.og.image.url} />
        <meta property="og:image:url" content={HT_INFO.social.facebook.og.image.url} />
        <meta property="og:image:width" content={HT_INFO.social.facebook.og.image.width} />
        <meta property="og:image:height" content={HT_INFO.social.facebook.og.image.height} />
        <meta property="og:updated_time" content={moment().unix()} />
        <meta name="description" content={description || HT_INFO.meta.description} />
        { noindex && <meta name="robots" content="noindex" /> }
      </Helmet>
    );
  }
}
