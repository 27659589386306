import { call, put, takeLatest } from 'redux-saga/effects';
import { selectRoutes } from 'src/apiRoutes';
import { addSkuPath, push } from 'src/utils/paths';
import { displayErrors, requestFinished, requestStarted } from 'src/utils/request';
import { updateCart } from 'src/containers/AddSkuPage/actions';
import { BOOK_NOW } from './constants';

function* checkLandingCoupon(landing) {
  if (landing.coupon) {
    const routes = yield call(selectRoutes);
    yield put(requestStarted());
    const response = yield call(routes.cart.addCoupon, { code: landing.coupon.text });
    yield put(requestFinished());

    if (response.err) {
      yield put(displayErrors(response));
    } else {
      const { cart } = response.data;
      yield put(updateCart({ cart }));
    }
  }
}

function* bookNowFlow({ payload: { id, landing } }) {
  yield put(requestStarted());
  const routes = yield call(selectRoutes);
  const response = yield call(routes.cart.canAddService, { sku_id: id });
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrors(response));
  } else {
    if (landing) {
      yield call(checkLandingCoupon, landing);
    }
    const url = addSkuPath(id);
    yield put(push(url));
  }
}

export function* mainFlow() {
  yield takeLatest(BOOK_NOW, bookNowFlow);
}

export default [
  mainFlow,
];
