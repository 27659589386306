import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { THEMES } from 'HTKit/Forms/Button';
import BookNowButton from 'src/components/BookNowButton';
import { getSkuPrice } from '../utils';
import styles from './styles.scss';

export default function MultipleAccountSkuModalDetails({ sku, landing }) {
  const price = getSkuPrice(sku);
  return (
    <div className={`${styles.skuInfo} paddingX-small2 paddingBottom-small2`}>
      <h2>{ sku.name }</h2>
      <h4 className="paddingTop-tiny1 paddingBottom-small2">{ price }</h4>
      <p className="p1 n700">{ sku.description }</p>
      <p className="paddingTop-small1 paddingBottom-small text-weight-bold">Service includes:</p>
      <ul className={`${styles.skuInfoBullets} marginBottom-medium`}>
        { map(sku.skuBullets, (item) => <li className="marginLeft-small"><p className="p1 n700">{ item.bullet }</p></li>) }
      </ul>
      <div>
        <BookNowButton skuId={sku.id} landing={landing} mediumV2 theme={THEMES.V2PRIMARY} />
      </div>
    </div>
  );
}

MultipleAccountSkuModalDetails.propTypes = {
  sku: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    skuBullets: PropTypes.array,
  }).isRequired,
  landing: PropTypes.object.isRequired,
};
