import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removePageSnackNotice } from 'src/actions/snackNotice';
import Relative from './Relative';

Relative.propTypes = {
  pages: PropTypes.object,
  removePageSnackNotice: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    pages: state.get('pages'),
  };
};

export default connect(
  mapStateToProps,
  { removePageSnackNotice },
)(Relative);

/*
  This component is used for placing the snackbar wherever it is needed.
  The prop 'componentName' should be unique for each Snackbar instance on the page.

  Usage:

  import { addSuccessPageSnackNotice } from 'src/actions/snackNotice';
  import Snackbar from 'src/components/Snackbar';


  addSuccessPageSnackNotice = () => {
    this.props.addSuccessPageSnackNotice({
      pageName: PAGE_NAME,
      componentName: 'blah',
      content: 'The content for a successful alert.'
    })
  }

  ...

  <Snackbar
    pageName={PAGE_NAME}
    componentName="blah"
  />

  ...

  export default connect(mapStateToProps, { ..., addSuccessPageSnackNotice })(SomeComponent);

  --------

  For sagas

  import { addSuccessPageSnackNotice } from 'src/actions/snackNotice';

  ...

  yield put(addSuccessPageSnackNotice({
    pageName: PAGE_NAME,
    componentName: 'blah',
    content: 'The content for a successful alert.'
  }));

*/
