import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

export const NoOptionsMessage = memo(({ innerProps = {} } = {}) => {
  const { emptyText = 'No Results' } = innerProps;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...innerProps} className={styles.htSelectEmpty}>
      {emptyText}
    </div>
  );
});

NoOptionsMessage.propTypes = {
  innerProps: PropTypes.object,
  emptyText: PropTypes.string,
};

NoOptionsMessage.displayName = 'NoOptionsMessage';
