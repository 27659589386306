import React from 'react';
import { RadioV2, RadioGroupV2 } from 'src/components/HTKit/Forms/RadioV2';
import PropTypes from 'prop-types';
import { AffirmMonthlyPayment } from 'src/components/Affirm';
import {PAYMENT_TYPES} from './constants';
import styles from './styles.scss'

const PaymentChoices = ({setPaymentType, paymentType}) => {
    return (
      <RadioGroupV2
        value={paymentType}
        onChange={setPaymentType}
        containerClassName={styles.paymentOptions}
        columnClassName={styles.paymentOptionsColumn}
        disableSort
      >
        <RadioV2
          key={PAYMENT_TYPES.card}
          value={PAYMENT_TYPES.card}
          label={<div>Credit Card</div>}
        />
        <RadioV2
          key={PAYMENT_TYPES.affirm}
          value={PAYMENT_TYPES.affirm}
          label={<AffirmMonthlyPayment />}
          wrapperClass="affirm_payment"
        />
      </RadioGroupV2>
    );
}

PaymentChoices.propTypes = {
  setPaymentType: PropTypes.func.isRequired,
  paymentType: PropTypes.string.isRequired,
};

export default PaymentChoices;
