/* Actions */
export const SET_IS_ZIP_SUCCESS_FLOW_ACTIVE = 'servicePage/SET_IS_ZIP_SUCCESS_FLOW_ACTIVE';

export function setIsZipSuccessFlowActive(payload) {
  return { type: SET_IS_ZIP_SUCCESS_FLOW_ACTIVE, payload };
}

/* State */
/**
 * @typedef {{type: "ERROR_AND_SUCCESS" | "ERROR_ONLY" | null, isZipSuccessViewActive: boolean}} ZipCodeGateState
 * @typedef {{zipCodeGate: ZipCodeGateState}}ServicePageState
 */
export const INITIAL_STATE = {
  zipCodeGate: {
    isZipSuccessViewActive: false,
  },
};

/* Reducer */
export function servicePageReducer(state, action) {
  switch (action.type) {
    case SET_IS_ZIP_SUCCESS_FLOW_ACTIVE: {
      return {
        ...state,
        zipCodeGate: { ...state.zipCodeGate, isZipSuccessViewActive: action.payload },
      };
    }
    default:
      return state;
  }
}
