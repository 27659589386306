// Libraries
import { call, put, takeEvery, select } from 'redux-saga/effects';
// Utils
import { isPaloAltoNetworksPartner } from 'src/containers/BookingPagePaloAltoNetworks/utils';
// Data
import { paloAltoOrderReviewsPath, replace } from 'src/utils/paths';
import { selectRoutes } from '../../apiRoutes';
// Actions
import { LOAD_PAGE } from '../../constants/common';
import { formSubmitted } from './actions';
import { SUBMIT_SURVEY } from './constants';
import { pageLoadingError, pageLoaded, pageNotice, clearPageNotices } from '../../actions/common';
import { requestStarted, requestFinished } from '../../utils/request';
import { defaultNotice, errorNotice } from '../../utils/notice';

const CUSTOMER_SURVEY_PAGE_NAME = 'customerSurvey';

function* submitSurveySaga({ payload }) {
  const routes = yield call(selectRoutes);
  const { survey } = payload;
  const { orderToken } = survey;
  yield put(clearPageNotices(CUSTOMER_SURVEY_PAGE_NAME));
  yield put(requestStarted());
  const response = yield call(routes.orders.submitSurvey, { orderToken }, { survey });
  yield put(requestFinished());

  if (response.err) {
    const errors = response.data.messages || response.data.message;
    yield put(pageNotice(CUSTOMER_SURVEY_PAGE_NAME, errorNotice(errors)));
  } else {
    yield put(formSubmitted(response.data));
    // TODO - Probably going to want to remove this page notice and transition the page
    yield put(pageNotice(CUSTOMER_SURVEY_PAGE_NAME, defaultNotice('success!')));
  }
}

function* pageSaga(payload) {
  // Load the order info here by token
  const { orderId } = payload; // This should be orderToken, not orderId -GH Oct 14, 2021
  const routes = yield call(selectRoutes);
  let order = yield select((state) => state.getIn(['pages', CUSTOMER_SURVEY_PAGE_NAME, 'order']));
  if (order) {
    order = order.toJS();
  } else {
    const response = yield call(routes.orders.getSurvey, { orderId });
    if (response.err) {
      yield put(pageLoadingError(CUSTOMER_SURVEY_PAGE_NAME, response));
      return;
    }
    order = response.data.order;
  }
  if (isPaloAltoNetworksPartner({ partnerName: order.partner })) {
    yield put(replace(paloAltoOrderReviewsPath(orderId)));
  }
  yield put(pageLoaded(CUSTOMER_SURVEY_PAGE_NAME, { order }));
}

function* customerSurveyFlow() {
  yield takeEvery(
    (action) => action.type === LOAD_PAGE && action.page === CUSTOMER_SURVEY_PAGE_NAME,
    pageSaga,
  );
  yield takeEvery(SUBMIT_SURVEY, submitSurveySaga);
}

export default [customerSurveyFlow];
