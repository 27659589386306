import { useCallback, useState, useRef, useEffect } from 'react';
import get from 'lodash/get';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import useAPI from 'src/hooks/useAPI';
import { isBrowser } from 'src/utils/environment';

/**
 * Behavior was carried over from the original address complete component.
 *
 * Two behaviors to note:
 * - `placesService` is used to format the address to include the postal code.
 * - A call is made to Rails to validate the address against Smarty to ensure that
 *   the address exists.
 *
 * During the validation period, the user will see a loader and will not be able
 * to interact with the input field.
 */
export const useAddressValidation = ({
  placesService,
  updateAddressState,
  setShowInputLoader,
  setInputIsDirty,
  internalRef,
}) => {
  const api = useAPI();

  const getAddressComponent = useCallback((placeResult, type) => {
    const result = placeResult.address_components.find((c) => c.types.includes(type));
    if (result) {
      return result.short_name;
    }
    return null;
  }, []);

  const validateAddress = ({ placeId }) => {
    if (placesService.current && placeId) {
      setShowInputLoader(true);

      placesService.current.getDetails({ placeId }, async (placeResult) => {
        if (isEmpty(placeResult)) {
          setShowInputLoader(false);
          return;
        }

        /**
         * When an address has an unverified type, it does not return that attribute from placeResult (eg: administrative_area_level_1).
         * Therefore, in getAddressComponent method, result (placeResult) is null, and the function returns null.
         * To circumvent this issue, the solution is to get alternate 'types' from placeResult.
         * After testing multiple 'unverified' addresses, it seems like only the locality, administrative_area_level_1, & zip return null values.
         */
        const streetNumber = getAddressComponent(placeResult, 'street_number');
        const streetName = getAddressComponent(placeResult, 'route');
        const city =
          getAddressComponent(placeResult, 'locality') ||
          getAddressComponent(placeResult, 'sublocality');
        const state =
          getAddressComponent(placeResult, 'administrative_area_level_1') ||
          getAddressComponent(placeResult, 'administrative_area_level_2');
        const zip =
          getAddressComponent(placeResult, 'postal_code') ||
          getAddressComponent(placeResult, 'country');
        const street = trim(`${streetNumber || ''} ${streetName || ''}`);
        const newAddress = `${street}, ${city}, ${state}, ${zip}`;
        updateAddressState(newAddress);

        // Double validate against Smarty
        try {
          const { data, err } = await api.geo.geocode({ street, city, state, zip });
          if (!err) {
            const { geo: candidates } = data;
            if (!candidates.length) {
              // hacky way to trigger the dropdown
              setInputIsDirty(true);
              if (internalRef) {
                internalRef.current.focus();
              }
            }
          }
        } catch {
          // do nothing
        }

        setShowInputLoader(false);
      });
    }
  };

  return {
    validateAddress,
  };
};

/**
 * The SDK may not load on the first try, so we need to check for it multiple times.
 * However, we do not want to disable the input during these initial checks, so
 * after x counts, we'll enable the input, which will then act like a normal
 * input field. We'll continue to check for the SDK in the background a few more times.
 */
export const useGoogleMapsSDK = () => {
  // Whether or not Google Maps SDK has loaded
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  // Bypass Google Maps SDK if it hasn't loaded so the user can still type in an address
  const [bypassGoogleMaps, setBypassGoogleMaps] = useState(false);

  // Number of times we've checked for the SDK
  const countOfChecks = useRef(0);
  // Time to pass before each check
  const intervalDuration = 500; // 0.5 second
  // Number of times to check before enabling the input
  const initialMaxChecks = 6;
  // Number of times to check after enabling the input
  const postMaxChecks = 6;
  const maxChecks = initialMaxChecks + postMaxChecks;

  // Google Maps sdk instance
  const autocompleteService = useRef(null);
  const autocompleteOK = useRef(false);
  const placesService = useRef(null);

  const setGoogleMapInstances = () => {
    setGoogleMapsLoaded(true);
    setBypassGoogleMaps(true);
    autocompleteService.current = new window.google.maps.places.AutocompleteService();
    autocompleteOK.current = window.google.maps.places.PlacesServiceStatus.OK;
    placesService.current = new window.google.maps.places.PlacesService(
      document.createElement('div'),
    );
  };

  useEffect(() => {
    if (!isBrowser()) {
      setBypassGoogleMaps(true);
      return () => {};
    }

    const hasMapsObject = get(window, 'google.maps', false);

    if (hasMapsObject) {
      setGoogleMapInstances();
      return () => {};
    }

    const intervalId = setInterval(() => {
      if (!googleMapsLoaded) {
        if (hasMapsObject) {
          setGoogleMapInstances();
          clearInterval(intervalId);
        } else {
          countOfChecks.current += 1;
          if (countOfChecks.current === initialMaxChecks) {
            setBypassGoogleMaps(true);
          } else if (countOfChecks.current === maxChecks) {
            clearInterval(intervalId);
          }
        }
      }
    }, intervalDuration);

    return () => clearInterval(intervalId);
  }, []);

  return {
    googleMapsLoaded,
    bypassGoogleMaps,
    autocompleteService,
    autocompleteOK,
    placesService,
  };
};
