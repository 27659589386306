import { createSelector } from 'reselect';

const pageStateSelector = (name) => (state) => state.getIn(['pages', name]);
export const pageSelector = (name) =>
  createSelector(
    pageStateSelector(name),
    (page) => page,
  );

export const purePageSelector = (name) =>
  createSelector(
    pageStateSelector(name),
    (page) => page.toJS(),
  );

export default pageSelector;
