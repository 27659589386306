import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { formatDate } from 'src/components/AvailabilitySelector/utils';
import Icon from 'src/components/HTKit/Icon';
import StarRating from 'src/components/Elements/StarRating';
import useReadMore from 'src/hooks/useReadMore';
import styles from './styles.scss';

const Review = ({ review, className = '', suppressAddressLine }) => {
  const [{ text, collapsed, couldReadMore }, setCollapsed] = useReadMore(review.comment);
  const readMoreText = collapsed ? 'show more' : 'show less';
  const date = formatDate(review.timestamp, 'L');
  return (
    <div className={cn('site-v2', className, styles.review)}>
      <div className={styles.head}>
        <StarRating stars={review.stars} size="md" />

        <div className="p2 n700">{date}</div>
      </div>

      <div className={cn('p1', styles.text)}>
        {text}
        {couldReadMore && <a onClick={() => setCollapsed(!collapsed)}>{` ${readMoreText}`}</a>}
      </div>

      <div className={styles.info}>
        <div>
          <h5 className="h5">{review.author}</h5>
          {!suppressAddressLine && <div className="caption n700">{review.address}</div>}
        </div>

        <h6 className={cn('h6', styles.verified)}>
          Verified
          <Icon name="v2-green-checkmark" className={styles.check} />
        </h6>
      </div>
    </div>
  );
};

Review.propTypes = {
  className: PropTypes.string,
  suppressAddressLine: PropTypes.bool,
  review: PropTypes.shape({
    author: PropTypes.string,
    address: PropTypes.string,
    comment: PropTypes.string,
    timestamp: PropTypes.string,
    stars: PropTypes.number,
  }).isRequired,
};

export default React.memo(Review);
