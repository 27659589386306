import React from 'react';
import PropTypes from 'prop-types';
import HT_INFO from 'src/constants/hellotech';
import ModalWrapper from 'HTKit/Modals/ModalWrapper';
import styles from './styles.scss';

const PlanHolderModal = ({ visible, onClose }) => {
  const defaultSubscriptionHolderPhone = HT_INFO.phone.customers.subscriptionHolder.default;
  return (
    <ModalWrapper isOpen={visible} modalStyles={styles.planHolderModal} onClose={onClose}>
      <div className={styles.planHolderModalTitle}>We see that you’re already a member.</div>
      <div className={styles.planHolderModalText}>
        We currently only allow our members to have 1 plan at a time.
      </div>
      <div className={styles.planHolderModalHint}>
        Please call{' '}
        <a href={defaultSubscriptionHolderPhone.link}>{defaultSubscriptionHolderPhone.title}</a> if
        you have any questions.
      </div>
    </ModalWrapper>
  );
};

PlanHolderModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default React.memo(PlanHolderModal);
