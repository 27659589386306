import { isImmutable } from 'src/utils/helpers';
import { UPDATE_PROFILE, PREVENT_MEMBERSHIP_CANCELLATION } from './constants';

export function updateProfile(profile, usr, opts = {}) {
  const user = isImmutable(usr) ? usr.toJS() : usr || {};

  return {
    type: UPDATE_PROFILE,
    payload: { profile, opts },
    meta: {
      analytics: [
        {
          eventType: 'identify',
          eventPayload: {
            userId: user.id,
            traits: {
              email: profile.email,
              first_name: profile.firstName,
              last_name: profile.lastName,
              id: user.id,
              phone: profile.phone,
              subscribe_for_feedback: profile.subscribeForFeedback,
              subscribe_for_updates: profile.subscribeForUpdates,
            },
          },
        },
      ],
    },
  };
}

export function preventCancellationSegmentEvent(user) {
  return {
    type: PREVENT_MEMBERSHIP_CANCELLATION,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: 'Membership Modal - Cancel FAQ Clicked',
            properties: user,
          },
        },
      ],
    },
  };
}
