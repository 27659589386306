import { WALMART_THEME } from 'src/utils/branding/constants';
import { isProduction } from 'src/utils/env';

/**
 * Add your FAQ urls here
 * @type {Readonly<{default: string}>}
 */

export const IFRAME_SRCS = Object.freeze({
  default: 'https://help.hellotech.com/',
  contactUs: 'https://hellotech.kustomer.help/contact/faq-contact-form-rJ0u09lXL',
  /*
    The walmart FAQ was used for Walmart branded customers (e.g. Walmart Subscription holders).
    We're not selling Walmart subscriptions anymore, but will leave the link here for now -GH Nov 15, 2021
  */
  [WALMART_THEME]: 'https://hellotech-walmart.kustomer.help/ ',
  privacyPolicy: `https://content${isProduction() ? '' : 's'}.hellotech.com/privacy-policy`,
  membershipGuarantee:
    'https://help.hellotech.com/en_us/how-does-the-no-risk-hellotech-membership-guarantee-work-HJJd49hXI',
});

export const getIframeSource = (src = 'default') => `${IFRAME_SRCS[src]}`;

export const CALLER_PAGE = 'faq';

export const SRC_TYPES = {
  PRIVACY_POLICY: 'privacyPolicy',
  CONTACT_US: 'contactUs',
  MEMBERSHIP_GUARANTEE: 'membershipGuarantee',
};

export const FAQ_PAGE_CONTAINER_ID = 'FAQPageContainer';
export const FAQ_PAGE_IFRAME_ID = 'FAQIframeContainer';
