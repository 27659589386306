import { put } from 'redux-saga/effects';
import { get } from 'lodash';
import { getBrandingCookie, removeBrandingCookie, setBrandingCookie } from 'src/utils/cookies';
import { setBranding, removeBranding } from './actions';

/*
  For now, we only have one branding theme, Walmart. We'll see how things go.
*/

/*
  Determine the theme by the user's plan id.
*/
const getBrandingInfoFromUser = ({ user }) => {
  const partnerObj = get(user, 'account.subscription.partner', null);
  if (partnerObj) {
    const { partnerName, cobrandedLogo } = partnerObj;
    return {
      partnerName,
      cobrandedLogo,
    };
  }
  return null;
};

/** Update Redux state with partner branding info */
export function* setBrandingState({ user }) {
  if (!user) {
    const branding = getBrandingCookie();
    yield put(setBranding({ branding }));
  } else {
    const userJS = user.toJS ? user.toJS() : user;
    const branding = getBrandingInfoFromUser({ user: userJS });

    if (branding) {
      setBrandingCookie(branding);
      yield put(setBranding({ branding }));
    } else {
      removeBrandingCookie();
      yield put(removeBranding());
    }
  }
}
