import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Grid from 'src/components/HTKit/Grid/Grid';
import SlideModal from 'HTKit/Modals/Slide';
import { LEGAL_LINKS, PARTNER_LEGAL_LINKS, PARTNER_LEGAL_INITIAL_STATE } from '../constants';
import FooterSocial from './FooterSocial';
import FooterLineItem from './FooterLineItem';
import styles from '../styles.scss';

const DefaultLegalSection = () => {
  return LEGAL_LINKS.map((item) => (
    <FooterLineItem
      key={item.name}
      className={styles.legalItem}
      link={item.link}
      action={item.action}
      target={item.target}
    >
      {item.name}
    </FooterLineItem>
  ));
};

const PartnerLegalSection = () => {
  const [modalState, setModalState] = useState(PARTNER_LEGAL_INITIAL_STATE);
  const openModal = (name) => {
    setModalState({ ...modalState, [name]: true });
  };
  const closeModal = (name) => {
    setModalState({ ...modalState, [name]: false });
  };
  return PARTNER_LEGAL_LINKS.map((item) => {
    const View = item.component;
    return (
      <>
        <FooterLineItem
          key={item.name}
          className={styles.legalItem}
          openModal={() => openModal(item.view)}
        >
          {item.name}
        </FooterLineItem>
        <SlideModal
          open={modalState[item.view]}
          handleClose={() => closeModal(item.view)}
        >
          <div className="l-content-section">
            <View />
          </div>
        </SlideModal>
      </>
    );
  });
};

const LegalSection = ({ hideSocial, isPartner }) => {
  const year = new Date().getFullYear();
  return (
    <Grid.Fluid>
      <Grid.Row>
        <Grid.Column sm={4} mdOffset={1} md={6} lgOffset={1} lg={10}>
          <div className={cn(styles.legalSection, 'paddingY-medium')}>
            <div className={styles.legalItemsContainer}>
              {isPartner ? <PartnerLegalSection /> : <DefaultLegalSection />}
            </div>
            <div className={styles.copyrightNotice}>
              <p className="p2 n700">&#169; {year} HelloTech. All Rights Reserved.</p>
              {!hideSocial && <FooterSocial />}
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid.Fluid>
  );
};

LegalSection.propTypes = { hideSocial: PropTypes.bool, isPartner: PropTypes.bool };

export default LegalSection;

/*
  LegalSection: Section of the footer that contains links to legal info; the bottom portion of the complete header.

  Props
  ------
    - hideSocial(boolean): Our social media links are hidden for certain 3rd party partner pages
*/
