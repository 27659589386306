import { memo } from 'react';
import { connect } from 'react-redux';
import { flow } from 'lodash/fp';
import ServicePage from './ServicePage';
import selector from './selector';

export default flow(
  memo,
  connect(
    selector,
    null,
  ),
)(ServicePage);
