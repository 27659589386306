import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Constants
import { DEFAULT_HOME_PAGE_LAYOUT } from 'src/constants/common';
import { PAGE_NAME } from 'src/containers/HomePage/constants';
// Cookies
import { removeFieldSalesCookie } from 'src/utils/cookies';
import { removeDirectBookingTechData } from 'src/utils/cookies/directBookingCookie';
// Actions
import { loadPage, layoutUpdate } from 'src/actions/common';
import { getRatingsAndNumServices, removePartnerEffects } from 'src/containers/HomePage/actions';

const useLoadHomePage = ({ getNumServices = true, layoutType = DEFAULT_HOME_PAGE_LAYOUT } = {}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPage(PAGE_NAME));
    dispatch(removePartnerEffects());
    removeFieldSalesCookie();
    removeDirectBookingTechData();
    if (getNumServices) {
      dispatch(getRatingsAndNumServices());
    }
  }, []);

  useEffect(() => {
    dispatch(layoutUpdate(layoutType));
  }, [layoutType]);
};

export default useLoadHomePage;

/*
  A hook containing common functions to be shared across different HomePage implementations
*/
