const HT_INFO = {
  utils: {
    cdn: '//cdn.hellotech.com',
  },
  siteLinks: {
    blog: {
      url: 'http://www.hellotech.com/blog/',
    },
    recruit_signup: {
      url: 'https://content.hellotech.com/techs',
    },
    oldBrowserUpsell: {
      url: 'https://us.hellotech.com/upgrade',
    },
    mainSite: {
      url: 'https://www.hellotech.com',
    },
    facebook: {
      url: 'https://www.facebook.com/hellotech',
    },
    twitter: {
      url: 'https://twitter.com/hellotech',
    },
    linkedin: {
      url: 'https://www.linkedin.com/company/hello-tech/about/',
    },
    articles: {
      usToday:
        'http://www.usatoday.com/story/tech/columnist/saltzman/2017/01/21/tech-support-we-all-need-but-how-do-you-get/96783928/',
      fortune: 'http://fortune.com/2017/05/30/succeeding-with-a-bolt-on-geek-squad/',
      verge:
        'https://www.theverge.com/2017/8/23/16187814/samsung-galaxy-note-8-tech-support-hellotech',
    },
    techs: {
      url: 'https://content.hellotech.com/techs',
    },
    guide: {
      url: 'https://www.hellotech.com/guide',
    },
    careers: {
      url: 'https://jobs.lever.co/hellotech',
    },
    priceGuarantee: {
      url: 'https://promo.hellotech.com/price-match/',
    },
    partnerships: {
      url: 'https://content.hellotech.com/partnerships',
    },
  },

  emails: {
    admin: {
      john: 'john@hellotech.com',
    },
    info: {
      link: 'mailto:info@hellotech.com',
      title: 'info@hellotech.com',
    },
    general: {
      support: 'support@hellotech.com',
      onboarding: 'onboarding@hellotech.com',
    },
    office_support: {
      address: 'csus@hellotech.com',
    },
    support: {
      link: 'mailto:support@hellotech.com',
      title: 'support@hellotech.com',
    },
    techs: {
      appIssues: {
        link: 'mailto:app@hellotech.com',
        title: 'app@hellotech.com',
      },
    },
    unsubscribe: {
      link: 'mailto:unsubscribe@hellotech.com',
      title: 'unsubscribe@hellotech.com',
    },
    membership: {
      link: 'mailto:memberships@hellotech.com',
      title: 'memberships@hellotech.com',
    },
  },

  phone: {
    customers: {
      // DO NOT USE DIRECTLY!!! Call redux state to get phone to display
      default: {
        link: 'tel:18006409005',
        title: '1-800-640-9005',
        value: '18006409005',
      },
      empty: {
        link: 'tel:10000000000',
        title: '1-000-000-0000',
        value: '10000000000',
      },
      plans: {
        link: 'tel:18007541007',
        title: '1-800-754-1007',
        value: '18007541007',
      },
      newPlans: {
        link: 'tel:18008953687',
        title: '1-800-895-3687',
        value: '18008953687',
      },
      cancellation: {
        link: 'tel:18337991738',
        title: '1-833-799-1738',
        value: '18337991738',
      },
      membershipCancellation: {
        link: 'tel:18333691227',
        title: '1-833-369-1227',
        value: '18333691227',
      },
      alfred: {
        link: 'tel:18555092447',
        title: '1-855-509-2447',
        value: '18555092447',
      },
      subscriptionHolder: {
        default: {
          link: 'tel:18007538408',
          title: '1-800-753-8408',
          value: '18007538408',
        },
        walmart: {
          link: 'tel:18556626034',
          title: '1-855-662-6034',
          value: '18556626034',
        },
        assurant: {
          link: 'tel:18443519732',
          title: '1-844-351-9732',
          value: '18443519732',
        },
        frontier: {
          link: 'tel:18559770505',
          title: '1-855-977-0505',
          value: '18559770505',
        },
      },
      topNavExperiment: {
        link: 'tel:18332887680',
        title: '1-833-288-7680',
        value: '18332887680',
      },
      alternativeOne: {
        link: 'tel:18335995711',
        title: '1-833-599-5711',
        value: '18335995711',
      },
      alternativeTwo: {
        link: 'tel:18338923592',
        title: '1-833-892-3592',
        value: '18338923592',
      },
      membership: {
        link: 'tel:18333691227',
        title: '1-833-369-1227',
        value: '18333691227',
      },
      zipcodeSkuGateSplit: {
        link: 'tel:18336472456',
        title: '1-833-647-2456',
        value: '18336472456 ',
      },
    },
    techs: {
      escalation: {
        link: 'tel:14242094660',
        title: '1-424-209-4660',
        value: '14242094660',
      },
    },
    partners: {
      walmart: {
        link: 'tel:18559994699',
        title: '1-855-999-4699',
        value: '18559994699',
      },
      samsung: {
        link: 'tel:18006400206',
        title: '1-800-640-0206',
        value: '18006400206',
      },
      target: {
        pin_redemption: {
          link: 'tel:18007610987',
          title: '1-800-761-0987',
          value: '18007610987',
        },
        api_order: {
          link: 'tel:18007610987',
          title: '1-800-761-0987',
          value: '18007610987',
        },
      },
      qvc: {
        pin_redemption: {
          link: 'tel:18666070447',
          title: '1-866-607-0447',
          value: '18666070447',
        },
      },
      cove: {
        link: 'tel:18337521821',
        title: '1-833-752-1821',
        value: '18337521821',
      },
      paloAltoNetworks: {
        cancellation: {
          link: 'tel:18559565962',
          title: '1-855-956-5962',
          value: '18559565962',
        },
      },
      emporia: {
        link: 'tel:18559997252',
        title: '1-855-999-7252',
        value: '18559997252',
      },
      frontier: {
        link: 'tel:18559770505',
        title: '1-855-977-0505',
        value: '18559770505',
      },
      angi: {
        api_order: {
          link: 'tel:8003338038',
          title: '1-800-333-8038',
          value: '18003338038',
        },
      },
    },
  },

  social: {
    google: {
      review:
        'https://www.google.com/search?q=HelloTech&ludocid=10347118932497971632#lrd=0x80c2bab91ee8f49d:0x8f9859d90442b9b0,3,5',
    },
    bestcompany: {
      review: 'https://bestcompany.com/tech-support/company/hellotech/leave-a-review',
    },
    facebook: {
      appId: '780036812045104',
      admins: ['100002397193848', '100000700373533', '675161394'],
      og: {
        siteName: 'HelloTech',
        title: 'HelloTech',
        type: 'website',
        url: 'https://www.hellotech.com',
        image: {
          url: 'https://cdn.hellotech.com/HT-sharing-logo.png',
          width: 300,
          height: 250,
        },
      },
    },
  },

  meta: {
    title:
      'HelloTech: Home & Business Tech Support, Smart Home Set Up, Installations, and IT Services Near You',
    description:
      'HelloTech provides affordable, same-day on-site and 24/7 online tech support services such as computer repair, TV mounting, smart home installations, virus removal and more.',
  },

  video: {
    youtubePromo: 'https://www.youtube.com/embed/7-aswjALzyU?rel=0&amp;showinfo=0',
  },
};

export default HT_INFO;

/*
  hellotech.js:
    This file exports an object that contains HelloTech related emails and phone numbers.

  * Last modified: -GH March 25, 2017
*/
