import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAPI from 'src/hooks/useAPI';
import { logger } from 'src/utils/logger';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Layout and Components
import PageLoader from 'src/components/PageLoader';
import withLayout from 'src/hoc/withLayout';
import { DIRECT_BOOKING_BASE_LAYOUT } from 'src/constants/common';
import TechBanner from 'src/containers/DirectBooking/TechSkusPage/Parts';
import { techProfileByIdOrSlugJSSelector } from 'src/selectors/techs';
import { loadProfileData } from 'src/components/Techs/data/actions';
import useNotice from 'src/hooks/useNotice';

// Utils and Actions
import { iframeMessaging } from 'src/utils/iframeMessagingPage';
import { messagePageActions } from './messagePageActions';

// Page Assets
import { IFRAME, CALLER_PAGE, techBookingiframeContainerId } from './constants';
import { fillDirectBookingCookie } from '../utils';
import styles from './styles.scss';

const TechProductsPage = (props) => {
  const [pageLoaded, setPageLoaded] = useState(false);
  const { techId: techIdOrSlug } = useParams();
  const dispatch = useDispatch();
  const api = useAPI();
  const { error: errorSnackbar, requestError: requestErrorSnackbar } = useNotice();
  const techProfile = useSelector(techProfileByIdOrSlugJSSelector(techIdOrSlug));
  const bannerHeader = 'Shop for Products';
  const { dedicatedPhone, firstName = '', slug: techSlug = '' } = techProfile || {};
  const bannerSubtext = `Purchase devices from HelloTech and get them installed by ${firstName}.`;

  const messageAPI = iframeMessaging(messagePageActions);

  const eventer = (type) =>
    window[`${type}EventListener`](
      'message',
      messageAPI({ props, attributes: { CALLER_PAGE, setPageLoaded } }),
      false,
    );

  /**
   * Load Tech Data
   *
   * We're setting the cookie here again just in case of refreshes or weird navigation paths.
   * We're going to assume that the client is committed to using this tech at this point.
   */
  useEffect(() => {
    // Load techdata
    (async () => {
      try {
        if (techProfile) {
          fillDirectBookingCookie({
            techId: techProfile.id,
            techPhoneNumber: dedicatedPhone,
            techSlug,
          });
          setPageLoaded(true);
          return;
        }
        const requestResult = await api.techs.profile({ id: techIdOrSlug });
        if (!requestResult.err) {
          const {
            data: { tech: techData },
          } = requestResult;
          dispatch(loadProfileData({ techId: techData.id, data: techData }));
          fillDirectBookingCookie({
            techId: techData.id,
            techPhoneNumber: techData.dedicatedPhone,
            techSlug: techData.slug,
          });
          setPageLoaded(true);
        } else {
          const {
            data: { errors },
          } = requestResult;
          requestErrorSnackbar(requestResult);
          logger('TechProductsPage - response data')(errors);
          return;
        }
      } catch (e) {
        errorSnackbar("This technician's profile could not load. Please contact support.");
        logger('TechProductsPage')(e);
      }
    })();

    // add/remove eventer for iframe
    eventer('add');
    return () => eventer('remove');
  }, [techIdOrSlug]);

  return (
    <section className={styles.container}>
      {!pageLoaded && <PageLoader />}
      <>
        <div className={styles.heroContainer}>
          <TechBanner
            techInfo={techProfile}
            header={bannerHeader}
            subText={bannerSubtext}
            showRating={false}
          />
        </div>
        <div className={styles.iframeContainer}>
          <div
            id={techBookingiframeContainerId}
            dangerouslySetInnerHTML={{ __html: IFRAME(props.src) }}
          />
        </div>
      </>
    </section>
  );
};

TechProductsPage.propTypes = {
  src: PropTypes.string.isRequired,
};

export default withLayout(DIRECT_BOOKING_BASE_LAYOUT)(TechProductsPage);
