import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { layoutUpdate, loadPage } from 'src/actions/common';
import { DEFAULT_HOME_PAGE_LAYOUT } from 'src/constants/common';
import LegacyHomePageHero from 'src/components/Sections/legacy/HomePageHero';
import LegacyFeaturedIn from 'src/components/Sections/legacy/FeaturedIn';
import LegacyCustomerReviews from 'src/components/Sections/legacy/CustomerReviews';
import LegacyExpertInHomeService from 'src/components/Sections/legacy/ExpertInHomeService';
import LegacyQualifiedTechs from 'src/components/Sections/legacy/QualifiedTechs';
import PageLoader from 'src/components/PageLoader';
import { DefaultMetaTags } from 'src/components/SEO';
import HellotechVsGeekSquad from './HellotechVsGeekSquad';

const GeekSquadPage = ({ page, reviews, geeksquadPathName }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(layoutUpdate(DEFAULT_HOME_PAGE_LAYOUT));
    dispatch(loadPage('geekSquad'));
  }, []);
  if (page.get('loading')) return <PageLoader />;
  return (
    <div className="site-v2">
      <DefaultMetaTags title="HelloTech Support to Your Door: Repair, Setup &amp; Installation" />
      <LegacyHomePageHero title="Tech Support to Your Door" headerClasses="blue500" />
      <LegacyFeaturedIn backgroundColor="white" />
      <LegacyExpertInHomeService backgroundColor="white" headerClasses="blue500" />
      <HellotechVsGeekSquad backgroundColor="gray" />
      <LegacyQualifiedTechs
        backgroundColor="white"
        pathname={geeksquadPathName}
        headerClasses="blue500"
      />
      <LegacyCustomerReviews
        reviews={reviews}
        backgroundColor="white"
        title="Customer Testimonials"
        headerClasses="blue500"
      />
    </div>
  );
};

GeekSquadPage.propTypes = {
  page: PropTypes.object.isRequired,
  loadPage: PropTypes.func.isRequired,
  layoutUpdate: PropTypes.func.isRequired,
  reviews: PropTypes.object,
  geeksquadPathName: PropTypes.string.isRequired,
};

GeekSquadPage.defaultProps = {
  reviews: [],
};

function mapStateToProps(state, ownProps) {
  const geeksquadPathName =
    ownProps.location && ownProps.location.pathname ? ownProps.location.pathname : '';
  return {
    geeksquadPathName,
    page: state.getIn(['pages', 'geekSquad']),
    reviews: state.getIn(['entities', 'reviews', 'home'], null),
  };
}

export default connect(mapStateToProps, null)(GeekSquadPage);
