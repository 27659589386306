import React from 'react';
import PropTypes from 'prop-types';
import { prevented } from 'src/utils/event';
import MenuTemplate from '../MenuTemplate';

const CategoryMenu = ({
  visible,
  skuCategoriesAndSkus,
  setSelectedCategory,
  setCategoryMenuVisibleState,
}) => {
  const text = 'Services';
  const headerItems = [
    {
      text,
      onBackClick: prevented(() => setCategoryMenuVisibleState(false)),
    },
  ];

  const categories = skuCategoriesAndSkus;
  const secondaryItems = categories.map((category) => ({
    text: category.name,
    onClick: prevented(() => setSelectedCategory(category)),
  }));

  return (
    <MenuTemplate
      slide
      visible={visible}
      headerItems={headerItems}
      secondaryItems={secondaryItems}
    />
  );
};

CategoryMenu.propTypes = {
  visible: PropTypes.bool.isRequired,
  setCategoryMenuVisibleState: PropTypes.func.isRequired,
  skuCategoriesAndSkus: PropTypes.array,
  setSelectedCategory: PropTypes.func,
};

export default React.memo(CategoryMenu);
