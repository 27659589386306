import { targetPinRedemptionPath, prepaidPinRedemptionPath } from 'src/utils/paths';
import { PARTNERS } from 'src/constants/common';

export const PAGE_NAME = 'pinRedemptionPage';

export const SET_CARD_DATA = `${PAGE_NAME}/SET_CARD_DATA`;
export const CLEAR_CARD = `${PAGE_NAME}/CLEAR_CARD`;

export const PATHS = Object.freeze({
  target: targetPinRedemptionPath,
  qvc: prepaidPinRedemptionPath,
});

export const COOKIES = Object.freeze({
  VERIFIED_PIN_DATA: 'verifiedPinData',
});

// These skus will be hardcoded for now, a BE change will come in the future -GH NOV 2, 2020
export const SKU_IDS_THAT_REQUIRE_PINS = Object.freeze([1103, 1104, 1105]);
export const PARTNER_NAMES_THAT_REQUIRE_PINS = Object.freeze([PARTNERS.QVC]);

// The text for sku FAQ comes from the BE. Hardcoding this here since there is no sku
export const PIN_REDEMPTION_SERVICE_FAQ = {
  faq: [
    {
      questionText: 'What is HelloTech?',
      answerText:
        'HelloTech is the leading independent, on-demand technology support service. HelloTech provides in-home and remote support, utilizing a network of thousands of experts nationwide that are rigorously vetted and background-checked to provide a top-notch customer experience.',
    },
    {
      questionText: 'How does HelloTech work?',
      answerText:
        'When requesting a service, you will be asked to provide your appointment availability date and time. HelloTech will work to match your service request with a local skilled technician and secure an appointment. If successful, a confirmation email will be sent to you with the exact appointment date and time allocated for your technician to arrive and perform the service.',
    },
    {
      questionText: 'Is HelloTech available in my area?',
      answerText:
        'HelloTech is available nationwide with skilled and vetted tech professionals in all 50 states, plus Washington, DC.  Rural areas may require additional time to confirm your service booking. Our team of technicians is growing every day to meet growing demand from our customers.',
    },
    {
      questionText: 'Is my service guaranteed?',
      answerText:
        "HelloTech is committed to your complete satisfaction and offers a 30-day service guarantee. If any issues arise during or after your service, please let us know right away. We'll do whatever it takes to make it right.",
    },
    {
      questionText: 'How skilled are your technicians?',
      answerText:
        'HelloTech professionals come from a variety of skilled backgrounds. We hand-select our technicians after they have been vetted, background-checked and assessed for technical skills expertise.',
    },
  ],
};
