import { fromJS } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import authSelector from 'src/selectors/auth';
import { toggleLoading } from 'src/utils/request';
import routes from 'src/apiRoutes';

export default () => {
  const dispatch = useDispatch();
  const api = routes(useSelector(authSelector));
  return {
    ...api,
    toggleLoader(param) {
      dispatch(toggleLoading(param));
    },
    /**
     * Updates the API routes with new authentication information.
     * It can be useful after a user registration or when the authentication information needs to be
     * refreshed. By calling this function with the new authentication information, subsequent API
     * requests will be made with the updated authentication.
     *
     * @param {Object} auth
     * @param {String} auth.authToken
     * @param {String} auth.formAuthenticityToken
     * @param {String} auth.cookie when is this happening?
     *
     */
    routesWithNewAuth(auth = {}) {
      return routes(fromJS(auth));
    },
  };
};
