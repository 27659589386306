import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'src/components/HTKit/Icon';
import styles from './styles.scss';

const BookingHeaderLogo = ({ logo }) => (
  <div className={styles.logos}>
    <Icon name="logo-horizontal" render={(props) => <span {...props} />} />
    {logo && (
      <div className={cn(styles.partnerLogoWrapper, 'marginLeft-small paddingLeft-small')}>
        <img src={logo} alt="Logo" />
      </div>
    )}
  </div>
);

BookingHeaderLogo.propTypes = {
  logo: PropTypes.string,
};

BookingHeaderLogo.defaultProps = {
  logo: null,
};

export default BookingHeaderLogo;
