import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SkuFAQButton from './SkuFAQButton';
import SkuFAQListDesktop from './SkuFAQListDesktop';
import SkuFAQListMobile from './SkuFAQListMobile';
import styles from './styles.scss';

export default class SkuFAQ extends PureComponent {
  static propTypes = {
    faq: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    className: PropTypes.string,
    isGridMobileOrTablet: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    className: null,
  };

  state = {
    open: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  handleFAQToggle = (event) => {
    const { open } = this.state;

    if (event) event.preventDefault();
    this.setState({ open: !open });
  };

  handleOutsideClick = (e) => {
    const { isGridMobileOrTablet } = this.props;
    if (this.state.open && !this.node.contains(e.target) && !isGridMobileOrTablet)
      this.handleFAQToggle();
  };

  render() {
    const { open } = this.state;
    const { faq, className, isGridMobileOrTablet } = this.props;

    return (
      <div className={cn(styles.faq, className)} ref={(node) => { this.node = node; }}>
        <div className={styles.faqWrapper}>
          {!isGridMobileOrTablet ? (
            <SkuFAQListDesktop open={open} faq={faq} onClose={this.handleFAQToggle} />
          ) : (
            <SkuFAQListMobile open={open} faq={faq} onClose={this.handleFAQToggle} />
          )}

          {!isGridMobileOrTablet && (
            <SkuFAQButton onClick={this.handleFAQToggle}>FAQS</SkuFAQButton>
          )}
        </div>
      </div>
    );
  }
}
