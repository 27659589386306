import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Layout and Components
import IframeView from 'src/components/Elements/IframeView';
import withLayout from 'src/hoc/withLayout';
import { DEFAULT_LAYOUT } from 'src/constants/common';
import { pureLocationSelector } from 'src/selectors/router';

// Utils and Actions
import { useSplitIOTreatment, splitioConstants } from 'src/components/SplitIO';
import { messagePageActions } from './messagePageActions';

// Page Assets
import { CALLER_PAGE, getIframeSource } from './constants';

/**
 * Wrapper page for our Webflow Page created by marketing. We listen for events passed from
 * child to parent/this page. And act on those evented messages.
 *
 * If in the future they modify the Plan Page, you just have to concern yourself with the following:
 * 1. Coordinate with the person creating the page to use their existing (supplied js) onclick format.
 * 2. Update the button keys to match what they are passing you, and add the tracking in the actions.js file.
 *
 * @param url
 * @returns {string}
 */
const ProductPage = (props = {}) => {
  const { pathname } = useSelector(pureLocationSelector);
  // If we are on radio store, lets pass along a string params.
  const params = pathname.includes('/shop/radio') ? '?source=radio' : '';
  const { splitLoaded, splitConfig } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_PRODUCT_SHOP_VERSION_TEST,
  );

  const iframeSourceString = getIframeSource(props.src, {
    params,
    splitLoaded,
    version: splitConfig.path,
  });

  return (
    <IframeView
      callerPageName={CALLER_PAGE}
      src={iframeSourceString}
      additionalActions={messagePageActions}
    />
  );
};

ProductPage.propTypes = {
  src: PropTypes.string.isRequired,
};

export default withLayout(DEFAULT_LAYOUT)(ProductPage);
