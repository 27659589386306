import { call, put, takeLatest } from 'redux-saga/effects';
import { push, planPath } from 'src/utils/paths';
import { selectRoutes } from 'src/apiRoutes';
import { requestStarted, requestFinished } from 'src/utils/request';
import { clearFormErrors } from 'src/actions/common';
import { setUpFormErrors } from 'src/sagas/common/form';
import { PAGE_NAME, CHECK_REDEEM_CODE } from './constants';
import { addGiftCard } from './actions';

function* checkRedeemCodeSaga({ payload: { code } }) {
  const routes = yield call(selectRoutes);
  yield put(clearFormErrors(PAGE_NAME));
  yield put(requestStarted());
  const response = yield call(routes.plans.redeem, { code });

  yield put(requestFinished());
  if (response.err) {
    yield setUpFormErrors(PAGE_NAME, response);
  } else {
    const { clientPlanOrder } = response.data;
    yield put(addGiftCard(clientPlanOrder));
    yield put(push(planPath(clientPlanOrder.seoName)));
  }
}

function* redeemFlow() {
  yield takeLatest(CHECK_REDEEM_CODE, checkRedeemCodeSaga);
}

export default [redeemFlow];
