import { fromJS } from 'immutable';
import { ADD_GIFT_CARD, REMOVE_GIFT_CARD } from 'src/containers/Plans/RedeemPage/constants';
import { ADD_URL_PARAM_COUPON } from 'src/constants/common';


const couponsInitialState = fromJS({ giftCard: null, param: null });

export default function couponsReducer(state = couponsInitialState, action) {
  switch (action.type) {
    case ADD_GIFT_CARD:
      return state.merge({ giftCard: action.payload.clientPlanOrder });
    case REMOVE_GIFT_CARD:
      return state.merge({ giftCard: null });
    case ADD_URL_PARAM_COUPON:
      return state.merge({ param: action.coupon });
    default:
      return state;
  }
}
