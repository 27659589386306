import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import flow from 'lodash/flow';
import cn from 'classnames';
import styles from './styles.scss';

const DarkOverlay = ({ show, fixed, absolute, onClick = () => {}, children, className }, ref) => {
  const overlayStyles = cn(
    styles.overlay,
    {
      [styles.show]: show,
      [styles.fixed]: fixed,
      [styles.absolute]: absolute,
    },
    className,
  );

  return (
    <div ref={ref} onClick={onClick} className={overlayStyles}>
      {children}
    </div>
  );
};

DarkOverlay.defaultProps = {
  show: false,
  fixed: false,
  absolute: false,
  className: '',
};

DarkOverlay.propTypes = {
  show: PropTypes.bool,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default flow(
  forwardRef,
  memo,
)(DarkOverlay);
