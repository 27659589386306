// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { meetOurTechsPath } from 'src/utils/paths';
// Images
import glenn from './HT_techGlenn.jpg';
import jose from './HT_techJose.jpg';
import katie from './HT_techKatie.jpg';
// Styles
import styles from './styles.scss';

const QualifiedTechs = ({ backgroundColor, headerClasses = '' }) => {
  const background = `${backgroundColor}Background`;
  return (
    <section className={cn('l-content-section', styles[background])}>
      <h2 className={cn('l-section-header', styles.header, headerClasses)}>Our Expert Techs</h2>
      <div className={styles.container}>
        <figure className={styles.techContainer}>
          <img src={glenn} alt="Glenn" />
          <h5>Glenn H.</h5>
          <p className="paddingBottom-tiny1">TV and AV Mounting Specialist</p>
          <p className={cn('p1 n700', styles.techQuote)}>
            &ldquo;I love helping people solve their tech problems as well as helping them
            understand the issue so it doesn&apos;t happen again!&rdquo;
          </p>
        </figure>
        <figure className={styles.techContainer}>
          <img src={jose} alt="Jose" />
          <h5>Jose C.</h5>
          <p className="paddingBottom-tiny1">Smart Home Specialist</p>
          <p className={cn('p1 n700', styles.techQuote)}>
            &ldquo;I&apos;ve always been interested in technology. It&apos;s great to be able to
            share what I love with people that need my help.&rdquo;
          </p>
        </figure>
        <figure className={styles.techContainer}>
          <img src={katie} alt="Katie" />
          <h5>Katie F.</h5>
          <p className="paddingBottom-tiny1">Mobile Specialist</p>
          <p className={cn('p1 n700', styles.techQuote)}>
            &ldquo;It really makes me happy to see customers so excited to learn a new trick on
            their mobile devices! Definitely puts a smile on my face everytime.&rdquo;
          </p>
        </figure>
      </div>
      <Link to={meetOurTechsPath()} className={cn('large-link', styles.moreLink)}>
        View more
      </Link>
    </section>
  );
};

QualifiedTechs.defaultProps = {
  backgroundColor: 'white',
};

QualifiedTechs.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  headerClasses: PropTypes.string,
};

export default QualifiedTechs;

/*
  QualifiedTechs:
    A reusable page section showing pictures of techs and brief quotes.

  Examples:
    <QualifiedTechs backgroundColor="white" />

  Properties:
    backgroundColor:
      type: string
      description: Used to set the background-color CSS property

  Last modified: -GH March 20, 2017

  Customizing:
    Conditionally renders a part of the page depending on the url (currently only geeksquad comparison page).

    Last modified: -MR Nov 29, 2018
*/
