// Status Name
export const LABEL = Object.freeze({
  ERROR: 'Error',
  PENDING: 'Pending',
  EN_ROUTE: 'En Route',
  CONFIRMED: 'Confirmed',
  STARTED: 'Job Started',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  UNSERVICEABLE: 'Unserviceable',
  ACTION_REQUIRED: 'Action Required',
  PENDING_SHIPPING: 'Pending Shipping',
});

// Panel Type (mainly for OrderPage)
export const PANEL_TYPE = Object.freeze({
  ERROR: 'error',
  STAND_BY: 'standby',
  PENDING: 'pending',
  EN_ROUTE: 'enroute',
  CONFIRMED: 'confirmed',
  STARTED: 'started',
  TECH_LATE: 'techLate',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  UNSERVICEABLE: 'unserviceable',
  RESCHEDULE: 'reschedule',
  PENDING_SHIPPING: 'pending',
});

