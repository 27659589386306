import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import sign from 'src/images/sign.png';
import Grid from 'HTKit/Grid/Grid';
import { errorText } from './utils';
import styles from './styles.scss';

const PureErrorPage = ({ popularSkus, code, noRedux }) => (
  <Grid.Fluid classes="site-v2 paddingY-medium">
    <div className={styles.container}>
      <div className={styles.signContainer}>
        <div className={styles.signTextContainer}>
          <img src={sign} className={styles.signImg} alt="Error Page" />
          <h1 className={styles.code}>
            <span>{code}</span>
            <br />
            error
          </h1>
          <h2 className={styles.errorText}>{errorText(code)}</h2>
          {noRedux ? (
            <a href="/" className={styles.takeMeHome}>
              Take Me Home
            </a>
          ) : (
            <Link to="/" className={styles.takeMeHome}>
              Take Me Home
            </Link>
          )}
        </div>
      </div>
      <div className={`paddingX-small1 ${styles.servicesContainer}`}>
        <h3 className="marginBottom-small2">Perhaps you were looking for some of our most popular services.</h3>
        <ul>
          {popularSkus.map((sku) => (
            <li key={sku.get('id')} className="marginBottom-small">
              <h5>
                {noRedux ? (
                  <a href={sku.get('link')}>{sku.get('name')}</a>
                ) : (
                  <Link to={sku.get('link')}>{sku.get('name')}</Link>
                )}
              </h5>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </Grid.Fluid>
);

PureErrorPage.propTypes = {
  code: PropTypes.number,
  popularSkus: PropTypes.object,
  noRedux: PropTypes.bool,
};

export default React.memo(PureErrorPage);
