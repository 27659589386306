import { fromJS } from 'immutable';
import { SET_EDIT_PAYMENT_INFORMATION, SET_SHOW_AVAILABILITY, TOGGLE_MODAL } from './constants';

const initialState = fromJS({
  editMode: false,
  showAvailability: false,
  modals: {
    invalidDates: false,
  }
});

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EDIT_PAYMENT_INFORMATION: {
      return state.set('editMode', action.value);
    }
    case SET_SHOW_AVAILABILITY: {
      return state.set('showAvailability', action.value);
    }
    case TOGGLE_MODAL: {
      return state.updateIn(['modals', action.modalName], (currentState) => (!currentState));
    }
    default:
      return state;
  }
}

export default pageReducer;
