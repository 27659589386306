import React from 'react';
import PropTypes from 'prop-types';
import SlideModal from 'HTKit/Modals/Slide';
import SkuFAQContent from './SkuFAQContent';

const SkuFAQListMobile = ({ faq, onClose, open }) => (
  <SlideModal open={open} handleClose={onClose}>
    <SkuFAQContent faq={faq} className="padding-medium2" />
  </SlideModal>
);

SkuFAQListMobile.propTypes = {
  faq: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SkuFAQListMobile;
