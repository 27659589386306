import useSearchParams from 'src/hooks/useSearchParams';

/*
*****************************************************************************
  Cart Token in url query

  Since the EV feature, clients api endpoints can be appended with
  a cart token in the url query. This token is used to override the cart
  held in session and load the cart associated with the token.

  To pass around the cart token, we'll be leveraging the url. For pages that
  require a cart token to be manually passed, we'll be appending the token
  to the url query. This will allow us to pass the token around without
  having to worry about the token being lost in the page flow. On page refreshes,
  `handle-render` will also pass the cart token to the backend, so the desired
  cart is populated.

  For example, `/ev/scheduling?ct=12345` should load the cart associated
  with the token `12345` on the Scheduling page.
*****************************************************************************
*/

/**
 * Name to use on the frontend to signal that there's a cart token that should
 * be passed manually to an endpoint.
 */
export const CART_QUERY_NAME = 'ct';

export const buildCartTokenQuery = (cartToken = '') => cartToken ? `${CART_QUERY_NAME}=${cartToken}` : null;

/**
 * Appends the cart token to the url query. Will not prepend `?` if query is empty.
 *
 * @param {string} query - The url query to append the cart token to.
 * @param {string} cartToken - The cart token to append to the url query.
 */
export const appendCartTokenToUrl = (query, cartToken) => {
  const cartTokenQuery = buildCartTokenQuery(cartToken);
  const url = query ? `${query}&${cartTokenQuery}` : `${cartTokenQuery}`;
  return url;
};

export const useExtractCartTokenFromQuery = () => {
  const searchParams = useSearchParams();
  const cartToken = searchParams.get(CART_QUERY_NAME);
  return !['null', 'undefined', ''].includes(cartToken) ? cartToken : null;
};
