import React from 'react';

export const SPEEDTEST_IFRAME_URL = 'https://hellotech.speedtestcustom.com';

export const PAGE_NAME = 'speedTestPage';

export const SPEEDTEST_RESULTS_RECEIVED = `${PAGE_NAME}/SPEEDTEST_RESULTS_RECEIVED`;
export const SET_SKU_DATA = `${PAGE_NAME}/SET_SKU_DATA`;

export const SPEEDTEST_SKU_IDS = [17, 426, 427, 1036];

export const DEV_TEST_DATA = {
  ping: 5,
  jitter: 0.222222222,
  download: 477723,
  upload: 415095,
};

export const SPEED_TEST_FAQ_DATA = [
  {
    id: 1,
    questionText: 'How to Test Your WiFi Speed',
    answerText: (
      <>
        For the most accurate results, ensure your other devices are not actively using the
        internet. Use the HelloTech WiFi Speed Test and select <strong>Go</strong>.
      </>
    ),
  },
  {
    id: 2,
    questionText: 'What Does Mbps Mean?',
    answerText:
      'Megabits per second (Mbps) is a measurement of internet download and upload speeds. There are roughly 8 megabits for every megabyte. A download speed of 100Mbps means you can download 12.5 megabytes of data per second.',
  },
  {
    id: 3,
    questionText: 'What is Download Speed?',
    answerText:
      'Download speed refers to how fast you can receive data from the internet to your device. A fast download speed means your web pages will load quicker, which leads to less buffering when streaming video.',
  },
  {
    id: 4,
    questionText: 'What is Upload Speed?',
    answerText:
      'Upload speed refers to how fast you can send data from your device to the internet. A fast upload speed means you will be able to send email attachments quicker. You will also experience less choppiness during video conferences.',
  },
  {
    id: 5,
    questionText: 'What is Ping?',
    answerText:
      'Ping (also known as “latency”) measures how many milliseconds it takes for your device to get a response. A fast ping means you have a responsive connection, which is useful for applications like video games.',
  },
  {
    id: 6,
    questionText: 'What is a Good Download Speed?',
    answerText:
      'You need a download speed of at least 1-6Mbps for video conferencing. To stream 4K video, you need 25Mbps. If you want to use many devices at once, it’s good to have 50-100Mbps or more. The average download speed in the US is 96Mbps.',
  },
  {
    id: 7,
    questionText: 'What is a Good Upload Speed?',
    answerText:
      'To live-stream HD video, you need an upload speed of at least 3-6Mbps. If you want to live-stream 4K video at 60 frames per second, it’s good to have an upload speed of 20-50Mbps or more. The average upload speed in the US is 33Mbps.',
  },
];
