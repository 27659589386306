import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FORM_SIZE } from '../../constants';
import styles from './styles.scss';

/**
  Label

  Accepts a string or a custom component
*/
const Label = ({
  label,
  labelComponent = null,
  dangerouslySetLabel = null,
  inputId,
  size = FORM_SIZE.medium,
  className,
}) => {
  if (!(label || labelComponent || dangerouslySetLabel)) return null;

  const labelWrapperStyles = cn(styles.labelWrapper, {
    [styles.medium]: size !== FORM_SIZE.large,
    [styles.large]: size === FORM_SIZE.large,
    [styles.extraSmall]: size === FORM_SIZE.extraSmall,
  });

  const labelStyles = cn(className,{
    [`input-label-${size}`]: true,
  });

  let labelToRender = (
    <label htmlFor={inputId} className={labelStyles}>
      {label}
    </label>
  );

  if (labelComponent) labelToRender = labelComponent;

  if (dangerouslySetLabel) {
    labelToRender = (
      <label
        htmlFor={inputId}
        className={labelStyles}
        dangerouslySetInnerHTML={{ __html: dangerouslySetLabel }}
      />
    );
  }

  return <div className={labelWrapperStyles}>{labelToRender}</div>;
};

Label.propTypes = {
  label: PropTypes.string,
  labelComponent: PropTypes.node,
  dangerouslySetLabel: PropTypes.string,
  inputId: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};

export default Label;
