import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import classNames from 'classnames';
import ModalWrapper from '../ModalWrapper';
import styles from './styles.scss';

export default class ContentModal extends Component {
  static propTypes = {
    children: PropTypes.node,
    styles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    closeStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onCancel: PropTypes.func,
    title: PropTypes.string,
    titleStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    visible: PropTypes.bool,
    hideCloseButton: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onCancel: () => {},
    visible: false,
    hideCloseButton: false,
  };

  constructor(props, context) {
    super(props, context);
    this.state = { visible: false, params: {} };
    this.onClose = ::this.onClose;
  }

  onClose(event) {
    event.preventDefault();
    this.close();
  }

  open(params = {}) {
    this.setState({ visible: true, params });
  }

  close() {
    this.props.onCancel(this.state.params);
    this.setState({ visible: false, params: {} });
  }

  render() {
    const propsToRender = omit(this.props, ['title', 'children', 'styles', 'hideCloseButton']);
    const modalStyles = classNames(styles.modalWrapper, this.props.styles);
    const titleStyles = classNames('h3 text-align-center', styles.modalTitle, this.props.titleStyles);
    return (
      <div>
        <ModalWrapper
          contentLabel="ContentModal"
          isOpen={this.state.visible || this.props.visible}
          onClose={this.onClose}
          propsToRender={propsToRender}
          modalStyles={modalStyles}
          closeStyles={this.props.closeStyles}
          hideCloseButton={this.props.hideCloseButton}
        >
          <div className={titleStyles}>{this.props.title}</div>
          <div className={styles.modalContent}>
            {this.props.children}
          </div>
        </ModalWrapper>
      </div>
    );
  }
}
