import React from 'react';
import PropTypes from 'prop-types';
import PureErrorPage from './containers/ErrorPage/PureErrorPage';

const ErrorRoot = ({ code, popularSkus }) => (
  <div>
    <PureErrorPage code={code} popularSkus={popularSkus} noRedux />
  </div>
);

ErrorRoot.propTypes = {
  code: PropTypes.number.isRequired,
  popularSkus: PropTypes.object.isRequired,
};

export default React.memo(ErrorRoot);
