import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { HIDE_PRODUCT_CONNECTED_HOME } from 'src/constants/app';
import FieldSaleGuard from 'src/containers/FieldSales/components/FieldSaleGuard';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { BUTTON_CTAS } from 'src/containers/CartPage/constants';
import styles from './styles.scss'

export const AddMoreServicesButton = ({ onClick, children, fitContent }) => {
  const buttonStyles = classNames(styles.addAnotherBtn, { [styles.fitContent]: fitContent });
  return (
    <Button className={buttonStyles} onClick={onClick} theme={THEMES.V2SECONDARY} hugeV2>
      {children}
    </Button>
  );
};

const AddAnotherServiceBlock = ({
  canAddAnotherService,
  isPartner,
  onServiceClick,
  onProductClick,
  classes,
}) => {
  const showProductsButton = (!isPartner && !HIDE_PRODUCT_CONNECTED_HOME);

  return (
    <>
      {canAddAnotherService && (
        <>
          <div className={classNames(classes, styles.buttonContainer)}>
            <p className="p0 n900">What else can we help with?</p>
            <div className={styles.buttonsWrapper}>
              <FieldSaleGuard>
                <AddMoreServicesButton onClick={onServiceClick}>
                  {BUTTON_CTAS.SERVICES}
                </AddMoreServicesButton>
                {showProductsButton && (
                  <AddMoreServicesButton onClick={onProductClick}>
                    {BUTTON_CTAS.PRODUCTS}
                  </AddMoreServicesButton>
                )}
              </FieldSaleGuard>
              <FieldSaleGuard positiveShow>
                <AddMoreServicesButton onClick={onServiceClick}>
                  {BUTTON_CTAS.FIELD_SALES_CTA}
                </AddMoreServicesButton>
              </FieldSaleGuard>
            </div>
          </div>
          <div className={styles.mobileButtonContainer}>
            <AddMoreServicesButton onClick={onServiceClick}>
              <FieldSaleGuard>{BUTTON_CTAS.KEEP_SHOPPING}</FieldSaleGuard>
              <FieldSaleGuard positiveShow>{BUTTON_CTAS.FIELD_SALES_CTA}</FieldSaleGuard>
            </AddMoreServicesButton>
          </div>
        </>
      )}
    </>
  );
};


AddMoreServicesButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  fitContent: PropTypes.bool,
};

AddMoreServicesButton.defaultProps = { onClick: () => {} };

AddAnotherServiceBlock.propTypes = {
  canAddAnotherService: PropTypes.bool,
  isPartner: PropTypes.bool,
  onServiceClick: PropTypes.func,
  onProductClick: PropTypes.func,
  classes: PropTypes.string,
};

export default AddAnotherServiceBlock;

/*
  This is the add another service/product and plan buttons block (originally in regular cartpage,
  and is now being used in two places.
  It was best to break it out.
*/
