/**
 * Key refers to the "parent" sku. The value array is the "sku recommendations" that are associated with it.
 * You night have issue locally as these are "prod" mappings. So, just add yours for a local test (dont' forget
 * to remove when done).
 *
 * @type {Map<number, number[]>}
 * @type {Map<ParentSku, AssociatedSkuRecommendations[]>}
 */
export const skuRecommendations = new Map([
  [426, [1312, 1254, 1297]],
  [613, [1203, 1254, 1203]],
  [1199, [1203, 1254, 1347]],
  [1200, [1203, 1324, 1204]],
  [1220, [1203, 1254, 1347]],
  [1312, [1203, 1254, 1347]],
  [1326, [1203, 1324, 1204]],
  [1349, [1312, 1254, 1347]],
  [1351, [1312, 1254, 1347]],
  [1024, [1205, 1204, 1326]],
  [1194, [1205, 1204, 1326]],
  [200, [1203, 1312, 1396]],
  [1207, [1203, 1326, 1205]],
  [1208, [1203, 1326, 1205]],
  [1209, [1203, 1326, 1205]],
  [1210, [1203, 1326, 1205]],
  [1252, [1203, 1312, 1396]],
  [1254, [1203, 1312, 1396]],
  [1256, [1203, 1312, 1396]],
  [1293, [1203, 1312, 1396]],
  [1314, [1203, 1312, 1396]],
  [1316, [1203, 1312, 1396]],
  [1318, [1203, 1312, 1396]],
  [1320, [1203, 1312, 1396]],
  [1322, [1203, 1312, 1396]],
  [1324, [1203, 1312, 1396]],
  [1347, [1203, 1312, 1396]],
  [411, [1326, 1201, 1324]],
  [1297, [1326, 1201, 1208]],
  [1299, [1326, 1201, 1208]],
  [1301, [1326, 1201, 1208]],
  [1353, [1326, 1201, 1208]],
  [1355, [1326, 1201, 1208]],
  [416, [1197, 1297, 1205]],
  [1201, [1197, 1297, 1205]],
  [415, [1222, 1396, 1312]],
  [1203, [1326, 1324, 1197]],
  [1204, [1326, 1324, 1197]],
  [1222, [1230, 1234, 1238]],
  [1224, [1232, 1236, 1240]],
  [1226, [1222, 1396, 1203]],
  [1228, [1222, 1396, 1203]],
  [1230, [1222, 1396, 1203]],
  [1232, [1222, 1396, 1203]],
  [1234, [1222, 1396, 1203]],
  [1236, [1222, 1396, 1203]],
  [1238, [1222, 1396, 1203]],
  [1240, [1222, 1396, 1203]],
  [1242, [1222, 1396, 1203]],
  [1244, [1222, 1396, 1203]],
  [1289, [1222, 1396, 1203]],
  [1291, [1222, 1396, 1203]],
  [612, [1326, 1297, 1204]],
  [1195, [1312, 1297, 1203]],
  [1196, [1326, 1297, 1204]],
  [1197, [1326, 1297, 1204]],
  [1211, [1312, 1297, 1204]],
  [1212, [1312, 1297, 1204]],
  [1213, [1326, 1297, 1204]],
  [1214, [1326, 1297, 1204]],
  [1357, [1361, 1297, 1204]],
  [1359, [1326, 1297, 1204]],
  [1205, [1326, 1324, 1297]],
  [1258, [1326, 1324, 1297]],
  [1260, [1326, 1324, 1297]],
  [1262, [1326, 1324, 1297]]
]);
