import { UPDATE_TRACKING, UPDATE_TRACKING_BY_UPSELL, UPSELL_TRACKING } from '../constants/tracking';

// eslint-disable-next-line import/prefer-default-export
export const updateTracking = (params) => ({
  type: UPDATE_TRACKING,
  payload: { params },
});

const pathPatterns = {
  orderSummaryPage: /\/account\/orders\/.*/,
  skuPage: /\/tech-support\/*/,
  cartBookingFlow: /\/cart\/booking\/.*/,
  cartPage: /\/cart/,
  orderConfirmationPage: /\/orders\/confirmation/,
  productPage: /\/product/,
  plansPage: /\/plans/,
  default: /\.*/,
};
const TRACKING_NAMES = {
  ORDER_IN_PROGRESS_UPSELL: 'ORDER_IN_PROGRESS_UPSELL',
  SKU_UPSELL: 'SKU_UPSELL',
  ORDER_REVIEW_UPSELL: 'ORDER_REVIEW_UPSELL',
  CART_UPSELL: 'CART_UPSELL',
  ORDER_CONFIRMATION_UPSELL: 'ORDER_CONFIRMATION_UPSELL',
  PRODUCT_UPSELL: 'PRODUCT_UPSELL',
  CLIENT_STANDALONE: 'CLIENT_STANDALONE',
  NO_PAGE_DERIVED: 'NO_PAGE_DERIVED',
  UNKNOWN_UPSELL: 'UNKNOWN_UPSELL',
};

export const deriveSegmentReferrer = (pathname) => {
  const segmentMap = [
    [pathPatterns.orderSummaryPage, TRACKING_NAMES.ORDER_IN_PROGRESS_UPSELL],
    [pathPatterns.skuPage, TRACKING_NAMES.SKU_UPSELL],
    [pathPatterns.cartBookingFlow, TRACKING_NAMES.ORDER_REVIEW_UPSELL],
    [pathPatterns.cartPage, TRACKING_NAMES.CART_UPSELL],
    [pathPatterns.orderConfirmationPage, TRACKING_NAMES.ORDER_CONFIRMATION_UPSELL],
    [pathPatterns.productPage, TRACKING_NAMES.PRODUCT_UPSELL],
    [pathPatterns.plansPage, TRACKING_NAMES.CLIENT_STANDALONE],
    [pathPatterns.default, TRACKING_NAMES.NO_PAGE_DERIVED],
  ];

  const [, context] = segmentMap.find(([pattern]) => pattern.test(pathname)) || [
    null,
    'UNKNOWN_UPSELL',
  ];
  return context;
};

export const deriveBEUpsellChannel = (pathname) => {
  const beMap = [
    [pathPatterns.orderSummaryPage, TRACKING_NAMES.ORDER_IN_PROGRESS_UPSELL],
    [pathPatterns.skuPage, TRACKING_NAMES.SKU_UPSELL],
    [pathPatterns.cartBookingFlow, TRACKING_NAMES.ORDER_REVIEW_UPSELL],
    [pathPatterns.cartPage, TRACKING_NAMES.CART_UPSELL],
    [pathPatterns.orderConfirmationPage, TRACKING_NAMES.ORDER_CONFIRMATION_UPSELL],
    [pathPatterns.productPage, TRACKING_NAMES.PRODUCT_UPSELL],
    [pathPatterns.plansPage, TRACKING_NAMES.CLIENT_STANDALONE],
    [pathPatterns.default, null], // Backend does not accept 'NO_PAGE_DERIVED'
  ];

  const [, context] = beMap.find(([pattern]) => pattern.test(pathname)) || [null, null];
  return context;
};

/**
 * Track when user see's, adds or removes upsell.
 * We are making a contract here that the caller needs to pass in the plan/partner.
 */
export const updateTrackingByUpsell = ({
  referrer = '',
  event = UPSELL_TRACKING.viewed,
  plan = {},
  partner = null,
}) => {
  const isFiredFromPlansPage = /\/plans/.test(window.location.pathname);
  // If no plan data or if fired from plans page, bone out
  if (!Object.keys(plan).length || isFiredFromPlansPage) return { type: UPDATE_TRACKING_BY_UPSELL };

  // If referrer is empty, we'll derive the referrer from the pathname
  const derivedReferrer = deriveSegmentReferrer(window.location.pathname);

  // we'll accept the data payload and consolidate the properties here
  const { amount, name, id, planId } = plan;
  const consolidatedProperties = {
    upsell_name: referrer || derivedReferrer,
    plan: {
      plan_id: id || planId,
      amount,
      name,
      partner,
    },
  };

  return {
    type: UPDATE_TRACKING_BY_UPSELL,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event,
            properties: consolidatedProperties,
          },
        },
      ],
    },
  };
};
