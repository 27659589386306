import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Hooks && Utils
import { noop } from 'src/utils/event';
import { useRecurringServicePanel } from './RecurringServicePanel.hooks';
// Constants
import {
  RECURRING_FREQUENCY_VALUES,
  MONTHLY_OPTION_TYPES,
  WEEKDAY_SHORT_NAMES,
  CUSTOM_MONTHLY_VIEW_TABS,
  MONTHLY_CALENDAR_GRID_CONFIG,
} from './RecurringServicePanel.constants';
// Components
import { PanelV2 } from '../HTKit/Elements/PanelV2';
import { CheckboxV2 } from '../HTKit/Forms/CheckboxV2';
import { Label } from '../HTKit/Forms/Parts';
import { InputFieldCounter } from '../HTKit/Forms/InputFieldCounter';
import Select from '../HTKit/Forms/SelectField';
import styles from './styles.scss';

export const RecurringServicePanel = ({
  isEditMode,
  cart,
  selectedDate, // YYYY-MM-DD
  selectedTimes,
  setRecurrence,
  useTimeWindows = false,
  showCheckbox = true,
  panelClassName,
  onPanelVisibilityChange = () => {},
}) => {
  const {
    isOpen,
    setIsOpen,
    frequency,
    interval,
    weeklyDays,
    startTime,
    daysOfTheMonth,
    monthlyCustom,
  } = useRecurringServicePanel({
    isEditMode,
    cart,
    selectedDate,
    selectedTimes,
    setRecurrence,
    useTimeWindows,
    onPanelVisibilityChange,
  });
  const { recurringFrequency, setRecurringFrequency, optionsRecurringFrequency } = frequency;
  const { selectedWeeklyDays, handleWeeklyDaySelection } = weeklyDays;
  const { recurrenceInterval, handleIntervalChange, minValueInterval } = interval;
  const { selectedStartTime, setSelectedStartTime, optionsStartTime } = startTime;
  const {
    monthlyRecurrenceOption,
    setMonthlyRecurrenceOption,
    optionsMonthlyDaysOfTheMonth,
  } = daysOfTheMonth;
  const { customMenuView, setCustomMenuView, specificDays, ordinal } = monthlyCustom;
  const { monthlyCustomDays, handleMonthlyCustomDaySelection } = specificDays;
  const {
    optionsMonthlyCustomOrdinal,
    optionsMonthlyCustomWeekdays,
    monthlyCustomOrdinal,
    setMonthlyCustomOrdinal,
    monthlyCustomWeekdays,
    setMonthlyCustomWeekdays,
  } = ordinal;

  const panelStyles = cn(
    styles.panel,
    {
      [styles.active]: isOpen,
    },
    panelClassName,
  );

  /** Necessary to real-time update week to weeks or month to months when interval > 1 */
  const recurringFrequencySelectValue =
    optionsRecurringFrequency.find((f) => f.value === recurringFrequency.value) ??
    recurringFrequency;

  const isWeekly = recurringFrequency?.value === RECURRING_FREQUENCY_VALUES.WEEKLY;
  const isMonthly = recurringFrequency?.value === RECURRING_FREQUENCY_VALUES.MONTHLY;
  const isCustom = isMonthly && monthlyRecurrenceOption?.value === MONTHLY_OPTION_TYPES.CUSTOM;

  return (
    <PanelV2 noShadow largeBorderRadius className={panelStyles}>
      {showCheckbox && (
        <CheckboxV2
          checked={isOpen}
          value={isOpen}
          label="Schedule recurring services"
          onCheckboxChange={() => setIsOpen(!isOpen)}
        />
      )}
      {isOpen && (
        <div>
          {/* This is for spacing between the checkbox and content */}
          {showCheckbox && <div className="marginBottom-small" />}

          <div className="flex alignItemsFlexEnd">
            <InputFieldCounter
              key={recurrenceInterval}
              startValue={recurrenceInterval}
              minValue={minValueInterval}
              onChange={handleIntervalChange}
              className="marginRight-tiny1"
              label="Repeat every"
            />
            <Select
              value={recurringFrequencySelectValue}
              onChange={setRecurringFrequency}
              options={optionsRecurringFrequency}
              className={styles.frequency}
            />
          </div>

          <div className={styles.spacer} />

          {isWeekly && (
            <>
              <div>
                <Label label="Days of the week" />
                <div className={styles.weeklyDaySelectionContainer}>
                  {WEEKDAY_SHORT_NAMES.map((day) => {
                    const boxStyle = cn('plainButton', styles.daySelectionBox, {
                      [styles.active]: selectedWeeklyDays.includes(day.value),
                    });
                    return (
                      <button
                        type="button"
                        className={boxStyle}
                        key={day.value}
                        onClick={handleWeeklyDaySelection(day.value)}
                      >
                        <p className="caption">{day.label}</p>
                      </button>
                    );
                  })}
                </div>
              </div>
              <div className={styles.spacer} />
            </>
          )}

          {isMonthly && (
            <>
              <div>
                <Label label="Days of the month" />
                <Select
                  value={monthlyRecurrenceOption}
                  onChange={setMonthlyRecurrenceOption}
                  options={optionsMonthlyDaysOfTheMonth}
                />
              </div>
              <div className={styles.spacer} />
            </>
          )}

          {isCustom && (
            <div className="marginBottom-small1">
              <div className={styles.customMenuTabContainer}>
                <button
                  type="button"
                  onClick={() => setCustomMenuView(CUSTOM_MONTHLY_VIEW_TABS.ON_THE_ORDINAL)}
                  className={cn('plainButton p2', styles.menuTab, {
                    [styles.active]: customMenuView === CUSTOM_MONTHLY_VIEW_TABS.ON_THE_ORDINAL,
                  })}
                >
                  On the...
                </button>
                <button
                  type="button"
                  onClick={() => setCustomMenuView(CUSTOM_MONTHLY_VIEW_TABS.SPECIFIC_DAYS)}
                  className={cn('plainButton p2', styles.menuTab, {
                    [styles.active]: customMenuView === CUSTOM_MONTHLY_VIEW_TABS.SPECIFIC_DAYS,
                  })}
                >
                  Select specific dates
                </button>
              </div>

              {customMenuView === CUSTOM_MONTHLY_VIEW_TABS.ON_THE_ORDINAL && (
                <section className="flex">
                  <Select
                    value={monthlyCustomOrdinal}
                    onChange={setMonthlyCustomOrdinal}
                    options={optionsMonthlyCustomOrdinal}
                    className={cn(styles.monthlyCustomSelectOption, 'marginRight-tiny1')}
                  />
                  <Select
                    value={monthlyCustomWeekdays}
                    onChange={setMonthlyCustomWeekdays}
                    options={optionsMonthlyCustomWeekdays}
                    className={styles.monthlyCustomSelectOption}
                  />
                </section>
              )}
              {customMenuView === CUSTOM_MONTHLY_VIEW_TABS.SPECIFIC_DAYS && (
                <section className={cn(styles.monthlyDaySelectionContainer)}>
                  {MONTHLY_CALENDAR_GRID_CONFIG.map((day) => {
                    const isDisabledBox = day.value < 0;
                    const boxStyle = cn('plainButton', styles.daySelectionBox, {
                      [styles.active]: monthlyCustomDays.includes(day.value),
                      [styles.disabled]: isDisabledBox,
                    });
                    const onClickCallback = isDisabledBox
                      ? noop
                      : handleMonthlyCustomDaySelection(day.value);
                    return (
                      <button
                        type="button"
                        className={boxStyle}
                        key={day.value}
                        onClick={onClickCallback}
                      >
                        <p className="caption">{day.label}</p>
                      </button>
                    );
                  })}
                </section>
              )}
            </div>
          )}

          <div>
            <Label label="Start time" />
            <Select
              value={selectedStartTime}
              onChange={setSelectedStartTime}
              options={optionsStartTime}
            />
          </div>
          <div className={styles.spacer} />
        </div>
      )}
    </PanelV2>
  );
};

RecurringServicePanel.propTypes = {
  selectedDate: PropTypes.string, // YYYY-MM-DD
  selectedTimes: PropTypes.arrayOf(PropTypes.string).isRequired,
  isEditMode: PropTypes.bool,
  cart: PropTypes.object.isRequired,
  setRecurrence: PropTypes.func.isRequired,
  useTimeWindows: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  panelClassName: PropTypes.string,
  onPanelVisibilityChange: PropTypes.func,
};
