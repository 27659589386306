import React, { useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { toggleSidebar, toggleSidebarWithCategory } from 'src/components/Sidebar/actions';
import { toggleTopnav } from 'src/components/Header/TopNavHeader/actions';
import { isMobileV2, isTabletV2 } from 'src/utils/ui';
import { CATEGORY_MENU } from 'src/components/Sidebar/constants';
import {
  SERVICES_MENU,
} from 'src/components/Header/TopNavHeader/constants';

const withToggleSiteNav = () => (Component) =>
  connect(() => ({}), {
    toggleTopnavFn: toggleTopnav,
    toggleSidebarFn: toggleSidebar,
    toggleSidebarWithCategoryFn: toggleSidebarWithCategory,
  })(
    ({
      toggleTopnavFn,
      toggleSidebarFn,
      toggleSidebarWithCategoryFn,
      ...rest
    }) => {
      const ref = useRef();

      const topNavFunctions = {
        [SERVICES_MENU]: toggleTopnavFn,
      };

      const toggleSiteNav = useCallback(
        ({
          sidebarCategory = CATEGORY_MENU,
          topNavCategory = SERVICES_MENU,
          sidebarPrimaryMenu = false,
        } = {}) => {
          let toggleFn = topNavFunctions[topNavCategory];
          if (isMobileV2() || isTabletV2()) {
            toggleFn = sidebarPrimaryMenu
              ? () => toggleSidebarFn
              : () => toggleSidebarWithCategoryFn({ menuOnOpen: sidebarCategory });
          }
          toggleFn();
        },
        [],
      );

      return <Component toggleSiteNav={toggleSiteNav} {...rest} ref={ref} />;
    },
  );

export default withToggleSiteNav;
