const AMAZON_TRACKING_VALUES = Object.freeze({
  "smart-home": {
    attributeLink: 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3D5144dd8b-9d2e-1f1f-981c-841ef17f8278%26type%3D55%26m%3D1&ex-fch=416613&ex-src=https://www.hellotech.com/tech-support/for/smart-home&ex-hargs=v%3D1.0%3Bc%3D7966137420201%3Bp%3D5144DD8B-9D2E-1F1F-981C-841EF17F8278',
    pixelLink: 'https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D5144dd8b-9d2e-1f1f-981c-841ef17f8278%26type%3D55%26m%3D1&ex-fch=416613&ex-src=https://www.hellotech.com/tech-support/for/smart-home&ex-hargs=v%3D1.0%3Bc%3D7966137420201%3Bp%3D5144DD8B-9D2E-1F1F-981C-841EF17F8278',
    pixelId: '_pix_id_5144dd8b-9d2e-1f1f-981c-841ef17f8278',
  },
  "tv-mounting": {
    attributeLink: 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3D52908486-55af-656d-42d3-575536ed3911%26type%3D55%26m%3D1&ex-fch=416613&ex-src=https://www.hellotech.com/tech-support/for/tv-mounting&ex-hargs=v%3D1.0%3Bc%3D7966137420201%3Bp%3D52908486-55AF-656D-42D3-575536ED3911',
    pixelLink: 'https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D52908486-55af-656d-42d3-575536ed3911%26type%3D55%26m%3D1&ex-fch=416613&ex-src=https://www.hellotech.com/tech-support/for/tv-mounting&ex-hargs=v%3D1.0%3Bc%3D7966137420201%3Bp%3D52908486-55AF-656D-42D3-575536ED3911',
    pixelId: '_pix_id_52908486-55af-656d-42d3-575536ed3911',
  },
  "confirmation_page": {
    attributeLink: 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3D2a5aaf7c-2383-dc1f-0887-cb75b4fe4f36%26type%3D30%26m%3D1&ex-fch=416613&ex-src=https://www.hellotech.com/&ex-hargs=v%3D1.0%3Bc%3D7966137420201%3Bp%3D2A5AAF7C-2383-DC1F-0887-CB75B4FE4F36',
    pixelLink: 'https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D2a5aaf7c-2383-dc1f-0887-cb75b4fe4f36%26type%3D30%26m%3D1&ex-fch=416613&ex-src=https://www.hellotech.com/&ex-hargs=v%3D1.0%3Bc%3D7966137420201%3Bp%3D2A5AAF7C-2383-DC1F-0887-CB75B4FE4F36',
    pixelId: '_pix_id_2a5aaf7c-2383-dc1f-0887-cb75b4fe4f36',
  },
  "home_page": {
    attributeLink: 's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3D49ee5656-8a8b-e152-086d-68d06743ae3e%26type%3D55%26m%3D1&ex-fch=416613&ex-src=https://www.hellotech.com/&ex-hargs=v%3D1.0%3Bc%3D7966137420201%3Bp%3D49EE5656-8A8B-E152-086D-68D06743AE3E',
    pixelLink: 'https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D49ee5656-8a8b-e152-086d-68d06743ae3e%26type%3D55%26m%3D1&ex-fch=416613&ex-src=https://www.hellotech.com/&ex-hargs=v%3D1.0%3Bc%3D7966137420201%3Bp%3D49EE5656-8A8B-E152-086D-68D06743AE3E',
    pixelId: '_pix_id_49ee5656-8a8b-e152-086d-68d06743ae3e',
  }
});

export default AMAZON_TRACKING_VALUES;

/* Tracking pixels information
  When additional requirement comes in to place tracking pixels in pages, add the values here and refer to AmazonTrackingPixels.js for more information.
*/