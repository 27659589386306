import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { injectStripe } from 'react-stripe-elements';
import { DefaultMetaTags } from 'src/components/SEO';
// Constants
import { PAYMENT_TYPES } from 'src/containers/BookingPage/PaymentPage/constants';
// Redux Actions
import { loadPage } from 'src/actions/common';
import {
  submitPayment,
  submitExistingPayment,
  paymentEditMode,
  submitPaymentType,
} from 'src/containers/BookingPage/PaymentPage/actions';
// Selectors
import pageSelectors from 'src/containers/BookingPage/PaymentPage//selectors';
// Utils
import { displayErrors } from 'src/utils/request';
import { logger } from 'src/utils/logger';
import { getFormattedPlanTrialExpirationDate } from 'src/containers/CartPage/utils';
// Components
import SecurePayment from 'src/components/SecurePayment';
import Grid from 'src/components/HTKit/Grid/Grid';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { PaymentPageSubtitle } from 'src/components/PaymentPageSubtitle';
import PaymentForm, { submitPaymentForm } from 'src/components/PaymentForm';
import { AffirmPaymentChoice } from 'src/components/Affirm';
import { CalloutBox, CALLOUTBOX_THEMES } from 'Elements/CalloutBox';
import PaymentCard from './PaymentCard';
import PaymentChoices from './PaymentChoices';
import { MembershipRenewal } from './Parts';
import styles from './styles.scss';

/**
 * @typedef {import('src/containers/CartPage/CartTypes/RegularCart/RegularCart.js').PlanTrialData} PlanTrialData
 *
 * @param {{planTrialData: PlanTrialData}} param0
 */
const getTrialMembershipCalloutText = ({ planTrialData = {} }) => {
  if (!planTrialData.expiresAt || !planTrialData.days)
    return { header: 'Membership Trial', text: ' ' };
  const formattedExpDate = getFormattedPlanTrialExpirationDate(planTrialData);
  return {
    header: 'Membership Trial',
    text: `You will only be charged for membership after your ${planTrialData.days} day free trial ends on ${formattedExpDate} unless you cancel before the first billing cycle.`,
  };
};

class PaymentPage extends React.Component {
  static propTypes = {
    cart: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    stripe: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    currentStage: PropTypes.string,
    loadPage: PropTypes.func.isRequired,
    submitPayment: PropTypes.func.isRequired,
    submitExistingPayment: PropTypes.func.isRequired,
    paymentEditMode: PropTypes.func.isRequired,
    subscription: PropTypes.object,
    showMembershipRenewal: PropTypes.bool,
    isMobileOrTablet: PropTypes.bool,
    isMobile: PropTypes.bool,
    affirmEligible: PropTypes.bool,
    submitPaymentType: PropTypes.func.isRequired,
    paymentType: PropTypes.string.isRequired,
    plan: PropTypes.object,
  };

  state = {
    number: '',
    cvc: '',
    zip: '',
    expiration: '',
    willOptInValues: [],
    paymentType: '',
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (this.props.currentStage === 'payment') {
      this.props.loadPage('payment');
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { paymentType } = nextProps;

    if (!this.state.paymentType && paymentType) {
      this.setPaymentType(paymentType);
    }
  }

  onValueChange = (field) => {
    return (data) => {
      const { value } = data.target;
      this.setState({ [field]: value });
    };
  };

  setPaymentType = (paymentType) => {
    this.setState({
      paymentType,
    });
  };

  allFieldsFilledIn = () => {
    return this.state.number && this.state.cvc && this.state.expiration && this.state.zip;
  };

  handlePaymentSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    submitPaymentForm(this.props.dispatch, this.props.stripe, {
      name: this.props.cart.get('clientName'),
    }).then(
      (token) => {
        if (process.NODE_ENV !== 'production') {
          console.log('Received Stripe token:', token); // eslint-disable-line
        }
        const canOptIn = this.props.showMembershipRenewal;
        const willOptIn = !!this.state.willOptInValues.length;
        this.props.submitPayment(token, { canOptIn, willOptIn }, this.props.cart);
      },
      (error) => {
        if (error) {
          this.props.dispatch(displayErrors({ data: { errors: [error.message] } }));
          logger(`Payment Page`)(error);
          console.log(error); // eslint-disable-line
        }
      },
    );
  };

  submitPaymentType = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.props.submitPaymentType(this.state.paymentType);
  };

  submitExistingPayment = () => {
    this.props.submitExistingPayment();
  };

  editPaymentInformation = (event) => {
    event.preventDefault();
    this.props.paymentEditMode(true);
  };

  cancelEdit = (event) => {
    event.preventDefault();
    this.props.paymentEditMode(false);
  };

  onOptInChange = (values) => {
    this.setState({ willOptInValues: values });
  };

  renderButton = () => {
    const { page, cart } = this.props;
    const isAffirmPaymentChoice = this.isAffirmPaymentChoice();
    const isEditMode = page.get('editMode');
    const isSummary = cart.get('status') === 'summary';
    const title =
      isAffirmPaymentChoice || isEditMode || !isSummary ? 'Continue to Order Review' : 'Save';
    let handleClick = isEditMode ? this.handlePaymentSubmit : this.submitExistingPayment;

    if (isAffirmPaymentChoice) {
      handleClick = this.submitPaymentType;
    }

    return (
      <>
        <p className="text-align-center p2 n700 marginBottom-tiny1">
          You&apos;ll be able to review this order before it&apos;s final
        </p>
        <Button theme={THEMES.V2PRIMARY} onClick={handleClick} className={styles.bookingButton}>
          {title}
        </Button>
      </>
    );
  };

  renderCardButton = () => {
    const { cart } = this.props;
    return (
      cart.get('card') && (
        <Button
          theme={THEMES.V2PRIMARY}
          onClick={this.submitExistingPayment}
          className={styles.bookingButton}
        >
          Use this card
        </Button>
      )
    );
  };

  getPaymentType = () => {
    return this.state.paymentType || this.props.paymentType || PAYMENT_TYPES.card;
  };

  isAffirmPaymentChoice = () => {
    return this.getPaymentType() === PAYMENT_TYPES.affirm;
  };

  render() {
    const {
      cart,
      page,
      currentStage,
      isMobileOrTablet,
      isMobile,
      subscription,
      showMembershipRenewal,
      affirmEligible,
      plan,
    } = this.props;
    if (currentStage !== 'payment' || page.get('loading')) {
      return null;
    }

    const isEditMode = page.get('editMode');
    const card = cart.get('card');
    const affirmPaymentChoice = this.isAffirmPaymentChoice();
    const planTrialData = plan && plan.trial;

    const containerStyles = cn({
      [styles.addPaymentContainer]: isEditMode && card && isMobileOrTablet,
    });

    const trialMembershipCalloutText =
      planTrialData && getTrialMembershipCalloutText({ planTrialData });

    return (
      <Grid.FullWidth classes={containerStyles}>
        <DefaultMetaTags title="Payment" />
        <div className={styles.sectionHeaderWrapper}>
          <h4 className={`${styles.pageTitle}`}>Payment</h4>

          {planTrialData && (
            <CalloutBox
              className="marginTop-medium marginBottom-small1 text-align-left"
              theme={CALLOUTBOX_THEMES.PRIMARY}
              {...trialMembershipCalloutText}
            />
          )}

          {!affirmPaymentChoice && <PaymentPageSubtitle />}
        </div>
        {affirmEligible && (
          <PaymentChoices
            paymentType={this.getPaymentType()}
            setPaymentType={this.setPaymentType}
          />
        )}
        {affirmPaymentChoice ? (
          <>
            <AffirmPaymentChoice />
            <div className={styles.buttonWrapper}>{this.renderButton()}</div>
          </>
        ) : (
          <>
            {isEditMode && (
              <div className="form">
                <section>
                  {this.props.stripe && <PaymentForm />}
                  {
                    <MembershipRenewal
                      showMembershipRenewal={showMembershipRenewal}
                      subscription={subscription}
                      willOptInValues={this.state.willOptInValues}
                      onOptInChange={this.onOptInChange}
                    />
                  }
                </section>

                <div className={styles.buttonWrapper}>
                  {!isMobileOrTablet && this.renderButton()}
                  {!isMobileOrTablet && card && (
                    <p className={`p1 ${styles.edit}`}>
                      <a href className="text-link -no-decoration" onClick={this.cancelEdit}>
                        Use existing card
                      </a>
                    </p>
                  )}
                </div>
              </div>
            )}
            {!isEditMode && card && (
              <PaymentCard
                onClick={this.submitExistingPayment}
                card={card.toJS()}
                onEditPaymentClick={this.editPaymentInformation}
              />
            )}
            {isMobile && isEditMode && (
              <SecurePayment containerStyle={styles.securePaymentWrapper} />
            )}

            <div className={styles.buttonWrapper}>
              {isEditMode && card && isMobileOrTablet && (
                <div className="form-item">
                  <p className={`p1 ${styles.existingCard}`}>
                    <a href className="text-link -no-decoration" onClick={this.cancelEdit}>
                      Use existing card
                    </a>
                  </p>
                </div>
              )}
              {isMobileOrTablet && isEditMode && this.renderButton()}
              {!isMobile && isEditMode && (
                <SecurePayment containerStyle={styles.securePaymentWrapper} />
              )}
            </div>
          </>
        )}
      </Grid.FullWidth>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    loadPage: (...args) => dispatch(loadPage(...args)),
    submitPayment: (...args) => dispatch(submitPayment(...args)),
    submitPaymentType: (...args) => dispatch(submitPaymentType(...args)),
    submitExistingPayment: (...args) => dispatch(submitExistingPayment(...args)),
    paymentEditMode: (...args) => dispatch(paymentEditMode(...args)),
  };
}

export default injectStripe(connect(pageSelectors, mapDispatchToProps)(PaymentPage));
