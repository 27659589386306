import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
// Hooks && Utils
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useAPI from 'src/hooks/useAPI';
import { logger } from 'src/utils/logger';
// Actions
import { addAppSnackNotice } from 'src/actions/snackNotice';
import { push, evPortalPath } from 'src/utils/paths';
// Constants
import { WORKFLOW_STATUS } from '../EV/ev.constants';
import { TEMPLATE_NAMES } from 'src/components/Snackbar/constants';
// Selectors
import { pureUserSelector } from 'src/selectors/user';

/**
 * List of permitted paths for the workflow.
 * Add more paths to this array as needed.
 *
 * The function `isPermittedPath` uses this array to dynamically create a Regular Expression
 * to test against the current location path. This ensures that the system recognizes
 * the URL as a part of the "walled-garden" workflow experience.
 *
 * The Regular Expression also covers sub-routes, e.g., "/ev/sub-route" will be recognized.
 *
 * Example Usage:
 * --------------
 * To add another permitted path, simply add it to the array like so:
 *
 *  export const PERMITTED_PATHS = ['ev', 'newroute'];
 */
export const PERMITTED_PATHS = ['ev', 'account'];

const isPermittedPath = (path) => {
  const joinedPaths = PERMITTED_PATHS.join('|');
  const regex = new RegExp(`^\\/(${joinedPaths})(\\/|$)`);

  return regex.test(path);
};

const DEFAULT_SNACK_CONFIG = {
  template: TEMPLATE_NAMES.MANAGE_WORKFLOW,
  content: '',
  autoClose: false,
  dismissable: true,
  status: 'success',
};

export const getSnackConfig = (options = {}) => {
  return { ...DEFAULT_SNACK_CONFIG, ...options };
};

export const fetchLastWorkflowForCurrentUser = async (api) => {
  try {
    const result = await api.workflows.last();
    if (result.err) throw result;

    // If user has no workflows, it will return an empty object
    return result.data.workflow;
  } catch (e) {
    logger('fetchActiveWorkflows')(e);
    return null;
  }
};

/**
 * useWorkflowManager
 *
 * Purpose:
 * --------
 * Manages the workflow experience by creating a "walled-garden" for users actively participating in workflows.
 * The hook ensures that if a user navigates outside of the permitted paths related to active workflows, they
 * will be notified via a snackbar.
 *
 * Important Note:
 * ----------------
 * This hook is specifically designed to manage workflow constraints and notifications for users.
 * It fetches active workflow data from the API and determines whether to show a snackbar based on
 * the user's current route and their involvement in an active workflow.
 */
export const useWorkflowManager = () => {
  const [workflow, setWorkflow] = useState({ status: null, token: null });
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [hasShownSnackbar, setHasShownSnackbar] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  const api = useAPI();
  const user = useSelector(pureUserSelector);
  const isUserLoggedIn = user ? Boolean(user.id) : false;

  const goToEvPortal = () => {
    const params = { auth_token: user.authToken };
    dispatch(
      push({
        pathname: evPortalPath(workflow.token),
        search: new URLSearchParams(params).toString(),
      }),
    );
  };
  const openSnackbarComponent = (opts = {}) => {
    const snackConfig = getSnackConfig({
      templateData: { onLinkClick: goToEvPortal },
      ...opts,
    });
    dispatch(addAppSnackNotice(snackConfig));
  };

  const shouldFetchWorkflowData =
    isUserLoggedIn && !isPermittedPath(location.pathname) && !hasShownSnackbar;
  useEffect(() => {
    const fetchData = async () => {
      const fetchedWorkflow = await fetchLastWorkflowForCurrentUser(api);
      setWorkflow(fetchedWorkflow);

      const hasActiveWorkflow =
        !isEmpty(fetchedWorkflow) &&
        ![WORKFLOW_STATUS.COMPLETED, WORKFLOW_STATUS.FEEDBACK].includes(fetchedWorkflow.status);

      if (hasActiveWorkflow) {
        setShowSnackbar(true);
        setHasShownSnackbar(true);
      }
    };

    if (shouldFetchWorkflowData) {
      fetchData();
    }
  }, [shouldFetchWorkflowData]);

  useEffect(() => {
    if (showSnackbar) {
      openSnackbarComponent();
    }

    return () => {
      setShowSnackbar(false);
    };
  }, [showSnackbar]);

  return { workflow, showSnackbar };
};
