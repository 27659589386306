import { fromJS } from 'immutable';
import { SPEEDTEST_RESULTS_RECEIVED, SET_SKU_DATA } from './constants';

const initialState = fromJS({ isSpeedtestComplete: false, skus: [] });

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SPEEDTEST_RESULTS_RECEIVED:
      return state.set('isSpeedtestComplete', true);
    case SET_SKU_DATA:
      return state.set('skus', action.skus);
    default:
      return state;
  }
};

export default pageReducer;
