import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import AddAnotherServiceBlock from 'src/containers/CartPage/Parts/AddAnotherServiceBlock';
import { PromoCode, ServicesBreakdown } from 'src/containers/CartPage/Parts';
import { BUTTON_CTAS } from 'src/containers/CartPage/constants';
import styles from './styles.scss';
import HourlyPriceTypeDisclaimer from 'src/components/Cart/Disclaimers/HourlyPriceDisclaimer';
import { atLeastOnePriceTypeHourlySelector } from 'src/selectors/cart';

const StandardTab = ({
  cart,
  showStandardTab,
  removeItem,
  goToAnotherService,
  goToProductsShop,
  goToEditSku,
  startBooking,
  canAddAnotherService,
}) => {
  const showHourlyDisclaimer = useSelector(atLeastOnePriceTypeHourlySelector);
  const containerStyles = classNames(styles.container, {
    [styles.hide]: !showStandardTab,
  });
  const isPartner = Boolean(cart.get('partner'));

  return (
    <section className={containerStyles}>
      <ServicesBreakdown
        cart={cart}
        removeItem={removeItem}
        goToEditSku={goToEditSku}
        isPartner={isPartner}
      />

      {/* Desktop only block */}
      <AddAnotherServiceBlock
        onServiceClick={goToAnotherService}
        onProductClick={goToProductsShop}
        canAddAnotherService={canAddAnotherService}
        isPartner={isPartner}
        classes="paddingTop-small paddingBottom-medium showDesktop"
      />

      {/* Mobile/Tablet only block */}
      <div className="showMobileTablet">
        <Button onClick={goToAnotherService} mediumV2 theme={THEMES.V2SECONDARY}>
          {BUTTON_CTAS.KEEP_SHOPPING}
        </Button>
        <Button
          onClick={startBooking}
          mediumV2
          theme={THEMES.V2PRIMARY}
          className={styles.itemSpacer}
        >
          Checkout
        </Button>
        <HourlyPriceTypeDisclaimer showHourlyDisclaimer={showHourlyDisclaimer} />
        <PromoCode wrapperStyle={styles.itemSpacer} copySize="p2" />
      </div>
    </section>
  );
};

StandardTab.propTypes = {
  cart: PropTypes.object,
  showStandardTab: PropTypes.bool.isRequired,
  removeItem: PropTypes.func.isRequired,
  startBooking: PropTypes.func.isRequired,
  goToAnotherService: PropTypes.func.isRequired,
  goToProductsShop: PropTypes.func.isRequired,
  goToEditSku: PropTypes.func.isRequired,
  canAddAnotherService: PropTypes.bool,
};

export default StandardTab;
