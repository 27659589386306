import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Components
import HTLogo from 'src/components/Header/Parts/HTLogo';
import ActionButtons from 'src/containers/FieldSales/Header/ActionButtons';
import NewSalesOrderModal from 'src/containers/FieldSales/Modals/NewSalesOrderModal';
import EndedSessionModal from 'src/containers/FieldSales/Modals/EndedSessionModal';
// Utils / constants
import { fieldSalesSkuListPath, fieldSalesPath } from 'src/utils/paths';
import {
  AUTH_EXCLUSIONS,
  MODAL_ENDED_SESSION,
  MODAL_NEW_SALES_ORDER,
} from 'src/containers/FieldSales/constants';
import { getFieldSalesCookieById } from 'src/utils/cookies/fieldSalesCookie';
// Selectors
import {
  fieldSaleAgentIdSelector,
  fieldSalesModalSelector,
} from 'src/containers/FieldSales/selectors';
// Actions
import { fieldSalesPathAction, fieldSalesModalAction } from 'src/containers/FieldSales/actions';
// Styles
import styles from './styles.scss';

const Header = () => {
  // hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const agentIdCart = useSelector(fieldSaleAgentIdSelector);
  const agentIdCookie = getFieldSalesCookieById();
  const modalNewSalesOrderBoolean = useSelector(fieldSalesModalSelector(MODAL_NEW_SALES_ORDER));
  const modalEndedSessionBoolean = useSelector(fieldSalesModalSelector(MODAL_ENDED_SESSION));
  /*
   * Verify user by active cart & cookie
   * It needs to be in sync. If its not, go to seller login and re-align
   */
  const verifiedUser = () => {
    return !!(agentIdCart && agentIdCookie);
  };

  const pathExclusion = () => {
    return AUTH_EXCLUSIONS.some((exclusion) => {
      return exclusion.includes(location.pathname);
    });
  };
  /*
   * Only a verified user can access the flow
   * */
  const onLoadPathCheck = () => {
    // did not pass check: goto seller sign in
    if (!verifiedUser() && !pathExclusion()) {
      dispatch(fieldSalesPathAction(fieldSalesPath));
    }
  };

  /*
   * Only a verified user and not on a path exclusion can
   * go to skuList page.
   * */
  const onClickPathCheck = () => {
    if (verifiedUser() && !pathExclusion()) {
      dispatch(fieldSalesPathAction(fieldSalesSkuListPath));
    }
  };
  /*
   * Redirect except with path exceptions.
   * Use current cart to determine validity.
   * */
  useEffect(() => {
    /* Check: user is not identified, move to seller signin */
    onLoadPathCheck();
  }, []);

  return (
    <div className={`htHeaderContainer ${styles.header_container}`}>
      <div onClick={() => onClickPathCheck()}>
        <HTLogo />
      </div>
      <ActionButtons />
      <NewSalesOrderModal
        visible={modalNewSalesOrderBoolean}
        onClose={() =>
          dispatch(fieldSalesModalAction({ name: MODAL_NEW_SALES_ORDER, visible: false }))
        }
      />
      <EndedSessionModal
        visible={modalEndedSessionBoolean}
        onClose={() =>
          dispatch(fieldSalesModalAction({ name: MODAL_ENDED_SESSION, visible: false }))
        }
      />
    </div>
  );
};

export default Header;
