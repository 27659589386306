import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useWithStyles from 'src/hooks/useWithStyles';
import styles from './styles.scss';

/**
 * import { Panel, PanelGroup } from 'src/components/HTKit/Elements/Panel';
 * Note: Wrap a group of panels with PanelGroup if you want to remove border-bottom
 *
 * USAGES:
 * <Panel><div>my content</div></Panel>
 *
 *  Various examples
 * <Panel bottomEdge><div>my content</div></BasePanel>
 * <Panel noMargins><div>my content</div></BasePanel>
 * <Panel large noMargins topEdge><div>my content</div></BasePanel>
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
const Panel = (props) => {
  const { className, children } = props;

  if (!children) return null;

  const panelClasses = cn(styles.panelcontainer, useWithStyles(props, styles), {
    [className]: className,
  });

  return <div className={panelClasses}>{children}</div>;
};

Panel.defaulProps = {
  className: '',
};

Panel.propTypes = {
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  topEdge: PropTypes.bool,
  bottomEdge: PropTypes.bool,
  shadow: PropTypes.bool,
  className: PropTypes.string,
  noMargins: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default React.memo(Panel);
