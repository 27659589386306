import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import GeoMap from 'src/utils/GeoMap';
import LazyView from 'src/components/LazyLoad/LazyView';
import { DefaultMetaTags } from 'src/components/SEO';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import ErrorPage from 'src/containers/ErrorPage';
import LegacyExpertInHomeService from 'src/components/Sections/legacy/ExpertInHomeService/index';
import LegacyFeaturedIn from 'src/components/Sections/legacy/FeaturedIn/index';
import LegacyCustomerReviews from 'src/components/Sections/legacy/CustomerReviews/lazy';
import LocationPopularServices from 'src/containers/LocationPage/Parts/lazyLocation';
import PageLoader from 'src/components/PageLoader';
import usePrevious from 'src/hooks/usePrevious';
import { LocationVideo, USToday } from './Parts';
import MapHeader from './Parts/MapHeader';
import LocationSku from './Parts/LocationSku';
import LocationsList from './Parts/LocationsList';
import LocationSkuCategory from './Parts/LocationSkuCategory';
import { PAGE_TYPES, PAGE_NAME } from './constants';

const LocationPage = ({
  loadPage,
  page,
  location,
  locationPath,
  phone,
  breadcrumbs,
  skuPage,
  childLocations,
  updatePhone,
  setSkuLinkPrefix,
}) => {
  const mapBoxRef = useRef();
  const [oldMap, setOldMap] = useState(null);
  const previousPageProps = usePrevious(page);

  const { splitLoaded, splitTreatment } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_PLAN_PRICING,
  );
  const isPlanPricingTest = splitTreatment === splitioConstants.ON;

  const updateMap = (locationProp) => {
    if (!mapBoxRef.current) return;
    const geoMap = new GeoMap();
    if (oldMap) {
      geoMap.clearLayers(oldMap);
    }
    const geoConfig = locationProp.getIn(['geoMap']).toJS();
    geoMap
      .getMap({
        element: mapBoxRef.current,
        mapType: 'mapbox.light',
        mapConfig: { zoomControl: false },
      })
      .then((map) => {
        setOldMap(map);
        geoMap.makeStrict(map).defineCenter(map, {
          coords: geoConfig.coords,
          zoom: geoConfig.zoom,
        });
      });
  };

  // Load Page
  useEffect(() => {
    if (process.browser && splitLoaded && page.get('loading')) {
      loadPage(PAGE_NAME, { locationPath, isPlanPricingTest });
    }

    return () => {
      if (process.browser) {
        updatePhone({ phone: null, soft: true });
        setSkuLinkPrefix(null);
      }
    };
  }, [splitLoaded, locationPath]);

  // Update map
  useEffect(() => {
    if (process.browser && previousPageProps) {
      const previousLoadingProp = previousPageProps.get('loading');
      const loadingProp = page.get('loading');
      if (!loadingProp && loadingProp !== previousLoadingProp) {
        updateMap(location);
      }
    }
  }, [page, location]);

  if (page.get('loading')) return <PageLoader />;
  if (page.get('error')) return <ErrorPage page={page} />;

  const pageType = location.get('pageType');
  const reviews = location.get('ratings');
  const title = location.get('title');
  const name = location.get('name');
  const suppressAddressLine = name !== 'US';

  return (
    <div className="site-v2">
      <DefaultMetaTags title={title} />

      {pageType === PAGE_TYPES.SKU && (
        <LocationSku
          breadcrumbs={breadcrumbs}
          location={location}
          phone={phone}
          skuPage={skuPage}
          mapBoxRef={mapBoxRef}
          isPlanPricingTest={isPlanPricingTest}
        />
      )}

      {pageType === PAGE_TYPES.SKU_CATEGORY && (
        <LocationSkuCategory phone={phone} breadcrumbs={breadcrumbs} location={location} />
      )}

      {pageType === PAGE_TYPES.GEO && (
        <>
          <MapHeader
            breadcrumbs={breadcrumbs}
            location={location}
            phone={phone}
            mapBoxRef={mapBoxRef}
          />
          <LocationVideo location={location} />
        </>
      )}

      <USToday />
      <LegacyFeaturedIn backgroundColor="white" />
      <LegacyExpertInHomeService backgroundColor="grey" />

      {pageType !== PAGE_TYPES.GEO && (
        <LocationVideo
          location={location}
          title={`HelloTech Proudly Services ${location.get('name')}`}
        />
      )}

      <LazyView source="REVIEWS">
        <LegacyCustomerReviews
          reviews={reviews}
          reviewCount={6}
          backgroundColor={pageType === PAGE_TYPES.GEO ? 'white' : 'grey'}
          title="What Your Neighbors Are Saying About HelloTech"
          viewMore={false}
          suppressAddressLine={suppressAddressLine}
        />
      </LazyView>

      {pageType === PAGE_TYPES.GEO && (
        <LazyView source="POPULAR SERVICES">
          <LocationPopularServices location={location} />
        </LazyView>
      )}

      <LazyView source="LOCATIONS">
        <LocationsList
          breadcrumbs={breadcrumbs}
          childLocations={childLocations}
          location={location}
          locationPath={locationPath}
        />
      </LazyView>
    </div>
  );
};

LocationPage.propTypes = {
  page: PropTypes.object.isRequired,
  skuPage: PropTypes.object.isRequired,
  location: PropTypes.object,
  locationPath: PropTypes.string,
  updatePhone: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
  setSkuLinkPrefix: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
  childLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LocationPage;
