import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { ItemWithCaret } from 'src/components/HTKit/Elements/Menu';
import NavItem from './NavItem';
import styles from './styles.scss';

const SubNavDropdown = ({ navItems, selectedItem, setSelectedItem }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const isHomepage = selectedItem.name === "My Connected Home";
  const handleToggle = () => {
    setShowDropdown(!showDropdown);
  };
  const handleSelect = (item) => {
    setSelectedItem(item);
    setShowDropdown(false);
  };

  return (
    <>
      <div className="flex justifyContentSpaceBetween alignItemsCenter width100">
        <NavItem
          item={navItems[0]}
          className={cn("flex alignItemsCenter overrideLinkStyles", styles.clickPaddingValue)}
          navStyles="overline-small n000"
        />
        <ItemWithCaret
          containerStyles={styles.clickPaddingValue}
          iconOrientation="right"
          iconName="chevron"
          iconStyles={cn(styles.chevron, 'teal')}
          iconOpenStyles={styles.chevronUp}
          isOpen={showDropdown}
          onClick={handleToggle}
          text={isHomepage ? 'Category' : selectedItem.name}
          textStyles={'overline-small teal'}
        />
      </div>
      {showDropdown && (
        <div className={styles.dropdown}>
          {navItems?.map(
            (item) =>
              !item.id &&
              !item.disabled && (
                <NavItem
                  onLinkClick={() => handleSelect(item)}
                  key={item.name}
                  className="overrideLinkStyles"
                  navStyles={cn(styles.navItemMobile, 'p1 n900')}
                  item={item}
                  currentItem={selectedItem}
                />
              ),
          )}
        </div>
      )}
    </>
  );
};

SubNavDropdown.propTypes = {
  setSelectedItem: PropTypes.func,
  selectedItem: PropTypes.object,
  navItems: PropTypes.array,
};

export default SubNavDropdown;
