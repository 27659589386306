/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.scss';

const ProductPrice = ({ startsAtPrice, cheapestPrice, hasSubscription, CustomDisplay }) => {
  const reviewStyles = cn('p2', styles.reviews);

  if (CustomDisplay) {
    return <CustomDisplay />;
  }

  if (!hasSubscription) {
    return (
      <p className={reviewStyles}>
        Starts at
        <span className={styles.serviceMemberPrice}>${cheapestPrice}</span>
      </p>
    );
  }

  return (
    <p className={reviewStyles}>
      Starts at
      <span className={styles.serviceMemberPrice}>${cheapestPrice}</span>
      <span className={styles.serviceRetailPrice}>{startsAtPrice}</span>
    </p>
  );
};

ProductPrice.propTypes = {
  cheapestPrice: PropTypes.shape({}),
  startsAtPrice: PropTypes.shape({}),
  hasSubscription: PropTypes.bool,
  rawDisplay: PropTypes.bool,
};

export default ProductPrice;
