import Cookies from 'js-cookie';

const SURVEY_SHOW_COOKIE_NAME = 'survey-monkey-shown';

export default {
  get shown() {
    return Cookies.get(SURVEY_SHOW_COOKIE_NAME) === 'true';
  },
  set shown(value) {
    Cookies.set(SURVEY_SHOW_COOKIE_NAME, value, { expires: 1 });
  },
};
