/**
 * Formats user initials for use with the Avatar component.
 *
 * The Avatar component expects a full name string, so this function
 * transforms initials into a format that mimics a full name's initials.
 * For example, "JD" becomes "J. D."
 *
 * @param {string} initials - The user's initials (1-2 characters)
 * @return {string} Formatted initials string
 */
export const formatInitialsForAvatar = (initials) => {
  if (!initials) return '';
  const firstInitial = `${initials[0]}.`;
  const secondInitial = initials[1] ? `${initials[1]}.` : '';
  return `${firstInitial} ${secondInitial}`.trim();
};
