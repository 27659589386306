import { select, call, put, takeEvery } from 'redux-saga/effects';
import { LOAD_PAGE } from 'src/constants/common';
import { pageLoaded } from 'src/actions/common';
import { selectRoutes } from 'src/apiRoutes';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { orderInfoLoaded } from 'src/actions/order';
import { logger } from 'src/utils/logger';
import { createWSConnection } from './TechTrackingWS/actions';

const PAGE_NAME = 'techMap';

export function* pageSaga({ orderId }) {
  const routes = yield call(selectRoutes);
  yield loadUserProfileSaga(PAGE_NAME, true);

  const orderList = yield select((state) => state.getIn(['entities', 'orders', 'list']));
  let orderInfo = orderList.find((order) => order.get('id') === orderId);

  if (orderInfo) {
    orderInfo = orderInfo.toJS();
  }

  if (!orderInfo || !orderInfo.breakdown) {
    const orderInfoResponse = yield call(routes.orders.info, { orderId }, { full: true });
    orderInfo = orderInfoResponse.data.order;
    yield put(orderInfoLoaded({ order: orderInfo }));
  }

  if (orderInfo.showTechTrackingMap) {
    yield put(createWSConnection());
  }
  yield put(pageLoaded(PAGE_NAME));
  logger('TechMapPage')('visited');
}

export function* techMapPageFlow() {
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
}

export default [
  techMapPageFlow,
];
