// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import cn from 'classnames';
import SlideModal from 'HTKit/Modals/Slide';
import LegacyCustomerReviews from 'src/components/Sections/legacy/CustomerReviews';
import LegacyExpertInHomeService from 'src/components/Sections/legacy/ExpertInHomeService';
// Styles
import MultipleAccountSkuModalDetails from './MultipleAccountSkuModalDetails';
import MultipleAccountSkuHero from './MultipleAccountSkuHero';
import MultipleAccountSkuCard from './MultipleAccountSkuCard';
import styles from './styles.scss';

export default class MultipleAccountSku extends Component {
  static propTypes = {
    landing: PropTypes.object.isRequired,
    reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
    isMobile: PropTypes.bool.isRequired,
    push: PropTypes.func.isRequired,
  };

  state = {
    primarySku: null,
  };

  onHeroImageClick = (url) => {
    this.props.push(url);
  };

  showSkuDetails = (sku) => {
    this.setState({ primarySku: sku });
  };

  closeModal = () => {
    this.setState({ primarySku: null });
  };

  renderSkus = (skus) => {
    const landing = this.props.landing.toJS();
    return (
      <div className={styles.products}>
        {skus.map((sku, index) => (
          <div className={styles.product} key={`${index}-${sku.id}`}>
            <div className={styles.productItem}>
              <MultipleAccountSkuCard
                sku={sku}
                landing={landing}
                onShowDetails={this.showSkuDetails}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { landing, reviews, isMobile } = this.props;
    const skus = landing.getIn(['skus']).toJS();
    const contentClasses = cn('paddingX-medium paddingY-small1', styles.pageContent);
    const headerStyles = cn('l-section-header', styles.header);
    const { primarySku } = this.state;
    const detailsOpened = !!primarySku;
    const title = landing.getIn(['header', 'title']);
    const contentDescription = landing.getIn(['content', 'description']);
    const rawLanding = landing.toJS();

    return (
      <div className={cn(styles.page, 'site-v2')}>
        <Helmet title={landing.get('pageTitle')} />
        <MultipleAccountSkuHero
          landing={landing}
          isMobile={isMobile}
          onLinkClick={this.onHeroImageClick}
        />
        <div className={contentClasses}>
          {!!contentDescription && (
            <p
              className={styles.pageContentDescription}
              dangerouslySetInnerHTML={{ __html: contentDescription }}
            />
          )}
          {!!title && (
            <div className={styles.pageContentTitle}>
              <h2 className={headerStyles}>{title}</h2>
            </div>
          )}
          <div className={styles.pageContentProducts}>{this.renderSkus(skus)}</div>
          <SlideModal open={detailsOpened} handleClose={this.closeModal} showLogo={false}>
            {primarySku && <MultipleAccountSkuModalDetails sku={primarySku} landing={rawLanding} />}
          </SlideModal>
        </div>
        <div className={styles.pageInfo}>
          <LegacyExpertInHomeService backgroundColor="gray" />
          <LegacyCustomerReviews
            reviews={reviews}
            reviewCount={6}
            backgroundColor="white"
            title="What Customers Are Saying"
            viewMore={false}
          />
        </div>
      </div>
    );
  }
}
