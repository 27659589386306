import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export default function Icon({ name, className, render, children, ...rest }) {
  const icon = name ? name.replace(/^ht-/i, '') : 'logo';
  const styles = cn(className, 'ht-icon', `ht-${icon}`);
  const props = { ...rest, className: styles, children };
  if (render) return render(props);
  return <i {...props} />;
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.any,
  render: PropTypes.func,
  children: PropTypes.node,
};

Icon.defaultProps = {
  className: null,
  render: null,
  children: null,
};
