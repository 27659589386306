import { XFINITY_VANITY_URLS } from 'src/constants/partner';

import { contentBasehost as baseRedirectPath } from 'src/utils/environment';


export const COMCAST_IFRAME_CONTAINER_ID = 'IframeComcastContainer'; // This gets applied to the container surrounding the iframe
export const COMCAST_IFRAME_PAGE_ID = 'IframeComcastPage'; // This gets applied directly to iframe

/**
 * This tells us what messaging group to listen for, else we get all messages across the iframe(s)/sockets.
 */
export const CALLER_PAGE_NAMES = { XFINITY_PAGE: 'xfinity-page' };
/**
 * Add your urls slugs here
 */
const baseEnterPath = '/comcast'; // e.g., hellotech.com/comcast

// In the future we may need to iframe Comcast Business pages. So I'm separating the Xfinity slugs now
export const PATHS_TO_IFRAME_XFINITY = Object.freeze({
  // Individual service pages
  COMPUTER_REPAIR_HELP: `${baseEnterPath}/xfinity-computer-repair-help`,
  HDTV_HOOKUP_SETUP: `${baseEnterPath}/xfinity-hdtv-or-tv-hook-up-setup`,
  HOME_THEATER_HOOKUP_SETUP: `${baseEnterPath}/xfinity-home-theater-hook-up-setup`,
  WALL_RUNNING: `${baseEnterPath}/xfinity-in-wall-cable-running`,
  NEW_COMPUTER_SETUP: `${baseEnterPath}/xfinity-new-computer-setup`,
  PRINTER_SETUP: `${baseEnterPath}/xfinity-printer-setup`,
  PRINTER_TROUBLESHOOTING: `${baseEnterPath}/xfinity-printer-trouble-shooting-or-repair`,
  SELF_PROTECTION_CAMERA: `${baseEnterPath}/xfinity-self-protection-cam`,
  SELF_PROTECTION_SMART_LOCK: `${baseEnterPath}/xfinity-self-protection-lock`,
  SELF_PROTECTION_DOORBELL: `${baseEnterPath}/xfinity-self-protection-smart-doorbell-installation`,
  SMART_DOOR_LOCK: `${baseEnterPath}/xfinity-smart-door-lock-installation`,
  GARAGE_DOOR_OPENER: `${baseEnterPath}/xfinity-smart-garage-door-opener-installation`,
  SMART_HUB_SPEAKER: `${baseEnterPath}/xfinity-smart-hub-or-speaker-setup`,
  SMART_LIGHT_SWITCH: `${baseEnterPath}/xfinity-smart-light-switch-installation`,
  SMART_LIGHT_BULB: `${baseEnterPath}/xfinity-smart-lightbulb-installation`,
  SMART_PLUG: `${baseEnterPath}/xfinity-smart-plug-installation`,
  SMART_SECURITY_CAM: `${baseEnterPath}/xfinity-smart-security-cam-installation`,
  SMART_THERMOSTAT: `${baseEnterPath}/xfinity-smart-thermostat-installation`,
  STREAMING_VIDEO_DEVICE: `${baseEnterPath}/xfinity-streaming-video-device-setup-support`,
  SURROUND_SOUND_SYSTEM: `${baseEnterPath}/xfinity-surround-sound-system-setup`,
  TV_DISMOUNT_REMOUNT: `${baseEnterPath}/xfinity-tv-dismount-or-remount`,
  TV_MOUNTING: `${baseEnterPath}/xfinity-tv-mounting`,
  UNIVERSAL_REMOTE: `${baseEnterPath}/xfinity-universal-remote-setup`,
  VIDEO_DOORBELL: `${baseEnterPath}/xfinity-video-doorbell-installation`,
  // Category Pages
  AUDIO_VIDEO_CATEGORY: `${baseEnterPath}/xfinity-audio-video-category`,
  COMPUTER_PRINTER_CATEGORY: `${baseEnterPath}/xfinity-computer-printer-category`,
  SELF_PROTECTION_CATEGORY: `${baseEnterPath}/xfinity-self-protection-category`,
  SMART_HOME_CATEGORY: `${baseEnterPath}/xfinity-smart-home-category`,
  TV_MOUNTING_CATEGORY: `${baseEnterPath}/xfinity-tv-mounting-category`,
  // Multisku "Default" Page
  DEFAULT: `${baseEnterPath}/xfinity`,
});

// Per Product the Xfinity vanity URLs should render the same content as the MultiSku page
const XFINITY_DEFAULT_SOURCE = `${baseRedirectPath}/partner/comcast-xfinity`;

export const XFINITY_VANITY_URL_SOURCES = XFINITY_VANITY_URLS.reduce((acc, url) => {
  acc[url] = XFINITY_DEFAULT_SOURCE;
  return acc;
}, {});

export const IFRAME_SRCS = Object.freeze({
  // Individual service pages
  [PATHS_TO_IFRAME_XFINITY.COMPUTER_REPAIR_HELP]: `${baseRedirectPath}/partner-service/xfinity-computer-repair-help`,
  [PATHS_TO_IFRAME_XFINITY.HDTV_HOOKUP_SETUP]: `${baseRedirectPath}/partner-service/xfinity-hdtv-or-tv-hook-up-setup`,
  [PATHS_TO_IFRAME_XFINITY.HOME_THEATER_HOOKUP_SETUP]: `${baseRedirectPath}/partner-service/xfinity-home-theater-hook-up-setup`,
  [PATHS_TO_IFRAME_XFINITY.WALL_RUNNING]: `${baseRedirectPath}/partner-service/xfinity-in-wall-cable-running`,
  [PATHS_TO_IFRAME_XFINITY.NEW_COMPUTER_SETUP]: `${baseRedirectPath}/partner-service/xfinity-new-computer-setup`,
  [PATHS_TO_IFRAME_XFINITY.PRINTER_SETUP]: `${baseRedirectPath}/partner-service/xfinity-printer-setup`,
  [PATHS_TO_IFRAME_XFINITY.PRINTER_TROUBLESHOOTING]: `${baseRedirectPath}/partner-service/xfinity-printer-trouble-shooting-or-repair`,
  [PATHS_TO_IFRAME_XFINITY.SELF_PROTECTION_CAMERA]: `${baseRedirectPath}/partner-service/xfinity-self-protection-smart-camera-installation`,
  [PATHS_TO_IFRAME_XFINITY.SELF_PROTECTION_SMART_LOCK]: `${baseRedirectPath}/partner-service/xfinity-self-protection-smart-lock-installation `,
  [PATHS_TO_IFRAME_XFINITY.SMART_DOOR_LOCK]: `${baseRedirectPath}/partner-service/xfinity-smart-door-lock-installation`,
  [PATHS_TO_IFRAME_XFINITY.SELF_PROTECTION_DOORBELL]: `${baseRedirectPath}/partner-service/xfinity-self-protection-smart-doorbell-installation`,
  [PATHS_TO_IFRAME_XFINITY.GARAGE_DOOR_OPENER]: `${baseRedirectPath}/partner-service/xfinity-smart-garage-door-opener-installation`,
  [PATHS_TO_IFRAME_XFINITY.SMART_HUB_SPEAKER]: `${baseRedirectPath}/partner-service/xfinity-smart-hub-or-speaker-setup`,
  [PATHS_TO_IFRAME_XFINITY.SMART_LIGHT_SWITCH]: `${baseRedirectPath}/partner-service/xfinity-smart-light-switch-installation`,
  [PATHS_TO_IFRAME_XFINITY.SMART_LIGHT_BULB]: `${baseRedirectPath}/partner-service/xfinity-smart-lightbulb-installation`,
  [PATHS_TO_IFRAME_XFINITY.SMART_PLUG]: `${baseRedirectPath}/partner-service/xfinity-smart-plug-installation`,
  [PATHS_TO_IFRAME_XFINITY.SMART_SECURITY_CAM]: `${baseRedirectPath}/partner-service/xfinity-smart-security-cam-installation`,
  [PATHS_TO_IFRAME_XFINITY.SMART_THERMOSTAT]: `${baseRedirectPath}/partner-service/xfinity-smart-thermostat-installation`,
  [PATHS_TO_IFRAME_XFINITY.STREAMING_VIDEO_DEVICE]: `${baseRedirectPath}/partner-service/xfinity-streaming-video-device-setup-support`,
  [PATHS_TO_IFRAME_XFINITY.SURROUND_SOUND_SYSTEM]: `${baseRedirectPath}/partner-service/xfinity-surround-sound-system-setup`,
  [PATHS_TO_IFRAME_XFINITY.TV_DISMOUNT_REMOUNT]: `${baseRedirectPath}/partner-service/xfinity-tv-dismount-or-remount`,
  [PATHS_TO_IFRAME_XFINITY.TV_MOUNTING]: `${baseRedirectPath}/partner-service/xfinity-tv-mounting`,
  [PATHS_TO_IFRAME_XFINITY.UNIVERSAL_REMOTE]: `${baseRedirectPath}/partner-service/xfinity-universal-remote-setup`,
  [PATHS_TO_IFRAME_XFINITY.VIDEO_DOORBELL]: `${baseRedirectPath}/partner-service/xfinity-video-doorbell-installation`,
  // Category Pages
  [PATHS_TO_IFRAME_XFINITY.AUDIO_VIDEO_CATEGORY]: `${baseRedirectPath}/partner-category/xfinity-audio-video-category`,
  [PATHS_TO_IFRAME_XFINITY.COMPUTER_PRINTER_CATEGORY]: `${baseRedirectPath}/partner-category/xfinity-computer-printer-category`,
  [PATHS_TO_IFRAME_XFINITY.SELF_PROTECTION_CATEGORY]: `${baseRedirectPath}/partner-category/xfinity-self-protection-category`,
  [PATHS_TO_IFRAME_XFINITY.SMART_HOME_CATEGORY]: `${baseRedirectPath}/partner-category/xfinity-smart-home-category`,
  [PATHS_TO_IFRAME_XFINITY.TV_MOUNTING_CATEGORY]: `${baseRedirectPath}/partner-category/xfinity-tv-mounting-category`,
  // Multisku "Default" Page
  [PATHS_TO_IFRAME_XFINITY.DEFAULT]: XFINITY_DEFAULT_SOURCE,
  ...XFINITY_VANITY_URL_SOURCES,
});

export const getIframeSource = (src) => {
  // This is similar the IFRAME function below, but instead of generating the source string and then returning an iframe tag, we just generate the source string
  if (!src) return '';
  return IFRAME_SRCS[src] || XFINITY_DEFAULT_SOURCE;
};

// Routes that match for the iframe
export const IframeXfinityPageRoutes = Object.keys(IFRAME_SRCS);
