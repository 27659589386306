import { takeLatest, call, put, select } from 'redux-saga/effects';
import { LOAD_PAGE } from 'src/constants/common';
import { selectRoutes } from 'src/apiRoutes';
import { loadProfileData } from 'src/components/Techs/data/actions';
import { userSelector } from 'src/selectors/user';
import { pageLoadingError, pageLoaded } from 'src/actions/common';
import { techProfileByIdJSSelector } from 'src/selectors/techs';
import { PAGE_NAME } from './constants';

function* pageSaga({ orderId }) {
  const routes = yield call(selectRoutes);
  const user = yield select(userSelector);
  const userId = user && user.get('id');
  let order = yield select((state) =>
    state.getIn(['entities', 'orderConfirmations', orderId.toString()]),
  );

  /*
  TODO: Redux is not updated with the order info after the order is created, so order is always undefined
  and it always makes an API call. See src/reducers/entities/orderConfirmations.js. Do we care? Should we delete that
  reducer and this if/else block and just make an API call every time? -GH Sep 9, 2021
  */
  if (order) {
    order = order.toJS();
  } else {
    const response = yield call(routes.orders.confirmation, { orderId });

    if (!response.err) {
      order = response.data.order;
    } else {
      yield put(pageLoadingError(PAGE_NAME, response));
      return;
    }
  }

  const { preferredTechId, cart } = order;

  if (preferredTechId) {
    const techData = yield select(techProfileByIdJSSelector(preferredTechId));
    if (!techData) {
      const techResult = yield call(routes.techs.profile, { id: preferredTechId });
      if (!techResult.err) {
        const {
          data: { tech: techDataResult },
        } = techResult;
        yield put(loadProfileData({ techId: preferredTechId, data: techDataResult }));
      }
    }
  }

  let planDetails;
  if (cart && cart.plan) {
    const planId = cart.plan.id;
    const planResult = yield call(routes.plans.subscriptions.show, { id: planId });
    if (!planResult.err) {
      planDetails = planResult.data.plan;
    }
  }

  yield put(pageLoaded(PAGE_NAME, { order, userId, planDetails }));
}

function* pageFlow() {
  yield takeLatest((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
}

export default [pageFlow];
