import React from 'react';
import discountBannerImage from 'src/images/orange-triangle.png';
import styles from './styles.scss';

const TriangleDiscountImage = () => (
  <div className={styles.banner}>
    <div className={styles.bannerImage}>
      <img src={discountBannerImage} alt="AmFam" />
    </div>
    <div className={styles.bannerPromo}>
      <p>5% OFF For all AMFAM Members</p>
    </div>
  </div>
);

export default TriangleDiscountImage;
