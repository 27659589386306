import React from 'react';
import PropTypes from 'prop-types';
import Fixed from './Fixed';
import Relative from './Relative';

const Snackbar = ({ type, ...otherProps }) => {
  return type === 'relative' ? (
    <Relative {...otherProps} />
  ) : (
    <Fixed {...otherProps} />
  );
}

Snackbar.propTypes = {
  type: PropTypes.string,
};

Snackbar.defaultProps = {
  type: 'relative', // ['relative', 'fixed']
};

export default Snackbar;
