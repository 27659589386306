import Parser from 'ua-parser-js';
import config from 'config';
import { newChatMessage, chatShown } from 'src/actions/chat';
import { isMobileV2, isTabletV2 } from 'src/utils/ui';
import { checkIsPrerenderUA, checkIsBotUA } from 'src/utils/seo';

export default class HTKustomerChat {
  static initializedAndStarted = false;

  static dispatch = () => {};

  /* We turned off chat on mobile (nobody remembers why), turrn it back on */
  static shouldHideChatIcon = () => false; // isMobileV2() || isTabletV2();

  static shouldNotRunCheck = () => {
    const isNotBrowser = !process.browser;
    const isPrerenderUA = checkIsPrerenderUA();
    const isBotUA = checkIsBotUA();
    const isEVTechQuote = window.location.pathname.includes('/ev/tech');
    return isNotBrowser || isPrerenderUA || isBotUA || isEVTechQuote;
  };

  /**
   * Initialize and start the Kustomer Chat.
   *
   * This is intended to be called only once on app render.
   */
  static initializeAndStart = ({ dispatch, user = null }, startCB = () => {}) => {
    if (this.shouldNotRunCheck() || this.initializedAndStarted) {
      return;
    }

    this.dispatch = dispatch;

    const kustomerScript = () => {
      // Once the script (below) is loaded, start the chat service
      window.addEventListener('kustomerLoaded', () => {
        // Start
        window.Kustomer.start(
          {
            hideChatIcon: this.shouldHideChatIcon(),
          },
          () => {
            this.initializedAndStarted = true;

            // Set user info if an user exists on app mount
            if (user) {
              window.Kustomer.login({
                jwtToken: user.get('kustomerJwt'),
              });
            }

            // If there is a change in unread messages, update redux
            window.Kustomer.addListener('onUnread', () => {
              this.dispatch(newChatMessage());
            });

            /*
              When a new conversation starts, pass attributes to describe it
              This is the base info we want to pass for every new conversations.
              If we want to pass additional data, create another addListener, and
              be sure to remove the listener when needed.
            */
            window.Kustomer.addListener('onConversationCreate', (res) => {
              const screenSize =
                (isMobileV2() && 'Mobile') || (isTabletV2() && 'Tablet') || 'Desktop';
              window.Kustomer.describeConversation({
                conversationId: res.conversationId,
                customAttributes: {
                  chatDeviceTypeStr: screenSize,
                  chatBrowserTypeStr: Parser().browser.name,
                  chatOriginUrlStr: window.location.href,
                },
              });
            });

            // Finally, run the callback
            startCB();
          },
        );
      });

      // Add the script to initialize
      const script = document.createElement('script');
      script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
      script.setAttribute('data-kustomer-api-key', config.kustomer.apiKey);
      window.document.body.appendChild(script);
    };

    if (!window.Kustomer) {
      kustomerScript();
    }
  };

  static identify = (jwt) => {
    if (this.shouldNotRunCheck()) {
      return;
    }

    // Auths the user and fetches their Kustomer data
    window.Kustomer.login({
      jwtToken: jwt,
    });
  };

  static logout = () => {
    if (this.shouldNotRunCheck()) {
      return;
    }
    // Removes user info from the chat session
    window.Kustomer.logout();
  };

  static open = (cb = () => {}) => {
    if (this.shouldNotRunCheck()) {
      return;
    }
    // Open the chat box
    window.Kustomer.open(cb);
    HTKustomerChat.dispatch(chatShown(true));
  };

  static openAndSendMessage = ({ message = '', assistantId = '' }) => {
    if (this.shouldNotRunCheck()) {
      return;
    }
    if (message || assistantId) {
      // Calling start again to override the assistantId in previous calls to start
      this.open(() => {
        window.Kustomer.createConversation({
          message,
          assistantId,
        });
      });
    }
  };

  static hideChat = () => {
    if (this.shouldNotRunCheck()) {
      return;
    }
    // just hides chat box
    window.Kustomer.close();
  };

  static addListener = (event, handler) => {
    if (this.shouldNotRunCheck()) {
      return;
    }
    window.Kustomer.addListener(event, handler);
  };

  static removeListener = (event, handler) => {
    if (this.shouldNotRunCheck()) {
      return;
    }
    window.Kustomer.removeListener(event, handler);
  };

  static describeConversation = (conversationId, customAttributes = {}) => {
    if (this.shouldNotRunCheck()) {
      return;
    }
    if (!conversationId) return;
    window.Kustomer.describeConversation({
      conversationId,
      customAttributes,
    });
  };
}
