import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const OrderSummaryLineItem = ({ children, className, ...rest }) => {
  if (!children) return null;
  const containerStyles = cn('table-row secondary', className);
  return (
    <p className={containerStyles} {...rest}>
      {children}
    </p>
  );
};

OrderSummaryLineItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default OrderSummaryLineItem;

/*
  OrderSummaryLineItem
    - This is a base level component for rendering customer info such as address, CC info, etc.
      There is a design sheet in Abstract for this component.

  Notes: If you want to pass a className prop you can pass it directly to <OrderSummaryLineItem>
    or pass it through whatever component is rendering it, e.g.

  const MyNewComponent = ({ data, ...rest }) => (
    <OrderSummaryLineItem {...rest}>bleh</OrderSummaryLineItem>
  );

  <MyNewComponent data={data} className="css-class-name" />
*/
