import state from './state';

/* eslint-disable */
export const embed = () => {
  if (window.SMCX) return; // already inited

  (function(t, e, s, o) {
    var n, c, l;
    (t.SMCX = t.SMCX || []),
      e.getElementById(o) ||
        ((n = e.getElementsByTagName(s)),
        (c = n[n.length - 1]),
        (l = e.createElement(s)),
        (l.type = 'text/javascript'),
        (l.async = !0),
        (l.id = o),
        (l.src = [
          'https:' === location.protocol ? 'https://' : 'http://',
          'widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd52jovj_2BaMxeVk2r1wPPSbg3jPKw6mI3Ii4ukswovF8p.js',
        ].join('')),
        c.parentNode.insertBefore(l, c));
  })(window, document, 'script', 'smcx-sdk');

  state.shown = true; // let's set it shown
};
/* eslint-enable */
