import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DefaultMetaTags } from 'src/components/SEO';
import SkuCategoryImage from 'src/components/SkuCategoryImage';
import SkuCategoryTitle from 'src/components/SkuCategoryTitle';
import { SkuCategoryItems } from 'src/containers/SkuCategoryPage/Parts';
import { locationSkuCategoryJsonLd, breadcrumbsJsonLd } from 'src/utils/jsonLd/index';
import { push } from 'src/utils/paths';
import { buildGeoServiceLink } from '../../utils';
import styles from './styles.scss';

/**
  Due to the api response from src/server/validateLocationPage, location sku category pages will
  never render. Additionally, there's no way to navigate to this page through the site
  navigation components. -J.Ko, Feb 2021
*/
const LocationSkuCategory = ({ location, phone, breadcrumbs }) => {
  const dispatch = useDispatch();

  const skuCategory = location.get('skuCategory');
  const name = skuCategory.get('name');
  const seoName = skuCategory.get('seoName');

  const title = location.get('title');

  const onSkuItemClick = (sku) => {
    return (event) => {
      event.preventDefault();
      const link = buildGeoServiceLink(location, sku);
      dispatch(push(link));
    };
  };

  const skuItemLink = (sku) => buildGeoServiceLink(location, sku);

  return (
    <section className={styles.locationSkuCategory}>
      <DefaultMetaTags
        title={title}
        script={[
          locationSkuCategoryJsonLd(skuCategory, location, phone),
          breadcrumbsJsonLd(breadcrumbs),
        ]}
      />
      <SkuCategoryImage skuName={name} skuSeoName={seoName} />
      <div className="l-content-section">
        <div className={styles.locationSkuCategoryTitle}>
          <SkuCategoryTitle skuCategory={skuCategory} />
        </div>
        <SkuCategoryItems
          skuCategory={skuCategory}
          isDesktop
          onItemClick={onSkuItemClick}
          itemLink={skuItemLink}
        />
        <SkuCategoryItems
          skuCategory={skuCategory}
          onItemClick={onSkuItemClick}
          itemLink={skuItemLink}
        />
      </div>
    </section>
  );
};

LocationSkuCategory.propTypes = {
  location: PropTypes.object,
  phone: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(LocationSkuCategory);
