// Libraries
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Components
import { Grid } from 'HTKit/Grid';
import TotalCompletedServices from 'src/components/HTKit/Elements/TotalCompletedServices/TotalCompletedServices';
import { ReadyToBookServiceBlock } from 'src/components/ReadyToBookServiceBlock';
import ServiceReviews from 'src/components/Elements/CustomerReviews';
import HeaderPanel from './Parts';
import ServiceBlock from './Parts/ServiceBlock';
// Constants
import { PAGE_NAME, PARTNER_DETAILS } from './constants';
// Styles
import styles from './styles.scss';

const SimpliSafeLandingPage = ({ loadPage, partner, reviews, skus, totalCompletedServices }) => {
  const details = PARTNER_DETAILS[partner];

  useEffect(() => {
    loadPage(PAGE_NAME, { partner, skuIds: details.skus, skus });
  }, [partner]);

  const containerStyles = cn('site-v2', styles.pageContainer);

  return (
    <>
      <div className={containerStyles}>
        <HeaderPanel skus={skus} />

        <div className={styles.totalCompletedWrapper}>
          <TotalCompletedServices
            duration={2000}
            servicesCompleted={totalCompletedServices}
            text={details.totalCompletedServices.text}
          />
        </div>

        <Grid.Fluid>
          <ServiceBlock />
        </Grid.Fluid>

        <div className={styles.serviceReviewsWrapper}>
          <ServiceReviews
            reviews={reviews}
            className={styles.customerReviews}
            headerText="Customer Reviews"
            headerStyles="text-align-center"
            itemStyles={styles.reviewItem}
          />
        </div>

        <Grid.Fluid>
          <ReadyToBookServiceBlock
            buttonText={details.readyToBook.buttonText}
            ctaText={details.readyToBook.ctaText}
            imgClasses={styles.readyToBookPhotos}
            onButtonClick={() => {
              window.scrollTo({
                top: 100,
                behavior: 'smooth',
              });
            }}
          />
        </Grid.Fluid>
      </div>
    </>
  );
};

SimpliSafeLandingPage.propTypes = {
  loadPage: PropTypes.func.isRequired,
  partner: PropTypes.string.isRequired,
  reviews: PropTypes.array,
  skus: PropTypes.array,
  totalCompletedServices: PropTypes.number,
};

SimpliSafeLandingPage.defaultProps = { reviews: [] };

export default SimpliSafeLandingPage;
