import walmartLogo from 'src/images/branding/walmart-header-logo.svg';
import walmartLogoWhite from 'src/images/branding/walmart-header-logo-white.svg';

// Redux
export const SET_BRANDING = 'branding/SET_BRANDING';
export const REMOVE_BRANDING = 'branding/REMOVE_BRANDING';

// Theme
export const WALMART_THEME = 'walmart';

// Branding Images - Use utils to get the logos
export const MAIN = 'main';
export const MONOCHROME = 'monochrome';

export const BRANDING_LOGOS = {
  [WALMART_THEME]: {
    [MAIN]: walmartLogo,
    [MONOCHROME]: walmartLogoWhite,
  },
};
