import { SELECT_LOCATION_PAGE, LOCATION_UPDATED } from './constants';

export function selectLocationPage({ page, locationPath }) {
  return {
    type: SELECT_LOCATION_PAGE,
    page,
    locationPath,
  };
}

export function locationUpdated({ location, locationPath }) {
  return {
    type: LOCATION_UPDATED,
    location,
    locationPath,
  };
}
