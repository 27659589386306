import {
  SET_EDIT_PAYMENT_INFORMATION,
  BOOK,
  TOGGLE_MODAL,
  SET_SHOW_AVAILABILITY,
} from './constants';

export function setEditPaymentInformation(value) {
  return {
    type: SET_EDIT_PAYMENT_INFORMATION,
    value,
  };
}

export function setShowAvailability(value) {
  return {
    type: SET_SHOW_AVAILABILITY,
    value,
  };
}

export function toggleModal({ modalName }) {
  return {
    type: TOGGLE_MODAL,
    modalName,
  };
}

export function book({ updatePayment, token, selectedDateTimes }) {
  return {
    type: BOOK,
    token,
    updatePayment,
    selectedDateTimes,
  };
}
