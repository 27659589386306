import { connect } from 'react-redux';
import componentSelector from 'src/selectors/component';
import { removeAppSnackNotice } from 'src/actions/snackNotice';
import Fixed from './Fixed';

const mapStateToProps = (state) => {
  return {
    snackbar: componentSelector('snackbar')(state),
  };
};

export default connect(
  mapStateToProps,
  { removeAppSnackNotice },
)(Fixed);

/*
  This component is used on an app-wide basis. It will float above the page content.
  Additionally, the content will be removed on route changes.

  To use this snackbar, see the following.

  import { addSuccessAppSnackNotice } from 'src/actions/snackNotice';

  ....

  addNotice = () => {
    this.props.addSuccessAppSnackNotice({
      content: 'Something successful happened.'
    });
  }
*/
