import { fromJS } from 'immutable';
import { PAGE_LOADED } from 'src/constants/common';
import { PAGE_NAME } from './constants';

const initialState = fromJS({
  planDetails: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        case PAGE_NAME: {
          const { planDetails } = action;
          return state.set('planDetails', fromJS(planDetails));
        }
        default:
          return state;
      }
    default:
      return state;
  }
};
