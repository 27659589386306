import { createSelector, createStructuredSelector } from 'reselect';
import { skusSelector } from 'src/selectors/sku';
import { flow, values, find } from 'lodash/fp';
import get from 'lodash/get';
import { PAGE_NAME } from 'src/containers/SkuPage/constants';
import { adjustSku } from './utils';

const loadingSelector = (state) => state.getIn(['pages', 'sku', 'loading']);
const seoNameSelector = (_, ownProps) => get(ownProps, 'match.params.seoName', '');

const skuSelector = createSelector(
  skusSelector,
  seoNameSelector,
  (state) => {
    const user = state.get('user');
    return {
      isMobile: state.get('isMobile'),
      showPlanModal: state.getIn(['pages', PAGE_NAME, 'showPlanModal']),
      cart: state.getIn(['entities', 'cart']).toJS(),
      user: (user && user.toJS()) || null,
      reviews: state.getIn(['entities', 'reviews']).toJS(),
      plan: state.getIn(['pages', PAGE_NAME, 'plan']).toJS(),
      plansInfo: state.getIn(['pages', PAGE_NAME, 'plansInfo']).toJS(),
      mobileButtonCta: state.getIn(['components', 'zipCodeTest', 'buttonCta']),
      showZipCodeTest: state.getIn(['components', 'zipCodeTest', 'showZipCodeTest']),
    };
  },
  (skus, seoName, extra) =>
    flow(
      values,
      find((s) => s.seoName === seoName),
      adjustSku.bind(null, extra),
    )(skus),
);

export default createStructuredSelector({
  loading: loadingSelector,
  seoName: seoNameSelector,
  sku: skuSelector,
});
