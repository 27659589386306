import { fromJS } from 'immutable';
import { PAGE_LOADED } from 'src/constants/common';
import { APPOINTMENT_CONFIRMED } from 'src/containers/Account/OrdersPage/constants';
import { ORDER_INFO_LOADED } from 'src/constants/order';

const ordersInitialState = fromJS({ list: [] });

export default function ordersReducer(state = ordersInitialState, action) {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        case 'accountOrders':
          return state.merge({ list: action.orders });
        default:
          return state;
      }
    case ORDER_INFO_LOADED:
    case APPOINTMENT_CONFIRMED: {
      const { order } = action.payload;
      const orderList = state.get('list');

      const list = orderList.update(
        orderList.findIndex((item) => item.get('id') === order.id),
        () => fromJS(order),
      );
      return state.merge({ list });
    }
    default:
      return state;
  }
}
