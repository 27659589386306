import { useState, useEffect } from 'react';

const MAX_LENGTH = 100;
const TRIMMED_LENGTH = MAX_LENGTH - 3; // ...

export default (text, max = MAX_LENGTH) => {
  const couldReadMore = text.length >= max;
  const [collapsed, setCollapsed] = useState(couldReadMore);
  const [adjusted, setAdjustedText] = useState(text);

  useEffect(() => {
    const adjustedText = collapsed ? `${text.substring(0, TRIMMED_LENGTH).trim()}...` : text;
    setAdjustedText(adjustedText);
  }, [collapsed]);

  return [{ text: adjusted, collapsed, couldReadMore }, setCollapsed];
};
