import { HEADER_TEMPLATES } from 'src/constants/common';
import PaloAltoOkyoHeader from 'src/containers/BookingPagePaloAltoNetworks/PaloAltoOkyoHeader';
import FieldSalesHeader from 'src/containers/FieldSales/Header';
import DirectBookingHeader from 'src/containers/DirectBooking/Parts/Header';
import EVPortalHeader from 'src/containers/EV/Headers/EVPortalHeader';
import EVBookingHeader from 'src/containers/EV/Headers/EVBookingHeader';
import TopNavHeader from './TopNavHeader';
import BookingHeader from './BookingHeader';
import SamsungHeader from './SamsungHeader';
import WalmartHeader from './WalmartHeader';
import TargetEasyInstallHeader from './TargetEasyInstallHeader';
import PlainHeader from './PlainHeader';
import CobrandHeader from './CobrandHeader';

export const HEADER_WRAPPER_ID = 'ht-header-wrapper';
export const HEADER_ID = 'ht-main-header';
export const HEADER_PORTAL_ID = 'ht-header-portal';

export const TOP_NAV_NAME = 'TopNavHeader';

export const HEADER_TYPES = {
  topNav: {
    type: TOP_NAV_NAME,
    header: TopNavHeader,
  },
  booking: {
    type: HEADER_TEMPLATES.Booking,
    header: BookingHeader,
  },
  plain: {
    type: HEADER_TEMPLATES.Plain,
    header: PlainHeader,
  },
  samsung: {
    type: HEADER_TEMPLATES.Samsung,
    header: SamsungHeader,
  },
  walmart: {
    type: HEADER_TEMPLATES.Walmart,
    header: WalmartHeader,
  },
  targetEasyInstall: {
    type: HEADER_TEMPLATES.TargetEasyInstall,
    header: TargetEasyInstallHeader,
  },
  cobrand: {
    type: HEADER_TEMPLATES.Cobrand,
    header: CobrandHeader,
  },
  fieldSales: {
    type: HEADER_TEMPLATES.FieldSales,
    header: FieldSalesHeader,
  },
  directBooking: {
    type: HEADER_TEMPLATES.DirectBooking,
    header: DirectBookingHeader,
  },
  paloAltoOkyo: {
    type: HEADER_TEMPLATES.PaloAltoOkyo,
    header: PaloAltoOkyoHeader,
  },
  evPortal: {
    type: HEADER_TEMPLATES.EVPortal,
    header: EVPortalHeader,
  },
  evBooking: {
    type: HEADER_TEMPLATES.EVBooking,
    header: EVBookingHeader,
  },
};
