import { pathnameFromString } from 'src/utils/validation';
import get from 'lodash/get';

/**
 * Our action object. We could use a switch statement, but inevitably, we'll have nesting.
 * Our recursive/gen functions will iterate over this object and get at our functional intent.
 *
 * note: postMessage can pass an object. So, at some point, the iframe can pass a full segment
 *       analytic object, but for now it's just patrick hardcoding and he doesn't have access to
 *       the api response. So, we currently would handle it on our end, BUT if the iframe every
 *       deals with APIS', he could pass the full object to us and we just "action" on it.
 */
/*
  Helper for redirect (this needs to be extracted out. We use it in numerous places).
*/
const trackAndRedirect = (props, segmentAttributes, redirect) => {
  window.location = redirect;
};

const messagePageActions = (propsFromComponent) => (props, attributes) => {
  return {
    loaded() {
      /* This is the useState setter passed in */
      attributes.setPageLoaded(true);
    },
    genericLinkAndTrackClick() {
      /* Patrick has to pass full urls. But we have to redirect on the environment level */
      const pathName = pathnameFromString(get(attributes, 'message.redirectUrl', ''));
      const segmentAttributes = get(attributes, 'message.segment', {});
      const mergedProps = { ...props, ...propsFromComponent };

      trackAndRedirect(mergedProps, segmentAttributes, pathName);
    },
    anchorScrollBy() {
      window.scrollTo(0, attributes.message);
    },
    captureBodyClickEvent() {
      const { isTopNavOpen = false, toggleTopnav = () => {} } = props;
      if (isTopNavOpen) toggleTopnav();
    },
  };
};

export { messagePageActions };
