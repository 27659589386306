/**
 *
 * @param {*} locationData
 * @returns {Object | null} Should be the same shape of the `address` object in the cart
 */
export const extractAddressObjectFromLocation = (locationData) => {
  const { state } = locationData;
  let { address1, address2, city, state: stateCode, zip } = state || {};

  // Guard against NULL values being explicitly passed in from the BE
  address1 = address1 || '';
  address2 = address2 || '';
  city = city || '';
  stateCode = stateCode || '';
  zip = zip || '';

  const fullAddress =
    address1 && city && stateCode && zip ? `${address1} ${city}, ${stateCode} ${zip}` : '';
  return fullAddress
    ? { fullAddress, address1, address2, city, state: stateCode, zip, details: '' }
    : null;
};
