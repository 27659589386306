import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { skuPath, landingPath } from 'src/utils/paths';
import LegacyHomePageHero from 'src/components/Sections/legacy/HomePageHero';
import LegacyFeaturedIn from 'src/components/Sections/legacy/FeaturedIn';
import LegacyCustomerReviews from 'src/components/Sections/legacy/CustomerReviews';
import LegacyExpertInHomeService from 'src/components/Sections/legacy/ExpertInHomeService';
import LegacyQualifiedTechs from 'src/components/Sections/legacy/QualifiedTechs';
import LegacyInTheNews from 'src/components/Sections/legacy/InTheNews';
import { TriangleDiscount } from 'src/components/Discount';
import FeaturedServices, { TYPE as FEATURED_SERVICES_TYPE } from './FeaturedServices/index';
import { buildHeaderStyles, buildDiscountProps } from './utils';
import styles from './styles.scss';

export default class AmFam extends Component {
  static propTypes = {
    landing: PropTypes.object.isRequired,
    reviews: PropTypes.array,
    isMobile: PropTypes.bool.isRequired,
    push: PropTypes.func.isRequired,
    setHeaderLogo: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const landing = this.props.landing.toJS();
    const logo = landing.images.logo.original;
    this.props.setHeaderLogo(logo);
  }

  onServiceClick = (service, type) => {
    const method = type === FEATURED_SERVICES_TYPE.SKU ? skuPath : landingPath;
    this.props.push(method(service.seoName));
  };

  render() {
    const { isMobile, reviews } = this.props;
    const landing = this.props.landing.toJS();
    const { skus, coupon, relatedLandings, header } = landing;
    const discountProps = buildDiscountProps(coupon);
    const headerStyles = buildHeaderStyles(landing, isMobile);
    const headerTitle = header.title || 'Goodbye Frustration. HelloTech.';
    const haveFeatured = !!skus.length || !!relatedLandings.length;
    return (
      <div className={`site-v2 ${styles.page}`}>
        <Helmet title="Tech Support Special Rate" />
        {coupon && <TriangleDiscount {...discountProps} />}
        <LegacyHomePageHero
          title={headerTitle}
          containerStyles={headerStyles}
          headerClasses="blue500"
        />
        <LegacyFeaturedIn backgroundColor="white" />
        {haveFeatured && (
          <FeaturedServices
            skus={skus}
            landings={relatedLandings}
            onServiceClick={this.onServiceClick}
          />
        )}
        <LegacyExpertInHomeService backgroundColor="white" headerClasses="blue500" />
        <LegacyInTheNews backgroundColor="grey" headerClasses="blue500" />
        <LegacyQualifiedTechs backgroundColor="white" headerClasses="blue500" />
        <LegacyCustomerReviews
          reviews={reviews}
          backgroundColor="grey"
          title="1 Million+ Satisfied Customers"
          headerClasses="blue500"
        />
      </div>
    );
  }
}
