import styles from './styles.scss';
/* Promo Types: We declare custom so affirm can map proper display */
export const AFFIRM_LOGO = "https://cdn-assets.affirm.com/images/black_logo-white_bg.svg";
export const AFFIRM_LOGO_HALFMOON = "https://cdn-assets.affirm.com/images/blue_solid_circle-transparent_bg.png";

export const AFFIRM_PROMO_TYPES = Object.freeze({
  product: "product",
  cart: "cart",
  review: "review"
});

export const AFFIRM_PROMO_BY_TYPE_SCHEMA = Object.freeze({
  [AFFIRM_PROMO_TYPES.product]: {
    attrs: {
      "data-page-type": "product",
      "data-learnmore-show": "true",
      "data-promo-id": "promo_set_product_prequal",
      "className": "affirm-as-low-as",
      "alwaysEligible": true
    }
  },
  [AFFIRM_PROMO_TYPES.cart]: {
    attrs: {
      "data-page-type": "cart",
      "data-learnmore-show": "true",
      "data-promo-id": "promo_set_cart_prequal",
      "className": `affirm-as-low-as marginBottom-small paddingY-small ${styles.affirm_promo_border}`,
      "alwaysEligible": false
    }
  },
  [AFFIRM_PROMO_TYPES.review]: {
    attrs: {
      "data-page-type": "review",
      "data-learnmore-show": "true",
      "data-promo-id": "promo_set_review",
      "className": `affirm-as-low-as text-align-right paddingBottom-small`,
      "alwaysEligible": false
    }
  }
})
