import React, { memo, useState, useCallback, cloneElement } from 'react';
import PropTypes from 'prop-types';

const AccordionGroup = ({ className, children, defaultOpenIndex = null }) => {
  const [openItem, setOpenItem] = useState(defaultOpenIndex);
  const handleToggle = useCallback((index, visible) => {
    const item = visible ? index : null;
    setOpenItem(item);
  }, []);
  return (
    <div className={className}>
      {React.Children.toArray(children)
        .filter(Boolean)
        .map((child, index) =>
          cloneElement(child, {
            opened: openItem === index,
            onToggle: (visible) => handleToggle(index, visible),
          }),
        )}
    </div>
  );
};

AccordionGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  /** index of the accordion item that should be open by default */
  defaultOpenIndex: PropTypes.number,
};

export default memo(AccordionGroup);
