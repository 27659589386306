import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'src/components/HTKit/Icon';
import styles from './styles.scss';

const SkuCategoryTitle = ({ skuCategory }) => {
  const icon = skuCategory.get('icon');
  const name = skuCategory.get('name');
  return (
    <h1 className={styles.title}>
      <Icon name={icon} className={styles.icon} />
      {name}
    </h1>
  );
};

SkuCategoryTitle.propTypes = {
  skuCategory: PropTypes.shape({}).isRequired,
};

export default SkuCategoryTitle;
