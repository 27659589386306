import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import Icon from 'src/components/HTKit/Icon';
import { homehubCategoryPath, homehubPath } from 'src/utils/paths';
import styles from './styles.scss';

const NavItem = ({ onLinkClick, item, className, navStyles }) => {
  const linkPath = item.id ? homehubPath : homehubCategoryPath(item.name);

  return !item.disabled ? (
    <Link onClick={onLinkClick} to={linkPath} key={item.name} className={className}>
      {item?.icon && <Icon name={item?.icon} className={styles.icon} />}
      <p className={navStyles}>{capitalize(item?.name)}</p>
    </Link>
  ) : (
    <div className={className}>
      <p className={navStyles}>{item?.name}</p>
    </div>
  );
};

NavItem.propTypes = {
  onLinkClick: PropTypes.func,
  item: PropTypes.object,
  className: PropTypes.string,
  navStyles: PropTypes.string,
};

export default NavItem;
