import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import { selectTreatmentWithConfig, selectTreatmentValue } from '@splitsoftware/splitio-redux';
import get from 'lodash/get';
import { isImmutable } from 'src/utils/helpers';

const defaultState = fromJS({});
/** Split doesn't have guards. They always assume pristine operation. So, we have 'guard' */
const stateEmptyGuard = (fn, defaultValue) => state => getSplitTreatmentKeys(state).length ? fn() || defaultValue : defaultValue;
/** Split doesn't deal w/ immutability. Lets always pass off non-immutable to them */
const splitioStateSelector = (state = defaultState) => isImmutable(state) ? (state.get('splitio') || {}) : state.splitio;

/** Select specifically splitio state */
export const splitioSelector = createSelector(
  splitioStateSelector,
  (splitioState) => splitioState
);

/** Not sure this will be memoized => I'll try splitName => createSelector( createSelector ( */
export const treatmentWithConfigSelector = splitName => createSelector(
  splitioSelector,
  (splitio) => stateEmptyGuard(() => JSON.parse(selectTreatmentWithConfig(splitio, splitName).config), {})(splitio)
);

/** Not sure this will be memoized */
export const treatmentSelector = splitName => createSelector(
  splitioSelector,
  (splitio) => stateEmptyGuard(() => selectTreatmentValue(splitio, splitName), '')(splitio)
);

export const splitReadySelector = createSelector(
  splitioSelector,
  (splitio) => splitio.isReady || false
);

export const splitTimeOutSelector = createSelector(
  splitioSelector,
  (splitio) => splitio.isTimedout || splitio.hasTimedout || false
);

export const splitIsDestroyedSelector = createSelector(
  splitioSelector,
  (splitio) => splitio.isDestroyed || false
);

export const splitErrorSelector = createSelector(
  splitioSelector,
  (splitio) => splitio.isTimedout || splitio.hasTimedout || splitio.isDestroyed || get(splitio, 'treatments.isControlGroup', false) || false
);

export const splitTreatmentSeenSelector = createSelector(
  splitioSelector,
  (splitio) => getSplitTreatmentKeys(splitio)
);

const getSplitTreatmentKeys = (state = {}) => Object.keys(get(state, 'treatments', {}) || {});
