import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { CalloutBox, CALLOUTBOX_THEMES } from 'Elements/CalloutBox';
import styles from './styles.scss';

const NotePanel = ({ text }) => {
  return (
    <CalloutBox
      theme={CALLOUTBOX_THEMES.DEFAULT}
      text={text}
      textContentContainer={({ children }) => (
        <div className={cn('paddingY-small', styles.noteCalloutBox)}>{children}</div>
      )}
    />
  );
};

NotePanel.propTypes = {
  text: PropTypes.string,
};

NotePanel.defaultProps = {
  text: 'HelloTech accepts all major credit cards.',
};

export default NotePanel;
