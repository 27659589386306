import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { getInitials } from 'src/utils/text';
import useWithStyles from 'src/hooks/useWithStyles';
import styles from './styles.scss';

const getLabel = (name, imageAlt) => imageAlt || `Your HelloTech Technician is ${name}`;
const getImage = (img, name, imageAlt) => (
  <img className={styles.avatarImage} alt={getLabel(name, imageAlt)} src={img} />
);

/**
 * Usages:
 *   default: <Avatar name='John J. Doe' image='some/image' />
 *   w/modifier: <Avatar image='/some/path' small />
 *   w/classes: <Avatar image='image/path' name='John Doe' classes={`${styles.foo} ${styles.whatever}`} />
 *
 * @param props
 * @returns {null| jsx}
 * @constructor
 */
const Avatar = (props) => {
  if (!props.name && !props.image) return null;

  const { image, name, imageAlt, className } = props;
  const avatarClass = cn(styles.avatarcontainer, useWithStyles(props, styles), {
    [className]: className,
  });

  const body = (image && getImage(image, name, imageAlt)) || (name && getInitials(name));

  return body ? (
    <div aria-label={getLabel(name)} className={avatarClass}>
      {body}
    </div>
  ) : null;
};

Avatar.defaultProps = {
  className: '',
  name: '',
  image: '',
  imageAlt: '',
};

Avatar.propTypes = {
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  name: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  className: PropTypes.string,
};

export default React.memo(Avatar);
