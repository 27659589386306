import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
// Selectors
import { cartSelector } from 'src/selectors/cart';
// Constants
import { MONTHLY_PLAN_ID } from 'src/constants/plan';
// Actions
import { bookNow } from 'src/components/BookNowButton/actions';
import { addPlanToCart, removePlanFromCart } from 'src/actions/cart';
import {
  togglePlanModal,
  planAddedOnSkuTrack,
  planRemovedOnSkuTrack,
} from 'src/containers/SkuPage/actions';
// Components
import { Grid } from 'HTKit/Grid';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import StarRating from 'src/components/Elements/StarRating';
import ServiceInfoOptions from 'src/components/Service/ServiceInfo/ServiceInfoOptions';
import ServiceInfoUpsellV2 from 'src/components/Service/ServiceInfo/ServiceInfoUpsellV2';
import ServicePrice from 'src/components/Service/ServicePrice/ServicePrice';
import { ServiceHeaderTrigger } from 'src/components/Service/ServiceHeader';
import { ServiceBookNowTrigger } from 'src/components/Service/ServiceBookNow';
import styles from './styles.scss';

const LeftSkuInfoBlock = ({
  service,
  onRatingClick,
  onUpsellLearnMoreClick,
  showUpsell,
  className,
  plansInfo,
}) => {
  const dispatch = useDispatch();
  const cart = useSelector(cartSelector);
  const selectedPlanId = get(cart, 'plan.id', null);
  const { id, ratingAttributes, name, skuBullets } = service;
  const { rating, ratingCount } = ratingAttributes;

  const handleBook = useCallback(() => {
    dispatch(bookNow(id));
  }, [service]);

  const handleTogglePlanModal = useCallback((visible) => {
    dispatch(togglePlanModal({ modalState: visible }));
  }, []);

  const handleUpsellToggle = useCallback(
    (shouldAdd, planId = MONTHLY_PLAN_ID) => {
      if (shouldAdd) {
        dispatch(addPlanToCart(planId));
        dispatch(planAddedOnSkuTrack(service, 'New'));
        handleTogglePlanModal(false);
      } else {
        dispatch(removePlanFromCart());
        dispatch(planRemovedOnSkuTrack(service, 'New'));
      }
    },
    [service],
  );

  const {
    extra: {
      plan: { name: upsellPlanName, amount },
      prices: { priceDifference },
      showMemberPrice,
    },
  } = service;

  return (
    <Grid.Fluid classes={cn(className, styles.leftBlockContainer)}>
      <Grid.Row>
        <Grid.Column sm={4} md={8} lg={12}>
          <h2>{name}</h2>
          <ServicePrice
            service={service}
            onInfo={onUpsellLearnMoreClick}
            className={styles.price}
            showMemberPrice={showMemberPrice}
          />
          {!!rating && (
            <StarRating
              stars={rating}
              count={ratingCount}
              onClick={onRatingClick}
              className={styles.rating}
              size="md"
            />
          )}
        </Grid.Column>

        <Grid.Column sm={4} md={8} lg={12} classes={styles.upsellContainer}>
          {showUpsell && (
            <ServiceInfoUpsellV2
              selectedPlanId={selectedPlanId}
              plansInfo={plansInfo}
              upsellPlanPrice={amount}
              amount={priceDifference}
              onToggle={handleUpsellToggle}
              onLearnMore={onUpsellLearnMoreClick}
              upsellPlanName={upsellPlanName}
            />
          )}
          <ServiceHeaderTrigger />
          <ServiceBookNowTrigger />

          <Button theme={THEMES.V2PRIMARY} className={styles.button} onClick={handleBook}>
            Book Now
          </Button>
          <ServiceInfoOptions options={skuBullets} className={styles.serviceInfoOptions} />
        </Grid.Column>
      </Grid.Row>
    </Grid.Fluid>
  );
};

LeftSkuInfoBlock.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    ratingAttributes: PropTypes.shape({
      rating: PropTypes.number,
      ratingCount: PropTypes.number,
    }),
    skuBullets: PropTypes.arrayOf(PropTypes.shape({})),
    extra: PropTypes.shape({
      plan: PropTypes.object,
      prices: PropTypes.object,
      showMemberPrice: PropTypes.bool,
    }),
  }),
  prices: PropTypes.object,
  onRatingClick: PropTypes.func.isRequired,
  onUpsellLearnMoreClick: PropTypes.func.isRequired,
  showUpsell: PropTypes.bool,
  className: PropTypes.string,
  plansInfo: PropTypes.object,
};

export default memo(LeftSkuInfoBlock);
