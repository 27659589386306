import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { isMobileOrTablet } from 'src/utils/ui';
import StickyBar from 'src/components/Layout/StickyBar';
import SubNavDropdown from './SubNavDropdown';
import NavItem from './NavItem';
import {NAV_ITEMS} from './constants';
import styles from './styles.scss';

const SubNav = () => {
  const { category } = useParams();
  const initialState = {
    name: !category ? 'My Connected Home' : category,
    icon: '',
    id: null,
    disabled: false,
  }
  const [currentItem, setCurrentItem] = useState(initialState);
  const isMobileTablet = isMobileOrTablet();

  useEffect(() => {
    if (!category) {
      setCurrentItem(initialState);
    }
  }, [category]);

  return (
    <StickyBar className={styles.subnavContainer} elevatedClassName={styles.noBorder}>
      <div
        className={
          isMobileTablet ? 'positionRelative flex justifyContentSpaceBetween width100' : 'flex'
        }
      >
        {!isMobileTablet ? (
          NAV_ITEMS.map((item) => {
            const navItemStyles = classNames('overline-small n000', {
              'subdued-text-40': !item?.id && currentItem.name !== item.name,
            });
            const linkStyles = classNames(
              'flex alignItemsCenter paddingX-small overrideLinkStyles',
              {
                disabledCursor: !item.disabled,
                [styles.link]: !item.disabled,
                [styles.activeItem]: !item.disabled && currentItem.name === item.name,
              },
            );
            return (
              <NavItem
                onLinkClick={() => setCurrentItem(item)}
                key={item.name}
                className={linkStyles}
                navStyles={navItemStyles}
                item={item}
                currentItem={currentItem}
              />
            );
          })
        ) : (
          <SubNavDropdown
            navItems={NAV_ITEMS}
            selectedItem={currentItem}
            setSelectedItem={setCurrentItem}
          />
        )}
      </div>
    </StickyBar>
  );
};

export default SubNav;
