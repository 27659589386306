/* eslint-disable import/order */
/* eslint-disable camelcase */
// Libraries
import React, { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import compact from 'lodash/compact';
import classNames from 'classnames';
// Utils
import { prevented } from 'src/utils/event';
import { getAnnualAndMonthlySplitPlanPricing } from 'src/components/Plans/Pricing/utils';
// Constants
import { UPSELL_PLAN_BILLING_CYCLES, MONTHLY_PLAN_ID, UPSELL_PLAN_NAME } from 'src/constants/plan';
import PLAN_TYPES from 'src/components/Service/ServiceInfo/Parts/constants';

// Components && Styles
import { CheckboxGroupV2, CheckboxV2 } from 'src/components/HTKit/Forms/CheckboxV2';
import getPlanLabel from './Parts/ServiceUpsellLabel';
import styles from './styles.scss';

// Keeping this for showing both (monthly & annual) options together.
const HeaderComponent = ({ amount, onLearnMore, planName }) => (
  <div className={styles.upsellV2Header}>
    <h6>
      Join {planName} and save up to {amount} on this service.{' '}
      <a href="#" className={styles.upsellV2LearnMore} onClick={prevented(onLearnMore)}>
        Learn More
      </a>
    </h6>
  </div>
);

HeaderComponent.propTypes = {
  amount: PropTypes.number,
  onLearnMore: PropTypes.func,
  planName: PropTypes.string,
};

const ServiceInfoUpsellV2 = ({
  selectedPlanId,
  plansInfo,
  amount,
  onToggle,
  onLearnMore,
  upsellPlanPrice,
}) => {
  const [checkedValues, setCheckedValues] = useState([]);
  const { MONTHLY } = UPSELL_PLAN_BILLING_CYCLES;
  const monthlyPlanId = get(plansInfo, [MONTHLY, 'id'], MONTHLY_PLAN_ID);
  const monthlyPlanAmount = get(plansInfo, [MONTHLY, 'amount'], upsellPlanPrice);
  const planName = Object.keys(plansInfo).length
    ? get(plansInfo, [MONTHLY, 'name'])
    : UPSELL_PLAN_NAME;

  useEffect(() => {
    setCheckedValues(compact([selectedPlanId]));
  }, [selectedPlanId]);

  const onChange = useCallback(
    (newSelection) => {
      const planAlreadySelected = checkedValues.length;
      if (!planAlreadySelected) {
        setCheckedValues(newSelection);
        onToggle(true, newSelection[0]);
      }
      if (planAlreadySelected) {
        if (!newSelection.length) {
          // checked box is clicked again (newSelection === [])
          setCheckedValues(newSelection);
          onToggle(false);
        }
        if (newSelection.length) {
          // multiple boxes are checked (newSelection === [1, 4])
          newSelection.shift();
          setCheckedValues(newSelection);
          onToggle(true, newSelection[0]);
        }
      }
    },
    [checkedValues],
  );

  const planValue = monthlyPlanId;
  const showPlanType = PLAN_TYPES.MONTHLY;
  const planAmount = monthlyPlanAmount;
  const { oldPrice, currentPrice, planPricingSplit } = getAnnualAndMonthlySplitPlanPricing(true);
  const checkBoxPanelStyles = checkedValues.length ? styles.upsellContent : styles.upsellV2Content;

  const LabelComponent = getPlanLabel({
    showPlanType,
    amount,
    onLearnMore,
    planValue,
    planAmount,
    planName,
    oldPrice,
    currentPrice,
    planPricingSplit,
  });

  return (
    <CheckboxGroupV2
      containerClassName={checkBoxPanelStyles}
      value={checkedValues}
      onChange={onChange}
    >
      <CheckboxV2
        wrapperClass={classNames(styles.labelV2, styles.paddingOverride)}
        label={LabelComponent}
        value={planValue}
      />
    </CheckboxGroupV2>
  );
};

ServiceInfoUpsellV2.propTypes = {
  selectedPlanId: PropTypes.number,
  plansInfo: PropTypes.shape({
    yearly: PropTypes.shape({
      id: PropTypes.number,
      amount: PropTypes.number,
      planName: PropTypes.string,
    }),
    monthly: PropTypes.shape({
      id: PropTypes.number,
      amount: PropTypes.number,
    }),
  }),
  amount: PropTypes.string,
  upsellPlanPrice: PropTypes.string,
  onToggle: PropTypes.func,
  onLearnMore: PropTypes.func,
};

export default memo(ServiceInfoUpsellV2);

/*
  ServiceInfoUpsellV2
    - The original version of the component had a different appearance and
      only had the option for annual subscriptions. This new component offers a new look
      and annual/monthly options

  UPDATE(Sept 18, 2020):
    - Using this component to either show monthly or annual plan depending on whether
      split is turned on or off.
      The functionality for both options is still available in case product wants it put
      back again.
      HeaderComponent is part of showing both options together.
*/
