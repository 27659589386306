// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Components
import Icon from 'src/components/HTKit/Icon';
// Styles
import styles from './styles.scss';

const BreadCrumbStep = (props) => {
  const { activeStep, completedStep, currentIndex } = props;
  const stepStyles = cn({
    [styles.active]: activeStep >= currentIndex || completedStep >= currentIndex,
  }); // This makes the lines and circles green
  const showIcon = completedStep >= currentIndex && activeStep !== currentIndex && (
    <Icon name="v2-green-checkmark" className={styles.activeIcon} />
  );
  return <li className={stepStyles}>{showIcon}</li>;
};

BreadCrumbStep.propTypes = {
  activeStep: PropTypes.number,
  completedStep: PropTypes.number,
  currentIndex: PropTypes.number,
};

export default React.memo(BreadCrumbStep);
