import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTreatments } from '@splitsoftware/splitio-redux';
import { splitioSelectors } from 'src/components/SplitIO';

/**
 * This dispatches our split into the store for the caller toggle component or config/treatment useHook.
 * This hook hands off the treatment/config and the callers need to decide what to do with that data.
 * You would use this if you have another display wrapper for split maybe a connect function or something
 * and you want to dispatch a split.
 *
 * Note: See how its used, its fairly base. I extracted the useSelectors here to consolidate.
 * @param splitName
 */
export const useDispatchSplitTreatment = (splitName, attributes = null, predicate = () => true) => {
    /*
    * These are the treatment/config that could be handed back by split, which are now in redux.
    * If we don't have a matching split we will get treatment/control, config/{}
    */
    const [splitLoaded, setSplitLoaded] = useState(false);
    const dispatch = useDispatch();
    const isSplitReady = useSelector(splitioSelectors.splitReadySelector);
    const splitConfig = useSelector(splitioSelectors.treatmentWithConfigSelector(splitName)) || {};
    const splitTreatment = useSelector(splitioSelectors.treatmentSelector(splitName));
    const isError = useSelector(splitioSelectors.splitErrorSelector);

  useEffect(() => {
        if ((isSplitReady && splitTreatment) || isError) {
          setSplitLoaded(true);
        }
    }, [splitTreatment])

    useEffect( () => {
      /*  Split is loaded and no error with sdk */
      const predicateResult = typeof predicate === 'function' ? predicate() : true
      if (isSplitReady && !isError && predicateResult) {
        const treatments = getTreatments({ splitNames: [splitName], ...{attributes} });
        /* Do we have a valid treatment and the split isn't in state yet, dispatch into state */
        if (typeof treatments === 'object') {
          dispatch(treatments);
        }
      }

    }, [isSplitReady, splitName, predicate()]);

  return {
    splitLoaded,
    splitConfig,
    splitTreatment,
  }
};
