import { connect } from 'react-redux';
import { loadPage } from 'src/actions/common';
import { setAddress, submitAddress } from './actions';
import AddressPage from './AddressPage';

function mapStateToProps(state) {
  return {
    cart: state.getIn(['entities', 'cart'], null),
    address: state.getIn(['entities', 'cart', 'address'], null),
    fullAddress: state.getIn(['entities', 'cart', 'address', 'fullAddress'], null),
    address2: state.getIn(['entities', 'cart', 'address', 'address2'], null),
    page: state.getIn(['pages', 'address'], null),
    isMobileOrTablet: state.get('isMobile') || state.get('isTablet'),
  };
}

export default connect(mapStateToProps, { loadPage, setAddress, submitAddress })(AddressPage);
