import { select, put } from 'redux-saga/effects';
import { push, signInPath } from 'src/utils/paths';
// import { USER_LOADED, USER_NOT_LOADED } from '../containers/AppBase/constants';

export function* requireUser() {
  const user = yield select((state) => state.get('user'));
  if (user) {
    return user;
  }
  // const action = yield take.maybe((a) => {
  //   return ([USER_LOADED, USER_NOT_LOADED].includes(a.type));
  // });
  // if (action.user) {
  //   return action.user;
  // }
  yield put(push(signInPath));
  return null;
}
