/** Available frequencies for recurring bookings. */
export const RECURRING_FREQUENCY_VALUES = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
};

/**
 * Maps day index to single character, Sunday (0) to Saturday (6).
 * Used for weekday selection in recurring booking UI.
 */
export const WEEKDAY_SHORT_NAMES = [
  { value: 0, label: 'S' }, // Sunday
  { value: 1, label: 'M' }, // Monday
  { value: 2, label: 'T' }, // Tuesday
  { value: 3, label: 'W' }, // Wednesday
  { value: 4, label: 'T' }, // Thursday
  { value: 5, label: 'F' }, // Friday
  { value: 6, label: 'S' }, // Saturday
];

/**
 * Defines three types of monthly booking patterns:
 * - DAY_OF_THE_MONTH: Same date each month (e.g. "15th")
 * - ON_THE_ORDINAL: Pattern-based (e.g. "First Monday")
 * - CUSTOM: Advanced selection with additional options
 */
export const MONTHLY_OPTION_TYPES = {
  DAY_OF_THE_MONTH: 'day_of_the_month',
  ON_THE_ORDINAL: 'on_the_ordinal',
  CUSTOM: 'custom',
};

/**
 * Generates a fixed-size calendar grid for monthly selection.
 * Creates array of 31 numbered days plus empty placeholder entries to maintain grid layout.
 * [{value: 1, label: "1"} ... {value: 31, label: "31"}, {value: -1, label: ""}, ...]
 */
export const MONTHLY_CALENDAR_GRID_CONFIG = (() => {
  const calendarEntries = [];

  // First loop: Generate the actual days of the month
  for (let day = 1; day <= 31; day += 1) {
    calendarEntries.push({ value: day, label: `${day}` });
  }

  // Second loop: Add placeholder entries to ensure grid layout consistency
  const PLACEHOLDER_COUNT = 4;
  for (let i = 1; i <= PLACEHOLDER_COUNT; i += 1) {
    calendarEntries.push({ value: -1 * i, label: '' });
  }
  return calendarEntries;
})();

/**
 * Defines view options for custom monthly booking pattern:
 * - ON_THE_ORDINAL: Select by pattern (e.g. "Last Friday of month")
 * - SPECIFIC_DAYS: Select individual dates (e.g. "1st, 15th, 30th")
 */
export const CUSTOM_MONTHLY_VIEW_TABS = {
  ON_THE_ORDINAL: 'on_the_ordinal',
  SPECIFIC_DAYS: 'specific_days',
};

/** Default state configuration for recurring service panel */
export const STATE_DEFAULTS = {
  recurrenceInterval: 1,
  recurringFrequency: {
    value: RECURRING_FREQUENCY_VALUES.WEEKLY,
    label: 'Week',
  },
  selectedWeeklyDays: [],
  startTime: undefined,
  monthlyRecurrence: undefined,
  monthlyCustomDays: [],
  monthlyCustomOrdinal: undefined,
  monthlyCustomWeekdays: undefined,
};
