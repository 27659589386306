import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useToggle from 'src/hooks/useToggle';
import { CATEGORY_MENU } from 'src/components/Sidebar/constants';
import MenuFlows from './Parts/MenuFlows';
import styles from './styles.scss';

const Nav = ({
  isOpen,
  menuOnOpen,
  user,
  skuCategoriesAndSkus = {},
  skuLinkPrefix,
  hideMembership,
  logout,
  isMobile,
}) => {
  // Nav Controller
  const { toggleState: navIsOpen, setBooleanToggle: setNavIsOpenState } = useToggle(false);

  // CategoryMenu Controller
  const {
    toggleState: categoryMenuVisible,
    setBooleanToggle: setCategoryMenuVisibleState,
  } = useToggle(false);

  // ServiceMenu Controller
  const {
    toggleState: serviceMenuVisible,
    setBooleanToggle: setServiceMenuVisibleState,
  } = useToggle(false);

  // SubCategory Controller
  const {
    toggleState: subCategoryMenuVisible,
    setBooleanToggle: setSubCategoryMenuVisibleState,
  } = useToggle(false);

  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState({});

  const setSelectedCategoryCB = useCallback((categoryAndSkus) => {
    setSelectedCategory(categoryAndSkus);
    setServiceMenuVisibleState(true);
  }, []);

  // Use internal state to show nav
  useEffect(() => {
    if (isOpen) {
      if (menuOnOpen === CATEGORY_MENU) {
        setCategoryMenuVisibleState(true);
      }

      setNavIsOpenState(true);
    } else {
      // Reset menus on close
      setCategoryMenuVisibleState(false);
      setServiceMenuVisibleState(false);
      setNavIsOpenState(false);
      setSubCategoryMenuVisibleState(false);
    }
  }, [isOpen]);

  // Styles
  const navContainerStyles = cn(styles.navContainer, {
    [styles.open]: navIsOpen,
  });

  const menuProps = {
    user,
    setCategoryMenuVisibleState,
    setServiceMenuVisibleState,
    hideMembership,
    skuCategoriesAndSkus,
    logout,
    isMobile,
    categoryMenuVisible,
    setSelectedCategoryCB,
    serviceMenuVisible,
    selectedCategory,
    skuLinkPrefix,
    subCategoryMenuVisible,
    setSubCategoryMenuVisibleState,
    selectedSubCategory,
    setSelectedSubCategory,
  };

  const MenuFlow = MenuFlows.RepeatBooking;

  return (
    <nav className={navContainerStyles}>
      <MenuFlow {...menuProps} />
    </nav>
  );
};

Nav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  menuOnOpen: PropTypes.string,
  user: PropTypes.object,
  skuLinkPrefix: PropTypes.string,
  skuCategoriesAndSkus: PropTypes.array.isRequired,
  hideMembership: PropTypes.bool,
  isMobile: PropTypes.bool,
  logout: PropTypes.func,
};

export default React.memo(Nav);
