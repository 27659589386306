import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'src/components/HTKit/Icon';

const BackButton = ({ onClick, className }) => (
  <button className={cn("plainButton", className)} onClick={onClick} >
    <Icon name="v2-skinny-arrow" />
  </button>
);

BackButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default BackButton;

/*
  USAGE
  import BackButton from 'src/components/Elements/BackButton';

  <BackButton className={cn(styles.backButton)} onClick={()=>{}} />

  Todo: replace other back buttons from the site with this one, if applicable
*/
