import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.scss';

const Description = ({ benefit, wrapperStyle }) => {
  const { name, mobileName, value, description } = benefit;
  const descriptionStyles = classNames("p2 n700", styles.description);

  return (
    <div className={wrapperStyle}>
      <h5 className={styles.benefitName}>{name}</h5>
      <h5 className={styles.benefitNameMobile}>{mobileName}</h5>
      {value && (<h6 className={styles.valueBadge}>{value} value</h6>)}
      <p className={descriptionStyles}>{description}</p>
    </div>
  );
}

Description.propTypes = {
  benefit: PropTypes.shape({
    name: PropTypes.string.isRequired,
    mobileName: PropTypes.string.isRequired,
    value: PropTypes.string,
    description: PropTypes.string.isRequired,
  }),
  wrapperStyle: PropTypes.string,
};

Description.defaultProps = {
  wrapperStyle: ''
};

export default Description;

/*
  Currently being used in the cart and sku page upsell.
*/
