import { AuthFailed, PinRequired, ManageWorkflowTemplate } from './CustomTemplate';

export const TEMPLATE_NAMES = Object.freeze({
  AUTH_FAILED: 'authFailed',
  PIN_REQUIRED: 'pinRequired',
  MANAGE_WORKFLOW: 'manageWorkflow',
});

export const TEMPLATE_COMPONENTS = Object.freeze({
  [TEMPLATE_NAMES.AUTH_FAILED]: AuthFailed,
  [TEMPLATE_NAMES.PIN_REQUIRED]: PinRequired,
  [TEMPLATE_NAMES.MANAGE_WORKFLOW]: ManageWorkflowTemplate,
});
