import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  OPEN_MODAL,
  CLOSE_MODAL,
  LOGIN_MODAL_OPTIONS,
  OPEN_PASSCODE_MODAL,
  CLOSE_PASSCODE_MODAL,
  SET_USER_INFO,
  SET_LOGIN_PASSCODE_ERROR,
} from './constants';
import { USER_LOADED } from '../../AppBase/constants';

const initialState = fromJS({
  /** The original legacy login modal */
  open: false,
  /** Handles passcode auth modal */
  isPasscodeModalOpen: false,
  /** Skip redirect on login? */
  skipRedirect: false,
  [LOGIN_MODAL_OPTIONS.IS_PASSWORDLESS]: false,
  email: '',
  phoneLastFour: '',
  userInitials: '',
  loginPasscodeError: '',
  isPasswordlessCheckout: false,
});

function loginModalReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return state.merge({
        open: true,
        skipRedirect: action.skipRedirect,
        [LOGIN_MODAL_OPTIONS.IS_PASSWORDLESS]: action[LOGIN_MODAL_OPTIONS.IS_PASSWORDLESS] || false,
      });
    // Clear out the modal state on location change or user load
    case LOCATION_CHANGE:
    case USER_LOADED:
      return state.merge({
        open: false,
        isPasscodeModalOpen: false,
        [LOGIN_MODAL_OPTIONS.IS_PASSWORDLESS]: false,
      });
    case CLOSE_MODAL:
      return state.merge({
        open: false,
        skipRedirect: false,
        [LOGIN_MODAL_OPTIONS.IS_PASSWORDLESS]: false,
      });
    case OPEN_PASSCODE_MODAL:
      return state.merge({
        isPasscodeModalOpen: true,
        isPasswordlessCheckout: action?.payload?.isPasswordlessCheckout,
      });
    case CLOSE_PASSCODE_MODAL:
      return state.merge({
        isPasscodeModalOpen: false,
        skipRedirect: false,
        isPasswordlessCheckout: false,
      });
    case SET_USER_INFO:
      return state.merge({
        email: action.payload.email,
        phoneLastFour: action.payload.phoneLastFour,
        userInitials: action.payload.userInitials,
      });
    case SET_LOGIN_PASSCODE_ERROR:
      return state.merge({
        loginPasscodeError: action.payload.error,
      });
    default:
      return state;
  }
}

export default loginModalReducer;
