import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.scss';

export const Option = memo(({ innerProps, isSelected, isFocused, children }) => {
  return (
    <div
      {...innerProps}
      className={cn(styles.htSelectOption, {
        selected: isSelected || isFocused,
      })}
    >
      {children}
    </div>
  );
});

Option.displayName = 'Option';
Option.propTypes = {
  innerProps: PropTypes.object,
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool,
  children: PropTypes.node,
};
