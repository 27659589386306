import get from 'lodash/get';
import { createSelector } from 'reselect';
import { pureCartSelector } from 'src/selectors/cart';
import modalSelector from 'src/selectors/modal';
import { getFieldSalesCookie } from 'src/utils/cookies/fieldSalesCookie';
import { formatPhoneBasic } from 'src/utils/formatter/phone';
import { FIELD_SALES_PATH_ALLOWS } from 'src/containers/FieldSales/constants';
import { beforeRouteTransitionSelector } from 'src/selectors/router';
import { isImmutable } from 'src/utils/helpers';

export const fieldSaleAgentSelector = createSelector(pureCartSelector, (cart) => {
   return {
     agentId: get(cart, 'fieldSaleAgent.id', ''),
     agentType: get(cart, 'fieldSaleAgent.agentType', ''),
     agentEmail: get(cart, 'fieldSaleAgent.email', ''),
   }
});

export const fieldSaleAgentTypeSelector = createSelector(pureCartSelector, (cart) => {
  return get (cart, 'fieldSaleAgent.agentType', '')
});

export const fieldSaleClientIdSelector = createSelector(pureCartSelector, (cart) => {
  return get (cart, 'clientId', '')
});

export const fieldSaleTokenSelector = createSelector(pureCartSelector, (cart) => {
  return get (cart, 'token', '')
});

export const fieldSaleAgentIdSelector = createSelector(
  pureCartSelector, (cart) => {
  return get(cart, 'fieldSaleAgent.id', '')
});

/*
* Does the seller have a cookie or has a cart with fieldSales AND the window
* dictates a fieldSales flow.
*
* Admittedly, this is a VERY JANKY. Not sure of any other work around until
* we can actually "really" componentize things. Too coupled to layout.
* */
export const fieldSaleAgentVerifyLayoutSelector = createSelector(
  fieldSaleAgentSelector,
  beforeRouteTransitionSelector,
  (fieldAgent, beforeRouteTransition) => {
    const route = isImmutable(beforeRouteTransition)
       ? beforeRouteTransition.toJS()
       : beforeRouteTransition;

    if (!process.browser) return;

    const cookie = getFieldSalesCookie() || {};
    const pathname = get(route, 'location.pathname', '');
    // should probably do a selector that booleans if both agentIds places exist
    const cookieAgentId = cookie.agentId;
    const reduxAgentId = fieldAgent.agentId;
    // eslint-disable-next-line consistent-return
    return (cookieAgentId && reduxAgentId)
          && FIELD_SALES_PATH_ALLOWS.some(path => {
            return pathname.includes(path)
    })
});

export const fieldSalesModalSelector = (name) => createSelector(
  modalSelector('fieldSales'),
  (modals) => {
    return modals.getIn([name, 'visible']);

});

export const fieldSaleAgentClientSelector = createSelector(
  pureCartSelector,
  (cart) => {
    /*
      The apis in both cases "confirm/add user" return diff objects. Get at both.
      And the fact they are named differently, makes it a lil' tedious.
    */
    const { phone: cartPhone, clientName: cartName, clientEmail: cartEmail, clientId: cartId } = cart || {};

    return {
      clientName: cartName,
      clientEmail: cartEmail,
      clientId: cartId,
      phone: formatPhoneBasic((cartPhone), '-')
    }
  });
