import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SelectField from 'src/components/HTKit/Forms/SelectField';
import { SIZES } from 'src/components/HTKit/Forms/constants';
import { QTY_OPTIONS } from './constants';
import styles from './styles.scss';

const QtyDropdown = ({ shouldShowDropdown, onDropdownChange, dropdownValue, quantity, options, className }) => {

  return shouldShowDropdown ? (
    <SelectField
      value={dropdownValue}
      onChange={onDropdownChange}
      className={cn(className, styles.dropDown)}
      defaultValue={quantity}
      clearable={false}
      searchable={false}
      options={options}
      placeholder={dropdownValue}
      size={SIZES.xs}
    />
  ): null;
};

QtyDropdown.propTypes = {
  shouldShowDropdown: PropTypes.bool,
  options: PropTypes.object,
  onDropdownChange: PropTypes.func,
  dropdownValue: PropTypes.number,
  quantity: PropTypes.number,
  className: PropTypes.string,
};

QtyDropdown.defaultProps = {
  options: QTY_OPTIONS,
  shouldShowDropdown: false,
  className: '',
}

export default QtyDropdown;
