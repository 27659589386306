const stringToBoolean = (string = '', defaultValue = true) => {
  switch (`${string}`.toLowerCase()) {
    case 'false':
    case '0':
      return false;
    case 'true':
    case '1':
      return true;
    default:
      return defaultValue;
  }
};

export default stringToBoolean;
