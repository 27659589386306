/**
 * Simple generator yield out array values.
 * @param arr
 * @returns {IterableIterator<__Cursor.Cursor | List<any> | Stack<any> | any | St.props>}
 */
function* arrayGenerator(arr = []) {
  if (!Array.isArray(arr)) return '';

  while (true) {
    yield arr.shift();
  }
}

/**
 * This is a recursion operation so that we can iterate over a pathing array and fire
 * the matching function call. If we can't get at a valid value, we gracefully return out.
 *
 * @param arr {array}: pathing to desired effect
 * @param parentObject {object}: Object tree to traverse over
 * @returns {*}
 */
const findMatchingFunction = (arr = [], parentObject = {}) => {
  /* Create generator */
  const genArray = arrayGenerator(arr);

  /**
   * Recursion function
   *
   * @param value: yielded generator value
   * @param childObj: Object to find our value
   */
  return (function recurseFinder({ value }, childObj) {
    /* base case */
    if (!value || !childObj) return genArray.return('').value;
    /* found a function match */
    if (typeof childObj[value] === 'function') return childObj[value]();

    /* no match, recurse */
    return recurseFinder(genArray.next(), childObj[value]);
  })(genArray.next(), parentObject);
};

/**
 * This serves as our command center for incoming messages thru the postMessage API
 *
 * @param messagingActions fn -> {} This is the pages actionable methods.
 * @param properties { *: * } object containing props and attributes from the page
 * @param data {type: string, message: any, caller: string}
 */
const iframeMessaging = (messagingActions) => (properties) => {
  const { props, attributes } = properties;
  return ({ data }) => {
    const { type = '', message = '', caller = '' } = data;
    /*
     * If our listener picks up a 'message' from the caller page,
     * lets get the contents and create a pathing
     */
    const pathAction = caller === attributes.CALLER_PAGE && [type, message].filter((a) => a);
    /* Simple helper to consolidate the same behavior of segment tracking & redirect */
    findMatchingFunction(pathAction, messagingActions(props, { ...attributes, message }));
  };
};

// eslint-disable-next-line import/prefer-default-export
export { iframeMessaging };
