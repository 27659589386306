import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updatePhone, cookiesLoaded } from 'src/containers/AppBase/actions';
import { LANDING_PHONE_COOKIE } from 'src/constants/tracking';
import Cookies from 'js-cookie';

const useUpdatePhone = ({ phone, soft, expires = LANDING_PHONE_COOKIE.EXPIRATION }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (phone) {
      dispatch(updatePhone({ phone, soft }));
      Cookies.set(LANDING_PHONE_COOKIE.NAME, phone, { expires });
      dispatch(cookiesLoaded(Cookies.get()));
    }
  }, []);
};

export default useUpdatePhone;

/*
  Product may want to display a specific phone number for partners and/or source attribution.
  Use this hook to update the Redux store and set a cookie with the desired phone number.
*/
