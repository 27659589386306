// PopularHomeServices
import computerSupport from 'src/images/products/computer-with-sandy-background.png';
import smartThermostat from 'src/images/products/Nest.png';
import smartSecurityCam from 'src/images/products/SmartCam.png';
import tvMounting61plus from 'src/images/products/tvmount61plus.png';
import tvmount3360 from 'src/images/products/tvmount33-60.png';

// PopularRemoteServices
import CompAccessories from 'src/images/products/CompAccessories.png';
import Computer from 'src/images/products/computer-blank-screen.png';
import HomeTheater from 'src/images/products/HomeTheater.png';
import Printer from 'src/images/products/Printer.png';
import StreamingDevice from 'src/images/products/StreamingDevice.png';
import WiFi from 'src/images/products/WiFi.png';

export const PAGE_NAME = 'homePage';
export const GET_RATINGS_AND_NUM_SERVICES = `${PAGE_NAME}/GET_RATINGS_AND_NUM_SERVICES`;
export const SET_RATINGS_AND_NUM_SERVICES = `${PAGE_NAME}/SET_RATINGS_AND_NUM_SERVICES`;
export const REMOVE_PARTNER_EFFECTS = `${PAGE_NAME}/REMOVE_PARTNER_EFFECTS`;

const skuLinks = '/tech-support/';

export const POPULAR_SKUS = Object.freeze([
  {
    id: 1049,
    name: 'TV Mounting (33" - 60")',
    image: tvmount3360,
    link: `${skuLinks}tvmount-medium`,
  },
  {
    id: 1050,
    name: 'TV Mounting (61" or larger)',
    image: tvMounting61plus,
    link: `${skuLinks}tvmount-large`,
  },
  {
    id: 13,
    name: 'Computer Support',
    image: computerSupport,
    link: `${skuLinks}computer-repair-laptop-desktop`,
  },
  {
    id: 200,
    name: 'Smart Security Cam Install',
    image: smartSecurityCam,
    link: `${skuLinks}smart-cam-installation`,
  },
  {
    id: 612,
    name: 'Smart Thermostat Install',
    image: smartThermostat,
    link: `${skuLinks}Smart-Thermostat-Installation`,
  },
]);

export const POPULAR_VIRTUAL_SKUS = Object.freeze([
  {
    id: 1034,
    name: 'Computer Support',
    image: Computer,
    link: `${skuLinks}remote-computer`,
  },
  {
    id: 1037,
    name: 'Streaming Device Setup',
    image: StreamingDevice,
    link: `${skuLinks}online-streaming-device`,
  },
  {
    id: 1036,
    name: 'WiFi Support',
    image: WiFi,
    link: `${skuLinks}online-wifi`,
  },
  {
    id: 1035,
    name: 'Printer Support',
    image: Printer,
    link: `${skuLinks}online-printer`,
  },
  {
    id: 1039,
    name: 'Periphiral Computer Device Setup',
    image: CompAccessories,
    link: `${skuLinks}online-computer-device`,
  },
  {
    id: 1038,
    name: 'Home Theatre Support',
    image: HomeTheater,
    link: `${skuLinks}online-home-theater`,
  },
]);

export const TOTAL_COMPLETED_SERVICES_AND_REVIEWS_ID = 'total-completed-services-and-reviews';

export const HOME_PAGE_CLICK_SEGMENT = 'src/containers/HomePageV2/HOME_PAGE_CLICK_SEGMENT';

const prepareSkuCategories = (categories) =>
  categories.map((cat, index) => {
    return { ...cat, listOrder: index };
  });

// Categories will be rendered in the same order as this list -GH Apr 9, 2020
const skuCategoryDetails = [
  { categoryId: 1, name: 'computer', icon: 'computer-support-outline' },
  { categoryId: 2, name: 'wifi', icon: 'wifi-network-outline' },
  { categoryId: 3, name: 'mobile', icon: 'mobile-tablet-outline' },
  { categoryId: 4, name: 'audioVideo', icon: 'audio-video-outline' },
  { categoryId: 7, name: 'tvMounting', icon: 'tv-mounting-outline' },
  { categoryId: 8, name: 'smartHome', icon: 'smart-home-outline' },
  { categoryId: 27, name: 'virtualServices', icon: 'cat-icon-virtual' },
  { categoryId: 40, name: 'homeSecurity', icon: 'cat-icon-home-security' },
  { categoryId: 41, name: 'aroundTheHome', icon: 'home-office-outline' },
];

export const SKU_CATEGORY_ICONS = prepareSkuCategories(skuCategoryDetails);

export const DYNAMIC_ONLINE_HEADER_LABELS = [
  { id: 0, title: 'Computer' },
  { id: 1, title: 'WiFi Network' },
  { id: 2, title: 'Streaming Device' },
  { id: 3, title: 'Printer' },
  { id: 4, title: 'Home Theater' },
];
