import { fromJS } from 'immutable';
import { SET_LANDING } from 'src/constants/landing';
import { PAGE_LOADED } from 'src/constants/common';
import { PAGE_NAME as LANDING_PAGE_NAME } from 'src/containers/LandingPage/constants';

const landingsInitialState = fromJS({});

export default function landingsReducer(state = landingsInitialState, action) {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        case LANDING_PAGE_NAME: {
          return state.set(action.landing.seoName, fromJS(action.landing));
        }
        default:
          return state;
      }
    case SET_LANDING: {
      const {
        payload: { landing },
      } = action;
      return state.set(landing.seoName, fromJS(landing));
    }
    default:
      return state;
  }
}
