import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { merge, omit } from 'lodash';
import { push, plansPath, planThankYouPath } from 'src/utils/paths';
import moment from 'moment';
import Cookies from 'js-cookie';
import { selectRoutes } from 'src/apiRoutes';
import { LOAD_PAGE } from 'src/constants/common';
import { clearFormErrors, pageLoaded, updateUser } from 'src/actions/common';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import { setUpFormErrors } from 'src/sagas/common/form';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { removeGiftCard } from '../RedeemPage/actions';	
import { PAGE_NAME, CHECK_USER, ORDER_PLAN, ORDERING_TYPES, UPDATE_PLAN_PRICES } from './constants';
import {
  planLoaded,
  planOrdered,
  userChecked,
  giftCardMessage,
  planPricesUpdated,
  updatePlanPrices,
} from './actions';

const ACCOUNT_PAGE_NAME = 'account';
const PROMO_PLANS = ['online-promo', 'groupon-online-promo'];
const ONLINE_PROMO_PLAN_NAME = 'online-promo';

export function* loadPlanSaga({ name }) {
  const routes = yield call(selectRoutes);
  const requestResult = yield call(routes.users.current, { account: true });
  if (!requestResult.err) {
    const { user } = requestResult.data;
    yield put(updateUser(user));
  }

  const response = yield call(routes.plans.show, { name });
  if (response.err) {
    const { status } = response.err.response;
    if (status === 404) {
      yield put(push(plansPath));
      return;
    }
    yield put(displayErrors(response));
  } else {
    const { plan } = response.data;
    yield put(planLoaded(plan));
  }
  yield put(pageLoaded(PAGE_NAME));
}

function isPromoPage(name) {
  return PROMO_PLANS.includes(name.toLowerCase());
}

export function* updatePricesSaga({ payload }) {
  const { address, keepErrors } = payload;
  let { couponCode, name, paymentCycle } = payload;
  const applyOnlinePromoPlan = isPromoPage(name);

  // if (name.toLowerCase() === 'online-promo') {
  if (applyOnlinePromoPlan) {
    couponCode = 'FREEMONTH';
    paymentCycle = 'monthly';
    name = ONLINE_PROMO_PLAN_NAME;
  }
  const routes = yield call(selectRoutes);
  if (!keepErrors) yield put(clearFormErrors(PAGE_NAME));
  yield put(requestStarted());
  const response = yield call(
    routes.plans.updatePrices,
    { name },
    { paymentCycle, address, couponCode },
  );

  yield put(requestFinished());
  if (response.err) {
    yield setUpFormErrors(PAGE_NAME, response);
    if (response.data && response.data.formErrors && response.data.formErrors.couponCode) {
      yield put(updatePlanPrices(merge({}, payload, { couponCode: null, keepErrors: true })));
    }
  } else {
    const { breakdown } = response.data;
    yield put(planPricesUpdated({ breakdown }));
  }
}

export function* checkUserSaga({ payload }) {
  const { email } = payload;
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.users.check, { email });

  yield put(requestFinished());
  if (response.err) {
    yield put(userChecked(false));
  } else {
    const { user } = response.data;
    yield put(userChecked(user));
  }
}

function* orderPlanSaga({ payload }) {
  yield put(requestStarted());
  yield put(clearFormErrors(PAGE_NAME));
  const { plan, token } = payload;
  const { name, tax, pageName } = plan;
  const routes = yield call(selectRoutes);
  const siteID = Cookies.get('siteID');
  const dte = Cookies.get('dte');
  if (
    siteID &&
    dte &&
    moment()
      .subtract(30, 'days')
      .isBefore(new Date(dte))
  ) {
    plan.siteID = siteID;
    plan.dte = dte;
  }
  const response = yield call(
    routes.plans.order,
    { name },
    merge(omit(plan, ['name', 'pageName']), { stripeToken: token }),
  );
  yield put(requestFinished());
  if (response.err) {
    yield setUpFormErrors('plan', response);
  } else {
    if (plan.planTarget === ORDERING_TYPES.OTHER) {
      yield put(giftCardMessage({ clientFirstName: plan.clientFirstName, planName: plan.name }));
    }
    response.data.plan.tax = tax;
    yield put(planOrdered(response.data.plan));
    yield put(removeGiftCard());
    yield loadUserProfileSaga(ACCOUNT_PAGE_NAME, false, true);
    yield put(push(planThankYouPath(pageName)));
  }
}

export function* planFlow() {
  yield takeEvery(UPDATE_PLAN_PRICES, updatePricesSaga);
  yield takeLatest(CHECK_USER, checkUserSaga);
  yield takeLatest(ORDER_PLAN, orderPlanSaga);
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, loadPlanSaga);
}

export default [planFlow];
