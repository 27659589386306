import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.scss';

/**
  ErrorMsg

  Basic component to render error messages
*/
const ErrorMsg = ({ error, className }) => {
  return error ? (
    <div className={cn(className, styles.wrapper)}>
      <p className={styles.error}>{error}</p>
    </div>
  ) : null;
};

ErrorMsg.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string,
};

export default ErrorMsg;
