import React, { memo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Grid } from 'HTKit/Grid';
import { Picture, TYPES as PICTURE_TYPES } from 'src/components/Elements/Picture';
import peaceOfMindJpg from 'src/images/peace-of-mind.jpg';
import styles from './styles.scss';

const defaultImageSources = [{ srcSet: peaceOfMindJpg, type: PICTURE_TYPES.JPG }];

const GuaranteeBlock = ({
  classes,
  imgClasses,
  title,
  text,
  ctaButton = null,
  imageSources = defaultImageSources,
}) => {
  const imageStyles = cn(styles.image, imgClasses);
  const subTitleStyles = cn('p0', styles.subTitle);
  const containerStyles = cn(styles.container, classes);

  return (
    <Grid.Fluid classes={containerStyles}>
      <Grid.Row classes={styles.customRow}>
        <Grid.Column sm={4} md={8} lg={5}>
          <div className={styles.serviceText}>
            <div className={styles.watermark} />
            <h3 className={styles.title}> {title} </h3>
            <p className={subTitleStyles}>{text}</p>
            {ctaButton}
          </div>
        </Grid.Column>
        <Grid.Column sm={4} md={8} lg={7}>
          <Picture sources={imageSources} className={imageStyles} />
        </Grid.Column>
      </Grid.Row>
    </Grid.Fluid>
  );
};

GuaranteeBlock.propTypes = {
  classes: PropTypes.string,
  imgClasses: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  ctaButton: PropTypes.element,
  imageSources: PropTypes.arrayOf(PropTypes.object),
};

GuaranteeBlock.defaultProps = {
  imgClasses: styles.guaranteeBlockImage,
  title: 'Peace of Mind Guarantee',
  text:
    "We're committed to your complete satisfaction with our service. If you're not 100% happy, we'll do whatever it takes to make it right.",
};

export default memo(GuaranteeBlock);
