/* eslint-disable import/prefer-default-export  */
import get from 'lodash/get';
import { skuMeta } from 'src/utils/jsonLd/index';
import { displayTimestamp } from 'src/utils/date';
import { isMobileView as setIsMobileView } from 'src/utils/ui';
import { IFRAME_SRCS_CAT_BY_ID } from 'src/containers/ProductPage/IframeProductPage/constants';
import { formatDollarAmount, formatPrice } from 'src/utils/formatter/currency';
import { getDirectBookingTechIdOrSlug } from 'src/utils/cookies/directBookingCookie';
import { TAB_KEYS } from './constants';

const getMemberPriceDifference = (basePrice, memberPrice) => {
  if (!basePrice || !memberPrice) return null;
  return basePrice - memberPrice;
};
const skuPrices = (sku) => {
  const { remoteSavings, startsAtPrice, basePrice, subscriptionPrices = {} } = sku;
  const [productWithInstallationSku = {}] = sku.productVariants;

  const memberPriceDifference = {
    product: formatDollarAmount({
      amount: getMemberPriceDifference(
        basePrice,
        get(subscriptionPrices, 'product.cheapestPrice', 0),
      ),
      truncateEvenDollars: true,
    }),
    productWithInstallation: formatDollarAmount({
      amount: getMemberPriceDifference(
        productWithInstallationSku.basePrice,
        get(subscriptionPrices, 'productWithInstallation.cheapestPrice', 0),
      ),
      truncateEvenDollars: true,
    }),
  };
  return {
    price: remoteSavings ? startsAtPrice : formatPrice(basePrice + remoteSavings),
    memberPriceDifference,
  };
};

const hasSectionData = (data) => Array.isArray(data) && data.length;

const sortSectionData = (data = []) => data.sort((a, b) => a.position - b.position);

/*
  TODO - The backend is returning mobile and desktop images, but <ImageGallery />
  is not set up to handle both types. For now, we'll just pull the desktop
  version.
*/
const galleryFormatter = (gallery) => {
  // ToDo - sort
  return gallery.map((item) => {
    const { id, attachments } = item;
    const isVideoItem = attachments.find((a) => a.mediaType === 'video');

    if (isVideoItem) {
      const { original } = isVideoItem;
      const thumbnail = attachments.find((a) => a.mediaType === 'image');
      const { thumb = '' } = thumbnail || {};
      return { id, type: 'video', source: original, thumbnailSource: thumb };
    }

    const desktopAsset = attachments.find((a) => a.resolution === 'desktop');
    const { original, thumb } = desktopAsset;
    return { id, type: 'image', source: original, thumbnailSource: thumb };
  });
};

/*
  Helper to format api data for usage on ProductPage.
*/
const formatProductData = (sku) => {
  const { FEATURE, FAQ, INSTALLATION, COMPATIBILITY, WORKS_WITH, PRODUCT_SPECS } = TAB_KEYS;
  const {
    heroImageUrl,
    heroMobileImageUrl,
    heroHeader,
    heroSubheader,
    features,
    faq,
    installation,
    compatibility,
    worksWith,
    specs,
    gallery: _gallery,
    productVariants,
    basePrice,
  } = sku;
  const tabsData = {};

  // Tabs
  if (hasSectionData(features)) {
    tabsData[FEATURE] = { label: 'Features', data: sortSectionData(features) };
  }

  if (hasSectionData(faq)) {
    tabsData[FAQ] = { label: 'FAQ', data: sortSectionData(faq) };
  }

  const { steps, attachment } = installation || {};
  let installationCost = '99';
  if (productVariants && productVariants.length) {
    const productVariantSku = productVariants.find((p) => p.requiresInstallation === true);
    const productVariantValue = productVariantSku.basePrice;
    installationCost = (productVariantValue - basePrice) / 100;
  }

  if (hasSectionData(steps)) {
    tabsData[INSTALLATION] = {
      label: 'Installation',
      data: { steps: sortSectionData(steps), attachment, value: `$${installationCost}` },
    };
  }

  if (hasSectionData(compatibility)) {
    tabsData[COMPATIBILITY] = { label: 'Compatibility', data: compatibility };
  }

  if (hasSectionData(worksWith)) {
    tabsData[WORKS_WITH] = { label: 'Works With', data: worksWith };
  }

  if (hasSectionData(specs)) {
    tabsData[PRODUCT_SPECS] = { label: 'Specs', data: specs };
  }

  const tabs = Object.values(tabsData).map((tab) => tab.label);

  // Gallery
  let gallery = [];
  if (hasSectionData(_gallery)) {
    gallery = [...galleryFormatter(sortSectionData(_gallery))];
  }

  // Bottom Hero
  const bottomHero = {};
  if (heroImageUrl) {
    bottomHero.heroImageUrl = heroImageUrl;
    bottomHero.heroMobileImageUrl = heroMobileImageUrl;
    bottomHero.header = heroHeader;
    bottomHero.subheader = heroSubheader;
  }

  return { tabsData, tabs, gallery, bottomHero };
};

/*
  We need to mirror return value of ServicePage's utils. This
  container uses Service components, which expect data in a certain format.

  We'll definitely need to circle back to
  consolidating the two, if possible, in the future.
*/
export const adjustProductInfo = ({
  sku,
  reviews,
  plan,
  plansInfo,
  selectedPlanId,
  showMemberPrice,
  showUpsell,
}) => {
  if (!sku) return null;

  const isMobileView = setIsMobileView();
  const { tabsData, tabs, gallery, bottomHero } = formatProductData(sku);
  const directBookingTechId = getDirectBookingTechIdOrSlug();
  const productLink = directBookingTechId
    ? `/schedule/${directBookingTechId}/products`
    : '/products/shop';
  const categoryLink = directBookingTechId ? '' : IFRAME_SRCS_CAT_BY_ID[sku.categoryId];

  return {
    ...sku,
    extra: {
      bottomHero,
      breadcrumbs: [
        { label: 'Products', link: productLink },
        { label: sku.category, link: categoryLink },
        { label: sku.name },
      ],
      gallery,
      ctaText: 'Add to Cart',
      meta: {
        script: skuMeta(sku),
      },
      plan,
      plansInfo,
      prices: skuPrices(sku),
      rating: sku.ratingAttributes && sku.ratingAttributes.rating > 0 && sku.ratingAttributes,
      reviews: (reviews[sku.id] || []).map((r) => ({
        ...r,
        date: displayTimestamp(r.timestamp),
      })),
      selectedPlanId,
      showMemberPrice,
      showUpsell,
      showStickyBookNow: isMobileView,
      tabs,
      tabsData,
    },
  };
};

export const trimSeoName = ({ seoName }) => {
  return seoName.replace('-with-installation', '');
};

export const getInstallationPrice = ({ product, service, quantity = 1 }) => {
  /*
    We need to get the installation price from service(productVariant).
    serviceSku is the service only sku associated with the product variant.
    This function is used in the cart page, which needs to take quantities into account
  */
  if (!service) return null;
  const { basePrice } = product;
  const { basePrice: servicePrice } = service;
  const serviceOnlyPrice = service.serviceSku && service.serviceSku.basePrice;
  return {
    installationPrice: (servicePrice - basePrice) * quantity,
    installationPriceFormatted: formatDollarAmount({
      amount: (servicePrice - basePrice) * quantity,
      truncateEvenDollars: true,
    }),
    serviceOnlyPrice: serviceOnlyPrice * quantity,
    serviceOnlyPriceFormatted: formatDollarAmount({
      amount: serviceOnlyPrice * quantity,
      truncateEvenDollars: true,
    }),
  };
};

export const getMemberInstallationPrice = ({ subscriptionPrices = {}, service = {} }) => {
  // Right now this function is only used on Product Page which does not deal with quantities
  if (!Object.keys(subscriptionPrices).length || !Object.keys(service).length) return {};
  const serviceOnlyPrice = service.serviceSku && service.serviceSku.basePrice;
  const { cheapestPrice: cpProductOnly } = subscriptionPrices.product;
  const { cheapestPrice: cpInstallation } = subscriptionPrices.productWithInstallation;
  if (!cpProductOnly || !cpInstallation) return {};
  return {
    installationPrice: cpInstallation - cpProductOnly,
    installationPriceFormatted: formatDollarAmount({
      amount: cpInstallation - cpProductOnly,
      truncateEvenDollars: true,
    }),
    serviceOnlyPrice,
    serviceOnlyPriceFormatted: formatDollarAmount({
      amount: serviceOnlyPrice,
      truncateEvenDollars: true,
    }),
  };
};

/**
 * Returns estimated delivery based on delivery days
 *
 * @param startDate? defaults to now
 * @param daysToAdd? defaults to 3
 * @returns {string}
 */
export const addBusinessDays = (startDate = new Date(), daysToAdd = 3) => {
  let count = 0;
  let deliveryDate;
  while (count < daysToAdd) {
    const sunday = 0;
    const saturday = 6;
    deliveryDate = new Date(startDate.setDate(startDate.getDate() + 1));
    const dayOfTheWeek = deliveryDate.getDay();
    const isWeekday = dayOfTheWeek !== sunday && dayOfTheWeek !== saturday;
    if (isWeekday) {
      count += 1;
    }
  }
  return deliveryDate.toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  });
};
