import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import usePrevious from 'src/hooks/usePrevious';
import ModalHeader from './SlideModalHeader';
import styles from './styles.scss';

const TRANSITION_DURATION_MS = 200; // see styles

const SlideModal = ({
  children,
  customOverlayClass,
  closeStyles,
  showLogo,
  handleClose,
  headerStyles,
  contentStyles,
  childrenStyles,
  headerType,
  customHeader: CustomHeader,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [appear, setAppear] = useState(false);

  const previousOpenProp = usePrevious(props.open);

  useEffect(() => {
    const didOpenPropChange = Boolean(previousOpenProp) !== Boolean(props.open);
    if (props.open) {
      setAppear(true);
      setOpen(true);
    } else if (!props.open && didOpenPropChange) {
      setOpen(false);
      setTimeout(() => {
        setAppear(false);
      }, TRANSITION_DURATION_MS);
    }
  }, [props.open]);

  const overlayClasses = cn({
    [styles.overlay]: true,
    [styles.slide]: open,
    [styles.appear]: appear,
    [customOverlayClass]: !!customOverlayClass,
  });
  const contentClasses = cn({
    [styles.contentWrapper]: true,
    [contentStyles]: true,
  });

  const headerProps = {
    handleClose,
    buttonStyle: closeStyles,
    showLogo,
    headerStyles,
    headerType,
  };

  return (
    <div className={overlayClasses}>
      <div className={contentClasses}>
        {CustomHeader ? <CustomHeader {...headerProps} /> : <ModalHeader {...headerProps} />}
        <div className={childrenStyles}>{children}</div>
      </div>
    </div>
  );
};

export { HEADER_TYPES } from './constants';

SlideModal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  customOverlayClass: PropTypes.string,
  showLogo: PropTypes.bool,
  closeStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  headerStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  contentStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  childrenStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  headerType: PropTypes.string,
  customHeader: PropTypes.element, // A JSX element that will render instead of <ModalHeader>
};

SlideModal.defaultProps = {
  closeStyles: '',
  headerStyles: '',
  contentStyles: '',
  childrenStyles: '',
};

export default SlideModal;
