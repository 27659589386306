import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.scss';

/**
 * SmallCallout
 *
 * Only to be used in headers.
 */
const SmallCallout = ({ text = '', children, className = '' }) => {
  const smallCalloutStyles = cn('text-align-center padding-tiny1', styles.container, className);

  return (
    <div className={smallCalloutStyles}>
      {children ? <>{children}</> : <p className="caption">{text}</p>}
    </div>
  );
};

SmallCallout.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default SmallCallout;
