// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { customerReviewsPath } from 'src/utils/paths';
import ServiceReviews from 'src/components/Elements/CustomerReviews';
// Styles
import styles from './styles.scss';

const CustomerReviews = ({ reviews, title, viewMore, suppressAddressLine, backgroundColor, headerClasses = '' }) => {
  const background = `${backgroundColor}Background`;

  return (
    <section className={`l-content-section ${styles[background]}`}>
      <h2 className={`l-section-header ${styles.header} ${headerClasses}`}>{title}</h2>
      <div className={styles.headerImage} />
      <div className={styles.container}>
        <div className={styles.reviewContainer}>
          <ServiceReviews
            reviews={reviews && reviews.toJS()}
            className={styles.customerReviews}
            headerText=""
            headerStyles="text-align-center"
            itemStyles={styles.reviewItem}
            suppressAddressLine={suppressAddressLine}
          />
        </div>
      </div>
      {viewMore && (
        <Link to={customerReviewsPath()} className={cn('large-link', styles.moreLink)}>
          View More
        </Link>
      )}
    </section>
  );
};

CustomerReviews.defaultProps = {
  backgroundColor: 'white',
  reviewCount: 3,
  title: 'Tech Issues Solved Across America',
  viewMore: true,
};
CustomerReviews.propTypes = {
  reviews: PropTypes.object,
  backgroundColor: PropTypes.string.isRequired,
  reviewCount: PropTypes.number,
  title: PropTypes.string,
  viewMore: PropTypes.bool,
  suppressAddressLine: PropTypes.bool,
  headerClasses: PropTypes.string,
};

export default CustomerReviews;

/*
  CustomerReviews:
    A reusable page section showing brief customer reviews.

  Examples:
    <CustomerReviews backgroundColor="white" reviews={reviews} />

  Properties:
    backgroundColor:
      type: string
      description: Used to set the background-color CSS property
    reviews:
      type: object (Immutable list)
      description: List of reviews

  Last modified: -JK March 24, 2017
*/
