import { call, put, takeEvery, select, takeLatest, all } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import qs from 'query-string';
import { selectRoutes } from 'src/apiRoutes';
import { PAGE_LOADING_ERROR } from 'src/constants/common';
import {
  newRouteSchema,
  addUrlParamCoupon,
  identifySegmentStandaloneCall,
} from 'src/actions/common';
import { checkPlanSubscriberPhone } from 'src/sagas/common/marketing';
import { paramsSelector, fullPathSelector, locationSearchSelector } from 'src/selectors/router';
import { updateTracking } from 'src/actions/tracking';
import Bugsnag from 'src/utils/Bugsnag';
import HTKustomerChat from 'src/utils/Kustomer/HTKustomerChat';
import { push, loginPath } from 'src/utils/paths';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import { setBrandingState } from 'src/utils/branding/utils';
import { getCouponCookie, setCouponCookie } from 'src/utils/cookies/coupon';
import { loginRedirect } from 'src/containers/LoginPage/LoginForm/actions';
import { updateCart } from '../AddSkuPage/actions';
import { LOAD_USER, LOAD_CART_PREVIEW, LOGOUT, APP_LOADED, USER_LOADED } from './constants';
import { userLoaded, userNotLoaded, userLoggedOut, updateCartPreview } from './actions';
import { updateLowTierConfig } from '../BookingPage/SummaryPage/actions';

const userSelector = (state) => state.get('user');
const includeAccount = (state) => {
  const pathName = state.getIn(['router', 'location', 'pathname']);
  return pathName.substring(0, 7) === '/plans';
};

function setKustomerUser(user) {
  HTKustomerChat.hideChat();
  HTKustomerChat.identify(user.kustomerJwt);
}

function resetKustomerChat() {
  HTKustomerChat.logout();
}

function* paramsSaga() {
  const params = yield select(paramsSelector);
  yield put(updateTracking(params));
}

// Since add_coupon_id can come in anywhere, we sniff on every page
function* urlParamsCouponSaga() {
  const locationSearch = yield select(locationSearchSelector);
  const { add_coupon_id: coupon } = qs.parse(qs.extract(locationSearch));

  // We might also have a "previous" cookie param. Lets check that too
  const cookieCoupon = getCouponCookie();

  // lets grab the one we have
  const couponToSet = coupon || cookieCoupon;

  // If have "add_coupon_id" in url, save it to state entities:coupons:param
  if (couponToSet) {
    yield put(addUrlParamCoupon(couponToSet));
    // set this coupon to cookie, for one hour. explained here: Jira GROW-443
    setCouponCookie(couponToSet);
  }
}

/** Fires when the app is loaded */
function* appLoadedSaga() {
  const user = yield select(userSelector);
  yield call(setBrandingState, { user });
  if (!user) return;
  yield call(checkPlanSubscriberPhone, user.getIn(['account', 'hasSubscription']));
  const jsUser = user.toJS();
  Bugsnag.setUser(jsUser);
}

export function* loadUserSaga() {
  const user = yield select(userSelector);
  if (user) {
    const jsUser = user.toJS();
    setKustomerUser(jsUser);
    Bugsnag.setUser(jsUser);
    yield put(identifySegmentStandaloneCall(jsUser));
    return;
  }
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const include = yield select(includeAccount);
  const requestResult = yield call(routes.users.current, { account: include });
  yield put(requestFinished());

  if (!requestResult.err) {
    const { user: newUser, routes: siteRoutes } = requestResult.data;
    yield put(userLoaded(newUser));
    setKustomerUser(newUser);
    Bugsnag.setUser(newUser);
    yield call(checkPlanSubscriberPhone, newUser.account && newUser.account.hasSubscription);
    if (siteRoutes) {
      yield put(newRouteSchema(siteRoutes));
    }
  } else {
    yield put(userNotLoaded());
    const status = requestResult.err.response ? requestResult.err.response.status : 401;
    if (status === 401) {
      // do nothing, user is not logged in
    } else {
      yield put(displayErrors(requestResult));
    }
  }
}

/** Fires in response to a login and successful user load event */
function* userLoadedSaga({ user }) {
  yield call(setBrandingState, { user });
  yield call(checkPlanSubscriberPhone, user.account && user.account.hasSubscription);
}

/**
 * Reset application states to their defaults on user logout, preparing the system for a new user session.
 */
function* cleanupOnLogout() {
  yield put(
    updateLowTierConfig({ splitTreatment: null, hthUpsellShown: false, showHthModal: false }),
  );
}

export function* logoutSaga() {
  let routes = yield call(selectRoutes);
  yield put(requestStarted());
  const requestResult = yield call(routes.users.logout);
  yield put(requestFinished());

  if (!requestResult.err) {
    yield put(loginRedirect(null));
    yield put(userLoggedOut());
    yield call(cleanupOnLogout);
    routes = yield call(selectRoutes);
    const [cartResponse, siteRoutesResponse] = yield all([
      call(routes.cart.find, { breakdown: true }),
      call(routes.routes.skuCategories),
    ]);
    if (!cartResponse.err) {
      yield put(updateCart({ cart: cartResponse.data.cart }));
    } else {
      yield put(displayErrors(cartResponse));
    }
    if (!siteRoutesResponse.err) {
      const { routes: siteRoutes } = siteRoutesResponse.data;
      if (siteRoutes) {
        yield put(newRouteSchema(siteRoutes));
      }
    }
    yield call(resetKustomerChat);
  } else {
    yield put(displayErrors(requestResult));
  }
}

function* loadCartSaga() {
  const routes = yield call(selectRoutes);
  let cart = yield select((state) => state.getIn(['entities', 'cart']));
  if (cart && cart.get('items')) {
    cart = cart.toJS();
  } else {
    const requestResult = yield call(routes.cart.preview);
    if (!requestResult.err) {
      cart = requestResult.data.cart;
    } else {
      yield put(displayErrors(requestResult));
      return;
    }
  }
  yield put(updateCartPreview({ cart }));
}

function* pageLoadingErrorSaga({ error: { status } }) {
  if (status === 401) {
    // not authenticated
    const path = yield select(fullPathSelector);
    yield put(push(loginPath, { nextPathname: path }));
  }
}

// eslint-disable-next-line require-yield
function* bugsnagTrackingSaga(action) {
  Bugsnag.leaveActionBreadcrumb(action);
}

export function* loadPageFlow() {
  yield takeLatest(APP_LOADED, appLoadedSaga);
  yield takeLatest(APP_LOADED, paramsSaga);
  yield takeEvery(LOAD_USER, loadUserSaga);
  yield takeEvery(USER_LOADED, userLoadedSaga);
  yield takeEvery(LOAD_CART_PREVIEW, loadCartSaga);
  yield takeEvery(LOGOUT, logoutSaga);
  yield takeLatest(PAGE_LOADING_ERROR, pageLoadingErrorSaga);
  yield takeEvery(() => true, bugsnagTrackingSaga);
  yield takeEvery(LOCATION_CHANGE, urlParamsCouponSaga);
}

// Bootstrap sagas
export default [loadPageFlow];
