import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { THEMES } from 'src/components/HTKit/Forms/Button';
import BookNowButton from 'src/components/BookNowButton';
import SubNav from 'src/components/Layout/SubNav';
import OpaqueSubNav from 'src/components/Layout/OpaqueSubNav';
import Icon from 'src/components/HTKit/Icon';
import { LOCATION_HEADER_TRIGGER } from '../constants';
import TitleAndCTADesktop from './TitleAndCTADesktop';
import TitleAndCTAMobile from './TitleAndCTAMobile';
import styles from './styles.scss';

export default class SkuHeader extends Component {
  static propTypes = {
    sku: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    onReviewClick: PropTypes.func,
    isMain: PropTypes.bool,
    backgroundView: PropTypes.node,
    onFAQToggle: PropTypes.func,
    faq: PropTypes.array,
    fetchPlanAndOpenModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onReviewClick: (event) => {
      event.preventDefault();
    },
    isMain: false,
  };

  getTitle = () => {
    const { title, sku } = this.props;
    return title || sku.get('name');
  };

  // mobile
  renderHeader = (sku) => {
    const { subtitle, onFAQToggle, faq, onReviewClick, fetchPlanAndOpenModal } = this.props;
    const title = this.getTitle();
    return (
      <TitleAndCTAMobile
        title={title}
        subtitle={subtitle}
        sku={sku}
        onFAQToggle={onFAQToggle}
        withFAQ={faq && !!faq.length}
        onReviewClick={onReviewClick}
        fetchPlanAndOpenModal={fetchPlanAndOpenModal}
      />
    );
  };

  // secondary header for desktop and mobile
  renderMainHeader = (sku) => {
    const { subtitle, onFAQToggle, faq, onReviewClick, fetchPlanAndOpenModal } = this.props;
    const title = this.getTitle();
    return (
      <>
        <OpaqueSubNav innerClasses={styles.desktopOnly}>
          <div className={styles.desktopSecondaryHeader}>
            {this.props.backgroundView}
            <div className={styles.desktopSecondaryHeaderContent}>
              <TitleAndCTADesktop
                title={title}
                sku={sku}
                subtitle={subtitle}
                onReviewClick={onReviewClick}
                fetchPlanAndOpenModal={fetchPlanAndOpenModal}
              />
            </div>
          </div>
        </OpaqueSubNav>

        <SubNav
          innerClasses={styles.mobileOnly}
          startElementSelector={`#${LOCATION_HEADER_TRIGGER}`}
        >
          <div className={styles.mobileSecondaryHeader}>
            <div className={styles.skuTitle}>
              <h6>{this.getTitle()}</h6>
              {faq && !!faq.length && (
                <Icon name="question" className={styles.faqIcon} onClick={onFAQToggle} />
              )}
            </div>
            <div className={styles.getAQuote}>
              <BookNowButton skuId={sku.get('id')} smallV2 theme={THEMES.V2SCIENCEBLUE} />
            </div>
          </div>
        </SubNav>
      </>
    );
  };

  render() {
    const { sku, isMain } = this.props;
    return isMain ? this.renderMainHeader(sku) : this.renderHeader(sku);
  }
}
