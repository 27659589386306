import { connect } from 'react-redux';
import { logout } from 'src/containers/AppBase/actions';
import { toggleSidebar } from './actions';
import sidebarSelectors from './selectors';
import Sidebar from './Sidebar';

export default connect(
  sidebarSelectors,
  { toggleSidebar, logout },
)(Sidebar);
