import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SubNav from 'src/components/Layout/SubNav';
import OpaqueSubNav from 'src/components/Layout/OpaqueSubNav';
import BookNowButton from 'src/components/BookNowButton';
import Button, { THEMES } from 'HTKit/Forms/Button';
import ReviewCount from 'src/components/HTKit/ReviewCount';
import { MembersPrice } from 'src/containers/SkuPage/Parts';
import StartsAtPrice from 'src/components/StartsAtPrice';
import { PRODUCT_SKU_HEADER_TRIGGER } from '../../constants';
import styles from './styles.scss';

export const CTA_TEXT = 'Join & Book Now';

export const Price = ({
  className,
  showMemberOnlyView,
  plansInfo,
  onMemberPriceClick,
  sku,
  withReviews = false,
}) => {
  const memberPriceStyles = cn('marginBottom-small1', styles.memberPrice, {
    'marginLeft-tiny1': !withReviews,
  });
  return showMemberOnlyView ? (
    <p className={cn('n900', className, styles.startsAt)}>
      with ${plansInfo.amount}/month <br className={styles.linebreak} />
      membership.{' '}
      <a href="#" className={styles.upsellV2LearnMore} onClick={onMemberPriceClick}>
        Learn More
      </a>
    </p>
  ) : (
    <MembersPrice sku={sku} buttonStyle={memberPriceStyles} onClick={onMemberPriceClick} />
  );
};

Price.propTypes = {
  className: PropTypes.object,
  showMemberOnlyView: PropTypes.bool,
  plansInfo: PropTypes.shape({
    amount: PropTypes.number,
  }),
  onMemberPriceClick: PropTypes.func.isRequired,
  sku: PropTypes.object,
  withReviews: PropTypes.bool,
};

export const BookingButton = (props) => {
  const { showMemberOnlyView, skuId, onClickMemberOnlyView } = props;
  return showMemberOnlyView ? (
    <Button mediumV2 onClick={onClickMemberOnlyView} {...props}>
      {CTA_TEXT}
    </Button>
  ) : (
    <BookNowButton theme skuId={skuId} {...props} />
  );
};

BookingButton.propTypes = {
  showMemberOnlyView: PropTypes.bool,
  onClickMemberOnlyView: PropTypes.func,
  skuId: PropTypes.number,
};

const ProductSingleSkuHeader = ({
  sku,
  ratingAttributes,
  image,
  onMemberPriceClick,
  showMemberOnlyView,
  onBookClick,
  plansInfo,
}) => {
  const skuId = sku.get('id');
  const Title = () => {
    const hasAdjustments = sku.get('hasAdjustments');
    const logoStyles = {
      backgroundImage: `url(${image})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      height: '70px',
    };
    const cheapestPriceFormatted = sku.getIn(['subscriptionPrices', 'cheapestPriceFormatted']);
    return (
      <div className={cn('site-v2', styles.desktopSecondaryHeaderContent)}>
        <section className={styles.mainTitleContainer}>
          <div className={styles.mainTitleContainerLogo}>
            {image && <figure style={logoStyles} />}
          </div>
          <div className={styles.dividerContainer}>
            <div className={styles.divider}></div>
          </div>
          <div className={styles.skuNameContainer}>
            <h3 className={styles.skuName}>{sku.get('name')}</h3>
            {ratingAttributes && ratingAttributes.get('ratingCount') > 0 && (
              <ReviewCount
                reviews={ratingAttributes.get('ratingCount')}
                stars={ratingAttributes.get('rating')}
                inline
                keyStr="desktop"
              />
            )}
          </div>
          <div className={styles.priceContainer}>
            {sku.get('showFree') ? (
              <div>
                <h2>FREE</h2>
              </div>
            ) : (
              <>
                {hasAdjustments && <span className={styles.startsAtMobile}>Starts at only</span>}
                <h3 className={styles.startsAt}>
                  {hasAdjustments && (
                    <span className={styles.startsAtDesktop}>
                      Starts
                      <br />
                      at only
                    </span>
                  )}
                  {showMemberOnlyView ? (
                    cheapestPriceFormatted
                  ) : (
                    <StartsAtPrice sku={sku} showSubscriptionPrice />
                  )}
                </h3>
                <Price
                  showMemberOnlyView={showMemberOnlyView}
                  plansInfo={plansInfo}
                  onMemberPriceClick={onMemberPriceClick}
                  sku={sku}
                  className="p2 marginLeft-small"
                />
              </>
            )}
            <div className={styles.priceContainerButton}>
              <div className={styles.ctaContainer}>
                <div className={styles.ctaButtonWrapper}>
                  <BookingButton
                    onClickMemberOnlyView={onBookClick}
                    skuId={skuId}
                    showMemberOnlyView={showMemberOnlyView}
                    mediumV2
                    theme={THEMES.V2PRIMARY}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };

  return (
    <>
      <OpaqueSubNav innerClasses={styles.desktopOnly}>
        <div className={styles.desktopSecondaryHeader}>
          <Title />
        </div>
      </OpaqueSubNav>
      <SubNav
        innerClasses={styles.mobileOnly}
        startElementSelector={`#${PRODUCT_SKU_HEADER_TRIGGER}`}
      >
        <div className={styles.mobileSecondaryHeader}>
          <div className={styles.logo}>{image && <img src={image} alt="" />}</div>
          <div className={styles.getAQuote}>
            <BookingButton
              onClick={onBookClick}
              skuId={skuId}
              showMemberOnlyView={showMemberOnlyView}
              smallV2
              theme={THEMES.V2PRIMARY}
            />
          </div>
        </div>
      </SubNav>
    </>
  );
};

ProductSingleSkuHeader.propTypes = {
  sku: PropTypes.object,
  ratingAttributes: PropTypes.object,
  image: PropTypes.string,
  onMemberPriceClick: PropTypes.func.isRequired,
  showMemberOnlyView: PropTypes.bool,
  onBookClick: PropTypes.func.isRequired,
  plansInfo: PropTypes.shape({
    amount: PropTypes.number,
  }),
};

export default ProductSingleSkuHeader;
