import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import Icon from 'src/components/HTKit/Icon';
import Grid from 'src/components/HTKit/Grid/Grid';

// Utils
import { skuCatPath } from 'src/utils/paths';
import { searchResultsClickedSegment } from 'src/components/Searchbar/actions';
import { categories } from '../constants';

// Styles
import styles from '../styles.scss';

const CategoryResults = ({ iconClick, getItemProps, searchTerm }) => {
  const dispatch = useDispatch();

  return (
    <>
      <p className="caption marginTop-medium paddingBottom-tiny1 paddingX-small1 n700">
        CATEGORIES ({categories.length})
      </p>
      {categories.map((category, i) => {
        const onClick = () => {
          dispatch(searchResultsClickedSegment({ searchTerm, clickedItem: "category", attributes: category }));
          iconClick();
        };
        return (
          <div key={i} onClick={onClick}>
            <Grid.FullWidth classes={styles.categoryContainer}>
              <Grid.Row classes={styles.customRow}>
                <Link
                  {...getItemProps({
                    key: category.id.raw,
                    item: category,
                  })}
                  to={skuCatPath(category.category_seo_name.raw)}
                  className={cn('paddingX-small1', styles.clickContainer)}
                >
                  <Grid.Column classes={styles.columnContainer}>
                    <div className={styles.innerContainer}>
                      <Icon name={category.category_icon.raw} className={styles.icon} />
                      <div className={cn('h6 paddingLeft-small1', styles.name)}>
                        {category.category_name.raw}
                      </div>
                    </div>
                    <Icon name="chevron-right" className={styles.chevron} />
                  </Grid.Column>
                </Link>
              </Grid.Row>
            </Grid.FullWidth>
          </div>
        );
      })}
    </>
  );
};

CategoryResults.defaultProps = {
  iconClick: () => {},
};

CategoryResults.propTypes = {
  iconClick: PropTypes.func,
  getItemProps: PropTypes.func,
  searchTerm: PropTypes.string,
};


export default CategoryResults;
