import { merge, get } from 'lodash';
import { LANDING_TEMPLATES as LT } from 'src/constants/landings';
import {
  HEADER_TEMPLATES,
  DEFAULT_LAYOUT,
  BOOKING_LAYOUT,
  PARTNER_SKU_LAYOUT,
  OPTIMUM_LAYOUT,
  SUDDENLINK_LAYOUT,
  SAMSUNG_LAYOUT,
  WALMART_LAYOUT,
  FEATURED_CONTENT_OR_SKU_LAYOUT,
  PARTNER_MULTIPLE_ACCOUNT_SKU_LAYOUT,
  TARGET_FINISH_ORDER_LAYOUT,
  COBRAND_ORDER_LAYOUT,
  DIRECT_BOOKING_BASE_LAYOUT,
  PARTNERS,
} from 'src/constants/common';
import { landingPath, directBookingTechProfilePath } from 'src/utils/paths';
import { getDirectBookingTechIdOrSlug } from 'src/utils/cookies/directBookingCookie';
import { isImmutable, immutableToJS } from './helpers';

/**
 * Is our partner a cobrand.
 * Need this for now until we rework branding and we can use the selectors wherever we need.
 * @param baseObject - the object we want to check for patner in
 * @returns {*}
 *
 */
function isCoBranding(baseObject = {}) {
  const base = isImmutable(baseObject) ? baseObject.toJS() : baseObject;

  return ['partner', 'plan.partner'].find((accessor) => {
    return get(base, `${accessor}.cobrandedLogo.length`);
  })
    ? PARTNERS.COBRAND
    : '';
}

/**
 * This is util is generally used to control the layout type for landing pages
 * and sku-related pages (ServicePage and AddSkuPage). This means that sku
 * or landing data determines the identity of the layout. Pages that call this
 * typically do not have cart data.
 *
 * This util is also used to control the layout for flows that behave similiarly
 * to landing pages.
 *
 * Side Note: Check `resolveHomeUrl` in `src/components/Header/utils`. This
 * overrides the homeUrl for the logo in the nav.
 *
 * Params
 * - templateName
 *   - derived from a few sources: sku data, landing page data, hardcoded values
 * - defaultLayout
 *   - used for overriding the default if necessary
 */
export function resolveLayout(templateName, defaultLayout = DEFAULT_LAYOUT) {
  if (getDirectBookingTechIdOrSlug()) {
    return merge({}, defaultLayout, DIRECT_BOOKING_BASE_LAYOUT);
  }
  if (
    [LT.PRODUCT_MULTIPLE_SKU, LT.PRODUCT_SINGLE_SKU, LT.ALTICE_NEST, LT.SMB_SKU].includes(
      templateName,
    )
  )
    return PARTNER_SKU_LAYOUT;
  if (
    [
      LT.INTERNAL_PRODUCT_MULTIPLE_SKU,
      LT.INTERNAL_PRODUCT_SINGLE_SKU,
      LT.INTERNAL_GOOGLE_ADS_SKU,
      LT.GOOGLE_ADS_SKU,
    ].includes(templateName)
  )
    return DEFAULT_LAYOUT;
  if (LT.MULTIPLE_ACCOUNT_SKU === templateName) return PARTNER_MULTIPLE_ACCOUNT_SKU_LAYOUT;
  if ([LT.OPTIMUM_GENERAL, LT.OPTIMUM_MOVERS, LT.OPTIMUM_SUPPORT].includes(templateName))
    return OPTIMUM_LAYOUT;
  if ([LT.FEATURED_CONTENT_OR_SKU].includes(templateName)) return FEATURED_CONTENT_OR_SKU_LAYOUT;
  if ([LT.SUDDENLINK_GENERAL, LT.SUDDENLINK_MOVERS, LT.SUDDENLINK_SUPPORT].includes(templateName))
    return SUDDENLINK_LAYOUT;
  return defaultLayout;
}

const PARTNER_LAYOUTS = Object.freeze({
  [PARTNERS.SAMSUNG]: { ...SAMSUNG_LAYOUT, footer: { show: false } },
  [PARTNERS.WALMART]: { ...WALMART_LAYOUT, footer: { show: false } },
  [PARTNERS.TARGET]: { ...TARGET_FINISH_ORDER_LAYOUT },
  [PARTNERS.COBRAND]: { ...COBRAND_ORDER_LAYOUT },
});

/**
 * This util is generally used to override layout props for partner carts on booking-related
 * pages (CartPage, BookingPage, CreatePasswordPage, etc). This is also used outside of
 * booking-rlated pages in special circumstances.
 *
 * Pages that call this util typically have cart data, so the layout overrides
 * are driven by it though there are exceptions.
 *
 * Params
 * - cart
 *   - derived from the cart object in Redux
 * - currentPartner
 *   - derived from 'entities.currentLanding' in Redux.
 *   - 'entities.currentLanding' is populated by the partner_landing cookie, which is
 *     set by landing on /landings/seoName. 'entities.currentLanding' is also manually
 *     set by landing on any Altice pages.
 * - partnerLayout
 *   - used to manually force a certain layout regardless of Redux values. E.g., PIN Redemption, Innover
 *   - possible values are 'samsung', 'walmart', 'target', 'qvc', 'cobrand'
 * - defaultLayout
 *   - specify the base layout to apply overrides on, if necessary
 */
export const resolveBookingPageLayout = ({
  cart,
  currentPartner: currentLanding,
  partnerLayout: _partnerLayout,
  defaultLayout: _defaultLayout = BOOKING_LAYOUT,
  layoutOverrides: _layoutOverrides = {},
}) => {
  let defaultLayout = _defaultLayout || BOOKING_LAYOUT;
  let layoutOverrides = _layoutOverrides || {};

  const cartJS = immutableToJS(cart);
  const currentLandingJS = immutableToJS(currentLanding);

  const partnerLayout = (cartJS && cartJS.partnerName) || _partnerLayout || isCoBranding(cartJS);
  const cartPartner = get(cartJS, 'partner', null);
  const currentLandingSeoName = get(currentLandingJS, 'seoName', null);

  const directBookingTechId = getDirectBookingTechIdOrSlug();

  if (partnerLayout) {
    // for now, just assuming a default layout of BOOKING_LAYOUT in all instances
    defaultLayout = PARTNER_LAYOUTS[partnerLayout] || BOOKING_LAYOUT;
  } else if (cartPartner) {
    const landingSeoName = get(cartPartner, 'landingSeoName', null);
    if (landingSeoName) {
      layoutOverrides.header = {
        homeUrl: landingPath(landingSeoName),
        actionableLogo: true,
        showSiteLinks: false,
      };
    } else {
      layoutOverrides.header = { actionableLogo: false, showSiteLinks: false };
    }
  } else if (currentLandingSeoName) {
    const { template } = currentLandingJS;
    const templateLayout = resolveLayout(template);
    const {
      header: { showSiteLinks },
    } = templateLayout;
    layoutOverrides.header = {
      homeUrl: landingPath(currentLandingSeoName),
      actionableLogo: true,
      showSiteLinks,
    };
  } else if (directBookingTechId) {
    const { header: { template: defaultHeaderTemplate } = {} } = defaultLayout;
    if (defaultHeaderTemplate !== HEADER_TEMPLATES.Booking) {
      // use the DIRECT_BOOKING_HEADER on the CartPage
      layoutOverrides = merge({}, layoutOverrides, {
        header: { template: DIRECT_BOOKING_BASE_LAYOUT.header.template },
      });
    }
    layoutOverrides = merge({}, layoutOverrides, {
      header: { homeUrl: directBookingTechProfilePath(directBookingTechId) },
    });
  }

  return merge({}, defaultLayout, layoutOverrides);
};
