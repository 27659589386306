export const SUBMIT_FORM = 'XfinityTechPage/SUBMIT_FORM';
export const FORM_SUBMITTED = 'XfinityTechPage/FORM_SUBMITTED';
export const DISMISS_THANK_YOU_PAGE = 'XfinityTechPage/DISMISS_THANK_YOU_PAGE';
export const XFINITY_TECH_PAGE_NAME = 'XfinityTech';
export const XFINITY_TECH_PAGE_FIRE_SEGMENT = 'XfinityTechPage/FIRE_SEGMENT';
export const PAGE_SECTIONS = {
  FEATURED: 'featured',
  COMPUTER: 'computer',
  SMART_HOME: 'smartHome',
  MOUNTING: 'mounting',
  SELF_PROTECTION: 'selfProtection',
  AV: 'av',
  WIFI: 'wifi',
  CONTACT: 'Contact',
  IDS: 'IDs', // The original version of the page had the tech enter their employee id
  SERVICES: 'Services',
  FEEDBACK: 'Feedback',
  SUBMITTED: 'Submitted',
};

export const TECH_FEEDBACK_LINK =
  'https://teams.microsoft.com/_#/conversations/General?threadId=19:53efd6f520584f96a5a5a4aed127c84d@thread.skype&ctx=channel';

export const CUSTOMER_TYPES = {
  RESIDENTIAL: 'residential',
  COMMERCIAL: 'commercial',
};

export const INITIAL_STATE = {
  clientFirstName: '',
  clientLastName: '',
  clientEmail: '',
  clientPhone: '',
  comcastUserId: '',
  comcastEntityId: '',
  emailTosCheckBox: false,
  phoneTosCheckBox: false,
  disableButton: true,
  renderValidation: false,
  displaySection: PAGE_SECTIONS.IDS,
  services: [],
  serviceDetails: {},
  comcastRedirect: false,
  customerType: '',
  isBackButtonModalOpen: false,
};
