import Cookies from 'js-cookie';
import { PARTNER_LANDING_COOKIE } from 'src/containers/LandingPage/constants';

export function getPartnerLandingCookie() {
  return Cookies.getJSON(PARTNER_LANDING_COOKIE);
}

export function removeLandingCookies() {
  Cookies.remove(PARTNER_LANDING_COOKIE);
}

export function setLandingCookies(landing) {
  if (typeof window === 'object') {
    const partnerInfo = {
      id: landing.get('id'),
      landingId: landing.get('id'),
      seoName: landing.get('seoName'),
      template: landing.get('template'),
    };

    // 24h
    Cookies.set(PARTNER_LANDING_COOKIE, partnerInfo, { expires: new Date(Date.now() + 86400000) });
  }
}
