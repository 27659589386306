import React from 'react';
import PropTypes from 'prop-types';
import { TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES } from 'src/components/AvailabilitySelectorWithConfig/Scheduling.constants';
import AvailabilityManager from 'src/components/AvailabilitySelector/AvailabilityManager';
import { AvailabilityManagerWithConfig } from 'src/components/AvailabilitySelectorWithConfig/AvailabilityManagerWithConfig';

export const LegacyTimeManager = ({
  useTimeWindows,
  numSelectedHours,
  selectedHours,
  selectedDateTimes,
}) => {
  if (useTimeWindows) {
    return (
      <AvailabilityManagerWithConfig.TimeRangeDisplay
        numSelectedHours={numSelectedHours}
        selectedHours={selectedHours}
        selectedDateTimes={selectedDateTimes}
        config={TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES}
      />
    );
  }
  return (
    <AvailabilityManager
      numSelectedHours={numSelectedHours}
      selectedHours={selectedHours}
      selectedDateTimes={selectedDateTimes}
    />
  );
};

LegacyTimeManager.propTypes = {
  useTimeWindows: PropTypes.bool,
  selectedDateTimes: PropTypes.array.isRequired,
  selectedHours: PropTypes.array.isRequired,
  numSelectedHours: PropTypes.number.isRequired,
};
