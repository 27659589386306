import React from 'react';
import cn from 'classnames';
// Constants
import { SITE_LINKS } from 'src/components/Footer/constants';
// Components & Styles
import Grid from 'src/components/HTKit/Grid/Grid';
import FooterLineItem from './FooterLineItem';
import SubscribeForm from './SubscribeForm';
import styles from '../styles.scss';

const Links = () => (
  <div className={styles.linksListContainer}>
    {SITE_LINKS.map((category) => {
      return (
        <div key={category.header} className={styles.linksCategory}>
          <h5 className="marginBottom-small1">{category.header}</h5>
          <div className={styles.linksList}>
            {category.lineItems.map((item) => (
              <FooterLineItem key={item.name} {...item}>
                {item.name}
              </FooterLineItem>
            ))}
          </div>
        </div>
      );
    })}
  </div>
);

const SubscribeToNewsletter = () => {
  return (
    <div className={styles.subscriptionContainer}>
      <h5 className="marginBottom-small">Subscribe to our Newsletter</h5>
      <p className="p2 n700 marginBottom-small1">
        Join our newsletter to get the latest news and tips on home technology, plus save $10 on your
        next order!
      </p>
      <SubscribeForm />
    </div>
  );
};

const SiteLinks = () => {
  return (
    <Grid.Fluid classes={cn(styles.borderBottom, 'paddingTop-medium2')}>
      <div className={cn(styles.showLgGrid, styles.siteLinksContainer)}>
        <Grid.Row>
          <Grid.Column lgOffset={1} lg={6}>
            <Links />
          </Grid.Column>
          <Grid.Column lg={4}>
            <SubscribeToNewsletter />
          </Grid.Column>
        </Grid.Row>
      </div>

      <div className={cn(styles.showSmMdGrid, styles.siteLinksContainer)}>
        <Grid.Row classes={styles.linksRow}>
          <Grid.Column sm={4} mdOffset={1} md={6}>
            <Links />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row classes={styles.showSmMdGrid}>
          <Grid.Column sm={4} mdOffset={2} md={4}>
            <SubscribeToNewsletter />
          </Grid.Column>
        </Grid.Row>
      </div>
    </Grid.Fluid>
  );
};

export default SiteLinks;

/*
  SiteLinks: Section of the footer that contains links to various Parts around the site; the top portion of the complete header.
*/
