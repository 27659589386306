import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'src/utils/paths';
import withToggleSiteNav from 'src/hoc/withToggleSiteNav';
import { toggleTopnav } from 'src/components/Header/TopNavHeader/actions';
import pageSelectors from './selectors';
import HomePage from './HomePage';
import IframeVersion from './Iframe';

const HomePageIframe = compose(
  connect(pageSelectors, {
    push,
    toggleTopnav,
  }),
  withToggleSiteNav(),
)(IframeVersion);

export { HomePageIframe };
export default compose(
  connect(pageSelectors, {
    push,
    toggleTopnav,
  }),
  withToggleSiteNav(),
)(HomePage);
