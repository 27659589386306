import { call, put, takeEvery, select } from 'redux-saga/effects';
import { LOAD_PAGE } from 'src/constants/common';
import { pageLoadingError, pageLoaded } from 'src/actions/common';
import { selectRoutes } from 'src/apiRoutes';

export function* pageSaga() {
  const routes = yield call(selectRoutes);

  let reviews = yield select((state) => state.getIn(['entities', 'reviews', 'customerReviews']));
  if (reviews) {
    reviews = reviews.toJS();
  } else {
    const requestResult = yield call(routes.reviews.reviewsPage);
    if (!requestResult.err) {
      reviews = requestResult.data.reviews;
    } else {
      yield put(pageLoadingError('customerReviews', requestResult));
      return;
    }
  }
  yield put(pageLoaded('customerReviews', { reviews }));
}

export function* pageFlow() {
  yield takeEvery(
    (action) => action.type === LOAD_PAGE && action.page === 'customerReviews', pageSaga
  );
}

export default [
  pageFlow,
];
