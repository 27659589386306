import validate from 'validate.js'; // eslint-disable-line import/extensions
import { omit, merge, pick, keys } from 'lodash';
import defaultRules from './rules';

export function isValid(object, rules = {}, except = [], strict = false) {
  const initialRules = merge({}, defaultRules, rules);
  const validationRules = strict ? initialRules : pick(initialRules, keys(object));
  const validationResult = validate(object, omit(validationRules, except));
  return { valid: !validationResult, messages: validationResult || {} };
}

/**
 * If we have a full url, get pathName, else return string as is.
 * If the passed 'str' is invalid, return an empty string which will do nothing.
 * @param str
 * @returns {string}
 */
export function pathnameFromString (str) {
  const matchedString = typeof str === 'string' ? str : '';
  const [, pathName = ''] = matchedString.match(/(?:https?:\/\/[^/\r\n]+)(\/[^\r\n]*)?/) || [];

  return pathName;
}
