export const CREATE_WS_CONNECTION = 'techTrackingWS/CREATE_WS_CONNECTION';
export const CLOSE_WS_CONNECTION = 'techTrackingWS/CLOSE_WS_CONNECTION';
export const UPDATE_LOCATION = 'techTrackingWS/UPDATE_LOCATION';
export const UPDATE_STATUS = 'techTrackingWS/UPDATE_STATUS';

// connection states
export const CONNECTED = 'techTrackingWS/CONNECTED';
export const NOT_CONNECTED = 'techTrackingWS/NOT_CONNECTED';
export const RECONNECTING = 'techTrackingWS/RECONNECTING';
export const RECONNECT_FAILED = 'techTrackingWS/RECONNECT_FAILED';


let trackingUrl = 'ws://localhost:3009';

if (process.env.NODE_ENV === 'production') {
  trackingUrl = 'wss://tracking.hellotech.com';
} else if (process.env.NODE_ENV === 'staging') {
  trackingUrl = 'wss://trackings.hellotech.com';
}

export const TECH_TRACKING_URL = trackingUrl;
