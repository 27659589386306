export const PAGE_NAME = 'accountPlan';
/* Redux Actions */
export const TRIGGER_AUTO_RENEW = 'accountPlanPage/TRIGGER_AUTO_RENEW';
export const REQUEST_CANCELLATION = 'accountPlanPage/REQUEST_CANCELLATION';
export const REDEEM = 'accountPlanPage/REDEEM';
export const SET_REDEMPTION_CODE = 'accountPlanPage/SET_REDEMPTION_CODE';
export const TOGGLE_MODAL = 'accountPlanPage/TOGGLE_MODAL';
export const SET_STATS = 'accountPlanPage/SET_STATS';
export const UPDATE_AUTO_RENEW_STATUS = 'accountPlanPage/UPDATE_AUTO_RENEW_STATUS';
export const SET_TRANSACTIONS = 'accountPlanPage/SET_TRANSACTIONS';
export const SET_MEMBERSHIP = 'accountPlanPage/SET_MEMBERSHIP';
export const AUTO_RENEW_TOGGLED = 'accountPlanPage/AUTO_RENEW_TOGGLED';
export const CANCEL_SUBSCRIPTION = 'accountPlanPage/CANCEL_SUBSCRIPTION';
