// Libraries
import { call, takeLatest, put } from 'redux-saga/effects';
// External Actions
import { invalidForm, clearFormErrors, updateUser, pageLoaded } from 'src/actions/common';
import { updateAuth } from 'src/containers/AppBase/actions';
import { updateCart } from 'src/containers/AddSkuPage/actions';
// Utils
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import { requireUser } from 'src/utils/sagas';
import { push, accountPath } from 'src/utils/paths';
// Routes
import { selectRoutes } from 'src/apiRoutes';
// External Constants
import { LOAD_PAGE } from 'src/constants/common';
// Local Constants
import { SET_PASSWORD, PAGE_NAME } from './constants';
// Local Actions
import { passwordCreated } from './actions';

export function* createPasswordFlowSaga() {
  yield call(requireUser);
  yield put(pageLoaded(PAGE_NAME));
}

export function* createPasswordSaga({ password, resetPasswordToken, orderToken }) {
  const routes = yield call(selectRoutes);

  yield put(clearFormErrors(PAGE_NAME));
  yield put(requestStarted());
  const response = yield call(routes.users.createPassword, {
    password,
    rpt: resetPasswordToken,
    orderToken,
  });
  yield put(requestFinished());
  if (!response.err) {
    const { user, cart, redirectTo } = response.data;
    yield put(updateAuth({ authToken: user.authToken }));
    yield put(updateUser(user));
    if (cart) {
      yield put(updateCart(cart));
      yield put(passwordCreated()); // will redirect to a booking stage
    } else if (redirectTo) {
      yield put(push(redirectTo));
    } else {
      yield put(push(accountPath));
    }
  } else {
    const { status } = response.err.response;
    if (status === 422) {
      const { formErrors } = response.data;
      yield put(invalidForm(PAGE_NAME, formErrors));
    } else {
      yield put(displayErrors(response));
    }
  }
}

export function* loginFlow() {
  yield takeLatest(
    (action) => action.type === LOAD_PAGE && action.page === PAGE_NAME,
    createPasswordFlowSaga,
  );
  yield takeLatest(SET_PASSWORD, createPasswordSaga);
}

export default [loginFlow];
