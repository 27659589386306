import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Grid from 'src/components/HTKit/Grid/Grid';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { cardType } from 'src/utils/payments/card';
import styles from './styles.scss';

const PaymentCard = ({ card, onClick, onEditPaymentClick }) => {
  const { expMonth, expYear, lastDigits } = card;
  const expMonthFormatted = expMonth.padStart(2, '0');
  const type = cardType(lastDigits);
  const ccStyles = cn('p1', styles.creditCard);

  return (
    <div className="form site-v2">
      <Grid.FullWidth classes={styles.cardContainer}>
        <Grid.Row classes={styles.customRow}>
          <Grid.Column sm={4} md={4}>
            <Grid.Column sm={4} md={8} classes={ccStyles}>
              <span className={styles.ccInfo}>{type}</span> ending in {lastDigits}
            </Grid.Column>
            <Grid.Column sm={4} md={8} classes={ccStyles}>
              Expires {expMonthFormatted}/{expYear}
            </Grid.Column>
          </Grid.Column>
          <Grid.Column sm={4} md={4} classes={styles.cardButtonWrapper}>
            <Button theme={THEMES.V2PRIMARY} onClick={onClick} className={styles.bookingButton}>
              Use this card
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid.FullWidth>
      <p className={`p1 ${styles.edit}`}>
        <a href className="text-link -no-decoration" onClick={onEditPaymentClick}>
          Use a different card
        </a>
      </p>
    </div>
  );
};

PaymentCard.propTypes = {
  card: PropTypes.shape({
    lastDigits: PropTypes.string,
    expMonth: PropTypes.string,
    expYear: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  onEditPaymentClick: PropTypes.func,
};

export default React.memo(PaymentCard);
