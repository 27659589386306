import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleFirstServiceDiscountState } from 'src/components/HTKit/Modals/FirstServiceDiscount/actions';
import ContentModal from '../HTKit/Modals/Content';
import styles from './styles.scss';

const VideoModal = (props) => {
  const [videoVisible, setVideoVisible] = useState(false);

  const toggleVideo = (visible) => {
    return () => {
      setVideoVisible(visible);
      props.toggleFirstServiceDiscountState(!visible);
    };
  };

  const renderModal = () => {
    const { videoUrl } = props;
    return (
      <ContentModal
        visible={videoVisible}
        onCancel={toggleVideo(false)}
        styles={styles.modal}
        closeStyles={styles.modalClose}
      >
        <div>
          <iframe width="100%" height="300" src={videoUrl} frameBorder="0" allowFullScreen />
        </div>
      </ContentModal>
    );
  };

  const renderVideo = () => {
    const { previewImage } = props;
    return (
      <div className={styles.video}>
        {/* eslint-disable */}
        <div className={styles.videoPreview} onClick={toggleVideo(true)}>
          {/* eslint-enable */}
          <div className={styles.videoPreviewIcon}>
            <i className="ht-icon ht-play" />
          </div>
          <div>
            <img src={previewImage} alt="Tech Support Services" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderModal()}
      {renderVideo()}
    </div>
  );
};

VideoModal.propTypes = {
  previewImage: PropTypes.object,
  videoUrl: PropTypes.string,
  toggleFirstServiceDiscountState: PropTypes.func.isRequired,
};

export default connect(() => ({}), { toggleFirstServiceDiscountState })(VideoModal);
