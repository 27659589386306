import { UPSELL_MODULE_BENEFITS_DETAILS } from 'src/constants/planBenefits';

// similar to filterBenefits from plans/pricing/utils.
// This will be moved to split or other cms, so didn't mess with the other function.

const filterUpsellBenefits = ({ plan = {} }) => {
  const { includedSkus = [], productDiscount: discount } = plan;
  const {
    homeTech,
    inHome,
    checkup,
    antivirus,
    password,
    productDiscount,
  } = UPSELL_MODULE_BENEFITS_DETAILS;
  const benefits = [];
  const { amount: discountAmount } = discount;
  benefits.push(inHome);
  benefits.push(homeTech);
  benefits.push(productDiscount(discountAmount));
  includedSkus.forEach((sku) => {
    const skuName = sku.name.toLowerCase();

    if (skuName.includes('checkup')) {
      benefits.push(checkup);
    } else if (skuName.includes('malwarebytes')) {
      benefits.push(antivirus);
    } else if (skuName.includes('dashlane')) {
      benefits.push(password);
    }
  });
  return benefits;
};

export const formatUpsellPlan = ({ plan, planId }) => {
  const hellotechHomePlans = plan.prices.find((p) => p.id === planId);

  return { ...hellotechHomePlans, benefits: filterUpsellBenefits({ plan: hellotechHomePlans }) };
};

// Given a planId, get estimated cart.
export const getEstimatedCartDetails = ({ api, planId }) => {
  return new Promise((resolve, reject) => {
    api.cart
      .estimatePlan({ plan_id: planId })
      .then(({ error, data }) => {
        if (error) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ error, data });
        } else {
          resolve({ data });
        }
      })
      .catch(reject);
  });
};
