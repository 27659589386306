// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Components
import Icon from 'src/components/HTKit/Icon';
// Styles
import styles from './styles.scss';

const ExpertInHomeService = ({ backgroundColor, headerClasses = '' }) => {
  const background = `${backgroundColor}Background`;
  const columnStyles = cn(styles.column, styles.copyContainer);
  return (
    <section className={cn('l-content-section', styles[background])}>
      <h2 className={cn('l-section-header', styles.header, headerClasses)}>Expert Nationwide Service</h2>
      <div className={styles.container}>
        <figure className={columnStyles}>
          <Icon name="icon-trusted" className={styles.logoSize} />
          <h4>Trusted Techs</h4>
          <p className="p1 n700">
            A nationwide network of friendly techs. Each expert is hand-selected, background-checked
            and insured.
          </p>
        </figure>
        <figure className={columnStyles}>
          <Icon name="icon-convenient" className={styles.logoSize} />
          <h4>Convenient Booking</h4>
          <p className="p1 n700">
            In-home service from 9am-9pm. Book online or by phone in minutes for service as soon as
            next-day.
          </p>
        </figure>
        <figure className={columnStyles}>
          <Icon name="icon-guranteed" className={styles.logoSize} />
          <h4>HelloTech Guarantee</h4>
          <p className="p1 n700">
            We&apos;re committed to your complete satisfaction with our service. If you&apos;re not
            100% happy, we&apos;ll do whatever it takes to make it right.
          </p>
        </figure>
      </div>
    </section>
  );
};

ExpertInHomeService.defaultProps = {
  backgroundColor: 'white',
};
ExpertInHomeService.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  headerClasses: PropTypes.string,
};

export default ExpertInHomeService;
/*
  ExpertInHomeService:
    A reusable page section with marketing copy indicating HelloTech are experts in our field.

  Examples:
    <ExpertInHomeService backgroundColor="white" />

  Properties:
    backgroundColor:
      type: string
      description: Used to set the background-color CSS property

  Last modified: -GH July 14, 2020
*/
