// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Utils && Data
import { prevented } from 'src/utils/event';
import { resolveHomeUrl, usePartnerLogoUrl } from 'src/components/Header/utils';
// Components
import Icon from 'src/components/HTKit/Icon';
import HTLogo from 'src/components/Header/Parts/HTLogo';
// Styles
import styles from '../styles.scss';

const HamburgerToggle = ({ sidebarIsOpen, toggleSidebar, themeStyles }) => {
  const toggle = () => prevented(toggleSidebar());
  const hamburgerStyles = cn(styles.hamburgerIcon, themeStyles);
  const closeStyles = cn(styles.closeIcon, themeStyles);
  const iconToRender = sidebarIsOpen ? (
    <Icon name="v2-close-icon" className={closeStyles} onClick={toggle} />
  ) : (
    <Icon name="hamburger-v2" className={hamburgerStyles} onClick={toggle} />
  );
  return iconToRender;
};

const LeftComponent = ({
  actionableLogo,
  currentPartnerLanding,
  homeUrl,
  showSiteLinks,
  sidebarIsOpen,
  toggleSidebar,
  themeStyles,
  topNavIsTransparent,
}) => {
  const resolvedHomeUrl = resolveHomeUrl({ currentPartnerLanding, homeUrl });
  const toLink = actionableLogo ? resolvedHomeUrl : null;
  const partnerLogo = usePartnerLogoUrl({ topNavIsTransparent });
  const linkWrapperStyles = cn('-no-decoration', styles.leftContentLinkWrapper);

  return (
    <div className={styles.leftContent}>
      {showSiteLinks && (
        <HamburgerToggle
          sidebarIsOpen={sidebarIsOpen}
          toggleSidebar={toggleSidebar}
          themeStyles={themeStyles}
        />
      )}
      <Link to={toLink} className={linkWrapperStyles}>
        <HTLogo className={themeStyles} />
      </Link>
      {partnerLogo && (
        <div style={{ backgroundImage: `url(${partnerLogo})` }} className={styles.partnerLogo}>
          &nbsp;
        </div>
      )}
    </div>
  );
};

LeftComponent.propTypes = {
  actionableLogo: PropTypes.bool,
  currentPartnerLanding: PropTypes.object,
  homeUrl: PropTypes.string,
  showSiteLinks: PropTypes.bool.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  topNavIsTransparent: PropTypes.bool.isRequired,
  themeStyles: PropTypes.string,
};

HamburgerToggle.propTypes = {
  sidebarIsOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  themeStyles: PropTypes.string,
};

export default LeftComponent;
