import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { prevented } from 'src/utils/event';
import { CheckboxV2 } from 'src/components/HTKit/Forms/CheckboxV2';
import PLAN_TYPES from './constants';
import styles from '../styles.scss';

const LearnMore = ({ onLearnMore }) => (
  <span className={styles.upsellV2AnnualSavings}>
    <a href="#" className={styles.upsellV2LearnMore} onClick={prevented(onLearnMore)}>
      Learn More
    </a>
  </span>
);

LearnMore.propTypes = { onLearnMore: PropTypes.func };

export const ServiceUpsellPrice = ({ oldPrice, currentPrice, classes }) => (
  <>
    <span className={cn("line-through paddingRight-tiny", classes)}>{oldPrice}</span>
    <span>{currentPrice}</span>
  </>
);

ServiceUpsellPrice.propTypes = {
  oldPrice: PropTypes.string,
  currentPrice: PropTypes.string,
  classes: PropTypes.string,
};

const AnnualLabel = ({
  amount,
  planAmount,
  onLearnMore,
  planName,
  planPricingSplit,
  oldPrice,
  currentPrice,
}) => {
  const planPrice = planPricingSplit ? (
    <ServiceUpsellPrice oldPrice={oldPrice} currentPrice={currentPrice} />
  ) : (
    `$${planAmount}`
  );
  return (
    <span className={cn('caption', styles.upsellLabel)}>
      <h6 className={styles.planInfo}>
        Join {planName} and Save {amount}
      </h6>
      <p className="caption n700">
        {' '}
        with {planPrice} Annual membership. <LearnMore onLearnMore={onLearnMore} />
      </p>
    </span>
  );
};
AnnualLabel.propTypes = {
  showMonthly: PropTypes.bool,
  amount: PropTypes.string,
  planAmount: PropTypes.number,
  onLearnMore: PropTypes.func,
  planName: PropTypes.string,
  planPricingSplit: PropTypes.bool,
  oldPrice: PropTypes.string,
  currentPrice: PropTypes.string,
};

const MonthlyLabel = ({
  planAmount,
  amount,
  onLearnMore,
  planName,
  planPricingSplit,
  oldPrice,
  currentPrice,
}) => {
  const planPrice = planPricingSplit ? (
    <ServiceUpsellPrice oldPrice={oldPrice} currentPrice={currentPrice} />
  ) : (
    `$${planAmount}`
  );
  return (
    <span className={styles.upsellLabel}>
      <h6 className={styles.planInfo}>
        Join {planName} and Save {amount}
      </h6>
      <p className="caption n700">
        {' '}
        with {planPrice}/mo annual membership. <LearnMore onLearnMore={onLearnMore} />
      </p>
    </span>
  );
};
MonthlyLabel.propTypes = {
  planAmount: PropTypes.number,
  amount: PropTypes.string,
  onLearnMore: PropTypes.func,
  planName: PropTypes.string,
  planPricingSplit: PropTypes.bool,
  oldPrice: PropTypes.string,
  currentPrice: PropTypes.string,
};

const AnnualOptionLabel = ({ amount, savings = '' }) => (
  <span className={cn('p2', styles.upsellV2Label)}>
    Join and pay ${amount} once{' '}
    <span className={styles.upsellV2AnnualSavings}>(Save {savings})</span>
  </span>
);
AnnualOptionLabel.propTypes = { amount: PropTypes.number, savings: PropTypes.string };

const MonthlyOptionLabel = ({ amount }) => (
  <span className={cn('p2', styles.upsellV2Label)}>Join for ${amount}/month</span>
);
MonthlyOptionLabel.propTypes = { amount: PropTypes.number };

const AnnualAndMonthlyCheckboxOption = ({
  monthlyPlanAmount,
  monthlyPlanId,
  yearlyPlanAmount,
  yearlyPlanId,
  annualSavingsVsMonthlyPrice,
}) => (
  <>
    <CheckboxV2 label={<MonthlyOptionLabel amount={monthlyPlanAmount} />} value={monthlyPlanId} />
    <CheckboxV2
      label={<AnnualOptionLabel amount={yearlyPlanAmount} savings={annualSavingsVsMonthlyPrice} />}
      value={yearlyPlanId}
    />
  </>
);

AnnualAndMonthlyCheckboxOption.propTypes = {
  monthlyPlanAmount: PropTypes.number,
  monthlyPlanId: PropTypes.number,
  yearlyPlanAmount: PropTypes.number,
  yearlyPlanId: PropTypes.number,
  amount: PropTypes.number,
  annualSavingsVsMonthlyPrice: PropTypes.string,
  onLearnMore: PropTypes.func,
};

const getPlanLabel = ({ showPlanType, planValue, ...rest }) => {
  const planType = {
    [PLAN_TYPES.ANNUAL]: <AnnualLabel {...rest} />,
    [PLAN_TYPES.MONTHLY]: <MonthlyLabel {...rest} />,
    // [PLAN_TYPES.ANNUAL_AND_MONTHLY]: <AnnualAndMonthlyCheckboxOption onLearnMore={onLearnMore} {...rest} />,
  };

  return planType[showPlanType];
};

export default getPlanLabel;
