import get from 'lodash/get';
import { useEffect, useMemo, useState } from 'react';
import useAPI from 'src/hooks/useAPI';
import { useSelector } from 'react-redux';
import { immutableToJS } from 'src/utils/helpers';
import { logger } from 'src/utils/logger';
import { skusSelector } from 'src/selectors/skus';

/**
 * Determine if a virtual site visit is required based on the cart, SKU, and order details.
 * - If the cart contains a same-day service request, we'll return false.
 * - If the cart contains a non-same-day service request, we'll check the SKUs to determine if a
 *  virtual site visit is required.
 */
export const useIsVirtualVisitRequired = ({ cart = {}, order = {} } = {}) => {
  const [isVisitRequired, setIsVisitRequired] = useState(false);
  const api = useAPI();

  const skusState = useSelector(skusSelector);

  const skusToJS = immutableToJS(skusState) || {};
  const cartToJS = immutableToJS(cart) || {};
  const orderToJS = immutableToJS(order) || {};

  const breakdownItems = get(cartToJS, 'breakdown.items', []);
  const cartSkuIds = breakdownItems.map((item) => item.skuId);

  const isSameDayServiceRequest = useMemo(() => {
    /** Array of ISO8601 times  */
    const selectedDateTimes = get(cartToJS, 'availability', []).map((d) => d.value);
    const orderCreationDate = get(orderToJS, 'createdAt.iso', new Date().toISOString());

    if (!selectedDateTimes.length) return false;

    const isSameDay = (date1, date2) => {
      return new Date(date1).toLocaleDateString() === new Date(date2).toLocaleDateString();
    };

    // If this is a converted order, we'll compare the order creation date against the selected
    const isCartForConvertedOrder = Boolean(cartToJS.order);
    if (isCartForConvertedOrder) {
      return selectedDateTimes.some((dateTime) => isSameDay(dateTime, orderCreationDate));
    }

    // If this is an in-progress cart, we'll compare the selected times against today's date
    const today = new Date().toLocaleDateString();
    return selectedDateTimes.some((dateTime) => isSameDay(dateTime, today));
  }, [cartToJS, orderToJS]);

  /**
   * The item data in the cart does not include sku.virtualVisitRequired, so we need to fetch
   * the SKUs to determine if a virtual site visit is required. We'll check the SKUs in the store
   * first, and then fetch any missing SKUs.
   */
  let allSkus = skusToJS;
  const missingSkuIds = cartSkuIds.filter((skuId) => !allSkus[skuId]);
  const hasMissingSkus = missingSkuIds.length > 0;

  // Main effect hook to fetch missing SKUs and determine if a virtual site visit is required.
  useEffect(() => {
    const fetchSkuById = async (skuId) => {
      try {
        const response = await api.skus.find({ id: skuId });
        if (response.err) throw new Error(response.err);
        return response.data.sku;
      } catch (error) {
        logger('useIsVirtualVisitRequired')(error);
        return null;
      }
    };

    const determineIfVirtualVisitRequired = async () => {
      if (isSameDayServiceRequest) return false;

      if (hasMissingSkus) {
        const newSkus = await Promise.all(missingSkuIds.map(fetchSkuById));
        const newSkusById = newSkus.reduce((acc, sku) => ({ ...acc, [sku.id]: sku }), {});
        allSkus = { ...allSkus, ...newSkusById };
      }

      const isRequired = cartSkuIds.some((skuId) => allSkus[skuId]?.virtualVisitRequired);
      setIsVisitRequired(isRequired);
      return isRequired;
    };

    determineIfVirtualVisitRequired();
  }, [hasMissingSkus, isSameDayServiceRequest, cartSkuIds.length]);

  return isVisitRequired;
};
