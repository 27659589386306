import React, { Suspense as RSuspense } from 'react';
import PropTypes from 'prop-types';
import Loader from 'src/components/Loader';

const FallbackLoader = ({ defaultLoaderClass = 'paddingY-huge1' }) => (
  <div className={defaultLoaderClass}>
    <Loader />
  </div>
);

FallbackLoader.propTypes = {
  defaultLoaderClass: PropTypes.string,
};

const Suspense = ({ children, fallback, defaultLoaderClass }) => {
  const fallbackLoader = fallback || <FallbackLoader defaultLoaderClass={defaultLoaderClass} />;
  return <RSuspense fallback={fallbackLoader}>{children}</RSuspense>;
};

Suspense.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
  defaultLoaderClass: PropTypes.string,
};

export default Suspense;
