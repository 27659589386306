import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import HT_INFO from '../../../constants/hellotech';

class SocialMetaTags extends Component { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    imageWidth: PropTypes.string,
    imageHeight: PropTypes.string,
    pathname: PropTypes.string,
  };

  static defaultProps = {
    imageWidth: '320',
    imageHeight: '320',
  };

  render() {
    const { title, description, image, imageWidth, imageHeight, pathname } = this.props;
    return (
      <Helmet>
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={`${HT_INFO.utils.cdn}/public${image}`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`${HT_INFO.social.facebook.og.url}${pathname}`} />
        <meta property="og:image" content={`${HT_INFO.utils.cdn}/public${image}`} />
        <meta property="og:image:url" content={`${HT_INFO.utils.cdn}/public${image}`} />
        <meta property="og:image:width" content={imageWidth} />
        <meta property="og:image:height" content={imageHeight} />
      </Helmet>
    );
  }
}

function mapStateToProps(state) {
  return {
    pathname: state.getIn(['router', 'location', 'pathname']),
  };
}

export default connect(mapStateToProps, {})(SocialMetaTags);
