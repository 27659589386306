// For installation scheduling page + site visit/installation rescheduling pages
export const EV_BASE_SCHEDULING_ATTRIBUTES = {
  /* Amount of days in the future from today. */
  BUFFERED_DAYS: 1,
  /* This is the group by amount of hours. It needs to be equal amounts per group/window */
  GROUP_BY_HOURS: 4,
  /* This is the group(s) selected or "windows". We require at least 1 */
  MIN_GROUP_REQUIRED: 1,
  /* This is the group(s) selected or "windows". If you want only 1 group max. Put 1 */
  MAX_GROUP_REQUIRED: Infinity,
  /* This is the translated into single selection quantity min required */
  get REQUIRED_MIN_TIMES_LENGTH() {
    return this.MIN_GROUP_REQUIRED * this.GROUP_BY_HOURS;
  },
  /* Use this to get the number of dates given your max group. So, 2 max groups of 4 equal, 8 dates */
  get REQUIRED_MAX_TIMES_LENGTH() {
    return this.MAX_GROUP_REQUIRED * this.GROUP_BY_HOURS;
  },
  /* This is the latter boundary of time. EV ends at 5, last appt. App returns till 8 */
  END_HOUR_MILIITARY: 18,
};

// For site visit scheduling page only
export const EV_SITE_VISIT_SCHEDULING_ATTRIBUTES = {
  ...EV_BASE_SCHEDULING_ATTRIBUTES,
  /* Amount of days in the future from today. */
  BUFFERED_DAYS: 2,
};

export const TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES = {
  /** Amount of days in the future from today. */
  BUFFERED_DAYS: 0,
  /** This is the group by amount of hours. It needs to be equal amounts per group/window */
  GROUP_BY_HOURS: 4,
  /** This is the group(s) selected or "windows". We require at least 1 */
  MIN_GROUP_REQUIRED: 1,
  /* This is the group(s) selected or "windows". If you want only 1 group max. Put 1 */
  MAX_GROUP_REQUIRED: 1,
  /* This is the translated into single selection quantity min required */
  get REQUIRED_MAX_TIMES_LENGTH() {
    return this.MAX_GROUP_REQUIRED * this.GROUP_BY_HOURS;
  },
  /** This is the latter boundary of time. BE returns times until 8pm (20), but we want to include every time, so let's make it 24 */
  END_HOUR_MILIITARY: 24,
};
