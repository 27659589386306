import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// Actions
import { openPasscodeModal, setUserInfo } from './actions';
import { useSendAuthCode } from './LoginPasscode/LoginPasscode.hooks';

// Components
import { ModalV2 } from 'src/components/HTKit/Modals/ModalV2';
import { LoginFormPasswordless } from '../LoginFormPasswordless/LoginForm.Passwordless';

export const LoginModalPasswordless = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();

  const sendAuthCode = useSendAuthCode();

  const onEmailSubmitSuccess = async ({ phoneLastFour = '', userInitials = '', email = '' }) => {
    dispatch(setUserInfo({ email, phoneLastFour, userInitials }));
    dispatch(openPasscodeModal());
    closeModal();
    await sendAuthCode({ email });
  };

  return (
    <ModalV2 isVisible={isOpen} hide={closeModal} header="Sign in" size="medium">
      <LoginFormPasswordless onSuccess={onEmailSubmitSuccess} />
    </ModalV2>
  );
};

LoginModalPasswordless.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
