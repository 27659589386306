import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Grid, Breakpoints } from 'HTKit/Grid';

import { Picture, TYPES } from 'src/components/Elements/Picture';
import missingImage from 'src/images/missing.png';

import LeftSkuInfoBlock from './LeftSkuInfoBlock';
import styles from './styles.scss';


const getFileFormat = (filename = '') => {
  const ext = filename.split('.').pop();
  if (ext.includes('jpg') || ext.includes('jpeg')) return TYPES.JPG;
  if (ext.includes('png')) return TYPES.PNG;
  if (ext.includes('webp')) return TYPES.WEBP;
  return TYPES.JPG;
};

const MISSING_PNG_REGEX = /^\/images\/.*missing\.png/;

const HeaderBlock = ({ landing, className, ...props }) => {
  const desktopImage = landing.getIn(['images', 'front', 'original']) || ''; // landing.getIn(...) will be null if user doesn't upload an image
  const mobileImage = landing.getIn(['images', 'front', 'mobile']) || ''; // landing.getIn(...) will be null if user doesn't upload an image

  const sources = useMemo(() => {
    const values = [];
    if (!mobileImage.match(MISSING_PNG_REGEX)) values.push({
      srcSet: mobileImage,
      type: getFileFormat(mobileImage),
    });

    if (!desktopImage.match(MISSING_PNG_REGEX)) values.push({
      srcSet: desktopImage,
      type: getFileFormat(desktopImage),
      media: values.length > 0 ? `(min-width: ${Breakpoints.LG_GRID}px)` : undefined,
    });
    return values;
  }, [desktopImage, mobileImage]);

  return (
    <section className={styles.container}>
      <Grid.FullWidth classes={cn(className, styles.headerWrapper)}>
        <Grid.Row classes={cn(styles.heroContainer)}>
          <Grid.Column sm={4} md={8} lgOffset={1} lg={5} classes={styles.skuInfoContainer}>
            <LeftSkuInfoBlock className={styles.skuInfo} {...props} />
          </Grid.Column>
          <Grid.Column sm={4} md={8} lg={5} classes={styles.imageContainer}>
            <Picture sources={sources} className={styles.image} src={missingImage} />
          </Grid.Column>
        </Grid.Row>
      </Grid.FullWidth>
    </section>
  );
};

HeaderBlock.propTypes = {
  landing: PropTypes.object,
  className: PropTypes.string,
};

export default HeaderBlock;
