import { SET_BRANDING, REMOVE_BRANDING } from './constants';

export const setBranding = ({ branding }) => ({
  type: SET_BRANDING,
  branding,
});

export const removeBranding = () => ({
  type: REMOVE_BRANDING,
});
