import { fromJS } from 'immutable';
import { PAGE_LOADED } from 'src/constants/common';

const plansInitialState = fromJS({});

export default function plansReducer(state = plansInitialState, action) {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        case 'plans': {
          return state.merge({ list: action.plans });
        }
        default:
          return state;
      }
    default:
      return state;
  }
}
