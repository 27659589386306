// Libraries
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// Utils
import { push, addSkuPath } from 'src/utils/paths';
// Components
import { Grid } from 'src/components/HTKit/Grid';
import ProductCardItem from 'src/components/Elements/ProductCard';
import ModalContents from 'src/components/SkuDetailsFullModal/ModalContents';

const ProductCards = ({ skus }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentSku, setCurrentSku] = useState(null);
  const dispatch = useDispatch();

  const handleClick = (sku) => {
    setIsOpen(!isOpen);
    setCurrentSku(sku);
  };

  const handleOnBookClick = (id) => {
    dispatch(push(addSkuPath(id)));
  };

  return (
    <Grid.Fluid classes="paddingTop-small paddingBottom-huge1">
      <Grid.Row>
        {skus.map((sku) => (
          <Grid.Column sm={4} md={4} lg={3} key={sku.id}>
            <ProductCardItem
              cheapestPrice={sku.startsAtPrice}
              icon={sku.image}
              name={sku.name}
              linkText="See Details"
              buttonText="Book Now"
              onButtonClick={() => handleOnBookClick(sku.id)}
              onClick={() => handleClick(sku)}
              onLinkClick={() => handleClick(sku)}
              shouldShowImage={false}
            />
          </Grid.Column>
        ))}
        {isOpen && (
          <ModalContents
            open={isOpen}
            handleClose={() => handleClick(currentSku)}
            name={currentSku && currentSku.name}
            cheapestPrice={currentSku.startsAtPrice}
            onClick={() => handleOnBookClick(currentSku.id)}
            skuBullets={currentSku && currentSku.skuBullets}
            description={null}
          />
        )}
      </Grid.Row>
    </Grid.Fluid>
  );
};

ProductCards.propTypes = { skus: PropTypes.array };

export default ProductCards;
