import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.scss';

/**
 * Use this to wrap your panels to remove the bottom-borders (except last) or pass in a custom class
 * <PanelGroup className="notRequired">
 *   <Panel>some info one</Panel>
 *   <Panel>some info two</Panel>
 * </PanelGroup>
 * @param props
 * @returns {*}
 * @constructor
 */
const PanelGroup = ({ className, children }) => {
  const panelGroupClasses = cn(styles.panelGroup, {
    [className]: className,
  });

  return <div className={panelGroupClasses}>{children}</div>;
};

PanelGroup.defaulProps = {
  className: '',
};

PanelGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default React.memo(PanelGroup);
