/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logger } from 'src/utils/logger';
import { Grid } from 'src/components/HTKit/Grid';
import Disclaimer from 'src/containers/CartPage/CartTypes/UpsellPanel/Disclaimer';

import { updateTrackingByUpsell } from 'src/actions/tracking';
import { UPSELL_TRACKING } from 'src/constants/tracking';
import Desktop from './Desktop';
import Mobile from './Mobile';

const UpsellCart = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const plan = props.plansInfo.get('monthly').toJS();
      const partner = props.cart.get('partner', null);

      /* Upsell tracking: viewed */
      dispatch(
        updateTrackingByUpsell({
          plan,
          partner: partner ? partner.toJS() : partner,
          event: UPSELL_TRACKING.viewed,
        }),
      );
    } catch (e) {
      logger(UPSELL_TRACKING.viewed)(e);
    }

  }, []);

  return (
    <Grid.Fluid>
      <Desktop {...props} />
      <Mobile {...props} />
      <Disclaimer />
    </Grid.Fluid>
  );
};

export default UpsellCart;
