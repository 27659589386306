import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCartFlow } from 'src/utils/cartFlow';
import { purePageSelector } from 'src/selectors/page';
import { useSplitIOTreatment, splitioConstants } from 'src/components/SplitIO';
import { SUMMARY_PAGE_NAME_SHORT } from '../constants';
import { updateLowTierConfig } from '../actions';
import { pureCartSelector } from 'src/selectors/cart';
import { pureUserSelector } from 'src/selectors/user';
import { HELLOTECH_LITE_PLAN } from 'src/constants/plan';

const useFeatureFlags = () => {
  const { splitTreatment: hthSplitTreatment } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_HTH_UPSELL_MODAL_ORDER_SUMMARY_PAGE,
  );
  const {
    splitLoaded: lowTierSplitLoaded,
    splitTreatment: lowTierSplitTreatment,
  } = useSplitIOTreatment(splitioConstants.SPLITIONAME_LOW_TIER_MEMBERSHIP_V1);

  return {
    hthSplitTreatment,
    lowTierSplitTreatment,
    lowTierSplitLoaded,
  };
};

/**
 * Manages the visibility and interaction states of upsells based on feature flags and user choices.
 *
 * <LowTierMembershipUpsell /> will appear 100% of the time in every checkout.
 * - lowTierSplitTreatment === splitioConstants.ON will auto-add HTL plan to the cart
 * - lowTierSplitTreatment === splitioConstants.OFF will not auto-add HTL plan to the cart
 *
 * If lowTierSplit is on, the handleAddToPlan() callback will open the HTH modal after success or error
 * If lowTierSplit is off, the useEffect below will handle the HTH modal
 */
export const useUpsellManagement = () => {
  const dispatch = useDispatch();

  const cart = useSelector(pureCartSelector);
  const user = useSelector(pureUserSelector);

  const { hthSplitTreatment, lowTierSplitTreatment, lowTierSplitLoaded } = useFeatureFlags();

  const { isSingleServiceFlow } = useCartFlow();

  const pageReduxState = useSelector(purePageSelector(SUMMARY_PAGE_NAME_SHORT)) || {};
  const { hthUpsellShown } = pageReduxState.lowTierConfig || {};

  const isHtLiteSubscriber =
    user?.account?.subscription?.planId === HELLOTECH_LITE_PLAN || // Test against split plan id
    /^(HelloTech Lite)$/i.test(user?.account?.subscription?.planName); // Backup for lite plan sold from `/plans` page with different plan id

  const openHthModal = () => {
    const isEligibleToSeeUpsell =
      /** No partner skus in cart */
      !cart?.partner &&
      /** No partner plan in cart */
      !cart?.plan?.partner &&
      /** Show to lite plan subscribers, but not subscribers of other plans */
      (!user?.account?.subscription?.id || isHtLiteSubscriber);
    return isEligibleToSeeUpsell
      ? dispatch(updateLowTierConfig({ showHthModal: true, hthUpsellShown: true }))
      : null;
  };

  /**
   * Callback that triggers after the HTLite plan has success or error
   */
  const addPlanCallback = useCallback(() => {
    if (hthSplitTreatment !== splitioConstants.ON) {
      return;
    }
    if (!hthUpsellShown) {
      dispatch(updateLowTierConfig({ hthUpsellShown: true }));
      openHthModal();
    }
  }, [hthSplitTreatment, hthUpsellShown]);

  /**
   * Defines conditions under which <LowTierMembershipUpsell /> will not render
   *
   * LowTierMembershipUpsell has it's own list of conditions that determines whether or
   * not it should render. Ideally there would be a single source of truth, but
   * this was the best solution at the time it was written. -GH Sep 8 2024
   */
  const isLowTierUpsellHidden =
    lowTierSplitTreatment === splitioConstants.OFF ||
    (lowTierSplitLoaded && lowTierSplitTreatment === splitioConstants.CONTROL) ||
    isSingleServiceFlow();

  useEffect(() => {
    if (hthSplitTreatment === splitioConstants.ON && !hthUpsellShown) {
      /**
       * Manually trigger the HTH upsell modal to open if:
       * 1. The low tier split is off
       * 2. It's a single service flow
       * 3. The user is already an HT Lite subscriber (LowTier checkbox won't render)
       */
      const shouldOpenModal = isLowTierUpsellHidden || isHtLiteSubscriber;

      if (shouldOpenModal) {
        openHthModal();
      }
    }
  }, [hthSplitTreatment, hthUpsellShown, isLowTierUpsellHidden, isHtLiteSubscriber]);

  return {
    addPlanCallback,
  };
};
