export const SKU_LOADED = 'skuPage/SKU_LOADED';
export const FETCH_PLAN_DETAILS = 'skuPage/FETCH_PLAN_DETAILS';
export const LOAD_PLAN_DETAILS = 'skuPage/LOAD_PLAN_DETAILS';
export const TOGGLE_PLAN_MODAL = 'skuPage/TOGGLE_PLAN_MODAL';
export const ADD_PLAN_TO_CART = 'skuPage/ADD_PLAN_TO_CART';
export const TRACK_REMOVE_PLAN_FROM_SKU = 'skuPage/TRACK_REMOVE_PLAN_FROM_SKU';
export const TRACK_ADD_PLAN_FROM_SKU = 'skuPage/TRACK_ADD_PLAN_FROM_SKU';

// Only for Location and ProductSingleSku pages
export const FETCH_PLANS_FOR_MONTHLY_UPSELL = 'skuPage/FETCH_PLANS_FOR_MONTHLY_UPSELL';
export const PLANS_INFO_LOADED = 'skuPage/PLANS_INFO_LOADED';

export const PAGE_NAME = 'sku';
