import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { over, compact, find, get } from 'lodash';
import { splitioConstants, SplitTreatmentToggle } from 'src/components/SplitIO';
import PromoBar from 'src/components/PromoBar';
import LeadGeneratedPolling from 'src/utils/leadGenerated/LeadGeneratedPolling';
import { HEADER_TEMPLATES } from 'src/constants/common';
import { LANDING_TEMPLATES as LT } from 'src/constants/landings';
import { HEADER_WRAPPER_ID, HEADER_ID, HEADER_PORTAL_ID, HEADER_TYPES, TOP_NAV_NAME } from './constants';
// Styles
import styles from './styles.scss';


/**
  If the client is on a partner landing page, then the landing info takes precedent of
  layout header state.

  Currently, we want to retain the phone number in the header for the following partner
  templates during checkout, so instead of using BookingHeader, we'll just use TopNavHeader
*/
const getTemplateByPartnerCookie = ({ currentPartnerLanding }) => {
  const partnerTemplate = currentPartnerLanding && currentPartnerLanding.get('template');
  if ([LT.FEATURED_CONTENT_OR_SKU, LT.SMB_SKU].includes(partnerTemplate)) {
    return HEADER_TYPES.topNav;
  }
  return null;
};

getTemplateByPartnerCookie.propTypes = { currentPartnerLanding: PropTypes.object };

/*
 * This a 'catch all' in which the rigidness of the layout can be overwritten
 * on a per needed basis.
 * */
const getHeaderByExternalSource = ({ fieldSaleAgent, header }) => {
  const layoutTemplate = header.get('template');
  // fieldSales conditional
  if (fieldSaleAgent && layoutTemplate !== HEADER_TEMPLATES.Booking) {
    return HEADER_TYPES.fieldSales;
  }

  return null;
};

getHeaderByExternalSource.propTypes = {
  fieldSaleAgent: PropTypes.object,
  header: PropTypes.object,
};

/**
  Get header template based on the layout header state
*/
const getTemplateByLayout = ({ header }) => {
  const layoutTemplate = header.get('template');

  return (
    find(HEADER_TYPES, (headerType) => get(headerType, 'type') === layoutTemplate) ||
    HEADER_TYPES.topNav
  );
};

getTemplateByLayout.propTypes = { header: PropTypes.object };

/*
  Helpers to get templates
*/
const iterateTemplateGetters = over(
  getHeaderByExternalSource,
  getTemplateByPartnerCookie,
  getTemplateByLayout,
);
const getHeaderTemplate = ({ fieldSaleAgent, currentPartnerLanding, header }) => {
  return compact(iterateTemplateGetters({ fieldSaleAgent, currentPartnerLanding, header }))[0];
};

/*
  Purpose of this header is to determine which Header Template to use based on
  layout state in redux and partner info.
*/
const Header = ({ fieldSaleAgent, currentPartnerLanding, header, showHeader }) => {


  if (!showHeader) return <LeadGeneratedPolling />;

  const mainWrapperRef = useRef();

  const { type, header: HeaderTemplate } = getHeaderTemplate({
    fieldSaleAgent,
    currentPartnerLanding,
    header,
  });

  return (
    <div className={styles.mainWrapper} ref={mainWrapperRef} id={HEADER_WRAPPER_ID}>
      <LeadGeneratedPolling />
      {type === TOP_NAV_NAME && (
        <SplitTreatmentToggle splitName={splitioConstants.SPLITIONAME_PROMO_BAR}>
          <PromoBar refHeader={mainWrapperRef} />
        </SplitTreatmentToggle>
      )}
      <header className={styles.headerWrapper} id={HEADER_ID}>
        <HeaderTemplate />
      </header>
      <div id={HEADER_PORTAL_ID} />
    </div>
  );
};

Header.propTypes = {
  fieldSaleAgent: PropTypes.object,
  currentPartnerLanding: PropTypes.object,
  header: PropTypes.object,
  showHeader: PropTypes.bool,
};

Header.defaultProps = {
  fieldSaleAgent: false,
};

export default Header;
