import { takeLatest, select, put, call } from 'redux-saga/effects';
import {
  displayErrors,
  displayErrorsWithSnack,
  requestStarted,
  requestFinished,
} from 'src/utils/request';
import { LOAD_PAGE } from 'src/constants/common';
import { selectRoutes } from 'src/apiRoutes';
import { clearFormErrors, pageLoaded } from 'src/actions/common';
import { updateCart } from 'src/containers/AddSkuPage/actions';
import {
  cartHasEVWorkflowSelector,
  cartHasEVInstallOnlyWorkflowSelector,
} from 'src/containers/EV/ev.selectors';
import { paymentTypeSelector } from 'src/selectors/cart';
import { paymentEditMode, paymentSubmitted, subscriptionLoaded } from './actions';
import {
  PAGE_NAME,
  SUBMIT_PAYMENT,
  SUBMIT_EXISTING_PAYMENT,
  SUBMIT_PAYMENT_TYPE,
  PAYMENT_TYPES,
} from './constants';
import { subscriptionSelector } from './selectors';
import { goToStage } from '../actions';

// Helper functions
function* getActiveSubscription() {
  const existingSubscription = yield select(subscriptionSelector);
  if (existingSubscription) return;

  const routes = yield call(selectRoutes);
  const response = yield call(routes.plans.subscriptions.active);
  const {
    data: { subscription },
  } = response;
  if (subscription) {
    yield put(subscriptionLoaded({ subscription }));
  }
}

function* getSubscriptionId() {
  const subscription = yield select(subscriptionSelector);
  return subscription && subscription.get('id');
}

function* setAutoRenew({ canOptIn, willOptIn }) {
  if (!canOptIn) return;

  const subId = yield call(getSubscriptionId);
  const routes = yield call(selectRoutes);
  yield call(routes.plans.subscriptions.autoRenewStatus, { id: subId }, { renew: willOptIn });
}

/**
 *
 * @returns {Generator<any, void, *>}
 */
function* paymentTypeNotCardSubmit() {
  const paymentTypeSelected = yield select(paymentTypeSelector);
  const paymentTypeCard = PAYMENT_TYPES.card;
  const routes = yield call(selectRoutes);

  if (!paymentTypeSelected || paymentTypeSelected === paymentTypeCard) return;

  /* Only reset paymentType to 'card' if previously set */
  yield call(routes.cart.setPaymentType, { paymentType: paymentTypeCard });
}

// Page Sagas
function* pageSaga() {
  const cart = yield select((state) => state.getIn(['entities', 'cart']));
  const card = cart.get('card');
  const shouldBeInEdit = !card;
  yield put(paymentEditMode(shouldBeInEdit));
  yield call(getActiveSubscription);
  yield put(pageLoaded(PAGE_NAME));
}

function* submitPaymentTypeSaga({ paymentType = PAYMENT_TYPES.affirm }) {
  const routes = yield call(selectRoutes);
  const cart = yield select((state) => state.getIn(['entities', 'cart']));
  yield put(requestStarted());
  yield put(clearFormErrors(PAGE_NAME));

  const response = yield call(routes.cart.setPaymentType, { paymentType });

  if (response.err) {
    yield put(displayErrors(response));
  } else {
    yield put(paymentEditMode(false));
    yield put(updateCart({ cart: response.data.cart }));
    yield put(
      paymentSubmitted({
        updated: cart.get('status') !== 'payment',
        id: cart.get('id'),
        paymentType: PAYMENT_TYPES.affirm,
      }),
    );
    yield put(goToStage('summary'));
  }

  yield put(requestFinished());
}

function* submitPaymentSaga({ token, canOptIn = false, willOptIn = false }) {
  const routes = yield call(selectRoutes);
  const cart = yield select((state) => state.getIn(['entities', 'cart']));
  yield put(requestStarted());
  yield put(clearFormErrors(PAGE_NAME));

  /* Only reset paymentType to 'card' if previously set to something else */
  yield call(paymentTypeNotCardSubmit);
  const response = yield call(routes.cart.addCard, {
    card_token: token.id,
    token: cart.get('token'),
  });

  // EV
  const cartIsEVWorkflow = select(cartHasEVWorkflowSelector);
  const cartIsEVInstallOnlyWorkflow = select(cartHasEVInstallOnlyWorkflowSelector);

  if (response.err) {
    if (cartIsEVWorkflow || cartIsEVInstallOnlyWorkflow) {
      yield put(displayErrorsWithSnack(response));
    } else {
      yield put(displayErrors(response));
    }
  } else {
    yield put(paymentEditMode(false));
    yield put(updateCart({ cart: response.data.cart }));
    yield call(setAutoRenew, { canOptIn, willOptIn });
    yield put(paymentSubmitted({ updated: cart.get('status') !== 'payment', id: cart.get('id') }));
  }
  yield put(requestFinished());
}

function* submitExistingPaymentSaga() {
  const routes = yield call(selectRoutes);
  const cart = yield select((state) => state.getIn(['entities', 'cart']));
  yield put(requestStarted());

  /* Only reset paymentType to 'card' if previously set to something else */
  yield call(paymentTypeNotCardSubmit);
  const response = yield call(routes.cart.goToSummary, { token: cart.get('token') });

  const cartIsEVWorkflow = select(cartHasEVWorkflowSelector);
  const cartIsEVInstallOnlyWorkflow = select(cartHasEVInstallOnlyWorkflowSelector);

  if (response.err) {
    // EV
    if (cartIsEVWorkflow || cartIsEVInstallOnlyWorkflow) {
      yield put(displayErrorsWithSnack(response));
    } else {
      yield put(displayErrors(response));
    }
  } else {
    yield put(paymentEditMode(false));
    yield put(updateCart({ cart: response.data.cart }));
    yield put(paymentSubmitted({ updated: cart.get('status') !== 'payment', id: cart.get('id') }));
  }
  yield put(requestFinished());
}

function* pageFlow() {
  yield takeLatest((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
  yield takeLatest(SUBMIT_PAYMENT, submitPaymentSaga);
  yield takeLatest(SUBMIT_PAYMENT_TYPE, submitPaymentTypeSaga);
  yield takeLatest(SUBMIT_EXISTING_PAYMENT, submitExistingPaymentSaga);
}

export default [pageFlow];
