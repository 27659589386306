/* eslint-disable import/prefer-default-export */
export const calcHoursBeforeAppt = ({ appointmentStartDateTime }) => {
  return (new Date(appointmentStartDateTime) - new Date()) / (1000 * 3600); // in hours
};

/**
 * Workflow EV range adjustment works within 'windows'. As such, the display
 * gives a wider range since we have to adjust for the 'hour stretch'. This
 * adds an hour.
 *
 * @param range 9am - 12pm , 1pm - 4pm
 * @returns {`${*} - ${*}`}
 */
export const workFlowRangeAdjustment = (range) => {
  /* 1. get the range repexp off the end and add an hour */
  const [start, end] = range.split(' - ');
  const updatedEndTime = end.replace(/(\d+)([ap]m)/i, (match, hour, ampm) => {

    // Increment the hour by 1 (keeping it within 1-12 range)
    const h = (parseInt(hour, 10) % 12) + 1;

    // Merge the updated hour and AM/PM back together
    return h + ampm;
  });

  return `${start} - ${updatedEndTime}`;
}
