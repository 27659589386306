import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'src/components/HTKit/Icon';
import { ORDER_ADJUSTMENT_TYPES } from 'src/components/OrderSummary/Breakdown/constants';
import CreditAppliedLineItem from 'src/components/OrderSummary/Breakdown/CreditAppliedLineItem';
import SupportFeeHint from 'src/components/Cart/SummaryBreakdown/Parts/SupportFeeHint';
import OrderBreakdownLineItem from './OrderBreakdownLineItem';

/**
 * @param {{type: string, explanation: string}} param0
 */
const isSupportFee = ({ type, explanation } = {}) => {
  return type === ORDER_ADJUSTMENT_TYPES.SUPPORT_FEE || Boolean(explanation);
};

const isCreditAdjustment = ({ type } = {}) => type === ORDER_ADJUSTMENT_TYPES.CREDIT;

/**
 * @param {{amount: string, originalAmount: string}} adjustment
 */
const getLineThroughText = (adjustment) =>
  adjustment.amount !== adjustment.originalAmount ? adjustment.originalAmount : null;

const SupportFeeLineItem = ({ className, adjustment }) => {
  const [showHint, setShowHint] = useState(false);
  const toggleHint = () => setShowHint(!showHint);
  const nameComponent = (
    <>
      {adjustment.name}
      <span>
        <Icon
          name="question"
          onClick={toggleHint}
          className="paddingLeft-tiny"
          style={{ cursor: 'pointer' }}
        />
      </span>
    </>
  );

  return (
    <div className={className} key={adjustment.type}>
      <OrderBreakdownLineItem
        name={nameComponent}
        lineThroughText={getLineThroughText(adjustment)}
        amount={adjustment.amount}
        className="marginBottom-tiny1"
      />
      {showHint && <SupportFeeHint explanation={adjustment.explanation} />}
    </div>
  );
};

SupportFeeLineItem.propTypes = {
  className: PropTypes.string,
  adjustment: PropTypes.shape({
    type: PropTypes.string,
    amount: PropTypes.string,
    originalAmount: PropTypes.string,
    name: PropTypes.string,
    explanation: PropTypes.string,
  }),
};

/**
 * Display order global adjustments and not subscription adjustments
 */
const OrderAdjustmentsBreakdown = ({ adjustments, className, hideCreditAdjustment = false }) => {
  if (!adjustments) return null;
  return adjustments
    .sort((adjA) => (adjA.type === ORDER_ADJUSTMENT_TYPES.CREDIT ? -1 : 0))
    .map((adjustment) => {
      if (isCreditAdjustment(adjustment))
        return !hideCreditAdjustment ? (
          <CreditAppliedLineItem amount={adjustment.amount} className="marginBottom-small" />
        ) : null;
      if (isSupportFee(adjustment))
        return <SupportFeeLineItem adjustment={adjustment} className={className} />;
      return (
        <div className={className} key={adjustment.name}>
          <OrderBreakdownLineItem
            name={adjustment.name}
            amount={adjustment.amount}
            lineThroughText={getLineThroughText(adjustment)}
            className="marginBottom-tiny1"
          />
        </div>
      );
    })
    .filter(Boolean);
};

OrderAdjustmentsBreakdown.propTypes = {
  adjustments: PropTypes.array,
  className: PropTypes.string,
  /**
   * Some parent components will have their own way of displaying credit adjustments.
   * Hide the credit adjustment line item if this prop is true.
   */
  hideCreditAdjustment: PropTypes.bool,
};

export default OrderAdjustmentsBreakdown;

/*
  OrderAdjustmentsBreakdown
    - Use this to iterate over a list of order adjustments.
    - Order adjustments are things like coupons, safety/support fee. Subscription discounts/adjustments
      are attached to the individual service line item, not order -GH Jun 9, 2020
*/
