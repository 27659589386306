export const LANDING_TEMPLATES = {
  PRODUCT_MULTIPLE_SKU: 'ProductMultipleSku',
  INTERNAL_PRODUCT_MULTIPLE_SKU: 'InternalProductMultipleSku',
  PRODUCT_SINGLE_SKU: 'ProductSingleSku',
  INTERNAL_PRODUCT_SINGLE_SKU: 'InternalProductSingleSku',
  PARTNERSHIP_PLAN: 'PartnershipPlan', // template is not longer in use
  OPTIMUM_GENERAL: 'OptimumGeneral',
  OPTIMUM_MOVERS: 'OptimumMovers',
  OPTIMUM_SUPPORT: 'OptimumSupport',
  SUDDENLINK_GENERAL: 'SuddenlinkGeneral',
  SUDDENLINK_MOVERS: 'SuddenlinkMovers',
  SUDDENLINK_SUPPORT: 'SuddenlinkSupport',
  ALTICE_NEST: 'AlticeNest',
  FEATURED_CONTENT_OR_SKU: 'FeaturedContentOrSku',
  MULTIPLE_ACCOUNT_SKU: 'MultipleAccountSku',
  SMB_SKU: 'SmbSku',
  GOOGLE_ADS_SKU: 'GoogleAds',
  INTERNAL_GOOGLE_ADS_SKU: 'InternalGoogleAds',
};
