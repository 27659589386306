import { useSelector } from 'react-redux';
import { pureCartSelector } from 'src/selectors/cart';
import { memoizedCreateCartFlow, DEFAULT_CONFIG_VALUES } from './cartFlowFactory';

export const useCartFlow = () => {
  const cart = useSelector(pureCartSelector);

  if (!cart) {
    return DEFAULT_CONFIG_VALUES;
  }

  return memoizedCreateCartFlow({ cart });
};
