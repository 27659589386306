import { TOGGLE_SIDEBAR, SET_SKU_LINK_PREFIX, CATEGORY_MENU } from './constants';

export function toggleSidebar() {
  return {
    type: TOGGLE_SIDEBAR,
  };
}

export const toggleSidebarWithCategory = ({ menuOnOpen = CATEGORY_MENU }) => ({
  type: TOGGLE_SIDEBAR,
  menuOnOpen,
});

export function setSkuLinkPrefix(prefix) {
  return {
    type: SET_SKU_LINK_PREFIX,
    payload: { prefix },
  };
}
