import React from 'react';
import PropTypes from 'prop-types';

const SelectFormattedLabel = ({ label = '' }, { inputValue = '' }) => {
  const lbl = label || '';

  const highlighted = lbl
    .toString()
    .replace(new RegExp(inputValue, 'gi'), (h) => `<strong>${h}</strong>`);
  return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
};

SelectFormattedLabel.propTypes = {
  label: PropTypes.string,
};

export default SelectFormattedLabel;
