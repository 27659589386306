import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Utils
import { skuPath } from 'src/utils/paths';

// Components
import Icon from 'src/components/HTKit/Icon';
import Grid from 'src/components/HTKit/Grid/Grid';

// Helpers
import { searchResultsClickedSegment } from 'src/components/Searchbar/actions';
import { getServiceType } from '../constants';

// Styles
import styles from '../styles.scss';

const ServiceResults = ({ serviceResults, iconClick, getItemProps, searchTerm }) => {
  const dispatch = useDispatch();

  return !!serviceResults.length && (
    <>
      <p className="caption marginTop-medium paddingX-small1 n700">
        SERVICES ({serviceResults.length})
      </p>
      {serviceResults &&
        serviceResults.map((service) => {
          const serviceType = getServiceType(service);
          const onClick = () => {
            dispatch(searchResultsClickedSegment({ searchTerm, clickedItem: "service", attributes: service }));
            iconClick();
          }
          return (
            <div key={service.id} onClick={onClick}>
              <Grid.FullWidth classes={styles.categoryContainer}>
                <Grid.Row classes={styles.customRow}>
                  <Link
                    to={skuPath(service.seo_name.raw)}
                    {...getItemProps({
                      key: service.id.raw,
                      item: service,
                    })}
                    className={cn('paddingX-small1', styles.clickContainer)}
                  >
                    <Grid.Column classes={cn('paddingY-small', styles.servicesColumnContainer)}>
                      <div className={styles.innerColumn}>
                        <p className={cn('caption paddingBottom-tiny', styles.services)}>
                          {serviceType}
                        </p>
                        <div className={cn('h6 paddingBottom-tiny', styles.name)}>
                          {service.name.raw}
                        </div>
                      </div>
                      <Icon name="chevron-right" className={styles.chevron} />
                    </Grid.Column>
                  </Link>
                </Grid.Row>
              </Grid.FullWidth>
            </div>
          );
        })}
    </>
  );
};

ServiceResults.defaultProps = {
  serviceResults: [],
  iconClick: () => {},
};

ServiceResults.propTypes = {
  iconClick: PropTypes.func,
  getItemProps: PropTypes.func,
  serviceResults: PropTypes.array,
  searchTerm: PropTypes.string,
};

export default ServiceResults;
