// Libraries
import React from 'react';
import PropTypes from 'prop-types';
// Images
import featuredIn from './featured-in.png';
import featuredInMobile from './featured-in-mobile.png';
// Styles
import styles from './styles.scss';

const FeaturedIn = ({ backgroundColor }) => {
  const background = `${backgroundColor}Background`;
  return (
    <section className={styles[background]}>
      <img alt="Featured In" src={featuredIn} className={styles.featuredIn} />
      <img alt="Featured In" src={featuredInMobile} className={styles.featuredInMobile} />
    </section>
  );
};

FeaturedIn.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
};

FeaturedIn.defaultProps = {
  backgroundColor: 'white',
};

export default FeaturedIn;

/*
 FeaturedIn:
 A reusable page section showing featured in graphic.

 Examples:
 <FeaturedIn backgroundColor="white" />

 Properties:
 backgroundColor:
 type: string
 description: Used to set the background-color CSS property
 */
