import { useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import chunk from 'lodash/chunk';
// Hooks && Utils
import usePrevious from 'src/hooks/usePrevious';
import {
  convertToIsoFormat,
  convertToTimeZone,
  createTimeWindowDropdownOptions,
  parseDateInTimeZone,
} from 'src/containers/BookingPage/AvailabilityPage/AvailabilityPage.utils';
import {
  updateSelectedDateTimes,
  formatTimeForTimeWindowsDisplay,
} from '../AvailabilitySelectorWithConfig/Scheduling.utils';
import { ensureImmutable } from 'src/utils/helpers';
// Actions & Selectors
import { inEditModeSelector } from 'src/selectors/router';
import { availabilityStateSelectorJs } from 'src/selectors/availability';
import { toggleHourByIndex } from '../AvailabilitySelector/actions';
import { submitAvailability } from 'src/containers/BookingPage/AvailabilityPage/actions';
import { submitSchedule } from 'src/containers/ReschedulingPage/actions';
// Constants
import { DEFAULT_TIME_ZONE } from 'src/constants/time';
import { TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES } from '../AvailabilitySelectorWithConfig/Scheduling.constants';
import { SCHEDULING_ACTIONS } from 'src/utils/serviceScheduling/serviceScheduling.constants';

/**
 * Manages date and time slot selection state for exact time booking.
 * Handles time options generation, valid dates filtering, and syncs selections with availability context.
 *
 * @param {Object} props
 * @param {Array} props.selectedTimes - An array of selected times
 * @param {import('src/types/cart').Cart} props.cart - The cart object
 */
export const useAvailabilitySelectorSelectTimeWindow = ({
  /** For legacy reasons `selectedTimes` is a useRef array stored in AvailabilityPage */
  selectedTimes,
  cart,
  isEditing,
  schedulingAction = SCHEDULING_ACTIONS.NEW_ORDER,
  orderConfig = { orderId: null, orderToken: null, publicPage: false, order: null },
}) => {
  const dispatch = useDispatch();
  const isEditModeGlobalState = useSelector(inEditModeSelector);
  const isEditMode = isEditing || isEditModeGlobalState;

  /** @type {[boolean, (isOpen: boolean) => void]} */
  const [isRecurringPanelOpen, setIsRecurringPanelOpen] = useState(false);

  /** @type {[TRecurrence | null, (recurrence: TRecurrence | null) => void]} */
  const [recurrence, setRecurrence] = useState(null);

  /** @type {[string | null, (date: string | null) => void]} */
  const [selectedDate, setSelectedDate] = useState(null);

  /** @type {[TimeWindowDropdownOption | null, (timeSlot: TimeWindowDropdownOption | null) => void]} */
  const [chosenTimeSlot, setChosenTimeSlot] = useState(null);

  /** Availability as provided by the API */
  const availableTimes = useSelector(availabilityStateSelectorJs);

  /** Availability indexed by UTC Zulu time */
  const availabilityByDateTime = useSelector((state) =>
    state.getIn(['entities', 'availabilityByDateTime']),
  );

  const { orderId, orderToken, publicPage, order } = orderConfig;
  const entity = schedulingAction === SCHEDULING_ACTIONS.NEW_ORDER ? cart : order;
  const showSubmitButton = selectedTimes?.length >= 1;
  const isSubmitButonDisabled = isRecurringPanelOpen && !recurrence;

  /** Submit availability for new orders */
  const handleNewOrderAction = useCallback(
    () =>
      dispatch(
        submitAvailability({ selectedDateTimes: selectedTimes, skipMinCount: true, recurrence }),
      ),
    [dispatch, selectedTimes, recurrence],
  );

  /** Submit availability for rescheduling active orders */
  const handleRescheduleAction = useCallback(() => {
    const hasIdOrToken = Boolean(orderId) || Boolean(orderToken);
    if (!hasIdOrToken) return;

    // The recurrence is handled by a separate UI piece, so we don't need to pass it
    dispatch(submitSchedule({ ranges: selectedTimes, orderId, orderToken, publicPage }));
  }, [dispatch, selectedTimes, orderId, orderToken, publicPage]);

  const handleSubmitAvailability = useCallback(() => {
    if (schedulingAction === SCHEDULING_ACTIONS.NEW_ORDER) {
      handleNewOrderAction();
    } else if (schedulingAction === SCHEDULING_ACTIONS.RESCHEDULE_ORDER) {
      handleRescheduleAction();
    }
  }, [schedulingAction, handleNewOrderAction, handleRescheduleAction]);

  const timeOptions = useMemo(
    () =>
      createTimeWindowDropdownOptions({
        availableTimes: ensureImmutable(availableTimes),
        selectedDate,
      }),
    [availableTimes, selectedDate],
  );

  const handleRecurringPanelVisibilityChange = useCallback((isOpen) => {
    setIsRecurringPanelOpen(isOpen);
  }, []);

  /** Returns a list of `Date` objects that contain at least one valid time */
  const validDates = useMemo(() => {
    const validList = availableTimes
      .filter((dateObj) => {
        const numHoursInTimeWindow = TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES.GROUP_BY_HOURS;
        const windows = chunk(dateObj.hours, numHoursInTimeWindow);
        return windows.some((window) => window.every((hour) => hour.valid === 1));
      })
      .map((dateObj) => {
        return parseDateInTimeZone({
          date: dateObj.date,
          timezone: entity.timezone,
        });
      });
    return validList;
  }, [availableTimes, entity.timezone]);

  /** @param {Date} time  */
  const handleSelectDate = (date) => {
    if (!date) return;
    // To avoid working with time zones we're just going to set the date in YYYY-MM-DD format
    setSelectedDate(convertToIsoFormat(date));
  };

  const allowRecurringBooking = useMemo(() => {
    if (schedulingAction === SCHEDULING_ACTIONS.NEW_ORDER) {
      return cart?.allowRecurringBooking;
    }
    // Should only be allowed for new order flow
    return false;
  }, [schedulingAction, cart?.allowRecurringBooking]);

  const clearStoredTimeValues = useCallback(() => {
    if (!selectedTimes.length || !chosenTimeSlot) return;

    // Toggle hour false in Redux
    selectedTimes.forEach((time) => {
      const match = availabilityByDateTime[time];
      if (!match) return;
      dispatch(
        toggleHourByIndex({
          dateIndex: match.dateIndex,
          hourIndex: match.hourIndex,
          checked: false,
        }),
      );
    });

    // Empty selectedTimes
    selectedTimes.splice(0);
  }, [availabilityByDateTime, chosenTimeSlot, dispatch, selectedTimes]);

  const updateStoredTimeValues = useCallback(() => {
    if (!chosenTimeSlot) return;
    chosenTimeSlot.hours.forEach((hour) => {
      const match = availabilityByDateTime[hour.dateTime];
      if (!match) return;
      dispatch(
        toggleHourByIndex({
          dateIndex: match.dateIndex,
          hourIndex: match.hourIndex,
          checked: true,
        }),
      );
      updateSelectedDateTimes(selectedTimes, hour, true);
    });
  }, [availabilityByDateTime, chosenTimeSlot, dispatch, selectedTimes]);

  const createFormattedChosenTimeSlot = () => {
    if (!selectedTimes.length) return '';
    const sortedTimes = [...selectedTimes].sort();

    const tz = entity.timezone || DEFAULT_TIME_ZONE;
    const startDateTime = convertToTimeZone(sortedTimes[0], tz);
    let endDateTime = convertToTimeZone(sortedTimes[sortedTimes.length - 1], tz);

    if (endDateTime.hour() !== 20) {
      endDateTime = endDateTime.add(1, 'hour');
    }

    const startTimeFormatted = formatTimeForTimeWindowsDisplay(startDateTime.hour());
    const endTimeFormatted = formatTimeForTimeWindowsDisplay(endDateTime.hour());

    const formattedDate = startDateTime.format('dddd, MMM D');

    return `${formattedDate}, between ${startTimeFormatted} - ${endTimeFormatted}`;
  };

  useEffect(() => {
    if (chosenTimeSlot?.value) {
      clearStoredTimeValues();

      updateStoredTimeValues();
    }
  }, [chosenTimeSlot, clearStoredTimeValues, updateStoredTimeValues]);

  const previousSelectedDate = usePrevious(selectedDate);
  useEffect(() => {
    // Clear previously selected time slot if date changes
    if (selectedDate && selectedDate !== previousSelectedDate) {
      clearStoredTimeValues();
      setChosenTimeSlot(null);
    }
  }, [selectedDate, previousSelectedDate, clearStoredTimeValues]);

  // ############################
  // INITIALIZERS FOR SAVED DATA
  // ############################
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    // First effect to set the date
    const shouldSkip = !isEditMode || initialized || !selectedTimes.length;
    if (shouldSkip) return;

    const convertedDate = parseDateInTimeZone({
      date: selectedTimes[0],
      timezone: entity.timezone,
    });

    setSelectedDate(convertToIsoFormat(convertedDate));
  }, [isEditMode, selectedTimes, entity.timezone, initialized]);

  useEffect(() => {
    // Second effect to set the time slot once options are available
    const shouldSkip = !isEditMode || initialized || !timeOptions.length || !selectedTimes.length;
    if (shouldSkip) return;

    const matchingTimeSlot = timeOptions.find(
      (time) => time.dateTimeFirstHour === selectedTimes[0],
    );

    if (matchingTimeSlot) {
      setChosenTimeSlot(matchingTimeSlot);
      updateStoredTimeValues();
      setInitialized(true);
    }
  }, [isEditMode, selectedTimes, timeOptions, updateStoredTimeValues, initialized]);

  return {
    selectedDate,
    handleSelectDate,
    chosenTimeSlot,
    setChosenTimeSlot,
    timeOptions,
    validDates,
    handleSubmitAvailability,
    recurrence,
    setRecurrence,
    isEditMode,
    allowRecurringBooking,
    formattedChosenTimeSlot: createFormattedChosenTimeSlot(),
    showSubmitButton,
    isSubmitButonDisabled,
    handleRecurringPanelVisibilityChange,
  };
};

/** @typedef {import('src/types/service').TRecurrence} TRecurrence */
/** @typedef {import('src/containers/BookingPage/AvailabilityPage/AvailabilityPage.types.js').TimeWindowDropdownOption} TimeWindowDropdownOption */
