import { useEffect, useCallback, useState } from 'react';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import { useValidateEmailExternal } from 'src/hooks/useValidateEmailExternal';
import debounce from 'lodash/debounce';

const performEmailValidation = async ({
  formik,
  fieldName,
  email,
  suppressError,
  useValidationService,
  isSplitOn,
  onSuccess,
  validateEmail,
}) => {
  // Early exit if validation service is not used, regardless of the email presence
  if (!useValidationService) {
    if (onSuccess) onSuccess();
    return;
  }

  // Proceed only if there is an email to validate
  if (!email) return;

  // Proceed only if the split is on
  if (!isSplitOn) {
    if (onSuccess) onSuccess();
    return;
  }

  // Proceed only if the email contains an '@'. Prevents invalid emails from being validated
  if (!email.includes('@')) return;

  // Proceed with using the external validation service
  const response = await validateEmail(email);

  // Handle Network Error
  if (response.err) {
    formik.setFieldError(fieldName, 'An error occurred. Please try again later.');
    return;
  }

  // Handle Validation Error
  if (!response.valid) {
    if (response.did_you_mean) return; // Suggestion will always take precedence over error
    if (!suppressError) {
      formik.setFieldError(fieldName, 'Enter a valid email address');
    }
    return;
  }

  if (onSuccess) onSuccess();
};

/**
 * useExternalEmailCheck()
 *
 * Custom hook for validating emails with an external service and Formik. Updates Formik's field
 * errors based on external validation results.
 *
 * Note: The external service may suggest alternative emails that also fail validation.
 *
 * @param {object} formik - The Formik instance.
 * @param {string} fieldName - The field name to validate (e.g., 'email').
 * @param {Function} onSuccess - Callback after successful validation.
 * @param {boolean} useValidationService - Flag to use the external validation service.
 * @returns {object} - Contains 'validateEmailWithConfig', 'emailValidationState', 'useSuggestion', and 'handleOnChange'.
 *
 * Usage:
 * const { validateEmailWithConfig, emailValidationState, useSuggestion, handleOnChange } = useExternalEmailCheck({
 *   formik,
 *   fieldName: 'email',
 *   onSuccess: () => {...},
 *   useValidationService: someConditional ? true : false,
 * });
 */

export const useExternalEmailCheck = ({
  formik,
  fieldName,
  onSuccess = () => {},
  useValidationService = true,
  debounceDelay = 1000, // Default debounce delay in milliseconds
}) => {
  const { validateEmail, emailValidationState } = useValidateEmailExternal();

  const { splitTreatment } = useSplitIOTreatment(splitioConstants.SPLITIONAME_USER_EMAIL_VALIDATOR);
  const isSplitOn = splitTreatment === splitioConstants.ON;

  // Create a local copy of the email validation state that we can update
  const [localEmailValidationState, setLocalEmailValidationState] = useState({
    ...emailValidationState,
  });

  // Sync local state with external state
  useEffect(() => {
    setLocalEmailValidationState({ ...emailValidationState });
  }, [emailValidationState]);

  const sharedConfig = {
    formik,
    fieldName,
    useValidationService,
    isSplitOn,
    validateEmail,
  };
  /** Call validateEmail for manual submission. */
  const validateEmailWithConfig = async ({ email, suppressError = false }) => {
    await performEmailValidation({
      ...sharedConfig,
      email: email.trim(),
      suppressError,
      onSuccess,
    });
  };

  /** Call validateEmail for auto-submit. */
  const validateEmailWithConfigAutoSubmit = async ({ email, suppressError = true }) => {
    await performEmailValidation({
      ...sharedConfig,
      email: email.trim(),
      suppressError,
      onSuccess: null, // Don't call onSuccess for auto-submit
    });
  };

  /** A debounced version of validateEmailWithConfig used for auto-submit */
  const debouncedValidateEmailWithConfig = useCallback(
    debounce(({ email, suppressError }) => {
      validateEmailWithConfigAutoSubmit({ email, suppressError });
    }, debounceDelay),
    [debounceDelay, useValidationService, isSplitOn],
  );

  const useSuggestion = useCallback(() => {
    if (!localEmailValidationState?.did_you_mean) return;
    setLocalEmailValidationState({ ...localEmailValidationState, did_you_mean: null }); // clear did_you_mean
    formik.setFieldError(fieldName, ''); // clear error
    formik.setFieldValue(fieldName, localEmailValidationState.did_you_mean);
    validateEmailWithConfigAutoSubmit({
      email: localEmailValidationState.did_you_mean,
      suppressError: false,
    }); // Test validity again. Sometimes the services suggests an invalid email
  }, [localEmailValidationState?.did_you_mean, fieldName, formik]);

  const handleOnChange = (event) => {
    formik.setFieldError(fieldName, '');
    formik.handleChange(event);
    debouncedValidateEmailWithConfig({ email: event.target.value, suppressError: true });
  };

  useEffect(() => {
    return () => {
      debouncedValidateEmailWithConfig.cancel();
    };
  }, [debouncedValidateEmailWithConfig]);

  return {
    /** State of the last email validation call */
    emailValidationState: localEmailValidationState,
    /** Call the external validation service */
    validateEmailWithConfig,
    /** Populate the email form field with the suggested email */
    useSuggestion,
    /** Wrapper around formik.handleChange to call the validation service */
    handleOnChange,
  };
};
