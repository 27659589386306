import { ORDER_STATUS, JOB_PROGRESS } from 'src/constants/order';
import { LABEL, PANEL_TYPE } from './constants';

/* eslint-disable import/prefer-default-export */

export const getStatusInfo = ({ order }) => {
  if (!order) return {};

  const {
    statusId: orderStatusId,
    progressStatusId: jobProgressStatusId,
    atRisk,
    remote,
    standByTimes,
  } = order;
  const standByTimesLength = standByTimes ? standByTimes.length : 0;

  let label = LABEL.ERROR;
  let panel = PANEL_TYPE.ERROR;

  switch (orderStatusId) {
    case ORDER_STATUS.PENDING:
    case ORDER_STATUS.SEARCHING: {
      if (standByTimesLength) {
        label = LABEL.ACTION_REQUIRED;
        panel = PANEL_TYPE.STAND_BY;
        break;
      }
      if (atRisk) {
        label = LABEL.ACTION_REQUIRED;
        panel = PANEL_TYPE.RESCHEDULE;
        break;
      }
      if (remote) {
        label = LABEL.CONFIRMED;
        panel = PANEL_TYPE.CONFIRMED;
        break;
      }
      label = LABEL.PENDING;
      panel = PANEL_TYPE.PENDING;
      break;
    }
    case ORDER_STATUS.ASSIGNED: {
      switch (jobProgressStatusId) {
        case JOB_PROGRESS.CLAIMED: {
          label = LABEL.CONFIRMED;
          panel = PANEL_TYPE.CONFIRMED;
          break;
        }
        case JOB_PROGRESS.EN_ROUTE: {
          label = LABEL.EN_ROUTE;
          panel = PANEL_TYPE.EN_ROUTE;
          break;
        }
        case JOB_PROGRESS.STARTED: {
          label = LABEL.STARTED;
          panel = PANEL_TYPE.STARTED;
          break;
        }
        default: {
          break;
        }
      }
      break;
    }
    case ORDER_STATUS.COMPLETED:
    case ORDER_STATUS.TECH_PAID: {
      label = LABEL.COMPLETED;
      panel = PANEL_TYPE.COMPLETED;
      break;
    }
    case ORDER_STATUS.CANCELLED: {
      label = LABEL.CANCELLED;
      panel = PANEL_TYPE.CANCELLED;
      break;
    }
    case ORDER_STATUS.UNSERVICEABLE:
    case ORDER_STATUS.INCOMPLETE: {
      label = LABEL.UNSERVICEABLE;
      panel = PANEL_TYPE.UNSERVICEABLE;
      break;
    }
    case ORDER_STATUS.LATE_TECH: {
      label = LABEL.ACTION_REQUIRED;
      panel = PANEL_TYPE.TECH_LATE;
      break;
    }
    case ORDER_STATUS.PENDING_SHIPPING: {
      label = LABEL.PENDING_SHIPPING;
      panel = PANEL_TYPE.PENDING_SHIPPING;
      break;
    }
    default: {
      label = LABEL.ERROR;
      panel = PANEL_TYPE.ERROR;
      break;
    }
  }

  return { label, panel };
};
