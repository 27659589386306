import { takeLatest, takeEvery, select, put, call } from 'redux-saga/effects';
import moment from 'moment';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import Cookies from 'js-cookie';
import { htToast } from 'HTKit/Toast';
import { selectRoutes } from 'src/apiRoutes';
import { parseOptimizeCookieForExperiment } from 'src/utils/cookies/parseOptimizeCookieForExperiment';
import {
  pageLoadingError,
  pageLoaded,
  invalidForm,
  clearFormErrors,
  updateUser,
} from 'src/actions/common';
import { LOAD_PAGE } from 'src/constants/common';
import { NEED_AT_LEAST_ONE_VALID_DATE } from 'src/constants/cart';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import logger from 'src/utils/logger';
import { getTotalCreditAdjustments } from 'src/utils/cart';
import { loadProfileData } from 'src/components/Techs/data/actions';
import { CREDIT_LABEL } from 'src/constants/credit';
import { updateCart } from 'src/containers/AddSkuPage/actions';
import { goToStage } from 'src/containers/BookingPage/actions';
import { toggleModal } from 'src/containers/BookingPage/AvailabilityPage/actions';
import { setHideUpsellCookie } from 'src/containers/CartPage/utils';
import { push, cartPath, subscriptionConfirmationPath } from 'src/utils/paths';
import { getRedirectCookie, removeRedirectCookies } from 'src/utils/cookies';
import {
  getDirectBookingTechId,
  removeDirectBookingTechData,
} from 'src/utils/cookies/directBookingCookie';
import { irclickidSelector } from 'src/selectors/tracking';
import { cartSelector } from 'src/selectors/cart';
import { techProfileByIdJSSelector } from 'src/selectors/techs';
import {
  CONFIRM_BOOKING,
  APPLY_BONUS,
  REMOVE_ADJUSTMENT,
  REMOVE_ITEM,
  ADD_COUPON,
  REMOVE_PLAN_FROM_CART,
  PURCHASE_PLAN_ONLY,
} from './constants';
import {
  summarySubmitted,
  bonusEditMode,
  getSubscriptionDetails,
  showCreditAppliedToast,
} from './actions';
import { pageSelector } from './selectors';

function* getSubscriptionDetailsSaga() {
  const routes = yield call(selectRoutes);
  const plan = yield select((state) => state.getIn(['entities', 'cart', 'plan']));
  if (!plan) return;
  const response = yield call(routes.plans.subscriptions.show, { id: plan.get('id') });
  if (response.err) {
    logger.log(response.err);
  } else {
    const planDetails = response.data.plan;
    yield put(getSubscriptionDetails(planDetails));
  }
}

function* pageSaga() {
  const routes = yield call(selectRoutes);
  const requestResult = yield call(routes.cart.find, { breakdown: true });
  let cart;
  if (!requestResult.err) {
    cart = requestResult.data.cart;
  } else {
    yield put(pageLoadingError('summary', requestResult));
    return;
  }
  const loadProfile = yield loadUserProfileSaga('summary');
  if (!loadProfile) {
    return;
  }
  const directBookingTechId = getDirectBookingTechId();
  if (directBookingTechId) {
    const techData = yield select(techProfileByIdJSSelector(directBookingTechId));
    if (!techData) {
      const techResult = yield call(routes.techs.profile, { id: directBookingTechId });
      if (!techResult.err) {
        const {
          data: { tech: techDataResult },
        } = techResult;
        yield put(loadProfileData({ techId: directBookingTechId, data: techDataResult }));
      }
    }
  }
  yield getSubscriptionDetailsSaga();
  yield put(pageLoaded('summary', { cart }));

  // show toast if credit is available
  const pageState = yield select(pageSelector);
  if (pageState && !pageState.get('creditAppliedToastShown')) {
    const { amount: totalCredit, amountFormattedAbs } = getTotalCreditAdjustments(cart);
    if (totalCredit) {
      htToast(`${amountFormattedAbs} ${CREDIT_LABEL} applied to your cart!`);
      yield put(showCreditAppliedToast());
    }
  }
}

function* confirmBookingSaga({ affirmToken = '' } = {}) {
  const routes = yield call(selectRoutes);
  const cartData = yield select(cartSelector);
  const googleOptimize = parseOptimizeCookieForExperiment();
  const redirectCookie = getRedirectCookie();
  const irclickid = yield select(irclickidSelector);
  /* Only use if have values */
  const filterParams = pickBy({ affirm_token: affirmToken }, identity);
  yield put(requestStarted());
  const siteID = Cookies.get('siteID');
  const dte = Cookies.get('dte');
  const tag = {
    experiments: merge({}, googleOptimize, redirectCookie),
    ...filterParams,
  };

  if (
    siteID &&
    dte &&
    moment()
      .subtract(30, 'days')
      .isBefore(new Date(dte))
  ) {
    tag.siteID = siteID;
    tag.dte = dte;
  }

  if (irclickid) {
    tag.irclickid = irclickid;
  }

  const partnerLanding = yield select((state) => state.getIn(['entities', 'currentLanding'], null));
  if (partnerLanding && partnerLanding.get('landingId')) {
    tag.partnerLandingId = partnerLanding.get('landingId');
  }

  const directBookingTechId = getDirectBookingTechId();
  if (directBookingTechId && !cartData.get('remote')) {
    tag.direct_booking = true;
    tag.preferred_tech_id = directBookingTechId;
  }

  const response = yield call(routes.cart.completeBooking, tag);
  yield put(requestFinished());
  if (response.err) {
    const errors = response.data.errors || [];
    const errMsg = errors[0];
    if (errMsg === NEED_AT_LEAST_ONE_VALID_DATE) {
      yield put(goToStage('availability'));
      yield put(toggleModal({ modalName: 'invalidDates' }));
    } else {
      yield put(displayErrors(response));
    }
  } else {
    removeRedirectCookies();
    removeDirectBookingTechData();
    const {
      cart,
      id,
      amount,
      services,
      discount,
      coupon,
      user,
      geek_cut: geekCut = 0,
      partnerObject,
    } = response.data;
    const tax = yield select((state) => state.getIn(['entities', 'cart', 'breakdown', 'tax'], 0));
    const stateCart = yield select((state) => state.getIn(['entities', 'cart'], {}));
    /* BE getting partner in cart at this point is not easy, so just do it here */
    if (partnerObject) {
      cart.partner = partnerObject;
    }
    yield put(updateCart({ cart, replace: true }));
    yield put(
      summarySubmitted({
        id,
        amount,
        services,
        tax,
        discount,
        coupon,
        stateCart,
        geekCut,
      }),
    );

    yield put(updateUser(user));
  }
}

function* applyBonusSaga({ amount }) {
  const routes = yield call(selectRoutes);

  yield put(requestStarted());
  const response = yield call(routes.cart.applyBonus, { amount: amount * 100 });
  yield put(requestFinished());
  if (response.err) {
    yield put(displayErrors(response));
  } else {
    yield put(updateCart({ cart: response.data.cart }));
    yield put(bonusEditMode(false));
  }
}

function* removeAdjustmentSaga({ adjustmentType }) {
  const routes = yield call(selectRoutes);

  yield put(requestStarted());
  const response = yield call(routes.cart.removeAdjustment, { type: adjustmentType });
  yield put(requestFinished());
  if (response.err) {
    yield put(displayErrors(response));
  } else {
    yield put(updateCart({ cart: response.data.cart }));
  }
}

function* couponSaga({ code }) {
  const noTextError = { coupon: 'Sorry, that promo code is not valid.' };

  if (code.length === 0) {
    yield put(invalidForm('summary', noTextError));
    return;
  }

  const routes = yield call(selectRoutes);
  yield put(clearFormErrors('summary'));
  yield put(requestStarted());
  const response = yield call(routes.cart.addCoupon, { code });
  yield put(requestFinished());
  if (response.err) {
    const { status } = response.err.response;
    if (status === 422) {
      const { formErrors } = response.data;
      yield put(invalidForm('summary', formErrors));
    } else {
      yield put(displayErrors(response));
    }
  } else {
    const { cart } = response.data;
    yield put(updateCart({ cart }));
  }
}

function* itemSaga({ itemIndex }) {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.cart.removeItem, { itemIndex });
  yield put(requestFinished());
  if (response.err) {
    yield put(displayErrors(response));
  } else {
    const { cart } = response.data;
    yield put(updateCart({ cart }));
    if (cart.items.length === 0) {
      yield put(push(cartPath));
    }
  }
}

function* removePlanFromCart() {
  const routes = yield call(selectRoutes);
  const plan = yield select((state) => state.getIn(['entities', 'cart', 'plan']));
  if (!plan) return;
  const response = yield call(routes.cart.removePlan);
  if (response.err) {
    displayErrors(response);
  } else {
    const { cart } = response.data;
    yield put(updateCart({ cart }));
    setHideUpsellCookie();
    if (cart.items.length === 0) {
      yield put(push(cartPath));
    }
  }
}

function* purchasePlanOnly() {
  yield put(requestStarted());
  const routes = yield call(selectRoutes);
  const planId = yield select((state) => state.getIn(['entities', 'cart', 'plan', 'id']));
  const irclickid = yield select(irclickidSelector);

  const response = yield call(routes.cart.completeBooking, { planId, irclickid });
  const {
    data: { cart, user },
    err,
  } = response;
  if (err) {
    displayErrors(response);
  } else {
    yield put(updateCart({ cart, replace: true }));
    yield put(updateUser(user));
    yield put(push(subscriptionConfirmationPath));
    removeDirectBookingTechData();
  }
  yield put(requestFinished());
}

function* pageFlow() {
  yield takeLatest((action) => action.type === LOAD_PAGE && action.page === 'summary', pageSaga);
  yield takeLatest(CONFIRM_BOOKING, confirmBookingSaga);
  yield takeLatest(APPLY_BONUS, applyBonusSaga);
  yield takeEvery(REMOVE_ADJUSTMENT, removeAdjustmentSaga);
  yield takeEvery(REMOVE_ITEM, itemSaga);
  yield takeEvery(ADD_COUPON, couponSaga);
  yield takeEvery(REMOVE_PLAN_FROM_CART, removePlanFromCart);
  yield takeEvery(PURCHASE_PLAN_ONLY, purchasePlanOnly);
}

export default [pageFlow];
