import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { THEMES } from 'src/components/HTKit/Forms/Button';
import ReviewCount from 'src/components/HTKit/ReviewCount';
import BookNowButton from 'src/components/BookNowButton';
import StartsAtPrice from 'src/components/StartsAtPrice';
import { MembersPrice } from 'src/containers/SkuPage/Parts';
import styles from './styles.scss';

const TitleAndCTADesktop = ({
  title,
  subtitle,
  sku,
  onReviewClick,
  fetchPlanAndOpenModal
}) => {
  const headerStyles = cn(styles.mainTitleContainer, {
    [styles.mainTitleContainerWithInfo]: !!subtitle,
  });

  return (
    <section className={headerStyles}>
      <div className={styles.titleColumn}>
        <h1 className="h3">
          {title}
        </h1>
        {subtitle && (
          <div className="h4 marginTop-tiny1">{subtitle}</div>
        )}
        {sku.getIn(['ratingAttributes', 'ratingCount']) > 0 && (
          <a
            href=""
            onClick={onReviewClick}
            className={styles.reviewCount}
          >
            <ReviewCount
              reviews={sku.getIn(['ratingAttributes', 'ratingCount'])}
              stars={sku.getIn(['ratingAttributes', 'rating'])}
              inline
              withHover
              keyStr="desktop"
            />
          </a>
        )}
      </div>
      <div className={styles.ctaColumn}>
        <div className={styles.priceContainer}>
          {sku.get('showFree') && (<h2>FREE</h2>)}
          {!sku.get('showFree') && (
            <div className={styles.startsAtWrapper}>
              <h3>
                <span className={styles.startsAt}>
                  STARTS
                  <br />
                  AT ONLY
                </span>
                <StartsAtPrice sku={sku} showSubscriptionPrice />
              </h3>
              <MembersPrice
                sku={sku}
                buttonStyle={styles.memberPrice}
                onClick={fetchPlanAndOpenModal}
              />
            </div>
          )}
        </div>
        <div className={styles.ctaButtonWrapper}>
          <BookNowButton skuId={sku.get('id')} mediumV2 theme={THEMES.V2SCIENCEBLUE} />
        </div>
      </div>
    </section>
  );
}

TitleAndCTADesktop.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sku: PropTypes.object.isRequired,
  onReviewClick: PropTypes.func.isRequired,
  fetchPlanAndOpenModal: PropTypes.func.isRequired,
};

TitleAndCTADesktop.defaultProps = {
  title: '',
  subtitle: null,
};

export default TitleAndCTADesktop;
