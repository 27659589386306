import { fromJS } from 'immutable';
import { PAGE_LOADED } from '../../constants/common';
import { FORM_SUBMITTED, CUSTOMER_SURVEY_PAGE_NAME } from './constants';

const initialState = fromJS({ order: null });

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case PAGE_LOADED:
      if (action.page === CUSTOMER_SURVEY_PAGE_NAME) {
        return state.merge({ order: action.order });
      }
      return state;
    case FORM_SUBMITTED:
      return state.merge({
        formSubmitted: action.payload.formSubmitted,
        techFirstName: action.payload.techFirstName,
        clientFirstName: action.payload.clientFirstName,
        ratingComment: action.payload.ratingComment,
        yelpLink: action.payload.yelpLink,
        order: action.payload.order,
      });
    default:
      return state;
  }
}

export default pageReducer;
