import _ from 'lodash';

import {
  STAGE_LOADED,
  GO_TO_STAGE,
  TOGGLE_SAME_DAY_SERVICE_INFO,
  CREATE_PASSWORDLESS_ACCOUNT,
} from './constants';

export function stageLoaded(stage) {
  return {
    type: STAGE_LOADED,
    stage,
  };
}

export function goToStage(stage, hard = false, params) {
  return {
    type: GO_TO_STAGE,
    stage: _.last(stage.split('/')),
    hard,
    params,
  };
}

// no longer being used but retaining this action for historical purposes
export function toggleSameDayServiceInfo() {
  return {
    type: TOGGLE_SAME_DAY_SERVICE_INFO,
  };
}

export function createPasswordlessAccount(payload) {
  return {
    type: CREATE_PASSWORDLESS_ACCOUNT,
    payload,
  };
}
