import chatImage from 'src/images/icons/remote-chat.svg';

export const PAGE_NAME = 'summaryPage';
export const SUMMARY_PAGE_NAME_SHORT = 'summary';
export const CONFIRM_BOOKING = 'summaryPage/CONFIRM_BOOKING';
export const SUMMARY_SUBMITTED = 'summaryPage/SUMMARY_SUBMITTED';
export const APPLY_BONUS = 'summaryPage/APPLY_BONUS';
export const BONUS_EDIT_MODE = 'summaryPage/BONUS_EDIT_MODE';
export const REMOVE_ADJUSTMENT = 'summaryPage/REMOVE_ADJUSTMENT';
export const REMOVE_ITEM = 'summaryPage/REMOVE_ITEM';
export const ADD_COUPON = 'summaryPage/ADD_COUPON';
export const GET_SUBSCRIPTION_DETAILS = 'summaryPage/GET_SUBSCRIPTION_DETAILS';
export const REMOVE_PLAN_FROM_CART = 'summaryPage/REMOVE_PLAN_FROM_CART';
export const PURCHASE_PLAN_ONLY = 'summaryPage/PURCHASE_PLAN_ONLY';
export const CREDIT_APPLIED_TOAST_SHOWN = 'summaryPage/CREDIT_APPLIED_TOAST_SHOWN';
export const UPDATE_LOW_TIER_CONFIG = 'summaryPage/UPDATE_LOW_TIER_CONFIG';

export const VIRTUAL_SERVICE_HEADER = 'About your Virtual Service';
export const VIRTUAL_SERVICE_NOTICE =
  'You can initiate your virtual service anytime you prefer. Our virtual technicians are professionally trained, background-checked, and motivated to provide you with outstanding customer service.\n\nIf you’re not 100% happy, we’ll do whatever it takes to make it right.';
export const VIRTUAL_SERVICE_HEADER_IMAGE = {
  imgSrc: chatImage,
  altText: 'chat',
};

export const MAX_AVAILBILITY_DATES_TO_SHOW = 3;
