import React from 'react';
import PropTypes from 'prop-types';

const STAR_PATH = 'M99.41 37.77c-.868-2.496-3.068-4.32-5.74-4.716l-25.885-3.85L56.538 5.788c-1.185-2.466-3.72-4.032-6.498-4.032-2.795 0-5.328 1.566-6.514 4.032l-11.25 23.418-25.885 3.85c-2.653.396-4.872 2.22-5.735 4.716-.85 2.498-.198 5.252 1.667 7.12L21.3 63.866l-4.404 26.34c-.44 2.665.7 5.31 2.962 6.864 1.232.852 2.674 1.292 4.13 1.292 1.198 0 2.4-.288 3.475-.884L50.04 85.305l22.572 12.173c1.08.596 2.277.884 3.463.884 1.457 0 2.915-.44 4.143-1.293 2.25-1.555 3.39-4.2 2.946-6.865l-4.404-26.34L97.738 44.89c1.885-1.884 2.522-4.622 1.67-7.12z';

const HOVER_COLOR = '#32A4EE'; // ht-blue

const Star = (props) => {
  const { emptyColor, size, keyStr, fillPercentage, hover } = props;
  const fillColor = hover ? HOVER_COLOR : props.fillColor;

  if (fillPercentage === 100 || fillPercentage === 0) {
    return (
      <svg width={`${size}px`} height={`${size}px`} viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg" onClick={props.onClick}>
        <g transform={`scale(${size / 100})`}>
          <path fill={fillPercentage === 100 ? fillColor : emptyColor} d={STAR_PATH} />
        </g>
      </svg>
    );
  }

  const fillPercentageFixed = fillPercentage.toFixed(2);
  const idHash = `ht-star-${fillPercentageFixed}${keyStr ? `-${keyStr}-${hover}` : ''}`;

  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg" onClick={props.onClick}>
      <defs>
        <linearGradient id={idHash}>
          <stop offset="0%" stopColor={fillColor} />
          <stop offset={`${fillPercentage}%`} stopColor={fillColor} />
          <stop offset={`${fillPercentage}%`} stopColor={emptyColor} />
          <stop offset="100%" stopColor={emptyColor} />
        </linearGradient>
      </defs>
      <g transform={`scale(${size / 100})`}>
        <path fill={`url(#${idHash})`} d={STAR_PATH} />
      </g>
    </svg>
  );
};

Star.propTypes = {
  fillColor: PropTypes.string,
  emptyColor: PropTypes.string,
  fillPercentage: PropTypes.number,
  size: PropTypes.number,
  hover: PropTypes.bool,
  keyStr: PropTypes.string,
  onClick: PropTypes.func,
};

Star.defaultProps = {
  fillColor: '#f67d5b',
  emptyColor: '#b3b3b3',
  fillPercentage: 100,
  hover: false,
  size: 25,
};

export default Star;

/*
  Star:
    Used to render a single star.  Can render a partial star (for ratings).

  Properties:
    fillColor:
      type: String
      description: The hex number of the color to fill the star.  Default color is ht-red-orange (#f67d5b).
    emptyColor:
      type: String
      description: The hex number of color to fill the empty portion of the star.  Default color is $ht-cool-grey-tint-l2 (#b3b3b3).
    fillPercentage:
      type: Number
      description: The percentage of the star to fill
    size:
      type: Number
      description: A number used to scale the size of the star in pixels.
    hover:
      type: boolean
      description: if component has hover state
    keyStr:
      type: String
      description: string to create a unique id
*/
