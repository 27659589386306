import Cookies from 'js-cookie';
import {
  COOKIES,
  SKU_IDS_THAT_REQUIRE_PINS,
  PARTNER_NAMES_THAT_REQUIRE_PINS,
} from 'src/containers/FinishOrder/PINRedemption/constants';
import HT_INFO from 'src/constants/hellotech';

const { VERIFIED_PIN_DATA } = COOKIES;

export const isPinRequiredToAddSkuId = (skuId) => SKU_IDS_THAT_REQUIRE_PINS.includes(skuId);
export const isPinRequiredForPartnerSku = (partnerName) =>
  PARTNER_NAMES_THAT_REQUIRE_PINS.includes(partnerName);

export const setCookiesPinData = (cardData) => {
  /*
    The verified PIN data is being set in Redux after validation, but Redux values do not persist through a
    browser refresh. To avoid losing this data we will set a cookie as a backup.
  */
  if (!cardData) return;
  Cookies.set(VERIFIED_PIN_DATA, cardData);
};

export const getCookiesPinData = (skuId) => {
  const cardData = Cookies.get(VERIFIED_PIN_DATA) ? JSON.parse(Cookies.get(VERIFIED_PIN_DATA)) : {};
  return skuId === cardData.skuId ? cardData : null;
};

export const deleteCookiesPinData = () => Cookies.remove(VERIFIED_PIN_DATA);

export const getPartnerPhone = (partner) => {
  const {
    phone: { partners: partnerPhones },
  } = HT_INFO;

  if (!partner || !partnerPhones[partner] || !partnerPhones[partner].pin_redemption)
    return { link: '', title: '' };

  return partnerPhones[partner].pin_redemption;
};
