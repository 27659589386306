import { useState, useCallback } from 'react';
import { logger } from 'src/utils/logger';
import useAPI from './useAPI';

/**
 * useValidateEmailExternal()
 *
 * This custom hook is used for validating email addresses through a third-party API (Emailable).
 * It provides functionality to send a request to the API and manage the response.
 *
 * Example:
 * const { validateEmail, emailValidation } = useValidateEmailExternal();
 * validateEmail('test@example.com');
 */
export const useValidateEmailExternal = () => {
  const api = useAPI();
  const [error, setError] = useState(null);
  const [emailValidationState, setEmailValidationState] = useState({
    valid: null,
    did_you_mean: null,
    email: '',
  });

  const resetState = () => {
    setError(null);
    setEmailValidationState({
      valid: null,
      did_you_mean: null,
      email: '',
    });
  };
  const validateEmail = useCallback(async (email) => {
    resetState();
    try {
      const response = await api.users.validateEmailExternal({ email });
      if (response.err) {
        throw new Error(response.err);
      }
      const { data } = response;
      setEmailValidationState(data);
      return data;
    } catch (err) {
      logger('useValidateEmailExternal - validateEmail()')(err);
      setEmailValidationState({
        valid: null,
        did_you_mean: null,
        email: '',
      });
      const errDetails = { err: err.message };
      setError(errDetails);
      return errDetails;
    }
  }, []);

  return {
    validateEmail,
    error,
    emailValidationState,
  };
};
