import { isImmutable } from 'src/utils/helpers';
import {
  PAYMENT_EDIT_MODE,
  SUBMIT_PAYMENT,
  SUBMIT_EXISTING_PAYMENT,
  PAYMENT_SUBMITTED,
  SUBSCRIPTION_LOADED,
  SUBMIT_PAYMENT_TYPE,
} from './constants';

function genericPaymentSegment({id, action = 'Entered', paymentType = ''}) {
  const appendPaymentType = paymentType ? ` ${paymentType}` : '';

  return {
    analytics: [
      {
        eventType: 'track',
        eventPayload: {
          event: `Payment Info Entered${appendPaymentType}`,
          properties: {
            cart_id: id,
            step: 'Payment',
            action
          },
        },
      },
    ]
  }
}
export function paymentEditMode(editMode) {
  return {
    type: PAYMENT_EDIT_MODE,
    editMode,
  };
}

export function submitPaymentType(paymentType) {
  return {
    type: SUBMIT_PAYMENT_TYPE,
    paymentType
  };
}

export function submitPayment(token, { canOptIn, willOptIn }, _cart) {
  const cart = isImmutable(_cart) ? _cart.toJS() : _cart || {};

  return {
    type: SUBMIT_PAYMENT,
    token,
    canOptIn,
    willOptIn,
    meta: { ...genericPaymentSegment({id: cart.id}) }
  };
}

export function submitExistingPayment(_cart) {
  const cart = isImmutable(_cart) ? _cart.toJS() : _cart || {};

  return {
    type: SUBMIT_EXISTING_PAYMENT,
    meta: { ...genericPaymentSegment({ id: cart.id, action: 'Existing' }) }
  };
}

export function paymentSubmitted({ updated = false, id, paymentType = '' }) {
  const action = updated ? 'Updated' : 'Entered';

  return {
    type: PAYMENT_SUBMITTED,
    updated,
    meta: { ...genericPaymentSegment({ id, action, paymentType }) }
  };
}

export const subscriptionLoaded = ({ subscription }) => ({
  type: SUBSCRIPTION_LOADED,
  subscription,
});
