import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.scss';

const Loader = ({ className }) => (
  <div className={cn(className, styles.loader)}>
    <span className={styles.logo}>
      <svg
        id="Group_1157"
        data-name="Group 1157"
        xmlns="http://www.w3.org/2000/svg"
        width="39.689"
        height="38.184"
        viewBox="0 0 39.689 38.184"
      >
        <path
          id="Path_461"
          data-name="Path 461"
          d="M114.558,79.333,96.89,64.184a1.911,1.911,0,0,0-1.314-.584,2.018,2.018,0,0,0-1.387.584l-17.7,15.149a2.157,2.157,0,0,0,1.533,3.833h1.9v17.449a1.132,1.132,0,0,0,2.263,0V80.867h-3.8L95.5,66.228,112.623,80.9h-3.8V99.52H96.634V94.848h-2.3v5.8a1.137,1.137,0,0,0,1.132,1.132h14.492a1.137,1.137,0,0,0,1.132-1.132V83.166h1.862a2.277,2.277,0,0,0,2.227-1.351A2.1,2.1,0,0,0,114.558,79.333Z"
          transform="translate(-75.669 -63.6)"
          fill="#263746"
        />
        <path
          id="Path_462"
          data-name="Path 462"
          d="M116.055,138.628a4.785,4.785,0,0,1-4.855-4.855V131.4h13.689v2.373a5.037,5.037,0,0,1-5.147,4.855Z"
          transform="translate(-98.23 -106.65)"
          fill="#263746"
        />
        <path
          id="Path_463"
          data-name="Path 463"
          d="M118.412,104.906a1.757,1.757,0,0,0-1.606-1.606,1.726,1.726,0,0,0-1.606,1.606h0v6.133h3.249l-.037-6.133Z"
          transform="translate(-100.77 -88.808)"
          fill="#263746"
        />
        <path
          id="Path_464"
          data-name="Path 464"
          d="M138.212,104.906a1.757,1.757,0,0,0-1.606-1.606A1.726,1.726,0,0,0,135,104.906h0v6.133h3.249l-.036-6.133Z"
          transform="translate(-113.342 -88.808)"
          fill="#263746"
        />
      </svg>
    </span>
    <span>L</span>
    <span>O</span>
    <span>A</span>
    <span>D</span>
    <span>I</span>
    <span>N</span>
    <span>G</span>
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
};

Loader.defaultProps = {
  className: null,
};

export default Loader;
