import { call, put, takeEvery, select } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import { invalidForm, clearFormErrors } from 'src/actions/common';
import { selectRoutes } from 'src/apiRoutes';
import { LOAD_PAGE } from 'src/constants/common';
import { push, accountPath } from 'src/utils/paths';
import { LEAD_GEN_SOURCE_TYPES } from 'src/constants/tracking';
import { setLeadGeneratedCookie } from 'src/utils/cookies/leadGenerated';
import { REGISTRATION_REQUEST, PAGE_NAME } from './constants';
import { updateAuth, userLoaded } from '../AppBase/actions';
import { userSignedUp } from './actions';

export function* registrationSaga({ payload }) {
  const routes = yield call(selectRoutes);
  yield put(clearFormErrors('registration'));
  yield put(requestStarted());
  const response = yield call(routes.users.registration, { user: payload, sync_tasks: true });
  yield put(requestFinished());
  if (response.err) {
    const { status } = response.err.response;
    if (status === 422) {
      const { formErrors } = response.data;
      yield put(invalidForm('registration', formErrors));
    } else {
      yield put(displayErrors(response));
    }
  } else {
    const { user } = response.data;
    const { authToken } = user;
    Cookies.set('new_user', true);

    yield put(updateAuth({ authToken }));
    yield put(userLoaded(user));
    yield put(userSignedUp({ user, attributes: payload })); // Tracking event
    yield call(routes.tracking.leadGeneration, {
      email: user.email,
      source_name: LEAD_GEN_SOURCE_TYPES.registration.name,
      source_type: LEAD_GEN_SOURCE_TYPES.registration.type,
    });
    /* Set cookie */
    setLeadGeneratedCookie({ email: user.email });

    yield put(push(accountPath));
  }
}

function* loadPage() {
  const user = yield select((state) => state.get('user'));
  const clientEmail = yield select((state) => state.getIn(['entities', 'clientEmail']));
  if (user && clientEmail) {
    yield put(push(accountPath));
  }
}

export function* registrationFlow() {
  yield takeEvery(REGISTRATION_REQUEST, registrationSaga);
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, loadPage);
}

export default [registrationFlow];
