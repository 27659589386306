import { createSelector } from 'reselect';
import { cartSelector } from 'src/selectors/cart';

export const getUserAccountAttribute = (attribute) => (user) =>
  user && user.getIn(['account', attribute]);

export const userStateSelector = (state) => state.get('user');

export const userSelector = createSelector(userStateSelector, (user) => user);

export const pureUserSelector = createSelector(userStateSelector, (user) => {
  if (!user) return null;
  return user.toJS();
});

// has subscription or legacy plan
export const userPlanHolderSelector = createSelector(
  userSelector,
  getUserAccountAttribute('planHolder'),
);

export const userSubscriptionSelector = createSelector(
  userSelector,
  getUserAccountAttribute('hasSubscription'),
);

export const userLegacyPlanHolderSelector = createSelector(
  userSelector,
  getUserAccountAttribute('plan'),
);

export const userPlanIdSelector = createSelector([cartSelector, userSelector], (cart, user) => {
  const userSubscriptionId = user && user.getIn(['account', 'subscription', 'planId'], null);
  const cartPlanId = cart && cart.getIn(['plan', 'id'], null);
  return userSubscriptionId || cartPlanId;
});

export const userSubscriptionDetailsSelector = createSelector(userSelector, (user) => {
  if (!user || !user.getIn(['account', 'subscription'])) return null;
  return user.getIn(['account', 'subscription']).toJS();
});

/**
 * This is needed because a fresh user AND a not-authorized user are the same.
 * We don't have any indication whether the current user is "new" or "not authorized". They are both null.
 * We have to know if we've tried to get the user from the server.
 * @type {OutputSelector<unknown, *, (res: *) => *>}
 */
export const isUserPristineSelector = createSelector(
(state) => state.get('isUserPristine'),
  (isUserPristine) => isUserPristine,
);
