import { createSelector } from 'reselect';

export const ordersListStateSelector = (state) => state.getIn(['entities', 'orders', 'list']);

export const orderIdPropsSelector = (propName = 'orderId') => (_, props) => {
  const orderId = props.match.params[propName];
  const numOrderId = parseInt(orderId, 10);
  return !numOrderId || Number.isNaN(numOrderId) ? null : numOrderId;
};

export const ordersListSelector = createSelector(
  ordersListStateSelector,
  (ordersList) => ordersList,
);
