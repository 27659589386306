import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import FirstServiceDiscountDisclaimer from '../FirstServiceDiscountDisclaimer';
import FirstServiceDiscountCode from '../FirstServiceDiscountCode';
import styles from './styles.scss';

const FirstServiceDiscountThankYou = ({ code }) => (
  <div className={styles.firstServiceDiscountThankYou}>
    <div className={styles.firstServiceDiscountThankYouTitle}>Thank You!</div>
    <div className="h4">Book now and save instantly.</div>
    <p className={cn('p1', styles.firstServiceDiscountThankYouPromo)}>
      Use the promo code below to receive $10 off your first order. We&apos;ll also send you an
      email in case you decide to use it later.
    </p>
    <FirstServiceDiscountCode code={code} />
    <FirstServiceDiscountDisclaimer />
  </div>
);

FirstServiceDiscountThankYou.propTypes = {
  code: PropTypes.string.isRequired,
  onLinkClick: PropTypes.func.isRequired,
};

export default React.memo(FirstServiceDiscountThankYou);
