import moment from 'moment';
import 'moment-timezone';
import {
  formatTimeSpanDisplay,
  normalizeAvailabilityForWindowBasedView,
} from 'src/components/AvailabilitySelectorWithConfig/Scheduling.utils';
import { TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES } from 'src/components/AvailabilitySelectorWithConfig/Scheduling.constants';

/**
 * Converts Date object to YYYY-MM-DD
 * @param {Date} date
 * @returns {string}
 */
export const convertToIsoFormat = (date) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
    date.getDate(),
  ).padStart(2, '0')}`;
};

/**
 * Converts an ISO date string to a specific timezone
 * @param {UTCDateTime} isoString - The ISO 8601 date string to convert
 * @param {IANATimezone} timeZone - The IANA timezone to convert to
 * @returns {moment.Moment} A moment object in the specified timezone
 */
export const convertToTimeZone = (isoString, timeZone) => {
  const tmz = timeZone || 'America/Los_Angeles';
  return moment(isoString).tz(tmz);
};

/**
 * Workaround for timezone issues
 * @param {{date: Date|string, timezone: string}} params
 */
export const parseDateInTimeZone = ({ date, timezone }) => {
  const entityOffset = moment.tz(date, timezone).utcOffset();
  // Get local timezone offset
  const localOffset = moment().utcOffset();
  // Adjust for the difference
  const offsetDiff = entityOffset - localOffset;

  const momentDate = moment.tz(date, timezone).add(offsetDiff, 'minutes');

  return momentDate.toDate();
};

/**
 * Takes a base recurrence pattern (e.g. "Every 1st Friday of the month") and optionally adds a prefix.
 * Used to flexibly format recurrence display text with different prefixes (typically "Repeats").
 * Example: formatRecurrenceText({recurrenceText: "every Tuesday", prefix: "Repeats"}) -> "Repeats every Tuesday"
 */
export function formatRecurrenceText({ recurrenceText, prefix, options }) {
  if (!recurrenceText) return '';

  let transformedRecurrenceText = recurrenceText;
  if (options?.downcaseRecurrence) {
    transformedRecurrenceText = `${recurrenceText.charAt(0).toLowerCase()}${recurrenceText.slice(
      1,
    )}`;
  }

  if (!prefix) return transformedRecurrenceText;
  return `${prefix} ${transformedRecurrenceText}`;
}

// ############################################
// EXACT TIME (SINGLE TIME SLOT) SELECTOR UTILS
// ############################################
/**
 * @param {Object} params
 * @param {AvailableTime[]} params.availableTimes
 * @param {string|null} params.selectedDate - Date in YYYY-MM-DD format
 * @returns {Array<ExactTimeDropdownOption>}
 */
export const createTimeDropdownOptions = ({ availableTimes, selectedDate }) => {
  if (!selectedDate) return [];

  const selectedDayData = availableTimes.find((day) => day.date === selectedDate);
  if (!selectedDayData) return [];

  const formatHourLabel = (hour) => {
    const period = hour.hour >= 12 ? 'PM' : 'AM';
    const displayHour = hour.hour % 12 || 12; // Convert 0 to 12 for 12 AM
    const displayMinute = hour.minute.toString().padStart(2, '0');
    return `${displayHour}:${displayMinute} ${period}`;
  };

  const options = selectedDayData.hours.reduce((acc, hour) => {
    if (hour.valid) {
      acc.push({
        value: hour.dateTime,
        label: formatHourLabel(hour),
        date: selectedDate,
        hour,
      });
    }
    return acc;
  }, []);

  return options;
};

// ###############################################
// TIME WINDOW - SELECT DROPDOWN - SELECTOR UTILS
// ###############################################

/**
 * Creates dropdown options for time windows based on available times and selected date
 * @param {Object} params
 * @param {AvailableTime[]} params.availableTimes
 * @param {string|null} params.selectedDate - Date in YYYY-MM-DD format
 * @returns {Array<TimeWindowDropdownOption>}
 */
export const createTimeWindowDropdownOptions = ({ availableTimes, selectedDate }) => {
  if (!selectedDate) return [];

  /**
   * Get normalized availability data split into time windows
   * @type {TimeWindow[]}
   */
  const timeWindows = normalizeAvailabilityForWindowBasedView({
    availability: availableTimes,
    config: TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES,
  });

  const selectedDayWindows = timeWindows.find((d) => d.date === selectedDate);
  if (!selectedDayWindows) return [];

  const validTimeWindows = selectedDayWindows.hours.filter((group) => {
    return group.every((hour) => hour.valid);
  });
  if (validTimeWindows.length === 0) return [];

  return validTimeWindows.map((window, i) => ({
    value: i + 1,
    label: formatTimeSpanDisplay(window),
    date: selectedDate,
    dateTimeFirstHour: window[0].dateTime,
    hours: window,
  }));
};

/** @typedef {import('src/types/availability').AvailableTime} AvailableTime */
/** @typedef {import('src/types/availability').AvailableTimeImmutable} AvailableTimeImmutable */
/** @typedef {import('src/types/availability').UTCDateTime} UTCDateTime */
/** @typedef {import('src/types/availability').IANATimezone} IANATimezone */
/** @typedef {import('src/types/availability').HourData} HourData */
/** @typedef {import('src/containers/BookingPage/AvailabilityPage/AvailabilityPage.types.js').ExactTimeDropdownOption} ExactTimeDropdownOption */
/** @typedef {import('src/containers/BookingPage/AvailabilityPage/AvailabilityPage.types.js').TimeWindowDropdownOption} TimeWindowDropdownOption */

/**
 * @typedef {Object} TimeWindow
 * @property {string} date - ISO formatted date string
 * @property {Array<Array<HourData>>} hours - Array of hour groups, where each group is an array of hourly time slots
 */
