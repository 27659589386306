// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Element, scroller } from 'react-scroll';
// Data
import { dateHasSelectedHours, formatDate } from './utils';
import { SCREEN_WIDTHS } from './constants';
// Components
import MobileHourBlock from './MobileHourBlock';
// Styles
import styles from './styles.scss';

class MobileSelector extends Component {
  static propTypes = {
    availability: PropTypes.object.isRequired,
    toggleHour: PropTypes.func.isRequired,
  };

  dateToView = null;

  componentWillMount() {
    const { availability } = this.props;
    availability.forEach((dateData) => {
      if (this.dateToView) return;
      dateData.get('hours').forEach((hourData) => {
        if (hourData.get('valid')) {
          this.dateToView = dateData.get('date');
        }
      });
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.scrollToFirstAvailableDate);
    this.scrollToFirstAvailableDate();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.scrollToFirstAvailableDate);
  }

  scrollToFirstAvailableDate = () => {
    if (window.innerWidth > SCREEN_WIDTHS.tablet) return;
    scroller.scrollTo(this.dateToView, {
      offset: -120, // account for header height and availability header
      delay: 100,
      smooth: true,
    })
};

  render() {
    const { availability, toggleHour } = this.props;
    const mobileContainerStyles = classNames(styles.mobile, 'site-v2');
    return (
      <div className={mobileContainerStyles}>
        {availability &&
          availability.map((dateData) => {
            const date = dateData.get('date');
            const hours = dateData.get('hours');
            return (
              <div key={date}>
                <Element name={date}>
                  <p className={styles.dateLabel}>
                    {formatDate(date, 'dddd[,] MMM D')}
                    {dateHasSelectedHours(dateData.toJS()) && (
                      <div className={styles.availabilityDateSelected} />
                    )}
                  </p>
                </Element>

                <div className={styles.hourWrapperMobile}>
                  {hours &&
                    hours.map((hourData, index) => (
                      <MobileHourBlock
                        key={index}
                        date={date}
                        hourData={hourData}
                        toggleHour={toggleHour}
                      />
                    ))}
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

export default MobileSelector;
