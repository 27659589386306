import React from 'react'; // eslint-disable-line no-unused-vars
import HT_INFO from 'src/constants/hellotech';

const CanonicalUrlTag = () => {
  if (process.browser) {
    const url = [HT_INFO.siteLinks.mainSite.url, window.location.pathname].join('');
    const canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) canonicalLink.setAttribute('href', url);
  }
  return null;
};

export default CanonicalUrlTag;
