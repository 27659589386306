export const OPTIMIZE_INDEX = { OLD: '0', NEW: '1' };
export const ENV = Object.freeze({
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
});

export const PST_TIMEZONE_LA = "America/Los_Angeles";

export const HIDE_PRODUCT_CONNECTED_HOME = true;
