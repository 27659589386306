import { DEFAULT_LAYOUT } from 'src/constants/common';
import { isDevelopment, isStaging } from 'src/utils/env';

export const ACCOUNT_ORDER_LAYOUT = Object.freeze({
  ...DEFAULT_LAYOUT,
  main: { withNavy050BG: true },
});
export const PUBLIC_ORDER_LAYOUT = Object.freeze({
  ...DEFAULT_LAYOUT,
  header: { template: 'PlainHeader', showSiteLinks: false },
  main: { withNavy050BG: true },
});
export const PAGE_NAME = 'accountOrder';
export const ACCEPT_STAND_BY_TIME = 'accountOrder/ACCEPT_STAND_BY_TIME';
export const DECLINE_STAND_BY_TIMES = 'accountOrder/DECLINE_STAND_BY_TIMES';
export const CANCEL_ORDER = 'accountOrder/CANCEL_ORDER';
export const CANCE_ORDER_SEGMENT_EVENT = 'accountOrder/CANCE_ORDER_SEGMENT_EVENT';
export const MEMBERSHIP_ADDITION_TOAST_DISPLAYED =
  'accountOrder/MEMBERSHIP_ADDITION_TOAST_DISPLAYED';
export const UPDATE_RECURRING_SERVICE = 'accountOrder/UPDATE_RECURRING_SERVICE';

export const HOURS_BEFORE_APPT = {
  CANCEL_PARTIAL: 24,
  CANCEL_FULL: 3,
  RESCHEDULE_PARTIAL: 3,
  MANUAL_OVERRIDE_NO_FEE: 25,
};

export const FEES = {
  MEMBER_CANCEL_PARTIAL: 'memberCancelPartial',
  CANCEL_TECH_ASSIGNED: 'cancelTechAssigned',
  CANCEL_NO_FEE: 'cancelNoFee',
  CANCEL_PARTIAL: 'cancelPartial',
  CANCEL_FULL: 'cancelFull',
  RESCHEDULE_NO_FEE: 'rescheduleNoFee',
  RESCHEDULE_PARTIAL: 'reschedulePartial',
};

export const SNACKBAR_NAME = 'ORDER_PAGE_SNACKBAR';

// TODO: Move this up a level? -GH June 18, 2021
export const SERVICE_ADJUSTMENT_TYPES = {
  /*
    Service Adjustments are adjustments attached to an individual service.

    Service Adjustments do not come through with a type property like the cart, only with a name
    and amount. So instead of filtering by type we need to filter by substring.
  */
  SHP_DISCOUNT: 'subscription discount',
  SUBSIDIZED: 'covered by', // Complete message from server is "Covered by ${partnerName}"
  AUTOMATIC_DISCOUNT: 'automatic discount', // See discount_skus table for applicable skus
};

export const ORDER_ADJUSTMENT_TYPES = {
  /* Order Adjustments are adjustments applied to the entire order, and not individual service line items */
  TAX: 'tax',
};

export const PANEL_HEADERS = {
  ORDER_DETAILS: 'Order Details',
  PRODUCT_SHIPPING_INFO: 'Shipping Information',
};

export const ORDER_SUMMARY_CARD_ID = 'order-summary-card';

export const STATUS_CARD_TITLES = {
  APPT: 'APPOINTMENT',
  SHIPMENT: 'SHIPMENT',
  REMOTE_SUPPORT: 'REMOTE SUPPORT',
};

export const STATUS_CARD_HEADERS = {
  PENDING: 'Pending',
  PENDING_WITH_TIME_WINDOWS: 'Your appointment is confirmed!',
  CONFIRMED: 'Your appointment has been scheduled!',
  CONFIRMED_WITH_TIME_WINDOWS: 'Your appointment is confirmed!',
  REMOTE_SUPPORT: 'Initiate your virtual service anytime',
  TECH_LATE:
    'Your technician couldn’t make it to the original appointment. Do any of these times work for you?',
  JOB_STARTED: 'Your appointment has started!',
  COMPLETED: 'Your service is completed. Thank you for choosing HelloTech!',
  STAND_BY: 'Do any of these alternate times work for you?',
  STAND_BY_WITH_TIME_WINDOWS: 'Your appointment is confirmed!',
  RESCHEDULE: "We couldn't find a tech for the time you selected. Please pick a new appointment.",
  RESCHEDULE_WITH_TIME_WINDOWS: 'Our tech is unable to make the appointment.',
  ERROR: 'Error Detected',
};

export const STATUS_CARD_SUBTEXTS = {
  STAND_BY: 'We weren’t able to find any technicians for your exact times.',
};

export const PANEL_GROUP = {
  PROD_SHIPMENT: 'prodShipment',
  ORDER_STATUS: 'orderStatus',
  ORDER_SUMMARY: 'orderSummary',
  RECURRING_SERVICE: 'recurringService',
};

/** Defines display order of order information panels in normal and error states */
export const PANEL_GROUP_ORDER = {
  AUTH_ERROR: [
    PANEL_GROUP.ORDER_STATUS,
    PANEL_GROUP.RECURRING_SERVICE,
    PANEL_GROUP.PROD_SHIPMENT,
    PANEL_GROUP.ORDER_SUMMARY,
  ],
  DEFAULT: [
    PANEL_GROUP.PROD_SHIPMENT,
    PANEL_GROUP.ORDER_STATUS,
    PANEL_GROUP.RECURRING_SERVICE,
    PANEL_GROUP.ORDER_SUMMARY,
  ],
};

export const DEEP_LINK_QUERY_PARAMS = {
  ADD_PLAN_TO_ORDER: 'add_plan_to_order',
};

// Okay to hardcode these plan ids for new per product - GH Apr 9 2024
export const ORDER_SUBSCRIPTION_INTENT_PLAN_ID = (() => {
  const stagingPlanId = 34;
  const prodPlanId = 31;
  if (isDevelopment() || isStaging()) return stagingPlanId;
  return prodPlanId;
})();

export const CANCELLATION_TOAST_MESSAGES = {
  APPOINTMENT_CANCELLED: 'Appointment cancelled',
  RECURRING_SERIES_CANCELLED: 'Appointment series cancelled',
};
