import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DefaultMetaTags, SocialMetaTags } from 'src/components/SEO';

const ServiceMeta = ({ service }) => (
  <>
    <DefaultMetaTags
      title={service.meta.title}
      description={service.meta.description}
      script={service.extra.meta.script}
      noindex={service.meta.noindex}
    />
    <SocialMetaTags
      title={service.meta.title}
      description={service.meta.description}
      image={service.image}
    />
  </>
);

ServiceMeta.propTypes = {
  service: PropTypes.shape({
    image: PropTypes.string,
    extra: PropTypes.shape({
      meta: {
        script: PropTypes.arrayOf(PropTypes.string),
      },
    }),
    meta: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      noindex: PropTypes.bool,
    }),
  }),
};

export default memo(ServiceMeta);
