import { fromJS } from 'immutable';
import { TOGGLE_TOPNAV } from './constants';

const initialState = fromJS({
  open: false,
  productsMenuOpen: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_TOPNAV: {
      return state.set('open', !state.get('open'));
    }
    default:
      return state;
  }
}
