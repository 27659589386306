export const makeOrganizationJsonLd = () =>
  `"@type": "Organization",
    "name": "HelloTech",
    "award": "A+ Rating from the Better Business Bureau",
    "logo": "https://www.hellotech.com/HT-sharing-logo.png",
    "url": "https://www.hellotech.com",
    "member": {
      "@type": "Organization",
      "name": "Better Business Bureau"
    }`;

export const makeAddressJsonLd = (location, region, postalCode) => `
  "@type": "PostalAddress",
  "addressLocality": "${location}",
  "addressRegion": "${region}",
  "postalCode": "${postalCode}",
  "addressCountry": {
    "@type": "Country",
    "name": "US"
  }
`;
