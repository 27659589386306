import { call, put, takeEvery, select } from 'redux-saga/effects';
import { LOAD_PAGE } from '../../constants/common';
import { pageLoadingError, pageLoaded } from '../../actions/common';
import { selectRoutes } from '../../apiRoutes';

export function* pageSaga({ seoName }) {
  let skuCategory = yield select((state) =>
    state.getIn(['entities', 'skuCategories', seoName], null),
  );
  if (skuCategory) {
    yield put(pageLoaded('skuCategory', { skuCategory: skuCategory.toJS() }));
    return;
  }
  const routes = yield call(selectRoutes);
  const requestResult = yield call(routes.skuCategories.find, { seoName });
  if (!requestResult.err) {
    skuCategory = requestResult.data.skuCategory;
    yield put(pageLoaded('skuCategory', { skuCategory }));
  } else {
    yield put(pageLoadingError('skuCategory', requestResult));
  }
}

export function* pageFlow() {
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === 'skuCategory', pageSaga);
}

export default [pageFlow];
