import { connect } from 'react-redux';
import { push } from 'src/utils/paths';
import { toggleTopnav } from 'src/components/Header/TopNavHeader/actions';
import IframeView from './IframeView';

/*
  Using connect & mapFooBarToProps pattern here in order to pass this data
  to the messagePageActions handler
*/
const mapStateToProps = (state) => ({
  isTopNavOpen: state.getIn(['components', 'topNav', 'open'], false),
});

const mapDispatchToProps = (dispatch) => {
  return {
    push: (...args) => dispatch(push(...args)),
    toggleTopnav: () => dispatch(toggleTopnav()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IframeView);
