import { SKU_CATEGORIES } from 'src/constants/sku';

import { contentBasehost as baseRedirectPath } from 'src/utils/environment';

/**
 * Add your Product urls here
 */
const baseEnterPath = '/tech-support/for';

/**
 * Webflow has a staging/production environment. Lets slot in the appropriate one.
 * DNS for this is not setup yet. (see above as a probably example, baseHost)
 */
const {
  TV_MOUNTING,
  COMPUTERS,
  WIFI,
  SMART_HOME,
  AUDIO_VIDEO,
  MOBILE_TABLETS,
  HOME_OFFICE,
  HOME_SECURITY,
  AROUND_THE_HOME,
} = SKU_CATEGORIES;

/*
  Lets map cat ids to urls. This would be needed for breadcrumbs.
  These are the routes that will be having an iframe
  example:
    TV_MOUNTING
    /tech-support/for/tv-mounting
*/
export const IFRAME_SRCS_CAT_BY_ID = Object.freeze({
  [TV_MOUNTING.id]: `${baseEnterPath}/${TV_MOUNTING.seoName}`,
  [COMPUTERS.id]: `${baseEnterPath}/${COMPUTERS.seoName}`,
  [WIFI.id]: `${baseEnterPath}/${WIFI.seoName}`,
  [SMART_HOME.id]: `${baseEnterPath}/${SMART_HOME.seoName}`,
  [AUDIO_VIDEO.id]: `${baseEnterPath}/${AUDIO_VIDEO.seoName}`,
  [MOBILE_TABLETS.id]: `${baseEnterPath}/${MOBILE_TABLETS.seoName}`,
  [HOME_OFFICE.id]: `${baseEnterPath}/${HOME_OFFICE.seoName}`,
  [HOME_SECURITY.id]: `${baseEnterPath}/${HOME_SECURITY.seoName}`,
  [AROUND_THE_HOME.id]: `${baseEnterPath}/${AROUND_THE_HOME.seoName}`,
});

/*
 * These are the sources which a user is "rerouted" in the iframe. These go in app.us as "routes"
 * We map the incoming location (passed from router in app) to the iframe src.
 *
 * Mapped:
 * client_app_route: iframe_url
 * The iframe source will be given to you by Patrick.
 * */

export const IFRAME_SRCS = () => {
  const categoryPath = `${baseRedirectPath}/categoryv2`;
  return {
    [IFRAME_SRCS_CAT_BY_ID[TV_MOUNTING.id]]: `${categoryPath}/tv-mounting`,
    [IFRAME_SRCS_CAT_BY_ID[WIFI.id]]: `${categoryPath}/wifi-network`,
    [IFRAME_SRCS_CAT_BY_ID[SMART_HOME.id]]: `${categoryPath}/smart-home`,
    [IFRAME_SRCS_CAT_BY_ID[AUDIO_VIDEO.id]]: `${categoryPath}/audio-video`,
    [IFRAME_SRCS_CAT_BY_ID[MOBILE_TABLETS.id]]: `${categoryPath}/mobile-tablets`,
    [IFRAME_SRCS_CAT_BY_ID[HOME_OFFICE.id]]: `${categoryPath}/home-office-school`,
    [IFRAME_SRCS_CAT_BY_ID[HOME_SECURITY.id]]: `${categoryPath}/home-security`,
    [IFRAME_SRCS_CAT_BY_ID[COMPUTERS.id]]: `${categoryPath}/computer-support`,
    [IFRAME_SRCS_CAT_BY_ID[AROUND_THE_HOME.id]]: `${categoryPath}/around-the-home`,
  }
};

export const getIframeSource = (src, options = {}) => {
  const { params = '', version = '' } = options;
  const iframeUrls = IFRAME_SRCS(version);
  return `${iframeUrls[src]}${params}`;
};

/**
 * This tells us what messaging group to listen for, else we get all messages across the iframe(s)/sockets.
 * @type {string}
 */
export const CALLER_PAGE = 'categorypage';

// Routes that match for the iframe
export const IframeCategoryPageRoutes = Object.keys(IFRAME_SRCS());

// Action types
export const TYPES = {
  CATEGORY_LINK_CLICK: 'segment/CATEGORY_LINK_CLICK',
  CATEGORY_PAGE_LOAD: 'segment/CATEGORY_PAGE_LOAD',
};
