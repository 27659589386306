import React from 'react';
import PropTypes from 'prop-types';
import { PanelV2 } from 'HTKit/Elements/PanelV2';
import styles from './styles.scss';

const Content = ({ children, contentContainerClasses }) => (
  <div className={styles.desktopContent}>
    <PanelV2 className={`padding-small2 ${contentContainerClasses}`}>
      {children}
    </PanelV2>
  </div>
);

const DesktopOrderSummary = ({ leftContent, rightContent, contentContainerClasses }) => (
  <section className={styles.desktopContainer}>
    <Content contentContainerClasses={contentContainerClasses}>{leftContent}</Content>
    <Content contentContainerClasses={contentContainerClasses}>{rightContent}</Content>
  </section>
);

Content.propTypes = {
  children: PropTypes.element.isRequired,
  contentContainerClasses: PropTypes.string,
};

Content.defaultProps = {
  contentContainerClasses: "",
};

DesktopOrderSummary.propTypes = {
  leftContent: PropTypes.element.isRequired,
  rightContent: PropTypes.element.isRequired,
  contentContainerClasses: PropTypes.string,
};

export default React.memo(DesktopOrderSummary);

/*
  DesktopOrderSummary
    - Render Order Summary view for desktop
*/

// TODO: This is only being used for SubscriptionGifting and when that feature redone or removed this component should be removed. -GH June 22, 2020
