import { combineReducers } from 'redux-immutable';
import snackbar from 'src/components/Snackbar/Fixed/reducer';
import noticeStack from 'src/components/NoticeStack/reducer';
import sidebar from 'src/components/Sidebar/reducer';
import loginModal from 'src/containers/LoginPage/Modal/reducer';
import zipCodeTest from 'src/containers/ServicePage/reducer';
import search from 'src/components/Searchbar/reducer';
import featuredContentOrSkuHeader from 'src/components/Header/featuredContentOrSku/reducer';
import chatCallDropdown from 'src/components/Header/Parts/ChatCallDropdown/reducer';
import topNav from 'src/components/Header/TopNavHeader/reducer';
import cloudinary from 'src/components/Cloudinary/cloudinary.reducer';

export default () =>
  combineReducers({
    noticeStack,
    sidebar,
    loginModal,
    search,
    featuredContentOrSkuHeader,
    chatCallDropdown,
    snackbar,
    topNav,
    zipCodeTest,
    cloudinary,
  });
