import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.scss';

const SavingsCallout = ({ amountFormatted, className, copyClassName }) => {
  if (!amountFormatted) return null;

  const copyStyles = classNames('p2', 'blue', styles.copy, copyClassName);
  const calloutStyles = classNames(styles.callout, className);

  return (
    <div className={calloutStyles}>
      <p className={copyStyles}>{amountFormatted}</p>
    </div>
  );
};

SavingsCallout.defaultProps = {
  className: '',
  copyClassName: '',
};

SavingsCallout.propTypes = {
  amountFormatted: PropTypes.string,
  className: PropTypes.string,
  copyClassName: PropTypes.string,
};

export default SavingsCallout;
