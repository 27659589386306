import React from 'react';
import PropTypes from 'prop-types';
import { prevent } from 'src/utils/event';
import { TYPE } from './constants';
import styles from './styles.scss';

const FeaturedServiceLanding = ({ landing, onClick }) => {
  const onServiceClick = (event) => {
    prevent(event);
    onClick(landing, TYPE.LANDING);
  };
  return (
    <div className={styles.service}>
      <div className={styles.serviceImage} onClick={onServiceClick}>
        <img src={landing.image.original} alt="Product" />
      </div>
      <p className="h4 marginY-small1">{landing.pageTitle}</p>
      <a href="" onClick={onServiceClick} className="large-link">
        Learn More
      </a>
    </div>
  );
};

FeaturedServiceLanding.propTypes = {
  landing: PropTypes.shape({
    pageTitle: PropTypes.string,
    image: PropTypes.shape({
      original: PropTypes.string,
    }),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FeaturedServiceLanding;
