import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectRoutes } from 'src/apiRoutes';
import get from 'lodash/get';
// saga
import { addPlanToCart } from 'src/sagas/common/cart'
// actions
import { updateCart } from 'src/containers/AddSkuPage/actions';
import { fieldSalesPathAction, clientConfirmAction, TYPES } from 'src/containers/FieldSales/actions';
import { updateClientAction } from 'src/containers/FieldSales/context/actions';

// Selectors
import { fieldSaleAgentSelector, fieldSaleAgentTypeSelector, fieldSaleClientIdSelector } from 'src/containers/FieldSales/selectors';
// Utils
import { noop } from 'src/utils/event';
import { requestStarted, requestFinished, displayErrorsWithSnack } from 'src/utils/request';
import { getFieldSalesCookieByType, setFieldSalesCookie, getFieldSalesCookieById } from 'src/utils/cookies/fieldSalesCookie';
import { sanitizeObject } from 'src/utils/helpers';
import { LogSalesError } from 'src/containers/FieldSales/utils';
// Paths
import { push, fieldSalesPath, fieldSalesQRCodePath, fieldSalesCustomerPath, fieldSalesSkuListPath, fieldSalesVerifyCustomerPath, cartPath } from 'src/utils/paths';
// Constant
import {
  FIELD_SALES_AGENT_TYPES,
} from 'src/containers/FieldSales/constants';

export function* agentStartNewProcessFlowSaga({
  existing = false,
  clientId,
  agentId,
  agentType,
  agentEmail,
  responseAction = noop
}) {

  let agentEmailLocal = agentEmail;
  let agentIdLocal = agentId;
  let agentTypeLocal = agentType;
  let clientIdLocal = clientId;
  const routes = yield call(selectRoutes);
  const fieldSaleAgentCurrent = yield select(fieldSaleAgentSelector);

  yield put(requestStarted());
  /*
   This means agent currently has session, but wants to create a new one. Lets just derive their
   current id/type from the "current" cart (we could use cookie, but lets keep it consistent).

   Cart will be source of truth.
   */
  if (existing) {
    agentIdLocal = fieldSaleAgentCurrent.agentId;
    agentTypeLocal = fieldSaleAgentCurrent.agentType;
    agentEmailLocal = fieldSaleAgentCurrent.agentEmail;

    // Only for tech(hellotech), do we want to re-init with their customer (client id).
    if (FIELD_SALES_AGENT_TYPES.TECH === fieldSaleAgentCurrent.agentType) {
      clientIdLocal = yield select(fieldSaleClientIdSelector);
    }
  }

  const sanitizedValues = sanitizeObject({
    client_id: clientIdLocal,
    agent_id: agentIdLocal,
    agent_email: agentEmailLocal,
    agent_type: agentTypeLocal
  });

  const response = yield call(routes.fieldSales.startNewProcess, { ...sanitizedValues});
  yield put(requestFinished());
  if (response.err) {
    LogSalesError('NewProcessFlowSaga',response);
    yield put(displayErrorsWithSnack(response));
  } else {
    const { cart } = response.data;

    yield put(updateCart({ cart }));
    // Get the latest values from the cart
    const fieldSaleAgentLatest = yield select(fieldSaleAgentSelector);

    // Set the cookie for future logins (expires 1 day). Use latest results from the cart
    setFieldSalesCookie(sanitizeObject({
      agentId: fieldSaleAgentLatest.agentId,
      agentType: fieldSaleAgentLatest.agentType,
      agentEmail: fieldSaleAgentLatest.agentEmail
    }));
  }
  // Callback from the caller
  responseAction(response);
  return response;
}


/*
* Creates the order link and depending on client auth'd returns a certain status
* Used on /cart
* */
export function* startBookingSaga() {
  const routes = yield call(selectRoutes);

  yield put(requestStarted());
  const response = yield call(routes.fieldSales.startBooking);
  const clientId = yield select(fieldSaleClientIdSelector);
  yield put(requestFinished());

  if (response.err) {
    LogSalesError('startBookingSaga',response);
    yield put(displayErrorsWithSnack(response));
  } else {
    const { cart } = response.data;
    // we have a client association, go to QR code.
    if (clientId) {
      yield put(fieldSalesPathAction(fieldSalesQRCodePath));
    } else {
      yield put(fieldSalesPathAction(fieldSalesCustomerPath));
    }
    yield put(updateCart({ cart }));
  }

  return response;
}

/*
* Customer sign up
* params { email, phone, firstName, lastName, dispatch }
*
* note: fieldsale is a hard requirement. add it here.
* */
export function* registrationSaga({ params }) {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.users.registration, { fieldsale: 'true', user: params});
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrorsWithSnack(response));
    LogSalesError('registrationSaga',response);
  } else {
    const { user = {} } = response.data;
    yield put(clientConfirmAction({clientId: user.id}));
    yield put(fieldSalesPathAction(fieldSalesQRCodePath));
  }
}

/*
* Confirms the "add" to the cart of the client
* Used on fieldsales/:agenttype/customer
*
* comes after "check"
* */
export function* clientConfirmSaga({ clientId }) {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.fieldSales.clientConfirm, { client_id: clientId });
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrorsWithSnack(response));
    LogSalesError('clientConfirmSaga',response);
  } else {
    const { cart } = response.data;

    yield put(updateCart({ cart }));
    yield put(fieldSalesPathAction(fieldSalesQRCodePath));
  }
}

/*
* Confirms whether the entered client email has a valid HT account
* Used on fieldsales/:agenttype/customer
*
* Comes prior to "confirm"
* */
export function* clientCheckSaga({ email, dispatch }) {
  const routes = yield call(selectRoutes);
  const fieldSaleAgent = yield select(fieldSaleAgentSelector);
  yield put(requestStarted());
  const response = yield call(routes.fieldSales.clientCheck, { email });
  yield put(requestFinished());

  if (response.err) {
    const customerUrl= fieldSalesCustomerPath(fieldSaleAgent.agentType);
    yield put(displayErrorsWithSnack(response));
    yield put(push(`${customerUrl}?type=new`));
  } else {
    // Client was found. Move to the verification step
    const client = get(response, 'data.client', {});
    // We get back a partial client. Put it in our local context
    yield call(dispatch, updateClientAction(client));
    yield put(fieldSalesPathAction(fieldSalesVerifyCustomerPath));
  }
}

/*
* Takes Customers pin input and returns cart on valid entry
*
* */
export function* verifyPinSaga({ pin, token }) {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.fieldSales.verifyPin, { pin, cart_token: token });
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrorsWithSnack(response));
    LogSalesError('verifyPinSaga',response);
  } else {
    // All good, update cart and redirect
    const { cart } = response.data;

    yield put(updateCart({ cart }));
    yield put(push(cartPath));
  }
}

/*
* We take the function path, extract the type, then push it forward.
* This just prefills the "type" aspect of the url.
*
* path {function}
* options: {
*   type: {string}
* }
* Please note: If we can't derive path by current cart, we fallback to cookie for pathing.
*              If neither can be derived, we will try the localstorage. Otherwise, we show Index.
* */
export function* pathChangeSaga({ path }) {
  const fieldSaleTypeAgent = yield select(fieldSaleAgentTypeSelector);
  const fieldSaleTypeAgentCookie = getFieldSalesCookieByType();
  // this dispatches a path change
  if (!fieldSaleTypeAgent && !fieldSaleTypeAgentCookie) {
    LogSalesError('pathChangeSaga','Could not derive type');
  }

  yield put(push(path(fieldSaleTypeAgent || fieldSaleTypeAgentCookie)));

}

/*
* actionSessionValidatorSaga
*
* Some actions need to be validated before they are allowed.
* We will most likely use this only for externa linking/pathing. This would be a
* non-issue if we could contain the fieldsales...
*
* ie. Moving from skuList to client flow. Checking cart in header (client flow)
* If they try to do this w/o a cookie, they'll get the non fieldSales vie. No bueno.
*
* Note: If the page loaded and 'then' the session cookie was deleted/expied, then the cart
*       data is still in state, so its not very useful here. to "test" for it. The
*       Header component handles it (if we are in a /fieldsales flow), otherwise
*       bunkenstein happens.
* */
export function* sessionPathValidatorSaga({ action }) {
  /* Simple helper to properly handle the passed type of action. */
  const yieldType = () => typeof action === 'function' ? call(action) : put(action);
  const { agentType: agentTypeCart, agentId: agentIdCart, agentEmail: agentEmailCart } = yield select(fieldSaleAgentSelector);
  /* We use the ID instead of type because type has a back up in storage */
  const fieldSaleIdAgentCookie = getFieldSalesCookieById();
  /*
     The sellers cookie is gone, but trying to continue their actions.
     So, for now (until product lets us know), lets try to recover that data
     and reset the cookie.
   */
  switch (true) {
    case !fieldSaleIdAgentCookie && !agentTypeCart:
      yield put(fieldSalesPathAction(fieldSalesPath));
      break;
    case !!(!fieldSaleIdAgentCookie && agentTypeCart):
      // Set the cookie for future logins (expires 1 day). Use latest results from the cart
      setFieldSalesCookie(sanitizeObject({
        agentId: agentIdCart,
        agentType: agentTypeCart,
        agentEmail: agentEmailCart
      }));

      yield yieldType();
      break;
    default:
      yield yieldType();
  }
}

export function* qrCodeDeepLinkSaga({
    clientId,
    agentId,
    planId,
    agentType, }) {
  // Need to be executed in sequence. No need to keep calling if any fail.
  let response = {};
  response = yield call(agentStartNewProcessFlowSaga, { clientId, agentId, agentType });
  if (!response.err)
    response = yield call(addPlanToCart, planId);
  if (!response.err)
    response = yield call(startBookingSaga);

  // To not conflate the individual sagas we return out the response, and act it out here.
  // Errors redirect.
  if (response.err) {
    const fieldSaleAgent = yield select(fieldSaleAgentSelector);
    yield put(push(fieldSalesSkuListPath(fieldSaleAgent.agentType)));
  } else {
    yield put(fieldSalesPathAction(fieldSalesQRCodePath));
  }
}

export function* agentCustomerFlow() {
  yield takeLatest(TYPES.FIELD_SALES_CLIENT_CHECK, clientCheckSaga);
  yield takeLatest(TYPES.FIELD_SALES_CLIENT_CONFIRM, clientConfirmSaga);
  yield takeLatest(TYPES.FIELD_SALES_START_BOOKING, startBookingSaga);
  yield takeLatest(TYPES.FIELD_SALES_REGISTRATION, registrationSaga);
  yield takeLatest(TYPES.FIELD_SALES_CLIENT_PATH_CHANGE, pathChangeSaga);
  yield takeLatest(TYPES.FIELD_SALES_VERIFY_PIN, verifyPinSaga);
  yield takeLatest(TYPES.FIELD_SALES_FLOW_VALIDATOR, sessionPathValidatorSaga);
  yield takeLatest(TYPES.FIELD_SALES_DEEPLINK_QRCODE, qrCodeDeepLinkSaga);
}

export function* agentLoginFlow() {
  yield takeLatest(TYPES.FIELD_SALES_AGENT_START_NEW_PROCESS, agentStartNewProcessFlowSaga);
}

export default [agentLoginFlow, agentCustomerFlow];
