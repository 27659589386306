import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import useAPI from 'src/hooks/useAPI';
import { pureCartSelector } from 'src/selectors/cart';
import { updateAuth, userLoaded } from 'src/containers/AppBase/actions';
import { push, accountPath } from 'src/utils/paths';
import { goToStage } from 'src/containers/BookingPage/actions';
import { userSignedUp } from 'src/containers/RegistrationPage/actions';
import { useLeadGeneration } from 'src/utils/leadGenerated/useLeadGeneration';

export default () => {
  const dispatch = useDispatch();
  const cart = useSelector(pureCartSelector);
  const api = useAPI();
  const { submitLeadGenerated } = useLeadGeneration();

  const request = useCallback(
    ({ params, optParams = {}, opts = {} }) =>
      new Promise((resolve, reject) => {
        api.toggleLoader(true);
        api.users
          .registration({ user: params, ...optParams })
          .then(async ({ err, data }) => {
            if (err) {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({ err, data });
            } else {
              const cartData = cart;
              const { user } = data;

              resolve(data);

              Cookies.set('new_user', true);

              dispatch(updateAuth({ authToken: user.authToken }));
              dispatch(userLoaded(user));
              dispatch(userSignedUp({ user, attributes: params }));

              if (cartData) {
                submitLeadGenerated({
                  email: params.email,
                  source: 'registrationCheckout',
                  page: 'useSignUp',
                });

                if (cartData.statusId > 0) {
                  dispatch(goToStage(cartData.status, false, { ...opts }));
                  return;
                }
                dispatch(goToStage('address', false, { ...opts }));
              } else {
                dispatch(push(accountPath));
              }
            }
          })
          .catch(reject)
          .finally(() => {
            api.toggleLoader(false);
          });
      }),
    [cart, api],
  );

  return request;
};
