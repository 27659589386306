import { put, select, takeEvery } from 'redux-saga/effects';
import { REMOVE_EXCESS_SELECTED_TIME, TOGGLE_HOUR } from './constants';
import { toggleHourByIndex } from './actions';

// =========================================
// Utility Functions
// =========================================

/**
 * Toggles the availability of a specific hour if it's valid.
 *
 * @generator
 * @param {string} targetDateTime - The datetime string of the hour to be toggled.
 * @param {boolean} newCheckState - Whether the hour should be selected (true) or deselected (false).
 */
function* toggleHourAvailability(targetDateTime, newCheckState) {
  const state = yield select();
  const availabilityByDateTime = state.getIn(['entities', 'availabilityByDateTime']);
  const targetHour = availabilityByDateTime[targetDateTime];

  if (!targetHour) return;

  const { dateIndex, hourIndex, valid } = targetHour;

  // Only toggle the hour if it is valid (not in the past)
  if (valid) {
    yield put(toggleHourByIndex({ dateIndex, hourIndex, checked: newCheckState }));
  }
  // If the hour is not valid, we don't dispatch any action
}

// =========================================
// Action Handlers
// =========================================

/**
 * Handles the removal of excess selected times when the maximum allowed selections is exceeded.
 *
 * @generator
 * @param {Object} action - The dispatched action.
 * @param {string} action.selectedHourData - The datetime string of the hour to be removed.
 */
function* handleRemoveExcessSelectedTime(action) {
  const { selectedHourData } = action;
  yield* toggleHourAvailability(selectedHourData, false);
}

/**
 * Handles the toggling of an hour in the state.entities.availability selector.
 *
 * @generator
 * @param {Object} action - The dispatched action.
 * @param {Object} action.selectedHourData - An Immutable.js Map containing data about the selected hour.
 * @param {string} action.selectedHourData.dateTime - The datetime string of the hour to be toggled.
 * @param {boolean} action.newCheckState - The new check state (true for checked, false for unchecked).
 */
function* handleToggleHour(action) {
  const { selectedHourData, newCheckState } = action;
  yield* toggleHourAvailability(selectedHourData.get('dateTime'), newCheckState);
}

// =========================================
// Saga
// =========================================
function* availabilitySelectorSaga() {
  yield takeEvery(REMOVE_EXCESS_SELECTED_TIME, handleRemoveExcessSelectedTime);
  yield takeEvery(TOGGLE_HOUR, handleToggleHour);
}

export default [availabilitySelectorSaga];
