import { contentBasehost } from 'src/utils/environment';

/**
 * This tells us what messaging group to listen for, else we get all messages across the iframe(s)/sockets.
 * @type {string}
 */
export const CALLER_PAGE = 'homehubpage';

const proxyHandler = {
  get(obj, prop) {
    const key = typeof prop === 'string' ? prop.toLowerCase() : prop;
    return key in obj ? obj[key] : obj.default;
  }
}

export const HOME_HUB_SOURCES = new Proxy({
  default: {
    src: `${contentBasehost}/home-hub/home`,
    metaTitle: 'HelloTech Connected Home: Smart Solutions for Smart Homes',
    metaDescription: 'Get recommendations on the latest connected devices with HelloTech Connected Home. Take our short survey to find personalized solutions for your smart home. ',
  },
  security: {
    src: `${contentBasehost}/home-hub/security`,
    metaTitle: 'HelloTech Connected Home: Solutions for a Safer Home',
    metaDescription: 'Get recommendations on the latest security products with HelloTech Connected Home. Take our short survey to find personalized solutions for your smart home. ',
  },
  energy: {
    src: `${contentBasehost}/home-hub/energy`,
    metaTitle: 'HelloTech Connected Home: Solutions for an Energy-Saving Home',
    metaDescription: 'Get recommendations on the latest energy-saving products with HelloTech Connected Home. Take a short survey to find personalized solutions for your smart home.',
  },
  connectivity: {
    src: `${contentBasehost}/home-hub/connectivity`,
    metaTitle: 'HelloTech Connected Home: Solutions for Faster WiFi',
    metaDescription: 'Get recommendations on the latest WiFi products with HelloTech Connected Home. Take our short survey to find connectivity solutions personalized for you. ',
  },
}, proxyHandler);
