import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { servicePageReducer, INITIAL_STATE } from './ducks';

export const ServicePageContext = React.createContext();
export const ServicePageDispatchContext = React.createContext();

const ServicePageProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(servicePageReducer, INITIAL_STATE);

  return (
    <ServicePageContext.Provider value={state}>
      <ServicePageDispatchContext.Provider value={dispatch}>
        {children}
      </ServicePageDispatchContext.Provider>
    </ServicePageContext.Provider>
  );
};

const useServicePageContext = () => {
  const contextState = useContext(ServicePageContext);
  const contextDispatch = useContext(ServicePageDispatchContext);
  return [contextState, contextDispatch];
};

export { ServicePageProvider, useServicePageContext };

ServicePageProvider.propTypes = { children: PropTypes.node.isRequired };
