import { useMemo } from 'react';
import { sortBy } from 'lodash';
import useWindowType, { WIN_LG, WIN_MD, WIN_SM, WIN_XL } from 'src/hooks/useWindowType';

const OPTIONS_LIMIT = 7;

const NUM_COLS = {
  [WIN_SM]: 1,
  [WIN_MD]: 2,
  [WIN_LG]: 3,
  [WIN_XL]: 3,
};

const COL_PROPS = {
  [WIN_SM]: { sm: 4 },
  [WIN_MD]: { md: 4 },
  [WIN_LG]: { lg: 4 },
  [WIN_XL]: { lg: 4 },
};

// Break down into groups. Lodash's chunk behavior wasn't a great fit.
const breakIntoGroups = (list = [], numGroups = 1, finalList = []) => {
  if (!Array.isArray(list) || numGroups < 0) return list;
  const listLength = list.length;
  const listQuotient = listLength / numGroups;
  const sliceValue = listLength % numGroups === 0 ? listQuotient : listQuotient + 1;
  finalList.push(list.slice(0, sliceValue));
  const remaining = list.slice(sliceValue);
  if (remaining.length) {
    breakIntoGroups(remaining, numGroups - 1, finalList);
  }
  return finalList;
};

/*
  We want to group the children into columns if children.length is greater than
  the limit (7).

  For desktop, split into 3 columns.
  For tablet, split into 2 columns.
  For mobile, split into 1 column.
*/
const getGroupedChildren = ({ children, disableSort = false, disableGrouping = false }) => {
  const windowType = useWindowType();

  let groupedChildren = [children];
  let columnProps = {};

  // Break options into columns
  if (children.length > OPTIONS_LIMIT && !disableGrouping) {
    groupedChildren = useMemo(() => {
      const sortFn = disableSort ? (arr) => arr : sortBy;
      const sortedChildren = sortFn(children, (child) => child.props.label);
      return breakIntoGroups(sortedChildren, NUM_COLS[windowType]);
    }, [children, windowType]);
    columnProps = { ...COL_PROPS[windowType] };
  }

  return {
    groupedChildren,
    columnProps,
  };
};

export default getGroupedChildren;
