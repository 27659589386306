import { fromJS } from 'immutable';
import { SET_CARD_DATA, CLEAR_CARD } from './constants';

const initialState = fromJS({ card: null });

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CARD_DATA:
      return state.set('card', action.card);
    case CLEAR_CARD:
      return state.set('card', null);
    default:
      return state;
  }
};

export default pageReducer;
