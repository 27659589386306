import Cookies from 'js-cookie';
import { put, select, call } from 'redux-saga/effects';
import { updatePhone } from 'src/containers/AppBase/actions';
import { brandingThemeSelector } from 'src/selectors/branding';
import HT_INFO from 'src/constants/hellotech';
import { LANDING_PHONE_COOKIE } from 'src/constants/tracking';

export const PLAN_SUBSCRIBER_PHONE_KEY = 'plan-subscriber-phone';

function* getPhone({ subscribed }) {
  const theme = yield select(brandingThemeSelector);
  const subscriberPhone = HT_INFO.phone.customers.subscriptionHolder[theme || 'default'];
  const landingPhone = Cookies.get(LANDING_PHONE_COOKIE.NAME);
  const nonSubscriberPhone = landingPhone || HT_INFO.phone.customers.default.value;
  const phone = subscribed ? subscriberPhone && subscriberPhone.value : nonSubscriberPhone;
  return phone;
}

export function* checkPlanSubscriberPhone(subscribed) {
  const phone = yield call(getPhone, { subscribed });
  if (!subscribed) {
    Cookies.remove(PLAN_SUBSCRIBER_PHONE_KEY);
  } else {
    Cookies.set(PLAN_SUBSCRIBER_PHONE_KEY, phone, { expires: 14 });
  }

  yield put(updatePhone({ phone }));
}
