import { useState, useEffect } from 'react';

export const useInputFieldCounter = ({
  startValue = 0,
  minValue = null,
  maxValue = null,
  incrementBy = 1,
  decrementBy = 1,
  onChange = () => {},
}) => {
  const [value, setValue] = useState(startValue);

  const isAtMinValue = minValue && value <= minValue;
  const isAtMaxValue = maxValue && value >= maxValue;

  const increment = () => {
    if (isAtMaxValue) return;
    setValue((prevValue) => prevValue + incrementBy);
  };

  const decrement = () => {
    if (isAtMinValue) return;
    setValue((prevValue) => prevValue - decrementBy);
  };

  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  return {
    value,
    isAtMinValue,
    isAtMaxValue,
    increment,
    decrement,
  };
};
