import { forIn, map, range, startsWith } from 'lodash';

const getCardType = (number) => {
  const cards = { visa: /^4/, mastercard: /^5[1-5]/, amex: /^3[47]/, diners: /^3(?:0[0-5]|[68][0-9])/, discover: /^6(?:011|5[0-9]{2})/, jcb: /^(?:2131|1800|35\d{3})/ };
  let type = null;
  forIn(cards, (value, key) => {
    if (cards[key].test(number)) {
      type = key;
    }
  });
  return type;
};

const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];

const creditCardMask = (rawValue) => {
  const cardType = getCardType(rawValue);
  let mask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];

  if (cardType === 'amex') {
    mask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/];
  } else if (cardType === 'diners') {
    mask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/];
  }

  return mask;
};

const digitsMask = (rawValue) => map(range((rawValue || '').length + 1), () => /\d/);

const expirationMonthAndYearMask = [/[0-1]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/];

const expirationMonthAndShortYearMask = [/[0-1]/, /\d/, '/', /\d/, /\d/];

const promoCodeMask = [/./, /./, /./, /./, /./, '-', /./, /./, /./, /./, /./, '-', /./, /./, /./, /./, /./];

const nDigits = (n) => map(range(n), () => /\d/);

const cvcMask = nDigits(4);

const zipCodeMask = nDigits(5);

export function prepareMask(initialMask) {
  if (initialMask === 'phone') return phoneMask;
  if (initialMask === 'creditCard') return creditCardMask;
  if (initialMask === 'cvc') return cvcMask;
  if (initialMask === 'zip') return zipCodeMask;
  if (initialMask === 'digits') return digitsMask;
  if (initialMask === 'expirationDate') return expirationMonthAndYearMask;
  if (initialMask === 'expirationDateShortYear') return expirationMonthAndShortYearMask;
  if (initialMask === 'promoCode') return promoCodeMask;
  if (startsWith(initialMask, 'digits:')) return nDigits(initialMask.split(':')[1]);
  return initialMask;
}
