/* eslint-disable import/prefer-default-export */
import { SPEEDTEST_RESULTS_RECEIVED, SET_SKU_DATA } from './constants';

export const speedtestResultsReceived = (testResults) => ({
  type: SPEEDTEST_RESULTS_RECEIVED,
  testResults,
});

export const setSkuData = (skus) => ({
  type: SET_SKU_DATA,
  skus,
});
