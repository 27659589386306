import React from 'react';
import PropTypes from 'prop-types';
import { Picture, TYPES } from 'src/components/Elements/Picture';
import { resolveHeroImage } from './utils';
import styles from './styles.scss';

const SkuCategoryImage = ({ skuName, skuSeoName }) => {
  const heroImage = resolveHeroImage(skuSeoName);
  /*
    TODO: This page will probably be replaced, but if not and we need to refurbish htDesktopWidth is an old-school
          v1 value in the SCSS variables file -GH Dec30, 2020
  */
  const htDesktopWidth = 769;
  const sources = [
    {
      srcSet: heroImage.desktop,
      type: TYPES.PNG,
      media: `(min-width: ${htDesktopWidth}px)`,
    },
    {
      srcSet: heroImage.mobile,
      type: TYPES.PNG,
    },
  ];
  return (
    <div>
      <Picture sources={sources} className={styles.hero} alt={skuName} />
    </div>
  );
};

SkuCategoryImage.propTypes = {
  skuSeoName: PropTypes.string.isRequired,
  skuName: PropTypes.string.isRequired,
  isMobileOrTablet: PropTypes.bool.isRequired,
};

export default SkuCategoryImage;
