import React from 'react';

import doorbellDesktop from 'src/images/products/doorbell_wide.jpg';
import securityDesktop from 'src/images/products/simpliSafepkg__wide.jpg';
import cameraDesktop from 'src/images/products/camera_wide.jpg';
import lockDesktop from 'src/images/products/lock_wide.jpg';
import doorbellMobile from 'src/images/products/doorbell_square.jpg';
import securityMobile from 'src/images/products/simpliSafepkg_square.jpg';
import cameraMobile from 'src/images/products/camera_square.jpg';
import lockMobile from 'src/images/products/lock_square.jpg';

export const getSimplisafeSkus = (sku) => {
  switch (sku.id) {
    case 804:
      return {
        image: securityDesktop,
        mobileImage: securityMobile,
      };
    case 805:
      return {
        image: doorbellDesktop,
        mobileImage: doorbellMobile,
      };
    case 806:
      return {
        image: cameraDesktop,
        mobileImage: cameraMobile,
      };
    case 958:
      return {
        image: lockDesktop,
        mobileImage: lockMobile,
      };
    default:
      return null;
  }
};

export const SIMPLISAFE_SKUS = Object.freeze([
  {
    id: 804,
    name: 'SimpliSafe Home Security System Installation',
    image: doorbellDesktop,
    mobileImage: doorbellMobile,
    cheapestPrice: 79,
    ratingAttributes: { rating: '4.5' },
  },
  {
    id: 805,
    name: 'SimpliSafe Video Doorbell Installation',
    image: securityDesktop,
    mobileImage: securityMobile,
    cheapestPrice: 79,
    ratingAttributes: { rating: '4.5' },
  },
  {
    id: 806,
    name: 'SimpliSafe Outdoor Weatherized Camera Kit Installation',
    image: cameraDesktop,
    mobileImage: cameraMobile,
    cheapestPrice: 79,
    ratingAttributes: { rating: '4.5' },
  },
  {
    id: 958,
    name: 'SimpliSafe Smart Lock Installation',
    image: lockDesktop,
    mobileImage: lockMobile,
    cheapestPrice: 79,
    ratingAttributes: { rating: '4.5' },
  },
]);

export const PAGE_NAME = 'landingPageTest';

export const PRODUCT_CARD_ID = 'landing-product-card-container';
export const TOTAL_COMPLETED_SERVICES_ID = 'total-completed-services';

export const PARTNER_DETAILS = Object.freeze({
  simplisafe: {
    totalCompletedServices: {
      text: 'Simplisafe Services Completed',
    },
    readyToBook: {
      ctaText: (
        <>
          <div>Ready to Book Your</div>
          <div>SimpliSafe Service?</div>
        </>
      ),
      buttonText: 'Get Started',
    },
    skus: [804, 805, 806, 958],
  },
});
