export const ANSWER_CHANGE = 'preQuestion/ANSWER_CHANGE';
export const ANSWER_QUANTITY_CHANGE = 'preQuestion/ANSWER_QUANTITY_CHANGE';
export const DEVICE_ANSWER_CHANGE = 'preQuestion/DEVICE_ANSWER_CHANGE';
export const DEVICE_INPUT_ANSWER_CHANGE = 'preQuestion/DEVICE_INPUT_ANSWER_CHANGE';

export const ANSWER_CHANGE_DEVICE_INSTANTIATE = 'preQuestion/ANSWER_CHANGE_DEVICE_INSTANTIATE';

export const CUSTOM_DROPDOWN_OPTIONS = {
  I_DONT_KNOW: { value: -1, label: "I don't know", searchable: false }, // BE expects -1 value for this option
  OTHER: { value: '', label: 'Other', searchable: false },
  NO_OPTIONS: {
    value: 0,
    label: 'Sorry, we were unable to find a match',
    isDisabled: true,
    searchable: false,
  }, // This is just visual render - not actionable
};

export const DEFAULT_VALUES = {
  PLACEHOLDER: 'Select',
};

export const INITIAL_STATE = {
  hintVisible: false,
  isModelDisabled: true,
  showInput: false,
  showModelInput: false,
  models: null,
  filteredModels: null,
  placeholder: DEFAULT_VALUES.PLACEHOLDER,
  allOptions: [],
};
