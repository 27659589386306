import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SubNav from '../SubNav';
import styles from './styles.scss';

const BREAKPOINT = 200;

const OpaqueSubNav = ({ children, wrapperClasses = '', ...rest }) => {
  const [overScrolledState, setOverScrolledState] = useState(false);
  const [opaqueClass, setOpaqueClass] = useState(styles.opaque);

  const handleScroll = () => {
    const position = window.scrollY || document.body.scrollTop;
    const overScrolled = position > BREAKPOINT;

    if (overScrolled !== overScrolledState) {
      setOverScrolledState(overScrolled);
      setOpaqueClass(overScrolled ? '' : styles.opaque);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <SubNav float wrapperClasses={cn(styles.base, opaqueClass, wrapperClasses)} {...rest}>
      {children}
    </SubNav>
  );
};

OpaqueSubNav.propTypes = {
  children: PropTypes.node,
  wrapperClasses: PropTypes.string,
};

export default memo(OpaqueSubNav);
