import { QA_FLOW_GOOGLE_ADS_MAPPING } from './constants';

/**
  Originally created for https://hellotech.atlassian.net/browse/SFR-655 split test
*/
export const getMappedQaFlowSku = ({ mapping = QA_FLOW_GOOGLE_ADS_MAPPING, skuId }) => {
  const env = process.env.NODE_ENV;
  const mappedSkus = mapping[env] || mapping.default;
  return mappedSkus[skuId] || skuId;
};
