import React, { memo } from 'react';
import Grid from 'src/components/HTKit/Grid/Grid';
import HeaderTextPanel from './HeaderTextPanel';
import ProductCards from '../ProductCards';

const HeaderPanel = (props) => {
  return (
    <>
      <HeaderTextPanel />
      <Grid.Fluid>
        <ProductCards isPartner={true} shouldShowImage={true} {...props}/>
      </Grid.Fluid>
    </>
  )
}

export default memo(HeaderPanel);
