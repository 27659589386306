/* eslint-disable camelcase */
// Libraries
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Helmet from 'react-helmet';
import { useDispatch } from 'react-redux';
// Global Actions
import { loadPage } from 'src/actions/common';
import { goToStage } from 'src/containers/BookingPage/actions';
// Utils
import { isPlanOnlyCart } from 'src/utils/cart';
// Components
import PageLoader from 'src/components/PageLoader';
import { Grid } from 'src/components/HTKit/Grid';
import SummaryPageContent from './SummaryPageContent';
// Local Actions
import {
  confirmBooking as confirmBookingAction,
  removeAdjustment,
  addCoupon as addCouponAction,
  purchasePlanOnly,
} from './actions';
// Styles
import styles from './styles.scss';

const SummaryPage = ({ loading, cart, ...rest }) => {
  const [promo, setPromo] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPage('summary'));
  }, []);
  const editStage = (stage) => {
    return (event) => {
      event.preventDefault();
      dispatch(goToStage(stage, false, { mode: 'edit' }));
    };
  };

  const confirmBooking = (event = {}, { affirmToken = '' } = {}) => {
    if (event && event.preventDefault) event.preventDefault();

    const planOnly = isPlanOnlyCart(cart);
    if (planOnly) {
      dispatch(purchasePlanOnly());
    } else {
      dispatch(confirmBookingAction({ affirmToken }));
    }
  };
  const promoChange = (event) => setPromo(event.target.value);

  const addCoupon = (event) => {
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();
    dispatch(addCouponAction(promo));
  };

  const removeCoupon = () => {
    dispatch(removeAdjustment('coupon'));
    setPromo('');
  };

  const propObj = {
    addCoupon,
    removeCoupon,
    editStage,
    confirmBooking,
    promoChange,
    promoValue: promo,
  };

  if (loading) return <PageLoader />;
  return (
    <Grid.Fluid classes={cn('site-v2', styles.page)}>
      <Helmet title="Order Summary" />
      <SummaryPageContent {...rest} {...propObj} cart={cart} />
    </Grid.Fluid>
  );
};

SummaryPage.propTypes = {
  cart: PropTypes.shape({
    get: PropTypes.func,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SummaryPage;

/*
  SummaryPage: Final summary page in booking flow.
*/
