import React from 'react';
import PropTypes from 'prop-types';
import StarRating from 'src/components/Elements/StarRating';
import cn from 'classnames';
import Grid from 'src/components/HTKit/Grid/Grid';
import SlideModal from 'src/components/HTKit/Modals/Slide';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import ServiceInfoOptions from './ServiceInfoOptions';
import styles from './styles.scss';

const ModalContents = (props) => {
  const {name, rating, ratingAttributes, ratingCount, cheapestPrice, skuBullets, description, onClick, open, handleClose} = props;
  const productCardsStyles = cn('h3', styles.title);
  const subTitleStyles = cn("p0", styles.subTitle);

  return (
    <SlideModal open={open} handleClose={handleClose} showLogo={false}>
      <Grid.Fluid classes={styles.contentsContainer}>
        <Grid.Row>
          <Grid.Column sm={4} md={8}>
            <h3 className={productCardsStyles}>{name}</h3>
            <p className={styles.price}>
              Starts at
              <span className={styles.serviceMemberPrice}> ${cheapestPrice}</span>
            </p>
            {ratingAttributes && (
              <StarRating
                stars={rating}
                count={ratingCount}
                className={styles.rating}
                size="sm"
              />
            )}

            <p className={subTitleStyles}>{description}</p>
            {skuBullets && <ServiceInfoOptions options={skuBullets} className={styles.options}/>}
            <Button theme={THEMES.V2PRIMARY} className={styles.ctaButton} onClick={onClick}>
              Book Now
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid.Fluid>
    </SlideModal>
  );
}

ModalContents.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  ratingAttributes: PropTypes.object,
  rating: PropTypes.number,
  ratingCount: PropTypes.number,
  cheapestPrice: PropTypes.number,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  skuBullets: PropTypes.arrayOf(PropTypes.shape({})),
}

ModalContents.defaultProps = {
  skuBullets: [{bullet: "Free, Unlimited 24/7 Tech Support"}, {bullet: "Configure any necessary peripheral devices"}, {bullet: "Conceal in-wall rated wires of mask wires if selected (for an additional cost)."}, {bullet: "Connect all necessary video and basic audio components"}],
  description: "Get your SimpliSafe Service set up by a pro. Just choose which components you have to install and let us take it from there."
}

export default ModalContents;
