import { call, put, select } from 'redux-saga/effects';
import { pageNotice, updateUser, pageLoadingError, userIsPristine } from '../../actions/common';
import { selectRoutes } from '../../apiRoutes';
import { errorNotice } from '../../utils/notice';
import { checkPlanSubscriberPhone } from './marketing';

/**
 * Saga function to load user profile information.
 *
 * This function fetches the user profile data from the state, and if not available or forced to do so,
 * it makes an API call to retrieve the user's profile. It handles loading errors, updates the user data
 * in the state, and sets a flag to indicate that the user profile has been loaded.
 *
 * @param {string|null} pageName - Optional. The name of the page where the loading error occurred.
 * @param {boolean} throwLoadingError - Optional. Whether to throw a loading error if encountered.
 * @param {boolean} force - Optional. Whether to force reload the user profile even if it's available in the state.
 * @returns {boolean} - True if the user profile is successfully loaded; otherwise, false.
 */
export function* loadUserProfileSaga(pageName = null, throwLoadingError = false, force = false) {
  const profile = yield select((state) => state.getIn(['user', 'account']));

  let result = true;
  if (!profile || force) {
    const routes = yield call(selectRoutes);
    const requestResult = yield call(routes.users.current, { account: true });

    if (requestResult.err) {
      result = false;
      if (pageName != null) {
        const { errors } = requestResult.data;
        yield put(pageNotice(pageName, errorNotice(errors)));

        if (throwLoadingError) {
          yield put(pageLoadingError(pageName, requestResult));
        }
      }
    } else {
      const { user } = requestResult.data;

      yield call(checkPlanSubscriberPhone, user.account && user.account.hasSubscription);
      yield put(updateUser(user));
    }
    yield put(userIsPristine());
  }

  return result;
}
