import { isArray } from 'lodash';
import { NOTICE_TYPE } from '../constants/common';

const parseNotice = (notice) => isArray(notice) ? notice.join('. ') : notice;

export function defaultNotice(notice, name = 'general') {
  return { type: NOTICE_TYPE.DEFAULT, name, message: parseNotice(notice) };
}

export function successNotice(notice, name = 'general') {
  return { type: NOTICE_TYPE.SUCCESS, name, message: parseNotice(notice) };
}

export function errorNotice(notice, name = 'general') {
  return { type: NOTICE_TYPE.ERROR, name, message: parseNotice(notice) };
}
