import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.scss';

const Breadcrumbs = ({ className, items }) => (
  <div className={cn(className, styles.breadcrumbs)}>
    {items.map((item, index) => {
      const linkStyles = cn(
        'caption',
        { n900: index === items.length - 1, n700: index !== items.length - 1 },
        styles.link,
      );

      return item.link ? (
        <Link key={index} to={item.link} className={linkStyles}>
          {item.label}
        </Link>
      ) : (
        <p key={index} className={linkStyles}>
          {item.label}
        </p>
      );
    })}
  </div>
);

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

export default memo(Breadcrumbs);
