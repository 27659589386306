import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeLoginModal } from './actions';
import LoginModal from './LoginModal';
import { LoginModalPasswordless } from './LoginModal.Passwordless';
import { LOGIN_MODAL_OPTIONS } from './constants';

export const LoginModalManager = () => {
  const dispatch = useDispatch();
  const loginModalState = useSelector((state) => state.getIn(['components', 'loginModal']));

  const isOpen = loginModalState.get('open');
  const isPasswordless = loginModalState.get(LOGIN_MODAL_OPTIONS.IS_PASSWORDLESS);

  const closeModal = () => {
    dispatch(closeLoginModal());
  };

  if (!isOpen) {
    return null;
  }

  if (isPasswordless) {
    return <LoginModalPasswordless isOpen={isOpen} closeModal={closeModal} />;
  }

  return <LoginModal isOpen={isOpen} closeModal={closeModal} />;
};
