import React, { useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Hooks
import { useFetchPlanDetails } from 'src/containers/LandingPage/templates/ProductSingleSku/hooks';
// Utils
import { faqJsonLd } from 'src/utils/jsonLd/index';
import { isImmutable } from 'src/utils/helpers';
import { adjustSku } from 'src/containers/ServicePage/utils';
import { getUpsellPlanIds } from 'src/components/Plans/Pricing/utils';
import { isMobileV2, isTabletV2 } from 'src/utils/ui';
import { createUpsellModalUtils } from 'src/containers/LandingPage/utils';
// Selectors
import { pureUserSelector } from 'src/selectors/user';
// Constants
import { PAGE_NAME as SKU_PAGE_NAME } from 'src/containers/SkuPage/constants';
// Components & Styles
import { DefaultMetaTags } from 'src/components/SEO';
import ServiceHeader from 'src/components/Service/ServiceHeader';
import ServiceBookNow from 'src/components/Service/ServiceBookNow';
import LazyView from 'src/components/LazyLoad/LazyView';
import CustomerReviews from 'src/components/Elements/CustomerReviews';
import GuaranteeBlock from 'src/components/Sections/GuaranteeBlock/lazy';
import FaqV2 from 'src/components/Elements/FaqV2/FaqV2';
import ServiceBanner from 'src/components/Service/ServiceBanner';
import PlanModal, { CTA_BUTTON_TEXT } from 'src/containers/SkuPage/Parts/PlanModal/PlanModal';
import ServicePlanBanner from 'src/components/Elements/Banner/PageBannerWithCta/lazy';
import HeaderBlock from './Parts/HeaderBlock';
import DetailsBlock from './Parts/DetailsBlock/lazy';
import ReadyToBookBlock from './Parts/ReadyToBookBlock/lazy';
import HowItWorksBlock from './Parts/HowItWorksBlock/lazy';
import styles from './styles.scss';

const getFaqJsonLdScript = (faq = []) => {
  if (!faq.length) return {};
  return faqJsonLd({ faq });
};

/*
  DefaultMetaTags/Helmet accepts an array of script objects which it will convert into <script /> tags to be
  inserted in between the <head /> tags. Each object in the array will be a separate <script />. Empty objects
  will not create a script.
*/
const getJsonLdScripts = (sku) => [getFaqJsonLdScript(sku.faq)];

const GoogleProductSingleSku = ({
  landing,
  isPlanPricingTest,
  upsellPlan,
  sku,
  skuWithExtra = {},
}) => {
  if (!landing || !landing.get('skus').size || !Object.keys(skuWithExtra).length) return null;

  const landingJS = isImmutable(landing) ? landing.toJS() : landing;

  const { id: skuId } = skuWithExtra;
  const {
    bannerText = '',
    showUpsellBanner = true,
    plansInfo = {},
    reviews = [],
    showPlanModal = false,
    reviewsScrollOffset,
  } = skuWithExtra.extra;

  const detailsBlockText = landingJS.content && landingJS.content.details;

  const jsonLdScripts = getJsonLdScripts(skuWithExtra);

  const { monthlyID: upsellPlanId } = getUpsellPlanIds({ isPlanPricingTest });

  const { fetchPlanAndOpenModal, addPlan, closePlanModal, startBooking } = createUpsellModalUtils({
    isPlanPricingTest,
    upsellPlanId,
    skuId,
  });

  useFetchPlanDetails({ upsellPlanId });

  const reviewsRef = useRef(null);
  const isMobileOrTablet = isMobileV2() || isTabletV2();

  const handleRatingClick = useCallback(() => {
    window.scrollTo({
      top: reviewsRef.current.offsetTop - reviewsScrollOffset,
    });
  }, [sku]);

  return (
    <div>
      <DefaultMetaTags script={jsonLdScripts} />
      <ServiceHeader service={skuWithExtra} onBook={startBooking} onInfo={fetchPlanAndOpenModal} />
      {skuWithExtra.extra.showStickyBookNow && (
        <ServiceBookNow service={skuWithExtra} onBook={startBooking} />
      )}
      <HeaderBlock
        service={skuWithExtra}
        landing={landing}
        plansInfo={plansInfo}
        showPlanModal={showPlanModal}
        showUpsell={showUpsellBanner}
        onUpsellLearnMoreClick={fetchPlanAndOpenModal}
        onRatingClick={handleRatingClick}
        className={styles.headerBlock}
      />
      <ServiceBanner
        toolkitLabel="Expert Technicians Nationwide"
        showBlobs={false}
        theme="darkNavy"
      />
      <CustomerReviews
        headerText="See what our customers are saying"
        reviews={reviews}
        headerStyles="text-align-center paddingTop-huge1"
        className={styles.reviews}
        carouselItemStyle={styles.reviewItem}
        ref={reviewsRef}
      />
      <LazyView>
        <FaqV2 items={landingJS.faq} className="paddingTop-huge1" />
        <HowItWorksBlock />
        <GuaranteeBlock
          title="Our job’s not done until you are 100% satisfied"
          classes={styles.guaranteeBlockContainer}
        />
        <DetailsBlock text={detailsBlockText} />
        {showUpsellBanner && <ServicePlanBanner text={bannerText} onMore={fetchPlanAndOpenModal} />}
        <ReadyToBookBlock sku={skuWithExtra} landing={landing} />
      </LazyView>
      <PlanModal
        plan={upsellPlan}
        sku={sku}
        visible={showPlanModal}
        closePlanModal={closePlanModal}
        addPlan={addPlan}
        plansInfo={plansInfo}
        isMobileOrTablet={isMobileOrTablet}
        ctaButtonText={CTA_BUTTON_TEXT}
      />
    </div>
  );
};

GoogleProductSingleSku.propTypes = {
  page: PropTypes.object,
  upsellPlan: PropTypes.object,
  isPlanPricingTest: PropTypes.bool,
  landing: PropTypes.object.isRequired,
  sku: PropTypes.object.isRequired,
  skuWithExtra: PropTypes.shape({
    id: PropTypes.number,
    extra: PropTypes.shape({
      bannerText: PropTypes.string,
      showUpsellBanner: PropTypes.bool,
      plansInfo: PropTypes.object,
      reviews: PropTypes.arrayOf(PropTypes.shape({})),
      showPlanModal: PropTypes.bool,
    }),
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { landing } = ownProps;
  const page = state.getIn(['pages', SKU_PAGE_NAME]);
  const user = pureUserSelector(state);
  const immutableSku = landing.getIn(['skus', 0]);
  const skuJs = (immutableSku && immutableSku.toJS()) || {};
  const landingJs = isImmutable(landing) ? landing.toJS() : landing;
  const extra = {
    isMobile: state.get('isMobile'),
    showPlanModal: state.getIn(['pages', SKU_PAGE_NAME, 'showPlanModal']),
    cart: state.getIn(['entities', 'cart']).toJS(),
    user,
    reviews: { [skuJs.id]: landingJs.reviews },
    plan: state.getIn(['pages', SKU_PAGE_NAME, 'plan']).toJS(),
    plansInfo: state.getIn(['pages', SKU_PAGE_NAME, 'plansInfo']).toJS(),
    mobileButtonCta: state.getIn(['components', 'zipCodeTest', 'buttonCta']),
  };
  const skuWithExtra = adjustSku(extra, { ...skuJs, faq: landingJs.faq });
  return {
    upsellPlan: page.get('plan'),
    sku: immutableSku,
    skuWithExtra,
  };
};

export default connect(mapStateToProps, {})(GoogleProductSingleSku);

/*
  NOTES:
  1.Add the components like guarantee block etc inside a lazy load tag like homepage.
  2.To render your work temporarily, add your component here and import this component
    to src/containers/LandingPage/templates/index.js
    In src/containers/LandingPage/LandingPage.js, import this component and on line 52,
    replace ProductSingleSku with GoogleProductSingleSku
*/
