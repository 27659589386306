import Cookies from 'js-cookie';

export function setRedirectCookies (obj) {
  Object.keys(obj).forEach(k => Cookies.set(`redirect_${k}`, obj[k]));
}

export function removeRedirectCookie(name) {
  if (name.substring(0, 9) === 'redirect_') {
    Cookies.remove(name);
  } else {
    Cookies.remove(`redirect_${name}`);
  }
}

export function removeRedirectCookies() {
  Object.keys(Cookies.getJSON()).forEach(k => {
    if (k.substring(0, 9) === 'redirect_') {
      Cookies.remove(k);
    }
  })
}

export function getRedirectCookie() {
  let latest;
  Object.keys(Cookies.getJSON()).forEach(k => {
    if (k.substring(0, 9) === 'redirect_') {
      latest = latest === undefined || Cookies.get(latest) < Cookies.get(k) ? k : latest;
    }
  });
  return latest ? { [latest]: 0 } : {};
}
