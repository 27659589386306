import { fromJS } from 'immutable';
import { LOGIN_REDIRECT } from './LoginForm/constants';

const initialState = fromJS({
  redirectPath: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REDIRECT:
      return state.set('redirectPath', action.path);
    default:
      return state;
  }
};
