import React from 'react';
import PropTypes from 'prop-types';
import { TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES } from 'src/components/AvailabilitySelectorWithConfig/Scheduling.constants';
import AvailabilitySelector from 'src/components/AvailabilitySelector';
import AvailabilitySelectorWithConfig from 'src/components/AvailabilitySelectorWithConfig';

export const LegacyTimeSelector = ({ useTimeWindows, selectedDateTimes, availability }) => {
  if (useTimeWindows) {
    return (
      <AvailabilitySelectorWithConfig
        availability={availability}
        selectedDateTimes={selectedDateTimes}
        config={TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES}
        enableDateToggle={false}
      />
    );
  }
  return <AvailabilitySelector availability={availability} selectedDateTimes={selectedDateTimes} />;
};

LegacyTimeSelector.propTypes = {
  useTimeWindows: PropTypes.bool,
  selectedDateTimes: PropTypes.array.isRequired,
  availability: PropTypes.object.isRequired,
};
