import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'src/components/HTKit/Icon';
import { THEMES } from 'src/components/HTKit/Forms/Button';
import ReviewCount from 'src/components/HTKit/ReviewCount';
import BookNowButton from 'src/components/BookNowButton';
import StartsAtPrice from 'src/components/StartsAtPrice';
import { MembersPrice } from 'src/containers/SkuPage/Parts';
import styles from './styles.scss';

const TitleAndCTAMobile = ({
  title,
  subtitle,
  sku,
  onFAQToggle,
  withFAQ,
  onReviewClick,
  fetchPlanAndOpenModal
}) => {
  const headerStyles = cn(styles.mainTitleContainer, {
    [styles.mainTitleContainerWithInfo]: !!subtitle,
  });

  return (
    <div>
      <section className={headerStyles}>
        <div className="paddingBottom-small2">
          <h1 className="h3">
            {title}
            {withFAQ && (
              <button className={`plainButton ${styles.faqIcon}`} onClick={onFAQToggle} >
                <Icon name="question" />
              </button>
            )}
          </h1>
          {subtitle && (
            <div className="h5 marginBottomY-tiny1">{subtitle}</div>
          )}
        </div>
        <div className={styles.priceContainer}>
          {sku.get('showFree') && (<h2>FREE</h2>)}
          {!sku.get('showFree') && (
            <div>
              <span className={styles.startsAt}>STARTS AT ONLY</span>
              <h3>
                <StartsAtPrice sku={sku} showSubscriptionPrice />
              </h3>
            </div>
          )}
        </div>
      </section>
      <section className={styles.ctaContainer}>
        <div>
          {sku.getIn(['ratingAttributes', 'ratingCount']) > 0 && (
            <a
              href=""
              onClick={onReviewClick}
              className={styles.reviewCount}
            >
              <ReviewCount
                reviews={sku.getIn(['ratingAttributes', 'ratingCount'])}
                stars={sku.getIn(['ratingAttributes', 'rating'])}
                inline
                withHover
                keyStr="mobile"
              />
            </a>
          )}
        </div>
        <div>
          <div className={styles.ctaButtonWrapper}>
            <BookNowButton smallV2 skuId={sku.get('id')} theme={THEMES.V2SCIENCEBLUE} />
          </div>
        </div>
      </section>
      <MembersPrice sku={sku} buttonStyle={styles.membersPrice} onClick={fetchPlanAndOpenModal} />
    </div>
  );
}

TitleAndCTAMobile.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sku: PropTypes.object.isRequired,
  onFAQToggle: PropTypes.func.isRequired,
  withFAQ: PropTypes.bool,
  onReviewClick: PropTypes.func.isRequired,
  fetchPlanAndOpenModal: PropTypes.func.isRequired,
};

TitleAndCTAMobile.defaultProps = {
  title: '',
  subtitle: null,
  withFAQ: true,
};

export default TitleAndCTAMobile;
