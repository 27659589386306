import _ from 'lodash';
import jsonCycle from 'json-cycle';

let instance;
if (process.browser) {
  require('bugsnag-js'); // eslint-disable-line
  instance = window.Bugsnag;
  window.Bugsnag.disableAutoBreadcrumbs();
} else {
  instance = require('bugsnag'); // eslint-disable-line
}

const notifyReleaseStages = ['production', 'staging'];

// eslint-disable-next-line
const stringDecycle = (obj) => {
  let cache = [];

  const stringified = JSON.stringify(obj, function handleKey(key, value) {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        return;
      }
      cache.push(value);
    }
    // eslint-disable-next-line
    return value;
  });
  cache = null;

  return stringified;
};

// https://github.com/bugsnag/bugsnag-ruby/blob/master/lib/bugsnag/helpers.rb
const MAX_STRING_LENGTH = 2500;
const MAX_PAYLOAD_LENGTH = 100000;
const MAX_ARRAY_LENGTH = 30;

const withLimit = (obj) => {
  let value = jsonCycle.decycle(obj);
  if (!tooLong(value)) return value;
  value = trimStringsInValue(value);
  if (!tooLong(value)) return value;
  value = truncateArraysInValue(value);
  if (!tooLong(value)) return value;
  return { bugsnagBodyTruncated: true };
};

const tooLong = (value) => {
  if (typeof value === 'string') {
    return value.length >= MAX_STRING_LENGTH;
  }

  if (typeof value === 'function') {
    return value.toString().length >= MAX_STRING_LENGTH;
  }

  return JSON.stringify(value).length >= MAX_PAYLOAD_LENGTH;
};

const trimStringsInValue = (value) => {
  if (!_.isObject(value) && !_.isArray(value) && typeof value !== 'string') {
    return value;
  }
  if (_.isObject(value)) {
    return trimStringsInObject(value);
  }
  if (_.isArray(value)) {
    return trimStringsInArray(value);
  }
  return trimAsString(value);
};

const trimStringsInObject = (oldValue) => {
  const value = oldValue;
  if (!_.isObject(value)) {
    return value;
  }
  _.forEach(value, (k, v) => {
    value[k] = trimStringsInValue(v);
  });
  return value;
};

const trimStringsInArray = (value) => {
  if (!_.isArray(value)) {
    return value;
  }
  return _.map(value, (v) => {
    return trimStringsInValue(v);
  });
};

const trimAsString = (value) => {
  if (value.length >= MAX_STRING_LENGTH) {
    return value.substring(0, MAX_STRING_LENGTH);
  }
  return value;
};

const truncateArraysInValue = (value) => {
  if (_.isObject(value)) {
    return truncateArraysInObject(value);
  }
  if (_.isArray(value)) {
    return truncateArray(value);
  }
  return value;
};

const truncateArraysInObject = (oldValue) => {
  const value = oldValue;
  if (!_.isObject(value)) {
    return value;
  }
  _.forEach(value, (k, v) => {
    value[k] = truncateArraysInValue(v);
  });
  return value;
};

const truncateArray = (value) => {
  if (!_.isArray(value)) {
    return value;
  }
  return value.slice(0, MAX_ARRAY_LENGTH).map((v) => {
    return truncateArraysInValue(v);
  });
};

const Bugsnag = {
  store: null,
  instance,
  init: () => {
    if (process.browser) {
      try {
        instance.autoNotify = true;
        instance.apiKey = '161bb74f27271aa5a0ac7d6bc1a552d3';
        instance.releaseStage = process.env.NODE_ENV;
        instance.metaData = {};
        instance.notifyReleaseStages = notifyReleaseStages;
        instance.maxDepth = 10;
        instance.beforeNotify = (payload, metaData) => {
          if (Bugsnag.store) {
            // eslint-disable-next-line
            _.assign(metaData, Bugsnag.filteredStore(Bugsnag.store));
          }
        };
      } catch (e) {
        console.log(e); // eslint-disable-line
      }
    } else {
      instance.register('9db7f27997b5a5507c332b5cc8b566cb', {
        releaseStage: process.env.NODE_ENV,
        notifyReleaseStages,
        onUncaughtError: (err) => {
          console.error(err.stack || err); // eslint-disable-line
        },
      });
      instance.onBeforeNotify((notification) => {
        // can't trust data while in async
        if (Bugsnag.store) {
          const { metaData } = notification.events[0];
          _.assign(metaData, Bugsnag.filteredStore(Bugsnag.store));
        }
      });
    }
  },

  notifyException: (e, storeOrExtra) => {
    if (process.browser) {
      instance.notifyException(e, storeOrExtra);
    } else if (storeOrExtra) {
      instance.notify(
        e,
        storeOrExtra.getState ? Bugsnag.filteredStore(storeOrExtra) : storeOrExtra,
      );
    } else {
      instance.notify(e);
    }
  },

  setUser: (user) => {
    if (!process.browser) return;
    instance.user = user;
  },

  setStore: (store) => {
    Bugsnag.store = store;
  },

  leaveActionBreadcrumb: (action) => {
    if (!process.browser) return;
    // https://docs.bugsnag.com/platforms/browsers/#attaching-custom-breadcrumbs
    const string = JSON.stringify({ a: _.omit(action, 'type') }).substring(0, 120);
    instance.leaveBreadcrumb(`action ${action.type}`, string);
  },

  filteredStore: (store) => {
    const state = store.getState().toJS();
    const filtered = _.omit(state, [
      'beforeRouteTransition',
      'chat',
      'tracking',
      'components',
      'segment',
      'routesSchema',
      'layout',
      'router',
      'pages',
      'user',
      'entities',
    ]);
    const limited = withLimit(filtered);
    const separateTabKeys = ['auth', 'newItem'];
    const result = {};
    _.assign(result, _.pick(limited, separateTabKeys), {
      otherState: _.omit(limited, separateTabKeys),
    });
    if (state.entities && state.entities.cart) {
      result.cart = state.entities.cart;
      if (result.cart.breakdown) {
        result.cart.breakdown = JSON.stringify(result.cart.breakdown).substring(1, 1000);
      }
      if (result.cart.items) {
        result.cart.items = JSON.stringify(result.cart.items).substring(1, 1000);
      }
    }
    return result;
  },
};

export default Bugsnag;

export function reducerMiddleware() {
  // eslint-disable-next-line
  return (store) => (next) => (action) => {
    try {
      return next(action);
    } catch (err) {
      if (action) {
        Bugsnag.notifyException(err, {
          action: withLimit(action),
        });
      }
    }
  };
}
