import { getWebFlowBasePathByEnv } from 'src/utils/iframes';

export const techBookingiframeContainerId = "IframeProductPageContainer";
export const techBookingiframeInnerId = 'IframeProductPage';

const baseEnterPath = '/schedule/:techId/products';
const baseRedirectPath = getWebFlowBasePathByEnv('products', 'productss');

/* These are the sources which a user is "rerouted" */
export const IFRAME_SRCS = () => {
  return {
    default: `${baseRedirectPath}/tech-booking`,
    [`${baseEnterPath}`]: `${baseRedirectPath}/`,
  };
};

export const IFRAME = (src = 'default') => {
  const iframeUrls = IFRAME_SRCS();
  const iframeSrc = iframeUrls[src] || iframeUrls.default;

  return `<iframe style="visibility:hidden;" scrolling="no" onload="this.style.visibility='visible'" id="IframeProductsPageDirectBooking" src="${iframeSrc}"></iframe>`;
};
export const CALLER_PAGE = 'productspage';

export const IframeTechProductPageRoutes = Object.keys(IFRAME_SRCS());
