import React, { memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button, { THEMES as BUTTON_THEMES } from 'HTKit/Forms/Button';
import Guarantee from 'src/components/Guarantee';
import ReviewsModal from 'src/components/ReviewsModal';
import { DefaultMetaTags } from 'src/components/SEO';
import {
  togglePlanModal,
  fetchPlansForMonthlyUpsell,
  addPlanToCart,
} from 'src/containers/SkuPage/actions';
import { PlanModal } from 'src/containers/SkuPage/Parts';
import { locationSkuJsonLd, breadcrumbsJsonLd } from 'src/utils/jsonLd/index';
import { locationsRootPath } from 'src/utils/paths';
import { getLocationScrollerName } from '../../utils';
import { GEO_TYPES } from '../../constants';
import SkuDescription from './SkuDescription';
import SkuHeader from './SkuHeader';
import SkuImage from './SkuImage';
import SkuFAQ from './SkuFAQ';
import { LOCATION_HEADER_TRIGGER } from './constants';
import styles from './styles.scss';

const BackgroundView = ({ mapBoxRef }) => (
  <div className={styles.mapHeader}>
    <div className={styles.mapHeaderContainer} ref={mapBoxRef} />
  </div>
);

BackgroundView.propTypes = {
  mapBoxRef: PropTypes.func,
};

/*
  LocationSku
*/
const LocationSku = ({ location, phone, breadcrumbs, skuPage, mapBoxRef, isPlanPricingTest }) => {
  const dispatch = useDispatch();
  const faqRef = useRef();
  const [showReviewsModal, setShowReviewsModal] = useState(false);

  const openReviewsModal = (e) => {
    e.preventDefault();
    setShowReviewsModal(true);
  };

  const closeReviewsModal = () => setShowReviewsModal(false);

  const handleFAQToggle = () => {
    faqRef.current.component.current.handleFAQToggle();
  };

  const fetchPlanAndOpenModal = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(fetchPlansForMonthlyUpsell({ isPlanPricingTest }));
  };

  const closePlanModal = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(togglePlanModal({ modalState: false }));
  };

  const selectPlan = (e) => {
    e.preventDefault();
    const sku = location.get('sku');

    const plansInfo = skuPage.get('plansInfo').toJS();
    const {
      monthly: { id: monthlyPlanId },
    } = plansInfo;

    dispatch(addPlanToCart({ planId: monthlyPlanId, skuId: sku.get('id') }));
  };

  const sku = location.get('sku');
  const faq = sku.get('faq').toJS();
  const showPlanModal = skuPage.get('showPlanModal');
  const upsellPlan = skuPage.get('plan');
  const plansInfoJS = skuPage.get('plansInfo').toJS();
  const title = location.get('headlines').join('');
  const reviews = location.get('ratings');
  const buttonVisible = location.get('type') !== GEO_TYPES.COUNTRY;
  const meta = location.get('meta').toJS();

  return (
    <div className={styles.locationSku}>
      <DefaultMetaTags
        title={meta.title}
        description={meta.description}
        script={[locationSkuJsonLd(sku, location, phone), breadcrumbsJsonLd(breadcrumbs)]}
      />

      <div className={styles.locationSkuTitle}>
        <SkuHeader
          sku={sku}
          isMain
          backgroundView={<BackgroundView mapBoxRef={mapBoxRef} />}
          title={title}
          subtitle={sku.get('name')}
          onReviewClick={openReviewsModal}
          onFAQToggle={handleFAQToggle}
          faq={faq}
          fetchPlanAndOpenModal={fetchPlanAndOpenModal}
        />
      </div>

      <SkuImage sku={sku} />

      <section className="l-content-section">
        {!!faq.length && <SkuFAQ ref={faqRef} faq={faq} className={styles.skuFAQ} />}

        <section className={styles.mobileOnly}>
          <SkuHeader
            sku={sku}
            onReviewClick={openReviewsModal}
            onFAQToggle={handleFAQToggle}
            faq={faq}
            fetchPlanAndOpenModal={fetchPlanAndOpenModal}
          />
        </section>

        <section id={LOCATION_HEADER_TRIGGER}>
          <div className={styles.locationSkuContent}>
            {buttonVisible && (
              <div className={styles.locationSkuContentButton}>
                <Button isLink goTo={locationsRootPath()} mediumV2 theme={BUTTON_THEMES.V2SECONDARY}>
                  {getLocationScrollerName(location)}
                </Button>
              </div>
            )}
            <SkuDescription sku={sku} />
            <Guarantee />
          </div>
        </section>
      </section>

      <PlanModal
        plan={upsellPlan}
        sku={sku}
        visible={showPlanModal}
        closePlanModal={closePlanModal}
        addPlan={selectPlan}
        plansInfo={plansInfoJS}
      />

      <ReviewsModal reviews={reviews} visible={showReviewsModal} onClose={closeReviewsModal} />
    </div>
  );
};

LocationSku.propTypes = {
  location: PropTypes.object,
  phone: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
  skuPage: PropTypes.object.isRequired,
  mapBoxRef: PropTypes.func,
  isPlanPricingTest: PropTypes.bool,
};

export default memo(LocationSku);
