import React, { memo } from 'react';
import PropTypes from 'prop-types';

// to mach viewbox
const DIVIDER = 530;

const Star = ({ color = '#ccd4dd', fillColor = '#faca71', value, size = 25, className }) => {
  const percentage = value.toFixed(2) * 100;
  return (
    <svg
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
    >
      <defs>
        <linearGradient id={`star-${percentage}`}>
          <stop offset="0%" stopColor={fillColor} />
          <stop offset={`${percentage}%`} stopColor={fillColor} />
          <stop offset={`${percentage}%`} stopColor={color} />
          <stop offset="100%" stopColor={color} />
        </linearGradient>
      </defs>
      <g transform={`scale(${size / DIVIDER})`}>
        <path
          fill={`url(#star-${percentage})`}
          d="M275.747 418.909l-164.153 86.3 31.351-182.786-132.802-129.45 183.528-26.668 82.076-166.304 82.076 166.304 183.528 26.668-132.802 129.45 31.351 182.786z"
        />
      </g>
    </svg>
  );
};

Star.propTypes = {
  color: PropTypes.string,
  fillColor: PropTypes.string,
  value: PropTypes.number,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default memo(Star);
