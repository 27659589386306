import { addSkuPath, push } from 'src/utils/paths';
import get from 'lodash/get';
import { addPlanToCart } from 'src/actions/cart';
import { ENV } from 'src/constants/app';
import { VIDEO_DOORBELL_INSTALL_HTH_SMARTCIRCLE_PLAN, VIDEO_DOORBELL_INSTALL_SMARTCIRCLE_PLAN } from 'src/constants/plan';

export const BASE_TYPE = 'Category';
export const FIELD_SALES_GENERIC_ERROR = 'Something went wrong: ';
export const FIELD_SALES_HEADER_TYPE = 'FieldSales';
export const FIELD_SALES_TYPE_BUNDLE_CUSTOM = 'BundleCustom';
export const FIELD_SALES_AGENT_TYPES = Object.freeze({
  TECH: 'hellotech'
});

/* Logger title */
export const FIELD_SALES_LOGGER_TITLE = "Field Sales";
export const DEFAULT_LIST_NAME = 'Start a new order';
/* Page Names */
export const QRCODE_PAGE_NAME = 'qrcode';
export const PAGE_NAME_CURRENT = 'current';
/* Modal Names */
export const MODAL_ENDED_SESSION = 'endedSessionModal';
export const MODAL_NEW_SALES_ORDER = 'newSalesOrderModal';

/*
 Redirect Action types
 - These will denote to what "page/route" you will redirect to.
 - These will also correspond to the unique behavior for this action type
*/
export const REDIRECT_ACTION_TYPES = {
  qrcode: QRCODE_PAGE_NAME
}

// SkuList Specific Constants
export const DEFAULT_SKU_LIST = { categoryName: '', children: [] };
export const DEFAULT_PLAN_MODAL = { show: false, id: '', monthly: '' };
export const ITEM_TYPES = new Map([
  ['PLAN', /plan/i],
  ['SKU', /sku/i],
  ['PRODUCT', /product/i],
  ['BUNDLE_CUSTOM', /bundlecustom/i]
])

/*
* These are mappings for ENV
* In the future, there could be a situation in which a sku lives on prod but a diff sku id in staging
*
* PROD will be the denoted key id, as that will be king/queen.
*
* NOTE: We created a nesting under the ENV just in case there are there other ENV' variables needed.
* */
export const SKU_MAPPINGS_BY_ENV = {
  1247: {
    [ENV.DEVELOPMENT]: {
      skuID: 1247
    },
    [ENV.STAGING]: {
      skuID: 1247
    },
    [ENV.PRODUCTION]: {
      skuID: 1247
    },
  },
  /* Getter */
  getMappedKeyByEnv({ parent, attribute }) {
    if (!parent || !attribute) return '';

    return get(this, `${parent}.${process.env.NODE_ENV}.${attribute}`, '')
  }
}

/*
* Type specific differences between the sellers.
*
* We'll add a proxy here later to catch bad references.
* */
const NEXT_RING_IMAGE = "https://cdn.hellotech.com/public/resources/attachments/88/original/NestHelloVideoDoorbell1-desktop.png?1600195954";
const SHARED_SKUS = [
  {
    id: SKU_MAPPINGS_BY_ENV.getMappedKeyByEnv({ parent: 1247, attribute: 'skuID' }),
    children: [],
    name: "Nest Hello Video Doorbell with Installation",
    type: FIELD_SALES_TYPE_BUNDLE_CUSTOM,
    priceFormatted: '$24.58/monthly',
    image: NEXT_RING_IMAGE,
    action(dispatch) {
      const successCB = () => dispatch(push(addSkuPath(this.id)));
      dispatch(addPlanToCart(VIDEO_DOORBELL_INSTALL_SMARTCIRCLE_PLAN, { successCB }));
    }
  },
  {
    id: SKU_MAPPINGS_BY_ENV.getMappedKeyByEnv({ parent: 1247, attribute: 'skuID' }),
    children: [],
    name: "Nest Hello Video Doorbell with Installation and HelloTech Home",
    type: FIELD_SALES_TYPE_BUNDLE_CUSTOM,
    priceFormatted: '$32.92/monthly',
    image: NEXT_RING_IMAGE,
    action(dispatch) {
      const successCB = () => dispatch(push(addSkuPath(this.id)));
      dispatch(addPlanToCart(VIDEO_DOORBELL_INSTALL_HTH_SMARTCIRCLE_PLAN, { successCB }));
    }
  },
];

export const FIELD_SALES_AGENTS_CONFIG = Object.freeze({
  [FIELD_SALES_AGENT_TYPES.TECH]: {
    type: FIELD_SALES_AGENT_TYPES.TECH,
    rules: {
      sku: {
        concat: true,
        skus: [
          {
            type: BASE_TYPE,
            name: FIELD_SALES_TYPE_BUNDLE_CUSTOM,
            children: [
              ...SHARED_SKUS
            ]
          }
        ],
      },
      validations: {
        requiredCookie: ['agentId','agentType'],
        email: (Yup) => {
          return Yup.string().email('Invalid Email Address')
        }
      }
    }
   }
})

/* The paths in which we allow the user to sit even if they are not logged in */
export const AUTH_EXCLUSIONS = (() => {
  const basePath = '/fieldsales';
  const dynamicActionType = Object.keys(REDIRECT_ACTION_TYPES).map(type => `redirect/${type}`);
  const postPaths = [...dynamicActionType];

  return [basePath].concat(
    // iterate by base type
    Object.values(FIELD_SALES_AGENT_TYPES).map(type => {
       const baseByType = `${basePath}/${type}`;
       // iterate by sub paths within base type
       const allDirs = postPaths.map(pp => `${baseByType}/${pp}`)
       return [...allDirs, baseByType];
    })
  ).flatMap(path => path);
})()

/*
* We need a way to allow the header non-fieldsales pages
* Why? The current client site is not set up to really handle
* these types of flows. so we have to be explict.
*
* Note: Even though we can wrap fieldsales in a proper route,
* */
export const FIELD_SALES_PATH_ALLOWS = [
  '/cart',
  '/tech-support',
  '/products',
  '/add-sku',
  '/fieldsales',
];

const proxyHandler = {
  get(obj, prop) {
    const key = typeof prop === 'string' ? prop.toLowerCase() : prop;
    return key in obj ?
      obj[key] : {
        ...obj.default,
        header: prop
      }
  }
}

export const CUSTOM_DESCRIPTOR_MAPPINGS = new Proxy({
  services: {
    icon: 'tool',
    header: 'Installation & Support',
    subHeader: 'Help with existing devices'
  },

  products: {
    icon: 'box',
    header: 'Devices & Install Bundles',
    subHeader: 'Buy new devices with optional installation'
  },

  memberships: {
    icon: 'credit-card',
    header: 'Memberships',
    subHeader: 'Ongoing, whole-time tech support'
  },

  bundlecustom: {
    icon: 'star-outline',
    header: 'Special Offers',
    subHeader: 'Featured bundles and deals'
  },

  default: {
    icon: '',
    header: DEFAULT_LIST_NAME,
    subHeader: ''
  }
}, proxyHandler);


