import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Hooks
import { Portal } from 'src/hooks/useCreatePortal';
import useScrollTrigger from 'src/hooks/useScrollTrigger';
// Helpers
import { getElementProperty } from 'src/utils/ui';
// Constants
import { HEADER_PORTAL_ID, HEADER_ID } from 'src/components/Header/constants';
// Components & Styles
import styles from './styles.scss';

const SubNav = (
  {
    children,
    wrapperClasses = '',
    innerClasses = '',
    startElementSelector,
    endElementSelector,
    float = false,
    showMobileOnly = false,
    showDesktopOnly = false,
  },
  ref,
) => {
  if (!children) return null;

  const [visible, setVisible] = useState(!startElementSelector);

  const bottomOfTopNav =
    getElementProperty({
      selector: `#${HEADER_ID}`,
      prop: 'offsetHeight',
    }) || 0;
  /*
    Per Design, the callback should fire when the trigger element reaches the bottom of the top nav
    - Using the header height doesn't take into account any static elements we may place above the top nav.
      The PromoBar element disappears on scroll, so it's not an issue at this time -GH Feb 24, 2020
  */
  const showSubNav = (v) => setVisible(v);
  const hideSubNav = (v) => {
    if (!endElementSelector) return;

    const target = document.querySelector(endElementSelector);
    if (!target) return;

    const { bottom } = target.getBoundingClientRect();
    if (bottomOfTopNav < bottom) return;

    setVisible(!v);
  };

  useScrollTrigger({
    trigger: startElementSelector,
    onReach: showSubNav,
    targetY: bottomOfTopNav,
    dependencies: [startElementSelector],
  });

  useScrollTrigger({
    trigger: endElementSelector,
    onReach: hideSubNav,
    targetY: bottomOfTopNav,
    dependencies: [endElementSelector],
  });

  /*
    Elements that are only visible on scroll must have position:absolute applied to them so that they "float" above the
    content and are not inserted into the document flow, pushing down the content.
  */
  const wrapperStyles = cn(
    styles.subnav,
    {
      [styles.visible]: visible,
      [styles.fadeIn]: startElementSelector,
      [styles.absolute]: startElementSelector || float,
      [styles.mobileOnly]: showMobileOnly,
      [styles.desktopOnly]: showDesktopOnly,
    },
    wrapperClasses,
  );

  return (
    <Portal id={HEADER_PORTAL_ID}>
      <div className={wrapperStyles} ref={ref}>
        <div className={cn('htHeaderSubNavPadding', innerClasses)}>{children}</div>
      </div>
    </Portal>
  );
};

SubNav.propTypes = {
  children: PropTypes.node.isRequired,
  wrapperClasses: PropTypes.string,
  innerClasses: PropTypes.string,
  startElementSelector: PropTypes.string,
  endElementSelector: PropTypes.string,
  float: PropTypes.bool,
  showMobileOnly: PropTypes.bool,
  showDesktopOnly: PropTypes.bool,
};

export default forwardRef(SubNav);

/*
  - If no startElementSelector prop is passed the SubNav will be statically positioned and take up space in the document flow.
    It will also be visible at page load and throughout all Y positions

  - startElementSelector indicates you want the SubNav to be invisible on page load and appear when position Y is reached.
  - endElementSelector should be used in tandem with startElementSelector and changes the SubNav from visible to !visible state

  - float - use this prop to show the subnav in front the main content without pushing it down.
*/
