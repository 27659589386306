import React from 'react';
import PropTypes from 'prop-types';
import { InView as ReactInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { pathnameSelector } from 'src/selectors/router';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { checkIsPrerenderUA } from 'src/utils/seo';
import Suspense from '../Suspense';

const LazyView = ({ options = {}, withSuspense = true, defaultLoaderClass, source, children }) => {
  const pathname = useSelector(pathnameSelector);
  const errorSource = `Lazy Load Error (${source || pathname})`;
  const componentToRender = withSuspense ? (
    <Suspense defaultLoaderClass={defaultLoaderClass}>{children}</Suspense>
  ) : (
    children
  );

  /**
   * Lets make sure that if the user agent is 'prerender' we do not try to
   * load below the fold. This will address SEO concerns.
  */
  const isPrerender = checkIsPrerenderUA();

  return (
    <ErrorBoundary errorSource={errorSource} fallback={componentToRender}>
      {!isPrerender ? (
        <ReactInView {...options} triggerOnce>
          {({ inView, ref }) => <div ref={ref}>{inView && componentToRender}</div>}
        </ReactInView>
      ) : (
        componentToRender
      )}
    </ErrorBoundary>
  );
};

LazyView.propTypes = {
  source: PropTypes.string,
  children: PropTypes.node.isRequired,
  options: PropTypes.object,
  withSuspense: PropTypes.bool,
  defaultLoaderClass: PropTypes.string,
};

export default LazyView;

/*
  LazyView - A helper component to lazy load components

  -----

  Usage:

  1. Export the component with lazy.

  In src/components/Something/lazy.js

  ...

  import { lazy } from 'react';

  export default lazy(() => import('./Something'));


  2. Import the lazy component and this component

  import LazyView from 'src/components/LazyLoad/LazyView';
  import Something from 'src/components/Something/lazy';

  ...

  return (
    <div>

      ...

      <LazyView>
        <Grid.Fluid>
          <Grid.Row>
            <Grid.Column>
              <Something />
            </Grid.Column>
          </Grid.Row>
        </Grid.Fluid>
      </LazyView>
    </div>
  );
*/
