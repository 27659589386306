import tmz from 'moment-timezone';
import moment from 'moment';
import { logger } from 'src/utils/logger';

export const FORMAT = {
  DATE: 'MM/DD/YYYY',
  SCHEDULED_FOR: 'MMM D, YYYY [at] h:mma',
  ORDER_PAGE_WITH_MINUTES: 'ddd, MMM D, YYYY [at] h:mma',
  ORDER_PAGE_WITHOUT_MINUTES: 'ddd, MMM D, YYYY [at] ha',
  INVALID: 'Invalid Date',
  TIME: 'h:mma',
};

export const displayTimestamp = (timestamp, format = FORMAT.DATE) =>
  moment(timestamp).format(format);

/**
 * Scheduled Format
 * Will return the following display: Dec 10, 2019 at 4:00pm"
 * @param date
 * @param options { timezone } - accepts a timezone
 * @returns {string}
 */
export const scheduledForFormat = (date, { timezone } = {}) => {
  let formattedDate;

  try {
    formattedDate = timezone
      ? tmz.tz(date, timezone).format(FORMAT.SCHEDULED_FOR)
      : moment(date).format(FORMAT.SCHEDULED_FOR);
  } catch (e) {
    formattedDate = FORMAT.INVALID;
    logger('scheduledFormat')(e);
  }

  return formattedDate === FORMAT.INVALID ? '' : formattedDate;
};

/**
 * Return format of Thurs, Jan 12, 2024 at 2pm if there are no minutes
 * Return format of Thurs, Jan 12, 2024 at 2:30pm if there are minutes
 */
export const formatTimeForOrdersListDisplay = ({ date, timezone }) => {
  const dateHasMinutes = moment(date).minutes() > 0;
  const format = dateHasMinutes
    ? FORMAT.ORDER_PAGE_WITH_MINUTES
    : FORMAT.ORDER_PAGE_WITHOUT_MINUTES;

  let formattedDate;
  try {
    formattedDate = timezone ? tmz.tz(date, timezone).format(format) : moment(date).format(format);
  } catch (e) {
    formattedDate = FORMAT.INVALID;
    logger('formatTimeForOrdersListDisplay')(e);
  }
  return formattedDate === FORMAT.INVALID ? '' : formattedDate;
};

export const THIRTY_MINS_IN_MS = 30 * 60 * 1000;
export const FIFTEEN_MINS_IN_MS = 15 * 60 * 1000;

/**
 * This deals with "dates/times". Convert minutes to hours, in 30 minute increments
 * @param duration
 * @param postPend - pluralizes if greater than 1
 * @returns {number}
 */
export const convertMinutesToHours = (duration, postPend = false) => {
  if (typeof parseInt(duration, 10) !== 'number') return '';

  // Convert minutes to hours
  const hours = duration / 60;

  // Round to the nearest half-hour increment
  const roundedHours = Math.round(hours * 2) / 2;

  // If the duration is greater than 1 and we want a postpend of hrs, pluralize the postPend. add parens for readability
  const result = roundedHours + (postPend ? `hr${roundedHours > 1 ? 's' : ''}` : '');

  return result;
};
