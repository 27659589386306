const BADGE_TYPES = {
  COMPLETED: 'completed',
  CONFIRMED: 'confirmed',
  PENDING: 'pending',
  CANCELLED: 'cancelled',
  WARNING: 'warning',
  RATING: 'rating',
};

export default BADGE_TYPES;
