import { BubbleOverlapBackground, BlobBackground } from './backgrounds';

export const PAGE_NAME = 'createPasswordPage';
export const SET_PASSWORD = 'createPasswordPage/SET_PASSWORD';
export const PASSWORD_CREATED = 'createPasswordPage/PASSWORD_CREATED';

export const THEMES = {
  SHP: 'shp',
  DEFAULT: 'default',
};

export const BACKGROUNDS = {
  [THEMES.SHP]: BubbleOverlapBackground,
  [THEMES.DEFAULT]: BlobBackground,
};

export const FORM_VALIDATIONS = {
  minLength: 6,
  minLengthRequiredMessage: 'Password must be a minimum of 6 characters',
  passwordMustMatchMessage: 'Passwords must match',
};
