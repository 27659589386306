import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as Yup from 'yup';
// Redux Actions
import { userSubmittedEmailLowTechCoverageSegment } from 'src/containers/ServicePage/actions';
// Hooks
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useLeadGeneration } from 'src/utils/leadGenerated/useLeadGeneration';
// Components, Images, Style
import SlideModal from 'HTKit/Modals/Slide';
import LazyView from 'src/components/LazyLoad/LazyView';
import { Grid, Breakpoints } from 'HTKit/Grid';
import Form from 'src/components/HTKit/Forms/Form';
import InputFieldV2 from 'src/components/HTKit/Forms/InputFieldV2';
import Button, { THEMES as BUTTON_THEMES } from 'src/components/HTKit/Forms/Button/index';
import { Picture, TYPES } from 'src/components/Elements/Picture';
import Icon from 'HTKit/Icon';
import { htToast } from 'HTKit/Toast';
import coverageMapDesktop from 'src/images/coverage_map_desktop.png';

import styles from './styles.scss';


// See <SlideModal> component for the list of props passed to this header
const CustomHeader = ({ handleClose = () => {} }) => {
  const iconContainerStyles = cn(
    'flex justifyContentSpaceBetween marginTop-huge1',
    styles.iconContainer,
  );
  return (
    <header>
      <Grid.Fluid>
        <Grid.Row>
          <Grid.Column sm={4} mdOffset={1} md={6} lgOffset={1} lg={10}>
            <div className={iconContainerStyles}>
              <Icon name="logo-big" className={styles.tealText} />
              <Icon name="close" className="n700" onClick={handleClose} />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid.Fluid>
    </header>
  );
};

const CoverageNotAvailableModal = ({ visible, handleClose = () => {} }) => {
  /* Hooks */
  const dispatch = useDispatch();
  const {submitLeadGenerated} = useLeadGeneration();
  /* Text */
  const headerText = "Sorry, we're not in your area yet.";
  const bodyText =
    "We're working to expand our network of technicians to cover more markets including yours. If you’d like, we can give you a heads up when we begin serving your area.";
  const disclaimerText =
    'By selecting Notify Me you agree to receive marketing emails in addition to updates from HelloTech regarding expanded service in your area.';
  const headerTextStyles = cn(styles.tealText, styles.headerText, 'marginBottom-medium1');
  const bodyTextStyles = cn(styles.bodyText, 'marginBottom-large2');

  /* Image */
  // Wrapping <Picture> in <LazyView> prevents the image from loading until the modal is visible
  const imageSources = [
    {
      srcSet: coverageMapDesktop,
      types: TYPES.PNG,
      media: `(min-width: ${Breakpoints.LG_GRID}px)`,
    },
  ];

  /* Form validation */
  const validationSchema = Yup.object().shape({
    emailAddress: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter a valid email address'),
  });
  const formik = useFormik({
    initialValues: { emailAddress: '' },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit(params) {
      const attributes = { email: params.emailAddress };
      dispatch(userSubmittedEmailLowTechCoverageSegment({ attributes }));

      submitLeadGenerated({email: params.emailAddress, source: 'lowTechCoverage', page: 'ServiceZipCodeEntry'});

      htToast('Thank you for your interest!');
      handleClose();
    },
  });
  return (
    <SlideModal open={visible} handleClose={handleClose} customHeader={CustomHeader}>
      <Grid.Fluid>
        <Grid.Row classes="marginTop-large2">
          <Grid.Column sm={4} mdOffset={1} md={6} lgOffset={1} lg={5}>
            <h1 className={headerTextStyles}>{headerText}</h1>
            <p className={bodyTextStyles}>{bodyText}</p>
            <Form classes="marginBottom-medium1" onSubmit={formik.handleSubmit}>
              <Form.Row>
                <Form.Column sm={4} md={8} lg={12}>
                  <InputFieldV2
                    type="text"
                    name="emailAddress"
                    label="Email Address"
                    onChange={formik.handleChange}
                    value={formik.values.emailAddress}
                    error={formik.errors.emailAddress}
                  />
                </Form.Column>
              </Form.Row>
              <Form.Row>
                <Form.Column sm={4} md={8} lg={9} classes={styles.customColumn}>
                  <div className={styles.buttonRow}>
                    <Button
                      type="submit"
                      theme={BUTTON_THEMES.V2PRIMARY}
                      className={styles.confirmButton}
                    >
                      Notify Me
                    </Button>
                    <Button type="button" theme={BUTTON_THEMES.V2SECONDARY} onClick={handleClose}>
                      No Thanks
                    </Button>
                  </div>
                </Form.Column>
              </Form.Row>
            </Form>
            <p className="p2 n700 marginTop-medium1">{disclaimerText}</p>
          </Grid.Column>
          <Grid.Column classes="showDesktop" lg={6}>
            <LazyView>
              <Picture sources={imageSources} className={styles.desktopImage} />
            </LazyView>
          </Grid.Column>
        </Grid.Row>
      </Grid.Fluid>
    </SlideModal>
  );
};

CustomHeader.displayName = 'CustomHeader.CoverageNotAvailableModal';
CustomHeader.propTypes = { handleClose: PropTypes.func };
CoverageNotAvailableModal.propTypes = { visible: PropTypes.bool, handleClose: PropTypes.func };
export default CoverageNotAvailableModal;
