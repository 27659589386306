import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import QtyDropdown from 'src/components/HTKit/Elements/QtyDropdown';
import styles from '../styles.scss';

const OrderBreakdownLineItem = ({
  name,
  lineThroughText,
  amount,
  amountStyles,
  caption,
  className,
  secondary, // color option
  success, // color option
  blue700, // color option
  boldName,
  boldAmount,
  mediumWeightAmount,
  mediumWeightName,
  nameEllipsis,
  shouldShowDropdown,
  shouldShowLink,
  onItemNameClick,
  ...props
}) => {
  const colorOptions = { secondary, success, blue700 };
  const lineThroughStyles = cn('p2 n300 line-through marginRight-tiny1 table-row');
  const nameStyles = cn(styles.itemNameContainer, 'table-row', {
    'text-weight-bold': boldName,
    'text-weight-med': mediumWeightName,
    [styles.ellipsis]: nameEllipsis,
    ...colorOptions,
  });
  const appliedAmountStyles = cn('table-row', amountStyles, {
    'text-weight-bold': boldAmount,
    'text-weight-med': mediumWeightAmount,
    success,
    blue700,
  });
  const itemStyles = shouldShowDropdown ? styles.itemAmountWithQty : styles.itemAmount;
  const serviceNameStyles = shouldShowLink ? cn(styles.showLink) : '';
  const qtyStyles = lineThroughText ? styles.qtyDropdown : styles.qtyDropdownWithoutLinethrough;
  const handleClick = (e) => (shouldShowLink ? onItemNameClick() : e.preventDefault());

  return (
    <p className={className}>
      <div className={styles.itemInfo}>
        <div className={nameStyles} onClick={handleClick}>
          <span className={serviceNameStyles} data-testid='service-name'>{name}</span>
        </div>
        <div className={itemStyles}>
          <QtyDropdown shouldShowDropdown={shouldShowDropdown} className={qtyStyles} {...props} />
          {lineThroughText && <span className={lineThroughStyles}>{lineThroughText}</span>}
          <span className={appliedAmountStyles} data-testid='service-price'>{amount}</span>
        </div>
      </div>
      <div className="caption n700">{caption}</div>
    </p>
  );
};

OrderBreakdownLineItem.propTypes = {
  name: PropTypes.node,
  lineThroughText: PropTypes.node,
  amount: PropTypes.node,
  amountStyles: PropTypes.string,
  caption: PropTypes.node,
  className: PropTypes.string,
  secondary: PropTypes.bool,
  success: PropTypes.bool,
  blue700: PropTypes.bool,
  mediumWeightAmount: PropTypes.bool,
  mediumWeightName: PropTypes.bool,
  boldName: PropTypes.bool,
  boldAmount: PropTypes.bool,
  nameEllipsis: PropTypes.bool,
  shouldShowDropdown: PropTypes.bool,
  shouldShowLink: PropTypes.bool,
  onItemNameClick: PropTypes.func,
};

export default OrderBreakdownLineItem;

/*
  OrderBreakdownLineItem
    - This is the base level component for rendering a name, price and other info for a service.
      There's a design sheet in Abstract with the details.

  Props:
    - secondary: Apply the secondary class to table-row, e.g. gray color
    - success: Apply the secondary class to table-row, e.g. green color
    - boldName: Apply bold font-weight to the name
    - boldAmount: Apply bold font-weight to the amount
    - mediumWeightAmount: Apply medium font-weight to the amount
    - mediumWeightName: Apply medium font-weight to the name
    - nameEllipsis: Prevent line-break and style overflow with an ellipsis
*/
