import React, { memo, useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Slick from 'react-slick';
import flow from 'lodash/flow';
import Icon from 'src/components/HTKit/Icon';
import styles from './styles.scss';

const convertResolutions = (r) =>
  Object.keys(r).reduce(
    (result, key) => [
      ...result,
      {
        breakpoint: +key,
        settings: r[key],
      },
    ],
    [],
  );

const useResolutions = (resolutions) => {
  const [responsive, setResponsive] = useState(convertResolutions(resolutions || {}));

  useEffect(() => {
    setResponsive(convertResolutions(resolutions || {}));
  }, [resolutions]);

  return responsive;
};

// eslint-disable-next-line react/prop-types
const CarouselNext = ({ className, ...rest }) => (
  <span {...rest} className={cn(className, styles.next)}>
    <Icon name="v2-skinny-arrow-right" />
  </span>
);

// eslint-disable-next-line react/prop-types
const CarouselPrevious = ({ className, ...rest }) => (
  <span {...rest} className={cn(className, styles.previous)}>
    <Icon name="v2-skinny-arrow" />
  </span>
);

const baseSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <CarouselNext />,
  prevArrow: <CarouselPrevious />,
};

const Carousel = (
  { className, itemStyle, children, resolutions, equalHeight, infinite = true, ...rest },
  ref,
) => {
  const responsive = useResolutions(resolutions);
  return (
    <Slick
      ref={ref}
      className={cn(
        styles.carousel,
        { [styles.equalHeight]: equalHeight, [styles.nonInfinite]: !infinite },
        className,
      )}
      {...baseSettings}
      responsive={responsive}
      {...rest}
    >
      {children.map((child, index) => (
        <div key={index} className={cn(styles.item, itemStyle)}>
          {child}
        </div>
      ))}
    </Slick>
  );
};

Carousel.propTypes = {
  className: PropTypes.string,
  itemStyle: PropTypes.string,
  children: PropTypes.node,
  resolutions: PropTypes.shape({}),
  equalHeight: PropTypes.bool,
  infinite: PropTypes.bool,
};

export default flow(
  forwardRef,
  memo,
)(Carousel);
