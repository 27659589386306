import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Portal } from 'src/hooks/useCreatePortal';
import { PINNED_FOOTER_ID } from 'src/components/Layout/PinnedContainer/constants';
import { adjustZIndex } from 'src/components/Layout/PinnedContainer/utils';
import styles from './styles.scss';

const ButtonFooter = ({
  children,
  className = '',
  noZIndex = false,
  showMobile = true,
  showTablet = true,
  showDesktop = false,
  transparentBG = false,
}) => {
  const containerStyles = cn(
    'paddingY-small',
    styles.defaultButtonFooter,
    {
      [styles.showMobile]: showMobile,
      [styles.showTablet]: showTablet,
      [styles.showDesktop]: showDesktop,
      [styles.transparent]: transparentBG,
    },
    className,
  );

  useEffect(() => {
    adjustZIndex(noZIndex ? 0 : null);
  }, [noZIndex]);

  useEffect(() => {
    return () => adjustZIndex();
  }, []);

  return (
    <Portal id={PINNED_FOOTER_ID}>
      <div className={containerStyles}>{children}</div>
    </Portal>
  );
};

ButtonFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noZIndex: PropTypes.bool,
  showMobile: PropTypes.bool,
  showTablet: PropTypes.bool,
  showDesktop: PropTypes.bool,
  transparentBG: PropTypes.bool,
};

export default ButtonFooter;

/**
  ButtonFooter

  This component will portal into a sticky container in the app layout. See `AppBase/index.js`
  and `components/Layout/PinnedContainer` to see its usage.
*/
