import { fromJS } from 'immutable';
import { PARTNER_INFO_LOADED } from 'src/constants/partnerMemberships';

/**
 *******************************************************
  {
    number of id or "comapny Name": {
      id: 1,
      company_name: "Hello Alfred",
      logo: {
        medium: "http://localhost/resources/partners/194976/medium/helloAlfred.png?1559763572",
        retina: "http://localhost/resources/partners/194976/retina/helloAlfred.png?1559763572",
        thumb: "http://localhost/resources/partners/194976/thumb/helloAlfred.png?1559763572",
        retina_thumb: "http://localhost/resources/partners/194976/retina_thumb/helloAlfred.png?1559763572"
      },
    }
  }
 *******************************************************
 */
const partnersInitialState = fromJS({});

const partnersReducer = (state = partnersInitialState, action) => {
  const { type, data, key } = action;
  switch (type) {
    case PARTNER_INFO_LOADED: {
      return state.set(key, fromJS(data));
    }
    default:
      return state;
  }
};

export default partnersReducer;
