import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

const defaultBreadcrumbs = fromJS([]);
const defaultChildren = fromJS([]);

// location
const locationStateSelector = (name) => (state) => state.getIn(['entities', 'locations', name]);

const locationSelector = (name) =>
  createSelector(
    locationStateSelector(name),
    (location) => location,
  );

// breadcrumbs
const locationBreadcrumbsStateSelector = (name) =>
  createSelector(
    locationStateSelector(name),
    (location) => (location ? location.getIn(['geoPath'], defaultBreadcrumbs) : defaultBreadcrumbs),
  );

export const locationBreadcrumbsSelector = (name) =>
  createSelector(
    locationBreadcrumbsStateSelector(name),
    (breadcrumbs) => breadcrumbs.toJS(),
  );

// children stuff
const locationChildrenStateSelector = (name) =>
  createSelector(
    locationStateSelector(name),
    (location) => (location ? location.getIn(['children'], defaultChildren) : defaultChildren),
  );

export const locationChildrenSelector = (name) =>
  createSelector(
    locationChildrenStateSelector(name),
    (children) => children.toJS(),
  );

export default locationSelector;
