import React from 'react';
import cn from 'classnames';
// Hooks
import { useSelector } from 'react-redux';
// Selectors
import { showFooterSelector, footerTypeSelector } from 'src/selectors/layout';
import { fieldSaleAgentVerifyLayoutSelector } from 'src/containers/FieldSales/selectors';
// Constants
import { TYPES } from './constants';
// Components & Styles
import LegalSection from './Parts/LegalSection';
import SiteLinks from './Parts/SiteLinks';
import styles from './styles.scss';

const footerStyles = cn(styles.footerContainer, 'site-v2');

export const PartnerFooter = () => (
  <footer className={footerStyles}>
    <LegalSection hideSocial isPartner />
  </footer>
);

export const DefaultFooter = () => {
  const showFooter = useSelector(showFooterSelector);
  const overrideShowFooter = useSelector(fieldSaleAgentVerifyLayoutSelector);
  const type = useSelector(footerTypeSelector);
  const isPartnerFooter = type === TYPES.PARTNER;
  if (!showFooter || overrideShowFooter) return null;
  if (isPartnerFooter) return <PartnerFooter />;
  return (
    <footer className={footerStyles}>
      <SiteLinks />
      <LegalSection />
    </footer>
  );
};

/*
  DefaultFooter: The footer to be displayed across the site
  PartnerFooter: A lighter version of Default Footer

  Notes:
    - The default footer is rendered in src/containers/AppBase/index.js, and is conditionally rendered by a value
      in the Redux store.
    - The partner footer is declared directly in the markup of the target page. On these pages the Redux value of showFooter
      will be false.

*/
