import { toast } from 'react-toastify';
import { extend } from 'lodash';
import { TOAST_CONTAINER_ID } from './constants';

/**
  In case the project uses `react-toastify` for snackbars, we've set an id to the
  toast container to identify that it'll be used for toasts. Here, we're extending
  `toast` to conveniently apply to the toast container.

  See https://fkhadra.github.io/react-toastify/api/toast for full api.

  ----

  Usage (in components or sagas):

  import { htToast } from 'HTKit/Toast';

  htToast('A Message');
*/
const baseOpts = { containerId: TOAST_CONTAINER_ID };

const htToast = (message, opts = {}) => toast(message, { ...baseOpts, ...opts });

const htBaseToast = {};
htBaseToast.success = (message, opts = {}) => toast.success(message, { ...baseOpts, ...opts });
htBaseToast.info = (message, opts = {}) => toast.info(message, { ...baseOpts, ...opts });
htBaseToast.error = (message, opts = {}) => toast.error(message, { ...baseOpts, ...opts });
htBaseToast.warn = (message, opts = {}) => toast.warn(message, { ...baseOpts, ...opts });
htBaseToast.warning = (message, opts = {}) => toast.warning(message, { ...baseOpts, ...opts });
htBaseToast.dark = (message, opts = {}) => toast.dark(message, { ...baseOpts, ...opts });
htBaseToast.clearWaitingQueue = (params = {}) =>
  toast.clearWaitingQueue({ ...baseOpts, ...params });

extend(htToast, toast, htBaseToast);

export { htToast };
