import { SPLIT_KEYS } from 'src/containers/CartPage/Parts/LowTierMembershipUpsell/constants';
import { immutableToJS } from 'src/utils/helpers';

/**
 * Checks if the low-tier (HT Lite) plan is present in the user's cart.
 * The cart object may be an Immutable.js structure or a plain JavaScript object,
 * so it is converted to a plain object if necessary to safely access its properties.
 *
 * Note: This util is meant to work with SPLITIONAME_LOW_TIER_MEMBERSHIP_V1.
 *
 * @param {Object} params - The parameters object.
 * @param {Object|Immutable.Map} params.cart - The user's cart, which can be a plain object or an Immutable.js Map.
 * @param {Object} params.splitConfig - Configuration object for feature flagging, containing plan ids for low-tier plans.
 * @returns {boolean} - Returns true if the ID of the plan in the cart matches the split test plan ID, otherwise false.
 */
export const checkIsLowTierPlanInCart = ({ cart, splitConfig }) => {
  const cartPlanId = Number(immutableToJS(cart)?.plan?.id);
  const splitTestPlanId = Number(splitConfig[SPLIT_KEYS.PLAN_ID]);
  return cartPlanId === splitTestPlanId;
};
