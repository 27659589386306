import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'src/components/HTKit/Icon';
import { getAnnualAndMonthlySplitPlanPricing } from 'src/components/Plans/Pricing/utils';
import { ServiceUpsellPrice } from 'src/components/Service/ServiceInfo/Parts/ServiceUpsellLabel';
import Benefits from '../Benefits';
import styles from './styles.scss';

const MainContent = ({ plan, sku, plansInfo }) => {
  const upsellPlan = plansInfo.monthly;
  const { amount: planCost } = upsellPlan;
  const planInterval = '/month';
  const planCostFormatted = `$${planCost}${planInterval}`;
  const { oldPrice, currentPrice, planPricingSplit } = getAnnualAndMonthlySplitPlanPricing(true);
  const splitPlanPricing = planPricingSplit ? (
    <>
      <ServiceUpsellPrice oldPrice={oldPrice} currentPrice={currentPrice} classes="blue300" />
      {planInterval}
    </>
  ) : (
    planCostFormatted
  );
  // savings
  const basePrice = sku.get('basePrice');
  const cheapestPrice = sku.getIn(['subscriptionPrices', 'cheapestPrice'], basePrice);
  const savings = basePrice - cheapestPrice;
  const savingsFormatted = `$${savings / 100}`;

  return (
    <Fragment>
      <div className={cn('paddingY-medium1 paddingX-medium', styles.bannerWrapper)}>
        <section className={styles.banner}>
          <Icon name="logo-hellotech-home" className={styles.planName} />
          {planPricingSplit ? (
            <p className={cn('p1 text-weight-regular white', styles.subHeader)}>
              {splitPlanPricing} Membership
            </p>
          ) : (
            <p className={cn('p1 text-weight-regular white', styles.subHeader)}>
              Annual Membership for only {splitPlanPricing}
            </p>
          )}
        </section>
      </div>

      <section className={styles.mainContent}>
        <div className={styles.subHeaderWrapper}>
          {savings > 0 && (
            <h4 className={styles.savings}>
              <span className="blue700">Save {savingsFormatted}</span> on this order and get access
              to:
            </h4>
          )}
        </div>
        <Benefits plan={plan} plansInfo={plansInfo} />
      </section>
    </Fragment>
  );
};

MainContent.propTypes = {
  plan: PropTypes.object,
  sku: PropTypes.object,
  plansInfo: PropTypes.object,
};

export default MainContent;
