import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Counter from 'src/utils/counter';
import { FORM_SIZE } from '../constants';
import { Label, ErrorMsg } from '../Parts';
import styles from './styles.scss';

/*
  TextAreaV2

  Notes:
  Design called for increasing the height if there are more than 3 rows of text.
  There's no way to acheive this function without using javascript.
*/
const TextAreaV2 = ({
  size = FORM_SIZE.medium,
  label,
  labelComponent,
  dangerouslySetLabel,
  error,
  value,
  onChange = () => {},
  placeholder,
  containerClass = '',
  textareaClass = '',
  ...rest
}) => {
  const textareaRef = useRef();
  const initialAttributes = useRef({ offsetHeight: 0, borderWidth: 1 });
  const textareaIdRef = useRef(Counter.uniqueId('TextArea-'));
  const textareaId = textareaIdRef.current;

  // Save textarea attributes that are needed for resizing
  useLayoutEffect(() => {
    const { offsetHeight } = textareaRef.current;
    const { borderWidth: borderWidthPixels } = getComputedStyle(textareaRef.current);
    const borderWidth = parseInt(borderWidthPixels, 10);
    initialAttributes.current = { offsetHeight, borderWidth };
  }, []);

  // This is where the resizing happens.
  useLayoutEffect(() => {
    const { offsetHeight: initialOffsetHeight, borderWidth } = initialAttributes.current;
    const currentScrollHeight = textareaRef.current.scrollHeight;

    if (currentScrollHeight > initialOffsetHeight) {
      /*
        Set the height to auto to reset the height.
        The user may remove text that'll delete a row.
      */
      textareaRef.current.style.height = 'auto';
      const totalHeight = textareaRef.current.scrollHeight + borderWidth * 2;
      textareaRef.current.style.setProperty('height', `${totalHeight}px`);
    }
  }, [value]);

  // Styles
  const containerStyles = cn('site-v2', containerClass);

  const textAreaStyles = cn(
    styles.textarea,
    {
      [styles.medium]: size !== FORM_SIZE.large,
      [styles.large]: size === FORM_SIZE.large,
      [styles.withError]: !!error,
    },
    textareaClass,
  );

  return (
    <div className={containerStyles}>
      <Label
        label={label}
        labelComponent={labelComponent}
        dangerouslySetLabel={dangerouslySetLabel}
        size={size}
        inputId={textareaId}
      />
      <textarea
        id={textareaId}
        rows={3}
        ref={textareaRef}
        className={textAreaStyles}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...rest}
      />
      <ErrorMsg error={error} />
    </div>
  );
};

TextAreaV2.propTypes = {
  size: PropTypes.string,
  label: PropTypes.string,
  labelComponent: PropTypes.node,
  dangerouslySetLabel: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  containerClass: PropTypes.string,
  textareaClass: PropTypes.string,
};

export default TextAreaV2;

/*
  TextAreaV2

  Component to render textareas.

  Props:

  containerClass - A class to apply to the overall component.
  textareaClass - A class to apply to <textarea />
  size - The size theme for the input. 'medium' is the default.
  onChange - Function to call when the input changes.
  value - Value of the input.
  label - A label of type string to render.
  labelComponent - A custom component to render for the label.
  dangerouslySetLabel - A string to dangerously set its inner html.
  error - The message to display for the input.
  placeholder - A placeholder.

  Remaining props will be used for the textarea tag.

  --------

  Usage:

  import TextAreaV2 from 'HTKit/Forms/TextAreaV2';
  import { FORM_SIZE } from 'HTKit/Forms/constants';

  ...

  const [inputValue, setInputValue] = useState();

  const onChange = (e) => {
    setInputValue(e.target.value);
  }

  return (
    <TextAreaV2
      label="A happy label"
      size={FORM_SIZE.large}
      value={inputValue}
      onChange=(onChange)
    />
  );
*/
