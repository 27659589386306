/* eslint-disable */
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';
import queryString from 'query-string';
import get from 'lodash/get';

import {
  APP_LOADED,
  COOKIES_LOADED,
  UPDATE_PHONE,
  UPDATE_IP,
} from 'src/containers/AppBase/constants';
import { UPDATE_TRACKING } from 'src/constants/tracking';
import { UPDATE_CART } from 'src/containers/AddSkuPage/constants';
import { UPDATE_CART_PREVIEW } from 'src/containers/AppBase/constants';
import { PLAN_SUBSCRIBER_PHONE_KEY } from 'src/sagas/common/marketing';
import { isImmutable } from 'src/utils/helpers';
import HT_INFO from 'src/constants/hellotech';
import Cookies from 'js-cookie';
import { getCartProperties } from 'src/containers/CartPage/actions';
import { LANDING_PHONE_COOKIE } from 'src/constants/tracking';
import { PAGE_LOADED } from '../constants/common';
import { setLeadGeneratedCookie } from 'src/utils/cookies/leadGenerated';

let analytics = false;
let gtag = false;
let ga = false;
const firstHit = {};
let originalUser = false;
let loaded = false;

const initialState = {
  cartID: null,
  ip: null,
  phone: {
    link: HT_INFO.phone.customers.default.link,
    title: HT_INFO.phone.customers.default.title,
    value: HT_INFO.phone.customers.default.value,
  },
  adwordPhone: null,
  landingPhone: null,
  previousLocation: null,
  currentLocation: null,
  cart: null,
};

function getPhoneObject(phone) {
  const phoneTitle =
    phone.substr(0, 1) +
    '-' +
    phone.substr(1, 3) +
    '-' +
    phone.substr(4, 3) +
    '-' +
    phone.substr(7, 4); // eslint-disable-line
  const phoneLink = `tel:${phone}`;
  return {
    value: phone,
    title: phoneTitle,
    link: phoneLink,
  };
}

const getQueryParamsForSource = () => {
  const source = queryString.parse(window.location.search);

  return Object.keys(source).length ? source : '';
};

const getUser = function(tracker) {
  if (!firstHit[tracker]) {
    firstHit[tracker] = true;
    if (!originalUser && window.APP_STATE) {
      originalUser = window.APP_STATE.user;
    }
  }
  return originalUser;
};

const trackCheckout = function({ analytics, pathName, state }) {
  const cart_id = state.get('cartID');
  const cart = state.get('cart');
  let stepName;

  switch (pathName) {
    case '/clients/signup':
      stepName = 'Service Address';
      break;
    case '/cart/booking/availability':
      stepName = 'Scheduling';
      break;
    case '/cart/booking/payment':
      stepName = 'Payment';
      break;
    case '/cart/booking/summary':
      stepName = 'Summary';
      break;
  }

  if (!stepName) return;

  analytics.track(`Checkout Step Viewed`, {
    step: stepName,
    cart_id,
    cart,
  });
};

const pushToDataLayer = function(params, gt) {
  if (gt) {
    gt('config', 'AW-949056333/tuMMCI-53oIBEM3mxcQD', params);
  }
};

const regExp = /bot|spider/gi;
const userAgent = get(typeof window === 'object' && window, 'navigator.userAgent', '');
const isBot = regExp.test(userAgent);

const trackingReducer = function(state = fromJS(initialState), action) {
  if (typeof window === 'object' && true) {
    // process.env.NODE_ENV === 'production'
    analytics = window.analytics;
    gtag = window.gtag;

    if (analytics) {
      ga = new Promise(function(resolve) {
        if (window.ga) {
          // TODO: remove this whenever we no longer support refresh smart home.
          window.ga('require', 'linker');
          window.ga('linker:autoLink', ['refresh-smart-home.com']);
          resolve(window.ga);
        } else {
          analytics.ready(function() {
            resolve(window.ga);
          });
        }
      });
    }
  }

  switch (action.type) {
    case APP_LOADED: {
      if (action.phone) {
        state = state.set('phone', fromJS(getPhoneObject(action.phone)));
      }
      pushToDataLayer({ phone_conversion_number: state.getIn(['phone', 'title']) }, gtag);
      break;
    }
    case UPDATE_PHONE: {
      let newPhone;
      if (action.soft) {
        newPhone =
          action.phone ||
          state.get('adwordPhone') ||
          state.get('plansPhone') ||
          state.get('landingPhone') ||
          HT_INFO.phone.customers.default.value;
      } else {
        newPhone = action.phone || HT_INFO.phone.customers.default.value;
      }
      state = state.set('phone', fromJS(getPhoneObject(newPhone)));
      pushToDataLayer({ phone_conversion_number: newPhone }, gtag);
      break;
    }
    case COOKIES_LOADED: {
      const cookies = action.cookies;
      let phone;
      if (cookies[LANDING_PHONE_COOKIE.NAME]) {
        phone = cookies[LANDING_PHONE_COOKIE.NAME];
        state = state.set('phone', fromJS(getPhoneObject(phone))).set('landingPhone', phone);
        pushToDataLayer({ phone_conversion_number: phone }, gtag);
      }
      if (cookies['plans-phone']) {
        phone = cookies['plans-phone'];
        state = state.set('phone', fromJS(getPhoneObject(phone))).set('plansPhone', phone);
        pushToDataLayer({ phone_conversion_number: phone }, gtag);
      }
      if (cookies['adword-phone']) {
        phone = cookies['adword-phone'];
        state = state.set('phone', fromJS(getPhoneObject(phone))).set('adwordPhone', phone);
        pushToDataLayer({ phone_conversion_number: phone }, gtag);
      }
      if (cookies[PLAN_SUBSCRIBER_PHONE_KEY]) {
        phone = cookies[PLAN_SUBSCRIBER_PHONE_KEY];
        state = state.set('phone', fromJS(getPhoneObject(phone)));
        pushToDataLayer({ phone_conversion_number: phone }, gtag);
      }
      break;
    }
    case UPDATE_IP: {
      state = state.set('ip', action.ip);
      break;
    }
    case UPDATE_TRACKING: {
      const {
        payload: {
          params: { irclickid },
        },
      } = action;
      if (irclickid) {
        state = state.set('irclickid', irclickid);
      }
      break;
    }
    case PAGE_LOADED:
    case UPDATE_CART_PREVIEW:
    case UPDATE_CART: {
      try {
        // https://hellotech.atlassian.net/browse/HTD-2656 AC #3
        if (action.type === PAGE_LOADED) {
          const {
            utm_adgroup: adGroup = '',
            utm_term: term = '',
            email = '',
            utm_campaign: campaign,
          } = getQueryParamsForSource();

          const user = getUser('analytics');

          // We have an email in url, but no user id, so we need to identify the user
          if (!get(user, 'id') && email) {
            analytics.identify(email, {
              email,
              adGroup,
              term,
              ...(campaign && { campaign }),
            });

            // If we have an email in url, lets set our cookie(s).
            setLeadGeneratedCookie({ email: user.email });
          }
        }

        const cartID = isImmutable(action.cart)
          ? action.cart.get('id')
          : get(action, 'cart.id', '');
        if (cartID) {
          const cart = getCartProperties(action.cart);
          state = state.set('cartID', cartID);
          state = state.set('cart', cart);
        }
      } catch (e) {
        //
      }

      break;
    }
  }
  const meta = action.meta;
  if (meta) {
    if (analytics) {
      const eventsAnalytics = meta.analytics;
      if (eventsAnalytics) {
        eventsAnalytics.forEach((eventAnalytics) => {
          const eventPayload = eventAnalytics.eventPayload || {};
          switch (eventAnalytics.eventType) {
            case 'identify': {
              if (!isBot) {
                const { userId, traits, options } = eventPayload;
                const userID = userId || get(traits, 'id');
                const {
                  utm_adgroup: adGroup = '',
                  utm_term: term = '',
                  utm_campaign: campaign,
                } = getQueryParamsForSource();
                analytics.identify(
                  userID,
                  { ...traits, adGroup, term, ...(campaign && { campaign }) },
                  options,
                );
              }
              break;
            }
            case 'track': {
              if (!isBot) {
                const { event, properties, options, callback } = eventPayload;
                analytics.track(event, properties, options, callback);
              }
              break;
            }
            case 'page': {
              const { category, name, properties, options, callback } = eventPayload;
              const user = getUser('analytics');
              ga.then(function(gaInstance) {
                gaInstance('require', 'GTM-TTSMPMT');
                if (name === '/plans') {
                  const oIndex = Cookies.get('optimize_index');
                  gaInstance('set', 'exp', `Bun0pXrvRI-iYWgJcoYbRw.${oIndex}`);
                }

                if (!isBot) {
                  trackCheckout({ analytics, pathName: name, state });

                  analytics.page(
                    category,
                    name,
                    { ...properties, user_id: user && user.id, ...getQueryParamsForSource() },
                    options,
                    callback,
                  );
                }
              });
              break;
            }
          }
        });
      }
    }
  }
  if (action.type === LOCATION_CHANGE) {
    if (loaded) {
      const pathName = action.payload.location.pathname;
      const previousLocation = state.get('previousLocation');
      if (analytics) {
        const user = getUser('analytics') || {};
        const properties = {
          path: pathName,
          user_id: user && user.id,
          ...getQueryParamsForSource(),
        };
        if (pathName === '/plans') {
          ga.then(function(gaInstance) {
            const oIndex = Cookies.get('optimize_index');
            gaInstance('set', 'exp', `Bun0pXrvRI-iYWgJcoYbRw.${oIndex}`);
            if (!isBot) {
              analytics.page('', pathName, properties);
            }
          });
        } else {
          if (!isBot) {
            trackCheckout({ analytics, pathName: previousLocation, state, type: 'Completed' });
            analytics.page('', pathName, properties);
          }
        }
      }
    } else {
      loaded = true;
    }
  }
  return state;
};

export default trackingReducer;
