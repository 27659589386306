// Libraries
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// Utils && Data
import { useIsPasswordlessCheckoutEnabled } from 'src/containers/Account/AccountPage/AccountPage.hooks';
import { LOGIN_MODAL_OPTIONS } from 'src/containers/LoginPage/Modal/constants';
import { prevented } from 'src/utils/event';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { LOGGED_IN_MENU, LOGGED_OUT_MENU, PROFILE_MENU_ACTIONS } from '../constants';
// Styles
import styles from '../styles.scss';

const LoggedInMenu = ({ signOut, toggleAccountMenu }) => {
  return LOGGED_IN_MENU.map((item, index) => {
    const { link } = item;
    const clickAction = signOut;
    return (
      <div key={index} className={styles.accountMenuItem} onClick={toggleAccountMenu}>
        {link ? (
          <Link to={link}>
            <div>{item.name}</div>
          </Link>
        ) : (
          <Link onClick={clickAction}>
            <div>{item.name}</div>
          </Link>
        )}
      </div>
    );
  });
};

const LoggedOutMenu = ({ openLoginModal, toggleAccountMenu }) => {
  const isPasswordlessCheckoutEnabled = useIsPasswordlessCheckoutEnabled();
  const handleActionClick = (action) => {
    toggleAccountMenu();
    if (action === PROFILE_MENU_ACTIONS.SIGN_IN)
      prevented(
        openLoginModal(false, {
          [LOGIN_MODAL_OPTIONS.IS_PASSWORDLESS]: isPasswordlessCheckoutEnabled,
        }),
      );
  };
  return LOGGED_OUT_MENU.map((item, index) => {
    const { action, link } = item;
    if (action) {
      return (
        <div
          key={index}
          className={styles.accountMenuItem}
          onClick={() => handleActionClick(item.action)}
        >
          <div>{item.name}</div>
        </div>
      );
    }
    if (link) {
      return (
        <div className={styles.accountMenuItem} onClick={toggleAccountMenu}>
          <Link to={link}>
            <div>{item.name}</div>
          </Link>
        </div>
      );
    }
    return null;
  });
};

const AccountMenuItems = ({ isSignedIn, openLoginModal, toggleAccountMenu, signOut }) => {
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, toggleAccountMenu);
  return (
    <div ref={wrapperRef} className={styles.accountMenu}>
      {isSignedIn ? (
        <LoggedInMenu toggleAccountMenu={toggleAccountMenu} signOut={signOut} />
      ) : (
        <LoggedOutMenu toggleAccountMenu={toggleAccountMenu} openLoginModal={openLoginModal} />
      )}
    </div>
  );
};

LoggedInMenu.propTypes = {
  toggleAccountMenu: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

LoggedOutMenu.propTypes = {
  openLoginModal: PropTypes.func.isRequired,
  toggleAccountMenu: PropTypes.func.isRequired,
};

AccountMenuItems.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  toggleAccountMenu: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

AccountMenuItems.defaultProps = {
  isSignedIn: false,
  openLoginModal: () => {},
  toggleAccountMenu: () => {},
  signOut: () => {},
};

export default React.memo(AccountMenuItems);
