import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { Provider } from 'react-redux';
import ErrorBoundary from 'src/components/ErrorBoundary';
import App from './app';

const Root = ({ store, history, context = {} }) => (
  <ErrorBoundary>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App context={context} />
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>
);

Root.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
};

export default Root;
