import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Configs and Utils
import { DEFAULT_TEXTS } from 'src/components/Searchbar/constants';

// Components
import Icon from 'src/components/HTKit/Icon';
import SearchInput from 'src/components/Searchbar/SearchInput';

// Styles
import styles from './styles.scss';

const SearchBar = ({ showSearchBar, searchClick, onClose, className, ...props }) => {
  return (
    <>
      {showSearchBar ? (
        <SearchInput
          iconName="v2-close-icon"
          iconClick={() => onClose()}
          location="Top nav search"
          placeholder={DEFAULT_TEXTS.PLACEHOLDER.topNavSearchText}
          {...props}
        />
      ) : (
        <Icon name="search" onClick={searchClick} className={cn(styles.icon, className)} />
      )}
    </>
  );
};

SearchBar.propTypes = {
  onClose: PropTypes.func,
  searchClick: PropTypes.func,
  showSearchBar: PropTypes.bool,
  className: PropTypes.string,
};

export default SearchBar;
