import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.scss';


const LoadersComponent = ({
  primary = false,
  className = '',
  message = '',
  messageClassName = '',
  loaderSize = '',
  containerClassName = '',
} = {}) => {
  const loaderType = cn(className, {
    [styles.columnLoader]: primary,
    [styles.spinningBubbles]: !primary,
    [styles[loaderSize]]: true,
  });

  return (
    <div className={cn(styles.loadingComponentContainer, containerClassName)}>
      <div className={loaderType} />
      {message && <div className={cn('p2', messageClassName)}>{message}</div>}
    </div>
  );
};

LoadersComponent.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  loaderSize: PropTypes.oneOf(['small', null]),
  message: PropTypes.string,
  messageClassName: PropTypes.string,
  primary: PropTypes.bool,
}
export default LoadersComponent;
