import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { ModalCloseButton } from 'src/components/HTKit/Modals/Parts';
import styles from './styles.scss';

const ModalWrapper = (props, ref) => {
  const { children, isOpen, contentLabel, propsToRender, onClose, hideCloseButton } = props;
  const modalStyles = classNames(styles.dialog, props.modalStyles);
  const closeStyles = classNames(styles.modalClose, props.closeStyles);
  const containerStyles = classNames(styles.modal, props.containerOverride);

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      className={modalStyles}
      overlayClassName={styles.overlay}
      contentLabel={contentLabel}
      {...propsToRender}
    >
      {!hideCloseButton && <ModalCloseButton onClick={onClose} buttonStyle={closeStyles} />}
      <div className={containerStyles} ref={ref}>{children}</div>
    </ReactModal>
  );
};

ModalWrapper.propTypes = {
  contentLabel: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  propsToRender: PropTypes.object,
  modalStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  closeStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node.isRequired,
  hideCloseButton: PropTypes.bool,
  containerOverride: PropTypes.string,
};

ModalWrapper.defaultProps = { containerOverride: '' };

export default React.forwardRef(ModalWrapper);
