import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'src/components/HTKit/Icon';
import { useInputFieldCounter } from './useInputFieldCounter';
import styles from './styles.scss';
import { Label } from '../Parts';

// Note: This is considered to be a one-off component for the time being whose only use
// case is for the RecurringServicePanel. The Design team will add more styling and functionality
// to this component in the future. -GH Nov 29, 2024
export const InputFieldCounter = ({
  startValue,
  minValue,
  maxValue,
  incrementBy,
  decrementBy,
  onChange,
  label,
  className,
}) => {
  const { value, isAtMinValue, isAtMaxValue, increment, decrement } = useInputFieldCounter({
    startValue,
    minValue,
    maxValue,
    incrementBy,
    decrementBy,
    onChange,
  });

  const incrementIconstyles = cn(styles.icon, { [styles.disabled]: isAtMaxValue });
  const decrementIconStyles = cn(styles.icon, { [styles.disabled]: isAtMinValue });

  const incrementHitBoxStyles = cn('plainButton', styles.hitBox, {
    [styles.disabled]: isAtMaxValue,
  });
  const decrementHitBoxStyles = cn('plainButton', styles.hitBox, {
    [styles.disabled]: isAtMinValue,
  });

  return (
    <div>
      {label && <Label label={label} />}
      <div className={cn(styles.container, className)}>
        <button
          type="button"
          onClick={decrement}
          disabled={isAtMinValue}
          className={decrementHitBoxStyles}
        >
          <Icon name="minus-icon" className={decrementIconStyles} />
        </button>
        <div className={cn(styles.value, 'paddingX-tiny1')}>{value}</div>
        <button
          type="button"
          onClick={increment}
          disabled={isAtMaxValue}
          className={incrementHitBoxStyles}
        >
          <Icon name="v2-plus-icon" className={incrementIconstyles} />
        </button>
      </div>
    </div>
  );
};

InputFieldCounter.propTypes = {
  startValue: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  incrementBy: PropTypes.number,
  decrementBy: PropTypes.number,
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
};
