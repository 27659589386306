import { getSegmentAnonymousId } from 'src/utils/cookies';
import {
  SET_BUTTON_CTA,
  SET_SHOW_ZIP_CODE_TEST,
  GET_A_PRICE_CLICK_SEGMENT,
  LOW_TECH_COVERAGE_EMAIL_SUBMISSION,
} from './constants';

export const setButtonCta = (payload) => {
  return {
    type: SET_BUTTON_CTA,
    payload,
  };
};

export const setShowZipCodeTest = (payload) => {
  return {
    type: SET_SHOW_ZIP_CODE_TEST,
    payload,
  };
};

export const getAPriceClickSegment = ({ zipCode, email, treatment }) => {
  return {
    type: GET_A_PRICE_CLICK_SEGMENT,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: 'Geo Get A Price',
            properties: {
              zipCode,
              email,
              treatment,
            },
          },
        },
      ],
    },
  };
};

export function userSubmittedEmailLowTechCoverageSegment({ attributes }) {
  return {
    type: LOW_TECH_COVERAGE_EMAIL_SUBMISSION,
    meta: {
      analytics: [
        {
          eventType: 'identify',
          eventPayload: {
            userId: getSegmentAnonymousId(),
            traits: {
              email: attributes.email,
            },
          },
        },
      ],
    },
  };
}
