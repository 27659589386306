import React from 'react';
import PropTypes from 'prop-types';
import { formatPhoneBasic } from 'src/utils/formatter/phone';
import OrderSummaryLineItem from './Breakdown/OrderSummaryLineItem';
import styles from './styles.scss';

const ContactInfo = ({ name, email, phone }) => {
  const formattedPhone = formatPhoneBasic(phone, '-');

  return (
    <>
      <OrderSummaryLineItem>{name}</OrderSummaryLineItem>
      <OrderSummaryLineItem>{formattedPhone}</OrderSummaryLineItem>
      <OrderSummaryLineItem className={styles.customerInfoEmailLineItem}>
        {email}
      </OrderSummaryLineItem>
    </>
  );
};

ContactInfo.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
};

export default ContactInfo;
