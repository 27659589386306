import { createSelector } from 'reselect';
import { isImmutable} from 'src/utils/helpers';

export const skusStateSelector = (state) => state.getIn(['entities', 'skus'], null);
export const skuStateSelector = (skuId) => (state) => state.getIn(['entities', 'skus', `${skuId}`], null);


export const skusSelector = createSelector(
  skusStateSelector,
  (skus) => skus,
);

export const skuSelector = (skuId) => createSelector(
  skuStateSelector(skuId),
  (sku) => sku,
);

export const skuByIdSelector = (skuId) => createSelector(
  skuStateSelector(skuId),
  (sku) => isImmutable(sku) ? sku.toJS() : sku,
);
