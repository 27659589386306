import get from 'lodash/get';
import { isImmutable } from 'src/utils/helpers';
import { getTotalCreditAdjustments } from 'src/utils/cart';
import {
  REMOVE_ITEM,
  START_BOOKING,
  ITEM_ADDED,
  ITEM_EDITED,
  CLEAR_ITEM_MESSAGES,
  SHOW_MODAL_MULTIPLE_AUTO_APPLY_COUPONS,
  DISMISS_MODAL_MULTIPLE_AUTO_APPLY_COUPONS,
  SET_RELATED_SKUS,
  LOAD_RELATED_SKUS,
  TOGGLE_ADD_ANOTHER_SERVICE,
  REMOVE_PLAN,
  LOAD_PLAN_DETAILS,
  LOAD_ESTIMATED_CART,
  ADD_PLAN_AND_START_BOOKING,
  SHOW_UPSELL_CART_VERSION,
  TOGGLE_REMOVE_PLAN_MODAL,
  TOGGLE_PLAN_HOLDER_MODAL,
  VIEW_CART_SEGMENT_DISPATCH,
  API_ITEM_UPDATED,
  UPDATE_QTY,
  PRODUCT_INSTALLATION_SEGMENT,
  ADD_COUPON_TO_CART,
  ADD_CART_BY_TOKEN_TO_CART,
  ADD_CART_BY_TOKEN_TO_CART_SEGMENT,
} from './constants';

const getCartMutationType = (_cart) => (isImmutable(_cart) ? _cart.toJS() : _cart || {});

export function getCartProperties(_cart) {
  const cart = getCartMutationType(_cart);
  const breakdown = get(cart, 'breakdown', {});
  const items = get(cart, 'items', {});
  const breakdownItems = get(breakdown, 'items', []) || [];
  // try to get partner name from various areas of cart :-(
  const getName = () =>
    cart.partnerName ||
    get(cart, 'partner.name', '') ||
    get(cart, 'partner.landingSeoName', '') ||
    '';
  // try to get additional info by matching in another part of cart
  const getAdditionalItemAttributes = (id) => items.find((item) => item.skuId === id);

  // partner colate
  const partnerObject = get(cart, 'partner', false);
  const partner = partnerObject
    ? {
        partner_id: partnerObject.id,
        partner_name: getName(),
      }
    : null;

  const products = breakdownItems.map((item) => {
    const { skuId } = item;
    return {
      sku_id: skuId,
      base_price: item.amount / 100,
      category: item.category,
      is_remote: get(getAdditionalItemAttributes(skuId), 'remote', false),
      image_url: get(getAdditionalItemAttributes(skuId), 'heroImageUrl', false),
      name: item.name,
      partner,
      physical_product: item.isProduct,
      requires_installation: item.requiresInstallation,
    };
  });

  const { amount: creditsAmount = 0 } = getTotalCreditAdjustments(_cart);

  return {
    cart_id: cart.id,
    amount: breakdown.total / 100,
    savings: breakdown.totalSavings,
    products,
    ...(_cart.workflow && { workflow: _cart.workflow }),
    token: cart.token,
    credits_amount: creditsAmount / 100,
  };
}

export function removeItem(itemIndex, options = {}) {
  return {
    type: REMOVE_ITEM,
    itemIndex,
    options,
  };
}

export function updateQuantity(quantity, index) {
  return {
    type: UPDATE_QTY,
    quantity,
    index,
  };
}

export function startBooking(_cart, { stage, isPasswordless = false } = {}) {
  if (!_cart) {
    return {
      type: START_BOOKING,
      stage,
      isPasswordless,
    };
  }

  return {
    type: START_BOOKING,
    stage,
    isPasswordless,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: 'Checkout Started',
            properties: getCartProperties(_cart),
          },
        },
      ],
    },
  };
}

export function itemAdded(item, cart) {
  const cartItem = cart.items.find((i) => i.skuId === item.skuId) || {};
  const partner = item.partner
    ? {
        partner_id: item.partner.id,
        partner_name: item.partner.landingSeoName,
      }
    : null;

  return {
    type: ITEM_ADDED,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: 'Product Added',
            properties: {
              base_price: item.amount / 100,
              category: item.category,
              id: `${item.skuId}`,
              content_ids: [item.skuId],
              image_url: cartItem.heroImageUrl,
              is_remote: cartItem.remote,
              name: item.name,
              partner,
              price: item.amount / 100,
              sku_id: item.skuId,
              cart: getCartProperties(cart),
              physical_product: item.isProduct,
              requires_installation: item.requiresInstallation,
            },
          },
        },
      ],
    },
  };
}

export const itemEdited = () => ({
  type: ITEM_EDITED,
});

export function clearItemMessages() {
  return {
    type: CLEAR_ITEM_MESSAGES,
  };
}

export function showModalMultipleAutoApplyCoupons() {
  return {
    type: SHOW_MODAL_MULTIPLE_AUTO_APPLY_COUPONS,
  };
}

export function dismissModalMultipleAutoApplyCoupons() {
  return {
    type: DISMISS_MODAL_MULTIPLE_AUTO_APPLY_COUPONS,
  };
}

export function setRelatedSkus(skus) {
  return {
    type: SET_RELATED_SKUS,
    payload: { skus },
  };
}

export function loadRelatedSkus() {
  return {
    type: LOAD_RELATED_SKUS,
  };
}

export function toggleAnotherService(visible) {
  return {
    type: TOGGLE_ADD_ANOTHER_SERVICE,
    payload: { visible },
  };
}

export const removePlan = (payload = {}) => ({
  type: REMOVE_PLAN,
  toastMessage: payload.toastMessage,
});

export const loadPlanDetails = ({ plan }) => ({
  type: LOAD_PLAN_DETAILS,
  plan,
});

export const loadEstimatedCart = ({ cart }) => ({
  type: LOAD_ESTIMATED_CART,
  cart,
});

export const addPlanAndStartBooking = (_cart, { planId, isPasswordless }) => {
  return {
    type: ADD_PLAN_AND_START_BOOKING,
    planId,
    isPasswordless,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: 'Checkout Started',
            properties: getCartProperties(_cart),
          },
        },
      ],
    },
  };
};

export const showUpsellCartVersion = (show = true) => ({
  type: SHOW_UPSELL_CART_VERSION,
  show,
});

export const toggleRemovePlanModal = () => ({
  type: TOGGLE_REMOVE_PLAN_MODAL,
});

export const togglePlanHolderModal = (show) => ({
  type: TOGGLE_PLAN_HOLDER_MODAL,
  show,
});

export const viewCartPage = (_cart = {}) => {
  const cart = getCartMutationType(_cart);

  if (!get(cart, 'breakdown')) return { type: VIEW_CART_SEGMENT_DISPATCH };

  return {
    type: VIEW_CART_SEGMENT_DISPATCH,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: 'Cart Viewed',
            properties: getCartProperties(_cart),
          },
        },
      ],
    },
  };
};

export const apiItemUpdated = () => ({
  type: API_ITEM_UPDATED,
});

export const productUpsellSegment = ({ product }) => {
  return {
    type: PRODUCT_INSTALLATION_SEGMENT,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: 'Product Upsell Clicked',
            properties: {
              sku_id: product.id,
              id: `${product.id}`,
              base_price: product.basePrice / 100,
              price: product.basePrice / 100,
              content_ids: [product.id],
              category: product.category || '',
              image_url: product.heroImageUrl,
              is_remote: product.remote || false,
              name: product.name,
              partner: product.partner,
              physical_product: product.physicalProduct,
              requires_installation: product.requiresInstallation,
            },
          },
        },
      ],
    },
  };
};

export const addCouponToStateForCart = ({
  code = '',
  successMsg = '',
  errorMsg = '',
  withToast = false,
}) => {
  return {
    type: ADD_COUPON_TO_CART,
    code,
    successMsg,
    errorMsg,
    withToast,
  };
};

export function getCartByToken({ token }) {
  return {
    type: ADD_CART_BY_TOKEN_TO_CART,
    token,
  };
}

export function fireIdentifyFromDeepLinkToken(cart) {
  const { clientEmail = '', clientId = '' } = cart;

  return {
    type: ADD_CART_BY_TOKEN_TO_CART_SEGMENT,
    meta: {
      analytics: [
        {
          eventType: 'identify',
          eventPayload: {
            ...(clientId && { userId: clientId }),
            traits: {
              id: clientId,
              email: clientEmail,
            },
          },
        },
      ],
    },
  };
}
