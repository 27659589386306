import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PageLoader from 'src/components/PageLoader';
import { iframeMessaging } from 'src/utils/iframeMessagingPage';
import { generateMessagePageActions } from './baseMessagePageActions';
import styles from './styles.scss';

export const IFRAME_TAG = ({ src, id }) => {
  return `<iframe style="visibility:hidden;" scrolling="yes" onload="this.style.visibility='visible'" id="${id}" src="${src}"></iframe>`;
};

const IframeView = (props) => {
  const { src, callerPageName, containerId, iframeId, additionalActions, isTopNavOpen } = props;

  const containerIdName = containerId || `${callerPageName}-IframeContainer`;
  const iframeIdName = iframeId || `${callerPageName}-IframePage`;

  const [pageLoaded, setPageLoaded] = useState(false);

  const messageAPI = iframeMessaging(generateMessagePageActions(additionalActions));
  const partialMessageAPI = messageAPI({
    props,
    attributes: {
      CALLER_PAGE: callerPageName,
      setPageLoaded,
      containerId: containerIdName,
      iframeId: iframeIdName,
    },
  });

  const eventer = (type) => window[`${type}EventListener`]('message', partialMessageAPI, false);

  useEffect(() => {
    eventer('add');
    return () => eventer('remove');
  }, [isTopNavOpen]);

  if (!src) return null;
  return (
    <section className={styles.iframeContainer}>
      {!pageLoaded && <PageLoader />}
      <div
        id={containerIdName}
        dangerouslySetInnerHTML={{
          __html: IFRAME_TAG({ src, id: iframeIdName }),
        }}
      />
    </section>
  );
};

IframeView.propTypes = {
  src: PropTypes.string,
  containerId: PropTypes.string,
  iframeId: PropTypes.string,
  callerPageName: PropTypes.string,
  additionalActions: PropTypes.func,
  isTopNavOpen: PropTypes.bool,
};

IframeView.defaultProps = { params: '' };

export default IframeView;

/*
  IframeView
  - This component is made to work with the original Webflow iframe implementation. Webflow allows us to attach some custom JS code, which we use
    to send postMessages from the iframe that we catch here in the app. In theory any 3rd party provider that allows custom JS code should
    work with this implementation

  How it works
  ------------
  - The iframe should have code attached that fires postMessage events that our page can capture and then act based on the type of message. E.g.,
    when the iframe loads it will fire a postMessage with its height, and our iframe container will execute a function to  adjust its height
    based on this postMessage

  Usage
  -----
  <IframeView callerPageName="foobarPage" src={https://path/to/content} />

  - At a bare minimum you'll need to provide a callerPageName and a src. The callerPageName is used to filter through
    postMessage actions and also to generate id properties for the HTML tags used in the component (see containerIdName and iframeIdName)
  - If you'd like to add/extend to the functions that respond to the iframe's postMessages see instructions in the generateMessagePageActions
    function in src/components/Elements/IframeView/baseMessagePageActions.js
*/
