import Cookies from 'js-cookie';

const CART_PLAN_UPSELL_COOKIE = 'cartPlanUpsell';
const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;
const ONE_MINUTE = 60000;
const COOKIE_DURATION = process.env.NODE_ENV === 'production' ? SEVEN_DAYS : ONE_MINUTE;

export const getCartPlanUpsellCookie = () => {
  return Cookies.getJSON(CART_PLAN_UPSELL_COOKIE);
}

export const removeCartPlanUpsellCookie = () => {
  Cookies.remove(CART_PLAN_UPSELL_COOKIE);
}

export const setCartPlanUpsellCookie = () => {
  if (typeof window === 'object') {
    const upsellInfo = { hide: true };

    Cookies.set(CART_PLAN_UPSELL_COOKIE, upsellInfo, {
      expires: new Date(Date.now() + (COOKIE_DURATION))
    })
  }
}
