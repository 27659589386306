import React from 'react';
import propTypes from 'prop-types';
import { ModalV2 } from 'src/components/HTKit/Modals/ModalV2';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { SIZES } from 'src/components/HTKit/Forms/constants';

export const MODAL_TYPES = { SERVICE: 'service', MEMBERSHIP: 'membership' };

export const RemoveItemModal = ({ isVisible, hide, onConfirm, type = MODAL_TYPES.SERVICE }) => {
  return (
    <ModalV2
      isVisible={isVisible}
      hide={hide}
      header={`Are you sure you want to remove this ${type}?`}
      size={SIZES.MEDIUM}
      footerElement2={
        <Button theme={THEMES.V2SECONDARY} onClick={hide}>
          Cancel
        </Button>
      }
      footerElement3={
        <Button theme={THEMES.V2PRIMARY} onClick={onConfirm}>
          Remove
        </Button>
      }
    />
  );
};

RemoveItemModal.propTypes = {
  hide: propTypes.func.isRequired,
  isVisible: propTypes.bool.isRequired,
  onConfirm: propTypes.func.isRequired,
  type: propTypes.oneOf([MODAL_TYPES.PLAN, MODAL_TYPES.SERVICE]),
};
