import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import HT_INFO from 'src/constants/hellotech';
import VideoModal from 'src/components/VideoModal';
import Icon from 'src/components/HTKit/Icon';
import previewImage from 'src/images/ht-video-preview-image.jpg';
import styles from './styles.scss';

const HellotechVsGeekSquad = ({ backgroundColor }) => {
  const containerClass = cn(
    'l-content-section',
    styles.container,
    styles[`${backgroundColor}Background`],
  );

  return (
    <section className={containerClass}>
      <h2 className="l-section-header blue500">How HelloTech Compares</h2>
      <div className={styles.containerRow}>
        <div className={styles.containerColumn}>
          <table className={styles.containerTable}>
            <thead>
              <tr>
                <td></td>
                <td>
                  <Icon name="logo-stacked" className={styles.logo} />
                </td>
                <td className="h4">
                  Geek
                  <br />
                  Squad
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Retailer Independent</td>
                <td>
                  <Icon name="check-mark" className={styles.success} />
                </td>
                <td>
                  <Icon name="v2-close-icon" className={styles.error} />
                </td>
              </tr>
              <tr>
                <td>Exact Appointment Times</td>
                <td>
                  <Icon name="check-mark" className={styles.success} />
                </td>
                <td>
                  <Icon name="v2-close-icon" className={styles.error} />
                </td>
              </tr>
              <tr>
                <td>Nationwide Coverage</td>
                <td>
                  <Icon name="check-mark" className={styles.success} />
                </td>
                <td>
                  <Icon name="check-mark" className={styles.success} />
                </td>
              </tr>
              <tr>
                <td>Vetted Techs</td>
                <td>
                  <Icon name="check-mark" className={styles.success} />
                </td>
                <td>
                  <Icon name="check-mark" className={styles.success} />
                </td>
              </tr>
              <tr>
                <td>Fully Insured</td>
                <td>
                  <Icon name="check-mark" className={styles.success} />
                </td>
                <td>
                  <Icon name="check-mark" className={styles.success} />
                </td>
              </tr>
              <tr>
                <td>Remote Services</td>
                <td>
                  <Icon name="check-mark" className={styles.success} />
                </td>
                <td>
                  <Icon name="check-mark" className={styles.success} />
                </td>
              </tr>
              <tr>
                <td>
                  <div>In-Home Support</div>
                </td>
                <td>
                  <Icon name="check-mark" className={styles.success} />
                </td>
                <td>
                  <Icon name="check-mark" className={styles.success} />
                </td>
              </tr>
              <tr>
                <td>Support Plan</td>
                <td>
                  <Icon name="check-mark" className={styles.success} />
                </td>
                <td>
                  <Icon name="check-mark" className={styles.success} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.containerColumn}>
          <div className={styles.containerQuestion}>
            <h4 className={styles.containerQuestionTitle}>Is HelloTech in my town?</h4>
            <p className={cn('p1', styles.containerQuestionAnswer)}>
              <strong>YES!</strong> Our network includes over 7000 background-checked tech
              providers. We provide service in all 50 states in large and small cities alike.
            </p>
          </div>
          <div className={styles.containerQuestion}>
            <p className={cn('p1', styles.containerQuestionAnswer)}>
              We&apos;re committed to your complete satisfaction with our service. If you&apos;re
              not 100% happy, we&apos;ll do whatever it takes to make it right.
            </p>
          </div>
          <div className={styles.containerQuestion}>
            <VideoModal previewImage={previewImage} videoUrl={HT_INFO.video.youtubePromo} />
          </div>
        </div>
      </div>
    </section>
  );
};

HellotechVsGeekSquad.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
};

HellotechVsGeekSquad.defaultProps = {
  backgroundColor: 'white',
};

export default HellotechVsGeekSquad;
