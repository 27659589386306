import computersDesktop from 'src/images/hero/computers-desktop.jpg';
import computersMobile from 'src/images/hero/computers-mobile.jpg';
import homeOfficeSetupDesktop from 'src/images/hero/home-office-setup-desktop.jpg';
import homeOfficeSetupMobile from 'src/images/hero/home-office-setup-mobile.png';
import mobileDesktop from 'src/images/hero/mobile-desktop.jpg';
import mobileMobile from 'src/images/hero/mobile-mobile.jpg';
import smartHomeDesktop from 'src/images/hero/smart-home-desktop.jpg';
import smartHomeMobile from 'src/images/hero/smart-home-mobile.jpg';
import tvAudioDesktop from 'src/images/hero/television-and-audio-desktop.jpg';
import tvAudioMobile from 'src/images/hero/television-and-audio-mobile.jpg';
import tvMountingDesktop from 'src/images/hero/tv-mounting-desktop.jpg';
import tvMountingMobile from 'src/images/hero/tv-mounting-mobile.jpg';
import networkingDesktop from 'src/images/hero/networking-desktop.jpg';
import networkingMobile from 'src/images/hero/networking-mobile.jpg';
import virtualServicesDesktop from 'src/images/hero/virtual-services-desktop.png';
import virtualServicesMobile from 'src/images/hero/virtual-services-mobile.png';

const HERO_IMAGES = {
  computers: {
    desktop: computersDesktop,
    mobile: computersMobile,
  },
  mobile: {
    desktop: mobileDesktop,
    mobile: mobileMobile,
  },
  'smart-home': {
    desktop: smartHomeDesktop,
    mobile: smartHomeMobile,
  },
  'television-and-audio': {
    desktop: tvAudioDesktop,
    mobile: tvAudioMobile,
  },
  'tv-mounting': {
    desktop: tvMountingDesktop,
    mobile: tvMountingMobile,
  },
  networking: {
    desktop: networkingDesktop,
    mobile: networkingMobile,
  },
  'home-office': {
    desktop: homeOfficeSetupDesktop,
    mobile: homeOfficeSetupMobile,
  },
  'virtual-services': {
    desktop: virtualServicesDesktop,
    mobile: virtualServicesMobile,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const resolveHeroImage = (seoName) => HERO_IMAGES[seoName];
