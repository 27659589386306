import { SCRIPT_TYPE } from './constants';
import { makeOfferJsonLd, makePriceRangeJsonLd } from './offer';
import { makeRatingJsonLd } from './rating';
import { makeOrganizationJsonLd } from './organization';
import { makeLocalBusinessJsonLd } from './business';
import { locationAddress } from './utils';

const makeSkuCategoryDefaults = (skuCategory) => {
  return `"@context": "http://schema.org",
  "@type": "Product",
  "name": "${skuCategory.name}",
  "award": "A+ Rating from the Better Business Bureau",
  "brand": {
    "@type": "Organization",
    "name": "HelloTech"
  },
  "description": "${skuCategory.description}"`;
};

const makeSkuCategoryRating = (skuCategory) => {
  const attrs = skuCategory.ratingAttributes;
  if (attrs.rating == null || attrs.ratingCount == null) return '';
  return makeRatingJsonLd({ rating: attrs.rating, count: attrs.ratingCount });
};

export const makeSkuCategoryJsonLd = (skuCategory) => ({
  type: SCRIPT_TYPE,
  innerHTML: `{
  ${makeSkuCategoryDefaults(skuCategory)},
  "url": "${`https://www.hellotech.com/tech-support/for/${skuCategory.seoName}`}",
  ${makeOfferJsonLd(skuCategory.cheapestPrice)},
  ${makeSkuCategoryRating(skuCategory)}
}`,
});

export const makeLocationSkuCategoryJsonLd = (skuCategory, location, phone) => {
  const description = `${location.title} from HelloTech ${location.name}`;
  // const url = `https://www.hellotech.com${locationUrl(location)}/${skuCategory.seoName}`;
  return {
    type: SCRIPT_TYPE,
    innerHTML: `{
  "@context": "http://schema.org",
  ${makeLocalBusinessJsonLd(location.name, description, phone)},
  "parentOrganization": {
    ${makeOrganizationJsonLd()}
  },
  ${makeSkuCategoryRating(skuCategory)},
  "address": "${locationAddress(location)}",
  ${makePriceRangeJsonLd(skuCategory.cheapestPrice)}
}`,
  };
};
