import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import Icon from 'src/components/HTKit/Icon';
import getPlanBenefitsListAndValue from 'src/components/SubscriptionBenefitsBreakdown/utils';
import styles from './styles.scss';

const RemoveSubscriptionContent = ({ onCancel, cart, planDetails, removePlanFromCart }) => {
  const benefitsListAndValue = useMemo(() => {
    return getPlanBenefitsListAndValue({ plan: planDetails });
  }, [planDetails]);

  const { benefitsList, benefitsValue } = benefitsListAndValue;
  const breakdown = cart.get('breakdown');

  const benefitNameStyles = cn('p1 n700', styles.benefit);
  const totalSavings = breakdown.get('totalSavingsFormatted');

  return (
    <>
      <h4>By removing this membership, you will lose these benefits:</h4>
      {totalSavings > 0 && (
        <section className={styles.benefitsContainer}>
          <Icon name="v2-red-circle-x" className={styles.benefitsIcon} />
          <div className={styles.benefitsCopy}>
            <h5>{totalSavings} savings on this order</h5>
          </div>
        </section>
      )}
      <section className={styles.benefitsContainer}>
        <Icon name="v2-red-circle-x" className={styles.benefitsListIcon} />
        <div className={styles.benefitsCopy}>
          <h5>{`$${benefitsValue} value membership features:`}</h5>
          <ul>
            {benefitsList.map((benefit, index) => {
              return <li key={index} className={benefitNameStyles}>{benefit.name}</li>
            })}
          </ul>
        </div>
      </section>
      <section className={styles.buttonWrapper}>
        <Button theme={THEMES.V2PRIMARY} onClick={onCancel}>Keep Benefits</Button>
        <a href className={styles.removeConfirm} onClick={removePlanFromCart}>
          <span>Remove Benefits and Savings</span>
        </a>
      </section>
    </>
  );
}

RemoveSubscriptionContent.propTypes = {
  cart: PropTypes.object.isRequired,
  planDetails: PropTypes.object.isRequired,
  removePlanFromCart: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default RemoveSubscriptionContent;
