import React from 'react';
import PropTypes from 'prop-types';
import StartsAtPrice from 'src/components/StartsAtPrice';
import styles from './styles.scss';

const SkuDescription = ({ sku }) => (
  <div>
    {sku.get('showFree') ? (
      <p className="marginBottom-small text-weight-med showDesktop">FREE</p>
    ) : (
      <p className="marginBottom-small text-weight-med showDesktop">
        STARTS AT ONLY <StartsAtPrice sku={sku} showSubscriptionPrice showNormalPrice={false} />
      </p>
    )}
    <p className="p1 n700">{sku.get('description')}</p>
    <p className="text-weight-bold marginTop-small2 marginBottom-small1">Services Include:</p>
    <ul className={styles.skuBullets}>
      {sku.get('skuBullets').map((bullet, index) => (
        <li key={index}>
          <p className='p1 n700'>{bullet.get('bullet')}</p>
        </li>
      ))}
    </ul>
  </div>
);

SkuDescription.propTypes = {
  sku: PropTypes.object.isRequired,
};

export default React.memo(SkuDescription);
