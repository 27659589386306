import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from 'src/actions/common';
import useAPI from 'src/hooks/useAPI';
import { pureUserSelector } from 'src/selectors/user';
import { noop } from 'src/utils/event';

/**
 * Load user data and store in redux
 */
const useLoadUser = () => {
  const dispatch = useDispatch();
  const api = useAPI();

  const userJS = useSelector(pureUserSelector);

  const loadUser = async ({ errorCB = noop, successCB = noop, force = false } = {}) => {
    if (userJS && !force) {
      successCB(userJS);
      return;
    }

    const { err, data } = await api.users.current();
    if (err) {
      const { errors } = data;
      errorCB(errors);
    } else {
      dispatch(updateUser(data.user));
      successCB(data);
    }
  };

  return {
    loadUser,
  };
};

export default useLoadUser;
