import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PATHS } from 'src/containers/FinishOrder/PINRedemption/constants';

const PinRequired = ({ templateData }) => {
  const partner = templateData.get('partner');
  const path = PATHS[partner];
  const message = path ? (
    <>
      A valid pin number is required to redeem this service. Please enter your pin number{' '}
      <Link to={path()}>on this page</Link> to proceed with booking.
    </>
  ) : (
    'A valid pin number is required to redeem this service.'
  );
  return <div>{message}</div>;
};

PinRequired.propTypes = {
  templateData: PropTypes.shape({
    partner: PropTypes.string.isRequired,
    get: PropTypes.func,
  }),
};

export default PinRequired;
