const CARDS = [
  [/^4/, 'visa'],
  [/^5[1-5]/, 'mastercard'],
  [/^3[47]/, 'amex'],
  [/^3(?:0[0-5]|[68][0-9])/, 'diners'],
  [/^6(?:011|5[0-9]{2})/, 'discover'],
  [/^(?:2131|1800|35\d{3})/, 'jcb'],
]

// common utils
// eslint-disable-next-line import/prefer-default-export
export const cardType = (digits) => (CARDS.find(([regexp]) => regexp.test(digits)) || [])[1]; // matching text with a pattern using regex
