import { PAINTED_DOOR_TEST_IDS } from '../../constants';

const getSkuTitle = (skuID) => {
  switch (skuID) {
    case 879:
      return 'TV Mounting'
    case 880:
      return 'TV Dismount or Remount'
    default:
      return 'Installation';
  }
}

export default getSkuTitle;

export const isPaintedDoorTestSku = ({ skuId }) => {
  return PAINTED_DOOR_TEST_IDS.includes(skuId);
}
