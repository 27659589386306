/* eslint-disable import/no-cycle */
import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import qs from 'query-string';
// Hooks
import { useLocation } from 'react-router';
// HOC
import withLayout from 'src/hoc/withLayout';
// Components
import IframeView from 'src/components/Elements/IframeView';
// Constants
import { DEFAULT_LAYOUT } from 'src/constants/common';
// eslint-disable-next-line import/order
import {
  CALLER_PAGE,
  getIframeSource,
  FAQ_PAGE_CONTAINER_ID,
  FAQ_PAGE_IFRAME_ID,
} from './constants';

import { messagePageActions } from './messagePageActions';

const FAQ = (props) => {
  const location = useLocation();
  const { src: sourceFromQueryParams } = qs.parse(qs.extract(location.search));
  const iframeSourceString = getIframeSource(props.src || sourceFromQueryParams);
  const pageTitle = props.title || 'FAQs';
  const callerPage = props.callerPage || CALLER_PAGE;
  const containerId = props.containerId || FAQ_PAGE_CONTAINER_ID;
  const iframeId = props.iframeId || FAQ_PAGE_IFRAME_ID;

  return (
    <>
      {!props.hideTitle && <Helmet title={pageTitle} />}
      <IframeView
        callerPageName={callerPage}
        src={iframeSourceString}
        additionalActions={messagePageActions}
        containerId={containerId}
        iframeId={iframeId}
      />
    </>
  );
};

FAQ.propTypes = {
  src: PropTypes.string,
  hideTitle: PropTypes.bool,
  title: PropTypes.string,
  callerPage: PropTypes.string,
  iframeId: PropTypes.string,
  containerId: PropTypes.string,
};

export const FAQwithoutLayout = React.memo(FAQ);

export default React.memo(withLayout(DEFAULT_LAYOUT)(FAQ));
