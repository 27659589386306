/* eslint-disable import/prefer-default-export */

const regExTest = /[A-Z]{2}[,]?\s(\d{5}([-_\s.]\d{4})?)(?:\s*\D*)$/;

export const extractZipFromAddress = (address = '') => {
  if (!address) return { zip: null, address };
  const [, match = null] = address.match(regExTest) || [];
  return { zip: match, address };
};

/*

Example Success
----------------
extractZipFromAddress('900 Hilgard Ave, Los Angeles, CA, 90024'); // { zip: "90024", address: "900 Hilgard Ave, Los Angeles, CA, 90024" }

Example Failure
---------------
extractZipFromAddress('900 Hilgard Ave, Los Angeles, CA, 9002412345'); // { zip: "", address: "900 Hilgard Ave, Los Angeles, CA, 9002412345" }

*/
