export function buildHeaderStyles(landing, isMobile = false) {
  let headerStyles = {};

  if (landing.images.front) {
    const { original, mobile } = landing.images.front;
    if (isMobile && mobile) {
      headerStyles = { backgroundImage: `url(${mobile})` };
    } else if (!isMobile && original) {
      headerStyles = { backgroundImage: `url(${original})` };
    }
  }

  return headerStyles;
}

export const buildDiscountProps = (coupon) => {
  if (!coupon) return {};
  return {
    raw: true,
    name: coupon.name,
    type: coupon.value.displayType,
    value: coupon.value.raw,
    info: `With Promo Code ${coupon.text}`,
  };
};
