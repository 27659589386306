import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'src/components/HTKit/Icon';
import styles from './styles.scss';

const StackedHeader = ({ planName, benefitsValue, planAmount, wrapperStyle }) => {
  return (
    <div className={wrapperStyle}>
      <Icon name="logo-house-plus" className={styles.logoIcon} />
      <div className={styles.headerDetails}>
        <h3 className={styles.planName}>{planName}</h3>
        <h6 className={styles.valueBadge}>${benefitsValue} value</h6>
        <p>{planAmount}</p>
      </div>
    </div>
  );
}

StackedHeader.propTypes = {
  planName: PropTypes.string.isRequired,
  benefitsValue: PropTypes.string.isRequired,
  planAmount: PropTypes.string.isRequired,
  wrapperStyle: PropTypes.string,
};

StackedHeader.defaultProps = {
  wrapperStyle: ''
};

export default StackedHeader;

/*
  Currently being used in the cart upsell
*/
