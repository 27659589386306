import { call, put, takeEvery } from 'redux-saga/effects';
import { LOAD_PAGE } from 'src/constants/common';
import { pageLoaded, pageNotice } from 'src/actions/common';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { errorNotice } from '../../../utils/notice';
import { selectRoutes } from '../../../apiRoutes';
import { PAGE_NAME } from './constants';

export function* loadAuditData() {
  const routes = yield call(selectRoutes);
  const response = yield call(routes.plans.subscriptions.audit);

  if (response.err) {
    const { errors } = response.data;
    yield put(pageNotice(PAGE_NAME, errorNotice(errors)));
    yield put(pageLoaded(PAGE_NAME, { audits: [] }));
  } else {
    const { audits } = response.data;
    yield put(pageLoaded(PAGE_NAME, { audits }));
  }
}

export function* pageSaga() {
  yield loadUserProfileSaga(PAGE_NAME, true);
  yield call(loadAuditData);
}

export function* accountScoreCardFlow() {
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
}

export default [accountScoreCardFlow];
