import { takeLatest, select, put, call } from 'redux-saga/effects';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import { invalidForm, clearFormErrors, pageLoaded } from 'src/actions/common';
import { selectRoutes } from 'src/apiRoutes';
import { LOAD_PAGE } from 'src/constants/common';
import { BOOKING_STAGES } from 'src/containers/BookingPage/constants';
import { updateCart } from 'src/containers/AddSkuPage/actions';
import { addressSubmitted } from './actions';
import { SUBMIT_ADDRESS } from './constants';

function* pageSaga() {
  yield put(pageLoaded(BOOKING_STAGES.ADDRESS));
}

function* submitAddressSaga({ payload = {} }) {
  const routes = yield call(selectRoutes);

  const { address = {} } = payload;

  const cart = yield select((state) => state.getIn(['entities', 'cart']));

  yield put(clearFormErrors(BOOKING_STAGES.ADDRESS));
  yield put(requestStarted());
  const response = yield call(routes.cart.goToAvailability, {
    address: {
      fullAddress: address.fullAddress,
      address2: address.address2,
      addressDetails: address.addressDetails,
    },
  });
  yield put(requestFinished());
  if (response.err) {
    const { status } = response.err.response;
    if (status === 422) {
      const { formErrors } = response.data;
      yield put(invalidForm(BOOKING_STAGES.ADDRESS, formErrors));
    } else {
      yield put(displayErrors(response));
    }
  } else {
    yield put(updateCart({ cart: response.data.cart }));
    yield put(addressSubmitted({ updated: cart.get('status') !== BOOKING_STAGES.ADDRESS }));
  }
}

function* pageFlow() {
  yield takeLatest(
    (action) => action.type === LOAD_PAGE && action.page === BOOKING_STAGES.ADDRESS,
    pageSaga,
  );
  yield takeLatest(SUBMIT_ADDRESS, submitAddressSaga);
}

export default [pageFlow];
