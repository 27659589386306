import { SCRIPT_TYPE, RICH_FAQ_SKUS } from './constants';
import { makeRatingJsonLd } from './rating';
import { makeOfferJsonLd, makePriceRangeJsonLd } from './offer';
import { makeOrganizationJsonLd } from './organization';
import { makeLocalBusinessJsonLd } from './business';
import { locationAddress } from './utils';
import { makeOpeningHoursJsonLd } from './hours';

const makeSkuDefaults = (sku) => {
  return `"@context": "http://schema.org",
  "@type": "Product",
  "name": "${sku.name}",
  "award": "A+ Rating from the Better Business Bureau",
  "brand": {
    "@type": "Organization",
    "name": "HelloTech"
  },
  "description": "${sku.description}"`;
};

const makeSkuRating = (sku) => {
  const attrs = sku.ratingAttributes;
  if (attrs.rating == null || attrs.ratingCount == null) return '';
  return makeRatingJsonLd({ rating: attrs.rating, count: attrs.ratingCount });
};

export const makeRichFaqQA = (qa) => {
  return qa.map((elem) => {
    return `{
      "@type": "Question",
      "name": "${elem.questionText}",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "${elem.answerText}"
      }
    }`;
  });
};

export const makeRichFaqScript = ({ seoName, faq }) => {
  /*
    This was originally written for a narrow use case (RICH_FAQ_SKUS). This function needs
    to be compatible with legacy use and also new use case. Shape of questions/answers should be
    { questionText: '', answerText: ''} which corresponds to how faq questions are stored in the DB
  */
  const questionsAnswers = faq || RICH_FAQ_SKUS[seoName] || [];
  if (!questionsAnswers.length) return {};
  return {
    type: SCRIPT_TYPE,
    innerHTML: `{
    "@context": "http://schema.org",
    "@type": "FAQPage",
    "mainEntity": [${makeRichFaqQA(questionsAnswers)}]
    }`,
  };
};

export const makeSkuJsonLd = (sku) => {
  const url = `https://www.hellotech.com/tech-support/${sku.seoName}`;
  return {
    type: SCRIPT_TYPE,
    innerHTML: `{
  ${makeSkuDefaults(sku)},
  "url": "${url}",
  ${makeSkuRating(sku)},
  ${makeOfferJsonLd(sku.basePrice)}
}`,
  };
};

export const makeskuMeta = (sku) => {
  if (!sku) return [];
  const richFaqScript = RICH_FAQ_SKUS[sku.seoName]
    ? [makeRichFaqScript({ seoName: sku.seoName })]
    : [];

  return [makeSkuJsonLd(sku), ...richFaqScript];
};

export const makeLocationSkuJsonLd = (sku, location, phone) => {
  // const url = `https://www.hellotech.com${locationUrl(location)}/${sku.seoName}`;
  const description = `Order a ${sku.name} from HelloTech ${location.name}`;
  return {
    type: SCRIPT_TYPE,
    innerHTML: `{
  "@context": "http://schema.org",
  ${makeLocalBusinessJsonLd(location.name, description, phone)},
  ${makeOpeningHoursJsonLd()},
  "parentOrganization": {
    ${makeOrganizationJsonLd()}
  },
  ${makeSkuRating(sku)},
  "address": "${locationAddress(location)}",
  ${makePriceRangeJsonLd(sku.basePrice)}
}`,
  };
};
