export const TRAFFIC_TYPE = "anonymous-user";
export const REQUEST_TIMEOUT_CLIENT = 7000;
export const REQUEST_TIMEOUT_SDK = 7;
export const PERIODIC_EXECUTOR_SLEEP = 50;

/* Strings to denote on/off/control */
export const ON = 'on';
export const OFF = 'off';
export const CONTROL = 'control';

/* 
* This represents a view that is anything but "control" or "off"
* This is used for multiple treatments that can exist beyond "on" and "off"
*
* Used BY PASSING IN "matchTreatment" prop to SplitTreatmentToggle
*
* Use Case: You'd use this with a config from split to represent a diff view.
*           You would not need this for urls and such, as those can be extracted right from the treatment.
* */
export const ANY = 'any';
