import { useEffect, useState } from 'react';
import useAPI from 'src/hooks/useAPI';
import { useIsPasswordlessCheckoutEnabled } from 'src/containers/Account/AccountPage/AccountPage.hooks';
import { useDispatch, useSelector } from 'react-redux';
import { updateCart } from 'src/containers/AddSkuPage/actions';
import { startBooking, removePlan, removeItem } from 'src/containers/CartPage/actions';
import { pureCartSelector } from 'src/selectors/cart';
import { breakdownItemsSelector } from 'src/containers/CartPage/selectors';
import { addSkuPath, homePath, push } from 'src/utils/paths';
import { logger } from 'src/utils/logger';
import { displayErrorsWithSnack } from 'src/utils/request';
import { BOOKING_STAGES } from 'src/containers/BookingPage/constants';

/**
 * This is being used for Single Service carts, so we can assume that the
 * zero index item is the only item we need to worry about.
 */
export const FIRST_ITEM_INDEX = 0;

export const ITEM_REMOVAL_TOAST_MESSAGES = {
  SERVICE: 'Service removed',
  MEMBERSHIP: 'Membership removed',
};

/**
 * Provides navigation functions for the cart icon display in the top nav header.
 */
export const useCartNavigationDisplay = () => {
  const api = useAPI();
  const dispatch = useDispatch();
  const isPasswordlessCheckoutEnabled = useIsPasswordlessCheckoutEnabled();
  const cart = useSelector(pureCartSelector);

  const cartedPlan = cart?.plan;

  // *****************
  // START BOOKING
  // *****************
  const isInBookingFlow = (pathname = '') => pathname.includes('/cart/booking');
  const startBookingProcess = () =>
    dispatch(
      startBooking(cart, {
        stage: BOOKING_STAGES.VERIFICATION,
        isPasswordless: isPasswordlessCheckoutEnabled,
      }),
    );

  // **********************
  // SKU/SERVICE RELATED
  // **********************
  const [showRemoveServiceModal, setShowRemoveServiceModal] = useState(false);
  const toggleRemoveServiceModal = () => setShowRemoveServiceModal(!showRemoveServiceModal);

  const editSku = (skuId) =>
    dispatch(push(addSkuPath(skuId), { mode: 'edit', itemIndex: FIRST_ITEM_INDEX }));
  const handleRemoveSku = (pathname) => {
    dispatch(removeItem(FIRST_ITEM_INDEX, { toastMessage: ITEM_REMOVAL_TOAST_MESSAGES.SERVICE }));
    if (isInBookingFlow(pathname)) {
      dispatch(push(homePath));
    }
  };

  // **********************
  // PLAN RELATED
  // **********************
  const [showRemovePlanModal, setShowRemovePlanModal] = useState(false);
  const toggleRemovePlanModal = () => setShowRemovePlanModal(!showRemovePlanModal);

  const handleRemovePlan = (pathname) => {
    dispatch(removePlan({ toastMessage: ITEM_REMOVAL_TOAST_MESSAGES.MEMBERSHIP }));

    const cartHasNoItems = cart.items.length === 0;
    const shouldRedirectToHome = isInBookingFlow(pathname) && cartHasNoItems;
    if (shouldRedirectToHome) {
      dispatch(push(homePath));
    }
  };

  // ************************
  // CART BREAKDOWN RELATED
  // ************************

  /**
   * Simplified breakdown items for display in the cart navigation.
   * These items contain only the essential information needed for the cart displays.
   *
   * Note: This is a simplified version of the full breakdown items. It includes
   * properties like name, quantity, amount, subTotal, image, and priceType,
   * but may exclude more detailed information present in the full breakdown.
   */
  const simplifiedBreakdownItems = useSelector(breakdownItemsSelector) || [];
  const cartBreakdown = cart?.breakdown;
  useEffect(() => {
    const fetchCartWithBreakdown = async () => {
      try {
        api.toggleLoader(true);
        const response = await api.cart.find({ breakdown: true });
        if (response.err) {
          dispatch(displayErrorsWithSnack(response));
          throw new Error(response.err);
        }
        dispatch(updateCart({ cart: response.data.cart, replace: true }));
      } catch (e) {
        logger('useCartNavigationDisplay')(e);
      } finally {
        api.toggleLoader(false);
      }
    };

    // For logged-in users with a fresh page load the full cart with breakdown is
    // not fetched by default, so we need to fetch it here.
    if (!cartBreakdown) fetchCartWithBreakdown();
  }, [cartBreakdown]);

  return {
    /** Breakdown of items in the cart */
    breakdownItems: simplifiedBreakdownItems,
    /** Plan in the cart */
    cartedPlan,
    /** Callback for editing a sku */
    editSku,
    /** Is the cart in the booking flow? */
    isInBookingFlow,
    /** Callback for removing a plan */
    removePlan: handleRemovePlan,
    /** Callback for removing a sku */
    removeSku: handleRemoveSku,
    /** Show the remove plan modal? */
    showRemovePlanModal,
    /** Show the remove service modal? */
    showRemoveServiceModal,
    /** Callback for starting the booking process */
    startBookingProcess,
    /** Toggle the remove plan modal */
    toggleRemovePlanModal,
    /** Toggle the remove service modal */
    toggleRemoveServiceModal,
  };
};
