// Libraries
import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSpring, animated } from 'react-spring';
// Helpers
import { numberWithComma } from 'src/components/MemberBenefits/helpers';
import useEventListener from 'src/hooks/useEventListener';
import { TOTAL_COMPLETED_SERVICES_ID } from 'src/containers/SimpliSafeLandingPage/constants';
// Styles
import styles from './styles.scss';

const TotalCompletedServices = ({ containerId, text, servicesCompleted, duration }) => {
  if (!servicesCompleted) return null;

  const [active, setActive] = useState(false);
  const serviceStyles = cn('p1', styles.servicesCompleted);
  const totalNumberStyles = cn('h1', styles.totalNumber);

  let baseConfig = { total: active ? servicesCompleted : 0 };
  if (duration) baseConfig = { ...baseConfig, config: { duration } };
  const count = useSpring(baseConfig);

  const handleAnimation = () => {
    const elem = document.querySelector(`#${containerId}`);
    if (active || !elem) return;
    const targetPosition = elem.offsetTop + elem.offsetHeight * 0.666;
    const bottomOfViewport = Math.round(window.scrollY + window.innerHeight);
    const shouldStart = bottomOfViewport >= targetPosition;
    if (shouldStart) setActive(true);
  };

  useLayoutEffect(() => {
    handleAnimation();
  }, []);

  useEventListener({ eventName: 'scroll', handler: handleAnimation });

  return (
    <div className={styles.container} id={containerId}>
      <article className={styles.content}>
        <h1 className={totalNumberStyles}>
          {!count.total && <span>0</span>}
          <animated.div>
            {count.total.interpolate((x) => numberWithComma({ num: Math.floor(x), maxDigits: 10 }))}
          </animated.div>
        </h1>
        <p className={serviceStyles}>{text}</p>
      </article>
    </div>
  );
};

TotalCompletedServices.propTypes = {
  containerId: PropTypes.string,
  duration: PropTypes.number,
  text: PropTypes.string,
  servicesCompleted: PropTypes.number.required,
};

TotalCompletedServices.defaultProps = {
  containerId: TOTAL_COMPLETED_SERVICES_ID,
  text: 'Services Completed',
  servicesCompleted: 0,
};

export default TotalCompletedServices;
