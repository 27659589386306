import { all, call, put, takeEvery } from 'redux-saga/effects';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import { pageNotice, pageLoaded, invalidForm, clearFormErrors } from 'src/actions/common';
import { LOAD_PAGE } from 'src/constants/common';
import { selectRoutes } from 'src/apiRoutes';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { errorNotice } from 'src/utils/notice';
import { SUBMIT_DEVICE, LOAD_MODELS, UPDATE_DEVICE, UPDATE_WARRANTY, DELETE_DEVICE } from './constants'
import { deviceSubmitted, modelsLoaded, deviceDeleted, warrantyUpdated, deviceUpdated } from './actions';

const ACCOUNT_DEVICES_PAGE_NAME = 'accountDevices';

export function* pageSaga() {
  const routes = yield call(selectRoutes);
  const { 
    productsResponse: { data: { product_categories: categories } },
    purchaseLocationsResponse: { data: { purchase_locations: purchaseLocations } },
    clientProductsResponse: { data: { client_products_services: clientProducts } } 
  } = yield all({
    productsResponse: call(routes.products.categories),
    purchaseLocationsResponse: call(routes.products.purchaseLocations),
    clientProductsResponse: call(routes.clientProducts.list),
  })

  yield loadUserProfileSaga(ACCOUNT_DEVICES_PAGE_NAME, true);
  yield put(pageLoaded(ACCOUNT_DEVICES_PAGE_NAME, { clientProducts, categories, purchaseLocations }));
}

export function* loadModelsSaga({ payload }) {
  const { categoryId, makeId } = payload;
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const requestResult = yield call(routes.products.list, { product_category_id: categoryId, product_make_id: makeId })
  yield put(requestFinished());
  if (requestResult.err) {
    const { errors } = requestResult.data;
    yield put(pageNotice(ACCOUNT_DEVICES_PAGE_NAME, errorNotice(errors)));
  } else {
    const { products } = requestResult.data;
    yield put(modelsLoaded({ models: products }))
  }
}

export function* submitDeviceSaga({ payload }) {
  const { productId } = payload
  const routes = yield call(selectRoutes);
  yield put(clearFormErrors('accountDevices'));
  yield put(requestStarted());
  const response = yield call(routes.clientProducts.createDevice, { client_product: { product_id: productId }} );
  yield put(requestFinished());
  if (response.err) {
      const { formErrors } = response.data;
      yield put(invalidForm('accountDevices', formErrors));
  } else {
    const { data: { client_products_services: clientProducts }} = yield call(routes.clientProducts.list)
    const { data: { product_categories: categories }} = yield call(routes.products.categories)
    const { data: { purchase_locations: purchaseLocations }} = yield call(routes.products.purchaseLocations)
    yield put(pageLoaded(ACCOUNT_DEVICES_PAGE_NAME, { clientProducts, categories, purchaseLocations }));
    yield put(deviceSubmitted(true));
    yield put(modelsLoaded({ models: [] }))
  }
}

export function* updateDeviceSaga({ payload }) {
  const { clientProductId, form } = payload;
  const { productId } = form
  const routes = yield call(selectRoutes);
  yield put(clearFormErrors('accountDevices'));
  yield put(requestStarted());
  const response = yield call(routes.clientProducts.updateDevice, { id: clientProductId }, { product_id: productId } );
  yield put(requestFinished());
  if (response.err) {
    const { formErrors } = response.data;
    yield put(invalidForm('accountDevices', formErrors));
    yield put(deviceUpdated(false));
  } else {
    const { data: { client_products_services: clientProducts }} = yield call(routes.clientProducts.list)
    const { data: { product_categories: categories }} = yield call(routes.products.categories)
    const { data: { purchase_locations: purchaseLocations }} = yield call(routes.products.purchaseLocations)
    yield put(pageLoaded(ACCOUNT_DEVICES_PAGE_NAME, { clientProducts, categories, purchaseLocations }));
    yield put(deviceUpdated(true));
    yield put(modelsLoaded({ models: [] }))
  }
}

export function* updateWarrantySaga({ payload }) {
  const { purchaseLocationId, datePurchased, deviceId } = payload
  const routes = yield call(selectRoutes);
  yield put(clearFormErrors('accountDevices'));
  yield put(requestStarted());
  const response = yield call(routes.clientProducts.updateDevice, { id: deviceId }, {purchased_at: datePurchased, purchase_location_id: purchaseLocationId});
  yield put(requestFinished());
  if (response.err) {  
    const { formErrors } = response.data;
    yield put(invalidForm('accountDevices', formErrors));
    yield put(warrantyUpdated(false));
  } else {
    const { data: { client_products_services: clientProducts }} = yield call(routes.clientProducts.list)
    const { data: { product_categories: categories }} = yield call(routes.products.categories)
    const { data: { purchase_locations: purchaseLocations }} = yield call(routes.products.purchaseLocations)
    yield put(pageLoaded(ACCOUNT_DEVICES_PAGE_NAME, { clientProducts, categories, purchaseLocations }));
    yield put(warrantyUpdated(true));
  }
}

export function* deleteDeviceSaga({ payload }) {
  const { id } = payload;
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.clientProducts.deleteDevice, { id });
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrors(response));
    yield put(deviceDeleted(false));
  } else {
    yield put(deviceDeleted(id));
  }
}

export function* accountDevicesFlow() {
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === ACCOUNT_DEVICES_PAGE_NAME, pageSaga);
  yield takeEvery(SUBMIT_DEVICE, submitDeviceSaga);
  yield takeEvery(LOAD_MODELS, loadModelsSaga);
  yield takeEvery(UPDATE_WARRANTY, updateWarrantySaga);
  yield takeEvery(UPDATE_DEVICE, updateDeviceSaga);
  yield takeEvery(DELETE_DEVICE, deleteDeviceSaga);
}

export default [
  accountDevicesFlow,
];