/* eslint-disable import/prefer-default-export */

export const FORM_SIZE = {
  extraSmall: 'extraSmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export const SIZES = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

export const SELECT_FIELD_LABEL_ID = 'select-field-label-id';
