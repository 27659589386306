import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PageLoader from 'src/components/PageLoader';

class AsyncRoute extends PureComponent {
  static propTypes = {
    props: PropTypes.object.isRequired,
    loadingPromise: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.mounted = false;
    this.state = { loaded: false };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { loadingPromise } = this.props;
    loadingPromise.then((module) => {
      this.component = module.default;
      if (this.mounted) {
        this.setState({ loaded: true });
      }
    });
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { loaded } = this.state;
    const { props } = this.props;
    if (loaded) return <this.component {...props} />;
    return <PageLoader />;
  }
}

export default AsyncRoute;
