import {
  REMOTE,
  BENEFITS_DESCRIPTION,
  SMART_HOME_BENEFITS_DETAILS,
} from 'src/constants/planBenefits';
import { HIDE_PRODUCT_CONNECTED_HOME } from 'src/constants/app';
import { PLAN_PRICING_TYPE } from 'src/constants/plan';
import filterIncludedSkus from './helper';

const getPlanBenefitsListAndValue = ({ plan }) => {
  const benefitsInfo = { benefitsList: [], benefitsValue: 0 };
  let benefits = [];
  let totalValue = 0;
  if (!plan || plan.isEmpty()) return benefitsInfo;
  const truckRollFee = plan.get('truckRollFee');
  const discount = plan.getIn(['productDiscount', 'amount']);
  const pricingType = plan.get('pricingType');

  const { includedBenefits, includedTotalValue } = filterIncludedSkus({ plan });

  // remote
  benefits.push({
    name: 'Unlimited 24/7 Remote Support',
    mobileName: 'Unlimited 24/7 Online Support',
    cost: 'INCLUDED',
    value: REMOTE.valueFormatted,
    description: BENEFITS_DESCRIPTION.remote,
  });
  totalValue += REMOTE.value;

  // benefits from included skus
  benefits = benefits.concat(includedBenefits);
  totalValue += includedTotalValue;

  // In-home Services
  if (truckRollFee > 0) {
    const percentage = () => {
      const percentValue = truckRollFee * 100;
      return {
        name: `${percentValue}% Savings on In-Home Services`,
        mobileName: `${percentValue}% Savings on In-Home Services`,
      };
    };
    const fixed = {
      name: `Only $${truckRollFee} In-Home Services`,
      mobileName: `$${truckRollFee} In-Home Services`,
    };

    const benefitType = pricingType === PLAN_PRICING_TYPE.percentage ? percentage() : fixed;

    benefits.push({
      name: benefitType.name,
      cost: 'INCLUDED',
      mobileName: benefitType.mobileName,
      description: BENEFITS_DESCRIPTION.inHomeServices,
    });
  }

  if (!HIDE_PRODUCT_CONNECTED_HOME) {
    // product discount
    benefits.push({
      name: SMART_HOME_BENEFITS_DETAILS.productDiscount(discount).header,
      cost: 'INCLUDED',
      mobileName: 'Product Discount',
      description: BENEFITS_DESCRIPTION.productDiscount(discount),
    });
  }

  // home tech specialist
  benefits.push({
    name: 'Home Tech Specialist',
    cost: 'INCLUDED',
    mobileName: 'Home Tech Specialist',
    description: BENEFITS_DESCRIPTION.homeTechSpecialist,
  });

  benefitsInfo.benefitsList = benefits;
  benefitsInfo.benefitsValue = totalValue;

  return benefitsInfo;
};

export default getPlanBenefitsListAndValue;

/*
  Utilized in
  cart page line items (when plan is in cart)
  cart page remove plan modal
  upsell cart
  upsell Modal in services page and field sales
*/
