import React from 'react';
import PropTypes from 'prop-types';
import SelectField from 'react-select';
import { Label } from 'src/components/HTKit/Forms/Parts';
import { DropdownIndicator } from './TimeOptionsSelectField.Indicator';
import { Option } from './TimeOptionsSelectField.Option';
import { NoOptionsMessage } from './TimeOptionsSelectField.NoOptionsMessage';
import styles from './styles.scss';

export const TimeOptionsSelectField = ({ options, value, onChange, className, label }) => {
  return (
    <div className={className}>
      <Label label={label} />
      <SelectField
        options={options}
        value={value}
        onChange={onChange}
        components={{ IndicatorSeparator: () => null, DropdownIndicator, NoOptionsMessage, Option }}
        classNamePrefix="time-options-select"
        className={styles.timeOptionsContainer}
        placeholder="Select..."
      />
    </div>
  );
};

TimeOptionsSelectField.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
};
