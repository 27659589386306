import { createSelector, createStructuredSelector } from 'reselect';
import { pageSelector as selectPage } from 'src/selectors/page';
import { isMobileOrTabletSelector } from 'src/selectors/resolution';
import { formatter as currencyFormatter } from 'src/components/MemberBenefits/helpers';
import { PAGE_NAME } from './constants';

const pageSelector = selectPage(PAGE_NAME);

const pageIsLoadingSelector = createSelector(
  pageSelector,
  (page) => page.get('loading'),
);

const completedStageSelector = createSelector(
  pageSelector,
  (page) => page.get('completedStage'),
);

const pageErrorsSelector = createSelector(
  pageSelector,
  (page) => page.get('errors').toJS(),
);

const recipientFormSelector = createSelector(
  pageSelector,
  (page) => page.get('holder'),
);

const purchaserFormSelector = createSelector(
  pageSelector,
  (page) => page.get('purchaser'),
);

const activateAtSelector = createSelector(
  pageSelector,
  (page) => page.get('activateAt'),
);

const formatCardData = (page) => {
  return {
    brand: page.get('brand'),
    digits: page.get('digits'),
    expiration: page.get('expiration'),
  };
};

const cardInfoSelector = createSelector(
  pageSelector,
  (page) => page && formatCardData(page),
);

const tokenSelector = createSelector(
  pageSelector,
  (page) => page.get('token'),
);

const planDetailsSelector = createSelector(
  pageSelector,
  (page) => page.get('planDetails'),
);

const giftItemSelector = createSelector(
  planDetailsSelector,
  (details) =>
    details && {
      name: `${details.get('name')} Gift Membership`,
      priceFormatted: currencyFormatter().format(details.get('amount')),
    },
);

const totalAmountSelector = createSelector(
  planDetailsSelector,
  (details) => details && currencyFormatter().format(details.get('total') || details.get('amount')),
);

export const planIdSelector = createSelector(
  planDetailsSelector,
  (details) => details && details.get('id'),
);

const couponSelector = createSelector(
  pageSelector,
  (page) => {
    const coupon = page.get('coupon');
    return (
      coupon && {
        ...coupon,
        name: `"${coupon.text}" coupon discount`,
        priceFormatted: currencyFormatter().format(coupon.discount),
      }
    );
  },
);

export default createStructuredSelector({
  activateAt: activateAtSelector,
  cardInfo: cardInfoSelector,
  completedStage: completedStageSelector,
  giftItem: giftItemSelector,
  recipientForm: recipientFormSelector,
  purchaserForm: purchaserFormSelector,
  isMobileOrTablet: isMobileOrTabletSelector,
  page: pageSelector,
  pageIsLoading: pageIsLoadingSelector,
  pageErrors: pageErrorsSelector,
  planId: planIdSelector,
  planDetails: planDetailsSelector,
  token: tokenSelector,
  totalAmount: totalAmountSelector,
  coupon: couponSelector,
});
