import { merge } from 'lodash';
import { homePath } from 'src/utils/paths';
import { TRANSPARENT_THEMES } from 'src/components/Header/TopNavHeader/constants';
import { TYPES as FOOTER_TYPES } from 'src/components/Footer/constants';
import { FIELD_SALES_HEADER_TYPE } from '../containers/FieldSales/constants';

export const NOOP = 'common/NOOP';
export const LAYOUT_UPDATE = 'common/LAYOUT_UPDATE';
export const NEW_ROUTE_SCHEMA = 'common/NEW_ROUTE_SCHEMA';
export const INVALID_FORM = 'common/INVALID_FORM';
export const CLEAR_FORM_ERRORS = 'common/CLEAR_FORM_ERRORS';
export const CLEAR_FORM_ERROR = 'common/CLEAR_FORM_ERROR';
export const UPDATE_USER = 'common/UPDATE_USER';
export const USER_IS_PRISTINE = 'common/USER_IS_PRISTINE';
export const UPDATE_USER_SEGMENT_IDENTIFY = 'common/UPDATE_USER_SEGMENT_IDENTIFY';
export const LOAD_PAGE = 'pages/LOAD_PAGE';
export const PAGE_LOADED = 'pages/PAGE_LOADED';
export const PAGE_LOADING_ERROR = 'pages/PAGE_LOADING_ERROR';
export const PAGE_NOTICES = 'pages/PAGE_NOTICES';
export const CLEAR_PAGE_NOTICES = 'pages/CLEAR_PAGE_NOTICES';
export const PAGE_NOTICE = 'pages/PAGE_NOTICE';
export const CLEAR_PAGE_NOTICE = 'pages/CLEAR_PAGE_NOTICE';
export const VALIDATE_FORM = 'common/VALIDATE_FORM';
export const SET_ZIP_CODE_RESTRICTION = 'common/SET_ZIP_CODE_RESTRICTION';
export const ADD_URL_PARAM_COUPON = 'common/ADD_URL_PARAM_COUPON';

// internal common stuff
export const DATE_FORMAT = 'MM/DD/YYYY';
export const SHORT_DATE_FORMAT = 'ddd, MMM D';
export const CALL_CLICK = 'CALL_CLICK';
export const NOTICE_TYPE = { DEFAULT: 'default', ERROR: 'error', SUCCESS: 'success', INFO: 'info' };

// 3rd party partners
// Why does the front end care about partners? This should be transparent.
export const PARTNERS = {
  SAMSUNG: 'samsung',
  WALMART: 'walmart',
  TARGET: 'target',
  COMCAST: 'comcast',
  XFINITY: 'xfinity',
  QVC: 'qvc',
  ANGI: 'angi',
  COBRAND: 'cobrand',
};

// Header Types (To Do - more to refactor)
export const HEADER_TEMPLATES = {
  Booking: 'BookingHeader',
  Plain: 'PlainHeader',
  Samsung: 'SamsungHeader',
  Walmart: 'WalmartHeader',
  Optimum: 'OptimumHeader',
  Suddenlink: 'SuddenlinkHeader',
  TargetEasyInstall: 'TargetEasyInstallHeader',
  Cobrand: 'CobrandHeader',
  DirectBooking: 'DirectBookingHeader',
  PaloAltoOkyo: 'PaloAltoOkyo',
  FieldSales: FIELD_SALES_HEADER_TYPE,
  EVPortal: 'EVPortalHeader',
  EVBooking: 'EVBookingHeader',
};

// General Layout
export const DEFAULT_LAYOUT = {
  header: {
    template: null, // options: BookingHeader, SamsungHeader, WalmartHeader
    actionableLogo: true,
    homeUrl: homePath(),
    showHeader: true,
    showSiteLinks: true, // controls top nav's service links and account icon, and hamburger
    transparent: false,
    theme: null,
    partnerKey: null, // for CobrandHeader usage only
  },
  footer: {
    show: true,
    type: FOOTER_TYPES.DEFAULT, // options: DEFAULT and PARTNER
  },
  main: {
    withNavy050BG: false,
    withNavy050BGMobile: false,
    withPaloAltoBG: false,
  },
};

export const showPartnerFooter = { footer: { show: true, type: FOOTER_TYPES.PARTNER } };

export const DEFAULT_LAYOUT_NO_SITE_LINKS = merge({}, DEFAULT_LAYOUT, {
  header: { showSiteLinks: false },
});

/* INITIAL_LAYOUT is used to set initial values in the Redux store. See src/selectors/layout.js  */
export const INITIAL_LAYOUT = merge({}, DEFAULT_LAYOUT_NO_SITE_LINKS, { footer: { show: false } });

export const DEFAULT_HOME_PAGE_LAYOUT = merge({}, DEFAULT_LAYOUT);
export const HOME_PAGE_LAYOUT_WITH_TRANSPARENT_TOP_NAV = merge({}, DEFAULT_LAYOUT, {
  header: { transparent: true, theme: TRANSPARENT_THEMES.INVERTED },
});
export const DEFAULT_LAYOUT_NO_SITE_LINKS_NO_FOOTER = merge({}, DEFAULT_LAYOUT, {
  header: { showSiteLinks: false },
  footer: { show: false },
});
export const DEFAULT_LAYOUT_NO_FOOTER = merge({}, DEFAULT_LAYOUT, { footer: { show: false } });
export const PARTNER_SKU_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { actionableLogo: false, showSiteLinks: false },
  ...showPartnerFooter,
});
export const BOOKING_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { template: HEADER_TEMPLATES.Booking },
  footer: { show: false },
});
export const BOOKING_LAYOUT_NO_LINKS = merge({}, DEFAULT_LAYOUT, {
  header: { template: HEADER_TEMPLATES.Booking, actionableLogo: false, showSiteLinks: false },
  footer: { show: false },
});
export const BOOKING_LAYOUT_WITH_BUTTON_FOOTER = merge({}, DEFAULT_LAYOUT, {
  header: { template: HEADER_TEMPLATES.Booking },
  footer: { show: false },
});
export const FINISH_PARTNER_ORDER_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { template: HEADER_TEMPLATES.Booking },
  footer: { show: false },
});
export const SAMSUNG_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { template: HEADER_TEMPLATES.Samsung },
});
export const OPTIMUM_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { template: HEADER_TEMPLATES.Optimum, actionableLogo: false },
  footer: { show: false },
});
export const FEATURED_CONTENT_OR_SKU_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { actionableLogo: false },
  ...showPartnerFooter,
});
export const SUDDENLINK_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { template: HEADER_TEMPLATES.Suddenlink, actionableLogo: false },
  ...showPartnerFooter,
});
export const WALMART_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { template: HEADER_TEMPLATES.Walmart },
});
export const TARGET_FINISH_ORDER_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { template: HEADER_TEMPLATES.TargetEasyInstall, actionableLogo: false },
  ...showPartnerFooter,
});
export const PARTNER_MULTIPLE_ACCOUNT_SKU_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { actionableLogo: false, showSiteLinks: false },
  ...showPartnerFooter,
});
export const GEEK_SQUAD_PARTNER_MULTIPLE_ACCOUNT_SKU_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { actionableLogo: false, showSiteLinks: true },
  ...showPartnerFooter,
});
export const XFINITY_PAGE_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { showHeader: false },
  footer: { show: false },
});
export const NO_HEADER_NO_FOOTER_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { showHeader: false },
  footer: { show: false },
});
export const COBRAND_ORDER_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { template: HEADER_TEMPLATES.Cobrand, actionableLogo: false },
  footer: { show: false },
});
export const FIELD_SALES_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { template: HEADER_TEMPLATES.FIELD_SALES_HEADER_TYPE },
  footer: { show: false },
});
export const DIRECT_BOOKING_BASE_LAYOUT = merge({}, DEFAULT_LAYOUT, {
  header: { template: HEADER_TEMPLATES.DirectBooking },
  ...showPartnerFooter,
});
