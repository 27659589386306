import { addErrorAppSnackNotice } from 'src/actions/snackNotice';
import { TEMPLATE_NAMES } from 'src/components/Snackbar/constants';
import {
  UPDATE_ITEM_PRICE,
  ITEM_PRICE_UPDATED,
  ADD_TO_CART,
  UPDATE_CART,
  UPDATE_SKU_QUESTIONS,
  CONFIRM_ITEM,
  POPULATE_CART_ANSWERS,
  ADD_TO_CART_Q_FLOW,
  PAGE_NAME,
  ADD_PRODUCT_TO_CART,
  POPULATE_NEW_ITEM_WITH_CART_ANSWERS,
  CLEAR_ITEM_STATUS,
} from './constants';

export function updateItemPrice() {
  return {
    type: UPDATE_ITEM_PRICE,
  };
}

export function itemPriceUpdated(payload) {
  return {
    type: ITEM_PRICE_UPDATED,
    payload,
  };
}

export function addToCart(payload = {}) {
  return {
    type: ADD_TO_CART,
    payload,
  };
}

export function addProductToCart({ id }) {
  return {
    type: ADD_PRODUCT_TO_CART,
    id,
  };
}

// Update cart item
export function confirmItem(payload = {}) {
  return {
    type: CONFIRM_ITEM,
    payload,
  };
}

export function updateCart({ cart, replace }) {
  return {
    type: UPDATE_CART,
    cart,
    replace,
  };
}

export function updateSkuQuestions({ skuId, questions }) {
  return {
    type: UPDATE_SKU_QUESTIONS,
    skuId,
    questions,
  };
}

export const populateCartAnswers = ({ cartQandA, quantity }) => ({
  type: POPULATE_CART_ANSWERS,
  cartQandA,
  quantity,
});

export const addToCartQFlow = ({ completedAnswers }) => ({
  type: ADD_TO_CART_Q_FLOW,
  completedAnswers,
});

export const pinRequiredErrorPageSnackNotice = ({ partner } = {}) => {
  return addErrorAppSnackNotice({
    template: TEMPLATE_NAMES.PIN_REQUIRED,
    templateData: { partner },
    componentName: `${PAGE_NAME}_snackbar`,
    dismissable: true,
  });
};

export const populateNewItemWithCartAnswers = (payload) => ({
  type: POPULATE_NEW_ITEM_WITH_CART_ANSWERS,
  payload,
});

export const clearItemStatus = () => ({
  type: CLEAR_ITEM_STATUS,
});
