import {
  UPSELL_PLAN_ID,
  MONTHLY_PLAN_ID,
  PRICING_TEST_YEARLY_ID,
  PRICING_TEST_MONTHLY_ID,
  HELLOTECH_LITE_PLAN,
  HELLOTECH_LITE_PLAN_ANNUAL_ID,
  ONLINE_PLAN_IDS,
} from 'src/constants/plan';

export const ICON_ORIENTATION = { left: 'left', right: 'right' };
export const LAST_FOUR_DEFAULT_HOME_PAGE = '9005';

export const PLAN_IDS_FOR_PHONE_DISPLAY = [
  UPSELL_PLAN_ID,
  MONTHLY_PLAN_ID,
  PRICING_TEST_YEARLY_ID,
  PRICING_TEST_MONTHLY_ID,
  HELLOTECH_LITE_PLAN,
  HELLOTECH_LITE_PLAN_ANNUAL_ID,
  ...ONLINE_PLAN_IDS,
];
