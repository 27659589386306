/* eslint-disable import/no-cycle */
import React from 'react';
import {
  push,
  plansPath,
  locationsRootPath,
  getOrderStatusPath,
  faqPath,
  privacyPath,
  tosPath,
  snsPath,
  cancellationPolicyPath,
  speedTestPage,
  productsIframeBasePath,
} from 'src/utils/paths';
import { HIDE_PRODUCT_CONNECTED_HOME } from 'src/constants/app';
import HT_INFO from 'src/constants/hellotech';
import { BADGE_TYPES } from 'src/components/Elements/Badge';
import { StandardTosAugust2022 } from 'src/components/TermsOfService';
import CancellationPolicy from 'src/components/CancellationPolicy';
import SafetyandSupport from 'src/components/SafetyandSupport/SafetyandSupport';
import { FAQwithoutLayout } from 'src/containers/SiteFAQ/index';
import { SRC_TYPES } from 'src/containers/SiteFAQ/constants';

export const TYPES = {
  PARTNER: 'partner',
  DEFAULT: 'default',
};

export const MODAL_VIEWS = {
  PRIVACY: 'PRIVACY',
  TOS: 'TOS',
  SNS: 'SNS',
  CANCELLATION: 'CANCELLATION',
};

export const LEGAL_LINKS = [
  { name: 'Privacy Policy', action: push(privacyPath()) },
  { name: 'Terms of Services', action: push(tosPath()) },
  { name: 'Safety & Support', action: push(snsPath()) },
  { name: 'Cancellation Policy', action: push(cancellationPolicyPath()) },
];

export const PARTNER_LEGAL_INITIAL_STATE = {
  [MODAL_VIEWS.CANCELLATION]: false,
  [MODAL_VIEWS.TOS]: false,
  [MODAL_VIEWS.PRIVACY]: false,
  [MODAL_VIEWS.SNS]: false,
};

export const PARTNER_LEGAL_LINKS = [
  {
    name: 'Privacy Policy',
    view: MODAL_VIEWS.PRIVACY,
    // eslint-disable-next-line react/display-name
    component: () => <FAQwithoutLayout src={SRC_TYPES.PRIVACY_POLICY} hideTitle callerPage="privacy-policy" />,
  },
  { name: 'Terms of Services', view: MODAL_VIEWS.TOS, component: StandardTosAugust2022 },
  { name: 'Safety & Support', view: MODAL_VIEWS.SNS, component: SafetyandSupport },
  {
    name: 'Cancellation Policy',
    view: MODAL_VIEWS.CANCELLATION,
    component: CancellationPolicy,
  },
];

const { techs, blog, guide, careers, partnerships } = HT_INFO.siteLinks;
export const SITE_LINKS = [
  {
    header: 'HelloTech',
    lineItems: [
      { name: 'Services', isServicesLink: true },
      { name: 'Membership', action: push(plansPath()) },
      ...(!HIDE_PRODUCT_CONNECTED_HOME
        ? [
            {
              name: 'Products',
              action: push(productsIframeBasePath()),
              badge: {
                text: 'New',
                type: BADGE_TYPES.COMPLETED,
                variant: 'outlined',
                size: 'small',
              },
            },
          ]
        : []),
      { name: 'Locations', action: push(locationsRootPath()) },
    ],
  },
  {
    header: 'Team',
    lineItems: [
      { name: 'Partnerships', link: partnerships.url, target: '_blank' },
      { name: 'Careers', link: careers.url, target: '_blank' },
      { name: 'Become a Technician', link: techs.url },
    ],
  },
  {
    header: 'Support',
    lineItems: [
      { name: 'Track my Order', action: push(getOrderStatusPath()) },
      { name: 'FAQ', action: push(faqPath()) },
      { name: 'Speed Test', action: push(speedTestPage()) },
      { name: 'Blog', link: blog.url, target: '_blank' },
      { name: 'Guides & Tutorials', link: guide.url, target: '_blank' },
      { name: 'Chat with us', isChatLink: true },
      { name: 'Call', isPhoneLink: true }, // The text will be replaced before rendering -GH Oct 23, 2020
    ],
  },
];
