import { createSelector, createStructuredSelector } from 'reselect';
import { isMobileSelector, isTabletSelector } from 'src/selectors/resolution';
import componentSelector from 'src/selectors/component';
import { cartSelector } from 'src/selectors/cart';
import { pageSelector as selectPage } from 'src/selectors/page';
import { availabilityStateSelector } from 'src/selectors/availability';
import { fromJS } from 'immutable';

const pageSelector = selectPage('availability');

const getSelectedHours = (availability) => {
  const convertedAvailability = availability.toJS();
  const allHours = convertedAvailability.flatMap((day) => {
    return day.hours.map((hour) => {
      return { date: day.date, selectedHourData: hour };
    });
  });
  const selectedHours = allHours.filter((hour) => hour.selectedHourData.checked);
  return selectedHours.map((hour) => {
    return { date: hour.date, selectedHourData: fromJS(hour.selectedHourData) };
  });
};

const numSelectedHoursSelector = createSelector(
  availabilityStateSelector,
  (availability) =>
    availability
      .toJS()
      .flatMap((elem) => elem.hours)
      .filter((elem) => elem.checked === true).length,
);

const selectedHoursSelector = createSelector(
  availabilityStateSelector,
  (availability) => getSelectedHours(availability),
);

export default createStructuredSelector({
  cart: cartSelector,
  page: pageSelector,
  isMobile: isMobileSelector,
  isTablet: isTabletSelector,
  availability: availabilityStateSelector,
  numSelectedHours: numSelectedHoursSelector,
  selectedHours: selectedHoursSelector,
  snackbar: componentSelector('snackbar'),
});
