import { put, call, takeLatest, select, delay } from 'redux-saga/effects';
import get from 'lodash/get';
import { selectRoutes } from 'src/apiRoutes';
import { updateCart } from 'src/containers/AddSkuPage/actions';
import { ADD_PLAN_TO_CART, REMOVE_PLAN_FROM_CART } from 'src/constants/cart';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import { updateTrackingByUpsell, deriveBEUpsellChannel } from 'src/actions/tracking';
import { UPSELL_TRACKING } from 'src/constants/tracking';

export function* addPlanToCart(planId, { cancelPlanId = null } = {}) {
  const routes = yield call(selectRoutes);
  const pathname = yield select((state) => state.getIn(['router', 'location', 'pathname']));

  /**
   * For Data team we are sending the same value as the Segment's events's `upsell_name` property
   * with plan creation request, but named as `plan_upsell_channel`
   */
  const planUpsellChannel = deriveBEUpsellChannel(pathname) || null;
  const { data = {}, err } = yield call(routes.cart.selectPlan, {
    plan_id: planId,
    ...(cancelPlanId ? { cancel_plan_id: cancelPlanId } : {}),
    ...(planUpsellChannel ? { plan_upsell_channel: planUpsellChannel } : {}),
  });
  const plan = get(data, 'cart.plan', {});
  const partner = get(data, 'cart.partner', {});

  if (!err) {
    yield put(updateCart({ cart: data.cart }));
    yield delay(300);
    yield put(
      updateTrackingByUpsell({
        plan,
        partner,
        event: UPSELL_TRACKING.added,
      }),
    );
  }

  return { data, err };
}

function* addPlanToCartFlow({
  planId,
  successCB = () => {},
  errorCB = () => {},
  cancelPlanId = null,
}) {
  yield put(requestStarted());
  const response = yield call(addPlanToCart, planId, { cancelPlanId });
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrors(response));
    errorCB(response);
  } else {
    successCB(response);
  }
}

function* removePlanFromCart() {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.cart.removePlan);
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrors(response));
  } else {
    const prevCart = yield select((state) => state.getIn(['entities', 'cart']));
    const plan = prevCart.get('plan').toJS();
    const cart = get(response, 'data.cart', {});

    yield put(updateCart({ cart: response.data.cart }));
    yield delay(300);
    yield put(
      updateTrackingByUpsell({ plan, partner: cart.partner, event: UPSELL_TRACKING.removed }),
    );
  }
}

export function* pageFlow() {
  yield takeLatest(ADD_PLAN_TO_CART, addPlanToCartFlow);
  yield takeLatest(REMOVE_PLAN_FROM_CART, removePlanFromCart);
}

export default [pageFlow];
