import {
  SUBMIT_FORM,
  RESET_FORM,
  ZIP_VALIDATED,
  ZIP_NOT_VALIDATED,
  HSN_CLICK_SEGMENT,
} from './constants';

export function submitForm(info) {
  return {
    type: SUBMIT_FORM,
    payload: info,
  };
}

export function resetForm() {
  return { type: RESET_FORM };
}

export function zipValidated() {
  return { type: ZIP_VALIDATED };
}

export function zipNotValidated() {
  return { type: ZIP_NOT_VALIDATED };
}

export const hsnSegmentClickTracking = (success, userInput = '') => {
  if (!userInput) return {};
  return {
    type: HSN_CLICK_SEGMENT,
  };
};
