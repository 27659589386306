// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Utils
// Components
import DesktopSelector from './DesktopSelector';
import MobileSelector from './MobileSelector';
// Utils
import { updateSelectedDateTimes } from './utils';

const Selector = (props) => (
  <>
    <div className="showMobileOnlyV2">
      <MobileSelector {...props} />
    </div>
    <div className="showTabletDesktopV2">
      <DesktopSelector {...props} />
    </div>
  </>
);

class AvailabilitySelector extends Component {
  static propTypes = {
    availability: PropTypes.object.isRequired,
    toggleHour: PropTypes.func.isRequired,
    availabilityLoaded: PropTypes.func.isRequired,
    selectedDateTimes: PropTypes.array,
    isMobile: PropTypes.bool.isRequired,
  };

  componentWillUnmount() {
    this.props.availabilityLoaded({ availability: [] });
  }

  toggleHour = (date, selectedHourData, newCheckState) => {
    const { selectedDateTimes } = this.props;
    return (event) => {
      if (event) event.preventDefault();

      if (!selectedHourData.get('valid')) return;

      updateSelectedDateTimes(selectedDateTimes, selectedHourData, newCheckState);
      this.props.toggleHour({ date, selectedHourData, newCheckState });
    };
  };

  render() {
    const { availability } = this.props;
    return <Selector availability={availability} toggleHour={this.toggleHour} />;
  }
}

Selector.propTypes = {
  availability: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  toggleHour: PropTypes.func,
};

Selector.defaultProps = {
  toggleHour: () => { },
}

export default AvailabilitySelector;


/*
  AvailabilitySelector
    Purpose: Render a scheduling selector
*/
