import Cookies from 'js-cookie';
import stringToBoolean from 'src/utils/formatter/stringToBoolean';

/**
  This cookie is set when a client lands on the following urls with specific criteria

  - LandingPage
      - The url contains '/landings/'
      - The landing page has an associated partner_id. Not all '/landings' pages will
        have a partner_id.
  - SkuPage
      - The urls contains '/tech-support/'
      - The sku has a partner_id
  - AddSkuPage
      - The urls contains '/cart/add-sku'
      - The skus has a partner_id
  - Additionally, if any of the items in the cart has a partner_id, the cookie is set.

  For now, we're using this cookie as a flag to block top-nav changes while the optimizely
  experiment is running.

  Rails is also involved in this flag. Please see api/clients/servers#initial.
*/

export const IS_PARTNER_RELATED_COOKIE = 'isPartnerRelated';
export const IS_PARTNER_RELATED_COOKIE_DURATION = 86400000; // 24 hours

export const getIsPartnerRelatedCookie = () => stringToBoolean(Cookies.get(IS_PARTNER_RELATED_COOKIE), false);

export const removeIsPartnerRelatedCookie = () => Cookies.remove(IS_PARTNER_RELATED_COOKIE);
