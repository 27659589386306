import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'src/components/HTKit/Icon';

export const DropdownIndicator = memo(({ selectProps }) => {
  const iconName = selectProps.menuIsOpen ? 'chevron-up' : 'chevron';
  return <Icon name={iconName} />;
});

DropdownIndicator.displayName = 'DropdownIndicator';
DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
  }),
};
