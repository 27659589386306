import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { THEMES } from 'HTKit/Forms/Button';
import BookNowButton from 'src/components/BookNowButton';
import { getSkuPrice } from '../utils';
import styles from './styles.scss';

export default class MultipleAccountSkuCard extends Component {
  static propTypes = {
    sku: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      image: {
        original: PropTypes.string,
      },
    }).isRequired,
    landing: PropTypes.object.isRequired,
    onShowDetails: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  onViewDetails = (event) => {
    const { onShowDetails, sku } = this.props;
    event.preventDefault();
    onShowDetails(sku);
    this.props.push('/xfinity');
  };

  render() {
    const { sku, landing } = this.props;
    const price = getSkuPrice(sku, '');
    const imageStyles = {
      backgroundImage: `url(${sku.image.original})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };
    return (
      <div className={styles.card}>
        <div className={styles.cardImage} style={imageStyles} />
        <div className='padding-small'>
          <div className={styles.cardInfo}>
            <div className={styles.cardInfoName}>
              { sku.name }
            </div>
            <div className={styles.cardInfoStarts}>
              <div className={styles.cardInfoStartsInfo}>
                Starts<br />at&nbsp;only
              </div>
              <div className={styles.cardInfoStartsPrice}>
                { price }
              </div>
            </div>
          </div>
          <div className={styles.cardButtons}>
            <div className={styles.cardButtonsBook}>
              <BookNowButton skuId={sku.id} landing={landing} smallV2 theme={THEMES.V2PRIMARY} inlineBlock />
            </div>
            <div className={styles.cardButtonsMore}>
              <a href="/" className="text-link -no-decoration" onClick={this.onViewDetails}>View Details</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
