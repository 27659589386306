import { SCRIPT_TYPE } from './constants';

export const makePlanJsonLd = (plan) => ({
  type: SCRIPT_TYPE,
  innerHTML: `{
  "@context": "http://schema.org",
  "@type": "Product",
  "name": "${plan.name}",
  "description": "${plan.description}",
  "url": "${`https://www.hellotech.com/plans/${plan.seoName}`}"
}`,
});
