import { connect } from 'react-redux';
import { loadPage, layoutUpdate } from 'src/actions/common';
import { push } from 'src/utils/paths';
import { purePageSelector as pageSelector } from 'src/selectors/page';
import { PAGE_NAME } from './constants';
import SimpliSafeLandingPage from './SimpliSafeLandingPage';

const mapStateToProps = (state) => {
  const page = pageSelector(PAGE_NAME)(state);
  const partner = 'simplisafe';
  const landingRaw = state.getIn(['entities', 'landings', partner], null)
  const landing = landingRaw && landingRaw.toJS();
  return {
    page,
    partner,
    pageIsLoading: page && page.loading,
    reviews: landing && landing.reviews,
    skus: landing && landing.skus,
    totalCompletedServices: page && page.totalCompletedServices,
  };
};

export default connect(
  mapStateToProps,
  { loadPage, layoutUpdate, push },
)(SimpliSafeLandingPage);
