import { fromJS } from 'immutable';
import { LOAD_PROFILE_DATA } from './constants';

const initialState = fromJS({
  profile: {},
});

const techDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROFILE_DATA: {
      const { data, techId } = action;
      if (!techId) return state;
      /**
       * Note: type of the keys matter. we're going to force the keys to be a number.
       */
      const techIdNumber = Number(techId);
      return state.mergeIn(['profile', techIdNumber], fromJS(data));
    }
    default:
      return state;
  }
};

export default techDataReducer;
