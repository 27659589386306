export const PAGE_NAME = 'plan';

// Redux
export const PLAN_LOADED = 'planPage/PLAN_LOADED';
export const PLAN_ORDERED = 'planPage/PLAN_ORDERED';
export const CHECK_USER = 'planPage/CHECK_USER';
export const USER_CHECKED = 'planPage/USER_CHECKED';
export const ORDER_PLAN = 'planPage/ORDER_PLAN';
export const PAYMENT_EDIT_MODE = 'planPage/PAYMENT_EDIT_MODE';
export const GIFT_CARD_MESSAGE = 'planPage/GIFT_CARD_MESSAGE';
export const UPDATE_PLAN_PRICES = 'planPage/UPDATE_PLAN_PRICES';
export const PLAN_PRICES_UPDATED = 'planPage/PLAN_PRICES_UPDATED';

export const ORDERING_TYPES = { MYSELF: 'myself', OTHER: 'other' };

export const ORDERING_FOR = {
  myself: { label: 'Myself', value: ORDERING_TYPES.MYSELF },
  other: { label: "It's a gift for someone else", value: ORDERING_TYPES.OTHER },
};

export const GIFT_DATE_OPTIONS = {
  IMMEDIATELY: 'immediately',
  DATE: 'date',
};

export const PLAN_BENEFITS = [
  'Unlimited Remote, Phone and Chat Services, 24/7/365',
  'Unlimited Devices Covered',
  'No Set-Up Fee Required',
  'Free Computer Tune Ups',
  'Free Virus Removals',
];
