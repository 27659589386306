import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import CartPreviewIcon from 'src/components/Header/Parts/CartPreviewIcon';
import Icon from 'src/components/HTKit/Icon';
// selectors
import { cartSelector } from 'src/selectors/cart';
// Actions
import { fieldSalesModalAction, sessionValidatorPathAction } from 'src/containers/FieldSales/actions';
// Utils
import { noop } from 'src/utils/event';
import { getCartItemCount } from 'src/utils/cart';
import { cartPath, push } from 'src/utils/paths';
// Constants
import { MODAL_NEW_SALES_ORDER } from '../constants';
// styles
import styles from './styles.scss';

const ActionButtons = () => {
  const dispatch = useDispatch();
  const cart = useSelector(cartSelector);
  const cartHasNoItems = getCartItemCount(cart) === 0;
  // This links outside the fieldsales garden. Need to validate it first.
  const cartIconAction = () => dispatch(sessionValidatorPathAction(push(cartPath)))
  // If there is no cart, suppress click, otherwise pass false and its handled by component
  const onClickBehavior = cartHasNoItems ? noop : cartIconAction;
  const buttonWrapper = cn(styles.actionButtonsWrapper, {
    [styles.opacity_dimmer]: cartHasNoItems
  })

  return (
    <div className={buttonWrapper}>
      <Icon name="circle-add" className='paddingRight-medium n900' onClick={() => {
        if (cartHasNoItems) return;
        dispatch(fieldSalesModalAction({ name: MODAL_NEW_SALES_ORDER, visible: true }));
      }}/>
      <CartPreviewIcon
        cart={cart}
        onClick={onClickBehavior}
      />
    </div>
  );
};

export default ActionButtons;
