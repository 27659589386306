import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
} from 'react-stripe-elements';
import Label from 'HTKit/Forms/Parts/Label';
import ErrorMsg from 'HTKit/Forms/Parts/ErrorMsg';
import { Grid } from 'HTKit/Grid';
import Form from 'HTKit/Forms/Form';
import { requestStarted, requestFinished } from 'src/utils/request';
import NotePanel from 'src/components/SecurePayment/NotePanel';
import styles from './styles.scss';

class PaymentForm extends Component {
  static propTypes = {
    stripe: PropTypes.object,
  };

  static getResponsiveValue(minValue, maxValue, minWidth, maxWidth) {
    const px = minValue - (minWidth * (maxValue - minValue)) / (maxWidth - minWidth);
    const vw = (100 * (maxValue - minValue)) / (maxWidth - minWidth);
    const addedPx = (vw * window.innerWidth) / 100;
    return `${px + addedPx}px`;
  }

  state = {
    errors: {},
    styles: {
      fontSize: '18px',
      color: '#263746', // ht-navy-900
      '::placeholder': {
        color: '#CCD4DD'
      }
    },
  };

  makeChange = (name) => (e) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: e.error ? e.error.message : null,
      },
    });
  };

  inputClass = (name) => {
    const error = this.state.errors[name];
    return classNames(styles.inputField, { [styles.inputFieldInvalid]: error });
  };

  inputStyles = () => {
    return { base: { ...this.state.styles } };
  };

  render() {
    return (
      <Grid.FullWidth>
        <Form.Row>
          <Form.Column sm={4} lg={12}>
            <Label label="Credit Card Number" />
            <CardNumberElement
              onChange={this.makeChange('number')}
              style={this.inputStyles()}
              className={this.inputClass('number')}
              placeholder=""
              showIcon
            />
            <ErrorMsg error={this.state.errors.number} />
            <div className={styles.notePanelContainer}>
              <NotePanel />
            </div>
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column sm={2} lg={6}>
            <Label label="Expiration Date" />
            <CardExpiryElement
              onChange={this.makeChange('expiration')}
              style={this.inputStyles()}
              className={this.inputClass('expiration')}
            />
            <ErrorMsg error={this.state.errors.expiration} />
          </Form.Column>
          <Form.Column sm={2} lg={6}>
            <Label label="CVC" />
            <CardCVCElement
              onChange={this.makeChange('cvc')}
              style={this.inputStyles()}
              className={this.inputClass('cvc')}
              placeholder=""
            />
            <ErrorMsg error={this.state.errors.cvc} />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column sm={2} lg={6}>
            <Label label="Billing Zip" />
            <PostalCodeElement
              onChange={this.makeChange('zip')}
              style={this.inputStyles()}
              className={this.inputClass('zip')}
              placeholder=""
            />
            <ErrorMsg error={this.state.errors.zip} />
          </Form.Column>
        </Form.Row>
      </Grid.FullWidth>
    );
  }
}

export function submitPaymentForm(dispatch, stripe, params = {}) {
  return new Promise((resolve, reject) => {
    dispatch(requestStarted());
    stripe.createToken(params).then(({ token, error }) => {
      dispatch(requestFinished());
      if (error) {
        if (
          !['incomplete_number', 'incomplete_cvc', 'incomplete_expiry', 'incomplete_zip'].includes(
            error.code,
          )
        ) {
          reject(error);
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(null);
        }
      } else {
        resolve(token);
      }
    });
  });
}

export default PaymentForm;

/*
  PaymentForm: Container for Stripe Input Elements
*/
