import { useDispatch } from 'react-redux';
import {
  addErrorAppSnackNotice,
  addSuccessAppSnackNotice,
  addAppSnackNotice,
} from 'src/actions/snackNotice';
import { displayErrorsWithSnack } from 'src/utils/request';

export default () => {
  const dispatch = useDispatch();

  return {
    error(message) {
      dispatch(addErrorAppSnackNotice({ content: message }));
    },
    success(message) {
      dispatch(addSuccessAppSnackNotice({ content: message }));
    },
    info(message) {
      dispatch(addAppSnackNotice({ status: 'info', content: message, dismissable: false }));
    },
    requestError(response, opts = {}) {
      dispatch(displayErrorsWithSnack(response, opts));
    }
  };
};
