// Libraries
import React from 'react';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import { UPSELL_PLAN_NAME } from 'src/constants/plan';
// Components
import Banner from 'src/components/Elements/Banner';
// Styles
import styles from './styles.scss';

export const BANNER_RADIO_OFFER_DEFAULTS = {
  backgroundColor: '#FF726C',
  textColor: '#FFFFFF',
  headline: `${UPSELL_PLAN_NAME} Membership Offer: Get $200 Worth of Software and Services for Free`,
};

const RadioOfferBanner = () => {
  const { splitConfig } = useSplitIOTreatment(splitioConstants.SPLITIONAME_BANNER_RADIO_OFFER);
  const { textColor, backgroundColor, headline } = splitConfig;

  return (
    <Banner
      showBlobs
      className={styles.banner}
      style={{
        color: textColor,
        backgroundColor,
      }}
    >
      <div className={styles.content}>
        <h5 className="white">{headline}</h5>
      </div>
    </Banner>
  );
};

export default React.memo(RadioOfferBanner);
