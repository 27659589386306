import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Grid } from 'HTKit/Grid';
import Button, { THEMES as BUTTON_THEMES } from 'HTKit/Forms/Button';
import { PRODUCT_CARD_ID } from 'src/containers/SimpliSafeLandingPage/constants';
import styles from './styles.scss';

const scrollToProductCard = () => document.querySelector(`#${PRODUCT_CARD_ID}`).scrollIntoView();

const ReadyToBookServiceBlock = ({ buttonText, ctaText, imgClasses, onButtonClick }) => {
  if (!imgClasses || !ctaText) return null;
  const imageStyles = cn(styles.image, imgClasses);
  return (
    <Grid.FullWidth>
      <Grid.Row>
        <Grid.Column lgOffset={1} lg={6} md={4} sm={4}>
          <div className={imageStyles} />
        </Grid.Column>
        <Grid.Column lg={5} md={4} sm={4}>
          <div className={styles.cta}>
            <h3>{ctaText}</h3>
            <Button
              theme={BUTTON_THEMES.V2PRIMARY}
              className={styles.button}
              onClick={onButtonClick}
            >
              {buttonText}
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid.FullWidth>
  );
};

ReadyToBookServiceBlock.propTypes = {
  buttonText: PropTypes.string,
  ctaText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  imgClasses: PropTypes.string.required,
  onButtonClick: PropTypes.func,
};

ReadyToBookServiceBlock.defaultProps = {
  buttonText: 'Get Started',
  imgClasses: '',
  onButtonClick: scrollToProductCard,
};

export default React.memo(ReadyToBookServiceBlock);

/*
  ReadyToBookServiceBlock

  Props:
  - ctaText: Pass a string or JSX elements
  - imgClasses: The styles you pass in should be applying a background-image and its background-position
*/
