export const PAGE_NAME = 'accountScoreCard';

// TODO: move it to backend one day
const PRODUCT_CATEGORIES = Object.freeze({
  THERMOSTAT: {
    id: 'thermostat',
    name: 'Thermostat',
  },
  STREAMING_DEVICE: {
    id: 'streamingDevice',
    name: 'Streaming Device',
  },
  CAMERA: {
    id: 'camera',
    name: 'Camera',
  },
  VIDEO_DOORBELL: {
    id: 'videoDoorbell',
    name: 'Video Doorbell',
  },
  SMART_DOORLOCK: {
    id: 'smartDoorlock',
    name: 'Smart Doorlock',
  },
  GARAGE_DOOR_OPENER: {
    id: 'garageDoorOpener',
    name: 'Garage Door Opener',
  },
  LIGHTING: {
    id: 'lighting',
    name: 'Lighting',
  },
  SMART_SECURITY_HUBS: {
    id: 'smartSecurityHubs',
    name: 'Smart Security Hubs',
  },
  SMART_SECURITY_SENSORS: {
    id: 'smartSecuritySensors',
    name: 'Smart Security Sensors',
  },
  SMART_PLUGS: {
    id: 'smartPlugs',
    name: 'Smart Plugs',
  },
  SMART_HUB: {
    id: 'smartHub',
    name: 'Smart Hub',
  },
  SMART_SPEAKERS: {
    id: 'smartSpeakers',
    name: 'Smart Speakers',
  },
  TVS: {
    id: 'tvs',
    name: 'TVs',
  },
  COMPUTER: {
    id: 'computer',
    name: 'Computer',
  },
});

export const AUDIT_TYPE = Object.freeze({
  SINGLE: 'single',
  TABLE: 'table',
  TABBED: 'tabbed',
});

export const AUDITS = [
  {
    id: 'computerSecurity',
    type: AUDIT_TYPE.SINGLE,
    key: 'computerSecurity',
    title: 'Computer Security',
    hint: {
      header: 'Computer Security',
      content:
        'There are measures that you can take to make sure your computer and your information are as secure as possible. In addition to your own personal security, it is critical for your computer’s health that you take all possible precautions in making sure that you’re protected. If any of the areas in this section are red, we recommend addressing them as quickly as possible to ensure that your data will not be compromised.',
    },
  },
  {
    id: 'networkSecurity',
    type: AUDIT_TYPE.SINGLE,
    key: 'networkSecurity',
    title: 'Network Security',
    hint: {
      header: 'Network Security',
      content:
        'It is critical that your network security measures are up-to-standard if you want to keep unauthorized users off of your network. Even if there is no malicious intent, keeping intruders away is key to keeping your network running smoothly for you and your family.',
    },
  },
  {
    id: 'modemSpeed',
    type: AUDIT_TYPE.SINGLE,
    key: 'modemSpeed',
    title: 'Modem Speed',
    hint: {
      header: 'Modem Speeds',
      content:
        'Modem speed is the speed you get directly from your internet service provider. Measured in MBPS (megabits per second), it’s the backbone of your entire network! If your modem speed is insufficient, your WiFi will be also. If you’re not getting the speed you’re paying for, you should contact your service producer immediately.',
    },
  },
  {
    id: 'wifiCoverage',
    type: AUDIT_TYPE.TABLE,
    key: 'wifiCoverage',
    title: 'Wifi Coverage',
    hint: {
      header: 'WiFi Coverage',
      content:
        'The farther from your router, the weaker the WiFi signal. You may think that your WiFi speed around your home is adequate, but you may not even know how great it can be to have each of your devices running at maximum capacity!',
    },
  },
];

export const HOME_READINESS = [
  {
    id: 'controlAndConvenience',
    key: 'controlAndConvenience',
    title: 'Control & Convenience',
    categories: [
      PRODUCT_CATEGORIES.SMART_HUB,
      PRODUCT_CATEGORIES.LIGHTING,
      PRODUCT_CATEGORIES.THERMOSTAT,
      PRODUCT_CATEGORIES.SMART_PLUGS,
      PRODUCT_CATEGORIES.GARAGE_DOOR_OPENER,
    ],
    hint: {
      header: 'Control & Convenience',
      content:
        'Turn on the lights when you’re not home, control the temperature of every room in your house, plug anything you want into a smart plug to make it easily controllable. The devices in this section will give you the power to control your home with the push of a button.',
    },
  },
  {
    id: 'entertainment',
    key: 'entertainment',
    title: 'Entertainment',
    categories: [
      PRODUCT_CATEGORIES.STREAMING_DEVICE,
      PRODUCT_CATEGORIES.SMART_SPEAKERS,
      PRODUCT_CATEGORIES.TVS,
    ],
    hint: {
      header: 'Entertainment',
      content:
        'A quality entertainment experience is a must have for any smart home. Maximize that experience by making sure your WiFi signal can support HD streaming and perfect sound quality throughout your home.',
    },
  },
  {
    id: 'smartSecurity',
    key: 'smartSecurity',
    title: 'Smart Security',
    categories: [
      PRODUCT_CATEGORIES.CAMERA,
      PRODUCT_CATEGORIES.SMART_SECURITY_SENSORS,
      PRODUCT_CATEGORIES.VIDEO_DOORBELL,
      PRODUCT_CATEGORIES.SMART_DOORLOCK,
    ],
    hint: {
      header: 'Smart Security',
      content:
        'Be aware of who is at your home and control who is granted access. These security devices will always allow you yo maintain control of your home, even when you’re not there.',
    },
  },
];

export const PAGE_MENU = [
  {
    title: 'Home Status',
    links: AUDITS.map((a) => ({ id: a.id, label: a.title })),
  },
  {
    title: 'Smart Home Readiness',
    links: HOME_READINESS.map((h) => ({ id: h.id, label: h.title })),
  },
];
