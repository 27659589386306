import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { providesOrderingSkus } from 'src/utils/landings';
import {
  addSkuPath,
  fieldSalesSkuListPath,
  directBookingTechSkusPath,
  directBookingTechProfilePath,
  productsIframeBasePath,
  directBookingTechProductsPath,
} from 'src/utils/paths';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import ConfirmModal from 'src/components/HTKit/Modals/Confirm';
import MultipleAutoApplyCouponsAdded from 'src/components/HTKit/Modals/MultipleAutoApplyCouponsAdded';
import { getUpsellPlanIds } from 'src/components/Plans/Pricing/utils';
import PlanHolderModal from 'src/components/PlanHolderModal';
import PageLoader from 'src/components/PageLoader';
import ErrorPage from 'src/containers/ErrorPage';
import { Grid } from 'src/components/HTKit/Grid';
import { resolveBookingPageLayout } from 'src/utils/layout';
import { getDirectBookingTechIdOrSlug } from 'src/utils/cookies/directBookingCookie';
import { getCartItemCount } from 'src/utils/cart';
import { isMobileOrTabletSelector } from 'src/selectors/resolution';
import { BOOKING_STAGES } from '../BookingPage/constants';

// Field Sales
import { fieldSaleAgentVerifyLayoutSelector } from 'src/containers/FieldSales/selectors';
import { fieldSalesPathAction } from 'src/containers/FieldSales/actions';
// End Field sales
import { CartRelatedSkus } from './Parts';
import CartTypes from './CartTypes';
import { PAGE_NAME, CART_LAYOUT } from './constants';
import { setHideUpsellCookie } from './utils';
import styles from './styles.scss';
import { useIsPasswordlessCheckoutEnabled } from '../Account/AccountPage/AccountPage.hooks';

const CartPage = ({
  addPlanAndStartBooking: addPlanAndStartBookingFN,
  userHasSubscription,
  cart,
  clearAppNotices,
  clearItemMessages,
  currentPartner,
  dismissModalMultipleAutoApplyCoupons: dismissModalMultipleAutoApplyCouponsAction,
  layoutUpdate,
  loadPage,
  page,
  plansInfo,
  push,
  removeItem,
  removePlan: removePlanAction,
  showFirstServiceDiscountModal,
  showUpsellCartVersion,
  showUpsellCartVersionAction,
  snackbar,
  startBooking: startBookingAction,
  togglePlanHolderModal,
  toggleRemovePlanModal: toggleRemovePlanModalAction,
  toggleSiteNav,
  ...rest
}) => {
  const {
    splitLoaded: planPricingSplitLoaded,
    splitTreatment: planPricingSplitTreatment,
  } = useSplitIOTreatment(splitioConstants.SPLITIONAME_PLAN_PRICING);
  const isMobileOrTablet = useSelector(isMobileOrTabletSelector);
  const isPlanPricingTest = planPricingSplitTreatment === splitioConstants.ON;
  const [upsellPlanId, setUpsellPlanId] = useState(null);
  const isPasswordlessCheckoutEnabled = useIsPasswordlessCheckoutEnabled();

  /* Specific Cart Deep Linking Concerns */
  const { token } = useParams();

  useEffect(() => {
    const { monthlyID } = getUpsellPlanIds({ isPlanPricingTest });
    setUpsellPlanId(monthlyID);
  }, [isPlanPricingTest]);

  const elements = {
    removeItemModal: null,
  };
  const isFieldSales = useSelector(fieldSaleAgentVerifyLayoutSelector);
  const dispatch = useDispatch();
  const getLayout = () =>
    resolveBookingPageLayout({ cart, currentPartner, defaultLayout: CART_LAYOUT });

  const setRemoveModalRef = (el) => {
    elements.removeItemModal = el;
  };

  const toggleRemoveItemModal = (service, index) => {
    elements.removeItemModal.open({ index, service });
  };

  const onItemRemoveConfirm = ({ index, service }) => {
    removeItem(index, service, cart.get('remote'));
  };

  const dismissModalMultipleAutoApplyCoupons = (event) => {
    event.preventDefault();
    dismissModalMultipleAutoApplyCouponsAction();
  };

  const goToAddSku = (event) => {
    event.preventDefault();
    const notices = snackbar.get('notices');
    const isNoticePresent = notices.size;
    if (isNoticePresent) {
      clearAppNotices();
    }
    if (event.nativeEvent) event.nativeEvent.stopImmediatePropagation();
    toggleSiteNav();
  };

  const directBookingTechId = getDirectBookingTechIdOrSlug();
  const isDirectBookingFlow = Boolean(directBookingTechId);
  const goToProductsShop = () => dispatch(push(productsIframeBasePath));
  const goToDirectBookingProductsShop = () =>
    dispatch(push(directBookingTechProductsPath(directBookingTechId)));

  const handleProductButtonClick = () => {
    if (isDirectBookingFlow) {
      goToDirectBookingProductsShop();
    } else {
      goToProductsShop();
    }
  };
  const totalItemCount = getCartItemCount(cart);

  const goToAnotherService = (event) => {
    const providesPartnerOrdering = currentPartner && providesOrderingSkus(currentPartner.toJS());
    const hasPartnerSkus = providesPartnerOrdering || !!cart.get('partner');

    switch (true) {
      case isDirectBookingFlow: {
        // give direct booking top priority
        event.preventDefault();
        if (isMobileOrTablet && totalItemCount !== 0) {
          push(directBookingTechProfilePath(directBookingTechId));
        } else {
          push(directBookingTechSkusPath(directBookingTechId));
        }

        break;
      }
      case hasPartnerSkus: {
        event.preventDefault();
        const seoName =
          currentPartner && currentPartner.get('seoName')
            ? currentPartner.get('seoName')
            : cart.getIn(['partner', 'landingSeoName']);
        const route = `/landings/${seoName}`;
        /* Temporary fix: Need a better strategy for all landings not comcast/xfinity - could be as easy as testing against "xfinity" or "comcast" */
        if (route.toLowerCase().includes('frontier')) {
          window.location.href = route;
          break;
        }
        push(route);
        break;
      }
      // field sales (fieldsales) flow
      case isFieldSales: {
        event.preventDefault();
        dispatch(fieldSalesPathAction(fieldSalesSkuListPath));
        break;
      }
      default:
        goToAddSku(event);
    }
  };

  const goToEditSku = ({ skuId, index }) => (event) => {
    event.preventDefault();
    push(addSkuPath(skuId), { mode: 'edit', itemIndex: index });
  };

  const removePlan = () => {
    removePlanAction();
  };

  const toggleRemovePlanModal = (e) => {
    if (e) e.preventDefault();
    toggleRemovePlanModalAction();
  };

  const closePlanHolderModal = (e) => {
    if (e) e.preventDefault();
    togglePlanHolderModal(false);
  };

  const addPlanAndStartBooking = (event) => {
    event.preventDefault();
    addPlanAndStartBookingFN(cart, {
      planId: upsellPlanId,
      isPasswordless: isPasswordlessCheckoutEnabled,
    });
  };

  const startBooking = (event) => {
    event.preventDefault();
    startBookingAction(cart, {
      stage: BOOKING_STAGES.VERIFICATION,
      isPasswordless: isPasswordlessCheckoutEnabled,
    });
    if (showUpsellCartVersion) {
      setHideUpsellCookie();
    }
  };

  useEffect(() => {
    if (planPricingSplitLoaded && page.get('loading')) {
      layoutUpdate(getLayout());
      loadPage(PAGE_NAME, { isPlanPricingTest, ...(token && { token }) });
    }
  }, [planPricingSplitLoaded, token]);

  useEffect(() => {
    layoutUpdate(getLayout());
  }, [cart]);

  useEffect(() => {
    return () => {
      clearItemMessages();
      showFirstServiceDiscountModal();
      togglePlanHolderModal(false);
      showUpsellCartVersionAction(false);
    };
  }, []);

  if (page.get('loading')) {
    return <PageLoader />;
  }

  if (page.get('error')) {
    return <ErrorPage page={page} />;
  }

  const relatedSkus = page.get('relatedSkus').toJS();
  const providesPartnerOrdering = currentPartner && providesOrderingSkus(currentPartner.toJS());
  const hasPartnerSkus = providesPartnerOrdering || !!cart.get('partner');
  return (
    <div className={classNames('site-v2', styles.container)}>
      <Helmet title="Cart" />
      <section>
        <Grid.Fluid>
          <h1 className={classNames('h2', styles.mainTitle)}>Cart</h1>
        </Grid.Fluid>

        <MultipleAutoApplyCouponsAdded
          isOpen={page.get('showModalMultipleAutoApplyCoupons')}
          onClose={dismissModalMultipleAutoApplyCoupons}
        />
        <ConfirmModal
          ref={setRemoveModalRef}
          modalStyles={styles.modalDialogStyle}
          onConfirm={onItemRemoveConfirm}
          cancelButtonTitle="No"
          confirmButtonTitle="Yes"
          title="Are you sure you want to delete this item from your shopping cart?"
        />

        <PlanHolderModal visible={page.get('showPlanHolderModal')} onClose={closePlanHolderModal} />

        <CartTypes
          {...rest}
          totalItemCount={totalItemCount}
          cart={cart}
          page={page}
          isPartner={hasPartnerSkus}
          plansInfo={plansInfo}
          removeItem={toggleRemoveItemModal}
          removePlan={removePlan}
          showUpsellCartVersion={showUpsellCartVersion}
          startBooking={startBooking}
          goToAnotherService={goToAnotherService}
          onProductButtonClick={handleProductButtonClick}
          goToEditSku={goToEditSku}
          addPlanAndStartBooking={addPlanAndStartBooking}
          toggleRemovePlanModal={toggleRemovePlanModal}
          userHasSubscription={userHasSubscription}
        />

        <Grid.Fluid>
          <CartRelatedSkus relatedSkus={relatedSkus} />
        </Grid.Fluid>
      </section>
    </div>
  );
};

CartPage.propTypes = {
  layoutUpdate: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
  clearItemMessages: PropTypes.func.isRequired,
  startBooking: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  showFirstServiceDiscountModal: PropTypes.func.isRequired,
  dismissModalMultipleAutoApplyCoupons: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  cart: PropTypes.object,
  page: PropTypes.object.isRequired,
  currentPartner: PropTypes.object,
  userHasSubscription: PropTypes.bool,
  removePlan: PropTypes.func.isRequired,
  addPlanAndStartBooking: PropTypes.func.isRequired,
  toggleRemovePlanModal: PropTypes.func.isRequired,
  togglePlanHolderModal: PropTypes.func.isRequired,
  toggleSiteNav: PropTypes.func.isRequired,
  clearAppNotices: PropTypes.func.isRequired,
  snackbar: PropTypes.object,
  showUpsellCartVersion: PropTypes.bool.isRequired,
  showUpsellCartVersionAction: PropTypes.func.isRequired,
  plansInfo: PropTypes.object,
};

export default CartPage;
