export const PAGE_NAME = 'subscriptionGifting';
export const INITIAL_FORM = {
  activateAt: '',
  token: '',
  purchaser: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  holder: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
};
export const FLOW_STAGES = Object.freeze({
  RECIPIENT: 1,
  PURCHASER: 2,
  SUMMARY: 3,
});

export const INCREMENT_COMPLETED_STAGE = `${PAGE_NAME}/INCREMENT_COMPLETED_STAGE`;
export const CLEAR_PAGE_DATA = `${PAGE_NAME}/CLEAR_PAGE_DATA`;

export const GET_PLAN_DETAILS = `${PAGE_NAME}/GET_PLAN_DETAILS`;
export const SET_PLAN_DETAILS = `${PAGE_NAME}/SET_PLAN_DETAILS`;

export const SUBMIT_RECIPIENT_INFO = `${PAGE_NAME}/SUBMIT_RECIPIENT_INFO`;
export const SUBMIT_PURCHASER_INFO = `${PAGE_NAME}/SUBMIT_PURCHASER_INFO`;
export const SUBMIT_CARD_INFO = `${PAGE_NAME}/SUBMIT_CARD_INFO`;
export const SUBMIT_ORDER = `${PAGE_NAME}/SUBMIT_ORDER`;
export const APPLY_PROMO_CODE = `${PAGE_NAME}/APPLY_PROMO_CODE`;
export const APPLY_DISCOUNT = `${PAGE_NAME}/APPLY_DISCOUNT`;
