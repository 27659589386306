import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push, plansPath } from 'src/utils/paths';
import { currentLandingSelector } from 'src/selectors/landings';
import { PARTNERS } from 'src/constants/common';
import { HIDE_PRODUCT_CONNECTED_HOME } from 'src/constants/app';
import { BUTTON_CTAS } from 'src/containers/CartPage/constants';
import { Grid } from 'src/components/HTKit/Grid';
import { PanelV2 } from 'src/components/HTKit/Elements/PanelV2';
import EmptyState from 'src/components/EmptyState';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import FieldSaleGuard from 'src/containers/FieldSales/components/FieldSaleGuard';
import { UPSELL_PLAN_NAME } from 'src/constants/plan';
import { getDirectBookingTechIdOrSlug } from 'src/utils/cookies/directBookingCookie';
import styles from './styles.scss';

const EmptyCart = ({ goToAnotherService, userHasSubscription, onProductButtonClick }) => {
  const dispatch = useDispatch();
  const goToPlanPage = () => dispatch(push(plansPath));
  const isDirectBookingFlow = Boolean(getDirectBookingTechIdOrSlug());

  /*
    Per PAR-272 we are going to use isXfinity as the basis to show/hide buttons instead of a larger solution,
    e.g. hide for all partners, because it was a last-minute request for an important partner.
  */
  const currentLanding = useSelector(currentLandingSelector);
  const isXfinity = currentLanding && currentLanding.get('seoName') === PARTNERS.XFINITY;

  const showProductsButton = !isXfinity && !HIDE_PRODUCT_CONNECTED_HOME;
  const showMembershipButton = !userHasSubscription && !isXfinity && !isDirectBookingFlow;

  return (
    <Grid.Fluid classes={styles.container}>
      <PanelV2 className="paddingY-large2">
        <EmptyState iconName="cart2" title="Your cart is empty." showButton>
          <FieldSaleGuard positiveShow>
            <Button
              onClick={goToAnotherService}
              theme={THEMES.V2SECONDARY}
              className="marginBottom-small"
              mediumV2
            >
              Add Items
            </Button>
          </FieldSaleGuard>
          <FieldSaleGuard>
            <Button
              onClick={goToAnotherService}
              theme={THEMES.V2SECONDARY}
              className="marginBottom-small"
              mediumV2
            >
              Shop Services
            </Button>
            {showProductsButton && (
              <Button
                onClick={onProductButtonClick}
                theme={THEMES.V2SECONDARY}
                className="marginBottom-small"
                mediumV2
              >
                {BUTTON_CTAS.PRODUCTS}
              </Button>
            )}
            {showMembershipButton && (
              <Button
                onClick={goToPlanPage}
                theme={THEMES.V2PRIMARY}
                className="marginBottom-small"
                mediumV2
              >
                Join {UPSELL_PLAN_NAME}
              </Button>
            )}
          </FieldSaleGuard>
        </EmptyState>
      </PanelV2>
    </Grid.Fluid>
  );
};

EmptyCart.propTypes = {
  goToAnotherService: PropTypes.func.isRequired,
  onProductButtonClick: PropTypes.func.isRequired,
  userHasSubscription: PropTypes.bool,
};

export default React.memo(EmptyCart);
