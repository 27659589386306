import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.scss';

const PromoCode = ({ wrapperStyle, type, copySize, headerSize }) => {
  const wrapperStyles = classNames(
    {
      [styles.box]: type === 'box',
      [styles.inline]: type === 'inline',
    },
    wrapperStyle,
  );
  const promoStyle = classNames(headerSize, 'n900');
  const summaryStyle = classNames(copySize, 'n700');

  return (
    <div className={wrapperStyles}>
      <p className={promoStyle}>Have a promo code?</p>
      <p className={summaryStyle}>Enter it on your service request summary.</p>
    </div>
  );
};

PromoCode.propTypes = {
  wrapperStyle: PropTypes.string,
  type: PropTypes.string,
  headerSize: PropTypes.string,
  copySize: PropTypes.string,
};

PromoCode.defaultProps = {
  wrapperStyle: '',
  type: 'box',
  headerSize: 'p1',
  copySize: 'p1',
};

/*
  type: ['inline', 'box']
*/

export default PromoCode;
