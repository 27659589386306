export const PROMO_BAR_PRIMARY_LINK_CLICKED = 'promobar/PROMO_BAR_PRIMARY_LINK_CLICKED';
export const PROMO_BAR_CONFIG_DEFAULTS = {
    backgroundColor: '#133061',
    textColor: '#FFFFFF',
    icon: 'icon-smart-home-wifi',
    headline: 'Set Up Your Home For Work or School.',
    ctaText: 'Learn More',
    ctaLink: '/landings/home-office',
    ctaLinkExternal: ''
};
