import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import { LOAD_PAGE } from 'src/constants/common';
import { selectRoutes } from 'src/apiRoutes';
import { pageLoaded, pageLoadingError } from 'src/actions/common';
import { loadProfileData } from 'src/components/Techs/data/actions';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { orderInfoLoaded } from 'src/actions/order';
import { displayErrorsWithSnack } from 'src/utils/request';
import {
  replace,
  paloAltoNetworksAccountOrderStatusPath,
  paloAltoNetworksAccountOrderStatusPublicPath,
  paloAltoReschedulePath,
  paloAltoPublicReschedulePath,
} from 'src/utils/paths';
import { getStatusInfo } from 'src/utils/status';
import { techProfileByIdJSSelector } from 'src/selectors/techs';
import { PALO_ALTO_ORDER_PAGE_NAME } from 'src/containers/BookingPagePaloAltoNetworks/constants';
import { PAGE_NAME } from './constants';

import { cancelReasonsSelector } from './selectors';
import { authFailedErrorPageSnackNotice } from './actions';

/* Helper Functions */

function* getOrderInfo({ orderId, orderToken, publicPage }) {
  const routes = yield call(selectRoutes);

  let orderInfo;

  let response;
  if (publicPage) {
    response = yield call(routes.orders.public.info, { orderToken });
  } else {
    response = yield call(routes.orders.info, { orderId }, { full: true });
  }
  const {
    data: { order },
    err,
  } = response;
  if (err) {
    yield put(pageLoadingError(PAGE_NAME, response));
    yield put(displayErrorsWithSnack(response));
    return null;
  }
  // eslint-disable-next-line prefer-const
  orderInfo = order;

  const { preferredTechId } = orderInfo;

  if (preferredTechId) {
    const techData = yield select(techProfileByIdJSSelector(preferredTechId));
    if (!techData) {
      const techResult = yield call(routes.techs.profile, { id: preferredTechId });
      if (!techResult.err) {
        const {
          data: { tech: techDataResult },
        } = techResult;
        yield put(loadProfileData({ techId: preferredTechId, data: techDataResult }));
      }
    }
  }
  return orderInfo;
}

function* getCancelReasons() {
  let cancelReasons = yield select(cancelReasonsSelector);

  if (!cancelReasons.length) {
    const routes = yield call(selectRoutes);
    const response = yield call(routes.orders.cancelReasons);

    const {
      data: { cancelReasons: responseCancelReasons },
      err,
    } = response;

    cancelReasons = err ? [] : responseCancelReasons;
    return cancelReasons;
  }

  return cancelReasons;
}

function* updateStatusRoute({ order, publicPage, panel }) {
  const { id, token } = order;
  let currentPanel = panel;
  if (!currentPanel) {
    currentPanel = getStatusInfo({ order }).panel;
  }

  if (publicPage) {
    yield put(replace(paloAltoNetworksAccountOrderStatusPublicPath(token, currentPanel)));
  } else {
    yield put(replace(paloAltoNetworksAccountOrderStatusPath(id, currentPanel)));
  }
}

// function* isPublicPage() {
//   return yield select((state) => state.getIn(['pages', PAGE_NAME, 'publicPage']));
// }

const getRescheduleUrl = ({ orderId, orderToken, publicPage }) => {
  // return publicPage ? publicReschedulePath(orderToken) : reschedulePath(orderId);
  return publicPage ? paloAltoPublicReschedulePath(orderToken) : paloAltoReschedulePath(orderId);
};

/*
  Account Order Page
*/

function* pageSaga({ orderId, orderToken, orderCardStatus, publicPage }) {
  if (!publicPage) {
    const userLoaded = yield loadUserProfileSaga(PAGE_NAME, true);
    if (!userLoaded) return;
  }

  const { orderInfo, cancelReasons } = yield all({
    orderInfo: call(getOrderInfo, { orderId, orderToken, publicPage }),
    cancelReasons: call(getCancelReasons),
  });
  if (orderInfo) {
    yield put(orderInfoLoaded({ order: orderInfo }));
    const { panel } = getStatusInfo({ order: orderInfo });
    if (orderCardStatus !== panel) {
      yield call(updateStatusRoute, { order: orderInfo, publicPage, panel });
    }
    if (orderInfo.hasAuthError) {
      yield put(
        authFailedErrorPageSnackNotice({
          isLoggedIn: !publicPage,
          hasSetPassword: orderInfo.hasSetPassword,
        }),
      );
    }

    const formattedRescheduleUrl = yield call(getRescheduleUrl, {
      orderId,
      orderToken,
      publicPage,
    });
    yield put(pageLoaded(PAGE_NAME, { publicPage, formattedRescheduleUrl, cancelReasons }));
  }
}

function* accountOrderFlow() {
  yield takeEvery(
    (action) => action.type === LOAD_PAGE && action.page === PALO_ALTO_ORDER_PAGE_NAME,
    pageSaga,
  );
}

export default [accountOrderFlow];
