// eslint-disable-next-line no-unused-vars
import React from 'react';
import { useSelector } from 'react-redux';
import { fieldSaleAgentVerifyLayoutSelector } from 'src/containers/FieldSales/selectors';

/*
* This just suppresses the component if flow is fieldSales
*
* children { ReactElement } - Element to hide/show
* positiveShow { boolean } - a reverse trait. If we are fieldsales, show.
*
* Note: because of how the cookies are a driving determinant. You might get
*       behavior if you start mixing flows.
* */
const FieldSaleGuard = ({ children, positiveShow = false }) => {
 const isFieldSale = useSelector(fieldSaleAgentVerifyLayoutSelector);

 switch (true) {
   case positiveShow && isFieldSale:
     return children;
   case positiveShow && !isFieldSale:
   case isFieldSale:
     return null;
   default:
     return children;
 }
}

export default FieldSaleGuard;
