import { fromJS } from 'immutable';
import { TOGGLE_MODAL } from 'src/constants/modals';
import { state as surveyState } from 'src/utils/SurveyMonkey';
import { MODAL_NAME, INIT_MODAL } from './constants';

const initialState = fromJS({ shown: false });

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_MODAL: {
      return state.set('shown', surveyState.shown);
    }
    case TOGGLE_MODAL: {
      const { name, visible } = action.payload;
      if (name === MODAL_NAME) return state.set('shown', visible);
      return state;
    }
    default:
      return state;
  }
};
