import { fromJS } from 'immutable';
import get from 'lodash/get';
import { PAGE_LOADED } from 'src/constants/common';
import {
  ANSWER_CHANGE,
  ANSWER_QUANTITY_CHANGE,
  DEVICE_ANSWER_CHANGE,
  DEVICE_INPUT_ANSWER_CHANGE,
  CUSTOM_DROPDOWN_OPTIONS,
} from 'src/components/PreQuestion/constants';
import {
  ITEM_PRICE_UPDATED,
  POPULATE_CART_ANSWERS,
  QUESTION_TYPES,
} from 'src/containers/AddSkuPage/constants';

export default function reducer(state = fromJS({}), action) {
  switch (action.type) {
    case PAGE_LOADED: {
      switch (action.page) {
        case 'addSku': {
          const { sku, questions } = action;
          const newQuestions = {};
          questions.forEach((q) => {
            switch (q.inputType) {
              case QUESTION_TYPES.INPUT:
                newQuestions[q.id.toString()] = { text: '' };
                break;
              case QUESTION_TYPES.TEXT_AREA:
                newQuestions[q.id.toString()] = { text: '' };
                break;
              case QUESTION_TYPES.DROPDOWN: {
                const defaultAnswer = q.answers.find((a) => a.default);
                newQuestions[q.id.toString()] = defaultAnswer ? { id: defaultAnswer.id } : null;
                break;
              }
              case QUESTION_TYPES.CHECKBOX:
                newQuestions[q.id.toString()] = null;
                break;
              case QUESTION_TYPES.DEVICE: {
                const defaultAnswer = q.answers.find((a) => a.default);
                newQuestions[q.id.toString()] = defaultAnswer
                  ? { make: defaultAnswer.id, model: defaultAnswer.id }
                  : null;
                break;
              }
              case QUESTION_TYPES.MEDIA:
                newQuestions[q.id.toString()] = { media: [] };
                break;
              default: {
                throw new Error(`Unknown inputType: ${q.inputType}`);
              }
            }
          });
          return fromJS({
            skuId: sku.id,
            autoApplyCoupon: sku.autoApplyCoupon,
            startsAtPrice: sku.startsAtPrice,
            totalPrice: sku.price,
            overrideBasePrice: sku.overrideBasePrice,
            questions: newQuestions,
            heroImageUrl: sku.heroImageUrl,
            category: sku.category,
            partner: sku.partner,
            name: sku.name,
          });
        }
        default:
          return state;
      }
    }
    case POPULATE_CART_ANSWERS: {
      const { cartQandA, quantity } = action;
      return state.mergeDeep({ questions: fromJS(cartQandA), quantity });
    }
    case ITEM_PRICE_UPDATED: {
      const { price, breakdown } = action.payload;
      return state.merge({ totalPrice: price, breakdown });
    }
    case ANSWER_CHANGE: {
      // Destructure `question` and `value` from the action payload.
      // `question` is an object containing details about the question, such as id and inputType.
      // `value` varies based on the question's inputType:
      // - For checkboxes, it's an array of selected checkbox IDs.
      // - For text inputs and textareas, it's the entered text as a string.
      // - For dropdowns, it's the selected option's ID.
      const { question, value } = action;
      switch (question.inputType) {
        case QUESTION_TYPES.INPUT:
          return state.setIn(['questions', question.id.toString(), 'text'], value);
        case QUESTION_TYPES.TEXT_AREA:
          return state.setIn(['questions', question.id.toString(), 'text'], value);
        case QUESTION_TYPES.DROPDOWN:
          return state.setIn(
            ['questions', question.id.toString()],
            value ? fromJS({ id: value }) : null,
          );
        case QUESTION_TYPES.CHECKBOX: {
          // Get current checkbox states or default to an empty list if none.
          const currentCheckboxState =
            state.getIn(['questions', question.id.toString()]) || fromJS([]);

          // Create a map from the current states for direct ID-based access.
          // Add each checkbox state to the map with its ID as the key.
          const currentCheckboxMap = currentCheckboxState.reduce((map, item) => {
            return map.set(item.get('id'), item);
          }, fromJS({}));

          // Update states based on the selection; keep existing states where possible.
          // Retain the whole state for existing selections, or create a new one.
          const newCheckboxState = value.map((v) => {
            return currentCheckboxMap.get(v) || fromJS({ id: v });
          });

          // Save the new states back to the corresponding question.
          return state.setIn(['questions', question.id.toString()], fromJS(newCheckboxState));
        }

        case QUESTION_TYPES.MEDIA:
          return state.setIn(['questions', question.id.toString(), 'media'], value || []);
        default: {
          throw new Error(`Unknown inputType: ${question.inputType}`);
        }
      }
    }
    case DEVICE_ANSWER_CHANGE: {
      const { questionType, question, makeValue, modelValue } = action;
      const answerMatch = question.answers.find((a) => {
        return a.id === makeValue;
      });
      /*
        BE needs the id of the productQuestion, not the answer id.
        But when editing answer, FE needs answer id.
      */
      let makeVal = get(answerMatch, 'productQuestion.id', null);
      let modelVal = modelValue;
      let answerId = makeValue;
      /*
        If answerMatch is not present, it means its either "other" or "idk" option
        The "other" option opens an inputfield which is addressed in "DEVICE_INPUT_ANSWER_CHANGE"
      */
      if (makeValue === CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.value) {
        answerId = null;
        makeVal = CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.value;
        modelVal = CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.value;
      }
      const data = { answerId, answerValue: makeValue, make: makeVal, model: modelVal };
      const values = makeValue || modelValue ? fromJS(data) : null;
      switch (questionType) {
        case 'makeDropdown':
          return state.setIn(['questions', question.id.toString()], values);
        case 'modelDropdown':
          return state.setIn(['questions', question.id.toString(), 'model'], fromJS(modelVal));
        default:
          return state;
      }
    }
    case DEVICE_INPUT_ANSWER_CHANGE: {
      const { questionType, question, makeInputValue, modelInputValue } = action;
      const data = {
        answerId: null,
        answerValue: makeInputValue,
        make: makeInputValue,
        model: modelInputValue,
      };
      const values = makeInputValue || modelInputValue ? fromJS(data) : null;

      switch (questionType) {
        case 'makeInput':
          return state.setIn(['questions', question.id.toString()], values);
        case 'modelInput':
          return state.setIn(
            ['questions', question.id.toString(), 'model'],
            fromJS(modelInputValue),
          );
        default:
          return state;
      }
    }

    case ANSWER_QUANTITY_CHANGE: {
      const { question, id, quantity } = action;
      const answerIndex = state
        .getIn(['questions', question.id.toString()])
        .findIndex((a) => a.get('id') === id);
      return state.setIn(['questions', question.id.toString(), answerIndex, 'quantity'], quantity);
    }
    default:
      return state;
  }
}
