import { createSelector } from 'reselect';

export const brandingStateSelector = (state) => state.get('branding');

export const brandingSelector = createSelector(brandingStateSelector, (branding) => branding);

export const brandingSelectorJS = createSelector(
  brandingStateSelector,
  (branding) => branding && branding.toJS(),
);

/**
 * With the refactor of the branding strategy, `theme` key is not a value
 * in the branding object. However, `theme` was really the partner name, but
 * in lower case.
 */
export const brandingThemeSelector = createSelector(
  brandingStateSelector,
  (branding) => branding && branding.get('partnerName', '').toLowerCase(),
);

/**
 * Just pick the first logo for now (probably the desktop version)
 */
export const brandingFirstCobrandLogoSelector = createSelector(
  brandingSelectorJS,
  (brandingJS) => ((brandingJS.cobrandedLogo || []).find((logo) => !!logo.medium) || {}).medium,
);
