/* eslint-disable import/prefer-default-export */
import React from 'react';
import femaleTech from 'src/images/female-tech.png';
import femaleTechRemote from 'src/images/female-tech-remote.png';
import DynamicOnlineHeader from './Headers/DynamicOnlineHeader';

// The blobs are styled independently of the background color -GH Apr 26, 2020
export const THEME_COLORS = {
  BLUE: 'blue',
  YELLOW: 'yellow',
};

export const HERO_THEME_DEFAULT = {
  themeName: 'default',
  themeColor: THEME_COLORS.BLUE,
  images: {
    lg: {
      png: femaleTech,
    },
  },
  subTitle: 'The best in-home tech solution, right at your doorstep. Book a service today!',
  title: 'In a HelloTech home, technology just works.',
};

export const HERO_THEME_DYNAMIC_HEADER = {
  themeName: 'dynamicHeader',
  themeColor: THEME_COLORS.BLUE,
  images: {
    lg: {
      png: femaleTechRemote,
    },
  },
  title: <DynamicOnlineHeader />,
  subTitle: 'The best tech solution, ready to help you - anytime, anywhere.',
};
