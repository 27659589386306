/*
* Looks like there are some tabs like "laptop" and "Online Service" that are in question
* Lets follow suite here with "around the home". Getting confirmation from product. Maybe we
* can just get rid of ths constant and display all from BE.
*  */
export const SERVICE_TABS = {
  POPULAR_SERVICES: 'most-popular',
  TV_MOUNTING: 'tv-mounting',
  SMART_HOME: 'smart-home',
  COMPUTER_SUPPORT: 'computers',
  WIFI_NETWORKS: 'networking',
  MOBILE_TABLETS: 'mobile',
  AUDIO_VIDEO: 'television-and-audio',
  HOMEOFFICE_SCHOOL: 'home-office',
  AROUND_THE_HOME: 'around-the-home',
};
