// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Components
import BreadCrumbStep from './BreadCrumbStep';
// Styles
import styles from './styles.scss';

const Breadcrumbs = (props) => {
  const { numSteps, completedStep, className } = props;
  // Subtracting 1 here because of the way they want the dots to render
  const completedStepToRender = completedStep - 1;
  return (
    <section className={cn(styles.container, className)}>
      <ul className={styles.progressbar}>
        {[...new Array(numSteps)].map((_, index) => (
          <BreadCrumbStep
            key={index}
            {...props}
            currentIndex={index + 1}
            completedStep={completedStepToRender}
          />
        ))}
      </ul>
    </section>
  );
};

Breadcrumbs.propTypes = {
  numSteps: PropTypes.number.isRequired,
  completedStep: PropTypes.number,
  className: PropTypes.string,
  // For BreadCrumbStep
  activeStep: PropTypes.number,
};

Breadcrumbs.defaultProps = {
  completedStep: 1,
  className: '',
};

export default React.memo(Breadcrumbs);

/*
  Example usage:

  const BREADCRUMB_STAGES_DEFAULT = [
    { name: 'address', step: 1 },
    { name: 'availability', step: 2 },
    { name: 'payment', step: 3 },
    { name: 'summary', step: 4 },
  ];
  ...
  ...
  const completedBreadCrumbStep = BREADCRUMB_STAGES_DEFAULT.find((stg) => stg.name === cart.get('status')) (This ).step || 1; (This returns a number)
  const activeBreadCrumbStep = BREADCRUMB_STAGES_DEFAULT.find((stg) => stg.name === stage).step || 1 (This returns a number)
  ...
  ...
  <Breadcrumbs activeStep={activeBreadCrumbStep} numSteps={BREADCRUMB_STAGES_DEFAULT.length} completedStep={completedBreadCrumbStep} />

  This component accepts up to 5 steps comfortably. If more steps are needed, you'll need to edit the CSS styles. Styling will indicate which step they
  are currently on, and which steps they've completed.

  Props:
    activeStep: This is the page/step you're on in the flow, independent of how many steps you've completed.
    completedStep: User may have completed steps and goes back to edit. E.g., user is on Summary page, goes back to edit address.
*/
