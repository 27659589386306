import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useSplitIOTreatment, splitioConstants } from 'src/components/SplitIO';
import Icon from 'HTKit/Icon';
import { ModalV2 } from 'src/components/HTKit/Modals/ModalV2';
import Button, { THEMES as BUTTON_THEMES } from 'HTKit/Forms/Button';
import styles from './styles.scss';

const SubtitleDefault = ({ className }) => {
  const text = "You won't be charged until your service is completed.";
  return (
    <p className={cn('p2 n700 text-align-center', className)}>
      <Icon name="clock" /> {text}
    </p>
  );
};

const SubtitlePreAuth = ({ onLearnMoreClick, className }) => {
  const text =
    "HelloTech will process a pre-authorization on your card, but you won't be charged until your service is completed.";
  return (
    <p className={cn('p2 n700 text-align-center', className)}>
      {text}&nbsp;
      <button className="plainButton underline teal" onClick={onLearnMoreClick}>
        Learn More
      </button>
    </p>
  );
};

const PreAuthModal = ({ isOpen, onClose }) => (
  <ModalV2
    isVisible={isOpen}
    hide={onClose}
    header="Pre-Authorization"
    footerClasses={styles.footerContainer}
    footerElement3={
      <Button theme={BUTTON_THEMES.V2PRIMARY} onClick={onClose}>
        I Understand
      </Button>
    }
  >
    <p className="p1 n900 marginTop-small2 text-weight-regular">
      A pre-authorization is performed to verify that the card you provided is valid and has
      sufficient funds to cover your service. Funds may be temporary placed on hold by your bank for
      2-5 days.
    </p>
    <p className="p1 n900 marginTop-small1 text-weight-med">
      You will not actually be charged until your service is completed.
    </p>
  </ModalV2>
);

export const PaymentPageSubtitle = ({ className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const { splitTreatment } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_PAYMENT_SCREEN_PREAUTH_VARIANT,
  );
  const isPreAuthSplitOn = splitTreatment === splitioConstants.ON;

  return isPreAuthSplitOn ? (
    <>
      <SubtitlePreAuth onLearnMoreClick={toggleModal} className={className} />
      <PreAuthModal isOpen={isModalOpen} onClose={toggleModal} />
    </>
  ) : (
    <SubtitleDefault className={className} />
  );
};

const commonPropTypes = {
  className: PropTypes.string,
};

PreAuthModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ...commonPropTypes,
};
SubtitlePreAuth.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  ...commonPropTypes,
};
SubtitleDefault.propTypes = commonPropTypes;
PaymentPageSubtitle.propTypes = commonPropTypes;
