export const makeOpeningHoursJsonLd = () =>
  `"openingHoursSpecification":[{
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "opens": "00:00",
    "closes": "23:59"
  }]`;
