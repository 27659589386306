import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Constants
import { PARTNERS } from 'src/constants/common';
import { BOOKING_STAGES } from 'src/containers/BookingPage/constants';
import { useLocation } from 'react-router';
// Components & Styles
import { DefaultMetaTags } from 'src/components/SEO';
import AddressCard from './AddressCard';
import AddressForm from './AddressForm';
import { extractAddressObjectFromLocation } from './utils';
import styles from './styles.scss';

const PartnerLanguage = ({ visible }) => {
  if (!visible) return null;
  return (
    <p className={styles.partnerLanguage}>
      Where would you like us to visit you? Enter a home, office or other address.
    </p>
  );
};
PartnerLanguage.propTypes = { visible: PropTypes.bool };

const AddressPage = ({
  currentStage,
  fullAddress = '',
  partner,
  cart,
  page,
  submitAddress,
  loadPage,
}) => {
  const locationData = useLocation();

  const addressObject = (() => {
    const addressObjectFromCart = cart.get('address').toJS();
    const addressObjectFromCartHasValues = Object.values(addressObjectFromCart).some(Boolean);
    const addressObjectFromLocation = extractAddressObjectFromLocation(locationData);
    // Looks janky since the address object from cart should be the default
    return addressObjectFromCartHasValues ||
      (!addressObjectFromCartHasValues && !addressObjectFromLocation)
      ? addressObjectFromCart
      : addressObjectFromLocation;
  })();

  const userHasSavedAddress = Boolean(fullAddress.length);

  const [showForm, setShowForm] = useState(!userHasSavedAddress);
  const toggleForm = () => setShowForm(!showForm);

  const showPartnerLanguage = [PARTNERS.WALMART, PARTNERS.SAMSUNG].includes(partner);

  const submitButtonText = cart.get('status') === 'summary' ? 'Continue' : 'Next';

  const errorsFromBackEnd = page.get('errors').toJS(); // If BE encounters an error Redux will be updated

  const submitAddressAction = (payload) => {
    submitAddress({ address: payload });
  };

  useEffect(() => {
    if (currentStage === BOOKING_STAGES.ADDRESS) {
      loadPage(BOOKING_STAGES.ADDRESS);
    }
  }, []);

  return (
    <div>
      <DefaultMetaTags title="Address" />
      <div className={styles.sectionHeaderWrapper}>
        <h4 className={styles.sectionHeader}>What&apos;s your address?</h4>
        <PartnerLanguage visible={showPartnerLanguage} />
      </div>

      {showForm ? (
        <>
          <AddressForm
            buttonText={submitButtonText}
            address={addressObject}
            errorsFromBackEnd={errorsFromBackEnd}
            onSubmit={submitAddressAction}
          />
          {userHasSavedAddress && (
            <p className={styles.existingWrapper}>
              <a href className="text-link -no-decoration" onClick={toggleForm}>
                Use existing address
              </a>
            </p>
          )}
        </>
      ) : (
        <>
          <AddressCard onClick={() => submitAddressAction(addressObject)} address={addressObject} />
          <p className={styles.edit}>
            <a href className="text-link -no-decoration" onClick={toggleForm}>
              Enter a different address
            </a>
          </p>
        </>
      )}
    </div>
  );
};

AddressPage.propTypes = {
  currentStage: PropTypes.string,
  fullAddress: PropTypes.string,
  partner: PropTypes.string,
  cart: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  submitAddress: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
};
export default AddressPage;
