import { fromJS } from 'immutable';
import { PAGE_LOADED } from 'src/constants/common';
import { DASHLANE } from 'src/components/MemberBenefits/Modals/Dashlane/constants';
import { MALWAREBYTES } from 'src/components/MemberBenefits/Modals/Malwarebytes/constants';
import { AUTO_RENEW } from 'src/components/MemberBenefits/MemberAutoRenew/constants';
import { PREVENT_MEMBERSHIP_CANCELLATION } from 'src/components/MemberBenefits/constants';
import {
  PAGE_NAME,
  TOGGLE_MODAL,
  SET_REDEMPTION_CODE,
  SET_STATS,
  SET_TRANSACTIONS,
  SET_MEMBERSHIP,
} from './constants';

const initialState = fromJS({
  statistic: {},
  [MALWAREBYTES.modalName]: false,
  [DASHLANE.modalName]: false,
  [AUTO_RENEW.modalName]: false,
  [PREVENT_MEMBERSHIP_CANCELLATION.modalName]: false,
  codes: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        case PAGE_NAME:
          return state.set('statistic', action.statistic);
        default:
          return state;
      }
    case SET_STATS: {
      const { statistic } = action;
      return state.set('statistic', statistic);
    }
    case SET_TRANSACTIONS: {
      const { transactions } = action;
      return state.set('transactions', transactions);
    }
    case SET_MEMBERSHIP: {
      const { membership } = action;
      return state.set('membership', membership);
    }
    case TOGGLE_MODAL: {
      const { modalName, forceState } = action;
      const oldState = state.get(modalName);
      if (forceState !== undefined) {
        return state.merge({ [modalName]: forceState });
      }
      return state.merge({ [modalName]: !oldState });
    }
    case SET_REDEMPTION_CODE: {
      const { skuId, code } = action;
      return state.setIn(['codes', skuId], code);
    }
    default:
      return state;
  }
};
