export const ORDER_INFO_LOADED = 'order/ORDER_INFO_LOADED';

/* eslint-disable key-spacing */
export const ORDER_STATUS = {
  PENDING: 0,
  SEARCHING: 1,
  ASSIGNED: 2,
  COMPLETED: 3,
  CANCELLED: 4,
  TECH_PAID: 5,
  ABANDONED: 6,
  CANCELLED_BY_TECH: 7,
  MASTER_CLIENT_APPROVAL: 8,
  UNSERVICEABLE: 9,
  PAUSED: 10,
  READY_TO_RESUME: 11,
  INCOMPLETE: 12,
  LATE_TECH: 13,
  PENDING_SHIPPING: 14,
};

export const JOB_PROGRESS = {
  CLAIMED: 0,
  EN_ROUTE: 1,
  STARTED: 2,
  COMPLETED: 3,
  CANCELLED: 4,
  LEFT_FEEDBACK: 5,
  PAUSED: 6,
  READY_TO_RESUME: 7,
  INCOMPLETE: 8,
};
