import { useEffect } from 'react';

export default ({ trigger, onReach, dependencies = [], targetY = 0 }) => {
  useEffect(() => {
    let target = document.querySelector(trigger);

    const handleScroll = () => {
      target = target || document.querySelector(trigger);
      if (!target) return;
      const { bottom } = target.getBoundingClientRect();
      const reached = bottom <= targetY;
      onReach(reached);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, dependencies);
};

/*
  hooks for scrolling triggers
  it'll fire the callback (onReach: (reached: Boolean) => void)
  when the given selector, if it exists (trigger: string), scrolls
  past the top of the viewport.

  usage:

  useScrollTrigger({
    trigger: '.tigger-selector',
    onReach: (visible) => alert(visible),
  });

  - The "0" in "targetY = 0" represents the top of the viewport. If you want to trigger the callback at a different
    Y point, e.g. the bottom of the topNav which may be 56px high, use targetY to override.
*/
