// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Hooks
import useLoadHomePage from 'src/containers/HomePage/hooks/useLoadHomePage';
// Constants
import {
  DEFAULT_HOME_PAGE_LAYOUT,
} from 'src/constants/common';
// Helpers
import { stopImmediatePropagation } from 'src/utils/event';
import {
  splitioConstants,
  useSplitIOTreatment,
  SplitTreatmentToggle,
} from 'src/components/SplitIO';
// Components
import LazyView from 'src/components/LazyLoad/LazyView';
import AmazonTracking from 'src/utils/tracking/amazon/AmazonTrackingPixels';
import PopularSkusByType from 'src/components/PopularSkus';
import { Grid } from 'HTKit/Grid';
import { HERO_THEME_DYNAMIC_HEADER } from 'src/components/Sections/HomePageHero/constants';
import { DynamicHomePageBanner } from 'src/components/Banners/DynamicHomePage';
import ServiceBanner from 'src/components/Service/ServiceBanner';
import ReadyToGetStartedBanner from 'src/components/Banners/ReadyToGetStartedBanner/lazy';
import ServiceReviews from 'src/components/Elements/CustomerReviews/lazy';
import HomePageHero from 'src/components/Sections/HomePageHero';
import MemberPanel from 'src/components/Sections/MemberPanel/lazy';
import SpeedTestBlock from 'src/components/Sections/SpeedTestBlock/lazy';
import GuaranteeBlock from 'src/components/Sections/GuaranteeBlock/lazy';
import PartnerPanel from 'src/components/Sections/PartnerPanel/lazy';
import TotalCompletedServicesAndRating from 'src/components/Sections/TotalCompletedServicesAndRating/lazy';
import MeetOurTechs from 'src/components/Sections/MeetOurTechs/lazy';
import HowCanWeHelp from 'src/components/Sections/HowCanWeHelp/lazy';
import BecomeATechBlock from 'src/components/Sections/BecomeATechBlock/lazy';
import { POPULAR_VIRTUAL_SKUS } from './constants';
// Styles
import styles from './styles.scss';

const HomePage = (props) => {
  const {
    push,
    rating,
    reviews,
    servicesCompleted,
    skuCategories,
    toggleSiteNav,
    hideMemberShip,
  } = props;

  const { splitTreatment: popularSplit } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_POPULAR_ONLINE_SERVICES,
  );

  const popularSkusTitle = 'Popular Online Services';
  const popularSkusSubTitle = 'We offer contactless online support, in the comfort of your home.';

  const popularSkusAltStyles =
    popularSplit === splitioConstants.ON ? '' : styles.popularVirtualSkusContainerAlt;
  const popularTitle =
    popularSplit === splitioConstants.ON ? 'Popular In-Home Services' : 'Popular Services';

  const layoutType = DEFAULT_HOME_PAGE_LAYOUT;

  useLoadHomePage({ layoutType });

  return (
    <div className={cn('site-v2', styles.container)}>
      <AmazonTracking pageName="home_page" />
      <DynamicHomePageBanner />
      <HomePageHero dropDownOptions={skuCategories} push={push} theme={HERO_THEME_DYNAMIC_HEADER} />
      <ServiceBanner
        containerClasses={styles.serviceBannerContainer}
        textColor={styles.serviceBannerText}
        showBlobs={false}
        itemStyle={styles.banner}
      />
      <SplitTreatmentToggle splitName={splitioConstants.SPLITIONAME_POPULAR_ONLINE_SERVICES}>
        <Grid.FullWidth classes={styles.popularVirtualSkusContainer}>
          <PopularSkusByType
            title={popularSkusTitle}
            subTitle={popularSkusSubTitle}
            skus={POPULAR_VIRTUAL_SKUS}
            classes={styles.virtualSkus}
            cardClasses={styles.popularCard}
          />
        </Grid.FullWidth>
      </SplitTreatmentToggle>

      <Grid.FullWidth classes={cn(popularSkusAltStyles, styles.popularSkusContainer)}>
        <PopularSkusByType title={popularTitle} />
      </Grid.FullWidth>

      <LazyView>
        {!hideMemberShip && (
          <Grid.Fluid classes={styles.memberPanelContainer}>
            <MemberPanel push={push} />
          </Grid.Fluid>
        )}
        <SpeedTestBlock outerClasses={styles.speedTest} />
        <GuaranteeBlock classes={styles.guaranteeBlockContainer} />
        <Grid.Fluid classes={styles.partnerPanelContainer}>
          <PartnerPanel />
        </Grid.Fluid>
      </LazyView>

      <LazyView>
        <TotalCompletedServicesAndRating
          servicesCompleted={servicesCompleted}
          rating={rating}
          ratingDuration={1500}
        />
        <ReadyToGetStartedBanner
          onButtonClick={stopImmediatePropagation(toggleSiteNav)}
          classes={styles.readytoGetStartedBanner}
        />
        <ServiceReviews
          reviews={reviews}
          headerText="Customer Reviews"
          headerStyles="text-align-center"
          className={styles.reviews}
          carouselItemStyle={styles.reviewItem}
        />
      </LazyView>

      <LazyView>
        <MeetOurTechs classes={styles.meetOurTechsSection} />
        <HowCanWeHelp categories={skuCategories} push={push} />
        <BecomeATechBlock />
      </LazyView>
    </div>
  );
};

HomePage.propTypes = {
  push: PropTypes.func,
  rating: PropTypes.number,
  reviews: PropTypes.array,
  servicesCompleted: PropTypes.number,
  skuCategories: PropTypes.array.isRequired,
  toggleSiteNav: PropTypes.func.isRequired,
  hideMemberShip: PropTypes.bool,
};

HomePage.defaultProps = {
  push: () => {},
  reviews: [],
  skuCategories: [],
  toggleSiteNav: () => {},
};

export default React.memo(HomePage);
