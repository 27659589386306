import { fromJS } from 'immutable';
import { LAYOUT_UPDATE, INITIAL_LAYOUT } from '../constants/common';

export default function layoutReducer(state = fromJS({}), action) {
  let newState = state;
  if (!newState.get('layout')) {
    newState = newState.set('layout', fromJS(INITIAL_LAYOUT));
  }

  switch (action.type) {
    case LAYOUT_UPDATE: {
      // TODO refactor it
      if (action.deep) return newState.mergeDeep({ layout: action.layout });

      const newLayout = newState.get('layout').merge(action.layout);
      if (newLayout.equals(newState.get('layout'))) return newState;
      return newState.set('layout', newLayout);
    }
    default:
      return newState;
  }
}
