import { put, takeEvery, takeLatest, call, select } from 'redux-saga/effects';
import { requestStarted, requestFinished, displayErrors } from 'src/utils/request';
import {
  pageNotice,
  clearPageNotices,
  pageLoaded,
  updateUser,
  pageLoadingError,
} from 'src/actions/common';
import { defaultNotice, errorNotice } from 'src/utils/notice';
import { htToast } from 'HTKit/Toast';
import { LOAD_PAGE } from 'src/constants/common';
import { selectRoutes } from 'src/apiRoutes';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { checkPlanSubscriberPhone } from 'src/sagas/common/marketing';
import { AUTO_RENEW } from 'src/components/MemberBenefits/MemberAutoRenew/constants';
import { SUBSCRIPTION_CANCELLATION } from 'src/components/MemberBenefits/constants';
import { addErrorAppSnackNotice, clearAppNotices } from 'src/actions/snackNotice';
import { setBrandingState } from 'src/utils/branding/utils';
import { userStateSelector } from 'src/selectors/user';
import { logger } from 'src/utils/logger';
import {
  PAGE_NAME,
  TRIGGER_AUTO_RENEW,
  REQUEST_CANCELLATION,
  REDEEM,
  UPDATE_AUTO_RENEW_STATUS,
  CANCEL_SUBSCRIPTION,
} from './constants';
import {
  setRedemptionCode,
  toggleModal,
  setStats,
  setTransactions,
  setMembership,
} from './actions';

export function* triggerAutoPaymentSaga() {
  const routes = yield call(selectRoutes);
  yield put(clearPageNotices(PAGE_NAME));
  yield put(requestStarted());
  const requestResult = yield call(routes.plans.autoRenew);
  yield put(requestFinished());

  if (requestResult.err) {
    const { errors } = requestResult.data;
    yield put(pageNotice(PAGE_NAME, errorNotice(errors)));
  } else {
    const { user } = requestResult.data;
    yield put(pageNotice(PAGE_NAME, defaultNotice('Plan was updated.')));
    yield put(updateUser(user));
  }
}

export function* requestCancellationSaga() {
  const routes = yield call(selectRoutes);
  yield put(clearPageNotices(PAGE_NAME));
  yield put(requestStarted());
  const requestResult = yield call(routes.plans.requestCancellation);
  yield put(requestFinished());

  if (requestResult.err) {
    const { errors } = requestResult.data;
    yield put(pageNotice(PAGE_NAME, errorNotice(errors)));
  } else {
    const { user } = requestResult.data;
    yield put(
      pageNotice(PAGE_NAME, defaultNotice('Plan cancellation was successfully requested.')),
    );
    yield put(updateUser(user));
  }
}

/**
 * Submit subscription cancellation request
 * @param {Object} action
 * @param {Object} action.payload
 * @param {number} action.payload.id - subscription id
 * @param {Object} action.payload.reason
 * @param {number | null} action.payload.reason.subscription_cancellation_reason_id - id matches primary key in subscription_cancellation_reasons
 * @param {string} [action.payload.reason.other_reason] - freeform text input by client
 */
export function* cancelSubscriptionSaga(action) {
  const { id, reason } = action.payload;
  const routes = yield call(selectRoutes);
  yield put(clearAppNotices());

  yield put(requestStarted());
  const cancelResponse = yield call(routes.plans.subscriptions.cancel, { id }, reason);
  yield put(requestFinished());

  if (cancelResponse.err) {
    yield put(addErrorAppSnackNotice({ content: 'An error occured. Please try again later' }));
    yield put(toggleModal({ modalName: SUBSCRIPTION_CANCELLATION.modalName, forceState: false }));
    logger(PAGE_NAME)(`${cancelResponse.err} ${cancelResponse.data.errors}`);
  } else {
    /* On cancellation success fetch the updated user */

    yield put(requestStarted());
    const userResponse = yield call(routes.users.current, { account: true });
    yield put(requestFinished());

    if (userResponse.err) {
      yield put(addErrorAppSnackNotice({ content: 'An error occured. Please try again later' }));
      yield put(toggleModal({ modalName: SUBSCRIPTION_CANCELLATION.modalName, forceState: false }));
      logger(PAGE_NAME)(`${userResponse.err} ${userResponse.data.errors}`);
    } else {
      const { user } = userResponse.data;
      yield put(updateUser(user));
      yield put(toggleModal({ modalName: SUBSCRIPTION_CANCELLATION.modalName, forceState: true }));
    }
  }
}

function* redeemSaga({ skuId, modalName }) {
  yield put(requestStarted());
  const routes = yield call(selectRoutes);

  const requestResult = yield call(routes.users.account.skuRedemption, { id: skuId });

  if (requestResult.err) {
    yield put(displayErrors(requestResult));
    yield put(requestFinished());
  } else {
    const { sku_inventory: skuInventory } = requestResult.data;
    const { redemptionCode: code } = skuInventory;
    yield put(setRedemptionCode({ skuId, code }));
    yield put(toggleModal({ modalName }));
    yield put(requestFinished());

    // to reload the benefits count
    const response = yield call(routes.plans.subscriptions.statistic);
    if (!response.err) {
      const { statistic } = response.data;
      yield put(setStats({ statistic }));
    }
  }
}

function* updateAutoRenewStatus({ status, renewLocation = '', cancellationId = null }) {
  const { modalName, autoRenewLocation } = AUTO_RENEW;

  yield put(requestStarted());
  const routes = yield call(selectRoutes);

  const planId = yield select((state) => {
    const statistic = state.getIn(['pages', PAGE_NAME, 'statistic']);
    return statistic.subscriptionInfo.id;
  });
  let discount = false;
  const didCustomerRenewFromUpsell = status === true && renewLocation === autoRenewLocation;
  if (didCustomerRenewFromUpsell) {
    discount = true;
  }
  const requestResult = yield call(
    routes.plans.subscriptions.autoRenewStatus,
    { id: planId },
    {
      should_renew: status,
      discount,
      reason: cancellationId,
    },
  );

  if (requestResult.err) {
    yield put(displayErrors(requestResult));
    yield put(requestFinished());
  } else {
    // to reload the benefits count
    const response = yield call(routes.plans.subscriptions.statistic);
    const membershipResponse = yield call(routes.users.account.memberships);
    if (!response.err) {
      const { statistic } = response.data;
      const { membership } = membershipResponse.data;
      let content = '';
      if (didCustomerRenewFromUpsell) {
        content = 'Auto-renew is turned on. Promo rate will be applied on your renewal!';
      } else if (status === true) {
        content = 'Your changes have been saved.';
        yield put(toggleModal({ modalName }));
      } else {
        content = 'Auto-renew is turned off';
        yield put(toggleModal({ modalName }));
      }
      yield put(setStats({ statistic }));
      yield put(setMembership({ membership }));
      htToast(content);
      yield put(requestFinished());
    }
  }
}

function* loadSubscriptionStatistic() {
  const hasSubscription = yield select((state) =>
    state.getIn(['user', 'account', 'hasSubscription']),
  );
  if (!hasSubscription) {
    yield put(pageLoaded(PAGE_NAME, {}));
    return;
  }
  const routes = yield call(selectRoutes);
  const response = yield call(routes.plans.subscriptions.statistic);
  const transactionResponse = yield call(routes.users.account.transactions);
  const membershipResponse = yield call(routes.users.account.memberships);

  if (response.err) {
    const { errors } = response.data;
    yield put(pageNotice(PAGE_NAME, errorNotice(errors)));
    yield put(pageLoaded(PAGE_NAME, {}));
  } else {
    const { statistic } = response.data;
    const { transactions } = transactionResponse.data;
    const { membership } = membershipResponse.data;
    yield put(setTransactions({ transactions }));
    yield put(setMembership({ membership }));
    yield put(pageLoaded(PAGE_NAME, { statistic }));
  }
}

export function* pageSaga() {
  // Now that we are selling partner-branded plans we need to set branding state -GH Feb 2024
  const user = yield select(userStateSelector);

  if (!user) {
    /** Force a 401 unauthorized error to redirect to the sign in page */
    const requestResponse = {
      err: {
        response: { status: 401 },
      },
      data: {
        error: 'You need to sign in or sign up before continuing.',
      },
    };
    yield put(pageLoadingError(PAGE_NAME, requestResponse));
    return;
  }

  yield call(setBrandingState, { user });
  yield call(checkPlanSubscriberPhone, user.getIn(['account', 'hasSubscription']));

  yield loadUserProfileSaga(PAGE_NAME, true);
  yield call(loadSubscriptionStatistic);
}

export function* accountPlansFlow() {
  yield takeEvery((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
  yield takeEvery(TRIGGER_AUTO_RENEW, triggerAutoPaymentSaga);
  yield takeEvery(REQUEST_CANCELLATION, requestCancellationSaga);
  yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubscriptionSaga);
  yield takeEvery(REDEEM, redeemSaga);
  yield takeLatest(UPDATE_AUTO_RENEW_STATUS, updateAutoRenewStatus);
}

export default [accountPlansFlow];
