import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ModalV2 } from 'HTKit/Modals/ModalV2';
import Button, { THEMES as BUTTON_THEMES } from 'HTKit/Forms/Button';
import componentSelector from 'src/selectors/component';
import { PALO_ALTO_NETWORKS_DETAILS } from 'src/containers/BookingPagePaloAltoNetworks/constants';

const ConfirmButton = ({ close, theme }) => {
  const themeMappings = {
    [PALO_ALTO_NETWORKS_DETAILS.shortName]: BUTTON_THEMES.PALO_ALTO_PRIMARY,
    default: BUTTON_THEMES.V2PRIMARY,
  };
  const buttonTheme = themeMappings[theme] || themeMappings.default;

  return (
    <Button smallV2 theme={buttonTheme} onClick={close}>
      Got it
    </Button>
  );
};

ConfirmButton.propTypes = {
  close: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

const NoticeStack = ({ removeNotice, theme }) => {
  const noticeStack = useSelector(componentSelector('noticeStack'));
  const notices = noticeStack.get('notices');

  const closeNotice = useCallback(
    (noticeId) => () => {
      removeNotice(noticeId);
    },
    [],
  );

  return notices.map((notice) => {
    const noticeId = notice.get('noticeId');
    const close = closeNotice(noticeId);
    return (
      <ModalV2
        key={noticeId}
        isVisible
        header={notice.get('title')}
        hide={close}
        footerElement1={<ConfirmButton close={close} theme={theme} />}
      >
        <p className="p1 n700 paddingTop-small" dangerouslySetInnerHTML={{ __html: notice.get('content') }} />
      </ModalV2>
    );
  });
};

NoticeStack.propTypes = {
  noticeStack: PropTypes.object.isRequired,
  removeNotice: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

export default NoticeStack;
