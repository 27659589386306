export const AVAILABILITY_LOADED = 'availabilitySelector/AVAILABILITY_LOADED';
export const TOGGLE_HOUR = 'availabilitySelector/TOGGLE_HOUR';
export const REMOVE_EXCESS_SELECTED_TIME = 'availabilitySelector/REMOVE_EXCESS_SELECTED_TIME';
export const TOGGLE_HOUR_BY_INDEX = 'availabilitySelector/TOGGLE_HOUR_BY_INDEX';

export const SCREEN_WIDTHS = {
  maxWidth: 1920,
  desktop: 840,
  tablet: 600,
};
