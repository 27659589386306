import { DEFAULT_LAYOUT, HEADER_TEMPLATES, showPartnerFooter } from 'src/constants/common';
import HT_INFO from 'src/constants/hellotech';
import { ENV } from 'src/constants/app';

export const SKU_QUERY_PARAM_NAME = 'sku_id';

export const EV_BOOKING_FLOW_MAP = {
  CUSTOMER_INFO: 1,
  QA: 2,
  SCHEDULING: 3,
  PAYMENT: 4,
};

/**
 * Metadata for API registration requests.
 * This helps the backend identify and handle EV partner registrations differently.
 */
export const EV_REGISTRATION_METADATA = {
  source: 'workflow',
  source_name: 'EV',
};

/** The values here should match skuQuestion.group as set on BE */
export const EV_SKU_QUESTION_GROUPS = {
  HOME_INFORMATION: 'Home Information',
  DEVICE_TO_BE_INSTALLED: 'Device To Be Installed',
  PANEL_INFORMATION: 'Panel Information',
  CHARGER_INSTALLATION_LOCATION: 'Charger Installation Location',
  CUSTOM_OPTIONS: 'Custom Work (optional)',
};

export const EV_INSTALLATION_ESTIMATE_DISCLAIMER =
  "This price is an estimate based on what you've told us. A master electrician will get in contact to confirm the details of your install. Should additional scope be discovered, we reserve the right to adjust the estimate as necessary, with your approval. You'll never be charged until the work is done.";

export const EV_INSTALLATION_ESTIMATE_DISCLAIMER_ORDER_COMPLETE =
  "This price is an estimate based on what you've told us. Should additional scope be discovered, we reserve the right to adjust the estimate as necessary, with your approval. You'll never be charged until the work is done.";

export const EV_CUSTOM_ADJUSTMENT_LABEL = 'customitem';

/**
 * EV Partner Attributes for the object EV_ASSOCIATED_ATTRIBUTES.
 * Partner attributes might only exist from client, ie phone. We know this.
 * But, some partners/display might have specific UI considerations and what
 * we know of a partner name is subject to the tool. So, here is a base understanding.
 *
 * for example, we use "emporia" instead of "Emporia Energy", but only as a defining key that
 * sits outside user manipulation via the tool.
 *
 * @type {{d2c: string, emporia: string}}
 */
export const EV_ATTRIBUTES_TYPES = {
  emporia: 'emporia',
  d2c: 'hellotech',
};
/**
 * Fairly base EV attributes by default & Partner. For now, just phone and perhaps questionGroups.
 * Could expand to page images etc. But some rework would have go be done with the static image displays.
 *
 * @type {{default: {phone: {link: string, title: string, value: string}, questionGroups: {CHARGER_INSTALLATION_LOCATION: string, CUSTOM_OPTIONS: string, HOME_INFORMATION: string, DEVICE_TO_BE_INSTALLED: string, PANEL_INFORMATION: string}}, emporia: {phone: {link: string, title: string, value: string}, name: string, id: number[], questionGroups: {CHARGER_INSTALLATION_LOCATION: string, CUSTOM_OPTIONS: string, HOME_INFORMATION: string, DEVICE_TO_BE_INSTALLED: string, PANEL_INFORMATION: string}}}}
 */
export const EV_ASSOCIATED_ATTRIBUTES = {
  emporia: {
    name: 'Emporia Energy',
    leadGen: EV_ATTRIBUTES_TYPES.emporia,
    type: EV_ATTRIBUTES_TYPES.emporia,
    skuIdByEnv: {
      [ENV.DEVELOPMENT]: [10564],
      [ENV.STAGING]: [10564],
      [ENV.PRODUCTION]: [1627],
    },
    idByEnv: {
      // partner ids by environment
      [ENV.DEVELOPMENT]: 730329,
      [ENV.STAGING]: 730329,
      [ENV.PRODUCTION]: 979138,
    },
    questionGroups: EV_SKU_QUESTION_GROUPS,
    /* Phones should be attached to the partner in the db, imho */
    phone: {
      ...HT_INFO.phone.partners.emporia,
    },
  },
  default: {
    type: EV_ATTRIBUTES_TYPES.d2c,
    leadGen: EV_ATTRIBUTES_TYPES.d2c,
    name: 'default',
    questionGroups: EV_SKU_QUESTION_GROUPS,
    phone: {
      ...HT_INFO.phone.customers.default,
    },
  },
};

/*
*******************************************************
  Layouts
*******************************************************
*/

export const EV_BOOKING_LAYOUT = {
  ...DEFAULT_LAYOUT,
  ...showPartnerFooter,
  header: {
    template: HEADER_TEMPLATES.EVBooking,
    actionableLogo: false,
  },
};

export const EV_PORTAL_LAYOUT = {
  ...DEFAULT_LAYOUT,
  ...showPartnerFooter,
  header: {
    template: HEADER_TEMPLATES.EVPortal,
    actionableLogo: false,
  },
};

export const EV_QUOTE_GENERATION_LAYOUT = {
  header: { showHeader: false },
  footer: { show: false },
};

/**
 *******************************************************
   Workflow Data related

   Values are defined in the BE
 *******************************************************
 */

/**
 * Sourced from BE. Determines the current step in the workflow
 * See workflow.status provided by the BE endpoint.
 */
export const WORKFLOW_STATUS = {
  SITE_VISIT: 'site_visit',
  INSTALLATION: 'installation',
  FEEDBACK: 'feedback',
  COMPLETED: 'completed',
};

/**
 * This corresponds to workflow.type provided by the BE endpoint.
 * Different workflow types may have different sequences.
 */
export const WORKFLOW_TYPE = {
  EV: 'ev',
  EV_INSTALL_ONLY: 'ev_install_only',
};

export const WORKFLOW_STATUS_SEQUENCE = [
  WORKFLOW_STATUS.SITE_VISIT,
  WORKFLOW_STATUS.INSTALLATION,
  WORKFLOW_STATUS.FEEDBACK,
  WORKFLOW_STATUS.COMPLETED,
];

export const WORKFLOW_STEP_TYPE = {
  CART: 'Cart',
  Order: 'Order',
};

// When step type is "Cart", only these statuses apply
export const WORKFLOW_CART_STATUS = {
  NO_BOOKING: 'no_booking',
};

// When step type is "Order", only these statuses apply.
// These are the direct statuses based on `order.status_id` in the db except for standby and at risk
export const WORKFLOW_ORDER_STATUS = {
  PENDING: 'pending',
  SEARCHING: 'searching',
  ASSIGNED: 'assigned',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  TECH_PAID: 'tech_paid',
  INCOMPLETE: 'incomplete',
  // ABANDONED: 'abandoned', // most likely won't happen for EV
  UNSERVICEABLE: 'unserviceable',
  STANDBY: 'standby',
  AT_RISK: 'at_risk',
};

/**
 *******************************************************
   MISCELLANEOUS
 *******************************************************
 */

/**
 * See docs: https://docs.knowupfront.com/reference/programs
 *
 * These values correspond to `programs[i].homeType` from our BE endpoint.
 */
export const UPFRONT_INCENTIVE_HOME_TYPES = {
  SINGLE_FAMILY: 'singlefamily',
  MULTI_FAMILY: 'multifamily',
  WORKPLACE: 'workplace',
  FLEET: 'fleet',
};

export const UPFRONT_INCENTIVE_HOME_TYPE_DISPLAY_NAMES = {
  [UPFRONT_INCENTIVE_HOME_TYPES.SINGLE_FAMILY]: 'Single Family Home',
  [UPFRONT_INCENTIVE_HOME_TYPES.MULTI_FAMILY]: 'Multi-Family Home',
  [UPFRONT_INCENTIVE_HOME_TYPES.WORKPLACE]: 'Workplace',
  [UPFRONT_INCENTIVE_HOME_TYPES.FLEET]: 'Fleet',
};
