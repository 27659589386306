import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import classNames from 'classnames';
import ModalWrapper from '../ModalWrapper';
import Button, { THEMES } from '../../Forms/Button';
import styles from './styles.scss';

export const BUTTON_POSITIONS = { LEFT: 'left', RIGHT: 'right' };

export default class ConfirmModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    confirmButtonTitle: PropTypes.string,
    cancelButtonTitle: PropTypes.string,
    confirmButtonPosition: PropTypes.string,
    cancelButtonPosition: PropTypes.string,
    confirmButtonTheme: PropTypes.string,
    cancelButtonTheme: PropTypes.string,
    modalStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    titleStyles: PropTypes.object,
    descriptionStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  };

  static defaultProps = {
    title: 'Are you sure?',
    onCancel: () => {},
    onConfirm: () => {},
    confirmButtonTitle: 'Ok',
    cancelButtonTitle: 'Nevermind',
    confirmButtonPosition: BUTTON_POSITIONS.LEFT,
    cancelButtonPosition: BUTTON_POSITIONS.RIGHT,
    confirmButtonTheme: THEMES.DEFAULT,
    cancelButtonTheme: THEMES.SECONDARY,
  };

  state = { visible: false, params: {} };

  onClose = (event) => {
    event.preventDefault();
    this.close();
  };

  onConfirm = (event) => {
    event.preventDefault();
    this.props.onConfirm(this.state.params);
    this.setState({ visible: false, params: {} });
  };

  open = (params = {}) => {
    this.setState({ visible: true, params });
  };

  close = () => {
    this.props.onCancel(this.state.params);
    this.setState({ visible: false, params: {} });
  };

  renderButton(position) {
    return (
      <div>
        {this.props.cancelButtonPosition === position && (
          <Button theme={this.props.cancelButtonTheme} onClick={this.onClose}>
            {this.props.cancelButtonTitle}
          </Button>
        )}
        {this.props.confirmButtonPosition === position && (
          <Button theme={this.props.confirmButtonTheme} onClick={this.onConfirm}>
            {this.props.confirmButtonTitle}
          </Button>
        )}
      </div>
    );
  }

  render() {
    const propsToRender = omit(this.props, [
      'title',
      'description',
      'contentLabel',
      'isOpen',
      'onClose',
      'onConfirm',
      'modalStyles',
      'descriptionStyles',
    ]);
    const modalStyles = classNames(styles.modalWrapper, this.props.modalStyles);
    const titleStyles = classNames('h3 text-align-center', styles.modalTitle, this.props.titleStyles);
    const descriptionStyles = classNames(styles.modalDescription, this.props.descriptionStyles);
    return (
      <div>
        <ModalWrapper
          contentLabel="ConfirmModal"
          isOpen={this.state.visible}
          onClose={this.onClose}
          modalStyles={modalStyles}
          propsToRender={propsToRender}
        >
          <div className={titleStyles}>{this.props.title}</div>
          <p className={descriptionStyles}>{this.props.description}</p>
          <div className={styles.modalButtons}>
            <div>{this.renderButton(BUTTON_POSITIONS.RIGHT)}</div>
            <div>{this.renderButton(BUTTON_POSITIONS.LEFT)}</div>
          </div>
        </ModalWrapper>
      </div>
    );
  }
}
