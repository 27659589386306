// import { getMappedQaFlowSku } from 'src/containers/AddSkuPage/QuestionsFlowPage/utils';

export const CHECKBOX = 'checkbox';
export const RADIO = 'radio';
export const TEXTAREA = 'textarea';
export const DEFAULT_GROUP = 'A';
export const DIRECTION_NEXT = 'next';
export const DIRECTION_PREVIOUS = 'previous';
export const DIRECTION_COMPLETED = 'completed';

/* This animates the direction of our question flow */
export const ANIMATION_DIRECTION = {
  [DIRECTION_NEXT]: { from: '100%', enter: '0%', leave: '-100%' },
  [DIRECTION_PREVIOUS]: { from: '-100%', enter: '0%', leave: '100%' },
  [DIRECTION_COMPLETED]: { from: '0%', enter: '0%', leave: '0%' },
};

// Plan Holder Types
export const LEGACY_PLAN = 'legacyPlan';
export const SMART_HOME_PLAN = 'smartHomePlan';
export const NO_PLAN = 'noPlan';
export const TRUCK_ROLL_FEE = 69;

export const QUESTIONS_FLOW_CONTAINER_ID = 'questions-flow-page';

export const QA_FLOW_ANSWER_LAYOUTS = { ROW: 'row', COLUMN: 'column' };

const GOOGLE_AD_WORDS_TV_MOUNT_SKU_ID_QA_FLOW = {
  production: 1328,
  staging: 1319,
  default: 1328, // If your local DB matches staging then you'll need to change this to 1319 and also the mappings in src/containers/AddSkuPage/QuestionsFlowPage/skudata/constants.js
}; // New QA Flow treatment
export const GOOGLE_AD_WORDS_TV_MOUNT_SKU_ID_DEFAULT = 879; // Legacy Treatment

export const QA_FLOW_GOOGLE_ADS_MAPPING = {
  production: {
    [GOOGLE_AD_WORDS_TV_MOUNT_SKU_ID_DEFAULT]: GOOGLE_AD_WORDS_TV_MOUNT_SKU_ID_QA_FLOW.production,
  },
  staging: {
    [GOOGLE_AD_WORDS_TV_MOUNT_SKU_ID_DEFAULT]: GOOGLE_AD_WORDS_TV_MOUNT_SKU_ID_QA_FLOW.staging,
  },
  default: {
    [GOOGLE_AD_WORDS_TV_MOUNT_SKU_ID_DEFAULT]: GOOGLE_AD_WORDS_TV_MOUNT_SKU_ID_QA_FLOW.default,
  },
};

// export const QA_FLOW_VALID_SKUS = [
//   13,
//   38,
//   1034,
//   getMappedQaFlowSku({ skuId: GOOGLE_AD_WORDS_TV_MOUNT_SKU_ID_DEFAULT }), // 1328 or 1319
// ];

export const QA_FLOW_VALID_SKUS = [];
