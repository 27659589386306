import htLogo from 'src/images/HT-logo-SHP.png';
import { plansPath, getOrderStatusPath, accountPath, faqPath } from 'src/utils/paths';
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import { SRC_TYPES } from 'src/containers/SiteFAQ/constants';

export const TOGGLE_SEARCH_BAR = 'search/TOGGLE_SEARCH_BAR';
export const SEARCH_CLICK_SEGMENT = 'search/SEARCH_CLICK_SEGMENT';
export const SEARCH_RESULTS_CLICKED_SEGMENT = 'search/SEARCH_RESULTS_CLICKED_SEGMENT';

export const searchConfig = (apiKeys) => {
  const connector = new AppSearchAPIConnector({ ...apiKeys });

  return {
    alwaysSearchOnInitialLoad: false,
    a11yNotificationMessages: {
      searchResults: ({ searchTerm }) => `Searching for "${searchTerm}".`,
    },
    searchQuery: {
      search_fields: {
        name: {},
        category_name: {},
        seo_name: {},
      },
    },
    autocompleteQuery: {
      results: {
        result_fields: {
          name: {
            raw: {},
          },
          seo_name: {
            raw: {},
          },
          category_name: {
            raw: {},
          },
          category_icon: {
            raw: {},
          },
          category_seo_name: {
            raw: {},
          },
          average_review: {
            raw: {},
          },
          description: {
            raw: {},
          },
          available_through_customer_site: {
            raw: {},
          },
          remote_service: {
            raw: {},
          },
          remote_only_service: {
            raw: {},
          },
          is_product: {
            raw: {},
          },
          image: {
            raw: {},
          },
        },
      },
    },
    apiConnector: connector,
  };
};

export const PROMO_DETAILS = Object.freeze([
  {
    id: 1,
    title: 'Become a member and save up to $100 on your next service!',
    text: '',
    image: htLogo,
    link: plansPath(),
    paddedImage: false,
  },
]);

export const DEFAULT_TEXTS = Object.freeze({
  EMPTY_STATE: {
    title: 'No results for',
    text: 'Make sure all words are spelled correctly. Otherwise, please try different keywords.',
  },
  PLACEHOLDER: {
    heroSearchText: 'I need help with...',
    topNavSearchText: 'Search for a service or product',
  },
});

export const getServiceType = (service) => {
  switch (true) {
    case service.remote_service.raw === 'false' && service.remote_only_service.raw === 'false':
      return 'In-Home';
    case service.remote_only_service.raw === 'true':
      return 'Online';
    case service.remote_service.raw === 'true':
      return 'In-Home/Online';
    default:
      return null;
  }
};

export const categories = [
  {
    id: { raw: 1 },
    category_icon: { raw: 'ht-computer' },
    category_name: { raw: 'Computer Support' },
    category_seo_name: { raw: 'computers' },
  },
  {
    id: { raw: 2 },
    category_icon: { raw: 'ht-mobile-phone' },
    category_name: { raw: 'Mobile & Tablets' },
    category_seo_name: { raw: 'mobile' },
  },
  {
    id: { raw: 3 },
    category_icon: { raw: 'ht-wifi' },
    category_name: { raw: 'WiFi & Network' },
    category_seo_name: { raw: 'networking' },
  },
  {
    id: { raw: 4 },
    category_icon: { raw: 'ht-smart-home' },
    category_name: { raw: 'Smart Home' },
    category_seo_name: { raw: 'smart-home' },
  },
  {
    id: { raw: 5 },
    category_icon: { raw: 'ht-speakers' },
    category_name: { raw: 'Audio & Video' },
    category_seo_name: { raw: 'television-and-audio' },
  },
  {
    id: { raw: 6 },
    category_icon: { raw: 'ht-tv-mounting' },
    category_name: { raw: 'TV Mounting' },
    category_seo_name: { raw: 'tv-mounting' },
  },
  {
    id: { raw: 7 },
    category_icon: { raw: 'cat-icon-home-security' },
    category_name: { raw: 'Home Security' },
    category_seo_name: { raw: 'home-security' },
  },
  {
    id: { raw: 8 },
    category_icon: { raw: 'home-office-outline' },
    category_name: { raw: 'Around the Home' },
    category_seo_name: { raw: 'around-the-home' },
  },
];

export const quickLinks = [
  { id: 1, name: 'Track my order', link: getOrderStatusPath() },
  { id: 2, name: 'Manage my account', link: accountPath() },
  { id: 3, name: 'FAQ', link: faqPath() },
  {
    id: 4,
    name: 'Membership Cancellation',
    link: `${faqPath()}?src=${SRC_TYPES.MEMBERSHIP_GUARANTEE}`,
  },
];
