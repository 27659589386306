// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
// Components
import LegacyFeaturedIn from 'src/components/Sections/legacy/FeaturedIn';
import LegacyCustomerReviews from 'src/components/Sections/legacy/CustomerReviews';
import LegacyExpertInHomeService from 'src/components/Sections/legacy/ExpertInHomeService';
import LegacyQualifiedTechs from 'src/components/Sections/legacy/QualifiedTechs';
import ContentModal from 'HTKit/Modals/Content';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import HeroSectionAlticeGeneral from './HeroSectionAlticeGeneral'; // eslint-disable-line
import HeroSectionAlticeMovers from './HeroSectionAlticeMovers'; // eslint-disable-line
import HeroSectionAlticeTechSupport from './HeroSectionAlticeTechSupport'; // eslint-disable-line
// Data
import { setLandingCookies } from 'src/utils/cookies';
import { resolveLayout } from 'src/utils/layout';
import { layoutUpdate } from 'src/actions/common';
import { LANDING_TEMPLATES as LT } from 'src/constants/landings';
import { setCurrentLanding } from '../../actions';
import styles from './styles.scss';

class Altice extends Component {
  static propTypes = {
    landing: PropTypes.object.isRequired,
    reviews: PropTypes.array,
    layoutUpdate: PropTypes.func,
    setCurrentLanding: PropTypes.func,
  };

  static resolveHero(landing) {
    const { template } = landing;
    switch (template) {
      case LT.OPTIMUM_GENERAL:
      case LT.SUDDENLINK_GENERAL:
        return <HeroSectionAlticeGeneral details={landing.header} />;
      case LT.OPTIMUM_MOVERS:
      case LT.SUDDENLINK_MOVERS:
        return <HeroSectionAlticeMovers details={landing.header} />;
      case LT.OPTIMUM_SUPPORT:
      case LT.SUDDENLINK_SUPPORT:
        return <HeroSectionAlticeTechSupport details={landing.header} />;
      default:
        throw new Error(`Unknown template: ${template}`);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.layoutUpdate(resolveLayout(this.props.landing.get('template')));
  }

  state = {
    modalVisible: true,
  };

  onModalClose = () => {
    this.setState({ modalVisible: false });
  };

  markAsPartner = () => {
    const { landing } = this.props;
    const info = {
      id: landing.get('id'),
      landingId: landing.get('id'),
      seoName: landing.get('seoName'),
      template: landing.get('template'),
    };
    this.props.setCurrentLanding(info);
    setLandingCookies(landing);
    this.onModalClose();
  };

  render() {
    const landing = this.props.landing.toJS();
    const { reviews } = this.props;
    const { modalVisible } = this.state;
    return (
      <div className="site-v2">
        <Helmet title={landing.pageTitle} />
        {Altice.resolveHero(landing)}
        <LegacyFeaturedIn backgroundColor="white" />
        <LegacyExpertInHomeService backgroundColor="white" />
        <LegacyQualifiedTechs backgroundColor="grey" />
        <LegacyCustomerReviews reviews={reviews} backgroundColor="white" />
        <ContentModal visible={modalVisible} onCancel={this.onModalClose}>
          <div className={`${styles.modal} site-v2`}>
            <p className="h3 text-align-center">
              Welcome! Were you referred to HelloTech by Optimum or Suddenlink?
            </p>
            <div className={styles.modalButtons}>
              <div>
                <Button mediumV2 theme={THEMES.V2PRIMARY} onClick={this.markAsPartner}>
                  Yes
                </Button>
              </div>
              <div>
                <Button mediumV2 theme={THEMES.V2SECONDARY} onClick={this.onModalClose}>
                  No
                </Button>
              </div>
            </div>
          </div>
        </ContentModal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.landing.toJS();
  return {
    reviews: state.getIn(['entities', 'reviews', 'landings', id.toString()], null),
  };
}

export default connect(mapStateToProps, { layoutUpdate, setCurrentLanding })(Altice);

/*
  Altice:
    Handles the rendering of landing pages for our partner Altice.

  Notable Methods:
    resolveHero:
      description:  Currently Altice has multiple landing pages under the "Suddenlink" and "Optimum" brand names. This method
                    handles rendering the correct hero image/component.

  Last modified: -GH May 10, 2017
*/
