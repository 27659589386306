import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Utils
import { sleep } from 'src/utils/helpers';
// Components
import ContentModal from 'HTKit/Modals/Content';
import SlideModal from 'HTKit/Modals/Slide';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { isMobileOrTablet as isMobileOrTabletUI } from 'src/utils/ui';
import MainContent from './MainContent';
import styles from './styles.scss';

export const CTA_BUTTON_TEXT = 'Add Membership to Your Order';

const PlanModal = ({
  plan,
  sku,
  visible,
  closePlanModal,
  addPlan,
  plansInfo = {},
  ctaButtonText = CTA_BUTTON_TEXT,
}) => {
  if (plan.isEmpty() || !Object.keys(plansInfo).length) return null;
  const contentModalContentStyles = cn('site-v2', styles.contentModal);
  const SCROLL_ELEM = 'planModal-scrollElem';
  const isMobileOrTablet = isMobileOrTabletUI();

  useEffect(() => {
    /*
    This solves an issue an issue with iOS devices and overflow scroll. See JIRA ticket ISSUE-220
    The container needs to scroll overflow-y to display content, but iOS Safari caused the "X" close
    icon to have its appearance delayed for up to N number of seconds. I observed an average of 2-3 seconds
    */
    const el = document.getElementById(SCROLL_ELEM);
    if (!isMobileOrTablet || !el) return;
    const removeClass = () => el.classList.remove(styles.innerMainContent);
    const addClass = () => sleep(250).then(() => el.classList.add(styles.innerMainContent));
    if (visible) addClass();
    if (!visible) removeClass();
  }, [visible, isMobileOrTablet]);

  return !isMobileOrTablet ? (
    <ContentModal
      visible={visible}
      onCancel={closePlanModal}
      styles={contentModalContentStyles}
      closeStyles={styles.closeButton}
    >
      <MainContent plan={plan} sku={sku} plansInfo={plansInfo} />
      <div className={styles.ctaWrapper}>
        <Button theme={THEMES.V2PRIMARY} className={styles.ctaButton} onClick={addPlan}>
          {ctaButtonText}
        </Button>
      </div>
    </ContentModal>
  ) : (
    <SlideModal
      open={visible}
      handleClose={closePlanModal}
      showLogo={false}
      headerStyles={styles.slideHeader}
      contentStyles="site-v2"
      childrenStyles={styles.childrenWrapper}
      closeStyles={styles.slideCloseButton}
    >
      <div className={styles.outerMainContent}>
        <div id={SCROLL_ELEM}>
          <MainContent plan={plan} sku={sku} plansInfo={plansInfo} />
        </div>
      </div>
      <div className={styles.ctaButtonWrapper}>
        <Button theme={THEMES.V2PRIMARY} onClick={addPlan}>
          {ctaButtonText}
        </Button>
      </div>
    </SlideModal>
  );
};

PlanModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  plan: PropTypes.object,
  sku: PropTypes.object,
  plansInfo: PropTypes.object,
  closePlanModal: PropTypes.func.isRequired,
  addPlan: PropTypes.func.isRequired,
  ctaButtonText: PropTypes.string,
};

export default memo(PlanModal);
