import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import useAPI from 'src/hooks/useAPI';
import Cookies from 'js-cookie';
import { useLeadGeneration } from 'src/utils/leadGenerated/useLeadGeneration';
import { logger } from 'src/utils/logger';
import { noop } from 'src/utils/event';
import { displayErrors } from 'src/utils/request';
import { useExtractCartTokenFromQuery } from 'src/utils/urlQuery';
import { cartWorkflowStatusIsSiteVisit, extractCartTokenFromWorkflow, buildEVPaths } from './utils';
import useLoadUser from 'src/hooks/useLoadUser';
// Paths
import { push } from 'src/utils/paths';
// Selectors
import { pureCartSelector } from 'src/selectors/cart';
import { questionJSSelector } from 'src/selectors/questions';
import { skuByIdSelector } from 'src/selectors/skus';
import { pureUserSelector } from 'src/selectors/user';
import { selectedServiceTimesSelector } from 'src/containers/BookingPage/SummaryPage/selectors';
import { phoneTitleSelector, phoneLinkSelector } from 'src/selectors/tracking';
// Actions
import { updateAuth, userLoaded } from 'src/containers/AppBase/actions';
import { userSignedUp } from 'src/containers/RegistrationPage/actions';
import { updateSkuQuestions, updateCart } from 'src/containers/AddSkuPage/actions';
import { toggleModal } from 'src/containers/BookingPage/AvailabilityPage/actions';
import { skuDataLoaded } from 'src/actions/sku';
import { addErrorAppSnackNotice } from 'src/actions/snackNotice';
// Constants
import { NEED_AT_LEAST_ONE_VALID_DATE } from 'src/constants/cart';
import {
  EV_REGISTRATION_METADATA,
  EV_BOOKING_FLOW_MAP,
  EV_ASSOCIATED_ATTRIBUTES,
  EV_ATTRIBUTES_TYPES,
} from './ev.constants';
import { matchPath, useLocation } from 'react-router';
import HT_INFO from 'src/constants/hellotech';

/**
 * Props for breadcrumbs
 */
export const useBreadcrumbsProps = ({ currentFlow }) => {
  const storedCartJS = useSelector(pureCartSelector);
  const cartIsSiteVisit =
    cartWorkflowStatusIsSiteVisit(storedCartJS) ||
    currentFlow === EV_BOOKING_FLOW_MAP.CUSTOMER_INFO;

  const bookingFlowMap = { ...EV_BOOKING_FLOW_MAP };

  if (cartIsSiteVisit) {
    delete bookingFlowMap.PAYMENT;
  } else {
    delete bookingFlowMap.CUSTOMER_INFO;
  }

  const bookingFlowValues = Object.values(bookingFlowMap);
  const numberOfSteps = bookingFlowValues.length;
  const currentStepIndex = bookingFlowValues.indexOf(currentFlow) + 1;

  return {
    activeStep: currentStepIndex,
    numSteps: numberOfSteps,
    completedStep: currentStepIndex,
  };
};

/**
 * Ensure that redux is updated with the cart from the URL query params.
 */
export const useCartFromUrlQuery = () => {
  const dispatch = useDispatch();
  const api = useAPI();

  const [cartLoaded, setCartLoaded] = useState(false);

  const storedCartJS = useSelector(pureCartSelector);
  const manualCartToken = useExtractCartTokenFromQuery();

  useEffect(() => {
    (async () => {
      if (cartLoaded) {
        return;
      }

      // Let's just use the cart from redux if no cart token is found in the URL
      if (!manualCartToken) {
        setCartLoaded(true);
      } else {
        const { token } = storedCartJS || {};

        if (token !== manualCartToken) {
          try {
            const { err, data } = await api.cart.find({ token: manualCartToken, breakdown: true });

            if (err) {
              throw new Error(String(err));
            }
            dispatch(updateCart({ cart: data.cart, replace: true }));
            setCartLoaded(true);
          } catch (e) {
            logger('useCartFromUrlQuery')(e);
          }
        } else {
          setCartLoaded(true);
        }
      }
    })();
  }, [manualCartToken, storedCartJS]);

  return { cartLoaded };
};

/**
 * When an account is created or the user is auto-logged in via the CustomerInfoPage, we need to
 * generate a workflow for the user. Having a workflow at this point will allow for email campaigns
 * to direct the user to the PortalPage, which requires a workflow token to load. Additionally, when
 * generating a workflow, a cart will be created as well.
 *
 * Here, check if the user has a workflow. If not, generate one and return the associated cart. Otherwise
 * return the user's existing cart on their current workflow.
 */
export const useGenerateWorkflow = ({ sku_id }) => {
  const api = useAPI();

  const getCartTokenForWorkflow = useCallback(async () => {
    // Check if user as a workflow
    try {
      const getWorflowDataResponse = await api.workflows.last();

      const { err, data } = getWorflowDataResponse;
      if (err) {
        throw new Error(String(err));
      }

      const { workflow } = data;
      const cartToken = extractCartTokenFromWorkflow(workflow);
      if (cartToken) {
        return cartToken;
      }
    } catch (e) {
      logger('useGenerateWorkflow - get')(e);
      return null;
    }

    // If there's no workflow with a cart token, generate a new workflow. SkuId is required.
    try {
      const { err: createError, data: createData } = await api.workflows.create({
        ...(sku_id && { sku_id }),
      });

      if (createError) {
        throw new Error(String(createError));
      }

      const { workflow } = createData;
      return extractCartTokenFromWorkflow(workflow);
    } catch (e) {
      logger('useGenerateWorkflow - create')(e);
    }

    return null;
  }, [api]);

  return { getCartTokenForWorkflow };
};

/**
 * If skuId is present, load sku details for the header to render a possible partner logo.
 * On a fresh look of this page, workflow/cart object does not exist. We need to rely
 * on the skuId to pull the partner logo. However, in editMode, there's no need to
 * load sku details. A cart will exist at this point, and the cart object will have
 * partner logos embeded.
 *
 * Additionally, with the new generic flow, a skuId in the url query is a requirement as
 * it's the only way to carry over the skuId to the QA page. Throw up a snackbar if none
 * exists.
 */
export const useLoadEVCustomerPage = ({ skuId, isEditMode }) => {
  const dispatch = useDispatch();
  const [pageLoaded, setPageLoaded] = useState(false);
  const skuJS = useSelector(skuByIdSelector(skuId));
  const api = useAPI();
  /**
   * Load cart for the edge case when the client is editing info and a page refresh occurs.
   * Cart is needed to populate the partner logo in the header.
   */
  useCartFromUrlQuery();

  useEffect(() => {
    (async () => {
      /**
       * Partner logo will be pulled from the cart if needed when in edit mode.
       */
      if (isEditMode) {
        setPageLoaded(true);
        return;
      }

      if (!isEditMode && !skuId) {
        dispatch(
          addErrorAppSnackNotice({
            content: 'Unable to locate the service. Please contact Support.',
          }),
        );
        logger('useLoadEVCustomerPage')('No skuId found in url query');
        return;
      }

      if (!pageLoaded) {
        if (isEmpty(skuJS)) {
          const { err, data } = await api.skus.find({ id: skuId });
          if (!err) {
            const { sku } = data;
            dispatch(skuDataLoaded({ skuId, skuData: sku }));
            setPageLoaded(true);
          } else {
            dispatch(
              addErrorAppSnackNotice({
                content: 'Unable to locate the service. Please contact Support.',
              }),
            );
            logger('useLoadEVCustomerPage')(JSON.stringify(data));
          }
        } else {
          setPageLoaded(true);
        }
      }
    })();
  }, [pageLoaded, skuJS, skuId, isEditMode, dispatch]);

  return {
    pageLoaded,
  };
};

export const useEVClientRegistration = ({ onError = () => {}, skuId }) => {
  const dispatch = useDispatch();
  const cart = useSelector(pureCartSelector);
  const evAttributes = useExtractEVPartnerClientAttributes(skuId);
  const api = useAPI();
  const { submitLeadGenerated } = useLeadGeneration();
  /**
   * Registers a new user for EV. This is different than normal registration because we don't
   * require a BE to create an account.
   *
   * @async
   * @function
   * @param {Object} params - The user's registration information.
   * @param {string} params.firstName - The user's first name.
   * @param {string} params.lastName - The user's last name.
   * @param {string} params.email - The user's email address.
   * @param {string} params.phone - The user's phone number.
   * @param {string} params.fullAddress - The user's full address.
   * @param {string} [params.address2] - The user's secondary address (e.g., Apt/Unit). Optional.
   * @param {Object} [opts] - Additional options.
   * @param {string | null} [opts.source] - The source of the registration.
   * @param {string | null} [opts.source_name] - The name of the source of the registration.
   * @returns {Promise<Object>} A promise that resolves with the registered user's data.
   * @throws {Error} Throws an error if the registration fails.
   */
  const createUser = useCallback(
    async (params, opts = {}) => {
      api.toggleLoader(true);

      try {
        const payload = {
          user: params,
          // TODO - `source` and `source_name` are needed but need to be dynamic
          ...EV_REGISTRATION_METADATA,
          ...opts,
        };
        const { err, data } = await api.users.registration(payload);

        if (err) {
          throw new Error(String(err));
        }

        const { user } = data;

        Cookies.set('new_user', true);

        dispatch(updateAuth({ authToken: user.authToken }));

        // Analytics
        dispatch(userLoaded(user));
        dispatch(userSignedUp({ user, attributes: params }));

        if (cart) {
          const source = evAttributes.leadGen || EV_ATTRIBUTES_TYPES.d2c;
          submitLeadGenerated({
            email: params.email,
            name: source,
            source: 'evDefaultLandingPage',
            page: 'evContactInfo',
          });
        }
        return data;
      } catch (e) {
        onError(e);
        return null;
      } finally {
        api.toggleLoader(false);
      }
    },
    [cart, api],
  );

  return { createUser };
};

/**
 * This calls the /skus/124/questions api and sets in redux.
 * Also returns the api.
 */
export const useUpdateQuestionsAPI = () => {
  const [response, setResponse] = useState(null);
  const [responseError, setError] = useState(null);

  const api = useAPI();
  const dispatch = useDispatch();

  const fetchQuestions = async ({ skuId }) => {
    try {
      api.toggleLoader(true);
      const { err, data } = await api.skus.questions({ id: skuId });
      if (err) {
        throw new Error(String(err));
      }
      dispatch(updateSkuQuestions({ skuId, questions: data.questions }));
      setResponse(data.questions);
    } catch (error) {
      logger('useReviewPageData - fetchQuestions')(error);
      setError(error);
    } finally {
      api.toggleLoader(false);
    }
  };

  return {
    fetchQuestions,
    error: responseError,
    fetchQuestionsResponse: response,
  };
};

export const useReviewPageData = () => {
  const api = useAPI();
  const dispatch = useDispatch();

  const { cartLoaded } = useCartFromUrlQuery();
  const { loadUser } = useLoadUser();

  const cart = useSelector(pureCartSelector);
  const user = useSelector(pureUserSelector);
  const selectedServiceTimes = useSelector(selectedServiceTimesSelector);
  // Need to load user because of page refreshes
  useEffect(() => {
    loadUser();
  }, []);

  // We are making an assumption that there is only one item in the cart
  const { items = [] } = cart;
  const item = items[0] || {};
  const { skuId } = item;
  const skuQuestions = useSelector(questionJSSelector(skuId)); // Question text is in Redux
  const answers = item.questions; // item.questions only has answer ids, not question's text

  /** Extract question and answer text from skuQuestions */
  const formattedAnswers = Object.keys(answers || {}).reduce((acc, curr) => {
    const matchingQuestion = (skuQuestions || []).find((q) => q.id === Number(curr)) || {
      answers: [],
    };
    let answerText = '';
    switch (matchingQuestion.inputType) {
      // Right now we only have to worry about dropdowns, but this may change in the future.
      case 'dropdown':
        answerText = (matchingQuestion.answers.find((a) => a.id === (answers[curr] || {}).id) || {})
          .text;
        break;
      default:
        break;
    }
    acc.push({ questionText: matchingQuestion.textDirect, answerText });
    return acc;
  }, []);

  useEffect(() => {
    // If we don't have the sku questions, fetch them and update Redux.
    const fetchQuestions = async () => {
      try {
        api.toggleLoader(true);
        const { err, data } = await api.skus.questions({ id: skuId });
        if (err) {
          throw new Error(String(err));
        }
        dispatch(updateSkuQuestions({ skuId, questions: data.questions }));
      } catch (error) {
        logger('useReviewPageData - fetchQuestions')(error);
      } finally {
        api.toggleLoader(false);
      }
    };

    if (cartLoaded && !skuQuestions && item && skuId) {
      fetchQuestions();
    }
  }, [skuId, cartLoaded]);

  return {
    cart,
    user,
    selectedServiceTimes,
    formattedAnswers,
    skuId,
  };
};

export const useSubmitEVBooking = ({ onSuccess = noop, onError = noop } = {}) => {
  const api = useAPI();
  const dispatch = useDispatch();
  const manualCartToken = useExtractCartTokenFromQuery();

  const submitEVBooking = useCallback(async () => {
    try {
      api.toggleLoader(true);
      const response = await api.cart.completeBooking({ token: manualCartToken });
      const { err, data } = response;
      if (err) {
        const errors = data.errors || [];
        const errMsg = errors[0];
        if (errMsg === NEED_AT_LEAST_ONE_VALID_DATE) {
          dispatch(push(buildEVPaths({ pathType: 'scheduling', token: manualCartToken })));
          dispatch(toggleModal({ modalName: 'invalidDates' }));
        } else {
          dispatch(displayErrors(response));
        }
        throw new Error(String(err));
      }
      dispatch(updateCart({ cart: data.cart, replace: true }));
      onSuccess(data);
    } catch (e) {
      logger('useSubmitEVBooking')(e);
      onError(e);
    } finally {
      api.toggleLoader(false);
    }
  }, [onSuccess, onError]);
  return { submitEVBooking };
};

/**
 * Inevitably we need specific client side partner attributes, and we need a way to match
 * with actual BE partner objects. This hands back the client concerns, ie phone and matching name (see EV_ATTRIBUTES_TYPES)
 *
 * Later, if there are other concerns, we can add them to the base Attribute object (EV_ASSOCIATED_ATTRIBUTES).
 * @param partner: {id, name} || string || number;
 */
export const useExtractEVPartnerClientAttributes = (partner) => {
  const [clientPartnerAttr, setClientPartnerAttr] = useState(EV_ASSOCIATED_ATTRIBUTES.default);
  /* Lowercase it */
  const lowerCase = (str) => (typeof str === 'string' ? str.toLowerCase() : '');
  /* Partner arg could be object, string or number */
  const partnerObjArg = { string: 1, number: 1 }[typeof partner]
    ? { id: partner, name: partner }
    : partner || undefined;

  // 1. Try to get matches.
  const [, partnerObjectMatch] =
    Object.entries(EV_ASSOCIATED_ATTRIBUTES).find(([, { idByEnv, skuIdByEnv, name }]) => {
      if (!partnerObjArg) return false;

      // by id
      return (
        +partnerObjArg.id === +idByEnv?.[process.env.NODE_ENV] ||
        /* workflow is exhibiting race conditions, so lets also use skuId */
        +skuIdByEnv?.[process.env.NODE_ENV].includes(+partnerObjArg.id) ||
        // by name
        lowerCase(name).includes(lowerCase(partnerObjArg.name)) ||
        lowerCase(partnerObjArg.name).includes(lowerCase(name))
      );
    }) || [];

  useEffect(() => {
    if (partnerObjectMatch) {
      setClientPartnerAttr(partnerObjectMatch);
    }
  }, [partnerObjectMatch]);

  return omit(
    {
      ...clientPartnerAttr,
      id: +clientPartnerAttr.idByEnv?.[process.env.NODE_ENV] || partnerObjArg?.id,
    },
    'idByEnv',
  );
};

/**
 * No way to get at skuid from url by params because header sits "outside" the route.
 * We use this to get the skuId from the url.
 *
 *
 * @param path
 * @param keysWithPredicate
 *   - include the keys you want to match on and the predicate to match on. If auto, just include () => true
 *   - no keysWithPredicate means we just return all.
 * @returns {*|null}
 */
export const useMatchParamsByKey = (path, keysWithPredicate = null) => {
  const { pathname } = useLocation();
  const { params } = matchPath(pathname, { path }) || {};

  if (!params) return {};
  if (!keysWithPredicate) return params;

  const requestedParams = Object.entries(keysWithPredicate).reduce((acc, [key, predicate]) => {
    if (predicate(params[key])) {
      acc[key] = params[key];
    }
    return acc;
  }, {});

  return requestedParams || {};
};

export const useGetEVPhoneNumber = ({ partnerName = '' } = {}) => {
  const defaultPhoneTitle = useSelector(phoneTitleSelector);
  const defaultPhoneLink = useSelector(phoneLinkSelector);

  const {
    phone: { partners },
  } = HT_INFO;

  const evPhoneTitle = partners[partnerName]?.title || defaultPhoneTitle;
  const evPhoneLink = partners[partnerName]?.link || defaultPhoneLink;

  return {
    evPhoneTitle,
    evPhoneLink,
  };
};
