import { fromJS, Iterable, Map, List } from 'immutable';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import omit from 'lodash/omit';
import get from 'lodash/get';

// eslint-disable-next-line import/prefer-default-export
export const ensureImmutable = (data) => (Iterable.isIterable(data) ? data : fromJS(data));

/**
 * Create sleeper method akin to java in which once the timing resolves
 * resolve a promise and then you can ".then" off of it. A glorified
 * setTimeout w/o all the type.
 *
 * Usage: sleep(1000).then(action.doSomething())
 *        within an async method: await sleep(1000);
 */
export const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

/**
 * Lets return a truthy if the object is an immutable Map object.
 * @param obj
 * @returns {boolean}
 */
export const isImmutableMap = (obj = {}) => Map.isMap(obj);

/**
 * Lets return a truthy if the object is an immutable List object.
 * Needed if you want to apply List specific methods on the object ie. push
 *
 * @param obj
 * @returns {boolean}
 */
export const isImmutableList = (obj = {}) => List.isList(obj);

/**
 * Lets return a truthy if the object is an immutable iterator
 * @param obj
 * @returns {boolean}
 */
export const isImmutable = (obj = {}) => Iterable.isIterable(obj);

/**
 * Return the JS object of the immutable object if applicable
 * @param obj
 * @returns obj
 */
export const immutableToJS = (obj) => (isImmutable(obj) ? obj.toJS() : obj);

/**
 * To keep parity with current HT site dyanmics, we will not "sub" in character codes. Lets just
 * remove the not-wanted characters.
 *
 * We are case insensitive, multiline and global.
 * @param str
 * @returns {string}
 */
export const sanitizeString = (str = '') => str.replace(/(<([^>]+)[>;])/gim, '');

/**
 * Collate and objectify only those values that have a value. Used to pass into
 * apis only the key/values pairs that have a value.
 *
 * Optional omit by passing in a single or array of values
 *
 * @param object
 * @returns {object}
 */
export const sanitizeObject = (obj, omitValues) =>
  omit(pickBy(obj, identity), [].concat(omitValues));

/**
 * Try to get at the Response error. Not sure why there isn't a standard.
 * These are the error paths I've seen.
 *
 * @returns {string }
 * @param response
 */
export const getResponseError = (error = {}) => {
  // Order matters
  const errorPaths = ['data.errors.0', 'data.error.0', 'data.error', 'data.errors'];
  /* String is passed, use it. */
  if (typeof error === 'string') return error;

  /* Try to derive error */
  return (
    get(
      error,
      errorPaths.find((err) => typeof get(error, err) === 'string'),
    ) ||
    error.err ||
    'Generic error'
  );
};

/**
 * Takes in a string and returns only the numbers in string form.
 *
 * "(999) 999 - 9999" => "9999999999"
 *
 * @param {*} str
 * @returns {string}
 */
export const extractNumberFromString = (str = '') => str.replace(/\D+/g, '');
