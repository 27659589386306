import { useEffect } from 'react';
import useAPI from 'src/hooks/useAPI';
import { useSelector, useDispatch } from 'react-redux';
import { skuByIdSelector } from 'src/selectors/skus';
import { skuDataLoaded } from 'src/actions/sku';
import { logger } from 'src/utils/logger';

export const useExceedMaxServicesWarningModal = ({ skuId, isVisible, onFetchError = () => {} }) => {
  const dispatch = useDispatch();
  const api = useAPI();

  /** Complete sku details from Redux */
  const storedSkuDetails = useSelector(skuByIdSelector(skuId));

  const fetchSkuDetails = async () => {
    try {
      api.toggleLoader(true);
      const response = await api.skus.find({ id: skuId });
      dispatch(skuDataLoaded({ skuId, skuData: response.data.sku }));
    } catch (e) {
      logger('useExceedMaxServicesWarningModal')(e);
      onFetchError();
    } finally {
      api.toggleLoader(false);
    }
  };

  useEffect(() => {
    // Fetch complete SKU details if not already in Redux store
    if (isVisible && !storedSkuDetails) {
      fetchSkuDetails();
    }
  }, [isVisible, storedSkuDetails]);

  return {
    fetchSkuDetails,
    storedSkuDetails,
  };
};
