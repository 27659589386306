import React from 'react';

import useWindowType, { WIN_SM, WIN_MD } from 'src/hooks/useWindowType';
import DarkOverlay from './DarkOverlay';
import Nav from './Nav';


const Sidebar = (props) => {
  const windowType = useWindowType();
  if (![WIN_SM, WIN_MD].includes(windowType)) return null;

  return (
    <>
      <DarkOverlay {...props} />
      <Nav {...props} />
    </>
  );
};

export default Sidebar;
