import { useEffect, useState } from 'react';

/**
 * Find the index of the first date with available hours.
 *
 * @param {Array} availability - The array containing dates and their respective hours.
 * @returns {number | null} The index of the first available date or null if none is found.
 */
export const useFirstAvailableIndex = (availability) => {
  const [firstAvailableIndex, setFirstAvailableIndex] = useState(null);
  useEffect(() => {
    const foundIndex = availability.findIndex((dateData) =>
      dateData.hours.some((hourArray) => hourArray.some((hourData) => hourData.valid)),
    );

    // Set the index only if a valid one is found, otherwise set to null
    setFirstAvailableIndex(foundIndex !== -1 ? foundIndex : null);
  }, [availability]);

  return firstAvailableIndex;
};
