import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// Hooks
import { useDispatch, useSelector } from 'react-redux';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import { stopImmediatePropagation } from 'src/utils/event';
// Selectors
import { phoneLinkSelector } from 'src/selectors/tracking';
// HOC
import withToggleSiteNav from 'src/hoc/withToggleSiteNav';
// Constants
import { CATEGORY_MENU } from 'src/components/Sidebar/constants';
import { LAST_FOUR_DEFAULT_HOME_PAGE } from 'src/components/PhoneDisplayLink';
import { SERVICES_MENU } from 'src/components/Header/TopNavHeader/constants';
// Components & Styles
import HTKustomerChat from 'src/utils/Kustomer/HTKustomerChat';
import { Badge } from 'src/components/Elements/Badge';
import styles from '../styles.scss';

const FooterLineItem = ({
  children,
  className,
  link,
  target,
  action,
  toggleSiteNav,
  isServicesLink,
  isChatLink,
  isPhoneLink,
  badge,
  openModal,
}) => {
  const dispatch = useDispatch();
  const phoneLink = useSelector(phoneLinkSelector);

  const { splitConfig, splitTreatment } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_NAV_PHONE_NUMBER,
  );
  let phoneDisplay = '';
  const showPhone =
    phoneLink.endsWith(LAST_FOUR_DEFAULT_HOME_PAGE) &&
    [splitioConstants.OFF, splitioConstants.CONTROL].includes(splitTreatment);

  const attr = {};

  if (link) attr.href = link;
  if (target) attr.target = target;
  if (action) attr.onClick = () => dispatch(action);
  if (openModal) attr.onClick = openModal;

  if (isServicesLink) {
    attr.onClick = stopImmediatePropagation(() =>
      dispatch(() =>
        toggleSiteNav({ sidebarCategory: CATEGORY_MENU, topNavCategory: SERVICES_MENU }),
      ),
    );
  }

  if (isChatLink) attr.onClick = () => HTKustomerChat.open();

  if (isPhoneLink) {
    phoneDisplay = `Call: ${splitConfig.title}`;
    attr.href = splitConfig.link;
  }

  const displayText = isPhoneLink ? phoneDisplay : children;

  if (isPhoneLink && !showPhone) return null;
  return (
    <a className={cn(styles.footerLineItem, className)} {...attr}>
      {displayText}
      {badge && (
        <span className="marginLeft-tiny1">
          <Badge {...badge}>{badge.text}</Badge>
        </span>
      )}
    </a>
  );
};

FooterLineItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  link: PropTypes.string,
  target: PropTypes.string,
  action: PropTypes.object,
  toggleSiteNav: PropTypes.func,
  isServicesLink: PropTypes.bool,
  isProductsLink: PropTypes.bool,
  isChatLink: PropTypes.bool,
  isPhoneLink: PropTypes.bool,
  badge: PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
  }),
  openModal: PropTypes.func,
};

export default compose(withToggleSiteNav())(FooterLineItem);
