import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { pathnameSelector } from 'src/selectors/router';
import { logger } from 'src/utils/logger';
import PureErrorPage from './PureErrorPage';

const ErrorPage = ({ popularSkus, page, code }) => {
  const errorCode = page ? page.getIn(['error', 'status']) : code;
  const errorMsgs = page ? page.getIn(['error', 'messages'], '') : '';
  const pathName = useSelector(pathnameSelector);

  useEffect(() => {
    logger('Error Page')(`${pathName}${errorMsgs ? ` - ${errorMsgs}` : ''}`);
  }, []);

  return <PureErrorPage code={errorCode} popularSkus={popularSkus} />;
};

ErrorPage.propTypes = {
  code: PropTypes.number,
  popularSkus: PropTypes.object,
  page: PropTypes.object,
};

export default ErrorPage;
