import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const ProductImage = (propAttrs) => {
  const { shouldShowImage, isPartner, name, image, icon, imageClasses } = propAttrs;
  const skuImageStyles = isPartner ? styles.skuImagePadded : styles.skuImage;
  const imageContainerRef = useRef(null);
  const imageContainerStyles = cn({
    [styles.container]: isPartner,
    [styles.imageContainer]: !isPartner,
    [imageClasses]: !!imageClasses,
  });

  useEffect(() => {
    const ref = imageContainerRef.current;
    if (!ref) return null;
    return ref.style.setProperty('--image', `url(${image})`);
  }, [image]);

  return (
    <>
      {shouldShowImage ? (
        <div className={imageContainerStyles} ref={imageContainerRef}>
          <img alt={name} src={image} className={skuImageStyles} />
        </div>
      ) : (
        <img src={icon} className={styles.icon} ref={imageContainerRef} />
      )}
    </>
  );
};

ProductImage.propTypes = {
  shouldShowImage: PropTypes.bool,
  isPartner: PropTypes.bool,
  name: PropTypes.string,
  icon: PropTypes.shape({}),
  image: PropTypes.shape({}),
  imageClasses: PropTypes.string,
};

export default ProductImage;
