import { TOGGLE_SEARCH_BAR, SEARCH_CLICK_SEGMENT, SEARCH_RESULTS_CLICKED_SEGMENT } from './constants';

export const toggleSearchBar = () => {
  return {
    type: TOGGLE_SEARCH_BAR,
  };
};

export const searchClickSegment = ({ locationName }) => {
  return {
    type: SEARCH_CLICK_SEGMENT,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: 'Search Engaged',
            properties: {
              location: locationName,
            },
          },
        },
      ],
    },
  };
};

export const searchResultsClickedSegment = ({ searchTerm, clickedItem, attributes, device }) => {
  return {
    type: SEARCH_RESULTS_CLICKED_SEGMENT,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: 'Results Clicked',
            properties: {
              searchTerm,
              clickedItem,
              attributes,
              device,
            },
          },
        },
      ],
    },
  };
};
