import { FAQ_PAGE_CONTAINER_ID, FAQ_PAGE_IFRAME_ID } from 'src/containers/SiteFAQ/constants';

const messagePageActions = (props, attributes) => ({
  loaded() {
    attributes.setPageLoaded(true);
    window.scrollTo(0, 0);
  },
  unloaded() {
    /* reset iframe cause multiple pages in flow */
    const { faqContainer, faqIframe } = this.getElementRefs();

    /* If both doms do exist, else we know we've unmounted */
    if (faqContainer && faqIframe) {
      faqContainer.style.height = `0px`;
      faqIframe.style.height = `0px`;
    }
  },
  getElementRefs() {
    /* Get  elem references */
    const faqContainer = document.getElementById(FAQ_PAGE_CONTAINER_ID);
    const faqIframe = document.getElementById(FAQ_PAGE_IFRAME_ID);

    return { faqContainer, faqIframe };
  },
});

export { messagePageActions };
