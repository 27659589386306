import React, { useEffect } from 'react';
import * as Yup from 'yup';
// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
// Selectors
import { purePageSelector as pageSelector } from 'src/selectors/page';
import { pureModalSelector as modalSelector } from 'src/selectors/modal';
// Actions
import { getDiscount } from 'src/components/HTKit/Modals/FirstServiceDiscount/actions';
import { clearFormErrors } from 'src/actions/common';
// Constants
import {
  PAGE_NAME as CURRENT_PAGE,
  MODAL_NAME,
} from 'src/components/HTKit/Modals/FirstServiceDiscount/constants';
// Components & Styles
import {
  EmailFieldWithValidation,
  useExternalEmailCheck,
} from 'src/components/HTKit/Forms/EmailFieldWithValidation';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import styles from '../styles.scss';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid email address')
    .required("Email can't be blank"),
});

const SubscribeForm = () => {
  const dispatch = useDispatch();

  const page = useSelector(pageSelector(CURRENT_PAGE));
  const modal = useSelector(modalSelector(MODAL_NAME));
  const { visible: modalIsVisible } = modal;

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: schema,
    validateOnChange: false,
    onSubmit({ email }) {
      dispatch(clearFormErrors(CURRENT_PAGE));
      validateEmailWithConfig({ email });
    },
  });

  const launchModal = (email) => {
    // TODO: Refactor usage of getDiscount from FirstServiceDiscountModal actions. Currently used here for different feature.
    dispatch(getDiscount(email, { source: 'footer' }));
    formik.resetForm();
  };

  const {
    validateEmailWithConfig,
    emailValidationState,
    useSuggestion,
    handleOnChange,
  } = useExternalEmailCheck({
    useValidationService: true,
    formik,
    fieldName: 'email',
    onSuccess: () => launchModal(formik.values.email),
  });

  useEffect(() => {
    if (modalIsVisible) formik.resetForm();
  }, [modalIsVisible]);

  return (
    <form className={styles.subscriptionForm} onSubmit={formik.handleSubmit}>
      <EmailFieldWithValidation
        name="email"
        containerClass={styles.input}
        type="text"
        placeholder="Enter your email address"
        onChange={handleOnChange}
        value={formik.values.email}
        error={formik.errors.email || page.errors.email}
        softWarning={emailValidationState?.did_you_mean}
        useSuggestion={useSuggestion}
      />
      <Button theme={THEMES.V2PRIMARY} className={styles.button}>
        Submit
      </Button>
    </form>
  );
};

export default SubscribeForm;
