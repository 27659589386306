import React from 'react';
import { useWorkflowManager } from './hooks';

/**
 * This Higher-Order Component (HOC) is designed to extend the functionalities
 * of AppBase. The primary goal is to integrate hooks and other React functionalities
 * that are otherwise not available in class-based components.
 *
 * If there are more enhancements needed for AppBase, consider extending this HOC.
 */
export const withAppBaseEnhancements = (WrappedComponent) => {
  const EnhancedComponent = (props) => {
    useWorkflowManager();
    return <WrappedComponent {...props} />;
  };

  return EnhancedComponent;
};
