import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import affirmMonthlyPaymentImage from "src/images/affirm_monthly_payments.svg";
import styles from './styles.scss';

const AffirmMonthlyPayment = ({className = ''}) => {
  const classes = cn(styles.affirm_monthly_payment, className);

  return (
    <div className={classes}>
      <img src={affirmMonthlyPaymentImage} alt="Affirm Monthly Payment" />
    </div>
  )
}

AffirmMonthlyPayment.propTypes = {
  className: PropTypes.string
};

export default AffirmMonthlyPayment;
