import { createSelector, createStructuredSelector } from 'reselect';
import queryString from 'query-string';
import { cartSelector } from 'src/selectors/cart';
import { currentLandingSelector } from 'src/selectors/landings';
import { pageSelector as selectPage } from 'src/selectors/page';
import { userPlanIdSelector } from 'src/selectors/user';
import { skusSelector } from 'src/selectors/skus';
import { isQuestionAnswerFlowSelector } from 'src/containers/AddSkuPage/QuestionsFlowPage/selectors';
import { splitioSelectors, splitioConstants } from 'src/components/SplitIO';
import { getMappedQaFlowSku } from 'src/containers/AddSkuPage/QuestionsFlowPage/utils';
import { SERVICE_ADJUSTMENT_TYPES } from 'src/containers/Account/OrderPage/constants';
import { PAGE_NAME } from './constants';

export const pageSelector = selectPage(PAGE_NAME);

const pageLoadingSelector = createSelector(pageSelector, (page) => page.get('loading'));

const pageErrorSelector = createSelector(pageSelector, (page) => page.get('error'));

const isConfirmationPropsSelector = (_, props) => {
  return props.isConfirmation;
};

export const skuIdPropsSelector = (_, props) => {
  const {
    match: {
      params: { skuId = 1 },
    },
  } = props;

  return parseInt(skuId, 10);
};

export const locationStateSkuIdSelector = (state) =>
  state.getIn(['router', 'location', 'state', 'skuId']);

/*
  Need the ability update QA for multiple items on an API order. Grab the sku index from the router,
  and use the first item in the cart as a fallback -GH Aug 9, 2020
*/
const skuIdSelector = createSelector(
  [
    skuIdPropsSelector,
    isConfirmationPropsSelector,
    cartSelector,
    locationStateSkuIdSelector,
    splitioSelectors.treatmentSelector(splitioConstants.SPLITIONAME_GOOGLE_ADS_TV_MOUNT_SKU),
  ],
  (skuId, isConfirmation, cart, locationSkuId, splitTreatment) => {
    if (isConfirmation) return locationSkuId || cart.getIn(['items', 0, 'skuId']);
    if (splitTreatment === splitioConstants.ON) {
      return getMappedQaFlowSku({ skuId });
    }
    return skuId;
  },
);

const skuSelector = createSelector(
  [skuIdSelector, skusSelector],
  (skuId, skus) => skus && skus.get(`${skuId}`),
);

export const newItemSelector = createSelector(
  (state) => state.get('newItem'),
  (newItem) => newItem,
);

export const skuByNewItemSelectorJS = createSelector(
  [newItemSelector, skusSelector],
  (newItem, skus) => {
    const n = newItem.toJS();
    const s = skus.toJS();
    return n && s && s[n.skuId];
  },
);

/**
 * @typedef {{amount: number, amount_formatted: string, name: string, type: string}} ItemAdjustment
 * @typedef {Object}NewItemPriceBreakdown
 * @property {number} amount - total price of sku with discounts and add-ons
 * @property {number} savings - membership savings
 * @property {number} skuAmount - base price of the sku without discounts applied
 * @property {string} skuAmountFormatted - sku amount in $dollar format
 * @property {{answer: ItemAdjustment[], automaticDiscountAdjustments: ItemAdjustment[]}} adjustmentsByType
 */
export const newItemPriceBreakdownSelector = createSelector(
  newItemSelector,
  userPlanIdSelector, // Check cart and user profile for plan id
  (newItem) => {
    const defaultBreakdown = {
      amount: '',
      adjustmentsByType: { answer: [] },
    };
    const breakdown = newItem.get('breakdown');
    if (!breakdown) return defaultBreakdown;
    const breakdownJs = breakdown.toJS();

    /**
     * Customer selected add-ons to base price service
     * @type ItemAdjustment[]
     */
    const answerAdjustments = breakdownJs.adjustments.filter((adj) => adj.type === 'answer');
    /**
     * "Bundle" discounts that are auto applied, e.g. purchase a certain quantity of a sku to get a discount.
     * See the discount_skus table for applicable sku ids
     * @type ItemAdjustment[]
     */
    const automaticDiscountAdjustments = breakdownJs.adjustments.filter(
      (adj) => adj.name.trim().toLowerCase() === SERVICE_ADJUSTMENT_TYPES.AUTOMATIC_DISCOUNT,
    );
    /**
     * - breakdownJs.amount_without_subscription only appears for members and carts with plans. This should be used as the "base price" for member carts.
     * @type NewItemPriceBreakdown
     */
    const newItemPriceBreakdown = {
      amount: breakdownJs.amount,
      skuAmount: breakdownJs.sku_amount,
      skuAmountFormatted: breakdownJs.sku_amount_formatted,
      savings: breakdownJs.savings,
      adjustments: breakdownJs.adjustments,
      adjustmentsByType: {
        answer: answerAdjustments,
        automaticDiscount: automaticDiscountAdjustments,
      },
    };
    return newItemPriceBreakdown;
  },
);

const questionsStateSelector = (state) => state.getIn(['entities', 'questions'], null);

export const questionsSelector = createSelector(
  [skuIdSelector, questionsStateSelector],
  (skuId, questions) => questions && questions.get(`${skuId}`),
);

const emailPropsSelector = (_, props) => {
  const { email } = queryString.parse(props.location.search);
  return email ? email.replace('?utm_source=sendinblue', '') : null;
};

export const locationStateStateSelector = (state) => state.getIn(['router', 'location', 'state']);

// getting set in CartPage
export const inEditSkuModeSelector = createSelector(locationStateStateSelector, (locationState) =>
  locationState ? locationState.get('mode', '') === 'edit' : false,
);

/**
 * Index position in cart for items already added to cart. If item is not yet in cart this will be undefined.
 * @typedef {(number | undefined )} SkuItemIndex
 */
export const skuItemIndexSelector = createSelector(
  locationStateStateSelector,
  (locationState) => locationState && locationState.get('itemIndex'),
);

export default createStructuredSelector({
  page: pageSelector,
  pageLoading: pageLoadingSelector,
  pageError: pageErrorSelector,
  cart: cartSelector,
  skuId: skuIdSelector,
  sku: skuSelector,
  item: newItemSelector,
  newItemPriceBreakdown: newItemPriceBreakdownSelector,
  questions: questionsSelector,
  currentPartner: currentLandingSelector,
  email: emailPropsSelector,
  inEditSkuMode: inEditSkuModeSelector,
  isNewQAFlow: isQuestionAnswerFlowSelector,
});
