
export const TYPES = {
  FIELD_SALES_AGENT_START_NEW_PROCESS: 'fieldsales/FIELD_SALES_AGENT_START_NEW_PROCESS',
  FIELD_SALES_CLIENT_CHECK: 'fieldsales/FIELD_SALES_CLIENT_CHECK',
  FIELD_SALES_CLIENT_CONFIRM: 'fieldsales/FIELD_SALES_CLIENT_CONFIRM',
  FIELD_SALES_START_BOOKING: 'fieldsales/FIELD_SALES_START_BOOKING',
  FIELD_SALES_REGISTRATION: 'fieldsales/FIELD_SALES_REGISTRATION',
  FIELD_SALES_CLIENT_PATH_CHANGE: 'fieldsales/FIELD_SALES_CLIENT_PATH_CHANGE',
  FIELD_SALES_SEND_FINISH_LINK: 'fieldsales/FIELD_SALES_SEND_FINISH_LINK',
  FIELD_SALES_VERIFY_PIN: 'fieldsales/FIELD_SALES_VERIFY_PIN',
  FIELD_SALES_SEGMENT: 'fieldsales/FIELD_SALES_SEGMENT',
  FIELD_SALES_MODAL_ACTION: 'fieldsales/FIELD_SALES_MODAL_ACTION',
  FIELD_SALES_AGENT_ENDED_SESSION: 'fieldsales/FIELD_SALES_AGENT_ENDED_SESSION',
  FIELD_SALES_FLOW_VALIDATOR: 'fieldsales/FIELD_SALES_FLOW_VALIDATOR',
  FIELD_SALES_DEEPLINK_QRCODE: 'fieldsales/FIELD_SALES_DEEPLINK_QRCODE'
}

export const agentStartNewProcess = (
  params = {},
  responseAction = () => {}
) => {
  let existing = false;

  // If we don't pass anything in, we will just re-use what is already saved.
  if (!params.agentId || !params.agentType) existing = true;

  return {
    type: TYPES.FIELD_SALES_AGENT_START_NEW_PROCESS,
    ...params,
    responseAction,
    existing
  }
};

/* Check to see if client/customer exists in system */
export function clientCheckAction({ email, dispatch }) {
  return {
    type: TYPES.FIELD_SALES_CLIENT_CHECK,
    email,
    dispatch
  };
}

/* Takes the path function & initial seed value for type (if needed) */
export function fieldSalesPathAction(path, options) {
  return {
    type: TYPES.FIELD_SALES_CLIENT_PATH_CHANGE,
    path,
    options
  };
}

/* Attach client/customer to field sales cart */
export function clientConfirmAction({ clientId }) {
  return {
    type: TYPES.FIELD_SALES_CLIENT_CONFIRM,
    clientId
  };
}

/* Initiates the creation of the order link */
export function startBookingAction() {
  return {
    type: TYPES.FIELD_SALES_START_BOOKING
  };
}

/* Seller creates client registration */
export function registrationAction(params) {
  return {
    type: TYPES.FIELD_SALES_REGISTRATION,
    params
  };
}

/* Seller initiates the sending of the seller link to email */
export function sendFinishLinkAction (sms) {
  return {
    type: TYPES.FIELD_SALES_SEND_FINISH_LINK,
    sms
  };
}

/* Customer initiates to verify their pin entry */
export function verifyPinAction ({ pin, token}) {
  return {
    type: TYPES.FIELD_SALES_VERIFY_PIN,
    pin,
    token
  };
}

/* Our header lives in two worlds, so we need to hook into the app redux */
export function fieldSalesModalAction ({ name, visible}) {
  return {
    type: TYPES.FIELD_SALES_MODAL_ACTION,
    payload: {
      name, visible
    }
  };
}

/* Customer initiates to verify their pin entry */
export function segmentAction (event, properties) {
  return {
    type: TYPES.FIELD_SALES_SEGMENT,
    meta: {
      analytics: [{
        eventType: 'track',
        eventPayload: {
          event,
          properties
        }
      }]
    }
  };
}

/* Users cookie expired mid-stride. We need to cancel/remove the cart and send them back */
export function endedSessionAction() {
  return {
    type: TYPES.FIELD_SALES_AGENT_ENDED_SESSION,
  };
}

/*
 * sessionValidatorAction
 *
 * Until product irons out exactly what happens during a sellers flow and over time, we need to
 * run our behaviors thru a validator, that link out to the client flow.
 * If you link to a sku page w/o a cookie. It will screw up the layout. See saga for explanation.
 *
 * params { action } a callback function. Most likely a dispatch
 * */
export function sessionValidatorPathAction(action) {
  return {
    type: TYPES.FIELD_SALES_FLOW_VALIDATOR,
    action
  };
}

/* This handles the Deep Link sync calls for QRCode */
export function deepLinkQRCodeAction(params = {}) {
  return {
    type: TYPES.FIELD_SALES_DEEPLINK_QRCODE,
    ...params,
  };
}
