import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { closePasscodeModal } from '../actions';
// Hooks
import { useCountdownTimer } from './LoginPasscode.hooks';
// Components
import { ModalV2 } from 'src/components/HTKit/Modals/ModalV2';
import { LoginPasscodeForm } from './LoginPasscode.Form';
// Styles
import styles from '../styles.scss';

export const LoginPasscodeModal = () => {
  const dispatch = useDispatch();

  const isModalOpen = useSelector((state) =>
    state.getIn(['components', 'loginModal', 'isPasscodeModalOpen']),
  );

  const { startTimer, remainingTime, canResend } = useCountdownTimer();

  const onResendPasscodeSuccess = useCallback(() => {
    startTimer({ startFromBeginning: true });
  }, [startTimer]);

  useEffect(() => {
    if (isModalOpen) {
      startTimer();
    }
  }, [isModalOpen, startTimer]);

  if (!isModalOpen) return null;

  return (
    <ModalV2
      isVisible={isModalOpen}
      hide={() => dispatch(closePasscodeModal())}
      header="Confirm it's you"
      headerContainerClasses={styles.passcodeModalHeaderContainer}
      size="medium"
    >
      <LoginPasscodeForm
        onResendSuccess={onResendPasscodeSuccess}
        remainingTime={remainingTime}
        canResend={canResend}
      />
    </ModalV2>
  );
};
