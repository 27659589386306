import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import cn from 'classnames';
import get from 'lodash/get';
import { ModalCloseButton } from 'src/components/HTKit/Modals/Parts';
import { RULES } from 'src/utils/validation';
import FirstServiceDiscountThankYou from './FirstServiceDiscountThankYou';
import styles from './styles.scss';
import overlay from './images/overlay.jpg';

/**
 * @deprecated since late 2023 - will be removed TBD.
 * This component is no longer in use due to Wisepops integration.
 * Note: Some Redux actions from this component are still used elsewhere.
 * TODO: Refactor dependent Redux actions.
 */
export default class FirstServiceDiscountModal extends PureComponent {
  static propTypes = {
    page: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    clearFormErrors: PropTypes.func.isRequired,
    getDiscount: PropTypes.func.isRequired,
    firstServiceDiscount: PropTypes.object.isRequired,
    fieldSalesAgent: PropTypes.number.isRequired,
  };

  state = { email: null };

  node = React.createRef();

  componentDidUpdate(prevProps) {
    const {
      firstServiceDiscount: { visible },
    } = this.props;
    const {
      firstServiceDiscount: { visible: prevVisible },
    } = prevProps;
    if (visible !== prevVisible) {
      const method = visible ? 'addEventListener' : 'removeEventListener';
      document[method]('click', this.handleOutsideClick);
    }
  }

  handleEmailChange = (data) => {
    this.setState({ email: data.target.value });
  };

  handleFormSubmit = (event) => {
    const form = this.state;
    const rules = { email: RULES.email };
    event.preventDefault();
    this.props.validateForm(
      { form, rules, name: 'current' },
      {
        onSuccess: () => {
          this.props.getDiscount(this.state.email, { src: 'firstServiceDiscountModal' });
        },
      },
    );
  };

  handleClose = (event) => {
    event.preventDefault();
    this.setState({ email: null });
    this.props.toggleModal('firstServiceDiscount', false);
    this.props.clearFormErrors('current');
  };

  handleOutsideClick = (event) => {
    const {
      firstServiceDiscount: { visible },
    } = this.props;

    const validNode = get(this.node, 'current');
    const outsideClicked = validNode && !validNode.contains(event.target);
    if (visible && outsideClicked) this.handleClose(event);
  };

  render() {
    const {
      fieldSalesAgent,
      firstServiceDiscount: { visible, submitted, code },
      ...rest
    } = this.props;
    const modalStyles = cn(styles.firstServiceDiscountModal, 'site-v2');
    // field sales (fieldSales) is sales, so lets not show it.
    if (fieldSalesAgent || !submitted) return null;

    return (
      <ReactModal
        ariaHideApp={false}
        className={styles.dialog}
        overlayClassName={styles.overlay}
        {...rest}
        isOpen={visible}
        contentLabel="FirstServiceDiscountModal"
      >
        <div className={modalStyles} ref={this.node}>
          <ModalCloseButton
            onClick={this.handleClose}
            buttonStyle={styles.firstServiceDiscountModalClose}
          />

          <div className={styles.firstServiceDiscountModalLeft}>
            <FirstServiceDiscountThankYou code={code} />
          </div>

          <div className={styles.firstServiceDiscountModalRight}>
            <img
              src={overlay}
              className={styles.firstServiceDiscountModalImage}
              alt="Save $10 Off"
            />
          </div>
        </div>
      </ReactModal>
    );
  }
}
