import { takeLatest, put, select } from 'redux-saga/effects';
import modalSelector from 'src/selectors/modal';
import { toggleModal } from 'src/actions/modals';
import { embed } from 'src/utils/SurveyMonkey';
import { MODAL_NAME as DISCOUNT_MODAL } from 'src/components/HTKit/Modals/FirstServiceDiscount/constants';
import { toggleFirstServiceDiscountState } from 'src/components/HTKit/Modals/FirstServiceDiscount/actions';
import { SHOW_MODAL, MODAL_NAME } from './constants';

function* showModalSaga() {
  const discountModal = yield select(modalSelector(DISCOUNT_MODAL));
  const visible = discountModal.get('visible');

  if (!visible) {
    yield put(toggleFirstServiceDiscountState(false));
    yield put(toggleModal(MODAL_NAME, true));
    embed();
  }
}

export function* componentFlow() {
  yield takeLatest(SHOW_MODAL, showModalSaga);
}

export default [componentFlow];
