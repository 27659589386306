import React from 'react';
import PropTypes from 'prop-types';
import { isMobileV2 } from 'src/utils/ui';
import styles from './styles.scss';

const SkuImage = (props) => {
  const { sku } = props;
  const isMobile = isMobileV2();
  const image = isMobile ? sku.get('heroMobileImageUrl') : sku.get('heroImageUrl');
  return (
    image && (
      <div>
        <img alt={sku.get('name')} src={image} className={styles.heroImg} />
      </div>
    )
  );
};

SkuImage.propTypes = {
  sku: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
};

export default SkuImage;
