// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import classNames from 'classnames';
// Components
import Icon from 'src/components/HTKit/Icon';
import DesktopHourBlock from './DesktopHourBlock';
// Data
import { dateHasSelectedHours, formatDate } from './utils';
// Constants
import { SCREEN_WIDTHS } from './constants';
// Styles
import styles from './styles.scss';

class DesktopSelector extends Component {
  static propTypes = {
    availability: PropTypes.object.isRequired,
    toggleHour: PropTypes.func.isRequired,
  };

  state = {
    currentIndex: 0,
    numDatesToShow: 4,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { availability } = this.props;
    let initialIndex = null;
    availability.forEach((dateData, dateIndex) => {
      if (initialIndex !== null) return;
      dateData.get('hours').forEach((hourData) => {
        if (hourData.get('valid')) {
          initialIndex = dateIndex;
        }
      });
    });
    this.shiftDates(initialIndex)();
  }

  componentDidMount() {
    this.setNumDatesToShow();
    window.addEventListener('resize', this.setNumDatesToShow);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setNumDatesToShow);
  }

  setNumDatesToShow = () => {
    const screen = SCREEN_WIDTHS;
    if (window.innerWidth > screen.maxWidth) {
      this.setState({ numDatesToShow: 5 });
    } else if (window.innerWidth > screen.tablet && window.innerWidth < screen.desktop) {
      this.setState({ numDatesToShow: 3 });
    } else {
      this.setState({ numDatesToShow: 4 });
    }
  };

  shiftDates = (shift = 1) => {
    return (event) => {
      if (event) event.preventDefault();
      const { currentIndex } = this.state;
      const { availability } = this.props;
      if (currentIndex === 0 && shift < 0) return;
      if (this.reachedEndOfViewableList(currentIndex, availability) && shift > 0) return;
      this.setState({ currentIndex: currentIndex + shift });
    };
  };

  reachedEndOfViewableList = (currentIndex, availability) => {
    return currentIndex === availability.size - this.state.numDatesToShow;
  };

  toggleHoursForDay = (date) => () => {
    const { toggleHour } = this.props;
    const dateJS = date.toJS();
    const allValidHours = dateJS.hours.filter((hour) => hour.valid);
    const checkedHours = allValidHours.filter((hour) => hour.checked && hour.valid);
    const noHoursSelected = checkedHours.length === 0;
    const someHoursSelected = checkedHours.length > 0 && checkedHours.length < allValidHours.length;
    const noOrSomeHours = noHoursSelected || someHoursSelected;
    allValidHours.forEach((hour) => {
      toggleHour(date.get('date'), fromJS(hour), noOrSomeHours)();
    });
  };

  render() {
    const { availability, toggleHour } = this.props;
    const { currentIndex, numDatesToShow } = this.state;
    const datesToShow = availability.slice(
      this.state.currentIndex,
      this.state.currentIndex + numDatesToShow,
    );
    const reachedEndOfViewableList = this.reachedEndOfViewableList(currentIndex, availability);
    const leftToggleStyles = classNames(styles.toggle, {
      [styles.disable]: currentIndex === 0,
    });
    const rightToggleStyles = classNames(styles.toggle, {
      [styles.disable]: reachedEndOfViewableList,
    });

    return (
      <div className={styles.desktop}>
        <div className={styles.selectorWrapper}>
          <a className={leftToggleStyles} onClick={this.shiftDates(-1)}>
            <Icon name="v2-skinny-arrow" className={styles.calendarIcon} />
          </a>

          {datesToShow &&
            datesToShow.map((date) => (
              <section key={date.get('date')} className={styles.dateHourWrapper}>
                <div className={styles.availabilityDate} onClick={this.toggleHoursForDay(date)}>
                  <p className={styles.weekday}>{formatDate(date, 'ddd')}</p>
                  <p className={styles.day}>{formatDate(date, 'MMM D')}</p>
                  {dateHasSelectedHours(date.toJS()) && (
                    <div className={styles.availabilityDateSelected} />
                  )}
                </div>
                <div className={styles.hourwrapperDesktop}>
                  {date.get('hours').map((hourData, index) => (
                    <DesktopHourBlock
                      key={index}
                      date={date.get('date')}
                      hourData={hourData}
                      toggleHour={toggleHour}
                    />
                  ))}
                </div>
              </section>
            ))}

          <a className={rightToggleStyles} onClick={this.shiftDates()}>
            <Icon name="v2-skinny-arrow-right" className={styles.calendarIcon} />
          </a>
        </div>
      </div>
    );
  }
}

export default DesktopSelector;
