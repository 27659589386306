import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import StarRating from 'src/components/Elements/StarRating';
import { PanelV2 } from 'src/components/HTKit/Elements/PanelV2';
import Cta from './Parts/Cta';
import ProductPrice from './Parts/ProductPrice';
import ProductImage from './Parts/ProductImage';
import styles from './Parts/styles.scss';

const ProductCard = (propAttrs) => {
  const {
    name,
    classes,
    rating,
    ratingCount,
    isPartner,
    showCta = true,
    ratingAttributes,
    onClick = () => {},
  } = propAttrs;
  const serviceTitleStyles = cn('h6', styles.serviceTitle);
  const panelStyles = cn(classes, styles.panel, 'marginTop-small1');
  return (
    <PanelV2 className={panelStyles}>
      <div onClick={onClick} className={styles.cardContainer}>
        <div className={styles.productCard}>
          <ProductImage isPartner={isPartner} {...propAttrs} />
          <div className={styles.productCardItems}>
            <p className={serviceTitleStyles}>{name}</p>
            <ProductPrice {...propAttrs} />
            {ratingAttributes && (
              <StarRating stars={rating} count={ratingCount} className={styles.rating} size="sm" />
            )}
          </div>
        </div>
        {showCta && <Cta {...propAttrs} />}
      </div>
    </PanelV2>
  );
};

ProductCard.propTypes = {
  name: PropTypes.string,
  isPartner: PropTypes.bool,
  showCta: PropTypes.bool,
  ratingAttributes: PropTypes.object,
  rating: PropTypes.number,
  ratingCount: PropTypes.number,
  onClick: PropTypes.func,
  dangerouslySetInnerHTMLPrice: PropTypes.string,
};

export default memo(ProductCard);

/*
  ProductCard: Component to show info -  name, reviews, price, image/icon and optional CTA.

  Example Usage:

  import ProductCard from 'src/components/Elements/ProductCard';

  <ProductCard linkPath={func} onClick={func} hasSubscription={bool} shouldShowImage={bool} isPartner={bool} buttonText={string} linkText={string} />

  Props:
    linkPath: Path to navigate to desired location
    onLinkClick: In case Link needs an onClick
    onClick: Action for button click
    hasSubscription: whether the user has a subscription or not.
    shouldShowImage: whenever the image is needed
    isPartner: whenever partnercta is needed
    linkText: String value for link text
    buttonText: String value for text that goes on button
*/
