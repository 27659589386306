import { isImmutable } from 'src/utils/helpers';
/**
 * https://hellotech.atlassian.net/browse/MEM-475
 * @typedef {Object} Plan
 * @property {number} id
 * @property {string} name
 * @property {number} billingIntervalMonths
 * @property {number} renewIntervalMonths
 * @property {{} | null} trial
 */
export const formatSubscriptionName = (plan) => {
  if (!plan) return '';

  /** @type Plan */
  const planJs = isImmutable(plan) ? plan.toJS() : plan;

  if (planJs.trial) return `${planJs.name} Membership`;

  const isMonthlyBilling = planJs.billingIntervalMonths === 1;
  let displayName = `${planJs.name} ${planJs.renewIntervalMonths}-Month Membership`;
  if (isMonthlyBilling) displayName += ' (billed monthly)';

  return displayName;
};
