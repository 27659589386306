// Libraries
import React, { useCallback, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { useSplitIOTreatment, splitioConstants } from 'src/components/SplitIO';
// Actions && Constants
import { pureCartSelector } from 'src/selectors/cart';
import { isUserPristineSelector, pureUserSelector } from 'src/selectors/user';
import { loadPage } from 'src/actions/common';
import { updateTrackingByUpsell } from 'src/actions/tracking';
import { addPlanToCart, removePlanFromCart } from 'src/actions/cart';
import { UPSELL_TRACKING } from 'src/constants/tracking';
import { zipCodeCta, bookingCta } from 'src/components/Service/ServiceZipCodeEntry/constants';
import { startBooking } from '../CartPage/actions';
// Context
import {
  ServicePageProvider,
  useServicePageContext,
} from 'src/containers/ServicePage/context/context';
// Components
import LazyView from 'src/components/LazyLoad/LazyView';
import { bookNow } from 'src/components/BookNowButton/actions';
import PageLoader from 'src/components/PageLoader';
import PlanModal from 'src/containers/SkuPage/Parts/PlanModal';
import ServiceInfo from 'src/components/Service/ServiceInfo';
import ServiceHeader from 'src/components/Service/ServiceHeader';
import ServiceFaq from 'src/components/Service/ServiceFaq';
import ServiceDetails from 'src/components/Service/ServiceDetails';
import ServiceBookNow from 'src/components/Service/ServiceBookNow';
import ServiceBanner from 'src/components/Service/ServiceBanner';
import ServicePlanBanner from 'src/components/Elements/Banner/PageBannerWithCta/lazy';
import ServiceMeta from 'src/components/Service/ServiceMeta';
import ServiceReviews from 'src/components/Elements/CustomerReviews';
import GuaranteeBlock from 'src/components/Sections/GuaranteeBlock';
import InTheNews from 'src/components/Sections/InTheNews/lazy';
import ExpertInHomeService from 'src/components/Sections/ExpertInHomeService/lazy';
import { ExceedMaxServicesWarningModal } from './ExceedMaxServicesWarning.Modal';
// Utils and Actions
import {
  fetchPlanDetails,
  togglePlanModal,
  planAddedOnSkuTrack,
  planRemovedOnSkuTrack,
} from 'src/containers/SkuPage/actions';
import { setButtonCta, setShowZipCodeTest } from 'src/containers/ServicePage/actions';
import { getZipDataCookie } from 'src/utils/cookies';
import { useCartFlow } from 'src/utils/cartFlow';
import { useIsPasswordlessCheckoutEnabled } from '../Account/AccountPage/AccountPage.hooks';
// Styles
import styles from './styles.scss';
import { BOOKING_STAGES } from '../BookingPage/constants';

const ServicePage = ({ sku, loading, seoName }) => {
  const dispatch = useDispatch();
  const userIsPristine = useSelector(isUserPristineSelector);
  const user = useSelector(pureUserSelector);
  const isPasswordless = useIsPasswordlessCheckoutEnabled();
  const reviews = useRef(null);
  const plansInfo = get(sku, 'extra.plansInfo', null);
  const showUpsell = get(sku, 'extra.showUpsell', null);
  const [servicePageContextState] = useServicePageContext();

  const { getSkuPageConfig } = useCartFlow();
  const cart = useSelector(pureCartSelector);
  const existingSkuInCart = cart.items[0];
  const cartFlowConfig = getSkuPageConfig({
    newSkuId: sku?.id,
    existingSkuId: existingSkuInCart?.skuId,
  });

  const [showServicesWarningModal, setShowServicesWarningModal] = useState(false);

  /* Plan Pricing Split */
  const {
    splitLoaded: planPricingSplitLoaded,
    splitTreatment: planPricingSplitTreatment,
  } = useSplitIOTreatment(splitioConstants.SPLITIONAME_PLAN_PRICING);

  const isPlanPricingTest = planPricingSplitTreatment === splitioConstants.ON;
  const planInfoMonthly = get(plansInfo, 'monthly', {});
  const { id: upsellPlanId } = planInfoMonthly;

  const upsellTrackingProperties = (planId) => {
    return {
      plan: { ...planInfoMonthly, planId },
      partner: sku.partner,
    };
  };

  /* start zip code test - SPLITIONAME_CUSTOMER_ZIP */
  const isUserLoggedIn = get(user, 'id', null);
  const isZipCodeGateSuccessFlow = servicePageContextState.zipCodeGate.isZipSuccessViewActive;
  const showZipCodeTest = !isUserLoggedIn;
  const customCTAByZipCodeView = showZipCodeTest && !isZipCodeGateSuccessFlow;
  /* custom cta to pass into header. Cant set globally as the button is also used out the header display */
  const customCTA = customCTAByZipCodeView ? zipCodeCta : '';
  /* Zip Gate Split */
  const { splitTreatment: customerZipSplitTreatment } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_CUSTOMER_ZIP,
    null,
    () => !userIsPristine && showZipCodeTest && !get(getZipDataCookie(), 'isBookingAllowed'),
  );

  useEffect(() => {
    /** Handle cta button text for the mobile/tablet header */
    if (showZipCodeTest && !isZipCodeGateSuccessFlow) {
      dispatch(setButtonCta({ buttonCta: zipCodeCta }));
    } else {
      dispatch(setButtonCta({ buttonCta: bookingCta }));
    }
    dispatch(setShowZipCodeTest({ showZipCodeTest })); // This would be dependent on split + cookie
  }, [showZipCodeTest, isZipCodeGateSuccessFlow]);

  useEffect(() => {
    if (planPricingSplitLoaded && loading) {
      dispatch(loadPage('sku', { seoName, isPlanPricingTest }));
    }
  }, [seoName, planPricingSplitLoaded]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const initiateBookingProcess = () => {
    if (cartFlowConfig.showWarning) {
      setShowServicesWarningModal(true);
      return;
    }
    dispatch(bookNow(sku.id));
  };

  const handleSelectNewService = () => {
    setShowServicesWarningModal(false);
    dispatch(bookNow(sku.id));
  };

  /** This is the callback handler for zip code test */
  const handleGetPriceClick = useCallback(() => {
    /*
      Save zip/email in cookie
      Send info to segment
      Set button cta back to 'book now'
     */
    dispatch(setShowZipCodeTest({ showZipCodeTest }));
    initiateBookingProcess();
    dispatch(setButtonCta({ buttonCta: bookingCta }));
  }, [sku, showZipCodeTest]);

  /* end zip code test - SPLITIONAME_CUSTOMER_ZIP */
  const upsellTrackingViewedTriggered = useRef(false);
  /* Consolidate upsell */
  useEffect(() => {
    if (sku && showUpsell) {
      /* fire upsell viewed tracking */
      if (!upsellTrackingViewedTriggered.current && isZipCodeGateSuccessFlow) {
        dispatch(
          updateTrackingByUpsell({
            ...upsellTrackingProperties(upsellPlanId),
            event: UPSELL_TRACKING.viewed,
          }),
        );
        upsellTrackingViewedTriggered.current = true;
      }
    }
  }, [sku, isZipCodeGateSuccessFlow, showUpsell]);

  /** Callback for book now button WITHOUT zip code test and floating nav */
  const handleBook = useCallback(() => {
    if (showZipCodeTest && !isZipCodeGateSuccessFlow) {
      // Handles case where user scrolls down and floating cta is shown
      scrollToTop();
    } else {
      initiateBookingProcess();
    }
  }, [sku]);

  const handleRating = useCallback(() => {
    window.scrollTo({
      top: reviews.current.offsetTop - sku.extra.reviewsScrollOffset,
    });
  }, [sku]);

  const handleUpsellMore = useCallback(() => {
    dispatch(fetchPlanDetails({ planId: upsellPlanId }));
  }, [upsellPlanId]);

  const handleTogglePlanModal = useCallback((visible) => {
    dispatch(togglePlanModal({ modalState: visible }));
  }, []);

  const handleUpsellToggle = useCallback(
    (shouldAdd, planId = upsellPlanId) => {
      if (shouldAdd) {
        dispatch(addPlanToCart(planId));
        dispatch(planAddedOnSkuTrack(sku, 'New'));
        handleTogglePlanModal(false);
        dispatch(
          updateTrackingByUpsell({
            ...upsellTrackingProperties(planId),
            event: UPSELL_TRACKING.added,
          }),
        );
      } else if (showUpsell) {
        dispatch(removePlanFromCart());
        dispatch(planRemovedOnSkuTrack(sku, 'New'));
        dispatch(
          updateTrackingByUpsell({
            ...upsellTrackingProperties(planId),
            event: UPSELL_TRACKING.removed,
          }),
        );
      }
    },
    [sku, upsellPlanId],
  );

  const addPlanFromModal = () => handleUpsellToggle(true, upsellPlanId);

  if (loading) return <PageLoader />;

  return (
    // <ServicePageProvider>
    <div>
      <ServiceMeta service={sku} />
      <ServiceHeader
        service={sku}
        onBook={handleBook}
        customCta={customCTA}
        onInfo={handleUpsellMore}
      />
      {sku.extra.showStickyBookNow && (
        <ServiceBookNow service={sku} onBook={handleBook} customCta={customCTA} />
      )}
      <ServiceInfo
        service={sku}
        onBook={handleBook}
        onRating={handleRating}
        onUpsellToggle={handleUpsellToggle}
        onUpsellMore={handleUpsellMore}
        className={`paddingBottom-medium2 ${styles.info}`}
        onGetPriceClick={handleGetPriceClick}
        splitTreatment={customerZipSplitTreatment}
      />
      <ServiceBanner />
      {sku.extra.showFaq && <ServiceFaq service={sku} className={styles.faq} />}
      <GuaranteeBlock classes={styles.guaranteeBlockContainer} />
      <ServiceReviews ref={reviews} reviews={sku.extra.reviews} className={styles.reviews} />
      <LazyView>
        {sku.extra.showUpsellBanner && (
          <ServicePlanBanner text={sku.extra.bannerText} onMore={handleUpsellMore} />
        )}
        <ServiceDetails service={sku} className={styles.details} />
        <ExpertInHomeService className={styles.service} />
        <InTheNews className={styles.news} />
      </LazyView>

      {/* modal */}
      <PlanModal
        plan={sku.extra.plan}
        sku={sku}
        visible={sku.extra.showPlanModal}
        closePlanModal={() => handleTogglePlanModal(false)}
        addPlan={addPlanFromModal}
        plansInfo={plansInfo}
      />

      <ExceedMaxServicesWarningModal
        isVisible={showServicesWarningModal}
        hide={() => setShowServicesWarningModal(false)}
        onKeepServiceClick={() =>
          dispatch(
            startBooking(cart, {
              stage: BOOKING_STAGES.VERIFICATION,
              isPasswordless,
            }),
          )
        }
        onSelectNewService={handleSelectNewService}
        newSkuToAdd={sku}
        existingSkuInCart={existingSkuInCart}
      />
    </div>
  );
};

ServicePage.propTypes = {
  sku: PropTypes.shape({
    partner: PropTypes.object,
    id: PropTypes.number,
    remoteOnly: PropTypes.bool,
    extra: PropTypes.shape({
      bannerText: PropTypes.string,
      showUpsellBanner: PropTypes.bool,
      showFaq: PropTypes.bool,
      prices: PropTypes.shape({
        priceDifference: PropTypes.string,
      }),
      plan: PropTypes.shape({}),
      plansInfo: PropTypes.object,
      reviews: PropTypes.arrayOf(PropTypes.shape({})),
      showStickyBookNow: PropTypes.bool,
      showPlanModal: PropTypes.bool,
      reviewsScrollOffset: PropTypes.number,
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      seoName: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
  seoName: PropTypes.string,
};

const ServicePageWrapper = (props) => {
  return (
    <ServicePageProvider>
      <ServicePage {...props} />
    </ServicePageProvider>
  );
};

export default ServicePageWrapper;
