import ROUTES from 'src/routes';
import { LANDING_PAGES } from 'src/containers/LandingPage/constants';

// general
export const PAGE_NAME = 'current';
export const MODAL_NAME = 'firstServiceDiscount';
export const GET_DISCOUNT = 'firstServiceDiscountModal/GET_DISCOUNT';
export const DISCOUNT_SUBMITTED = 'firstServiceDiscountModal/DISCOUNT_SUBMITTED';
export const SHOW_FIRST_SERVICE_DISCOUNT_MODAL =
  'firstServiceDiscountModal/SHOW_FIRST_SERVICE_DISCOUNT_MODAL';
export const SHOW_FIRST_SERVICE_DISCOUNT_DELAYED_MODAL =
  'firstServiceDiscountModal/SHOW_FIRST_SERVICE_DISCOUNT_DELAYED_MODAL';
export const TOGGLE_FIRST_SERVICE_DISCOUNT_STATE =
  'firstServiceDiscountModal/TOGGLE_FIRST_SERVICE_DISCOUNT_STATE';
export const SUCCESS_RESPONSE = 'firstServiceDiscountModal/SUCCESS_RESPONSE';
export const LOAD_PLAN_DETAILS = 'firstServiceDiscountModal/LOAD_PLAN_DETAILS';
export const PLAN_DETAILS_LOADED = 'firstServiceDiscountModal/PLAN_DETAILS_LOADED';

// logic modals
export const MODAL_WHITE_LISTED_URLS = [
  ROUTES.CART,
  ROUTES.TECH_SUPPORT,
  ROUTES.CLIENTS_REGISTRATION,
  ROUTES.ORDER_CONFIRMATION,
  ROUTES.BOOKING,
  ROUTES.LOGIN,
  ROUTES.SIGN,
  ...LANDING_PAGES.map((l) => l[0]),
];

export const MODAL_WITH_DELAY_WHITE_LISTED_URLS = [
  ROUTES.HOME,
  ROUTES.GEEK_SQUAD,
  ROUTES.MICROSOFT_SUPPORT,
  ROUTES.MICROSOFT_WINDOWS_SUPPORT,
];

export const HIDE_MODAL_URLS = [ROUTES.PLANS, ROUTES.PRIVACY];
