/* eslint-disable no-plusplus */
class Counter {
  constructor() {
    this.idCounter = 0;
    this.uniqueId = ::this.uniqueId;
  }

  uniqueId(prefix) {
    const id = ++this.idCounter;
    return prefix + id;
  }
}

const counter = new Counter();

export default counter;
