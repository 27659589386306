import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { logger } from 'src/utils/logger';
import AppLevelFallback from './AppLevelFallback';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    errorSource: PropTypes.string.isRequired,
    fallback: PropTypes.node,
  };

  static defaultProps = {
    errorSource: 'Error',
    fallback: <AppLevelFallback />,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  state = { hasError: false, error: null, info: null };

  componentDidCatch(error, info) {
    const { errorSource } = this.props;
    this.setState({ error, info });
    logger(errorSource)(error);
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError, error } = this.state;
    if (hasError) return cloneElement(fallback, { error });
    return children;
  }
}

export default ErrorBoundary;
