import get from 'lodash/get';
import { createSelector } from 'reselect';
import { pureCartSelector, cartItemsJSSelector } from 'src/selectors/cart';
import {
  partnerKeyBySkuIdSelector,
  partnerKeyByCartSelector,
  partnerKeyByNewItemWithSkuIdSelector,
  PARTNER_LOGOS,
  partnerKeyByWorkflowSelector,
  PARTNER_LOGOS_SNAKE,
} from 'src/selectors/partner';

export const evPartnerCobrandImageSelector = ({ skuId = null } = {}) =>
  createSelector(
    partnerKeyBySkuIdSelector(skuId)(PARTNER_LOGOS),
    partnerKeyByCartSelector(PARTNER_LOGOS),
    partnerKeyByNewItemWithSkuIdSelector(skuId)(PARTNER_LOGOS),
    // does one exist in the standalone workflow?
    partnerKeyByWorkflowSelector(PARTNER_LOGOS_SNAKE),

    (...selectorCoBrandImages) => {
      const cobrandImages =
        selectorCoBrandImages.find((partnerLogoArray) => {
          return partnerLogoArray && partnerLogoArray.length;
        }) || [];

      // Let's just pull the desktop version
      const cobrandImage = cobrandImages.find((image) => image.resolution === 'desktop') || {};

      return get(cobrandImage, 'original', null);
    },
  );

export const cartHasItemsAndWorkflowSelector = createSelector(
  pureCartSelector,
  cartItemsJSSelector,
  (cartJS, itemsJS) => {
    if (!itemsJS || !Array.isArray(itemsJS) || !itemsJS.length) {
      return false;
    }

    // D2C carts will not have a workflow, e.g. `cart.workflow === null`
    return cartJS?.workflow;
  },
);

export const cartHasEVWorkflowSelector = createSelector(pureCartSelector, (cartJS) => {
  return get(cartJS, 'workflow.type') === 'ev';
});

export const cartHasEVInstallOnlyWorkflowSelector = createSelector(pureCartSelector, (cartJS) => {
  return get(cartJS, 'workflow.type') === 'ev_install_only';
});
