import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Element, scroller } from 'react-scroll';
// Data
import { useFirstAvailableIndex } from './hooks';
import { SCREEN_WIDTHS } from 'src/components/AvailabilitySelector/constants';
import { dateHasSelectedHours, formatDate } from './Scheduling.utils';
// Components
import MobileHourBlock from './MobileHourBlock';
import styles from './styles.scss';

const MobileSelector = (props) => {
  const { availability, toggleHour } = props;
  const mobileContainerStyles = classNames(styles.mobile, 'site-v2');
  const firstAvailableIndex = useFirstAvailableIndex(availability);
  const dateToView = useRef(null);

  const scrollToFirstAvailableDate = useCallback(() => {
    if (window.innerWidth > SCREEN_WIDTHS.tablet || !dateToView.current) return;
    scroller.scrollTo(dateToView.current, {
      offset: -120, // account for header height and availability header
      delay: 100,
      smooth: true,
    });
  }, []);

  useEffect(() => {
    const foundFirstAvailableTime =
      firstAvailableIndex !== null && availability[firstAvailableIndex];
    const isDateChanged = dateToView.current !== availability[firstAvailableIndex]?.date;

    if (foundFirstAvailableTime && isDateChanged) {
      dateToView.current = availability[firstAvailableIndex].date;
      scrollToFirstAvailableDate(); // Trigger scroll when dateToView is updated
    }
  }, [firstAvailableIndex, availability, scrollToFirstAvailableDate]);

  useEffect(() => {
    window.addEventListener('resize', scrollToFirstAvailableDate);

    return () => {
      window.removeEventListener('resize', scrollToFirstAvailableDate);
    };
  }, [scrollToFirstAvailableDate]);

  return (
    <div className={mobileContainerStyles}>
      {availability &&
        availability.map((dateData) => {
          const { date, hours } = dateData;
          return (
            <div key={date}>
              <Element name={date}>
                <p className={styles.dateLabel}>
                  {formatDate(date, 'dddd[,] MMM D')}
                  {dateHasSelectedHours(dateData) && (
                    <div className={styles.availabilityDateSelected} />
                  )}
                </p>
              </Element>
              <div className={styles.hourWrapperMobile}>
                {hours &&
                  hours.map((hourData, index) => (
                    <MobileHourBlock
                      key={index}
                      date={date}
                      hourData={hourData}
                      toggleHour={toggleHour}
                    />
                  ))}
              </div>
            </div>
          );
        })}
    </div>
  );
};

MobileSelector.propTypes = {
  availability: PropTypes.array.isRequired,
  toggleHour: PropTypes.func.isRequired,
};

export default MobileSelector;
