export const ZIP_EMAIL_ERROR_TEXT = {
  ZIP: 'Enter 5-digit ZIP code',
  EMAIL: 'Please enter a valid email address',
};

export const zipOnlyTestTreatment = 'testA';
export const zipAndEmailTestTreatment = 'testB';

export const zipCodeCta = 'Get Your Price';
export const bookingCta = 'Book Now';
export const specialOfferCouponSplitTest = 'GET10TODAY';
