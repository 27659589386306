import React from 'react';
import PropTypes from 'prop-types';
import { Badge, BADGE_TYPES } from 'src/components/Elements/Badge';
import Icon from 'src/components/HTKit/Icon';
import styles from './TechBanner/styles.scss';

const TechRatingBadge = ({ rating, className }) => (
  <Badge type={BADGE_TYPES.RATING} variant="solid" size="xl" classes={className} inline>
    {rating} <Icon name="rating-star" className={styles.ratingStar} />
  </Badge>
);

TechRatingBadge.propTypes = {
  rating: PropTypes.number,
  className: PropTypes.string,
};
export default TechRatingBadge;
