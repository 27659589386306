import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../HTKit/Icon';
import Calendar from 'react-calendar';
import styles from './styles.scss';
import { parseDateInTimeZone } from 'src/containers/BookingPage/AvailabilityPage/AvailabilityPage.utils';

// ################
// CONFIG && UTILS
// ################
export const CALENDAR_CONFIG = {
  DETAIL: {
    MONTH: 'month',
  },
  LOCALE: 'en-US',
  WEEKDAY_INITIALS: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
};

/** Formats the given date as "Month Year" (e.g., "October 2024"). */
export const formatMonthYearLabel = ({ date }) =>
  `${date.toLocaleString(CALENDAR_CONFIG.LOCALE, { month: 'long' })} ${date.getFullYear()}`;

/** Returns the initial letter of the weekday for the given date. */
export const getWeekdayInitial = (_locale, date) => CALENDAR_CONFIG.WEEKDAY_INITIALS[date.getDay()];

// ###############
// MAIN COMPONENT
// ###############

/** A customizable calendar component for selecting dates. */
export const CalendarDatePicker = memo(
  ({ containerClassName, selectedDate, onDateChange, validDates }) => {
    const isDateDisabled = ({ date, view }) => {
      if (view === CALENDAR_CONFIG.DETAIL.MONTH) {
        // Disable date if it's not in the validDates array
        return !validDates.some(
          (validDate) =>
            validDate.getDate() === date.getDate() &&
            validDate.getMonth() === date.getMonth() &&
            validDate.getFullYear() === date.getFullYear(),
        );
      }
      return false;
    };

    /**
     * react-calendar expects a Date object but we use ISO strings for dates.
     * Parse the ISO string and set to start of day to ensure consistent date selection display
     * regardless of timezone.
     */
    const parsedDate = selectedDate
      ? parseDateInTimeZone({ date: selectedDate, timezone: 'UTC' })
      : null;

    return (
      <div className={cn(styles.calendarWrapper, containerClassName)}>
        <Calendar
          locale={CALENDAR_CONFIG.LOCALE}
          value={parsedDate}
          tileDisabled={isDateDisabled}
          onChange={onDateChange}
          minDetail={CALENDAR_CONFIG.DETAIL.MONTH}
          maxDetail={CALENDAR_CONFIG.DETAIL.MONTH}
          navigationLabel={formatMonthYearLabel}
          formatShortWeekday={getWeekdayInitial}
          prev2Label={null}
          next2Label={null}
          prevLabel={<Icon name="chevron-left" />}
          nextLabel={<Icon name="chevron-right" />}
          navigationAriaLabel="Change month"
        />
      </div>
    );
  },
);

CalendarDatePicker.displayName = 'CalendarDatePicker';

CalendarDatePicker.propTypes = {
  containerClassName: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  validDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
};
