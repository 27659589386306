import { fromJS } from 'immutable';
import { SET_RATINGS_AND_NUM_SERVICES } from './constants';

const initialState = fromJS({});

function pageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RATINGS_AND_NUM_SERVICES:
      return state.merge({ stats: action.stats });
    default:
      return state;
  }
}

export default pageReducer;
