import { BOOKING_LAYOUT, HEADER_TEMPLATES } from 'src/constants/common';

// Let's hardcode some details since this is just a temporary solution
export const PALO_ALTO_NETWORKS_DETAILS = Object.freeze({
  name: 'Palo Alto Networks', // users.company_name
  shortName: 'panw',
  id: 615529, // users.id
  skus: [1287], // SELECT * FROM skus WHERE partner_id = 615529;
});

export const PAGE_NAME = 'bookingPaloAltoNetworks';
export const PALO_ALTO_ORDER_PAGE_NAME = 'orderPagePaloAltoNetworks';

export const PALO_ALTO_STYLES_ID = 'palo-alto-styles';

export const BOOKING_STAGES = Object.freeze({
  ADDRESS: 'address',
  QUESTIONS: 'questions',
  AVAILABILITY: 'availability',
  SUMMARY: 'summary',
});

export const BOOKING_STAGE_PROGRESS_STEPS = {
  [BOOKING_STAGES.ADDRESS]: 1,
  [BOOKING_STAGES.QUESTIONS]: 2,
  [BOOKING_STAGES.AVAILABILITY]: 3,
  [BOOKING_STAGES.SUMMARY]: 4,
};

export const OPT_IN_VALUES = Object.freeze({
  SUBSCRIBE_FOR_FEEDBACK: 'subscribeForFeedback', // This sets users.opt_in_sms_transactional to the selected Boolean value
});

export const CHECKBOX_ATTRS = Object.freeze([
  {
    value: OPT_IN_VALUES.SUBSCRIBE_FOR_FEEDBACK,
    label:
      'By providing my mobile number, I agree to receive updates about my service via text or SMS.  I understand that message and data rates may apply.',
  },
]);

export const PALO_ALTO_TOS_URL = 'https://content.hellotech.com/okyo/terms-of-service';

// Actions
export const SUBMIT_ADDRESS = `${PAGE_NAME}/SUBMIT_ADDRESS`;
export const LOAD_SKU_DATA = `${PAGE_NAME}/LOAD_SKU_DATA`;
export const SUBMIT_SKU_QUESTIONS = `${PAGE_NAME}/SUBMIT_SKU_QUESTIONS`;
export const GET_AVAILABLE_TIMES = `${PAGE_NAME}/GET_AVAILABLE_TIMES`;
export const SUBMIT_AVAILABILITY = `${PAGE_NAME}/SUBMIT_AVAILABILITY`;
export const CONFIRM_BOOKING = `${PAGE_NAME}/CONFIRM_BOOKING`;
export const SUBMIT_RESCHEDULE = `${PAGE_NAME}/SUBMIT_RESCHEDULE`;

export const PALO_ALTO_LAYOUT = {
  ...BOOKING_LAYOUT,
  ...{ header: { template: HEADER_TEMPLATES.PaloAltoOkyo, actionableLogo: false } },
  ...{ main: { withPaloAltoBG: true } },
};
