import Cookies from 'js-cookie';

/**
 * Ran across two variations of GAX1.1, GAX1.2; Lets cover our bases.
 * @type {RegExp}
 */
const experimentRegExp = /GAX1.\d./;
export function parseOptimizeCookieForExperiment() {
  const gaexp = Cookies.get('_gaexp');

  if (experimentRegExp.test(gaexp)) {
    const mapped = {};
    gaexp.split(experimentRegExp)[1].split('!').forEach((experiment) => {
      /* eslint no-unused-vars: 0  */
      const [id, date, variant] = experiment.split('.');
      mapped[id] = variant;
    });

    return mapped;
  }
  return {};
}

