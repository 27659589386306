import { DRAG_DROP_ERROR_CODES, FILE_UPLOAD_VALIDATIONS } from './cloudinary.constants';
import snakeCase from 'lodash/snakeCase';

export const formatFileSize = (bytes, decimalPoint = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1000;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint))} ${sizes[i]}`;
};

// See FileList documentation here: https://developer.mozilla.org/en-US/docs/Web/API/FileList
export const getFilenamesFromFileList = (fileList) => {
  if (!fileList) return [];
  const filenames = [];
  for (let i = 0; i < fileList.length; i += 1) {
    filenames.push(fileList[i].name);
  }
  return filenames;
};

export const getFilesGroupedByMaxFileSize = (fileList, maxFileSize = FILE_UPLOAD_VALIDATIONS.MAX_FILE_SIZE) => {
  const seed = {error:[], success: []};
  const reducer = (acc, file) => {
    if (file.size > maxFileSize) {
      return {
        ...acc,
        error: [...acc.error, {...file, status: "rejected", reason: { message: `File ${file.name} exceeded ${FILE_UPLOAD_VALIDATIONS.MAX_FILE_SIZE_DISPLAY_STRING} in size`}}],
      };
    }

    return {
      ...acc,
      success: [...acc.success, file],
    };

  };

  if (Array.isArray(fileList)) {
    const grouped = fileList.reduce(reducer, seed);


    return grouped;
  }

  if (fileList instanceof FileList) {
    const grouped = Array.from(fileList).reduce(reducer, seed);
    return grouped;
  }

  return seed;
}

/**
 * DragDrop hands back an error object that is different than our rails BE
 * error response. So, we need to normalize the dragdrop one.
 *
 * @param fileList
 */
export const tranformDragDropToResponseErrors = (fileList) => {
  if (!fileList || !Array.isArray(fileList)) return [];

  const dragErrors = fileList.reduce((acc, f) => {
    const {errors, file} = f;
    const error = DRAG_DROP_ERROR_CODES[errors[0].code](file.name);

    return [...acc, {
      status: 'rejected',
      reason: { message: error },
    }]
  }, []);

  return dragErrors;
}

/**
 * Cloudinary uses snake case, our app returns camel, normalize
 *
 * note: Only need top level, atm.
 * @param obj
 * @returns {{}}
 */
export const keysToSnakeCase = (obj) => {
  const n = {};
  Object.keys(obj).forEach(k => {
    n[snakeCase(k)] = obj[k]
  });
  return n;
}
