import React, { memo } from 'react';
import cn from 'classnames';
// Hooks
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
// Routes
import routes from 'src/routes';
import { directBookingTechProfilePath } from 'src/utils/paths';
// Selectors
import { cartSelector } from 'src/selectors/cart';
import { techProfileByIdOrSlugJSSelector } from 'src/selectors/techs';
// Utils
import { formatPhoneDash, formatPhoneHref } from 'src/utils/formatter/phone';
import {
  getDirectBookingTechIdOrSlug,
  getDirectBookingPhoneNumber,
} from 'src/utils/cookies/directBookingCookie';
// Components
import CartPreviewIcon from 'src/components/Header/Parts/CartPreviewIcon';
import HTLogo from 'src/components/Header/Parts/HTLogo';
import PhoneIcon from 'src/components/Header/Parts/PhoneIcon';
import SmallCallout from 'src/components/Header/Parts/SmallCallout';
import styles from './styles.scss';

const DirectBookingHeader = () => {
  const { pathname = '' } = useLocation();
  const cart = useSelector(cartSelector);

  /**
   * The tech id will not always exist in the cookie as this is an opt-in
   * behavior when the client clicks "Book Now" on the <TechProfilePage />.
   *
   * We'll find the tech id by either checking the cookie or by
   * extracting the id from the url.
   *
   * useParams() does not work because this header is rendered outside <Router />.
   */
  const getTechId = () => {
    /*
      This header may be used in places other than the Tech's profile page, e.g. CartPage. In that case we won't
      have the id/slug available in the path and need to grab it from the Cookie
    */
    const idInCookie = getDirectBookingTechIdOrSlug();
    if (idInCookie) {
      return idInCookie;
    }

    /*
      Right now we only need the tech's id/slug off the end of the path on the tech's profile pages. If we ever add
      query params to the pathing this will need to be changed. This condition only runs if the cookie is not set.
    */
    const isOnTechDirectBookingPage = pathname.startsWith(routes.DIRECT_BOOKING);
    if (isOnTechDirectBookingPage) {
      const [techIdOrSlug] = pathname.split('/').slice(-1);
      return techIdOrSlug;
    }

    return null;
  };

  const techId = getTechId();
  // get tech profile form redux
  const techProfile = useSelector(techProfileByIdOrSlugJSSelector(techId)) || {};

  const getPhoneNumber = () => {
    const phoneInCookie = getDirectBookingPhoneNumber();
    const { dedicatedPhone = '' } = techProfile;
    return phoneInCookie || dedicatedPhone || '';
  };

  const phoneNumber = getPhoneNumber();
  return (
    <nav className={cn('site-v2 htHeaderContainer', styles.container)}>
      {techId ? (
        <Link to={directBookingTechProfilePath(techId)} className="-no-decoration">
          <HTLogo />
        </Link>
      ) : (
        <HTLogo defaultCursor />
      )}
      <div className={styles.rightContent}>
        {phoneNumber && (
          <a
            href={formatPhoneHref(phoneNumber)}
            className={cn('p1 -no-decoration marginRight-small1', styles.phoneLink)}
          >
            <PhoneIcon />
            <span className={styles.phoneNumber}>{formatPhoneDash(phoneNumber)}</span>
            <SmallCallout text="Contact Technician" className={styles.contactCallout} />
          </a>
        )}
        <CartPreviewIcon cart={cart} />
      </div>
    </nav>
  );
};

export default memo(DirectBookingHeader);
