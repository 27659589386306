import { createSelector } from 'reselect';

export const mobileResolutionSelector = (state) => state.get('isMobile');
export const tabletResolutionSelector = (state) => state.get('isTablet');
export const mobileV2ResolutionSelector = (state) => state.get('isMobileV2');
export const tabletV2ResolutionSelector = (state) => state.get('isTabletV2');

export const isMobileSelector = createSelector(
  mobileResolutionSelector,
  (isMobile) => isMobile,
);

export const isTabletSelector = createSelector(
  tabletResolutionSelector,
  (isTablet) => isTablet,
  );

export const isMobileV2Selector = createSelector(
  mobileV2ResolutionSelector,
  (isMobileV2) => isMobileV2,
);

export const isTabletV2Selector = createSelector(
  tabletV2ResolutionSelector,
  (isTabletV2) => isTabletV2,
);

export const isMobileOrTabletSelector = createSelector(
  mobileResolutionSelector,
  tabletResolutionSelector,
  (isMobile, isTablet) => isMobile || isTablet,
);

export const isMobileOrTabletV2Selector = createSelector(
  mobileV2ResolutionSelector,
  tabletV2ResolutionSelector,
  (isMobileV2, isTabletV2) => isMobileV2 || isTabletV2,
);
