import React from 'react';
import PropTypes from 'prop-types';
import { prevent } from 'src/utils/event';
import { TYPE } from './constants';
import styles from './styles.scss';

const FeaturedServiceSku = ({ sku, onClick }) => {
  const onServiceClick = (event) => {
    prevent(event);
    onClick(sku, TYPE.SKU);
  };
  return (
    <div className={styles.service} key={sku.id}>
      <div className={styles.serviceImage} onClick={onServiceClick}>
        <img src={sku.image.original} alt="Product" />
      </div>
      <p className="h4 marginY-small1">{sku.name}</p>
      <a href="" onClick={onServiceClick} className="large-link">
        Learn More
      </a>
    </div>
  );
};

FeaturedServiceSku.propTypes = {
  sku: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.shape({
      original: PropTypes.string,
    }),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FeaturedServiceSku;
