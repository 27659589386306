import { fromJS } from 'immutable';
import { TOGGLE_MODAL } from '../../../../constants/modals';
import {
  DISCOUNT_SUBMITTED,
  TOGGLE_FIRST_SERVICE_DISCOUNT_STATE,
  MODAL_NAME,
  PLAN_DETAILS_LOADED,
} from './constants';

const initialState = fromJS({ visible: false, submitted: false, activated: false, enabled: true, code: null, plan: null });

function componentReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL: {
      const { name, visible } = action.payload;
      const modalActivated = state.get('activated');
      const activated = modalActivated || (visible && !modalActivated);
      if (name === MODAL_NAME) return state.merge({ visible, submitted: false, code: null, activated });
      return state;
    }
    case DISCOUNT_SUBMITTED: {
      const { code } = action.payload;
      return state.merge({ submitted: true, code });
    }
    case TOGGLE_FIRST_SERVICE_DISCOUNT_STATE:
      return state.merge({ enabled: action.payload.enabled });
    case PLAN_DETAILS_LOADED:
      return state.merge({ plan: action.payload.plan });
    default:
      return state;
  }
}

export default componentReducer;
