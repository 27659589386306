import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import config from 'config';
import throttle from 'lodash/throttle';
import get from 'lodash/get';
import useInterval from 'src/hooks/useInterval';
import { affirmEligibleSelector } from 'src/selectors/cart';
import {logger} from 'src/utils/logger';
import { noop } from 'src/utils/event';
import { splitioConstants, useSplitIOTreatment } from '../SplitIO';
import {AFFIRM_PROMO_TYPES, AFFIRM_PROMO_BY_TYPE_SCHEMA} from './constants';

const affirmLogger = logger('Affirm');
const AffirmPromo = ({
        type = AFFIRM_PROMO_TYPES.product,
        amount = 0,
        className = 'p2',
        options = {}
}) => {
  const { splitConfig = true} = useSplitIOTreatment(splitioConstants.SPLITIONAME_AFFIRM);
  const affirmEligible = useSelector(affirmEligibleSelector);
  const attrs = AFFIRM_PROMO_BY_TYPE_SCHEMA[type].attrs;
  const {finished: affirmLoaded} = useInterval(() => {
    if (('affirm' in window && window.affirm.jsReady())) {
      return true;
    }

    return false;
  }, 200, 40)

  AffirmPromo.loaded = affirmLoaded;

  const generatePropsByType = () => {
    return {
      ...attrs,
      ...options,
      ...{ className: [].concat(attrs.className, className).join(' ').trim() }
    }
  }

  /**
   * Is affirm eligible?
   *
   * overrides: product page
   */
  const affirmEligibility = () => {
    // overrides
    if (attrs.alwaysEligible) return true;
    // cart level determination
    if (!affirmEligible) return false;

    return true;
  }

  useEffect(() => {
    if (affirmLoaded) {
      window.affirm.ui.ready(function(){
        window.affirm.ui.refresh();
      });
    }
  }, [affirmLoaded]);

  useEffect(() => {
    if (affirmLoaded) {
      window.affirm.ui.ready(function(){
        window.affirm.ui.refresh();
      });
    }
  }, [amount]);

  /**
   * We null out if:
   * - split is not enabled
   * - affirm is not eligible
   * - amount is not a valid safe integer
   */
  if (!splitConfig || !affirmEligibility() || !Number.isSafeInteger(parseInt(amount, 10))) {
    return null;
  }

  return (
    <div data-amount={amount} {...generatePropsByType()} />
  )
}

/**
 * Affirm Checkout Start
 *
 * @param userPure
 * @param cartPure
 * @param successCallback
 */
AffirmPromo.initializeCheckout = ({cartPure, successCallback = noop}) => {
  if (AffirmPromo.loaded) {
    /* Get the Checkout Schema together */
    /*
      These are adjustments that need to be merged with affirm scheme requirements.
      - discounts
      - adjustments that add to the cost of the cart, treat as an item.
    */
    const breakdownAdjustments = get(cartPure, 'breakdown.adjustments') || [];
    const breakdownItems = cartPure.breakdown.items;
    const fees = [];
    let coupon = cartPure.coupon;

    breakdownAdjustments.forEach(adjustment => {
      if (adjustment.type === 'coupon') {
        coupon = {
          ...coupon,
          discount_amount: Math.abs(adjustment.amount),
          discount_display_name: adjustment.name
        }
      } else if (adjustment.type.includes('fee')) {
        fees.push({
          "display_name":         adjustment.name,
          "sku":                  String(adjustment.skuId),
          "unit_price":           adjustment.amount,
          "qty":                  1,
        })
      }
    })
    /* Discounts */
    const discounts = coupon && {
      [coupon.text.toUpperCase()]:
        {
          discount_amount: coupon.discount_amount,
          discount_display_name: coupon.discount_display_name
        }

    }

    /* Breakdown items */
    const items = breakdownItems.map(item => {
      return {
        "display_name":         item.name,
        "sku":                  String(item.skuId),
        "unit_price":           item.skuAmount,
        "qty":                  1,
        "item_image_url":       "",
        "item_url":             "",
        "categories": [[item.category]]
      }
    });

    /* Object we pass to Affirm of our cart/user data */
    const checkoutObject = {
      "metadata": {
        "mode":        "modal"
      },
      "merchant": {
        // redo this part
        "user_confirmation_url":    `${config.protocol}://${config.url}/cart/booking/summary`,
        "user_cancel_url":          `${config.protocol}://${config.url}/cart/booking/summary`,
        "user_confirmation_url_action": "POST",
        "name": "HelloTech"
      },
      "fees": fees,
      "discounts": discounts,
      "shipping_amount": 0,
      "currency": "USD",
      "items": items,
      "tax_amount": cartPure.breakdown.tax,
      "total": cartPure.breakdown.total
    }
    // temp for qa:
    window.AFFIRM_HT_CHECKOUT = checkoutObject;
    window.affirm.checkout(checkoutObject);
    window.affirm.checkout.open({
      onFail(){
        affirmLogger(`User canceled checkout flow.`);
      },
      onSuccess(a){
        successCallback(null, {affirmToken: a.checkout_token});
      }
    })
  } else {
    const affirmExists = 'affirm' in window;
    affirmLogger(`initializeCheckout (affirm not loaded[exists: ${affirmExists}])`);
  }

}

AffirmPromo.refresh = throttle(() => {
  if (AffirmPromo.loaded) {
    window.affirm.ui.refresh();
  }
}, 3000)


AffirmPromo.propTypes = {
  type: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  className: PropTypes.string,
  options: PropTypes.shape({}),
};

export default AffirmPromo;

