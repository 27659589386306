// Libraries
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import OrderBreakdownLineItem from './OrderBreakdownLineItem';
import styles from '../styles.scss';
import { formatPriceTypeHourly } from 'src/containers/CartPage/utils';
import { BREAKDOWN_PRICE_TYPES } from 'src/containers/CartPage/constants';

/*
  { name:'service 1', amount: '$1', adjustments: [name:'adjustment 1', amount:'$2']};
*/

const ServiceBreakdownItem = ({
  name,
  adjustments,
  caption,
  amount,
  lineThroughText,
  boldServiceName,
  mediumWeightAmount,
  mediumWeightName,
  subTotal,
  serviceDiscounts,
  omitPlus,
  priceType,
  withAsterik,
  ...props
}) => {
  const isPriceTypeHourly = priceType === BREAKDOWN_PRICE_TYPES.hourly;
  const wa = typeof withAsterik === 'boolean' ? withAsterik : true;
  /* price is the pre manipulated (not * by hours) hourly price */
  let price;
  if (isPriceTypeHourly) {
    if (subTotal.price) {
      price = subTotal.price;
    } else {
      price = subTotal.amount;
    }
  } else {
    price = subTotal.amount;
  }

  return (
    <div className={cn('marginBottom-tiny marginTop-tiny', styles.serviceBreakdownItem)}>
      <OrderBreakdownLineItem
        name={name}
        caption={caption}
        amount={formatPriceTypeHourly({
          service: priceType,
          price,
          withAsterik: wa,
        })}
        lineThroughText={subTotal.lineThroughText}
        boldName={boldServiceName}
        mediumWeightName={mediumWeightName}
        className="marginBottom-small"
        boldAmount={!mediumWeightAmount}
        mediumWeightAmount={mediumWeightAmount}
        {...props}
      />
      {!isPriceTypeHourly && (
        <OrderBreakdownLineItem
          name="Base Price"
          amount={amount}
          lineThroughText={lineThroughText}
          className="marginBottom-small"
        />
      )}
      {Boolean(adjustments.length) &&
        adjustments.map((adj, adjustmentIndex) => {
          const n = `${adj.omitPlus ? '' : '+'} ${adj.name}`;
          const isSubscriptionDiscount = /^subscription\sdiscount$/i.test(adj.name);

          return (
            <OrderBreakdownLineItem
              name={n}
              amount={adj.amount}
              key={`adjustment-${adjustmentIndex}`}
              lineThroughText={adj.lineThroughText}
              className="marginBottom-small"
              secondary
              nameEllipsis
              blue700={isSubscriptionDiscount}
            />
          );
        })}
      {Boolean(serviceDiscounts.length) &&
        serviceDiscounts.map((disc, discIndex) => {
          return (
            <OrderBreakdownLineItem
              name={disc.name}
              amount={disc.amount}
              key={`serviceDiscount-${discIndex}`}
              className="marginBottom-small"
              blue700
              nameEllipsis
            />
          );
        })}
    </div>
  );
};

ServiceBreakdownItem.propTypes = {
  name: PropTypes.node,
  amount: PropTypes.node,
  adjustments: PropTypes.array,
  serviceDiscounts: PropTypes.array,
  caption: PropTypes.node,
  lineThroughText: PropTypes.node,
  mediumWeightAmount: PropTypes.bool,
  mediumWeightName: PropTypes.bool,
  boldServiceName: PropTypes.bool,
  subTotal: PropTypes.shape({
    amount: PropTypes.node,
    lineThroughText: PropTypes.node,
    price: PropTypes.node,
  }),
  omitPlus: PropTypes.bool,
  priceType: PropTypes.object,
  withAsterik: PropTypes.bool,
};

ServiceBreakdownItem.defaultProps = {
  adjustments: [],
  serviceDiscounts: [],
  subTotal: {},
};

export default React.memo(ServiceBreakdownItem);

/*
  This represents the breakdown of one service, including adjustments
*/
