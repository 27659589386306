import { call, put, takeLatest, select } from 'redux-saga/effects';
import { pageLoadingError, pageLoaded, updateUser } from 'src/actions/common';
import { selectRoutes } from 'src/apiRoutes';
import { LOAD_PAGE } from 'src/constants/common';
import { NEED_AT_LEAST_ONE_VALID_DATE } from 'src/constants/cart';
import { updateCart } from 'src/containers/AddSkuPage/actions';
import { displayErrors, requestFinished, requestStarted } from 'src/utils/request';
import { getAvailableTimesApi } from 'src/components/AvailabilitySelector/utils';
import { availabilityLoaded } from 'src/components/AvailabilitySelector/actions';
import { summarySubmitted } from 'src/containers/BookingPage/SummaryPage/actions';
import { logger } from 'src/utils/logger';
import { BOOK } from './constants';
import { setEditPaymentInformation, toggleModal, setShowAvailability } from './actions';

function* loadAvailableTimes() {
  const availability = yield select((state) => state.getIn(['entities', 'availability']));
  if (availability.size !== 0) return;
  const response = yield call(getAvailableTimesApi);
  if (!response.err) {
    yield put(availabilityLoaded({ availability: response.data }));
  } else {
    yield put(displayErrors(response));
  }
}

function* availabilityIsMissing() {
  yield call(loadAvailableTimes);
  yield put(setShowAvailability(true));
}

function* pageSaga() {
  const routes = yield call(selectRoutes);
  let cart = yield select((state) => state.getIn(['entities', 'cart']));
  if (!cart || !cart.get('breakdown')) {
    cart = null;
  }
  if (cart) {
    cart = cart.toJS();
  } else {
    const requestResult = yield call(routes.cart.find, { breakdown: true });
    if (!requestResult.err) {
      cart = requestResult.data.cart;
    } else {
      yield put(pageLoadingError('finishPartnerOrder', requestResult));
      return;
    }
  }
  yield put(updateCart({ cart }));
  yield put(setEditPaymentInformation(!cart.card));
  yield put(
    pageLoaded('finishPartnerOrder', {
      cart,
    }),
  );
  if (cart.availability.length === 0) {
    yield call(availabilityIsMissing);
  }

  // figure out if this page is used anymore
  logger('FinishPartnerOrder')(`Page accessed by cart #${cart.id}`);
}

function* bookSaga({ updatePayment, token, selectedDateTimes }) {
  const routes = yield call(selectRoutes);
  const cartObject = yield select((state) => state.getIn(['entities', 'cart']));
  yield put(requestStarted());

  const updateTimesResponse = yield call(routes.cart.updateAvailability, {
    availability: selectedDateTimes,
  });
  if (updateTimesResponse.err) {
    yield put(displayErrors(updateTimesResponse));
    yield put(requestFinished());
    return;
  }
  if (updateTimesResponse.data && updateTimesResponse.data.cart.availability.length === 0) {
    yield put(toggleModal({ modalName: 'invalidDates' }));
    yield call(availabilityIsMissing);
    yield put(requestFinished());
    return;
  }

  const usersResponse = yield call(routes.users.update, {
    client: { subscriptionPreferencesSet: true },
  });
  if (usersResponse.err) {
    yield put(requestFinished());
    yield put(displayErrors(usersResponse));
    return;
  }

  if (updatePayment) {
    const response = yield call(routes.cart.addCard, {
      card_token: token.id,
      token: cartObject.get('token'),
    });
    if (response.err) {
      yield put(requestFinished());
      yield put(displayErrors(response));
      return;
    }
    yield put(setEditPaymentInformation(false));
    yield put(updateCart({ cart: response.data.cart }));
  }

  const createOrderResponse = yield call(routes.cart.completeBooking);
  if (createOrderResponse.err) {
    const orderErrMsg = createOrderResponse.data.errors && createOrderResponse.data.errors[0];
    if (orderErrMsg === NEED_AT_LEAST_ONE_VALID_DATE) {
      yield put(toggleModal({ modalName: 'invalidDates' }));
      yield call(availabilityIsMissing);
    } else {
      yield put(displayErrors(createOrderResponse));
    }
    yield put(requestFinished());
    return;
  }
  yield put(requestFinished());
  const {
    cart,
    id,
    amount,
    services,
    discount,
    coupon,
    user,
    geek_cut: geekCut = 0,
    partnerObject,
  } = createOrderResponse.data;
  const tax = yield select((state) => state.getIn(['entities', 'cart', 'breakdown', 'tax'], 0));
  const stateCart = yield select((state) => state.getIn(['entities', 'cart'], {}));
  /* BE getting partner in cart at this point is not easy, so just do it here */
  if (partnerObject) {
    cart.partner = partnerObject;
  }

  yield put(
    summarySubmitted({
      id,
      amount,
      services,
      tax,
      discount,
      coupon,
      stateCart,
      geekCut,
    }),
  );
  yield put(updateUser(user));
  yield put(updateCart({ cart, replace: true }));
}

function* pageFlow() {
  yield takeLatest(
    (action) => action.type === LOAD_PAGE && action.page === 'finishPartnerOrder',
    pageSaga,
  );
  yield takeLatest(BOOK, bookSaga);
}

export default [pageFlow];
