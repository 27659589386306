import Cookies from 'js-cookie';
import pickBy from 'lodash/pickBy';
import merge from 'lodash/merge';
import identity from 'lodash/identity';
import flatten from 'lodash/flatten';
import moment from 'moment';
import { isPaloAltoNetworksPartner } from 'src/containers/BookingPagePaloAltoNetworks/utils';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { selectRoutes } from 'src/apiRoutes';
import { logger } from 'src/utils/logger';
import {
  pageLoadingError,
  pageLoaded,
  setZipCodeRestriction,
  updateUser,
  clearFormErrors,
  invalidForm,
} from 'src/actions/common';
import { LOAD_PAGE } from 'src/constants/common';
import { updateCart } from 'src/containers/AddSkuPage/actions';
import { displayErrors, requestStarted, requestFinished } from 'src/utils/request';
import {
  push,
  cartPath,
  paloAltoNetworksApiFlowPath,
  paloAltoNetworksOrderConfirmationPath,
} from 'src/utils/paths';

// Availability start
import { availabilityLoaded } from 'src/components/AvailabilitySelector/actions';
import {
  getAvailableTimesApi,
  checkZipCodeRestriction,
} from 'src/components/AvailabilitySelector/utils';
import { NEED_AT_LEAST_ONE_VALID_DATE, RECOMMEND_THREE_DATES } from 'src/constants/cart';
import { toggleModal } from 'src/containers/BookingPage/AvailabilityPage/actions';
// Availability end

// Sku Questions start
import { PAGE_NAME as ADD_SKU_PAGE_NAME } from 'src/containers/AddSkuPage/constants';
import { getErrors } from 'src/containers/AddSkuPage/sagas';
// Sku Questions end

// Summary Start
import { getRedirectCookie, removeRedirectCookies } from 'src/utils/cookies';
import { irclickidSelector } from 'src/selectors/tracking';
import { parseOptimizeCookieForExperiment } from 'src/utils/cookies/parseOptimizeCookieForExperiment';
import { removeDirectBookingTechData } from 'src/utils/cookies/directBookingCookie';
// Summary End

// OrderPage start
import orderPageSagas from 'src/containers/BookingPagePaloAltoNetworks/OrderPage/sagas';
// Order Page End

// ReschedulingPage start
import reschedulingPageSagas from 'src/containers/BookingPagePaloAltoNetworks/ReschedulingPage/sagas';
// ReschedulingPage end

import {
  PAGE_NAME,
  SUBMIT_ADDRESS,
  LOAD_SKU_DATA,
  SUBMIT_SKU_QUESTIONS,
  GET_AVAILABLE_TIMES,
  SUBMIT_AVAILABILITY,
  BOOKING_STAGES,
  CONFIRM_BOOKING,
} from './constants';

function* pageLoadSaga() {
  const routes = yield call(selectRoutes);
  let cart = yield select((state) => state.getIn(['entities', 'cart']));
  if (!cart || !cart.get('breakdown')) {
    cart = null;
  }
  if (cart) {
    cart = cart.toJS();
  } else {
    yield put(requestStarted());
    const requestResult = yield call(routes.cart.find, { breakdown: true });
    yield put(requestFinished());
    if (!requestResult.err) {
      cart = requestResult.data.cart;
    } else {
      yield put(pageLoadingError(PAGE_NAME, requestResult));
      return;
    }
  }

  yield put(updateCart({ cart }));

  const cartPartner = cart.partner || {};
  if (!isPaloAltoNetworksPartner(cartPartner)) {
    yield put(push(cartPath()));
    return;
  }

  yield put(pageLoaded(PAGE_NAME, { cart }));
}

function* submitAddressSaga({ payload = {} }) {
  /*
  The address form combines a number of different apis that would normally
  be handled by different forms/pages
  - Address change
  - Phone number change
  */
  const { isEditMode, address, client } = payload;
  const routes = yield call(selectRoutes);

  // Handle Profile Change
  yield put(requestStarted());
  const profileUpdateResponse = yield call(routes.users.update, { client });
  yield put(requestFinished());
  if (profileUpdateResponse.err) {
    const { errors } = profileUpdateResponse.data;
    logger(PAGE_NAME)(new Error(`Profile Update error -> ${String(errors)}`));
  } else {
    const { user, cart } = profileUpdateResponse.data;
    yield put(updateUser(user));
    if (cart) {
      yield put(updateCart({ cart }));
    }
  }

  // Handle Address Change
  const newAddressProvided = Object.keys(address).length;
  if (newAddressProvided) {
    yield put(requestStarted());
    const response = yield call(routes.cart.goToAvailability, {
      address,
    });
    yield put(requestFinished());

    if (response.err) {
      yield put(displayErrors(response));
      logger(PAGE_NAME)(new Error(`Address submission error -> ${response.err}`));
      return;
    }
    yield put(updateCart({ cart: response.data.cart }));
  }

  const nextStage = isEditMode ? BOOKING_STAGES.SUMMARY : BOOKING_STAGES.QUESTIONS;
  yield put(push(paloAltoNetworksApiFlowPath(nextStage)));
}

function* loadSkuDataSaga({ payload = {} }) {
  const { skuId } = payload;
  const routes = yield call(selectRoutes);

  // get cart data
  let cart = yield select((state) => state.getIn(['entities', 'cart']));
  if (!cart || !cart.get('breakdown')) {
    cart = null;
  }

  if (cart) {
    cart = cart.toJS();
  } else {
    const requestResult = yield call(routes.cart.find, { breakdown: true });
    if (!requestResult.err) {
      cart = requestResult.data.cart;
    } else {
      yield put(pageLoadingError('cart', requestResult));
      return;
    }
  }

  yield put(updateCart({ cart }));

  // get sku data
  let sku = yield select((state) => state.getIn(['entities', 'skus', String(skuId)], null));
  if (sku) {
    sku = sku.toJS();
  } else {
    const requestResult = yield call(routes.skus.find, { id: skuId });
    if (!requestResult.err) {
      sku = requestResult.data.sku;
    } else {
      logger(PAGE_NAME)(new Error(`Sku data fetch error -> ${requestResult.err}`));
      return;
    }
  }

  // get questions data
  let questions = yield select((state) =>
    state.getIn(['entities', 'questions', String(sku.id)], null),
  );
  if (questions) {
    questions = questions.toJS();
  } else {
    const requestResult = yield call(routes.skus.questions, { id: sku.id });
    if (!requestResult.err) {
      questions = requestResult.data.questions;
    } else {
      logger(PAGE_NAME)(new Error(`Sku question fetch error -> ${requestResult.err}`));
      return;
    }
  }

  yield put(pageLoaded(ADD_SKU_PAGE_NAME, { sku, questions }));
}

function* submitSkuQuestionsSaga() {
  const routes = yield call(selectRoutes);
  const email = yield select((state) => state.getIn(['pages', ADD_SKU_PAGE_NAME, 'email']));
  const item = yield select((state) => state.get('newItem'));
  const questions = yield select((state) =>
    state.getIn(['entities', 'questions', item.get('skuId').toString()]),
  );

  yield put(clearFormErrors(ADD_SKU_PAGE_NAME));
  const errors = getErrors({ item, questions });
  if (Object.keys(errors).length > 0) {
    yield put(invalidForm(ADD_SKU_PAGE_NAME, errors));
    return;
  }

  yield put(requestStarted());
  const index = 0;
  const response = yield call(routes.cart.updateItem, { index, item, email });
  yield put(requestFinished());

  if (response.err) {
    yield put(displayErrors(response));
    logger(PAGE_NAME)(new Error(`Address submission error -> ${response.err}`));
    return;
  }
  const { cart } = response.data;
  yield put(updateCart({ cart }));

  yield put(push(paloAltoNetworksApiFlowPath(BOOKING_STAGES.AVAILABILITY)));
}

function* getAvailableTimesSaga() {
  const availableDatesResponse = yield call(getAvailableTimesApi);
  const isOrderableZipResponse = yield call(checkZipCodeRestriction);
  if (!availableDatesResponse.err) {
    yield put(availabilityLoaded({ availability: availableDatesResponse.data }));
    yield put(pageLoaded('availability'));
  } else {
    yield put(displayErrors(availableDatesResponse));
  }
  if (!isOrderableZipResponse.err) {
    const {
      data: { isZipRestricted, orderableFromDate },
    } = isOrderableZipResponse;
    yield put(
      setZipCodeRestriction({
        isZipRestricted,
        orderableFromDate,
        page: PAGE_NAME,
      }),
    );
  }
}

function* submitAvailabilitySaga({ payload = {} }) {
  const { selectedDateTimes, skipMinCount } = payload;
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.cart.goToPayment, {
    availability: selectedDateTimes,
    skip_min_count: skipMinCount,
  });
  yield put(requestFinished());
  if (!response.err) {
    yield put(updateCart({ cart: response.data.cart }));
    yield put(push(paloAltoNetworksApiFlowPath(BOOKING_STAGES.SUMMARY)));
  } else {
    const errors = response.data.errors || [];
    const errMsg = errors[0];
    if (errMsg === NEED_AT_LEAST_ONE_VALID_DATE) {
      yield put(toggleModal({ modalName: 'invalidDates' }));
    } else if (errMsg === RECOMMEND_THREE_DATES) {
      yield put(toggleModal({ modalName: 'recommendedDates' }));
    } else {
      yield put(displayErrors(response));
    }
  }
}

function* confirmBookingSaga({ affirmToken = '' } = {}) {
  const routes = yield call(selectRoutes);
  const googleOptimize = parseOptimizeCookieForExperiment();
  const redirectCookie = getRedirectCookie();
  const irclickid = yield select(irclickidSelector);
  /* Only use if have values */
  const filterParams = pickBy({ affirm_token: affirmToken }, identity);
  yield put(requestStarted());
  const siteID = Cookies.get('siteID');
  const dte = Cookies.get('dte');
  const tag = {
    experiments: merge({}, googleOptimize, redirectCookie),
    ...filterParams,
  };

  if (
    siteID &&
    dte &&
    moment()
      .subtract(30, 'days')
      .isBefore(new Date(dte))
  ) {
    tag.siteID = siteID;
    tag.dte = dte;
  }

  if (irclickid) {
    tag.irclickid = irclickid;
  }

  const response = yield call(routes.cart.completeBooking, tag);
  yield put(requestFinished());
  if (response.err) {
    const errors = response.data.errors || [];
    const errMsg = errors[0];
    if (errMsg === NEED_AT_LEAST_ONE_VALID_DATE) {
      yield put(push(paloAltoNetworksApiFlowPath(BOOKING_STAGES.AVAILABILITY)));
      yield put(toggleModal({ modalName: 'invalidDates' }));
    } else {
      yield put(displayErrors(response));
    }
  } else {
    removeRedirectCookies();
    removeDirectBookingTechData();
    const { cart, id, user, partnerObject } = response.data;
    /* BE getting partner in cart at this point is not easy, so just do it here */
    if (partnerObject) {
      cart.partner = partnerObject;
    }
    yield put(updateCart({ cart, replace: true }));
    yield put(updateUser(user));
    yield put(push(paloAltoNetworksOrderConfirmationPath(id)));
  }
}

function* pageFlow() {
  yield takeLatest(
    (action) => action.type === LOAD_PAGE && action.page === PAGE_NAME,
    pageLoadSaga,
  );
  yield takeLatest(SUBMIT_ADDRESS, submitAddressSaga); // STEP 1 - ADDRESS
  yield takeLatest(LOAD_SKU_DATA, loadSkuDataSaga); // STEP 2 - SKU QUESTIONS
  yield takeLatest(SUBMIT_SKU_QUESTIONS, submitSkuQuestionsSaga); // STEP 2 - SKU QUESTIONS
  yield takeLatest(GET_AVAILABLE_TIMES, getAvailableTimesSaga); // STEP 3 - AVAILABILITY
  yield takeLatest(SUBMIT_AVAILABILITY, submitAvailabilitySaga); // STEP 3 - AVAILABILITY
  yield takeLatest(CONFIRM_BOOKING, confirmBookingSaga); // STEP 4 - SUMMARY
}

// export default [pageFlow];
// export default flatten([pageFlow, availabilitySaga, addressSaga, paymentSaga, summarySaga]);
export default flatten([pageFlow, orderPageSagas, reschedulingPageSagas]);
