import React, { memo } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Grid } from 'src/components/HTKit/Grid';

export const AvailabilityPageGrid = ({ children }) => (
  <Grid.Fluid>
    <Grid.Row></Grid.Row>
    <Grid.Column sm={4} md={8} lgOffset={2} lg={8}>
      <Helmet title="Availability" />
      {children}
    </Grid.Column>
  </Grid.Fluid>
);

export default memo(AvailabilityPageGrid);
AvailabilityPageGrid.propTypes = { children: PropTypes.node.isRequired };
