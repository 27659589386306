import { createSelector, createStructuredSelector } from 'reselect';
import moment from 'moment';
import { pageSelector as selectPage } from 'src/selectors/page';
import {
  cartSelector,
  cartBreakdownSelector,
  totalSavingsSelector,
  totalSavingsFormattedSelector,
  badgeSavingsSelector,
  couponSelector,
  couponValueFormattedSelector,
  partnerIdSelector,
  planSelector,
  planAmountSelector,
  orderAdjustmentsNoCouponSelector,
  subscriptionTaxFormattedSelector,
  serviceTotalFormattedSelector,
  getPricesAndDiscounts,
  getFilteredServiceAdjustments,
  getFilteredServiceDiscounts,
  orderSelector,
  orderIsFromApiSelector,
  cardSelector,
  couponTextSelector,
  subscriptionCouponSelector,
  subscriptionCouponValueSelector,
  subscriptionCreditAdjustmentsSelector,
} from 'src/selectors/cart';
import { fieldSaleAgentIdSelector } from 'src/containers/FieldSales/selectors';
import { techProfilesSelector } from 'src/selectors/techs';
import { userSelector, userSubscriptionSelector } from 'src/selectors/user';
import { formatter } from 'src/components/MemberBenefits/helpers';
import { getFullFirstLastInitial } from 'src/utils/text';
import { PREPAID_STATUS } from 'src/constants/sku';
import { getDirectBookingTechId } from 'src/utils/cookies/directBookingCookie';
import { workFlowRangeAdjustment } from 'src/utils/schedule/appointments';

export const pageSelector = selectPage('summary');

const skuIdSelector = createSelector(cartSelector, (cart) => cart.getIn(['items', 0, 'skuId']));

const loadingSelector = createSelector(pageSelector, (page) => page.get('loading'));

const couponErrorsSelector = createSelector(pageSelector, (page) =>
  page.getIn(['errors', 'coupon']),
);

const planTotalSubTextSelector = createSelector(planSelector, (plan) => {
  if (!plan || plan.get('trial')) return null;
  const isMonthlyBilling = plan.get('billingIntervalMonths') === 1;
  if (isMonthlyBilling) return 'First of 12 Monthly Payments';
  return null;
});

const isRemoteCartSelector = createSelector(cartSelector, (cart) => cart.get('remote'));

const serviceTaxSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => breakdown && breakdown.get('tax'),
);

const serviceTaxFormattedSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => breakdown && breakdown.get('serviceTaxFormatted'),
);

const totalTaxFormattedSelector = createSelector(
  cartBreakdownSelector,
  (breakdown) => breakdown && breakdown.get('taxFormatted'),
);

const subscriptionTotalSelector = createSelector(cartBreakdownSelector, (breakdown) => {
  if (!breakdown) return null;
  return breakdown.get('subscriptionTotalFormatted');
});

const hasProductsInCartSelector = createSelector(cartSelector, (cart) =>
  Boolean(cart.get('hasProducts')),
);

const hasOnlyProductsInCartSelector = createSelector(cartSelector, (cart) =>
  Boolean(cart.get('hasOnlyProducts')),
);

export const breakdownItemsSelector = createSelector(cartBreakdownSelector, (breakdown) => {
  // returns { name:'service 1', amount: '$1', adjustments: [{ name:'adjustment 1', amount:'$2' }]};
  if (!breakdown) return null;
  return breakdown.toJS().items.map((breakdownItem) => {
    const { name, adjustments, prepaid, image, quantity } = breakdownItem;
    const isItemPrepaid = prepaid === PREPAID_STATUS.BASE || prepaid === PREPAID_STATUS.FULL;
    const pricesAndDiscounts = getPricesAndDiscounts({
      item: breakdownItem,
      isItemPrepaid,
    });
    return {
      name,
      quantity,
      adjustments: getFilteredServiceAdjustments({ adjustments, isItemPrepaid: prepaid }),
      serviceDiscounts: getFilteredServiceDiscounts(adjustments),
      ...pricesAndDiscounts,
      image,
      priceType: breakdownItem.priceType,
    };
  });
});

const consolidatedAvailabilitySelector = createSelector(cartSelector, (cart) =>
  cart.get('consolidatedAvailability'),
);
const availabilitySelector = createSelector(cartSelector, (cart) => cart.get('availability'));

const subscriptionTotalNoTaxSelector = createSelector(cartBreakdownSelector, (breakdown) => {
  if (!breakdown) return null;
  if (!breakdown.get('subscriptionTax')) return breakdown.get('subscriptionTotalFormatted');
  const totalMinusTax = breakdown.get('subscriptionTotal') - breakdown.get('subscriptionTax');
  return formatter(0).format(totalMinusTax / 100);
});

export const selectedServiceTimesSelector = createSelector(cartSelector, (cart) => {
  // return an array of formatted strings with selected date/time
  if (!cart || !cart.get('consolidatedAvailability')) return null;
  const rawTimes = Object.entries(cart.get('consolidatedAvailability').toJS());
  return rawTimes.flatMap((date) => {
    const ranges = date[1];
    return ranges.map((range) => {
      let r = range;
      if (cart.getIn(['workflow', 'token'])) {
        r = workFlowRangeAdjustment(range);
      }
      const formattedDate = moment(date, 'YYYY-MM-DD').format('ddd[.] MMM D');
      return `${formattedDate} at ${r}`;
    });
  });
});

const priceBreakdownSelector = createSelector(cartSelector, (cart) =>
  cart.getIn(['breakdown', 'totalFormatted']),
);

const showPaymentSourceSelector = createSelector(
  [cardSelector, cartBreakdownSelector, orderIsFromApiSelector],
  (card, breakdown, orderIsFromApi) => {
    // Always show payment info for direct-to-consumer orders, but apply conditions to API orders
    if (!card || !breakdown) return null;
    if (!orderIsFromApi) return true;
    const servicesTotal = breakdown.get('serviceTotal');
    const subscriptionTotal = breakdown.get('subscriptionTotal');
    return card && (servicesTotal > 0 || subscriptionTotal > 0);
  },
);

export const isEligibleToSeeLowTierUpsellSelector = createSelector(
  // This selector was originally created with the Low Tier Membership upsell in mind -GH Mar 28, 2021
  [userSubscriptionSelector, fieldSaleAgentIdSelector, cartSelector],
  (userHasPlan, fieldSaleAgent, cart) => {
    // isMembershipOnlyCart ensures we don't show Low Tier upsell in a cart with no items.
    const isMembershipOnlyCart = cart.get('items').size === 0;
    if (isMembershipOnlyCart) return false;
    const skusIncludedInUpsellPlan = cart.get('skusIncludedInUpsellPlan');
    return !userHasPlan && !fieldSaleAgent && skusIncludedInUpsellPlan;
  },
);

const preferredTechDataJSSelector = createSelector(techProfilesSelector, (techProfiles) => {
  const directBookingTechId = getDirectBookingTechId();
  if (directBookingTechId) {
    const profile = techProfiles.get(directBookingTechId);
    if (profile) {
      const profileJS = profile.toJS();
      const {
        firstName,
        lastName,
        profilePicture: { retinaThumb },
      } = profileJS;
      const techName = getFullFirstLastInitial(firstName, lastName);
      return { techName, imageUrl: retinaThumb };
    }
    return {};
  }
  return {};
});

export default createStructuredSelector({
  skuId: skuIdSelector,
  cart: cartSelector,
  loading: loadingSelector,
  partnerId: partnerIdSelector,
  page: pageSelector,
  user: userSelector,
  coupon: couponSelector,
  couponErrors: couponErrorsSelector,
  couponValueFormatted: couponValueFormattedSelector,
  couponText: couponTextSelector,
  breakdownItems: breakdownItemsSelector,
  totalSavings: totalSavingsSelector,
  totalSavingsFormatted: totalSavingsFormattedSelector,
  badgeSavings: badgeSavingsSelector,
  orderAdjustmentsNoCoupon: orderAdjustmentsNoCouponSelector,
  isRemoteCart: isRemoteCartSelector,
  plan: planSelector,
  planTotalSubText: planTotalSubTextSelector,
  serviceTotalFormatted: serviceTotalFormattedSelector,
  serviceTax: serviceTaxSelector,
  serviceTaxFormatted: serviceTaxFormattedSelector,
  subscriptionTaxFormatted: subscriptionTaxFormattedSelector,
  totalTaxFormatted: totalTaxFormattedSelector,
  subscriptionTotal: subscriptionTotalSelector,
  subscriptionTotalNoTax: subscriptionTotalNoTaxSelector,
  order: orderSelector,
  orderIsFromApi: orderIsFromApiSelector,
  card: cardSelector,
  selectedServiceTimes: selectedServiceTimesSelector,
  hasProductsInCart: hasProductsInCartSelector,
  hasOnlyProductsInCart: hasOnlyProductsInCartSelector,
  priceBreakdown: priceBreakdownSelector,
  consolidatedAvailability: consolidatedAvailabilitySelector,
  availability: availabilitySelector,
  subscriptionCoupon: subscriptionCouponSelector,
  subscriptionCouponValue: subscriptionCouponValueSelector,
  planAmount: planAmountSelector,
  showPaymentSource: showPaymentSourceSelector,
  isEligibleToSeeLowTierUpsell: isEligibleToSeeLowTierUpsellSelector,
  preferredTechData: preferredTechDataJSSelector,
  subscriptionCreditAdjustments: subscriptionCreditAdjustmentsSelector,
});
