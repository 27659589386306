import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import qs from 'query-string';

// Constants
import { COMCAST_PHONE_NUMBERS } from 'src/constants/partner';
// Hooks && Utils
import useUpdatePhone from 'src/containers/PartnerIframePage/hooks/useUpdatePhone';

// Layout and Components
import withLayout from 'src/hoc/withLayout';
import { PARTNER_SKU_LAYOUT } from 'src/constants/common';
import IframeView from 'src/components/Elements/IframeView';

import { CALLER_PAGE_NAMES, getIframeSource } from './constants';
import { messagePageActions } from './messagePageActions';

const ComcastIframe = (props) => {
  const { src, queryParams } = props;

  const { XFINITY_PAGE } = CALLER_PAGE_NAMES;

  const source = getIframeSource(src);

  const { phone_number: phone = COMCAST_PHONE_NUMBERS.XFINITY } = qs.parse(qs.extract(queryParams));

  useUpdatePhone({ phone });
  return (
    <IframeView callerPageName={XFINITY_PAGE} src={source} additionalActions={messagePageActions} />
  );
};

ComcastIframe.propTypes = {
  push: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
  isTopNavOpen: PropTypes.bool,
  pageLoadStartTime: PropTypes.number,
  queryParams: PropTypes.string,
};

ComcastIframe.defaultProps = {
  isTopNavOpen: false,
  computersCategoryPage: false,
  pageLoadStartTime: 0,
};

export default compose(withLayout(PARTNER_SKU_LAYOUT))(ComcastIframe);
