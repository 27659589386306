import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const SkuFAQItem = ({ question }) => (
  <div className={styles.item}>
    <p className={`p1 text-weight-bold ${styles.itemQuestion}`}>{question.questionText}</p>
    <p className={`p1 n700 ${styles.itemAnswer}`}>{question.answerText}</p>
  </div>
);

SkuFAQItem.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string,
    answerText: PropTypes.string,
  }).isRequired,
};

export default SkuFAQItem;
