import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { StageEdit, StageEditHeader } from 'src/components/OrderSummary';
import OrderSummaryLineItem from 'src/components/OrderSummary/Breakdown/OrderSummaryLineItem';
import { getFormattedAvailabilityForTimeWindows } from 'src/components/AvailabilitySelectorWithConfig/Scheduling.utils';
import CartAvailabilityAccordion from './CartAvailabilityAccordion';
import { useCartServiceScheduling } from 'src/utils/serviceScheduling/useCartServiceScheduling';
import { DEFAULT_TIME_ZONE } from 'src/constants/time';
import { getFormattedChosenTimeSlot } from 'src/components/AvailabilitySelectorExactTime/AvailabilitySelectorExactTime.utils';
import { RecurringServiceBlock } from './RecurringServiceBlock';

export const CartAvailabilityBlock = ({
  isVisible,
  cart,
  onHeaderClick,
  consolidatedAvailability,
  availability,
  selectedServiceTimes,
}) => {
  const { isTimeWindowSchedule, isExactTimeSchedule } = useCartServiceScheduling();
  const isExactTime = isExactTimeSchedule();
  const isTimeWindow = isTimeWindowSchedule();
  const hasRecurringService = cart?.recurring;
  const timeZone = cart?.timezone || DEFAULT_TIME_ZONE;

  const headerText = useMemo(() => {
    let text = 'Your Availability';
    if (isTimeWindow || isExactTime) {
      text = 'Your Appointment';
    }
    return text;
  }, [isTimeWindow, isExactTime]);

  const content = useMemo(() => {
    let display;
    if (isTimeWindow && consolidatedAvailability) {
      const { formattedDate, formattedTimes } = getFormattedAvailabilityForTimeWindows(
        consolidatedAvailability,
      );
      display = (
        <>
          <p className="p1 n700">{formattedDate}</p>
          <p className="p1 n700">Tech arrival between {formattedTimes}</p>
        </>
      );
    } else if (isExactTime && availability.size > 0) {
      const timeSlot = availability.get(0).get('value');

      const formattedChosenTimeSlot = getFormattedChosenTimeSlot([timeSlot], timeZone);
      display = <OrderSummaryLineItem> {formattedChosenTimeSlot}</OrderSummaryLineItem>;
    } else if (consolidatedAvailability) {
      display = <CartAvailabilityAccordion times={selectedServiceTimes} />;
    } else {
      display = availability.map((a, i) => (
        <OrderSummaryLineItem key={i}>{a.get('display')}</OrderSummaryLineItem>
      ));
    }
    return display;
  }, [
    availability,
    consolidatedAvailability,
    isExactTime,
    isTimeWindow,
    selectedServiceTimes,
    timeZone,
  ]);

  if (!isVisible) return null;
  return (
    <>
      <StageEdit>
        <StageEditHeader headerText={headerText} onClick={onHeaderClick} />
        {content}
      </StageEdit>
      {hasRecurringService && (
        <RecurringServiceBlock onHeaderClick={onHeaderClick} text={cart.recurrenceText} />
      )}
    </>
  );
};

CartAvailabilityBlock.propTypes = {
  isVisible: PropTypes.bool,
  cart: PropTypes.object,
  onHeaderClick: PropTypes.func,
  consolidatedAvailability: PropTypes.object,
  availability: PropTypes.array,
  selectedServiceTimes: PropTypes.array,
};
