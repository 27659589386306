import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIsPasswordlessCheckoutEnabled } from 'src/containers/Account/AccountPage/AccountPage.hooks';
import { pureUserSelector } from 'src/selectors/user';
import CreateAccountPage from 'src/containers/CreateAccountPage';
import VerificationForm from './VerificationForm';
import { VerificationFormPasswordless } from './VerificationForm.Passwordless';

const VerificationPage = (props) => {
  const user = useSelector(pureUserSelector);
  const isPasswordlessCheckoutEnabled = useIsPasswordlessCheckoutEnabled();

  if (isPasswordlessCheckoutEnabled) {
    return <VerificationFormPasswordless />;
  }
  return user ? <VerificationForm /> : <CreateAccountPage location={props.location} />;
};

VerificationPage.propTypes = {
  location: PropTypes.string,
};
export default VerificationPage;
