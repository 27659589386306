// Libraries
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
// Components
import { Grid } from 'HTKit/Grid';
import { Picture, FullsizePicture, TYPES } from 'src/components/Elements/Picture';
import homepageClickSegment from 'src/containers/HomePage/actions';
import { splitioConstants, useSplitIOTreatment } from 'components/SplitIO';
import HeroDropDown from './HeroDropdown/HeroDropDown';
import HeroSearchbar from './HeroSearchbar/HeroSearchbar';
// Constants
import { HERO_THEME_DEFAULT } from './constants';
// Styles, etc.
import styles from './styles.scss';

const HomePageHero = ({ containerClasses, dropDownOptions, push, subTitle, title, theme }) => {
  const titleToShow = theme.title || title;
  const subTitleToShow = theme.subTitle || subTitle;
  const { themeColor, themeName } = theme;

  const containerStyles = cn(
    styles.container,
    containerClasses,
    styles[`${themeColor}-background`],
    styles[`${themeName}`],
  );
  const titleStyles = cn(styles.title, 'h2');
  const subTitleStyles = cn(styles.subTitle, 'p0');
  const dispatch = useDispatch();
  const handleDropdownSelection = (option) => {
    if (!option.link) return;
    dispatch(homepageClickSegment({ id: option.id, event: 'Homepage Category Dropdown' }));
    push(option.link);
  };
  const { splitTreatment } = useSplitIOTreatment(splitioConstants.SPLITIONAME_HOMEPAGE_SEARCH);
  const showSearchbar = splitTreatment === splitioConstants.ON;
  const showImageForTablet = 700; // This number was chosen by Design instead of the MD grid value
  const imageSources = [
    {
      srcSet: theme.images.lg.png,
      media: `(min-width: ${showImageForTablet}px)`,
      type: TYPES.PNG,
    },
  ];
  return (
    <section className={containerStyles}>
      <div className={cn(styles[`${themeColor}-blob`], styles.blobContainer)} />
      <Grid.Fluid>
        <Grid.Row classes={styles.rowOverride}>
          <Grid.Column sm={4} md={6} lg={5} classes={styles.textContent}>
            <div className={titleStyles}>{titleToShow}</div>
            <div className={subTitleStyles}>{subTitleToShow}</div>
            {showSearchbar ? (
              <Grid.Row classes="marginBottom-large1">
                <Grid.Column sm={4} md={7} lg={10}>
                  <HeroSearchbar />
                </Grid.Column>
              </Grid.Row>
            ) : (
              <div className={styles.dropDown}>
                <HeroDropDown
                  options={dropDownOptions}
                  push={push}
                  onSelect={handleDropdownSelection}
                />
              </div>
            )}
          </Grid.Column>
          <Grid.Column sm="hidden" classes={styles.techImageTablet} lg="hidden">
            <FullsizePicture
              center={false}
              overflow={theme === HERO_THEME_DEFAULT ? 'visible' : null}
              sources={imageSources}
              cover="height"
            />
          </Grid.Column>
          <Grid.Column sm="hidden" classes={styles.techImageDesktop} lgOffset={1} lg={5}>
            <Picture sources={imageSources} />
          </Grid.Column>
        </Grid.Row>
      </Grid.Fluid>
    </section>
  );
};

HomePageHero.propTypes = {
  containerClasses: PropTypes.string,
  dropDownOptions: PropTypes.array.isRequired,
  push: PropTypes.func,
  subTitle: PropTypes.string,
  title: PropTypes.node,
  theme: PropTypes.object,
};

HomePageHero.defaultProps = {
  containerStyles: {},
  dropDownOptions: [],
  push: () => {},
  subTitle: 'The best in-home tech solution, right at your doorstep. Book a service today!',
  title: 'In a HelloTech home, technology just works.',
  theme: HERO_THEME_DEFAULT,
};

export default memo(HomePageHero);
