import React from 'react';
import { LANDING_TEMPLATES } from 'src/constants/landings';
import {
  ProductSingleSku,
  ProductMultipleSku,
  Altice,
  AlticeNest,
  AmFam,
  MultipleAccountSku,
  GoogleProductSingleSku,
} from './templates';

/*
  This should only be used to select a template that corresponds with a value in partner_landings.template_name.
  See the LANDING_TEMPLATES constant for valid values.
*/
const LandingPageTemplateSelector = ({ landing, isMobile, isPlanPricingTest }) => {
  switch (landing.get('template')) {
    case LANDING_TEMPLATES.PRODUCT_SINGLE_SKU:
      return (
        <ProductSingleSku
          landing={landing}
          isMobile={isMobile}
          isPlanPricingTest={isPlanPricingTest}
        />
      );
    case LANDING_TEMPLATES.INTERNAL_GOOGLE_ADS_SKU:
    case LANDING_TEMPLATES.GOOGLE_ADS_SKU:
      return (
        <GoogleProductSingleSku
          landing={landing}
          isMobile={isMobile}
          isPlanPricingTest={isPlanPricingTest}
        />
      );
    case LANDING_TEMPLATES.PRODUCT_MULTIPLE_SKU:
      return <ProductMultipleSku landing={landing} isMobile={isMobile} />;
    case LANDING_TEMPLATES.INTERNAL_PRODUCT_SINGLE_SKU:
      return (
        <ProductSingleSku
          landing={landing}
          isMobile={isMobile}
          isPlanPricingTest={isPlanPricingTest}
        />
      );
    case LANDING_TEMPLATES.INTERNAL_PRODUCT_MULTIPLE_SKU:
      return <ProductMultipleSku landing={landing} isMobile={isMobile} />;
    case LANDING_TEMPLATES.OPTIMUM_GENERAL:
    case LANDING_TEMPLATES.OPTIMUM_MOVERS:
    case LANDING_TEMPLATES.OPTIMUM_SUPPORT:
    case LANDING_TEMPLATES.SUDDENLINK_GENERAL:
    case LANDING_TEMPLATES.SUDDENLINK_MOVERS:
    case LANDING_TEMPLATES.SUDDENLINK_SUPPORT:
      return <Altice landing={landing} isMobile={isMobile} />;
    case LANDING_TEMPLATES.ALTICE_NEST:
      return <AlticeNest landing={landing} isMobile={isMobile} />;
    case LANDING_TEMPLATES.FEATURED_CONTENT_OR_SKU:
      return <AmFam landing={landing} isMobile={isMobile} />;
    case LANDING_TEMPLATES.MULTIPLE_ACCOUNT_SKU:
      return <MultipleAccountSku landing={landing} isMobile={isMobile} />;
    case LANDING_TEMPLATES.SMB_SKU:
      return <MultipleAccountSku landing={landing} isMobile={isMobile} isSmb />;
    default:
      throw new Error(`Unknown template: ${landing.get('template')}`);
  }
};

export default LandingPageTemplateSelector;
