import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
// Hooks
import { useDispatch, useSelector } from 'react-redux';
import useSignUp from 'src/containers/CreateAccountPage/useSignUp';
import { useSignupForPartnerPlanRedemption } from 'src/containers/CreateAccountPage/useSignupForPartnerPlanRedemption';
import useAPI from 'src/hooks/useAPI';
// Actions
import { layoutUpdate } from 'src/actions/common';
// Selectors
import { pureCartSelector } from 'src/selectors/cart';
import { partnerInfoJSSelector } from 'src/selectors/partner';
// Utils
import { resolveBookingPageLayout } from 'src/utils/layout';
import { PARTNERS } from 'src/constants/common';
import { partnerInfoLoaded } from 'src/actions/partnerMemberships';
// Components
import { Grid } from 'src/components/HTKit/Grid';
import CheckoutUserProfileForm from 'src/components/Forms/CheckoutUserProfileForm';
import PageLoader from 'src/components/PageLoader';

const CreateAccountPage = ({ location: { state = {} } }) => {
  const dispatch = useDispatch();
  const api = useAPI();
  const [pageLoaded, setPageLoaded] = useState(false);
  const cart = useSelector(pureCartSelector);
  const { planId, partnerName, partnerMembershipsId, ...user } = state;
  const partnerInfo = useSelector(partnerInfoJSSelector(partnerName));

  const regularSignUp = useSignUp();
  const partnerPlanSignUp = useSignupForPartnerPlanRedemption({ planId, partnerMembershipsId });
  const signUp = planId ? partnerPlanSignUp : regularSignUp;

  /**
   * With the new partner membership redemption flow (innover), the cart's partner identity cannot
   * be determined because the plan has not been added to the cart yet. For this particular flow,
   * the plan cannot be added since the `selectPlan` endpoint requires a client email for partner
   * membership plan, which is not available at this stage. Here, we are using the partner name
   * to manuall trigger the cobranding layout and to fetch partner details for the logos.
   */
  useEffect(() => {
    (async () => {
      if (pageLoaded) return;
      const partnerLayoutName = partnerName ? PARTNERS.COBRAND : null;
      const layoutOverrides = partnerName ? { header: { partnerKey: partnerName } } : null;

      dispatch(
        layoutUpdate(
          resolveBookingPageLayout({ cart, partnerLayout: partnerLayoutName, layoutOverrides }),
        ),
      );

      if (partnerName) {
        if (!partnerInfo) {
          const response = await api.partnerMemberships.info({
            company_name: partnerName,
            force_case: 'camel',
          });
          if (!response.err) {
            const { partner } = response.data;
            dispatch(partnerInfoLoaded({ key: partnerName, data: partner }));
          }
        }
      }
      setPageLoaded(true);
    })();
  }, [partnerName, partnerInfo]);

  const onValidation = ({ params, form, optParams = {}, opts = {} }) => {
    signUp({ params, optParams, opts })
      .then(async () => {
        form.resetForm({});
      })
      .catch((err) => {
        if (err.data) {
          form.setErrors(err.data.formErrors);
        }
      });
  };

  return (
    <Grid.Fluid>
      <Helmet title="Create an Account" />
      {pageLoaded ? (
        <div className="marginTop-medium2">
          <CheckoutUserProfileForm
            user={user}
            opts={{ partnerName, ...user }}
            onValidation={onValidation}
            header="Create an Account"
            buttonText="Create Account"
          />
        </div>
      ) : (
        <PageLoader />
      )}
    </Grid.Fluid>
  );
};

CreateAccountPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string,
      phone: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      planId: PropTypes.number,
      partnerName: PropTypes.string,
      partnerMembershipsId: PropTypes.number,
    }),
  }),
};

export default memo(CreateAccountPage);
