import { useState, useEffect } from 'react';

/**
 * This is the default operation on how we want to reduce on.
 * We offer an inversion of control if needed, but the default should suffice.
 * @param value
 * @returns {boolean}
 */
const defaultFunc = value => typeof value === 'boolean';


/**
 * This should be used with classnames as it returns an object of classnames with a boolean true so
 * 'classnames' package can consume them.
 * TODO: pass flag to circumvent the key/value association and just return what want.
 *
 * @param attrs
 * @param styles
 * @param fn
 * @returns {{
 *   myClassone: true,
 *   myClasstwo: true
 * }}
 */
export default (attrs = {}, styles = {}, fn = defaultFunc) => {
  const [newStyles, setNewStyles] = useState({...styles});

  useEffect(() => {
    const validStyles = Object
      .entries(attrs)
      .filter(([,v]) => Boolean(v))
      .reduce((accum, [k, v]) => ({...accum, ...(fn(v) ? { [styles[k]]: v } : {}) }), {});

    setNewStyles(validStyles);

  }, []);

  return newStyles;
};

