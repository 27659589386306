import React from 'react';
import PropTypes from 'prop-types';
import { prevented } from 'src/utils/event';
import { buildLocationsLink } from 'src/utils/locations';
import MenuTemplate from '../MenuTemplate';

const ServiceMenu = ({ visible, setServiceMenuVisibleState, selectedCategory, skuLinkPrefix }) => {
  const { name = '', skus = [] } = selectedCategory;

  const headerItems = [
    {
      text: name,
      onBackClick: prevented(() => setServiceMenuVisibleState(false)),
    },
  ];

  const secondaryItems = skus.map((sku) => {
    const { link = '', name: skuName } = sku;
    return {
      text: skuName,
      link: skuLinkPrefix ? buildLocationsLink({ link, prefix: skuLinkPrefix }) : link,
    };
  });

  return (
    <MenuTemplate
      slide
      visible={visible}
      headerItems={headerItems}
      secondaryItems={secondaryItems}
    />
  );
};

ServiceMenu.propTypes = {
  visible: PropTypes.bool.isRequired,
  setServiceMenuVisibleState: PropTypes.func.isRequired,
  selectedCategory: PropTypes.object,
  skuLinkPrefix: PropTypes.string,
};

export default React.memo(ServiceMenu);
