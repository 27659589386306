import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import { LAST_FOUR_DEFAULT_HOME_PAGE } from './constants';
/**
 * We need an override for home_page split display. If not an override, just
 * return out what was passed in.
 *
 * @param l : link
 * @param t: title
 * @param splitCaller : this tells us if our caller needs this
 */
export const HomePageSplitOverride = (l = '', t = '', splitCaller) => {
  const { splitConfig, splitTreatment } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_NAV_PHONE_NUMBER,
  );
  const overrideSplitIOPhone9005 = l.endsWith(LAST_FOUR_DEFAULT_HOME_PAGE) && splitCaller;
  let link = l;
  let title = t;

  if (overrideSplitIOPhone9005) {
    link = '';
    title = '';
    if (splitTreatment === splitioConstants.ON) {
      link = splitConfig.link;
      title = splitConfig.title;
    }
  }

  return {
    link,
    title,
  };
};
