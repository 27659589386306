import React from 'react';
import PropTypes from 'prop-types';
import { AffirmMonthlyPayment } from 'src/components/Affirm';
import OrderSummaryLineItem from './Breakdown/OrderSummaryLineItem';

const CardData = ({ brand, digits, expiration, ...rest }) => {
  return (
    <OrderSummaryLineItem {...rest}>
      <div>
        {brand} ending in {digits}
      </div>
      <div>Exp {expiration}</div>
    </OrderSummaryLineItem>
  );
};

export const CardDataAffirm = () => {
  return (
    <OrderSummaryLineItem>
      <AffirmMonthlyPayment />
    </OrderSummaryLineItem>
  );
};


CardData.propTypes = {
  brand: PropTypes.string,
  digits: PropTypes.string,
  expiration: PropTypes.string,};

CardData.defaultProps = {
  brand: '',
  digits: '',
  expiration: '',
};

export default React.memo(CardData);
