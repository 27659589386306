import { NO_HEADER_NO_FOOTER_LAYOUT } from 'src/constants/common';

export const PAGE_NAME = 'zipCodeValidationPage';
export const SUBMIT_FORM = `${PAGE_NAME}/SUBMIT_FORM`;
export const RESET_FORM = `${PAGE_NAME}/RESET_FORM`;
export const ZIP_VALIDATED = `${PAGE_NAME}/ZIP_VALIDATED`;
export const ZIP_NOT_VALIDATED = `${PAGE_NAME}/ZIP_NOT_VALIDATED`;
export const HSN_CLICK_SEGMENT = `${PAGE_NAME}/HSN_CLICK_SEGMENT`;

export const ZIP_CODE_VALIDATION_LAYOUT = NO_HEADER_NO_FOOTER_LAYOUT;

export const ZIP_CODE_VALIDATION_RULES = {
  empty: {
    zipCode: { presence: { allowEmpty: false, message: "^ZIP code can't be blank" } },
  },
  notEmpty: {
    zipCode: { length: { is: 5, message: '^ZIP code should be 5 numbers' } },
  },
};
