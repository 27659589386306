import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// cookies & utils
import { getPromotionalBannerCookie, getBrandingCookie } from 'utils/cookies';

/**
 * These are the pages that the promo will not show on.
 * @type {*[]}
 */
const EXCLUDED_CONTAINED_PATHS = ['cart', 'account', 'addSku', 'landings', 'location'];
const EXCLUDED_BY_COOKIES = [getPromotionalBannerCookie, () => Boolean(getBrandingCookie())];
/**
 * Helper to determine if our conditional meets expectation to not show promo
 * @param conditionalArray - array of strings to match against
 * @returns {boolean}
 */
const hasExclusionPath =
  (...conditionalArray) =>
    EXCLUDED_CONTAINED_PATHS.some(path =>
      conditionalArray.some(conditional => conditional.includes(path)));

/**
 * Break out the cookie tests.
 * @param conditionalArray
 * @returns {boolean}
 */

const hasExclusionByCookie = () => EXCLUDED_BY_COOKIES.some(cookie => cookie() === true);
/**
 * Flow control to determine if any of our conditions to omit/exclude pass
 */

const excludeByCondition = (props) => ([
  () => hasExclusionPath(props.pathName, props.currentPageName),
  hasExclusionByCookie,
  () => !props.showSiteLinks,
].some(fn => fn()));
/**
 * This extracts out the determination of whether to show the promo or not as far as external
 * determinants. ie.. pagename. I asked if there would be more than one promo, and
 * they said no, so, no need to match a promo type with conditionals.
 *
 * We will use both pathname and pagename to determine to show.
 *
 * @param Component
 * @returns {Function}
 * @constructor
 *
 */
// eslint-disable-next-line react/display-name
const Guard = (Component) => (props) => {
  if (excludeByCondition(props)) return null;

  return <Component {...props} />;
};

Guard.propTypes = {
  currentPageName: PropTypes.string,
  pathName: PropTypes.string,
  showSiteLinks: PropTypes.bool,
};

/**
 * Another item for easier transition when tool is ready. Assuming this is data path, if not,
 * just change it up to match.
 */
function mapStateToProps(state) {
  return {
    currentPageName: state.getIn(['pages', 'current', 'name']),
    pathName: state.getIn(['router', 'location', 'pathname']),
    showSiteLinks: state.getIn(['layout', 'header', 'showSiteLinks']),
  };
}

const PromoGuard = Component => connect(mapStateToProps)(Guard(Component));

PromoGuard.defaultProps = {
  pathName: '',
  currentPageName: '',
};

export default PromoGuard;
