import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import { Description, StackedHeader } from 'src/components/SubscriptionBenefitsBreakdown/Parts';
import getPlanBenefitsListAndValue from 'src/components/SubscriptionBenefitsBreakdown/utils';
import BENEFITS_ELEMENT_NAME from 'src/containers/CartPage/CartTypes/UpsellCart/Mobile/constants';
import styles from './styles.scss';

const BenefitsBreakdown = ({ planDetails, plansInfo }) => {
  const benefitsListAndValue = useMemo(() => {
    return getPlanBenefitsListAndValue({ plan: planDetails });
  }, [planDetails]);
  const { benefitsList, benefitsValue } = benefitsListAndValue;
  const planName = planDetails.name;
  const amountFormatted = `$${plansInfo.monthly.amount}/month membership*`;

  return (
    <Element name={BENEFITS_ELEMENT_NAME}>
      <StackedHeader
        planName={planName}
        benefitsValue={benefitsValue}
        planAmount={amountFormatted}
        wrapperStyle={styles.breakdownSpacer}
      />
      {benefitsList.length > 0 &&
        benefitsList.map((benefit) => {
          const { name } = benefit;
          return <Description key={name} benefit={benefit} wrapperStyle={styles.breakdownSpacer} />;
        })}
    </Element>
  );
};

BenefitsBreakdown.propTypes = {
  planDetails: PropTypes.object,
  plansInfo: PropTypes.object,
};

export default BenefitsBreakdown;
